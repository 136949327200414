import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {AuthService} from '../../../auth/auth.service';

import {IPermissionData} from '../../../core/models/permission/i-permission-data';

import {PERMISSIONS} from '../../../core/services/service-permissions/constants';

import {AppAnimations} from '../../../app-animations';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class MailComponent implements OnDestroy {
  public isLoaded: boolean = false;
  public isUserHasPermissions: boolean = false;

  public title: string = 'This feature is for Professional Accounts Only.';
  public description: string = 'Mailchimp is the leading email marketing platform. With a Mailchimp account you can easily make professional emails, email newsletters and manage your email mailing list.';
  public permissions: IPermissionData[] = [
    {
      type: 'permission',
      value: PERMISSIONS.MAILING_LIST,
    },
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private permissionsService: PermissionsService,
    private authService: AuthService,
  ) {
    this.authService.permissionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.isLoaded = this.authService.isPermissionsFetched;
    });

    this.permissionsService.isUserHasPermissionsObservable(this.permissions).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isUserHasPermissions = isAllowed;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
