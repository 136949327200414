import {Component, OnInit} from '@angular/core';

import {RepeatPaymentService} from '../../../../../../core/services/payment/subscriptions/repeat-payment/repeat-payment.service';
import {AuthService} from '../../../../../../auth/auth.service';

import {CardModel} from '../../../../../../core/models/payment/card/card.model';

import {STEPS} from './constants';

@Component({
  selector: 'app-repeat-payment',
  templateUrl: './repeat-payment.component.html',
  styleUrls: ['./repeat-payment.component.scss'],
})
export class RepeatPaymentComponent implements OnInit {
  public currentStep: string;

  public get isCardsStep(): boolean {
    return this.currentStep === STEPS.CARDS;
  }

  public get isConfirmationStep(): boolean {
    return this.currentStep === STEPS.CONFIRMATION;
  }

  constructor(
    private service: RepeatPaymentService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.currentStep = STEPS.CARDS;

    this.service.verify();
  }

  public onCardSelect(card: CardModel) {
    this.service.card = card;

    if (this.service.invoiceId) {
      return this.updateInvoiceCard();
    }

    return this.updateSubscriptionCard(card);
  }

  private updateInvoiceCard() {
    if (!this.service.invoiceId) {
      return;
    }

    this.currentStep = STEPS.CONFIRMATION;
  }

  private updateSubscriptionCard(card: CardModel) {
    if (!this.service.currentSubscription) {
      return;
    }

    const data = {
      subscriptionId: this.service.currentSubscription ? this.service.currentSubscription.id : null,
      cardId: card.id,
    };

    return this.service.updateSubscriptionCard(data).subscribe(() => {
      if (!this.service.isCardUpdated) {
        return this.service.toPurchase();
      }

      this.currentStep = STEPS.CONFIRMATION;
    });
  }

  public onConfirm() {
    return this.service.retryPayment().add(() => {
      this.service.clear();
      this.service.initCurrentSubscription();

      return this.authService.updateCurrentUser(false);
    });
  }
}
