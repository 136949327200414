// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .fonts-manager-modal .modal-body {
  padding: 0 !important;
  width: calc(100vw - 100px) !important;
  max-width: calc(100vw - 100px) !important;
  height: calc(100vh - 100px) !important;
  max-height: calc(100vh - 100px) !important;
  min-height: calc(100vh - 100px) !important;
}
:host ::ng-deep .fonts-manager-modal app-close-icon {
  position: absolute;
  right: 0;
  top: 0;
}
:host ::ng-deep .fonts-manager-modal app-close-icon .close-btn {
  transform: translate(200%, 25%) scale(1.25) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/fonts-manager/fonts-manager-modal.component.scss"],"names":[],"mappings":"AAII;EACE,qBAAA;EACA,qCAAA;EACA,yCAAA;EACA,sCAAA;EACA,0CAAA;EACA,0CAAA;AAHN;AAMI;EACE,kBAAA;EACA,QAAA;EACA,MAAA;AAJN;AAMM;EACE,sDAAA;AAJR","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .fonts-manager-modal {\n    .modal-body {\n      padding: 0 !important;\n      width: calc(100vw - 100px) !important;\n      max-width: calc(100vw - 100px) !important;\n      height: calc(100vh - 100px) !important;\n      max-height: calc(100vh - 100px) !important;\n      min-height: calc(100vh - 100px) !important;\n    }\n\n    app-close-icon {\n      position: absolute;\n      right: 0;\n      top: 0;\n\n      .close-btn {\n        transform: translate(200%, 25%) scale(1.25) !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
