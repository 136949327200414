import {Component, Input} from '@angular/core';

import { PaypalEmailNotSetInfoModalService } from '../../../services/modals/paypal-email-not-set-info/paypal-email-not-set-info-modal.service';
import { NavigationService } from '../../../../services/navigation.service';

import { Button } from '../../../../common/models/button/button.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-paypal-email-not-set-info-modal',
  templateUrl: './paypal-email-not-set-info-modal.component.html',
  styleUrls: ['./paypal-email-not-set-info-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PaypalEmailNotSetInfoModalComponent {
  @Input() header: string = '';

  public buttons: Button[] = [
    new Button('OK', null, 'red ok-button', this.close.bind(this)),
  ];

  constructor(
    public service: PaypalEmailNotSetInfoModalService,
    public navigationService: NavigationService,
  ) {}

  public goToSettings(): void {
    this.navigationService.toPayPalSettings();

    this.close();
  }

  public close(): void {
    this.service.close();
  }
}
