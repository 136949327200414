import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {CUSTOM_STYLE_PROPERTY, CUSTOM_STYLE_PROPERTY_PX, DEFAULT_VALUE} from './constants';

export class ItemSeparatorWidthModel implements IStyleOption {
  public menu: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.menu = options.block;

    if (!this.menu) return;

    const currentValue: string = this.menu.style.getPropertyValue(CUSTOM_STYLE_PROPERTY);

    if (currentValue) return this.setValue(currentValue === 'undefined' ? void 0 : currentValue);

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);
    const value: string = computedValue.getPropertyValue(CUSTOM_STYLE_PROPERTY) || DEFAULT_VALUE;

    this.setValue(value === 'undefined' ? void 0 : value);
  }

  public reset() {
    if (!this.isEnabled || !this.element || !this.menu) return;

    this.menu.style.removeProperty(CUSTOM_STYLE_PROPERTY);
    this.menu.style.removeProperty(CUSTOM_STYLE_PROPERTY_PX);

    const computedValue = window.getComputedStyle(this.element);
    const value = computedValue.getPropertyValue(CUSTOM_STYLE_PROPERTY) || DEFAULT_VALUE;

    this.setValue(value);
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.menu) return;

    this.value = value;
    this.menu.style.setProperty(CUSTOM_STYLE_PROPERTY, value);
    this.menu.style.setProperty(CUSTOM_STYLE_PROPERTY_PX, `${value}px`);
  }
}
