import {Injectable} from '@angular/core';
import {IMAGE_RESOLUTIONS} from './image-resolution.constants';
import {ModalsService} from '../shared/services/modals/modals.service';
import {SidebarSectionsService} from './sidebar-sections.service';
import {Sections} from '../application/sidebar-short/constants';

@Injectable()
export class ImageResolutionService {
  private cancelButton = {
    text: 'Cancel',
    className: 'neutral ok-button',
    onClick: () => this.closeImageResolutionWarningModal(),
  };
  private submitButton = {
    text: 'Continue',
    className: 'neutral ok-button',
    onClick: () => this.closeImageResolutionWarningModal(),
  };
  private okButton = {
    text: 'OK',
    className: 'neutral ok-button',
    onClick: () => this.closeImageResolutionInfoModal(),
  };

  public imageResolution = IMAGE_RESOLUTIONS.NON_FULL_BLEED_IMAGE;
  public ids = {
    pages: {
      info: 'image-resolution-pages-info-modal',
      warning: 'image-resolution-pages-warning-modal',
    },
    app: {
      info: 'image-resolution-app-info-modal',
      warning: 'image-resolution-app-warning-modal',
    }
  };

  get badImageResolutionMessage() {
    return this.imageResolution.message;
  }

  get infoButtons() {
    return [this.okButton];
  }

  get warningButtons() {
    if (this.imageResolution === IMAGE_RESOLUTIONS.FULL_BLEED_IMAGE) return [this.submitButton, this.cancelButton];

    return [this.okButton];
  }

  get modalComponent() {
    return [Sections.Pages, Sections.Blocks, Sections.Templates].includes(this.sidebarSectionsService.selectedSection) ? this.ids.pages : this.ids.app;
  }

  get isNonFullBleedImage() {
    return this.imageResolution === IMAGE_RESOLUTIONS.NON_FULL_BLEED_IMAGE;
  }

  constructor(private modalsService: ModalsService,
              private sidebarSectionsService: SidebarSectionsService) {}

  setImageResolutionRequirements(imageResolution: string | undefined = IMAGE_RESOLUTIONS.NON_FULL_BLEED_IMAGE.type) {
    this.imageResolution = IMAGE_RESOLUTIONS[imageResolution];
  }

  isImageResolutionSufficient(image, imageResolution?, isOnLongestSide?) {
    const resolution = imageResolution || this.imageResolution;

    const width = image.width || image.widthPx || image.WidthPx;
    const height = image.height || image.heightPx || image.HeightPx;

    if (!isOnLongestSide) return resolution.longestSideMinPx <= width && resolution.longestSideMinPx <= height;

    const longestSide = Math.max(width, height);

    return resolution.longestSideMinPx <= longestSide;
  }

  showImageResolutionInfoModal() {
    this.modalsService.open(this.modalComponent.info);
  }

  closeImageResolutionInfoModal() {
    this.modalsService.close(this.modalComponent.info);
  }

  showImageResolutionWarningModal() {
    this.modalsService.open(this.modalComponent.warning);
  }

  closeImageResolutionWarningModal() {
    this.modalsService.close(this.modalComponent.warning);
  }

  onSubmit(callback) {
    this.submitButton.onClick = callback;
  }
}
