import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {STYLE_PROPERTIES} from './constants';

export class MenuDropdownArrowPaddingModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = options.block;

    if (!this.isEnabled || !this.element) return;

    const value: string = this.element.style.getPropertyValue(STYLE_PROPERTIES[this.key]) || getComputedStyle(this.element).getPropertyValue(STYLE_PROPERTIES[this.key]);

    this.setValue(value === 'undefined' ? void 0 : value);
  }

  public reset() {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(STYLE_PROPERTIES[this.key]);

    const value = this.element.style.getPropertyValue(STYLE_PROPERTIES[this.key]) || getComputedStyle(this.element).getPropertyValue(STYLE_PROPERTIES[this.key]);

    this.setValue(value);
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.element) return;

    this.value = value;
    this.element.style.setProperty(STYLE_PROPERTIES[this.key], value);
  }
}
