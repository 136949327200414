import {CaptchaDataModel} from './captcha-data.model';
import {DomainDto} from '../domain/domain.dto';
import {DomainModel} from '../domain/domain.model';

export class CaptchaDataDto {
  constructor(public IsCaptchaEnabled?: boolean,
              public Domains?: DomainDto[]) {}

  public static normalize(res: CaptchaDataDto): CaptchaDataModel {
    if (!res) return null;

    const domains = res.Domains ? res.Domains.map((domain: DomainDto) => DomainDto.normalize(domain)) : [];

    return new CaptchaDataModel(
      res.IsCaptchaEnabled,
      domains,
    );
  }

  public static toRequest(model: CaptchaDataModel): CaptchaDataDto {
    if (!model) return null;

    const domains = model.domains ? model.domains.map((domain: DomainModel) => DomainDto.toRequest(domain)) : [];

    return new CaptchaDataDto(
      model.isCaptchaEnabled,
      domains,
    );
  }
}
