import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../modals.service';
import {WebsiteTourService} from '../../../../core/services/website-tour/website-tour.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';

@Injectable()
export class CustomHomePageModalService {
  public readonly id: string = 'custom-homepage-modal';

  public isOpened: boolean = false;


  public constructor(
    private modalsService: ModalsService,
    private websiteTourService: WebsiteTourService,
    private iFrameService: IFrameService,
  ) {
  }

  public open(): void {
    const unsubscribe1: Subject<boolean> = new Subject<boolean>();

    this.iFrameService.isLoadedSubject.pipe(takeUntil(unsubscribe1)).subscribe((isLoaded: boolean) => {
      if (!isLoaded) return;

      const unsubscribe2: Subject<boolean> = new Subject<boolean>();

      this.websiteTourService.isOpened.pipe(takeUntil(unsubscribe2)).subscribe((isOpened: boolean) => {
        if (isOpened) return;

        this.modalsService.open(this.id);

        this.isOpened = true;

        unsubscribe2.next(true);
        unsubscribe2.complete();
      });

      unsubscribe1.next(true);
      unsubscribe1.complete();
    });
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
