import {SelectOption} from '../../../select/option/option.model';

export const TYPES = {
  INFO_ICON: 'INFO_ICON',
  INFO_BUTTON: 'INFO_BUTTON',
  HIDE: 'HIDE',
};

export const POSITIONS = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const ATTRIBUTES = {
  TYPE: 'data-image-label-button-type',
  POSITION: 'data-image-label-button-position',
  CUSTOM_SETUP: 'data-image-label-button-custom-setup',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('i overlay', TYPES.INFO_ICON),
  new SelectOption('INFO link', TYPES.INFO_BUTTON),
  new SelectOption('Hide', TYPES.HIDE),
];

export const POSITION_OPTIONS: SelectOption[] = [
  new SelectOption('Left', POSITIONS.LEFT),
  new SelectOption('Right', POSITIONS.RIGHT),
];

export const DEFAULT_TYPE: string = TYPES.INFO_ICON;
export const DEFAULT_POSITION: string = POSITIONS.LEFT;
