import {SeverityModel} from './severity.model';

export class SeverityDto {
  constructor(public Id?: number,
              public Name?: string) {}

  public static normalize(res: SeverityDto): SeverityModel {
    if (!res) return null;

    return new SeverityModel(
      res.Id,
      res.Name,
    );
  }
}
