import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PaymentService} from '../../../../../core/services/payment/payment.service';

import {PaymentModel} from '../../../../../core/models/payment/payment.model';
import {CardModel} from '../../../../../core/models/payment/card/card.model';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit, OnDestroy {
  @Input() isCardSelect: boolean = false;
  @Input() card: CardModel;
  @Input() selectedCard: CardModel;

  @Output() selectHandler: EventEmitter<CardModel> = new EventEmitter<CardModel>();

  public paymentData: PaymentModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private paymentService: PaymentService) {
  }

  public ngOnInit(): void {
    this.paymentService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.paymentData = value;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onMakeDefault(card: CardModel): Subscription {
    return this.paymentService.setDefaultCard(card.id).subscribe(() => {});
  }

  public onDelete(card: CardModel): Subscription {
    return this.paymentService.deleteCard(card.id).subscribe(() => {});
  }

  public onClick(e: MouseEvent, card: CardModel) {
    if (!this.isCardSelect) return;

    const target: HTMLElement = <HTMLElement>e.target;

    if (!!target.closest('.card-action.delete')) return;

    this.selectedCard = this.selectedCard && card.id === this.selectedCard.id ? null : card;

    this.selectHandler.emit(this.selectedCard);
  }
}
