import {SubscriptionModel} from '../payment/subscriptions/subscription.model';
import {WebsitesTemplatesPermissionsModel} from './templates-permissions/templates-permissions.model';

export class WebsiteModel {
  public isBlocked: boolean = false;
  public url: string = null;

  public get isUaSetupCompleted(): boolean {
    return !!this.gaCode && !this.gaCode.startsWith('G-');
  }

  public get isGa4SetupCompleted(): boolean {
    return !!this.ga4MeasurementId;
  }

  constructor(
    public id: number,
    public userId: number,
    public institutionId: number,
    public classId: number,
    public type: string,
    public templateId: number,
    public subscriptionId: number,
    public mailChimpListId: string,
    public currencyId: number,
    public measureUnitId: number,
    public gaAccountId: number,
    public gaCode: string,
    public ga4AccountName: string,
    public ga4PropertyName: string,
    public ga4MeasurementId: string,
    public title: string,
    public subtitle: string,
    public publishStatus: string,
    public copyrightNotice: string,
    public contact: string,
    public contactName: string,
    public contactEmail: string,
    public contactPhone: string,
    public contactAddress1: string,
    public contactAddress2: string,
    public company: string,
    public contactAddressCityStateZipCountry: string,
    public metaTagDescription: string,
    public metaTagKeywords: string,
    public menuStyle: string,
    public mobileMenuStyle: string,
    public mobilePortfolioKey: string,
    public mobileEnlargementKey: string,
    public privatePagePassword: string,
    public privatePagePasswordTimeUpdate: string,
    public payPalEmail: string,
    public faviconImage: string,
    public randomizeFromQueue: boolean,
    public templatesPermissions: WebsitesTemplatesPermissionsModel[],
    public subscription: SubscriptionModel,
    public isSetupCompleted: boolean,
    public isRightClickDisabled: boolean,
    public isCopyrightNoticeEnabled: boolean,
    public isEverPublished: boolean,
    public isCaptchaEnabled: boolean,
    public isBlogCommentsApprovable: boolean,
    public isSimpleImageLabel: boolean,
    public isMainStudentWebsite: boolean,
    public timeUpdatedOffset: string,
  ) {}
}
