import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ButtonsService} from '../../../services/buttons/buttons.service';
import {EditorDevicesService} from '../../../services/editor-devices/editor-devices.service';
import {WebsiteTourService} from '../../../services/website-tour/website-tour.service';

import {TOUR_KEY} from './constants';
import {DEVICES, DEVICES_TO_BUTTONS} from '../../../services/editor-devices/constants';
import {KEYS} from '../../../services/website-tour/constants';

@Component({
  selector: 'app-device-bar',
  templateUrl: './device-bar.component.html',
  styleUrls: ['./device-bar.component.scss'],
})
export class DeviceBarComponent implements OnInit, OnDestroy {
  public device: string;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get devices() {
    return DEVICES;
  }

  constructor(private buttonsService: ButtonsService,
              private websiteTourService: WebsiteTourService,
              private editorDevicesService: EditorDevicesService) {
    this.editorDevicesService.onDeviceChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((deviceKey: string) => {
      this.device = deviceKey;
    });

    this.editorDevicesService.onDeviceIconChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((deviceKey: string) => {
      this.device = deviceKey;
    });
  }

  public ngOnInit(): void {
    this.websiteTourService.addVisibleLocation(TOUR_KEY);
    this.websiteTourService.addVisibleItem(KEYS.DEVICES_BUTTONS);
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(TOUR_KEY);
    this.websiteTourService.removeVisibleItem(KEYS.DEVICES_BUTTONS);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onDeviceSelect(deviceKey: string) {
    this.buttonsService.setButtonState(DEVICES_TO_BUTTONS[deviceKey], true);
  }
}
