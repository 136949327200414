export class DiskSpaceModel {
  constructor(public total?: number,
              public used?: number,
              public images?: number,
              public audios?: number,
              public videos?: number,
              public documents?: number,
              public froalaImages?: number) {}

  public static normalize(res: DiskSpaceModel): DiskSpaceModel {
    return new DiskSpaceModel(
      res.total,
      res.used,
      res.images,
      res.audios,
      res.videos,
      res.documents,
      res.froalaImages,
    );
  }
}
