import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';

import {PortfolioDefaultsModel} from '../../../models/images/default/portfolio-defaults.model';
import {PortfolioDefaultsRequest} from '../../../models/images/default/portfolio-defaults.request';

@Injectable()
export class PortfolioDefaultsService {
  public portfolioDefaultsSubject: BehaviorSubject<PortfolioDefaultsModel> = new BehaviorSubject<PortfolioDefaultsModel>(null);

  constructor(private httpService: ImageManagerService) {
  }

  public fetchPortfolioDefaults(nodeId: number): Subscription {
    return this.httpService.fetchPortfolioDefaults(nodeId).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      this.portfolioDefaultsSubject.next(portfolioDefaults);
    });
  }

  public initOnSave(req: PortfolioDefaultsRequest) {
    this.portfolioDefaultsSubject.next(PortfolioDefaultsRequest.normalize(req));
  }
}
