// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-subscription-remove-error-modal-body {
  margin: 20px;
  clear: both;
  overflow: hidden;
  font-size: 16px;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/custom-subscription-remove-error/custom-subscription-remove-error-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.custom-subscription-remove-error-modal-body {\n  margin: 20px;\n  clear: both;\n  overflow: hidden;\n  font-size: 16px;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
