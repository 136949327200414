import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {BehaviorSubject, Observable, Subscription, catchError, of, tap, throwError} from 'rxjs';

import {TemplatesHttpService} from './http/templates-http.service';
import {WebsitesService} from '../websites/websites.service';
import {NodesService} from '../nodes/nodes.service';
import {NavigationService} from '../../../services/navigation.service';
import {EditorDevicesService} from '../editor-devices/editor-devices.service';
import {WebsitesHttpService} from '../interaction/http/websites/websites-http.service';
import {WebsiteDesignerService} from '../../../application/main/website-designer/website-designer.service';
import {SetupLoaderService} from '../loaders/setup/setup-loader.service';
import {PagesService} from '../../../application/sidebar-short/sidebar/pages/pages.service';
import {CustomHomePageModalService} from '../../../shared/services/modals/custom-home-page/custom-home-page-modal.service';
import { HomepageImageSelectModalService } from '../../../shared/services/modals/homepage-image-select/homepage-image-select-modal.service';

import {TemplateModel} from '../../models/templates/template.model';
import {TemplatePageModel} from '../../models/templates/pages/template-page.model';
import {NodeModel} from '../../models/nodes/node.model';
import {WebsiteModel} from '../../models/websites/website.model';

import {DEFAULT_WIDTH, DEVICES_TO_WIDTH} from './constants';
import {DEVICES} from '../editor-devices/constants';
import { IFrameService } from '../iframe/iframe.service';

@Injectable()
export class TemplatesService {
  public isWebsitePreviewSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public templatesSubject: BehaviorSubject<TemplateModel[]> = new BehaviorSubject<TemplateModel[]>([]);
  public activeTemplateSubject: BehaviorSubject<TemplateModel> = new BehaviorSubject<TemplateModel>(null);
  public selectedTemplateSubject: BehaviorSubject<TemplateModel> = new BehaviorSubject<TemplateModel>(null);
  public templatePagesSubject: BehaviorSubject<TemplatePageModel[]> = new BehaviorSubject<TemplatePageModel[]>([]);
  public windowWidthSubject: BehaviorSubject<string> = new BehaviorSubject<string>(DEFAULT_WIDTH);
  public isTemplateBeingUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private activeTemplateId: number;
  private homePage: NodeModel = null;

  private isSetup: boolean = false;

  public get templates(): TemplateModel[] {
    return this.templatesSubject.value;
  }

  constructor(private router: Router,
              private websiteDesignerService: WebsiteDesignerService,
              private httpService: TemplatesHttpService,
              private setupLoaderService: SetupLoaderService,
              private websitesService: WebsitesService,
              private websitesHttpService: WebsitesHttpService,
              private nodesService: NodesService,
              private navigationService: NavigationService,
              private iFrameService: IFrameService,
              private homepageImageSelectModalService: HomepageImageSelectModalService,
              private pagesService: PagesService,
              private customHomePageModalService: CustomHomePageModalService,
              private editorDevicesService: EditorDevicesService) {
    this.init();
  }

  private init(): void {
    this.httpService.fetchAll().subscribe((templates: TemplateModel[]) => {
      this.templatesSubject.next(templates);

      this.initActiveTemplate();
    });

    this.websitesService.activeTemplateIdSubject.subscribe((activeTemplateId: number) => {
      this.activeTemplateId = activeTemplateId;

      this.initActiveTemplate();
    });

    this.nodesService.nodesSubject.subscribe(() => {
      this.homePage = this.nodesService.homePage;
    });

    this.editorDevicesService.onDeviceChangeSubject.subscribe((deviceKey: string) => {
      this.windowWidthSubject.next(DEVICES_TO_WIDTH[deviceKey] || DEFAULT_WIDTH);
    });

    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      this.isSetup = website && !website.isSetupCompleted;
    });
  }

  private initActiveTemplate(): void {
    const templates = this.templates;

    if (!templates || !this.activeTemplateId) return;

    const activeTemplate = templates.find((template: TemplateModel) => template.id === this.activeTemplateId);

    this.activeTemplateSubject.next(activeTemplate);
  }

  public fetchTemplatePages(id: number): void {
    this.templatePagesSubject.next([]);

    this.httpService.fetchPreviewPages(id).subscribe((pages: TemplatePageModel[]) => {
      this.templatePagesSubject.next(pages);
    });
  }

  public selectOpenedTemplate(): Subscription {
    const value = this.selectedTemplateSubject.value;

    return this.isSetup ? this.selectTemplateOnSetup(value) : this.selectTemplate(value);
  }

  public selectTemplateOnSetup(template: TemplateModel): Subscription {
    this.setupLoaderService.show();

    return this.websiteDesignerService.updateTemplate({ TemplateID: template.id }).subscribe(() => {
      this.router.navigateByUrl('/setup/upload-images');
  
      if (template.id !== 12 && template.id !== 16) return;
  
      this.customHomePageModalService.open();
    });
  }

  public selectTemplate(template: TemplateModel): Subscription {
    this.isWebsitePreviewSubject.next(false);
    this.selectedTemplateSubject.next(null);
    
    this.editorDevicesService.onDeviceChange(DEVICES.DESKTOP);

    if (!this.homePage) {
      return Subscription.EMPTY;
    }

    this.isTemplateBeingUpdated.next(true);
    
    return this.switchToAnotherTemplate(template.id).pipe(
      catchError(e => {
        console.error(e);

        this.isTemplateBeingUpdated.next(false);

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.afterTemplateSwitch(template).pipe(
        catchError(e => {
          console.error(e);

          this.isTemplateBeingUpdated.next(false);

          return throwError(() => e);
        }),
      ).subscribe(() => {
        this.isTemplateBeingUpdated.next(false);
        this.activeTemplateSubject.next(template);
      });
    });
  }

  private switchToAnotherTemplate(templateId: number): Observable<void> {
    return this.websiteDesignerService.updateTemplate({ TemplateID: templateId }).pipe(
      tap(() => {
        this.iFrameService.isLoadedSubject.next(false);
        this.iFrameService.onContentLoad.next(false);

        this.nodesService.fetchNodes().add(() => {
          if (templateId === 10 || templateId === 11) {
            this.navigationService.toHomePage();
    
            return this.homepageImageSelectModalService.open();
          }
    
          if (templateId !== 12 && templateId !== 16) {
            return this.navigationService.toHomePage({ sidebar: null });
          }
    
          this.navigationService.toHomePage({ sidebar: ['pages'] });
    
          this.customHomePageModalService.open();
        });
      }),
    );
  }

  private afterTemplateSwitch(template: TemplateModel): Observable<void> {
    if (template.menuType !== 'BOTH') {
      return of(void 0);
    }
    
    return this.pagesService.rearrangeDoubleMenuTree();
  }
}
