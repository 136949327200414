import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subscriber, Observable, Subscription, BehaviorSubject} from 'rxjs';

import {AdminEducationClassesHttpService} from '../../../interaction/http/admin/education/classes/admin-education-classes-http.service';

import {EducationClassModel} from '../../../../models/education/classes/education-class.model';

@Injectable()
export class AdminEducationClassesService {
  public classesSubject: BehaviorSubject<EducationClassModel[]> = new BehaviorSubject<EducationClassModel[]>(null);
  public classDetailsSubject: BehaviorSubject<EducationClassModel> = new BehaviorSubject<EducationClassModel>(null);

  constructor(private httpService: AdminEducationClassesHttpService) {
  }

  public create(item: EducationClassModel): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      this.httpService.create(item).subscribe(() => {
        this.fetchAll();

        observer.next(null);
        observer.complete();
      }, (res: HttpErrorResponse) => {
        observer.next(res.error ? res.error.message : res.statusText);
        observer.complete();
      });
    });
  }

  public fetchOne(id: number): Subscription {
    return this.httpService.getOne(id).subscribe((res: EducationClassModel) => {
      this.classDetailsSubject.next(res);
    });
  }

  public update(item: EducationClassModel): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      this.httpService.update(item).subscribe(() => {
        this.fetchAll();

        observer.next(null);
        observer.complete();
      }, (res: HttpErrorResponse) => {
        observer.next(res.error ? res.error.message : res.statusText);
        observer.complete();
      });
    });
  }

  public remove(item: EducationClassModel): Subscription {
    return this.httpService.remove(item.id).subscribe(() => {
      this.fetchAll();
    });
  }

  public fetchAll(): Subscription {
    return this.httpService.getAll().subscribe((res: EducationClassModel[]) => {
      this.classesSubject.next(res);
    });
  }
}
