import {ImageLabelPriceModel} from './price/image-label-price.model';

export class ImageLabelModel {
  constructor(public imageId: number,
              public title: string,
              public caption: string,
              public pricingStructure: string,
              public copyright: string,
              public inquiryLinkText: string,
              public purchaseText: string,
              public purchaseInfo: string,
              public isVisible: boolean,
              public isInquireLinkVisible: boolean,
              public isPurchaseButtonVisible: boolean,
              public prices: ImageLabelPriceModel[]) {}

  public static clone(info: ImageLabelModel): ImageLabelModel {
    return new ImageLabelModel(
      info.imageId,
      info.title,
      info.caption,
      info.pricingStructure,
      info.copyright,
      info.inquiryLinkText,
      info.purchaseText,
      info.purchaseInfo,
      info.isVisible,
      info.isInquireLinkVisible,
      info.isPurchaseButtonVisible,
      info.prices.map((price: ImageLabelPriceModel) => ImageLabelPriceModel.clone(price)),
    );
  }
}
