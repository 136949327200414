import {ReceiptModalItemModel} from './receipt-modal-item.model';

export class ReceiptModalModel {
  constructor(
    public items: ReceiptModalItemModel[],
    public addOns: ReceiptModalItemModel[],
    public supportPlanDiscount: ReceiptModalItemModel,
    public discount: ReceiptModalItemModel,
    public subtotalAmount: string,
    public remainingCreditAmount: string,
    public paidAmount: string,
    public creditBalanceAmount: string,
    public isNew: boolean,
  ) {
  }
}
