import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';

import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';
import {FontVariantModel} from '../../../../core/models/font/variant/font-variant.model';

@Component({
  selector: 'app-fonts-manager-specimen',
  templateUrl: './fonts-manager-specimen.component.html',
  styleUrls: ['./fonts-manager-specimen.component.scss'],
})
export class FontsManagerSpecimenComponent implements OnInit, OnDestroy {
  @Input() font: GoogleFontModel;

  @Output() closeHandler: EventEmitter<void> = new EventEmitter<void>();

  public glyph: string = '';

  public variants: FontVariantModel[] = [];

  public customString: string = '';

  public characters: string = '';

  public fontSizeNumberValue: number = 40;
  public fontSizeValue: string = '40';
  public fontSizeString: string = '40px';

  public isOpenedFromAdminPanel: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get fontSize(): string {
    return this.fontSizeValue;
  }

  public set fontSize(value: string) {
    this.fontSizeNumberValue = Number.parseInt(value);
    this.fontSizeValue = value;
    this.fontSizeString = `${value}px`;
  }

  public get buttonStatusText(): string {
    if (this.isOpenedFromAdminPanel) return 'ADD TO DEFAULTS';

    return this.font.isAdded ? 'ADDED' : 'ADD FONT';
  }

  private get firstChar(): string {
    return this.font ? this.font.family[0] : '';
  }

  constructor(private googleFontsService: GoogleFontsService) {
    this.onFontAddedToDefaults = this.onFontAddedToDefaults.bind(this);
    this.onError = this.onError.bind(this);
  }

  public ngOnInit(): void {
    this.googleFontsService.isOpenedFromAdminPanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpenedFromAdminPanel: boolean) => {
      this.isOpenedFromAdminPanel = isOpenedFromAdminPanel;
    });

    this.glyph = `${this.firstChar.toUpperCase()}${this.firstChar.toLowerCase()}`;

    this.variants = this.googleFontsService.getVariantsModels(this.font);

    this.characters = this.getCharacters();
  }

  private getCharacters(): string {
    const alphabetsString: string = this.font.subsets.reduce((res, subset) => {
      return `${res}${this.googleFontsService.getAlphabet(subset)}`;
    }, '');

    const uniqueChars: Array<string> = Array.from(new Set(alphabetsString.split('')));

    return uniqueChars.sort((a: string, b: string) => a.localeCompare(b)).join('');
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onStatusButtonClick(): void {
    if (!this.font || this.font.isDefault) return;

    if (this.isOpenedFromAdminPanel) return this.onAddToDefaultsClick();

    this.googleFontsService.onStatusButtonClick(this.font);
  }

  public onAddToDefaultsClick(): void {
    this.googleFontsService.addDefaultFont(this.font).subscribe(this.onFontAddedToDefaults, this.onError);
  }

  private onFontAddedToDefaults(): void {
    const item = this.googleFontsService.fullListSubject.value.find(font => font.family === this.font.family);

    item.isDefault = true;
    this.font.isDefault = true;

    this.googleFontsService.fetchWebsiteData();
  }

  private onError(e: HttpErrorResponse): void {
    try {
      const err = JSON.parse(e.error);

      console.error(err);
    } catch (e) {
      console.error(e);
    }
  }
}
