import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sliceEnd'
})
export class SliceEndPipe implements PipeTransform {

  transform(value: any, max: number, replace: string): string {
    const newStr = value.substr(0, max);
    return value.length > max ? newStr + '...' : newStr;
  }
}
