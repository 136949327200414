import {Component, Output, Input, EventEmitter, forwardRef, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-vs-checkbox-simple',
  templateUrl: './checkbox-simple.component.html',
  styleUrls: ['./checkbox-simple.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxSimpleComponent),
    multi: true,
  }]
})
export class CheckboxSimpleComponent implements ControlValueAccessor, OnDestroy {
  private isCheckboxChecked: boolean;

  @Output() isCheckedChange = new EventEmitter();
  @Output() changeHandler = new EventEmitter();

  @Input() tooltip: string = '';
  @Input() isDisabled: boolean = false;

  public onChange = (value: boolean) => {};
  public onTouched = () => {};

  @Input()
  public get isChecked() {
    return this.isCheckboxChecked;
  }
  public set isChecked(value) {
    this.isCheckboxChecked = value;

    this.writeValue(this.isCheckboxChecked);

    this.isCheckedChange.emit(this.isCheckboxChecked);
    this.changeHandler.emit(this.isCheckboxChecked);
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  public ngOnDestroy(): void {
    this.cdr.detach();
  }

  public onClick(): void {
    this.isChecked = !this.isCheckboxChecked;
  }

  public writeValue(value: boolean): void {
    if (value === null) return;

    this.isCheckboxChecked = value;

    this.onChange(this.isCheckboxChecked);

    if (this.cdr['destroyed']) return;

    this.cdr.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
