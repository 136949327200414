import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class PrivatePagePasswordModalService {
  public readonly id = 'private-page-password-modal';

  public isOpened = false;

  public onSaveSubject: BehaviorSubject<{ isLocked: boolean, password: string }> = new BehaviorSubject<{ isLocked: boolean, password: string }>(null);
  public onCancelSubject: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);

  public constructor(private modalsService: ModalsService) {
  }

  public open({ isPrivate }: { isPrivate: boolean }): void {
    this.modalsService.open(this.id, { isPrivate });

    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
