import * as $ from "jquery";

import {CONTROL_TYPE} from "./constants";
import {FONT_SIZES} from '../../../../core/models/styles/native-setup/font-size/constants';
import {svgToDoc, getSvgFromSvgDataUri, getSvgDataUriFromSvg, wrapToUrl} from "../../../../shared/util/converting";
import {DetailsService} from '../details.service';
import {RgbColorSchemeModel} from '../../../../core/models/styles/color/rgb.color.scheme.model';
import {HexColorSchemeModel} from '../../../../core/models/styles/color/hex.color.scheme.model';

const DATA_DIVIDER_TEXT = 'data-divider-text';

export class DividerStyles {
  public detailsService: DetailsService;

  backgroundColor: any = {
    title: 'backgroundColor',
    cssName: 'background-color',
    controlType: CONTROL_TYPE.COLOR,
  };

  dividerHeight: any = {
    title: 'dividerHeight',
    cssName: 'border-top-width',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 1,
      max: 150
    }
  };

  dividerImage: any = {
    title: 'divider',
    cssName: 'border-image-source',
    controlType: CONTROL_TYPE.ICON,
  };

  dividerColor: any = {
    title: 'dividerColor',
    cssName: 'border-top-color',
    controlType: CONTROL_TYPE.COLOR,
  };

  textBackgroundColor: any = {
    title: 'textBackgroundColor',
    cssName: 'background-color',
    controlType: CONTROL_TYPE.COLOR,
  };

  textColor: any = {
    title: 'textColor',
    cssName: 'color',
    controlType: CONTROL_TYPE.COLOR,
  };

  textFont: any = {
    title: 'textFont',
    cssName: 'font-family',
    controlType: CONTROL_TYPE.FONT,
  };

  fontWeight: any = {
    title: 'fontWeight',
    cssName: 'font-weight',
    controlType: CONTROL_TYPE.WEIGHT,
  };

  fontSize: any = {
    title: 'fontSize',
    cssName: 'font-size',
    controlType: CONTROL_TYPE.SELECT,
    data: {
      values: FONT_SIZES
    }
  };

  textMarginLeft: any = {
    title: 'textMarginLeft',
    cssName: 'margin-left',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 100,
    }
  };

  textMarginRight: any = {
    title: 'textMarginRight',
    cssName: 'margin-right',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 100,
    }
  };

  letterSpacing: any = {
    title: 'letterSpacing',
    cssName: 'letter-spacing',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 40,
    }
  };

  lineHeight: any = {
    title: 'lineHeight',
    cssName: 'line-height',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 120,
    }
  };

  dotSpacing: any = {
    title: 'dashSpacing', // can be also dotSpacing
    createTitle: (name) => `${name || 'dot'}Spacing`,
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 40,
    }
  };

  wordSpacing: any = {
    title: 'wordSpacing',
    cssName: 'word-spacing',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 40,
    }
  };

  paddingTop: any = {
    title: 'paddingTop',
    cssName: 'padding-top',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 200,
    }
  };

  paddingBottom: any = {
    title: 'paddingBottom',
    cssName: 'padding-bottom',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 200,
    }
  };

  paddingRight: any = {
    title: 'paddingRight',
    cssName: 'padding-right',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 200,
    }
  };

  paddingLeft: any = {
    title: 'paddingLeft',
    cssName: 'padding-left',
    controlType: CONTROL_TYPE.SLIDER,
    data: {
      min: 0,
      max: 200,
    }
  };

  isTextEnabled: boolean = false;

  private element: any;
  private hrElement: any;
  private textElement: any;

  static parse(element: any, detailsService: DetailsService): DividerStyles {
    const styles = new DividerStyles();

    styles.element = $(element);
    styles.hrElement = styles.element.find('hr');
    styles.textElement = styles.element.find(`[${DATA_DIVIDER_TEXT}]`);

    if (styles.textElement.length) {
      styles.isTextEnabled = true;

      styles.textBackgroundColor.value = styles.textElement.css('background-color');
      styles.textColor.value = styles.textElement.css('color');
      styles.textFont.value = detailsService.getFontFamily(element.css('font-family'));

      styles.fontSize.value = parseInt(styles.textElement.css('font-size'), 10);
      styles.letterSpacing.value = parseInt(styles.textElement.css('letter-spacing'), 10);
      styles.wordSpacing.value = parseInt(styles.textElement.css('word-spacing'), 10);

      styles.textMarginLeft.value = parseInt(styles.textElement.css('margin-left'), 10);
      styles.textMarginRight.value = parseInt(styles.textElement.css('margin-right'), 10);

      styles.lineHeight.value = parseInt(styles.textElement.css('line-height'), 10);
    }

    styles.fontWeight.value = styles.element.css('font-weight');

    styles.backgroundColor.value = styles.element.css('background-color');

    styles.paddingTop.value = parseInt(styles.element.css('padding-top'), 10);
    styles.paddingBottom.value = parseInt(styles.element.css('padding-bottom'), 10);
    styles.paddingLeft.value = parseInt(styles.element.css('padding-left'), 10);
    styles.paddingRight.value = parseInt(styles.element.css('padding-right'), 10);

    styles.dividerColor.value = styles.hrElement.css('border-top-color') || styles.hrElement.css('color');
    styles.dividerHeight.value = parseInt(styles.hrElement.css('border-top-width'), 10);

    const imageSource = styles.hrElement.css('border-image-source');
    styles.dividerImage.value = imageSource.replace(/^url\(/, '') // remove `url(` at the beginning of string
                                           .replace(/\)$/, '') // remove `)` at the end of string
                                           .replace(/"/g,''); // remove all `"`

    const itemName = DividerStyles.getItemNameFromSvg(styles.dividerImage.value);
    styles.dotSpacing.title = styles.dotSpacing.createTitle(itemName);
    styles.dotSpacing.value = styles.hrElement.attr('data-dot-spacing') || 0;

    return styles;
  }

  static getItemNameFromSvg(dataUriSvg) {
    return $(svgToDoc(getSvgFromSvgDataUri(dataUriSvg))).find('svg').attr('data-item-name');
  }

  static prepareSvgIcon(encodedImage: string, padding: number, color: string, size: number) {
    const svgDocument = svgToDoc(getSvgFromSvgDataUri(encodedImage));
    const $svg = $(svgDocument.documentElement);
    const halfPadding = Math.round(padding / 2);
    const gs = $svg.find('g');

    gs.attr('width', size + 'px');
    gs.attr('height', size + 'px');

    $svg.attr('width', size + halfPadding * 2);
    $svg.attr('height', size);

    color = DividerStyles.getRgbColor(color);

    $svg.find('g').attr('fill', color);

    const svgCode = $svg.get(0).outerHTML;

    return wrapToUrl(getSvgDataUriFromSvg(svgCode));
  }

  private static getRgbColor(color: string): string {
    if (RgbColorSchemeModel.isRgb(color)) return color;

    const hex = new HexColorSchemeModel(color);

    return RgbColorSchemeModel.fromHex(hex).toString();
  }

  apply() {
    if (this.isTextEnabled) {
      this.textElement.css('background-color', this.textBackgroundColor.value);
      this.textElement.css('color', this.textColor.value);
      this.textElement.css('word-spacing', this.wordSpacing.value);
      this.textElement.css('letter-spacing', this.letterSpacing.value);
      this.textElement.css('font-size', this.fontSize.value);
      this.textElement.css('margin-left', this.textMarginLeft.value);
      this.textElement.css('margin-right', this.textMarginRight.value);
      this.textElement.css('line-height', this.lineHeight.value ? this.lineHeight.value + 'px' : 'unset');
    }

    this.element.css('font-weight', this.fontWeight.value);
    this.element.css('background-color', this.backgroundColor.value);
    this.element.css('padding-top', this.paddingTop.value);
    this.element.css('padding-bottom', this.paddingBottom.value);
    this.element.css('padding-left', this.paddingLeft.value);
    this.element.css('padding-right', this.paddingRight.value);

    this.hrElement.css('border-top-color', this.dividerColor.value);
    this.hrElement.css('border-top-width', this.dividerHeight.value);
    this.hrElement.attr('data-dot-spacing', this.dotSpacing.value);

    if (this.dividerImage.value && this.dividerImage.value.length) {
      if (this.dividerImage.value === 'none') {
        this.hrElement.css('border-image-source', 'none');
        this.hrElement.css('opacity', 0);

        return;
      }

      this.hrElement.css('opacity', 1);

      this.hrElement.css('border-image-source', DividerStyles.prepareSvgIcon(
        this.dividerImage.value,
        Number.parseInt(this.dotSpacing.value),
        this.dividerColor.value,
        Number.parseInt(this.dividerHeight.value),
      ));

      const itemName = DividerStyles.getItemNameFromSvg(this.dividerImage.value);
      this.dotSpacing.title = this.dotSpacing.createTitle(itemName);
    }
  }

  get propertyList() {
    let result = [
      'backgroundColor',
      'dividerHeight',
      'dividerColor',
      'paddingTop',
      'paddingBottom',
      'paddingRight',
      'paddingLeft',
      'dividerImage',
      'dotSpacing',
    ];

    if (this.isTextEnabled) {
      result = result.concat([
        'textBackgroundColor',
        'textColor',
        'textFont',
        'fontWeight',
        'fontSize',
        'lineHeight',
        'wordSpacing',
        'letterSpacing',
        'textMarginLeft',
        'textMarginRight',
      ]);
    }

    return result;
  }
}
