import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {EducationTeachersService} from '../../../../../core/services/education/teachers/education-teachers.service';
import {EducationInstitutionsService} from '../../../../../core/services/education/teachers/institutions/education-institutions.service';
import {EducationClassesService} from '../../../../../core/services/education/teachers/institutions/classes/education-classes.service';
import {EducationImageManagerService} from '../../../../../core/services/education/image-manager/education-image-manager.service';
import {ImageDetailsService} from '../../../../../core/services/image-manager/image-details/image-details.service';
import {EducationWebsiteExhibitionsService} from '../../../../../core/services/education/teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';
import {EducationWebsitesService} from '../../../../../core/services/education/teachers/institutions/classes/websites/education-websites.service';
import {WebsitesService} from '../../../../../core/services/websites/websites.service';
import {AddNodeModalService} from '../../../../../shared/services/modals/add-node/add-node-modal.service';
import {AuthService} from '../../../../../auth/auth.service';
import {PagesService} from '../pages.service';
import {MessageModalService} from '../../../../../services/message-modal.service';
import {IsPublishingService} from '../../../../../services/is-publishing/is-publishing.service';

import {EducationClassModel} from '../../../../../core/models/education/classes/education-class.model';
import {EducationInstitutionModel} from '../../../../../core/models/education/institutions/education-institution.model';
import {EducationTeacherModel} from '../../../../../core/models/education/teachers/education-teacher.model';
import {NestedSelectOption} from '../../../../../core/models/select/nested/nested-select-option.model';
import {PortfolioModel} from '../../../../../core/models/portfolios/portfolio.model';
import {WebsiteModel} from '../../../../../core/models/websites/website.model';
import {EducationExhibitionPortfolioModel} from '../../../../../core/models/education/portfolios/education-exhibition-portfolio.model';
import {NodeModel} from '../../../../../core/models/nodes/node.model';
import {ModalHeader} from '../../../../../common/models/modal/header/header.model';
import {Button} from '../../../../../common/models/button/button.model';

@Component({
  selector: 'app-educator-exhibitions-sidebar',
  templateUrl: './educator-exhibitions-sidebar.component.html',
  styleUrls: ['./educator-exhibitions-sidebar.component.scss'],
})
export class EducatorExhibitionsSidebarComponent implements OnInit, OnDestroy {
  public teacher: EducationTeacherModel;

  public institutions: EducationInstitutionModel[];
  public institutionsOptions: NestedSelectOption[];
  public selectedInstitution: EducationInstitutionModel;

  public classes: EducationClassModel[];
  public classesOptions: NestedSelectOption[];
  public selectedClass: EducationClassModel;

  public websites: WebsiteModel[];
  public websitesOptions: NestedSelectOption[];
  public selectedWebsite: WebsiteModel;

  public exhibitions: PortfolioModel[] = [];

  public selectedExhibition: EducationExhibitionPortfolioModel;

  public isInstitutionsDropdownVisible: boolean = false;
  public isClassesDropdownVisible: boolean = false;
  public isWebsitesDropdownVisible: boolean = false;

  private website: WebsiteModel;

  private publishingData: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean };

  private exhibitionIdToOpen: number;

  private errorModalButtons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onErrorModalClose.bind(this),
    },
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  private addPageModalUnsubscribe: Subject<boolean>;

  private get isPublishing(): boolean {
    if (!this.publishingData || !this.publishingData.isActiveWebsite) {
      return false;
    }

    return this.publishingData.isPublishing;
  }

  constructor(
    private teachersService: EducationTeachersService,
    private educationImageManagerService: EducationImageManagerService,
    private institutionsService: EducationInstitutionsService,
    private classesService: EducationClassesService,
    private educationWebsitesService: EducationWebsitesService,
    private websiteExhibitionsService: EducationWebsiteExhibitionsService,
    private websitesService: WebsitesService,
    private imageDetailsService: ImageDetailsService,
    private isPublishingService: IsPublishingService,
    private addNodeModalService: AddNodeModalService,
    private messageModalService: MessageModalService,
    private pagesService: PagesService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
    this.teachersService.fetchCurrent();
  }

  public ngOnInit(): void {
    this.authService.websiteChangedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.selectedInstitution = null;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;

      if (this.website && this.selectedClass && this.selectedClass.websites && this.selectedClass.websites.length > 0 && this.website.id === this.selectedClass.websites[0].id) {
        return;
      }
      
      this.handleInstitutions();
    });

    this.websiteExhibitionsService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((exhibitions: PortfolioModel[]) => {
      this.exhibitions = exhibitions;

      this.initExhibitions();
    });
    
    this.educationWebsitesService.websiteDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: WebsiteModel) => {
      this.selectedWebsite = item;

      this.initExhibitions();
    });

    this.classesService.classDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationClassModel) => {
      this.selectedClass = item;

      if (!item) return;

      this.updateActiveUserWebsite();

      this.initWebsites();
      
      this.onWebsitesSelect(this.websitesOptions);

      this.initExhibitions();
    });

    this.institutionsService.institutionDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationInstitutionModel) => {
      this.selectedInstitution = item;

      this.initClasses();
      
      this.onClassesSelect(this.classesOptions);

      this.initExhibitions();
    });

    this.teachersService.teacherDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((teacher: EducationTeacherModel) => {
      this.teacher = teacher;

      this.handleInstitutions();

      this.initExhibitions();
    });

    this.educationImageManagerService.selectedExhibitionPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedExhibition: EducationExhibitionPortfolioModel) => {
      if (!selectedExhibition && !this.selectedExhibition) {
        return;
      }

      if (selectedExhibition && this.selectedExhibition && selectedExhibition.id === this.selectedExhibition.id) {
        return;
      }

      this.selectedExhibition = selectedExhibition;

      this.onExhibitionSelect(this.selectedExhibition);
    });

    this.isPublishingService.isPublishingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean }) => {
      this.publishingData = data;
    });
  }

  private handleInstitutions(): void {
    this.initInstitutions();
    
    this.onInstitutionSelect(this.institutionsOptions);
  }

  private initInstitutions(): void {
    this.institutions = this.teacher ? this.teacher.institutions : [];

    this.institutionsOptions = this.institutions.map((item: EducationInstitutionModel) => {
      return new NestedSelectOption(
        item.name,
        `${item.id}`,
      );
    });

    this.isInstitutionsDropdownVisible = this.institutionsOptions.length > 1;

    if (this.institutionsOptions.length === 0) {
      this.clearSelectedInstitution();

      return;
    }

    if (!this.selectedInstitution && (!this.website || this.website.type !== 'Class')) {
      this.institutionsOptions[0].isSelected = true;

      return;
    }

    const institutionId: string = this.website.type === 'Class' ? `${this.website.institutionId}` : `${this.selectedInstitution.id}`;

    const option: NestedSelectOption = this.institutionsOptions.find((item: NestedSelectOption) => {
      return item.value === institutionId;
    });

    (option || this.institutionsOptions[0]).isSelected = true;
  }

  public onInstitutionSelect(options: NestedSelectOption[]): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    const institutions: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedInstitution: EducationInstitutionModel = this.institutions.find((item: EducationInstitutionModel) => {
      return institutions.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!selectedInstitution) {
      return;
    }

    this.institutionsService.fetchOne(selectedInstitution.id);
  }

  private initClasses(): void {
    const allClasses: EducationClassModel[] = this.teacher ? this.teacher.classes : [];

    this.classes = this.selectedInstitution ? allClasses.filter((item: EducationClassModel) => {
      return item.institutionId === this.selectedInstitution.id;
    }) : [];

    this.classesOptions = this.classes.map((item: EducationClassModel) => {
      return new NestedSelectOption(
        `${item.name} "${item.number}"`,
        `${item.id}`,
      );
    });

    this.isClassesDropdownVisible = this.isInstitutionsDropdownVisible || this.classesOptions.length > 1;

    if (this.classesOptions.length === 0) {
      this.clearSelectedClass();

      return;
    }
    
    if (!this.selectedClass && (!this.website || this.website.type !== 'Class')) {
      this.classesOptions[0].isSelected = true;

      return;
    }

    const classId: string = this.website.type === 'Class' ? `${this.website.classId}` : `${this.selectedClass.id}`;

    const option: NestedSelectOption = this.classesOptions.find((item: NestedSelectOption) => {
      return item.value === classId;
    });

    (option || this.classesOptions[0]).isSelected = true;
  }

  public onClassesSelect(options: NestedSelectOption[]): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    if (!this.selectedInstitution) {
      return;
    }
    
    const classes: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedClass: EducationClassModel = this.classes.find((item: EducationClassModel) => {
      return classes.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!selectedClass) {
      return;
    }

    this.classesService.fetchOne(this.selectedInstitution.id, selectedClass.id);
  }

  private updateActiveUserWebsite(): void {
    if (!this.website || !this.selectedClass || !this.selectedClass.websites || this.selectedClass.websites.length === 0) {
      return;
    }

    const newActiveWebsiteId: number = this.selectedClass.websites[0].id;

    if (this.website.id === newActiveWebsiteId) {
      return;
    }

    this.authService.updateActiveWebsite(newActiveWebsiteId, false);
  }

  private initWebsites(): void {
    this.websites = this.selectedClass ? this.selectedClass.websites : [];

    this.websitesOptions = this.websites.map((item: WebsiteModel) => {
      return new NestedSelectOption(
        item.title,
        `${item.id}`,
      );
    });

    this.isWebsitesDropdownVisible = this.websitesOptions.length > 1;

    if (this.websitesOptions.length === 0) {
      this.clearSelectedWebsite();

      return;
    }
    
    if (!this.selectedWebsite) {
      this.websitesOptions[0].isSelected = true;

      return;
    }

    const websiteId: string = `${this.selectedWebsite.id}`;

    const option: NestedSelectOption = this.websitesOptions.find((item: NestedSelectOption) => {
      return item.value === websiteId;
    });

    (option || this.websitesOptions[0]).isSelected = true;
  }

  public onWebsitesSelect(options: NestedSelectOption[]): void {
    const websites: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedWebsite: WebsiteModel = this.websites.find((item: WebsiteModel) => {
      return websites.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!this.selectedInstitution || !this.selectedClass || !selectedWebsite || (this.selectedWebsite && this.selectedWebsite.id === selectedWebsite.id)) {
      return; 
    }

    this.websiteExhibitionsService.listSubject.next([]);

    this.educationWebsitesService.fetchOne(this.selectedInstitution.id, this.selectedClass.id, selectedWebsite.id);
    
    this.websiteExhibitionsService.fetchList(this.selectedInstitution.id, this.selectedClass.id, selectedWebsite.id);
  }

  private initExhibitions(): void {
    if (!this.selectedClass) {
      return;
    }

    if (!this.exhibitions || this.exhibitions.length === 0) {
      this.educationImageManagerService.selectedExhibitionPortfolioSubject.next(null);

      return;
    }
    
    if (this.selectedExhibition && !this.exhibitionIdToOpen && this.selectedExhibition.classId === this.selectedClass.id) {
      return;
    }

    const exhibition: PortfolioModel = this.exhibitionIdToOpen ? this.exhibitions.find((exhibition: PortfolioModel) => exhibition.id === this.exhibitionIdToOpen) : null;

    this.exhibitionIdToOpen = null;

    this.onExhibitionSelect(exhibition || this.exhibitions[0]);
  }

  public onExhibitionSelect(exhibition: PortfolioModel): void {
    this.imageDetailsService.imageDetailsSubject.next(null);

    if (!this.selectedInstitution || !this.selectedClass || !this.selectedWebsite) {
      return;
    }

    if (!exhibition) {
      this.educationImageManagerService.selectedExhibitionPortfolioSubject.next(null);
 
      return;
    }

    this.educationImageManagerService.selectedExhibitionPortfolioSubject.next(new EducationExhibitionPortfolioModel(
      exhibition.id,
      this.selectedInstitution.id,
      this.selectedClass.id,
      this.selectedWebsite.id,
      exhibition.nodeId,
    ));
  }

  public onExhibitionUpdate(exhibition: PortfolioModel): Subscription {
    if (!this.selectedInstitution || !this.selectedClass || !this.selectedWebsite || !exhibition) {
      return Subscription.EMPTY;
    }

    return this.pagesService.validatePageName(exhibition.title).pipe(
      catchError(e => {
        this.onUpdateError(exhibition, e);

        return throwError(() => e);
      }),
    ).subscribe(() => {
      this.websiteExhibitionsService.updateOne(
        this.selectedInstitution.id,
        this.selectedClass.id,
        this.selectedWebsite.id,
        exhibition.id,
        exhibition,
      ).pipe(
        catchError(e => {
          this.onUpdateError(exhibition, e && e.error ? e.error.key : null, true);

          return throwError(() => e);
        })
      ).subscribe(() => {});
    });
  }

  public onUpdateError(exhibition: PortfolioModel, key: string, isUpdate: boolean = false): void {
    const header: ModalHeader = {
      text: this.pagesService.validationHeaders[key] || 'Something went wrong',
      className: 'error-header',
    };
    
    const message: string = this.pagesService.validationMessages[key] || key || 'Something went wrong';

    exhibition.title = exhibition.initialTitle;
    
    this.cdr.detectChanges();

    this.websiteExhibitionsService.errorSubject.next(message);
    
    if (isUpdate) {
      return this.messageModalService.addMessage(message, header, this.errorModalButtons);
    }
  }

  private onErrorModalClose(): void {
    this.messageModalService.close();
  }

  public onExhibitionDelete(exhibition: PortfolioModel): void {
    if (!this.selectedInstitution || !this.selectedClass || !this.selectedWebsite || !exhibition) {
      return;
    }

    this.websiteExhibitionsService.deleteOne(
      this.selectedInstitution.id,
      this.selectedClass.id,
      this.selectedWebsite.id,
      exhibition.id,
    ).add(() => {
      if (!this.selectedInstitution || !this.selectedClass || !this.selectedWebsite) {
        return;
      }

      this.exhibitions = this.exhibitions.filter((item: PortfolioModel) => {
        return item.id !== exhibition.id;
      });

      this.websiteExhibitionsService.listSubject.next(this.exhibitions);

      if (this.selectedExhibition && this.selectedExhibition.id === exhibition.id) {
        this.selectedExhibition = null;
      }

      this.initExhibitions();
    });
  }

  public openAddPageModal(): void {
    if (this.addPageModalUnsubscribe) {
      this.addPageModalUnsubscribe.next(true);
      this.addPageModalUnsubscribe.complete();
    }

    this.addPageModalUnsubscribe = new Subject<boolean>();

    this.pagesService.parentNodeSubject.next(null);
    
    this.addNodeModalService.open();

    this.websiteExhibitionsService.submitPageCreateSubject.pipe(takeUntil(this.addPageModalUnsubscribe)).subscribe((node: NodeModel) => {
      if (!node) {
        return;
      }
    
      this.websiteExhibitionsService.create(
        this.selectedInstitution.id,
        this.selectedClass.id,
        this.selectedWebsite.id,
        node,
      ).pipe(
        catchError(e => {
          console.error(e);
          
          this.onUpdateError(node, e.error.key);

          return throwError(() => e);
        })
      ).subscribe((node: NodeModel) => {
        this.addPageModalUnsubscribe.next(true);
        this.addPageModalUnsubscribe.complete();

        this.addNodeModalService.close();

        this.exhibitionIdToOpen = node ? node.id : null;

        this.websiteExhibitionsService.fetchList(this.selectedInstitution.id, this.selectedClass.id, this.selectedWebsite.id);
      });
    });
  }

  private clearSelectedInstitution(): void {
    this.selectedInstitution = null;

    this.clearSelectedClass();

    this.institutionsService.institutionDetailsSubject.next(null);
  }

  private clearSelectedClass(): void {
    this.selectedClass = null;

    this.clearSelectedWebsite();

    this.classesService.classDetailsSubject.next(null);
  }

  private clearSelectedWebsite(): void {
    this.selectedWebsite = null;
    
    this.educationWebsitesService.websiteDetailsSubject.next(null);
    this.imageDetailsService.imageDetailsSubject.next(null);
    this.educationImageManagerService.selectedExhibitionPortfolioSubject.next(null);
    this.websiteExhibitionsService.listSubject.next([]);
  }

  public ngOnDestroy(): void {
    if (this.addPageModalUnsubscribe) {
      this.addPageModalUnsubscribe.next(true);
      this.addPageModalUnsubscribe.complete();
    }

    this.institutionsService.institutionDetailsSubject.next(null);
    
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
