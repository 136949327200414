import {Component, OnInit, OnDestroy, Input} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {NavigationService} from '../../../services/navigation.service';

import {IPermissionData} from '../../../core/models/permission/i-permission-data';

@Component({
  selector: 'app-permissions-required',
  templateUrl: './permissions-required.component.html',
  styleUrls: ['./permissions-required.component.scss'],
})
export class PermissionsRequiredComponent implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() permissions: IPermissionData[] = [];

  public isUserHasPermissions: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private permissionsService: PermissionsService,
    private navigationService: NavigationService,
  ) {
  }

  public ngOnInit(): void {
    this.permissionsService.isUserHasPermissionsObservable(this.permissions).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isUserHasPermissions = isAllowed;
    });
  }

  public toPurchasePage(): void {
    this.permissionsService.beforeLeaveSubject.next(true);
    
    this.navigationService.toPurchase();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
