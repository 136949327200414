export const KEYS = {
  IS_RANDOMIZABLE: 'IS_RANDOMIZABLE',
  IS_ITEMS_MARGIN_CUSTOMIZABLE: 'IS_ITEMS_MARGIN_CUSTOMIZABLE',
  IS_CAPTION_ENABLED: 'IS_CAPTION_ENABLED',
  IS_BLOCK_CENTERED: 'IS_BLOCK_CENTERED',
  IS_FULLSCREEN_ICON_SHOWN: 'IS_FULLSCREEN_ICON_SHOWN',
  IS_CLICK_TO_ENLARGE: 'IS_CLICK_TO_ENLARGE',
  IS_SLIDESHOW_ENABLED: 'IS_SLIDESHOW_ENABLED',
  IS_SLIDESHOW_AUTOPLAY: 'IS_SLIDESHOW_AUTOPLAY',
  IS_FOOTER_VISIBLE_ON_LOAD: 'IS_FOOTER_VISIBLE_ON_LOAD',
  IS_PORTFOLIO_TITLE_VISIBLE: 'IS_PORTFOLIO_TITLE_VISIBLE',
  IS_THUMB_DRAWER_AUTOSCROLL_ENABLED: 'IS_THUMB_DRAWER_AUTOSCROLL_ENABLED',
  IS_THUMB_DRAWER_ARROWS_VISIBLE: 'IS_THUMB_DRAWER_ARROWS_VISIBLE',
  IS_THUMB_DRAWER_SCROLLBAR_ENABLED: 'IS_THUMB_DRAWER_SCROLLBAR_ENABLED',
  IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE: 'IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE',
  IS_SCALABLE_TEXT: 'IS_SCALABLE_TEXT',
  IS_EXHIBITION_TITLE_VISIBLE: 'IS_EXHIBITION_TITLE_VISIBLE',
  IS_SLIDE_TRACKER_VISIBLE: 'IS_SLIDE_TRACKER_VISIBLE',
  IS_ARROWS_VISIBLE: 'IS_ARROWS_VISIBLE',
  IS_SLIDE_TEXT_ADDED: 'IS_SLIDE_TEXT_ADDED',
  IS_SLIDE_BUTTON_ADDED: 'IS_SLIDE_BUTTON_ADDED',
  IS_SLIDESHOW_AUTOPLAY_ENABLED: 'IS_SLIDESHOW_AUTOPLAY_ENABLED',
  INITIAL_OVERLAY_CLICK_BEHAVIOR: 'INITIAL_OVERLAY_CLICK_BEHAVIOR',
  SLIDESHOW_TEXT_BACKGROUND_COLOR: 'SLIDESHOW_TEXT_BACKGROUND_COLOR',
  SLIDESHOW_TEXT_BACKGROUND_TRANSPARENCY: 'SLIDESHOW_TEXT_BACKGROUND_TRANSPARENCY',
  SELECT_LAYER: 'SELECT_LAYER',
  BLOCK_ALIGNMENT: 'BLOCK_ALIGNMENT',
  IMAGE_ALIGNMENT: 'IMAGE_ALIGNMENT',
  SLIDESHOW_HORIZONTAL_TEXT_ALIGNMENT: 'SLIDESHOW_HORIZONTAL_TEXT_ALIGNMENT',
  SLIDESHOW_VERTICAL_TEXT_ALIGNMENT: 'SLIDESHOW_VERTICAL_TEXT_ALIGNMENT',
  IMAGE_CAPTION_POSITION: 'IMAGE_CAPTION_POSITION',
  IMAGE_FIT_INTO: 'IMAGE_FIT_INTO',
  IMAGE_ENLARGEMENT: 'IMAGE_ENLARGEMENT',
  PORTFOLIO_ID: 'PORTFOLIO_ID',
  ANIMATION_DELAY: 'ANIMATION_DELAY',
  LOADER_TYPE: 'LOADER_TYPE',
  CUSTOMIZABLE_LAYERS: 'CUSTOMIZABLE_LAYERS',
  IMAGE_LABEL_TOGGLE: 'IMAGE_LABEL_TOGGLE',
  IMAGE_LABEL_BUTTON: 'IMAGE_LABEL_BUTTON',
  IMAGE_LABEL_STYLES: 'IMAGE_LABEL_STYLES',
  IMAGE_TRANSITION: 'IMAGE_TRANSITION',
  IMAGE_TRANSITION_DURATION: 'IMAGE_TRANSITION_DURATION',
  HREF: 'HREF',
  POSITION: 'POSITION',
  IMAGE_CROP: 'IMAGE_CROP',
  LINK_COLOR: 'LINK_COLOR',
  BUTTON_TEXT_COLOR: 'BUTTON_TEXT_COLOR',
  BUTTON_BACKGROUND_COLOR: 'BUTTON_BACKGROUND_COLOR',
  PORTFOLIO_ARROWS_BORDER_RADIUS: 'PORTFOLIO_ARROWS_BORDER_RADIUS',
  PORTFOLIO_ARROWS_COLOR: 'PORTFOLIO_ARROWS_COLOR',
  PORTFOLIO_ARROWS_SIZE: 'PORTFOLIO_ARROWS_SIZE',
  PORTFOLIO_ARROWS_BACKGROUND_COLOR: 'PORTFOLIO_ARROWS_BACKGROUND_COLOR',
  PORTFOLIO_ARROWS_TRANSPARENCY: 'PORTFOLIO_ARROWS_TRANSPARENCY',
  PORTFOLIO_ENLARGEMENT_ARROWS_BORDER_RADIUS: 'PORTFOLIO_ENLARGEMENT_ARROWS_BORDER_RADIUS',
  PORTFOLIO_ENLARGEMENT_ARROWS_COLOR: 'PORTFOLIO_ENLARGEMENT_ARROWS_COLOR',
  PORTFOLIO_ENLARGEMENT_ARROWS_SIZE: 'PORTFOLIO_ENLARGEMENT_ARROWS_SIZE',
  PORTFOLIO_ENLARGEMENT_ARROWS_BACKGROUND_COLOR: 'PORTFOLIO_ENLARGEMENT_ARROWS_BACKGROUND_COLOR',
  PORTFOLIO_ENLARGEMENT_ARROWS_TRANSPARENCY: 'PORTFOLIO_ENLARGEMENT_ARROWS_TRANSPARENCY',
  PORTFOLIO_IMAGE_TITLE_POSITION: 'PORTFOLIO_IMAGE_TITLE_POSITION',
  PORTFOLIO_IMAGE_TITLE_COLOR: 'PORTFOLIO_IMAGE_TITLE_COLOR',
  PORTFOLIO_IMAGE_TITLE_FONT_FAMILY: 'PORTFOLIO_IMAGE_TITLE_FONT_FAMILY',
  PORTFOLIO_IMAGE_TITLE_FONT_SIZE: 'PORTFOLIO_IMAGE_TITLE_FONT_SIZE',
  PORTFOLIO_IMAGE_TITLE_TEXT_TRANSFORM: 'PORTFOLIO_IMAGE_TITLE_TEXT_TRANSFORM',
  PORTFOLIO_IMAGE_TITLE_FONT_WEIGHT: 'PORTFOLIO_IMAGE_TITLE_FONT_WEIGHT',
  PORTFOLIO_IMAGE_TITLE_TEXT_DECORATION: 'PORTFOLIO_IMAGE_TITLE_TEXT_DECORATION',
  PORTFOLIO_IMAGE_TITLE_LINE_HEIGHT: 'PORTFOLIO_IMAGE_TITLE_LINE_HEIGHT',
  PORTFOLIO_IMAGE_TITLE_LETTER_SPACING: 'PORTFOLIO_IMAGE_TITLE_LETTER_SPACING',
  PORTFOLIO_IMAGE_TITLE_WORD_SPACING: 'PORTFOLIO_IMAGE_TITLE_WORD_SPACING',
  PORTFOLIO_ENLARGEMENT_BACKGROUND_COLOR: 'PORTFOLIO_ENLARGEMENT_BACKGROUND_COLOR',
  PORTFOLIO_ENLARGEMENT_BACKGROUND_TRANSPARENCY: 'PORTFOLIO_ENLARGEMENT_BACKGROUND_TRANSPARENCY',
  PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION: 'PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION',
  PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION_DURATION: 'PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION_DURATION',
  PORTFOLIO_ENLARGEMENT_IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE: 'PORTFOLIO_ENLARGEMENT_IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE',
  PORTFOLIO_ENLARGEMENT_TITLE_POSITION: 'PORTFOLIO_ENLARGEMENT_TITLE_POSITION',
  PORTFOLIO_ENLARGEMENT_TITLE_COLOR: 'PORTFOLIO_ENLARGEMENT_TITLE_COLOR',
  PORTFOLIO_ENLARGEMENT_TITLE_FONT_FAMILY: 'PORTFOLIO_ENLARGEMENT_TITLE_FONT_FAMILY',
  PORTFOLIO_ENLARGEMENT_TITLE_FONT_WEIGHT: 'PORTFOLIO_ENLARGEMENT_TITLE_FONT_WEIGHT',
  PORTFOLIO_ENLARGEMENT_TITLE_FONT_SIZE: 'PORTFOLIO_ENLARGEMENT_TITLE_FONT_SIZE',
  PORTFOLIO_ENLARGEMENT_TITLE_TEXT_TRANSFORM: 'PORTFOLIO_ENLARGEMENT_TITLE_TEXT_TRANSFORM',
  PORTFOLIO_ENLARGEMENT_TITLE_TEXT_DECORATION: 'PORTFOLIO_ENLARGEMENT_TITLE_TEXT_DECORATION',
  PORTFOLIO_ENLARGEMENT_TITLE_LINE_HEIGHT: 'PORTFOLIO_ENLARGEMENT_TITLE_LINE_HEIGHT',
  PORTFOLIO_ENLARGEMENT_TITLE_LETTER_SPACING: 'PORTFOLIO_ENLARGEMENT_TITLE_LETTER_SPACING',
  PORTFOLIO_ENLARGEMENT_TITLE_WORD_SPACING: 'PORTFOLIO_ENLARGEMENT_TITLE_WORD_SPACING',
  ZOOM_MODE_BACKGROUND: 'ZOOM_MODE_BACKGROUND',
  PORTFOLIO_ZOOM_ICON_COLOR: 'PORTFOLIO_ZOOM_ICON_COLOR',
  ENLARGE_ICON_COLOR: 'ENLARGE_ICON_COLOR',
  ENLARGE_ICON_HOVER_COLOR: 'ENLARGE_ICON_HOVER_COLOR',
  AUTOPLAY_SLIDE_DURATION: 'AUTOPLAY_SLIDE_DURATION',
  PORTFOLIO_SLIDESHOW_SLIDE_DURATION: 'PORTFOLIO_SLIDESHOW_SLIDE_DURATION',
  SLIDESHOW_SLIDE_DURATION: 'SLIDESHOW_SLIDE_DURATION',
  SLIDE_ARROWS_COLOR: 'SLIDE_ARROWS_COLOR',
  SLIDE_ARROWS_SIZE: 'SLIDE_ARROWS_SIZE',
  SLIDE_ARROWS_BORDER_RADIUS: 'SLIDE_ARROWS_BORDER_RADIUS',
  PORTFOLIO_INTRO_BACKGROUND_COLOR: 'PORTFOLIO_INTRO_BACKGROUND_COLOR',
  PORTFOLIO_INTRO_BACKGROUND_TRANSPARENCY: 'PORTFOLIO_INTRO_BACKGROUND_TRANSPARENCY',
  ELEMENT_BACKGROUND_COLOR: 'ELEMENT_BACKGROUND_COLOR',
  ELEMENT_BACKGROUND_TRANSPARENCY: 'ELEMENT_BACKGROUND_TRANSPARENCY',
  PORTFOLIO_INFO_ICON_BACKGROUND: 'PORTFOLIO_INFO_ICON_BACKGROUND',
  PORTFOLIO_INFO_ICON_COLOR: 'PORTFOLIO_INFO_ICON_COLOR',
  PORTFOLIO_INFO_ICON_BORDER_COLOR: 'PORTFOLIO_INFO_ICON_BORDER_COLOR',
  THUMB_DRAWER_ICON_SIZE: 'THUMB_DRAWER_ICON_SIZE',
  THUMB_DRAWER_FONT_FAMILY: 'THUMB_DRAWER_FONT_FAMILY',
  THUMB_DRAWER_FONT_SIZE: 'THUMB_DRAWER_FONT_SIZE',
  THUMB_DRAWER_FONT_WEIGHT: 'THUMB_DRAWER_FONT_WEIGHT',
  THUMB_DRAWER_TEXT_TRANSFORM: 'THUMB_DRAWER_TEXT_TRANSFORM',
  THUMB_DRAWER_LETTER_SPACING: 'THUMB_DRAWER_LETTER_SPACING',
  THUMB_DRAWER_WORD_SPACING: 'THUMB_DRAWER_WORD_SPACING',
  THUMB_DRAWER_ICON_BACKGROUND: 'THUMB_DRAWER_ICON_BACKGROUND',
  THUMB_DRAWER_ICON_COLOR: 'THUMB_DRAWER_ICON_COLOR',
  THUMB_DRAWER_ICON_BORDER_COLOR: 'THUMB_DRAWER_ICON_BORDER_COLOR',
  THUMB_DRAWER_BACKGROUND_COLOR: 'THUMB_DRAWER_BACKGROUND_COLOR',
  THUMB_DRAWER_BACKGROUND_TRANSPARENCY: 'THUMB_DRAWER_BACKGROUND_TRANSPARENCY',
  THUMB_DRAWER_THUMBS_POSITION: 'THUMB_DRAWER_THUMBS_POSITION',
  THUMB_DRAWER_TEXT_COLOR: 'THUMB_DRAWER_TEXT_COLOR',
  THUMB_DRAWER_BORDER_RADIUS: 'THUMB_DRAWER_BORDER_RADIUS',
  PORTFOLIO_SLIDE_THUMB_BACKGROUND_COLOR: 'PORTFOLIO_SLIDE_THUMB_BACKGROUND_COLOR',
  PORTFOLIO_SLIDE_THUMB_COLOR: 'PORTFOLIO_SLIDE_THUMB_COLOR',
  PORTFOLIO_SLIDE_THUMB_FONT_FAMILY: 'PORTFOLIO_SLIDE_THUMB_FONT_FAMILY',
  PORTFOLIO_SLIDE_THUMB_FONT_SIZE: 'PORTFOLIO_SLIDE_THUMB_FONT_SIZE',
  PORTFOLIO_SLIDE_THUMB_FONT_WEIGHT: 'PORTFOLIO_SLIDE_THUMB_FONT_WEIGHT',
  PORTFOLIO_SLIDE_THUMB_LINE_HEIGHT: 'PORTFOLIO_SLIDE_THUMB_LINE_HEIGHT',
  PORTFOLIO_SLIDE_THUMB_LETTER_SPACING: 'PORTFOLIO_SLIDE_THUMB_LETTER_SPACING',
  OVERLAY_BACKGROUND_COLOR: 'OVERLAY_BACKGROUND_COLOR',
  OVERLAY_BACKGROUND_TRANSPARENCY: 'OVERLAY_BACKGROUND_TRANSPARENCY',
  SLIDESHOW_IMAGE_OVERLAY_BACKGROUND_COLOR: 'SLIDESHOW_IMAGE_OVERLAY_BACKGROUND_COLOR',
  SLIDESHOW_IMAGE_OVERLAY_BACKGROUND_TRANSPARENCY: 'SLIDESHOW_IMAGE_OVERLAY_BACKGROUND_TRANSPARENCY',
  IMAGE_HOVER_OVERLAY_TEXT_COLOR: 'IMAGE_HOVER_OVERLAY_TEXT_COLOR',
  IMAGE_HOVER_OVERLAY_BACKGROUND_COLOR: 'IMAGE_HOVER_OVERLAY_BACKGROUND_COLOR',
  IMAGE_HOVER_OVERLAY_BACKGROUND_TRANSPARENCY: 'IMAGE_HOVER_OVERLAY_BACKGROUND_TRANSPARENCY',
  THUMB_HOVER_OVERLAY_BACKGROUND: 'THUMB_HOVER_OVERLAY_BACKGROUND',
  THUMB_HOVER_OVERLAY_BACKGROUND_TRANSPARENCY: 'THUMB_HOVER_OVERLAY_BACKGROUND_TRANSPARENCY',
  IMAGE_TRANSPARENCY: 'IMAGE_TRANSPARENCY',
  SPACE_BETWEEN_COLUMNS: 'SPACE_BETWEEN_COLUMNS',
  MASONRY_TYPE: 'MASONRY_TYPE',
  GRID_GAP: 'GRID_GAP',
  NUMBER_OF_COLUMNS: 'NUMBER_OF_COLUMNS',
  TITLE_SPACING: 'TITLE_SPACING',
  PORTFOLIO_LINK: 'PORTFOLIO_LINK',
  SLIDE_ARROWS_BACKGROUND_COLOR: 'SLIDE_ARROWS_BACKGROUND_COLOR',
  SLIDE_ARROWS_TRANSPARENCY: 'SLIDE_ARROWS_TRANSPARENCY',
  BLOCK_WIDTH_TYPE: 'BLOCK_WIDTH_TYPE',
  VIDEO_URL: 'VIDEO_URL',
  VIDEO_POSTER: 'VIDEO_POSTER',
  VIDEO_CONTROLS: 'VIDEO_CONTROLS',
  VIDEO_PRELOAD: 'VIDEO_PRELOAD',
  VIDEO_AUTOPLAY: 'VIDEO_AUTOPLAY',
  VIDEO_LOOP: 'VIDEO_LOOP',
  VIDEO_MUTED: 'VIDEO_MUTED',
  VIDEO_DOWNLOAD: 'VIDEO_DOWNLOAD',
  VIDEO_THUMBNAIL: 'VIDEO_THUMBNAIL',
  VIDEO_EMBED: 'VIDEO_EMBED',
  VIDEO_EMBED_CONTROLS: 'VIDEO_EMBED_CONTROLS',
  VIDEO_EMBED_AUTOPLAY: 'VIDEO_EMBED_AUTOPLAY',
  VIDEO_EMBED_LOOP: 'VIDEO_EMBED_LOOP',
  VIDEO_EMBED_MUTED: 'VIDEO_EMBED_MUTED',
  AUDIO_URL: 'AUDIO_URL',
  AUDIO_AUTOPLAY: 'AUDIO_AUTOPLAY',
  AUDIO_LOOP: 'AUDIO_LOOP',
  AUDIO_MUTED: 'AUDIO_MUTED',
  DESKTOP_FONT_SIZE: 'DESKTOP_FONT_SIZE',
  DESKTOP_LETTER_SPACING: 'DESKTOP_LETTER_SPACING',
  DESKTOP_LINE_HEIGHT: 'DESKTOP_LINE_HEIGHT',
  MOBILE_FONT_SIZE: 'MOBILE_FONT_SIZE',
  MOBILE_LETTER_SPACING: 'MOBILE_LETTER_SPACING',
  MOBILE_LINE_HEIGHT: 'MOBILE_LINE_HEIGHT',
  ENLARGEMENT_PADDING: 'ENLARGEMENT_PADDING',
  PADDING_VW: 'PADDING_VW',
  HAMBURGER_COLOR: 'HAMBURGER_COLOR',
  MOBILE_ALBUM_VIEW_SETUP: 'MOBILE_ALBUM_VIEW_SETUP',
  SCALABLE_FONT_SIZE: 'SCALABLE_FONT_SIZE',
  SCALABLE_LINE_HEIGHT: 'SCALABLE_LINE_HEIGHT',
  SCALABLE_LETTER_SPACING: 'SCALABLE_LETTER_SPACING',
  SCALABLE_WORD_SPACING: 'SCALABLE_WORD_SPACING',
  SLIDESHOW_IMAGE_TRANSITION_DURATION: 'SLIDESHOW_IMAGE_TRANSITION_DURATION',

  SCALABLE_SLIDESHOW_TITLE_FONT_SIZE: 'SCALABLE_SLIDESHOW_TITLE_FONT_SIZE',
  SCALABLE_SLIDESHOW_TITLE_LINE_HEIGHT: 'SCALABLE_SLIDESHOW_TITLE_LINE_HEIGHT',
  SCALABLE_SLIDESHOW_TITLE_LETTER_SPACING: 'SCALABLE_SLIDESHOW_TITLE_LETTER_SPACING',
  SCALABLE_SLIDESHOW_TITLE_WORD_SPACING: 'SCALABLE_SLIDESHOW_TITLE_WORD_SPACING',

  SCALABLE_SLIDESHOW_PARAGRAPH_FONT_SIZE: 'SCALABLE_SLIDESHOW_PARAGRAPH_FONT_SIZE',
  SCALABLE_SLIDESHOW_PARAGRAPH_LINE_HEIGHT: 'SCALABLE_SLIDESHOW_PARAGRAPH_LINE_HEIGHT',
  SCALABLE_SLIDESHOW_PARAGRAPH_LETTER_SPACING: 'SCALABLE_SLIDESHOW_PARAGRAPH_LETTER_SPACING',
  SCALABLE_SLIDESHOW_PARAGRAPH_WORD_SPACING: 'SCALABLE_SLIDESHOW_PARAGRAPH_WORD_SPACING',

  SCALABLE_SLIDESHOW_BUTTON_FONT_SIZE: 'SCALABLE_SLIDESHOW_BUTTON_FONT_SIZE',
  SCALABLE_SLIDESHOW_BUTTON_LINE_HEIGHT: 'SCALABLE_SLIDESHOW_BUTTON_LINE_HEIGHT',
  SCALABLE_SLIDESHOW_BUTTON_LETTER_SPACING: 'SCALABLE_SLIDESHOW_BUTTON_LETTER_SPACING',
  SCALABLE_SLIDESHOW_BUTTON_WORD_SPACING: 'SCALABLE_SLIDESHOW_BUTTON_WORD_SPACING',

  SLIDE_IMAGE_SETUP: 'SLIDE_IMAGE_SETUP',
  SLIDESHOW_BORDER_WIDTH: 'SLIDESHOW_BORDER_WIDTH',
  SLIDESHOW_BORDER_COLOR: 'SLIDESHOW_BORDER_COLOR',
  SLIDESHOW_BORDER_POSITION: 'SLIDESHOW_BORDER_POSITION',
  SLIDESHOW_BORDER_STYLE: 'SLIDESHOW_BORDER_STYLE',
  SLIDESHOW_BORDER_RADIUS: 'SLIDESHOW_BORDER_RADIUS',
  SLIDESHOW_TRANSITION: 'SLIDESHOW_TRANSITION',
  BUTTON_BG_COLOR: 'BUTTON_BG_COLOR',
  BUTTON_HOVER_COLOR: 'BUTTON_HOVER_COLOR',

  SLIDESHOW_TITLE_FONT_FAMILY: 'SLIDESHOW_TITLE_FONT_FAMILY',
  SLIDESHOW_TITLE_COLOR: 'SLIDESHOW_TITLE_COLOR',
  SLIDESHOW_TITLE_FONT_WEIGHT: 'SLIDESHOW_TITLE_FONT_WEIGHT',
  SLIDESHOW_TITLE_TEXT_TRANSFORM: 'SLIDESHOW_TITLE_TEXT_TRANSFORM',
  SLIDESHOW_TITLE_TEXT_ALIGNMENT: 'SLIDESHOW_TITLE_TEXT_ALIGNMENT',
  SLIDESHOW_PARAGRAPH_FONT_FAMILY: 'SLIDESHOW_PARAGRAPH_FONT_FAMILY',
  SLIDESHOW_PARAGRAPH_COLOR: 'SLIDESHOW_PARAGRAPH_COLOR',
  SLIDESHOW_PARAGRAPH_FONT_WEIGHT: 'SLIDESHOW_PARAGRAPH_FONT_WEIGHT',
  SLIDESHOW_PARAGRAPH_TEXT_TRANSFORM: 'SLIDESHOW_PARAGRAPH_TEXT_TRANSFORM',
  SLIDESHOW_PARAGRAPH_TEXT_ALIGNMENT: 'SLIDESHOW_PARAGRAPH_TEXT_ALIGNMENT',
  SLIDESHOW_BUTTON_FONT_FAMILY: 'SLIDESHOW_BUTTON_FONT_FAMILY',
  SLIDESHOW_BUTTON_COLOR: 'SLIDESHOW_BUTTON_COLOR',
  SLIDESHOW_BUTTON_FONT_WEIGHT: 'SLIDESHOW_BUTTON_FONT_WEIGHT',
  SLIDESHOW_BUTTON_TEXT_TRANSFORM: 'SLIDESHOW_BUTTON_TEXT_TRANSFORM',
  IS_SLIDESHOW_VIDEO_MUTED: 'IS_SLIDESHOW_VIDEO_MUTED',
};

export const TYPES = {
  ATTRIBUTE: 'ATTRIBUTE',
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
  SRC_QUERY_PARAM: 'SRC_QUERY_PARAM',
};

export const ATTRS = {
  [KEYS.IS_RANDOMIZABLE]: 'data-is-randomizable',
  [KEYS.IS_ITEMS_MARGIN_CUSTOMIZABLE]: 'data-is-items-margin-customizable',
  [KEYS.PORTFOLIO_ID]: 'data-portfolio-id',
};

export const VALUE_ATTRS = {
  [KEYS.IS_RANDOMIZABLE]: 'data-is-randomizable-value',
  [KEYS.IS_ITEMS_MARGIN_CUSTOMIZABLE]: 'data-items-margin-customizable-value',
  [KEYS.PORTFOLIO_ID]: ATTRS[KEYS.PORTFOLIO_ID],
};
