import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tooltip-box',
  templateUrl: './tooltip-box.component.html',
  styleUrls: ['./tooltip-box.component.scss']
})
export class TooltipBoxComponent {
  @Input() text: string;
  @Input() active: boolean = true;
  @Input() triangleX: number;
  @Input() textX: number;
  @Input() width: number;
  @Input() isLeft = false;

  // TODO: finish
  @Input()
  maxWidth: number;

  constructor() {}
}
