import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/title/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/title/mobile/constants';

export const PROPERTY_NAMES = {
  [DESKTOP_KEYS.SCALABLE_LINE_HEIGHT]: {
    OPTION: '--scalable-subtitle-line-height',
    OPTION_PX: '--scalable-subtitle-line-height-px',
  },
  [MOBILE_KEYS.SCALABLE_MOBILE_LINE_HEIGHT]: {
    OPTION: '--scalable-mobile-subtitle-line-height',
    OPTION_PX: '--scalable-mobile-subtitle-line-height-px',
  },
};

export const SCROLLED_PROPERTY_NAME = '--scalable-mobile-subtitle-scrolled-line-height';
export const SCROLLED_PROPERTY_NAME_PX = '--scalable-mobile-subtitle-scrolled-line-height-px';
export const MIN_SCROLLABLE_VALUE = 15;
