export const KEYS = {
  ADD_PAGE: 'ADD_PAGE',
  UPLOAD_IMAGES: 'UPLOAD_IMAGES',
  CONTACT_PAGE: 'CONTACT_PAGE',
  SEARCH_ENGINE_OPTIMIZATION: 'SEARCH_ENGINE_OPTIMIZATION',
  COPYRIGHT: 'COPYRIGHT',
  MOBILE_PREVIEW: 'MOBILE_PREVIEW',
  PUBLISH_WEBSITE: 'PUBLISH_WEBSITE',
  PURCHASE_PLAN: 'PURCHASE_PLAN',
  CONNECT_DOMAIN: 'CONNECT_DOMAIN',
};

export const DATA: { [key: string]: { title: string, isGoButtonVisible: boolean } } = {
  [KEYS.ADD_PAGE]: {
    title: 'ADD A PAGE',
    isGoButtonVisible: true,
  },
  [KEYS.UPLOAD_IMAGES]: {
    title: 'UPLOAD IMAGES',
    isGoButtonVisible: true,
  },
  [KEYS.CONTACT_PAGE]: {
    title: 'CONTACT PAGE',
    isGoButtonVisible: true,
  },
  [KEYS.SEARCH_ENGINE_OPTIMIZATION]: {
    title: 'SEARCH ENGINE OPTIMIZATION',
    isGoButtonVisible: true,
  },
  [KEYS.COPYRIGHT]: {
    title: 'COPYRIGHT',
    isGoButtonVisible: true,
  },
  [KEYS.MOBILE_PREVIEW]: {
    title: 'MOBILE PREVIEW',
    isGoButtonVisible: true,
  },
  [KEYS.PUBLISH_WEBSITE]: {
    title: 'PUBLISH WEBSITE',
    isGoButtonVisible: true,
  },
  [KEYS.PURCHASE_PLAN]: {
    title: 'PURCHASE A PLAN',
    isGoButtonVisible: true,
  },
  [KEYS.CONNECT_DOMAIN]: {
    title: 'CONNECT A DOMAIN',
    isGoButtonVisible: true,
  },
};
