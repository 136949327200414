export const KEYS = {
  IS_SUBTITLE_VISIBLE: 'IS_SUBTITLE_VISIBLE',
  TEXT_COLOR: 'TEXT_COLOR',
  LINE_HEIGHT: 'LINE_HEIGHT',
  LETTER_SPACING: 'LETTER_SPACING',
  WORD_SPACING: 'WORD_SPACING',
  SCALABLE_FONT_SIZE: 'SCALABLE_FONT_SIZE',
  SCALABLE_LINE_HEIGHT: 'SCALABLE_LINE_HEIGHT',
  SCALABLE_LETTER_SPACING: 'SCALABLE_LETTER_SPACING',
  SCALABLE_WORD_SPACING: 'SCALABLE_WORD_SPACING',
};

export const TYPES = {
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
  ATTRIBUTE: 'ATTRIBUTE',
};
