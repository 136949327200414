export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `気が付くとすでに地球を飛び立っていた。`,
      `彼らの機器や装置はすべて生命体だ。`,
      `赤い炎が尖った翼の輪郭を浮かび上がらせた。`,
      `帰路は孤独なものになるはずだった。`,
    ],
    [KEYS.ALPHABET]: [
      '一二三四五六七八九十百千上下左右中大小月日年早木林山川土空田天生花草虫犬人名女男子目耳口手足見音力気円入出立休先夕本文字学校村町森正水火玉王石竹糸貝車金雨赤青白数多少万半形太細広長点丸交光角計直線矢弱強高同親母父姉兄弟妹自友体毛頭顔首心時曜朝昼夜分週春夏秋冬今新古間方北南東西遠近前後内外場地国園谷野原里市京風雪雲池海岩星室戸家寺通門道話言答声聞語読書記紙画絵図工教晴思考知才理算作元食肉馬牛魚鳥羽鳴麦米茶色黄黒来行帰歩走止活店買売午汽弓回会組船明社切電毎合当台楽公引科歌刀番用何‘?’“!”(%)[#]{@}/&<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `各部位を正確に作るには時間がかかるので、当初の意図とは異なるが、巨大な人体を作ることにした。高さは約 8 フィートで、これに釣り合う体格だ。これを決断し、数か月にわたって材料を集め整理した後、作業を開始した。`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
