import {IStyleModelOptions} from '../../../option/i-style-model-options';

import {BaseSelectIntModel} from '../../../base/select/int/base-select-int.model';

import {PROPERTY_NAME, STYLE_PROPERTY_NAME} from './constants';

export class BlogCommentsUsernameFontSizeModel extends BaseSelectIntModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, STYLE_PROPERTY_NAME, value, isEnabled);
  }

  public init(selectedElement: HTMLElement, options: IStyleModelOptions) {
    if (!selectedElement || !this.isEnabled) return;

    this.isEnabled = options.block && options.block.classList.contains('blog');

    super.init(options.block, { selectedElement });
  }
}
