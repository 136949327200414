import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

import {AuthService} from '../../../../auth/auth.service';

import {ImagesIconModel} from '../../../models/image-manager/icons/images-icon.model';
import {ImagesIconDto} from '../../../models/image-manager/icons/images-icon.dto';
import {AccountModel} from '../../../models/accounts/account.model';

@Injectable()
export class ImagesIconsService {
  private model = 'images';

  public iconsSubject: BehaviorSubject<ImagesIconModel> = new BehaviorSubject<ImagesIconModel>(new ImagesIconModel());

  public get icons(): ImagesIconModel {
    return this.iconsSubject.value;
  }

  constructor(private http: HttpClient,
              private authService: AuthService) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      if (account) {
        return this.initIcons();
      }

      this.iconsSubject.next(new ImagesIconModel());
    });
  }

  private initIcons() {
    return this.fetchIcons().subscribe((res: ImagesIconDto[]) => {
      if (!res) {
        return;
      }

      const data: ImagesIconModel = new ImagesIconModel();

      res.forEach((item: ImagesIconDto) => {
        data[item.portfolioId] = item.path;
      });

      this.iconsSubject.next(data);
    });
  }

  public initIcon(id: number) {
    return this.fetchIcon(id).subscribe((res: ImagesIconDto) => {
      if (!res) {
        return;
      }

      this.icons[res.portfolioId] = res.path;

      this.notifyAboutChanges();
    });
  }

  public setIcon(id: number, value: string) {
    this.icons[id] = value;

    this.notifyAboutChanges();
  }

  private fetchIcons(): Observable<ImagesIconDto[]> {
    return this.http.get<ImagesIconDto[]>(`api/${this.model}/icons`);
  }

  private fetchIcon(id: number): Observable<ImagesIconDto> {
    return this.http.get<ImagesIconDto>(`api/${this.model}/icons/${id}`);
  }

  private notifyAboutChanges() {
    this.iconsSubject.next(this.iconsSubject.value);
  }
}
