import {IStyleOption} from '../option/i-style-option';
import {ColorModel} from '../../color/color.model';

import {BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB} from './constants';

export class OverlayColorModel implements IStyleOption {
  public element: HTMLElement;
  public defaultValueHolder: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.defaultValueHolder = <HTMLElement>element.querySelector('[data-color-overlay]');

    if (!this.defaultValueHolder) return;

    const value = this.defaultValueHolder.style.getPropertyValue(CUSTOM_BACKGROUND_COLOR);

    if (value) return this.setValue(value === 'undefined' ? void 0 : value);

    this.initDefaultValue();
  }

  public reset() {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(CUSTOM_BACKGROUND_COLOR);
    this.element.style.removeProperty(CUSTOM_BACKGROUND_COLOR_RGB);

    this.initDefaultValue();
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR, value);

    const colorModel = new ColorModel(value);

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR_RGB, colorModel.getRGBstring());
  }

  private initDefaultValue(): void {
    const colorModel = new ColorModel(window.getComputedStyle(this.defaultValueHolder)[BACKGROUND_COLOR]);

    this.value = `rgb(${colorModel.getRGBstring()})`;
  }
}
