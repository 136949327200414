export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `അവരുടെ എല്ലാ ഉപകരണങ്ങളും യന്ത്രങ്ങളും ഏതെങ്കിലും രൂപത്തിൽ സജീവമാണ്.`,
      `തുറമുഖം വിട്ട് മൂന്ന് മണിക്കൂറിനുള്ളിൽ കപ്പലിനെ മൂടൽ മഞ്ഞ് പൊതിഞ്ഞു.`,
      `വാതിലിനു മുകളിലെ സ്പീക്കറില്‍നിന്നുള്ള ശബ്‌ദത്തിൽ പതർച്ചയുണ്ടായിരുന്നു.`,
    ],
    [KEYS.ALPHABET]: [
      'അആഇഈഉഊഋഎഏഐഒഓഔഅംഅഃകഖഗഘങചഛജഝഞടഠഡഢണതഥദധനപഫബഭമയരലവശഷസഹളഴറഽ§₹',
    ],
    [KEYS.PARAGRAPH]: [
      `ചൊവ്വയുടെ ഉപരിതലം കാണുക എന്നതാണെന്റെ ആഗ്രഹം, അത് തീർത്തും ഒരു അപൂർവ്വ അനുഭവമായിരിക്കും. ലോക പര്യടനം നടത്തുന്നത് പോലെ ടെലിസ്‌കോപ്പിലൂടെ ആകാശഗോളങ്ങളെ നിരീക്ഷിക്കുന്നതും സ്വതന്ത്ര വിദ്യാഭ്യാസത്തിന്റെ ഭാഗമാകണം എന്നാണ് എന്റെ കാഴ്‌ചപ്പാട്.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
