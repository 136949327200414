// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.set-image-modal-body {
  padding: 20px;
  font-size: 0.9em;
}
.set-image-modal-body .text {
  padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/set-image/set-image-modal.component.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,gBAAA;AAFF;AAIE;EACE,oBAAA;AAFJ","sourcesContent":["\n@import \"../../../../../styles/colors\";\n\n.set-image-modal-body {\n  padding: 20px;\n  font-size: 0.9em;\n\n  .text {\n    padding-bottom: 15px;\n  }\n\n  .buttons-wrapper {\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
