import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../modals.service';

import {StripeCouponModel} from '../../../../core/models/stripe/coupon/stripe-coupon.model';

@Injectable()
export class CouponsModalService {
  public readonly id = 'coupons-modal';

  public isOpened = false;

  public openedFor: any;

  public selectedCouponSubject: Subject<StripeCouponModel> = new Subject<StripeCouponModel>();

  public constructor(private modalsService: ModalsService) {
  }

  public open(): void {
    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.openedFor = null;

    this.modalsService.close(this.id);
  }
}
