import {Injectable} from '@angular/core';

import {BlogCommentsSetupModel} from '../../../../models/styles/setup/blog/comments/blog-comments-setup.model';

import {StyleOptionModel} from '../../../../models/styles/settings/option/style-option.model';

@Injectable()
export class BlogCommentsStylesService {
  public setup: BlogCommentsSetupModel;

  public get element(): HTMLElement {
    return this.setup.element;
  }

  constructor() {
    this.setup = new BlogCommentsSetupModel();
  }

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.setup.init(element, enabledStyleOptions);
  }
}
