import {IStyleOption} from '../option/i-style-option';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {IsSubtitleVisibleModel} from './is-visible/is-subtitle-visible.model';
import {SubtitleTextColorModel} from './text-color/subtitle-text-color.model';
import {WebsiteSubtitleScalableFontSizeModel} from './scalable-font-size/website-subtitle-scalable-font-size.model';
import {WebsiteSubtitleScalableLineHeightModel} from './scalable-line-height/website-subtitle-scalable-line-height.model';
import {WebsiteSubtitleScalableLetterSpacingModel} from './scalable-letter-spacing/website-subtitle-scalable-letter-spacing.model';
import {WebsiteSubtitleScalableWordSpacingModel} from './scalable-word-spacing/website-subtitle-scalable-word-spacing.model';
import {ScalableFontSizeModel} from '../scalable-font-size/scalable-font-size.model';
import {ScalableLineHeightModel} from '../scalable-line-height/scalable-line-height.model';
import {ScalableLetterSpacingModel} from '../scalable-letter-spacing/scalable-letter-spacing.model';
import {ScalableWordSpacingModel} from '../scalable-word-spacing/scalable-word-spacing.model';

import {KEYS, TYPES} from '../../../../services/styles/subtitle/constants';

export class SubtitleSetupModel {
  public subtitle: HTMLElement;

  public isVisible: IsSubtitleVisibleModel = new IsSubtitleVisibleModel(KEYS.IS_SUBTITLE_VISIBLE, TYPES.CLASS);
  public textColor: SubtitleTextColorModel= new SubtitleTextColorModel(KEYS.TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public scalableFontSize: WebsiteSubtitleScalableFontSizeModel = new WebsiteSubtitleScalableFontSizeModel(KEYS.SCALABLE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public scalableLineHeight: WebsiteSubtitleScalableLineHeightModel = new WebsiteSubtitleScalableLineHeightModel(KEYS.SCALABLE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public scalableLetterSpacing: WebsiteSubtitleScalableLetterSpacingModel = new WebsiteSubtitleScalableLetterSpacingModel(KEYS.SCALABLE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public scalableWordSpacing: WebsiteSubtitleScalableWordSpacingModel = new WebsiteSubtitleScalableWordSpacingModel(KEYS.SCALABLE_WORD_SPACING, TYPES.STYLE_PROPERTY);
  public legacyScalableFontSize: ScalableFontSizeModel = new ScalableFontSizeModel(KEYS.SCALABLE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public legacyScalableLineHeight: ScalableLineHeightModel = new ScalableLineHeightModel(KEYS.SCALABLE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public legacyScalableLetterSpacing: ScalableLetterSpacingModel = new ScalableLetterSpacingModel(KEYS.SCALABLE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public legacyScalableWordSpacing: ScalableWordSpacingModel = new ScalableWordSpacingModel(KEYS.SCALABLE_WORD_SPACING, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.isVisible,
    this.textColor,
    this.scalableFontSize,
    this.scalableLineHeight,
    this.scalableLetterSpacing,
    this.scalableWordSpacing,
    this.legacyScalableFontSize,
    this.legacyScalableLineHeight,
    this.legacyScalableLetterSpacing,
    this.legacyScalableWordSpacing,
  ];

  private initHandlers = {
    [TYPES.CLASS]: this.initClass.bind(this),
    [TYPES.STYLE_PROPERTY]: this.initStyleProperty.bind(this),
  };

  public init(subtitle: HTMLElement) {
    this.subtitle = subtitle;

    if (!this.subtitle) return;

    const innerWrapper: HTMLElement = subtitle ? <HTMLElement>subtitle.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(subtitle);

    this.options.forEach(option => {
      this.initHandlers[option.type](option, {
        innerWrapper,
        block,
      });
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.matches('[data-menu-block]') ? element : <HTMLElement>element.closest('[data-menu-block]');
  }

  private initClass(option: IStyleOption, options: IStyleModelOptions) {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.subtitle, options);
  }

  private initStyleProperty(option: IStyleOption, options: IStyleModelOptions) {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.subtitle, options);
  }
}
