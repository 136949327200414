import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {EducationWebsiteExhibitionsService} from '../../teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';

import {ImagesCounterModel} from '../../../../models/image-manager/counters/images-counter.model';
import {PortfolioModel} from '../../../../models/portfolios/portfolio.model';
import {ImageDataModel} from '../../../../models/image-manager/counters/image-data.model';

@Injectable()
export class EducationExhibitionsImagesCounterService {
  public countersSubject: BehaviorSubject<ImagesCounterModel> = new BehaviorSubject<ImagesCounterModel>(new ImagesCounterModel());

  private exhibitions: PortfolioModel[];

  private get counters(): ImagesCounterModel {
    return this.countersSubject.value;
  }

  constructor(private exhibitionsService: EducationWebsiteExhibitionsService) {
    this.exhibitionsService.listSubject.subscribe((list: PortfolioModel[]) => {
      this.exhibitions = list;

      this.initCounters();
    });
  }

  private initCounters(): void {
    if (!this.exhibitions) return;

    const counters = new ImagesCounterModel();

    this.exhibitions.forEach((portfolio: PortfolioModel) => {
      counters.published[portfolio.id] = portfolio.publishedImagesCount || 0;
      counters.total[portfolio.id] = portfolio.totalImagesCount || 0;
    });

    this.countersSubject.next(counters);
  }

  public handlePublish(id: number, isPublish: boolean) {
    return isPublish ? this.onPublish(id) : this.onUnpublish(id);
  } 

  public onPublish(id: number) {
    this.counters.onPublish(id);
    
    this.notifyAboutChanges();
  }

  public onUnpublish(id: number) {
    this.counters.onUnpublish(id);

    this.notifyAboutChanges();
  }

  public onImageAdd(id: number, count: number = 1) {
    this.counters.onImageAdd(id, count);
    
    this.notifyAboutChanges();
  }

  public onImageRemove(id: number, imagesData: ImageDataModel[]) {
    this.counters.onImageRemove(id, imagesData);

    this.notifyAboutChanges();
  }

  private notifyAboutChanges() {
    this.countersSubject.next(this.countersSubject.value);
  }
}
