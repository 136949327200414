import {IStyleModelOptions} from '../option/i-style-model-options';

import {BaseColorModel} from '../base/color/base-color.model';

import {PROPERTY_NAME, STYLE_PROPERTY_NAME, SELECTORS} from './constants';

export class SlideshowButtonColorModel extends BaseColorModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, STYLE_PROPERTY_NAME, value, isEnabled, false);
  }

  public init(selectedElement: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !selectedElement) return;

    const elementToSelect: Element = selectedElement.closest(SELECTORS.SLIDESHOW_SLIDE) || selectedElement.closest(SELECTORS.BLOCK);

    super.init(<HTMLElement>elementToSelect, {
      selectedElement,
      defaultValueHolderElement: selectedElement,
    });
  }
}
