import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';

import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {ImageManagerService} from '../../../application/main/image-manager/image-manager.service';
import {ModalsService} from '../../../shared/services/modals/modals.service';
import {ImageUploadService} from '../../../core/services/image-manager/upload/image-upload.service';
import {ImageSpecificationsModalService} from '../../../shared/services/modals/image-specifications/image-specifications-modal.service';
import {UserSetupImagesService} from '../../../core/services/users/setup/images/user-setup-images.service';
import {ImageFileNameAsTitleModalService} from '../../../shared/services/modals/image-file-name-as-title/image-file-name-as-title-modal.service';
import {WrongImageTypeModalService} from '../../../shared/services/modals/wrong-image-type/wrong-image-type-modal.service';

import {ImageModel} from '../../../core/models/images/image.model';
import {ImageSetupModel} from '../../../core/models/setup/images/image-setup.model';
import {ModalDataModel} from '../../../core/models/modals/modal-data.model';
import {ImageDto} from '../../../core/models/images/image.dto';

import {MAX_FILE_SIZE} from './constants';

import {AppAnimations} from '../../../app-animations';

@Component({
  selector: 'app-image-dropzone',
  templateUrl: 'image-dropzone.component.html',
  styleUrls: ['./image-dropzone.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageDropzoneComponent implements OnInit, OnDestroy {
  @ViewChild('imagesContainer') imagesContainer: ElementRef;

  @Input() portfolioId: number;

  @Output() updateHandler = new EventEmitter();
  @Output() afterUpload = new EventEmitter();
  @Output() beforeDelete = new EventEmitter();
  @Output() afterDelete = new EventEmitter();

  public images: ImageSetupModel[];

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  public failedImages = [];

  public isDragOver: boolean = false;

  public sizeErrorModalId = 'setup-size-error-modal';
  public imageSizeErrorModalId = 'setup-image-size-error-modal';
  public errorModalId = 'setup-image-error-modal';
  public failedImagesModalHeader = 'Image Upload Error!';

  private dragEnterCounter: number = 0;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private imageManagerService: ImageManagerService,
              private modalsService: ModalsService,
              private imageSpecificationsModalService: ImageSpecificationsModalService,
              private imageFileNameAsTitleModalService: ImageFileNameAsTitleModalService,
              private wrongImageTypeModalService: WrongImageTypeModalService,
              private cdr: ChangeDetectorRef,
              public userSetupImagesService: UserSetupImagesService,
              public imageUploadService: ImageUploadService) {}

  public ngOnInit(): void {
    this.userSetupImagesService.imagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      if (!images) return;

      this.images = images.map((image: ImageModel) => new ImageSetupModel(image.id, `https://${image.sizesUrls['800']}`, true));

      this.updateHandler.emit(this.images.length);
    });

    this.imageFileNameAsTitleModalService.onFileNameAsTitleSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((options: { isImageFileNameAsTitle: boolean, images: File[] }) => {
      if (!options) return;

      this.uploadFiles(options.images, options.isImageFileNameAsTitle);
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onDragEnter(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();

    this.dragEnterCounter++;

    this.isDragOver = true;
  }

  public onDragOver(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
  }

  public onDragLeave(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();

    if (--this.dragEnterCounter > 0) return;

    this.isDragOver = false;
  }

  public onDrop(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();

    this.isDragOver = false;
    this.dragEnterCounter = 0;

    const inputFiles: File[] = Array.from(<FileList>e.dataTransfer.files);

    const files: File[] = inputFiles.filter((file: File) => file.type.startsWith('image/jpeg'));

    if (inputFiles.length !== files.length) {
      this.wrongImageTypeModalService.open();

      return;
    }

    this.imageFileNameAsTitleModalService.open(files);
  }

  public onUpload(e: Event): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const inputFiles: File[] = Array.from(target.files);

    const files: File[] = inputFiles.filter((file: File) => file.type.startsWith('image/jpeg'));

    if (inputFiles.length !== files.length) {
      this.wrongImageTypeModalService.open();

      target.value = null;

      return;
    }

    this.imageFileNameAsTitleModalService.open(files);

    target.value = null;
  }

  // async ok
  private async uploadFiles(files: File[], isImageFileNameAsTitle: boolean = false) {
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      if (this.images.length > this.userSetupImagesService.maxImagesCount) return;

      const image: ImageSetupModel = new ImageSetupModel(Math.floor(Math.random() * 1000000), null, false);

      const imageElement = await this.imageUploadService.readImage(files[i]);
      const isImageHasEnoughResolution = this.imageUploadService.isImageHasEnoughResolution(imageElement);

      if (!isImageHasEnoughResolution) {
        this.failedImages.push({ file: files[i] });

        this.modalsService.open(this.imageSizeErrorModalId);

        continue;
      }

      const fr = new FileReader();

      fr.onload = () => {
        image.src = <string>fr.result;

        this.images.push(image);

        this.uploadOneImage({ file: files[i], image, isImageFileNameAsTitle });
      };

      fr.readAsDataURL(files[i]);
    }
  }

  private uploadOneImage({ file, image, isImageFileNameAsTitle }: { file: File, image: ImageSetupModel, isImageFileNameAsTitle: boolean }): Subscription {
    if (file.size > MAX_FILE_SIZE) {
      this.images = this.images.filter((item: ImageSetupModel) => item.id !== image.id);

      this.failedImages.push({ file });

      this.modalsService.open(this.sizeErrorModalId);

      return Subscription.EMPTY;
    }

    return this.imageUploadService.uploadOne({
      file,
      portfolioId: this.portfolioId,
      classId: null,
      type: 'User',
      isImageFileNameAsTitle,
    }).pipe(
      catchError(e => {
        console.error(e);
  
        this.images = this.images.filter((item: ImageSetupModel) => item.id !== image.id);
  
        this.failedImages.push({ file });
  
        this.modalsService.open(this.errorModalId);
        
        return throwError(() => e);
      })
    ).subscribe((event: { body: ImageDto, type: number, loaded: number, total: number }) => {
      if (event.type === HttpEventType.UploadProgress) {
        image.percent = `${Math.round(100 / event.total * event.loaded)}%`;
      }

      if (event.type === HttpEventType.Response) {
        image.id = event.body.Id;
        image.isUploaded = true;

        this.afterUpload.emit();
        this.updateHandler.emit(this.images.length);
      }
    });
  }

  public removeFile(image: ImageSetupModel): Subscription {
    this.images = this.images.filter((item: ImageSetupModel) => item.id !== image.id);

    this.beforeDelete.emit(this.images.length);

    return this.imageManagerService.deleteImage(image.id).subscribe(() => {
      this.afterDelete.emit(this.images.length);
    });
  }

  public showImageSpecifications(): void {
    this.imageSpecificationsModalService.open();
  }

  public clearFailedFiles(): void {
    this.failedImages = [];
  }
}
