import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';
import {EducationStudentsWebsitesHttpService} from '../../../../core/services/interaction/http/education/students/websites/education-students-websites-http.service';

import {EducationStudentWebsiteModel} from '../../../../core/models/education/students/websites/education-student-website.model';

@Injectable()
export class StudentCourseEndedModalService {
  public readonly id = 'student-course-ended-modal';

  public isOpened: boolean = false;

  private lastOpenedFor: number = null;

  constructor(
    private modalsService: ModalsService,
    private educationStudentsWebsitesHttpService: EducationStudentsWebsitesHttpService,
  ) {
  } 

  public open({ websiteId, isSameWebsiteActive }: { websiteId: number, isSameWebsiteActive: boolean }): void {
    if (this.lastOpenedFor === websiteId) {
      return;
    }

    this.isOpened = true;

    this.lastOpenedFor = websiteId;

    this.educationStudentsWebsitesHttpService.getShortById(websiteId).subscribe((website: EducationStudentWebsiteModel) => {
      this.modalsService.open(this.id, { website, isSameWebsiteActive });
    });
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
