import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DeletePortfolioModalService} from '../../../services/modals/delete-portfolio/delete-portfolio-modal.service';

import {PortfolioModel} from '../../../../core/models/portfolios/portfolio.model';

@Component({
  selector: 'app-delete-portfolio-modal',
  templateUrl: './delete-portfolio-modal.component.html',
  styleUrls: ['./delete-portfolio-modal.component.scss'],
})
export class DeletePortfolioModalComponent implements OnInit, OnDestroy {
  public portfolio: PortfolioModel;

  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }
  
  public get modalHeader() {
    return {
      text: 'Delete Portfolio?',
      className: 'warning-header',
    };
  }

  constructor(private service: DeletePortfolioModalService) {
  }

  public ngOnInit(): void {  
    this.service.portfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolio: PortfolioModel) => {
      this.portfolio = portfolio;
    });
  }

  public removePortfolio(): void {
    this.isLoading = true;

    this.service.removePortfolio(this.portfolio);
  }

  public closeModal(): void {
    this.service.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
