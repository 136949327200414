import {NativeSetupOptionModel} from '../option/native-setup-option.model';
import {GoogleFontModel} from '../../../google/fonts/google-font.model';

import {PROPERTY, USED_FONT_ATTR} from './constants';

export class FontFamilyModel extends NativeSetupOptionModel {
  constructor(
    public key: string,
    public isEnabled: boolean = true,
  ) {
    super(key, PROPERTY, isEnabled);
  }

  public init(element: HTMLElement): void {
    this.isEnabled = this.isEnabled && !!element;

    if (!this.isEnabled) return;

    this.element = element;

    this.initValue();
  }

  public onChange(font: GoogleFontModel): void {
    this.setValue(font);

    this.element.setAttribute(USED_FONT_ATTR, font.family);
    
    this.element.style.setProperty('font-family', `"${font.family}"`);
  }

  public setValue(font: GoogleFontModel): void {
    this.value = font.family.replace(/"/gi, '');
  }

  public reset(): void {
    this.element.style.removeProperty(PROPERTY);
    this.element.removeAttribute(USED_FONT_ATTR);

    this.initValue();
  }

  private initValue(): void {
    const value = window.getComputedStyle(this.element).getPropertyValue(PROPERTY).trim(); // possible values: "Font Name", sans-serif; "Font Name"; Font Name.

    if (!value) return;

    if (!value.includes('"')) {
      this.value = value.match(/(\w|\s)+/gi)[0];

      return;
    }

    this.value = value.match(/"(\w|\s)+"/gi)[0].replace(/"/gi, '');
  }
}
