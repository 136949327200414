import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CreditCardsModalService} from '../../../services/modals/credit-cards/credit-cards-modal.service';
import {PaymentService} from '../../../../core/services/payment/payment.service';

import {CardModel} from '../../../../core/models/payment/card/card.model';
import {PaymentModel} from '../../../../core/models/payment/payment.model';

@Component({
  selector: 'app-credit-cards-modal',
  templateUrl: './credit-cards-modal.component.html',
  styleUrls: ['./credit-cards-modal.component.scss']
})
export class CreditCardsModalComponent implements OnInit, OnDestroy {
  public selectedCard: CardModel;

  private paymentData: PaymentModel;
  private lastAddedCardId: string;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.modalService.id;
  }

  constructor(private modalService: CreditCardsModalService,
              private paymentService: PaymentService) {
  }

  public ngOnInit(): void {
    this.paymentService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paymentData: PaymentModel) => {
      this.paymentData = paymentData;

      this.initCard();
    });

    this.paymentService.lastAddedCardIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cardId: string) => {
      this.lastAddedCardId = cardId;

      this.initCard();
    });
  }

  private initCard(): void {
    if (!this.paymentData || !this.lastAddedCardId) return;

    this.onCardSelect(this.paymentData.cards.find((card: CardModel) => {
      return this.lastAddedCardId ? card.id === this.lastAddedCardId : card.isDefault;
    }));
  }

  public ngOnDestroy(): void {
    this.paymentService.lastAddedCardIdSubject.next(null);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onCardSelect(card: CardModel): void {
    this.selectedCard = card;

    this.modalService.selectedCardSubject.next(card);
  }

  public onOpen(card?: CardModel): void {
    this.selectedCard = card;
  }

  public close(): void {
    this.modalService.close();
  }
}
