import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {EventsService} from '../interaction/events/events.service';
import {IFrameService} from '../iframe/iframe.service';
import {IFrameRoutingService} from '../iframe/routing/iframe-routing.service';
import {BlocksChangelogsService} from '../../../services/blocks-changelogs.service';

import {SelectedPageModel} from '../../models/selected-page/selected-page.model';
import {OutdatedBlocksDataModel} from '../../models/outdated-blocks-data/outdated-blocks-data.model';

import {OutdatedPagesDataModel} from '../../models/outdated-pages-data/outdated-pages-data.model';

import {EVENTS} from './constants';

@Injectable()
export class BlocksManagerService {
  public outdatedBlocksSubject: BehaviorSubject<OutdatedBlocksDataModel> = new BehaviorSubject<OutdatedBlocksDataModel>(null);
  public outdatedPagesSubject: BehaviorSubject<OutdatedPagesDataModel> = new BehaviorSubject<OutdatedPagesDataModel>(null);

  public onBlocksListChange: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  private selectedPage: SelectedPageModel;

  private handlers: { [key: string]: any } = {
    [EVENTS.LIST_CHANGED]: this.onListChanged.bind(this),
    [EVENTS.ADDED]: this.onBlockAdded.bind(this),
    [EVENTS.DUPLICATED]: this.onBlockDuplicated.bind(this),
    [EVENTS.REMOVE]: this.onBlockRemove.bind(this),
    [EVENTS.VIEW_CHANGELOG]: this.onViewChangelog.bind(this),
  };

  private isLoaded: boolean = false;
  private isContentLoaded: boolean = false;

  public get outdatedBlocksData(): OutdatedBlocksDataModel {
    return this.outdatedBlocksSubject.value;
  }

  constructor(private eventsService: EventsService,
              private ngZone: NgZone,
              private iFrameService: IFrameService,
              private iFrameRoutingService: IFrameRoutingService,
              private blockTemplateUpdatesInfoService: BlocksChangelogsService) {
    this.iFrameService.isLoadedSubject.subscribe(isLoaded => {
      this.isLoaded = isLoaded;
      this.initOutdatedBlocks();
    });

    this.iFrameService.onContentLoad.subscribe(isContentLoaded => {
      this.isContentLoaded = isContentLoaded;
      this.initOutdatedBlocks();
    });

    this.outdatedBlocksSubject.subscribe(() => {
      this.initOutdatedBlocks();
      this.setPagesWithOutdatedBlocks();
    });

    this.iFrameRoutingService.selectedPageSubject.subscribe((value: SelectedPageModel) => {
      this.selectedPage = value;
    });

    this.addListeners();
  }

  private initOutdatedBlocks() {
    if (!this.isLoaded || !this.isContentLoaded || !this.outdatedBlocksData) return;

    const outdatedBlocks = this.outdatedBlocksData.getCurrentPageOutdatedBlocks(this.selectedPage);

    this.eventsService.dispatchOutdatedBlocks(outdatedBlocks, this.iFrameService.sandboxWindow);
  }

  private addListeners() {
    this.eventsService.addFrameListener(EVENTS.LIST_CHANGED, this.handlers[EVENTS.LIST_CHANGED]);
    this.eventsService.addFrameListener(EVENTS.ADDED, this.handlers[EVENTS.ADDED]);
    this.eventsService.addFrameListener(EVENTS.DUPLICATED, this.handlers[EVENTS.DUPLICATED]);
    this.eventsService.addFrameListener(EVENTS.REMOVE, this.handlers[EVENTS.REMOVE]);
    this.eventsService.addFrameListener(EVENTS.VIEW_CHANGELOG, this.handlers[EVENTS.VIEW_CHANGELOG]);
  }

  private onListChanged(e: CustomEvent): void {
    if (!e.detail) return;

    this.onBlocksListChange.next(e.detail.blocksTemplatesIds);
  }

  private onBlockAdded(e: CustomEvent): void {
    if (!e.detail || !e.detail.block) return;

    this.onAddHandler(e.detail.block);
  }

  private onBlockDuplicated(e: CustomEvent): void {
    if (!e.detail) return;

    this.outdatedBlocksData.addOutdatedBlock(e.detail.fromBlockId, e.detail.block, this.selectedPage);
  }

  private onBlockRemove(e: CustomEvent): void {
    if (!e.detail) return;

    this.setIsOutdated(e.detail.block, this.selectedPage, false);
  }

  private onViewChangelog(e: CustomEvent): void {
    if (!e.detail) return;

    this.ngZone.run(() => this.blockTemplateUpdatesInfoService.showChangelog(e.detail));
  }

  private onAddHandler(block) {
    if (!block || !this.outdatedBlocksData) return;

    const blocks = this.outdatedBlocksData.getBlocks(block.BlockTemplateId, this.selectedPage);

    if (!blocks) return;

    if (block.IsSingle) return this.setIsOutdated(block, this.selectedPage, !!block.BlockID);

    const idx = blocks.findIndex(b => b.id === block.BlockID);

    if (idx !== -1) this.setIsOutdated(block, this.selectedPage, true);
  }

  private setIsOutdated(block, page: SelectedPageModel, value: boolean) {
    if (!block || !this.outdatedBlocksData) return;

    this.outdatedBlocksData.setIsBlockOutdated(block, page, value);
    this.setPagesWithOutdatedBlocks();

    this.outdatedBlocksSubject.next(this.outdatedBlocksData);
  }

  private setPagesWithOutdatedBlocks() {
    if (!this.outdatedBlocksData || !this.outdatedBlocksData.blocks) return this.outdatedPagesSubject.next(null);

    const outdatedPagesDataModel: OutdatedPagesDataModel = new OutdatedPagesDataModel(this.outdatedBlocksData);

    this.outdatedPagesSubject.next(outdatedPagesDataModel);
  }

  public static getCustomEditableType(element: HTMLElement): string {
    return element.getAttribute('data-editable-type');
  }

  public static getBlockId(element: HTMLElement): number {
    if (!BlocksManagerService.isBlock(element)) return null;

    return parseInt(element.getAttribute('data-block-template-id'), 10);
  }

  public static getBlockVersion(element: HTMLElement): string {
    if (!BlocksManagerService.isBlock(element)) return null;

    return element.getAttribute('data-block-version');
  }

  public static getBlockCategoryId(element: HTMLElement): number {
    if (!BlocksManagerService.isBlock(element)) return null;

    return parseInt(element.getAttribute('data-block-category-id'), 10);
  }

  public static isBlock(element: HTMLElement): boolean {
    if (!element) return false;

    return (element.classList.contains('block') || element.classList.contains('menu-block')) && element.hasAttribute('data-block-category-id');
  }
}
