export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `அந்திமாலையில், அலைகள் வேகமாக வீசத் தொடங்கின.`,
      `அறிவதற்கு முன்னமே, நாங்கள் தரையிறங்கிவிட்டோம்.`,
      `இரவில், எரிநட்சத்திரம் விழுவது போல் அவை வந்தன.`,
    ],
    [KEYS.ALPHABET]: [
      'க்ங்ச்ஞ்ட்ண்த்ந்ப்ம்ய்ர்ல்வ்ழ்ள்ற்ன்ஶ்ஜ்ஷ்ஸ்ஹ்க்ஷ்அஆஇஈஉஊஎஏஐஒஓஔ௳௴௵௶௷௸௹௺ளவ',
    ],
    [KEYS.PARAGRAPH]: [
      `தூரத்திலிருந்து கேட்கும் ஒரு சத்தம், புனிதமான இசையின் தாக்கம் அல்லது ஒரு உன்னதமான படம் அல்லது மகத்தான கவிஞர்கள் எழுதிய கவிதைகளின் சில வரிகளைப் போலவே, நமது எண்ணங்களை உயர்த்தி அதைச் சுத்திகரிக்கும் ஆற்றலைக் கொண்டது. இது எப்போதுமே ஒருவரை நல்லவராக்கும்.`
    ],
    [KEYS.NUMERALS]: [
      '௦௧௨௩௪௫௬௭௮௯௰௱௲',
    ],
  };
};
