import {PlanModel} from '../../../plan/plan.model';
import {PlanCartItemOptionsDto} from './plan-cart-item-options.dto';

export class PlanCartItemOptionsModel {
  constructor(
    public duration: string,
    public plan?: PlanModel,
  ) {
  }

  public static normalize(res: PlanCartItemOptionsDto): PlanCartItemOptionsModel {
    if (!res) return null;

    return new PlanCartItemOptionsModel(
      res.duration,
      PlanModel.normalize(res.plan),
    );
  }
}
