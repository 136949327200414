import {SelectOption} from '../../../select/option/option.model';

export const ATTRIBUTE = 'data-image-crop';
export const ATTRIBUTE_DEFAULT_KEY = 'data-image-crop-default-key';

export const KEYS = {
  NONE: 'NONE',
  RATIO_3x2: 'RATIO_3x2',
  RATIO_5x4: 'RATIO_5x4',
  SQUARE: 'SQUARE',
  RATIO_4x5: 'RATIO_4x5',
  RATIO_2x3: 'RATIO_2x3',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('None', KEYS.NONE),
  new SelectOption('3:2', KEYS.RATIO_3x2),
  new SelectOption('5:4', KEYS.RATIO_5x4),
  new SelectOption('Square', KEYS.SQUARE),
  new SelectOption('4:5', KEYS.RATIO_4x5),
  new SelectOption('2:3', KEYS.RATIO_2x3),
];

export const DEFAULT_KEY = KEYS.SQUARE;
