import {SelectOption} from '../../../select/option/option.model';

export const ATTRIBUTE = 'data-slideshow-transition';

export const KEYS = {
  CROSSFADE: 'CROSSFADE',
  SLIDE: 'SLIDE',
  FADE_IN: 'FADE_IN',
  FADE_IN_OUT: 'FADE_IN_OUT',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('Crossfade', KEYS.CROSSFADE),
  new SelectOption('Slide', KEYS.SLIDE),
  new SelectOption('Fade In', KEYS.FADE_IN),
  new SelectOption('Fade In & Out', KEYS.FADE_IN_OUT),
];

export const DEFAULT_KEY = KEYS.CROSSFADE;
export const DEFAULT_OPTION = OPTIONS[0];
