import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class PortfolioLinkModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!element) return;

    this.element = element;

    this.value = this.element.getAttribute(ATTRIBUTES.HREF);
  }

  public onChange(value: string) {
    this.value = value;

    this.element.setAttribute(ATTRIBUTES.HREF, value);
  }
}
