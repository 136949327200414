import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {ObjectRemoveErrorModalService} from '../../../services/modals/object-remove-error/object-remove-error-modal.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';

import {Button} from '../../../../common/models/button/button.model';
import {ObjectRemoveErrorDto} from '../../../../core/models/errors/objects/remove/object-remove-error.dto';
import {ObjectRemoveErrorModel} from '../../../../core/models/errors/objects/remove/object-remove-error.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';

import {AppAnimations} from '../../../../app-animations';

import {OBJECT_NAMES_MAPPING} from './constants';

@Component({
  selector: 'app-object-remove-error-modal',
  templateUrl: './object-remove-error-modal.component.html',
  styleUrls: ['./object-remove-error-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ObjectRemoveErrorModalComponent implements OnInit, OnDestroy {
  public errors: { [key: string]: ObjectRemoveErrorModel[] } = {};
  public errorsKeys: string[] = [];

  public mappedTemplates: { [key: string]: string } = {};

  public key: string;
  public objectName: string;

  public isCurrentPageError: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.onModalClose.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(private modalsService: ModalsService,
              private templatesService: TemplatesService,
              private service: ObjectRemoveErrorModalService) {
  }

  public ngOnInit(): void {
    this.templatesService.templatesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((templates: TemplateModel[]) => {
      if (!templates) return;

      templates.forEach((template: TemplateModel) => {
        this.mappedTemplates[template.id] = template.name;
      });
    });
  }

  public onOpen(err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } }): void {
    if (!err) return;

    this.isCurrentPageError = err.key === 'CURRENT_PAGE_ERROR';

    this.key = err.data.key;
    this.objectName = OBJECT_NAMES_MAPPING[this.key];

    if (!err.data || !err.data.usedObjectsData) return;

    this.errors = {};

    Object.keys(err.data.usedObjectsData).forEach((key: string) => {
      this.errors[key] = [];

      err.data.usedObjectsData[key].forEach((dto: ObjectRemoveErrorDto) => {
        this.errors[key].push(ObjectRemoveErrorDto.normalize(dto));
      });
    });
    
    this.errorsKeys = Object.keys(this.errors);
  }

  private onModalClose(): void {
    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
