import { trigger, state, style, transition, animate } from '@angular/animations';

export class AppAnimations {
  static fadeIn(timeMs = 250, name = 'fadeIn') {
    return [
      trigger(name, [
        state('void', style({
          opacity: 0
        })),
        state('*', style({
          opacity: 1
        })),
        transition(':enter', animate(`${timeMs}ms ease-in-out`)),
        transition(':leave', animate(`${timeMs}ms ease-out`))
      ])
    ];
  };
}
