export const PRODUCT_KEYS = {
  RECURRING: 'RECURRING',
  ONE_TIME: 'ONE_TIME',
};

export const PRODUCT_TYPES = {
  PLAN: 'PLAN',
  ADD_ON: 'ADD_ON',
  INCLUDED_ADD_ON: 'INCLUDED_ADD_ON',
};
