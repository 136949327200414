export const KEYS = {
  OVERLAY: 'OVERLAY',
  RIGHT_SIDE: 'RIGHT_SIDE',
};

export const CLASSES = {
  [KEYS.OVERLAY]: 'image-label-styles-overlay',
  [KEYS.RIGHT_SIDE]: 'image-label-styles-caption',
};

export const CLASSES_LIST = Object.keys(CLASSES).map(key => CLASSES[key]);

export const CLASSES_KEYS = Object.keys(CLASSES).reduce((res, key) => {
  res[CLASSES[key]] = key;
  return res;
}, {});

export const OPTIONS = [
  {
    key: KEYS.OVERLAY,
    text: 'overlay',
  },
  {
    key: KEYS.RIGHT_SIDE,
    text: 'right side',
  },
];

export const DEFAULT_CLASS_NAME = CLASSES[KEYS.OVERLAY];
export const DEFAULT_OPTION = OPTIONS[0];
export const RIGHT_SIDE_OPTION = OPTIONS[1];
