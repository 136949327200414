import {Component, OnDestroy} from '@angular/core';

import {Subject, catchError, finalize, throwError} from 'rxjs';

import {ModalsService} from '../../../services/modals/modals.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {TrialEndSurveyModalService} from '../../../services/modals/trial-end-survey/trial-end-survey-modal.service';
import {ExitSurveyResultsHttpService} from '../../../../core/services/interaction/http/exit-survey-results/exit-survey-results-http.service';

import {SurveySelectableItemModel} from '../../../models/survey/survey-selectable-item.model';

import {STEPS} from '../../../services/modals/trial-end-survey/constants';

@Component({
  selector: 'app-trial-end-survey-modal',
  templateUrl: './trial-end-survey-modal.component.html',
  styleUrls: ['./trial-end-survey-modal.component.scss']
})
export class TrialEndSurveyModalComponent implements OnDestroy {
  public surveyItems: SurveySelectableItemModel[] = [
    new SurveySelectableItemModel(null, 'Too Difficult to Use'),
    new SurveySelectableItemModel(null, 'Missing Features I Need'),
    new SurveySelectableItemModel( null, 'Plans Are Too Expensive'),
    new SurveySelectableItemModel( null, 'Other'),
  ];

  public step: string = STEPS.FIRST;

  public isBlocked: boolean = false;
  public isSurveyCompleted: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  public get isFirstStep(): boolean {
    return this.step === STEPS.FIRST;
  }

  public get isSecondStep(): boolean {
    return this.step === STEPS.SECOND;
  }

  public get isThirdStep(): boolean {
    return this.step === STEPS.THIRD;
  }

  public get isTrialExtensionConfirmationStep(): boolean {
    return this.step === STEPS.TRIAL_EXTENSION_CONFIRMATION;
  }

  constructor(private modalsService: ModalsService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private service: TrialEndSurveyModalService,
              private httpService: ExitSurveyResultsHttpService) {
  }

  public onModalOpen({ isExists, key }: { isExists: boolean, key: string }): void {
    this.surveyItems.forEach((item: SurveySelectableItemModel) => {
      item.key = key;
    });

    this.step = STEPS.FIRST;

    this.isBlocked = false;
    this.isSurveyCompleted = isExists;
  }

  public onSurveyItemClick(item: SurveySelectableItemModel): void {
    item.isSelected = !item.isSelected;
  }

  public switchToSecondStep(): void {
    this.step = STEPS.SECOND;
  }

  public onSurveySubmit(): void {
    if (this.isBlocked) return;

    const items: SurveySelectableItemModel[] = this.surveyItems.filter((item: SurveySelectableItemModel) => item.isSelected);

    this.isBlocked = true;

    this.httpService.saveSurvey(items).pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.isBlocked = false;
      }),
    ).subscribe(() => {
      this.step = STEPS.THIRD;

      window.setTimeout(() => {
        this.service.close();
      }, 4000);
    });
  }

  public submitHandler(): void {
    this.modalsService.close(this.id);
  }

  public extendTrial(): void {
    this.isBlocked = true;

    this.paymentSubscriptionsService.extendTrial().pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.isBlocked = false;
      }),
    ).subscribe(() => {
      this.step = STEPS.TRIAL_EXTENSION_CONFIRMATION;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
