export const ATTRIBUTES = {
  RANDOMIZED: 'data-randomized',
  SRC: 'src',
};

export const KEYS = {
  RANDOMIZE_ALL_PORTFOLIOS: 'RANDOMIZE_ALL_PORTFOLIOS',
  RANDOM: 'RANDOM',
  SINGLE_FROM_PORTFOLIO: 'SINGLE_FROM_PORTFOLIO',
};

export const SELECTORS = {
  [KEYS.RANDOMIZE_ALL_PORTFOLIOS]: `[${ATTRIBUTES.RANDOMIZED}="${KEYS.RANDOMIZE_ALL_PORTFOLIOS}"]`,
  [KEYS.RANDOM]: `[${ATTRIBUTES.RANDOMIZED}="${KEYS.RANDOM}"]`,
  [KEYS.SINGLE_FROM_PORTFOLIO]: `[${ATTRIBUTES.RANDOMIZED}="${KEYS.SINGLE_FROM_PORTFOLIO}"]`,
};
