import {DocumentDto} from './document.dto';
import {DocumentUploadUrlsModel} from './document-upload-urls.model';

export class DocumentUploadUrlsDto {
  constructor(public documentUrl?: string,
              public thumbUrl?: string,
              public document?: DocumentDto) {}

  public static normalize(res: DocumentUploadUrlsDto): DocumentUploadUrlsModel {
    if (!res) return null;

    return new DocumentUploadUrlsModel(
      res.documentUrl,
      res.thumbUrl,
      DocumentDto.normalize(res.document),
    );
  }
}
