// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep app-pages-rearrangement-warning-modal,
:host ::ng-deep app-delete-node-modal,
:host ::ng-deep app-save-options-modal,
:host ::ng-deep app-blog-setup-modal,
:host ::ng-deep app-contact-block-setup-modal,
:host ::ng-deep app-block-template-changelog-modal,
:host ::ng-deep app-set-image-modal,
:host ::ng-deep app-image-manager-modal,
:host ::ng-deep app-fonts-page-modal,
:host ::ng-deep app-message-modal,
:host ::ng-deep app-private-page-password-input-modal,
:host ::ng-deep app-link-input-modal,
:host ::ng-deep app-portfolio-picker-modal,
:host ::ng-deep app-remove-homepage-error-modal,
:host ::ng-deep app-page-lock-alert-modal,
:host ::ng-deep app-original-home-page-setup-modal,
:host ::ng-deep app-custom-home-page-setup-modal,
:host ::ng-deep app-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/modal-containers/page-editor-modal-container/page-editor-modal-container.component.scss"],"names":[],"mappings":"AACE;;;;;;;;;;;;;;;;;;EAkBE,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AAAJ","sourcesContent":[":host ::ng-deep {\n  app-pages-rearrangement-warning-modal,\n  app-delete-node-modal,\n  app-save-options-modal,\n  app-blog-setup-modal,\n  app-contact-block-setup-modal,\n  app-block-template-changelog-modal,\n  app-set-image-modal,\n  app-image-manager-modal,\n  app-fonts-page-modal,\n  app-message-modal,\n  app-private-page-password-input-modal,\n  app-link-input-modal,\n  app-portfolio-picker-modal,\n  app-remove-homepage-error-modal,\n  app-page-lock-alert-modal,\n  app-original-home-page-setup-modal,\n  app-custom-home-page-setup-modal,\n  app-modal {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 5000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
