import {Component, EventEmitter, Input, Output} from '@angular/core';
@Component({
  selector: 'app-collapsible-section',
  templateUrl: './collapsible-section.component.html',
  styleUrls: ['./collapsible-section.component.scss'],
})
export class CollapsibleSectionComponent {
  @Input() collapsed: boolean = true;
  @Input() hasBottomPadding: boolean = true;
  @Input() disabled: boolean = false;
  @Input() title: string = '';
  @Input() openedSection: string = '';
  @Input() openedTitle: string = '';
  @Input() level: number = 1;
  @Input() icon: string = '';

  // Help tooltip component params
  @Input() tooltipText: string = '';

  // Exclamation icon component params
  @Input() warningIsActive: boolean = false;
  @Input() warningTitle: string;
  @Input() warningTooltipText: string;

  @Output() toggleHandler = new EventEmitter();

  public get paddingLeft() {
    return 15 * (this.level - 1);
  }

  public get headerSection(): string {
    return this.collapsed ? '' : this.openedSection || '';
  }

  public get headerTitle(): string {
    return this.collapsed ? this.title : this.openedTitle || this.title;
  }

  public toggleHandle() {
    this.toggleHandler.emit(!this.collapsed);
  }
}
