
import {filter} from 'rxjs/operators';
import {Component} from '@angular/core';

import {ModalsService} from '../../../services/modals/modals.service';
import {PurchaseValidationModalService} from '../../../services/modals/purchase-validation/purchase-validation-modal.service';
import {AuthService} from '../../../../auth/auth.service';

import {Button} from '../../../../common/models/button/button.model';
import {PurchaseValidationModel} from '../../../../core/models/validation/purchase/purchase-validation.model';
import {PlanModel} from '../../../../core/models/plan/plan.model';
import {AccountSummaryModel} from '../../../../core/models/accounts/summary/account-summary.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-purchase-validation-modal',
  templateUrl: './purchase-validation-modal.component.html',
  styleUrls: ['./purchase-validation-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PurchaseValidationModalComponent {
  public header: ModalHeader = {
    text: 'Error',
    className: 'error-header',
  };

  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral',
      onClick: this.onModalClose.bind(this),
    },
  ];

  private plan: PlanModel;
  private validation: PurchaseValidationModel;
  private accountSummary: AccountSummaryModel;

  public get id(): string {
    return this.purchaseValidationModalService.id;
  }

  public get errorsList(): string[] {
    return this.validation && this.plan && this.accountSummary ? this.validation.getErrorsList(this.plan, this.accountSummary) : [];
  }

  constructor(private modalsService: ModalsService,
              private authService: AuthService,
              private purchaseValidationModalService: PurchaseValidationModalService) {
    this.authService.accountSummarySubject.pipe(filter((accountSummary: AccountSummaryModel) => !!accountSummary)).subscribe((accountSummary: AccountSummaryModel) => {
      this.accountSummary = accountSummary;
    });
  }

  public onOpen(data: { plan: PlanModel, validation: PurchaseValidationModel }): void {
    this.plan = data.plan;
    this.validation = data.validation;
  }

  public onModalClose(): void {
    this.modalsService.close(this.id);
  }
}
