export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `ขอบหยักของปีกแลดูเด่นชัดกลางแสงเรืองรองสีแดง`,
      `ธรรมชาติทั้งสองของฉันมีความทรงจำร่วมกัน`,
      `หมอกสีเงินฟุ้งกระจายไปทั่วดาดฟ้าเรือ`,
    ],
    [KEYS.ALPHABET]: [
      'กขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮะาเใไโฤฤๅๆฯฯลฯ๏๚๛฿',
    ],
    [KEYS.PARAGRAPH]: [
      `ฉันจะได้เห็นโฉมหน้าของดาวอังคาร อย่างไรก็ตาม นั่นคงเป็นประสบการณ์ที่ไม่ได้เกิดขึ้นบ่อยนัก ภาพวัตถุเหมือนฝันที่ฉันเห็นผ่านกล้องดูดาวคุณภาพเยี่ยม เหล่านี้ และการได้เที่ยวรอบโลก ควรเป็นส่วนหนึ่งของเสรีภาพทางการศึกษา`
    ],
    [KEYS.NUMERALS]: [
      '๐๑๒๓๔๕๖๗๘๙',
    ],
  };
};
