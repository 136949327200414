import {IStyleOption} from '../option/i-style-option';

import {DEFAULT_VALUE, OPACITY_PROPERTY} from './constants';

export class ElementBackgroundTransparencyModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const propertyValue: number = Number.parseFloat(computedStyles.getPropertyValue(OPACITY_PROPERTY)) * 100;
    const value: number = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;

    if (value) return this.setValue(Number.isNaN(value) ? void 0 : value.toString());

    this.value = DEFAULT_VALUE;
  }

  public reset() {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(OPACITY_PROPERTY);

    this.value = DEFAULT_VALUE;
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(OPACITY_PROPERTY, (1 - Number.parseFloat(value) / 100).toString());
  }
}
