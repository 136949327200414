import {AttributeModel} from '../base/attribute/attribute.model';

import {ATTRIBUTE} from './constants';

export class AudioLoopModel extends AttributeModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, ATTRIBUTE, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    const audioElement = element.querySelector('audio');

    if (!audioElement) return;

    super.init(audioElement);
  }

  public onChange(value: boolean): void {
    if (!this.isEnabled || !this.element) return;

    (<HTMLAudioElement>this.element).loop = value;

    super.onChange(value);
  }
}
