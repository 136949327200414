// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .logout-modal {
  position: fixed;
}

.logout-modal-body .text {
  padding: 20px;
  font-size: 0.9em;
}
.logout-modal-body .buttons-wrapper .dont-save-button {
  margin-left: auto !important;
}
.logout-modal-body .buttons-wrapper .continue-editing-button {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/logout-modal/logout-modal.component.scss"],"names":[],"mappings":"AAIE;EACE,eAAA;AAHJ;;AAQE;EACE,aAAA;EACA,gBAAA;AALJ;AASI;EACE,4BAAA;AAPN;AAUI;EACE,iBAAA;AARN","sourcesContent":["\n@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .logout-modal {\n    position: fixed;\n  }\n}\n\n.logout-modal-body {\n  .text {\n    padding: 20px;\n    font-size: 0.9em;\n  }\n\n  .buttons-wrapper {\n    .dont-save-button {\n      margin-left: auto !important;\n    }\n\n    .continue-editing-button {\n      margin-left: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
