import {Injectable} from '@angular/core';
import {RouterStateSnapshot, ActivatedRouteSnapshot, Router} from '@angular/router';

import {Subscriber, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../auth/auth.service';
import {WebsitesService} from '../../services/websites/websites.service';

import {AccountModel} from '../../models/accounts/account.model';

@Injectable()
export class SetupCompletedGuard  {
  constructor(private router: Router,
              private authService: AuthService,
              private websitesService: WebsitesService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return new Observable<boolean>(observer => {

      this.getUser().subscribe((account: AccountModel) => {
        if (!account) {
          return;
        }
      
        this.websitesService.activeWebsiteSubject.subscribe(website => {
          if (!website || website.id !== account.activeWebsiteId) {
            return;
          }

          observer.next(website.isSetupCompleted);
        });
      });
    });
  }

  private getUser(): Observable<AccountModel> {
    if (this.authService.isFetched) return this.authService.accountSubject;

    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    return new Observable((observer: Subscriber<AccountModel>) => {
      this.authService.accountSubject.pipe(takeUntil(unsubscribe)).subscribe((account: AccountModel) => {
        if (!this.authService.isFetched) return;

        unsubscribe.next(true);
        unsubscribe.complete();

        observer.next(account);
        observer.complete();
      });
    });
  }
}
