import {StyleOptionModel} from '../settings/option/style-option.model';
import {INativeStyleOption} from './option/i-native-style-option';
import {FontFamilyModel} from './font-family/font-family.model';
import {FontWeightModel} from './font-weight/font-weight.model';
import {FontSizeModel} from './font-size/font-size.model';
import {FontStyleModel} from './font-style/font-style.model';
import {TextTransformModel} from './text-transform/text-transform.model';
import {TextAlignmentModel} from './text-alignment/text-alignment.model';
import {TextDecorationModel} from './text-decoration/text-decoration.model';
import {TransparencyModel} from './transparency/transparency.model';

import {KEYS} from '../../../services/styles/native/constants';

export class NativeSetupModel {
  public element: HTMLElement;
  private enabledStyleOptions: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public fontFamily: FontFamilyModel = new FontFamilyModel(KEYS.FONT_FAMILY);
  public fontWeight: FontWeightModel = new FontWeightModel(KEYS.FONT_WEIGHT);
  public fontSize: FontSizeModel = new FontSizeModel(KEYS.FONT_SIZE);
  public fontStyle: FontStyleModel = new FontStyleModel(KEYS.FONT_STYLE);
  public textTransform: TextTransformModel = new TextTransformModel(KEYS.TEXT_TRANSFORM);
  public textAlignment: TextAlignmentModel = new TextAlignmentModel(KEYS.TEXT_ALIGNMENT);
  public textDecoration: TextDecorationModel = new TextDecorationModel(KEYS.TEXT_DECORATION);
  public transparency: TransparencyModel = new TransparencyModel(KEYS.TRANSPARENCY);

  public options: INativeStyleOption[] = [
    this.fontFamily,
    this.fontWeight,
    this.fontSize,
    this.fontStyle,
    this.textTransform,
    this.textAlignment,
    this.textDecoration,
    this.transparency,
  ];

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.element = element;
    this.enabledStyleOptions = enabledStyleOptions;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(element);

    this.options.forEach(option => {
      option.isEnabled = this.enabledStyleOptions.has(option.key);

      if (!option.init) return;

      try {
        option.init(element, {
          innerWrapper,
          block,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.classList.contains('block') ? element : <HTMLElement>element.closest('.block');
  }
}
