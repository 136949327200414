import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';

import {WebsiteFontsDataModel} from '../../../../core/models/google/fonts/website-fonts-data.model';
import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';

@Component({
  selector: 'app-admin-default-fonts',
  templateUrl: './default-fonts.component.html',
  styleUrls: ['./default-fonts.component.scss']
})
export class AdminDefaultFontsComponent implements OnDestroy {
  public defaultFonts: GoogleFontModel[] = [];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private googleFontsService: GoogleFontsService) {
    this.googleFontsService.websiteDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websiteFontData: WebsiteFontsDataModel) => {
      this.defaultFonts = websiteFontData ? websiteFontData.defaultFonts : [];
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onAddFontClick(): void {
    this.googleFontsService.openModal(true);
  }
}
