import { IArtworkDimensionsNumber } from "../../artwork-dimensions/i-artwork-dimensions-number";

export class PortfolioDefaultsModel implements IArtworkDimensionsNumber {
  public priceFormatted?: number;

  constructor(public portfolioId?: number,
              public title?: string,
              public caption?: string,
              public printProcess?: string,
              public otherPrintProcess?: string,
              public copyright?: string,
              public purchaseInfo?: string,
              public bucket?: string,

              public isEditioned?: boolean,
              public editionSize?: string,
              public printNumber?: string,

              public width?: number,
              public height?: number,
              public length?: number,
              public matWidth?: number,
              public matHeight?: number,

              public price?: number,
              public pricingStructure?: string,
              public inquiryLinkText?: string,
              public purchaseText?: string,
              public availability?: number,
              public isInquireLinkVisible?: boolean,
              public isPurchaseButtonVisible?: boolean,

              public year?: string,
              public processTypeId?: number) {
    this.priceFormatted = this.price ? this.price / 100 : 0;
  }
}
