export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `Ήταν απλώς θέμα χρόνου.`,
      `Όλος ο εξοπλισμός και τα όργανα είναι ζωντανά με κάποιον τρόπο.`,
      `Η Γη έλαμπε σαν ημισέληνος κάτω από το ιπτάμενο σκάφος.`,
    ],
    [KEYS.ALPHABET]: [
      'ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψωάΆέΈέΉίϊΐΊόΌύΰϋΎΫὰάὲέὴήὶίὸόὺύὼώΏ ‘?’“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `Κάτι που έμοιαζε με μικρή επιφάνεια από μωβ γρασίδι, πάνω από πέντε τετραγωνικά μέτρα, κατευθυνόταν προς το μέρος τους κατά μήκος της άμμου. Όταν τους πλησίασε αρκετά, κατάλαβαν ότι δεν ήταν γρασίδι. Δεν υπήρχε καθόλου χορτάρι, παρά μόνο μωβ ρίζες. Οι ρίζες έκαναν περιστροφή, για κάθε μικρό φυτό σε ολόκληρη την επιφάνεια, σαν ακτίνες ενός τροχού χωρίς σκελετό.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
