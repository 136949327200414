// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
}
:host .fullscreen-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/common/fullscreen-wrapper/fullscreen-wrapper.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[":host {\n  width: 100%;\n  height: 100%;\n\n  .fullscreen-wrapper {\n    position: relative;\n    width: 100%;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
