import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ReviewsListModalService} from '../../../../services/modals/education/reviews-list/reviews-list-modal.service';
import {ImageManagerService} from '../../../../../application/main/image-manager/image-manager.service';

import {StudentImageReviewModel} from '../../../../../core/models/images/review/student-image-review.model';

@Component({
  selector: 'app-reviews-list-modal',
  templateUrl: './reviews-list-modal.component.html',
  styleUrls: ['./reviews-list-modal.component.scss']
})
export class EducationReviewsListModalComponent implements OnInit, OnDestroy {
  @Input() id = 'reviews-list-modal';

  public reviews: StudentImageReviewModel[] = null;

  public header = {
    className: 'warning-header',
  };
  
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: ReviewsListModalService,
    private imageManagerService: ImageManagerService,
  ) {
  }

  public ngOnInit(): void {
    this.service.reviewsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((reviews: StudentImageReviewModel[]) => {
      this.reviews = reviews;
    });
  }

  public onOpen(): void {
    
  }

  public openUpdateSuggestions(review: StudentImageReviewModel): void {
    this.imageManagerService.viewSubject.next({
      key: 'full-view',
      forceIndex: this.service.imageIndex,
      forceSuggestionsView: {
        userId: review.reviewedByUserId,
      },
    });

    this.onClose();
  }

  public onClose(): void {
    this.service.close();
  }

  public ngOnDestroy(): void {
    this.reviews = null;

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}

