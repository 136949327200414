import * as moment from 'moment';

import {AccountModel} from '../account.model';

export class SupportMetaItemModel {
  public totalTimeMin: number;

  public isEditing: boolean = false;

  public isSupportDateValid: boolean = true;
  public isSummaryValid: boolean = true;
  public isMethodValid: boolean = true;

  public get isValid(): boolean {
    return this.isSupportDateValid && this.isSummaryValid && this.isMethodValid;
  }

  constructor(public id?: number,
              public adminId?: number,
              public userId?: number,
              public method?: string,
              public summary?: string,
              public notes?: string,
              public planTimeMin?: number,
              public addOnTimeMin?: number,
              public status?: string,
              public admin?: AccountModel,
              public supportedAt?: string,
              public createdAt?: string,
              public updatedAt?: string) {
    this.totalTimeMin = (this.planTimeMin || 0) + (this.addOnTimeMin || 0);
  }

  public validate(): void {
    this.isSupportDateValid = !!this.supportedAt && moment(this.supportedAt).isValid();
    this.isSummaryValid = !!this.summary && !!this.method && this.method.length > 0;
    this.isMethodValid = !!this.method;
  }

  public static clone(item: SupportMetaItemModel): SupportMetaItemModel {
    return new SupportMetaItemModel(
      item.id,
      item.adminId,
      item.userId,
      item.method,
      item.summary,
      item.notes,
      item.planTimeMin,
      item.addOnTimeMin,
      item.status,
      AccountModel.clone(item.admin),
      item.supportedAt,
      item.createdAt,
      item.updatedAt,
    );
  }
}
