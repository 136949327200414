import {Component, Input, Output, EventEmitter} from '@angular/core';

import {ModalsService} from "../../../services/modals/modals.service";

import {Button} from "../../../../common/models/button/button.model";

import {AppAnimations} from "../../../../app-animations";

@Component({
  selector: 'app-upgrade-modal',
  templateUrl: './upgrade-modal.component.html',
  styleUrls: ['./upgrade-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class UpgradeModalComponent {
  id = "upgrade-modal";
  buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral',
      onClick: this.onOkClick.bind(this),
    },
    {
      text: 'Cancel',
      className: 'neutral',
      onClick: this.onCancelClick.bind(this),
    },
  ];

  @Input() upgradeInfo: any;

  @Output() okHanlder = new EventEmitter();
  @Output() cancelHandler = new EventEmitter();

  get question() {
    if (!this.upgradeInfo) return '';
    return `Do you want to upgrade your plan from ${this.upgradeInfo.from.name} to ${this.upgradeInfo.to.name}?`;
  }

  constructor(private modalsService: ModalsService) {}

  getMoneyInfo() {
    if (!this.upgradeInfo) return;
    if (this.upgradeInfo.cost >= 0) return `You must pay $${this.upgradeInfo.cost / 100}`;
    return `You will get back $${Math.abs(this.upgradeInfo.cost) / 100} to your credit card.`;
  }

  onOkClick() {
    this.okHanlder.emit(this.upgradeInfo);

    this.modalsService.close(this.id);
  }

  onCancelClick() {
    this.cancelHandler.emit();

    this.modalsService.close(this.id);
  }
}
