// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-specifications-modal .modal-content-wrapper {
  margin: 20px;
  clear: both;
  overflow: hidden;
  text-align: left;
}
.image-specifications-modal .modal-content-wrapper .image-specifications {
  margin-bottom: 20px;
}
.image-specifications-modal .modal-content-wrapper .image-specifications .image-size {
  text-indent: 20px;
}
.image-specifications-modal .modal-content-wrapper .image-specifications-description {
  margin-bottom: 10px;
}
.image-specifications-modal .modal-content-wrapper .actions {
  display: flex;
  justify-content: center;
  float: none;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/image-specifications-modal/image-specifications-modal.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAEM;EACE,iBAAA;AAAR;AAII;EACE,mBAAA;AAFN;AAKI;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EACA,gBAAA;AAHN","sourcesContent":[".image-specifications-modal {\n  .modal-content-wrapper {\n    margin: 20px;\n    clear: both;\n    overflow: hidden;\n    text-align: left;\n\n    .image-specifications {\n      margin-bottom: 20px;\n\n      .image-size {\n        text-indent: 20px;\n      }\n    }\n\n    .image-specifications-description {\n      margin-bottom: 10px;\n    }\n\n    .actions {\n      display: flex;\n      justify-content: center;\n      float: none;\n      margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
