import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as $ from 'jquery';

import {DetailsService} from '../details.service';
import {WebsiteDesignerService} from '../../website-designer/website-designer.service';
import {TextAlertModalService} from '../../../../services/text-alert-modal.service';
import {StylesDimensionsService} from '../../../../core/services/styles/dimensions/styles-dimensions.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {IFrameRoutingService} from '../../../../core/services/iframe/routing/iframe-routing.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameMenuService} from '../../../../core/services/iframe/menu/menu.service';
import {SidebarSectionsService} from '../../../../services/sidebar-sections.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {StylesSettingsService} from '../../../../core/services/styles/settings/styles-settings.service';
import {CustomMenuStylesService} from '../../../../core/services/styles/custom-menu/custom-menu-styles.service';
import {CustomMobileMenuStylesService} from '../../../../core/services/styles/custom-mobile-menu/custom-mobile-menu-styles.service';
import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {IFrameHoverStylesService} from '../../../../core/services/iframe/styling/hover/hover-styles.service';
import {IFrameActiveStylesService} from '../../../../core/services/iframe/styling/active/active-styles.service';
import {IFrameMobileActiveStylesService} from '../../../../core/services/iframe/styling/mobile-active/mobile-active-styles.service';
import {ColorIntersectionService} from '../../../../core/services/styles/color/intersection/color-intersection.service';
import {EditorDevicesService} from '../../../../core/services/editor-devices/editor-devices.service';
import {SelectedBlocksService} from '../../../../services/selected-blocks.service';
import {NativeStylesService} from '../../../../core/services/styles/native/native-styles.service';
import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';
import {AttributesService} from '../../../../core/services/attributes/attributes.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {WebsiteStylesService} from '../../../../core/services/styles/website/website-styles.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';
import {PagesService} from '../../../sidebar-short/sidebar/pages/pages.service';
import {TitleStylesService} from '../../../../core/services/styles/title/title-styles.service';
import {MobileTitleStylesService} from '../../../../core/services/styles/title/mobile/mobile-title-styles.service';
import {SubtitleStylesService} from '../../../../core/services/styles/subtitle/subtitle-styles.service';
import {MobileSubtitleStylesService} from '../../../../core/services/styles/subtitle/mobile/mobile-subtitle-styles.service';
import {NavigationService} from '../../../../services/navigation.service';

import {CustomizerSections, StylePanel, WebsiteStyles, WebsiteStylesMap} from '../style-panel.model';
import {SelectOption} from '../../../../core/models/select/option/option.model';
import {MenuSetupModel} from '../../../../core/models/styles/setup/menu/desktop/menu-setup.model';
import {StyleOptionModel} from '../../../../core/models/styles/settings/option/style-option.model';
import {MobileMenuSetupModel} from '../../../../core/models/styles/setup/menu/mobile/mobile-menu-setup.model';
import {ColorIntersectionModel} from '../../../../core/models/styles/color/intersection/color-intersection.model';
import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';
import {NativeSetupModel} from '../../../../core/models/styles/native-setup/native-setup.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';
import {WebsiteSetupModel} from '../../../../core/models/styles/website-setup/website-setup.model';
import {TitleSetupModel} from '../../../../core/models/styles/setup/website-title/title-setup.model';
import {MobileTitleSetupModel} from '../../../../core/models/styles/setup/website-title/mobile/mobile-title-setup.model';
import {SubtitleSetupModel} from '../../../../core/models/styles/setup/website-subtitle/subtitle-setup.model';
import {MobileSubtitleSetupModel} from '../../../../core/models/styles/setup/website-subtitle/mobile/mobile-subtitle-setup.model';
import {IStyleOption} from '../../../../core/models/styles/setup/option/i-style-option';
import {SelectedPageModel} from '../../../../core/models/selected-page/selected-page.model';
import {NodeModel} from '../../../../core/models/nodes/node.model';

import {KEYS as MENU_STYLE_KEYS, OPTIONS as MENU_STYLE_OPTIONS} from '../../../../core/models/styles/setup/menu/style/constants';
import {KEYS as COLLAPSE_STYLE_KEYS, OPTIONS as COLLAPSE_STYLE_OPTIONS} from '../../../../core/models/styles/setup/menu/collapse-options/constants';
import {KEYS as COLOR_KEYS} from '../../../../core/models/styles/color/intersection/constants';
import {
  DESKTOP_OPTIONS as DESKTOP_MENU_DROPDOWN_ARROW_OPTIONS,
  HORIZONTAL_OPTIONS as HORIZONTAL_MENU_DROPDOWN_ARROW_OPTIONS,
  KEYS as MENU_DROPDOWN_ARROW_KEYS,
  MOBILE_OPTIONS as MOBILE_MENU_DROPDOWN_ARROW_OPTIONS
} from '../../../../core/models/styles/setup/menu/dropdown-arrow/constants';
import {
  KEYS as MOBILE_MENU_POSITION_KEYS,
  OPTIONS as MOBILE_MENU_POSITION_OPTIONS
} from '../../../../core/models/styles/setup/menu/position/constants';
import {OPTIONS as MOBILE_TITLE_LOGO_POSITION_OPTIONS} from '../../../../core/models/styles/setup/website-title/logo-position/constants';
import {Sections} from '../../../sidebar-short/constants';
import {FONT_SIZES, SUBTITLE_FONT_SIZES} from '../../../../core/models/styles/native-setup/font-size/constants';
import {TEXT_TRANSFORMATIONS} from '../../../../core/models/styles/native-setup/text-transform/constants';
import {TEXT_ALIGNMENTS} from '../../../../core/models/styles/native-setup/text-alignment/constants';
import {TEXT_DECORATIONS} from '../../../../core/models/styles/native-setup/text-decoration/constants';
import {OPTIONS as MENU_DROPDOWN_TEXT_ALIGNMENT_OPTIONS} from '../../../../core/models/styles/setup/menu/dropdown-text-alignment/constants';
import {OPTIONS as MENU_PADDING_OPTIONS} from '../../../../core/models/styles/setup/menu/padding/constants';
import { ChangesMonitorService } from '../../../../shared/services/changes-monitor/changes-monitor.service';
import { CHANGE_TYPE } from '../../../../shared/services/changes-monitor/constants';
import { RevertBlockToDefaultModalService } from '../../../../shared/services/modals/revert-block-to-default/revert-block-to-default-modal.service';
import { PageEditorService } from '../../website-designer/page-editor/page-editor.service';

@Component({
  selector: 'app-website-details',
  templateUrl: './website-details.component.html',
  styleUrls: ['./website-details.component.scss']
})
export class WebsiteDetailsComponent implements OnInit, OnDestroy {
  private website: WebsiteModel;

  private element: HTMLElement;

  public colorIntersectionData: ColorIntersectionModel;

  public desktopMenuSectionTitle: string = 'DESKTOP MENU';

  public template: TemplateModel;

  public isMenuHasPageLinks: boolean = false;
  public isBothMenus: boolean = false;
  public isHorizontalMenuElement: boolean = false;
  public isVerticalMenuElement: boolean = false;

  stylePanel = StylePanel;

  @Input() set areAllSectionsClosed(value) {
    if (value) {
      this.selectedSection = null;

      this.initSectionOptions();
    }
  }

  resetModalHeader = 'Reset to Factory Defaults';
  resetModalText = 'Do you want to reset all website style settings to factory defaults? Careful! You will lose ALL of your style changes!';

  selectedSection: CustomizerSections = CustomizerSections.General;

  private isDestroyed: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  get customizerSections() {
    return CustomizerSections;
  }

  public optionsMap: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public static ELEMENTS_KEYS = {
    [CustomizerSections.General]: 'WEBSITE',
    [CustomizerSections.Title]: 'WEBSITE_TITLE',
    [CustomizerSections.MobileTitle]: 'MOBILE_WEBSITE_TITLE',
    [CustomizerSections.Subtitle]: 'WEBSITE_SUBTITLE',
    [CustomizerSections.MobileSubtitle]: 'MOBILE_WEBSITE_SUBTITLE',
    [CustomizerSections.Pages]: 'MENU_LIST',
    [CustomizerSections.Mobile]: 'MOBILE_MENU_LIST',
    [CustomizerSections.Media]: 'SOCIAL_BLOCK',
    [CustomizerSections.MobileMedia]: 'MOBILE_SOCIAL_BLOCK',
  };

  private editingTypes = {
    [CustomizerSections.General]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.General],
      handle: () => this.parseWebsiteDefaultStyles(),
    },
    [CustomizerSections.Title]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.Title],
      handle: () => this.parseMenuElement(CustomizerSections.Title),
    },
    [CustomizerSections.MobileTitle]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.MobileTitle],
      handle: () => this.parseMenuElement(CustomizerSections.MobileTitle),
    },
    [CustomizerSections.Subtitle]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.Subtitle],
      handle: () => this.parseMenuElement(CustomizerSections.Subtitle),
    },
    [CustomizerSections.MobileSubtitle]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.MobileSubtitle],
      handle: () => this.parseMenuElement(CustomizerSections.MobileSubtitle),
    },
    [CustomizerSections.Pages]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.Pages],
      handle: () => this.parseMenuElement(CustomizerSections.Pages),
    },
    [CustomizerSections.Mobile]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.Mobile],
      handle: () => this.parseMenuElement(CustomizerSections.Mobile),
    },
    [CustomizerSections.Media]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.Media],
      handle: () => this.parseMenuElement(CustomizerSections.Media),
    },
    [CustomizerSections.MobileMedia]: {
      key: WebsiteDetailsComponent.ELEMENTS_KEYS[CustomizerSections.MobileMedia],
      handle: () => this.parseMenuElement(CustomizerSections.MobileMedia),
    },
  };

  resetStyleSpecificHandlers = {
    [CustomizerSections.Pages]: {
      'hover-design': () => {
        this.hoverStylesService.resetStyles();
      },
      'active-link': () => {
        this.activeStylesService.resetStyles();
      },
      'vertical-menu-style': () => {
        this.onMenuStyleChange(MENU_STYLE_KEYS.EXPANDED_INDENTED);
      },
      'dropdown-arrow': () => {
        this.onMenuDropdownArrowChange(MENU_DROPDOWN_ARROW_KEYS.LEFT);
      },
    },
    [CustomizerSections.Mobile]: {
      'active-link': () => {
        this.iFrameMenuService.resetMobileActiveOptions();
      },
      'vertical-menu-style': () => {
        this.onMobileMenuStyleChange(MENU_STYLE_KEYS.EXPANDED_INDENTED);
      },
      'dropdown-arrow': () => {
        this.onMobileMenuDropdownArrowChange(MENU_DROPDOWN_ARROW_KEYS.LEFT);
      },
      'mobile-menu-position': () => {
        this.onMobileMenuPositionChange(MOBILE_MENU_POSITION_KEYS.SIDE);
      },
    },
  };

  private verifySiteBackgroundColorTimeoutId;

  onWebsiteMobileTitleStyleChange: (styleName: string, styleValue: string) => any = this.onStyleChange.bind(this, CustomizerSections.MobileTitle);
  onMobileSubtitleStyleChange: (styleName: string, styleValue: string) => any = this.onStyleChange.bind(this, CustomizerSections.MobileSubtitle);
  onPageListStyleChange: (styleName: string, styleValue: string) => any = this.onStyleChange.bind(this, CustomizerSections.Pages);
  onMobilePageListStyleChange: (styleName: string, styleValue: string) => any = this.onStyleChange.bind(this, CustomizerSections.Mobile);

  public resetWebsiteLinksStyle: (styleName: string) => any = this.resetWebsiteStyles.bind(this, CustomizerSections.Pages);
  public resetWebsiteMobileTitleStyle: (styleName: string) => any = this.resetWebsiteStyles.bind(this, CustomizerSections.MobileTitle);
  public resetMobileSubtitleStyle: (styleName: string) => any = this.resetWebsiteStyles.bind(this, CustomizerSections.MobileSubtitle);
  public resetWebsiteMobileLinksStyle: (styleName: string) => any = this.resetWebsiteStyles.bind(this, CustomizerSections.Mobile);

  public styleIssuesTooltipText: string = 'Section has style issues';

  public selectedPage: SelectedPageModel;

  public verticalMenuPosition: string;

  public isPortfolioExists: boolean = false;

  public isAnySectionSelected: boolean = false;
  public isGeneralSection: boolean = false;
  public isPagesSection: boolean = false;
  public isMediaSection: boolean = false;
  public isTitleSection: boolean = false;
  public isSubtitleSection: boolean = false;
  public isMobileSection: boolean = false;
  public isMobileTemplatesSection: boolean = false;
  public isMobileTitleSection: boolean = false;
  public isMobileSubtitleSection: boolean = false;
  public isMobileTemplatesPortfoliosSection: boolean = false;
  public isMobileTemplatesEnlargementsSection: boolean = false;
  public isVerticalMenuPositionTop: boolean = false;
  public isVerticalMenuPositionSide: boolean = false;
  public isCustomMenuBlock: boolean = false;

  private editableTypes = [
    'WEBSITE',
    'SOCIAL_BLOCK',
    'MOBILE_SOCIAL_BLOCK',
    'MOBILE_MENU_LIST',
    'MENU_LIST',
    'WEBSITE_TITLE',
    'MOBILE_WEBSITE_TITLE',
    'WEBSITE_SUBTITLE',
    'MOBILE_WEBSITE_SUBTITLE',
  ];

  private device: string;

  private readonly maxTitleLineHeight: number = 100;
  private readonly maxSubtitleLineHeight: number = 30;

  // TODO: refactor
  get backgroundColorWarningTooltipText(): string {
    // return `Background color has small contrast ratio with text color.`;
    return 'Text will be invisible';
  }

  get textColorWarningTooltipText(): string {
    // return `Text color has small contrast ratio with background color.`;
    return 'Text will be invisible';
  }

  get Sections() {
    return Sections;
  }

  public get isSpecialView() {
    return this.isMobileTemplatesEnlargements || this.isMobileTemplatesPortfolios;
  }

  public get isMobileTemplatesEnlargements() {
    return this.selectedSection === this.customizerSections.MobileTemplatesEnlargements;
  }

  public get isMobileTemplatesPortfolios() {
    return this.selectedSection === this.customizerSections.MobileTemplatesPortfolios;
  }

  public get menuStyleOptions() {
    return MENU_STYLE_OPTIONS;
  }

  public get collapseStyleOptions(): SelectOption[] {
    return COLLAPSE_STYLE_OPTIONS;
  }

  public get menuDropdownArrowOptions() {
    return this.isVerticalMenuElement ? DESKTOP_MENU_DROPDOWN_ARROW_OPTIONS : HORIZONTAL_MENU_DROPDOWN_ARROW_OPTIONS;
  }

  public get menuDropdownTextAlignmentOptions() {
    return MENU_DROPDOWN_TEXT_ALIGNMENT_OPTIONS;
  }

  public get mobileMenuDropdownArrowOptions() {
    return MOBILE_MENU_DROPDOWN_ARROW_OPTIONS;
  }

  public get mobileMenuPositionOptions() {
    return MOBILE_MENU_POSITION_OPTIONS;
  }

  public get mobileTitleLogoPositionOptions() {
    return MOBILE_TITLE_LOGO_POSITION_OPTIONS;
  }

  public get nativeSetup(): NativeSetupModel {
    return this.nativeStylesService.setup;
  }

  public get menuSetup(): MenuSetupModel {
    return this.customMenuStylesService.setup;
  }

  public get mobileMenuSetup(): MobileMenuSetupModel {
    return this.customMobileMenuStylesService.setup;
  }

  public get titleSetup(): TitleSetupModel {
    return this.titleStylesService.setup;
  }

  public get subtitleSetup(): SubtitleSetupModel {
    return this.subtitleStylesService.setup;
  }

  public get mobileTitleSetup(): MobileTitleSetupModel {
    return this.mobileTitleStylesService.setup;
  }

  public get mobileSubtitleSetup(): MobileSubtitleSetupModel {
    return this.mobileSubtitleStylesService.setup;
  }

  public get fontWeightsOptions(): SelectOption[] {
    return this.nativeStylesService.fontWeightsOptions;
  }

  public get selectedFontWeightOption(): SelectOption {
    return this.nativeStylesService.fontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.nativeSetup.fontWeight.value;
    });
  }

  public get fontSizes(): number[] {
    return FONT_SIZES;
  }

  public get subtitleFontSizes(): number[] {
    return SUBTITLE_FONT_SIZES;
  }

  public get textTransformations(): string[] {
    return TEXT_TRANSFORMATIONS;
  }

  public get textAlignments(): string[] {
    return TEXT_ALIGNMENTS;
  }

  public get textDecorations(): string[] {
    return TEXT_DECORATIONS;
  }

  public get MENU_DROPDOWN_ARROW_KEYS() {
    return MENU_DROPDOWN_ARROW_KEYS;
  }

  public get MENU_PADDING_OPTIONS() {
    return MENU_PADDING_OPTIONS;
  }

  public get setup(): WebsiteSetupModel {
    return this.websiteStylesService.setup;
  }

  public get COLOR_KEYS() {
    return COLOR_KEYS;
  }

  constructor(public detailsService: DetailsService,
              public stylesDimensionsService: StylesDimensionsService,
              public websiteDesignerService: WebsiteDesignerService,
              public iFrameMenuService: IFrameMenuService,
              public templatesService: TemplatesService,
              public nativeStylesService: NativeStylesService,
              public customMenuStylesService: CustomMenuStylesService,
              public customMobileMenuStylesService: CustomMobileMenuStylesService,
              public buttonsService: ButtonsService,
              public pagesService: PagesService,
              public titleStylesService: TitleStylesService,
              public subtitleStylesService: SubtitleStylesService,
              public mobileTitleStylesService: MobileTitleStylesService,
              public mobileSubtitleStylesService: MobileSubtitleStylesService,
              private websitesService: WebsitesService,
              private websiteStylesService: WebsiteStylesService,
              private nodesService: NodesService,
              private attributesService: AttributesService,
              private googleFontsService: GoogleFontsService,
              private editorDevicesService: EditorDevicesService,
              private editorControlButtonsService: EditorControlButtonsService,
              private activeStylesService: IFrameActiveStylesService,
              private mobileActiveStylesService: IFrameMobileActiveStylesService,
              private hoverStylesService: IFrameHoverStylesService,
              private stylesSettingsService: StylesSettingsService,
              private textAlertModalService: TextAlertModalService,
              private iFrameService: IFrameService,
              private iFrameRoutingService: IFrameRoutingService,
              private eventsService: EventsService,
              private changesMonitorService: ChangesMonitorService,
              private navigationService: NavigationService,
              private sidebarSectionsService: SidebarSectionsService,
              private colorIntersectionService: ColorIntersectionService,
              private selectedBlocksService: SelectedBlocksService,
              private revertBlockToDefaultModalService: RevertBlockToDefaultModalService,
              private pageEditorService: PageEditorService,
              private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.eventsService.addFrameListener('blockAdded', (e: CustomEvent) => {
      try {
        if (e.detail.isDropped && e.detail.block.Block_Category === 'Menus') {
          this.onMenuChange();
        }
      } catch (e) {
        console.error(e);
      }
    });

    this.eventsService.addFrameListener('hamburgerColorChanged', () => {
      this.menuSetup.hamburgerColor.reInit();
    });

    this.eventsService.addFrameListener('verticalMenuPosition', (e: CustomEvent) => {
      if (this.isDestroyed) return;

      this.verticalMenuPosition = e.detail.position;

      this.isVerticalMenuPositionTop = this.verticalMenuPosition === 'TOP';
      this.isVerticalMenuPositionSide = this.verticalMenuPosition === 'SIDE';

      this.cdr.detectChanges();
    });

    this.pageEditorService.isCustomMenuBlock.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCustomMenuBlock: boolean) => {
      this.isCustomMenuBlock = isCustomMenuBlock;
    });

    this.detailsService
      .websiteStylesReload
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.parseWebsiteDefaultStyles.bind(this));

    this.iFrameService.onStylesAppliedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.parseWebsiteDefaultStyles();
    });

    this.editorDevicesService.onDeviceIconChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((deviceKey: string) => {
      this.device = deviceKey;

      this.customMenuStylesService.init({ menu: <HTMLElement>this.sectionsToElementsMapping[this.customizerSections.Pages](), device: this.device });
    });

    this.websiteDesignerService.editingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onElementChange.bind(this));

    this.detailsService.menuReadySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isMenuReady: boolean) => {
      if (!isMenuReady) return;

      this.customMenuStylesService.init({ menu: <HTMLElement>this.sectionsToElementsMapping[this.customizerSections.Pages](), device: this.device });
      this.customMobileMenuStylesService.init(this.sectionsToElementsMapping[this.customizerSections.Mobile]());

      this.titleStylesService.init(this.sectionsToElementsMapping[this.customizerSections.Pages]());
      this.subtitleStylesService.init(this.sectionsToElementsMapping[this.customizerSections.Pages]());

      this.mobileTitleStylesService.init(this.sectionsToElementsMapping[this.customizerSections.Mobile]());
      this.mobileSubtitleStylesService.init(this.sectionsToElementsMapping[this.customizerSections.Mobile]());
    });

    this.websitesService
      .activeWebsiteSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.handleWebsite.bind(this));

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.isPortfolioExists = nodes ? nodes.findIndex((node: NodeModel) => node.type === 'P') !== -1 : false;
    });

    this.selectedBlocksService.isMenuHasPageLinksSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isMenuHasPageLinks => {
      this.isMenuHasPageLinks = isMenuHasPageLinks;
    });

    this.colorIntersectionService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ColorIntersectionModel) => {
      this.colorIntersectionData = data;
    });

    this.stylesSettingsService.optionsMapSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((options: Map<string, StyleOptionModel>) => {
      this.optionsMap = options;

      this.initServices();
    });

    this.iFrameService.onContentLoad.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoaded: boolean) => {
      if (!isLoaded) return;

      setTimeout(() => this.initAllColors(), 250);
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;
    });

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.template = template;

      this.isBothMenus = template && template.menuType === 'BOTH';
    });

    this.googleFontsService.selectedFontSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((font: GoogleFontModel) => {
      if (!this.isAnySectionSelected) return;

      if (this.isGeneralSection) return this.onWebsiteFontChange(font);

      this.onFontSelect(font);
    });
  }

  private handleWebsite(website: WebsiteModel) {
    if (!website) return;

    this.website = website;
  }

  initMenuLinksOptions(menu): void {
    if (!menu || !menu.hasAttribute('data-menu-block')) return;

    const hoverOptions = this.hoverStylesService.getOptionsByClassList(menu.className.split(' '));

    this.onHoverOptionsSelect(hoverOptions);

    const activeOptions = this.activeStylesService.getOptionsByClassList(menu.className.split(' '));

    this.onActiveOptionsSelect(activeOptions);
  }

  initMobileMenuLinksOptions(mobileMenu): void {
    if (!mobileMenu || !mobileMenu.hasAttribute('data-side-menu')) return;

    const activeOptions = this.mobileActiveStylesService.getOptionsByClassList(mobileMenu.className.split(' '));

    this.onMobileActiveOptionsSelect(activeOptions);
  }

  private parseMenuElement(key: number): void {
    const elementData = this.stylePanel[key];

    elementData.nodes = key === CustomizerSections.Pages ? $(this.element) : $(this.iFrameService.getItems(`[${elementData.attr}]`));

    if (elementData.nodes.length === 0) return;

    this.setDefaultElementStyle(elementData);

    if (elementData === this.stylePanel[CustomizerSections.Subtitle]) this.dispatchFocusOnSubtitle();
  }

  // Template <body> styles
  parseWebsiteDefaultStyles() {
    const bodyElement = this.iFrameService.getItems('.innerWrapper');

    if (bodyElement.length === 0) return;

    const body = {
      styles: WebsiteStyles.styles,
      nodes: $(bodyElement),
    };

    this.setDefaultElementStyle(body);

    Object.keys(body.styles).forEach(styleName => {
      const mappedName = WebsiteStylesMap[styleName];

      if (!mappedName) throw new Error('Has not mapped property.');

      this.detailsService.websiteStyles[mappedName] = body.styles[styleName];
      this.detailsService.latestStyles.websiteStyles[mappedName] = body.styles[styleName];
    });

    this.detailsService.initColorIntersection();
  }

  private sectionsToElementsMapping = {
    [CustomizerSections.General]: () => this.iFrameService.innerWrapper,
    [CustomizerSections.Title]: () => this.element && this.element.matches('[data-website-title]') ? null : this.iFrameService.title,
    [CustomizerSections.Subtitle]: () => this.element && this.element.matches('[data-website-subtitle]') ? null : this.iFrameService.subtitle,
    [CustomizerSections.Pages]: () => this.iFrameMenuService.menu,
    [CustomizerSections.MobileTitle]: () => {
      if (!this.iFrameMenuService.isLegacyMenu) return this.iFrameService.title;

      return this.element && this.element.matches('[data-website-title-mobile]') ? null : this.iFrameService.mobileTitle;
    },
    [CustomizerSections.MobileSubtitle]: () => {
      if (!this.iFrameMenuService.isLegacyMenu) return this.iFrameService.subtitle;

      return this.element && this.element.matches('[data-website-subtitle-mobile]') ? null : this.iFrameService.mobileSubtitle;
    },
    [CustomizerSections.Mobile]: () => this.iFrameMenuService.mobileMenu,
  };

  private elementsColorMapping = {
    [COLOR_KEYS.DESKTOP_TITLE]: () => this.iFrameService.titleText,
    [COLOR_KEYS.DESKTOP_SUBTITLE]: () => this.iFrameService.subtitleText,
    [COLOR_KEYS.DESKTOP_MENU_TEXT]: () => this.iFrameService.menuItemInactive,
    [COLOR_KEYS.MOBILE_TITLE]: () => this.iFrameService.mobileTitleText,
    [COLOR_KEYS.MOBILE_SUBTITLE]: () => this.iFrameService.mobileSubtitleText,
    [COLOR_KEYS.MOBILE_MENU_TEXT]: () => this.iFrameService.mobileMenuItemInactive,
  };

  public selectSection(key: CustomizerSections, force?: boolean) {
    if (this.editingTypes[key] && this.editingTypes[key].handle) this.editingTypes[key].handle();

    this.selectedSection = this.selectedSection !== key || force ? key : null;

    this.initSectionOptions();
    this.setCustomizableElements(this.selectedSection);

    if (!this.sectionsToElementsMapping[this.selectedSection]) return;

    const element = $(this.sectionsToElementsMapping[this.selectedSection]());

    if (!element || !element[0] || this.element === element[0]) return;

    if (this.selectedSection === CustomizerSections.Pages && this.element && this.element.matches('[data-menu-block]')) return;

    const editingType = this.editingTypes[this.selectedSection].key;

    this.websiteDesignerService.editingSubject.next({
      element: element[0],
      editingType,
    });
  }

  public onWebsiteFontSizeChange(value: string): void {
    this.nativeSetup.fontSize.onChange(value);
    
    this.emitMenuResize();

    this.detailsService.websiteStyles.pagefontsize = value;

    this.saveDefaults('pagefontsize');
  }

  public onMobileTitleFontSizeChange(value: string): void {
    const fontSize = Number.parseInt(value);

    this.mobileTitleSetup.fontSize.onChange(fontSize);

    this.eventsService.dispatchStyleChangedEvent({
      key: this.mobileTitleSetup.fontSize.key,
      target: this.mobileTitleSetup.fontSize.element,
      value,
    }, this.iFrameService.sandboxWindow);

    this.afterChange();

    const lineHeight: number = this.mobileTitleSetup.lineHeight.value;

    const expectedLineHeight = fontSize * 1.5;

    this.onMenuChange();

    if (lineHeight >= expectedLineHeight) {
      return this.emitMenuResize();
    }

    const newLineHeight = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onMobileTitleLineHeightChange(`${newLineHeight}`);

    this.emitMenuResize();
  }

  public onMobileTitleFontSizeReset(): void {
    this.mobileTitleSetup.fontSize.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleFontSizeChange(value: string): void {
    const fontSize = Number.parseInt(value);

    this.mobileSubtitleSetup.fontSize.onChange(fontSize);

    this.afterChange();

    const lineHeight: number = this.mobileSubtitleSetup.lineHeight.value;

    const expectedLineHeight = fontSize * 1.5;

    this.onMenuChange(); 

    if (lineHeight >= expectedLineHeight) {
      return this.emitMenuResize();   
    }

    const newLineHeight = expectedLineHeight <= this.maxSubtitleLineHeight ? expectedLineHeight : this.maxSubtitleLineHeight;

    this.onMobileSubtitleLineHeightChange(`${newLineHeight}`);

    this.emitMenuResize();
  }

  public onMobileSubtitleFontSizeReset(): void {
    this.mobileSubtitleSetup.fontSize.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onFontSizeChange(value: string): void {
    this.nativeSetup.fontSize.onChange(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleScalableFontSizeChange(value: string): void {
    this.titleSetup.scalableFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.titleSetup.scalableFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onTitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onTitleScalableFontSizeReset(): void {
    this.titleSetup.scalableFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.titleSetup.scalableFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onTitleScalableLineHeightChange(`${newLineHeight}`);
  }



  public onTitleScalableLineHeightChange(value: string): void {
    this.titleSetup.scalableLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleScalableLineHeightReset(): void {
    this.titleSetup.scalableLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleScalableLetterSpacingChange(value: string): void {
    this.titleSetup.scalableLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleScalableLetterSpacingReset(): void {
    this.titleSetup.scalableLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleScalableWordSpacingChange(value: string): void {
    this.titleSetup.scalableWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleScalableWordSpacingReset(): void {
    this.titleSetup.scalableWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacyTitleScalableFontSizeChange(value: string): void {
    this.titleSetup.legacyScalableFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.titleSetup.legacyScalableFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onLegacyTitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onLegacyTitleScalableFontSizeReset(): void {
    this.titleSetup.legacyScalableFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.titleSetup.legacyScalableFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onLegacyTitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onLegacyTitleScalableLineHeightChange(value: string): void {
    this.titleSetup.legacyScalableLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableLineHeight);

    this.onMenuChange();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacyTitleScalableLineHeightReset(): void {
    this.titleSetup.legacyScalableLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacyTitleScalableLetterSpacingChange(value: string): void {
    this.titleSetup.legacyScalableLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacyTitleScalableLetterSpacingReset(): void {
    this.titleSetup.legacyScalableLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacyTitleScalableWordSpacingChange(value: string): void {
    this.titleSetup.legacyScalableWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacyTitleScalableWordSpacingReset(): void {
    this.titleSetup.legacyScalableWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.titleSetup.legacyScalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleScalableFontSizeChange(value: string): void {
    this.subtitleSetup.scalableFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.subtitleSetup.scalableFontSize.value;

    const newLineHeight = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onSubtitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onSubtitleScalableFontSizeReset(): void {
    this.subtitleSetup.scalableFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.subtitleSetup.scalableFontSize.value;

    const newLineHeight = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onSubtitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onSubtitleScalableLineHeightChange(value: string): void {
    this.subtitleSetup.scalableLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleScalableLineHeightReset(): void {
    this.subtitleSetup.scalableLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleScalableLetterSpacingChange(value: string): void {
    this.subtitleSetup.scalableLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleScalableLetterSpacingReset(): void {
    this.subtitleSetup.scalableLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleScalableWordSpacingChange(value: string): void {
    this.subtitleSetup.scalableWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleScalableWordSpacingReset(): void {
    this.subtitleSetup.scalableWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacySubtitleScalableFontSizeChange(value: string): void {
    this.subtitleSetup.legacyScalableFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.subtitleSetup.legacyScalableFontSize.value;

    const newLineHeight = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onLegacySubtitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onLegacySubtitleScalableFontSizeReset(): void {
    this.subtitleSetup.legacyScalableFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.subtitleSetup.legacyScalableFontSize.value;

    const newLineHeight = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onLegacySubtitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onLegacySubtitleScalableLineHeightChange(value: string): void {
    this.subtitleSetup.legacyScalableLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacySubtitleScalableLineHeightReset(): void {
    this.subtitleSetup.legacyScalableLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacySubtitleScalableLetterSpacingChange(value: string): void {
    this.subtitleSetup.legacyScalableLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacySubtitleScalableLetterSpacingReset(): void {
    this.subtitleSetup.legacyScalableLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacySubtitleScalableWordSpacingChange(value: string): void {
    this.subtitleSetup.legacyScalableWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onLegacySubtitleScalableWordSpacingReset(): void {
    this.subtitleSetup.legacyScalableWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.subtitleSetup.legacyScalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTextTransformChange(value: string): void {
    this.nativeSetup.textTransform.onChange(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTextDecorationChange(value: string): void {
    this.nativeSetup.textDecoration.onChange(value);

    this.afterChange();
  }

  public onTextAlignmentChange(value: string): void {
    this.nativeSetup.textAlignment.onChange(value);

    this.afterChange();
  }

  public onWebsiteFontWeightChange(value: string): void {
    this.nativeSetup.fontWeight.onChange(value);

    this.detailsService.websiteStyles.pagefontweight = value;

    this.saveDefaults('pagefontweight');
  }

  public onWebsiteTextTransformChange(value: string): void {
    this.nativeSetup.textTransform.onChange(value);

    this.emitMenuResize();

    this.detailsService.websiteStyles.pagetexttransform = value;

    this.saveDefaults('pagetexttransform');
  }

  public onFontWeightChange(value: string): void {
    this.nativeSetup.fontWeight.onChange(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.eventsService.dispatchStyleChangedEvent({
      target: this.nativeSetup.fontWeight.element,
      key: this.nativeSetup.fontWeight.key,
      value: this.nativeSetup.fontWeight.value,
    }, this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public saveDefaults(propertyName): void {
    this.detailsService.changeWebsiteStyleProperty(propertyName);

    this.afterChange();
  }

  public onBackgroundColorChange(value: string): void {
    this.eventsService.dispatchWebsiteStylesChanged({
      key: 'WEBSITE_BACKGROUND_COLOR',
      value,
    }, this.iFrameService.sandboxWindow);
  }

  onReset() {
    this.textAlertModalService.show({
      message: this.resetModalText,
      header: this.resetModalHeader,
      submitHandler: () => this.handleResetAlertSubmit(),
      showCancelButton: true,
    });
  }

  handleResetAlertSubmit() {
    this.detailsService.resetWebsiteStyles();

    this.emitMenuResize();

    this.onMenuChange();
    this.initAllColors();

    this.eventsService.dispatchResetToFactoryDefaultsCompleted(this.iFrameService.sandboxWindow);
  }

  onElementChange(options) {
    if (this.isDestroyed) return;

    if (!options || !this.editableTypes.includes(options.editingType)) {
      if (this.element) this.element.classList.remove('editor-select');

      this.element = null;
      this.selectedSection = null;

      this.initSectionOptions();

      this.cdr.detectChanges();

      return;
    }

    const element: HTMLElement = $(options.element)[0] as HTMLElement;

    if (this.element && this.element !== element) this.element.classList.remove('editor-select');

    this.element = element;

    this.initDesktopMenu();

    const key = Object.keys(this.editingTypes).find(key => this.editingTypes[key].key === options.editingType);

    if (!this.element) {
      this.selectedSection = null;

      this.initSectionOptions();
    }

    this.initServices();

    if (!key) return;

    if (this.element) this.element.classList.add('editor-select');

    const section: CustomizerSections = Number.parseInt(key, 10);

    const menu = section === CustomizerSections.Pages ? element : null;
    const mobileMenu = section === CustomizerSections.Mobile ? element : null;

    const title = section === CustomizerSections.Title ? <HTMLElement>element.closest('[data-menu-block]') : menu;
    const subtitle = section === CustomizerSections.Subtitle ? <HTMLElement>element.closest('[data-menu-block]') : menu;

    const mobileTitle = section === CustomizerSections.MobileTitle ? <HTMLElement>element.closest('[data-menu-block]') : menu;
    const mobileSubtitle = section === CustomizerSections.MobileSubtitle ? <HTMLElement>element.closest('[data-menu-block]') : menu;

    this.customMenuStylesService.init({ menu: <HTMLElement>menu, device: this.device });
    this.customMobileMenuStylesService.init(mobileMenu);

    this.titleStylesService.init(title);
    this.subtitleStylesService.init(subtitle);

    this.mobileTitleStylesService.init(mobileTitle);
    this.mobileSubtitleStylesService.init(mobileSubtitle);

    this.initMenuLinksOptions(menu);
    this.initMobileMenuLinksOptions(mobileMenu);

    if (this.selectedSection === section && (section !== CustomizerSections.Pages || !this.isBothMenus)) return;

    this.selectSection(section, true);
  }

  private initServices(): void {
    this.nativeStylesService.init(this.element, this.optionsMap);
    this.websiteStylesService.init(this.element, this.optionsMap);
    this.iFrameMenuService.init(this.element, this.optionsMap);

    this.cdr.detectChanges();
  }

  private initDesktopMenu(): void {
    this.desktopMenuSectionTitle = 'DESKTOP MENU';

    if (!this.element || !this.element.matches('[data-menu-block]')) return;

    this.isHorizontalMenuElement = this.element.matches('[data-is-menu-vertical="false"]');
    this.isVerticalMenuElement = this.element.matches('[data-is-menu-vertical="true"]');

    this.desktopMenuSectionTitle = this.isVerticalMenuElement ? 'SIDE MENU' : 'TOP MENU';
  }

  setCustomizableElements(value: CustomizerSections) {
    const { sandboxWindow } = this.iFrameService;

    if (!sandboxWindow) return;

    const clearElementSelection = (<any>sandboxWindow).clearElementSelection;

    if (!clearElementSelection) return;

    if (!this.editingTypes[value] || value === CustomizerSections.General) return clearElementSelection();

    const handler = this.editingTypes[value].handle;

    if (handler) handler();

    const highlightSelectedElement = (<any>sandboxWindow).hightlightSelectedElement;

    if (!highlightSelectedElement) return;

    return highlightSelectedElement(this.stylePanel[value].nodes);
  }

  setDefaultElementStyle(data) {
    if (!data.styles) return;

    const element = data.nodes.first();

    const isElementActive = element.hasClass('active');

    element.removeClass('active');

    Object.keys(data.styles).forEach(key => {
      if (data.prefix && key.startsWith(data.prefix)) {
        data.styles[key] = this.detailsService.parseCssVariable(element, data.prefix, key);

        return;
      }

      data.styles[key] = this.detailsService.parseStyleProperty(element, key);
    });

    if (isElementActive) element.addClass('active');
  }

  onStyleChange(modelName: CustomizerSections, styleName: string, styleValue: string) {
    this.applyMenuStyles(modelName, styleName, styleValue);

    if (!this.iFrameService.isLoaded) {
      return;
    }

    const allowed = [
      CustomizerSections.Pages,
      CustomizerSections.Mobile,
      CustomizerSections.MobileTitle,
      CustomizerSections.MobileSubtitle,
    ];

    if (allowed.includes(modelName)) {
      this.onMenuChange();
    }

    this.afterChange();
  }

  applyMenuStyles(modelName: CustomizerSections, styleName: string, styleValue: string) {
    if (!this.stylePanel[modelName].nodes || !this.stylePanel[modelName].nodes.length) return;

    const formattedValue = this.stylesDimensionsService.toStyleString(styleName, styleValue);

    this.stylePanel[modelName].styles[styleName] = styleValue;

    const element = this.selectedSection === CustomizerSections.Pages ? $(this.element) : this.stylePanel[modelName].nodes;

    element.css(styleName, formattedValue);
  }

  onHorizontalPaddingChange(value) {
    this.applyMenuStyles(this.customizerSections.Pages, '--menu-links-padding-left', value);
    this.applyMenuStyles(this.customizerSections.Pages, '--menu-links-padding-right', value);

    this.afterChange();
  }

  public onVerticalPaddingChange(value: string): void {
    this.menuSetup.verticalPadding.onChange(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onVerticalPaddingReset(): void {
    this.menuSetup.verticalPadding.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onHoverOptionsSelect(options: SelectOption[]): void {
    this.iFrameMenuService.onHoverOptionsSelect(options);
  }

  public onActiveOptionsSelect(options: SelectOption[]): void {
    this.iFrameMenuService.onActiveOptionsSelect(options);
  }

  public onMobileActiveOptionsSelect(options: SelectOption[]): void {
    this.iFrameMenuService.onMobileActiveOptionsSelect(options);

    this.onMenuChange();
  }

  private sectionColors = {
    [CustomizerSections.General]: [
      COLOR_KEYS.COMMON_BACKGROUND,
      COLOR_KEYS.COMMON_TEXT,
      COLOR_KEYS.COMMON_LINK,
    ],
    [CustomizerSections.Title]: [
      COLOR_KEYS.DESKTOP_TITLE,
    ],
    [CustomizerSections.Subtitle]: [
      COLOR_KEYS.DESKTOP_SUBTITLE,
    ],
    [CustomizerSections.Pages]: [
      COLOR_KEYS.DESKTOP_MENU_HAMBURGER,
      COLOR_KEYS.DESKTOP_MENU_TEXT,
      COLOR_KEYS.DESKTOP_MENU_HOVER,
      COLOR_KEYS.DESKTOP_MENU_ACTIVE,
      COLOR_KEYS.DESKTOP_MENU_BACKGROUND,
    ],
    [CustomizerSections.Mobile]: [
      COLOR_KEYS.MOBILE_MENU_BACKGROUND,
      COLOR_KEYS.MOBILE_MENU_TEXT,
      COLOR_KEYS.MOBILE_MENU_HOVER_TEXT_COLOR,
      COLOR_KEYS.MOBILE_MENU_ACTIVE,
    ],
    [CustomizerSections.MobileTitle]: [
      COLOR_KEYS.MOBILE_TITLE,
    ],
    [CustomizerSections.MobileSubtitle]: [
      COLOR_KEYS.MOBILE_SUBTITLE,
    ],
  };

  public hasIssues(key: CustomizerSections): boolean {
    const data = this.sectionColors[key];

    if (!data) return;

    return data.some(key => !this.isColorOkay(key));
  }

  public openSection(section: string) {
    this.sidebarSectionsService.selectSection(section);
  }

  public onChildClose() {
    this.selectedSection = this.stylePanel[this.selectedSection].parent ? this.stylePanel[this.selectedSection].parent : null;

    this.initSectionOptions();
  }

  private initSectionOptions(): void {
    if (this.isDestroyed) return;

    this.isAnySectionSelected = this.selectedSection !== null;

    this.isGeneralSection = this.isSectionSelected(this.customizerSections.General);
    this.isPagesSection = this.isSectionSelected(this.customizerSections.Pages);
    this.isMediaSection = this.isSectionSelected(this.customizerSections.Media);
    this.isTitleSection = this.isSectionSelected(this.customizerSections.Title);
    this.isSubtitleSection = this.isSectionSelected(this.customizerSections.Subtitle);
    this.isMobileSection = this.isSectionSelected(this.customizerSections.Mobile);
    this.isMobileTemplatesSection = this.isSectionSelected(this.customizerSections.MobileTemplates);
    this.isMobileTitleSection = this.isSectionSelected(this.customizerSections.MobileTitle);
    this.isMobileSubtitleSection = this.isSectionSelected(this.customizerSections.MobileSubtitle);
    this.isMobileTemplatesPortfoliosSection = this.isSectionSelected(this.customizerSections.MobileTemplatesPortfolios);
    this.isMobileTemplatesEnlargementsSection = this.isSectionSelected(this.customizerSections.MobileTemplatesEnlargements);

    this.cdr.detectChanges();
  }

  private isSectionSelected(value: CustomizerSections): boolean {
    const children = this.stylePanel[value] && this.stylePanel[value].children;

    return this.selectedSection === value || children && children.includes(this.selectedSection);
  }

  public onMenuStyleChange(value) {
    this.menuSetup.style.onChange(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMenuDropdownArrowChange(value) {
    this.detailsService.websiteStyles['dropdownArrow'] = value;

    this.menuSetup.dropdownArrow.onChange(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMenuDropdownTextAlignmentChange(value) {
    this.menuSetup.dropdownTextAlignment.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMenuDropdownTextAlignmentReset() {
    this.menuSetup.dropdownTextAlignment.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuStyleChange(value) {
    this.mobileMenuSetup.style.onChange(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuCollapseChange(value: string): void {
    this.mobileMenuSetup.collapseOptions.setValue(value);

    this.onMenuChange();

    this.afterChange();

    if (value !== COLLAPSE_STYLE_KEYS.AUTO_COLLAPSE) return;

    this.eventsService.dispatchAllMenuItemsCollapse({ target: this.element }, this.iFrameService.sandboxWindow);
  }

  public onMobileMenuCollapseReset(): void {
    this.mobileMenuSetup.collapseOptions.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuDropdownArrowChange(value) {
    this.detailsService.websiteStyles['mobileDropdownArrow'] = value;

    this.mobileMenuSetup.dropdownArrow.onChange(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuPositionChange(value) {
    this.detailsService.websiteStyles['mobileMenuPosition'] = value;
    this.mobileMenuSetup.position.onChange(value);

    this.eventsService.dispatchStyleChangedEvent({
      key: this.mobileMenuSetup.position.key,
      target: this.mobileMenuSetup.position.element,
      value,
    }, this.iFrameService.sandboxWindow);

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleLogoPositionChange(value) {
    this.detailsService.websiteStyles['mobileLogoPosition'] = value;

    this.mobileTitleSetup.logoPosition.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onWebsiteFontChange(font: GoogleFontModel): void {
    this.nativeStylesService.onFontFamilyChange(font);

    this.detailsService.websiteStyles.pagefontfamily = font.family;

    this.googleFontsService.addFontLink(font);

    this.saveDefaults('pagefontfamily');
  }

  public resetWebsiteDefaultStyle(styleName) {
    const { attributeStyle, specificSelector, resetHandlers, defaultInitBehavior } = this.detailsService.DETAILS_PROPERTIES[styleName];

    const elements = this.iFrameService.getItems(specificSelector || '.innerWrapper');

    this.afterChange();

    if (!elements.length) {
      this.detailsService.websiteStyles[styleName] = this.detailsService.getDefaultStyle(styleName);

      return this.initAllColors();
    }

    elements.forEach((element: HTMLElement) => {
      element.style.removeProperty(attributeStyle);
    });

    if (resetHandlers && resetHandlers.length) {
      resetHandlers.forEach(handler => handler($(elements)));

      return this.initAllColors();
    }

    this.detailsService.websiteStyles[styleName] = defaultInitBehavior === 'DATA' ? this.detailsService.getDefaultStyle(styleName) : this.detailsService.parseStyleProperty($(elements[0]), attributeStyle);

    if (defaultInitBehavior === 'DATA') {
      elements.forEach((element: HTMLElement) => {
        element.style.setProperty(attributeStyle, this.detailsService.websiteStyles[styleName]);
      });
    }

    this.emitMenuResize();

    this.onMenuChange();

    return this.initAllColors();
  }

  private resetWebsiteStyles(customizerSection, ...styleNames) {
    styleNames.forEach(styleName => {
      if (this.resetStyleSpecificHandlers[customizerSection] && this.resetStyleSpecificHandlers[customizerSection][styleName]) {
        this.resetStyleSpecificHandlers[customizerSection][styleName]();

        return this.afterChange();
      }

      const elements = this.selectedSection === CustomizerSections.Pages ? $(this.element) : this.stylePanel[customizerSection].nodes;

      elements.each((index, element) => {
        element.style.removeProperty(styleName);

        const prefix = this.stylePanel[customizerSection].prefix;

        this.stylePanel[customizerSection].styles[styleName] = prefix && styleName.startsWith(prefix) ? this.detailsService.parseCssVariable($(element), prefix, styleName) : this.detailsService.parseStyleProperty($(element), styleName);
      });

      this.afterChange();
    });

    const allowed = [
      CustomizerSections.Pages,
      CustomizerSections.Mobile,
      CustomizerSections.MobileTitle,
      CustomizerSections.MobileSubtitle,
    ];

    if (allowed.includes(customizerSection)) {
      this.onMenuChange();
    }

    this.initAllColors();
  }

  public onHamburgerColorChange(value) {
    this.menuSetup.hamburgerColor.setValue(value);

    this.eventsService.dispatchCustomStyleChangedEvent({
      key: this.menuSetup.hamburgerColor.key,
      target: this.menuSetup.hamburgerColor.element,
      value: this.menuSetup.hamburgerColor.value,
    }, this.iFrameService.sandboxWindow);

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onHamburgerColorReset() {
    this.menuSetup.hamburgerColor.reset();

    this.eventsService.dispatchCustomStyleChangedEvent({
      key: this.menuSetup.hamburgerColor.key,
      target: this.menuSetup.hamburgerColor.element,
      value: this.menuSetup.hamburgerColor.value,
    }, this.iFrameService.sandboxWindow);

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onActiveMenuLinkColorChange(value) {
    this.menuSetup.activeColor.setValue(value);

    this.initMenuColors();

    this.afterChange();
  }

  public onActiveMenuLinkColorReset() {
    this.menuSetup.activeColor.reset();

    this.initMenuColors();

    this.afterChange();
  }

  public onDropdownHoverDesignOptionsSelect(options: SelectOption[]): void {
    this.menuSetup.dropdownItemHoverDesign.onSelect(options);

    this.afterChange();
  }

  public onDropdownHoverDesignReset(): void {
    this.menuSetup.dropdownItemHoverDesign.reset();

    this.afterChange();
  }

  public onDropdownItemHoverBackgroundChange(value: string): void {
    this.menuSetup.dropdownItemHoverBackground.setValue(value);

    this.afterChange();
  }

  public onDropdownItemHoverBackgroundReset(): void {
    this.menuSetup.dropdownItemHoverBackground.reset();

    this.afterChange();
  }

  public onDropdownItemOpacityChange(value: string): void {
    this.menuSetup.dropdownItemOpacity.setValue(value);

    this.afterChange();
  }

  public onDropdownItemOpacityReset(): void {
    this.menuSetup.dropdownItemOpacity.reset();

    this.afterChange();
  }

  public onDropdownItemHoverOpacityChange(value: string): void {
    this.menuSetup.dropdownItemHoverOpacity.setValue(value);

    this.afterChange();
  }

  public onDropdownItemHoverOpacityReset(): void {
    this.menuSetup.dropdownItemHoverOpacity.reset();

    this.afterChange();
  }

  public onDropdownItemHoverColorChange(value: string): void {
    this.menuSetup.dropdownItemHoverColor.setValue(value);

    this.afterChange();
  }

  public onDropdownItemHoverColorReset(): void {
    this.menuSetup.dropdownItemHoverColor.reset();

    this.afterChange();
  }

  public onDesktopMenuBackgroundColorChange(value) {
    this.menuSetup.backgroundColor.setValue(value);

    this.eventsService.dispatchCustomStyleChangedEvent({
      key: this.menuSetup.backgroundColor.key,
      target: this.menuSetup.backgroundColor.element,
      value,
    }, this.iFrameService.sandboxWindow);

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onDesktopMenuBackgroundColorReset() {
    this.menuSetup.backgroundColor.reset();

    this.eventsService.dispatchCustomStyleChangedEvent({
      key: this.menuSetup.backgroundColor.key,
      target: this.menuSetup.backgroundColor.element,
      value: this.menuSetup.backgroundColor.value,
    }, this.iFrameService.sandboxWindow);

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onDesktopMenuBackgroundOpacityChange(value) {
    this.menuSetup.backgroundOpacity.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onDesktopMenuBackgroundOpacityReset(): void {
    this.menuSetup.backgroundOpacity.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuBackgroundColorChange(value) {
    this.mobileMenuSetup.backgroundColor.setValue(value);

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuBackgroundColorReset() {
    this.mobileMenuSetup.backgroundColor.reset();

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onActiveMobileMenuLinkColorChange(value) {
    this.mobileMenuSetup.activeColor.setValue(value);

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onActiveMobileMenuLinkColorReset() {
    this.mobileMenuSetup.activeColor.reset();

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onHoverMenuLinkColorChange(value) {
    this.menuSetup.hoverColor.setValue(value);

    this.initMenuColors();

    this.afterChange();
  }

  public onHoverMenuLinkColorReset() {
    this.menuSetup.hoverColor.reset();

    this.initMenuColors();

    this.afterChange();
  }

  public onMobileMenuHoverBackgroundChange(value: string): void {
    this.mobileMenuSetup.hoverBackground.setValue(value);

    this.initMenuColors();
    this.initMobileMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuHoverBackgroundReset(): void {
    this.mobileMenuSetup.hoverBackground.reset();

    this.initMenuColors();
    this.initMobileMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuHoverTextColorChange(value: string): void {
    this.mobileMenuSetup.hoverColor.setValue(value);

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuHoverTextColorReset(): void {
    this.mobileMenuSetup.hoverColor.reset();

    this.initMenuColors();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileItemSeparatorColorChange(value: string): void {
    this.mobileMenuSetup.itemSeparatorColor.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileItemSeparatorColorReset(): void {
    this.mobileMenuSetup.itemSeparatorColor.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileItemSeparatorWidthChange(value): void {
    this.mobileMenuSetup.itemSeparatorWidth.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileItemSeparatorWidthReset(): void {
    this.mobileMenuSetup.itemSeparatorWidth.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onDropdownArrowPaddingChange(value: number) {
    this.menuSetup.dropdownArrowPadding.setValue(`${value}px`);

    this.afterChange();
  }

  public onDropdownArrowPaddingReset() {
    this.menuSetup.dropdownArrowPadding.reset();

    this.afterChange();
  }

  public onMenuTextDecorationChange(value) {
    this.menuSetup.textDecoration.setValue(value);

    this.afterChange();
  }

  public onMenuTextDecorationReset() {
    this.menuSetup.textDecoration.reset();

    this.afterChange();
  }

  public onMobileMenuTextDecorationChange(value) {
    this.mobileMenuSetup.textDecoration.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileMenuTextDecorationReset() {
    this.mobileMenuSetup.textDecoration.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onHamburgerTooltipChange(value: boolean) {
    this.menuSetup.hamburgerTooltip.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public verifySiteBackgroundColor() {
    if (this.verifySiteBackgroundColorTimeoutId) return;

    this.verifySiteBackgroundColorTimeoutId = setTimeout(() => {
      clearTimeout(this.verifySiteBackgroundColorTimeoutId);
      this.verifySiteBackgroundColorTimeoutId = null;
      this.detailsService.initColorIntersection();
    }, 50);
  }

  public verifyColor(key: string, color: string) {
    this.colorIntersectionService.initColor(key, color);
    this.colorIntersectionService.calculate();
  }

  public isColorOkay(key: string) {
    if (!this.colorIntersectionData) return true;

    return this.colorIntersectionData.isColorOkay(key);
  }

  private initAllColors() {
    this.initElementsColors();
    this.initMenuColors();
    this.initMobileMenuColors();

    this.detailsService.initColorIntersection();
  }

  private initElementsColors() {
    Object.keys(this.elementsColorMapping).forEach(key => {
      const element = this.elementsColorMapping[key]();

      if (!element) return;

      const color = window.getComputedStyle(element)['color'];

      if (!color) return;

      this.colorIntersectionService.initColor(key, color);
    });

    this.colorIntersectionService.calculate();
  }

  private initMenuColors() {
    const menu = this.selectedSection === CustomizerSections.Pages ? this.element : this.sectionsToElementsMapping[CustomizerSections.Pages]();

    this.menuSetup.init({ element: menu, device: this.device });

    this.colorIntersectionService.initColor(COLOR_KEYS.DESKTOP_MENU_BACKGROUND, this.menuSetup.backgroundColor.value);
    this.colorIntersectionService.initColor(COLOR_KEYS.DESKTOP_MENU_HOVER, this.menuSetup.hoverColor.value);
    this.colorIntersectionService.initColor(COLOR_KEYS.DESKTOP_MENU_ACTIVE, this.menuSetup.activeColor.value);

    if (this.menuSetup.hamburgerColor.element) {
      this.colorIntersectionService.initColor(COLOR_KEYS.DESKTOP_MENU_HAMBURGER, this.menuSetup.hamburgerColor.value);
    }

    this.colorIntersectionService.calculate();
  }

  private initMobileMenuColors() {
    const mobileMenu = this.sectionsToElementsMapping[CustomizerSections.Mobile]();

    this.mobileMenuSetup.init(mobileMenu);

    this.colorIntersectionService.initColor(COLOR_KEYS.MOBILE_MENU_BACKGROUND, this.mobileMenuSetup.backgroundColor.value);
    this.colorIntersectionService.initColor(COLOR_KEYS.MOBILE_MENU_HOVER_BACKGROUND, this.mobileMenuSetup.hoverBackground.value);
    this.colorIntersectionService.initColor(COLOR_KEYS.MOBILE_MENU_HOVER_TEXT_COLOR, this.mobileMenuSetup.hoverColor.value);
    this.colorIntersectionService.initColor(COLOR_KEYS.MOBILE_MENU_ACTIVE, this.mobileMenuSetup.activeColor.value);

    this.colorIntersectionService.calculate();
  }

  public onFontSelect(font: GoogleFontModel): void {
    this.nativeStylesService.onFontFamilyChange(font);
    this.googleFontsService.addFontLink(font);
    this.attributesService.addAttributeToSave({ element: this.nativeStylesService.element, attributeToSave: 'data-used-font' });

    this.eventsService.dispatchStyleChangedEvent({
      target: this.nativeSetup.fontFamily.element,
      key: this.nativeSetup.fontFamily.key,
      value: this.nativeSetup.fontFamily.value,
    }, this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public onFontRevert(): void {
    this.nativeSetup.fontFamily.reset();

    this.eventsService.dispatchStyleChangedEvent({
      target: this.nativeSetup.fontFamily.element,
      key: this.nativeSetup.fontFamily.key,
      value: this.nativeSetup.fontFamily.value,
    }, this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public onWebsiteFontSizeRevert(): void {
    this.resetWebsiteDefaultStyle('pagefontsize');

    this.onFontSizeRevert();
  }

  public onWebsiteFontWeightRevert(): void {
    this.resetWebsiteDefaultStyle('pagefontweight');

    this.onFontWeightRevert();

    this.detailsService.websiteStyles['pagetexttransform'] = this.nativeSetup.fontWeight.value;
  }

  public onWebsiteTextTransformRevert(): void {
    this.resetWebsiteDefaultStyle('pagetexttransform');

    this.onTextTransformRevert();
  }

  public onFontSizeRevert(): void {
    this.nativeSetup.fontSize.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onFontWeightRevert(): void {
    this.nativeSetup.fontWeight.reset();

    this.nativeStylesService.initFontWeight();

    this.eventsService.dispatchStyleChangedEvent({
      target: this.nativeSetup.fontWeight.element,
      key: this.nativeSetup.fontWeight.key,
      value: this.nativeSetup.fontWeight.value,
    }, this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public onTextTransformRevert(): void {
    this.nativeSetup.textTransform.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTextDecorationRevert(): void {
    this.nativeSetup.textDecoration.reset();

    this.afterChange();
  }

  public onTextAlignmentRevert(): void {
    this.nativeSetup.textAlignment.reset();

    this.afterChange();
  }

  public onMenuTransparencyOnHomePageChange(value: boolean): void {
    this.menuSetup.isMenuTransparentOnHomePage.setValue(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMenuPaddingTopChange(value: string): void {
    this.menuSetup.menuPaddingTop.onChange(Number.parseInt(value));

    this.onMenuChange();

    this.afterChange();
  }

  public onMenuPaddingTopReset(): void {
    this.menuSetup.menuPaddingTop.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onMenuPaddingChange({ key, value }: { key?: string, value: string }) {
    this.menuSetup.padding.onChange({ key, value });

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMenuPaddingReset(key?: string) {
    if (!key) this.menuSetup.padding.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMarginBetweenBlocksChange(value: string): void {
    this.setup.spaceBetweenColumns.setValue(value);

    this.detailsService.websiteStyles.marginBetweenBlocks = value;
    this.saveDefaults('marginBetweenBlocks');
  }

  public onMarginBetweenBlocksReset(): void {
    this.setup.spaceBetweenColumns.reset();

    this.detailsService.websiteStyles.marginBetweenBlocks = this.setup.spaceBetweenColumns.value;
    this.saveDefaults('marginBetweenBlocks');
  }

  public onWebsiteMarginChange(value: string): void {
    this.setup.websiteMargin.setValue(value);

    this.detailsService.websiteStyles.margin = value;

    this.saveDefaults('margin');

    this.eventsService.dispatchResize(this.iFrameService.sandboxWindow);
  }

  public onWebsiteMarginReset(): void {
    this.setup.websiteMargin.reset();

    this.detailsService.websiteStyles.margin = this.setup.websiteMargin.value;

    this.saveDefaults('margin');

    this.eventsService.dispatchResize(this.iFrameService.sandboxWindow);
  }

  public onWebsiteLinkColorChange(value: string): void {
    this.setup.websiteLinkColor.onChange(value);

    this.detailsService.websiteStyles.textpagelinkfontcolor = this.setup.websiteLinkColor.value;

    this.saveDefaults('textpagelinkfontcolor');

    this.verifyColor(COLOR_KEYS.COMMON_LINK, this.setup.websiteLinkColor.value);

    this.afterChange();

    this.eventsService.dispatchWebsiteStylesChanged({
      key: this.setup.websiteLinkColor.key,
      value: value,
    }, this.iFrameService.sandboxWindow);
  }

  public onWebsiteLinkColorReset(): void {
    this.setup.websiteLinkColor.reset();

    this.detailsService.websiteStyles.textpagelinkfontcolor = this.setup.websiteLinkColor.value;

    this.saveDefaults('textpagelinkfontcolor');

    this.verifyColor(COLOR_KEYS.COMMON_LINK, this.setup.websiteLinkColor.value);

    this.eventsService.dispatchWebsiteStylesChanged({
      key: this.setup.websiteLinkColor.key,
      value: this.setup.websiteLinkColor.value,
    }, this.iFrameService.sandboxWindow);
  }

  public onWebsiteLinkHoverColorChange(value: string): void {
    this.setup.websiteLinkHoverColor.onChange(value);

    this.detailsService.websiteStyles.textpagelinkfonthovercolor = this.setup.websiteLinkHoverColor.value;

    this.saveDefaults('textpagelinkfonthovercolor');

    this.verifyColor(COLOR_KEYS.COMMON_LINK_HOVER, this.setup.websiteLinkHoverColor.value);

    this.afterChange();

    this.eventsService.dispatchWebsiteStylesChanged({
      key: this.setup.websiteLinkHoverColor.key,
      value: value,
    }, this.iFrameService.sandboxWindow);
  }

  public onWebsiteLinkHoverColorReset(): void {
    this.setup.websiteLinkHoverColor.reset();

    this.detailsService.websiteStyles.textpagelinkfonthovercolor = this.setup.websiteLinkHoverColor.value;

    this.saveDefaults('textpagelinkfonthovercolor');

    this.verifyColor(COLOR_KEYS.COMMON_LINK_HOVER, this.setup.websiteLinkHoverColor.value);

    this.eventsService.dispatchWebsiteStylesChanged({
      key: this.setup.websiteLinkHoverColor.key,
      value: this.setup.websiteLinkHoverColor.value,
    }, this.iFrameService.sandboxWindow);
  }

  public onMenuBlocksSpacingChange(value: string): void {
    this.setup.menuBlocksSpacing.setValue(value);

    this.onMenuChange();

    this.afterChange();

    this.eventsService.dispatchWebsiteStylesChanged({
      key: this.setup.menuBlocksSpacing.key,
      value: value,
    }, this.iFrameService.sandboxWindow);
  }

  public onMenuBlocksSpacingReset(): void {
    this.setup.menuBlocksSpacing.reset();

    this.onMenuChange();

    this.afterChange();

    this.eventsService.dispatchWebsiteStylesChanged({
      key: this.setup.menuBlocksSpacing.key,
      value: 0,
    }, this.iFrameService.sandboxWindow);
  }

  public onMenuSizeReducedOnScrollChange(value: boolean): void {
    this.menuSetup.isSizeReducedOnScroll.setValue(value);

    this.afterChange();
  }

  public onLogoVisibilityChange(value: boolean): void {
    this.menuSetup.isLogoVisible.setValue(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleDesktopSetupMatchChange(value: boolean): void {
    this.mobileTitleSetup.isDesktopSetupMatched.setValue(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleVisibilityChange(value: boolean): void {
    this.titleSetup.isVisible.setValue(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleHoverStylesToggled(value: boolean): void {
    this.titleSetup.isHoverStylesEnabled.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleHoverDesignSelect(options: SelectOption[]): void {
    this.titleSetup.hoverDesign.onSelect(options);

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleHoverDesignReset(): void {
    this.titleSetup.hoverDesign.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleHoverColorChange(value: string): void {
    this.titleSetup.hoverColor.setValue(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleHoverColorReset(): void {
    this.titleSetup.hoverColor.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleDesktopSetupMatchChange(value: boolean): void {
    this.mobileSubtitleSetup.isDesktopSetupMatched.setValue(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleVisibilityChange(value: boolean): void {
    this.subtitleSetup.isVisible.setValue(value);

    if (value) {
      this.iFrameService.sandboxWindow.focus();
  
      window.setTimeout(() => {
        this.eventsService.dispatchSubtitleVisibilityChanged({
          target: this.subtitleSetup.isVisible.menu,
          value: this.subtitleSetup.isVisible.value,
        }, this.iFrameService.sandboxWindow);
      });
    }

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onVerticalSpacingChange(value: string): void {
    this.menuSetup.verticalSpacing.onChange(Number.parseInt(value));

    this.emitMenuResize();

    this.onMenuChange();

    this.buttonsService.enableSaveButton();
  }

  public onVerticalSpacingReset(): void {
    this.menuSetup.verticalSpacing.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.buttonsService.enableSaveButton();
  }

  public onMenuCollapseChange(value: string): void {
    this.menuSetup.collapseOptions.setValue(value);

    this.onMenuChange();

    this.afterChange();

    if (value !== COLLAPSE_STYLE_KEYS.AUTO_COLLAPSE) return;

    this.eventsService.dispatchAllMenuItemsCollapse({ target: this.element }, this.iFrameService.sandboxWindow);
  }

  public onMenuCollapseReset(): void {
    this.menuSetup.collapseOptions.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleColorChange(value: string): void {
    this.titleSetup.textColor.onChange(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onTitleColorReset(): void {
    this.titleSetup.textColor.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleColorChange(value: string): void {
    this.subtitleSetup.textColor.onChange(value);

    this.onMenuChange();

    this.afterChange();
  }

  public onSubtitleColorReset(): void {
    this.subtitleSetup.textColor.reset();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleTextColorChange(value: string): void {
    this.mobileTitleSetup.textColor.onChange(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleTextColorReset(): void {
    this.mobileTitleSetup.textColor.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleScalableFontSizeChange(value: string): void {
    this.mobileTitleSetup.scalableFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.mobileTitleSetup.scalableFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onMobileTitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onMobileTitleScalableFontSizeReset(): void {
    this.mobileTitleSetup.scalableFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.mobileTitleSetup.scalableFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onMobileTitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onMobileTitleScalableLineHeightChange(value: string): void {
    this.mobileTitleSetup.scalableLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleScalableLineHeightReset(): void {
    this.mobileTitleSetup.scalableLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleScalableLetterSpacingChange(value: string): void {
    this.mobileTitleSetup.scalableLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleScalableLetterSpacingReset(): void {
    this.mobileTitleSetup.scalableLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleScalableWordSpacingChange(value: string): void {
    this.mobileTitleSetup.scalableWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleScalableWordSpacingReset(): void {
    this.mobileTitleSetup.scalableWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileTitleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleLineHeightChange(value: string): void {
    this.mobileTitleSetup.lineHeight.onChange(Number.parseInt(value));

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileTitleLineHeightReset(): void {
    this.mobileTitleSetup.lineHeight.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleTextColorChange(value: string): void {
    this.mobileSubtitleSetup.textColor.onChange(value);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleTextColorReset(): void {
    this.mobileSubtitleSetup.textColor.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleScalableFontSizeChange(value: string): void {
    this.mobileSubtitleSetup.scalableFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.mobileSubtitleSetup.scalableFontSize.value;

    const newLineHeight = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onMobileSubtitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onMobileSubtitleScalableFontSizeReset(): void {
    this.mobileSubtitleSetup.scalableFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableFontSize);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();

    const expectedLineHeight: number = this.mobileSubtitleSetup.scalableFontSize.value;

    const newLineHeight = expectedLineHeight <= this.maxTitleLineHeight ? expectedLineHeight : this.maxTitleLineHeight;

    this.onMobileSubtitleScalableLineHeightChange(`${newLineHeight}`);
  }

  public onMobileSubtitleScalableLineHeightChange(value: string): void {
    this.mobileSubtitleSetup.scalableLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleScalableLineHeightReset(): void {
    this.mobileSubtitleSetup.scalableLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableLineHeight);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleScalableLetterSpacingChange(value: string): void {
    this.mobileSubtitleSetup.scalableLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleScalableLetterSpacingReset(): void {
    this.mobileSubtitleSetup.scalableLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableLetterSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleScalableWordSpacingChange(value: string): void {
    this.mobileSubtitleSetup.scalableWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleScalableWordSpacingReset(): void {
    this.mobileSubtitleSetup.scalableWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.mobileSubtitleSetup.scalableWordSpacing);

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleLineHeightChange(value: string): void {
    this.mobileSubtitleSetup.lineHeight.onChange(Number.parseInt(value));

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  public onMobileSubtitleLineHeightReset(): void {
    this.mobileSubtitleSetup.lineHeight.reset();

    this.emitMenuResize();

    this.onMenuChange();

    this.afterChange();
  }

  private dispatchFocusOnSubtitle() {
    this.eventsService.dispatchFocusOnSubtitle(this.stylePanel[CustomizerSections.Subtitle].nodes[0], this.iFrameService.sandboxWindow);
  }

  private dispatchCustomStyleChangedEventForOption(option: IStyleOption): void {
    this.dispatchCustomStyleChangedEvent({
      target: option.element,
      key: option.key,
      value: option.value,
    });
  }

  private dispatchCustomStyleChangedEvent(data: { target: HTMLElement, key: string, value: any }) {
    this.eventsService.dispatchCustomStyleChangedEvent(data, this.iFrameService.sandboxWindow);
  }

  public emitMenuResize() {
    this.eventsService.dispatchResize(this.iFrameService.sandboxWindow);
  }

  private afterChange(): void {
    if (this.isDestroyed) return;
    
    this.buttonsService.enableSaveButton();

    this.cdr.detectChanges();
  }

  public onMenuChange(): void {
    this.changesMonitorService.onChange(CHANGE_TYPE.MENU);
  }

  public openCustomBlockRevertModal(): void {
    this.revertBlockToDefaultModalService.open({
      block: {
        blockId: Number.parseInt(this.element.getAttribute('data-block-id')),
        websiteId: this.website.id,
        pageId: this.selectedPage.id,
        pageType: this.selectedPage.type,
      },
    });
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    this.cdr.detach();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
