import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from "../../../services/modals/modals.service";

import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

@Component({
  selector: 'app-textarea-to-modal',
  templateUrl: './textarea-to-modal.component.html',
  styleUrls: ['./textarea-to-modal.component.scss'],
})
export class TextAreaToModalComponent implements OnInit, OnDestroy {
  @Input() value: string;
  @Input() readonly: boolean;
  @Input() rows: number = 2;
  @Input() isResizable: boolean = true;
  @Output() valueChange = new EventEmitter<string>();

  isOpen = false;

  modalId = 'textarea-to-modal' + Math.round(Math.random() * 100000);

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  @ViewChild('modalTextarea')
  set modalTextarea(value: ElementRef) {
    if(value) {
      value.nativeElement.focus();
    }
  }

  constructor(private modalsService: ModalsService,
              private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  open() {
    if (this.readonly) return;

    this.isOpen = true;
    this.modalsService.open(this.modalId);
  }

  close() {
    this.isOpen = false;

    this.modalsService.close(this.modalId);
  }
}
