import {IColorSchemeModel} from './icolor.scheme.model';
import {HexColorSchemeModel} from './hex.color.scheme.model';

export class RgbColorSchemeModel implements IColorSchemeModel {
  public r: number;
  public g: number;
  public b: number;
  public a: number;

  public constructor(public color: string) {
    const values = this.color.match(/([0-9]*[.])?[0-9]+/gi);

    if (!values || values.length < 2) return;

    this.r = parseInt(values[0]);
    this.g = parseInt(values[1]);
    this.b = parseInt(values[2]);

    if (values.length >= 2) this.a = parseFloat(values[3]);
  }

  public static isRgb(color: string) {
    return color.match(/rgba?\(\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\)/gi);
  }

  public toString() : string {
    const rgb = `${this.r},${this.g},${this.b}`;
    return this.a === void 0 ? `rgb(${rgb})` : `rgba(${rgb},${this.a})`;
  }

  public static fromHex(hex: HexColorSchemeModel): RgbColorSchemeModel {
    const rgb = new RgbColorSchemeModel('');

    rgb.r = parseInt(hex.r, 16);
    rgb.g = parseInt(hex.g, 16);
    rgb.b = parseInt(hex.b, 16);
    rgb.a = hex.a ? parseInt(hex.a, 16) : 1;

    return rgb;
  }
}
