export const KEYS = {
  IS_TITLE_VISIBLE: 'IS_TITLE_VISIBLE',
  IS_HOVER_ENABLED: 'IS_HOVER_ENABLED',
  TEXT_COLOR: 'TEXT_COLOR',
  LINE_HEIGHT: 'LINE_HEIGHT',
  LETTER_SPACING: 'LETTER_SPACING',
  WORD_SPACING: 'WORD_SPACING',
  HOVER_COLOR: 'HOVER_COLOR',
  HOVER_DESIGN: 'HOVER_DESIGN',
  SCALABLE_FONT_SIZE: 'SCALABLE_FONT_SIZE',
  SCALABLE_LINE_HEIGHT: 'SCALABLE_LINE_HEIGHT',
  SCALABLE_LETTER_SPACING: 'SCALABLE_LETTER_SPACING',
  SCALABLE_WORD_SPACING: 'SCALABLE_WORD_SPACING',
};

export const TYPES = {
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
  ATTRIBUTE: 'ATTRIBUTE',
};
