import {Component, Input} from '@angular/core';

import {WebsitesLimitExceededModalService} from '../../../services/modals/websites-limit-exceeded/websites-limit-exceeded-modal.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-websites-limit-exceeded-modal',
  templateUrl: './websites-limit-exceeded-modal.component.html',
  styleUrls: ['./websites-limit-exceeded-modal.component.scss'],
})
export class WebsitesLimitExceededModalComponent {
  public buttons: Button[] = [
    {
      text: `Got It`,
      className: 'neutral continue-editing-button',
      onClick: this.closeModal.bind(this),
    },
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: WebsitesLimitExceededModalService) {
  }

  public closeModal(): void {
    this.service.close();
  }
}
