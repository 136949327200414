import {DESCRIPTION_POSITION} from './constants';

export class WebsiteTourProgressItemModel {
  public element: HTMLElement;
  public idx: number;
  public slideNo: number;
  public descriptionTop: number;
  public descriptionLeft: number;
  public isViewed: boolean = false;
  public isBottomLeftCurvedArrow: boolean = false;
  public isBottomRightCurvedArrow: boolean = false;
  public isVerticallyCenteredRightToLeftArrow: boolean = false;
  public isVerticallyCenteredLeftToRightArrow: boolean = false;
  public isHorizontallyCenteredBottomToTopArrow: boolean = false;
  public isHorizontallyCenteredBottomToTop2xArrow: boolean = false;
  public isHorizontallyCenteredBottomToTop3xArrow: boolean = false;
  public isInnerBottomRightCurved30x30: boolean = false;
  public isTopLeftCurved: boolean = false;
  public isDoubleDragDropImage: boolean = false;
  public isHorizontallyCenteredTop: boolean = false;
  public isLeftTopToRightCenter: boolean = false;

  constructor(public id?: number,
              public groupKey?: string,
              public key?: string,
              public location?: string,
              public text?: string,
              public html?: string,
              public orderNo?: number,
              public descriptionPosition?: string,
              public videoUrl?: string) {
    this.isBottomLeftCurvedArrow = this.descriptionPosition === DESCRIPTION_POSITION.BOTTOM_LEFT_CURVED;
    this.isBottomRightCurvedArrow = this.descriptionPosition === DESCRIPTION_POSITION.BOTTOM_RIGHT_CURVED;
    this.isVerticallyCenteredRightToLeftArrow = this.descriptionPosition === DESCRIPTION_POSITION.VERTICALLY_CENTERED_RIGHT;
    this.isVerticallyCenteredLeftToRightArrow = this.descriptionPosition === DESCRIPTION_POSITION.VERTICALLY_CENTERED_LEFT;
    this.isHorizontallyCenteredBottomToTopArrow = this.descriptionPosition === DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_BOTTOM;
    this.isHorizontallyCenteredBottomToTop2xArrow = this.descriptionPosition === DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_BOTTOM_2X;
    this.isHorizontallyCenteredBottomToTop3xArrow = this.descriptionPosition === DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_BOTTOM_3X;
    this.isInnerBottomRightCurved30x30 = this.descriptionPosition === DESCRIPTION_POSITION.INNER_BOTTOM_RIGHT_CURVED_30_30;
    this.isTopLeftCurved = this.descriptionPosition === DESCRIPTION_POSITION.TOP_LEFT_CURVED;
    this.isDoubleDragDropImage = this.descriptionPosition === DESCRIPTION_POSITION.DOUBLE_DRAG_DROP_IMAGE;
    this.isHorizontallyCenteredTop = this.descriptionPosition === DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_TOP;
    this.isLeftTopToRightCenter = this.descriptionPosition === DESCRIPTION_POSITION.LEFT_TOP_TO_RIGHT_CENTER;
  }

  public static clone(item: WebsiteTourProgressItemModel): WebsiteTourProgressItemModel {
    return new WebsiteTourProgressItemModel(
      item.id,
      item.groupKey,
      item.key,
      item.location,
      item.text,
      item.html,
      item.orderNo,
      item.descriptionPosition,
      item.videoUrl,
    );
  }
}
