import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {AuthService} from '../../../../auth/auth.service';
import {WebsitesHttpService} from '../../../../core/services/interaction/http/websites/websites-http.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';

import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';
import {WebsiteDto} from '../../../../core/models/websites/website.dto';

import {BUTTONS_KEYS} from '../../../../core/services/buttons/constants';

@Component({
  selector: 'app-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrls: ['./security-settings.component.scss'],
})
export class SecuritySettingsComponent implements OnInit, OnDestroy {
  public isLoading: boolean = false;

  public website: WebsiteModel = null;

  public copyrightNotice: string = '';

  private account: AccountModel = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private websitesService: WebsitesService,
              private websitesHttpService: WebsitesHttpService,
              private editorControlButtonsService: EditorControlButtonsService,
              private authService: AuthService,
              private buttonsService: ButtonsService) {
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;

      this.initCopyrightNotice();
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;

      this.initCopyrightNotice();
    });

    this.editorControlButtonsService.controlBtnClick.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.save.bind(this));
  }

  private initCopyrightNotice(): void {
    if (!this.account || !this.website) return;

    this.copyrightNotice = this.website.copyrightNotice || `© ${this.account.name}, ${new Date().getFullYear()}. All rights reserved.`;
  }

  onOptionChange() {
    this.buttonsService.enableSaveButton();
  }

  onIsRightClickDisabledClick() {
    this.website.isRightClickDisabled = !this.website.isRightClickDisabled;

    this.onOptionChange();
  }

  onIsCopyrightNoticeEnabledClick() {
    this.website.isCopyrightNoticeEnabled = !this.website.isCopyrightNoticeEnabled;

    this.onOptionChange();
  }

  private save(): void {
    if (!this.website) return;

    this.isLoading = true;

    const onSuccess = () => {
      this.isLoading = false;

      this.websitesService.fetchWebsite();
      
      this.buttonsService.setButtonState(BUTTONS_KEYS.SAVE, false);
    };

    this.websitesHttpService.updateWebsite(this.website.id, WebsiteDto.fromModel(this.website)).pipe(
      catchError(e => {
        console.error(e);
  
        this.isLoading = false;

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  public ngOnDestroy() {
    this.buttonsService.disableAllButtons();

    this.websitesService.fetchWebsite();
    
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
