import {Component} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ThemesService} from './themes.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';

import {ThemeModel} from './theme.model';

@Component({
  selector: 'app-theme-pane',
  templateUrl: './theme-pane.component.html',
  styleUrls: ['./theme-pane.component.scss']
})
export class ThemePaneComponent {
  themes: ThemeModel[] = null;
  ngUnsubscribe = new Subject();

  constructor(public themesService: ThemesService,
              private buttonsService: ButtonsService) {
    themesService
      .getThemes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(themes => this.themes = themes);
  }

  selectTheme(theme: ThemeModel) {
    this.themesService.selectTheme(theme);
    this.themesService.toggleThemePane();
    this.buttonsService.enableSaveButton();
  }
}
