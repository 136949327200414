export const KEYS: { [key: string]: string } = {
  SIDEBAR: 'SIDEBAR',
  PAGES_TOP_MENU: 'PAGES_TOP_MENU',
  DEVICES_BUTTONS: 'DEVICES_BUTTONS',
  SAVE_CHANGES_BUTTON: 'SAVE_CHANGES_BUTTON',
  CANCEL_BUTTON: 'CANCEL_BUTTON',
  NAVBAR_LEFT_BUTTONS_GROUP: 'NAVBAR_LEFT_BUTTONS_GROUP',
  PUBLISH_WEBSITE_BUTTON: 'PUBLISH_WEBSITE_BUTTON',
  VIEW_WEBSITE_BUTTON: 'VIEW_WEBSITE_BUTTON',
  SIDEBAR_PAGES_LIST: 'SIDEBAR_PAGES_LIST',
  SIDEBAR_PAGES_ICON: 'SIDEBAR_PAGES_ICON',
  ACTIVE_PAGE: 'ACTIVE_PAGE',
  ADD_PAGE_BUTTON: 'ADD_PAGE_BUTTON',
  PORTFOLIO_PAGE: 'PORTFOLIO_PAGE',
  PAGE_TO_REMOVE: 'PAGE_TO_REMOVE',
  SIDEBAR_IMAGE_MANAGER_ICON_ACTIVE: 'SIDEBAR_IMAGE_MANAGER_ICON_ACTIVE',
  SIDEBAR_IMAGE_MANAGER: 'SIDEBAR_IMAGE_MANAGER',
  ADD_PORTFOLIO_BUTTON: 'ADD_PORTFOLIO_BUTTON',
  LIBRARY: 'LIBRARY',
  THUMBNAILS_DESCRIPTION: 'THUMBNAILS_DESCRIPTION',
  DRAG_DROP_ACTION_DESCRIPTION: 'DRAG_DROP_ACTION_DESCRIPTION',
  TOP_BUTTONS: 'TOP_BUTTONS',
  BOTTOM_BUTTONS_SIZE_SLIDER: 'BOTTOM_BUTTONS_SIZE_SLIDER',
  BOTTOM_BUTTONS_OUTPUT_SWITCH_BUTTON: 'BOTTOM_BUTTONS_OUTPUT_SWITCH_BUTTON',
  IMAGE_MANAGER_BOTTOM_BUTTONS: 'IMAGE_MANAGER_BOTTOM_BUTTONS',
  IMAGE_MANAGER_SELECTED_IMAGE: 'IMAGE_MANAGER_SELECTED_IMAGE',
  IMAGE_MANAGER_IMAGE_LABEL: 'IMAGE_MANAGER_IMAGE_LABEL',
  PORTFOLIO_DEFAULTS_BUTTON: 'PORTFOLIO_DEFAULTS_BUTTON',
  SIDEBAR_CUSTOMIZER_TITLE: 'SIDEBAR_CUSTOMIZER_TITLE',
  SIDEBAR_CUSTOMIZER_ICON_ACTIVE: 'SIDEBAR_CUSTOMIZER_ICON_ACTIVE',
  SIDEBAR_CUSTOMIZER: 'SIDEBAR_CUSTOMIZER',
  SIDEBAR_BLOCKS_ICON_ACTIVE: 'SIDEBAR_BLOCKS_ICON_ACTIVE',
  SIDEBAR_BLOCKS_MENU_CATEGORY_TITLE: 'SIDEBAR_BLOCKS_MENU_CATEGORY_TITLE',
  SIDEBAR_BLOCKS: 'SIDEBAR_BLOCKS',
  SIDEBAR_BLOCKS_THUMBNAIL: 'SIDEBAR_BLOCKS_THUMBNAIL',
};

export const GROUPS_KEYS: { [key: string]: string } = {
  TOOLBARS: 'TOOLBARS',
  PAGES: 'PAGES',
  IMAGES: 'IMAGES',
  BLOCKS: 'BLOCKS',
  CUSTOMIZER: 'CUSTOMIZER',
};

export const GROUPS_ITEMS: { [key: string]: string[] } = {
  [GROUPS_KEYS.TOOLBARS]: [
    KEYS.SIDEBAR,
    KEYS.PAGES_TOP_MENU,
    KEYS.DEVICES_BUTTONS,
    KEYS.SAVE_CHANGES_BUTTON,
    KEYS.CANCEL_BUTTON,
    KEYS.NAVBAR_LEFT_BUTTONS_GROUP,
    KEYS.PUBLISH_WEBSITE_BUTTON,
    KEYS.VIEW_WEBSITE_BUTTON,
  ],
  [GROUPS_KEYS.PAGES]: [
    KEYS.SIDEBAR_PAGES_LIST,
    KEYS.ACTIVE_PAGE,
    KEYS.ADD_PAGE_BUTTON,
    KEYS.PORTFOLIO_PAGE,
    KEYS.PAGE_TO_REMOVE,
  ],
  [GROUPS_KEYS.IMAGES]: [
    KEYS.SIDEBAR_IMAGE_MANAGER_ICON_ACTIVE,
    KEYS.SIDEBAR_IMAGE_MANAGER,
    KEYS.ADD_PORTFOLIO_BUTTON,
    KEYS.LIBRARY,
    KEYS.THUMBNAILS_DESCRIPTION,
    KEYS.DRAG_DROP_ACTION_DESCRIPTION,
    KEYS.TOP_BUTTONS,
    KEYS.BOTTOM_BUTTONS_SIZE_SLIDER,
    KEYS.BOTTOM_BUTTONS_OUTPUT_SWITCH_BUTTON,
    KEYS.IMAGE_MANAGER_BOTTOM_BUTTONS,
    KEYS.IMAGE_MANAGER_SELECTED_IMAGE,
    KEYS.IMAGE_MANAGER_IMAGE_LABEL,
    KEYS.PORTFOLIO_DEFAULTS_BUTTON,
  ],
  [GROUPS_KEYS.BLOCKS]: [
    KEYS.SIDEBAR_BLOCKS_ICON_ACTIVE,
    KEYS.SIDEBAR_BLOCKS_MENU_CATEGORY_TITLE,
    KEYS.SIDEBAR_BLOCKS,
    KEYS.SIDEBAR_BLOCKS_THUMBNAIL,
  ],
  [GROUPS_KEYS.CUSTOMIZER]: [
    KEYS.SIDEBAR_CUSTOMIZER_TITLE,
    KEYS.SIDEBAR_CUSTOMIZER_ICON_ACTIVE,
    KEYS.SIDEBAR_CUSTOMIZER,
  ],
};
