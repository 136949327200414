import * as moment from 'moment';

import {AccountModel} from "../../accounts/account.model";
import {WebsiteModel} from "../../websites/website.model";
import {EducationInstitutionModel} from "../institutions/education-institution.model";
import {EducationStudentModel} from "../students/education-student.model";

export class EducationClassModel {
  public isExpired: boolean = false;

  constructor(
    public id?: number,
    public institutionId?: number,
    public institution?: EducationInstitutionModel,
    public teachersUsers?: AccountModel[],
    public students?: EducationStudentModel[],
    public websites?: WebsiteModel[],
    public name?: string,
    public number?: string,
    public signUpCode?: string,
    public description?: string,
    public startDate?: string,
    public endDate?: string,
    public startDateFormatted?: string,
    public endDateFormatted?: string,
    public createdBy?: AccountModel,
    public deletedBy?: AccountModel,
    public createdAt?: string,
    public updatedAt?: string,
    public deletedAt?: string
  ) {
    this.isExpired = moment(this.endDate).isBefore(moment().subtract(30, 'days'));
  }
}
