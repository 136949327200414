export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `Видовище перед нашими очима справді вражало.`,
      `З гучномовця над дверима скрипів записаний голос.`,
      `Земля була схожа на серп, який дивовижно сяяв далеко позаду судна, що летіло вперед.`,
      `Ледь усвідомивши, що відбувається, ми вирушили від землі.`,
      `Настала ніч, коли впала перша зірка.`,
      `Лик місяця сховався в тіні.`,
      `Обидві сторони моєї натури поєднувала пам’ять.`,
      `Полум’я згасло, і він дивився на зірки крізь вікно.`,
      `Подорож назад обіцяла бути самотньою.`,
      `Туман огортав корабель ще три години після відбуття з порту.`,
      `Усе їхнє обладнання й інструменти так чи інакше було живим.`,
    ],
    [KEYS.ALPHABET]: [
      'АБВГҐДЂЕЁЄЖЗЅИІЇЙЈКЛЉМНЊОПРСТЋУЎФХЦЧЏШЩЪЫЬЭЮЯабвгґдђеёєжзѕиіїйјклљмнњопрстћуўфхцчџшщъыьэюя ‘?’“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `Можливість поглянути на якусь далеку планету має таку саму силу пробуджувати й очищувати людський розум, як церковні наспіви, відомі картини або уривки віршів визначніших поетів. Такий вплив завжди позитивний.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
