import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DeleteNodeModalService {
  public readonly id = 'delete-node-modal';

  public isOpened = false;

  public node: any = null;

  public onNodeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.node);
  public onRemoveSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.node);

  public constructor(private modalsService: ModalsService) {
  }

  public open(node): void {
    this.node = node;

    this.onNodeSubject.next(node);
    this.modalsService.open(this.id);
    this.isOpened = true;
  }

  public removeNode(node) {
    this.onRemoveSubject.next(node);
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;
    this.node = null;

    this.modalsService.close(this.id);
  }
}
