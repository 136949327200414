export const KEYS = {
  SPACE_BETWEEN_BLOCKS: 'SPACE_BETWEEN_BLOCKS',
  MENU_BLOCKS_SPACING: 'MENU_BLOCKS_SPACING',
  WEBSITE_MARGIN: 'WEBSITE_MARGIN',
  WEBSITE_LINK_COLOR: 'WEBSITE_LINK_COLOR',
  WEBSITE_LINK_HOVER_COLOR: 'WEBSITE_LINK_HOVER_COLOR',
};

export const TYPES = {
  ATTRIBUTE: 'ATTRIBUTE',
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
};
