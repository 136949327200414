import {Injectable} from '@angular/core';

import {Observable, Subject, BehaviorSubject, Subscription} from 'rxjs';

import {EducationStudentsPortfoliosHttpService} from '../../../interaction/http/education/students/websites/portfolios/education-students-portfolios-http.service';
import {AuthService} from '../../../../../auth/auth.service';

import {PortfolioDefaultsModel} from '../../../../models/images/default/portfolio-defaults.model';
import {ImageModel} from '../../../../models/images/image.model';
import {EducationStudentPortfolioModel} from '../../../../models/education/portfolios/education-student-portfolio.model';
import {IDefaultResponse} from '../../../../models/responses/i-default-reponse';

import {StudentImageManagerTabs} from './constants';

@Injectable()
export class StudentImageManagerService {
  public activeTabSubject: BehaviorSubject<StudentImageManagerTabs> = new BehaviorSubject<StudentImageManagerTabs>('student');
  
  public selectedPortfolioSubject: BehaviorSubject<EducationStudentPortfolioModel> = new BehaviorSubject<EducationStudentPortfolioModel>(null);
  public portfolioDefaultsSubject: BehaviorSubject<PortfolioDefaultsModel> = new BehaviorSubject<PortfolioDefaultsModel>(null);
  public portfolioImagesSubject: BehaviorSubject<ImageModel[]> = new BehaviorSubject<ImageModel[]>(null);
  
  private isLocked: boolean = false;

  constructor(
    private httpService: EducationStudentsPortfoliosHttpService,
    private authService: AuthService,
  ) {
    this.authService.accountSubject.subscribe(() => {
      this.selectedPortfolioSubject.next(null);
      this.portfolioDefaultsSubject.next(null);
      this.portfolioImagesSubject.next(null);
    });
  }

  public fetchPortfolioDefaults({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Subscription {
    return this.httpService.getDefaults({ websiteId, portfolioId }).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      this.portfolioDefaultsSubject.next(portfolioDefaults);
    });
  }

  public fetchStudentPortfolioImages({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Subscription {
    return this.httpService.getImages({ websiteId, portfolioId }).subscribe((images: ImageModel[]) => {
      this.portfolioImagesSubject.next(images);
    });
  }

  public deleteAll({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Observable<IDefaultResponse> {
    return this.httpService.deleteAllImages({ websiteId, portfolioId });
  }

  // public replaceImage(portfolioData: EducationExhibitionPortfolioModel, imageId: number, file: File): Observable<ImageModel> {
  //   const formData: FormData = new FormData();
    
  //   formData.append('file', file, file.name);

  //   return this.httpService.replaceImage(portfolioData, imageId, formData);
  // }

  // public moveImageToLibrary(portfolioData: EducationExhibitionPortfolioModel, imageId: number): Subscription {
  //   return this.httpService.moveImageToLibrary(portfolioData, imageId).subscribe(() => {});
  // }

  // public moveMultipleImagesToLibrary(portfolioData: EducationExhibitionPortfolioModel, imagesIds: number[]): Subscription {
  //   return this.httpService.moveMultipleImagesToLibrary(portfolioData, imagesIds).subscribe(() => {});
  // }

  public setActiveTab(key: StudentImageManagerTabs, options: { force?: boolean, lock?: boolean, unlock?: boolean } = { force: false, lock: false, unlock: false }): void {
    if (options.unlock) {
      this.isLocked = false;
    }

    if (this.isLocked && !options.force) {
      return;
    }
    
    this.activeTabSubject.next(key);

    this.isLocked = !!options.lock;
  }
}
