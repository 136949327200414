import {SetupOptionModel} from '../option/setup-option.model';
import {SelectOption} from '../../../select/option/option.model';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {ATTRIBUTE, KEYS, CLASSES, CLASSES_LIST, OPTIONS} from './constants';
import {DEVICES} from '../../../../services/editor-devices/constants';

export class BlockWidthTypeModel extends SetupOptionModel {
  public element: HTMLElement;

  public selectedOption: SelectOption = null;

  public get className(): string {
    return CLASSES[this.value];
  }

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.isEnabled = options.device === DEVICES.DESKTOP;

    if (!this.isEnabled) return;

    this.element = element;

    this.value = this.element.getAttribute(ATTRIBUTE) || KEYS.FULL_WIDTH;
    this.selectedOption = OPTIONS.find((option: SelectOption) => option.value === this.value);
  }

  public onChange(key: string): void {
    if (!this.isEnabled) return;

    this.value = key || KEYS.FULL_WIDTH;

    this.selectedOption = OPTIONS.find((option: SelectOption) => option.value === this.value);

    this.element.setAttribute(ATTRIBUTE, this.value);

    this.removeClasses();

    if (!CLASSES[this.value]) return;

    this.element.classList.add(CLASSES[this.value]);
  }

  public reset(): void {
    if (!this.isEnabled) return;

    this.value = KEYS.FULL_WIDTH;

    this.selectedOption = OPTIONS.find((option: SelectOption) => option.value === this.value);

    this.element.setAttribute(ATTRIBUTE, this.value);

    this.removeClasses();

    if (!CLASSES[this.value]) return;

    this.element.classList.add(CLASSES[this.value]);
  }

  private removeClasses(): void {
    CLASSES_LIST.forEach(className => this.element.classList.remove(className));
  }
}
