import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {PlanDto} from '../../../../models/plan/plan.dto';
import { Observable } from 'rxjs';

@Injectable()
export class PlansHttpService {
  private model = 'plans';

  constructor(private http: HttpClient) {}

  getPlansWithPermissions(): Observable<any> {
    return this.http.get(`/api/admin/${this.model}`);
  }

  save(plan: PlanDto): Observable<any> {
    return this.http.put(`/api/admin/${this.model}`, plan);
  }

  addPermission({ planId, permissionId }): Observable<any> {
    return this.http.post(`/api/admin/${this.model}/${planId}/permissions/${permissionId}`, {});
  }

  removePermission({ planId, permissionId }): Observable<any> {
    return this.http.delete(`/api/admin/${this.model}/${planId}/permissions/${permissionId}`);
  }
}
