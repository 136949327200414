import {Injectable} from '@angular/core';

import {ButtonsService} from '../../../buttons/buttons.service';
import { WebsiteDesignerService } from '../../../../../application/main/website-designer/website-designer.service';

@Injectable()
export class SidebarCustomizerElementService {
  private selected: HTMLElement = null;

  private attrs = {
    linkable: 'data-linkable',
  };

  private tags = {
    anchor: 'A',
  };

  private editableTypes = [
    'ELEMENT',
    'DIVIDER',
  ];

  public isCouldBeWrappedInLink: boolean = false;

  public get element(): HTMLElement {
    return this.selected;
  }

  public set element(value: HTMLElement) {
    this.selected = value;

    this.isCouldBeWrappedInLink = this.isLinkable();
  }

  public get isWrapped(): boolean {
    return !!this.getParentAnchor();
  }

  constructor(
    private buttonsService: ButtonsService,
    private websiteDesignerService: WebsiteDesignerService,
  ) {

    this.websiteDesignerService.editingSubject.subscribe(options => {
      if (!options || !this.editableTypes.includes(options.editingType)) {
        return;
      }

      this.element = options.element ? $(options.element)[0] : options.element;
    });
  }

  public wrapInAnchor(uri?: string, isCustomLink: boolean = false): void {
    const elem = this.element;

    if (!elem || !elem.parentElement) {
      console.error(`>> wrapInAnchor: elem is not in DOM tree.`);

      return;
    }

    uri = isCustomLink ? this.prepareLink(uri) : uri;

    const anchor = document.createElement(this.tags.anchor);

    anchor.classList.add('image-link-wrapper');
    anchor.setAttribute('href', uri);
    anchor.setAttribute('target', isCustomLink ? '_blank' : '');
    anchor.appendChild(elem.cloneNode());

    elem.parentElement.replaceChild(anchor, elem);

    this.selected = anchor.children[0] as HTMLElement;
  }

  public unwrapFromAnchor(): void {
    const parent = this.selected.parentElement;

    parent.insertAdjacentHTML('beforebegin', parent.innerHTML);

    this.selected = parent.previousElementSibling as HTMLElement;
    parent.remove();
    this.buttonsService.enableSaveButton();
  }

  public getLink(): string {
    const anchor = this.getParentAnchor();

    return anchor.getAttribute('href');
  }

  private prepareLink(uri?: string): string {
    return uri.startsWith('http') ? uri : `http://${uri}`;
  }

  private isLinkable(): boolean {
    return this.selected && this.selected.hasAttribute(this.attrs.linkable);
  }

  private getParentAnchor(): HTMLElement {
    const parent: HTMLElement = this.selected.parentElement;

    return parent && parent.tagName === this.tags.anchor ? parent : null;
  }
}
