import {Injectable} from "@angular/core";
import {ImageManagerService} from '../application/main/image-manager/image-manager.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {ImageProcessModel} from '../models/image-processes/image-process.model';
import {ImageProcessTypeModel} from '../models/image-processes/image-process-type.model';

@Injectable()
export class ImageProcessesService {
  public modalId: string = 'image-processes-modal';
  public newProcessOptionName: string = 'New Process';

  public imageProcessesSubject: BehaviorSubject<Array<ImageProcessModel>> = new BehaviorSubject([]);
  public imageProcessesTypesSubject: BehaviorSubject<Array<ImageProcessTypeModel>> = new BehaviorSubject([]);
  public imageProcessesTypesMap: Map<number, string> = null;

  constructor(private imageManagerService: ImageManagerService,
              private authService: AuthService) {
    this.initImageProcessesData();

    this.authService.onSignIn.subscribe(() => this.initImageProcessesData());
    this.authService.onSignOut.subscribe(() => this.clearImageProcessesData());
  }

  private initImageProcessesData() {
    this.updateImageProcesses();
    this.updateImageProcessesTypes();
  }

  public updateImageProcesses() {
    return this.imageManagerService.getImageProcesses().subscribe((imageProcesses: ImageProcessModel[]) => {
      this.imageProcessesSubject.next(imageProcesses);
    }, console.log);
  }

  public updateImageProcessesTypes() {
    this.imageManagerService.getImageProcessesTypes().subscribe((imageProcessesTypes: ImageProcessTypeModel[]) => {
      this.imageProcessesTypesSubject.next(imageProcessesTypes);

      this.imageProcessesTypesMap = imageProcessesTypes.reduce((map, imageProcessType) => {
        return map.set(imageProcessType.id, imageProcessType.type);
      }, new Map());
    }, console.log);
  }

  private clearImageProcessesData() {
    this.imageProcessesSubject.next(null);
    this.imageProcessesTypesSubject.next(null);
  }

  public addNewProcess(imageProcess: ImageProcessModel): Observable<ImageProcessModel> {
    return this.imageManagerService.addNewProcess(imageProcess);
  }

  public deleteImageProcesses(imageProcesses: ImageProcessModel[]): Observable<{ notRemovedIds: number[] }> {
    const ids = imageProcesses.map(process => process.processId);

    return this.imageManagerService.deleteImageProcesses(ids);
  }

  public getProcessByName(processes: ImageProcessModel[], name: string): ImageProcessModel {
    if (!processes) return null;

    return processes.find(p => p.processName === name);
  }

  public getProcessById(processes: ImageProcessModel[], id: number): ImageProcessModel {
    if (!processes) return null;

    return processes.find(p => p.processId === id);
  }
}
