import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {DiskSpaceModel} from '../../../../models/disk-space/disk-space.model';

@Injectable()
export class DashboardHttpService {
  private model = 'dashboard';

  constructor(private http: HttpClient) {}

  public getDiskSpaceData(): Observable<DiskSpaceModel> {
    return this.http.get(`/api/${this.model}/disk-space`).pipe(
      map((res: DiskSpaceModel) => DiskSpaceModel.normalize(res)),
    );
  }
}
