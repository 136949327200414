
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ChangeDetectorRef} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import {throwError, Subject, Observable} from 'rxjs';
import {takeUntil, catchError, finalize} from 'rxjs/operators';

import {LogoManagerService} from '../../../../core/services/images/logos/logo-manager.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {ImagesService} from '../../../../services/images.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';
import {ObjectRemoveErrorModalService} from '../../../services/modals/object-remove-error/object-remove-error-modal.service';

import {LogoModel} from '../../../../core/models/logo/logo.model';
import {LogoDto} from '../../../../core/models/logo/logo.dto';
import {ObjectRemoveErrorDto} from '../../../../core/models/errors/objects/remove/object-remove-error.dto';

import {AppAnimations} from '../../../../app-animations';

import {ERRORS_KEYS} from '../../../../shared/services/errors/constants';
import {KEYS} from '../../modals/object-remove-error/constants';

@Component({
  selector: 'app-logo-manager',
  templateUrl: './logo-manager.component.html',
  styleUrls: ['./logo-manager.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class LogoManagerComponent implements OnInit, OnDestroy {
  @Input() isModal = false;

  @Output() enlargeHandler: EventEmitter<SafeResourceUrl> = new EventEmitter<SafeResourceUrl>();

  public isNewDragging: boolean = false;
  public isEnlarged: boolean = false;
  public isLoading: boolean = true;

  public logos: LogoModel[] = null;
  public selectedLogos: LogoModel[] = [];
  public firstClickedLogo: LogoModel;

  public logoUri: SafeResourceUrl = null;

  public categories = [
    {
      text: 'Logo',
      textPlural: 'Logos',
      allowedMimeTypes: ['image/png', 'image/gif', 'image/jpeg'],
    },
  ];

  public views = {
    list: {
      key: 'list',
      icon: 'fa-th',
    },
    enlarged: {
      key: 'enlarged',
      icon: 'fa-stop',
    },
  };

  public currentView = this.views.list.key;

  public editableLogoId: number = null;

  private keyDownEvent = this.onKeyDown.bind(this);

  private errorsMapping = {
    [ERRORS_KEYS.OBJECTS_USED]: this.onObjectsUsed.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isEmpty(): boolean {
    const { logos } = this;
    return logos && logos.length === 0;
  }

  public get selectedCategory() {
    return this.logoManagerService.selectedCategory;
  }
  public set selectedCategory(value) {
    this.logoManagerService.selectedCategory = value;
  }

  public get nOfLogos() {
    const { logos } = this;

    return logos ? logos.length : 0;
  }

  public get nOfSelectedLogos() {
    const { selectedLogos } = this;

    return selectedLogos ? selectedLogos.length : 0;
  }

  constructor(private logoManagerService: LogoManagerService,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private utilsService: UtilsService,
              private buttonsService: ButtonsService,
              private objectRemoveErrorModalService: ObjectRemoveErrorModalService,
              private imagesService: ImagesService,
              private cdr: ChangeDetectorRef,
              private sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    this.initCategory();

    this.logoManagerService.isLoadingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;

      this.cdr.detectChanges();
    });

    this.logoManagerService.logosSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((logos: LogoModel[]) => {
      this.logos = logos;

      this.cdr.detectChanges();
    });
  }

  private initCategory() {
    const logoCategory = this.categories.find(category => category.text === 'Logo');

    this.selectCategory(logoCategory);
  }

  public selectCategory(category) {
    this.selectedCategory = category;
    
    this.logoManagerService.loadLogos();
  }

  public onLogoClick(e: MouseEvent, logo: LogoModel) {
    const { firstClickedLogo, logos } = this;
    const isNewSelected = !e.shiftKey || !firstClickedLogo || firstClickedLogo === logo;

    logos.forEach(logo => {
      logo.isSelected = false;

      if (isNewSelected) logo.isFirstClicked = false;
    });

    if (isNewSelected) return this.selectOneItem(logo);

    return this.selectManyItems(logos, logo);
  }

  private selectOneItem(logo: LogoModel) {
    logo.isFirstClicked = true;
    logo.isSelected = !logo.isSelected;

    this.firstClickedLogo = logo;

    this.initselectedLogos();
  }

  private selectManyItems(logos: LogoModel[], logo: LogoModel) {
    const selectedIdx = logos.findIndex(item => item === this.firstClickedLogo);
    const clickedLogoIdx = logos.findIndex(item => item === logo);

    const startIdx = selectedIdx < clickedLogoIdx ? selectedIdx : clickedLogoIdx;
    const endIdx = selectedIdx > clickedLogoIdx ? selectedIdx : clickedLogoIdx;

    for (let i = startIdx; i <= endIdx; i++) logos[i].isSelected = true;

    this.initselectedLogos();
  }

  public onDragEnter() {
    this.isNewDragging = true;
  }

  public onDragLeave() {
    this.isNewDragging = false;
  }

  public onDrop(files: File[]) {
    this.isNewDragging = false;

    return files.forEach(file => {
      const logo = this.addLogoMock(file);
      this.uploadFile(file, logo).subscribe(res => this.initLogo(logo, res));
    });
  }

  public onUpload(ev) {
    const files = Array.from(<File[]>ev.target.files);

    files.forEach(file => {
      const logo = this.addLogoMock(file);

      this.uploadFile(file, logo).pipe(
        catchError(err => {
          return throwError(() => err);
        }),
        finalize(() => {
          ev.target.value = null;
        }),
      ).subscribe((res: LogoDto) => {
        this.initLogo(logo, res);
      });
    });
  }

  private uploadFile(file: File, logo: LogoModel): Observable<LogoDto> {
    return this.logoManagerService.uploadLogo(file).pipe(
      catchError(e => {
        logo.isError = true;
        logo.isUploading = false;
        
        this.initErrorMessage(logo, e);
        
        return throwError(() => e);
      }),
    );
  }

  private initErrorMessage(logo: LogoModel, e): void {
    if (e instanceof HttpErrorResponse) {
      logo.errorMessage = e.error ? e.error.message : 'Server error.';
      
      return;
    }

    logo.errorMessage = e;
  }

  private initLogo(logo: LogoModel, res: LogoDto) {
    logo.id = res.Id;
    logo.userId = res.UserId;
    logo.fileName = res.FileName;
    logo.originalFileName = res.OriginalFileName;
    logo.link = res.Link;
    logo.fileSize = res.FileSize;
    logo.widthPx = res.WidthPx;
    logo.heightPx = res.HeightPx;
    logo.isUploading = false;
  }

  private addLogoMock(file: File): LogoModel {
    const logo = new LogoModel(
      void 0,
      void 0,
      void 0,
      file.name,
      void 0,
      void 0,
      void 0,
      '',
      false,
      false,
      true,
    );
    this.logos.push(logo);
    return logo;
  }

  public selectAll() {
    this.logos.forEach(logo => logo.isSelected = true);
    
    this.initselectedLogos();
  }

  public deselectAll() {
    this.logos.forEach(logo => logo.isSelected = false);

    this.initselectedLogos();
  }

  private initselectedLogos() {
    this.selectedLogos = this.logos.filter(logo => logo.isSelected);
  }

  // async ok
  public async onDelete() {
    const { selectedLogos } = this;
    const ids = selectedLogos.map(logo => logo.id);

    if (ids.length === 0) return;

    const isCurrentPageGood: boolean = await this.isCurrentPageGoodBeforeDelete({
      key: KEYS.LOGO,
      objects: selectedLogos,
    });

    if (!isCurrentPageGood) {
      const err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } } = {
        key: 'CURRENT_PAGE_ERROR',
        message: null,
        data: {
          key: KEYS.LOGO,
          usedObjectsData: null,
        },
      };
      
      this.objectRemoveErrorModalService.open(err);

      return;
    }

    selectedLogos.forEach(logo => {
      logo.isUploading = true;
    });

    const onSuccess = () => {
      this.logos = this.logos.filter(logo => !selectedLogos.includes(logo));

      this.initselectedLogos();
    };

    this.logoManagerService.deleteLogos(ids).pipe(
      catchError(e => {
        console.dir(e);
  
        if (this.errorsMapping[e.error.key]) {
          this.errorsMapping[e.error.key](e.error);
        }
  
        selectedLogos.forEach(logo => {
          logo.isUploading = false;
        });

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  // async ok
  private async isCurrentPageGoodBeforeDelete(data: { key, objects: LogoModel[] }): Promise<boolean> {
    if (!this.isModal) return true;

    return new Promise<boolean>((resolve, reject) => {
      this.eventsService.addFrameListener('objectsDataByObjectsId', (e: CustomEvent) => {
        try {
          const { objects } = e.detail;

          resolve(objects.length === 0);
        } catch (e) {
          resolve(false);
        }
      }, { once: true });

      this.eventsService.dispatchRequestObjectsDataByObjectsId(data, this.iFrameService.sandboxWindow);
    });
  }

  public onSelect(logo: LogoModel) {
    this.eventsService.dispatchLogoImageSelected({
      logo,
      element: this.logoManagerService.element,
    }, this.iFrameService.sandboxWindow);

    this.onClose();

    this.buttonsService.enableSaveButton();
  }

  public onLinkCopy(document: LogoModel): Promise<void> {
    return this.utilsService.copyToClipboard(document.link);
  }

  public onClose() {
    this.logoManagerService.close();

    this.logos = [];
    this.selectedLogos = [];
    this.firstClickedLogo = null;
  }

  public onViewChange(key) {
    if (key === this.views.list.key) {
      this.currentView = key;

      return;
    }

    if (!this.firstClickedLogo) return;

    this.currentView = key;

    this.cdr.detectChanges();
    
    this.enlarge(this.firstClickedLogo);
  }

  public enlarge(logo: LogoModel) {
    this.isEnlarged = true;

    this.logoUri = this.sanitizer.bypassSecurityTrustResourceUrl(logo.link);

    this.addEnlargementHandlers();

    this.cdr.detectChanges();
  }

  private addEnlargementHandlers(): void {
    window.addEventListener('keydown', this.keyDownEvent);
  }

  private onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode !== 27) return;

    this.closeEnlargement();

    this.removeEnlargementHandlers();
  }

  private removeEnlargementHandlers(): void {
    window.removeEventListener('keydown', this.keyDownEvent);
  }

  public onThumbLoaded(logo: LogoModel) {
    logo.isThumbLoaded = true;
  }

  public getLogoInfo(logo: LogoModel) {
    return this.imagesService.getImageData(logo);
  }

  public closeEnlargement() {
    if (!this.isEnlarged) return;

    this.currentView = this.views.list.key;

    this.isEnlarged = false;

    this.logoUri = null;

    this.cdr.detectChanges();
  }

  public editName(event, logo: LogoModel): void {
    event.stopPropagation();

    const input = event.target.nextElementSibling;

    this.editableLogoId = logo.id;

    input.value = logo.originalFileName;

    setTimeout(() => input.focus());
  }

  public updateName(event): void {
    const input = event.target;

    if (!input.value || !this.editableLogoId) return;

    const editableLogo = this.logos.find(video => video.id === this.editableLogoId);

    if (!editableLogo || input.value === editableLogo.originalFileName) {
      this.editableLogoId = null;

      return;
    }

    this.logoManagerService.updateName(this.editableLogoId, input.value).pipe(
      catchError(e => {
        console.error(e);
        
        input.value = editableLogo.originalFileName;

        return throwError(() => e);
      }),
      finalize(() => {
        this.editableLogoId = null;
      }),
    ).subscribe(() => {
      editableLogo.originalFileName = input.value;
    });
  }

  private onObjectsUsed(err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } }): void {
    this.objectRemoveErrorModalService.open(err);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.removeEnlargementHandlers();
  }
}
