import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject} from 'rxjs';

import {ISocketPublishMessageDataModel} from '../../core/models/sockets/message/publish/i-publish-message-data.model';
import {WebsiteModel} from '../../core/models/websites/website.model';

import {SOCKET_PUBLISHING_STATUSES, WEBSITE_PUBLISHING_STATUSES} from '../publish-website/constants';

@Injectable()
export class IsPublishingService {
  public openWarningModal: Subject<boolean> = new Subject<boolean>();
  public isPublishingSubject: BehaviorSubject<{ websiteId: number, isActiveWebsite: boolean, isPublishing: boolean }> = new BehaviorSubject<{ websiteId: number, isActiveWebsite: boolean, isPublishing: boolean }>({ websiteId: null, isActiveWebsite: false, isPublishing: false });

  private website: WebsiteModel;

  private get activeWebsiteId(): number {
    return this.website ? this.website.id : null;
  }

  constructor(
  ) {
  }

  public handleActiveWebsite(website: WebsiteModel): void {
    this.website = website;

    if (!website) {
      return;
    }

    console.dir(`-->> website: ${website.publishStatus} | ${WEBSITE_PUBLISHING_STATUSES.includes(website.publishStatus)}`);

    this.isPublishingSubject.next({
      websiteId: website.id,
      isActiveWebsite: true,
      isPublishing: WEBSITE_PUBLISHING_STATUSES.includes(website.publishStatus),
    });
  }

  public handleSocketPublishData(data: ISocketPublishMessageDataModel): void {
    if (!data || !data.status || data.websiteId !== this.activeWebsiteId) {
      return;
    }

    console.dir(`-->> socket: ${SOCKET_PUBLISHING_STATUSES.includes(data.status)}`);

    this.isPublishingSubject.next({
      websiteId: data.websiteId,
      isActiveWebsite: true,
      isPublishing: SOCKET_PUBLISHING_STATUSES.includes(data.status),
    });
  }
}
