import {IncludedAddOnsDto} from './included-add-ons.dto';

import {AddOnModel} from '../../add-ons/add-on.model';

export class IncludedAddOnsModel {
  constructor(public key?: string,
              public quantity?: number,
              public addOn?: AddOnModel) {
  }

  public static normalize(res: IncludedAddOnsDto): IncludedAddOnsModel {
    if (!res) return null;

    return new IncludedAddOnsModel(
      res.Key,
      res.Quantity,
      AddOnModel.normalize(res.AddOn),
    );
  }
}
