export const KEYS = {
  FONT_FAMILY: 'FONT_FAMILY',
  FONT_WEIGHT: 'FONT_WEIGHT',
  FONT_SIZE: 'FONT_SIZE',
  FONT_STYLE: 'FONT_STYLE',
  TEXT_TRANSFORM: 'TEXT_TRANSFORM',
  TEXT_ALIGNMENT: 'TEXT_ALIGNMENT',
  TEXT_DECORATION: 'TEXT_DECORATION',
  TRANSPARENCY: 'TRANSPARENCY',
};
