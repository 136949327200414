import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../modals.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';

import {FailedImagesStepModel} from '../../../../core/models/images/failed-images-step/failed-images-step.model';
import {IFailedImagesHandlingMessageDataModel} from '../../../../core/models/sockets/message/failed-images-handling/i-failed-images-handling-message-data.model';

import {HANDLING_STEPS} from './constants';

@Injectable()
export class FailedImagesHandlingModalService {
  public readonly id = 'failed-images-handling-modal';

  public isOpened = false;

  public stepsSubject: BehaviorSubject<FailedImagesStepModel[]> = new BehaviorSubject<FailedImagesStepModel[]>([]);

  public steps: FailedImagesStepModel[] = [
    new FailedImagesStepModel(HANDLING_STEPS.STARTED, `Initialization`, false),
    new FailedImagesStepModel(HANDLING_STEPS.DB_DATA_COLLECTING, `Collecting Database Data`, true),
    new FailedImagesStepModel(HANDLING_STEPS.S3_DATA_COLLECTING, `Collecting S3 Data`, true),
    new FailedImagesStepModel(HANDLING_STEPS.FILE_HANDLED, `Handling Files`, true),
    new FailedImagesStepModel(HANDLING_STEPS.DONE, `Done`, false),
  ];

  public constructor(private modalsService: ModalsService,
                     private socketsService: SocketsService) {
    this.socketsService.failedImagesHandlingSubject.subscribe((data: IFailedImagesHandlingMessageDataModel) => this.onMessage(data));
  }

  private onMessage(data: IFailedImagesHandlingMessageDataModel) {
    if (!data || !data.status) return;

    const idx: number = this.steps.findIndex((step: FailedImagesStepModel) => step.key === data.status);

    if (idx === -1) return this.onFailed();

    this.steps.forEach((step: FailedImagesStepModel, i: number) => {
      step.isDone = i < idx;
      step.isInProgress = i === idx;

      if (step.key === HANDLING_STEPS.DONE && data.status === HANDLING_STEPS.DONE) {
        step.isDone = true;
        step.isInProgress = false;
      }
    });

    this.steps[idx].count = data.count || 0;
    this.steps[idx].totalCount = data.totalCount || 0;

    this.stepsSubject.next(this.steps);
  }

  private onFailed() {
    this.close();
  }

  public open(): void {
    this.modalsService.open(this.id);
    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
