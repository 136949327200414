export const IMAGE_RESOLUTIONS = {
  NON_FULL_BLEED_IMAGE: {
    type: 'NON_FULL_BLEED_IMAGE',
    message: 'Uploaded image has low resolution. Uploading images with higher resolution would look better on bigger screens.',
    longestSideMinPx: 1000,
  },
  HALF_BLEED_IMAGE: {
    type: 'HALF_BLEED_IMAGE',
    message: 'Upload a larger image to show larger and more beautiful images on website. We recommend uploading 3000 pixel wide images.',
    longestSideMinPx: 1400,
  },
  FULL_BLEED_IMAGE: {
    type: 'FULL_BLEED_IMAGE',
    message: 'The image you have selected for your full-bleed splash screen will not look ideal on large 27" retina displays. Please upload a higher resolution image. We recommend at least a 3000 pixel wide image. VisualServer uses a smaller image for smaller displays.',
    longestSideMinPx: 2000,
  },
};
