import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {ATTRIBUTE} from './constants';

export class IsDesktopSetupMatchedModel implements IStyleOption {
  private menu: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.menu = options.block;

    this.value = this.menu.matches(`[${ATTRIBUTE}="true"]`);
  }

  public setValue(value: boolean): void {
    if (!this.isEnabled) return;

    this.value = value;

    this.menu.setAttribute(ATTRIBUTE, `${value}`);
  }
}
