import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/custom-menu/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/custom-mobile-menu/constants';

export const STYLE_PROPERTIES = {
  [DESKTOP_KEYS.HOVER_COLOR]: 'color',
  [MOBILE_KEYS.MOBILE_HOVER_COLOR]: 'color',
};

export const CUSTOM_STYLE_PROPERTIES = {
  [DESKTOP_KEYS.HOVER_COLOR]: '--menu-hover-link-color',
  [MOBILE_KEYS.MOBILE_HOVER_COLOR]: '--mobile-menu-hover-link-color',
};
