import {PlanDto} from './plan.dto';
import {AddOnModel} from '../add-ons/add-on.model';
import {IncludedAddOnsDto} from './included-add-ons/included-add-ons.dto';
import {IncludedAddOnsModel} from './included-add-ons/included-add-ons.model';
import {PriceAmountModel} from '../price-amount/price-amount.model';

import {DURATIONS} from '../payment/subscriptions/constants';

export class PlanModel {
  public includedSupportTimeMin: number;
  public includedAddOnsPrice: number;

  public includedSupportTimeHoursTextShort: string;
  public includedSupportTimeHoursText: string;

  public amounts = {};

  public annualSaveText: string = '';

  public isCurrent: boolean = false;
  public isSupportPlan: boolean = false;

  public hasLabel: boolean = false;

  private amountResolvers = {
    [DURATIONS.ONE_DAY]: () => this.amountDaily,
    [DURATIONS.ONE_MONTH]: () => this.amountMonthly,
    [DURATIONS.ONE_YEAR]: () => this.amountAnnually,
  };

  public get isLite(): boolean {
    return this.id === 'Lite';
  }

  public get isStandard(): boolean {
    return this.id === 'Standard';
  }

  public get isProfessional(): boolean {
    return this.id === 'Professional';
  }

  public get isMultiSite(): boolean {
    return this.id === 'MultiSite';
  }

  public get isLitePlus(): boolean {
    return this.id === 'Lite+';
  }

  public get isStandardPlus(): boolean {
    return this.id === 'Standard+';
  }

  public get isProfessionalPlus(): boolean {
    return this.id === 'Professional+';
  }

  public get isMultiSitePlus(): boolean {
    return this.id === 'MultiSite+';
  }

  constructor(public id?: string,
              public name?: string,
              public isEcommerce?: boolean,
              public isEnabled?: boolean,
              public websiteLimit?: number,
              public imagesPricesLimit?: number,
              public portfolioLimit?: number,
              public pageLimit?: number,
              public diskSpaceMb?: number,
              public includedAddOns?: IncludedAddOnsModel[],
              public stripeProductId?: string,
              public annualPlanStripeId?: string,
              public monthPlanStripeId?: string,
              public dailyPlanStripeId?: string,
              public amountAnnually?: number,
              public amountMonthly?: number,
              public amountDaily?: number,
              public level?: number) {
    this.isSupportPlan = this.includedAddOns ? this.includedAddOns.findIndex((addOn: AddOnModel) => addOn.key === 'SUPPORT_INCLUDED_ANNUAL_ONE_HOUR' || addOn.key === 'SUPPORT_INCLUDED_ANNUAL_TWO_HOURS' || addOn.key === 'SUPPORT_INCLUDED_DAILY_ONE_HOUR') !== -1 : false;

    this.includedSupportTimeMin = this.getIncludedSupportTimeMin();
    this.includedAddOnsPrice = this.getIncludedAddOnsPrice();

    this.includedSupportTimeHoursTextShort = this.getIncludedSupportTextShort();
    this.includedSupportTimeHoursText = this.getIncludedSupportText();

    this.initAmounts();

    this.annualSaveText = this.getAnnualSaveText();
  }

  private initAmounts(): void {
    Object.keys(DURATIONS).forEach((period: string) => {
      if (!this.amountResolvers[period]) return;

      const amount: number = this.amountResolvers[period]();

      this.amounts[period] = this.amounts[period] || {};

      this.amounts[period].amount = amount;
      this.amounts[period].roundAmount = Math.floor(amount);
      this.amounts[period].centsAmount = Number((amount * 100).toFixed()) % 100;
      this.amounts[period].formattedAmount = `$${this.amounts[period].roundAmount}.${`${this.amounts[period].centsAmount}`.padStart(2, '0')}`;

      this.amounts[period].totalAmount = amount + this.includedAddOnsPrice;
      this.amounts[period].roundTotalAmount = Math.floor(this.amounts[period].totalAmount);
      this.amounts[period].centsTotalAmount = Number(((this.amounts[period].totalAmount) * 100).toFixed()) % 100;
      this.amounts[period].formattedTotalAmount = `$${this.amounts[period].roundTotalAmount}.${`${this.amounts[period].centsTotalAmount}`.padStart(2, '0')}`;
    });
  }

  private getIncludedSupportTimeMin(): number {
    if (!this.includedAddOns) return 0;

    const supportAddOn: IncludedAddOnsModel = this.includedAddOns.find((addOn: AddOnModel) => addOn.key === 'SUPPORT_INCLUDED_ANNUAL_ONE_HOUR' || addOn.key === 'SUPPORT_INCLUDED_ANNUAL_TWO_HOURS' || addOn.key === 'SUPPORT_INCLUDED_DAILY_ONE_HOUR');

    if (!supportAddOn) return 0;

    return supportAddOn.addOn.includedSupportTimeMin * supportAddOn.quantity;
  }

  private getIncludedAddOnsPrice(): number {
    if (!this.includedAddOns) return 0;

    return this.includedAddOns.reduce((res: number, item: IncludedAddOnsModel) => {
      const amountForPlan: PriceAmountModel = item.addOn.getAmountForPlan(this);

      return res + (amountForPlan.amount || 0) * item.quantity;
    }, 0);
  }

  private getIncludedSupportTextShort(): string {
    if (!this.isSupportPlan) return null;

    const includedSupportTimeHours: number = this.includedSupportTimeMin / 60;

    return `${includedSupportTimeHours} HOUR${includedSupportTimeHours === 1 ? '' : 'S'}`;
  }

  private getIncludedSupportText(): string {
    if (!this.isSupportPlan) return null;

    const includedSupportTimeHours: number = this.includedSupportTimeMin / 60;

    return `${includedSupportTimeHours} HOUR${includedSupportTimeHours === 1 ? '' : 'S'} PERSONALIZED SUPPORT`;
  }

  private getAnnualSaveText(): string {
    if (!this.amounts[DURATIONS.ONE_YEAR] || !this.amounts[DURATIONS.ONE_MONTH] || !this.amounts[DURATIONS.ONE_YEAR].amount || !this.amounts[DURATIONS.ONE_MONTH].amount) return null;

    return `${this.amounts[DURATIONS.ONE_MONTH].amount * 12 - this.amounts[DURATIONS.ONE_YEAR].amount}`;
  }

  public static normalize(res: PlanDto): PlanModel {
    if (!res) return null;

    return new PlanModel(
      res.Id,
      res.Name,
      res.IsEcommerce,
      res.IsEnabled,
      res.WebsiteLimit,
      res.ImagesPricesLimit,
      res.PortfolioLimit,
      res.PageLimit,
      res.DiskSpaceMb,
      res.IncludedAddOns ? res.IncludedAddOns.map((item: IncludedAddOnsDto) => IncludedAddOnsModel.normalize(item)) : null,
      res.StripeProductId,
      res.AnnualPlanStripeId,
      res.MonthPlanStripeId,
      res.DailyPlanStripeId,
      res.AmountAnnually/ 100,
      res.AmountMonthly / 100,
      res.AmountDaily / 100,
      res.Level,
    );
  }
}
