import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AdminEducationClassesService} from '../../../../../../core/services/admin/education/classes/admin-education-classes.service';

import {EducationClassModel} from '../../../../../../core/models/education/classes/education-class.model';

@Component({
  selector: 'app-admin-education-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss']
})
export class AdminEducationClassDetailsComponent implements OnInit, OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public classItem: EducationClassModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationClassesService) {
  }

  public ngOnInit(): void {
    this.service.classDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((classItem: EducationClassModel) => {
      this.classItem = classItem;
    });
  }

  public cancel(): void {
    this.closeHandler.emit();

    this.service.classDetailsSubject.next(null);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
