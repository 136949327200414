import {BlockTemplateDto} from '../template/block-template.dto';
import {BlockModel} from '../block.model';

export class DefaultPortfolioBlockDto {
  constructor(public BlockCategoryId?: number,
              public BlockTemplate?: BlockTemplateDto,
              public BlockTemplateId?: number,
              public WebsiteID?: number,
              public TemplateID?: number,
              public Block_Category?: string,
              public Block_HTML?: string,
              public Name?: string,
              public Version?: number,
              public PageType?: string,
              public UsedFonts?: string,
              public IsBlockImported?: boolean,
              public IsSavedBlock?: boolean,
              public IsSingleBlockCategory?: boolean) {}

  public static normalize(res: DefaultPortfolioBlockDto): DefaultPortfolioBlockDto {
    if (!res) return null;

    return new DefaultPortfolioBlockDto(
      res.BlockCategoryId,
      BlockTemplateDto.normalize(res.BlockTemplate),
      res.BlockTemplateId,
      res.WebsiteID,
      res.TemplateID,
      res.Block_Category,
      res.Block_HTML,
      res.Name,
      res.Version,
      res.PageType,
      res.UsedFonts,
      res.IsBlockImported,
      res.IsSavedBlock,
      res.IsSingleBlockCategory,
    );
  }

  public static normalizeToBlock(res: DefaultPortfolioBlockDto): BlockModel {
    if (!res) return null;

    const block: BlockModel = new BlockModel(
      res.BlockCategoryId,
      res.BlockTemplateId,
      null,
      res.BlockTemplateId,
      res.Block_Category,
      res.BlockTemplate.Dynamic,
      res.Name,
      res.Version,
      res.Block_HTML,
      res.PageType,
      res.UsedFonts,
      res.BlockTemplate.Thumb,
      res.BlockTemplate.ThumbFileName,
      res.BlockTemplate.GifThumbnail,
      res.BlockTemplate.IsSingle,
      res.IsSingleBlockCategory,
    );

    block.IsDefaultPortfolio = true;

    return block;
  }
}
