export const PROPERTY_NAME = '--portfolio-slide-thumb-font-size';
export const PROPERTY_NAME_PX = '--portfolio-slide-thumb-font-size-px';
export const STYLE_PROPERTY = 'font-size';

export const FONT_SIZES: Array<number> = [
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
];
