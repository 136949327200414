export class UserSocialNetworkDto {
  constructor(public Id: number,
              public UserId: number,
              public WebsiteId: number,
              public Token: string,
              public SocialNetworkId: string,
              public UserName: string,
              public Uri: string,
              public SelectedIcon: string,
              public OrderNo: number,
              public IsShown: boolean) {}
}
