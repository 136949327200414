import {BaseFileObjectDto} from "../../../base/file-object/base-file-object.dto";

export class ObjectRemoveErrorModel {
    constructor(public templateId?: number,
                public pageId?: number,
                public pageType?: string,
                public blockId?: number,
                public blockCategory?: string,
                public pageTitle?: string,
                public object?: BaseFileObjectDto) {}
  }
  