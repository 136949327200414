import * as moment from 'moment';

import {StripeCouponDto} from './stripe-coupon.dto';
import {StripeCouponMetadataModel} from './stripe-coupon-metadata.model';

import {TIME_FORMATTER, DISCOUNT_TYPES} from './constants';

export class StripeCouponModel {
  public get discountText(): string {
    return this.metadata.discountType === DISCOUNT_TYPES.PERCENT ? `${this.percentOff}%` : `${this.amountOff} USD`;
  }

  constructor(public id?: string,
              public name?: string,
              public duration?: string,
              public percentOff?: number,
              public amountOff?: number,
              public isValid?: boolean,
              public createdAt?: string,
              public createdAtTimestamp?: number,
              public metadata?: StripeCouponMetadataModel) {
    this.metadata = this.metadata || new StripeCouponMetadataModel();
  }

  public static normalize(res: StripeCouponDto): StripeCouponModel {
    if (!res) return null;

    return new StripeCouponModel(
      res.id,
      res.name,
      res.duration,
      res.percent_off,
      res.amount_off / 100,
      res.valid,
      moment.unix(res.created).format(TIME_FORMATTER),
      res.created,
      StripeCouponMetadataModel.normalize(res.metadata),
    );
  }

  public clone(): StripeCouponModel {
    return new StripeCouponModel(
      this.id,
      this.name,
      this.duration,
      this.percentOff,
      this.amountOff,
      this.isValid,
      moment(this.createdAtTimestamp).format(TIME_FORMATTER),
      this.createdAtTimestamp,
      StripeCouponMetadataModel.normalize(this.metadata),
    );
  }
}
