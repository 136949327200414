import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, BehaviorSubject} from 'rxjs';

import {WebsiteDesignerService} from '../../../../application/main/website-designer/website-designer.service';
import {BlocksManagerService} from '../../blocks/blocks.service';
import {AuthService} from '../../../../auth/auth.service';

import {StylesSettingsDto} from '../../../models/styles/settings/styles-settings.dto';
import {StylesSettingsModel} from '../../../models/styles/settings/styles-settings.model';
import {StyleOptionModel} from '../../../models/styles/settings/option/style-option.model';

import {DEFAULT_ELEMENT} from './constants';
import {AccountModel} from '../../../models/accounts/account.model';

@Injectable()
export class StylesSettingsService {
  private model: string = 'styles';
  private account: AccountModel = null;

  public optionsMap: Map<string, StyleOptionModel>;

  public settingsSubject: BehaviorSubject<StylesSettingsModel> = new BehaviorSubject<StylesSettingsModel>(null);

  public optionsSubject: BehaviorSubject<StyleOptionModel[]> = new BehaviorSubject<StyleOptionModel[]>([]);
  public optionsMapSubject: BehaviorSubject<Map<string, StyleOptionModel>> = new BehaviorSubject<Map<string, StyleOptionModel>>(new Map<string, StyleOptionModel>());
  public disabledOptionsSubject: BehaviorSubject<StyleOptionModel[]> = new BehaviorSubject<StyleOptionModel[]>([]);

  public get settings(): StylesSettingsModel {
    return this.settingsSubject.value;
  }

  public get options(): StyleOptionModel[] {
    return this.optionsSubject.value;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private websiteDesignerService: WebsiteDesignerService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.init();

    this.websiteDesignerService.editingSubject.subscribe(options => {
      this.onElementChange(options);
    });
  }

  public onElementChange(data) {
    const element: HTMLElement = this.recognizeElement(data);

    this.initOptions(element);
    this.initOptionsMap();
  }

  private recognizeElement(data): HTMLElement {
    if (!data || !data.element) {
      return null;
    }

    if (data.element[0]) {
      return data.element[0];
    }

    return data.element || null;
  }

  public init() {
    this.fetchSettings();
  }

  public fetchSettings() {
    this.getSettings().subscribe((res: StylesSettingsDto) => {
      this.settingsSubject.next(StylesSettingsDto.normalize(res));
    });
  }

  private getSettings(): Observable<StylesSettingsDto> {
    return this.http.get<StylesSettingsDto>(`api/${this.model}/settings`);
  }

  private initOptions(element: HTMLElement): StyleOptionModel[] {
    const { settings } = this;

    if (!element || !settings) {
      return [];
    }

    const options = this.getOptions(settings, element);

    this.optionsSubject.next(options);

    if (!this.account.isAdmin) {
      return;
    }

    const disabledOptions = this.settings.optionsList.filter(option => !options.find(o => o.id === option.id));

    this.disabledOptionsSubject.next(disabledOptions);
  }

  private getOptions(settings: StylesSettingsModel, element: HTMLElement): StyleOptionModel[] {
    const { blocks, blocksCategories, customElements, elements } = settings;

    const editableType = BlocksManagerService.getCustomEditableType(element);

    if (customElements[editableType]) return customElements[editableType];

    const isBlock = BlocksManagerService.isBlock(element);

    if (!isBlock) return elements[element.nodeName] ? elements[element.nodeName] : elements[DEFAULT_ELEMENT];

    const blockId = BlocksManagerService.getBlockId(element);
    const blockVersion = BlocksManagerService.getBlockVersion(element);

    if (blocks[blockId] && blocks[blockId][blockVersion]) return blocks[blockId][blockVersion];

    const blockCategoryId = BlocksManagerService.getBlockCategoryId(element);

    return blocksCategories[blockCategoryId] ? blocksCategories[blockCategoryId] : elements[DEFAULT_ELEMENT];
  }

  private initOptionsMap() {
    const map = new Map<string, StyleOptionModel>();

    this.options.forEach((option: StyleOptionModel) => {
      map.set(option.optionKey, option);
    });

    this.optionsMap = map;

    this.optionsMapSubject.next(map);
  }
}
