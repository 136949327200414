import {SetupOptionModel} from '../../../../option/setup-option.model';
import {SelectOption} from '../../../../../../select/option/option.model';

import {ATTRIBUTES, OPTIONS, DEFAULT_OPTION} from './constants';

export class PortfolioEnlargementTitlePositionModel extends SetupOptionModel {
  public element: HTMLElement;

  public selectedOption: SelectOption = null;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    if (!this.element) return;

    const key: string = this.element.getAttribute(ATTRIBUTES.IMAGE_TITLE_POSITION);

    this.selectedOption = OPTIONS.find(o => o.value === key) || DEFAULT_OPTION;
    this.value = this.selectedOption.value;
  }

  public onChange(key: string): void {
    if (!this.isEnabled) return;

    this.selectedOption = OPTIONS.find(o => o.value === key) || DEFAULT_OPTION;
    this.value = this.selectedOption.value;

    this.element.setAttribute(ATTRIBUTES.IMAGE_TITLE_POSITION, this.selectedOption.value);
  }

  public reset(): void {
    this.selectedOption = DEFAULT_OPTION;
    this.value = this.selectedOption.value;

    this.element.setAttribute(ATTRIBUTES.IMAGE_TITLE_POSITION, this.selectedOption.value);
  }
}
