import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CourseWebsiteDto} from '../../../../../../models/websites/course-website.dto';
import {CourseWebsiteModel} from '../../../../../../models/websites/course-website.model';
import {EducationTeacherDataModel} from '../../../../../../models/education/teachers/education-teacher-data.model';

@Injectable()
export class EducationTeachersWebsitesHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public getWebsites(): Observable<CourseWebsiteModel[]> {
    return this.http.get(`${this.prefix}/websites`).pipe(
      map((res: CourseWebsiteDto[]) => res.map((item: CourseWebsiteDto) => CourseWebsiteDto.normalize(item)))
    );
  }

  public getData(): Observable<EducationTeacherDataModel> {
    return this.http.get(`${this.prefix}/data`).pipe(
      map((res: EducationTeacherDataModel) => EducationTeacherDataModel.normalize(res))
    );
  }
}
