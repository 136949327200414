import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appForceFocus]'
})
export class ForceFocusDirective implements AfterViewInit {
  @Input() appForceFocus;
  
  constructor(private elRef: ElementRef) { }

  ngAfterViewInit() {
    this.elRef.nativeElement.focus();
  }
}
