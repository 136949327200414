
import {Injectable} from '@angular/core';

import {from as observableFrom, ReplaySubject, Subscription, Subject, BehaviorSubject, Observable, throwError, of} from 'rxjs';
import {catchError, mergeMap, take, tap} from 'rxjs/operators';

import {AdminService} from '../application/main/admin-panel/admin.service';
import {AccountsHttpService} from '../core/services/interaction/http/accounts/accounts-http.service';
import {RoutingService} from '../core/services/routing/routing.service';
import {RequestInterceptorService} from '../core/services/interaction/requests/interceptor/request-interceptor.service';
import {ButtonsService} from '../core/services/buttons/buttons.service';
import {IsPublishingService} from '../services/is-publishing/is-publishing.service';

import {AccountDto} from '../core/models/accounts/account.dto';
import {AccountModel} from '../core/models/accounts/account.model';
import {ISocketInitialDataMessageDataModel} from '../core/models/sockets/message/initial-data/i-initial-data-message-data.model';
import {AccountSummaryModel} from '../core/models/accounts/summary/account-summary.model';
import {PermissionModel} from '../core/models/permission/permission.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  public accountSubject: BehaviorSubject<AccountModel> = new BehaviorSubject<AccountModel>(null);
  public accountSummarySubject: BehaviorSubject<AccountSummaryModel> = new BehaviorSubject<AccountSummaryModel>(null);
  public permissionsSubject: BehaviorSubject<PermissionModel[]> = new BehaviorSubject<PermissionModel[]>(null);
  public websiteChangedSubject: Subject<boolean> = new Subject<boolean>();

  user: AccountDto = null;
  userReplaySubject = new ReplaySubject(1); // <any>(null);
  userSubject = new BehaviorSubject(null);

  public isFetched = false;
  public isPermissionsFetched = false;
  public isLoggedOut = false;

  public onSignIn: Subject<AccountModel> = new Subject();
  public onSignOut: Subject<boolean> = new Subject<boolean>();

  private permissionsFetchId: Number;

  private publishingData: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean };

  public get account(): AccountModel {
    return this.accountSubject.value;
  }

  public get isTrialSubscription(): boolean {
    const { account } = this;

    return account && account.isTrialSubscription;
  }

  public get isSubscriptionExpired(): boolean {
    const { account } = this;

    return account && account.isSubscriptionExpired;
  }

  public get username(): string {
    return this.user ? `${this.user.FirstName} ${this.user.LastName}` : '';
  }

  private get isPublishing(): boolean {
    if (!this.publishingData || !this.publishingData.isActiveWebsite) {
      return false;
    }

    return this.publishingData.isPublishing;
  }

  constructor(
    private http: HttpClient,
    private requestInterceptorService: RequestInterceptorService,
    private adminService: AdminService,
    private routingService: RoutingService,
    private accountsHttpService: AccountsHttpService,
    private buttonsService: ButtonsService,
    private isPublishingService: IsPublishingService,
  ) {
    this.requestInterceptorService.onForceLogoutSubject.subscribe((isLogout: boolean) => {
      if (!isLogout) return;

      this.forceLogout();
    });

    this.isPublishingService.isPublishingSubject.subscribe((data: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean }) => {
      this.publishingData = data;
    });
  }

  public forceLogout(): void {
    this.isLoggedOut = true;

    this.clearUser();

    this.toLogin();
  }

  logout(): Observable<any> {
    this.buttonsService.disableAllButtons();

    return this.http.post<{ isAdmin: boolean }>('/api/auth/logout', null).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      mergeMap(({ isAdmin }) => {
        this.isLoggedOut = !isAdmin;
  
        if (!isAdmin) {
          this.clearUser();
  
          this.onSignOut.next(true);
  
          return of(this.toLogin());
        }

        return of(this.updateCurrentUser()).pipe(
          tap(() => {
            this.routingService.toAdminPanelSubject.next(true);
          })
        );
      })
    );
  }

  public toLogin() {
    window.location.href = `/home${window.location.search}`;
  }

  private clearUser() {
    try {
      this.setUser(null);
    } catch (e) {
      console.error(e);
    }
  }

  ping(): Observable<boolean> {
    return this.http.get<boolean>('/api/auth/ping');
  }

  isEmailTaken(email: string): Observable<string> {
    return this.http.post<string>('/api/auth/email', { email });
  }

  public updateActiveWebsite(id: number, isNotifyEnabled: boolean = true): Subscription {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return Subscription.EMPTY;
    }

    return this.accountsHttpService.setActiveWebsite(id).subscribe((account: AccountDto) => {
      this.setUser(account);

      if (!isNotifyEnabled) {
        return;
      }

      this.websiteChangedSubject.next(true);
    });
  }

  public updateCurrentUser(isLoaderNeeded = true): Subscription {
    if (this.isLoggedOut) {
      return Subscription.EMPTY;
    }

    this.isFetched = !isLoaderNeeded;
    this.isPermissionsFetched = this.isFetched;

    const onSuccess = (account: AccountDto) => {
      this.setUser(account);
    };

    return this.accountsHttpService.fetchAccount().pipe(
      catchError(e => {
        this.clearUser();
        
        this.requestInterceptorService.onForceLogoutSubject.next(true);

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  public setUser(user: AccountDto) {
    this.isFetched = true;

    const account: AccountModel = AccountDto.normalize(user);

    this.accountSubject.next(account);

    this.user = user;
    this.userSubject.next(user);
    this.userReplaySubject.next(user);
    
    this.fetchPermissions();

    this.handleUrl();

    return user;
  }

  public fetchPermissions(options?: { isForceEmpty?: boolean }): Subscription {
    if (options && options.isForceEmpty) {
      this.isPermissionsFetched = true;

      this.permissionsFetchId = null;

      this.setPermissions([]);

      return Subscription.EMPTY;
    }

    const id: Number = Math.random();

    this.permissionsFetchId = id;

    this.isPermissionsFetched = false;

    return this.accountsHttpService.fetchPermissions().subscribe((permissions: PermissionModel[]) => {
      if (this.permissionsFetchId !== id) {
        return;
      }

      this.isPermissionsFetched = true;

      this.setPermissions(permissions);
    });
  }

  private setPermissions(permissions: PermissionModel[]): void {
    const currentValue: PermissionModel[] = this.permissionsSubject.value;

    if (this.isPermissionsSame(currentValue, permissions)) {
      return;
    }

    this.permissionsSubject.next(permissions);
  }

  private isPermissionsSame(list1: PermissionModel[], list2: PermissionModel[]): boolean {
    if (list1 === list2 || (!list1 && !list2)) {
      return true;
    }

    if (!list1 || !list2) {
      return false;
    }

    if (list1.length !== list2.length) {
      return false;
    }

    for (let i = 0; i < list1.length; i++) {
      if (!PermissionModel.isSame(list1[i], list2[i])) {
        return false;
      }
    }

    return true;
  }

  private handleUrl() {
    if (!this.account || this.isLoggedOut) return;

    if (!this.account.isEmailVerified && !this.account.isUnderAdmin) return this.redirectToEmailVerification();
  }

  public redirectToEmailVerification() {
    window.location.href = '/confirmation-required';
  }

  public fetchAccountSummary(userId?: number): void {
    const fetchMethod: Observable<AccountSummaryModel> = userId ? this.adminService.fetchCustomerSummary(userId) : this.accountsHttpService.fetchAccountSummary();

    fetchMethod.pipe(take(1)).subscribe((accountSummary: AccountSummaryModel) => {
      this.accountSummarySubject.next(AccountSummaryModel.clone(accountSummary));
    });
  }

  public requestInitialData(): Observable<ISocketInitialDataMessageDataModel> {
    return this.accountsHttpService.requestInitialData();
  }
}
