// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.website-statistic-collecting-info-modal .modal-content-wrapper {
  margin: 20px;
  clear: both;
  overflow: hidden;
  text-align: justify;
}
.website-statistic-collecting-info-modal .modal-content-wrapper .website-statistic-text {
  margin-bottom: 10px;
}
.website-statistic-collecting-info-modal .modal-content-wrapper .actions {
  display: flex;
  justify-content: center;
  float: none;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/website-statistic-collecting-info/website-statistic-collecting-info-modal.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAGI;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EACA,gBAAA;AADN","sourcesContent":[".website-statistic-collecting-info-modal {\n  .modal-content-wrapper {\n    margin: 20px;\n    clear: both;\n    overflow: hidden;\n    text-align: justify;\n\n    .website-statistic-text {\n      margin-bottom: 10px;\n    }\n\n    .actions {\n      display: flex;\n      justify-content: center;\n      float: none;\n      margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
