import {SetupOptionModel} from '../option/setup-option.model';
import {SelectOption} from '../../../select/option/option.model';

import {STYLE_PROPERTY_NAME, OPTIONS} from './constants';

export class ThumbDrawerThumbsPositionModel extends SetupOptionModel {
  public selectedOption: SelectOption = null;

  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled) return;

    this.element = element;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: string = computedStyles.getPropertyValue(STYLE_PROPERTY_NAME).trim();

    this.selectedOption = OPTIONS.find(o => o.value === value);
    this.value = this.selectedOption.value;
  }

  public setValue(key: string): void {
    if (!this.isEnabled) return;

    this.selectedOption = OPTIONS.find(o => o.value === key);
    this.value = this.selectedOption.value;

    this.element.style.setProperty(STYLE_PROPERTY_NAME, this.selectedOption.value);
  }

  public reset(): void {
    this.element.style.removeProperty(STYLE_PROPERTY_NAME);

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: string = computedStyles.getPropertyValue(STYLE_PROPERTY_NAME).trim();

    this.selectedOption = OPTIONS.find(o => o.value === value);
    this.value = this.selectedOption.value;

    this.element.style.setProperty(STYLE_PROPERTY_NAME, this.selectedOption.value);
  }
}
