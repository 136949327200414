import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {BaseColorRgbModel} from '../../setup/base/color-rgb/base-color-rgb.model';

import {CUSTOM_COLOR, CUSTOM_COLOR_RGB} from './constants';

export class WebsiteLinkColorModel extends BaseColorRgbModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, null, CUSTOM_COLOR, CUSTOM_COLOR_RGB, value, true);
  }

  public init(selectedElement: HTMLElement, options: IStyleModelOptions): void {
    this.isEnabled = !!options.body;

    if (!selectedElement) {
      return;
    }

    super.init(options.body, {
      defaultValueHolderElement: options.body,
    });
  }
}
