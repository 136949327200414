export const PORTFOLIO_ID_SUPPORTS_SLIDES: number[] = [
  23,
  72,
  98,
];

export const EVENTS = {
  OPEN_CUSTOMIZER: 'openCustomizer',
  CLOSE_CUSTOMIZER: 'closeCustomizer',
  OPEN_PORTFOLIO: 'openPortfolio',
  FORCE_SAVE_PAGE: 'forceSavePage',
  SAVE_WEBSITE_TITLE: 'saveWebsiteTitle',
  SAVE_WEBSITE_SUBTITLE: 'saveWebsiteSubtitle',
  OPEN_PORTFOLIO_SELECT_MODAL: 'openPortfolioSelectModal',
  DROPDOWN_TOO_HIGH: 'dropdownTooHigh',
  UPDATE_DROPPED_BLOCKS: 'updateDroppedBlocks',
  SAVE_AS_DEFAULT_PORTFOLIO: 'saveAsDefaultPortfolio',
  OPEN_IMAGE_MANAGER: 'openImageManager',
};

export const EDITABLE_TYPES = [
  'ELEMENT',
  'DIVIDER',
];
