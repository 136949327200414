import {Component, ElementRef, HostListener, Input, OnInit, OnDestroy, ViewChild, ChangeDetectorRef} from '@angular/core';
import {HttpParams} from '@angular/common/http';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {GoogleFontsService} from '../../../core/services/google/fonts/google-fonts.service';

import {SelectOption} from '../../../core/models/select/option/option.model';
import {GoogleFontModel} from '../../../core/models/google/fonts/google-font.model';
import {GoogleFontsDataModel} from '../../../core/models/google/fonts/google-fonts-data.model';
import {GoogleFontCategoryModel} from '../../../core/models/google/fonts/google-font-category.model';
import {GoogleFontSubsetModel} from '../../../core/models/google/fonts/google-font-subset.model';
import {GoogleFontSortByModel} from '../../../core/models/google/fonts/google-font-sort-by.model';
import {GoogleFontRowDataModel} from '../../../core/models/google/fonts/google-font-row-data.model';
import {TabSettingsModel} from '../../../core/models/sidebar/tabs-settings.model';
import {WebsiteFontsDataModel} from '../../../core/models/google/fonts/website-fonts-data.model';

import {MAX_COLUMN_WIDTH, CONTENT_TYPE_OPTIONS, LIST_OUTPUT_TYPE_KEYS} from './constants';
import {LIST_OUTPUT_TYPE_ICONS, SORT_BY_KEYS, MIN_CELL_HEIGHT, TABS_KEYS, TABS} from './constants';
import {CONTENT_TYPE_KEYS, DEFAULT_FONT_SIZE, DEFAULT_LINE_HEIGHT, SUBSETS_KEYS} from '../../../core/services/google/fonts/constants';

import Timer = NodeJS.Timer;

@Component({
  selector: 'app-fonts-manager',
  templateUrl: './fonts-manager.component.html',
  styleUrls: ['./fonts-manager.component.scss'],
})
export class FontsManagerComponent implements OnInit, OnDestroy {
  @Input() isModal = false;

  @ViewChild('scrollableWrapper') scrollableWrapper: ElementRef;
  @ViewChild('customInput') customInput: ElementRef;

  public tabs: TabSettingsModel[] = TABS();
  public selectedTabKey: string = TABS_KEYS.ALL;

  public fonts: GoogleFontModel[] = [];
  public defaultFonts: GoogleFontModel[] = [];
  public specimen: GoogleFontModel = null;

  public categoriesSelectOptions: SelectOption[] = [];
  public subsetsSelectOptions: SelectOption[] = [];
  public sortBySelectOptions: SelectOption[] = [];

  public contentTypeOptions: SelectOption[] = CONTENT_TYPE_OPTIONS();
  public selectedContentTypeOption: SelectOption = null;
  public selectedCustomContentTypeOption: SelectOption = null;

  public selectedSortByOption: SelectOption = null;
  public selectedSubsetOption: SelectOption = null;

  public query: string = '';

  public customString: string = '';

  public isLoaded: boolean = false;

  public totalNumberOfFonts: number = 0;
  public fontsCount: number = 0;

  public fontSize: number = DEFAULT_FONT_SIZE;
  public fontSizeString: string = `${DEFAULT_FONT_SIZE}px`;

  public lineHeight: number = DEFAULT_LINE_HEIGHT;

  public outputType = LIST_OUTPUT_TYPE_KEYS.GRID;
  public outputTypeClass = LIST_OUTPUT_TYPE_ICONS[LIST_OUTPUT_TYPE_KEYS.GRID];

  public sortByText: string = '';

  public isSingleColumn: boolean = false;
  public isTwoColumns: boolean = false;
  public isThreeColumns: boolean = false;
  public isFourColumns: boolean = false;

  public initialFontsHeight: number = 1024;
  public fontsHeight: number = 1024;
  public fontsMarginTop: number = 0;

  public avgRowHeight: number = MIN_CELL_HEIGHT;
  public handledRowsCount: number = 0;

  private rowsData: GoogleFontRowDataModel[] = null;

  private startIdx: number = 0;
  private endIdx: number = 0;

  private portionSize: number = 0;
  private currentRowNumber: number = -1;

  private scrollTimeoutId: Timer;
  private resizeTimeoutId: Timer;
  private searchTimeoutId: Timer;

  private isList: boolean = false;
  private isGrid: boolean = true;

  private nOfColumns: number = 4;
  private nOfRows: number = 0;

  private isUserFonts: boolean = false;
  public isDefaultFonts: boolean = false;
  public isOpenedFromAdminPanel: boolean = false;

  private tabHandlers = {
    [TABS_KEYS.ALL]: this.onAllFonts.bind(this),
    [TABS_KEYS.ADDED]: this.onAddedFonts.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private get sort(): string {
    return this.selectedSortByOption ? this.selectedSortByOption.value : '';
  }

  public get contentType(): string {
    return this.selectedContentTypeOption ? this.selectedContentTypeOption.value : '';
  }

  public get subset(): string {
    return this.selectedSubsetOption ? this.selectedSubsetOption.value : '';
  }

  private get categories(): string {
    if (!this.categoriesSelectOptions) return '';

    return this.categoriesValues.join(',');
  }

  private get categoriesValues(): string[] {
    if (!this.categoriesSelectOptions) return [];

    return this.categoriesSelectOptions.filter(option => option.isSelected).map(option => option.value);
  }

  constructor(private service: GoogleFontsService,
              private cdr: ChangeDetectorRef) {
    this.selectedContentTypeOption = this.contentTypeOptions.find(option => option.isSelected);
    this.selectedCustomContentTypeOption = this.contentTypeOptions.find(option => option.value === CONTENT_TYPE_KEYS.CUSTOM);

    this.initContentType();
  }

  public ngOnInit(): void {
    this.service.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoaded: boolean) => {
      this.isLoaded = isLoaded;
    });

    this.service.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: GoogleFontsDataModel) => {
      this.categoriesSelectOptions = data ? data.categories.map((category: GoogleFontCategoryModel) => new SelectOption(category.text, category.key, category.isSelected)) : [];
      this.subsetsSelectOptions = data ? data.subsets.map((subset: GoogleFontSubsetModel) => new SelectOption(subset.text, subset.key, subset.isSelected)) : [];
      this.sortBySelectOptions = data ? data.sortBy.map((sortBy: GoogleFontSortByModel) => new SelectOption(sortBy.text, sortBy.key, sortBy.isSelected)) : [];
      this.totalNumberOfFonts = data ? data.totalNumberOfFonts : 0;

      this.selectedSubsetOption = this.subsetsSelectOptions.find(option => option.isSelected);
      this.selectedSortByOption = this.sortBySelectOptions.find(option => option.value === SORT_BY_KEYS.TRENDING);

      this.initSortByText();

      if (data) this.doFetch();
    });

    this.service.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((fonts: GoogleFontModel[]) => {
      this.fontsCount = fonts.length;

      this.nOfRows = 0;

      this.reset();

      this.initList();
    });

    this.service.visibleSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((fonts: GoogleFontModel[]) => {
      this.calcRowsData(this.fonts, fonts);

      this.fonts = fonts;
    });

    this.service.websiteDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websiteFontsDataModel: WebsiteFontsDataModel) => {
      this.defaultFonts = websiteFontsDataModel ? websiteFontsDataModel.defaultFonts : [];
    });

    this.service.isOpenedFromAdminPanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpenedFromAdminPanel: boolean) => {
      this.isOpenedFromAdminPanel = isOpenedFromAdminPanel;
    });
  }

  private calcRowsData(currFonts: GoogleFontModel[], nextFonts: GoogleFontModel[]) {
    if (currFonts.length === 0 || nextFonts.length === 0) return;

    const currFirstRow = Math.trunc(currFonts[0].index / this.nOfColumns);
    const currLastRow = Math.ceil(currFonts[currFonts.length - 1].index / this.nOfColumns);

    const nextFirstRow = Math.trunc(nextFonts[0].index / this.nOfColumns);
    const nextLastRow = Math.ceil(nextFonts[nextFonts.length - 1].index / this.nOfColumns);

    if (nextFirstRow === currFirstRow && nextLastRow === currLastRow) return;

    for (let i = nextFirstRow; i <= nextLastRow && i < this.nOfRows; i++) {
      if (i >= currFirstRow && i <= currLastRow) continue;

      this.rowsData[i].nOfLoadedFonts = 0;
      this.rowsData[i].isAllFontsLoaded = false;
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onTabClick(tab: TabSettingsModel) {
    this.selectedTabKey = tab ? tab.key : '';

    this.isUserFonts = this.selectedTabKey === TABS_KEYS.ADDED;
    this.isDefaultFonts = this.selectedTabKey === TABS_KEYS.DEFAULT;

    this.cdr.detectChanges();

    if (!this.tabHandlers[this.selectedTabKey]) return;

    return this.tabHandlers[this.selectedTabKey]();
  }

  public onSearchClear(): void {
    this.search('');
  }

  public onSearch(e: Event): void {
    const input: HTMLInputElement = <HTMLInputElement>e.target;

    clearTimeout(this.searchTimeoutId);

    this.searchTimeoutId = setTimeout(() => this.search(input.value), 250);
  }

  private search(value: string): void {
    this.query = value;

    this.reset();
    this.resetSortBy();

    this.service.search(this.query, this.subset, this.categoriesValues, this.isUserFonts);

    this.initCurrentRowNumber(0);
  }

  private onAllFonts() {
    this.totalReset();
    this.initCurrentRowNumber(0);
  }

  private onAddedFonts() {
    this.totalReset();
    this.initCurrentRowNumber(0);

    this.service.initUserFonts();
  }

  public onCustomStringInput(): void {
    this.onContentTypeChange(this.selectedCustomContentTypeOption);
  }

  public onContentTypeChange(selectedOption: SelectOption): void {
    this.selectedContentTypeOption.isSelected = false;
    this.selectedContentTypeOption = selectedOption;
    this.selectedContentTypeOption.isSelected = true;

    this.resetRowsDataHeight();

    this.contentTypeOptions.forEach(option => {
      option.isSelected = option === selectedOption;
    });

    this.initContentType();

    this.onFontSizeChange(this.service.getFontSize(this.selectedContentTypeOption.value));
  }

  public onFontSizeChange(value: number): void {
    this.fontSize = value;
    this.fontSizeString = `${value}px`;
    this.lineHeight = this.service.calcLineHeight(value);

    this.resetRowsDataHeight();
  }

  private resetRowsDataHeight(): void {
    this.avgRowHeight = MIN_CELL_HEIGHT;
    this.handledRowsCount = 0;
  }

  private initContentType() {
    const selectedContentType = this.selectedContentTypeOption.value;

    this.service.selectedContentTypeKey = selectedContentType;

    const isCustomString = this.service.isCustomString(this.subset, selectedContentType);

    if (isCustomString) return this.customInput && this.customInput.nativeElement.focus();

    this.customString = '';
  }

  public onListTypeClick() {
    this.outputType = this.outputType === LIST_OUTPUT_TYPE_KEYS.LIST ? LIST_OUTPUT_TYPE_KEYS.GRID : LIST_OUTPUT_TYPE_KEYS.LIST;
    this.outputTypeClass = LIST_OUTPUT_TYPE_ICONS[this.outputType];

    this.isList = this.outputType === LIST_OUTPUT_TYPE_KEYS.LIST;
    this.isGrid = this.outputType === LIST_OUTPUT_TYPE_KEYS.GRID;

    this.initGridData();
    this.initColumns();
    this.initPortionSize();

    this.reset();
  }

  public onCategoriesChange(): void {
    this.query = '';

    this.reset();

    this.doFetch();
  }

  public onLanguageChange(selectedOption: SelectOption): void {
    this.query = '';

    this.selectedSubsetOption = selectedOption;

    this.subsetsSelectOptions.forEach(option => {
      option.isSelected = option === selectedOption;
    });

    this.reset();

    this.doFetch();
  }

  public onSortByChange(selectedOption: SelectOption) {
    this.query = '';

    this.selectedSortByOption.isSelected = false;
    this.selectedSortByOption = selectedOption;
    this.selectedSortByOption.isSelected = true;

    this.sortBySelectOptions.forEach(option => {
      option.isSelected = option === selectedOption;
    });

    this.initSortByText();

    this.doFetch();
  }

  private initSortByText() {
    if (!this.selectedSortByOption) return;

    this.sortByText = `Sort by: ${this.selectedSortByOption.label}`
  }

  private doFetch(): void {
    const sort = this.sort;
    const subset = this.subset;
    const categories = this.categories;

    let params = new HttpParams();

    if (sort) params = params.set('sort', sort);
    if (subset) params = params.set('subset', subset);
    if (categories) params = params.set('categories', categories);

    this.service.fetch(params, this.isUserFonts);
  }

  public onFontLoaded(font) {
    const rowNumber = Math.trunc(font.index / this.nOfColumns);

    if (this.rowsData[rowNumber].isAllFontsLoaded) return;

    this.rowsData[rowNumber].isAllFontsLoaded = ++this.rowsData[rowNumber].nOfLoadedFonts === this.nOfColumns;
  }

  public onCellHeight({ font, height, oldHeight }) {
    const rowNumber = Math.trunc(font.index / this.nOfColumns);

    const diff = height - this.rowsData[rowNumber].height;

    this.rowsData[rowNumber].height = height;

    if (diff === 0) return;

    this.fontsHeight += diff;

    if (oldHeight) {
      this.avgRowHeight = this.handledRowsCount > 0 ? this.avgRowHeight - ((oldHeight - this.avgRowHeight) / (this.handledRowsCount - 1)) : 0;
      this.handledRowsCount = this.handledRowsCount > 0 ? this.handledRowsCount - 1 : 0;
    }

    this.avgRowHeight = this.avgRowHeight + ((height - this.avgRowHeight) / ++this.handledRowsCount);

    for (let i = rowNumber + 1; i < this.nOfRows; i++) {
      this.rowsData[i].offsetTop += diff;
    }
  }

  public onSpecimenShow(font: GoogleFontModel) {
    this.service.initFullFontLink(font);
    this.specimen = font;
  }

  public onSpecimenClose() {
    this.service.removeFullFontLink(this.specimen);
    this.specimen = null;
  }

  public onFontsScroll(e: Event): void {
    const scrollTop = (<HTMLElement>e.currentTarget).scrollTop;

    clearTimeout(this.scrollTimeoutId);

    this.scrollTimeoutId = setTimeout(() => {
      this.initCurrentRowNumber(scrollTop);
    }, 50);
  }

  private getRowNumber(scrollTop: number): number {
    if (this.nOfRows === 0) return 0;

    const expectedRowNumber: number = Math.trunc(scrollTop / this.avgRowHeight);
    const minRowNumber: number = expectedRowNumber > 0 ? expectedRowNumber - 1 : 0;
    const rowNumber: number = minRowNumber < this.nOfRows ? minRowNumber : this.nOfRows - 1;

    if (this.isRowOkay(this.rowsData[rowNumber], scrollTop)) return rowNumber;

    const isAfter = scrollTop >= this.rowsData[rowNumber].offsetTop;

    return isAfter ? this.getRowNumberAfter(scrollTop, rowNumber) : this.getRowNumberBefore(scrollTop, rowNumber);
  }

  private getRowNumberAfter(scrollTop: number, rowNumber: number) {
    let i = rowNumber + Math.trunc((scrollTop - this.rowsData[rowNumber].offsetTop) / this.avgRowHeight);

    i = i < this.nOfRows ? i : this.nOfRows - 1;

    if (this.isRowOkay(this.rowsData[i], scrollTop)) return i;

    return this.searchRowNumber(i, scrollTop);
  }

  private getRowNumberBefore(scrollTop: number, rowNumber: number) {
    let i = rowNumber - Math.trunc((this.rowsData[rowNumber].offsetTop - scrollTop) / this.avgRowHeight);

    i = i < 0 ? 0 : i;

    if (this.isRowOkay(this.rowsData[i], scrollTop)) return i;

    return this.searchRowNumber(i, scrollTop);
  }

  private searchRowNumber(i: number, scrollTop: number) {
    const isNeedToSearchAfter = scrollTop >= this.rowsData[i].offsetTop;

    return isNeedToSearchAfter ? this.searchRowAfter(i, scrollTop) : this.searchRowBefore(i, scrollTop);
  }

  private searchRowAfter(i: number, scrollTop: number) {
    while (i < this.nOfRows) {
      if (this.isRowOkay(this.rowsData[i++], scrollTop)) return i;
    }

    return i;
  }

  private searchRowBefore(i: number, scrollTop: number) {
    while (i >= 0) {
      if (this.isRowOkay(this.rowsData[i--], scrollTop)) return i;
    }

    return i;
  }

  private isRowOkay(rowData: GoogleFontRowDataModel, scrollTop: number): boolean {
    return Math.abs(scrollTop - rowData.offsetTop) < rowData.height;
  }

  @HostListener('window:resize', [])
  public onResize() {
    if (this.isDefaultFonts) return;

    this.currentRowNumber = -1;

    clearTimeout(this.resizeTimeoutId);

    this.resizeTimeoutId = setTimeout(() => {
      this.initList();
    }, 50);
  }

  private initList() {
    const nOfColumns = this.nOfColumns;
    const nOfRows = this.nOfRows;

    this.initGridData();

    if (this.nOfRows === 0) {
      return this.reset();
    }

    if (this.nOfColumns === nOfColumns && this.nOfRows === nOfRows) {
      return;
    }

    this.initRowsData();
    this.initColumns();
    this.initPortionSize();
    this.initCurrentRowNumber(this.scrollableWrapper && this.scrollableWrapper.nativeElement ? this.scrollableWrapper.nativeElement.scrollTop : 0);
  }

  private initGridData(): void {
    this.nOfColumns = this.calcNumberOfColumns();
    this.nOfRows = Math.ceil(this.fontsCount / this.nOfColumns);
    this.initialFontsHeight = this.nOfRows * MIN_CELL_HEIGHT;
  }

  private totalReset(): void {
    this.query = '';

    this.categoriesSelectOptions.forEach((option: SelectOption) => { option.isSelected = true; });

    this.subsetsSelectOptions.forEach((option: SelectOption) => {
      option.isSelected = option.value === SUBSETS_KEYS.ALL;

      if (!option.isSelected) return;

      this.selectedSubsetOption = option;
    });

    this.resetSortBy();

    this.service.search(this.query, this.subset, this.categoriesValues, this.isUserFonts);

    this.reset();
  }

  private resetSortBy(): void {
    this.sortBySelectOptions.forEach((option: SelectOption) => {
      option.isSelected = option.value === SORT_BY_KEYS.TRENDING;

      if (!option.isSelected) return;

      this.selectedSortByOption = option;

      this.initSortByText();
    });
  }

  private reset(): void {
    if (!this.scrollableWrapper || !this.scrollableWrapper.nativeElement) {
      return;
    }

    this.scrollableWrapper.nativeElement.scrollTop = 0;

    this.currentRowNumber = -1;
    this.startIdx = 0;
    this.endIdx = 0;

    this.initRowsData();
  }

  private initRowsData(): void {
    this.avgRowHeight = MIN_CELL_HEIGHT;
    this.fontsHeight = this.initialFontsHeight;

    this.rowsData = new Array(this.nOfRows);

    for (let i = 0; i < this.nOfRows; i++) {
      this.rowsData[i] = { height: MIN_CELL_HEIGHT, offsetTop: MIN_CELL_HEIGHT * i, nOfLoadedFonts: 0, isAllFontsLoaded: false };
    }
  }

  private calcNumberOfColumns(): number {
    if (this.isList) {
      return 1;
    }

    if (!this.scrollableWrapper || !this.scrollableWrapper.nativeElement) {
      return 4;
    }

    return Math.floor(this.scrollableWrapper.nativeElement.clientWidth / MAX_COLUMN_WIDTH) || 1;
  }

  private initColumns(): void {
    this.isSingleColumn = this.isList || (this.isGrid && this.nOfColumns === 1);
    this.isTwoColumns = this.isGrid && this.nOfColumns === 2;
    this.isThreeColumns = this.isGrid && this.nOfColumns === 3;
    this.isFourColumns = this.isGrid && this.nOfColumns === 4;
  }

  private initPortionSize(): void {
    const height: number = this.scrollableWrapper && this.scrollableWrapper.nativeElement ? this.scrollableWrapper.nativeElement.clientHeight : document.body.clientHeight;
    const baseSize = Math.ceil(height / this.avgRowHeight);

    this.portionSize = this.nOfColumns * baseSize;
  }

  private initCurrentRowNumber(scrollTop: number) {
    console.time('>> getRowNumber time');

    const rowNumber = this.getRowNumber(scrollTop);

    console.timeEnd('>> getRowNumber time');

    if (rowNumber === this.currentRowNumber) return;

    this.currentRowNumber = rowNumber;

    this.initVisibleFonts();
  }

  private initVisibleFonts(): void {
    const expectedStart = this.currentRowNumber * this.nOfColumns - this.portionSize;
    const startIdx = expectedStart < 0 ? 0 : expectedStart;
    const expectedEnd = startIdx + this.portionSize + this.portionSize * 2;
    const endIdx = expectedEnd >= this.fontsCount ? this.fontsCount : expectedEnd;

    if ((this.startIdx === startIdx && this.endIdx === endIdx) || this.fontsCount === 0) return;

    this.startIdx = startIdx;
    this.endIdx = endIdx;

    const startRowIdx = Math.trunc(this.startIdx / this.nOfColumns);

    this.initMarginTop(startRowIdx);

    this.service.initVisibleFonts(startIdx, endIdx);
  }

  private initMarginTop(startRowIdx: number): void {
    this.fontsMarginTop = this.rowsData[startRowIdx] ? this.rowsData[startRowIdx].offsetTop : 0;
  }

  public onDefaultFontClick(font: GoogleFontModel): void {
    this.service.selectedFontSubject.next(font);
    this.service.closeModal();
  }
}
