import {Component, Output, EventEmitter, Input} from '@angular/core';

import {BlocksChangelogsService} from '../../../../services/blocks-changelogs.service';

@Component({
  selector: 'app-block-template-changelog-modal',
  templateUrl: 'block-template-changelog-modal.component.html',
  styleUrls: ['block-template-changelog-modal.component.scss']
})
export class BlockTemplateChangelogModalComponent {
  @Input() id: string;

  @Output() saveHandler = new EventEmitter<string>();
  @Output() cancelHandler = new EventEmitter();

  public header: string = 'Changelog';

  public get dates() {
    return this.blocksChangelogsService.dates;
  }

  public get groupedChangeLogs() {
    return this.blocksChangelogsService.groupedChangelogs;
  }

  constructor(private blocksChangelogsService: BlocksChangelogsService) {}
}
