import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {KEYS, DEFAULT_KEY, CLASS_NAMES} from './constants';

export class CollapseOptionsModel implements IStyleOption {
  public element: HTMLElement;

  private menu: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public group?: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.menu = options.block;

    this.isEnabled = !!this.menu;

    if (!this.isEnabled) return;

    const key: string = Object.keys(KEYS).find(key => this.menu.matches(`.${CLASS_NAMES[this.group][key]}`)) || DEFAULT_KEY;

    this.setValue(key);
  }

  public reset(): void {
    this.setValue(DEFAULT_KEY);
  }

  public setValue(key: string): void {
    if (!this.isEnabled) return;

    this.value = key;

    Object.keys(KEYS).forEach(key => {
      this.menu.classList.remove(CLASS_NAMES[this.group][key]);
    });

    this.menu.classList.add(CLASS_NAMES[this.group][key]);
  }
}
