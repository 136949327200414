export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `আগুনের শিখা নিভে গিয়েছিল, আর তিনি জানলা দিয়ে তারাদের দিকে তাকালেন ৷`,
      `আমাদের প্রাই বোঝার আগেই আমরা মাটি ছেড়ে দূরে চলে এসেছিলাম৷`,
      `আমাদের সামনের দৃশ্য প্রকৃতঅর্থেই অত্যন্ত মহিমান্বিত ছিল৷`,
    ],
    [KEYS.ALPHABET]: [
      'অআইঈউঊঋঌএঐওঔকখগঘঙচছজঝঞটঠডঢণতথদধনপফবভমযরলবশষসহড়ঢ়য়ৎ₹',
    ],
    [KEYS.PARAGRAPH]: [
      `আমার উদীয়মান গতিবেগ নিয়ে এগিয়ে চলা কালীন, রাত্রি এবং দিনের রোমাঞ্চ একসময় একটা নিরন্তর ধুসরতায় মিলিত হল; এক চমৎকার গাঢ় নীল রঙে আকাশ আচ্ছাধিত হতে দেখলাম, গোধূলির প্রথম আলোর মতো অপূর্ব এক উজ্জ্বল রঙ; মহাকাশে, অস্থির সূর্য ধীরেধীরে আগুনের আঁকাবাঁকা শিখাই, একটি উজ্জ্বল গুম্বজে পরিণত হল; চাঁদের আলো অনুজ্জ্বল তরঙ্গায়িত বন্ধনীতে পরিণত হল ক্রমশ; এবং ক্ষণে-ক্ষণে নীলাভ বৃত্তের আবছা আলো ছাড়া আমি আর তারাদের দেখতে পাচ্ছিলাম না ।`
    ],
    [KEYS.NUMERALS]: [
      '০১২৩৪৫৬৭৮৯',
    ],
  };
};
