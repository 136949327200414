import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {STEPS} from './constants';

@Injectable()
export class DefaultPortfolioSetupService {
  public step: BehaviorSubject<string> = new BehaviorSubject<string>(STEPS.UPDATE);

  constructor() {
  }
}
