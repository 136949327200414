import {IStyleOption} from '../../option/i-style-option';
import {StyleOptionModel} from '../../../settings/option/style-option.model';

import {BlogCommentsBackgroundModel} from './background/blog-comments-background.model';
import {BlogCommentsTextColorModel} from './text-color/blog-comments-text-color.model';
import {BlogCommentsFontSizeModel} from './font-size/blog-comments-font-size.model';
import {BlogCommentsLineHeightModel} from './line-height/blog-comments-line-height.model';
import {BlogCommentsBorderColorModel} from './border-color/blog-comments-border-color.model';
import {BlogCommentsBorderRadiusModel} from './border-radius/blog-comments-border-radius.model';
import {BlogCommentsSeparatorColorModel} from './separator-color/blog-comments-separator-color.model';
import {BlogMarginBetweenCommentsModel} from './margin/blog-margin-between-comments.model';
import {BlogMarginBetweenChildCommentsModel} from './margin-children/blog-margin-between-child-comments.model';
import {BlogCommentsUsernameColorModel} from './username-color/blog-comments-username-color.model';
import {BlogCommentsUsernameFontSizeModel} from './username-font-size/blog-comments-username-font-size.model';
import {BlogCommentsReplyColorModel} from './reply-color/blog-comments-reply-color.model';
import {BlogCommentsReplyHoverColorModel} from './reply-hover-color/blog-comments-reply-hover-color.model';
import {BlogCommentsReplyFontSizeModel} from './reply-font-size/blog-comments-reply-font-size.model';
import {BlogCommentsTimestampColorModel} from './timestamp-color/blog-comments-timestamp-color.model';
import {BlogCommentsTimestampFontSizeModel} from './timestamp-font-size/blog-comments-timestamp-font-size.model';

import {KEYS, TYPES} from '../../../../../services/styles/blog/comments/constants';

export class BlogCommentsSetupModel {
  public element: HTMLElement;

  private enabledStyleOptions: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public background: BlogCommentsBackgroundModel = new BlogCommentsBackgroundModel(KEYS.BLOG_COMMENTS_BACKGROUND, TYPES.STYLE_PROPERTY);
  public textColor: BlogCommentsTextColorModel = new BlogCommentsTextColorModel(KEYS.BLOG_COMMENTS_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public fontSize: BlogCommentsFontSizeModel = new BlogCommentsFontSizeModel(KEYS.BLOG_COMMENTS_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public lineHeight: BlogCommentsLineHeightModel = new BlogCommentsLineHeightModel(KEYS.BLOG_COMMENTS_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public borderColor: BlogCommentsBorderColorModel = new BlogCommentsBorderColorModel(KEYS.BLOG_COMMENTS_BORDER_COLOR, TYPES.STYLE_PROPERTY);
  public borderRadius: BlogCommentsBorderRadiusModel = new BlogCommentsBorderRadiusModel(KEYS.BLOG_COMMENTS_BORDER_RADIUS, TYPES.STYLE_PROPERTY);
  public separatorColor: BlogCommentsSeparatorColorModel = new BlogCommentsSeparatorColorModel(KEYS.BLOG_COMMENTS_SEPARATOR_COLOR, TYPES.STYLE_PROPERTY);
  public marginBetweenComments: BlogMarginBetweenCommentsModel = new BlogMarginBetweenCommentsModel(KEYS.BLOG_MARGIN_BETWEEN_COMMENTS, TYPES.STYLE_PROPERTY);
  public marginBetweenChildComments: BlogMarginBetweenChildCommentsModel = new BlogMarginBetweenChildCommentsModel(KEYS.BLOG_MARGIN_BETWEEN_CHILD_COMMENTS, TYPES.STYLE_PROPERTY);
  public usernameColor: BlogCommentsUsernameColorModel = new BlogCommentsUsernameColorModel(KEYS.BLOG_COMMENTS_USERNAME_COLOR, TYPES.STYLE_PROPERTY);
  public usernameFontSize: BlogCommentsUsernameFontSizeModel = new BlogCommentsUsernameFontSizeModel(KEYS.BLOG_COMMENTS_USERNAME_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public replyColor: BlogCommentsReplyColorModel = new BlogCommentsReplyColorModel(KEYS.BLOG_COMMENTS_REPLY_COLOR, TYPES.STYLE_PROPERTY);
  public replyHoverColor: BlogCommentsReplyHoverColorModel = new BlogCommentsReplyHoverColorModel(KEYS.BLOG_COMMENTS_REPLY_HOVER_COLOR, TYPES.STYLE_PROPERTY);
  public replyFontSize: BlogCommentsReplyFontSizeModel = new BlogCommentsReplyFontSizeModel(KEYS.BLOG_COMMENTS_REPLY_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public timestampColor: BlogCommentsTimestampColorModel = new BlogCommentsTimestampColorModel(KEYS.BLOG_COMMENTS_TIMESTAMP_COLOR, TYPES.STYLE_PROPERTY);
  public timestampFontSize: BlogCommentsTimestampFontSizeModel = new BlogCommentsTimestampFontSizeModel(KEYS.BLOG_COMMENTS_TIMESTAMP_FONT_SIZE, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.background,
    this.textColor,
    this.fontSize,
    this.lineHeight,
    this.borderColor,
    this.borderRadius,
    this.separatorColor,
    this.marginBetweenComments,
    this.marginBetweenChildComments,
    this.usernameColor,
    this.usernameFontSize,
    this.replyColor,
    this.replyHoverColor,
    this.replyFontSize,
    this.timestampColor,
    this.timestampFontSize,
  ];

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.element = element;
    this.enabledStyleOptions = enabledStyleOptions;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(element);

    this.options.forEach(option => {
      option.isEnabled = this.enabledStyleOptions.has(option.key);

      if (!option.init) return;

      try {
        option.init(element, {
          innerWrapper,
          block,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.classList.contains('block') ? element : <HTMLElement>element.closest('.block');
  }
}
