import {WebsiteTourProgressItemModel} from './website-tour-progress-item.model';

export class WebsiteTourProgressItemDto {
  constructor(public Id?: number,
              public GroupKey?: string,
              public Key?: string,
              public Location?: string,
              public Text?: string,
              public Html?: string,
              public OrderNo?: number,
              public DescriptionPosition?: string,
              public VideoUrl?: string) {}

  public static normalize(res: WebsiteTourProgressItemDto): WebsiteTourProgressItemModel {
    if (!res) return null;

    return new WebsiteTourProgressItemModel(
      res.Id,
      res.GroupKey,
      res.Key,
      res.Location,
      res.Text,
      res.Html,
      res.OrderNo,
      res.DescriptionPosition,
      res.VideoUrl,
    );
  }
}
