import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SideMenuComponent {
  @Input() isCloseIconShown = true;
  @Input() header = '';

  @Output() closeHandler = new EventEmitter();

  constructor() {}
}
