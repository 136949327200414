import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import * as moment from 'moment';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EducationStudentDto} from '../../../../../models/education/students/education-student.dto';
import {EducationStudentModel} from '../../../../../models/education/students/education-student.model';

import {StudentImageAction, StudentImageReviewAction, StudentImageReviewAudioAction, StudentImageUpdateAction} from '../../../../education/students/constants';

@Injectable()
export class EducationStudentsHttpService {
  private prefix: string = 'api/education/students/current';

  constructor(private http: HttpClient) {
  }

  public getCurrent(websiteId: number): Observable<EducationStudentModel> {
    const params = new HttpParams()
      .set('websiteId', `${websiteId}`);

    return this.http.get(this.prefix, { params }).pipe(
      map((item: EducationStudentDto) => EducationStudentDto.normalize(item))
    );
  }

  public joinClass(inviteCode: string): Observable<any> {
    return this.http.post('api/education/students', { inviteCode });
  }

  public sendAction(action: string, userId: number, websiteId: number, portfolioId: number): Observable<any> {
    const params = new HttpParams()
     .set('websiteId', `${websiteId}`);

    return this.http.post(`${this.prefix}/images/action`, { action, userId, websiteId, portfolioId }, {
      params,
    });
  }

  public sendImageAction(portfolioId: number, imageId: number, action: StudentImageAction, file: Blob): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('image', file);
    formData.append('portfolioId', `${portfolioId}`);

    return this.http.post(`${this.prefix}/images/${imageId}/actions/${action}`, formData);
  }

  public sendImageReviewAction(imageId: number, reviewsIds: number[], action: StudentImageReviewAction): Observable<any> {
    return this.http.put(`${this.prefix}/images/${imageId}/reviews/actions/${action}`, {
      reviewsIds,
      timestamp: moment().format('YYYY-MM-DD HH:mm:ss Z'),
    });
  }

  public sendImageReviewAudioAction(imageId: number, audioId: number, action: StudentImageReviewAudioAction): Observable<any> {
    return this.http.post(`${this.prefix}/images/${imageId}/audios/${audioId}/actions/${action}`, {
      timestamp: moment().format('YYYY-MM-DD HH:mm:ss Z'),
    });
  }

  public sendManyImageUpdateSuggestionsAction(imageId: number, updateSuggestionsIds: number[], action: StudentImageUpdateAction): Observable<any> {
    return this.http.post(`${this.prefix}/images/${imageId}/updates/actions/${action}`, {
      updateSuggestionsIds,
      timestamp: moment().format('YYYY-MM-DD HH:mm:ss Z'),
    });
  }

  public getUpdateSuggestionsForImage(imageId: number): Observable<any> {
    return this.http.get(`${this.prefix}/images/${imageId}/updates`);
  }
}
