import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTE, ATTRIBUTE_DEFAULT_KEY, DEFAULT_KEY} from './constants';

export class ImageCropModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    this.isEnabled = this.isEnabled && !!element;

    if (!this.isEnabled) return;

    this.element = element;

    this.value = this.element.getAttribute(ATTRIBUTE);
  }

  public onChange(key: string): void {
    this.value = key;

    if (!this.isEnabled) return;

    this.element.setAttribute(ATTRIBUTE, key);
  }

  public onReset(): void {
    this.value = this.element.getAttribute(ATTRIBUTE_DEFAULT_KEY) || DEFAULT_KEY;

    if (!this.isEnabled) return;

    this.element.setAttribute(ATTRIBUTE, this.value);
  }
}
