import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class AnimationDelayModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.setValue(`${this.element.getAttribute(ATTRIBUTES.ANIMATION_DELAY)}`);
  }

  public onChange(value: number): void {
    this.setValue(`${value}`);
  }

  public setValue(value: string) {
    this.value = value;
    this.element.setAttribute(ATTRIBUTES.ANIMATION_DELAY, value);
  }
}
