import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox-option',
  templateUrl: './checkbox-option.component.html',
  styleUrls: ['./checkbox-option.component.scss']
})
export class CheckboxOptionComponent {
  @Input() name: string;
  @Input() value: number = 0;
  @Input() bottomPadding: string = null;
  @Input() isBold: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() valueChange = new EventEmitter();

  public handleChange(event): void {
    this.valueChange.emit(event);
  }
}
