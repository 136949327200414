import {Injectable} from '@angular/core';

import {BlogAddCommentButtonSetupModel} from '../../../../models/styles/setup/blog/add-comment-button/blog-add-comment-button-setup.model';

import {StyleOptionModel} from '../../../../models/styles/settings/option/style-option.model';

@Injectable()
export class BlogAddCommentButtonStylesService {
  public setup: BlogAddCommentButtonSetupModel;

  public get element(): HTMLElement {
    return this.setup.element;
  }

  constructor() {
    this.setup = new BlogAddCommentButtonSetupModel();
  }

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.setup.init(element, enabledStyleOptions);
  }
}
