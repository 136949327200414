export const KEY_CODES = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  CTRL: 17,
  ALT: 18,
  ESC: 27,
  DELETE: 46,
  END: 35,
  HOME: 36,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,

  NUMBER_0: 48,
  NUMBER_1: 49,
  NUMBER_2: 50,
  NUMBER_3: 51,
  NUMBER_4: 52,
  NUMBER_5: 53,
  NUMBER_6: 54,
  NUMBER_7: 55,
  NUMBER_8: 56,
  NUMBER_9: 57,

  A: 65,
  C: 67,
  V: 86,
  X: 88,

  COMMAND: 91,
  PERIOD: 190,
};

export const NUMBERS_KEY_CODES = [
  KEY_CODES.NUMBER_0,
  KEY_CODES.NUMBER_1,
  KEY_CODES.NUMBER_2,
  KEY_CODES.NUMBER_3,
  KEY_CODES.NUMBER_4,
  KEY_CODES.NUMBER_5,
  KEY_CODES.NUMBER_6,
  KEY_CODES.NUMBER_7,
  KEY_CODES.NUMBER_8,
  KEY_CODES.NUMBER_9,
];

export const ALLOWED_DECIMAL_NUMBER_KEY_CODES = [
  KEY_CODES.BACKSPACE,
  KEY_CODES.CTRL,
  KEY_CODES.ALT,
  KEY_CODES.ESC,
  KEY_CODES.DELETE,

  KEY_CODES.END,
  KEY_CODES.HOME,
  KEY_CODES.ARROW_LEFT,
  KEY_CODES.ARROW_UP,
  KEY_CODES.ARROW_RIGHT,
  KEY_CODES.ARROW_DOWN,

  KEY_CODES.COMMAND,
  KEY_CODES.PERIOD,
];

export const ALLOWED_CTRL_KEYS = [
  KEY_CODES.A,
  KEY_CODES.C,
  KEY_CODES.V,
  KEY_CODES.X,
];
