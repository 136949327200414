import {Component, ElementRef, Input, OnInit, OnChanges, ViewChild, SimpleChanges, Output, EventEmitter, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';

import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';
import {SelectOption} from '../../../../core/models/select/option/option.model';

import {CONTENT_TYPE_OPTIONS} from '../constants';
import {CONTENT_TYPE_KEYS} from '../../../../core/services/google/fonts/constants';

import Timer = NodeJS.Timer;
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-fonts-manager-font',
  templateUrl: './fonts-manager-font.component.html',
  styleUrls: ['./fonts-manager-font.component.scss'],
})
export class FontsManagerFontComponent implements OnInit, OnDestroy, OnChanges {
  @Input() font: GoogleFontModel;
  @Input() fontSize: number;
  @Input() lineHeight: number;
  @Input() customText: string;
  @Input() outputType: string;
  @Input() subset: string;

  @Output() loadHandler: EventEmitter<GoogleFontModel> = new EventEmitter<GoogleFontModel>();
  @Output() cellHeightHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() specimenShowHandler: EventEmitter<GoogleFontModel> = new EventEmitter<GoogleFontModel>();

  @ViewChild('textInput') textInput: ElementRef;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public exampleText: string = null;
  public customTextValue: string = null;

  public dir: string = 'ltr';

  public isShown: boolean = false;
  public isHovered: boolean = false;
  public isFocused: boolean = false;
  public isSingleVariant: boolean = false;
  public isOpenedFromAdminPanel: boolean = false;

  public lineHeightValue: number;

  public fontSizeValue: number = 0;
  public fontSizeString: string = `0px`;

  public contentTypeOptions: SelectOption[] = null;
  public selectedContentType: SelectOption = null;

  public variantsOptions: SelectOption[] = null;
  public selectedVariant: SelectOption = null;

  private oldHeight: number = null;

  private isCustomOutputType: boolean = false;
  private isRootCustomOutputType: boolean = false;

  private notifyTimeoutId: Timer;

  public get buttonStatusText(): string {
    if (this.isOpenedFromAdminPanel) return 'ADD TO DEFAULTS';

    return this.font.isAdded ? 'ADDED' : 'ADD FONT';
  }

  private get fontData(): { font: GoogleFontModel, height: number, oldHeight: number } {
    return {
      font: this.font,
      height: this.hostRef.nativeElement.clientHeight,
      oldHeight: this.oldHeight,
    };
  }

  constructor(private hostRef: ElementRef,
              private service: GoogleFontsService) {
    this.onFontAddedToDefaults = this.onFontAddedToDefaults.bind(this);
    this.onError = this.onError.bind(this);
  }

  public ngOnInit(): void {
    this.service.isOpenedFromAdminPanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpenedFromAdminPanel: boolean) => {
      this.isOpenedFromAdminPanel = isOpenedFromAdminPanel;
    });

    this.isSingleVariant = this.font.variants.length === 1;
    this.dir = this.service.getDir(this.subset);

    this.loadFont();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['fontSize']) {
      this.fontSizeValue = changes['fontSize'].currentValue;
      this.fontSizeString = `${changes['fontSize'].currentValue}px`;

      this.oldHeight = 0;

      this.notifyAboutHeightWithDelay();
    }

    if (changes['lineHeight']) {
      this.lineHeightValue = changes['lineHeight'].currentValue;

      this.oldHeight = 0;

      this.notifyAboutHeightWithDelay();
    }

    if (changes['outputType']) {
      this.initFontOutputTypeKey(changes['outputType'].currentValue);

      this.isRootCustomOutputType = this.isCustomOutputType;

      this.customTextValue = this.isCustomOutputType && changes['customText'] ? changes['customText'].currentValue : '';

      this.oldHeight = 0;

      this.initStrings();
    }

    if (changes['customText']) {
      if (!this.isRootCustomOutputType) return;

      this.isCustomOutputType = this.isRootCustomOutputType;

      this.customTextValue = changes['customText'].currentValue;

      this.oldHeight = 0;

      this.initStrings();
    }
  }

  public onMouseEnter() {
    this.isHovered = true;
    this.isShown = true;

    this.initFont();
  }

  public onMouseLeave() {
    this.isHovered = false;
    this.isShown = this.isFocused;

    this.initFont();
  }

  public onFocus() {
    this.isFocused = true;
    this.isShown = true;

    this.initFont();
  }

  public onBlur() {
    this.isFocused = false;
    this.isShown = this.isHovered;

    this.initFont();
  }

  private initFont() {
    this.initContentType();
    this.initVariants();
  }

  private initContentType() {
    this.contentTypeOptions = this.isShown ? this.contentTypeOptions || CONTENT_TYPE_OPTIONS() : null;

    this.initSelectedContentType();
  }

  public onContentTypeChange(selectedOption: SelectOption): void {
    this.initFontOutputTypeKey(selectedOption.value);

    this.initSelectedContentType();
    this.initStrings();

    this.onFontSizeSliderChange(`${this.service.getFontSize(this.font.outputTypeKey)}`);

    if (!this.textInput) return;

    return this.isCustomOutputType ? this.textInput.nativeElement.focus() : this.textInput.nativeElement.blur();
  }

  private initFontOutputTypeKey(value: string): void {
    this.font.outputTypeKey = value;

    this.initIsCustomOutputType();
  }

  private initIsCustomOutputType(): void {
    this.isCustomOutputType = this.font.outputTypeKey === CONTENT_TYPE_KEYS.CUSTOM;
  }

  private initStrings(): void {
    if (this.isCustomOutputType) {
      this.exampleText = this.customTextValue;
      return this.notifyAboutHeightWithDelay();
    }

    const strings = this.service.getStrings(this.subset, this.font.outputTypeKey);

    this.exampleText = strings[this.font.index % strings.length];

    this.notifyAboutHeightWithDelay();
  }

  private initSelectedContentType(): void {
    if (!this.contentTypeOptions) return;

    this.selectedContentType = this.contentTypeOptions.find(item => item.value === this.font.outputTypeKey) || this.contentTypeOptions[0];

    this.contentTypeOptions.forEach((option: SelectOption) => option.isSelected = option === this.selectedContentType);

    this.notifyAboutHeight();
  }

  // async ok
  public async onVariantChange(selectedOption: SelectOption) {
    this.font.isLoaded = false;

    this.font.variant = selectedOption.value;

    this.initSelectedVariant();

    await this.loadFont();

    this.notifyAboutHeight();
  }

  private initVariants() {
    this.variantsOptions = this.isShown ? this.variantsOptions || this.service.getVariants(this.font) : null;

    this.initSelectedVariant();
  }

  private initSelectedVariant(): void {
    if (!this.variantsOptions) return;

    this.selectedVariant = this.variantsOptions.find(item => item.value === this.font.variant) || this.variantsOptions[0];

    this.variantsOptions.forEach((option: SelectOption) => option.isSelected = option === this.selectedVariant);
  }

  // async ok
  private async loadFont() {
    await this.service.loadFont(this.font);

    this.font.isLoaded = true;

    this.loadHandler.emit(this.font);

    this.notifyAboutHeight();
  }

  public onTextChange(): void {
    this.isCustomOutputType = true;
    this.font.outputTypeKey = CONTENT_TYPE_KEYS.CUSTOM;

    this.initContentType();

    this.notifyAboutHeight();
  }

  public onTextPaste(): void {
    setTimeout(() => {
      this.textInput.nativeElement.innerHTML = this.textInput.nativeElement.innerText;
    });
  }

  public onFontSizeSliderChange(value: string): void {
    this.fontSizeValue = Number.parseInt(value);
    this.fontSizeString = `${value}px`;
    this.lineHeightValue = this.service.calcLineHeight(this.fontSizeValue);

    this.notifyAboutHeight();
  }

  private notifyAboutHeightWithDelay(): void {
    clearTimeout(this.notifyTimeoutId);

    this.notifyTimeoutId = setTimeout(() => this.notifyAboutHeight(), 200);
  }

  private notifyAboutHeight(): void {
    const data = this.fontData;

    this.cellHeightHandler.emit(data);

    this.oldHeight = data.height;
  }

  public onStatusButtonClick(): void {
    if (!this.font || this.font.isDefault) return;

    if (this.isOpenedFromAdminPanel) return this.onAddToDefaultsClick();

    this.service.onStatusButtonClick(this.font);
  }

  public onSpecimenClick(): void {
    this.specimenShowHandler.emit(this.font);
  }

  public onAddToDefaultsClick(): void {
    this.service.addDefaultFont(this.font).subscribe(this.onFontAddedToDefaults, this.onError);
  }

  private onFontAddedToDefaults(): void {
    const item = this.service.fullListSubject.value.find(font => font.family === this.font.family);

    item.isDefault = true;
    this.font.isDefault = true;

    this.service.fetchWebsiteData();
  }

  private onError(e: HttpErrorResponse): void {
    try {
      const err = JSON.parse(e.error);

      console.error(err);
    } catch (e) {
      console.error(e);
    }
  }
}
