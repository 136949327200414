import {Component, OnDestroy} from '@angular/core';

import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';
import {MailChimpService} from '../../../../services/mailchimp.service';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class MailingListComponent implements OnDestroy {
  public isLoading: boolean = false;

  public listMembers = [];
  public mailingLists = [];
  public mailChimpListId: string = null;

  public currentPage: number = 1;
  public pagesCount: number;
  public count: number = 15;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  get isConnected() {
    return this.mailChimpService.status.value === this.mailChimpService.API_STATUSES.CONNECTED;
  }

  get isNotConnected() {
    return this.mailChimpService.status.value === this.mailChimpService.API_STATUSES.NOT_CONNECTED;
  }

  constructor(public mailChimpService: MailChimpService,
              private permissionsService: PermissionsService,
              private buttonsService: ButtonsService,
              private editorControlButtonsService: EditorControlButtonsService) {
    this.mailChimpService
      .mailchimpListIdSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(key => this.mailChimpListId = key);

    this.mailChimpService
      .status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onStatusChange.bind(this));

    editorControlButtonsService
      .controlBtnClick
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.save.bind(this));
  }

  ngOnDestroy() {
    this.buttonsService.disableAllButtons();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onStatusChange(status) {
    if (status === this.mailChimpService.API_STATUSES.CONNECTED) return this.handleApiReady();

    this.mailingLists = null;
  }

  save() {
    this.isLoading = true;
    
    this.mailChimpService.updateMailChimpListId(this.mailChimpListId).pipe(
      catchError(e => {
        console.log(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    ).subscribe(() => {});
  }

  logout() {
    this.mailChimpService.removeApiKey();
  }

  handleApiReady() {
    this.mailChimpService.getLists().pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
    ).subscribe(({ lists }) => {
      this.mailingLists = lists;

      if (this.mailChimpListId) {
        return this.updateMembers();
      }

      this.mailChimpListId = this.mailingLists[0].id;

      this.updateMembers();
      this.save();
    });
  }

  onListChange() {
    this.currentPage = 1;
    
    this.updateMembers();

    this.buttonsService.enableSaveButton();
  }

  private updateMembers(): Subscription {
    this.isLoading = true;
    
    return this.mailChimpService.getListMembers(this.mailChimpListId, {
      offset: this.count * (this.currentPage - 1),
      count: this.count,
    }).pipe(
      catchError(e => {
        this.mailChimpListId = null;
        
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(({ members, total_items }) => {
      this.pagesCount = Math.ceil(total_items / this.count);
      this.listMembers = members;
    });
  }

  handlePageChange(page) {
    this.currentPage = page;

    this.updateMembers();
  }
}
