import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {EventsService} from '../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../core/services/iframe/iframe.service';
import {DragService} from './drag.service';
import {UtilsService} from '../../../core/services/utils/utils.service';

export interface DraggableOptions {
  zone?: string;
  targetKey?: string;
  data?: any;
  dragTemplate?: any;
  needMoveHandle?: boolean;
  isCopy?: boolean;
}

@Directive({
  selector: '[appElementDraggable]'
})
export class DraggableDirective implements OnDestroy {
  @Input()
  public set appElementDraggable(options: DraggableOptions) {
    this.options = options;
  }

  @Output()
  public handleDrop = new EventEmitter();

  @Output()
  public handleDragStart = new EventEmitter();

  private options: DraggableOptions;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private mouseUpListener: any;
  private mouseMoveListener: any;

  private isContentLoaded: boolean = false;

  constructor(
    private elem: ElementRef,
    private dragService: DragService,
    private eventsService: EventsService,
    private iFrameService: IFrameService,
    private utilsService: UtilsService,
    private renderer: Renderer2,
  ) {
    this.iFrameService.onContentLoad.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.isContentLoaded = value;
    });
  }

  @HostListener('touchstart', ['$event'])
  public onTouchStart(event) {
    if (this.options.targetKey) {
      return;
    }

    event.preventDefault(); // To stop text selection in safari

    const position = event.touches[0];

    this.dragService.type = this.dragService.types.touch;

    event.clientX = position.pageX;
    event.clientY = position.pageY;

    this.onDragStart(event);

    this.options.dragTemplate.classList.remove('hidden');

    this.onDrag(event, position.pageX, position.pageY);
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event) {
    if (!this.isContentLoaded || !this.iFrameService.sandboxWindow || event.currentTarget.getAttribute('draggable') === 'false') {
      return;
    }

    this.eventsService.dispatchAttachDragEvents({ data: this.options.data, elem: this.elem.nativeElement }, this.iFrameService.sandboxWindow);

    if (this.options.targetKey) {
      return;
    }

    this.attachMouseUpListener();
    this.attachMouseMoveListener();

    this.dragService.isMouseDown = true;
    event.preventDefault(); // To stop text selection in safari

    if (this.options.dragTemplate) {
      window.setTimeout(() => {
        if (!this.dragService.isMouseDown) {
          return;
        }

        this.dragService.type = this.dragService.types.mouse;
        this.onDragStart(event);
        this.options.dragTemplate.classList.remove('hidden');
        this.onDrag(event, event.clientX, event.clientY);
      }, 200);
    }
  }

  private onDragStart(event) {
    this.dragService.isDragging = true;

    this.handleDragStart.next(event);

    const { zone = 'zone', data = {} } = this.options;

    this.dragService.startDrag(zone);

    this.dragService.isCopy = this.options.isCopy;
    this.dragService.data = Object.assign({}, data);
    this.dragService.dropped = false;
    this.dragService.dragStart();
  }

  @HostListener('touchmove', ['$event'])
  public onTouchMove(event) {
    event.preventDefault();

    if (this.dragService.type === this.dragService.types.touch && this.dragService.isDragging && !this.dragService.dropped) {
      const position = event.touches[0];
      
      this.onDrag(event, position.pageX, position.pageY);
    }
  }

  private onDrag(event, x, y) {
    if (this.options.targetKey) {
      return;
    }

    this.options.dragTemplate.style.left = x - this.options.dragTemplate.offsetWidth / 2 + 'px';
    this.options.dragTemplate.style.top = y - this.options.dragTemplate.offsetHeight / 2 + 'px';

    this.dragService.drag({ event, x, y, data: this.options.data });
  }

  private onMouseUp(event) {
    this.mouseUpListener();
    this.mouseMoveListener();

    this.dragService.isMouseDown = false;

    if (this.dragService.type === this.dragService.types.mouse) {
      this.onDrop(event);
    }

    this.dragService.drop({ event, x: event.clientX, y: event.clientY });
  }

  @HostListener('touchend', ['$event'])
  public onTouchEnd(event) {
    if (this.dragService.type === this.dragService.types.touch) {
      this.onDrop(event);
    }

    const position = event.changedTouches[0];

    this.dragService.drop({ event, x: position.pageX, y: position.pageY });
  }

  private onDrop(event) {
    const isDropZone = !!event.target.querySelector('#sandbox');

    if (this.handleDrop.observers.length !== 0) {
      this.handleDrop.next({ isDropZone });
      
      this.dragService.dropped = true;
    }
  }

  private attachMouseUpListener() {
    this.mouseUpListener = this.renderer.listen('document', 'mouseup', (event) => {
      if (this.options.targetKey) {
        return;
      }

      this.onMouseUp(event);
    });
  }

  private attachMouseMoveListener() {
    this.mouseMoveListener = this.renderer.listen('document', 'mousemove', this.utilsService.throttle((event) => {
      if (this.options.targetKey) {
        return;
      }

      this.onMouseMove(event);
    }, 16));
  }

  private onMouseMove(event) {
    if (this.dragService.type !== this.dragService.types.mouse) {
      return;
    }

    this.onDrag(event, event.clientX, event.clientY);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
