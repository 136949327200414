import {Injectable} from '@angular/core';

import {Subject, Observable, BehaviorSubject, Subscription} from 'rxjs';
import {concatMap} from 'rxjs/operators';

import {EducationStudentsImagesHttpService} from '../../../../../../interaction/http/education/teachers/institutions/classes/students/images/education-students-images-http.service';

import {StudentImageReviewModel} from '../../../../../../../models/images/review/student-image-review.model';
import {ImageModel} from '../../../../../../../models/images/image.model';
import {ImageUpdateSuggestionModel} from '../../../../../../../models/education/image-update-suggestion/image-update-suggestion.model';
import {IUpdateResponse} from '../../../../../../../models/responses/i-update-response';

@Injectable()
export class EducationStudentsImagesService {
  public currentImageReviewSubject: BehaviorSubject<StudentImageReviewModel> = new BehaviorSubject<StudentImageReviewModel>(null);
  public currentImageExhibitedImagesSubject: BehaviorSubject<ImageModel[]> = new BehaviorSubject<ImageModel[]>(null);
  
  public imageReviewDeleteSubject: Subject<StudentImageReviewModel> = new Subject<StudentImageReviewModel>();

  constructor(private httpService: EducationStudentsImagesHttpService) {
  }

  public cloneImage(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, targetPortfolioId: number): Observable<any> {
    return this.httpService.cloneImage(institutionId, classId, studentId, portfolioId, imageId, targetPortfolioId).pipe(
      concatMap(() => {
        return this.httpService.getExhibitedImages(institutionId, classId, studentId, portfolioId, imageId);
      })
    );
  }

  public sendAction(institutionId: number, classId: number, studentId: number, portfolioId: number, userId: number, websiteId: number, action: string): Subscription {
    return this.httpService.sendAction(institutionId, classId, studentId, portfolioId, userId, websiteId, action).subscribe(() => {});
  }

  public sendImageReviewUpdateNotification(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number): Subscription {
    return this.httpService.sendImageReviewUpdateNotification(institutionId, classId, studentId, portfolioId, imageId).subscribe(() => {});
  }

  public getReview(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number): Subscription {
    return this.httpService.getReview(institutionId, classId, studentId, portfolioId, imageId).subscribe((review: StudentImageReviewModel) => {
      this.currentImageReviewSubject.next(review);
    });
  }

  public getExhibitedImages(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number): Subscription {
    return this.httpService.getExhibitedImages(institutionId, classId, studentId, portfolioId, imageId).subscribe((exhibitedImages: ImageModel[]) => {
      this.currentImageExhibitedImagesSubject.next(exhibitedImages);
    });
  }

  public addReview(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, review: StudentImageReviewModel): Observable<StudentImageReviewModel> {
    return this.httpService.addReview(institutionId, classId, studentId, portfolioId, imageId, review);
  }

  public updateReview(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, review: StudentImageReviewModel): Observable<IUpdateResponse> {
    return this.httpService.updateReview(institutionId, classId, studentId, portfolioId, imageId, review);
  }

  public addUpdateSuggestion(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, updateSuggestion: ImageUpdateSuggestionModel): Observable<any> {
    return this.httpService.addUpdateSuggestion(institutionId, classId, studentId, portfolioId, imageId, updateSuggestion);
  }

  public removeClonedImage(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, targetPortfolioId: number): Observable<ImageModel[]> {
    return this.httpService.removeClonedImage(institutionId, classId, studentId, portfolioId, imageId, targetPortfolioId).pipe(
      concatMap(() => {
        return this.httpService.getExhibitedImages(institutionId, classId, studentId, portfolioId, imageId);
      })
    );
  }

  public initEmptyReview(imageId: number): void {
    const review: StudentImageReviewModel = new StudentImageReviewModel(
      null,
      imageId,
      null,
      null,
      0,
      '',
      null,
    );

    review.isEmpty = true;
    
    this.currentImageReviewSubject.next(review);
  }
}
