import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, OnDestroy, Output, OnChanges, SimpleChanges} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IFrameRoutingService} from '../../../../../core/services/iframe/routing/iframe-routing.service';
import {BlocksManagerService} from '../../../../../core/services/blocks/blocks.service';
import {WebsiteTourService} from '../../../../../core/services/website-tour/website-tour.service';

import {SelectedPageModel} from '../../../../../core/models/selected-page/selected-page.model';
import {OutdatedBlocksDataModel} from '../../../../../core/models/outdated-blocks-data/outdated-blocks-data.model';

import {KEYS} from '../../../../../core/services/website-tour/constants';

@Component({
  selector: 'app-blocks-categories',
  templateUrl: './blocks-categories.component.html',
  styleUrls: ['./blocks-categories.component.scss']
})
export class BlocksCategoriesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() categories;
  @Input() selectedCategory;
  @Input() descriptions;

  @Output() toggleElementHandler = new EventEmitter();

  private ngUnsubscribe = new Subject<void>();

  private outdatedBlocksData: OutdatedBlocksDataModel;
  private selectedPage: SelectedPageModel;

  public outdatedTooltipText: string = 'The design or functionality of block(s) in this category has changed. To see these changes on your website you will need to replace your current block(s).';

  constructor(private chRef: ChangeDetectorRef,
              private iFrameRoutingService: IFrameRoutingService,
              private websiteTourService: WebsiteTourService,
              private blocksManagerService: BlocksManagerService) {
  }

  public ngOnInit(): void {
    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;
    });

    this.blocksManagerService.outdatedBlocksSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: OutdatedBlocksDataModel) => {
      this.outdatedBlocksData = data;

      this.chRef.detectChanges();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['categories']) {
      const isMenuCategoryExists: boolean = changes['categories'].currentValue.find(category => category.category === 'Menus');

      if (isMenuCategoryExists) {
        this.websiteTourService.addVisibleItem(KEYS.SIDEBAR_BLOCKS_MENU_CATEGORY_TITLE);
      }
    }
  }

  isExpanded(category) {
    return category.available && this.selectedCategory && this.selectedCategory.category === category.category;
  }

  hasOutdatedBlocks(category) {
    if (!this.outdatedBlocksData) return false;

    return this.outdatedBlocksData.isSomeOutdated(category.files, this.selectedPage);
  }

  selectCategory(event, element) {
    this.toggleElementHandler.next({ event, element });
  }

  public tourGroupKey(title: string): string {
    return title === 'Menus' ? 'SIDEBAR_BLOCKS_MENU_CATEGORY_TITLE' : null;
  }

  public tourKey(title: string): string {
    return title === 'Menus' ? 'SIDEBAR_BLOCKS_MENU_CATEGORY_TITLE' : null;
  }

  public tourLocation(title: string): string {
    return title === 'Menus' ? 'SIDEBAR_BLOCKS' : null;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
