import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

import {ModalsService} from '../../../services/modals/modals.service';
import {DashboardSupportCallService} from '../../../services/modals/dashboard-support-call/dashboard-support-call.service';

@Component({
  selector: 'app-dashboard-support-call-modal',
  templateUrl: './dashboard-support-call-modal.component.html',
  styleUrls: ['./dashboard-support-call-modal.component.scss']
})
export class DashboardSupportCallModalComponent implements OnDestroy {
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(private modalsService: ModalsService,
              private service: DashboardSupportCallService) {
  }

  public submitHandler(): void {
    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
