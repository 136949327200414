import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';

import {ICartItem} from '../../../../models/cart/item/cart-item.model';

@Injectable()
export class CartHttpService {
  private model: string = 'cart';

  constructor(private http: HttpClient) {}

  public fetch(): Observable<ICartItem[]> {
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    return this.http.get<ICartItem[]>(`/api/${this.model}`, { headers });
  }

  public addItem(item: ICartItem): Observable<any> {
    return this.http.post(`/api/${this.model}`, item);
  }

  public deleteItem(item: ICartItem): Observable<any> {
    return this.http.delete(`/api/${this.model}/${item.stripePriceId}`);
  }
}
