import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {IsDesktopSetupMatchedModel} from '../is-desktop-setup-matched/is-desktop-setup-matched.model';
import {TitleFontSizeModel} from '../font-size/title-font-size.model';
import {TitleLineHeightModel} from '../line-height/title-line-height.model';
import {LogoPositionModel} from '../logo-position/logo-position-model';
import {TitleTextColorModel} from '../text-color/title-text-color.model';
import {WebsiteTitleScalableFontSizeModel} from '../scalable-font-size/website-title-scalable-font-size.model';
import {WebsiteTitleScalableLineHeightModel} from '../scalable-line-height/website-title-scalable-line-height.model';
import {WebsiteTitleScalableLetterSpacingModel} from '../scalable-letter-spacing/website-title-scalable-letter-spacing.model';
import {WebsiteTitleScalableWordSpacingModel} from '../scalable-word-spacing/website-title-scalable-word-spacing.model';

import {KEYS, TYPES} from '../../../../../services/styles/title/mobile/constants';

export class MobileTitleSetupModel {
  public title: HTMLElement;

  public isDesktopSetupMatched: IsDesktopSetupMatchedModel = new IsDesktopSetupMatchedModel(KEYS.IS_DESKTOP_SETUP_MATCHED, TYPES.ATTRIBUTE);
  public fontSize: TitleFontSizeModel = new TitleFontSizeModel(KEYS.FONT_SIZE, TYPES.STYLE_PROPERTY);
  public lineHeight: TitleLineHeightModel = new TitleLineHeightModel(KEYS.LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public logoPosition: LogoPositionModel = new LogoPositionModel(KEYS.MOBILE_TITLE_LOGO_POSITION, TYPES.CLASS);
  public textColor: TitleTextColorModel = new TitleTextColorModel(KEYS.MOBILE_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public scalableFontSize: WebsiteTitleScalableFontSizeModel = new WebsiteTitleScalableFontSizeModel(KEYS.SCALABLE_MOBILE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public scalableLineHeight: WebsiteTitleScalableLineHeightModel = new WebsiteTitleScalableLineHeightModel(KEYS.SCALABLE_MOBILE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public scalableLetterSpacing: WebsiteTitleScalableLetterSpacingModel = new WebsiteTitleScalableLetterSpacingModel(KEYS.SCALABLE_MOBILE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public scalableWordSpacing: WebsiteTitleScalableWordSpacingModel = new WebsiteTitleScalableWordSpacingModel(KEYS.SCALABLE_MOBILE_WORD_SPACING, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.isDesktopSetupMatched,
    this.fontSize,
    this.lineHeight,
    this.logoPosition,
    this.textColor,
    this.scalableFontSize,
    this.scalableLineHeight,
    this.scalableLetterSpacing,
    this.scalableWordSpacing,
  ];

  private initHandlers = {
    [TYPES.CLASS]: this.initClass.bind(this),
    [TYPES.STYLE_PROPERTY]: this.initStyleProperty.bind(this),
    [TYPES.ATTRIBUTE]: this.initAttribute.bind(this),
  };

  public init(title: HTMLElement): void {
    this.title = title;

    if (!this.title) return;

    const innerWrapper: HTMLElement = title ? <HTMLElement>title.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(title);

    this.options.forEach(option => {
      this.initHandlers[option.type](option, {
        innerWrapper,
        block,
      });
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.matches('[data-menu-block]') ? element : <HTMLElement>element.closest('[data-menu-block]');
  }

  private initClass(option: IStyleOption, options: IStyleModelOptions): void {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.title, options);
  }

  private initStyleProperty(option: IStyleOption, options: IStyleModelOptions): void {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.title, options);
  }

  private initAttribute(option: IStyleOption, options: IStyleModelOptions): void {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.title, options);
  }
}
