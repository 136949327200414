import {IStyleOption} from './i-style-option';
import {IStyleModelOptions} from './i-style-model-options';

export class SetupOptionModel implements IStyleOption {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = false,
  ) {}

  public init(element: HTMLElement, options?: IStyleModelOptions) {
  }
}
