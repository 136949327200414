import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {SidebarSectionsService} from '../../../services/sidebar-sections.service';
import {SidebarService} from '../../../core/services/sidebar/sidebar.service';
import {SidebarLoaderService} from '../../../services/sidebar-loader.service';
import {IFrameRoutingService} from '../../../core/services/iframe/routing/iframe-routing.service';

import {TabSettingsModel} from '../../../core/models/sidebar/tabs-settings.model';
import {SelectedPageModel} from '../../../core/models/selected-page/selected-page.model';

import {PAGE_TYPE_DETAILS} from '../constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public selectedTab: TabSettingsModel;

  public pageTypeDetails: string = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private cdr: ChangeDetectorRef,
              private sidebarSectionsService: SidebarSectionsService,
              private iFrameRoutingService: IFrameRoutingService,
              public sidebarService: SidebarService,
              public sidebarLoaderService: SidebarLoaderService) {
  }

  public ngOnInit(): void {
    this.sidebarService.currentTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tab: TabSettingsModel) => {
      this.selectedTab = tab;

      this.cdr.detectChanges();
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      if (!selectedPage) {
        this.pageTypeDetails = null;

        return;
      }
      
      this.pageTypeDetails = PAGE_TYPE_DETAILS[selectedPage.isSplash ? 'S' : selectedPage.type];
    });
  }

  public closeSidebar() {
    this.sidebarSectionsService.clearSelection();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
