import {Component} from '@angular/core';

import {PlansHttpService} from '../../../../core/services/interaction/http/plans/plans-http.service';
import {PermissionsHttpService} from '../../../../core/services/interaction/http/permissions/permissions-http.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';
import { catchError, finalize, forkJoin, throwError } from 'rxjs';

@Component({
  selector: 'app-plans-permissions',
  templateUrl: './plans-permissions.component.html',
  styleUrls: ['./plans-permissions.component.scss']
})
export class AdminPlansPermissionsComponent {
  plans;
  permissions;
  hoveredPlanId;

  private key = 'AdminPlansPermissionsComponent';

  constructor(private planService: PlansHttpService,
              private loaderService: ContentLoaderService,
              private permissionsHttpService: PermissionsHttpService) {
    this.fetch();
  }

  fetch() {
    this.loaderService.show(this.key);

    forkJoin([
      this.planService.getPlansWithPermissions(),
      this.permissionsHttpService.getPermissions(),
    ]).pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(([plans, permissions]) => {
      this.plans = plans;
      this.permissions = permissions;
    });
  }

  isPlanPermissionEnabled(plan, requiredPermission) {
    return !!plan.Permissions.find(permission => permission.ID === requiredPermission.ID);
  }

  public changePlanPermissions($event, plan, permission) {
    const isChecked: boolean = $event.currentTarget.checked;

    const data = {
      planId: plan.Id,
      permissionId: permission.ID
    };

    return (
      isChecked
        ? this.planService.addPermission(data)
        : this.planService.removePermission(data)
      ).subscribe(() => {});
  }

  onCellHover(plan) {
    this.hoveredPlanId = plan.Id;
  }

  onCellBlur() {
    this.hoveredPlanId = null;
  }
}
