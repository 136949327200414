import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../auth/auth.service';
import {NavigationService} from '../services/navigation.service';
import {WebsitesService} from '../core/services/websites/websites.service';
import {RoutingService} from '../core/services/routing/routing.service';
import {SetupLoaderService} from '../core/services/loaders/setup/setup-loader.service';
import {SocketsService} from '../core/services/interaction/sockets/sockets.service';

import {WebsiteModel} from '../core/models/websites/website.model';

@Component({
  selector: 'app-setup',
  templateUrl: 'setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit, OnDestroy {
  public user;
  public isMaintenanceOverlayVisible: boolean = false;

  private website: WebsiteModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private routingService: RoutingService,
              private navigationService: NavigationService,
              private setupLoaderService: SetupLoaderService,
              private authService: AuthService,
              private websitesService: WebsitesService,
              private socketsService: SocketsService) {
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ user }) => {
      this.user = user;

      if (user && !user.IsEmailVerified && !user.IsUnderAdmin) return this.authService.redirectToEmailVerification();
      if (!user) return this.navigationService.toApp();
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      if (!website) return;

      if (website.isSetupCompleted) return this.website ? null : this.navigationService.toApp();

      this.website = website;

      if (!website.title) return this.routingService.toSetup();

      if (!website.templateId) return this.router.navigateByUrl('/setup/select-template');

      this.router.navigateByUrl('/setup/upload-images');
    });

    this.socketsService.isMaintenanceOverlayVisibleSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isMaintenanveOverlayVisible: boolean) => {
      this.isMaintenanceOverlayVisible = isMaintenanveOverlayVisible;
    });
  }

  ngOnDestroy(): void {
    this.setupLoaderService.hide();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
