import {Injectable} from '@angular/core';

import {LoaderService} from '../loader.service';

@Injectable()
export class SetupLoaderService {
  private key = 'setup-flow';

  constructor(private loaderService: LoaderService) {}

  show() {
    this.loaderService.show(this.key);
  }

  hide() {
    this.loaderService.hide(this.key);
  }
}
