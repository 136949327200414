import {Component} from '@angular/core';

import { catchError, finalize, throwError } from 'rxjs';

import {ContentLoaderService} from '../../../../../core/services/loaders/content/content-loader.service';
import {ActivatedRoute} from '@angular/router';
import {BlocksTemplatesHttpService} from '../../../../../core/services/interaction/http/admin/blocks-templates/blocks-templates-http.service';
import {BlocksChangelogsModel} from '../../../../../core/models/blocks-changelogs/blocks-changelogs.model';

@Component({
  selector: 'app-block-template-changelog',
  templateUrl: './block-template-changelog.component.html',
  styleUrls: ['./block-template-changelog.component.scss']
})
export class BlockTemplateChangelogComponent {
  private key = 'BlockTemplateChangelog';
  private routeParamsSubscription: any;

  public blockTemplateId: number;
  public changelogs: BlocksChangelogsModel[] = [];


  constructor(private loaderService: ContentLoaderService,
              private blocksTemplatesHttpService: BlocksTemplatesHttpService,
              private activatedRoute: ActivatedRoute) {
    this.routeParamsSubscription = this.activatedRoute.params.subscribe(params => {
      this.blockTemplateId = parseInt(params['blockTemplateId'], 10);
      this.loadUpdatesInfo(this.blockTemplateId);
    });
  }

  loadUpdatesInfo(blockTemplateId: number) {
    this.loaderService.show(this.key);

    return this.blocksTemplatesHttpService.getBlockTemplateChangelogs(blockTemplateId).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(changelogs => {
      this.changelogs = changelogs;
    });
  }

  saveChangelogMessage(changelog) {
    this.loaderService.show(this.key);

    return this.blocksTemplatesHttpService.updateBlockUpdateInfo(this.blockTemplateId, changelog).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(() => {});
  }
}
