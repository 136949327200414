import {Injectable} from '@angular/core';

import {Subscription, BehaviorSubject} from 'rxjs';

import {EducationClassesHttpService} from '../../../../interaction/http/education/teachers/institutions/classes/education-classes-http.service';

import {EducationClassModel} from '../../../../../models/education/classes/education-class.model';

@Injectable()
export class EducationClassesService {
  public classesSubject: BehaviorSubject<EducationClassModel[]> = new BehaviorSubject<EducationClassModel[]>(null);
  public classDetailsSubject: BehaviorSubject<EducationClassModel> = new BehaviorSubject<EducationClassModel>(null);
  public isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpService: EducationClassesHttpService) {
  }

  public fetchOne(institutionId: number, classId: number): Subscription {
    this.isLoadedSubject.next(false);

    return this.httpService.getOne(institutionId, classId).subscribe((res: EducationClassModel) => {
      this.classDetailsSubject.next(res);

      this.isLoadedSubject.next(true);
    });
  }
}
