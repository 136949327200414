// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-fonts {
  height: 100%;
  padding: 18px;
}
.default-fonts .title {
  transform: translateZ(0);
}
.default-fonts .default-fonts-list {
  display: flex;
  flex-direction: column;
  height: calc(100% - 36px);
}
.default-fonts .default-fonts-list .header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 4px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  transform: translateZ(0);
}
.default-fonts .default-fonts-list .header .font-title {
  font-weight: 600;
  padding: 7px 5px;
}
.default-fonts .default-fonts-list .list {
  overflow: auto;
}
.default-fonts .default-fonts-list .list .font {
  padding: 7px 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/admin-panel/default-fonts/default-fonts.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;AACE;EACE,wBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;AAAJ;AAEI;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,mBAAA;EACA,4BAAA;EAAA,uBAAA;EACA,wBAAA;AAAN;AAEM;EACE,gBAAA;EACA,gBAAA;AAAR;AAQI;EACE,cAAA;AANN;AAQM;EACE,gBAAA;AANR","sourcesContent":[".default-fonts {\n  height: 100%;\n  padding: 18px;\n\n  .title {\n    transform: translateZ(0);\n  }\n\n  .default-fonts-list {\n    display: flex;\n    flex-direction: column;\n    height: calc(100% - 36px);\n\n    .header {\n      display: flex;\n      justify-content: space-between;\n      border-bottom: 1px solid black;\n      padding-bottom: 4px;\n      min-height: fit-content;\n      transform: translateZ(0);\n\n      .font-title {\n        font-weight: 600;\n        padding: 7px 5px;\n      }\n\n      .add-font-button {\n\n      }\n    }\n\n    .list {\n      overflow: auto;\n\n      .font {\n        padding: 7px 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
