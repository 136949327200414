import {Injectable} from '@angular/core';

import {Observable, BehaviorSubject} from 'rxjs';

import {WebsitesHttpService} from '../interaction/http/websites/websites-http.service';
import {AuthService} from '../../../auth/auth.service';
import {LoaderService} from '../loaders/loader.service';
import {SocketsService} from '../interaction/sockets/sockets.service';

import {WebsiteModel} from '../../models/websites/website.model';
import {AccountModel} from '../../models/accounts/account.model';
import {ISocketWebsitesMessageDataModel} from '../../models/sockets/message/websites/i-websites-message-data.model';


@Injectable()
export class MultiWebsiteService {
  public websitesSubject: BehaviorSubject<WebsiteModel[]> = new BehaviorSubject<WebsiteModel[]>(null);
  public isNewWebsiteAddingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly loaderId: string = 'MultiWebsiteService';

  private websitesSocketDataHandlers = {
    'WEBSITES_LIST_CHANGED': this.initList.bind(this),
  };

  constructor(
    private websitesHttpService: WebsitesHttpService,
    private authService: AuthService,
    private socketsService: SocketsService,
    private loaderService: LoaderService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      if (!account) return;

      this.initList();
    });

    this.socketsService.websitesDataSubject.subscribe((data: ISocketWebsitesMessageDataModel) => {
      this.onWebsitesSocketData(data);
    });
  }

  private onWebsitesSocketData(data: ISocketWebsitesMessageDataModel): void {
    if (!data || !this.websitesSocketDataHandlers[data.key]) {
      return;
    }

    this.websitesSocketDataHandlers[data.key](data);
  }

  public initList(): void {
    this.websitesHttpService.fetchWebsites().subscribe((items: WebsiteModel[]) => {
      this.websitesSubject.next(items);
    });
  }

  public setActiveWebsite(id: number) {
    return this.authService.updateActiveWebsite(id);
  }

  public createNewWebsite(title: string): Observable<WebsiteModel> {
    return this.websitesHttpService.createNewWebsite({ title });
  }

  public showLoader(): void {
    this.loaderService.show(this.loaderId);
  }

  public hideLoader(): void {
    this.loaderService.hide(this.loaderId);
  }
}
