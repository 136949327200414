import {Pipe, PipeTransform} from '@angular/core';

import {addSizePrefix} from '../util/formatting/image';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'imageSizePrefix'
})
export class ImageSizePrefixPipe implements PipeTransform {

  constructor(private httpClient: HttpClient) { }

  private checkImageFromBucket(url) {
    return url.includes(environment.aws.s3.bucket); // To filter default img (Remove after default img would be uploaded to s3)
  }

  transform(url: string, size: string): string {
    const imageUrlWithPrefix = addSizePrefix(url, size);
    return this.checkImageFromBucket(imageUrlWithPrefix) ? imageUrlWithPrefix : url;
  }
}
