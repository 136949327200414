import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject} from 'rxjs';

import {ImageManagerService} from '../../../application/main/image-manager/image-manager.service';

import {ImageModel} from '../../../core/models/images/image.model';

@Injectable()
export class FullscreenImageManagerEnlargementService {
  public currentImageSubject: BehaviorSubject<ImageModel> = new BehaviorSubject<ImageModel>(null);
  public currentImageIndexSubject: BehaviorSubject<{ current: number, total: number }> = new BehaviorSubject<{ current: number, total: number }>(null);
  public isOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onCurrentPortfolioReload: Subject<void> = new Subject<void>();

  public images: ImageModel[];

  public lastRegularViewKey: string;

  public currentIndex: number;
  public currentImage: ImageModel;
  
  public constructor(private imageManagerService: ImageManagerService) {
  }

  public open({ images, currentImage, previousView }: { images: ImageModel[], currentImage: ImageModel, previousView: string }): void {
    this.isOpenedSubject.next(true);

    this.images = images;
    this.currentIndex = images.findIndex((i: ImageModel) => i.id === currentImage.id);

    this.lastRegularViewKey = this.getLastRegularViewKey(previousView);

    this.initCurrentImage();
  }

  public reInit({ images }): void {
    this.images = images;
    
    this.initCurrentImage();
  }

  private getLastRegularViewKey(value: string): string {
    if (value === 'thumbnails' || value === 'large') {
      return value;
    }

    return this.lastRegularViewKey || 'thumbnails';
  }

  public close(): void {
    this.isOpenedSubject.next(false);
    
    this.images = null;
    this.currentIndex = null;
    
    this.initCurrentImage();
  }

  public onPrev(): void {
    if (!this.images) {
      return;
    }

    this.currentIndex = this.currentIndex === 0 ? this.images.length - 1 : this.currentIndex - 1;

    this.initCurrentImage();
  }

  public onNext(): void {
    if (!this.images) {
      return;
    }

    this.currentIndex = this.currentIndex === this.images.length - 1 ? 0 : this.currentIndex + 1;

    this.initCurrentImage();
  }

  private initCurrentImage(): void {
    if (!this.images || this.currentIndex > this.images.length) {
      this.currentImage = null;

      this.currentImageSubject.next(null);
      this.currentImageIndexSubject.next(null);

      return;
    }

    this.currentImage = this.images[this.currentIndex];

    this.currentImageSubject.next(this.images[this.currentIndex]);
    
    this.imageManagerService.onImageSelectSubject.next(this.images[this.currentIndex]);
    
    this.currentImageIndexSubject.next({
      current: this.currentIndex,
      total: this.images.length,
    });
  }
}
