import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ModalsService} from '../../../services/modals/modals.service';
import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {
  @Input()  id = 'logout-modal';

  @Output() revertChanges = new EventEmitter();
  @Output() continueEditing = new EventEmitter();

  header = {
    className: 'warning-header',
  };

  buttons: Button[] = [
    {
      text: `Logout anyway`,
      className: 'red dont-save-button',
      onClick: this.revertChangesHandler.bind(this),
    },
    {
      text: `Cancel`,
      className: 'neutral continue-editing-button',
      onClick: this.continueEditingHandler.bind(this),
    },
  ];

  constructor(private modalsService: ModalsService) { }

  private revertChangesHandler(): void {
    this.revertChanges.emit();

    this.modalsService.close(this.id);
  }

  public continueEditingHandler(): void {
    this.continueEditing.emit();

    this.modalsService.close(this.id);
  }
}

