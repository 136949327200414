import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class BlocksDeleteModalService {
  public readonly id = 'blocks-delete-modal';

  public isOpened = false;

  public node: any = null;

  public constructor(private modalsService: ModalsService) {
  }

  public open(node): void {
    this.node = node;

    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;
    this.node = null;

    this.modalsService.close(this.id);
  }
}
