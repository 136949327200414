const EVENTS = {
  ADDED: 'blockAdded',
  DUPLICATED: 'blockDuplicated',
  LIST_CHANGED: 'blocksListChanged',
  REMOVE: 'blockRemove',
  VIEW_CHANGELOG: 'viewChangelog',
};

export {
  EVENTS,
};
