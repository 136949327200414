import {AttributeBoolModel} from '../../base/attribute-bool/attribute-bool.model';

import {ATTRIBUTES} from './constants';

export class IsContactInfoUsedModel extends AttributeBoolModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, ATTRIBUTES.IS_CONTACT_INFO_USED, value, isEnabled);
  }

  public init(element: HTMLElement) {
    this.isEnabled = !!element && element.matches(`[${ATTRIBUTES.IS_DISPLAYED}="true"]`);

    this.element = <HTMLElement>element.closest('.block,.portfolio-enlargement-wrapper');

    if (!this.isEnabled) return;

    if (!this.element) return;

    this.value = this.element.getAttribute(ATTRIBUTES.IS_CONTACT_INFO_USED) !== 'false';

    this.element.setAttribute(ATTRIBUTES.IS_CONTACT_INFO_USED, `${this.value}`);

    super.init(this.element);
  }

  public onChange(value: boolean): void {
    if (!this.isEnabled || !this.element) return;

    super.onChange(value);
  }
}
