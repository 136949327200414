import {BaseFontFamilyModel} from '../base/font-family/base-font-family.model';

import {STYLE_PROPERTY_NAME, USED_FONT_ATTR, SELECTORS} from './constants';

export class SlideshowButtonFontFamilyModel extends BaseFontFamilyModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled, STYLE_PROPERTY_NAME, USED_FONT_ATTR);
  }

  public init(element: HTMLElement): void {
    const elementToSelect: Element = element.closest(SELECTORS.SLIDESHOW_SLIDE) || element.closest(SELECTORS.BLOCK);

    super.init(<HTMLElement>elementToSelect);
  }
}
