import * as moment from 'moment';

import {TeacherPortfolioDataModel} from './teacher-portfolio-data.model';

import {TIME_FORMATTER} from './constants';

export class TeacherPortfolioDataDto {
  constructor(public Id?: number,
              public TeacherId?: number,
              public PortfolioId?: number,
              public Status?: string,
              public LastViewedAt?: string) {}

  public static normalize(res: TeacherPortfolioDataDto): TeacherPortfolioDataModel {
    if (!res) return null;

    return new TeacherPortfolioDataModel(
      res.Id,
      res.TeacherId,
      res.PortfolioId,
      res.Status,
      moment(res.LastViewedAt).format(TIME_FORMATTER),
    );
  }
}
