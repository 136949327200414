import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ApplicationComponent} from './application/application.component';
import {WebsiteDesignerComponent} from './application/main/website-designer/website-designer.component';
import {PageEditorComponent} from './application/main/website-designer/page-editor/page-editor.component';
import {ImageManagerComponent} from './application/main/image-manager/image-manager.component';
import {PagesSectionComponent} from './application/sidebar-short/sidebar/pages/pages.component';
import {BlocksSectionComponent} from './application/sidebar-short/sidebar/blocks/blocks.component';
import {AppTreeComponent} from './application/sidebar-short/sidebar/pages/tree/tree.component';
import {SettingsSectionComponent} from './application/sidebar-short/sidebar/settings/settings.component';
import {AccountSectionComponent} from './application/sidebar-short/sidebar/account/account.component';
import {AdminSectionComponent} from './application/sidebar-short/sidebar/admin/admin-section.component';
import {EducatorAdminSectionComponent} from './application/sidebar-short/sidebar/educator-admin/educator-admin-section.component';

import {ErrorComponent} from './error/error.component';
import {NotFoundComponent} from './error/not-found/not-found.component';
import {InternalServerErrorComponent} from './error/internal-server-error/internal-server-error.component';

import {UserResolver} from './auth/auth-resolver.service';
import {WebsiteSettingsComponent} from './application/main/settings/website/website-settings.component';
import {ContactInfoSettingsComponent} from './application/main/settings/contact-info/contact-info-settings.component';
import {SettingsComponent} from './application/main/settings/settings.component';
import {CanLeaveComponentGuard} from './shared/services/guards/can-leave-component-guard.service';
import {MailGuard} from './shared/components/mail/guard/mail-guard.service';
import {LoginContactComponent} from './application/main/settings/account/login-contact/login-contact.component';
import {DomainsComponent} from './application/main/settings/account/domains/domains.component';
import {SubscriptionsComponent} from './application/main/settings/account/subscriptions/subscriptions.component';
import {PaymentsComponent} from './application/main/settings/account/payments/payments.component';
import {SupportHistoryComponent} from './application/main/settings/account/support-history/support-history.component';
import {DashboardComponent} from './application/main/dashboard/dashboard.component';
import {PurchaseComponent} from './application/main/settings/account/purchase/purchase.component';
import {PurchaseAddOnsComponent} from './application/main/settings/account/purchase-add-ons/purchase-add-ons.component';
import {RepeatPaymentComponent} from './application/main/settings/account/purchase/repeat-payment/repeat-payment.component';
import {AdminImportListComponent} from './application/main/admin-panel/import/admin-import-list.component';
import {AdminCustomerListComponent} from './application/main/admin-panel/customers/admin-customer-list.component';
import {AdminPanelComponent} from './application/main/admin-panel/admin-panel.component';
import {EducatorAdminComponent} from './application/main/educator/educator-admin.component';
import {EducatorAdminStudentsComponent} from './application/main/educator/students/educator-admin-students.component';
import {SetupComponent} from './setup/setup.component';
import {SetupIntroductionComponent} from './setup/introduction/setup-introduction.component';
import {SetupWebsiteSettingsComponent} from './setup/website-settings/setup-website-settings.component';
import {SetupUploadImagesComponent} from './setup/upload-images/setup-upload-images.component';
import {MailingListComponent} from './application/main/settings/mailing-list/mailing-list.component';
import {SocialNetworksComponent} from './application/main/settings/social-networks/social-networks.component';
import {BlogPermissionsComponent} from './shared/components/blog/permissions/blog-permissions.component';
import {SecuritySettingsComponent} from './application/main/settings/security-settings/security-settings.component';
import {PayPalComponent} from './application/main/settings/paypal/paypal.component';
import {EducationSettingsComponent} from './application/main/settings/education/education.component';
import {AdminCustomerInfoComponent} from './application/main/admin-panel/customers/customer-info/customer-info.component';
import {AdminEducationComponent} from './application/main/admin-panel/education/education.component';
import {AdminEducationTeachersComponent} from './application/main/admin-panel/education/teachers/teachers.component';
import {AdminEducationClassesComponent} from './application/main/admin-panel/education/classes/classes.component';
import {AdminEducationInstitutionsComponent} from './application/main/admin-panel/education/institutions/institutions.component';
import {AdminPlansComponent} from './application/main/admin-panel/plans/plans.component';
import {AdminPlansPermissionsComponent} from './application/main/admin-panel/plans-permissions/plans-permissions.component';
import {AdminCustomSubscriptionsComponent} from './application/main/admin-panel/custom-subscriptions/custom-subscriptions.component';
import {AdminFreeTrialComponent} from './application/main/admin-panel/free-trial/free-trial.component';
import {AdminBlocksTemplatesComponent} from './application/main/admin-panel/blocks-templates/blocks-templates.component';
import {AdminBlocksChangesComponent} from './application/main/admin-panel/blocks-changes/blocks-changes.component';
import {AdminGaAccountsComponent} from './application/main/admin-panel/ga-accounts/ga-accounts.component';
import {AdminAppVersionComponent} from './application/main/admin-panel/app-version/app-version.component';
import {FontsManagerComponent} from './shared/components/fonts-manager/fonts-manager.component';
import {MediaManagerComponent} from './shared/components/media-manager/media-manager.component';
import {CreditCardsPageComponent} from './application/main/settings/account/credit-cards-page/credit-cards-page.component';
import {MailComponent} from './shared/components/mail/mail.component';
import {BlockTemplateChangelogComponent} from './application/main/admin-panel/blocks-templates/block-template-changelog/block-template-changelog.component';
import {WebsitesManagerComponent} from './core/components/websites-manager/websites-manager.component';
import {TemplatesManagerComponent} from './core/components/templates-manager/templates-manager.component';
import {AdminDefaultFontsComponent} from './application/main/admin-panel/default-fonts/default-fonts.component';
import {AdminStripeComponent} from './application/main/admin-panel/stripe/admin-stripe.component';
import {AdminAppSettingsComponent} from './application/main/admin-panel/app-settings/app-settings.component';
import {GoogleAnalyticsSetupComponent} from './core/components/google-analytics-setup/google-analytics-setup.component';

import {PermissionGuard} from './core/services/service-permissions/guard/permission.guard';
import {SubscriptionActiveGuard} from './core/guards/auth/subscription-active/subscription-active.guard';
import {SetupCompletedGuard} from './core/guards/setup-completed/setup-completed.guard';
import {ForwardGuard} from './core/guards/forward/forward.guard';
import {DefaultPageGuard} from './core/guards/default-page/default-page.guard';
import {PathNotFoundGuard} from './core/guards/path-not-found/path-not-found.guard';
import {IsClassActiveGuard} from './core/guards/educator/is-class-active/is-class-active.guard';

import {PERMISSIONS} from './core/services/service-permissions/constants';

const appRoutes: Routes = [
  {
    path: 'setup',
    component: SetupComponent,
    resolve: {
      user: UserResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'introduction',
        pathMatch: 'full',
      },
      {
        path: 'introduction',
        component: SetupIntroductionComponent,
      },
      {
        path: 'website-settings',
        component: SetupWebsiteSettingsComponent,
      },
      {
        path: 'upload-images',
        component: SetupUploadImagesComponent,
      },
      {
        path: 'select-template',
        component: TemplatesManagerComponent,
      },
    ]
  },
  {
    path: 'app',
    component: ApplicationComponent,
    canActivate: [
      ForwardGuard,
      SetupCompletedGuard,
    ],
    children: [
      {
        path: 'admin',
        component: AdminPanelComponent,
        canActivate: [PermissionGuard],
        data: {
          path: 'admin',
          requiredPermissions: [
            {
              type: 'permission',
              value: PERMISSIONS.ADMIN,
            },
          ],
        },
        children: [
          {
            path: '',
            redirectTo: 'customers',
            pathMatch: 'full'
          },
          {
            path: 'customers',
            component: AdminCustomerListComponent,
          },
          {
            path: 'customers/:customerId',
            component: AdminCustomerInfoComponent,
          },
          {
            path: 'education',
            component: AdminEducationComponent,
            children: [
              {
                path: '',
                redirectTo: 'teachers',
                pathMatch: 'full'
              },
              {
                path: 'institutions',
                component: AdminEducationInstitutionsComponent,
              },
              {
                path: 'teachers',
                component: AdminEducationTeachersComponent,
              },
              {
                path: 'classes',
                component: AdminEducationClassesComponent,
              },
            ],
          },
          {
            path: 'plans',
            component: AdminPlansComponent,
          },
          {
            path: 'plans-permissions',
            component: AdminPlansPermissionsComponent,
          },
          {
            path: 'import',
            component: AdminImportListComponent,
          },
          {
            path: 'custom-subscriptions',
            component: AdminCustomSubscriptionsComponent,
          },
          {
            path: 'blocks-templates',
            component: AdminBlocksTemplatesComponent,
          },
          {
            path: 'blocks-changes',
            component: AdminBlocksChangesComponent,
          },
          {
            path: 'blocks-templates/:blockTemplateId',
            component: BlockTemplateChangelogComponent,
          },
          {
            path: 'ga-accounts',
            component: AdminGaAccountsComponent,
          },
          {
            path: 'app-version',
            component: AdminAppVersionComponent,
          },
          {
            path: 'default-fonts',
            component: AdminDefaultFontsComponent,
          },
          {
            path: 'stripe',
            component: AdminStripeComponent,
          },
          {
            path: 'app-settings',
            component: AdminAppSettingsComponent,
          },
          {
            path: 'free-trial',
            component: AdminFreeTrialComponent,
          },
        ],
      },
      {
        path: 'educator-admin',
        component: EducatorAdminComponent,
        canActivate: [SubscriptionActiveGuard],
        data: {
          requiredPermissions: [
            {
              type: 'permission',
              value: PERMISSIONS.EDUCATOR_ADMIN,
            },
          ],
        },
        
        children: [
          {
            path: '',
            redirectTo: 'students',
            pathMatch: 'full',
          },
          {
            path: 'students',
            component: EducatorAdminStudentsComponent,
          },
        ],
      },
      {
        path: 'website-designer',
        component: WebsiteDesignerComponent,
        canActivate: [
          SubscriptionActiveGuard,
          IsClassActiveGuard,
        ],
        data: {
          path: 'website-designer'
        },
        children: [
          {
            path: 'page-editor',
            component: PageEditorComponent,
            data: {
              path: 'page-editor'
            },
            canDeactivate: [CanLeaveComponentGuard],
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
          },
        ]
      },
      {
        path: 'image-manager',
        component: ImageManagerComponent,
        canActivate: [SubscriptionActiveGuard],
        canDeactivate: [CanLeaveComponentGuard],
        data: {
          path: 'image-manager'
        },
      },
      {
        path: 'websites-manager',
        component: WebsitesManagerComponent,
        canActivate: [PermissionGuard],
        data: {
          path: 'websites-manager',
          requiredPermissions: [
            {
              type: 'permission',
              value: PERMISSIONS.MULTI_WEBSITE_ADMIN_PANEL,
            },
          ],
        },
      },
      {
        path: 'classes-manager',
        component: WebsitesManagerComponent,
        canActivate: [PermissionGuard],
        data: {
          path: 'websites-manager',
          requiredPermissions: [
            {
              type: 'permission',
              value: PERMISSIONS.CLASSES_MANAGER,
            },
          ],
        },
      },
      {
        path: 'templates-manager',
        component: TemplatesManagerComponent,
        canActivate: [SubscriptionActiveGuard],
      },
      {
        path: 'media',
        component: MediaManagerComponent,
        canActivate: [SubscriptionActiveGuard],
      },
      {
        path: 'fonts-manager',
        component: FontsManagerComponent,
        canActivate: [SubscriptionActiveGuard],
      },
      {
        path: 'google-analytics/setup/:step',
        component: GoogleAnalyticsSetupComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          {
            path: '',
            redirectTo: 'purchase',
            pathMatch: 'full'
          },
          // {
          //   path: 'ecommerce',
          //   component: EcommerceSettingsComponent // Not used. Exists
          // },
          {
            path: 'login-contact',
            component: LoginContactComponent,
            canDeactivate: [CanLeaveComponentGuard],
          },
          {
            path: 'purchase',
            component: PurchaseComponent,
          },
          {
            path: 'purchase-add-ons',
            component: PurchaseAddOnsComponent,
          },
          {
            path: 'credit-cards',
            component: CreditCardsPageComponent,
          },
          {
            path: 'payments',
            component: PaymentsComponent,
          },
          {
            path: 'support',
            component: SupportHistoryComponent,
          },
          {
            path: 'subscriptions',
            component: SubscriptionsComponent,
          },
          {
            path: 'domains',
            component: DomainsComponent,
            canActivate: [SubscriptionActiveGuard],
          },
          {
            path: 'repeat-payment',
            component: RepeatPaymentComponent,
          },
          {
            path: 'website',
            component: WebsiteSettingsComponent,
            canActivate: [SubscriptionActiveGuard],
            canDeactivate: [CanLeaveComponentGuard],
          },
          {
            path: 'contact-info',
            component: ContactInfoSettingsComponent,
            canActivate: [SubscriptionActiveGuard],
            canDeactivate: [CanLeaveComponentGuard],
          },
          {
            path: 'mailing-list',
            component: MailingListComponent,
            canActivate: [
              SubscriptionActiveGuard,
              PermissionGuard,
            ],
            data: {
              requiredPermissions: [
                {
                  type: 'permission',
                  value: PERMISSIONS.MAILING_LIST,
                },
              ],
            },
          },
          {
            path: 'social-networks',
            component: SocialNetworksComponent,
            canActivate: [SubscriptionActiveGuard],
            canDeactivate: [CanLeaveComponentGuard],
          },
          {
            path: 'security',
            component: SecuritySettingsComponent,
            canActivate: [SubscriptionActiveGuard],
          },
          {
            path: 'paypal',
            component: PayPalComponent,
            canActivate: [SubscriptionActiveGuard],
          },
          {
            path: 'education',
            component: EducationSettingsComponent,
          },
        ],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [SubscriptionActiveGuard],
      },
      {
        path: 'mail',
        component: MailComponent,
        canActivate: [
          SubscriptionActiveGuard,
          MailGuard,
        ],
      },
      {
        path: 'blog-permissions',
        component: BlogPermissionsComponent,
        canActivate: [SubscriptionActiveGuard],
      },
      {
        path: 'tree',
        component: AppTreeComponent,
        canActivate: [SubscriptionActiveGuard],
        outlet: 'sidebar',
      },
      {
        path: 'pages',
        component: PagesSectionComponent,
        canActivate: [SubscriptionActiveGuard],
        outlet: 'sidebar',
      },
      {
        path: 'settings/:section',
        component: SettingsSectionComponent,
        outlet: 'sidebar'
      },
      {
        path: 'blocks',
        component: BlocksSectionComponent,
        canActivate: [SubscriptionActiveGuard],
        outlet: 'sidebar'
      },
      {
        path: 'account/:section',
        component: AccountSectionComponent,
        outlet: 'sidebar'
      },
      {
        path: 'admin/:section',
        component: AdminSectionComponent,
        outlet: 'sidebar',
        canActivate: [PermissionGuard],
        data: {
          requiredPermissions: [
            {
              type: 'permission',
              value: PERMISSIONS.ADMIN,
            },
          ],
        },
      },
      {
        path: 'educator-admin/:section',
        component: EducatorAdminSectionComponent,
        outlet: 'sidebar',
        canActivate: [PermissionGuard],
        data: {
          requiredPermissions: [
            {
              type: 'permission',
              value: PERMISSIONS.EDUCATOR_ADMIN,
            },
          ],
          isForbiddenForAdmins: true,
        },
      },
      {
        path: '',
        canActivate: [DefaultPageGuard],
        children: [],
        pathMatch: 'full',
      },
    ],
  },
];

const errorRoutes = [
  {
    path: 'error',
    component: ErrorComponent,
    children: [
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: '500',
        component: InternalServerErrorComponent
      }
    ]
  }
];

const defaultRoutes: Routes = [
  {
    path: '',
    canActivate: [DefaultPageGuard],
    children: [],
    pathMatch: 'full',
  },
  {
    path: '**',
    canActivate: [PathNotFoundGuard],
    component: PathNotFoundGuard,
    data: {
      errorUrl: '/errors/404'
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes),
    RouterModule.forChild(errorRoutes),
    RouterModule.forRoot(defaultRoutes, {
      enableTracing: false,
      canceledNavigationResolution: 'replace',
      paramsInheritanceStrategy: 'always',
      urlUpdateStrategy: 'deferred',
    }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {}
