import {Injectable} from '@angular/core';

import {SETUP_STAGES, REDIRECT_URLS} from './constants';

@Injectable()
export class InitialSetupService {
  public currentStage: string;

  get url() {
    return this.getUrl(this.currentStage);
  }

  get introUrl() {
    return REDIRECT_URLS[SETUP_STAGES.INTRO];
  }

  constructor() {}

  public getUrl(stage: string) {
    return REDIRECT_URLS[stage] || this.introUrl;
  }
}
