import {AccountModel} from "../../accounts/account.model";
import {EducationClassModel} from "../classes/education-class.model";
import {EducationInstitutionModel} from "../institutions/education-institution.model";

export class EducationTeacherModel {
  constructor(public id?: number,
              public userId?: number,
              public user?: AccountModel,
              public institutions?: EducationInstitutionModel[],
              public classes?: EducationClassModel[],
              public createdBy?: AccountModel,
              public deletedBy?: AccountModel,
              public createdAt?: string,
              public updatedAt?: string,
              public deletedAt?: string) {
  }
}
