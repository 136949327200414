import {Component, Input} from '@angular/core';
import {ModalsService} from '../../../services/modals/modals.service';
import {Button} from '../../../../common/models/button/button.model';
import {DragService} from '../../../../application/directives/drag-n-drop/drag.service';
import {PagesService} from '../../../../application/sidebar-short/sidebar/pages/pages.service';

@Component({
  selector: 'app-pages-rearrangement-warning-modal',
  templateUrl: './pages-rearrangement-warning-modal.component.html',
  styleUrls: ['./pages-rearrangement-warning-modal.component.scss']
})
export class PagesRearrangementWarningModalComponent {
  @Input() id = 'pages-rearrangement-modal';

  header = {
    className: 'warning-header',
  };

  buttons: Button[] = [
    {
      text: `Cancel`,
      className: 'red',
      onClick: this.cancelDropHandler.bind(this),
    },
    {
      text: `Continue`,
      className: 'green',
      onClick: this.continueDropHandler.bind(this),
    },
  ];

  constructor(private modalsService: ModalsService,
              private dragService: DragService,
              private pagesService: PagesService) {
  }

  cancelDropHandler() {
    this.dragService.frame.contentWindow.disableDndMode();
    this.dragService.frame.contentWindow.showBlocks();
    this.dragService.frame.contentWindow.removeBlockThumb();
    this.handleDragEnd();

    this.modalsService.close(this.id);
  }

  continueDropHandler() {
    this.pagesService.rearrangeTree();

    this.dragService.frame.contentWindow.dropEmulation({ block: this.dragService.data });
    this.dragService.frame.contentWindow.disableDndMode();
    this.handleDragEnd();

    this.modalsService.close(this.id);
  }

  handleDragEnd() {
    this.dragService.frame.style.pointerEvents = 'all';
    this.dragService.data = null;
    this.dragService.isDragging = false;
  }
}
