import { InvoiceShortModel } from "./invoice-short.model";

export class InvoiceShortDto {
  constructor(
    public id?: string,
    public status?: string,
    public invoiceStatus?: string,
  ) {}

  public static normalize(res: InvoiceShortDto): InvoiceShortModel {
    if (!res) {
      return null;
    }

    return new InvoiceShortModel(
      res.id,
      res.status,
      res.invoiceStatus,
    );
  }
}
