import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {DashboardSupportCallService} from '../../../../shared/services/modals/dashboard-support-call/dashboard-support-call.service';
import {NavigationService} from '../../../../services/navigation.service';
import {AuthService} from '../../../../auth/auth.service';

import {SupportStatisticBarModel} from './support-statistic-bar.model';
import {AccountSummaryModel} from '../../../../core/models/accounts/summary/account-summary.model';
import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-support-statistic',
  templateUrl: './support-statistic.component.html',
  styleUrls: ['./support-statistic.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SupportStatisticComponent implements OnInit, OnDestroy {
  public account: AccountModel;
  public accountSummary: AccountSummaryModel;
  public currentSubscription: SubscriptionModel;

  public bars: SupportStatisticBarModel[] = [];

  public isViewDisabled: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private paymentSubscriptionsService: PaymentSubscriptionsService,
              private dashboardSupportCallService: DashboardSupportCallService,
              private navigationService: NavigationService,
              private authService: AuthService) {
  }

  public ngOnInit(): void {
    this.authService.fetchAccountSummary();

    this.authService.accountSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((account: AccountModel) => !!account)
    ).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.authService.accountSummarySubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((accountSummary: AccountSummaryModel) => !!accountSummary)
    ).subscribe((accountSummary: AccountSummaryModel) => {
      this.accountSummary = accountSummary;

      this.initBars();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      this.initBars();
    });
  }

  private initBars(): void {
    if (!this.currentSubscription || !this.accountSummary) return;

    const planPercent: number = this.accountSummary.planSupportTimeMin / this.currentSubscription.plan.includedSupportTimeMin * 100;

    const planBar: SupportStatisticBarModel = new SupportStatisticBarModel(
      'plan',
      '+ SUPPORT PLAN',
      this.accountSummary.planSupportTimeMin,
      `${planPercent}%`,
      this.currentSubscription.plan.includedSupportTimeMin > 0,
      planPercent === 100,
    );

    const addOnPercent: number = this.accountSummary.addOnSupportTimeMin / this.account.totalAddOnSupportTime * 100;

    const addOnBar: SupportStatisticBarModel = new SupportStatisticBarModel(
      'addOn',
      'ADD-ON SUPPORT',
      this.accountSummary.addOnSupportTimeMin,
      `${addOnPercent > 100 ? 100 : addOnPercent}%`,
      this.accountSummary.addOnSupportTimeMin > 0,
      addOnPercent >= 100,
    );

    const isNormalSequence: boolean = planBar.isEnabled || !addOnBar.isEnabled;

    this.bars = [
      isNormalSequence ? planBar : addOnBar,
      isNormalSequence ? addOnBar : planBar,
    ];

    this.isViewDisabled = !planBar.isEnabled && !addOnBar.isEnabled;
  }

  public openCallModal(): void {
    this.dashboardSupportCallService.open();
  }

  public toPlansPurchase(): void {
    this.navigationService.toPurchase();
  }

  public toSupportHistory(): void {
    this.navigationService.toSupportHistory();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
