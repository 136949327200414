export class CustomerFilterOptions {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;

  constructor() { }

  get value() {
    const result: any = {};

    if (this.Id && this.Id.length) result.Id = +this.Id;
    if (this.FirstName && this.FirstName.length) result.FirstName = this.FirstName;
    if (this.LastName && this.LastName.length) result.LastName = this.LastName;
    if (this.Email && this.Email.length) result.Email = this.Email;

    return result;
  }
}
