import {Injectable} from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class UserResolver  {
  constructor(private router: Router, private authService: AuthService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authService.userReplaySubject.subscribe(user => {
        resolve(user);
      });
      this.authService.updateCurrentUser();
    });
  }
}
