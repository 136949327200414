// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-exhibition-modal .modal-content-wrapper {
  margin: 20px;
  clear: both;
  overflow: hidden;
}
.delete-exhibition-modal .modal-content-wrapper .question-text {
  text-align: justify;
}
.delete-exhibition-modal .modal-content-wrapper .actions {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/delete-exhibition/delete-exhibition-modal.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAGI;EACE,gBAAA;AADN","sourcesContent":[".delete-exhibition-modal {\n  .modal-content-wrapper {\n    margin: 20px;\n    clear: both;\n    overflow: hidden;\n\n    .question-text {\n      text-align: justify;\n    }\n\n    .actions {\n      margin-top: 20px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
