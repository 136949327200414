import {SelectOption} from '../../../../../select/option/option.model';

export const KEYS = {
  FADE_IN: 'FADE_IN',
  FADE_IN_OUT: 'FADE_IN_OUT',
  CROSSFADE: 'CROSSFADE',
  GROW: 'GROW',
  SHRINK: 'SHRINK',
};

export const CLASSES = {
  [KEYS.FADE_IN]: 'image-transition-fadein',
  [KEYS.FADE_IN_OUT]: 'image-transition-fade-in-out',
  [KEYS.CROSSFADE]: 'image-transition-crossfade',
  [KEYS.GROW]: 'image-transition-grow',
  [KEYS.SHRINK]: 'image-transition-shrink',
};

export const CLASSES_LIST = Object.keys(CLASSES).map(key => CLASSES[key]);

export const CLASSES_KEYS = Object.keys(CLASSES).reduce((res, key) => {
  res[CLASSES[key]] = key;
  return res;
}, {});

export const OPTIONS: SelectOption[] = [
  new SelectOption('Fade In', KEYS.FADE_IN),
  new SelectOption('Fade In & Out', KEYS.FADE_IN_OUT),
  new SelectOption('Crossfade', KEYS.CROSSFADE),
  new SelectOption('Grow', KEYS.GROW),
  new SelectOption('Shrink', KEYS.SHRINK),
];

export const DEFAULT_CLASS_NAME = CLASSES[KEYS.FADE_IN];
export const DEFAULT_OPTION = OPTIONS[0];
