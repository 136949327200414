import {ICartItem} from '../cart-item.model';
import {AddOnCartItemDto} from './add-on-cart-item.dto';
import {AddOnCartItemOptionsModel} from './add-on-cart-item-options.model';

export class AddOnCartItemModel implements ICartItem {
  constructor(
    public key: string,
    public type: string,
    public stripeProductId: string,
    public stripePriceId: string,
    public options?: AddOnCartItemOptionsModel,
  ) {
  }

  public static normalize(res: AddOnCartItemDto): AddOnCartItemModel {
    if (!res) return null;

    return new AddOnCartItemModel(
      res.key,
      res.type,
      res.stripeProductId,
      res.stripePriceId,
      AddOnCartItemOptionsModel.normalize(res.options),
    );
  }
}
