
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {throwError, BehaviorSubject, Observable, Subscription, map} from 'rxjs';

import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {MediaManagerService} from '../../media-manager/media-manager.service';

import {LogoModel} from '../../../models/logo/logo.model';
import {LogoDto} from '../../../models/logo/logo.dto';

import {SECTIONS_KEYS} from '../../media-manager/constants';

@Injectable()
export class LogoManagerService {
  public selectedCategory: { text: string, textPlural: string };

  public element: HTMLElement;

  public logosSubject: BehaviorSubject<LogoModel[]> = new BehaviorSubject<LogoModel[]>(null);
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private model = 'images/logos';

  private maxLogoSizeKb = 10 * 1024 * 1024;

  public logos(): LogoModel[] {
    return this.logosSubject.value;
  }

  constructor(
    private httpClient: HttpClient,
    private modalsService: ModalsService,
    private mediaManagerService: MediaManagerService,
  ) {
  }

  public open({ element }: { element: HTMLElement }): void {
    this.element = element;

    this.mediaManagerService.isSectionsDisabledSubject.next(true);

    this.mediaManagerService.selectSection(SECTIONS_KEYS.LOGOS);

    this.modalsService.open(this.mediaManagerService.modalId);
  }

  public close(): void {
    this.element = null;

    this.mediaManagerService.closeModal();
  }

  public loadLogos(): Subscription {
    this.isLoadingSubject.next(true);

    return this.httpClient.get(`api/${this.model}`).pipe(
      map((logos: LogoDto[]) => {
        return logos.map(logo => LogoDto.normalize(logo));
      }),
    ).subscribe((logos: LogoModel[]) => {
      this.setLogos(logos);
    });
  }

  public uploadLogo(file: File): Observable<LogoDto> {
    if (!this.isLogoSizeAcceptable(file)) return throwError(() => {
      return new Error('File is too large');
    });

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.httpClient.post<LogoDto>(`api/${this.model}`, formData);
  }

  private isLogoSizeAcceptable(file: File) {
    return file.size <= this.maxLogoSizeKb;
  }

  public deleteLogos(ids: number[]): Observable<any> {
    return this.httpClient.request('delete', `api/${this.model}`, { body: ids, responseType: 'json' });
  }

  private setLogos(logos: LogoModel[]) {
    this.logosSubject.next(logos);

    this.isLoadingSubject.next(false);
  }

  public updateName(id: number, name: string): Observable<any> {
    return this.httpClient.request('put', `api/${this.model}/${id}/name`, { body: { name }, responseType: 'text' });
  }
}
