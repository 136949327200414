import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

import {Subject} from 'rxjs';

import {AdminEducationInstitutionsService} from '../../../../../../core/services/admin/education/institutions/admin-education-institutions.service';

import {EducationInstitutionModel} from '../../../../../../core/models/education/institutions/education-institution.model';

@Component({
  selector: 'app-admin-education-delete-institution-modal',
  templateUrl: './delete-institution-modal.component.html',
  styleUrls: ['./delete-institution-modal.component.scss']
})
export class AdminEducationDeleteInstitutionModalComponent implements OnDestroy {
  @Input() institution: EducationInstitutionModel;

  @Output() closeHandler = new EventEmitter<void>();

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationInstitutionsService) {
  }

  public submit(): void {
    this.service.remove(this.institution);

    this.closeHandler.emit();
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
