// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1;
}

.content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-wrapper .infinity-dropdown {
  position: absolute;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 1px;
  z-index: 9999;
}
.content-wrapper .infinity-dropdown:hover {
  box-shadow: inset 0 0 1px 0 black;
  cursor: pointer;
}
.content-wrapper .content {
  padding: 4px 1px 4px 0;
  width: 100%;
  height: 27px;
  font: inherit;
  letter-spacing: inherit;
}
.content-wrapper .content:hover, .content-wrapper .content:focus {
  box-shadow: inset 0 0 1px 0 black;
}
.content-wrapper .content.hidden {
  opacity: 0;
  pointer-events: none;
}
.content-wrapper .content.limit-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.content-wrapper .content.custom-input {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/admin-panel/plans/editable-content/editable-content.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,OAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,kBAAA;EACA,WAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;AADJ;AAIE;EACE,iCAAA;EACA,eAAA;AAFJ;AAKE;EACE,sBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AAHJ;AAKI;EAEE,iCAAA;AAJN;AAOI;EACE,UAAA;EACA,oBAAA;AALN;AAQI;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AANN;AASI;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,oCAAA;EACA,SAAA;AAPN","sourcesContent":["@import '../../../../../../styles/colors';\n\n:host {\n  display: flex;\n  flex: 1;\n}\n\n.content-wrapper {\n  position: relative;\n  width: 100%;\n  height: 100%;\n\n  .infinity-dropdown {\n    position: absolute;\n    width: 100%;\n    background: rgb(255, 255, 255);\n    border-radius: 1px;\n    z-index: 9999;\n  }\n\n  .infinity-dropdown:hover {\n    box-shadow: inset 0 0 1px 0 black;\n    cursor: pointer;\n  }\n\n  .content {\n    padding: 4px 1px 4px 0;\n    width: 100%;\n    height: 27px;\n    font: inherit;\n    letter-spacing: inherit;\n\n    &:hover,\n    &:focus {\n      box-shadow: inset 0 0 1px 0 black;\n    }\n\n    &.hidden {\n      opacity: 0;\n      pointer-events: none;\n    }\n\n    &.limit-text {\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n      cursor: pointer;\n    }\n\n    &.custom-input {\n      position: absolute;\n      top: 0;\n      left: 0;\n      background: rgba(255, 255, 255, 0.7);\n      border: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
