import {PortfolioModel} from './portfolio.model';
import {TeacherPortfolioDataDto} from '../education/teacher-portfolio-data/teacher-portfolio-data.dto';

export class PortfolioDto {
  constructor(
    public PortfolioId?: number,
    public WebsiteId?: number,
    public NodeId?: number,
    public UserId?: number,
    public ClassId?: number,
    public Type?: string,
    public Title?: string,
    public TotalImagesCount?: number,
    public PublishedImagesCount?: number,
    public TeacherData?: TeacherPortfolioDataDto,
    public IsShared?: boolean,
    public IsDesktopPageVisible?: boolean,
    public IsMobilePageVisible?: boolean,
    ) {}

  public static normalize(res: PortfolioDto): PortfolioModel {
    if (!res) return null;

    return new PortfolioModel(
      res.PortfolioId,
      res.WebsiteId,
      res.NodeId,
      res.UserId,
      res.ClassId,
      res.Type,
      res.Title,
      res.TotalImagesCount,
      res.PublishedImagesCount,
      res.TeacherData ? TeacherPortfolioDataDto.normalize(res.TeacherData) : null,
      res.IsShared,
      res.IsDesktopPageVisible,
      res.IsMobilePageVisible,
    );
  }
}
