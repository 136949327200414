import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PaymentCouponsService} from '../../../../core/services/payment/coupons/payment-coupons.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';

import {StripeCouponModel} from '../../../../core/models/stripe/coupon/stripe-coupon.model';

@Component({
  selector: 'app-admin-stripe',
  templateUrl: './admin-stripe.component.html',
  styleUrls: ['./admin-stripe.component.scss']
})
export class AdminStripeComponent implements OnInit, OnDestroy {
  public onScroll: Function = null;

  private coupons: { list: StripeCouponModel[], hasMore: boolean } = null;

  private isLoading: boolean = false;

  private ngUnsubscribe = new Subject();

  constructor(private paymentCouponsService: PaymentCouponsService,
              private utilsService: UtilsService) {
    this.onScroll = this.utilsService.debounce(this.onScrollHandler.bind(this), 25);
  }

  public ngOnInit(): void {
    this.paymentCouponsService.couponsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coupons: { list: StripeCouponModel[], hasMore: boolean }) => {
      this.coupons = coupons;
    });
  }

  public onScrollHandler(e) {
    if (!this.coupons || !this.coupons.hasMore || this.isLoading) return;

    const list: StripeCouponModel[] = this.coupons.list;

    if (!list || list.length === 0) return;

    const currentPosition = e.target.scrollTop + e.target.clientHeight;

    if (currentPosition / e.target.scrollHeight < 0.5) return;

    this.isLoading = true;

    const last: StripeCouponModel = list[list.length - 1];

    this.paymentCouponsService.getCoupons(last.id).add(() => { this.isLoading = false; });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
