import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {WebsiteModel} from '../../../../../../../../models/websites/website.model';
import {WebsiteDto} from '../../../../../../../../models/websites/website.dto';
import {NodeModel} from '../../../../../../../../models/nodes/node.model';

@Injectable()
export class EducationWebsitesHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public getOne(institutionId: number, classId: number, websiteId: number): Observable<WebsiteModel> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}`).pipe(
      map((item: WebsiteDto) => WebsiteDto.normalize(item))
    );
  }

  public setIsSimpleImageLabel(institutionId: number, classId: number, websiteId: number, isSimpleImageLabel: boolean): Observable<any> {
    return this.http.put(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/simple-image-label`, { isSimpleImageLabel });
  }
}
