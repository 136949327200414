import {DEVICES} from '../../../../../services/editor-devices/constants';

export const KEYS = {
  MENU_PADDING_TOP: 'MENU_PADDING_TOP',
  MENU_PADDING_BOTTOM: 'MENU_PADDING_BOTTOM',
};

export const STYLE_PROPERTIES = {
  [DEVICES.DESKTOP]: {
    [KEYS.MENU_PADDING_TOP]: '--menu-padding-top-desktop',
    [KEYS.MENU_PADDING_BOTTOM]: '--menu-padding-bottom-desktop',
  },
  [DEVICES.TABLET]: {
    [KEYS.MENU_PADDING_TOP]: '--menu-padding-top-tablet',
    [KEYS.MENU_PADDING_BOTTOM]: '--menu-padding-bottom-tablet',
  },
  [DEVICES.MOBILE]: {
    [KEYS.MENU_PADDING_TOP]: '--menu-padding-top-mobile',
    [KEYS.MENU_PADDING_BOTTOM]: '--menu-padding-bottom-mobile',
  },
};

export const OPTIONS = [
  {
    text: 'Top',
    key: KEYS.MENU_PADDING_TOP,
  },
  {
    text: 'Bottom',
    key: KEYS.MENU_PADDING_BOTTOM,
  },
];
