import {KEYS} from '../../../../services/video-manager/embed/constants';

export const QUERY_PARAMS = {
  [KEYS.YOUTUBE_1]: 'autoplay',
  [KEYS.YOUTUBE_2]: 'autoplay',
  [KEYS.YOUTUBE_3]: 'autoplay',
  [KEYS.VIMEO_1]: 'autoplay',
  [KEYS.VIMEO_2]: 'autoplay',
};

export const VALUES = {
  [KEYS.YOUTUBE_1]: {
    DISABLED: '0',
    ENABLED: '1',
  },
  [KEYS.YOUTUBE_2]: {
    DISABLED: '0',
    ENABLED: '1',
  },
  [KEYS.YOUTUBE_3]: {
    DISABLED: '0',
    ENABLED: '1',
  },
  [KEYS.VIMEO_1]: {
    DISABLED: '0',
    ENABLED: '1',
  },
  [KEYS.VIMEO_2]: {
    DISABLED: '0',
    ENABLED: '1',
  },
};
