import {Injectable} from '@angular/core';

import {REGEXPS, IS_IPAD} from './constants';

@Injectable()
export class DeviceService {
  public isMobile: boolean = true;

  constructor() {
    try {
      const userAgent: string = navigator.userAgent || navigator.vendor || (<any>window).opera;

      this.isMobile = IS_IPAD || REGEXPS.MOBILE_1.test(userAgent) || REGEXPS.MOBILE_2.test(userAgent.substr(0, 4));
    } catch (e) {

    }
  }
}
