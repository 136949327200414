import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Subject, BehaviorSubject} from 'rxjs';

import {WebsiteDesignerService} from '../website-designer.service';
import {DetailsService} from '../../details/details.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';

import {ThemeModel} from './theme.model';

@Injectable()
export class ThemesService implements OnDestroy {
  public isThemePaneOpened: boolean = false;
  themes = new BehaviorSubject<ThemeModel[]>(null);

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private websiteDesignerService: WebsiteDesignerService,
    private detailsService: DetailsService,
    private iFrameService: IFrameService,
  ) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getThemes(): BehaviorSubject<ThemeModel[]> {
    this.httpClient.get<ThemeModel[]>('api/app/themes').subscribe(themes => {
      this.themes.next(themes);
    });

    return this.themes;
  }

  public selectTheme(theme: ThemeModel) {
    const elements = Array.from(this.iFrameService.sandbox.querySelectorAll('[style]:not(.block)'));

    const properElements = elements.filter((element: HTMLElement) => !element.closest('.block'));

    properElements.forEach(element => element.removeAttribute('style'));

    this.detailsService.websiteStyles.backgroundcolor = theme.BackgroundColor.Value;

    this.detailsService.changeWebsiteStyleProperty('backgroundcolor');

    this.detailsService.websiteStyles.pagefontcolor = theme.TextColor.Value;

    this.detailsService.changeWebsiteStyleProperty('pagefontcolor');

    this.detailsService.forceApplyStyles = true;
  }

  public toggleThemePane() {
    this.isThemePaneOpened = !this.isThemePaneOpened;

    this.websiteDesignerService.editingSubject.next(null);
  }
}
