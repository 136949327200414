export const KEYS = {
  SIDE: 'side',
  TOP: 'top',
};

export const CLASSES = {
  [KEYS.SIDE]: 'mobile-menu-position-side',
  [KEYS.TOP]: 'mobile-menu-position-top',
};

export const DEFAULT_CLASS_NAME = CLASSES[KEYS.SIDE];

export const CLASSES_LIST = Object.keys(CLASSES).map(key => CLASSES[key]);

export const CLASSES_KEYS = Object.keys(CLASSES).reduce((res, key) => {
  res[CLASSES[key]] = key;
  return res;
}, {});

export const OPTIONS = [
  {
    key: KEYS.SIDE,
    text: 'side',
  },
  {
    key: KEYS.TOP,
    text: 'top',
  },
];
