// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-request-modal-body .text {
  padding: 20px;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/pages-rearrangement-warning-modal/pages-rearrangement-warning-modal.component.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,gBAAA;AAHJ","sourcesContent":["\n@import \"../../../../../styles/colors\";\n\n.save-request-modal-body {\n  .text {\n    padding: 20px;\n    font-size: 0.9em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
