export const PROPERTY = 'font-size';

export const FONT_SIZES: Array<number> = [
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
];

export const FONT_SIZES_20: Array<number> = [
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
];

export const SUBTITLE_FONT_SIZES: Array<number> = [
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
];

export const FONT_SIZES_LIMITED: Array<number> = [
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
];
