import {ImageDataModel} from './image-data.model';

import {LIBRARY_ID} from '../../../../application/constants';

export class ImagesCounterModel {
  public published: { [key: number]: number } = {}; // portfolioId: published-images-count
  public total: { [key: number]: number } = {}; // portfolioId: total-images-count

  constructor() {}

  public getCount(id: number, isImageManager: boolean): number {
    const count = isImageManager ? this.total[id] : this.published[id];

    return count || 0;
  }

  public onImageAdd(id: number, count: number = 1) {
    if (this.total[id] === void 0) return;

    this.published[id] += count;
    this.total[id] += count;
  }

  public onImageRemove(id: number, imagesData: ImageDataModel[]) {
    if (this.total[id] === void 0) return;

    imagesData.forEach((item: ImageDataModel) => {
      if (item.isPublished) this.published[id]--;

      this.total[id]--;
    });
  }

  public onAllImagesMoveToLibrary(id: number) {
    if (this.total[id] === void 0) return;

    this.published[LIBRARY_ID] += this.published[id];
    this.total[LIBRARY_ID] += this.total[id];

    this.published[id] = 0;
    this.total[id] = 0;
  }

  public onAllImagesRemove(id: number) {
    if (this.total[id] === void 0) return;

    this.published[id] = 0;
    this.total[id] = 0;
  }

  public onPublish(id: number, count: number = 1) {
    if (this.published[id] === void 0) return;

    this.published[id] += count;
  }

  public onUnpublish(id: number, count: number = 1) {
    if (this.published[id] === void 0) return;

    this.published[id] -= count;
  }
}
