export const TYPES = {
  BLOCK: 'block',
  ELEMENT: 'element',
  DIVIDER: 'divider',
};

export const STYLE_KEYS = {
  COLOR: 'color',
  FONT_FAMILY: 'font-family',
  LINE_HEIGHT: 'line-height',
  BORDERS: {
    STYLE: 'border-style',
    WIDTH: 'border-width',
    POSITION: 'border-position',
    COLOR: 'border-color',
  },
  PADDING: 'padding',
  VERTICAL_PADDING: 'vertical-padding',
};

export const DEFAULT_BORDER_WIDTH = '1px';
