import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/title/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/title/mobile/constants';

export const PROPERTY_NAMES = {
  [DESKTOP_KEYS.SCALABLE_WORD_SPACING]: {
    OPTION: '--scalable-subtitle-word-spacing',
    OPTION_PX: '--scalable-subtitle-word-spacing-px',
  },
  [MOBILE_KEYS.SCALABLE_MOBILE_WORD_SPACING]: {
    OPTION: '--scalable-mobile-subtitle-word-spacing',
    OPTION_PX: '--scalable-mobile-subtitle-word-spacing-px',
  },
};
