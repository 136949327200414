import {Injectable} from '@angular/core';

import {SelectOption} from '../../../../models/select/option/option.model';

@Injectable()
export class IFrameBaseStylesService {
  public items: Element[] = [];

  public classes: string[];
  public getDefaultOptions: () => SelectOption[];
  public options: SelectOption[];

  constructor() {}

  public initItems(items: Element[]) {
    this.items = items;
  }

  public resetStyles(): void {
    this.onOptionsSelect(this.getDefaultOptions());
  }

  public onOptionsSelect(options: SelectOption[]): void {
    this.options = options;

    this.items.forEach((elem: HTMLElement) => this.initMenuItem(options, elem));
  }

  public getOptionsByClassList(classList: string[]): SelectOption[] {
    if (!classList || !classList.length) return this.getDefaultOptions();

    this.options.forEach(option => {
      option.isSelected = classList.includes(option.value);
    });

    return this.options;
  }

  public initMenuItem(options: SelectOption[], elem: HTMLElement) {
    if (!elem) return;

    options.forEach(option => {
      if (!option.isSelected) return elem.classList.remove(option.value);

      elem.classList.add(option.value);
    });
  }
}
