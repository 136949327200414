import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {WebsitesService} from '../websites/websites.service';

import {SocialNetwork} from '../../models/social-networks/social-network/social-network.model';
import {SocialNetworkDto} from '../../models/social-networks/social-network/social-network.dto';

import {environment} from '../../../../environments/environment';

@Injectable()
export class SocialNetworksService {
  private model: string = 'social-networks';

  public list: BehaviorSubject<SocialNetwork[]> = new BehaviorSubject([]);

  private websiteId: number;

  constructor(private http: HttpClient,
              private websitesService: WebsitesService) {
    this.websitesService.activeTemplateIdSubject.subscribe((id: number) => {
      this.websiteId = id;

      if (!id) return;

      this.init();
    });
  }

  private init(): void {
    this.fetchList().subscribe((socialNetworks: SocialNetwork[]) => {
      this.list.next(socialNetworks);
    });
  }

  private fetchList(): Observable<SocialNetwork[]> {
    return this.http.get(`${environment.apiBase}/${this.model}`).pipe(
      map((res: SocialNetworkDto[]) => res ? SocialNetwork.listFromResponse(res) : null)
    );
  }
}
