import {rgb, score} from 'wcag-contrast';

import {IColorSchemeModel} from './icolor.scheme.model';
import {RgbColorSchemeModel} from './rgb.color.scheme.model';
import {HexColorSchemeModel} from './hex.color.scheme.model';

export class ColorModel {
  public static isIntersection(color1: ColorModel, color2: ColorModel) {
    if (!color1 || !color2) return false;

    const r1 = color1.rgbModel.r;
    const g1 = color1.rgbModel.g;
    const b1 = color1.rgbModel.b;
    const a1 = color1.rgbModel.a;

    const r2 = color2.rgbModel.r;
    const g2 = color2.rgbModel.g;
    const b2 = color2.rgbModel.b;
    const a2 = color2.rgbModel.a;

    const result = rgb([r1, g1, b1, a1], [r2, g2, b2, a2]);

    return result < 1.5;
  }

  public static colorSchemas = {
    RGB: 'rgb',
    RGBA: 'rgba',
    HEX: 'hex',
    UNKNOWN: 'unknown',
  };

  public colorScheme: string = ColorModel.colorSchemas.UNKNOWN;
  public model: IColorSchemeModel;
  public rgbModel: RgbColorSchemeModel;
  public hexModel: HexColorSchemeModel;

  public colorSchemeResolvers = {
    [ColorModel.colorSchemas.RGB]: () => this.color.search(/^rgb\(/gi) !== -1,
    [ColorModel.colorSchemas.RGBA]: () => this.color.search(/^rgba\(/gi) !== -1,
    [ColorModel.colorSchemas.HEX]: () => this.color.search(/^#(\w{3}|\w{6}|\w{8})$/gi) !== -1,
  };

  public get isRgb() {
    return this.colorScheme === ColorModel.colorSchemas.RGB || this.colorScheme === ColorModel.colorSchemas.RGBA;
  }

  public get isHex() {
    return this.colorScheme === ColorModel.colorSchemas.HEX;
  }

  public constructor(public color: string) {
    this.color = this.color.trim();
    this.recognizeColorScheme();
    this.initModel();
  }

  private recognizeColorScheme() : void {
    const keys = Object.keys(this.colorSchemeResolvers);

    for (let key of keys) {
      if (!this.colorSchemeResolvers[key]()) continue;

      this.colorScheme = key;
      return;
    }
  }

  private initModel() {
    if (this.isRgb) {
      this.rgbModel = new RgbColorSchemeModel(this.color);
      this.hexModel = HexColorSchemeModel.fromRgb(this.rgbModel);
      return;
    }
    if (this.isHex) {
      this.hexModel = new HexColorSchemeModel(this.color);
      this.rgbModel = RgbColorSchemeModel.fromHex(this.hexModel);
      return;
    }
  }

  public getRGBstring() {
    return `${this.rgbModel.r}, ${this.rgbModel.g}, ${this.rgbModel.b}`;
  }
}
