import {BlocksChangesModel} from './blocks-changes.model';
import {BlocksTemplatesDto} from '../blocks-templates/blocks-templates.dto';
import {DeployDto} from '../deploy/deploy.dto';
import {SeverityDto} from '../severity/severity.dto';
import {BlocksTemplatesModel} from '../blocks-templates/blocks-templates.model';

export class BlocksChangesDto {
  constructor(public Id?: number,
              public BlockId?: number,
              public DeployId?: number,
              public SeverityId?: number,
              public IsHandled?: boolean,
              public CreatedAt?: string,

              public BlockTemplate?: BlocksTemplatesDto,
              public Deploy?: DeployDto,
              public Severity?: SeverityDto) {}

  public static normalize(res: BlocksChangesDto): BlocksChangesModel {
    if (!res) return null;

    return new BlocksChangesModel(
      res.Id,
      res.BlockId,
      res.DeployId,
      res.SeverityId,
      res.CreatedAt,
      res.IsHandled,
      false,

      BlocksTemplatesModel.normalize(res.BlockTemplate),
      DeployDto.normalize(res.Deploy),
      SeverityDto.normalize(res.Severity),
    );
  }
}
