// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-customer-filter {
  padding: 10px;
}
.admin-customer-filter .control {
  margin: 5px;
  min-width: 100px;
}
.admin-customer-filter .control input {
  max-height: 30px;
}
.admin-customer-filter .control label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/admin-panel/import/filter/import-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,WAAA;EACA,gBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,iBAAA;EACA,qBAAA;EACA,kBAAA;AAAN","sourcesContent":[".admin-customer-filter {\n  padding: 10px;\n\n  .control {\n    margin: 5px;\n    min-width: 100px;\n\n    input {\n      max-height: 30px;\n    }\n\n    label {\n      font-weight: bold;\n      display: inline-block;\n      margin-bottom: 3px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
