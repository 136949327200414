import {SelectOption} from '../../../../select/option/option.model';

export const LABELS = {
  INNER_SHADOW: 'Inner Shadow',
  COLOR: 'Color',
};

export const CLASSES = {
  INNER_SHADOW: 'dropdown-design-inner-shadow',
  COLOR: 'dropdown-design-color',
};

export const DEFAULT_OPTIONS: () => SelectOption[] = () => [
  new SelectOption(LABELS.INNER_SHADOW, CLASSES.INNER_SHADOW, true),
  new SelectOption(LABELS.COLOR, CLASSES.COLOR),
];
