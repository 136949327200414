import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {AppAnimations} from '../../app-animations';

import {IFrameClickCatcherService} from '../../services/iframe-click-catcher.service';
import {ColorService} from '../../core/services/styles/color/color.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ColorPickerComponent {
  private lastColor: string;

  @Input() format = 'rgba';

  currentColor = '#ffffff';
  hex = '#ffffff';

  private timeoutId: number;

  @Input()
  set color(value) {
    this.currentColor = this.colorService.prepareColor(this.lastColor, value);

    const hex = this.colorService.getAsHex(this.currentColor);
    this.hex = hex ? hex.toString() : '';
  }
  get color() {
    return this.currentColor;
  }

  @Output() colorChange = new EventEmitter();

  okButtonClass = 'color-picker_ok';
  okButtonSelector = `.${this.okButtonClass}`;

  constructor(private iframeClickCatcher: IFrameClickCatcherService,
              private colorService: ColorService,
              private cdr: ChangeDetectorRef) {
    iframeClickCatcher.iframeClick.subscribe(this.handleIFrameClick);
  }

  handleIFrameClick = () => {
    $(this.okButtonSelector).click();
  };

  handleColorChange(color: string) {
    if (this.timeoutId) window.clearTimeout(this.timeoutId);

    this.timeoutId = window.setTimeout(() => this.setColor(color));
  }

  private setColor(color: string) {
    const rgbModel = this.colorService.getAsRgb(color);
    const rgb = rgbModel ? rgbModel.toString() : color;

    this.color = rgb;
    this.lastColor = rgb;
    this.colorChange.next(rgb);

    this.cdr.detectChanges();
  }
}
