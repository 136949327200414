import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AdminEducationTeachersService} from '../../../../../../core/services/admin/education/teachers/admin-education-teachers.service';
import {AdminEducationInstitutionsService} from '../../../../../../core/services/admin/education/institutions/admin-education-institutions.service';
import {AdminEducationClassesService} from '../../../../../../core/services/admin/education/classes/admin-education-classes.service';

import {EducationNewTeacherModel} from '../../../../../../core/models/education/teachers/education-new-teacher.model';
import {EducationInstitutionModel} from '../../../../../../core/models/education/institutions/education-institution.model';
import {NestedSelectOption} from '../../../../../../core/models/select/nested/nested-select-option.model';
import {EducationTeacherModel} from '../../../../../../core/models/education/teachers/education-teacher.model';
import {AccountModel} from '../../../../../../core/models/accounts/account.model';
import {EducationClassModel} from '../../../../../../core/models/education/classes/education-class.model';

@Component({
  selector: 'app-admin-education-add-teacher-modal',
  templateUrl: './add-teacher-modal.component.html',
  styleUrls: ['./add-teacher-modal.component.scss']
})
export class AdminEducationAddTeacherModalComponent implements OnInit, OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public teacher: EducationNewTeacherModel = new EducationNewTeacherModel();

  public institutions: EducationInstitutionModel[];
  public institutionsOptions: NestedSelectOption[];

  public classes: EducationClassModel[];
  public classesOptions: NestedSelectOption[];

  public error: string = '';

  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationTeachersService,
              private insitutionsService: AdminEducationInstitutionsService,
              private classesService: AdminEducationClassesService) {
    this.insitutionsService.fetchAll();
    this.classesService.fetchAll();
  }

  public ngOnInit(): void {
    this.insitutionsService.institutionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list: EducationInstitutionModel[]) => {
      this.institutions = list;

      if (!list) {
        this.institutionsOptions = null;

        return;
      }

      this.institutionsOptions = list.map((item: EducationInstitutionModel) => {
        return new NestedSelectOption(
          item.name,
          `${item.id}`,
        );
      });
    });

    this.classesService.classesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list: EducationClassModel[]) => {
      this.classes = list;
    });
  }

  public onInstitutionSelect(options: NestedSelectOption[]): void {
    const institutions: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    this.teacher.institutionsIds = institutions ? institutions.map((institute: NestedSelectOption) => Number.parseInt(institute.value)) : void 0;

    this.initClassesOptions();
  }

  private initClassesOptions(): void {
    if (!this.classes) {
      this.classesOptions = null;

      return;
    }

    const filteredClasses: EducationClassModel[] = this.classes.filter((item: EducationClassModel) => {
      return this.teacher.institutionsIds.includes(item.institutionId);
    });

    this.classesOptions = filteredClasses.map((item: EducationClassModel) => {
      return new NestedSelectOption(
        `${item.name} "${item.number}"`,
        `${item.id}`,
      );
    });

    this.teacher.classesIds = this.teacher.classesIds ? this.teacher.classesIds.filter((classId: number) => filteredClasses.findIndex((item: EducationClassModel) => item.id === classId) !== -1) : [];
  }

  public onClassesSelect(options: NestedSelectOption[]): void {
    const classes: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    this.teacher.classesIds = classes ? classes.map((institute: NestedSelectOption) => Number.parseInt(institute.value)) : void 0;
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.key !== 'Enter') return;

    this.submit();
  }

  public submit(): void {
    this.teacher.validate();

    if (!this.teacher.isValid) return;

    this.isLoading = true;
    
    this.service.create(new EducationTeacherModel(
      void 0,
      void 0,
      new AccountModel(
        void 0, 
        void 0, 
        void 0, 
        this.teacher.email,
        this.teacher.firstName,
        this.teacher.lastName,
      ),
      this.teacher.institutionsIds ? this.teacher.institutionsIds.map((id: number) => new EducationInstitutionModel(id)) : [],
      this.teacher.classesIds ? this.teacher.classesIds.map((id: number) => new EducationClassModel(id)) : [],
      void 0,
    )).subscribe((err: string) => {
      if (!err) {
        this.closeHandler.emit();

        return;
      }

      this.error = err;

      this.isLoading = false;
    });
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
