import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {ColorModel} from '../../../color/color.model';

import {BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB} from './constants';

export class MenuDropdownItemHoverBackgroundModel implements IStyleOption {
  public menu: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.menu = options.block;

    if (!this.menu) return;

    const computed: CSSStyleDeclaration = window.getComputedStyle(this.menu);

    this.value = computed.getPropertyValue(CUSTOM_BACKGROUND_COLOR) || computed.getPropertyValue(BACKGROUND_COLOR);

    this.setValue(this.value);
  }

  public reset() {
    if (!this.isEnabled || !this.element || !this.menu) return;

    this.menu.style.removeProperty(CUSTOM_BACKGROUND_COLOR);
    this.menu.style.removeProperty(CUSTOM_BACKGROUND_COLOR_RGB);

    this.value = window.getComputedStyle(this.menu).getPropertyValue(CUSTOM_BACKGROUND_COLOR);
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.menu) return;

    this.value = value;

    this.menu.style.setProperty(CUSTOM_BACKGROUND_COLOR, value);

    const colorModel = new ColorModel(value);

    this.menu.style.setProperty(CUSTOM_BACKGROUND_COLOR_RGB, colorModel.getRGBstring());
  }
}
