import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EducationStudentDto} from '../../../../../../../../models/education/students/education-student.dto';
import {EducationStudentModel} from '../../../../../../../../models/education/students/education-student.model';
import {PortfolioModel} from '../../../../../../../../models/portfolios/portfolio.model';
import {PortfolioDto} from '../../../../../../../../models/portfolios/portfolio.dto';

@Injectable()
export class EducationTeachersStudentsHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public getOne(institutionId: number, classId: number, studentId: number): Observable<EducationStudentModel> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}`).pipe(
      map((item: EducationStudentDto) => EducationStudentDto.normalize(item))
    );
  }

  public getPortfolioConters(institutionId: number, classId: number, studentId: number, portfolioId: number): Observable<PortfolioModel> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/counters`).pipe(
      map((item: PortfolioDto) => PortfolioDto.normalize(item))
    );
  }

  public remove(institutionId: number, classId: number, studentId: number): Observable<any> {
    return this.http.delete(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}`);
  }
}
