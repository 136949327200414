import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {EducationClassModel} from '../../../../../core/models/education/classes/education-class.model';

import {AdminEducationClassesService} from '../../../../../core/services/admin/education/classes/admin-education-classes.service';

@Component({
  selector: 'app-admin-education-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class AdminEducationClassesComponent implements OnInit, OnDestroy {
  public list: EducationClassModel[];

  public itemToDelete: EducationClassModel = null;

  public isDetailsView: boolean = false;
  public isEditView: boolean = false;
  public isItemRemoving: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationClassesService) {
    this.service.fetchAll();
  }

  public ngOnInit(): void {
    this.service.classesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list: EducationClassModel[]) => {
      this.list = list;
    });
  }

  public openClass(item: EducationClassModel): void {
    this.service.fetchOne(item.id);

    this.isDetailsView = true;
  }

  public openEditModal(item: EducationClassModel): void {
    this.service.fetchOne(item.id);

    this.isEditView = true;
  }

  public openDeleteModal(item: EducationClassModel): void {
    this.isItemRemoving = true;

    this.itemToDelete = item;
  }

  public onDetailsViewClose(): void {
    this.isDetailsView = false;
  }

  public onEditViewClose(): void {
    this.isEditView = false;
  }

  public onItemDeleteClose(): void {
    this.isItemRemoving = false;

    this.itemToDelete = null;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}