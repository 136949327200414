import {Component} from '@angular/core';

import {TourCloseModalService} from '../../../services/modals/tour-close/tour-close-modal.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-tour-close-modal',
  templateUrl: './tour-close-modal.component.html',
  styleUrls: ['./tour-close-modal.component.scss']
})
export class TourCloseModalComponent {
  public buttons: Button[] = [
    new Button('SKIP', null, 'green', this.skipCurrentTour.bind(this)),
    new Button('CONTINUE TOUR', null, 'neutral', this.close.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: TourCloseModalService) {
  }

  private skipCurrentTour(): void {
    this.service.skipCurrentTour.next(true);
  }

  private skipAllTours(): void {
    this.service.skipAllTours.next(true);
  }

  public close(): void {
    this.service.close();
  }
}

