import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DeleteExhibitionModalService} from '../../../../../../shared/services/modals/delete-exhibition/delete-exhibition-modal.service';
import {ImagesCounterService} from '../../../../../../core/services/image-manager/counters/images-counter.service';
import {EducationExhibitionsImagesCounterService} from '../../../../../../core/services/education/image-manager/exhibitions-counters/education-exhibitions-images-counter.service';

import {PortfolioModel} from '../../../../../../core/models/portfolios/portfolio.model';
import {EducationExhibitionPortfolioModel} from '../../../../../../core/models/education/portfolios/education-exhibition-portfolio.model';
import {ImagesCounterModel} from '../../../../../../core/models/image-manager/counters/images-counter.model';

import {LIBRARY_ID} from '../../../../../constants';

@Component({
  selector: 'app-educator-exhibition-sidebar-item',
  templateUrl: './educator-exhibition-sidebar-item.component.html',
  styleUrls: ['./educator-exhibition-sidebar-item.component.scss'],
})
export class EducatorExhibitionSidebarItemComponent implements OnInit, OnDestroy {
  @ViewChild('titleInput')
  public titleInput: ElementRef;

  @Input()
  public exhibition: PortfolioModel;

  @Input()
  public selectedExhibition: EducationExhibitionPortfolioModel;

  @Output()
  public selectHandler: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();
  
  @Output()
  public updateHandler: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();
  
  @Output()
  public deleteHandler: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();

  private counters: ImagesCounterModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private deleteExhibitionModalService: DeleteExhibitionModalService,
    private exhibitionsImagesCounterService: EducationExhibitionsImagesCounterService,
    private imagesCounterService: ImagesCounterService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.exhibitionsImagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      this.counters = counters;
    });
  }

  public onTitleKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.submitEdit(e);
      
      return;
    }

    if (e.key === 'Escape') {
      this.exhibition.isEditing = false;

      this.exhibition.title = this.exhibition.initialTitle;
      
      return;
    }
  }

  public select(): void {
    if (this.exhibition.isEditing) {
      return;
    }

    this.selectHandler.emit(this.exhibition);
  }

  public update(): void {
    this.updateHandler.emit(this.exhibition);
  }

  public edit(e: PointerEvent): void {
    e.stopPropagation();
    e.preventDefault();

    this.exhibition.isEditing = true;

    this.exhibition.initialTitle = this.exhibition.title;

    window.setTimeout(() => {
      this.titleInput.nativeElement.focus();

      window.getSelection().selectAllChildren(this.titleInput.nativeElement);
      window.getSelection().collapseToEnd();
    }, 50);
  }

  public submitEdit(e: PointerEvent | KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();

    this.exhibition.isEditing = false;
    
    this.cdr.detectChanges();

    this.updateHandler.emit(this.exhibition);
  }

  public delete(e: PointerEvent): void {
    e.stopPropagation();
    e.preventDefault();

    const imagesCount: number = this.counters.total[this.exhibition.id] || 0;

    this.deleteExhibitionModalService.open(this.exhibition);

    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    this.deleteExhibitionModalService.onConfirm.pipe(takeUntil(unsubscribe)).subscribe(() => {
      unsubscribe.next(true);
      unsubscribe.complete();
    
      this.deleteHandler.emit(this.exhibition);

      this.imagesCounterService.onImageAdd(LIBRARY_ID, imagesCount);
    });

    this.deleteExhibitionModalService.onCancel.pipe(takeUntil(unsubscribe)).subscribe(() => {
      unsubscribe.next(true);
      unsubscribe.complete();
    });
  }
  
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
