import {Component, EventEmitter, Input, Output} from '@angular/core';

import {BlocksService} from '../../../application/sidebar-short/sidebar/blocks/blocks.service';
import {IFrameService} from '../../../core/services/iframe/iframe.service';
import {EventsService} from '../../../core/services/interaction/events/events.service';
import {MessageModalService} from '../../../services/message-modal.service';

@Component({
  selector: 'app-drop-area',
  templateUrl: './drop-area.component.html',
  styleUrls: ['./drop-area.component.scss'],
})
export class DropAreaComponent {
  @Input() type: string = 'file';
  @Input() allowedMimeTypes: string[] = [];
  @Input() page: any;
  @Input() isDropAllowed: boolean = true;

  @Output() dragEnterHandler = new EventEmitter();
  @Output() dragLeaveHandler = new EventEmitter();
  @Output() dropHandler = new EventEmitter();
  @Output() dropPreventHandler = new EventEmitter();

  private wrongFileTypeError: string = 'Wrong file type. Only JPGs and PNGs are allowed.';

  private dropHandlers = {
    PDF: this.onFileDrop.bind(this),
    Image: this.onFileDrop.bind(this),
    Logo: this.onFileDrop.bind(this),
    block: this.onBlockDrop.bind(this),
    VIDEOS: this.onVideoDrop.bind(this),
    AUDIO: this.onAudioDrop.bind(this),
  };

  constructor(private blocksService: BlocksService,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private messageModalService: MessageModalService) {
  }

  public stopEvent(e): void {
    e.preventDefault();
    e.stopPropagation();
  }

  public onDropHandler(event): void {
    if (!this.isDropAllowed) {
      this.dropPreventHandler.emit();

      return;
    }

    this.dropHandlers[this.type](event);
  }

  private onFileDrop(event) {
    const files = Array.from(event.dataTransfer.files).filter((file: File) => this.allowedMimeTypes.includes(file.type));

    if (!files.length) return this.showWrongFileTypeError();

    this.dropHandler.emit(files);
  }

  private onVideoDrop(event) {
    const files = Array.from(event.dataTransfer.files).filter((file: File) => this.allowedMimeTypes.includes(file.type));

    if (!files.length) return this.showWrongFileTypeError();

    this.dropHandler.emit(files);
  }

  private onAudioDrop(event) {
    const files = Array.from(event.dataTransfer.files).filter((file: File) => file.type.startsWith('audio/'));

    if (!files.length) return this.showWrongFileTypeError();

    this.dropHandler.emit(files);
  }

  private onBlockDrop(event) {
    const blockData = event.dataTransfer.getData('application/json');
    const parsedBlockData = JSON.parse(blockData);
    const formattedBlocks = this.blocksService.convertBlockDataToSave([parsedBlockData], this.page);

    this.blocksService.blocksToMoveToAnotherPage = [...this.blocksService.blocksToMoveToAnotherPage, ...formattedBlocks];

    this.dropHandler.emit();

    if (this.type !== 'block') {
      return;
    }

    this.eventsService.dispatchBlockRemoveByIndex(parsedBlockData.initialIndex, this.iFrameService.sandboxWindow);
  }

  private showWrongFileTypeError() {
    this.messageModalService.showError(this.wrongFileTypeError);
    
    this.dragLeaveHandler.emit();
  }
}
