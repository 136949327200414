import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';

import {DashboardHttpService} from '../../../../core/services/interaction/http/dashboard/dashboard-http.service';

import {DiskSpaceModel} from '../../../../core/models/disk-space/disk-space.model';

import {AppAnimations} from '../../../../app-animations';

import {COLORS} from '../../../../shared/components/charts/doughnut/constants';

@Component({
  selector: 'app-disk-space',
  templateUrl: './disk-space.component.html',
  styleUrls: ['./disk-space.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class DiskSpaceComponent implements OnInit, OnDestroy {
  public diskSpaceData: DiskSpaceModel = new DiskSpaceModel();

  public totalDiskSpace: number = null;
  public availableDiskSpace: number = null;

  public chartData: number[] = [10, 100];

  public chartColors: Array<any> = [
    COLORS.PRIMARY_YELLOW,
    COLORS.SHADOW,
  ];

  public isLoading: boolean = true;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private httpService: DashboardHttpService) {
  }

  public ngOnInit(): void {
    this.httpService.getDiskSpaceData().subscribe((diskSpaceData: DiskSpaceModel) => {
      this.diskSpaceData = diskSpaceData;

      this.isLoading = !diskSpaceData;

      this.initTotalDiskSpace();
      this.initAvailableDiskSpace();
      this.initChartData();
    });
  }

  private initTotalDiskSpace(): void {
    if (!this.diskSpaceData || !this.diskSpaceData.total) {
      this.totalDiskSpace = null;

      return;
    }

    this.totalDiskSpace = Math.round(this.diskSpaceData.total / 1024 * 100) / 100;
  }

  private initAvailableDiskSpace(): void {
    if (!this.diskSpaceData || !this.diskSpaceData.total) {
      this.availableDiskSpace = null;

      return;
    }

    this.availableDiskSpace = this.diskSpaceData.used < this.diskSpaceData.total ? Math.round((this.diskSpaceData.total - this.diskSpaceData.used) / 1024 * 100) / 100 : 0;
  }

  private initChartData(): void {
    if (!this.totalDiskSpace) {
      this.chartData = [
        1,
        0,
      ];

      this.chartColors = [
        COLORS.PRIMARY_GREEN,
        COLORS.SHADOW,
      ];

      return;
    }

    if (this.diskSpaceData.used < this.diskSpaceData.total) {
      this.chartData = [
        this.diskSpaceData.used,
        this.diskSpaceData.total - this.diskSpaceData.used,
      ];

      this.chartColors = [
        COLORS.PRIMARY_YELLOW,
        COLORS.SHADOW,
      ];

      return;
    }

    this.chartData = [
      this.diskSpaceData.total,
      0,
    ];

    this.chartColors = [
      COLORS.PRIMARY_RED,
      COLORS.SHADOW,
    ];
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
