import {Injectable} from '@angular/core';

import {GoogleFontsService} from '../../../google/fonts/google-fonts.service';

import {SelectOption} from '../../../../models/select/option/option.model';
import {NativeSetupModel} from '../../../../models/styles/native-setup/native-setup.model';
import {GoogleFontModel} from '../../../../models/google/fonts/google-font.model';

@Injectable()
export class NativeFontsStylesService {
  public setup: NativeSetupModel;

  public fontWeightsOptions: SelectOption[] = [];

  public get element(): HTMLElement {
    return this.setup.element;
  }

  constructor(private googleFontsService: GoogleFontsService) {
  }

  public init(setup: NativeSetupModel) {
    this.setup = setup;

    const font = this.googleFontsService.getFontForEditor(this.setup.fontFamily.value);

    if (!font) return;

    this.setup.fontFamily.setValue(font);

    this.initCurrentFontWeight(font);
    this.initFontWeightsOptions();
  }

  public initFontWeight() {
    const font = this.googleFontsService.getFontForEditor(this.setup.fontFamily.value);

    if (!font) return;

    this.initCurrentFontWeight(font);
  }

  public onFontFamilyChange(font: GoogleFontModel) {
    this.setup.fontFamily.onChange(font);

    this.initCurrentFontWeight(font);
    this.initFontWeightsOptions();
  }

  private initCurrentFontWeight(font: GoogleFontModel) {
    if (!this.element || !font || !font.variants) return;

    const value = `${GoogleFontModel.getClosestVariant(font, this.setup.fontWeight.value)}`;

    this.setup.fontWeight.setValue(value);
  }

  private initFontWeightsOptions() {
    const font: GoogleFontModel = this.googleFontsService.getFontForEditor(this.setup.fontFamily.value);

    if (!font) return;

    this.fontWeightsOptions = this.googleFontsService.getVariantsForEditor(font);
  }
}
