import {IStyleModelOptions} from '../option/i-style-model-options';

import {SetupOptionModel} from '../option/setup-option.model';

import {PROPERTY, DEFAULT_VALUE} from './constants';

export class SlideshowImageBorderWidthModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    if (!this.element) return;

    const value = Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(PROPERTY), 10) || DEFAULT_VALUE;

    this.initValue(value);
  }

  public onChange(value: number = DEFAULT_VALUE): void {
    this.setValue(value);
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.setValue(DEFAULT_VALUE);
  }

  public setValue(value: number): void {
    this.initValue(value);

    this.element.style.setProperty(PROPERTY, `${value}px`);
  }

  private initValue(value: number): void {
    this.value = value;
  }
}
