import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES, VALUES, DEFAULT_KEY} from './constants';

export class SelectLayerModel extends SetupOptionModel {
  public element: HTMLElement;

  public get values(): { key: string, label: string }[] {
    return VALUES;
  }

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    this.element = <HTMLElement>element.closest('.block');

    if (!this.isEnabled || !this.element) return;

    this.onChange(this.element.getAttribute(ATTRIBUTES.CURRENT_LAYER));
  }

  public onChange(key: string): void {
    this.value = key || DEFAULT_KEY;

    this.element.setAttribute(ATTRIBUTES.CURRENT_LAYER, this.value);
  }

  public reset(): void {
    this.value = DEFAULT_KEY;
  }
}
