import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class CustomizableLayersModel extends SetupOptionModel {
  public element: HTMLElement;

  public values: string[] = [];
  public defaultValue: string;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    this.element = element;

    this.isEnabled = this.element && this.element.hasAttribute(ATTRIBUTES.CUSTOMIZABLE_LAYERS);

    if (!this.isEnabled) return;

    this.values = this.element.getAttribute(ATTRIBUTES.CUSTOMIZABLE_LAYERS).split(',');
    this.defaultValue = this.element.getAttribute(ATTRIBUTES.DEFAULT_CUSTOMIZABLE_LAYER);

    this.onChange(this.defaultValue);
  }

  public onChange(value: string) {
    this.value = value;
  }
}
