import {AppSettingsModel} from './app-settings.model';
import {AppVersionChangesDto} from '../app-version-changes/app-version-changes.dto';

export class AppSettingsDto {
  constructor(
    public TrialPeriodDays?: number,
    public TrialPeriodEducationalDays?: number,
    public Version?: string,
    public VersionChanges?: AppVersionChangesDto,
    public IsMaintenanceOverlayVisible?: boolean,
  ) {}

  public static normalize(res: AppSettingsDto): AppSettingsModel {
    if (!res) return null;

    return new AppSettingsModel(
      `${res.TrialPeriodDays}`,
      `${res.TrialPeriodEducationalDays}`,
      res.Version,
      AppVersionChangesDto.normalize(res.VersionChanges),
      res.IsMaintenanceOverlayVisible,
    );
  }
}
