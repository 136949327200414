export const AMOUNT_FORMATTERS = {
  usd: (amount, isNullIgnored = false) => {
    return typeof amount === 'number' && (amount !== 0 || !isNullIgnored) ? `${amount < 0 ? '-' : ''}$${(Math.abs(amount) / 100).toFixed(2)}` : null;
  },
};

export const STATUSES = {
  'requires_payment_method': 'Requires payment method',
  'requires_confirmation': 'Confirmation required',
  'requires_action': 'Authentication required',
  'processing': 'Processing',
  'requires_capture': 'Requires capture',
  'canceled': 'Cancelled',
  'succeeded': 'Completed',
};

export default {
  AMOUNT_FORMATTERS,
  STATUSES,
};
