import {Injectable} from '@angular/core';

import {Subscription, BehaviorSubject} from 'rxjs';

import {AuthService} from '../../../../../auth/auth.service';
import {EducationInstitutionsHttpService} from '../../../interaction/http/education/teachers/institutions/education-institutions-http.service';

import {EducationInstitutionModel} from '../../../../models/education/institutions/education-institution.model';

@Injectable()
export class EducationInstitutionsService {
  public institutionsSubject: BehaviorSubject<EducationInstitutionModel[]> = new BehaviorSubject<EducationInstitutionModel[]>(null);
  public institutionDetailsSubject: BehaviorSubject<EducationInstitutionModel> = new BehaviorSubject<EducationInstitutionModel>(null);
  public isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private httpService: EducationInstitutionsHttpService,
    private authService: AuthService,
  ) {
    this.authService.websiteChangedSubject.subscribe(() => {
      this.institutionDetailsSubject.next(null);
    });
  }

  public fetchOne(id: number): Subscription {
    this.isLoadedSubject.next(false);

    return this.httpService.getOne(id).subscribe((res: EducationInstitutionModel) => {
      this.institutionDetailsSubject.next(res);

      this.isLoadedSubject.next(true);
    });
  }
}
