import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from './auth/auth.service';
import {BrowserService} from './core/services/browser/browser.service';
import {SocketsService} from './core/services/interaction/sockets/sockets.service';
import {LoaderService} from './core/services/loaders/loader.service';
import {RequestInterceptorService} from './core/services/interaction/requests/interceptor/request-interceptor.service';
import {WebsitesService} from './core/services/websites/websites.service';
import {DeviceService} from './core/services/device/device.service';
import {WebsiteTourService} from './core/services/website-tour/website-tour.service';
import {FullscreenImageManagerEnlargementService} from './shared/services/fullscreen-image-manager-enlargement/fullscreen-image-manager-enlargement.service';
import {PaymentSubscriptionsService} from './core/services/payment/subscriptions/payment-subscriptions.service';
import {EducationImageManagerService} from './core/services/education/image-manager/education-image-manager.service';
import {NavigationService} from './services/navigation.service';

import {AccountModel} from './core/models/accounts/account.model';
import {SubscriptionModel} from './core/models/payment/subscriptions/subscription.model';

import {SOCKET_STATUSES} from './core/services/interaction/sockets/constants';
import {EducatorImageManagerTabs} from './core/services/education/image-manager/constants';
import { ISocketWebsitesMessageDataModel } from './core/models/sockets/message/websites/i-websites-message-data.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = false;
  public isSigned: boolean = false;
  public isAdmin: boolean = false;
  public isConnectionLost: boolean = false;
  public isRequestUnhandled: boolean = false;
  public isMaintenanceOverlayVisible: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTourOverlayVisible: boolean = false;
  public isFullscreenImageManagerEnlargementVisible: boolean = false;

  public isWebsiteTourAllowed: boolean = false;

  private account: AccountModel;

  private currentSubscription: SubscriptionModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private isImageManager: boolean = false;
  private isEducatorAdmin: boolean = false;
  private isStudentImageManagerTab: boolean = false;
  private isExhibitionsImageManagerTab: boolean = false;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private socketsService: SocketsService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private requestInterceptorService: RequestInterceptorService,
    private websitesService: WebsitesService,
    private deviceService: DeviceService,
    private websiteTourService: WebsiteTourService,
    private educationImageManagerService: EducationImageManagerService,
    private fullscreenImageManagerEnlargementService: FullscreenImageManagerEnlargementService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    public browserService: BrowserService
  ) {
    this.authService.updateCurrentUser();

    this.isDesktopDevice = !this.deviceService.isMobile;
  }

  public ngOnInit(): void {
    this.loaderService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isLoaded => this.isLoaded = isLoaded);

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
      this.isSigned = !!account;
      this.isAdmin = account && account.isAdmin;

      this.initIsWebsiteTourAllowed();
    });

    this.socketsService.onSocketStatusChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status: string) => {
      this.isConnectionLost = status === SOCKET_STATUSES.CLOSED;

      if (!this.isConnectionLost && this.isRequestUnhandled) {
        window.location.reload();
      }
    });

    this.socketsService.isMaintenanceOverlayVisibleSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isMaintenanceOverlayVisible: boolean) => {
      this.isMaintenanceOverlayVisible = isMaintenanceOverlayVisible;
    });

    this.socketsService.websitesDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISocketWebsitesMessageDataModel) => {
      if (!data || data.key !== 'WEBSITE_SUBSCRIPTION_CHANGED') {
        return;
      }

      window.location.reload();
    });

    this.requestInterceptorService.onUnhandledRequestSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isRequestUnhandled: boolean) => {
      this.isRequestUnhandled = isRequestUnhandled;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      this.initIsWebsiteTourAllowed();
    });

    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      this.isStudentImageManagerTab = key === 'students';
      this.isExhibitionsImageManagerTab = key === 'exhibitions';

      this.initIsWebsiteTourAllowed();
    });

    this.navigationService.isImageManagerSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isImageManager = value;

      this.initIsWebsiteTourAllowed();
    });

    this.navigationService.isEducatorAdminSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isEducatorAdmin = value;

      this.initIsWebsiteTourAllowed();
    });

    this.websiteTourService.isOpened.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpened: boolean) => {
      this.isTourOverlayVisible = isOpened;

      this.cdr.detectChanges();
    });

    this.fullscreenImageManagerEnlargementService.isOpenedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpened: boolean) => {
      this.isFullscreenImageManagerEnlargementVisible = isOpened;

      this.cdr.detectChanges();
    });
  }

  private initIsWebsiteTourAllowed(): void {
    this.isWebsiteTourAllowed = this.getIsWebsiteTourAllowed();
  }

  private getIsWebsiteTourAllowed(): boolean {
    if (!this.account) {
       return false;
    }

    if (!this.currentSubscription) {
      return this.account.isAdmin;
    }

    if (!this.currentSubscription.isEducator) {
      return true;
    }

    if (this.isImageManager) {
      return !this.isStudentImageManagerTab && !this.isExhibitionsImageManagerTab;
    }

    return !this.isEducatorAdmin;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
