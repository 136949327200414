import {AccountModel} from "../../accounts/account.model";
import {PortfolioModel} from "../../portfolios/portfolio.model";
import {EducationClassModel} from "../classes/education-class.model";

export class EducationStudentModel {
  constructor(
    public id?: number,
    public userId?: number,
    public user?: AccountModel,
    public portfolios?: PortfolioModel[],
    public deletedBy?: AccountModel,
    public createdAt?: string,
    public updatedAt?: string,
    public deletedAt?: string
  ) {
  }
}
