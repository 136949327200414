export const environment = {
  local: false,
  production: true,
  protocol: 'https://',
  apiBase: "/api",
  socketUri: "wss://develop.visualserver.com/api/sockets",
  stripe: {
    publicKey: "pk_test_oOU86IGTz11Vr8FIgMPtd0j900djrG8cLO"
  },
  ga4: {
    clientId: "1000914687848-vaggek10d14nv186fov9is716i65gu33.apps.googleusercontent.com",
    oAuthRedirectUri: "https://develop.visualserver.com/app/google-analytics/setup/oauth",
    accountProvisionRedirectUri: "https://develop.visualserver.com/google/analytics/account-provision",
    scopes: [
      "https://www.googleapis.com/auth/analytics",
      "https://www.googleapis.com/auth/analytics.edit"
    ]
  },
  instagram: {
    model: "instagram",
    apiBase: "https://api.instagram.com",
    clientId: "9d41084d11c743ed832bde1c575bb0fc",
    redirectUri: "https://develop.visualserver.com/api/static/instagram-redirect-page"
  },
  aws: {
    s3: {
      bucket: "images.dev.visualserver.com",
    }
  },
  recaptcha: {
    key: '6LeGF3gUAAAAADe-nH5fZpU8cjEW_VWbwBuFNhva'
  },
  education: {
    studentSignUpUrl: 'https://develop.visualserver.com/register-student/'
  }
};
