import * as moment from 'moment';

import {TIME_FORMATTER} from '../constants';

export class StudentImageReviewAudioListenedAtDto {
  constructor(
    public Id: number,
    public UserId: number,
    public AudioId: number,
    public CreatedAt: string
  ) {
  }

  public static normalize(res: StudentImageReviewAudioListenedAtDto): string {
    return res ? moment(res.CreatedAt).format(TIME_FORMATTER) : null;
  }
}
