import {Component} from '@angular/core';

import {StudentCourseEndedModalService} from '../../../services/modals/student-course-ended/student-course-ended-modal.service';

import {Button} from '../../../../common/models/button/button.model';
import {CourseWebsiteModel} from '../../../../core/models/websites/course-website.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-student-course-ended-modal',
  templateUrl: './student-course-ended-modal.component.html',
  styleUrls: ['./student-course-ended-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class StudentCourseEndedModalComponent {
  public website: CourseWebsiteModel;

  public teachersNames: string = '';

  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.close.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: StudentCourseEndedModalService,
  ) {
  }

  public onOpen(data: { website: CourseWebsiteModel }): void {
    this.website = data.website;

    if (!this.website || !this.website.course || !this.website.course.teachersUsers || this.website.course.teachersUsers.length === 0) {
      return;
    }

    this.teachersNames = this.website.course.teachersUsers.map((user: AccountModel) => {
      return user.name;
    }).join(', ');
  }

  public close(): void {
    this.service.close();
  }
}
