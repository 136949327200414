import {Component, Output, EventEmitter, Input} from "@angular/core";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() isFirstAndLastButtonsVisible: boolean = true;

  @Input() currentPage: number;
  @Input() totalPageCount: number;

  @Output() doPageChange = new EventEmitter();

  constructor() { }

  next() {
    if (this.currentPage === this.totalPageCount) return;

    this.changePage(this.currentPage + 1);
  }

  prev() {
    if (this.currentPage === 1) return;

    this.changePage(this.currentPage - 1);
  }

  first() {
    if (this.currentPage === 1) return;

    this.changePage(1);
  }

  last() {
    if (this.currentPage === this.totalPageCount) return;

    this.changePage(this.totalPageCount);
  }

  changePage(index) {
    this.doPageChange.emit(index);
  }

  getVisiblePages() {
    const result = [this.currentPage];

    if (this.totalPageCount === 0) return result;

    if (this.currentPage !== 1) result.unshift(this.currentPage - 1);
    if (this.currentPage !== this.totalPageCount) result.push(this.currentPage + 1);

    return result;
  }

}
