import {Component, EventEmitter, Input, Output} from '@angular/core';

import {CardModel} from '../../../core/models/payment/card/card.model';

@Component({
  selector: 'app-credit-cards',
  templateUrl: './credit-cards.component.html',
  styleUrls: ['./credit-cards.component.scss'],
})
export class CreditCardsComponent {
  @Input() isCardSelect: boolean = false;
  @Input() selectedCard: CardModel;

  @Output() cardSelectHandler: EventEmitter<CardModel> = new EventEmitter<CardModel>();

  public onCardSelected(card: CardModel): void {
    this.cardSelectHandler.emit(card);
  }
}
