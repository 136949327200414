import {Injectable} from '@angular/core';

import {PortfolioImageTitleFontsStylesService} from './fonts/portfolio-image-title/portfolio-image-title-fonts-styles.service';
import {PortfolioEnlargementTitleFontsStylesService} from './fonts/portfolio-enlargement-title/portfolio-enlargement-title-fonts-styles.service';
import {PortfolioSlideThumbFontsStylesService} from './fonts/portfolio-slide-thumb/portfolio-slide-thumb-fonts-styles.service';
import {ThumbDrawerFontsStylesService} from './fonts/thumb-drawer/thumb-drawer-fonts-styles.service';

import {SetupModel} from '../../../models/styles/setup/setup.model';
import {StyleOptionModel} from '../../../models/styles/settings/option/style-option.model';
import {GoogleFontModel} from '../../../models/google/fonts/google-font.model';
import {SelectOption} from '../../../models/select/option/option.model';
import {SlideshowTitleFontsStylesService} from './fonts/slideshow-title/slideshow-title-fonts-styles.service';
import {SlideshowParagraphFontsStylesService} from './fonts/slideshow-paragraph/slideshow-paragraph-fonts-styles.service';
import {SlideshowButtonFontsStylesService} from './fonts/slideshow-button/slideshow-button-fonts-styles.service';

@Injectable()
export class CustomStylesService {
  public setup: SetupModel;

  public get element(): HTMLElement {
    return this.setup.element;
  }

  public get slideshowTitleFontWeightsOptions(): SelectOption[] {
    return this.slideshowTitleFontsStylesService.fontWeightsOptions;
  }

  public get slideshowParagraphFontWeightsOptions(): SelectOption[] {
    return this.slideshowParagraphFontsStylesService.fontWeightsOptions;
  }

  public get slideshowButtonFontWeightsOptions(): SelectOption[] {
    return this.slideshowButtonFontsStylesService.fontWeightsOptions;
  }

  public get portfolioImageTitleFontWeightsOptions(): SelectOption[] {
    return this.portfolioImageTitleFontsStylesService.fontWeightsOptions;
  }

  public get portfolioEnlargementTitleFontWeightsOptions(): SelectOption[] {
    return this.portfolioEnlargementTitleFontsStylesService.fontWeightsOptions;
  }

  public get portfolioSlideThumbFontWeightsOptions(): SelectOption[] {
    return this.portfolioSlideThumbFontsStylesService.fontWeightsOptions;
  }

  public get thumbDrawerFontWeightsOptions(): SelectOption[] {
    return this.thumbDrawerFontsStylesService.fontWeightsOptions;
  }

  constructor(private slideshowTitleFontsStylesService: SlideshowTitleFontsStylesService,
              private slideshowParagraphFontsStylesService: SlideshowParagraphFontsStylesService,
              private slideshowButtonFontsStylesService: SlideshowButtonFontsStylesService,
              private portfolioImageTitleFontsStylesService: PortfolioImageTitleFontsStylesService,
              private portfolioEnlargementTitleFontsStylesService: PortfolioEnlargementTitleFontsStylesService,
              private portfolioSlideThumbFontsStylesService: PortfolioSlideThumbFontsStylesService,
              private thumbDrawerFontsStylesService: ThumbDrawerFontsStylesService) {
    this.setup = new SetupModel();
  }

  public init({ element, enabledStyleOptions, device }: { element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>, device: string }) {
    this.setup.init({ element, enabledStyleOptions, device });

    this.slideshowTitleFontsStylesService.init(this.setup);
    this.slideshowParagraphFontsStylesService.init(this.setup);
    this.slideshowButtonFontsStylesService.init(this.setup);
    this.portfolioImageTitleFontsStylesService.init(this.setup);
    this.portfolioEnlargementTitleFontsStylesService.init(this.setup);
    this.portfolioSlideThumbFontsStylesService.init(this.setup);
    this.thumbDrawerFontsStylesService.init(this.setup);
  }

  public initSlideshowTitleFontWeight(): void {
    this.slideshowTitleFontsStylesService.initFontWeight();
  }

  public initSlideshowParagraphFontWeight(): void {
    this.slideshowParagraphFontsStylesService.initFontWeight();
  }

  public initSlideshowButtonFontWeight(): void {
    this.slideshowButtonFontsStylesService.initFontWeight();
  }

  public initPortfolioImageTitleFontWeight(): void {
    this.portfolioImageTitleFontsStylesService.initFontWeight();
  }

  public initPortfolioEnlargementTitleFontWeight(): void {
    this.portfolioEnlargementTitleFontsStylesService.initFontWeight();
  }

  public initPortfolioSlideThumbFontWeight(): void {
    this.portfolioSlideThumbFontsStylesService.initFontWeight();
  }

  public initThumbDrawerFontWeight(): void {
    this.thumbDrawerFontsStylesService.initFontWeight();
  }

  public onSlideshowTitleFontFamilyChange(font: GoogleFontModel): void {
    this.slideshowTitleFontsStylesService.onFontChange(font);
  }

  public onSlideshowParagraphFontFamilyChange(font: GoogleFontModel): void {
    this.slideshowParagraphFontsStylesService.onFontChange(font);
  }

  public onSlideshowButtonFontFamilyChange(font: GoogleFontModel): void {
    this.slideshowButtonFontsStylesService.onFontChange(font);
  }

  public onPortfolioImageTitleFontFamilyChange(font: GoogleFontModel): void {
    this.portfolioImageTitleFontsStylesService.onFontChange(font);
  }

  public onPortfolioEnlargementTitleFontFamilyChange(font: GoogleFontModel): void {
    this.portfolioEnlargementTitleFontsStylesService.onFontChange(font);
  }

  public onPortfolioSlideThumbFontFamilyChange(font: GoogleFontModel): void {
    this.portfolioSlideThumbFontsStylesService.onFontChange(font);
  }

  public onThumbDrawerFontFamilyChange(font: GoogleFontModel): void {
    this.thumbDrawerFontsStylesService.onFontChange(font);
  }
}
