import {MeasureUnitModel} from './measure-unit.model';

export class MeasureUnitDto {
  constructor(public Id?: number,
              public Name?: string,
              public Symbol?: string,
              public SymbolLong?: string,
              public ConversionRate?: number,
              public IsMain?: boolean) {}

  public static normalize(res: MeasureUnitDto): MeasureUnitModel {
    if (!res) return null;

    return new MeasureUnitModel(
      res.Id,
      res.Name,
      res.Symbol,
      res.SymbolLong,
      res.ConversionRate,
      res.IsMain,
    );
  }
}
