import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {EventsService} from '../core/services/interaction/events/events.service';
import {IFrameRoutingService} from '../core/services/iframe/routing/iframe-routing.service';
import {IFrameService} from '../core/services/iframe/iframe.service';

import {SelectedPageModel} from '../core/models/selected-page/selected-page.model';

import { get } from 'lodash';

@Injectable()
export class SelectedBlocksService {
  private EVENTS = {
    BLOCK_ADDED: 'blockAdded',
    BLOCK_REMOVE: 'blockRemove',
  };

  private MENU_CATEGORY = 23;

  public selectedBlocksSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public isMenuHasPageLinksSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private eventsService: EventsService,
              private iFrameRoutingService: IFrameRoutingService,
              private iFrameService: IFrameService) {
    this.onBlockAddedHandler = this.onBlockAddedHandler.bind(this);
    this.onBlockRemovedHandler = this.onBlockRemovedHandler.bind(this);

    this.eventsService.addFrameListener(this.EVENTS.BLOCK_ADDED, this.onBlockAddedHandler);
    this.eventsService.addFrameListener(this.EVENTS.BLOCK_REMOVE, this.onBlockRemovedHandler);
  }

  public init() {
    this.iFrameRoutingService.selectedPageSubject.subscribe((selectedPage: SelectedPageModel) => {
      this.selectedBlocksSubject.next([]);
    });
  }

  private onBlockAddedHandler(event: CustomEvent) {
    const selectedBlock = {
      blockCategoryId: get(event.detail, 'block.BlockCategoryId', null),
      blockTemplateId: get(event.detail, 'block.BlockTemplateId', null),
    };

    if (selectedBlock.blockCategoryId !== this.MENU_CATEGORY) return this.selectedBlocksSubject.next([...this.selectedBlocksSubject.value, selectedBlock]);

    this.selectedBlocksSubject.next([...this.selectedBlocksSubject.value.filter(block => {
      return block.blockCategoryId !== this.MENU_CATEGORY;
    }), selectedBlock]);

    this.isMenuHasPageLinksSubject.next(!!this.iFrameService.menuItemsWrapper);
  }

  private onBlockRemovedHandler(event: CustomEvent) {
    if (!event.detail || !event.detail.blocksIds) return;

    this.selectedBlocksSubject.next(this.selectedBlocksSubject.value.filter(selectedBlock => {
      return event.detail.blocksIds.includes(selectedBlock.blockTemplateId) || selectedBlock.blockCategoryId === this.MENU_CATEGORY;
    }));
  }
}
