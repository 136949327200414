import {Component, OnInit, OnDestroy} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import {Subject, combineLatest} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {TemplatesService} from '../../services/templates/templates.service';
import {WebsitesService} from '../../services/websites/websites.service';
import {SidebarSectionsService} from '../../../services/sidebar-sections.service';
import {NavigationService} from '../../../services/navigation.service';

import {TemplateModel} from '../../models/templates/template.model';
import {TemplatePageModel} from '../../models/templates/pages/template-page.model';
import {TemplatePreviewDetailsModel} from '../../models/templates/previews/details/template-preview-details.model';
import {WebsitesTemplatesPermissionsModel} from '../../models/websites/templates-permissions/templates-permissions.model';

import {DEFAULT_THEME_ID, HOME_TYPE_ID, SPLASH_TYPE_ID} from './constants';

@Component({
  selector: 'app-templates-manager',
  templateUrl: 'templates-manager.component.html',
  styleUrls: ['./templates-manager.component.scss']
})
export class TemplatesManagerComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = false;

  public iframeSrc: SafeResourceUrl = null;
  public isIframeExists: boolean = false;
  public isSetup: boolean = false;

  public activeTemplateId: number;

  public templates: TemplateModel[] = [];

  public selectedTemplate: TemplateModel;
  public selectedPage: TemplatePageModel = null;

  public width: string = '';

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: TemplatesService,
              private websitesService: WebsitesService,
              private sanitizer: DomSanitizer,
              private sidebarSectionsService: SidebarSectionsService,
              private navigationService: NavigationService) {}

  public ngOnInit(): void {
    const templatesObservable = this.service.templatesSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => !!value),
    );

    const activeWebsiteObservable = this.websitesService.activeWebsiteSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => !!value),
    );;

    combineLatest([templatesObservable, activeWebsiteObservable]).subscribe(([templates, website]) => {
      const additionalTemplates: number[] = website.templatesPermissions ? website.templatesPermissions.map((templateData: WebsitesTemplatesPermissionsModel) => templateData.templateId) : [];

      this.templates = templates ? templates.filter(template => template.id > 9 || additionalTemplates.includes(template.id)) : templates;

      this.templates.forEach((template: TemplateModel) => {
        template.isOldTemplate = additionalTemplates.includes(template.id);
      })

      this.isSetup = website && !website.isSetupCompleted;
    });

    this.service.isWebsitePreviewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPreview: boolean) => {
      this.isIframeExists = isPreview;
    });

    this.service.windowWidthSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((width: string) => {
      this.width = width;
    });

    this.websitesService.activeTemplateIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((templateId: number) => {
      this.activeTemplateId = templateId;
    });

    this.sidebarSectionsService.isSectionsCollapsedSubject.next(true);
  }

  public onMouseEnter(template: TemplateModel): void {
    template.isHovered = true;
  }

  public onMouseLeave(template: TemplateModel): void {
    template.isHovered = false;
  }

  public viewTemplate(template: TemplateModel): void {
    this.selectTemplate(template);

    if (!template || !template.preview || !template.preview.themes) return this.setSrcFromTemplate(template);

    const themes: TemplatePreviewDetailsModel[] = template.preview.themes[DEFAULT_THEME_ID] || Object.values(template.preview.themes)[0];

    const theme: TemplatePreviewDetailsModel = this.getTemplateTheme(themes);

    if (!theme) return;

    this.openWebsite(theme.previewUrl);
  }

  private getTemplateTheme(themes: TemplatePreviewDetailsModel[]): TemplatePreviewDetailsModel {
    if (!themes) return null;

    const splash: TemplatePreviewDetailsModel = themes.find((theme: TemplatePreviewDetailsModel) => theme.typeId === SPLASH_TYPE_ID);

    return splash || themes.find((theme: TemplatePreviewDetailsModel) => theme.typeId === HOME_TYPE_ID);
  }

  public selectTemplate(template: TemplateModel): void {
    this.selectedTemplate = template;
    this.service.selectedTemplateSubject.next(template);

    if (!template) return;

    this.service.fetchTemplatePages(template.id);
  }

  private setSrcFromTemplate(template: TemplateModel): void {
    const src = template ? `api/templates/${template.id}/preview/H` : '';

    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(src);

    this.service.isWebsitePreviewSubject.next(!!src);
  }

  public onPageSelect(page: TemplatePageModel): void {
    this.selectedPage = page;

    const src = this.selectedTemplate && this.selectedPage ? `api/templates/${this.selectedTemplate.id}/preview/${this.selectedPage.key}` : '';

    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(src);

    this.service.isWebsitePreviewSubject.next(!!src);
  }

  public openWebsite(src: string): void {
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(src);

    this.service.isWebsitePreviewSubject.next(!!src);
  }

  public useTemplate(template: TemplateModel) {
    if (!this.isSetup) return this.service.selectTemplate(template);

    this.service.selectTemplateOnSetup(template);
  }

  public onImageError(template: TemplateModel): void {
    console.error(`Can't load image for template ${template.id}.`);

    this.onImageLoaded(template);
  }

  public onImageLoaded(template: TemplateModel): void {
    template.isImageLoaded = true;

    if (!this.templates) return;

    this.isLoaded = this.templates.every((template: TemplateModel) => template.isImageLoaded);
  }

  public close(): void {
    this.navigationService.toPageEditorWithCurrentPage();
  }

  public ngOnDestroy(): void {
    this.service.isWebsitePreviewSubject.next(false);
    this.service.selectedTemplateSubject.next(null);

    this.sidebarSectionsService.isSectionsCollapsedSubject.next(false);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
