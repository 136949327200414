import {IStyleOption} from '../../option/i-style-option';
import {StyleOptionModel} from '../../../settings/option/style-option.model';

import {BlogAddCommentButtonBackgroundModel} from './background/blog-add-comment-button-background.model';
import {BlogAddCommentButtonTextColorModel} from './text-color/blog-add-comment-button-text-color.model';
import {BlogAddCommentButtonHoverBackgroundModel} from './hover-background/blog-add-comment-button-hover-background.model';
import {BlogAddCommentButtonHoverTextColorModel} from './hover-text-color/blog-add-comment-button-hover-text-color.model';
import {BlogAddCommentButtonBorderRadiusModel} from './border-radius/blog-add-comment-button-border-radius.model';

import {KEYS, TYPES} from '../../../../../services/styles/blog/add-comment-button/constants';

export class BlogAddCommentButtonSetupModel {
  public element: HTMLElement;

  private enabledStyleOptions: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public background: BlogAddCommentButtonBackgroundModel = new BlogAddCommentButtonBackgroundModel(KEYS.BLOG_ADD_COMMENT_BUTTON_BACKGROUND, TYPES.STYLE_PROPERTY);
  public textColor: BlogAddCommentButtonTextColorModel = new BlogAddCommentButtonTextColorModel(KEYS.BLOG_ADD_COMMENT_BUTTON_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public hoverBackground: BlogAddCommentButtonHoverBackgroundModel = new BlogAddCommentButtonHoverBackgroundModel(KEYS.BLOG_ADD_COMMENT_BUTTON_BACKGROUND, TYPES.STYLE_PROPERTY);
  public hoverTextColor: BlogAddCommentButtonHoverTextColorModel = new BlogAddCommentButtonHoverTextColorModel(KEYS.BLOG_ADD_COMMENT_BUTTON_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public borderRadius: BlogAddCommentButtonBorderRadiusModel = new BlogAddCommentButtonBorderRadiusModel(KEYS.BLOG_ADD_COMMENT_BUTTON_BORDER_RADIUS, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.background,
    this.textColor,
    this.hoverBackground,
    this.hoverTextColor,
    this.borderRadius,
  ];

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.element = element;
    this.enabledStyleOptions = enabledStyleOptions;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(element);

    this.options.forEach(option => {
      option.isEnabled = this.enabledStyleOptions.has(option.key);

      if (!option.init) return;

      try {
        option.init(element, {
          innerWrapper,
          block,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.classList.contains('block') ? element : <HTMLElement>element.closest('.block');
  }
}
