export class StripeCouponMetadataModel {
  constructor(public type?: string,
              public applyTo?: string,
              public discountType?: string,
              public startDate?: string,
              public endDate?: string) {}

  public static normalize(res: StripeCouponMetadataModel): StripeCouponMetadataModel {
    if (!res) return null;

    return new StripeCouponMetadataModel(
      res.type,
      res.applyTo,
      res.discountType,
      res.startDate,
      res.endDate,
    );
  }
}
