import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {AuthService} from '../../../auth/auth.service';
import {PaymentSubscriptionsService} from '../payment/subscriptions/payment-subscriptions.service';
import {EducationImageManagerService} from '../education/image-manager/education-image-manager.service';
import {PermissionsService} from '../service-permissions/permissions/permissions.service';
import {StudentImageManagerService} from '../education/students/image-manager/student-image-manager.service';

import {AccountModel} from '../../models/accounts/account.model';
import {SubscriptionModel} from '../../models/payment/subscriptions/subscription.model';
import {TabSettingsModel} from '../../models/sidebar/tabs-settings.model'
import {IPermissionData} from '../../models/permission/i-permission-data';;

import {EducatorImageManagerTabs} from '../education/image-manager/constants';
import {StudentImageManagerTabs} from '../education/students/image-manager/constants';

import {KEYS, TABS, ACCESS_KEYS, EDUCATOR_KEYS, STUDENT_KEYS, USER_TAB_KEYS} from './constants';
import {PERMISSIONS} from '../service-permissions/constants';

@Injectable()
export class SidebarService {
  public currentTabSubject: BehaviorSubject<TabSettingsModel> = new BehaviorSubject<TabSettingsModel>(null);
  public accessKeySubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private account: AccountModel;

  private currentSubscription: SubscriptionModel;

  private currentTab: TabSettingsModel;
  
  private isStudent: boolean = false;
  private isStudentTab: boolean = false; // student
  private isStudentsTab: boolean = false; // educator
  private isExhibitionsTab: boolean = false;

  public get keys(): { [key: string]: string } {
    return KEYS;
  }

  public get tabs(): { [key: string]: TabSettingsModel } {
    return TABS;
  }

  constructor(
    private authService: AuthService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private permissionsService: PermissionsService,
    private educationImageManagerService: EducationImageManagerService,
    private studentImageManagerService: StudentImageManagerService,
  ) {
    this.initPermissions();
    
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;

      this.initAccessKey();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      this.initAccessKey();
    });

    this.educationImageManagerService.activeTabSubject.subscribe((activeTab: EducatorImageManagerTabs) => {
      this.isStudentsTab = activeTab === 'students';
      this.isExhibitionsTab = activeTab === 'exhibitions';

      this.initAccessKey();
    });

    this.studentImageManagerService.activeTabSubject.subscribe((activeTab: StudentImageManagerTabs) => {
      this.isStudentTab = activeTab === 'student';

      this.initAccessKey();
    });
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).subscribe((isPermitted: boolean) => {
      this.isStudent = isPermitted;

      this.initAccessKey();
    });
  }

  public selectTab(key: string): void {
    if (this.currentTab && this.currentTab.key === key) {
      return;
    }

    const oldTab: TabSettingsModel = this.currentTab;

    this.currentTab = TABS[key] || null;
    
    this.currentTabSubject.next(this.currentTab);

    this.initAccessKey();
    
    if (key !== KEYS.IMAGES) {
      return;
    }
    
    this.initImageManagerTab(oldTab);
  }

  private initAccessKey(): void {
    this.accessKeySubject.next(this.getAccessKey());
  }

  private initImageManagerTab(oldTab: TabSettingsModel): void {
    if (!this.currentSubscription || !this.currentSubscription.isEducator) {
      return;
    }

    const isUserTab: boolean = !!oldTab && USER_TAB_KEYS.includes(oldTab.key);

    this.educationImageManagerService.setActiveTab(isUserTab ? 'user' : 'students');
  }

  private getAccessKey(): string {
    if (!this.account) {
      return ACCESS_KEYS.EMPTY;
    }

    if (!this.currentSubscription) {
      return ACCESS_KEYS.FULL;
    }

    if (this.currentSubscription.isEducator) {
      if (this.currentTab) {
        if (this.currentTab.key === KEYS.IMAGES) {
          return this.isStudentsTab || this.isExhibitionsTab ? ACCESS_KEYS.EDUCATOR : ACCESS_KEYS.FULL;
        }

        if (EDUCATOR_KEYS.includes(this.currentTab.key)) {
          return ACCESS_KEYS.EDUCATOR;
        }
      }
    }

    if (this.isStudent) {
      if (this.currentTab) {
        if (this.currentTab.key === KEYS.IMAGES) {
          return this.isStudentTab ? ACCESS_KEYS.STUDENT : ACCESS_KEYS.FULL;
        }

        if (STUDENT_KEYS.includes(this.currentTab.key)) {
          return ACCESS_KEYS.STUDENT;
        }
      }
    }

    return ACCESS_KEYS.FULL;
  }
}
