import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-close-icon',
  templateUrl: './close-icon.component.html',
  styleUrls: ['./close-icon.component.scss'],
})
export class CloseIconComponent {
  @Output() clickHandler = new EventEmitter();

  public handleClick(event): void {
    this.clickHandler.emit(event);
  }
}
