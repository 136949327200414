
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {BehaviorSubject, Subscription, combineLatest, Observable} from 'rxjs';

import {AuthService} from '../../../auth/auth.service';
import {WebsiteTourHttpService} from '../interaction/http/website-tour/website-tour-http.service';
import {WebsitesService} from '../websites/websites.service';

import {AccountModel} from '../../models/accounts/account.model';
import {WebsiteTourProgressItemModel} from '../../models/website-tour-progress/item/website-tour-progress-item.model';
import {WebsiteTourProgressAccountDataModel} from '../../models/website-tour-progress/account-data/website-tour-progress-account-data.model';
import {WebsiteModel} from '../../models/websites/website.model';

@Injectable()
export class WebsiteTourService {
  public itemsGroupedByLocations: BehaviorSubject<{ [key: string]: WebsiteTourProgressItemModel[] }> = new BehaviorSubject(null);
  public visibleLocations: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject({});
  public visibleItems: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject({});
  public isOpened: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public items: { [key: string]: WebsiteTourProgressItemModel };

  private account: AccountModel;
  private website: WebsiteModel;

  public get isSkipped(): boolean {
    if (!this.website || !this.website.isSetupCompleted) return true;

    return this.account.websiteTourProgress ? this.account.websiteTourProgress.isSkipped : false;
  }

  private get currentVisibleLocations(): { [key: string]: boolean } {
    return this.visibleLocations.value;
  }

  private get currentVisibleItems(): { [key: string]: boolean } {
    return this.visibleItems.value;
  }

  private get currentItemsGroupedByLocations(): { [key: string]: WebsiteTourProgressItemModel[] } {
    return this.itemsGroupedByLocations.value;
  }

  constructor(private router: Router,
              private httpService: WebsiteTourHttpService,
              private authService: AuthService,
              private websitesService: WebsitesService) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      if (!this.items) return;

      this.initGroupedItemsByLocations(this.items);
    });

    const accountObservable: Observable<AccountModel> = this.authService.accountSubject.pipe(filter((account: AccountModel) => !!account));
    const websiteObservable: Observable<WebsiteModel> = this.websitesService.activeWebsiteSubject.pipe(filter((website: WebsiteModel) => !!website));

    combineLatest([accountObservable, websiteObservable]).subscribe(([account, website]) => {
      this.account = account;
      this.website = website;

      if (this.isSkipped) return;

      this.initList();
    });
  }

  public reInitList(): void {
    this.initGroupedItemsByLocations(this.items);
  }

  public initList(): void {
    this.httpService.fetchAll().subscribe((items: { [key: string]: WebsiteTourProgressItemModel }) => {
      this.items = items;

      this.initGroupedItemsByLocations(items);
    });
  }

  private initGroupedItemsByLocations(items: { [key: string]: WebsiteTourProgressItemModel }): void {
    if (this.isSkipped) {
      this.itemsGroupedByLocations.next({});

      return;
    }

    const isInProgress: boolean = this.account.websiteTourProgress ? !!this.account.websiteTourProgress.progress : false;

    const groupedItems: { [key: string]: WebsiteTourProgressItemModel[] } = Object.keys(items).reduce((res: { [key: string]: WebsiteTourProgressItemModel[] }, key: string) => {
      if (!res[items[key].location]) res[items[key].location] = [];

      if (!isInProgress || !this.account.websiteTourProgress.progress[items[key].id]) {
        res[items[key].location].push(items[key]);
      }

      return res;
    }, {});

    this.itemsGroupedByLocations.next(groupedItems);

    this.isOpened.next(true);
  }

  public setProgress(tourProgress: WebsiteTourProgressAccountDataModel): Subscription {
    return this.httpService.setProgress(tourProgress).subscribe(() => {});
  }

  public addVisibleLocation(key: string): void {
    this.currentVisibleLocations[key] = true;

    this.visibleLocations.next(JSON.parse(JSON.stringify(this.currentVisibleLocations)));

    if (!this.currentItemsGroupedByLocations || !this.currentItemsGroupedByLocations[key] || this.currentItemsGroupedByLocations[key].length === 0) {
      return;
    }

    this.isOpened.next(true);
  }

  public removeVisibleLocation(key: string): void {
    this.currentVisibleLocations[key] = false;

    this.visibleLocations.next(JSON.parse(JSON.stringify(this.currentVisibleLocations)));
  }

  public addVisibleItem(key: string): void {
    if (this.currentVisibleItems[key]) return;

    this.currentVisibleItems[key] = true;

    this.visibleItems.next(JSON.parse(JSON.stringify(this.currentVisibleItems)));

    if (!this.currentItemsGroupedByLocations || !this.currentItemsGroupedByLocations[key] || this.currentItemsGroupedByLocations[key].length === 0) return;

    this.isOpened.next(true);
  }

  public removeVisibleItem(key: string): void {
    if (this.currentVisibleItems[key] === false) return;

    this.currentVisibleItems[key] = false;

    this.visibleItems.next(JSON.parse(JSON.stringify(this.currentVisibleItems)));
  }
}
