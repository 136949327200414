// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .remove-support-row-modal .modal-body .remove-support-row-modal-body {
  margin: 20px;
  font-size: 14px;
}
:host ::ng-deep .remove-support-row-modal .modal-body {
  min-width: 400px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/remove-support-row/remove-support-row-modal.component.scss"],"names":[],"mappings":"AAKM;EACE,YAAA;EACA,eAAA;AAJR;AAQI;EACE,2BAAA;AANN","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .remove-support-row-modal {\n    .modal-body {\n      .remove-support-row-modal-body {\n        margin: 20px;\n        font-size: 14px;\n      }\n    }\n\n    .modal-body {\n      min-width: 400px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
