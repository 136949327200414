import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ColorIntersectionModel} from '../../../../models/styles/color/intersection/color-intersection.model';

@Injectable()
export class ColorIntersectionService {
  public dataSubject: BehaviorSubject<ColorIntersectionModel> = new BehaviorSubject<ColorIntersectionModel>(new ColorIntersectionModel());

  private get data(): ColorIntersectionModel {
    return this.dataSubject.value;
  }

  public initColor(key: string, color: string) {
    this.data.initColor(key, color);
  }

  public calculate() {
    this.data.calculate();

    this.dataSubject.next(this.data);
  }
}
