export const KEYS = {
  BLOG_COMMENTS_BACKGROUND: 'BLOG_COMMENTS_BACKGROUND',
  BLOG_COMMENTS_TEXT_COLOR: 'BLOG_COMMENTS_TEXT_COLOR',
  BLOG_COMMENTS_FONT_SIZE: 'BLOG_COMMENTS_FONT_SIZE',
  BLOG_COMMENTS_LINE_HEIGHT: 'BLOG_COMMENTS_LINE_HEIGHT',
  BLOG_COMMENTS_BORDER_COLOR: 'BLOG_COMMENTS_BORDER_COLOR',
  BLOG_COMMENTS_BORDER_RADIUS: 'BLOG_COMMENTS_BORDER_RADIUS',
  BLOG_COMMENTS_SEPARATOR_COLOR: 'BLOG_COMMENTS_SEPARATOR_COLOR',
  BLOG_MARGIN_BETWEEN_COMMENTS: 'BLOG_MARGIN_BETWEEN_COMMENTS',
  BLOG_MARGIN_BETWEEN_CHILD_COMMENTS: 'BLOG_MARGIN_BETWEEN_CHILD_COMMENTS',
  BLOG_COMMENTS_USERNAME_COLOR: 'BLOG_COMMENTS_USERNAME_COLOR',
  BLOG_COMMENTS_USERNAME_FONT_SIZE: 'BLOG_COMMENTS_USERNAME_FONT_SIZE',
  BLOG_COMMENTS_REPLY_COLOR: 'BLOG_COMMENTS_REPLY_COLOR',
  BLOG_COMMENTS_REPLY_HOVER_COLOR: 'BLOG_COMMENTS_REPLY_HOVER_COLOR',
  BLOG_COMMENTS_REPLY_FONT_SIZE: 'BLOG_COMMENTS_REPLY_FONT_SIZE',
  BLOG_COMMENTS_TIMESTAMP_COLOR: 'BLOG_COMMENTS_TIMESTAMP_COLOR',
  BLOG_COMMENTS_TIMESTAMP_FONT_SIZE: 'BLOG_COMMENTS_TIMESTAMP_FONT_SIZE',
};

export const TYPES = {
  ATTRIBUTE: 'ATTRIBUTE',
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
};
