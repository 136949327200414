import {BaseColorModel} from '../../../../base/color/base-color.model';

import {PROPERTY_NAME, STYLE_PROPERTY_NAME} from './constants';

export class PortfolioEnlargementArrowsColorModel extends BaseColorModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, STYLE_PROPERTY_NAME, value, isEnabled);
  }

  public init(selectedElement: HTMLElement) {
    if (!this.isEnabled || !selectedElement) return;

    const wrapper: HTMLElement = <HTMLElement>selectedElement.closest('.portfolio-enlargement-wrapper');

    super.init(wrapper, {
      selectedElement,
      defaultValueHolderElement: selectedElement,
    });
  }
}
