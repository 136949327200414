import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {AppAnimations} from '../../../../../app-animations';

import {ModalsService} from '../../../../../shared/services/modals/modals.service';
import {PlansService} from '../../../../../core/services/plans/plans.service';
import {PaymentSubscriptionsService} from '../../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {UpgradeSubscriptionModalService} from '../../../../../shared/services/modals/upgrade-subscription/upgrade-subscription-modal.service';
import {CartService} from '../../../../../core/services/cart/cart.service';
import {NavigationService} from '../../../../../services/navigation.service';
import {AuthService} from '../../../../../auth/auth.service';
import {UtilsService} from '../../../../../core/services/utils/utils.service';
import {SidebarSectionsService} from '../../../../../services/sidebar-sections.service';
import {ComparePlansModalService} from '../../../../../shared/services/modals/compare-plans/compare-plans-modal.service';
import {PurchaseValidationModalService} from '../../../../../shared/services/modals/purchase-validation/purchase-validation-modal.service';
import {AppSettingsService} from '../../../../../core/services/app-settings/app-settings.service';

import {PlanModel} from '../../../../../core/models/plan/plan.model';
import {AccountSummaryModel} from '../../../../../core/models/accounts/summary/account-summary.model';
import {PurchaseValidationModel} from '../../../../../core/models/validation/purchase/purchase-validation.model';
import {SubscriptionModel} from '../../../../../core/models/payment/subscriptions/subscription.model';
import {AccountModel} from '../../../../../core/models/accounts/account.model';
import {ModalDataModel} from '../../../../../core/models/modals/modal-data.model';
import {AppSettingsModel} from '../../../../../core/models/app-settings/app-settings.model';

import {DURATIONS} from '../../../../../core/models/payment/subscriptions/constants';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PurchaseComponent implements OnInit, OnDestroy {
  public plansGroups: PlanModel[][] = [[]];
  public plans: PlanModel[] = [];
  public validation: PurchaseValidationModel;

  public account: AccountModel;
  public accountSummary: AccountSummaryModel;

  public currentSubscription: SubscriptionModel = null;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  public currentDuration: string = DURATIONS.ONE_YEAR;

  public appSettings: AppSettingsModel;

  public isLoading: boolean = true;
  public isFullCardsDescription: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get autoRenewsWords(): string {
    if (!this.currentSubscription) return '';

    return this.currentSubscription.isCustom || this.currentSubscription.isCancelledAtPeriodEnd ? 'Expires On' : 'Auto Renews';
  }

  public get autoRenews(): string {
    return this.currentSubscription ? this.currentSubscription.periodEndsAt : '';
  }

  public get DURATIONS(): {
    ONE_DAY: string,
    ONE_MONTH: string,
    THREE_MONTHS: string,
    ONE_YEAR: string,
  } {
    return DURATIONS;
  }

  public get isTrial(): boolean {
    return this.currentSubscription && this.currentSubscription.isTrial;
  }

  constructor(private plansService: PlansService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private upgradeSubscriptionModalService: UpgradeSubscriptionModalService,
              private modalsService: ModalsService,
              private authService: AuthService,
              private utilsService: UtilsService,
              private sidebarSectionsService: SidebarSectionsService,
              private cartService: CartService,
              private navigationService: NavigationService,
              private purchaseValidationModalService: PurchaseValidationModalService,
              private comparePlansModalService: ComparePlansModalService,
              private appSettingsService: AppSettingsService,
              private cdr: ChangeDetectorRef) {
    this.onResize = this.utilsService.debounce(this.onResize.bind(this), 50);
  }

  public ngOnInit(): void {
    window.addEventListener('resize', this.onResize);

    this.onResize();

    this.appSettingsService.settingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((settings: AppSettingsModel) => {
      this.appSettings = settings;
    });

    this.plansService.plansSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((plans: PlanModel[]) => {
      this.plans = plans;

      if (!plans) return;

      this.handlePlans();
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.authService.accountSummarySubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((accountSummary: AccountSummaryModel) => !!accountSummary)
    ).subscribe((accountSummary: AccountSummaryModel) => {
      this.accountSummary = accountSummary;

      this.isLoading = false;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      if (!subscription) return;

      this.handlePlans();
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });

    this.isLoading = true;

    this.authService.fetchAccountSummary();
  }

  private handlePlans(): void {
    if (!this.plans) return;

    this.plans.forEach((plan: PlanModel) => {
      plan.isCurrent = this.getIsCurrent(plan);
    });

    this.plansGroups = this.plans.reduce((res: PlanModel[][], plan: PlanModel) => {
      if (res[res.length - 1].length === 4) res.push([]);

      res[res.length - 1].push(plan);

      return res;
    }, [[]]);
  }

  private getIsCurrent(plan: PlanModel): boolean {
    if (!this.currentSubscription || this.currentSubscription.isExpired || this.currentSubscription.isTrial || this.currentSubscription.isTrialEnded) return false;

    return this.currentSubscription.metadata.planId === plan.id && this.currentSubscription.metadata.duration === this.currentDuration;
  }

  private onResize(): void {
    if (window.innerWidth > 1550) return;

    this.sidebarSectionsService.isSectionsCollapsedSubject.next(true);
  }

  public onSignUp(planData: { plan: PlanModel }) {
    if (this.isLoading) {
      return;
    }

    const planToPurchase: PlanModel = this.plans.find(p => p.id === planData.plan.id);

    this.validation = this.paymentSubscriptionsService.validate(planToPurchase);

    if (!this.validation.isValid) {
      return this.purchaseValidationModalService.open({
        plan: planToPurchase,
        validation: this.validation,
      });
    }

    this.paymentSubscriptionsService.signUp(planToPurchase, this.currentDuration).add(() => {
      this.upgradeSubscriptionModalService.isPlanPurchaseSubject.next(true);

      this.navigationService.toAddOns();
    });
  }

  public selectDuration(key: string): void {
    this.currentDuration = key;
  }

  public toggleCardDescription(value: boolean): void {
    this.isFullCardsDescription = value;
  }

  public openAnnualView(): void {
    this.selectDuration(DURATIONS.ONE_YEAR);
  }

  public handlePlanHasLabel(plan: PlanModel): void {

  }

  public openComparePlansModal(): void {
    this.comparePlansModalService.open();
  }

  public ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
