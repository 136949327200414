import {Component} from '@angular/core';

import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';

@Component({
  selector: 'app-fonts-manager-modal',
  templateUrl: './fonts-manager-modal.component.html',
  styleUrls: ['./fonts-manager-modal.component.scss']
})
export class FontsManagerModalComponent {
  public get modalId(): string {
    return this.googleFontsService.modalId;
  }

  constructor(private googleFontsService: GoogleFontsService) {
  }

  public onOpen({ isOpenedFromAdminPanel }) {
    this.googleFontsService.isOpenedFromAdminPanel.next(isOpenedFromAdminPanel);
  }

  public onClose() {
    this.googleFontsService.closeModal();
  }
}
