import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/title/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/title/mobile/constants';

export const PROPERTY_NAMES = {
  [DESKTOP_KEYS.SCALABLE_FONT_SIZE]: {
    OPTION: '--scalable-title-font-size',
    OPTION_PX: '--scalable-title-font-size-px',
  },
  [MOBILE_KEYS.SCALABLE_MOBILE_FONT_SIZE]: {
    OPTION: '--scalable-mobile-title-font-size',
    OPTION_PX: '--scalable-mobile-title-font-size-px',
  },
};

export const SCROLLED_PROPERTY_NAME = '--scalable-mobile-title-scrolled-font-size';
export const SCROLLED_PROPERTY_NAME_PX = '--scalable-mobile-title-scrolled-font-size-px';
export const MIN_SCROLLABLE_VALUE = 25;
