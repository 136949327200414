import {IStyleOption} from '../option/i-style-option';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {ColorModel} from '../../color/color.model';

import {BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB, ATTRIBUTES} from './constants';

export class ThumbDrawerBackgroundColorModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !options.block) {
      return;
    }

    this.element = options.block;

    this.reInit();
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) {
      return;
    }

    this.element.style.removeProperty(CUSTOM_BACKGROUND_COLOR);
    this.element.style.removeProperty(CUSTOM_BACKGROUND_COLOR_RGB);

    this.reInit();

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR, this.value);

    const colorModel: ColorModel = new ColorModel(this.value);

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR_RGB, colorModel.getRGBstring());

    this.element.setAttribute(ATTRIBUTES.THUMB_DRAWER_BACKGROUND_SET, 'false');
  }

  public reInit(): void {
    if (!this.isEnabled) {
      return;
    }

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const currentValue: string = computedStyles.getPropertyValue(CUSTOM_BACKGROUND_COLOR_RGB);

    if (currentValue) {
      this.value = `rgb(${currentValue})`;

      return;
    }

    this.value = computedStyles.getPropertyValue(BACKGROUND_COLOR);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) {
      return;
    }

    this.value = value;

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR, value);

    const colorModel: ColorModel = new ColorModel(value);

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR_RGB, colorModel.getRGBstring());

    this.element.setAttribute(ATTRIBUTES.THUMB_DRAWER_BACKGROUND_SET, 'true');
  }
}
