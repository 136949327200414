import {PreLaunchProgressItemModel} from './pre-launch-progress-item.model';

export class PreLaunchProgressItemDto {
  constructor(public Id?: number,
              public Key?: string,
              public OrderNo?: number) {}

  public static normalize(res: PreLaunchProgressItemDto): PreLaunchProgressItemModel {
    if (!res) return null;

    return new PreLaunchProgressItemModel(
      res.Id,
      res.Key,
      res.OrderNo,
    );
  }
}
