import {Component} from '@angular/core';

import {AppAnimations} from "../../../../../app-animations";

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class DomainsComponent {
  constructor() { }
}
