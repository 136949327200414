import {Component} from '@angular/core';

@Component({
  selector: 'app-vs-sidebar-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class SidebarMenuComponent {
  // todo
}
