import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

import {Subject} from 'rxjs';

import {AdminEducationClassesService} from '../../../../../../core/services/admin/education/classes/admin-education-classes.service';

import {EducationClassModel} from '../../../../../../core/models/education/classes/education-class.model';

@Component({
  selector: 'app-admin-education-delete-class-modal',
  templateUrl: './delete-class-modal.component.html',
  styleUrls: ['./delete-class-modal.component.scss']
})
export class AdminEducationDeleteClassModalComponent implements OnDestroy {
  @Input() classItem: EducationClassModel;

  @Output() closeHandler = new EventEmitter<void>();

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationClassesService) {
  }

  public submit(): void {
    this.service.remove(this.classItem);

    this.closeHandler.emit();
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
