import {AccountLimitsModel} from './account-limits.model';

export class AccountLimitsDto {
  constructor(public Websites?: number,
              public Pages?: number,
              public Portfolios?: number,
              public ImagesPrices?: number) {}

  public static normalize(res: AccountLimitsDto): AccountLimitsModel {
    if (!res) return null;

    return new AccountLimitsModel(
      res.Websites,
      res.Pages,
      res.Portfolios,
      res.ImagesPrices,
    );
  }
}
