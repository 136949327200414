export class NodeModel {
  public children?: NodeModel[] = [];

  public tourGroupKey?: string = null;
  public tourKey?: string = null;
  public tourLocation?: string = null;

  public newSequence: number;
  public newLevel: number;
  
  constructor(
    public id?: number,
    public nodeId?: number,
    public nodeLevel?: number,
    public nodeSequence?: number,
    public nodeLink?: string,
    public title?: string,
    public type?: string,
    public typeLong?: string,
    public websiteId?: number,
    public directLink?: string,
    public directLinkInNewTab?: boolean,
    public hasSplash?: boolean,
    public showSplash?: boolean,
    public qty?: number,
    public totalCount?: number,
    public groupType?: string,
    public isDesktopPageVisible?: boolean,
    public isMobilePageVisible?: boolean,
    public isPrivate?: boolean,
    public isPageExists?: boolean,
    public isHomePage?: boolean,
    public isTemporaryRemoved?: boolean,
    public isShared?: boolean,
  ) {}

  public isSameTo(other: NodeModel): boolean {
    return !!other
      && this.id === other.id
      && this.nodeId === other.nodeId
      && this.nodeLevel === other.nodeLevel
      && this.nodeSequence === other.nodeSequence
      && this.nodeLink === other.nodeLink
      && this.title === other.title
      && this.type === other.type
      && this.typeLong === other.typeLong
      && this.websiteId === other.websiteId
      && this.directLink === other.directLink
      && this.directLinkInNewTab === other.directLinkInNewTab
      && this.hasSplash === other.hasSplash
      && this.showSplash === other.showSplash
      && this.qty === other.qty
      && this.totalCount === other.totalCount
      && this.groupType === other.groupType
      && this.isDesktopPageVisible === other.isDesktopPageVisible
      && this.isMobilePageVisible === other.isMobilePageVisible
      && this.isPrivate === other.isPrivate
      && this.isPageExists === other.isPageExists
      && this.isHomePage === other.isHomePage
      && this.isTemporaryRemoved === other.isTemporaryRemoved
      && this.isShared === other.isShared;
  }

  public isSameToIgnoreQty(other: NodeModel): boolean {
    return !!other
      && this.id === other.id
      && this.nodeId === other.nodeId
      && this.nodeLevel === other.nodeLevel
      && this.nodeSequence === other.nodeSequence
      && this.nodeLink === other.nodeLink
      && this.title === other.title
      && this.type === other.type
      && this.typeLong === other.typeLong
      && this.websiteId === other.websiteId
      && this.directLink === other.directLink
      && this.directLinkInNewTab === other.directLinkInNewTab
      && this.hasSplash === other.hasSplash
      && this.showSplash === other.showSplash
      //&& this.qty === other.qty
      //&& this.totalCount === other.totalCount
      && this.groupType === other.groupType
      && this.isDesktopPageVisible === other.isDesktopPageVisible
      && this.isMobilePageVisible === other.isMobilePageVisible
      && this.isPrivate === other.isPrivate
      && this.isPageExists === other.isPageExists
      && this.isHomePage === other.isHomePage
      && this.isTemporaryRemoved === other.isTemporaryRemoved
      && this.isShared === other.isShared;
  }

  public static normalize(res: NodeModel): NodeModel {
    if (!res) {
      return null;
    }

    const node: NodeModel = new NodeModel(
      res.id,
      res.nodeId,
      res.nodeLevel,
      res.nodeSequence,
      res.nodeLink,
      res.title,
      res.type,
      res.typeLong,
      res.websiteId,
      res.directLink,
      res.directLinkInNewTab,
      res.hasSplash,
      res.showSplash,
      res.qty,
      res.totalCount,
      res.groupType,
      res.isDesktopPageVisible,
      res.isMobilePageVisible,
      res.isPrivate,
      res.isPageExists,
      res.isHomePage,
      res.isTemporaryRemoved,
      res.isShared,
    );
    
    node.tourGroupKey = res.tourGroupKey;
    node.tourKey = res.tourKey;
    node.tourLocation = res.tourLocation;

    return node;
  }
}
