import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-thumbnail-positioning',
  templateUrl: './thumbnail-positioning.component.html',
  styleUrls: ['./thumbnail-positioning.component.scss'],
})
export class ThumbnailPositioningComponent implements OnChanges {
  @Input() src = '';
  @Input() value = '';

  @Output() changeHandler: EventEmitter<string> = new EventEmitter<string>();

  public isMouseDown: boolean = false;

  private width: number = 50;
  private height: number = 50;

  private startX: number = 0;
  private startY: number = 0;

  public get objectPosition(): string {
    return `${this.width}% ${this.height}%`;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      const items = changes.value.currentValue.trim().split(' ');

      const width = Number.parseFloat(items[0]);
      const height = Number.parseFloat(items[1]);

      this.width = Number.isNaN(width) ? 50 : width;
      this.height = Number.isNaN(height) ? 50 : height;
    }
  }

  public onMouseDown(e: MouseEvent): void {
    this.isMouseDown = true;

    this.startX = e.x;
    this.startY = e.y;
  }

  public onMouseMove(e: MouseEvent): void {
    this.isMouseDown = e.buttons !== 0;

    if (!this.isMouseDown) return;

    const target: HTMLElement = <HTMLElement>e.target;

    const diffX = -(this.startX - e.x);
    const diffY = -(this.startY - e.y);

    const xTemp = Math.max(this.width - diffX / target.clientWidth * 100, 0);
    const yTemp = Math.max(this.height - diffY / target.clientHeight * 100, 0);

    this.width = xTemp > 100 ? 100 : xTemp;
    this.height = yTemp > 100 ? 100 : yTemp;

    this.cdr.detectChanges();

    this.startX = e.x;
    this.startY = e.y;

    this.changeHandler.emit(this.objectPosition);
  }

  public onMouseUp(): void {
    this.isMouseDown = false;
  }
}
