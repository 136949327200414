import {IStyleModelOptions} from '../../option/i-style-model-options';

import {SetupOptionModel} from '../../option/setup-option.model';

export class AttributeBoolModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public attribute: string,
    public value?: boolean,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = this.getAttributeValue();
  }

  public onChange(value: boolean): void {
    this.value = value;

    if (!this.isEnabled) return;

    this.element.setAttribute(this.attribute, `${this.value}`);
  }

  private getAttributeValue(): boolean {
    return this.element.getAttribute(this.attribute) === 'true';
  }
}
