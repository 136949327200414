import {IStyleOption} from '../../option/i-style-option';

import {MenuStyleModel} from '../style/menu-style.model';
import {HamburgerColorModel} from '../hamburger-color/hamburger-color.model';
import {ActiveColorModel} from '../active-color/active-color-model';
import {HoverColorModel} from '../hover-color/hover-color.model';
import {TextDecorationModel} from '../text-decoration/links-text-decoration.model';
import {BackgroundColorModel} from '../background-color/background-color.model';
import {BackgroundOpacityModel} from '../background-opacity/background-opacity.model';
import {HamburgerTooltipModel} from '../hamburger-tooltip/hamburger-tooltip.model';
import {MenuPaddingTopModel} from '../menu-blocks-padding/menu-blocks-padding.model';
import {MenuDropdownItemOpacityModel} from '../dropdown-item-opacity/menu-dropdown-item-opacity.model';
import {MenuDropdownItemHoverDesignModel} from '../dropdown-item-hover-design/menu-dropdown-item-hover-design.model';
import {MenuDropdownItemHoverBackgroundModel} from '../dropdown-item-hover-background/menu-dropdown-item-hover-background.model';
import {MenuDropdownItemHoverColorModel} from '../dropdown-item-hover-color/menu-dropdown-item-hover-color.model';
import {MenuDropdownItemHoverOpacityModel} from '../dropdown-item-hover-opacity/menu-dropdown-item-hover-opacity.model';
import {MenuVerticalPaddingModel} from '../vertical-padding/menu-vertical-padding.model';
import {MenuDropdownArrowModel} from '../dropdown-arrow/dropdown-arrow.model';
import {MenuDropdownArrowPaddingModel} from '../dropdown-arrow-padding/menu-dropdown-arrow-padding.model';
import {MenuDropdownTextAlignmentModel} from '../dropdown-text-alignment/dropdown-text-alignment.model';
import {CollapseOptionsModel} from '../collapse-options/collapse-options.model';
import {MenuPaddingModel} from '../padding/menu-padding.model';
import {MenuLogoPaddingModel} from '../logo-padding/menu-logo-padding.model';
import {IsMenuTransparentOnHomePageModel} from '../is-menu-transparent-on-home-page/is-menu-transparent-on-home-page.model';
import {IsLogoVisibleModel} from '../is-logo-visible/is-logo-visible.model';
import {IsSizeReducedOnScrollModel} from '../is-size-reduced-on-scroll/is-size-reduced-on-scroll.model';

import {KEYS, TYPES} from '../../../../../services/styles/custom-menu/constants';
import {GROUPS as COLLAPSE_OPTIONS_GROUPS} from '../collapse-options/constants';
import {VerticalSpacingModel} from '../../vertical-spacing/vertical-spacing.model';

export class MenuSetupModel {
  public menu: HTMLElement;

  public style: MenuStyleModel = new MenuStyleModel(KEYS.MENU_STYLE, TYPES.CLASS);
  public hamburgerColor: HamburgerColorModel = new HamburgerColorModel(KEYS.HAMBURGER_COLOR, TYPES.STYLE_PROPERTY);
  public backgroundColor: BackgroundColorModel = new BackgroundColorModel(KEYS.MENU_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public backgroundOpacity: BackgroundOpacityModel = new BackgroundOpacityModel(KEYS.MENU_BACKGROUND_OPACITY, TYPES.STYLE_PROPERTY);
  public hoverColor: HoverColorModel = new HoverColorModel(KEYS.HOVER_COLOR, TYPES.STYLE_PROPERTY);
  public activeColor: ActiveColorModel = new ActiveColorModel(KEYS.ACTIVE_COLOR, TYPES.STYLE_PROPERTY);
  public textDecoration: TextDecorationModel = new TextDecorationModel(KEYS.LINKS_TEXT_DECORATION, TYPES.STYLE_PROPERTY);
  public hamburgerTooltip: HamburgerTooltipModel = new HamburgerTooltipModel(KEYS.HAMBURGER_TOOLTIP, TYPES.ATTRIBUTE);
  public menuPaddingTop: MenuPaddingTopModel = new MenuPaddingTopModel(KEYS.MENU_PADDING_TOP, TYPES.STYLE_PROPERTY);
  public dropdownItemOpacity: MenuDropdownItemOpacityModel = new MenuDropdownItemOpacityModel(KEYS.MENU_DROPDOWN_ITEM_OPACITY, TYPES.STYLE_PROPERTY);
  public dropdownItemHoverDesign: MenuDropdownItemHoverDesignModel = new MenuDropdownItemHoverDesignModel(KEYS.MENU_DROPDOWN_ITEM_HOVER_DESIGN, TYPES.CLASS);
  public dropdownItemHoverBackground: MenuDropdownItemHoverBackgroundModel = new MenuDropdownItemHoverBackgroundModel(KEYS.MENU_DROPDOWN_ITEM_HOVER_BACKGROUND, TYPES.STYLE_PROPERTY);
  public dropdownItemHoverColor: MenuDropdownItemHoverColorModel = new MenuDropdownItemHoverColorModel(KEYS.MENU_DROPDOWN_ITEM_HOVER_COLOR, TYPES.STYLE_PROPERTY);
  public dropdownItemHoverOpacity: MenuDropdownItemHoverOpacityModel = new MenuDropdownItemHoverOpacityModel(KEYS.MENU_DROPDOWN_ITEM_HOVER_OPACITY, TYPES.STYLE_PROPERTY);
  public verticalPadding: MenuVerticalPaddingModel = new MenuVerticalPaddingModel(KEYS.VERTICAL_PADDING, TYPES.STYLE_PROPERTY);
  public dropdownArrow: MenuDropdownArrowModel = new MenuDropdownArrowModel(KEYS.MENU_DROPDOWN_ARROW, TYPES.CLASS);
  public dropdownArrowPadding: MenuDropdownArrowPaddingModel = new MenuDropdownArrowPaddingModel(KEYS.MENU_DROPDOWN_ARROW_PADDING, TYPES.STYLE_PROPERTY);
  public dropdownTextAlignment: MenuDropdownTextAlignmentModel = new MenuDropdownTextAlignmentModel(KEYS.MENU_DROPDOWN_TEXT_ALIGNMENT, TYPES.STYLE_PROPERTY);
  public collapseOptions: CollapseOptionsModel = new CollapseOptionsModel(KEYS.COLLAPSE_OPTIONS, TYPES.CLASS, COLLAPSE_OPTIONS_GROUPS.DESKTOP);
  public padding: MenuPaddingModel = new MenuPaddingModel(KEYS.MENU_PADDING, TYPES.STYLE_PROPERTY);
  public logoPadding: MenuLogoPaddingModel = new MenuLogoPaddingModel(KEYS.MENU_LOGO_PADDING, TYPES.STYLE_PROPERTY);
  public verticalSpacing: VerticalSpacingModel = new VerticalSpacingModel(KEYS.VERTICAL_SPACING, TYPES.STYLE_PROPERTY);
  public isMenuTransparentOnHomePage: IsMenuTransparentOnHomePageModel = new IsMenuTransparentOnHomePageModel(KEYS.IS_MENU_TRANSPARENT_ON_HOME_PAGE, TYPES.CLASS);
  public isLogoVisible: IsLogoVisibleModel = new IsLogoVisibleModel(KEYS.IS_LOGO_VISIBLE, TYPES.CLASS);
  public isSizeReducedOnScroll: IsSizeReducedOnScrollModel = new IsSizeReducedOnScrollModel(KEYS.IS_SIZE_REDUCED_ON_SCROLL, TYPES.CLASS);

  public options: IStyleOption[] = [
    this.style,
    this.backgroundColor,
    this.backgroundOpacity,
    this.hamburgerColor,
    this.hoverColor,
    this.activeColor,
    this.textDecoration,
    this.hamburgerTooltip,
    this.menuPaddingTop,
    this.dropdownItemOpacity,
    this.dropdownItemHoverDesign,
    this.dropdownItemHoverBackground,
    this.dropdownItemHoverColor,
    this.dropdownItemHoverOpacity,
    this.verticalPadding,
    this.dropdownArrow,
    this.dropdownArrowPadding,
    this.dropdownTextAlignment,
    this.collapseOptions,
    this.padding,
    this.logoPadding,
    this.verticalSpacing,
    this.isMenuTransparentOnHomePage,
    this.isLogoVisible,
    this.isSizeReducedOnScroll,
  ];

  private readonly handlers = {};

  private initHandlers = {
    [TYPES.CLASS]: this.initClass.bind(this),
    [TYPES.STYLE_PROPERTY]: this.initStyleProperty.bind(this),
    [TYPES.ATTRIBUTE]: this.initAttribute.bind(this),
  };

  constructor({ handlers }) {
    this.handlers = handlers;
  }

  public init({ element, device }: { element: HTMLElement, device: string }) {
    this.menu = element;

    if (!this.menu) return;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(element);

    this.options.forEach(option => {
      option.onChange = this.handlers[option.key] ? this.handlers[option.key] : option.onChange;

      this.initHandlers[option.type]({
        option,
        device,
        innerWrapper,
        block,
      });
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.matches('[data-menu-block]') ? element : <HTMLElement>element.closest('[data-menu-block]');
  }

  private initClass({ option, device, innerWrapper, block }: {
    option: IStyleOption,
    device: string,
    innerWrapper: HTMLElement,
    block: HTMLElement,
  }) {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.menu, {
      device,
      innerWrapper,
      block,
    });
  }

  private initStyleProperty({ option, device, innerWrapper, block }: {
    option: IStyleOption,
    device: string,
    innerWrapper: HTMLElement,
    block: HTMLElement,
  }) {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.menu, {
      device,
      innerWrapper,
      block,
    });
  }

  private initAttribute({ option, device, innerWrapper, block }: {
    option: IStyleOption,
    device: string,
    innerWrapper: HTMLElement,
    block: HTMLElement,
  }) {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.menu, {
      device,
      innerWrapper,
      block,
    });
  }
}
