import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';

import {Observable, Subscriber, BehaviorSubject, forkJoin, catchError, throwError} from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import {VideosHttpService} from '../interaction/http/videos/videos-http.service';
import {S3HttpService} from '../interaction/http/s3/s3-http.service';
import {VideoThumbnailsService} from './thumnails/video-thumbnails.service';
import {EventsService} from '../interaction/events/events.service';
import {MediaManagerService} from '../media-manager/media-manager.service';
import {PermissionsService} from '../service-permissions/permissions/permissions.service';

import {VideoModel} from '../../models/videos/video.model';
import {VideoUploadUrlsModel} from '../../models/videos/video-upload-urls.model';

import {MIME} from './constants';
import {SECTIONS_KEYS} from '../media-manager/constants';

@Injectable()
export class VideoManagerService {
  public modalId: string = 'video-manager-modal';

  public videosSubject: BehaviorSubject<VideoModel[]> = new BehaviorSubject<VideoModel[]>(null);
  public uploadVideosSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>(null);
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public selectedElement: HTMLVideoElement = null;

  public videos(): VideoModel[] {
    return this.videosSubject.value;
  }

  public get mime(): string {
    return MIME;
  }

  constructor(
    private http: HttpClient,
    private httpService: VideosHttpService,
    private s3HttpService: S3HttpService,
    private thumbnailsService: VideoThumbnailsService,
    private eventsService: EventsService,
    private permissionsService: PermissionsService,
    private mediaManagerService: MediaManagerService,
  ) {
    this.eventsService.addFrameListener('uploadVideo', this.onVideoUpload.bind(this));
    this.eventsService.addFrameListener('openVideoManager', this.open.bind(this));

    this.permissionsService.beforeLeaveSubject.subscribe(() => {
      this.close();
    });
  }

  private onVideoUpload(e: CustomEvent): void {
    const files: File[] = Array.from(<FileList>e.detail.files);

    this.open();

    this.uploadVideosSubject.next(files);
  }

  public open(e?: CustomEvent): void {
    this.selectedElement = !!e && !!e.detail ? e.detail.element : null;

    this.mediaManagerService.isSectionsDisabledSubject.next(true);

    this.mediaManagerService.selectSection(SECTIONS_KEYS.VIDEOS);

    this.mediaManagerService.openModal();
  }

  public loadVideos(): void {
    this.isLoadingSubject.next(true);

    this.httpService.fetch().subscribe((videos: VideoModel[]) => {
      this.videosSubject.next(videos);
      this.isLoadingSubject.next(false);
    });
  }

  public upload(file: File, video: VideoModel): Observable<VideoModel> {
    const extension = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${extension}`;
    const thumbName = `${uuidv4()}.jpg`;

    return new Observable((observer: Subscriber<VideoModel>) => {
      this.thumbnailsService.getThumb(file).subscribe((thumb: Blob) => {
        this.getUploadUrls(fileName, thumbName, file, thumb, video).pipe(
          catchError(e => {
            return throwError(() => e);
          })
        ).subscribe((urls: VideoUploadUrlsModel) => {
          video.id = urls && urls.video ? urls.video.id : null;

          if (video.isDeleting) {
            return observer.next(null);
          }

          video.percent = '0%';

          this.uploadHandler(urls, file, thumb, video).pipe(
            catchError(e => {
              return throwError(() => e);
            })
          ).subscribe(() => {
            if (video.isDeleting) {
              return this.httpService.deleteMany([video.id]).subscribe(() => observer.next(null));
            }

            this.httpService.finishUploading(video, fileName).pipe(
              catchError(e => {
                return throwError(() => e);
              })
            ).subscribe((res: VideoModel) => {
              observer.next(res);
              observer.complete();
            });
          });
        });
      });
    });
  }

  private uploadHandler(urls: VideoUploadUrlsModel, file: File, thumb: Blob, video: VideoModel): Observable<any> {
    return forkJoin([
      this.uploadVideoHandler(urls.videoUrl, file, video),
      this.uploadThumb(urls.thumbUrl, thumb),
    ]);
  }

  private uploadVideoHandler(url: string, file: File, video: VideoModel): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      this.uploadVideo(url, file).pipe(
        catchError(e => {
          return throwError(() => e);
        })
      ).subscribe((event: { type: number, loaded: number, total: number }) => {
        if (event.type === HttpEventType.UploadProgress) {
          video.percent = `${Math.round(100 / event.total * event.loaded)}%`;
        }

        if (event.type === HttpEventType.Response) {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  private uploadVideo(url: string, file: File): Observable<any> {
    const videoHeaders = new HttpHeaders().set('Content-Type', file.type);

    return this.http.put(url, file, { headers: videoHeaders, reportProgress: true, observe: 'events', responseType: 'text' });
  }

  private uploadThumb(url: string, thumb: Blob): Observable<any> {
    const thumbHeaders = new HttpHeaders().set('Content-Type', thumb.type);

    return this.http.put(url, thumb, { headers: thumbHeaders, responseType: 'text' });
  }

  private getUploadUrls(fileName: string, thumbName: string, file: File, thumb: Blob, video: VideoModel): Observable<VideoUploadUrlsModel> {
    return this.s3HttpService.getVideoUploadUrl(file.name, fileName, thumbName, file.type, thumb.type, video.duration, video.fileSize);
  }

  public deleteMany(ids: number[]): Observable<any> {
    return this.httpService.deleteMany(ids);
  }

  public cancelUploading(video: VideoModel): void {
    video.isUploading = true;
    video.isDeleting = true;
  }

  public close(): void {
    this.selectedElement = null;

    this.uploadVideosSubject.next(null);
    this.mediaManagerService.closeModal();
  }

  public updateName(id: number, name: string): Observable<any> {
    return this.httpService.updateName(id, name);
  }
}
