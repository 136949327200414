import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class PageLockAlertModalService {
  public readonly id: string = 'page-lock-alert-modal';

  public isOpened: boolean = false;

  public constructor(
    private modalsService: ModalsService,
  ) {
  }

  public open({ onConfirm }): void {
    this.modalsService.open(this.id, { onConfirm });

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
