import {Component, Output, EventEmitter, OnDestroy, HostListener} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {NodesService} from '../../../../../core/services/nodes/nodes.service';

import {Link} from '../../../../../core/models/common/link/link.model';
import {NodeModel} from '../../../../../core/models/nodes/node.model';

export const KEY_CODES = {
  ENTER: 13,
  ESC: 27,
};

@Component({
  selector: 'app-link-dropdown',
  templateUrl: './link-dropdown.component.html',
  styleUrls: ['./link-dropdown.component.scss'],
})
export class LinkDropdownComponent implements OnDestroy {
  @Output() selectHandler = new EventEmitter();
  @Output() closeHandler = new EventEmitter();

  public customLink: string = '';
  public portfolioLinks: Link[] = [];

  private ngUnsubscribe = new Subject<void>();

  private keyHandlers = {
    [KEY_CODES.ENTER]: this.onEnter.bind(this),
    [KEY_CODES.ESC]: this.onEsc.bind(this),
  };

  constructor(private nodesService: NodesService) {
    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      const portfolios: NodeModel[] = nodes.filter(node => node.type === 'P');

      this.portfolioLinks = portfolios.map((p: NodeModel) => new Link(`/${p.nodeLink}/`, p.title));
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (!this.keyHandlers[e.keyCode]) return;

    this.keyHandlers[e.keyCode]();
  }

  private onEnter(): void {
    this.onApply();
  }

  private onEsc(): void {
    this.closeHandler.emit();
  }

  public onApply(): void {
    this.selectHandler.emit({ uri: this.customLink, isCustom: true });
  }

  public onPortfolioApply(link: Link): void {
    this.selectHandler.emit({ uri: link.uri, isCustom: false });
  }

  @HostListener('document:mousedown', ['$event'])
  private onMouseDown(e: MouseEvent) {
    const target = <HTMLElement>e.target;

    if (target.closest('.link-dropdown')) return;

    return this.closeHandler.emit();
  }
}
