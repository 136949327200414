export const SETUP_STAGES = {
  INTRO: 'intro',
  WEBSITE_SETTINGS: 'website-settings',
  SELECT_TEMPLATE: 'select-template',
  UPLOAD_IMAGES: 'upload-images',
};

export const SETUP_STAGES_LIST = Object.keys(SETUP_STAGES).map(key => SETUP_STAGES[key]);

export const REDIRECT_URLS = {
  [SETUP_STAGES.INTRO]: '/setup/introduction',
  [SETUP_STAGES.WEBSITE_SETTINGS]: '/setup/website-settings',
  [SETUP_STAGES.UPLOAD_IMAGES]: '/setup/upload-images',
  [SETUP_STAGES.SELECT_TEMPLATE]: '/setup/select-template',
};
