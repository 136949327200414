import {Injectable} from '@angular/core';

import {GoogleFontsService} from '../../google/fonts/google-fonts.service';
import {NativeFontsStylesService} from './fonts/native-fonts-styles.service';

import {SelectOption} from '../../../models/select/option/option.model';
import {NativeSetupModel} from '../../../models/styles/native-setup/native-setup.model';
import {StyleOptionModel} from '../../../models/styles/settings/option/style-option.model';
import {GoogleFontModel} from '../../../models/google/fonts/google-font.model';

@Injectable()
export class NativeStylesService {
  public setup: NativeSetupModel;

  public get element(): HTMLElement {
    return this.setup.element;
  }

  public get fontWeightsOptions(): SelectOption[] {
    return this.fontsService.fontWeightsOptions;
  }

  constructor(private googleFontsService: GoogleFontsService,
              private fontsService: NativeFontsStylesService) {
    this.setup = new NativeSetupModel();
  }

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.setup.init(element, enabledStyleOptions);

    this.fontsService.init(this.setup);
  }

  public initFontWeight(): void {
    this.fontsService.initFontWeight();
  }

  public onFontFamilyChange(font: GoogleFontModel) {
    this.fontsService.onFontFamilyChange(font);
  }
}
