export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `Κοιτούσα την καταιγίδα… τόσο όμορφη αλλά αμείλικτη συνάμα.`,
      `Μια ασημένια ομίχλη εξαπλώθηκε στο κατάστρωμα του πλοίου.`,
      `Οι δύο φύσεις μου είχαν κοινές μνήμες.`,
      `Μια κόκκινη μορφή εμφανίστηκε στο μυτερό άκρο ενός φτερού.`,
      `Σχεδόν πριν το καταλάβουμε, είχαμε απογειωθεί.`,
    ],
    [KEYS.ALPHABET]: [
      'ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψω ‘?’“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `Θα δω όμως την όψη του Άρη, κι αυτή η εμπειρία θα είναι μοναδική. Πιστεύω ότι η παρατήρηση ουράνιων σωμάτων μέσω ειδικού τηλεσκοπίου, καθώς και μια περιήγηση σε ολόκληρο τον κόσμο, θα έπρεπε να είναι μέρος της φιλελεύθερης εκπαίδευσής μας.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
