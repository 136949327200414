import {Injectable} from '@angular/core';

import {Observable, Subscription, BehaviorSubject} from 'rxjs';

import {EducationTeachersStudentsHttpService} from '../../../../../interaction/http/education/teachers/institutions/classes/students/education-students-http.service';

import {EducationStudentModel} from '../../../../../../models/education/students/education-student.model';
import {PortfolioModel} from '../../../../../../models/portfolios/portfolio.model';

@Injectable()
export class EducationTeachersStudentsService {
  public studentDetailsSubject: BehaviorSubject<EducationStudentModel> = new BehaviorSubject<EducationStudentModel>(null);

  constructor(private httpService: EducationTeachersStudentsHttpService) {
  }

  public fetchOne(institutionId: number, classId: number, studentId: number): Subscription {
    return this.httpService.getOne(institutionId, classId, studentId).subscribe((res: EducationStudentModel) => {
      this.studentDetailsSubject.next(res);
    });
  }

  public fetchPortfolioConters(institutionId: number, classId: number, studentId: number, portfolioId: number): Observable<PortfolioModel> {
    return this.httpService.getPortfolioConters(institutionId, classId, studentId, portfolioId);
  }

  public remove(institutionId: number, classId: number, studentId: number): Subscription {
    return this.httpService.remove(institutionId, classId, studentId).subscribe(() => {});
  }
}
