import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, Subscriber, catchError, map, mergeMap, of, tap, throwError} from 'rxjs';

import {AuthService} from '../../../../auth/auth.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';

import {NodeModel} from '../../../../core/models/nodes/node.model';
import { NodeMoveDto } from '../../../../core/models/nodes/node-move.dto';
import {NodeTreeDataModel} from '../../../../core/models/nodes/node-tree-data.model';

import {LIBRARY_ID, PAGE_NAME_REGEXP} from '../../../constants';

@Injectable()
export class PagesService {
  public pageNodesSubject: BehaviorSubject<NodeTreeDataModel[]> = new BehaviorSubject<NodeTreeDataModel[]>(null);
  selectedNodeSubject = new BehaviorSubject<any>(null);
  public parentNodeSubject: BehaviorSubject<{ id: number, groupType: string }> = new BehaviorSubject<{ id: number, groupType: string }>(null);

  public isBlogPageExists: boolean = false;

  private nodes: NodeModel[] = [];

  MAX_TITLE_LENGTH = 100;
  MORE_THAN_ONE_BLOG_ERROR = 'You already have blog page.';
  DUPLICATE_PAGE_NAME_ERROR = 'The page title is already in use.';

  public validationErrorsKeys = {
    maxLength: 'maxLength',
    inappropriateSymbols: 'inappropriateSymbols',
    required: 'required',
    TITLE_ALREADY_USED: 'TITLE_ALREADY_USED',
  };

  public validationHeaders = {
    [this.validationErrorsKeys.maxLength]: `Maximum length exceeded`,
    [this.validationErrorsKeys.inappropriateSymbols]: `Inappropriate symbols`,
    [this.validationErrorsKeys.required]: `Title is required`,
  };

  public validationMessages = {
    [this.validationErrorsKeys.maxLength]: `Maximum page length is ${this.MAX_TITLE_LENGTH} characters.`,
    [this.validationErrorsKeys.inappropriateSymbols]: `Page name can include only english letters, numbers and symbols ' - – — , . _ : \\ ( ) /`,
    [this.validationErrorsKeys.required]: `The page title is required.`,
    [this.validationErrorsKeys.TITLE_ALREADY_USED]: `The page title is already in use.`,
  };

  pageCheckMiddleware: Function[] = [
    this._checkForSimilarName.bind(this),
    this._checkForBlogExist.bind(this),
  ];

  public get PAGE_NAME_REGEXP(): RegExp {
    return PAGE_NAME_REGEXP;
  }

  private get isUserExists(): boolean {
    return !!this.authService.account;
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private nodesService: NodesService,
  ) {
    this.nodesService.nodesSubject.subscribe((nodes: NodeModel[]) => {
      this.nodes = nodes;

      this.isBlogPageExists = !!this.nodes && !!this.nodes.find(node => node.type === 'B');
    });
  }

  setSelectedNode(nodeId) {
    if (nodeId === LIBRARY_ID) {
      return this.selectedNodeSubject.next({
        nodeId: LIBRARY_ID,
        id: LIBRARY_ID,
        nodeLevel: 0,
        nodeSequence: -1,
        type: 'P',
      });
    }

    const node = this.nodes.find(n => n.nodeId === nodeId);

    this.selectedNodeSubject.next(node);
  }

  public updateNodesAndPages(): Observable<NodeTreeDataModel[]> {
    this.nodesService.fetchNodes();

    return this.getPageNodes();
  }

  public getPageNodes(): Observable<NodeTreeDataModel[]> {
    if (!this.isUserExists) {
      return of([]);
    }

    return this.httpClient.get<NodeTreeDataModel[]>('/api/app/nodes/tree').pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      tap((pageNodes: NodeTreeDataModel[]) => {
        this.pageNodesSubject.next(pageNodes);
      }),
    );
  }

  getHomePage() {
    return this.nodes.find((node: NodeModel) => node.isHomePage);
  }

  public addNode({ node, templateId }: { node: NodeModel, templateId: number }): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this.isPageValid(node).then(() => {
        this.httpClient.post(`/api/app/nodes/tree?templateId=${templateId}`, node).pipe(
          mergeMap(res => {
            return this.updateNodesAndPages().pipe(
              map(() => res)
            );
          })
        ).subscribe(res => {
          observer.next(res);
          observer.complete();
        });
      }).catch(e => {
        observer.error(e);
        observer.complete();
      });
    });
  }

  removePage(data): Observable<any> {
    return this.httpClient.delete('api/app/nodes/tree', { params: data }).pipe(
      mergeMap(res => {
        return this.updateNodesAndPages().pipe(
          map(() => res)
        );
      })
    );
  }

  public movePage(data: NodeMoveDto): Observable<any> {
    return this.httpClient.put('/api/app/nodes/tree/move', data).pipe(
      mergeMap(res => {
        return this.updateNodesAndPages().pipe(
          map(() => res)
        );
      })
    );
  }

  rearrangeTree(): Observable<any> {
    return this.httpClient.post('/api/app/nodes/tree/rearrange', {}).pipe(
      mergeMap(() => this.updateNodesAndPages())
    );
  }

  public rearrangeDoubleMenuTree(): Observable<any> {
    return this.httpClient.post('/api/app/nodes/tree/double-menu-order', {}).pipe(
      mergeMap(() => this.updateNodesAndPages())
    );
  }

  // TODO: investigate removing duplication logic on client and server side
  isPageValid(newNode: NodeModel): Promise<boolean> {
    return this.pageCheckMiddleware.reduce((res, mw) => {
      return res.then(_ => mw(newNode, this.nodes));
    }, Promise.resolve());
  }

  private _checkForSimilarName(newNode: NodeModel, nodes) {
    const siblingNodes = this.getSiblingNodes(nodes, newNode.nodeSequence);
    const duplicate = siblingNodes.find(node => {
      return node.nodeId !== newNode.nodeId &&
        node.title.trim().toLowerCase() === newNode.title.trim().toLowerCase();
    });
    return duplicate ? Promise.reject(this.DUPLICATE_PAGE_NAME_ERROR) : Promise.resolve();
  }

  private _checkForBlogExist(newNode: NodeModel, nodes) {
    if (newNode.type !== 'B') return Promise.resolve();

    const blogPage = nodes.find(node => node.type === 'B');
    return blogPage && blogPage.nodeId !== newNode.nodeId ? Promise.reject(this.MORE_THAN_ONE_BLOG_ERROR) : Promise.resolve();
  }

  getSiblingNodes(nodes, nodeSequence) {
    const parentNodeSequence = nodeSequence - 1;
    const parentNodeIdx = nodes.findIndex(node => node.nodeSequence === parentNodeSequence);
    const parentNode = nodes[parentNodeIdx];

    if (parentNode.type !== 'C') return nodes.filter(node => node.nodeLevel === parentNode.nodeLevel);

    const childNodeLevel = parentNode.nodeLevel + 1;
    const start = parentNodeIdx + 1;
    const siblingNodes = [];

    const isLast = start === nodes.length;

    if (isLast) return siblingNodes;

    const isNextNodeChildren = nodes[start].nodeLevel === childNodeLevel;

    if (!isNextNodeChildren) return siblingNodes;

    for (let i = start; i < nodes.length && nodes[i].nodeLevel === childNodeLevel; i++) {
      siblingNodes.push({ ...nodes[i] });
    }

    return siblingNodes;
  }

  public validatePageName(name): Observable<string | boolean> {
    if (name.length === 0) return throwError(() => this.validationErrorsKeys.required);
    if (name.length > this.MAX_TITLE_LENGTH) return throwError(() => this.validationErrorsKeys.maxLength);
    if (!PAGE_NAME_REGEXP.test(name)) return throwError(() => this.validationErrorsKeys.inappropriateSymbols);

    return of(true);
  }

  public getPageByNodeId(id: number): NodeModel {
    return this.nodes.find(page => page.nodeId === id);
  }

  public updateTreeNodesLock(data: NodeModel[]): Observable<any> {
    return this.httpClient.put('/api/app/nodes/tree/lock', data).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      mergeMap(res => {
        return this.updateNodesAndPages().pipe(
          map(() => res)
        );
      })
    );
  }

  public updateTreeNodesVisibility(data: NodeModel[], isNeedToUpdateNodes: boolean = true) {
    return this.httpClient.put('/api/app/nodes/tree/visibility', data).pipe(
      mergeMap((res) => {
        if (isNeedToUpdateNodes) {
          return of(res);
        }

        return this.updateNodesAndPages().pipe(
          map(() => res)
        );
      })
    );
  }

  public updateTreeNode(data) {
    return this.httpClient.put('/api/app/nodes/node/update', data);
  }

  public updatePageExistence(data: NodeModel) {
    return this.httpClient.put(`/api/app/nodes/${data.nodeId}/page-existence`, { isPageExists: data.isPageExists });
  }

  public cloneNode({ id, type, nodeKey, templateId }: { id: number, type: string, nodeKey: number, templateId: number }) {
    return this.httpClient.post(`/api/app/nodes/${id}/clone`, { id, type, nodeKey, templateId });
  }
}
