import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {IsDesktopSetupMatchedModel} from '../../website-title/is-desktop-setup-matched/is-desktop-setup-matched.model';
import {SubtitleFontSizeModel} from '../font-size/subtitle-font-size.model';
import {SubtitleLineHeightModel} from '../line-height/subtitle-line-height.model';
import {SubtitleTextColorModel} from '../text-color/subtitle-text-color.model';
import {WebsiteSubtitleScalableFontSizeModel} from '../scalable-font-size/website-subtitle-scalable-font-size.model';
import {WebsiteSubtitleScalableLineHeightModel} from '../scalable-line-height/website-subtitle-scalable-line-height.model';
import {WebsiteSubtitleScalableLetterSpacingModel} from '../scalable-letter-spacing/website-subtitle-scalable-letter-spacing.model';
import {WebsiteSubtitleScalableWordSpacingModel} from '../scalable-word-spacing/website-subtitle-scalable-word-spacing.model';

import {KEYS, TYPES} from '../../../../../services/styles/subtitle/mobile/constants';

export class MobileSubtitleSetupModel {
  public subtitle: HTMLElement;

  public isDesktopSetupMatched: IsDesktopSetupMatchedModel = new IsDesktopSetupMatchedModel(KEYS.IS_DESKTOP_SETUP_MATCHED, TYPES.ATTRIBUTE);
  public fontSize: SubtitleFontSizeModel= new SubtitleFontSizeModel(KEYS.FONT_SIZE, TYPES.STYLE_PROPERTY);
  public lineHeight: SubtitleLineHeightModel = new SubtitleLineHeightModel(KEYS.LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public textColor: SubtitleTextColorModel= new SubtitleTextColorModel(KEYS.MOBILE_TEXT_COLOR, TYPES.STYLE_PROPERTY);
  public scalableFontSize: WebsiteSubtitleScalableFontSizeModel = new WebsiteSubtitleScalableFontSizeModel(KEYS.SCALABLE_MOBILE_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public scalableLineHeight: WebsiteSubtitleScalableLineHeightModel = new WebsiteSubtitleScalableLineHeightModel(KEYS.SCALABLE_MOBILE_LINE_HEIGHT, TYPES.STYLE_PROPERTY);
  public scalableLetterSpacing: WebsiteSubtitleScalableLetterSpacingModel = new WebsiteSubtitleScalableLetterSpacingModel(KEYS.SCALABLE_MOBILE_LETTER_SPACING, TYPES.STYLE_PROPERTY);
  public scalableWordSpacing: WebsiteSubtitleScalableWordSpacingModel = new WebsiteSubtitleScalableWordSpacingModel(KEYS.SCALABLE_MOBILE_WORD_SPACING, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.isDesktopSetupMatched,
    this.fontSize,
    this.lineHeight,
    this.textColor,
    this.scalableFontSize,
    this.scalableLineHeight,
    this.scalableLetterSpacing,
    this.scalableWordSpacing,
  ];

  private initHandlers = {
    [TYPES.CLASS]: this.initClass.bind(this),
    [TYPES.STYLE_PROPERTY]: this.initStyleProperty.bind(this),
    [TYPES.ATTRIBUTE]: this.initAttribute.bind(this),
  };

  public init(subtitle: HTMLElement): void {
    this.subtitle = subtitle;

    if (!this.subtitle) return;

    const innerWrapper: HTMLElement = subtitle ? <HTMLElement>subtitle.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(subtitle);

    this.options.forEach(option => {
      this.initHandlers[option.type](option, {
        innerWrapper,
        block,
      });
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.matches('[data-menu-block]') ? element : <HTMLElement>element.closest('[data-menu-block]');
  }

  private initClass(option: IStyleOption, options: IStyleModelOptions): void {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.subtitle, options);
  }

  private initStyleProperty(option: IStyleOption, options: IStyleModelOptions): void {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.subtitle, options);
  }

  private initAttribute(option: IStyleOption, options: IStyleModelOptions): void {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.subtitle, options);
  }
}
