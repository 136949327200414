import * as moment from 'moment';

import {EducationClassModel} from './education-class.model';
import {EducationInstitutionDto} from '../institutions/education-institution.dto';
import {EducationStudentDto} from '../students/education-student.dto';
import {AccountDto} from '../../accounts/account.dto';
import {WebsiteDto} from '../../websites/website.dto';

import {TIME_FORMATTER, TIME_FORMATTER_DATE} from './constants';

export class EducationClassDto {
  constructor(
    public Id?: number,
    public InstitutionId?: number,
    public Institution?: EducationInstitutionDto,
    public TeachersUsers?: AccountDto[],
    public Students?: EducationStudentDto[],
    public Websites?: WebsiteDto[],
    public Name?: string,
    public Number?: string,
    public SignUpCode?: string,
    public Description?: string,
    public StartDate?: string,
    public EndDate?: string,
    public CreatedBy?: AccountDto,
    public DeletedBy?: AccountDto,
    public CreatedAt?: string,
    public UpdatedAt?: string,
    public DeletedAt?: string,
  ) {}

  public static normalize(res: EducationClassDto): EducationClassModel {
    if (!res) {
      return null;
    }

    return new EducationClassModel(
      res.Id,
      res.InstitutionId,
      EducationInstitutionDto.normalize(res.Institution),
      res.TeachersUsers ? res.TeachersUsers.map((user: AccountDto) => AccountDto.normalize(user)) : null,
      res.Students ? res.Students.map((student: EducationStudentDto) => EducationStudentDto.normalize(student)) : null,
      res.Websites ? res.Websites.map((website: WebsiteDto) => WebsiteDto.normalize(website)) : null,
      res.Name,
      res.Number,
      res.SignUpCode,
      res.Description,
      res.StartDate,
      res.EndDate,
      moment(res.StartDate).format(TIME_FORMATTER_DATE),
      moment(res.EndDate).format(TIME_FORMATTER_DATE),
      AccountDto.normalize(res.CreatedBy),
      AccountDto.normalize(res.DeletedBy),
      moment(res.CreatedAt).format(TIME_FORMATTER),
      moment(res.UpdatedAt).format(TIME_FORMATTER),
      moment(res.DeletedAt).format(TIME_FORMATTER),
    );
  }
}
