export class CustomSubscriptionModel {
  public isPlanIdValid: boolean = true;
  public isTitleValid: boolean = true;
  public isExpirationDateValid: boolean = true;

  public get isValid() {
    return this.isPlanIdValid && this.isTitleValid && this.isExpirationDateValid;
  }

  constructor(public id?: number,
              public planId?: string,
              public title?: string,
              public expiresAt?: string) {}

  public validate(): void {
    this.isPlanIdValid = !!this.planId && this.planId.length > 0;
    this.isTitleValid = !!this.title && this.title.length > 0;
    this.isExpirationDateValid = !!this.expiresAt && this.expiresAt.length > 0;
  }
}
