import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {DefaultPortfolioBlockDto} from '../../../models/blocks/default-portfolio/default-portfolio-block.dto';
import {BlockModel} from '../../../models/blocks/block.model';

@Injectable()
export class DefaultPortfolioHttpService {
  private readonly model: string = 'default-portfolios';

  constructor(private httpClient: HttpClient) {
  }

  public fetch({ websiteId, templateId }: { websiteId: number, templateId: number }): Observable<DefaultPortfolioBlockDto> {
    const params = new HttpParams().set('templateId', `${templateId}`);

    return this.httpClient.get(`api/${this.model}/${websiteId}`, { params });
  }

  public save({ websiteId, templateId, blockTemplateId, version, usedFonts, html, portfolios, isApplyToAllPortfolios }: {
    websiteId: number,
    templateId: number,
    blockTemplateId: number,
    version: number,
    usedFonts: string,
    html: string,
    portfolios: BlockModel[],
    isApplyToAllPortfolios: boolean,
  }): Observable<any> {
    return this.httpClient.put(`api/${this.model}/${websiteId}`, {
      templateId,
      blockTemplateId,
      version,
      usedFonts,
      html,
      portfolios,
      isApplyToAllPortfolios,
    });
  }
}
