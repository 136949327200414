import {BaseFileObjectDto} from '../base/file-object/base-file-object.dto';

import {DocumentModel} from './document.model';

export class DocumentDto extends BaseFileObjectDto {
  constructor(public Id?: number,
              public UserId?: number,
              public FileName?: string,
              public ThumbFileName?: string,
              public OriginalFileName?: string,
              public Link?: string,
              public ThumbLink?: string,
              public FileSize?: string) {
    super();
  }

  public static normalize(res: DocumentDto): DocumentModel {
    if (!res) return null;

    return new DocumentModel(
      res.Id,
      res.UserId,
      res.FileName,
      res.ThumbFileName,
      res.OriginalFileName,
      res.Link,
      res.ThumbLink,
      res.FileSize,
    );
  }
}
