export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `Алая вспышка осветила силуэт зазубренного крыла.`,
      `В вечернем свете волны отчаянно бились о берег.`,
      `Возвращаться назад предстояло в одиночку.`,
      `Всё их оборудование и инструменты были живыми, в той или иной форме.`,
      `Едва осознав, что происходит, мы оторвались от земли.`,
      `Из динамика над дверью раздался скрежещущий голос.`,
      `Лик луны был скрыт тенью.`,
      `На бархатно-синем небе не было ни облачка.`,
      `Настала ночь первой упавшей звезды.`,
      `Обе стороны моей натуры обладали общей памятью.`,
      `Пламя угасло, и он глядел в окно на звезды.`,
    ],
    [KEYS.ALPHABET]: [
      'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя ‘?’“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `Лишь один взгляд на далекую планету может возвысить и очистить наши мысли не хуже духовной музыки, прекрасной картины или поэтических строк. Это приносит человеку несомненную пользу.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
