import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Subscription, BehaviorSubject} from 'rxjs';

import {StripeCouponModel} from '../../../models/stripe/coupon/stripe-coupon.model';

@Injectable()
export class PaymentCouponsService {
  public couponsSubject: BehaviorSubject<{ list: StripeCouponModel[], hasMore: boolean }> = new BehaviorSubject<{ list: StripeCouponModel[], hasMore: boolean }>(null);

  private model = 'payments';

  constructor(private http: HttpClient) {
    this.getCoupons();
  }

  public addCoupon(coupon: StripeCouponModel, nOfCoupons: number): Subscription {
    return this.http.post(`api/${this.model}/coupons`, { coupon, nOfCoupons }, { responseType: 'text' }).subscribe(() => {
      this.getCoupons();
    });
  }

  public updateCoupon(coupon: StripeCouponModel): Subscription {
    return this.http.put(`api/${this.model}/coupons`, coupon, { responseType: 'text' }).subscribe(() => {
      this.getCoupons();
    });
  }

  public deleteCoupon(coupon: StripeCouponModel): Subscription {
    const params = new HttpParams().set('id', coupon.id);

    return this.http.delete(`api/${this.model}/coupons`, { params, responseType: 'text' }).subscribe(() => {
      this.getCoupons();
    });
  }

  public getCoupons(lastId?: string): Subscription {
    const params = new HttpParams()
      .set('limit', '100')
      .set('lastId', lastId || '');

    return this.http.get(`api/${this.model}/coupons`, { params }).subscribe((data: { list: StripeCouponModel[], hasMore: boolean }) => {
      const newItems: StripeCouponModel[] = data && data.list ? data.list.map((item: StripeCouponModel) => StripeCouponModel.normalize(item)) : [];

      const oldItems: StripeCouponModel[] = lastId ? this.couponsSubject.value.list : [];

      this.couponsSubject.next({
        list: [...oldItems, ...newItems],
        hasMore: data ? data.hasMore : false,
      });
    });
  }
}
