import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../modals.service';

@Injectable()
export class PublishingWebsiteInfoModalService {
  public readonly id = 'publishing-website-info-modal';

  public isOpened: boolean = false;
  public isDashboard: boolean = false;

  public onPublishingConfirmSubject: Subject<boolean> = new Subject<boolean>();

  public constructor(private modalsService: ModalsService) {
  }

  public open(options: { isDashboard: boolean } = { isDashboard: false }): void {
    this.modalsService.open(this.id);

    this.isDashboard = options.isDashboard || false;
    this.isOpened = true;
  }

  public onConfirm(): void {
    this.onPublishingConfirmSubject.next(true);

    this.close();
  }

  public onCancel(): void {
    this.onPublishingConfirmSubject.next(false);

    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
