// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-input-modal-body {
  padding: 15px;
}
.select-input-modal-body .form-group-sm {
  width: 100%;
  padding-bottom: 10px;
}
.select-input-modal-body .form-group-sm .select-input {
  width: 100%;
  font-weight: 300;
  cursor: pointer;
  background: transparent;
}
.select-input-modal-body .btn-bar {
  height: 40px;
}
.select-input-modal-body .btn-bar .btn {
  border-radius: 0;
  border: 1px solid #333;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/select-input-modal/select-input-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAGE;EACE,WAAA;EACA,oBAAA;AADJ;AAGI;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;AADN;AAKE;EACE,YAAA;AAHJ;AAKI;EACE,gBAAA;EACA,sBAAA;AAHN","sourcesContent":["\n\n.select-input-modal-body {\n  padding: 15px;\n\n  .form-group-sm {\n    width: 100%;\n    padding-bottom: 10px;\n\n    .select-input {\n      width: 100%;\n      font-weight: 300;\n      cursor: pointer;\n      background: transparent;\n    }\n  }\n\n  .btn-bar {\n    height: 40px;\n\n    .btn {\n      border-radius: 0;\n      border: 1px solid #333;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
