import * as moment from 'moment';

import {AccountDto} from '../../accounts/account.dto';
import {StudentImageReviewModel} from './student-image-review.model';
import {StudentImageReviewAudioDto} from './audio/student-image-review-audio.dto';
import {ImageUpdateSuggestionDto} from '../../education/image-update-suggestion/image-update-suggestion.dto';

import {TIME_FORMATTER} from './constants';

export class StudentImageReviewDto {
  constructor(
    public Id: number,
    public ImageId: number,
    public ReviewedByUserId: number,
    public ReviewedByUser: AccountDto,
    public Rate: number,
    public Text: string,
    public Type: string,
    public Audios?: StudentImageReviewAudioDto[],
    public ImageUpdateSuggestions: ImageUpdateSuggestionDto[] = [],
    public CreatedAt?: string,
    public UpdatedAt?: string,
    public LastViewedByOwnerAt?: string
  ) {}

  public static normalize(res: StudentImageReviewDto): StudentImageReviewModel {
    if (!res) return null;

    return new StudentImageReviewModel(
      res.Id,
      res.ImageId,
      res.ReviewedByUserId,
      AccountDto.normalize(res.ReviewedByUser),
      res.Rate,
      res.Text,
      res.Type,
      res.Audios ? res.Audios.map((audio: StudentImageReviewAudioDto) => StudentImageReviewAudioDto.normalize(audio)) : null,
      res.ImageUpdateSuggestions ? res.ImageUpdateSuggestions.map((crop: ImageUpdateSuggestionDto) => ImageUpdateSuggestionDto.normalize(crop)) : null,
      moment(res.CreatedAt).format(TIME_FORMATTER),
      moment(res.UpdatedAt).format(TIME_FORMATTER),
      res.LastViewedByOwnerAt,
    );
  }
}
