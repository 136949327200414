import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';

import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {PagesService} from '../../../../application/sidebar-short/sidebar/pages/pages.service';
import {NavigationService} from '../../../../services/navigation.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';
import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';
import {AddNodeModalService} from '../../../services/modals/add-node/add-node-modal.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';
import {EducationImageManagerService} from '../../../../core/services/education/image-manager/education-image-manager.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {EducationWebsiteExhibitionsService} from '../../../../core/services/education/teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';
import {StudentImageManagerService} from '../../../../core/services/education/students/image-manager/student-image-manager.service';
import {StudentPortfoliosService} from '../../../../core/services/education/students/websites/portfolios/student-portfolios.service';
import {CoreImageManagerService} from '../../../../core/services/image-manager/core-image-manager.service';

import {AddPageModalModel} from '../../../models/modals/add-page-modal/add-page-modal.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {NodeModel} from '../../../../core/models/nodes/node.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';
import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {SelectOption} from '../../../../core/models/select/option/option.model';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';
import {ImageManagerUserTypeModel} from '../../../../core/models/image-manager/user-type/user-type.model';

import {inputLabel, PAGE_TYPES} from '../../../../application/sidebar-short/sidebar/pages/constants';
import {PAGE_GROUP_TYPES} from '../../../../application/sidebar-short/sidebar/pages/tree/constants';
import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';

import {EducatorImageManagerTabs} from '../../../../core/services/education/image-manager/constants';
import {StudentImageManagerTabs} from '../../../../core/services/education/students/image-manager/constants';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-add-page-modal',
  templateUrl: './add-page-modal.component.html',
  styleUrls: ['./add-page-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class AddPageModalComponent implements OnInit, OnDestroy {
  @ViewChild('titleInput') titleInput: ElementRef;

  public model: AddPageModalModel;

  public type: string;

  public errorMessage: string;

  public pageGroupType: string = PAGE_GROUP_TYPES.HORIZONTAL;

  public pageGroupTypes: SelectOption[] = [
    new SelectOption('Horizontal', PAGE_GROUP_TYPES.HORIZONTAL),
    new SelectOption('Vertical', PAGE_GROUP_TYPES.VERTICAL),
  ];

  public isSharedPortfolioAccessAllowed: boolean = false;

  public isSaving: boolean = false;
  public isBothMenus: boolean = false;
  public isShared: boolean = false;
  public isImageManager: boolean;
  public isTitleValid: boolean = true;

  private website: WebsiteModel;

  private nodes: NodeModel[];
  
  private activeEducatorTab: EducatorImageManagerTabs;
  private activeStudentTab: StudentImageManagerTabs;

  private userData: ImageManagerUserTypeModel;

  private isStudent: boolean;
  private isEducator: boolean;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  
  private studentImageManagerHandlers = {
    'student': this.onStudentWebsitePageCreate.bind(this),
  }

  private educatorImageManagerHandlers = {
    'exhibitions': this.onExibitionWebsitePageCreate.bind(this),
    'user': this.onUserWebsitePageCreate.bind(this),
  };

  public get id(): string {
    return this.service.id;
  }

  public get modalTitle(): string {
    return this.isImageManager ? 'Add Portfolio' : 'Add Page';
  }

  public get requiredError(): string {
    return this.pagesService.validationMessages[this.pagesService.validationErrorsKeys.required];
  }

  public get maxLengthError(): string {
    return this.pagesService.validationMessages[this.pagesService.validationErrorsKeys.maxLength];
  }

  public get inappropriateSymbolsError(): string {
    return this.pagesService.validationMessages[this.pagesService.validationErrorsKeys.inappropriateSymbols];
  }

  public get isBlogPermissionError(): boolean {
    return this.errorMessage === 'Blog is accessible for users with Professional plan only.';
  }

  public get isShareAllowed(): boolean {
    if (!this.userData || this.userData.type !== 'student') {
      return false;
    }

    if (this.userData.tab === 'student') {
      return true;
    }

    return this.isStudentEduSubscription;
  }

  public get isStudentEduSubscription(): boolean {
    if (!this.website || !this.website.subscription) {
      return false;
    }

    return this.website.subscription.isStudentEdu;
  }

  constructor(
    private service: AddNodeModalService,
    private navigationService: NavigationService,
    private imageManagerService: ImageManagerService,
    private websitesService: WebsitesService,
    private templatesService: TemplatesService,
    private permissionsService: PermissionsService,
    private educationImageManagerService: EducationImageManagerService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private educationWebsiteExhibitionsService: EducationWebsiteExhibitionsService,
    private studentImageManagerService: StudentImageManagerService,
    private studentPortfoliosService: StudentPortfoliosService,
    private coreImageManagerService: CoreImageManagerService,
    public pagesService: PagesService,
    public nodesService: NodesService
  ) {}

  public ngOnInit(): void {
    this.initPermissions();

    this.errorMessage = '';
    this.isSaving = false;

    this.navigationService.isImageManagerSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isImageManager => {
      this.isImageManager = isImageManager;

      this.setAddPageModalModel();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isEducator = false;

      if (!subscription) {
        return;
      }

      this.isEducator = subscription.isEducator;
    });

    this.coreImageManagerService.userTypeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userData: ImageManagerUserTypeModel) => {
      this.userData = userData;
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.nodes = nodes;
    });

    this.pagesService.parentNodeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { id: number, groupType: string }) => {
      this.model.parentNodeId = data ? data.id : null;
      this.model.groupType = data ? data.groupType : this.pageGroupType;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.isBothMenus = template && template.menuType === 'BOTH';
    });

    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      this.activeEducatorTab = key;
    });

    this.educationWebsiteExhibitionsService.errorSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error: string) => {
      this.errorMessage = error;
    });

    this.studentImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: StudentImageManagerTabs) => {
      this.activeStudentTab = key;
    });

    this.studentPortfoliosService.errorSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error: string) => {
      this.errorMessage = error;
    });
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    const addSharedPortfolioPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.ADD_SHARED_PORTFOLIO,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
    });

    this.permissionsService.isUserHasPermissionsObservable([addSharedPortfolioPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isSharedPortfolioAccessAllowed = isAllowed;
    });
  }

  public addPage(form: NgForm) {
    if (this.isSaving) {
      return null;
    }

    this.errorMessage = null;
    this.isSaving = true;

    try {
      const node: NodeModel = this.getNewNodeData(form);
      
      if (this.isImageManager && this.isStudent && this.studentImageManagerHandlers[this.activeStudentTab]) {
        return this.studentImageManagerHandlers[this.activeStudentTab](node);
      }

      if (!this.isImageManager || !this.isEducator || this.activeEducatorTab !== 'exhibitions') {
        this.onUserWebsitePageCreate(node);

        return;
      }

      if (!this.educatorImageManagerHandlers[this.activeEducatorTab]) {
        return;
      }

      this.educatorImageManagerHandlers[this.activeEducatorTab](node);
    } catch (error) {
      this.errorMessage = error || 'Something went wrong. Please, try later.';
    } finally {
      this.isSaving = false;
    }
  }

  private onUserWebsitePageCreate(node: NodeModel): Subscription {
    return this.pagesService.addNode({ node, templateId: this.website.templateId }).pipe(
      catchError(e => {
        console.error(e);
        
        this.errorMessage = e || 'Something went wrong. Please, try later.';

        return throwError(() => e);
      }),
      finalize(() => {
        this.isSaving = false;
      }),
    ).subscribe(page => {
      this.nodesService.fetchNodes().add(() => {
        this.onNewPageAdded(page);
  
        this.closeModal();
      });
    });
  }

  private onStudentWebsitePageCreate(node: NodeModel): void {
    this.studentPortfoliosService.submitPageCreateSubject.next(
      node,
    );
  }

  private onExibitionWebsitePageCreate(node: NodeModel): void {
    this.educationWebsiteExhibitionsService.submitPageCreateSubject.next(
      node,
    );
  }

  private onNewPageAdded(page): void {
    const options = {
      id: page.NodeKey,
      type: page.NodeType,
      isPageExists: page.IsPageExists,
      isSplash: false,
    };

    this.imageManagerService.onImageSelectSubject.next(null);

    if (this.navigationService.isPageEditor) {
      return this.navigationService.toPageEditor(options);
    }

    if (this.navigationService.isImageManager) {
      return this.navigationService.toImageManager(options);
    }
  }

  private getNewNodeData(form: NgForm): NodeModel {
    const parentPage: NodeModel = this.pagesService.getPageByNodeId(this.model.parentNodeId);

    const groupType: string = parentPage ? parentPage.groupType : this.pageGroupType;

    const type: string = form.value.type ? form.value.type : this.model.pageTypes[0].value;

    return new NodeModel(
      null,
      null,
      parentPage ? this.calculateNewNodeLevel({ type: parentPage.type, nodeLevel: parentPage.nodeLevel }) : 0,
      this.getSequence(parentPage, groupType),
      null,
      form.value.title.trim(),
      type,
      null,
      null,
      null,
      null,
      false,
      false,
      null,
      null,
      groupType,
      true,
      true,
      parentPage && parentPage.isPrivate || false,
      type !== 'C',
      false,
      false,
      this.isShared,
    );
  }

  private calculateNewNodeLevel({ type, nodeLevel }): number {
    return type === 'C' ? nodeLevel + 1 : nodeLevel;
  }

  private getSequence(parentPage: NodeModel, groupType: string): number {
    if (parentPage) return parentPage.nodeSequence + 1;

    if (!this.isBothMenus) return 1;

    const nodes: NodeModel[] = this.nodes.filter(n => n.groupType === groupType);

    const prevNode = nodes.length === 0 ? this.nodes[this.nodes.length - 1] : nodes[nodes.length - 1];

    return prevNode ? prevNode.nodeSequence + 1 : 1;
  }

  setAddPageModalModel() {
    this.model = {
      title: this.modalTitle,
      inputLabel,
      parentNodeId: null,
      pageTypes: this.navigationService.isImageManager ? PAGE_TYPES.filter(type => type.value === 'P') : PAGE_TYPES,
    } as AddPageModalModel;

    this.type = this.model.pageTypes[0].value;
  }

  public onInputChange(): void {
    this.clearErrorMesssage();

    const title: string = this.titleInput.nativeElement.value.trim();

    this.isTitleValid = this.pagesService.PAGE_NAME_REGEXP.test(title);
  }

  public clearErrorMesssage() {
    this.errorMessage = '';
  }

  public toPurchasePage() {
    this.closeModal();
    this.navigationService.toPurchase();
  }

  public closeModal() {
    this.service.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
