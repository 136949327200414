import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CurrencyModel} from '../../../../models/currency/currency.model';
import {CurrencyDto} from '../../../../models/currency/currency.dto';

@Injectable()
export class CurrenciesHttpService {
  private model: string = 'currencies';

  constructor(private http: HttpClient) {}
  public getAll(): Observable<CurrencyModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: CurrencyDto[]) => res.map(item => CurrencyDto.normalize(item)))
    );
  }

  public fetchById(id: number) {
    return this.http.get(`api/${this.model}/${id}`);
  }

  public fetchForWebsite() {
    return this.http.get(`api/${this.model}/current`);
  }

  public getForWebsite(): Observable<CurrencyModel> {
    return this.http.get(`api/${this.model}/current`).pipe(
      map((res: CurrencyDto) => CurrencyDto.normalize(res))
    );
  }
}
