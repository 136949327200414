// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-modal-main-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 101;
}
.floating-modal-main-wrapper .floating-modal-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.floating-modal {
  position: absolute;
  left: 0;
  top: 0;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  z-index: 102;
  width: 285px;
}
.floating-modal .floating-modal-content {
  border: 1px solid #ddd;
  width: -moz-fit-content;
  width: fit-content;
}
.floating-modal .floating-modal-content .dragging-tool {
  background: black;
  color: white;
  padding: 2px;
  text-align: center;
  width: 280px;
}
.floating-modal .floating-modal-content .dragging-tool:hover {
  cursor: move;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/floating-modal/floating-modal.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AADF;AAGE;EACE,sBAAA;EACA,uBAAA;EAAA,kBAAA;AADJ;AAGI;EACE,iBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AADN;AAGM;EACE,YAAA;AADR","sourcesContent":["\n.floating-modal-main-wrapper {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: 101;\n\n  .floating-modal-container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n  }\n}\n\n.floating-modal {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: fit-content;\n  border-radius: 3px;\n  height: fit-content;\n  max-height: 100%;\n  overflow-y: auto;\n  z-index: 102;\n  width: 285px;\n\n  .floating-modal-content {\n    border: 1px solid #ddd;\n    width: fit-content;\n\n    .dragging-tool {\n      background: black;\n      color: white;\n      padding: 2px;\n      text-align: center;\n      width: 280px;\n\n      &:hover {\n        cursor: move;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
