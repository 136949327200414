import {BaseColorModel} from '../../base/color/base-color.model';

import {STYLE_PROPERTY_NAME, PROPERTY_NAMES} from './constants';

export class TitleTextColorModel extends BaseColorModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAMES[key], STYLE_PROPERTY_NAME, value, isEnabled, false, false);
  }

  public init(selectedElement: HTMLElement): void {
    if (!this.isEnabled || !selectedElement) return;

    super.init(selectedElement, {
      selectedElement,
      defaultValueHolderElement: selectedElement,
    });
  }
}
