import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as $ from 'jquery';

import {AppAnimations} from "../../../app-animations";

import {ModalsService} from '../../../shared/services/modals/modals.service';
import {PaymentSubscriptionsService} from '../../../core/services/payment/subscriptions/payment-subscriptions.service';

import {ModalDataModel} from '../../../core/models/modals/modal-data.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SettingsComponent implements OnInit, OnDestroy {
  public unhandledModalErrorCode = '';
  public unhandledModalMessage = '';

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private modalsService: ModalsService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    $('app-page-menu').hide();
    $('.sub-navigation').hide();

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    $('app-page-menu').show();
    $('.sub-navigation').show();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onCardsManagementClick(id: string) {
    this.onErrorModalClose(id);
    return this.paymentSubscriptionsService.toCreditCards();
  }

  public onUnhandledPaymentModalOpen(data: { code: string, message: string }) {
    if (!data) return;

    this.unhandledModalErrorCode = data.code;
    this.unhandledModalMessage = data.message;
  }

  public onErrorModalClose(id): void {
    this.modalsService.close(id);
  }
}
