export const KEYS = {
  MENU_BACKGROUND_COLOR: 'MENU_BACKGROUND_COLOR',
  MENU_BACKGROUND_OPACITY: 'MENU_BACKGROUND_OPACITY',
  MENU_STYLE: 'MENU_STYLE',
  HAMBURGER_COLOR: 'HAMBURGER_COLOR',
  HOVER_COLOR: 'HOVER_COLOR',
  ACTIVE_COLOR: 'ACTIVE_COLOR',
  LINKS_TEXT_DECORATION: 'LINKS_TEXT_DECORATION',
  HAMBURGER_TOOLTIP: 'HAMBURGER_TOOLTIP',
  MENU_PADDING_TOP: 'MENU_PADDING_TOP',
  MENU_DROPDOWN_ITEM_OPACITY: 'MENU_DROPDOWN_ITEM_OPACITY',
  MENU_DROPDOWN_ITEM_HOVER_DESIGN: 'MENU_DROPDOWN_ITEM_HOVER_DESIGN',
  MENU_DROPDOWN_ITEM_HOVER_BACKGROUND: 'MENU_DROPDOWN_ITEM_HOVER_BACKGROUND',
  MENU_DROPDOWN_ITEM_HOVER_COLOR: 'MENU_DROPDOWN_ITEM_HOVER_COLOR',
  MENU_DROPDOWN_ITEM_HOVER_OPACITY: 'MENU_DROPDOWN_ITEM_HOVER_OPACITY',
  MENU_DROPDOWN_ARROW: 'MENU_DROPDOWN_ARROW',
  MENU_DROPDOWN_ARROW_PADDING: 'MENU_DROPDOWN_ARROW_PADDING',
  MENU_DROPDOWN_TEXT_ALIGNMENT: 'MENU_DROPDOWN_TEXT_ALIGNMENT',
  VERTICAL_PADDING: 'VERTICAL_PADDING',
  COLLAPSE_OPTIONS: 'COLLAPSE_OPTIONS',
  MENU_PADDING: 'MENU_PADDING',
  MENU_LOGO_PADDING: 'MENU_LOGO_PADDING',
  VERTICAL_SPACING: 'VERTICAL_SPACING',
  IS_MENU_TRANSPARENT_ON_HOME_PAGE: 'IS_MENU_TRANSPARENT_ON_HOME_PAGE',
  IS_LOGO_VISIBLE: 'IS_LOGO_VISIBLE',
  IS_CATEGORIES_AS_PAGES: 'IS_CATEGORIES_AS_PAGES',
  IS_SIZE_REDUCED_ON_SCROLL: 'IS_SIZE_REDUCED_ON_SCROLL',
};

export const TYPES = {
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
  ATTRIBUTE: 'ATTRIBUTE',
};
