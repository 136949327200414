import {Component, Input, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

import {ModalsService} from "../../../services/modals/modals.service";
import {AppVersionChangesService} from '../../../../core/services/app-version-changes/app-version-changes.service';

import {Button} from "../../../../common/models/button/button.model";
import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-version-changes-modal',
  templateUrl: './app-version-changes-modal.component.html',
  styleUrls: ['./app-version-changes-modal.component.scss']
})
export class AppVersionChangesModalComponent implements OnDestroy {
  @Input() id: string;

  public modalHeader: ModalHeader = {
    text: 'New Version Changes',
    className: 'success-header',
  };

  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.submitHandler.bind(this),
    }
  ];

  public isOpened: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get version(): string {
    return this.appVersionChangesService.version;
  }

  public get changes(): string {
    return this.appVersionChangesService.changes;
  }

  constructor(private modalsService: ModalsService,
              private appVersionChangesService: AppVersionChangesService) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onModalOpen() {
    this.isOpened = true;
  }

  public submitHandler() {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
