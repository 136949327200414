import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class IsCaptionEnabledModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!element) return;

    this.element = element;

    this.setValue(`${this.element.getAttribute(ATTRIBUTES.IS_CAPTION_ENABLED)}` === 'true');
  }

  public onChange(value: boolean): void {
    this.value = value;
    this.element.setAttribute(ATTRIBUTES.IS_CAPTION_ENABLED, `${value}`);
  }

  public setValue(value: boolean) {
    this.value = value;
    this.element.setAttribute(ATTRIBUTES.IS_CAPTION_ENABLED, `${value}`);
  }
}
