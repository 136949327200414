import {StyleOptionTypeModel} from './style-option-type.model';

export class StyleOptionTypeDto {
  constructor(
    public Id: number,
    public Type: string,
  ) {}

  public static normalize(res: StyleOptionTypeDto): StyleOptionTypeModel {
    if (!res) return null;

    return new StyleOptionTypeModel(
      res.Id,
      res.Type,
    );
  }
}
