import {IStyleOption} from '../../option/i-style-option';

import {CLASS_NAMES} from './constants';

export class IsLogoVisibleModel implements IStyleOption {
  private logos: HTMLElement[];

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement): void {
    const logos: HTMLElement[] = element ? <HTMLElement[]>Array.from(element.querySelectorAll('.menu-logo')) : null;

    this.isEnabled = logos && logos.length > 0;

    if (!this.isEnabled) return;

    this.logos = logos;

    this.value = !logos[0].classList.contains(CLASS_NAMES.HIDDEN);
  }

  public setValue(isEnabled: boolean): void {
    if (!this.isEnabled) return;

    this.logos.forEach(logo => {
      logo.classList.toggle(CLASS_NAMES.HIDDEN, !isEnabled);
    });
  }
}
