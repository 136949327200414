import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../../auth/auth.service';

import {AccountModel} from '../../../../models/accounts/account.model';
import {SeverityModel} from '../../../../models/severity/severity.model';
import {SeverityDto} from '../../../../models/severity/severity.dto';

@Injectable()
export class SeveritiesHttpService implements OnDestroy {
  private prefix: string = 'api';
  private model: string = 'severities';

  public severitiesSubject: BehaviorSubject<SeverityModel[]> = new BehaviorSubject<SeverityModel[]>([]);

  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient,
              private authService: AuthService) {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      if (!account) return;

      this.getAll();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getAll() {
    return this.http.get(`${this.prefix}/${this.model}`).subscribe((res: SeverityDto[]) => {
      const severities: SeverityModel[] = res.map((severity: SeverityDto) => SeverityDto.normalize(severity));
      this.severitiesSubject.next(severities);
    });
  }
}
