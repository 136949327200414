import {Injectable} from '@angular/core';

import {BehaviorSubject, Subject} from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import {BrowserService} from '../browser/browser.service';

import {IRecordedAudioModel} from '../../models/base/audio/i-recorded-audio.model';

import {ERRORS_MAPPING, CUSTOM_ERRORS, AudioState} from './constants';

@Injectable()
export class AudioService {
  public stateSubject: BehaviorSubject<AudioState> = new BehaviorSubject<AudioState>('stop');
  public onRecordedSubject: Subject<IRecordedAudioModel> = new Subject<IRecordedAudioModel>();
  public errorSubject: Subject<string> = new Subject<string>();
  public isInitializedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  private mediaRecorder: MediaRecorder;

  private chunks: Blob[] = [];

  private startAt: number;

  private recordedMimeType: string = null;

  private state: AudioState = 'stop';

  private isInitialized: boolean = false;

  private handlers: { [key: string]: any } = {
    dataAvailable: this.onDataAvailable.bind(this),  
    start: this.onStart.bind(this),  
    stop: this.onStop.bind(this),  
  };

  constructor(
    private browserService: BrowserService,
  ) {
  }

  // async ok
  public async onRecordButtonClick() {
    if (this.state === 'processing') {
      return;
    }

    this.setState('processing');

    try {
      if (!this.isInitialized) {
        await this.initRecorder();
      }
  
      this.handleButtonClick();
    } catch (e) {
      this.handleError(e);
    }
  }

  private handleButtonClick(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.killMediaRecorder();

      return;
    }

    this.mediaRecorder.start();
  }

  public killMediaRecorder(): void {
    if (!this.mediaRecorder) {
      return;
    }

    this.setState('processing');

    this.recordedMimeType = this.mediaRecorder.mimeType.split(';')[0];

    this.mediaRecorder.stop();

    this.mediaRecorder.stream.getTracks().forEach((track: MediaStreamTrack) => {
      track.stop();
    });

    this.mediaRecorder = null;

    this.isInitialized = false;
  }

  // async ok
  private async initRecorder(): Promise<void | Error> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      throw new Error(CUSTOM_ERRORS.NOT_SUPPORTED);
    }

    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    this.setMediaRecorder(new MediaRecorder(stream, this.getOptions()));
      
    this.mediaRecorder.addEventListener('dataavailable', this.handlers.dataAvailable);
    this.mediaRecorder.addEventListener('start', this.handlers.start);
    this.mediaRecorder.addEventListener('stop', this.handlers.stop);
  }

  private getOptions(): MediaRecorderOptions {
    const options: MediaRecorderOptions = {
      audioBitsPerSecond: 128000,
    };

    if (this.browserService.isChrome) {
      options.mimeType = 'audio/webm;codecs=opus';
    }

    if (this.browserService.isFirefox) {
      options.mimeType = 'audio/webm;codecs=opus';
    }

    if (this.browserService.isSafari) {
      options.mimeType = 'audio/mp4';
    }

    return options;
  }

  private onDataAvailable(e: BlobEvent): void {
    this.chunks.push(e.data);
  }

  private onStart(): void {
    this.startAt = new Date().getTime();

    this.setState('recording');
  }

  private onStop(): void {
    this.setState('stop');

    const durationMs: number = (new Date().getTime() - this.startAt) || 0;
    
    const blob: Blob = new Blob(this.chunks, { type: this.recordedMimeType });

    this.chunks = [];

    this.startAt = null;

    if (durationMs === 0) {
      return;
    }

    if (blob.size === 0) {
      return;
    }

    const audio: IRecordedAudioModel = {
      duration: `${Math.round(durationMs / 1000)}`,
      fileName: uuidv4(),
      fileSize: `${blob.size}`,
      mimeType: this.recordedMimeType,
      blob,
    };

    this.onRecordedSubject.next(audio);
  }

  private handleError(error: Error): void {
    console.error(error);

    this.errorSubject.next(this.getErrorText(error));

    this.setState('stop');
  }

  private getErrorText(error: Error): string | null {
    if (error.message.includes(CUSTOM_ERRORS.NOT_SUPPORTED)) {       
      return `Your browser is not supporting audio recording.`;
    }

    return ERRORS_MAPPING[error.name] ? ERRORS_MAPPING[error.name] : `${error.name} occured.`;
  }

  private setMediaRecorder(mediaRecorder: MediaRecorder): void {
    this.mediaRecorder = mediaRecorder;

    this.isInitialized = !!mediaRecorder;

    this.isInitializedSubject.next(this.isInitialized);
  }

  private setState(state: AudioState): void {
    this.state = state;

    this.stateSubject.next(state);
  }
}
