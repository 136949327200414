import {StudentImageReviewAudioDto} from './student-image-review-audio.dto';
import {StudentImageReviewAudioUploadUrlsModel} from './student-image-review-audio-upload-urls.model';

export class StudentImageReviewAudioUploadUrlsDto {
  constructor(public audioUrl?: string,
              public audio?: StudentImageReviewAudioDto) {}

  public static normalize(res: StudentImageReviewAudioUploadUrlsDto): StudentImageReviewAudioUploadUrlsModel {
    if (!res) return null;

    return new StudentImageReviewAudioUploadUrlsModel(
      res.audioUrl,
      StudentImageReviewAudioDto.normalize(res.audio),
    );
  }
}
