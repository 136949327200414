import {Component, EventEmitter, Input, Output} from '@angular/core';

import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {StudentImageManagerService} from '../../../../core/services/education/students/image-manager/student-image-manager.service';
import {EducationImageManagerService} from '../../../../core/services/education/image-manager/education-image-manager.service';
import {IFrameRoutingService} from '../../../../core/services/iframe/routing/iframe-routing.service';

import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {SelectedPageModel} from '../../../../core/models/selected-page/selected-page.model';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {EducatorImageManagerTabs} from '../../../../core/services/education/image-manager/constants';
import {StudentImageManagerTabs} from '../../../../core/services/education/students/image-manager/constants';
import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';
import {LIBRARY_ID} from '../../../../application/constants';

@Component({
  selector: 'app-delete-all-images-modal',
  templateUrl: './delete-all-images-modal.component.html',
  styleUrls: ['./delete-all-images-modal.component.scss'],
})
export class DeleteAllImagesModalComponent {
  @Input()
  public id: string;
  
  @Input()
  public nOfImages: number;

  @Output()
  public submitHandler: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public cancelHandler: EventEmitter<void> = new EventEmitter<void>();

  private activeTeacherTab: EducatorImageManagerTabs;
  private activeStudentTab: StudentImageManagerTabs;

  private isEducator: boolean = false;
  private isStudent: boolean = false;

  private isLibrary: boolean;

  private studentHeaderTextHandlers = {
    'student': this.getStudentTabHeaderText.bind(this),
    'user': this.getStudentUserTabHeaderText.bind(this),
  };

  private teacherHeaderTextHandlers = {
    'students': this.getTeacherStudentsTabHeaderText.bind(this),
    'exhibitions': this.getTeacherExhibitionsTabHeaderText.bind(this),
    'user': this.getTeacherUserTabHeaderText.bind(this),
  };

  private studentTextHandlers = {
    'student': this.getStudentTabText.bind(this),
    'user': this.getStudentUserTabText.bind(this),
  };

  private teacherTextHandlers = {
    'students': this.getTeacherStudentsTabText.bind(this),
    'exhibitions': this.getTeacherExhibitionsTabText.bind(this),
    'user': this.getTeacherUserTabText.bind(this),
  };

  public get modalHeader() {
    return {
      text: this.headerText,
      className: 'warning-header',
    };
  }

  private get headerText(): string {
    if (this.isEducator) {
      return this.teacherHeaderTextHandlers[this.activeTeacherTab] ? this.teacherHeaderTextHandlers[this.activeTeacherTab]() : '';
    }

    if (this.isStudent) {
      return this.studentHeaderTextHandlers[this.activeStudentTab] ? this.studentHeaderTextHandlers[this.activeStudentTab]() : '';
    }

    return this.userHeader;
  }

  private get userHeader(): string {
    if (this.isLibrary) {
      return this.permanentlyDeleteHeaderText;
    }
    
    return this.deleteHeaderText;
  }

  public get text(): string {
    if (this.isEducator) {
      return this.teacherTextHandlers[this.activeTeacherTab] ? this.teacherTextHandlers[this.activeTeacherTab]() : '';
    }

    if (this.isStudent) {
      return this.studentTextHandlers[this.activeStudentTab] ? this.studentTextHandlers[this.activeStudentTab]() : '';
    }

    return this.userText;
  }

  private get userText(): string {
    if (this.isLibrary) {
      return this.permanentlyDeleteLibraryText;
    }
    
    return this.deleteText;
  }

  private get deleteHeaderText(): string {
    return `Archive Image${this.isOneImage ? '' : 's'}?`;
  }

  private get permanentlyDeleteHeaderText(): string {
    return `Delete Image${this.isOneImage ? '' : 's'}?`;
  }

  private get deleteText(): string {
    return `Do you want to remove ALL images from this portfolio? If yes, ${this.isOneImage ? 'it' : 'they'} will be moved to your library.`;
  }

  private get permanentlyDeletePortfolioText(): string {
    return `Do you want to permanently delete ALL images from this portfolio?`;
  }

  private get permanentlyDeleteLibraryText(): string {
    return `Do you want to permanently delete ALL images from library?`;
  }

  private get isOneImage(): boolean {
    return this.nOfImages === 1;
  }

  constructor(
    private educationImageManagerService: EducationImageManagerService,
    private studentImageManagerService: StudentImageManagerService,
    private iFrameRoutingService: IFrameRoutingService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private permissionsService: PermissionsService,
  ) {
    this.init();
  }

  private init(): void {
    this.initPermissions();

    this.iFrameRoutingService.selectedPageSubject.subscribe((page: SelectedPageModel) => {
      this.isLibrary = page && page.type === 'P' ? page.id === LIBRARY_ID : false;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe((subscription: SubscriptionModel) => {
      this.isEducator = subscription ? subscription.isEducator : false;
    });

    this.educationImageManagerService.activeTabSubject.subscribe((key: EducatorImageManagerTabs) => {
      this.activeTeacherTab = key;
    });

    this.studentImageManagerService.activeTabSubject.subscribe((key: StudentImageManagerTabs) => {
      this.activeStudentTab = key;
    });
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
    });
  }

  private getStudentTabHeaderText(): string {
    return this.permanentlyDeleteHeaderText;
  }

  private getStudentUserTabHeaderText(): string {
    return this.userHeader;
  }

  private getTeacherStudentsTabHeaderText(): string {
    return this.permanentlyDeleteHeaderText;
  }

  private getTeacherExhibitionsTabHeaderText(): string {
    return this.permanentlyDeleteHeaderText;
  }

  private getTeacherUserTabHeaderText(): string {
    return this.userHeader;
  }

  private getStudentTabText(): string {
    return this.permanentlyDeletePortfolioText;
  }

  private getStudentUserTabText(): string {
    return this.userText;
  }

  private getTeacherStudentsTabText(): string {
    return this.permanentlyDeletePortfolioText;
  }

  private getTeacherExhibitionsTabText(): string {
    return this.permanentlyDeletePortfolioText;
  }

  private getTeacherUserTabText(): string {
    return this.userText;
  }
}
