import {SelectOption} from '../../../select/option/option.model';

export const STYLE_PROPERTY_NAME = '--portfolio-image-title-text-decoration';
export const PROPERTY_NAME = 'text-decoration';

export const KEYS = {
  LINE_THROUGH: 'line-through',
  OVERLINE: 'overline',
  UNDERLINE: 'underline',
  NONE: 'none',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('None', KEYS.NONE),
  new SelectOption('Underline', KEYS.UNDERLINE),
  new SelectOption('Overline', KEYS.OVERLINE),
  new SelectOption('Line Through', KEYS.LINE_THROUGH),
];

