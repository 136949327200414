import {ImageProcessModel} from './image-process.model';

export class ImageProcessDto {
  public ProcessID?: number;
  public ProcessName?: string;
  public Type?: number;
  public UserId?: number;

  constructor(processId, processName, type, userId) {
    this.ProcessID = processId;
    this.ProcessName = processName;
    this.Type = type;
    this.UserId = userId;
  }

  public static normalize(res: ImageProcessDto): ImageProcessModel {
    if (!res) return null;

    return new ImageProcessModel(res.ProcessID, res.ProcessName, res.Type, res.UserId);
  }
}
