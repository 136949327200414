import {CustomSubscriptionModel} from './custom-subscription.model';

export class CustomSubscriptionDto {
  constructor(public Id?: number,
              public PlanId?: string,
              public Title?: string,
              public ExpiresAt?: string) {}

  public static normalize(res: CustomSubscriptionDto): CustomSubscriptionModel {
    if (!res) return null;

    return new CustomSubscriptionModel(
      res.Id,
      res.PlanId,
      res.Title,
      res.ExpiresAt,
    );
  }

  public static fromModel(res: CustomSubscriptionModel): CustomSubscriptionDto {
    if (!res) return null;

    return new CustomSubscriptionDto(
      res.id,
      res.planId,
      res.title,
      res.expiresAt,
    );
  }
}
