import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

const KEY = {
  ENTER: 13,
  ESC: 27
};

@Component({
  selector: 'app-editable-content',
  templateUrl: './editable-content.component.html',
  styleUrls: ['./editable-content.component.scss']
})
export class EditableContentComponent {
  @ViewChild('infinityDropdown') dropdown;

  @Input() type: string;
  @Input() maxWidth: number = 250;
  @Input() isAlwaysEditing: boolean = false;
  @Input() isInfinityPossible: boolean = false;

  @Input() value;
  @Output() valueChange = new EventEmitter();

  private lastSavedValue: any;

  _isEditing = false;
  get isEditing() {
    return this._isEditing || this.isAlwaysEditing;
  }
  set isEditing(value) {
    this._isEditing = value;
  }

  get realValue(): string {
    return this.isInfinityPossible && this.value === null ? '∞' : this.value;
  }

  get isDropdownHovered(): boolean {
    return this.dropdown && this.dropdown.nativeElement.matches(':hover');
  }

  constructor() {}

  onClick(input: HTMLElement) {
    this.setEditing(true);
    input.focus();
  }

  handleKeyDown(event) {
    const key = event.keyCode;
    if (key === KEY.ENTER || key === KEY.ESC) {
      this.save();
    }
  }

  handleChange() {
    if (this.type !== 'checkbox') return;
    this.value = this.value !== 'true';
    this.save();
  }

  save() {
    this.setEditing(false);

    if (this.isDropdownHovered) this.value = null;

    if (this.lastSavedValue === this.value) return;
    if (!this.value && this.isInfinityPossible) this.value = null;

    this.lastSavedValue = this.value;
    this.valueChange.emit(this.value);
  }
  setEditing(value) {
    this.isEditing = value;
  }
}
