import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TemplateModel} from '../../../models/templates/template.model';
import {TemplateDto} from '../../../models/templates/template.dto';
import {TemplatePageModel} from '../../../models/templates/pages/template-page.model';

@Injectable()
export class TemplatesHttpService {
  constructor(private http: HttpClient) {
  }

  public fetchAll(): Observable<TemplateModel[]> {
    return this.http.get(`api/templates`).pipe(
      map((res: TemplateDto[]) => res.map((item: TemplateDto) => TemplateDto.normalize(item)))
    );
  }

  public fetchPreviewPages(id: number): Observable<TemplatePageModel[]> {
    return this.http.get<TemplatePageModel[]>(`api/templates/${id}/preview`);
  }
}
