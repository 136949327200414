const SECTIONS = {
  APP_SETTINGS: {
    title: 'App Settings',
    path: 'app-settings',
  },
  CUSTOMERS: {
    title: 'Customers',
    path: 'customers',
  },
  EDUCATION: {
    title: 'Education',
    path: 'education',
  },
  SUBSCRIPTIONS: {
    title: 'Plans',
    path: 'plans',
  },
  SUBSCRIPTIONS_PERMISSIONS: {
    title: 'Permissions',
    path: 'plans-permissions',
  },
  IMPORT: {
    title: 'Import',
    path: 'import',
  },
  CUSTOM_SUBSCRIPTIONS: {
    title: 'Custom Subscriptions',
    path: 'custom-subscriptions',
  },
  BLOCKS_TEMPLATES: {
    title: 'Blocks',
    path: 'blocks-templates',
  },
  BLOCKS_CHANGES: {
    title: 'Blocks Changes',
    path: 'blocks-changes',
  },
  GA_ACCOUNTS: {
    title: 'GA Accounts',
    path: 'ga-accounts',
  },
  APP_VERSION: {
    title: 'App Version',
    path: 'app-version',
  },
  DEFAULT_FONTS: {
    title: 'Default Fonts',
    path: 'default-fonts',
  },
  STRIPE: {
    title: 'Stripe',
    path: 'stripe',
  },
  FREE_TRIAL: {
    title: 'Free Trial',
    path: 'free-trial',
  },
};

export {
  SECTIONS,
};
