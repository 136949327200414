import {Injectable} from '@angular/core';

import { catchError, throwError } from 'rxjs';

import {ModalsService} from '../modals.service';
import {WebsiteDesignerService} from '../../../../application/main/website-designer/website-designer.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import { ImageManagerService } from '../../../../application/main/image-manager/image-manager.service';
import { IFrameService } from '../../../../core/services/iframe/iframe.service';
import { EventsService } from '../../../../core/services/interaction/events/events.service';

import { ImageModel } from '../../../../core/models/images/image.model';

import { getSrcSet } from '../../../util/formatting/image';

import {ATTRIBUTES, KEYS, SELECTORS} from './constants';

@Injectable()
export class SetImageModalService {
  public readonly id = 'set-image-modal';

  public isOpened = false;

  public selectedValue: string;

  public  element: HTMLElement;

  private initialSrc: string;

  public get isSplash(): boolean {
    return this.element && this.element.id === 'splash-image';
  }

  public get src() {
    return this.element && this.element.getAttribute('src');
  }
  public set src(value: string) {
    if (!this.element) return;

    this.element.setAttribute(ATTRIBUTES.SRC, value);
  }

  public constructor(
    private modalsService: ModalsService,
    private buttonsService: ButtonsService,
    private websiteDesignerService: WebsiteDesignerService,
    private imageManagerService: ImageManagerService,
    private iFrameService: IFrameService,
    private eventsService: EventsService,
  ) {
    this.websiteDesignerService.editingSubject.subscribe(data => {
      this.element = data && $(data.element)[0];
    });

    this.imageManagerService.onImageReplaceSubject.pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
    ).subscribe(image => {
      if (!image) {
        return;
      }

      this.handleImageChanged(image);
    });
  }

  public init(element: HTMLElement): void {
    this.element = element;
  }

  private getCurrentValue(): string {
    if (!this.element.hasAttribute(ATTRIBUTES.RANDOMIZED)) return KEYS.RANDOM;

    if (this.element.matches(`[${ATTRIBUTES.RANDOMIZED}="true"]`)) return KEYS.RANDOM;

    if (this.element.matches(SELECTORS[KEYS.RANDOMIZE_ALL_PORTFOLIOS])) return KEYS.RANDOMIZE_ALL_PORTFOLIOS;
    if (this.element.matches(SELECTORS[KEYS.RANDOM])) return KEYS.RANDOM;
    if (this.element.matches(SELECTORS[KEYS.SINGLE_FROM_PORTFOLIO])) return KEYS.SINGLE_FROM_PORTFOLIO;

    return KEYS.RANDOM;
  }

  public onSubmit() {
    this.buttonsService.enableSaveButton();
    this.buttonsService.enableCancelButton();

    this.onImageTypeChange();

    this.close();
  }

  private onImageTypeChange(): void {
    if (!this.element) return;

    if (this.selectedValue) return this.element.setAttribute(ATTRIBUTES.RANDOMIZED, this.selectedValue);

    return this.element.removeAttribute(ATTRIBUTES.RANDOMIZED);
  }

  public open(): void {
    if (!this.isSplash) return;

    this.initialSrc = this.src;
    this.selectedValue = this.getCurrentValue();

    this.modalsService.open(this.id);

    this.isOpened = true;
  }
  
  private handleImageChanged(image: ImageModel) {
    if (!image) {
      return;
    }

    const src = `https://${image.paths.default}`;
    const srcSet = getSrcSet(image);

    if (!this.element) {
      return console.error('Image changed: no element.');
    }
    
    this.element.setAttribute('data-is-image-set', 'true');

    const isBlog = !!this.element.closest('.blog');

    if (!isBlog) {
      this.buttonsService.enableSaveButton();
    }

    if (this.element.matches('img') && !this.element.matches('[data-responsive-image]')) {
      this.element.setAttribute('src', src);
      this.element.setAttribute('srcset', srcSet);
    }

    if (this.element.matches('img')) {
      this.element.setAttribute('data-default-image', null);
    }

    this.eventsService.dispatchImageChanged({
      element: this.element,
      id: image.id,
      src,
      image,
    }, this.iFrameService.sandboxWindow);
  }

  public onCancel(): void {
    this.src = this.initialSrc;

    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
