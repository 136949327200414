import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as moment from 'moment';

import {PaymentCouponsService} from '../../../../../core/services/payment/coupons/payment-coupons.service';

import {StripeRepeatOptionModel} from '../../../../../core/models/stripe/repeat/stripe-repeat-option.model';
import {StripeCouponModel} from '../../../../../core/models/stripe/coupon/stripe-coupon.model';
import {SelectOption} from '../../../../../core/models/select/option/option.model';

import {REPEAT_OPTIONS, COUPON_TYPE_LIST, COUPON_APPLIED_TO_LIST, DISCOUNT_TYPES_LIST, COUPON_DATE_FORMAT} from './constants';
import {COUPON_TYPES, COUPON_APPLIED_TO, DISCOUNT_TYPES} from '../../../../../core/models/stripe/coupon/constants';

@Component({
  selector: 'app-admin-stripe-coupons',
  templateUrl: './admin-stripe-coupons.component.html',
  styleUrls: ['./admin-stripe-coupons.component.scss'],
})
export class AdminStripeCouponsComponent implements OnDestroy {
  public coupons: StripeCouponModel[] = [];

  public coupon: StripeCouponModel = null;

  public nOfCoupons: number = 1;

  public isCreating: boolean = false;
  public isEditing: boolean = false;
  public isRemoving: boolean = false;

  public isNameValid: boolean = true;
  public isPercentOffValid: boolean = true;
  public isAmountOffValid: boolean = true;
  public isCouponsCountValid: boolean = true;
  public isDateValid: boolean = true;

  private ngUnsubscribe = new Subject();

  public get couponTypes(): { GENERAL: string, UNIQUE: string, DROPDOWN: string } {
    return COUPON_TYPES;
  }

  public get couponTypeList(): SelectOption[] {
    return COUPON_TYPE_LIST;
  }

  public get applyToList(): SelectOption[] {
    return COUPON_APPLIED_TO_LIST;
  }

  public get discountTypes(): { PERCENT: string, AMOUNT: string } {
    return DISCOUNT_TYPES;
  }

  public get discountTypesList(): SelectOption[] {
    return DISCOUNT_TYPES_LIST;
  }

  public get repeatOptions(): StripeRepeatOptionModel[] {
    return REPEAT_OPTIONS;
  }

  public get modalHeader(): string {
    return `${this.isCreating ? 'Create' : 'Update'} Coupon`;
  }

  public get modalSubmitButtonText(): string {
    return this.isCreating ? 'ADD' : 'UPDATE';
  }

  constructor(private service: PaymentCouponsService) {
    this.initDefaultCoupon();

    this.service.couponsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coupons: { list: StripeCouponModel[], hasMore: boolean }) => {
      this.coupons = coupons ? coupons.list : [];
    });
  }

  public onAddButtonClick(): void {
    this.isCreating = true;
  }

  public submitCouponAdding(): void {
    return this.isCreating ? this.addCoupon() : this.editCoupon();
  }

  private addCoupon(): void {
    if (!this.coupon) return;

    this.validateName();
    this.validatePercentOff();
    this.validateAmountOff();
    this.validateCouponsCount();
    this.validateDates();

    if (!this.isNameValid || !this.isPercentOffValid || !this.isAmountOffValid || !this.isCouponsCountValid || !this.isDateValid) return;

    this.isCreating = false;
    this.isEditing = false;

    this.service.addCoupon(this.coupon, this.nOfCoupons).add(() => {
      this.initDefaultCoupon();
    });
  }

  private editCoupon(): void {
    const isValid = this.coupon && this.validateName();

    if (!isValid) return;

    this.isCreating = false;
    this.isEditing = false;

    this.service.updateCoupon(this.coupon).add(() => {
      this.initDefaultCoupon();
    });
  }

  public onDiscountTypeChange(): void {
    this.coupon.amountOff = this.coupon.metadata.discountType === DISCOUNT_TYPES.PERCENT ? void 0 : 5;
    this.coupon.percentOff = this.coupon.metadata.discountType === DISCOUNT_TYPES.PERCENT ? 30 : void 0;
  }

  public cancelCouponAdding(): void {
    this.isCreating = false;
    this.isEditing = false;

    this.initDefaultCoupon();
  }

  public openEditModal(coupon: StripeCouponModel): void {
    this.coupon = coupon.clone();

    this.isEditing = true;
  }

  public openDeleteModal(coupon: StripeCouponModel): void {
    this.coupon = coupon.clone();

    this.isRemoving = true;
  }

  public submitCouponRemoving(): void {
    this.isRemoving = false;

    this.service.deleteCoupon(this.coupon).add(() => {
      this.initDefaultCoupon();
    });
  }

  public cancelCouponRemoving(): void {
    this.isRemoving = false;

    this.initDefaultCoupon();
  }

  private validateName(): boolean {
    this.isNameValid = this.coupon.name.trim().length > 0;

    return this.isNameValid;
  }

  private validatePercentOff(): boolean {
    this.isPercentOffValid = this.coupon.metadata.discountType !== DISCOUNT_TYPES.PERCENT || (this.coupon.percentOff > 0 && this.coupon.percentOff <= 100);

    return this.isPercentOffValid;
  }

  private validateAmountOff(): boolean {
    this.isAmountOffValid = this.coupon.metadata.discountType !== DISCOUNT_TYPES.AMOUNT || this.coupon.amountOff > 0;

    return this.isAmountOffValid;
  }

  private validateCouponsCount(): boolean {
    this.isCouponsCountValid = this.nOfCoupons > 0 && this.nOfCoupons <= 100;

    return this.isCouponsCountValid;
  }

  private validateDates(): boolean {
    const now = moment();
    const start = moment(this.coupon.metadata.startDate, COUPON_DATE_FORMAT);
    const end = moment(this.coupon.metadata.endDate, COUPON_DATE_FORMAT);

    this.isDateValid = start.isValid() && end.isValid() && end.isAfter(start) && end.isAfter(now);

    return this.isDateValid;
  }

  private initDefaultCoupon(): void {
    this.coupon = new StripeCouponModel('', '', REPEAT_OPTIONS[0].id, 30, null);

    this.coupon.metadata.type = COUPON_TYPES.GENERAL;
    this.coupon.metadata.applyTo = COUPON_APPLIED_TO.FIRST_ANNUAL_SUBSCRIPTION;
    this.coupon.metadata.discountType = DISCOUNT_TYPES.PERCENT;
    this.coupon.metadata.startDate = moment().format(COUPON_DATE_FORMAT);
    this.coupon.metadata.endDate = moment().add(1, 'day').format(COUPON_DATE_FORMAT);

    this.nOfCoupons = 1;

    this.isNameValid = true;
    this.isPercentOffValid = true;
    this.isAmountOffValid = true;
    this.isCouponsCountValid = true;
    this.isDateValid = true;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
