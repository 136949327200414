import {Component, Input, Output, EventEmitter, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';

import {PaymentModel} from '../../../../../../core/models/payment/intent/payment.model';

@Component({
  selector: 'app-payment-invoice-row',
  templateUrl: './payment-invoice-row.component.html',
  styleUrls: ['./payment-invoice-row.component.scss'],
})
export class PaymentInvoiceRowComponent implements OnDestroy {
  @Input() invoiceItems: PaymentModel[];

  @Output() retryPayment: EventEmitter<number> = new EventEmitter<number>();

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
  ) {
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
