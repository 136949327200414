import {Injectable} from '@angular/core';

import {ButtonsService} from '../../../buttons/buttons.service';
import { WebsiteDesignerService } from '../../../../../application/main/website-designer/website-designer.service';

import {SELECTORS, TAGS} from './constants';

@Injectable()
export class BlockElementService {
  public isCouldBeWrappedInLink: boolean = false;

  private block: HTMLElement = null;
  private img: HTMLImageElement = null;

  private editableTypes = [
    'ELEMENT',
    'DIVIDER',
  ];

  public set element(element: HTMLElement) {
    this.block = element && element.matches(SELECTORS.BLOCK) ? element : null;
    this.img = this.block ? <HTMLImageElement>this.block.querySelector(SELECTORS.LINKABLE_IMAGE) : null;

    this.isCouldBeWrappedInLink = this.isLinkable();
  }

  public get isWrapped(): boolean {
    return !!this.getParentAnchor();
  }

  constructor(
    private buttonsService: ButtonsService,
    private websiteDesignerService: WebsiteDesignerService,
  ) {
    this.websiteDesignerService.editingSubject.subscribe(options => {
      if (!options || !this.editableTypes.includes(options.editingType)) {
        return;
      }

      this.element = options.element ? $(options.element)[0] : options.element;
    });
  }

  public wrapInAnchor(uri?: string, isCustomLink: boolean = false): void {
    if (!this.img || !this.img.parentElement) {
      console.error(`>> wrapInAnchor: elem is not in DOM tree.`);

      return;
    }

    uri = isCustomLink ? this.prepareLink(uri) : uri;

    const anchor = document.createElement(TAGS.A);

    anchor.classList.add('image-link-wrapper');
    anchor.setAttribute('href', uri);
    anchor.setAttribute('target', isCustomLink ? '_blank' : '');
    anchor.appendChild(this.img.cloneNode());

    this.img.parentElement.replaceChild(anchor, this.img);

    this.img = anchor.children[0] as HTMLImageElement;
  }

  public unwrapFromAnchor(): void {
    const parent: any = this.img.parentElement;

    parent.replaceWith(this.img);

    this.buttonsService.enableSaveButton();
  }

  public getLink(): string {
    const anchor = this.getParentAnchor();

    return anchor.getAttribute('href');
  }

  private prepareLink(uri?: string): string {
    return uri.startsWith('http') ? uri : `http://${uri}`;
  }

  private isLinkable(): boolean {
    return !!this.img && this.img.matches(SELECTORS.LINKABLE_IMAGE);
  }

  private getParentAnchor(): HTMLElement {
    const parent: HTMLElement = this.img.parentElement;

    return parent && parent.tagName === TAGS.A ? parent : null;
  }
}
