import {IStyleOption} from '../option/i-style-option';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {ColorModel} from '../../color/color.model';

import {BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB} from './constants';

export class ArrowsBackgroundColorModel implements IStyleOption {
  public element: HTMLElement;
  public block: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.block = options.block;

    if (!this.block) return;

    const computed = window.getComputedStyle(this.block);

    const value = computed.getPropertyValue(CUSTOM_BACKGROUND_COLOR);

    if (value) return this.setValue(value === 'undefined' ? void 0 : value);

    const colorModel: ColorModel = new ColorModel(computed[BACKGROUND_COLOR]);

    this.value = `rgb(${colorModel.getRGBstring()})`;
  }

  public reset(): void {
    if (!this.isEnabled || !this.block) return;

    this.block.style.removeProperty(CUSTOM_BACKGROUND_COLOR);
    this.block.style.removeProperty(CUSTOM_BACKGROUND_COLOR_RGB);

    this.initDefaultValue();
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.block) return;

    this.value = value;

    this.block.style.setProperty(CUSTOM_BACKGROUND_COLOR, value);

    const colorModel: ColorModel = new ColorModel(value);

    this.block.style.setProperty(CUSTOM_BACKGROUND_COLOR_RGB, colorModel.getRGBstring());
  }

  private initDefaultValue(): void {
    const colorModel: ColorModel = new ColorModel(window.getComputedStyle(this.element)[BACKGROUND_COLOR]);

    this.value = `rgb(${colorModel.getRGBstring()})`;
  }
}
