import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {STYLE_PROPERTIES, CUSTOM_STYLE_PROPERTIES} from './constants';
import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/custom-menu/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/custom-mobile-menu/constants';

export class ActiveColorModel implements IStyleOption {
  public menu: HTMLElement;
  public element: HTMLElement;

  private anotherKey: string;

  private isMobile: boolean = false;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
    this.isMobile = this.key === MOBILE_KEYS.MOBILE_ACTIVE_COLOR;
    this.anotherKey = this.key === DESKTOP_KEYS.ACTIVE_COLOR ? MOBILE_KEYS.MOBILE_ACTIVE_COLOR : DESKTOP_KEYS.ACTIVE_COLOR;
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.menu = options.block;

    if (!this.menu) return;

    this.value = this.getValue();
  }

  public reset(): void {
    if (!this.isEnabled || !this.element || !this.menu) return;

    this.menu.style.removeProperty(CUSTOM_STYLE_PROPERTIES[this.key]);

    this.value = this.getValue();
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.menu) return;

    this.value = value;

    this.menu.style.setProperty(CUSTOM_STYLE_PROPERTIES[this.key], value);
  }

  private getValue(): string {
    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const currentValue: string = computedValue.getPropertyValue(CUSTOM_STYLE_PROPERTIES[this.key]);

    if (currentValue) return currentValue;

    if (!this.isMobile) return computedValue[STYLE_PROPERTIES[this.key]];

    return computedValue.getPropertyValue(CUSTOM_STYLE_PROPERTIES[this.anotherKey]) || computedValue[STYLE_PROPERTIES[this.key]];
  }
}
