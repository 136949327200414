import {RetryPaymentPlanDto} from './retry-payment-plan.dto';

import {AMOUNT_FORMATTERS} from './constants';
import {DURATIONS_TEXTS} from '../subscriptions/constants';

export class RetryPaymentPlanModel {
  constructor(
    public id?: string,
    public amount?: number,
    public amountFormatted?: string,
    public nickname?: string,
    public name?: string,
    public duration?: string,
  ) {}

  public static normalize(res: RetryPaymentPlanDto): RetryPaymentPlanModel {
    if (!res) return null;

    return new RetryPaymentPlanModel(
      res.id,
      res.amount,
      AMOUNT_FORMATTERS.usd(res.amount),
      res.nickname,
      res.name,
      DURATIONS_TEXTS[res.duration],
    );
  }
}
