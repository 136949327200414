import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BagService {

  data: any = {};
  change: BehaviorSubject<any> = new BehaviorSubject<any>(this.data);

  /** Events through change subject:
   *  pageChanged
   *  viewChanged
   *  thumbnailSizeChanged
   *  imagePublishChanged
   *  portfolioImagesChanged
   *  portfolioSelected
   *  selectedPage
   *  splashEnabled
   *  portfoliosChange
   *  replaceImageInBlock
   */

  setImages(images) {
    this.data.images = images;
    this.change.next({ portfolioImagesChanged: true });
  }

}
