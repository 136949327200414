import {Component} from '@angular/core';

import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';

@Component({
  selector: 'app-blog-permissions',
  templateUrl: './blog-permissions.component.html',
  styleUrls: ['./blog-permissions.component.scss'],
})
export class BlogPermissionsComponent {
  public title: string = 'Blog feature is for Professional Accounts Only.';
  public permissions: IPermissionData[] = [
    {
      type: 'permission',
      value: PERMISSIONS.BLOG,
    },
  ];

  constructor() {
  }
}
