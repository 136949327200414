// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.domains .domains-header {
  margin-bottom: 20px;
  color: #333333;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/settings/account/domains/domains.component.scss"],"names":[],"mappings":"AAGE;EACE,mBAAA;EACA,cAAA;AAFJ","sourcesContent":["\n\n.domains {\n  .domains-header {\n    margin-bottom: 20px;\n    color: #333333;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
