import {BasePxStyleOptionModel} from '../../base/base-px-style-option/base-px-style-option.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX, SCROLLED_PROPERTY_NAME, SCROLLED_PROPERTY_NAME_PX, STYLE_PROPERTY_NAME, MIN_SCROLLABLE_VALUE} from './constants';

export class SubtitleFontSizeModel extends BasePxStyleOptionModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, STYLE_PROPERTY_NAME, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    this.isEnabled = !!element;

    if (!this.isEnabled) return;

    super.init(element);
  }

  public onChange(value: number): void {
    super.onChange(value);

    const expectedScrolledFontSize: number = Math.round(this.value / 2) < MIN_SCROLLABLE_VALUE ? MIN_SCROLLABLE_VALUE : this.value / 2;
    const scrolledFontSize = expectedScrolledFontSize > this.value ? this.value : expectedScrolledFontSize;

    this.element.style.setProperty(SCROLLED_PROPERTY_NAME, `${scrolledFontSize}`);
    this.element.style.setProperty(SCROLLED_PROPERTY_NAME_PX, `${scrolledFontSize}px`);
  }
}
