import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

import {GaAccountsDto} from '../../../../models/google/ga-accounts/ga-accounts.dto';
import {GaAccountsModel} from '../../../../models/google/ga-accounts/ga-accounts.model';

@Injectable()
export class GoogleAnalyticsApiService {
  private model = 'google/analytics';

  public statistic: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public webPropertyId: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private httpClient: HttpClient) {
  }

  public fetchStatistic() {
    return this.httpClient.get(`api/${this.model}`);
  }

  public fetchAccounts(): Observable<GaAccountsModel[]> {
    return this.httpClient.get(`api/${this.model}/accounts`).pipe(map((gaAccountsDtos: GaAccountsDto[]) => {
      return gaAccountsDtos.map(gaAccountDto => {
        return GaAccountsDto.normalize(gaAccountDto);
      });
    }));
  }

  public fetchAccessToken() {
    return this.httpClient.get(`api/${this.model}/access-token`);
  }

  public countWebsites(gaAccountId: number) {
    return this.httpClient.put(`api/${this.model}/accounts/${gaAccountId}/websites/count`, {});
  }

  public enable() {
    return this.httpClient.post(`api/${this.model}/website`, {});
  }

  public getWebsiteProfile(websiteId: number): Observable<any> {
    const params = new HttpParams().set('websiteId', `${websiteId}`);

    return this.httpClient.get(`api/${this.model}/profile`, { params });
  }

  public addProfileUserLink({ accountId, webPropertyId, profileId, email }: { accountId: string, webPropertyId: string, profileId: string, email: string }): Observable<any> {
    return this.httpClient.post(`api/${this.model}/profiles/${profileId}/user-links`, { accountId, webPropertyId, email });
  }

  public getProfileUserLinks({ accountId, webPropertyId, profileId }: { accountId: string, webPropertyId: string, profileId: string }): Observable<any> {
    const params = new HttpParams().set('accountId', accountId).set('webPropertyId', webPropertyId);

    return this.httpClient.get(`api/${this.model}/profiles/${profileId}/user-links`, { params });
  }

  public deleteProfileUserLink({ accountId, webPropertyId, profileId, id }: { accountId: string, webPropertyId: string, profileId: string, id: string }): Observable<any> {
    return this.httpClient.request('delete', `api/${this.model}/profiles/${profileId}/user-links/${id}`, { body: { accountId, webPropertyId }, responseType: 'text' });
  }
}
