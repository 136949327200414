import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EducationClassModel} from '../../../../../../models/education/classes/education-class.model';
import {EducationClassDto} from '../../../../../../models/education/classes/education-class.dto';

@Injectable()
export class AdminEducationClassesHttpService {
  private prefix: string = 'api/admin/education/classes';

  constructor(private http: HttpClient) {
  }

  public create(item: EducationClassModel): Observable<any> {
    return this.http.post(`${this.prefix}`, item);
  }

  public getAll(orderBy?: string, isOrderUp?: boolean): Observable<EducationClassModel[]> {
    const params = new HttpParams()
      .set('orderBy', orderBy)
      .set('isOrderUp', `${isOrderUp}`);

    return this.http.get(`${this.prefix}`, { params }).pipe(
      map((res: EducationClassDto[]) => res.map((item: EducationClassDto) => EducationClassDto.normalize(item)))
    );
  }

  public getOne(id: number): Observable<EducationClassModel> {
    return this.http.get(`${this.prefix}/${id}`).pipe(
      map((item: EducationClassDto) => EducationClassDto.normalize(item))
    );
  }

  public update(item: EducationClassModel): Observable<any> {
    return this.http.put(`${this.prefix}/${item.id}`, item);
  }

  public remove(id: number): Observable<any> {
    return this.http.delete(`${this.prefix}/${id}`);
  }
}
