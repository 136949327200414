import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTE, DISABLED_VALUE, ENABLED_VALUE} from './constants';

export class VideoDownloadModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: boolean,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = this.getAttributeValue();
  }

  public onChange(value: boolean): void {
    this.value = value;

    if (!this.isEnabled) return;

    this.element.setAttribute(ATTRIBUTE, value ? ENABLED_VALUE : DISABLED_VALUE);
  }

  private getAttributeValue(): boolean {
    return this.element.getAttribute(ATTRIBUTE) !== DISABLED_VALUE;
  }
}
