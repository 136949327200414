import {IStyleOption} from '../option/i-style-option';

import {DEFAULT_VALUE, OPACITY_PROPERTY} from './constants';

export class PortfolioIntroBackgroundTransparencyModel implements IStyleOption {
  public block: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.block = <HTMLElement>element.closest('.block');

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);
    const propertyValue: number = parseFloat(computedValue.getPropertyValue(OPACITY_PROPERTY)) * 100;
    const value: number = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;

    if (value) return this.setValue(Number.isNaN(value) ? void 0 : value.toString());

    this.value = DEFAULT_VALUE;
  }

  public reset(): void {
    if (!this.isEnabled || !this.block) return;

    this.block.style.removeProperty(OPACITY_PROPERTY);

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);
    const propertyValue: number = parseFloat(computedValue.getPropertyValue(OPACITY_PROPERTY)) * 100;
    const value: number = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;

    if (value) return this.setValue(Number.isNaN(value) ? void 0 : value.toString());

    this.value = DEFAULT_VALUE;
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.block) return;

    this.value = value;

    this.block.style.setProperty(OPACITY_PROPERTY, (1 - parseFloat(value) / 100).toString());
  }
}
