// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
  display: flex;
  align-items: center;
  color: #ebeaea;
}
.back-button .arrow {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  transform: rotate(-45deg);
  border-left: 1px solid;
}
.back-button .text {
  margin-left: 3px;
  font-weight: 300;
  font-size: 13px;
}
.back-button:hover {
  color: white;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/back-button/back-button.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;EACE,qBAAA;EACA,UAAA;EACA,WAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;AACJ;AAEE;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AAGE;EACE,YAAA;EACA,eAAA;AADJ","sourcesContent":[".back-button {\n  display: flex;\n  align-items: center;\n  color: #ebeaea;\n\n  .arrow {\n    display: inline-block;\n    width: 8px;\n    height: 8px;\n    border-top: 1px solid;\n    transform: rotate(-45deg);\n    border-left: 1px solid;\n  }\n\n  .text {\n    margin-left: 3px;\n    font-weight: 300;\n    font-size: 13px;\n  }\n\n  &:hover {\n    color: white;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
