import {SelectOption} from '../../../../core/models/select/option/option.model';

export const FONT_SIZE_OPTIONS = (): SelectOption[] => [
  new SelectOption('8', '8'),
  new SelectOption('12', '12'),
  new SelectOption('14', '14'),
  new SelectOption('20', '20'),
  new SelectOption('24', '24'),
  new SelectOption('32', '32'),
  new SelectOption('40', '40', true),
  new SelectOption('48', '48'),
  new SelectOption('72', '72'),
];
