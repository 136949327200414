import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject} from 'rxjs';

import {AuthService} from '../../../../auth/auth.service';
import {EventsService} from '../events/events.service';
import {IFrameService} from '../../iframe/iframe.service';
import {TextAlertModalService} from '../../../../services/text-alert-modal.service';
import {AppSettingsService} from '../../app-settings/app-settings.service';
import {DiskIsAlmostFullModalService} from '../../../../shared/services/modals/disk-is-almost-full/disk-is-almost-full-modal.service';
import {NeedToUpgradePlanModalService} from '../../../../shared/services/modals/need-to-upgrade-plan/need-to-upgrade-plan-modal.service';

import {AccountModel} from '../../../models/accounts/account.model';
import {SocketMessageModel} from '../../../models/sockets/message/message.model';
import {SocketPublishMessageModel} from '../../../models/sockets/message/publish/publish-message.model';
import {SocketImageManagerMessageModel} from '../../../models/sockets/message/image-manager/image-manager-message.model';
import {AppSettingsModel} from '../../../models/app-settings/app-settings.model';
import {FailedImagesHandlingMessageModel} from '../../../models/sockets/message/failed-images-handling/failed-images-handling-message.model';
import {SocketSubscriptionsMessageModel} from '../../../models/sockets/message/subscriptions/subscriptions-message.model';
import {SocketWebsitesMessageModel} from '../../../models/sockets/message/websites/websites-message.model';
import {SocketImportMessageModel} from '../../../models/sockets/message/import/import-message.model';
import {ISocketPublishMessageDataModel} from '../../../models/sockets/message/publish/i-publish-message-data.model';
import {ISocketInitialDataMessageDataModel} from '../../../models/sockets/message/initial-data/i-initial-data-message-data.model';
import {IBlogUnapprovedCommentsCountModel} from '../../../models/sockets/message/blog-unapproved-comments-count/i-blog-unapproved-comments-count.model';
import {IFailedImagesHandlingMessageDataModel} from '../../../models/sockets/message/failed-images-handling/i-failed-images-handling-message-data.model';
import {ISocketImportMessageDataModel} from '../../../models/sockets/message/import/i-import-message-data.model';
import {ISocketImageManagerMessageDataModel} from '../../../models/sockets/message/image-manager/i-image-manager-message-data.model';
import {ISocketSubscriptionsMessageDataModel} from '../../../models/sockets/message/subscriptions/i-subscriptions-message-data.model';
import {ISocketWebsitesMessageDataModel} from '../../../models/sockets/message/websites/i-websites-message-data.model';
import {IPermissionsUpdatedModel} from '../../../models/sockets/message/permissions-updated/i-permissions-updated.model';

import {PING_TIMEOUT, LATENCY_TIMEOUT, SOCKET_STATUSES, MESSAGE_KEYS, RECONNECT_TIMEOUT} from './constants';

import {environment} from '../../../../../environments/environment';

@Injectable()
export class SocketsService {
  public onSocketStatusChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public subscriptionsDataSubject: BehaviorSubject<ISocketSubscriptionsMessageDataModel> = new BehaviorSubject<ISocketSubscriptionsMessageDataModel>(null);
  public websitesDataSubject: Subject<ISocketWebsitesMessageDataModel> = new Subject<ISocketWebsitesMessageDataModel>();
  public publishDataSubject: Subject<ISocketPublishMessageDataModel> = new Subject<ISocketPublishMessageDataModel>();
  public imageManagerDataSubject: Subject<ISocketImageManagerMessageDataModel> = new Subject<ISocketImageManagerMessageDataModel>();
  public importDataSubject: Subject<ISocketImportMessageDataModel> = new Subject<ISocketImportMessageDataModel>();
  public failedImagesHandlingSubject: Subject<IFailedImagesHandlingMessageDataModel> = new Subject<IFailedImagesHandlingMessageDataModel>();
  public blogUnapprovedCommentsCountSubject: BehaviorSubject<IBlogUnapprovedCommentsCountModel[]> = new BehaviorSubject<IBlogUnapprovedCommentsCountModel[]>(null);
  public isMaintenanceOverlayVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private ws: WebSocket;

  private socketRelatedToUserId: number;

  private account: AccountModel = null;
  private appVersion: string = '';

  private unapprovedCommentsCount: IBlogUnapprovedCommentsCountModel[];

  private timeoutId: number;
  private pingTimeoutId: number;

  private isMessageReceived: boolean = false;

  private handlers = {
    onOpen: this.onOpen.bind(this),
    onMessage: this.onMessage.bind(this),
    onError: this.onError.bind(this),
    onClose: this.onClose.bind(this),
    onAlertModalClose: this.onAlertModalClose.bind(this),
  };

  private messageHandlers = {
    [MESSAGE_KEYS.PUBLISHING]: this.onPublishMessage.bind(this),
    [MESSAGE_KEYS.SUBSCRIPTIONS]: this.onSubscriptionsMessage.bind(this),
    [MESSAGE_KEYS.WEBSITES]: this.onWebsitesMessage.bind(this),
    [MESSAGE_KEYS.IMAGE_MANAGER]: this.onImageManagerMessage.bind(this),
    [MESSAGE_KEYS.IMPORT]: this.onImportMessage.bind(this),
    [MESSAGE_KEYS.PING]: this.onPing.bind(this),
    [MESSAGE_KEYS.FORCE_LOGOUT]: this.onForceLogout.bind(this),
    [MESSAGE_KEYS.PERMISSIONS_UPDATED]: this.onPermissionsUpdated.bind(this),
    [MESSAGE_KEYS.NO_DISK_SPACE]: this.onDiskFull.bind(this),
    [MESSAGE_KEYS.ALMOST_FULL_DISK_SPACE]: this.onDiskAlmostFull.bind(this),
    [MESSAGE_KEYS.BLOG_UNAPPROVED_COMMENTS_COUNT]: this.onBlogUnapprovedCommentsCount.bind(this),
    [MESSAGE_KEYS.MAINTENANCE_OVERLAY]: this.onMaintenanceOverlayVisibilityChange.bind(this),
    [MESSAGE_KEYS.FAILED_IMAGES_HANDLING]: this.onFailedImagesHandling.bind(this),
  };

  private isConnectionCanBeEstablished: boolean = false;

  private get isOpen(): boolean {
    return this.ws && this.ws.readyState === WebSocket.OPEN;
  }

  constructor(
    private authService: AuthService,
    private eventsService: EventsService,
    private iFrameService: IFrameService,
    private appSettingsService: AppSettingsService,
    private textAlertModalService: TextAlertModalService,
    private diskIsAlmostFullModalService: DiskIsAlmostFullModalService,
    private needToUpgradePlanModalService: NeedToUpgradePlanModalService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.isConnectionCanBeEstablished = !!account;

      if (!account) {
        return this.close();
      }

      this.account = account;

      this.create();
    });

    this.appSettingsService.settingsSubject.subscribe((appSettings: AppSettingsModel) => {
      this.appVersion = appSettings && appSettings.version;
    });

    this.eventsService.addFrameListener('blogLoaded', () => {
      this.eventsService.dispatchBlogUnapprovedCommentsCount(this.unapprovedCommentsCount, this.iFrameService.sandboxWindow);
    });
  }

  private onOpen(e: Event) {
    this.initPingTimeout();

    this.onSocketStatusChangeSubject.next(SOCKET_STATUSES.CONNECTED);

    this.requestInitialData();
  }

  public requestInitialData(): void {
    this.authService.requestInitialData().subscribe((data: ISocketInitialDataMessageDataModel) => {
      if (this.appVersion && this.appVersion !== data.version) {
        return window.location.reload();
      }

      this.publishDataSubject.next({ status: data.publishStatus, websiteId: data.websiteId });
      this.isMaintenanceOverlayVisibleSubject.next(data.isMaintenanceOverlayVisible);

      this.initBlogUnapprovedCommentsCount(data.blogUnapprovedCommentsCount);
    });
  }

  private onPing() {
    this.initPingTimeout();

    this.ws.send(JSON.stringify({
      type: MESSAGE_KEYS.PONG,
      key: this.account.socketKey,
    }));
  }

  private onForceLogout(): void {
    this.textAlertModalService.show({
      message: 'You have been logged out.',
      cancelHandler: this.handlers.onAlertModalClose,
      closeHandler: this.handlers.onAlertModalClose,
    });
  }

  private onPermissionsUpdated(message: SocketMessageModel): void {
    const data: IPermissionsUpdatedModel = <IPermissionsUpdatedModel>message.data;

    this.authService.fetchPermissions({
      isForceEmpty: data ? data.isEmpty : false,
    });
  }

  private onDiskFull(): void {
    if (window.location.href.includes('settings/purchase')) {
      return;
    }

    this.needToUpgradePlanModalService.open();
  }

  private onDiskAlmostFull(): void {
    this.diskIsAlmostFullModalService.open();
  }

  private onBlogUnapprovedCommentsCount(message: SocketMessageModel): void {
    const data: IBlogUnapprovedCommentsCountModel[] = <IBlogUnapprovedCommentsCountModel[]>message.data;

    this.initBlogUnapprovedCommentsCount(data);
  }

  private initBlogUnapprovedCommentsCount(data: IBlogUnapprovedCommentsCountModel[]): void {
    this.unapprovedCommentsCount = data;

    this.blogUnapprovedCommentsCountSubject.next(data);

    this.eventsService.dispatchBlogUnapprovedCommentsCount(data, this.iFrameService.sandboxWindow);
  }

  private onMaintenanceOverlayVisibilityChange(message: SocketMessageModel): void {
    if (!this.account.isAdmin && this.isMaintenanceOverlayVisibleSubject.value && !Boolean(message.data)) {
      return window.location.reload();
    }

    this.isMaintenanceOverlayVisibleSubject.next(Boolean(message.data));
  }

  private onFailedImagesHandling(message: FailedImagesHandlingMessageModel) {
    this.failedImagesHandlingSubject.next(message.data);
  }

  private onAlertModalClose(): void {
    this.authService.forceLogout();
  }

  private initPingTimeout(): void {
    window.clearTimeout(this.pingTimeoutId);

    this.pingTimeoutId = window.setTimeout(() => {
      this.ws.close();
    }, PING_TIMEOUT + LATENCY_TIMEOUT);
  }

  private onMessage(e: MessageEvent): void {
    const message: SocketMessageModel = this.parseMessage(e.data);

    if (!this.messageHandlers[message.key]) {
      return console.error(`No message handler for '${message.key}'.`);
    }

    if (!this.isOpen) {
      return console.error(`Message isn't handled due to socket closed: '${message.key}'.`);
    }

    this.isMessageReceived = true;

    this.messageHandlers[message.key](message);
  }

  private onPublishMessage(message: SocketPublishMessageModel) {
    this.publishDataSubject.next(message.data);
  }

  private onSubscriptionsMessage(message: SocketSubscriptionsMessageModel): void {
    this.subscriptionsDataSubject.next(message.data);
  }

  private onWebsitesMessage(message: SocketWebsitesMessageModel): void {
    this.authService.updateCurrentUser();
    
    this.authService.websiteChangedSubject.next(true);

    this.websitesDataSubject.next(message.data);
  }

  private onImageManagerMessage(message: SocketImageManagerMessageModel): void {
    this.imageManagerDataSubject.next(message.data);
  }

  private onImportMessage(message: SocketImportMessageModel) {
    this.importDataSubject.next(message.data);
  }

  private parseMessage(message: string): SocketMessageModel {
    try {
      const msg = JSON.parse(message);

      return new SocketMessageModel(
        msg.key,
        msg.data,
      );
    } catch (e) {
      return null;
    }
  }

  private onError(e: Event) {
    console.dir('Socket onError');

    this.ws = null;
  }

  private onClose() {
    console.dir('Socket Closed');

    if (!this.isMessageReceived) {
      this.onSocketStatusChangeSubject.next(SOCKET_STATUSES.CLOSED);
    }

    this.isMessageReceived = false;

    this.ws = null;

    window.clearTimeout(this.pingTimeoutId);

    this.pingTimeoutId = null;

    this.reestablishConnection();
  }

  private reestablishConnection() {
    if (!this.isConnectionCanBeEstablished) {
      this.onSocketStatusChangeSubject.next(SOCKET_STATUSES.CLOSED);

      return;
    }

    this.timeoutId = window.setTimeout(() => {
      this.create();
    }, RECONNECT_TIMEOUT);
  }

  private create() {
    window.clearTimeout(this.timeoutId);

    console.dir('Socket Create');

    if (this.ws && this.account && this.socketRelatedToUserId === this.account.id) {
      return;
    }

    this.close();

    this.socketRelatedToUserId = this.account.id;

    this.ws = new WebSocket(environment.socketUri);

    console.dir('Socket Created');

    this.ws.onopen = this.handlers.onOpen;
    this.ws.onmessage = this.handlers.onMessage;
    this.ws.onerror = this.handlers.onError;
    this.ws.onclose = this.handlers.onClose;
  }

  private close(): void {
    if (!this.isOpen) {
      return;
    }

    this.ws.onclose = null;
    this.ws.close();
    
    this.onClose();
  }
}
