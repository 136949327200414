import {Component, Output, Input, EventEmitter, Renderer2, ViewChild, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges} from '@angular/core';

import {NestedSelectOption, NestedSelectOptionTypes} from '../../../core/models/select/nested/nested-select-option.model';

@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
})
export class SelectWithSearchComponent implements OnChanges {
  @ViewChild('container') container: ElementRef;
  @ViewChild('textInput') textInput: ElementRef;

  @Output() changeHandler = new EventEmitter<NestedSelectOption | NestedSelectOption[]>();

  @Input() allSelectedText: string = 'All';
  @Input() notSelectedText: string = 'Select...';
  @Input() noItemsText: string = 'No items';
  @Input() notFoundText: string = 'No results found';
  @Input() disabledText: string = 'No items';

  @Input() options: NestedSelectOption[] = [];
  
  @Input() maxHeight: number = void 0;

  @Input() isSearchable: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() isDisabled: boolean;
  @Input() isError: boolean = false;

  public containerClass: string = 'select-with-search';

  public filteredOptions: NestedSelectOption[] = [];

  public boxText: string = '';

  public isExpanded: boolean = false;

  private removeMouseUpListener;

  public get NestedSelectOptionTypes(): typeof NestedSelectOptionTypes {
    return NestedSelectOptionTypes;
  }

  public get selectedOptions(): NestedSelectOption[] {
    return this.options ? this.options.filter((option: NestedSelectOption) => option.isSelected) : [];
  }

  public get isAllSelected(): boolean {
    return !!this.options && this.options.length === this.selectedOptions.length;
  }

  constructor(private renderer: Renderer2,
              private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.initBoxText();
  }

  public onBoxClick(): void {
    if (!this.isExpanded)  return;

    this.isExpanded = !this.isExpanded;

    if (this.removeMouseUpListener) this.removeMouseUpListener();
    
    if (this.isExpanded) this.attachMouseUpListener();

    this.initBoxText();

    this.cdr.detectChanges();
  }

  public onSelect(option: NestedSelectOption): void {
    if (!this.isMultiple) {
      this.isExpanded = false;

      this.options.forEach((item: NestedSelectOption) => {
        item.isSelected = false;
      });

      if (this.removeMouseUpListener) this.removeMouseUpListener();
    }

    this.initOptionState(option, !option.isSelected);
  }
  
  public initOptionState(option: NestedSelectOption, value: boolean): void {
    if (option.isSelected === value) return;

    option.isSelected = value;

    this.changeHandler.emit(this.options);

    this.initBoxText();

    this.cdr.detectChanges();
  }

  public onInstitutionFieldFocus() {
    this.filterOptions('');

    this.isExpanded = true;
    
    this.boxText = '';

    
    if (this.removeMouseUpListener) this.removeMouseUpListener();
    
    this.attachMouseUpListener();

    this.cdr.detectChanges();
  }

  public onInstitutionFieldBlur(e: FocusEvent) {
    this.filterOptions('');

    this.isExpanded = false;
    
    this.initBoxText();
  }

  public onInstitutionFieldChange(): void {
    this.filterOptions(this.textInput.nativeElement.innerText);
  }

  public onInstitutionFieldPaste(): void {
    this.filterOptions(this.textInput.nativeElement.innerText);
  }

  private filterOptions(value: string): void {
    if (!this.options) {
      this.filteredOptions = [];

      return;
    }

    value = value.toLowerCase().trim();

    this.filteredOptions = this.options.filter((option: NestedSelectOption) => option.label.toLowerCase().includes(value));
  }

  public attachMouseUpListener(): void {
    if (this.removeMouseUpListener) this.removeMouseUpListener();

    this.removeMouseUpListener = this.renderer.listen('document', 'mouseup', this.handleClick.bind(this));
  }

  private handleClick(e: Event): void {
    const target: HTMLElement = <HTMLElement>e.target;

    const container: HTMLElement = <HTMLElement>target.closest(`.${this.containerClass}`);

    if (container === this.container.nativeElement) return;

    this.isExpanded = false;

    if (this.removeMouseUpListener) this.removeMouseUpListener();

    this.initBoxText();
  }

  private initBoxText(): void {
    if (this.isDisabled) {
      this.boxText = this.disabledText;

      return;
    }

    if (this.isExpanded || !this.options) {
      return;
    }

    if (!this.isMultiple) {
      const selectedOption: NestedSelectOption = this.options.find((option: NestedSelectOption) => option.isSelected);

      this.boxText = selectedOption ? selectedOption.label : this.notSelectedText;

      return;
    }
    
    if (this.isAllSelected) {
      this.boxText = this.allSelectedText;

      return;
    }

    this.boxText = this.selectedOptions.map((option: NestedSelectOption) => option.label).join(', ') || this.notSelectedText;
  }
}
