import {PermissionModel} from './permission.model';

export class PermissionDto {
  constructor(public ID?: number,
              public Description?: string,
              public Key?: string,
              public Name?: string) {

  }

  public static normalize(res: PermissionDto): PermissionModel {
    if (!res) return null;

    return new PermissionModel(
      res.ID,
      res.Description,
      res.Key,
      res.Name,
    );
  }
}
