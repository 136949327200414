// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.website-designer {
  height: 100%;
  background-color: #3f3e3e;
  display: flex;
}
.website-designer .page-container {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.website-designer .page-container .editor-menu {
  flex: 0;
}
.website-designer .page-container .designer-container {
  flex: 1;
  transition: height ease 0.65s;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.website-designer .details {
  flex: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/website-designer/website-designer.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,yBAAA;EACA,aAAA;AADF;AAGE;EACE,OAAA;EACA,gBAAA;EACA,kBAAA;EAEA,aAAA;EACA,sBAAA;AAFJ;AAII;EACE,OAAA;AAFN;AAKI;EACE,OAAA;EAEA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAJN;AAQE;EACE,OAAA;AANJ","sourcesContent":["\n\n.website-designer {\n  height:100%;\n  background-color: #3f3e3e;\n  display: flex;\n\n  .page-container {\n    flex: 1;\n    overflow: hidden;\n    position: relative;\n\n    display: flex;\n    flex-direction: column;\n\n    .editor-menu {\n      flex: 0\n    }\n\n    .designer-container {\n      flex: 1;\n\n      transition: height ease 0.65s;\n      width: 100%;\n      height: 100%;\n      max-height: 100%;\n    }\n  }\n\n  .details {\n    flex: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
