export class SelectedPageModel {
  constructor(public id: number,
              public type: string,
              public nodeId: number,
              public templateId: number,
              public isHomePage: boolean,
              public isSplash: boolean,
              public isDirectLink: boolean) {}

  public isSameTo(other: SelectedPageModel) {
    return !!other
      && this.id === other.id
      && this.type === other.type
      && this.nodeId === other.nodeId
      && this.templateId === other.templateId
      && this.isHomePage === other.isHomePage
      && this.isSplash === other.isSplash;
  }

  public clear() {
    this.id = null;
    this.type = null;
    this.nodeId = null;
    this.templateId = null;
    this.isHomePage = false;
    this.isSplash = false;
    this.isDirectLink = false;
  }

  public static clone(page: SelectedPageModel): SelectedPageModel {
    return new SelectedPageModel(
      page.id,
      page.type,
      page.nodeId,
      page.templateId,
      page.isHomePage,
      page.isSplash,
      page.isDirectLink,
    );
  }

  public static getClear(): SelectedPageModel {
    return new SelectedPageModel(
      null,
      null,
      null,
      null,
      false,
      false,
      false,
    );
  }
}
