import {Component} from '@angular/core';

import {FroalaVideoIsNotSupportedModalService} from '../../../services/modals/froala-video-is-not-supported/froala-video-is-not-supported-modal.service';

@Component({
  selector: 'app-froala-video-is-not-supported-modal',
  templateUrl: './froala-video-is-not-supported-modal.component.html',
  styleUrls: ['./froala-video-is-not-supported-modal.component.scss'],
})
export class FroalaVideoIsNotSupportedModalComponent {
  public get id(): string {
    return this.service.id;
  }

  constructor(public service: FroalaVideoIsNotSupportedModalService) {}

  public submitHandler(): void {
    this.service.close();
  }
}
