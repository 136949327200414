import {Injectable} from '@angular/core';
import {BlockText} from './constants';

@Injectable()
export class BlockHelpService {
  constructor() {}

  // TODO: refactor taking into account future needs
  getText(textOptions, blockOptions) {
    return textOptions.reduce((obj, key) => {
      return obj && obj[key] ? obj[key] : null;
    }, BlockText);
  }
}
