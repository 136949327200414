import {BlockCategoryDto} from '../category/block-category.dto';

export class BlockTemplateDto {
  constructor(public BlockCategory?: BlockCategoryDto,
              public BlockID?: number,
              public Category?: string,
              public CategoryID?: number,
              public Name?: string,
              public Version?: string,
              public html?: string,
              public PageType?: string,
              public Thumb?: string,
              public ThumbFileName?: string,
              public GifThumbnail?: string,
              public GifThumbnailFileName?: string,
              public OrderNo?: string,
              public Dynamic?: boolean,
              public IsEnabled?: boolean,
              public IsVisible?: boolean,
              public IsSingle?: boolean) {}

  public static normalize(res: BlockTemplateDto): BlockTemplateDto {
    if (!res) return null;

    return new BlockTemplateDto(
      BlockCategoryDto.normalize(res.BlockCategory),
      res.BlockID,
      res.Category,
      res.CategoryID,
      res.Name,
      res.Version,
      res.html,
      res.PageType,
      res.Thumb,
      res.ThumbFileName,
      res.GifThumbnail,
      res.GifThumbnailFileName,
      res.OrderNo,
      res.Dynamic,
      res.IsEnabled,
      res.IsVisible,
      res.IsSingle,
    );
  }
}
