import {SocialNetworkDto} from './social-network.dto';
import {UserSocialNetwork} from '../user-social-network/user-social-network.model';

export class SocialNetwork {
  public isSignInButtonBlocked: boolean = false;

  constructor(public id?: number,
              public name?: string,
              public faIcons?: string[],
              public isSignInAvailable?: boolean,
              public userSocialNetwork?: UserSocialNetwork) {}

  public static listFromResponse(res: SocialNetworkDto[]): SocialNetwork[] {
    return res.map((item: SocialNetworkDto) => SocialNetwork.fromDto(item));
  }

  public static fromDto(res: SocialNetworkDto) {
    return res ? new SocialNetwork(
      res.Id,
      res.Name,
      res.FaIcon ? res.FaIcon.split(',') : [],
      res.IsSignInAvailable,
      UserSocialNetwork.fromDto(res.UserSocialNetwork) || new UserSocialNetwork(),
    ) : null;
  }
}
