import * as moment from 'moment';

import {SubscriptionMetadataDto} from './subscription-metadata.dto';
import {PlanDto} from '../../plan/plan.dto';
import {CardModel} from '../card/card.model';
import {SubscriptionPauseCollectionDto} from './subscription-pause-collection.dto';
import {SubscriptionModel} from './subscription.model';
import {SubscriptionMetadataModel} from './subscription-metadata.model';
import {SubscriptionPauseCollectionModel} from './subscription-pause-collection.model';
import {PlanModel} from '../../plan/plan.model';
import {InvoiceShortDto} from '../invoice/invoice-short.dto';

import {STATUSES, STATUSES_KEYS} from './constants';
import {TIME_FORMATTER, TIME_FORMATTER_2} from '../constants';

export class SubscriptionDto {
  constructor(
    public id?: string,
    public billing?: string,
    public metadata?: SubscriptionMetadataDto,
    public status?: string,
    public statusText?: string,
    public defaultSource?: CardModel,
    public upcomingInvoiceAmount?: number,
    public upcomingInvoiceSubtotal?: number,
    public pauseCollection?: SubscriptionPauseCollectionDto,
    public start?: number,
    public cancelAt?: number,
    public trialEndAt?: number,
    public isCustom?: boolean,
    public isExpired?: boolean,
    public isCancelledAtPeriodEnd?: boolean,
    public isDiscountApplied?: boolean,
    public isTrialPeriodApplied?: boolean,
    public periodStartsAt?: number,
    public periodEndsAt?: number,
    public accountSubscriptionId?: number,
    public plan?: PlanDto,
    public latestInvoice?: InvoiceShortDto,
    public type?: string,
    public websiteId?: number,
  ) {}

  public static normalize(res: SubscriptionDto): SubscriptionModel {
    if (!res) {
      return null;
    }

    const start: string = res.start ? moment.unix(res.start).format(TIME_FORMATTER) : null;
    const cancelAt: string = res.cancelAt ? moment.unix(res.cancelAt).format(TIME_FORMATTER) : null;
    const trialEndAt: string = res.trialEndAt ? moment.unix(res.trialEndAt).format(TIME_FORMATTER) : null;
    const periodStartsAt: string = res.periodStartsAt ? moment.unix(res.periodStartsAt).format(TIME_FORMATTER_2) : null;
    const periodEndsAt: string = res.periodEndsAt ? moment.unix(res.periodEndsAt).format(TIME_FORMATTER_2) : null;

    const upcomingInvoiceAmount: string = res.upcomingInvoiceAmount ? `$${res.upcomingInvoiceAmount / 100}` : SubscriptionDto.getCancelledAmount(res);
    const upcomingInvoiceSubtotal: string = res.upcomingInvoiceSubtotal ? `$${res.upcomingInvoiceSubtotal / 100}` : SubscriptionDto.getCancelledAmount(res);

    const subscriptionMetadata = SubscriptionDto.getMetadata(res);

    return new SubscriptionModel(
      res.id,
      res.billing,
      subscriptionMetadata,
      SubscriptionDto.getStatus(res),
      SubscriptionDto.getStatusText(res),
      upcomingInvoiceAmount,
      upcomingInvoiceSubtotal,
      SubscriptionPauseCollectionModel.normalize(res.pauseCollection),
      start,
      cancelAt,
      trialEndAt,
      CardModel.normalize(res.defaultSource),
      res.isCustom,
      res.isExpired,
      res.isCancelledAtPeriodEnd,
      res.isDiscountApplied,
      res.isTrialPeriodApplied,
      periodStartsAt,
      periodEndsAt,
      res.periodStartsAt,
      res.periodEndsAt,
      res.cancelAt,
      res.trialEndAt,
      res.accountSubscriptionId,
      res.plan ? PlanModel.normalize(res.plan) : null,
      InvoiceShortDto.normalize(res.latestInvoice),
      res.type,
      res.websiteId,
    );
  }

  public static getMetadata(res: SubscriptionDto): SubscriptionMetadataModel {
    if (!res.metadata) {
      return null;
    }

    const { action, planId, planName, addOnId, addOnKey, addOnName, duration, quantity } = res.metadata;

    return new SubscriptionMetadataModel(
      action,
      planId,
      planName,
      addOnId,
      addOnKey,
      addOnName,
      duration,
      Number.parseInt(quantity),
    );
  }

  public static getCancelledAmount(res: SubscriptionDto): string {
    return res.isCancelledAtPeriodEnd ? 'n/a' : '$0.00';
  }

  public static getStatus(res: SubscriptionDto): string {
    if (!res.isCancelledAtPeriodEnd || !res.metadata) {
      return res.status;
    }

    return res.metadata.addOnKey === 'SUPPORT_INCLUDED_ANNUAL_ONE_HOUR' || res.metadata.addOnKey === 'SUPPORT_INCLUDED_ANNUAL_TWO_HOURS' || res.metadata.addOnKey === 'SUPPORT_INCLUDED_DAILY_ONE_HOUR' ? STATUSES_KEYS.CANCELLED : res.status;
  }

  public static getStatusText(res: SubscriptionDto): string {
    const cancelAt: string = moment.unix(res.cancelAt).format(TIME_FORMATTER);

    if (!res.isCancelledAtPeriodEnd || !res.metadata) {
      return STATUSES[res.status] || '';
    }

    if (res.metadata.addOnKey === 'SUPPORT_INCLUDED_ANNUAL_ONE_HOUR' || res.metadata.addOnKey === 'SUPPORT_INCLUDED_ANNUAL_TWO_HOURS' || res.metadata.addOnKey === 'SUPPORT_INCLUDED_DAILY_ONE_HOUR') {
      return STATUSES[STATUSES_KEYS.CANCELLED];
    }

    return `Ends on ${cancelAt}`;
  }
}
