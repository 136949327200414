export const TOUR_KEY = 'IMAGE_MANAGER';

export const SOCKET_ACTIONS = {
  REVIEW_UPDATED: 'REVIEW_UPDATED',
  IMAGE_REVIEW_UPDATED: 'IMAGE_REVIEW_UPDATED',
  IMAGE_UPDATE_SUGGESTION_CREATE: 'IMAGE_UPDATE_SUGGESTION_CREATE',
  IMAGE_UPDATE_SUGGESTION_REMOVE: 'IMAGE_UPDATE_SUGGESTION_REMOVE',
  IMAGE_UPLOAD_START: 'IMAGE_UPLOAD_START',
  IMAGE_UPLOAD_STOP: 'IMAGE_UPLOAD_STOP',
  IMAGE_REORDER_START: 'IMAGE_REORDER_START',
  IMAGE_REORDER_STOP: 'IMAGE_REORDER_STOP',
  IMAGE_REPLACE_START: 'IMAGE_REPLACE_START',
  IMAGE_REPLACE_STOP: 'IMAGE_REPLACE_STOP',
  IMAGE_CROP_START: 'IMAGE_CROP_START',
  IMAGE_CROP_STOP: 'IMAGE_CROP_STOP',
  IMAGE_UNCROP_START: 'IMAGE_UNCROP_START',
  IMAGE_UNCROP_STOP: 'IMAGE_UNCROP_STOP',
  IMAGE_DELETE_START: 'IMAGE_DELETE_START',
  IMAGE_DELETE_STOP: 'IMAGE_DELETE_STOP',
  PORTFOLIO_ACTION: 'PORTFOLIO_ACTION',
};
