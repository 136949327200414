import {Injectable} from '@angular/core';
import {HttpClient as Http} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppSettingsModel} from '../../models/app-settings/app-settings.model';
import {AppSettingsDto} from '../../models/app-settings/app-settings.dto';

@Injectable()
export class AppSettingsService {
  private model = 'app-settings';

  public settingsSubject: BehaviorSubject<AppSettingsModel> = new BehaviorSubject<AppSettingsModel>(null);

  constructor(private httpClient: Http) {
    this.init();
  }

  public init(): void {
    this.fetch().subscribe((res: AppSettingsModel) => {
      this.settingsSubject.next(res);
    });
  }

  private fetch(): Observable<AppSettingsModel> {
    return this.httpClient.get(`api/${this.model}`).pipe(
      map((res: AppSettingsDto) => {
        return AppSettingsDto.normalize(res);
      }),
    );
  }

  public updateChanges(id: number, changes: string): Observable<AppSettingsModel> {
    return this.httpClient.put(`api/${this.model}/version-changes`, { id, changes });
  }

  public setIsMaintenanceOverlayVisible(isMaintenanceOverlayVisible: boolean): void {
    this.httpClient.put(`api/${this.model}/maintenance-overlay`, { isMaintenanceOverlayVisible }).pipe(
      map((res: AppSettingsDto) => AppSettingsDto.normalize(res)),
    ).subscribe((res: AppSettingsModel) => {
      this.settingsSubject.next(res);
    });
  }
}
