export const PING_TIMEOUT = 10000;
export const LATENCY_TIMEOUT = 2500;
export const RECONNECT_TIMEOUT = 5000;

export const SOCKET_STATUSES = {
  CONNECTED: 'CONNECTED',
  CLOSED: 'CLOSED',
};

export const MESSAGE_KEYS = {
  INITIAL_DATA: 'INITIAL_DATA',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  WEBSITES: 'WEBSITES',
  PUBLISHING: 'PUBLISHING',
  IMAGE_MANAGER: 'IMAGE_MANAGER',
  IMAGE_ACTION: 'IMAGE_ACTION',
  IMPORT: 'IMPORT',
  PING: 'PING',
  PONG: 'PONG',
  FORCE_LOGOUT: 'FORCE_LOGOUT',
  PERMISSIONS_UPDATED: 'PERMISSIONS_UPDATED',
  NO_DISK_SPACE: 'NO_DISK_SPACE',
  ALMOST_FULL_DISK_SPACE: 'ALMOST_FULL_DISK_SPACE',
  BLOG_UNAPPROVED_COMMENTS_COUNT: 'BLOG_UNAPPROVED_COMMENTS_COUNT',
  MAINTENANCE_OVERLAY: 'MAINTENANCE_OVERLAY',
  FAILED_IMAGES_HANDLING: 'FAILED_IMAGES_HANDLING',
};

