// TODO: refactor taking into account future needs

const getSocialMediaNotSigned = key => `Please, sign in to your ${key}. You can do this in Settings tab.`;

// SOCIAL_MEDIA
const SM_KEYS = {
  INSTAGRAM: 'Instagram'
};

const SocialMedia = {
  [SM_KEYS.INSTAGRAM]: {
    notAuthorized: getSocialMediaNotSigned(SM_KEYS.INSTAGRAM)
  }
};

export const BlockText = {
  SocialMedia
};
