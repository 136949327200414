import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as $ from 'jquery';

import {SocialNetworksService} from '../../../../../core/services/social-networks/social-networks.service';
import {UserSocialNetworksService} from '../../../../../core/services/user-social-networks/user-social-networks.service';
import {IFrameService} from '../../../../../core/services/iframe/iframe.service';
import {EventsService} from '../../../../../core/services/interaction/events/events.service';
import {ButtonsService} from '../../../../../core/services/buttons/buttons.service';

import {SocialNetwork} from '../../../../../core/models/social-networks/social-network/social-network.model';
import {UserSocialNetworkDto} from '../../../../../core/models/social-networks/user-social-network/user-social-network.dto';
import {UserSocialNetwork} from '../../../../../core/models/social-networks/user-social-network/user-social-network.model';

import {WebsiteDesignerService} from '../../../website-designer/website-designer.service';

import {FONT_SIZES} from '../../../../../core/models/styles/native-setup/font-size/constants';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent implements OnInit, OnDestroy {
  public linkPlaceholder = 'http://www.example.com/';

  editingGroupPosition = null;

  isOpened = false;

  mousedownHandler = this.closeDropdown.bind(this);

  public socialNetworks: SocialNetwork[];
  public activeMedias: SocialNetwork[];

  public isAutosaveShown: boolean = false;

  public element: HTMLElement = null;

  private isMobileSocialMedia: boolean = false;

  public color: string = '';
  public hoverColor: string = '';
  public fontSize: Number = 14;

  private linkChangeTimeout;

  private ngUnsubscribe = new Subject<void>();

  get newOrderNo() {
    const { activeMedias } = this;
    if (!activeMedias || this.activeMedias.length <= 1) return 1;
    return activeMedias[activeMedias.length - 1].userSocialNetwork.orderNo + 1;
  }

  get userSocialNetworks(): UserSocialNetworkDto[] {
    if (!this.socialNetworks) return [];
    return this.socialNetworks.map(socialNetwork => UserSocialNetwork.toDto(socialNetwork.userSocialNetwork));
  }

  get fontSizes(): number[] {
    return FONT_SIZES;
  }

  constructor(private websiteDesignerService: WebsiteDesignerService,
              private iFrameService: IFrameService,
              private buttonsService: ButtonsService,
              private socialNetworksService: SocialNetworksService,
              private eventsService: EventsService,
              private userSocialNetworksService: UserSocialNetworksService,
              private cdr: ChangeDetectorRef) {
    this.socialNetworksService.list.pipe(takeUntil(this.ngUnsubscribe)).subscribe(list => {
      this.socialNetworks = list;
      this.initActiveMedias();
    });
  }

  public ngOnInit(): void {
    this.websiteDesignerService.editingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(options => {
      this.element = options && options.element && options.element.matches('[data-social-icon-container]') ? options.element : null;

      this.cdr.detectChanges();

      if (!this.element) return;

      this.isMobileSocialMedia = !!this.element.closest('aside');

      this.initStyles();

      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    $(document).off('mouseup', this.mousedownHandler);

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeDropdown(event) {
    if (event.target.classList.contains('dropdown-head')) return;

    this.isOpened = false;
    $(document).off('mouseup', this.mousedownHandler);
  }

  selectIconFromGroup(socialNetwork, icon) {
    socialNetwork.userSocialNetwork.selectedIcon = icon;
    this.editingGroupPosition = -1;
    this.saveChanges(socialNetwork);
  }

  getSocialNetworkIcons(icon) {
    const socialNetwork = this.socialNetworks.find((socialNetwork: SocialNetwork) => socialNetwork.faIcons.includes(icon));

    if (socialNetwork) return socialNetwork.faIcons;

    return icon instanceof Array ? icon : [icon];
  }

  onMediaDropdownItemClick(socialNetwork: SocialNetwork) {
    this.isOpened = false;
    this.addMedia(socialNetwork);
    $(document).off('mouseup', this.mousedownHandler);
  }

  addMedia(socialNetwork: SocialNetwork) {
    socialNetwork.userSocialNetwork.selectedIcon = socialNetwork.faIcons[0];
    socialNetwork.userSocialNetwork.isShown = true;
    socialNetwork.userSocialNetwork.orderNo = this.newOrderNo;
    socialNetwork.userSocialNetwork.uri = socialNetwork.userSocialNetwork.uri || null;
    this.saveChanges(socialNetwork);
  }

  removeMedia(socialNetwork: SocialNetwork) {
    socialNetwork.userSocialNetwork.isShown = false;
    this.saveChanges(socialNetwork);
  }

  onChangeLink(event, socialNetwork: SocialNetwork) {
    socialNetwork.userSocialNetwork.uri = event.target.value;

    clearTimeout(this.linkChangeTimeout);
    this.linkChangeTimeout = setTimeout(() => this.saveChanges(socialNetwork), 400);
  }

  private saveChanges(socialNetwork: SocialNetwork) {
    this.userSocialNetworksService.save(socialNetwork).add(() => {
      this.eventsService.dispatchSocialNetworksChanged({ socialNetworks: this.userSocialNetworks }, this.iFrameService.sandboxWindow);

      this.initActiveMedias();
      this.showAutosaveMessage();
    });
  }

  private initActiveMedias(): SocialNetwork[] {
    if (!this.socialNetworks) return [];

    const addedSocialNetworks = this.socialNetworks.filter(socialNetwork => socialNetwork.userSocialNetwork.isShown);
    this.activeMedias = addedSocialNetworks.sort((sn1, sn2) => sn1.userSocialNetwork.orderNo - sn2.userSocialNetwork.orderNo);
  }

  public onFontSizeChange() {
    if (!this.element) return;

    this.element.style.fontSize = `${this.fontSize}px`;
    this.eventsService.dispatchResize(this.iFrameService.sandboxWindow);
    this.buttonsService.enableSaveButton();
  }

  public onFontSizeReset() {
    if (!this.element) return;

    this.element.style.removeProperty('font-size');
    this.buttonsService.enableSaveButton();

    this.initStyles();

    this.cdr.detectChanges();
  }

  public onColorChange() {
    if (!this.element) return;

    this.element.style.color = this.color;
    this.buttonsService.enableSaveButton();
  }

  public onColorReset() {
    if (!this.element) return;

    this.element.style.removeProperty('color');
    this.buttonsService.enableSaveButton();

    this.initStyles();

    this.cdr.detectChanges();
  }

  public onHoverColorChange() {
    if (!this.element) return;

    this.element.style.setProperty('--element-social-media-hover-color', this.hoverColor);
    this.buttonsService.enableSaveButton();
  }

  public onHoverColorReset() {
    if (!this.element) return;

    this.element.style.removeProperty('--element-social-media-hover-color');
    this.buttonsService.enableSaveButton();

    this.initStyles();

    this.cdr.detectChanges();
  }

  private initStyles() {
    const styles = window.getComputedStyle(this.element);

    this.color = styles['color'];
    this.fontSize = Number.parseInt(styles['font-size']);

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.hoverColor = computedStyles.getPropertyValue('--element-social-media-hover-color') || computedStyles.getPropertyValue('color');
  }

  private showAutosaveMessage() {
    this.isAutosaveShown = true;

    setTimeout(() => this.isAutosaveShown = false, 2500);
  }

  public onDropdownHeadClick() {
    this.isOpened = !this.isOpened;

    if (this.isOpened) return $(document).on('mouseup', this.mousedownHandler);

    $(document).off('mouseup', this.mousedownHandler);
  }
}
