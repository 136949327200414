import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CaptchaDataModel} from '../../../../models/captcha-data/captcha-data.model';
import {CaptchaDataDto} from '../../../../models/captcha-data/captcha-data.dto';
import {DomainModel} from '../../../../models/domain/domain.model';

@Injectable()
export class CaptchaHttpService {
  private prefix: string = 'api';

  constructor(private http: HttpClient) {}

  public fetchExisting(websiteId: number): Observable<CaptchaDataModel> {
    return this.http.get(`${this.prefix}/websites/${websiteId}/domains/captchas`).pipe(
      map((res: CaptchaDataDto) => CaptchaDataDto.normalize(res)),
    );
  }

  public toggleCaptchaVisibility(websiteId: number, isVisible): Observable<any> {
    return this.http.put(`${this.prefix}/websites/${websiteId}/domains/captchas/visibility`, { isVisible });
  }

  public saveDomains(websiteId: number, domains: DomainModel[]): Observable<any> {
    return this.http.put(`${this.prefix}/websites/${websiteId}/domains/captchas`, { domains });
  }
}
