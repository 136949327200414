import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class ComparePlansModalService {
  public readonly id = 'compare-plans-modal';

  public isOpened = false;

  public constructor(private modalsService: ModalsService) {
  }

  public open(): void {
    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
