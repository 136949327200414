export const ATTRIBUTES = {
  LINKABLE_BLOCK: 'data-linkable-block',
  LINKABLE_IMAGE: 'data-linkable-image',
};

export const SELECTORS = {
  BLOCK: `.block`,
  LINKABLE_BLOCK: `[${ATTRIBUTES.LINKABLE_BLOCK}="true"]`,
  LINKABLE_IMAGE: `[${ATTRIBUTES.LINKABLE_IMAGE}="true"]`,
};

export const TAGS = {
  A: 'A',
};
