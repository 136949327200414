import {Component} from '@angular/core';

import {PublishingInProgressModalService} from '../../../services/modals/publishing-in-progress/publishing-in-progress-modal.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-publishing-in-progress-modal',
  templateUrl: './publishing-in-progress-modal.component.html',
  styleUrls: ['./publishing-in-progress-modal.component.scss'],
})
export class PublishingInProgressModalComponent {
  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.closeModal.bind(this),
    }
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: PublishingInProgressModalService) {
  }

  public closeModal(): void {
    this.service.close();
  }
}
