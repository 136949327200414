import {Injectable} from '@angular/core';

@Injectable()
export class CoreService {
  public constructor() {
  }

  public getDeferredPromise(): {
    promise: Promise<any>,
    resolve: (value: any) => void,
    reject: (reason?: any) => void,
  } {
    let resolve, reject;
  
    const promise = new Promise((resolver, rejecter) => {
      resolve = resolver;
      reject = rejecter;
    });
  
    return {
      promise,
      resolve,
      reject,
    };
  }
}
