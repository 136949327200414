import {Injectable} from '@angular/core';

import {Subscription, BehaviorSubject} from 'rxjs';

import {EducationTeachersWebsitesHttpService} from '../../../interaction/http/education/teachers/websites/ education-teachers-websites-http.service';
import {PaymentSubscriptionsService} from '../../../payment/subscriptions/payment-subscriptions.service';
import {SocketsService} from '../../../interaction/sockets/sockets.service';

import {CourseWebsiteModel} from '../../../../models/websites/course-website.model';
import {SubscriptionModel} from '../../../../models/payment/subscriptions/subscription.model';
import {EducationTeacherDataModel} from '../../../../models/education/teachers/education-teacher-data.model';
import {ISocketWebsitesMessageDataModel} from '../../../../models/sockets/message/websites/i-websites-message-data.model';

@Injectable()
export class EducationTeachersWebsitesService {
  public dataSubject: BehaviorSubject<EducationTeacherDataModel> = new BehaviorSubject<EducationTeacherDataModel>(null);
  public listSubject: BehaviorSubject<CourseWebsiteModel[]> = new BehaviorSubject<CourseWebsiteModel[]>(null);
  public websiteDetailsSubject: BehaviorSubject<CourseWebsiteModel> = new BehaviorSubject<CourseWebsiteModel>(null);

  public isDataFetched: boolean = false;

  private subscription: SubscriptionModel;

  private websitesSocketDataHandlers = {
    'WEBSITES_LIST_CHANGED': this.initData.bind(this),
    'ACTIVE_WEBSITE_CHANGED': this.initData.bind(this),
  };

  constructor(
    private httpService: EducationTeachersWebsitesHttpService,
    private socketsService: SocketsService,
    private paymentSubscriptionsService: PaymentSubscriptionsService
  ) {
    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe((subscription: SubscriptionModel) => {
      this.subscription = subscription;

      this.initData();
    });

    this.socketsService.websitesDataSubject.subscribe((data: ISocketWebsitesMessageDataModel) => {
      this.onWebsitesSocketData(data);
    });
  }

  private onWebsitesSocketData(data: ISocketWebsitesMessageDataModel): void {
    if (!data || !this.websitesSocketDataHandlers[data.key]) {
      return;
    }

    this.websitesSocketDataHandlers[data.key](data);
  }

  private initData(): void {
    if (!this.subscription || !this.subscription.isEducator) {
      this.isDataFetched = this.paymentSubscriptionsService.isCurrentSubscriptionFetched;

      this.listSubject.next(null);

      return;
    }

    this.isDataFetched = false;

    this.fetchData(); 
    this.fetchList(); 
  }

  public fetchData(): Subscription {
    return this.httpService.getData().subscribe((res: EducationTeacherDataModel) => {
      this.isDataFetched = true;

      this.dataSubject.next(res);
    });
  }

  public fetchList(): Subscription {
    return this.httpService.getWebsites().subscribe((res: CourseWebsiteModel[]) => {
      this.listSubject.next(res);
    });
  }
}
