import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {PostTemplateDto} from '../../models/post/post-template.dto';
import {PostTemplateModel} from '../../models/post/post-template.model';

@Injectable()
export class PostTemplatesService {
  public postTemplatesSubject = new BehaviorSubject<PostTemplateModel[]>([]);
  public selectedPostTemplateSubject = new BehaviorSubject<PostTemplateModel>(null);

  constructor(private http: HttpClient) {
    this.findPostTemplates();

    this.postTemplatesSubject.subscribe(styles => {
      if (styles) this.selectedPostTemplateSubject.next(styles[0]);
    });
  }

  private findPostTemplates(): Subscription {
    return this.http.get('/api/post-templates').pipe(take(1)).subscribe((res: PostTemplateDto[]) => {
      this.postTemplatesSubject.next(res.map(item => PostTemplateDto.normalize(item)));
    });
  }

  public selectPostTemplate(postTemplate: PostTemplateModel) {
    this.selectedPostTemplateSubject.next(postTemplate);
  }
}
