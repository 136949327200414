export class ImageProcessModel {
  constructor(public processId?: number,
              public processName?: string,
              public type?: number,
              public userId?: number) {}

  public static clone(process: ImageProcessModel): ImageProcessModel {
    if (!process) return null;

    return new ImageProcessModel(
      process.processId,
      process.processName,
      process.type,
      process.userId,
    );
  }
}
