import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../../../auth/auth.service';
import {BlocksService} from '../../../../../../application/sidebar-short/sidebar/blocks/blocks.service';

import {AccountModel} from '../../../../../models/accounts/account.model';
import {BlocksChangesDto} from '../../../../../models/blocks-changes/blocks-changes.dto';
import {BlocksChangesModel} from '../../../../../models/blocks-changes/blocks-changes.model';
import {BlocksTemplatesHttpService} from '../blocks-templates/blocks-templates-http.service';
import {NavigationService} from '../../../../../../services/navigation.service';

@Injectable()
export class BlocksChangesHttpService implements OnDestroy {
  private prefix: string = 'api/admin';
  private model: string = 'blocks';

  public isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public blocksChangesSubject: BehaviorSubject<BlocksChangesModel[]> = new BehaviorSubject<BlocksChangesModel[]>([]);

  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient,
              private authService: AuthService,
              private navigationService: NavigationService,
              private blocksService: BlocksService,
              private blocksTemplatesHttpService: BlocksTemplatesHttpService) {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      if (!account || !account.isAdmin) return;

      this.getAll();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getAll() {
    this.isLoadedSubject.next(false);

    const onSuccess = this.handleResponse.bind(this);
    const onError = this.handleError.bind(this);

    return this.http.get(`${this.prefix}/${this.model}/changes`).pipe(
      catchError(e => {
        this.handleError();

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  public update(data: BlocksChangesModel[]) {
    const body = data.map(item => {
      return {
        id: item.id,
        blockId: item.blockId,
        severityId: item.severityId,
      };
    });

    this.isLoadedSubject.next(false);

    const onSuccess = this.handleResponse.bind(this);
    const onError = this.handleError.bind(this);

    return this.http.put(`${this.prefix}/${this.model}/changes`, body).pipe(
      catchError(e => {
        this.handleError();
        
        return throwError(() => e);
      }),
    ).subscribe(onSuccess).add(() => {
      this.getAll();
      
      this.blocksTemplatesHttpService.getAll();
      this.blocksService.fetchOutdatedBlocks();
    });
  }

  private handleResponse(res: BlocksChangesDto[]) {
    const blocksTemplates: BlocksChangesModel[] = res.map((block: BlocksChangesDto) => BlocksChangesDto.normalize(block));
    this.blocksChangesSubject.next(blocksTemplates);
    this.isLoadedSubject.next(true);

    if (!blocksTemplates || !blocksTemplates.length) return;

    this.navigationService.toBlocksChanges();
  }

  private handleError() {
    this.isLoadedSubject.next(true);
  }
}
