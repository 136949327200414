import {StripeRepeatOptionModel} from '../../../../../core/models/stripe/repeat/stripe-repeat-option.model';
import {SelectOption} from '../../../../../core/models/select/option/option.model';

import {COUPON_TYPES, COUPON_APPLIED_TO, DISCOUNT_TYPES} from '../../../../../core/models/stripe/coupon/constants';

export const REPEAT_OPTIONS: StripeRepeatOptionModel[] = [
  new StripeRepeatOptionModel('once', 'Once'),
  new StripeRepeatOptionModel('forever', 'Forever'),
];

export const COUPON_TYPE_LIST: SelectOption[] = [
  new SelectOption('General Code', COUPON_TYPES.GENERAL, true),
  new SelectOption('Unique Code', COUPON_TYPES.UNIQUE, false),
  new SelectOption('Dropdown Code', COUPON_TYPES.DROPDOWN, false),
];

export const COUPON_APPLIED_TO_LIST: SelectOption[] = [
  new SelectOption('FIRST Annual Subscription', COUPON_APPLIED_TO.FIRST_ANNUAL_SUBSCRIPTION, true),
  new SelectOption('FIRST Monthly Subscription', COUPON_APPLIED_TO.FIRST_MONTH_SUBSCRIPTION, false),
  new SelectOption('Any Payment', COUPON_APPLIED_TO.ANY_PAYMENT, false),
];

export const DISCOUNT_TYPES_LIST: SelectOption[] = [
  new SelectOption('Percent', DISCOUNT_TYPES.PERCENT, true),
  new SelectOption('Amount', DISCOUNT_TYPES.AMOUNT, false),
];

export const COUPON_DATE_FORMAT = 'YYYY-MM-DD';
