export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `알아차리기도 전에 우리는 육지와 멀어졌다.`,
      `하늘을 나는 배 저 아래에서 빛나는 초승달.`,
      `돌아가는 길은 외로운 여행이 될 듯 했다.`,
    ],
    [KEYS.ALPHABET]: [
      '가개갸거게겨고괴괘교구귀궤규그긔기나내냐너네녀노뇌놰뇨누뉘눼뉴느늬니다대댜더데뎌도되돼됴두뒤뒈류드듸디라래랴러레려로뢰뢔료루뤼뤠류르릐리마매먀머메며모뫼뫠묘무뮈뭬뮤므믜미바배뱌버베벼보뵈봬뵤부뷔붸뷰브븨비사새샤서세셔소쇠쇄쇼수쉬쉐슈브븨비아애야어에여오외왜요우위웨유으의이자재쟈저제져조죄좨죠주쥐줴쥬즈즤지차채챠처체쳐초최쵀쵸추취췌츄츠츼치카캐캬커케켜코쾨쾌쿄쿠퀴퀘큐크킈키타태탸터테텨토퇴퇘툐투튀퉤튜트틔티파패퍄퍼페펴포푀퐤표푸퓌풰퓨프픠피하해햐허헤혀호회홰효후휘훼휴흐희히?“!”(%)[#]{@}/&<-+÷=>®©:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `대기권에서 높은 곳까지 올라왔던 것 같다. 하늘은 새까맣고, 별들은 반짝이길 멈췄기 때문이다. 언덕 위에 서 있는 사람에게 바다의 수평선이 자기의 눈높이로 보이는 것과 같이, 우리 아래로 담비털처럼 매끄러운 구름이 끝없이 펼쳐져 있었고, 우주선은 검고 거대한 구체의 한가운데에 떠 있는 것처럼 보였다. 그리고 이 우주선의 윗부분에는 은이 흩뿌려져 있는 듯했다.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
