import {BasePxStyleOptionModel} from '../../base/base-px-style-option/base-px-style-option.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX, SCROLLED_PROPERTY_NAME, SCROLLED_PROPERTY_NAME_PX, STYLE_PROPERTY_NAME} from './constants';
import {PROPERTY_NAME as FONT_SIZE_PROPERTY_NAME, SCROLLED_PROPERTY_NAME as FONT_SIZE_SCROLLED_PROPERTY_NAME} from '../font-size/constants';

export class TitleLineHeightModel extends BasePxStyleOptionModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, STYLE_PROPERTY_NAME, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    this.isEnabled = !!element;

    if (!this.isEnabled) return;

    super.init(element);
  }

  public onChange(value: number): void {
    super.onChange(value);

    const fontSize: number = Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(FONT_SIZE_PROPERTY_NAME));
    const scrolledFontSize: number = Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(FONT_SIZE_SCROLLED_PROPERTY_NAME));

    const scrolledLineHeight: number = this.value / (fontSize / scrolledFontSize);

    if (Number.isNaN(scrolledLineHeight)) return;

    this.element.style.setProperty(SCROLLED_PROPERTY_NAME, `${scrolledLineHeight}`);
    this.element.style.setProperty(SCROLLED_PROPERTY_NAME_PX, `${scrolledLineHeight}px`);
  }
}
