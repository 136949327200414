import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';

import {SupportMetaItemDto} from '../../../../../models/accounts/support-meta/support-meta-item.dto';
import {SupportMetaItemModel} from '../../../../../models/accounts/support-meta/support-meta-item.model';

@Injectable()
export class AdminSupportMetaHttpService {
  private prefix: string = 'api/admin/support-meta';

  constructor(private http: HttpClient) {
  }

  public create(userId: number, item: SupportMetaItemModel): Observable<any> {
    return this.http.post(`${this.prefix}/${userId}`, item);
  }

  public getAllForUser(userId: number, orderByKey: string, isOrderUp: boolean): Observable<SupportMetaItemModel[]> {
    const params = new HttpParams()
      .set('orderByKey', orderByKey)
      .set('isOrderUp', `${isOrderUp}`);

    return this.http.get(`${this.prefix}/${userId}`, { params }).pipe(
      map((res: SupportMetaItemDto[]) => res.map((item: SupportMetaItemDto) => SupportMetaItemDto.normalize(item)))
    );
  }

  public update(userId: number, item: SupportMetaItemModel): Observable<any> {
    return this.http.put(`${this.prefix}/${userId}/${item.id}`, item);
  }

  public updateNotes(userId: number, item: SupportMetaItemModel): Observable<any> {
    return this.http.put(`${this.prefix}/${userId}/${item.id}/notes`, item);
  }

  public remove(userId: number, item: SupportMetaItemModel): Observable<any> {
    return this.http.delete(`${this.prefix}/${userId}/${item.id}`);
  }
}
