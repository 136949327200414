export class ImageLabelDto {
  constructor(
    public imageId: number,
    public websiteId: number,
    public portfolioId: number,
    public title: string,
    public isTitleDefault: boolean,
    public caption: string,
    public isCaptionDefault: boolean,
    public purchaseInfo: string,
    public isPurchaseInfoDefault: boolean,
    public year: string,
    public isYearDefault: boolean,
    public copyright: string,
    public isCopyrightDefault: boolean,
    public pricingStructure: string,
    public isPricingStructureDefault: boolean,
    public isPurchaseLinkVisibilityDefault: boolean,
    public isInquireLinkVisible: boolean,
    public isPurchaseButtonVisible: boolean,
    public isPublished: boolean,
    public isInfoVisible: boolean
  ) {}
}
