import {Component, Input, ViewChild} from '@angular/core';

import {FullscreenManager} from '../../services/fullscreen-manager.service';
import {BlocksService} from '../../application/sidebar-short/sidebar/blocks/blocks.service';
import {EventsService} from '../../core/services/interaction/events/events.service';
import {IFrameService} from '../../core/services/iframe/iframe.service';

const requestFSMethodNameVariants = ['webkitRequestFullscreen', 'requestFullscreen', 'mozRequestFullScreen'];

const getRequestFullscreenMethod = ($el) => {
  for (const methodName of requestFSMethodNameVariants) {
    const method = $el.prop(methodName);

    if (method) {
      return method.bind($el.get(0));
    }
  }

  throw new Error('Full screen is not supported');
};

const cancelFSMethodNameVariants = ['webkitCancelFullScreen', 'mozCancelFullScreen', 'cancelFullScreen'];

const getCancelFullscreenMethod = () => {
  for (const methodName of cancelFSMethodNameVariants) {
    if (document[methodName]) {
      return document[methodName].bind(document);
    }
  }

  throw new Error('Full screen is not supported');
};

@Component({
  selector: 'app-fullscreen-wrapper',
  templateUrl: './fullscreen-wrapper.component.html',
  styleUrls: ['./fullscreen-wrapper.component.scss']
})
export class FullscreenWrapperComponent {
  @Input() isContentVisible = false;

  @ViewChild('iconWrapper') iconWrapper;

  public get isExhibitionPortfolio(): boolean {
    return this.blocksService.isExhibitionPortfolio;
  }

  public get isPortfolioZoom(): boolean {
    return this.blocksService.isPortfolioZoom;
  }

  constructor(private fullscreenManager: FullscreenManager,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private blocksService: BlocksService) {
    document.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this), false);
    document.addEventListener('webkitfullscreenchange', this.onFullscreenChange.bind(this), false);
    document.addEventListener('mozfullscreenchange', this.onFullscreenChange.bind(this), false);
  }

  private onFullscreenChange(): void {
    const root: HTMLElement = (<any>document).webkitFullscreenElement || (<any>document).mozFullScreenElement;
    const isOpen: boolean = !!root;

    this.fullscreenManager.isOpenSubject.next(isOpen);

    this.eventsService.dispatchEditorFullscreenToggle({
      isFullscreen: isOpen,
      root,
    }, this.iFrameService.sandboxWindow);
  }

  public handleFullscreenIconClick(): void {
    const isOpen: boolean = !!(<any>document).webkitFullscreenElement || !!(<any>document).mozFullScreenElement;

    return isOpen ? this.cancelFullscreenMode() : this.activateFullscreenMode();
  }

  private activateFullscreenMode() {
    const $el = $(this.iconWrapper.nativeElement);

    try {
      const requestMethod = getRequestFullscreenMethod($el);

      requestMethod();

      this.fullscreenManager.isOpenSubject.next(true);

      this.eventsService.dispatchEditorFullscreenToggle({
        isFullscreen: true,
        root: this.iconWrapper.nativeElement,
      }, this.iFrameService.sandboxWindow);
    } catch (e) {
      alert('Full screen mode is not supported');
    }
  }

  private cancelFullscreenMode() {
    try {
      const cancelMethod = getCancelFullscreenMethod();

      setTimeout(() => {
        cancelMethod();

        this.fullscreenManager.isOpenSubject.next(false);

        this.eventsService.dispatchEditorFullscreenToggle({ isFullscreen: false }, this.iFrameService.sandboxWindow);
      });
    } catch (e) {
      alert('Full screen mode is not supported');
    }
  }
}
