import {Component, Input} from '@angular/core';

import {ListItemModel} from '../../../core/models/list/list-item.model';

@Component({
  selector: 'app-multilevel-list',
  templateUrl: './multilevel-list.component.html',
  styleUrls: ['./multilevel-list.component.scss'],
})
export class MultilevelListComponent {
  @Input() items: ListItemModel[] = [];

  constructor() {
  }
}
