import {TemplateModel} from './template.model';
import {TemplatePreviewDto} from './previews/template-preview.dto';

export class TemplateDto {
  constructor(public TemplateID?: number,
              public Name?: string,
              public PortfolioLayout?: number,
              public Description?: string,
              public TemplateCategoryID?: number,
              public OrderNo?: number,
              public Thumbnail?: string,
              public MenuType?: string,
              public Preview?: TemplatePreviewDto) {}

  public static normalize(res: TemplateDto): TemplateModel {
    if (!res) return null;

    return new TemplateModel(
      res.TemplateID,
      res.Name,
      res.PortfolioLayout,
      res.Description,
      res.TemplateCategoryID,
      res.OrderNo,
      res.Thumbnail,
      res.MenuType,
      TemplatePreviewDto.normalize(res.Preview),
    );
  }
}
