import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

import {Subject} from 'rxjs';

import {AdminEducationTeachersService} from '../../../../../../core/services/admin/education/teachers/admin-education-teachers.service';

import {EducationTeacherModel} from '../../../../../../core/models/education/teachers/education-teacher.model';

@Component({
  selector: 'app-admin-education-delete-teacher-modal',
  templateUrl: './delete-teacher-modal.component.html',
  styleUrls: ['./delete-teacher-modal.component.scss']
})
export class AdminEducationDeleteTeacherModalComponent implements OnDestroy {
  @Input() teacher: EducationTeacherModel;

  @Output() closeHandler = new EventEmitter<void>();

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationTeachersService) {
  }

  public submit(): void {
    this.service.remove(this.teacher);

    this.closeHandler.emit();
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
