import {Injectable} from '@angular/core';

import {Observable, Subscriber, Subject, combineLatest} from 'rxjs';

import {AuthService} from '../../../../auth/auth.service';

import {AccountModel} from '../../../models/accounts/account.model';
import {PermissionModel} from '../../../models/permission/permission.model';
import {IPermissionData} from '../../../models/permission/i-permission-data';

@Injectable()
export class PermissionsService {
  public isAdmin: boolean = false;
  public isUserImported: boolean = false;

  public beforeLeaveSubject: Subject<boolean> = new Subject<boolean>();

  private account: AccountModel;
  private permissions: PermissionModel[];

  private resolvers = {
    'permission': this.resolvePermission.bind(this),
  };

  constructor(
    private authService: AuthService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;

      this.isAdmin = this.account && this.account.isAdmin;
      this.isUserImported = this.account && this.account.isUserImported;
    });

    this.authService.permissionsSubject.subscribe((permissions: PermissionModel[]) => {
      this.permissions = permissions;
    });
  }

  public isUserHasPermissions(requiredPermissions: IPermissionData[], options: { isForbiddenForAdmins?: boolean } = {}): boolean {
    if (!requiredPermissions) {
      return true;
    }

    if (!this.account) {
      return false;
    }

    if (this.isAdmin) {
      return !options.isForbiddenForAdmins;
    }

    return requiredPermissions.every((permission: IPermissionData) => {
      if (!this.resolvers[permission.type]) {
        console.error(`> isUserHasPermissions(): no resolver for '${permission.type}'.`);

        return false;
      }

      return this.resolvers[permission.type](permission.value);
    });
  }

  public isUserHasPermissionsObservable(requiredPermissions: IPermissionData[], options: { isForbiddenForAdmins?: boolean } = {}): Observable<boolean> {
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      if (!requiredPermissions) {
        observer.next(true);
        
        return observer.complete();
      }

      combineLatest([
        this.authService.accountSubject,
        this.authService.permissionsSubject,
      ]).subscribe(([account, permissions]: [AccountModel, PermissionModel[]]) => {
        if (!this.authService.isFetched || !this.authService.isPermissionsFetched) {
          return;
        }

        this.account = account;
        this.permissions = permissions;

        if (!this.account) {
          observer.next(false);

          return;
        }

        if (this.account.isAdmin) {
          observer.next(!options.isForbiddenForAdmins);

          return;
        }

        if (!this.permissions) {
          observer.next(false);

          return;
        }

        observer.next(requiredPermissions.every((permission: IPermissionData) => {
          if (!this.resolvers[permission.type]) {
            console.error(`> isUserHasPermissionsObservable(): no resolver for '${permission.type}'.`);

            return false;
          }

          return this.resolvers[permission.type](permission.value);
        }));
      });
    });
  }

  private resolvePermission(value: string): boolean {
    if (!this.permissions) {
      return false;
    }

    return this.permissions.some((permission: PermissionModel) => {
      return permission.key === value;
    });
  }
}
