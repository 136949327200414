import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {CLASS_NAMES} from './constants';

export class IsTitleVisibleModel implements IStyleOption {
  private menu: HTMLElement;
  private elements: HTMLElement[];

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.menu = options.block;
    this.elements = this.menu ? <HTMLElement[]>Array.from(this.menu.querySelectorAll('[data-website-title], [data-website-title-mobile]')) : null;

    this.isEnabled = this.elements && this.elements.length > 0;

    if (!this.isEnabled) {
      return;
    }

    this.value = !element.classList.contains(CLASS_NAMES.HIDDEN);
  }

  public setValue(value: boolean): void {
    if (!this.isEnabled) {
      return;
    }

    this.value = value;

    this.menu.classList.toggle(CLASS_NAMES.TITLE_VISIBLE, value);

    this.elements.forEach(title => {
      title.classList.toggle(CLASS_NAMES.HIDDEN, !value);
    });
  }
}
