export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `Bầu trời trong xanh thăm thẳm, không một gợn mây.`,
      `Cảnh tượng trước mắt chúng tôi thực sự hùng vĩ.`,
      `Gờ chắn màu đỏ in bóng tựa viền cánh chim lởm chởm.`,
    ],
    [KEYS.ALPHABET]: [
      'AĂÂBCDĐEÊGHIKLMNOÔƠPQRSTUƯVXYaăâbcdđeêghiklmnoôơpqrstuưvxy ‘?’“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `Chúng tôi đã đạt tới độ cao rất lớn trong khí quyển vì bầu trời tối đen và các vì sao không còn lấp lánh. Ảo giác về đường chân trời khiến đám mây ảm đạm bên dưới lõm xuống và chiếc xe như trôi bồng bềnh giữa quả cầu khổng lồ tăm tối.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
