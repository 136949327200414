import {PortfolioDefaultsModel} from './portfolio-defaults.model';

export class PortfolioDefaultsV1Dto {
  constructor(public defaulttitle_s?: string,
              public caption?: string,
              public defaultprocess_s?: string,
              public defaultprocessother_s?: string,
              public defaultcopyright_n?: string,
              public purchaseInfo?: string,
              public bucket?: string,

              public defaulteditioned_b?: boolean,
              public defaulteditionsize_n?: string,
              public defaultprintnumber_n?: string,

              public defaultwidth_n?: string,
              public defaultheight_n?: string,
              public defaultlength_n?: string,
              public defaultmatwidth_n?: string,
              public defaultmatheight_n?: string,

              public defaultprice_n?: number,
              public pricingStructure?: string,
              public inquiryLinkText?: string,
              public purchaseText?: string,
              public defaultavailability_n?: string,
              public isInquireLinkVisible?: boolean,
              public isPurchaseButtonVisible?: boolean,

              public defaultyearproduced_n?: string,
              public processTypeId?: number) {}

  public static normalize(res: PortfolioDefaultsV1Dto): PortfolioDefaultsModel {
    if (!res) return null;

    return new PortfolioDefaultsModel(
      null,
      res.defaulttitle_s,
      res.caption,
      res.defaultprocess_s,
      res.defaultprocessother_s,
      res.defaultcopyright_n,
      res.purchaseInfo,
      res.bucket,

      res.defaulteditioned_b,
      res.defaulteditionsize_n,
      res.defaultprintnumber_n,

      res.defaultwidth_n ? parseFloat(res.defaultwidth_n) : null,
      res.defaultheight_n ? parseFloat(res.defaultheight_n) : null,
      res.defaultlength_n ? parseFloat(res.defaultlength_n) : null,
      res.defaultmatwidth_n ? parseFloat(res.defaultmatwidth_n) : null,
      res.defaultmatheight_n ? parseFloat(res.defaultmatheight_n) : null,

      res.defaultprice_n,
      res.pricingStructure,
      res.inquiryLinkText,
      res.purchaseText,
      res.defaultavailability_n ? parseInt(res.defaultavailability_n, 10) : 0,
      res.isInquireLinkVisible,
      res.isPurchaseButtonVisible,

      res.defaultyearproduced_n,
      res.processTypeId,
    );
  }
}
