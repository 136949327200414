import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {BehaviorSubject, Observable, Subscription, catchError, forkJoin, tap, throwError} from 'rxjs';

import {EditorControlButtonsService} from '../../../../../services/editor-control-buttons.service';
import {EventsService} from '../../../interaction/events/events.service';
import {IFrameService} from '../../../iframe/iframe.service';
import {IFrameRoutingService} from '../../../iframe/routing/iframe-routing.service';
import {WebsitesService} from '../../../websites/websites.service';

import {MobilePortfolioModel} from '../../../../models/portfolios/mobile/mobile-portfolio.model';

@Injectable()
export class CustomizerMobileTemplatesService {
  public mobileEnlargement = new BehaviorSubject<string>(null);
  public mobilePortfolio = new BehaviorSubject<MobilePortfolioModel>(null);

  private originMobilePortfolioKey: string;
  private isChanged = false;

  private buttonHandlers = {};

  get values() {
    return {
      mobilePortfolioKey: this.mobilePortfolio.value.key,
      mobileEnlargementKey: this.mobileEnlargement.value,
    };
  }

  constructor(
    private http: HttpClient,
    private editorControlButtonsService: EditorControlButtonsService,
    private websitesService: WebsitesService,
    private iFrameService: IFrameService,
    private iFrameRoutingService: IFrameRoutingService,
    private eventsService: EventsService,
  ) {
    this.buttonHandlers = {
      [this.editorControlButtonsService.CONTROL_BUTTONS.SAVE]: this.onSave.bind(this),
      [this.editorControlButtonsService.CONTROL_BUTTONS.CANCEL]: this.onCancel.bind(this),
    };
      
    this.websitesService.activeWebsiteSubject.subscribe(this.init.bind(this));
    this.editorControlButtonsService.controlBtnClick.subscribe(this.onAction.bind(this));

    this.eventsService.addFrameListener('mobilePortfolioSelect', this.onMobilePortfolioSelect.bind(this));

    this.iFrameRoutingService.selectedPageSubject.subscribe(() => {
      this.setMobilePortfolio(new MobilePortfolioModel(this.originMobilePortfolioKey));
    });
  }

  private onMobilePortfolioSelect(e: CustomEvent) {
    this.mobilePortfolio.next(new MobilePortfolioModel(e.detail.key, e.detail.isLocked));
  }

  private onAction({ buttonName }): Observable<any[]> {
    if (!this.buttonHandlers[buttonName] || !this.isChanged) {
      return;
    }

    this.buttonHandlers[buttonName]().subscribe(() => {});
  }

  private onSave(): Observable<any[]> {
    return forkJoin([
      this.saveMobileEnlargement(),
      this.saveMobilePortfolio(),
    ]);
  }

  private saveMobileEnlargement(): Observable<any> {
    return this.http.post(`api/websites/active/mobile-enlargement`, { key: this.mobileEnlargement.value });
  }

  private saveMobilePortfolio(): Observable<any> {
    this.originMobilePortfolioKey = this.mobilePortfolio.value.key;
    return this.http.post(`api/websites/active/mobile-portfolio`, { key: this.mobilePortfolio.value.key });
  }

  private onCancel(): Observable<any> {
    return this.init();
  }

  private init(): Observable<any> {
    return forkJoin([
      this.initMobileEnlargement(),
      this.initMobilePortfolio(),
    ]).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      })
    );
  }

  private initMobileEnlargement(): Subscription {
    return this.http.get(`api/websites/active/mobile-enlargement`, { responseType: 'text' }).subscribe((mobileEnlargement: string) => {
      this.setMobileEnlargement(mobileEnlargement || 'swipe');
    });
  }

  private initMobilePortfolio(): Subscription {
    return this.http.get(`api/websites/active/mobile-portfolio`, { responseType: 'text' }).subscribe((mobilePortfolio: string) => {
      const key: string = mobilePortfolio || 'one-column';
  
      this.originMobilePortfolioKey = key;
  
      this.setMobilePortfolio(new MobilePortfolioModel(key));
    });
  }

  public setMobileEnlargement(key: string) {
    this.mobileEnlargement.next(key);

    if (this.iFrameService.isLoaded) this.eventsService.dispatchMobileEnlargementChange({ key }, this.iFrameService.sandboxWindow);

    this.isChanged = true;
  }

  public setMobilePortfolio(item: MobilePortfolioModel) {
    this.mobilePortfolio.next(item);

    if (this.iFrameService.isLoaded) this.eventsService.dispatchMobilePortfolioChange({ key: item.key }, this.iFrameService.sandboxWindow);

    this.isChanged = true;
  }
}
