// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-wrapper:hover {
  cursor: pointer;
}
.logo-wrapper .logo {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 3px;
  font-weight: 100 !important;
}
.logo-wrapper .logo img {
  height: 25px;
  margin-right: 7px;
}
.logo-wrapper .logo .white {
  color: #fff;
  line-height: 25px;
}
.logo-wrapper .logo .black {
  color: #1f1f1f;
  line-height: 25px;
}
.logo-wrapper .logo-website-for-artists {
  text-transform: uppercase;
  color: #1f1f1f;
  font-size: 11px;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/logo/logo.component.scss"],"names":[],"mappings":"AAOE;EACE,eAAA;AANJ;AASE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAda;EAeb,mBAAA;EACA,2BAAA;AAPJ;AASI;EACE,YApBQ;EAqBR,iBAAA;AAPN;AAUI;EACE,WArBE;EAsBF,iBA1BQ;AAkBd;AAWI;EACE,cA3BE;EA4BF,iBA/BQ;AAsBd;AAaE;EACE,yBAAA;EACA,cAlCI;EAmCJ,eAAA;EACA,eAAA;AAXJ","sourcesContent":["$logo-height: 25px;\n$logo-text-size: 22px;\n\n$black: #1f1f1f;\n$white: #fff;\n\n.logo-wrapper {\n  &:hover {\n    cursor: pointer;\n  }\n\n  .logo {\n    display: flex;\n    flex-direction: row;\n    text-transform: uppercase;\n    font-size: $logo-text-size;\n    letter-spacing: 3px;\n    font-weight: 100 !important;\n\n    img {\n      height: $logo-height;\n      margin-right: 7px;\n    }\n\n    .white {\n      color: $white;\n      line-height: $logo-height;\n    }\n\n    .black {\n      color: $black;\n      line-height: $logo-height;\n    }\n  }\n\n  .logo-website-for-artists {\n    text-transform: uppercase;\n    color: $black;\n    font-size: 11px;\n    margin-top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
