import {ProrateDto} from './prorate.dto';

import {AMOUNT_FORMATTERS} from '../intent/constants';

export class ProrateModel {
  constructor(public total?: string,
              public totalWithoutDiscount?: string,
              public amountOnAccount?: string,
              public newCreditBalance?: string,
              public totalAmount?: number,
              public totalWithoutDiscountAmount?: number,
              public amountOnAccountAmount?: number,
              public newCreditBalanceAmount?: number,
              ) {
    this.totalAmount = this.totalAmount / 100;
    this.totalWithoutDiscountAmount = this.totalWithoutDiscountAmount / 100;
    this.amountOnAccountAmount = this.amountOnAccountAmount / 100;
    this.newCreditBalanceAmount = this.newCreditBalanceAmount / 100;
  }

  public static normalize(res: ProrateDto): ProrateModel {
    if (!res) return null;

    return new ProrateModel(
      AMOUNT_FORMATTERS.usd(res.total),
      AMOUNT_FORMATTERS.usd(res.totalWithoutDiscount),
      AMOUNT_FORMATTERS.usd(res.amountOnAccount, true),
      AMOUNT_FORMATTERS.usd(res.newCreditBalance, true),
      res.total,
      res.totalWithoutDiscount,
      res.amountOnAccount,
      res.newCreditBalance,
    );
  }
}
