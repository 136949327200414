export class PreLaunchProgressAccountDataModel {
  constructor(public progress?: { [key: number]: boolean }) {}

  public static fromJson(json: string): PreLaunchProgressAccountDataModel {
    if (!json) return null;

    try {
      const item: PreLaunchProgressAccountDataModel = JSON.parse(json);

      return new PreLaunchProgressAccountDataModel(
        item.progress,
      );
    } catch (e) {
      return null;
    }
  }

  public static clone(item: PreLaunchProgressAccountDataModel): PreLaunchProgressAccountDataModel {
    if (!item) return null;

    return new PreLaunchProgressAccountDataModel(
      JSON.parse(JSON.stringify(item.progress)),
    );
  }
}
