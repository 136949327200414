import {AttributeWithValueModel} from '../base/attribute-with-value/attribute-with-value.model';

import {ATTRIBUTE} from './constants';

export class VideoPosterModel extends AttributeWithValueModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, ATTRIBUTE, value, isEnabled);
  }
}
