import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, Subject, BehaviorSubject, throwError, Subscription} from 'rxjs';
import {catchError, mergeMap, take, tap} from 'rxjs/operators';

import {WebsitesService} from '../../../core/services/websites/websites.service';

import {SelectedPageModel} from '../../../core/models/selected-page/selected-page.model';

@Injectable()
export class WebsiteDesignerService {
  public injectedDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  public forceSaveChangesSubject: Subject<boolean> = new Subject<boolean>();

  public selectedPage: SelectedPageModel;

  // Has issues that disable saving
  hasSaveIssues: boolean = false;

  editingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  checkForPageChangesSubject = new Subject();

  public onChangesSaved: Subject<boolean> = new Subject();

  get element() {
    return this.editingSubject.value && this.editingSubject.value.element;
  }

  constructor(
    private httpClient: HttpClient,
    private websitesService: WebsitesService,
  ) {
  }

  public getInjectedData(selectedPage: SelectedPageModel): Subscription {
    return this.fetchInjectedData(selectedPage).pipe(take(1)).subscribe(injectedData => {
      if (!injectedData || !this.selectedPage || injectedData.pageId !== this.selectedPage.id || injectedData.pageType !== this.selectedPage.type) {
        return;
      }

      this.injectedDataSubject.next(injectedData);
    });
  }

  private fetchInjectedData(selectedPage: SelectedPageModel): Observable<any> {
    const params = new HttpParams().set('templateId', `${selectedPage.templateId}`).set('pageId', `${selectedPage.id}`).set('pageType', selectedPage.type);

    return this.httpClient.get('/api/app/website-designer/injected-data', { params });
  }

  saveDetails(details): Observable<any> {
    return this.httpClient.put('/api/app/templates/details', details);
  }

  setEditingObject(value) {
    this.editingSubject.next(value);
  }

  getEditingObject() {
    return this.editingSubject.value;
  }

  updateWebsite(data): Observable<any> {
    return this.httpClient.put('/api/app/website-designer/settings', data).pipe(
      tap(() => this.websitesService.fetchWebsite())
    );
  }

  public updateTemplate(data): Observable<any> {
    return this.httpClient.put('/api/app/website-designer/template', data).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      tap(() => this.websitesService.fetchWebsite())
    );
  }

  getElement(category, name): Observable<any> {
    return this.httpClient.get(`/api/app/website-designer/element`, {
      params: {
        category,
        name,
      },
    });
  }

  findAllTemplates(): Observable<any> {
    return this.httpClient.get(`api/app/templates`);
  }

  getSplashInfo(pageId: number, pageType: string) {
    return this.httpClient.get<{ hasSplash, showSplash }>(`api/app/splash/info/${pageId}/${pageType}`);
  }

  toggleSplash(templateId: number, pageId: number, pageType: string, showSplash: boolean) {
    return this.httpClient.put(`api/app/splash/${templateId}/${pageId}/${pageType}/toggle`, { showSplash });
  }

  getPageDetailsWithDefaults(websiteId: number, templateId: number, pageType: string, pageId: number) {
    return this.httpClient.get<{ defaults, details }>(`api/app/templates/details`, {
      params: {
        websiteId,
        templateId,
        pageType,
        pageId,
      }
    });
  }

  // TODO: Move to styleDetails.service
  getWebsiteStyles(templateId: number) {
    return this.httpClient.get(`api/app/style-details/website/${templateId}`);
  }

  resetBlocksStyles(templateId: number) {
    return this.httpClient.get('api/app/website-designer/reset/styles', {
      params: {
        templateId,
      },
      responseType: 'text',
    });
  }

  public publishWebsite(): Observable<any> {
    return this.httpClient.post('api/app/website-designer/publish', {});
  }

  sendReport(data: FormData) {
    return this.httpClient.post('api/report', data);
  }

  checkForPageChanges(callback: Function): void {
    if (this.checkForPageChangesSubject.observers.length > 0) {
      return this.checkForPageChangesSubject.next(callback);
    }

    callback(true);
  }

  findTemplateCategories() {
    return this.httpClient.get('api/app/templates/categories');
  }
}
