import {StyleOptionModel} from './option/style-option.model';

export class StylesSettingsModel {
  constructor(
    public options: { [key: number]: StyleOptionModel },
    public optionsList: StyleOptionModel[],
    public blocks: { [key: string]: { [key: string]: StyleOptionModel[] } },
    public blocksCategories: { [key: number]: StyleOptionModel[] },
    public customElements: { [key: string]: StyleOptionModel[] },
    public elements: { [key: string]: StyleOptionModel[] },
  ) {}
}
