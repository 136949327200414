import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DetailsService} from '../../../../main/details/details.service';
import {CustomMenuStylesService} from '../../../../../core/services/styles/custom-menu/custom-menu-styles.service';
import {ButtonsService} from '../../../../../core/services/buttons/buttons.service';
import {TemplatesService} from '../../../../../core/services/templates/templates.service';
import {TitleStylesService} from '../../../../../core/services/styles/title/title-styles.service';

import {MenuSetupModel} from '../../../../../core/models/styles/setup/menu/desktop/menu-setup.model';
import {TemplateModel} from '../../../../../core/models/templates/template.model';
import {TitleSetupModel} from '../../../../../core/models/styles/setup/website-title/title-setup.model';

@Component({
  selector: 'app-block-options',
  templateUrl: './blocks-options.component.html',
  styleUrls: ['./blocks-options.component.scss']
})
export class BlocksOptionsComponent implements OnInit, OnDestroy {
  @Input() blockData;

  public isBothMenus: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get menuSetup(): MenuSetupModel {
    return this.customMenuStylesService.setup;
  }

  public get titleSetup(): TitleSetupModel {
    return this.titleStylesService.setup;
  }

  constructor(public detailsService: DetailsService,
              private templatesService: TemplatesService,
              private titleStylesService: TitleStylesService,
              private buttonsService: ButtonsService,
              private customMenuStylesService: CustomMenuStylesService) {}

  public ngOnInit(): void {
    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.isBothMenus = template && template.menuType === 'BOTH';
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onLogoVisibilityChange(value: boolean): void {
    this.menuSetup.isLogoVisible.setValue(value);
    this.buttonsService.enableSaveButton();
  }

  public onTitleVisibilityChange(value: boolean): void {
    this.titleSetup.isVisible.setValue(value);
    this.buttonsService.enableSaveButton();
  }
}
