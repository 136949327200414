import {Injectable} from '@angular/core';
import {ModalsService} from '../shared/services/modals/modals.service';

import {ITextAlertModalData} from '../shared/components/modals/text-alert-modal/text-alert-modal-data.model';

@Injectable()
export class TextAlertModalService {
  public modalId: string = 'text-alert-modal-from-service';

  public message: string | string[];
  public header: string;
  public submitButtonText: string;
  public cancelButtonText: string;
  public showCancelButton: boolean;

  private submitHandler: () => void;
  private cancelHandler: () => void;
  private closeHandler: () => void;

  constructor(private modalsService: ModalsService) {}

  show({ message, header, submitHandler, cancelHandler, closeHandler, showCancelButton, submitButtonText, cancelButtonText }: ITextAlertModalData) {
    this.modalsService.open(this.modalId);
    this.message = message;
    this.header = header;
    this.showCancelButton = showCancelButton;
    this.submitButtonText = submitButtonText || 'OK';
    this.cancelButtonText = cancelButtonText || 'CANCEL';
    this.submitHandler = submitHandler;
    this.cancelHandler = cancelHandler;
    this.closeHandler = closeHandler;
  }

  hide() {
    this.message = null;
    this.submitHandler = null;
    this.cancelHandler = null;

    this.modalsService.close(this.modalId);
  }

  onSubmit() {
    if (this.submitHandler) this.submitHandler();
  }

  onCancel() {
    if (this.cancelHandler) this.cancelHandler();
  }

  onClose() {
    if (this.closeHandler) this.closeHandler();
  }
}
