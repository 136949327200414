import {Component} from '@angular/core';

import {ModalsService} from '../../../services/modals/modals.service';
import {ImageReplaceInvalidationInfoModalService} from '../../../services/modals/image-replace-invalidation-info/image-replace-invalidation-info-modal.service';

import {Button} from '../../../../common/models/button/button.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-image-replace-invalidation-info-modal',
  templateUrl: './image-replace-invalidation-info-modal.component.html',
  styleUrls: ['./image-replace-invalidation-info-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageReplaceInvalidationInfoModalComponent {
  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.onModalClose.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(private modalsService: ModalsService,
              private service: ImageReplaceInvalidationInfoModalService) {
  }

  private onModalClose(): void {
    this.modalsService.close(this.id);
  }
}
