
import {filter, finalize, map} from 'rxjs/operators';


import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient as Http, HttpErrorResponse, HttpParams} from '@angular/common/http';

import {BehaviorSubject, Observable, Subscription, of} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';

import {ContentLoaderService} from '../../loaders/content/content-loader.service';
import {AuthService} from '../../../../auth/auth.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {CartService} from '../../cart/cart.service';

import {SubscriptionsModel} from '../../../models/payment/subscriptions/subscriptions.model';
import {SubscriptionsDto} from '../../../models/payment/subscriptions/subscriptions.dto';
import {SubscriptionModel} from '../../../models/payment/subscriptions/subscription.model';
import {SubscriptionDto} from '../../../models/payment/subscriptions/subscription.dto';
import {AccountModel} from '../../../models/accounts/account.model';
import {ProrateDto} from '../../../models/payment/subscriptions/prorate.dto';
import {ProrateModel} from '../../../models/payment/subscriptions/prorate.model';
import {PlanModel} from '../../../models/plan/plan.model';
import {PurchaseValidationModel} from '../../../models/validation/purchase/purchase-validation.model';
import {AccountSummaryModel} from '../../../models/accounts/summary/account-summary.model';
import {PlanCartItemModel} from '../../../models/cart/item/plan/plan-cart-item.model';
import {PlanCartItemOptionsModel} from '../../../models/cart/item/plan/plan-cart-item-options.model';

import {PRODUCT_KEYS, PRODUCT_TYPES} from '../../cart/constants';
import {DURATIONS} from '../../../models/payment/subscriptions/constants';

@Injectable()
export class PaymentSubscriptionsService {
  public model = 'payments';

  public successfullySubscribedModalId: string = 'SUCCESSFULLY_SUBSCRIBED_MODAL';

  public dataSubject: BehaviorSubject<SubscriptionsModel> = new BehaviorSubject<SubscriptionsModel>(null);
  public currentSubscriptionSubject: BehaviorSubject<SubscriptionModel> = new BehaviorSubject<SubscriptionModel>(null);
  public currentSubscriptionExpandedSubject: BehaviorSubject<{ isFetched: boolean, data: SubscriptionModel }> = new BehaviorSubject<{ isFetched: boolean, data: SubscriptionModel }>(null);
  public prorateSubject: BehaviorSubject<ProrateModel> = new BehaviorSubject<ProrateModel>(null);

  public isCurrentSubscriptionFetched: boolean = false;

  private account: AccountModel;
  private accountSummary: AccountSummaryModel;

  get data(): SubscriptionsModel {
    return this.dataSubject.value;
  }

  constructor(
    private router: Router,
    private httpClient: Http,
    private loaderService: ContentLoaderService,
    private cartService: CartService,
    private modalsService: ModalsService,
    private authService: AuthService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;

      return account ? this.init() : this.destroy();
    });

    this.authService.accountSummarySubject.pipe(filter((accountSummary: AccountSummaryModel) => !!accountSummary)).subscribe((accountSummary: AccountSummaryModel) => {
      this.accountSummary = accountSummary;
    });
  }

  public init() {
    this.initList();
    this.initCurrent();
  }

  public initList() {
    const key = 'PaymentSubscriptionsService_initList';

    this.loaderService.show(key);

    const done = (res: SubscriptionsDto) => {
      const subscriptions = SubscriptionsModel.normalize(res);

      this.dataSubject.next(subscriptions);
    };

    const error = err => this.handleError(err, key, true);

    this.fetch().pipe(
      catchError(error),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    ).subscribe(done);
  }

  public fetchMore(lastId: string, limit?: number): Subscription {
    const params = new HttpParams().set('lastId', lastId).set('limit', limit ? `${limit}` : '');

    return this.fetch(params).subscribe(res => {
      const data = SubscriptionsModel.normalize(res);
      const list = this.data.list.concat(data.list);
      const subscriptions = new SubscriptionsModel(data.hasMore, list);

      this.dataSubject.next(subscriptions);
    });
  }

  private fetch(params?: HttpParams): Observable<SubscriptionsDto> {
    return this.httpClient.get(`api/${this.model}/subscriptions`, { params });
  }

  public initCurrent() {
    this.initCurrentExpanded();

    const key = 'PaymentSubscriptionsService_initCurrent';

    this.isCurrentSubscriptionFetched = false;

    this.loaderService.show(key);

    const done = (res: SubscriptionDto) => {
      const subscription = SubscriptionDto.normalize(res);

      this.isCurrentSubscriptionFetched = true;

      this.currentSubscriptionSubject.next(subscription);
    };

    const error = err => {
      this.isCurrentSubscriptionFetched = true;

      this.currentSubscriptionSubject.next(null);

      this.handleError(err, key, true);

      return of(null);
    };

    return this.fetchCurrent().pipe(
      catchError(error),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    ).subscribe(done);
  }

  private fetchCurrent(): Observable<SubscriptionDto> {
    return this.httpClient.get(`api/${this.model}/subscriptions/current`);
  }

  public initCurrentExpanded() {
    const key = 'PaymentSubscriptionsService_initCurrentExpanded';

    const done = (res: SubscriptionDto) => {
      const subscription = SubscriptionDto.normalize(res);

      this.currentSubscriptionExpandedSubject.next({
        isFetched: true,
        data: subscription,
      });
    };

    const error = err => {
      console.error(err);

      this.currentSubscriptionExpandedSubject.next({
        isFetched: true,
        data: null,
      });
  
      this.modalsService.open(err.error.key, err.error);

      return of([]);
    };

    return this.fetchCurrentExpanded().pipe(
      catchError(error),
    ).subscribe(done);
  }

  public fetchCurrentExpanded(): Observable<SubscriptionDto> {
    return this.httpClient.get(`api/${this.model}/subscriptions/current/expanded`);
  }

  public initProration(coupon: string) {
    const key = 'PaymentSubscriptionsService_initProration';

    const params = new HttpParams().set('coupon', coupon);

    this.loaderService.show(key);

    const done = (res: ProrateModel) => {
      this.prorateSubject.next(res);
    };

    const error = err => this.handleError(err, key, true);

    this.fetchProration(params).pipe(
      catchError(error),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    ).subscribe(done);
  }

  private fetchProration(params: HttpParams): Observable<ProrateModel> {
    return this.httpClient.get(`api/${this.model}/subscriptions/upgrade-proration`, { params }).pipe(
      map((res: ProrateDto) => ProrateModel.normalize(res))
    );
  }

  public subscribe(planData: { cardId: string, coupon: string }): Observable<any> {
    return this.httpClient.post(`api/${this.model}/subscriptions`, planData);
  }

  public toggleStatus(subscription: SubscriptionModel) {
    const key = 'PaymentSubscriptionsService_unsubscribe';

    this.loaderService.show(key);

    const done = () => {
      this.init();
    };

    const error = err => this.handleError(err, key);

    this.doStatusToggle(subscription).pipe(
      catchError(error),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    ).subscribe(done);
  }

  public doStatusToggle(subscription: SubscriptionModel) {
    return this.httpClient.put(`api/${this.model}/subscriptions/${subscription.id}/status`, { isCancelled: !subscription.isCancelledAtPeriodEnd });
  }

  public switchSubscription(planData: { cardId: string, coupon: string }): Observable<any> {
    return this.httpClient.put(`api/${this.model}/subscriptions/current`, planData);
  }

  public extendTrial(): Observable<any> {
    return this.httpClient.post(`api/${this.model}/subscriptions/trial/extension`, {});
  }

  public updateSubscriptionCard(data: { subscriptionId: string, cardId: string }): Observable<any> {
    const key = 'PaymentSubscriptionsService_updateSubscriptionCard';

    this.loaderService.show(key);

    const done = () => {};

    const error = err => this.handleError(err, key, true);

    return this.doCardUpdate(data).pipe(
      tap(done),
      catchError(error),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    );
  }

  private doCardUpdate({ cardId, subscriptionId }: { subscriptionId: string, cardId: string }) : Observable<any> {
    return this.httpClient.put(`api/${this.model}/subscriptions/${subscriptionId}/card`, { cardId });
  }

  public updateSubscriptionCoupon(data: { subscriptionId: string, couponId: string }): Observable<any> {
    const key = 'PaymentSubscriptionsService_updateSubscriptionCoupon';

    this.loaderService.show(key);

    const done = () => {};
    const error = err => this.handleError(err, key);

    return this.doCouponUpdate(data).pipe(
      tap(done),
      catchError(error),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    );
  }

  private doCouponUpdate({ couponId, subscriptionId }: { subscriptionId: string, couponId: string }) : Observable<any> {
    return this.httpClient.put(`api/${this.model}/subscriptions/${subscriptionId}/coupon`, { couponId });
  }

  public signUp(plan: PlanModel, duration: string): Subscription {
    const cartItem: PlanCartItemModel = new PlanCartItemModel(
      PRODUCT_KEYS.RECURRING,
      PRODUCT_TYPES.PLAN,
      plan.stripeProductId,
      this.getStripePriceId({ plan, duration }),
      new PlanCartItemOptionsModel(plan.id === 'OneDay' || plan.id === 'OneDay+' ? DURATIONS.ONE_DAY : duration),
    );

    return this.cartService.addItem(cartItem);
  }

  private getStripePriceId(planData: { plan: PlanModel, duration: string }): string {
    // wow, but sorry
    if (planData.plan.id === 'OneDay' || planData.plan.id === 'OneDay+') return planData.plan.dailyPlanStripeId;

    if (planData.duration === DURATIONS.ONE_YEAR) return planData.plan.annualPlanStripeId;
    if (planData.duration === DURATIONS.ONE_MONTH) return planData.plan.monthPlanStripeId;
    if (planData.duration === DURATIONS.ONE_DAY) return planData.plan.dailyPlanStripeId;
  }

  public validate(purchasingPlan: PlanModel): PurchaseValidationModel {
    if (!purchasingPlan) throw 'Plan not found';

    const { websiteLimit, pageLimit, portfolioLimit, diskSpaceMb } = purchasingPlan;

    return new PurchaseValidationModel(
      !websiteLimit || websiteLimit >= this.accountSummary.websitesCount,
      !pageLimit || pageLimit >= this.accountSummary.pagesCount,
      !portfolioLimit || portfolioLimit >= this.accountSummary.portfoliosCount,
      !diskSpaceMb || diskSpaceMb >= this.accountSummary.diskSpaceMb,
    );
  }

  public handleError(err: HttpErrorResponse, key?: string, isRedirectDisabled?: boolean): Promise<boolean> {
    console.error(err);

    this.loaderService.hide(key);

    this.modalsService.open(err.error.key, err.error);

    if (isRedirectDisabled) {
      return;
    }

    return err.error.key === 'NO_PLAN' ? this.toPurchase() : this.toHistory();
  }

  public toPurchase() {
    if (this.account && this.account.isUserImported) {
      return this.toAddOns();
    }

    return this.redirect('purchase');
  }

  public toAddOns() {
    return this.redirect('purchase-add-ons');
  }

  public toCreditCards() {
    return this.redirect('credit-cards');
  }

  public toRepeatPayment() {
    return this.redirect('repeat-payment', 'credit-cards');
  }

  public toHistory() {
    return this.redirect('payments', 'payments');
  }

  public toSubscriptions() {
    return this.redirect('subscriptions', 'subscriptions');
  }

  public redirect(page: string, sidebar?: string) {
    return this.router.navigate([
      '/app',
      {
        outlets: {
          primary: [
            'settings',
            page,
          ],
          sidebar: [
            'account',
            sidebar || page,
          ],
          'over-sidebar': null,
        }
      },
    ], {
      queryParamsHandling: 'merge',
    });
  }

  public clear() {
    this.prorateSubject.next(null);
  }

  public destroy(): void {
    this.isCurrentSubscriptionFetched = true;
    
    this.dataSubject.next(null);
    this.currentSubscriptionSubject.next(null);
    this.prorateSubject.next(null);
  }
}
