import {BUTTONS_KEYS} from '../buttons/constants';

export const DEVICES = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const BUTTONS_TO_DEVICES = {
  [BUTTONS_KEYS.MOBILE_DEVICE]: DEVICES.MOBILE,
  [BUTTONS_KEYS.TABLET_DEVICE]: DEVICES.TABLET,
  [BUTTONS_KEYS.DESKTOP_DEVICE]: DEVICES.DESKTOP,
};

export const DEVICES_TO_BUTTONS = {
  [DEVICES.MOBILE]: BUTTONS_KEYS.MOBILE_DEVICE,
  [DEVICES.TABLET]: BUTTONS_KEYS.TABLET_DEVICE,
  [DEVICES.DESKTOP]: BUTTONS_KEYS.DESKTOP_DEVICE,
};
