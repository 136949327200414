import {Injectable} from '@angular/core';

@Injectable()
export class ChangesMonitorService {
  public changes: { [key: string]: boolean } = {};
  
  public constructor() {
  }

  public isChanged(key?: string): boolean {
    if (key) {
      return !!this.changes[key];
    }

    return Object.keys(this.changes).some((key: string) => {
      return !!this.changes[key];
    });
  }

  public onChange(key: string): void {
    this.changes[key] = true;
  }

  public clean(): void {
    this.changes = {};
  }
}
