// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1;
  min-height: 100%;
  background: linear-gradient(to bottom right, #eee, #ccc);
}

.subscription-order-cards-step {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.subscription-order-cards-step .cards-content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.subscription-order-cards-step .cards-content-wrapper .header {
  line-height: normal;
  font-size: 16pt;
  font-weight: 600;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/settings/account/purchase/steps/cards/subscription-order-step-cards.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,OAAA;EACA,gBAAA;EACA,wDAAA;AADF;;AAIA;EACE,aAAA;EACA,OAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AADJ;AAGI;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AADN","sourcesContent":["@import \"../../../../../../../../styles/colors\";\n\n:host {\n  display: flex;\n  flex: 1;\n  min-height: 100%;\n  background: linear-gradient(to bottom right, $primary-light, $primary);\n}\n\n.subscription-order-cards-step {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n\n  .cards-content-wrapper {\n    display: flex;\n    flex-direction: column;\n    padding: 30px;\n\n    .header {\n      line-height: normal;\n      font-size: 16pt;\n      font-weight: 600;\n      margin-bottom: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
