export const KEYS = {
  YOUTUBE_1: 'YOUTUBE_1',
  YOUTUBE_2: 'YOUTUBE_2',
  YOUTUBE_3: 'YOUTUBE_3',
  VIMEO_1: 'VIMEO_1',
  VIMEO_2: 'VIMEO_2',
  LOOM: 'LOOM',
  UNKNOWN: 'UNKNOWN',
};

export const SERVICES_REGEXPS = {
  [KEYS.YOUTUBE_1]: /youtube\.com\//i,
  [KEYS.YOUTUBE_2]: /youtu\.be\//i,
  [KEYS.YOUTUBE_3]: /youtube-nocookie\.com\//i,
  [KEYS.VIMEO_1]: /player\.vimeo\.com\//i,
  [KEYS.VIMEO_2]: /vimeo\.com\//i,
};

const YOUTUBE_EMBED_GENERATOR = (id: string) => {
  const query = `modestbranding=1&mute=1&autoplay=0&loop=1&controls=1`;

  return `<iframe src="https://www.youtube.com/embed/${id}?${query}" width="100%" height="100%" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
};

const YOUTUBE_ENHANCED_POLICY_EMBED_GENERATOR = (id: string) => {
  const query = `modestbranding=1&mute=1&autoplay=0&loop=1&controls=1`;

  return `<iframe src="https://www.youtube-nocookie.com/embed/${id}?${query}" width="100%" height="100%" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
};

const VIMEO_EMBED_GENERATOR = (id: string) => {
  return `<iframe src="https://player.vimeo.com/video/${id}?title=0&autoplay=0&byline=0&portrait=0&controls=1" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
<p><a href="https://vimeo.com/${id}"></a></p>`;
};

export const SERVICE_RESOLVER = (url: string = '') => {
  return Object.keys(SERVICES_REGEXPS).find(key => SERVICES_REGEXPS[key].test(url));
};

export const PARSERS = {
  [KEYS.YOUTUBE_1]: (url: string) => {
    if (!url) return null;

    const data = new URL(url);

    if (!data) return null;

    return new URLSearchParams(data.search).get('v') || PARSERS[KEYS.YOUTUBE_2](url);
  },
  [KEYS.YOUTUBE_2]: (url: string) => url ? url.replace(/^.*[\\\/]/, '') : null,
  [KEYS.YOUTUBE_3]: (url: string) => url ? url.replace(/^.*[\\\/]/, '') : null,
  [KEYS.VIMEO_1]: (url: string) => url ? new URL(url).pathname.split('/')[2] : null,
  [KEYS.VIMEO_2]: (url: string) => url ? new URL(url).pathname.split('/')[1] : null,
};

export const EMBED_GENERATORS = {
  [KEYS.YOUTUBE_1]: YOUTUBE_EMBED_GENERATOR,
  [KEYS.YOUTUBE_2]: YOUTUBE_EMBED_GENERATOR,
  [KEYS.YOUTUBE_3]: YOUTUBE_ENHANCED_POLICY_EMBED_GENERATOR,
  [KEYS.VIMEO_1]: VIMEO_EMBED_GENERATOR,
  [KEYS.VIMEO_2]: VIMEO_EMBED_GENERATOR,
};
