import {SetupOptionModel} from '../../../../option/setup-option.model';
import {SelectOption} from '../../../../../../select/option/option.model';

import {STYLE_PROPERTY_NAME, PROPERTY_NAME, OPTIONS} from './constants';

export class PortfolioEnlargementTitleTextDecorationModel extends SetupOptionModel {
  public selectedOption: SelectOption = null;

  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled) return;

    this.element = element;

    const value: string = this.getCurrentValue();

    this.selectedOption = OPTIONS.find(o => o.value === value);
    this.value = this.selectedOption.value;
  }

  public onChange(key: string): void {
    if (!this.isEnabled) return;

    this.selectedOption = OPTIONS.find(o => o.value === key);
    this.value = this.selectedOption.value;

    this.element.style.setProperty(STYLE_PROPERTY_NAME, this.selectedOption.value);
  }

  public reset(): void {
    this.element.style.removeProperty(STYLE_PROPERTY_NAME);

    const value: string = this.getCurrentValue();

    this.selectedOption = OPTIONS.find(o => o.value === value);
    this.value = this.selectedOption.value;

    this.element.style.setProperty(STYLE_PROPERTY_NAME, this.selectedOption.value);
  }

  private getCurrentValue(): string {
    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    return (computedStyles.getPropertyValue(STYLE_PROPERTY_NAME) || computedStyles.getPropertyValue(PROPERTY_NAME).split(' ')[0]).trim();
  }
}
