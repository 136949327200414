import {IStyleOption} from '../option/i-style-option';

import {ColorModel} from '../../color/color.model';

import {BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB} from './constants';

export class SlideshowTextBackgroundColorModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.value = computedValue.getPropertyValue(CUSTOM_BACKGROUND_COLOR) || computedValue.getPropertyValue(BACKGROUND_COLOR);
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(CUSTOM_BACKGROUND_COLOR);
    this.element.style.removeProperty(CUSTOM_BACKGROUND_COLOR_RGB);

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.value = computedValue.getPropertyValue(CUSTOM_BACKGROUND_COLOR) || computedValue.getPropertyValue(BACKGROUND_COLOR);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR, value);

    const colorModel: ColorModel = new ColorModel(value);

    this.element.style.setProperty(CUSTOM_BACKGROUND_COLOR_RGB, colorModel.getRGBstring());
  }
}
