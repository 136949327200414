import {Injectable} from '@angular/core';

@Injectable()
export class StylesDimensionsService {
  private formatters = {
    px: {
      styles: [
        'letter-spacing',
        'word-spacing',
        'font-size',
        'line-height',
        'padding',
        'padding-top',
        'padding-left',
        'padding-right',
        'padding-bottom',
        'border-radius',
        '--menu-links-padding-left',
        '--menu-links-padding-right',
        '--menu-links-line-height',
      ],
      handlers: {
        toStyleString: styleValue => `${Number.parseFloat(styleValue)}px`,
        toStyleValue: styleString => Number.parseFloat(styleString),
      },
    },
    quotas: {
      styles: [
        'font-family',
      ],
      handlers: {
        toStyleString: styleValue => `${styleValue.replace(/(^['"]+)|(['"]+$)/gi, '')}`,
        toStyleValue: styleString => styleString,
      }
    },
  };

  constructor() {}

  public getStyleString(styles) {
    return Object.keys(styles).filter(key => {
      const value = styles[key];
      return Array.isArray(value) ? value.length : value !== void 0 && value !== '';
    })
    .map(key => `${key}:${this.toStyleString(key, styles[key])};`)
    .join('');
  }

  public toStyleString(styleName, styleValue) {
    const keys = Object.keys(this.formatters);

    for (const preparerKey of keys) {
      if (this.formatters[preparerKey].styles.includes(styleName)) {
        return this.formatters[preparerKey].handlers.toStyleString(styleValue);
      }
    }

    return styleValue;
  }

  public toStyleValue(styleName, styleString) {
    const keys = Object.keys(this.formatters);

    for (const preparerKey of keys) {
      if (this.formatters[preparerKey].styles.includes(styleName)) {
        return this.formatters[preparerKey].handlers.toStyleValue(styleString);
      }
    }

    return styleString;
  }
}
