import {Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';

import {Observable, Subscriber} from 'rxjs';

import {NavigationService} from '../../../services/navigation.service';
import {AuthService} from '../../../auth/auth.service';

import {AccountModel} from '../../models/accounts/account.model';

import {FORWARD_KEYS} from './constants';

@Injectable()
export class ForwardGuard  {
  constructor(private router: Router,
              private authService: AuthService,
              private navigationService: NavigationService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const params: URLSearchParams = new URLSearchParams(window.location.search);

    const forward: string = params.get('forward');

    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      this.authService.accountSubject.subscribe((account: AccountModel) => {
        if (!this.authService.isFetched) {
          return;
        }

        const isRedirectToCreditCards: boolean = forward === FORWARD_KEYS.CREDIT_CARDS;

        if (isRedirectToCreditCards) {
          this.navigationService.toCreditCards();
        }

        const isRedirectToJoinClass: boolean = forward === FORWARD_KEYS.JOIN_CLASS;

        if (isRedirectToJoinClass) {
          this.navigationService.toEducationSetup();
        }

        observer.next(!isRedirectToCreditCards || !isRedirectToJoinClass);
        observer.complete();
      });
    })
  }
}
