export class GoogleFontUsagePageModel {
  constructor(public blockId?: number,
              public websiteId?: number,
              public templateId?: number,
              public pageId?: number,
              public pageType?: string,
              public blockTemplateId?: number,
              public usedFonts?: string,
              public isMenu?: boolean,
              public isSplash?: boolean,
              public templateNumber?: number,
              public pageData?: { title: string }) {}
}
