export const KEYS = {
  BLOG_POST_TITLES_FONT_SIZE: 'BLOG_POST_TITLES_FONT_SIZE',
  BLOG_POST_LINKS_COLOR: 'BLOG_POST_LINKS_COLOR',
  BLOG_SOCIAL_NETWORKS_COLOR: 'BLOG_SOCIAL_NETWORKS_COLOR',
  BLOG_TOP_BAR_BACKGROUND_COLOR: 'BLOG_TOP_BAR_BACKGROUND_COLOR',
  BLOG_TOP_BAR_COLOR: 'BLOG_TOP_BAR_COLOR',
  BLOG_POST_TAGS_COLOR: 'BLOG_POST_TAGS_COLOR',
  BLOG_POST_TAGS_BACKGROUND: 'BLOG_POST_TAGS_BACKGROUND',
  BLOG_IS_COMMENTS_ENABLED: 'BLOG_IS_COMMENTS_ENABLED',
  BLOG_IS_CONTACT_EMAIL_USED: 'BLOG_IS_CONTACT_EMAIL_USED',
  BLOG_SOCIAL_ICONS: 'BLOG_SOCIAL_ICONS',
};

export const TYPES = {
  ATTRIBUTE: 'ATTRIBUTE',
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
};
