import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class ImageReplaceInvalidationInfoModalService {
  public readonly id = 'image-replace-invalidation-info-modal';

  public isOpened = false;

  public constructor(private modalsService: ModalsService) {
  }

  public open(): void {
    this.modalsService.open(this.id);
    
    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
