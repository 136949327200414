import {Injectable} from '@angular/core';

import {ImageFileModel} from '../core/models/images/image-file/image-file.model';

@Injectable()
export class ImagesService {
  constructor() {}

  public getImageData({ fileSize, widthPx, heightPx }: ImageFileModel) {
    return fileSize ? `${widthPx}x${heightPx}px ${this.getFormattedFilesize(fileSize)}` : '';
  }

  public getFormattedFilesize(fileSize) {
    const measures = ['Kb', 'Mb'];
    const exp = Math.floor(Math.log(fileSize) / Math.log(1024));

    return `${(fileSize / 1024 ** exp).toFixed(2)}${measures[exp - 1] || 'b'}`;
  }

  public toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => callback(reader.result);
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  public base64toBlob(base64, contentType = 'image/png', sliceSize = 512) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public dataUriToBlob(uri: string): Blob {
    let byteString;

    if (uri.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(uri.split(',')[1]);
    }
    else {
      byteString = decodeURI(uri.split(',')[1]);
    }
      

    let mimeString = uri.split(',')[0].split(':')[1].split(';')[0];

    let ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }
}
