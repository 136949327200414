import {Injectable} from '@angular/core';
import {RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {Observable, Subject, Subscriber, combineLatest} from 'rxjs';
import {tap, takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../auth/auth.service';
import {PaymentSubscriptionsService} from '../../services/payment/subscriptions/payment-subscriptions.service';
import {NavigationService} from '../../../services/navigation.service';
import {PermissionsService} from '../../services/service-permissions/permissions/permissions.service';

import {AccountModel} from '../../models/accounts/account.model';
import {SubscriptionModel} from '../../models/payment/subscriptions/subscription.model';
import {IPermissionData} from '../../models/permission/i-permission-data';

import {FORWARD_KEYS} from '../forward/constants';
import {PERMISSIONS} from '../../services/service-permissions/constants';

@Injectable()
export class DefaultPageGuard  {
  private timeoutId;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private permissionsService: PermissionsService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    const params: URLSearchParams = new URLSearchParams(window.location.search);

    const forward: string = params.get('forward');

    return new Observable((observer: Subscriber<boolean>) => {
      if (Object.values(FORWARD_KEYS).includes(forward)) {
        unsubscribe.next(true);
        unsubscribe.complete();

        observer.next(false);
        observer.complete();

        return;
      }

      this.authService.accountSubject.pipe(takeUntil(unsubscribe)).subscribe((account: AccountModel) => {
        if (!this.authService.isFetched) {
          return;
        }

        if (account.isAdmin) {
          this.navigationService.toAdmin();

          unsubscribe.next(true);
          unsubscribe.complete();

          observer.next(false);
          observer.complete();

          return;
        }

        combineLatest([
          this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }),
          this.paymentSubscriptionsService.currentSubscriptionSubject,
        ]).pipe(takeUntil(unsubscribe)).subscribe(([isStudent, subscription]: [boolean, SubscriptionModel]) => {
          if (!this.paymentSubscriptionsService.isCurrentSubscriptionFetched) {
            this.reloadPageOnStuck();

            return;
          }

          unsubscribe.next(true);
          unsubscribe.complete();
          
          observer.next(false);

          if (!subscription || (!subscription.isEducator && !isStudent)) {
            this.navigationService.toPageEditorWithCurrentPage();

            observer.complete();
            
            return;
          }

          this.navigationService.toFirstPortfolio();

          observer.complete();
        });
      });
    }).pipe(
      tap(() => {
        this.cancelReload();
      })
    );
  }

  private cancelReload(): void {
    window.clearTimeout(this.timeoutId);
  }

  private reloadPageOnStuck(): void {
    this.timeoutId = window.setTimeout(() => {
      window.alert('Window will be reloaded.');

      window.location.reload();
    }, 5000);
  }
}
