import {AudioDto} from './audio.dto';
import {AudioUploadUrlsModel} from './audio-upload-urls.model';

export class AudioUploadUrlsDto {
  constructor(public audioUrl?: string,
              public audio?: AudioDto) {}

  public static normalize(res: AudioUploadUrlsDto): AudioUploadUrlsModel {
    if (!res) return null;

    return new AudioUploadUrlsModel(
      res.audioUrl,
      AudioDto.normalize(res.audio),
    );
  }
}
