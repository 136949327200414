import {Component} from '@angular/core';

import {ImageManagerErrorModalService} from '../../../services/modals/image-manager-error/image-manager-error-modal.service';

@Component({
  selector: 'app-image-manager-error-modal',
  templateUrl: './image-manager-error-modal.component.html',
  styleUrls: ['./image-manager-error-modal.component.scss']
})
export class ImageManagerErrorModalComponent {
  public get id(): string {
    return this.service.id;
  }

  public get error(): string {
    return this.service.error;
  }

  constructor(private service: ImageManagerErrorModalService) {
  }

  public close(): void {
    this.service.close();
  }
}
