import {SelectOption} from '../../../select/option/option.model';

export const KEYS = {
  TOP: 'TOP',
  CENTER: 'CENTER',
  BOTTOM: 'BOTTOM',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('Top', KEYS.TOP),
  new SelectOption('Center', KEYS.CENTER),
  new SelectOption('Bottom', KEYS.BOTTOM),
];

export const DEFAULT_KEY = KEYS.CENTER;
export const DEFAULT_OPTION: SelectOption = OPTIONS[1];

export const CLASS_NAMES = {
  [KEYS.TOP]: 'text-vertical-align-top',
  [KEYS.CENTER]: 'text-vertical-align-center',
  [KEYS.BOTTOM]: 'text-vertical-align-bottom',
};

export const DEFAULT_CLASS_NAME = CLASS_NAMES[DEFAULT_KEY];
