import {Injectable} from '@angular/core';

import {MobileTitleSetupModel} from '../../../../models/styles/setup/website-title/mobile/mobile-title-setup.model';

@Injectable()
export class MobileTitleStylesService {
  public setup: MobileTitleSetupModel;

  public isModernMenu: boolean = false;

  constructor() {
    this.setup = new MobileTitleSetupModel();
  }

  public init(menu: HTMLElement): void {
    if (!menu) return;

    const menuBlock: HTMLElement = <HTMLElement>menu.closest('[data-menu-block]');

    this.isModernMenu = menuBlock.matches('[data-menu-type="modern"]');

    const title: HTMLElement = <HTMLElement>menuBlock.querySelector(this.isModernMenu ? '[data-website-title]' : '[data-website-title-mobile]');

    this.setup.init(title);
  }
}
