import {Component, OnInit, OnDestroy} from '@angular/core';

import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AddOnsHttpService} from '../../../../../core/services/interaction/http/add-ons/add-ons-http.service';
import {CartService} from '../../../../../core/services/cart/cart.service';
import {UpgradeSubscriptionModalService} from '../../../../../shared/services/modals/upgrade-subscription/upgrade-subscription-modal.service';
import {PaymentSubscriptionsService} from '../../../../../core/services/payment/subscriptions/payment-subscriptions.service';

import {AddOnModel} from '../../../../../core/models/add-ons/add-on.model';
import {ICartItem} from '../../../../../core/models/cart/item/cart-item.model';
import {PlanCartItemModel} from '../../../../../core/models/cart/item/plan/plan-cart-item.model';
import {AddOnCartItemModel} from '../../../../../core/models/cart/item/plan/add-on-cart-item.model';
import {SubscriptionModel} from '../../../../../core/models/payment/subscriptions/subscription.model';

import {AppAnimations} from '../../../../../app-animations';

import {PRODUCT_KEYS, PRODUCT_TYPES} from '../../../../../core/services/cart/constants';

@Component({
  selector: 'app-purchase-add-ons.component',
  templateUrl: './purchase-add-ons.component.html',
  styleUrls: ['./purchase-add-ons.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PurchaseAddOnsComponent implements OnInit, OnDestroy {
  public addOns: AddOnModel[];
  public cart: ICartItem[];

  public currentSubscription: SubscriptionModel = null;

  public isSupportPlan: boolean = false;
  public isPurchasePlanFlow: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isCartEmpty(): boolean {
    return !this.cart || this.cart.length === 0;
  }

  constructor(private addOnsHttpService: AddOnsHttpService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private cartService: CartService,
              private upgradeSubscriptionModalService: UpgradeSubscriptionModalService) {
    this.addOnsHttpService.fetch();
    this.cartService.fetch();
  }

  public ngOnInit(): void {
    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      this.initIsSupportPlan();
    });

    this.cartService.cartSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cart: ICartItem[]) => {
      this.cart = cart;

      this.initIsSupportPlan();
      this.initAddOns();
    });

    this.addOnsHttpService.addOnsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((addOns: AddOnModel[]) => {
      this.addOns = addOns;

      this.initAddOns();
    });

    this.upgradeSubscriptionModalService.isPlanPurchaseSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPlanPurchase: boolean) => {
      this.isPurchasePlanFlow = isPlanPurchase;
    });
  }

  private initIsSupportPlan(): void {
    const purchasingPlan: PlanCartItemModel = <PlanCartItemModel>(this.cart ? this.cart.find((item: ICartItem) => item.type === PRODUCT_TYPES.PLAN) : false);

    if (purchasingPlan) {
      this.isSupportPlan = purchasingPlan.options.plan.isSupportPlan;

      return;
    }

    this.isSupportPlan = this.currentSubscription ? this.currentSubscription.plan.isSupportPlan : false;
  }

  private initAddOns(): void {
    if (!this.addOns) return;

    this.addOns.forEach((addOn: AddOnModel) => {
      addOn.isAddedToCart = this.cart.findIndex((item: ICartItem) => item.type === PRODUCT_TYPES.ADD_ON && item.stripePriceId === addOn.stripePriceId) !== -1;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.upgradeSubscriptionModalService.isPlanPurchaseSubject.next(false);
  }

  public onCheckboxClick(e: KeyboardEvent, addOn: AddOnModel): Subscription {
    const target: HTMLElement = <HTMLElement>e.target;

    if (target.tagName === 'A') {
      return Subscription.EMPTY;
    }

    e.preventDefault();
    e.stopPropagation();

    if (!addOn || !addOn.isPurchasable) {
      return Subscription.EMPTY;
    }

    addOn.isAddedToCart = !addOn.isAddedToCart;

    const cartItem: AddOnCartItemModel = new AddOnCartItemModel(
      PRODUCT_KEYS.ONE_TIME,
      PRODUCT_TYPES.ADD_ON,
      addOn.stripeProductId,
      addOn.stripePriceId,
    );

    return addOn.isAddedToCart ? this.cartService.addItem(cartItem) : this.cartService.deleteItem(cartItem);
  }

  public onPurchaseButtonClick(): void {
    if (this.isCartEmpty) return;

    this.upgradeSubscriptionModalService.open();
  }

  public onNoThanksButtonClick(): void {
    if (this.isCartEmpty) return;

    this.upgradeSubscriptionModalService.open();
  }
}
