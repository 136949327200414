import {UserSocialNetworkDto} from './user-social-network.dto';

export class UserSocialNetwork {
  constructor(public id?: number,
              public userId?: number,
              public websiteId?: number,
              public token?: string,
              public socialNetworkId?: string,
              public userName?: string,
              public uri?: string,
              public selectedIcon?: string,
              public orderNo?: number,
              public isShown?: boolean) {}

  public static listFromResponse(res: UserSocialNetworkDto[]): UserSocialNetwork[] {
    return res.map((item: UserSocialNetworkDto) => UserSocialNetwork.fromDto(item));
  }

  public static toDto(socialNetwork: UserSocialNetwork): UserSocialNetworkDto {
    return socialNetwork ? new UserSocialNetworkDto(
      socialNetwork.id,
      socialNetwork.userId,
      socialNetwork.websiteId,
      socialNetwork.token,
      socialNetwork.socialNetworkId,
      socialNetwork.userName,
      socialNetwork.uri,
      socialNetwork.selectedIcon,
      socialNetwork.orderNo,
      socialNetwork.isShown
    ) : null;
  }

  public static fromDto(res: UserSocialNetworkDto): UserSocialNetwork {
    return res ? new UserSocialNetwork(
      res.Id,
      res.UserId,
      res.WebsiteId,
      res.Token,
      res.SocialNetworkId,
      res.UserName,
      res.Uri,
      res.SelectedIcon,
      res.OrderNo,
      res.IsShown
    ) : null;
  }
}
