import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../../../../app-animations';

import {PaymentSubscriptionsService} from '../../../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {PaymentService} from '../../../../../../core/services/payment/payment.service';
import {CreditCardsModalService} from '../../../../../../shared/services/modals/credit-cards/credit-cards-modal.service';
import {CouponsModalService} from '../../../../../../shared/services/modals/coupons/coupons-modal.service';
import {AuthService} from '../../../../../../auth/auth.service';

import {SubscriptionModel} from '../../../../../../core/models/payment/subscriptions/subscription.model';
import {PaymentModel} from '../../../../../../core/models/payment/payment.model';
import {CardModel} from '../../../../../../core/models/payment/card/card.model';
import {StripeCouponModel} from '../../../../../../core/models/stripe/coupon/stripe-coupon.model';
import {AccountModel} from '../../../../../../core/models/accounts/account.model';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  @Input() subscription: SubscriptionModel;

  public paymentData: PaymentModel;
  public account: AccountModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get source(): string {
    if (!this.subscription) return '';

    if (this.subscription.defaultSource) return `${this.subscription.defaultSource.brand} *${this.subscription.defaultSource.last4}`;

    const defaultCard: CardModel = this.defaultCard;

    return defaultCard ? `${defaultCard.brand} *${defaultCard.last4}` : '';
  }

  private get defaultCard(): CardModel {
    if (!this.paymentData || !this.paymentData.cards || this.paymentData.cards.length === 0) return null;

    return this.paymentData.cards.find((card: CardModel) => card.isDefault);
  }

  public get canBeCancelled(): boolean {
    if (!this.subscription) return false;

    return this.subscription.metadata.addOnKey !== 'SUPPORT_INCLUDED_ANNUAL_ONE_HOUR' && this.subscription.metadata.addOnKey !== 'SUPPORT_INCLUDED_ANNUAL_TWO_HOURS' && this.subscription.metadata.addOnKey !== 'SUPPORT_INCLUDED_DAILY_ONE_HOUR';
  }

  constructor(private subscriptionsService: PaymentSubscriptionsService,
              private authService: AuthService,
              private paymentService: PaymentService,
              private creditCardsModalService: CreditCardsModalService,
              private couponsModalService: CouponsModalService) {
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.paymentService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paymentData: PaymentModel) => {
      this.paymentData = paymentData;
    });

    this.couponsModalService.selectedCouponSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coupon: StripeCouponModel) => {
      if (!coupon || this.couponsModalService.openedFor !== this.subscription) return;

      this.updateCoupon(coupon);
    });
  }

  private updateCoupon(coupon: StripeCouponModel): void {
    this.subscriptionsService.updateSubscriptionCoupon({ subscriptionId: this.subscription.id, couponId: coupon.id }).subscribe(() => {
      this.subscriptionsService.init();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public getStatusButtonText(): string {
    return this.subscription.isCancelledAtPeriodEnd ? 'Restore' : 'Cancel';
  }

  public onCouponApplyClick(): void {
    this.couponsModalService.open();

    this.couponsModalService.openedFor = this.subscription;
  }

  public onUpdateCardClick(): void {
    const card: CardModel = this.getCard();

    this.creditCardsModalService.open(card);

    this.creditCardsModalService.openedFor = this.subscription;
  }

  private getCard(): CardModel {
    const subscriptionDefaultSource: string = this.subscription.defaultSource ? this.subscription.defaultSource.id : null;

    if (subscriptionDefaultSource) return this.paymentData.cards.find((card: CardModel) => card.id === subscriptionDefaultSource);

    return this.paymentData.cards.find((card: CardModel) => card.isDefault);
  }

  public toggleStatus(): void {
    if (!this.canBeCancelled) return;

    this.subscriptionsService.toggleStatus(this.subscription);
  }
}
