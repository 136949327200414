import {Injectable} from '@angular/core';

import {GoogleFontsService} from '../../../../google/fonts/google-fonts.service';

import {SelectOption} from '../../../../../models/select/option/option.model';
import {SetupModel} from '../../../../../models/styles/setup/setup.model';
import {GoogleFontModel} from '../../../../../models/google/fonts/google-font.model';

@Injectable()
export class SlideshowParagraphFontsStylesService {
  public setup: SetupModel;

  public fontWeightsOptions: SelectOption[] = [];

  public get element(): HTMLElement {
    return this.setup.element;
  }

  constructor(private googleFontsService: GoogleFontsService) {
  }

  public init(setup: SetupModel): void {
    this.setup = setup;

    const font = this.googleFontsService.getFontForEditor(this.setup.scalableSlideshowParagraphFontFamily.value);

    if (!font) return;

    this.setup.scalableSlideshowParagraphFontFamily.setValue(font);

    this.initCurrentFontWeight(font);
    this.initFontWeightsOptions();
  }

  public initFontWeight(): void {
    const font = this.googleFontsService.getFontForEditor(this.setup.scalableSlideshowParagraphFontFamily.value);

    if (!font) return;

    this.initCurrentFontWeight(font);
  }

  public onFontChange(font: GoogleFontModel): void {
    this.setup.scalableSlideshowParagraphFontFamily.onChange(font);

    this.initCurrentFontWeight(font);
    this.initFontWeightsOptions();
  }

  private initCurrentFontWeight(font: GoogleFontModel): void {
    if (!this.element || !font || !font.variants) return;

    const value = `${GoogleFontModel.getClosestVariant(font, this.setup.scalableSlideshowParagraphFontWeight.value)}`;

    this.setup.scalableSlideshowParagraphFontWeight.setValue(value);
  }

  public initFontWeightsOptions(): void {
    const font: GoogleFontModel = this.googleFontsService.getFontForEditor(this.setup.scalableSlideshowParagraphFontFamily.value);

    if (!font) return;

    this.fontWeightsOptions = this.googleFontsService.getVariantsForEditor(font);
  }
}
