import {Injectable} from '@angular/core';

import {IFrameService} from '../iframe.service';
import {IFrameActiveStylesService} from '../styling/active/active-styles.service';
import {IFrameHoverStylesService} from '../styling/hover/hover-styles.service';
import {IFrameMobileActiveStylesService} from '../styling/mobile-active/mobile-active-styles.service';
import {NavigationService} from '../../../../services/navigation.service';
import {EventsService} from '../../interaction/events/events.service';

import {SelectOption} from '../../../models/select/option/option.model';
import {StyleOptionModel} from '../../../models/styles/settings/option/style-option.model';

@Injectable()
export class IFrameMenuService {
  public menuBlockAttr = 'data-menu-block';
  public sideMenuAttr = 'data-side-menu';
  public menuItemAttr = 'data-menu-item';
  public menuTypeAttr = 'data-menu-type';

  public menuElement: HTMLElement;

  public get menu(): HTMLElement {
    return this.iFrameService.getItem(`[${this.menuBlockAttr}]`);
  }

  public get mobileMenu(): HTMLElement {
    return this.iFrameService.getItem(`[${this.sideMenuAttr}]`);
  }

  public get isLegacyMenu(): boolean {
    const { menu } = this;

    return !!menu && !menu.matches(`[${this.menuTypeAttr}="modern"]`);
  }

  public get menuItems(): Element[] {
    return this.iFrameService.getItems(`*[${this.menuItemAttr}]`);
  }

  public get activeOptions(): SelectOption[] {
    return this.activeStylesService.options;
  }

  public get mobileActiveOptions(): SelectOption[] {
    return this.mobileActiveStylesService.options;
  }

  public get hoverOptions(): SelectOption[] {
    return this.hoverStylesService.options;
  }

  public resetMobileActiveOptions() {
    this.mobileActiveStylesService.resetStyles();
  }

  constructor(private iFrameService: IFrameService,
              private eventsService: EventsService,
              private navigationService: NavigationService,
              private activeStylesService: IFrameActiveStylesService,
              private mobileActiveStylesService: IFrameMobileActiveStylesService,
              private hoverStylesService: IFrameHoverStylesService) {
    this.addEvents();
  }

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>): void {
    this.menuElement = element && element.matches(`[${this.menuBlockAttr}]`) ? element : null;
  }

  public onActiveOptionsSelect(options: SelectOption[]): void {
    this.activeStylesService.initItems([this.menuElement]);
    this.activeStylesService.onOptionsSelect(options);
  }

  public onMobileActiveOptionsSelect(options: SelectOption[]): void {
    this.mobileActiveStylesService.initItems([this.mobileMenu]);
    this.mobileActiveStylesService.onOptionsSelect(options);
  }

  public onHoverOptionsSelect(options: SelectOption[]): void {
    this.hoverStylesService.initItems([this.menuElement]);
    this.hoverStylesService.onOptionsSelect(options);
  }

  public resetStyles(): void {
    this.activeStylesService.resetStyles();
    this.hoverStylesService.resetStyles();
  }

  private addEvents(): void {
    this.eventsService.addFrameListener('menuItemClick', this.clickHandler.bind(this));
  }

  private clickHandler(e): void {
    if (!e.detail.isPageExists) return;

    this.navigationService.toPageEditor(e.detail);
  }
}
