import {IStyleOption} from '../option/i-style-option';

import {DEFAULT_VALUE, PROPERTY} from './constants';

export class NumberOfColumnsModel implements IStyleOption {
  public element: HTMLElement;

  public label: string = '';

  public maxValue: number = 5;

  private isMasonryPortfolio: boolean = false;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.isMasonryPortfolio = this.element.matches('[data-block-template-id="138"]') || this.element.matches('[data-block-template-id="140"]') || this.element.matches('[data-block-template-id="142"]');

    this.maxValue = this.isMasonryPortfolio ? 7 : 5;

    this.initLabel();

    const value: string = window.getComputedStyle(this.element).getPropertyValue(PROPERTY);

    return this.setValue(value);
  }

  public initLabel(): void {
    this.label = 'Number of Columns';

    if (!this.isMasonryPortfolio) return;

    const masonryType = this.element.getAttribute('data-portfolio-masonry-type');

    if (masonryType === 'VERTICAL') return;

    this.label = 'Image Sizes';
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(PROPERTY);

    const value: string = window.getComputedStyle(this.element).getPropertyValue(PROPERTY);
    const numberValue: number = Number.parseInt(value);

    this.setValue(`${Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue}`);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    const numberValue: number = Number.parseInt(value);

    this.value = Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue;

    this.element.style.setProperty(PROPERTY, `${this.value}`);
  }
}
