export class ImageLabelPriceModel {
  constructor(public printProcess: string = '',
              public dimensions: string = '',
              public matDimensions: string = '',
              public edition: string = '',
              public price: string = '',
              public availability: string = '',
              public isAvailable: boolean = false,
              public isPriceUponRequest: boolean = false) {}

  public static clone(item: ImageLabelPriceModel): ImageLabelPriceModel {
    return new ImageLabelPriceModel(
      item.printProcess,
      item.dimensions,
      item.matDimensions,
      item.edition,
      item.price,
      item.availability,
      item.isAvailable,
      item.isPriceUponRequest,
    );
  }
}
