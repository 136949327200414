

import {Injectable} from '@angular/core';

import {PaymentSubscriptionsService} from '../payment-subscriptions.service';

import {CardModel} from '../../../../models/payment/card/card.model';
import {SubscriptionModel} from '../../../../models/payment/subscriptions/subscription.model';
import {StripeCouponModel} from '../../../../models/stripe/coupon/stripe-coupon.model';

import {STATUSES_KEYS, STATUSES_ERRORS, UNPAID_STATUSES} from '../../../../models/payment/subscriptions/constants';

@Injectable()
export class UpgradeSubscriptionService {
  public currentSubscription: SubscriptionModel;
  public coupon: StripeCouponModel;
  public couponCode: string;
  public card: CardModel;

  public isCouponValid: boolean = true;

  public get isPaymentFailed(): boolean {
    return this.currentSubscription && UNPAID_STATUSES[this.currentSubscription.status];
  }

  public get isPaymentExpired(): boolean {
    return this.currentSubscription && this.currentSubscription.status === STATUSES_KEYS.INCOMPLETE_EXPIRED;
  }

  public get statusErrorMessage(): string {
    if (!this.currentSubscription || !STATUSES_ERRORS[this.currentSubscription.status]) return '';

    return STATUSES_ERRORS[this.currentSubscription.status];
  }

  constructor(private paymentSubscriptionsService: PaymentSubscriptionsService) {
    this.init();
  }

  public init() {
    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;
    });
  }

  public verify() {
    const isSubscriptionExists = this.currentSubscription && this.currentSubscription.status !== STATUSES_KEYS.INCOMPLETE;

    if (isSubscriptionExists) return;

    return this.toPurchase();
  }

  public toPurchase() {
    return this.paymentSubscriptionsService.toPurchase();
  }

  public toRepeatPayment() {
    return this.paymentSubscriptionsService.toRepeatPayment();
  }

  public clear() {
    this.card = null;
  }
}
