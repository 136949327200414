// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vs-sidebar {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF","sourcesContent":["@import \"../../../../styles/colors\";\n\n.vs-sidebar {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
