import {StyleOptionUnitModel} from './style-option-unit.model';

export class StyleOptionUnitDto {
  constructor(
    public Id: number,
    public Unit: string,
  ) {}

  public static normalize(res: StyleOptionUnitDto): StyleOptionUnitModel {
    if (!res) return null;

    return new StyleOptionUnitModel(
      res.Id,
      res.Unit,
    );
  }
}
