import {ChangeDetectorRef, Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BagService} from '../../../bag.service';
import {DetailsService} from './details.service';
import {WebsiteDesignerService} from '../website-designer/website-designer.service';
import {SidebarSectionsService} from '../../../services/sidebar-sections.service';
import {WebsiteTourService} from '../../../core/services/website-tour/website-tour.service';
import {UtilsService} from '../../../core/services/utils/utils.service';

import {TOUR_KEY} from './constants';
import {KEYS} from '../../../core/services/website-tour/constants';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  TAB = {
    WEBSITE: 'WEBSITE',
    // PAGE: 'PAGE',
    ELEMENT: 'ELEMENT',
  };

  activeTab = this.TAB.WEBSITE;
  elementTabCanBeActivated = false;

  @Input() collapsed = true;
  @Input() collapsible = true;
  @Input() showCloseIcon = false;
  @Input() isFloating: boolean = false;

  selected: Array<any> = [];

  fonts = [];

  warningText = 'Changing Website Defaults enables you to setup custom styles for your entire site such as background color and fonts.';

  @ViewChild('customizerTabs') customizerTabs: any;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private cdr: ChangeDetectorRef,
              private websiteDesignerService: WebsiteDesignerService,
              private sidebarSectionsService: SidebarSectionsService,
              private websiteTourService: WebsiteTourService,
              private utilsService: UtilsService,
              public bag: BagService,
              public detailsService: DetailsService) {
    this.handleTours = this.utilsService.debounce(this.handleTours.bind(this), 500);
  }

  public ngOnInit(): void {
    this.bag.change.subscribe(value => {
      if (value.selectedPage) {
        this.collapsed = true;
      }

      this.handleTours();
    });

    this.sidebarSectionsService.isCustomizerCollapsedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCollapsed: boolean) => {
      this.collapsed = isCollapsed;

      this.handleTours();
    });

    this.websiteDesignerService.editingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onComponentChange.bind(this));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public selectTab(tab): void {
    tab = tab || this.TAB.WEBSITE;

    this.activeTab = tab;

    if (this.customizerTabs && this.customizerTabs.nativeElement) {
      const activeTabIdx: number = Object.keys(this.TAB).indexOf(tab);

      Array.from(this.customizerTabs.nativeElement.childNodes).forEach((elem: HTMLElement, idx: number) => {
        elem.classList.toggle('active', idx === activeTabIdx);
      });
    }

    this.cdr.detectChanges();
  }

  private onComponentChange(options): void {
    let targetTab;

    if (options) {
      const editingTypesOfElementTab = ['ELEMENT', 'DIVIDER', 'BLOCK'];

      if (editingTypesOfElementTab.includes(options.editingType)) {
        targetTab = this.TAB.ELEMENT;
        this.elementTabCanBeActivated = true;
      } else {
        targetTab = this.TAB.WEBSITE;
        this.elementTabCanBeActivated = false;
      }

      this.toggleDetails(true);
    } else {
      targetTab = this.TAB.WEBSITE;
      this.elementTabCanBeActivated = false;
    }

    this.selectTab(targetTab);
  }

  public toggleDetails(value?: boolean): void {
    this.collapsed = value === void 0 ? !this.collapsed : !value;

    this.sidebarSectionsService.isCustomizerCollapsedSubject.next(this.collapsed);
  }

  private handleTours(): void {
    if (this.collapsed) {
      this.websiteTourService.removeVisibleLocation(TOUR_KEY);
      this.websiteTourService.removeVisibleItem(KEYS.SIDEBAR_CUSTOMIZER);
      this.websiteTourService.removeVisibleItem(KEYS.SIDEBAR_CUSTOMIZER_TITLE);

      return;
    }

    this.websiteTourService.addVisibleLocation(TOUR_KEY);
    this.websiteTourService.addVisibleItem(KEYS.SIDEBAR_CUSTOMIZER);
    this.websiteTourService.addVisibleItem(KEYS.SIDEBAR_CUSTOMIZER_TITLE);
  }
}
