import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {SelectExhibitionModalService} from '../../../../services/modals/education/select-exhibition/select-exhibition-modal.service';
import {EducationWebsiteExhibitionsService} from '../../../../../core/services/education/teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';
import {EducationStudentsImagesService} from '../../../../../core/services/education/teachers/institutions/classes/students/images/education-students-images.service';

import {PortfolioModel} from '../../../../../core/models/portfolios/portfolio.model';
import {ImageModel} from '../../../../../core/models/images/image.model';

@Component({
  selector: 'app-select-exhibition-modal',
  templateUrl: './select-exhibition-modal.component.html',
  styleUrls: ['./select-exhibition-modal.component.scss']
})
export class SelectExhibitionModalComponent implements OnInit, OnDestroy {
  @Input() id = 'select-exhibition-modal';

  public exhibitions: PortfolioModel[] = null;

  public exhibitedTo: { [key: number]: boolean } = {};

  public header = {
    className: 'warning-header',
  };

  public isLoading: boolean = true;

  private handlers = {
    keydown: this.onKeyDown.bind(this),
  };

  private keyHandlers = {
    'Escape': this.onEsc.bind(this),
  };
  
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: SelectExhibitionModalService,
              private educationWebsiteExhibitionsService: EducationWebsiteExhibitionsService,
              private educationStudentsImagesService: EducationStudentsImagesService) {
  }

  public ngOnInit(): void {
    window.addEventListener('keydown', this.handlers.keydown);

    this.educationWebsiteExhibitionsService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolios: PortfolioModel[]) => {
      this.exhibitions = portfolios;
    });

    this.educationStudentsImagesService.currentImageExhibitedImagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      this.initExhibitedImagesData(images);
    });
  }

  private initExhibitedImagesData(exhibitedImages: ImageModel[]): void {
    this.exhibitedTo = {};

    if (!exhibitedImages) {
      return;
    }

    const isValid: boolean = this.isExhibitedImagesDataValid(exhibitedImages);

    if (!isValid) {
      return;
    }

    exhibitedImages.forEach((image: ImageModel) => {
      this.exhibitedTo[image.portfolioId] = true;
    });

    this.isLoading = false;
  }

  private isExhibitedImagesDataValid(exhibitedImages: ImageModel[]): boolean {
    if (!this.service.imageToClone) {
      return false;
    }

    return exhibitedImages.every((image: ImageModel) => {
      return image.sourceImageId === this.service.imageToClone.id;
    });
  }

  public handleClick(exhibition: PortfolioModel): void {
    if (!exhibition) {
      if (this.isLoading) {
        return;
      }

      this.service.close();

      return;
    }

    this.isLoading = true;

    this.service.onSelect.next(exhibition);
  }
  
  public onKeyDown(e: KeyboardEvent): void {
    if (!this.keyHandlers[e.key]) {
      return;
    }

    this.keyHandlers[e.key]();
  }

  public onEsc(): void {
    this.handleClick(null);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('keydown', this.handlers.keydown);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}

