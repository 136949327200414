// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .modal-body {
  padding: 0;
}

.block-template-changelog-modal .block-template-changelog-modal-body .updates-info-container {
  overflow-y: auto;
  max-height: 600px;
}
.block-template-changelog-modal .block-template-changelog-modal-body .updates-info-container .update-info {
  margin-bottom: 4px;
  margin-right: 8px;
  margin-left: 8px;
}
.block-template-changelog-modal .block-template-changelog-modal-body .updates-info-container .update-info .date {
  margin-bottom: 8px;
  font-size: 16px;
}
.block-template-changelog-modal .block-template-changelog-modal-body .updates-info-container .update-info .message {
  font-size: 14px;
  white-space: pre-wrap;
}
.block-template-changelog-modal .block-template-changelog-modal-body .close-button {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/block-template-changelog-modal/block-template-changelog-modal.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;;AAMI;EACE,gBAAA;EACA,iBAAA;AAHN;AAKM;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;AAHR;AAKQ;EACE,kBAAA;EACA,eAAA;AAHV;AAMQ;EACE,eAAA;EACA,qBAAA;AAJV;AASI;EACE,aAAA;EACA,uBAAA;AAPN","sourcesContent":[":host ::ng-deep {\n  .modal-body {\n    padding: 0;\n  }\n}\n\n.block-template-changelog-modal {\n  .block-template-changelog-modal-body {\n    .updates-info-container {\n      overflow-y: auto;\n      max-height: 600px;\n\n      .update-info {\n        margin-bottom: 4px;\n        margin-right: 8px;\n        margin-left: 8px;\n\n        .date {\n          margin-bottom: 8px;\n          font-size: 16px;\n        }\n\n        .message {\n          font-size: 14px;\n          white-space: pre-wrap;\n        }\n      }\n    }\n\n    .close-button {\n      display: flex;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
