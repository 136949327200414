import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

import {InitialSetupService} from '../users/initial-setup/initial-setup.service';

@Injectable()
export class RoutingService {
  public toAdminPanelSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private router: Router,
              private initialSetupService: InitialSetupService) {}

  public toSetup() {
    return this.router.navigateByUrl(this.initialSetupService.url);
  }
}
