import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {OriginalHomePageSetupModalService} from '../../../services/modals/original-home-page-setup/original-home-page-setup-modal.service';
import {WebsitesNodesService} from '../../../../core/services/websites-nodes/websites-nodes.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';

import {Button} from '../../../../common/models/button/button.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-original-home-page-setup-modal',
  templateUrl: './original-home-page-setup-modal.component.html',
  styleUrls: ['./original-home-page-setup-modal.component.scss']
})
export class OriginalHomePageSetupModalComponent implements OnInit, OnDestroy {
  public header: ModalHeader = {
    text: 'HOMEPAGE CHANGE',
    className: 'warning-header',
  };

  public buttons: Button[] = [
    new Button('YES', null, 'green', this.onUpdateAgree.bind(this)),
    new Button('NO', null, 'red', this.close.bind(this)),
  ];

  private activeWebsiteId: number;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: OriginalHomePageSetupModalService,
              private websitesNodesService: WebsitesNodesService,
              private websitesService: WebsitesService) {
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: number) => {
      this.activeWebsiteId = value;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onUpdateAgree(): void {
    this.websitesNodesService.updateIsOriginalHomePageRemoved({
      websiteId: this.activeWebsiteId,
      isRemoved: false,
    });

    this.close();
  }

  public close(): void {
    this.service.close();
  }
}

