

import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ReceiptModalModel} from '../../../../shared/models/modals/receipt-modal/receipt-modal.model';

@Injectable()
export class ReceiptService {
  public receiptSubject: BehaviorSubject<ReceiptModalModel> = new BehaviorSubject<ReceiptModalModel>(null);
}
