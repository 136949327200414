import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {UserSetupImagesService} from '../../core/services/users/setup/images/user-setup-images.service';
import {SetupLoaderService} from '../../core/services/loaders/setup/setup-loader.service';
import {WebsitesService} from '../../core/services/websites/websites.service';
import {WebsitesHttpService} from '../../core/services/interaction/http/websites/websites-http.service';
import {NodesService} from '../../core/services/nodes/nodes.service';
import {NavigationService} from '../../services/navigation.service';

import {IsValidImagesSetupModel} from '../../core/models/setup/images/is-valid-images-setup.model';
import {WebsiteModel} from '../../core/models/websites/website.model';

@Component({
  selector: 'app-setup-upload-images',
  templateUrl: 'setup-upload-images.component.html',
  styleUrls: ['./setup-upload-images.component.scss']
})
export class SetupUploadImagesComponent implements OnInit, OnDestroy {
  public images = [];
  public portfolioId: number;

  public isValid: boolean = false;

  private website: WebsiteModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private setupLoaderService: SetupLoaderService,
    private websitesService: WebsitesService,
    private websitesHttpService: WebsitesHttpService,
    private nodesService: NodesService,
    private navigationService: NavigationService,
    public userSetupImagesService: UserSetupImagesService
  ) {
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      if (!website || website.isSetupCompleted) return;

      this.website = website;

      this.userSetupImagesService.init();
    });

    this.userSetupImagesService.isValidSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IsValidImagesSetupModel) => {
      this.isValid = data && data.isImagesLoaded && data.isEnoughImages;
    });

    this.userSetupImagesService.imagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(images => {
      this.images = images;

      if (!images) return;

      this.setupLoaderService.hide();
    });

    this.userSetupImagesService.portfolioIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(portfolioId => {
      this.portfolioId = portfolioId;
    });
  }

  public ngOnDestroy(): void {
    this.userSetupImagesService.clear();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public finishSetup(): void {
    this.websitesHttpService.setIsSetupCompleted(true).subscribe(() => {
      this.websitesService.fetchWebsite().add(() => {
        this.nodesService.fetchNodes().add(() => {
          if (!this.website) {
            return this.navigationService.toHomePage();
          }

          if (this.website.templateId !== 12 && this.website.templateId !== 16) {
            return this.navigationService.toHomePage();
          }

          this.navigationService.toHomePage({ sidebar: ['pages'] });
        });
      });
    });
  }
}
