

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SupportMetaItemDto} from '../../../../models/accounts/support-meta/support-meta-item.dto';
import {SupportMetaItemModel} from '../../../../models/accounts/support-meta/support-meta-item.model';

@Injectable()
export class SupportHistoryHttpService {
  private model = 'support-history';

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<SupportMetaItemModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: SupportMetaItemDto[]) => res.map((item: SupportMetaItemDto) => SupportMetaItemDto.normalize(item))),
    );
  }
}
