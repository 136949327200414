import {SelectOption} from '../../../select/option/option.model';

export const KEYS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('Left', KEYS.LEFT),
  new SelectOption('Center', KEYS.CENTER),
  new SelectOption('Right', KEYS.RIGHT),
];

export const DEFAULT_KEY = KEYS.CENTER;
export const DEFAULT_OPTION: SelectOption = OPTIONS[1];

export const CLASS_NAMES = {
  [KEYS.LEFT]: 'text-horizontal-align-left',
  [KEYS.CENTER]: 'text-horizontal-align-center',
  [KEYS.RIGHT]: 'text-horizontal-align-right',
};

export const DEFAULT_CLASS_NAME = CLASS_NAMES[DEFAULT_KEY];
