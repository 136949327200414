import {IStyleOption} from '../setup/option/i-style-option';
import {StyleOptionModel} from '../settings/option/style-option.model';
import {SpaceBetweenBlocksModel} from './space-between-blocks/space-between-blocks.model';
import {MenuBlocksSpacingModel} from './menu-blocks-spacing/menu-blocks-spacing.model';
import {WebsiteMarginModel} from './website-margin/website-margin.model';
import {WebsiteLinkColorModel} from './website-link-color/website-link-color.model';
import {WebsiteLinkHoverColorModel} from './website-link-hover-color/website-link-hover-color.model';

import {KEYS, TYPES} from '../../../services/styles/website/constants';

export class WebsiteSetupModel {
  public element: HTMLElement;
  private enabledStyleOptions: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public spaceBetweenColumns: SpaceBetweenBlocksModel = new SpaceBetweenBlocksModel(KEYS.SPACE_BETWEEN_BLOCKS, TYPES.STYLE_PROPERTY);
  public menuBlocksSpacing: MenuBlocksSpacingModel = new MenuBlocksSpacingModel(KEYS.MENU_BLOCKS_SPACING, TYPES.STYLE_PROPERTY);
  public websiteMargin: WebsiteMarginModel = new WebsiteMarginModel(KEYS.WEBSITE_MARGIN, TYPES.STYLE_PROPERTY);
  public websiteLinkColor: WebsiteLinkColorModel = new WebsiteLinkColorModel(KEYS.WEBSITE_LINK_COLOR, TYPES.STYLE_PROPERTY);
  public websiteLinkHoverColor: WebsiteLinkHoverColorModel = new WebsiteLinkHoverColorModel(KEYS.WEBSITE_LINK_HOVER_COLOR, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.spaceBetweenColumns,
    this.menuBlocksSpacing,
    this.websiteMargin,
    this.websiteLinkColor,
    this.websiteLinkHoverColor,
  ];

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.element = element;
    this.enabledStyleOptions = enabledStyleOptions;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const body: HTMLBodyElement = innerWrapper ? <HTMLBodyElement>innerWrapper.closest('body') : null;
    const block: HTMLElement = this.getBlock(element);

    this.options.forEach(option => {
      option.isEnabled = this.enabledStyleOptions.has(option.key);

      if (!option.init) {
        return;
      }

      try {
        option.init(element, {
          body,
          innerWrapper,
          block,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) {
      return null;
    }

    return element.classList.contains('block') ? element : <HTMLElement>element.closest('.block');
  }
}
