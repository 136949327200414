
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {BehaviorSubject, Subscription} from 'rxjs';

import {PreLaunchChecklistHttpService} from '../interaction/http/pre-launch-checklist/pre-launch-checklist-http.service';
import {AuthService} from '../../../auth/auth.service';

import {PreLaunchProgressItemModel} from '../../models/pre-launch-progress/item/pre-launch-progress-item.model';
import {AccountModel} from '../../models/accounts/account.model';
import {PreLaunchProgressAccountDataModel} from '../../models/pre-launch-progress/account-data/pre-launch-progress-account-data.model';

@Injectable()
export class PreLaunchChecklistService {
  public itemsSubject: BehaviorSubject<PreLaunchProgressItemModel[]> = new BehaviorSubject(null);

  private account: AccountModel;

  constructor(private httpService: PreLaunchChecklistHttpService,
              private authService: AuthService) {
    this.authService.accountSubject.pipe(filter((account: AccountModel) => !!account)).subscribe((account: AccountModel) => {
      this.account = account;

      this.initList();
    });
  }

  public initList(): void {
    this.httpService.fetchAll().subscribe((items: PreLaunchProgressItemModel[]) => {
      this.handleList(items);
    });
  }

  private handleList(items: PreLaunchProgressItemModel[]): void {
    items.forEach((item: PreLaunchProgressItemModel) => {
      item.isCompleted = !!this.account.preLaunchProgress.progress[item.id];
    });

    this.itemsSubject.next(items);
  }

  public setProgress(tourProgress: PreLaunchProgressAccountDataModel): Subscription {
    return this.httpService.setProgress(tourProgress).subscribe(() => {});
  }
}
