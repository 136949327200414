import {Injectable} from '@angular/core';

import {BlogSetupModel} from '../../../models/styles/setup/blog/blog-setup.model';

import {StyleOptionModel} from '../../../models/styles/settings/option/style-option.model';

@Injectable()
export class BlogStylesService {
  public setup: BlogSetupModel;

  public get element(): HTMLElement {
    return this.setup.element;
  }

  constructor() {
    this.setup = new BlogSetupModel();
  }

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.setup.init(element, enabledStyleOptions);
  }
}
