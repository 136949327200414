export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `رأيت العاصفة؛ كم كانت مرعبة بقدر ما كانت رائعة!`,
      `ظهرت الأرض تحت السفينة الطائرة في البعد على شكل هلال متلألئ .`,
      `عندماانطفأت شعلة النار، بدأ يحدِّق في النجوم عبر النافذة.`,
      `غلّف الضباب السفينة عندما كانتعلى بعد ثلاث ساعات عن الميناء.`,
      `فما هي إلّا مسألة وقت.`,
      `كان المشهد أمامنا سامي.`,
      `كانت الأمواج تتلاطم وسط الليلة الزرقاء.`,
      `كانت الذاكرة العامل المشترك بين طبيعتي المزدوجة.`,
      `كانت السماء صافية من الغيوم ويغلب عليها لون أزرق قاتم.`,
      `كانت رحلة العودة ستتم من غير رفاق طريق.`,
      `كسى الضباب الرمادي سطح السفينة.`,
    ],
    [KEYS.ALPHABET]: [
      'ءيوهنملكقفغعظطضصشسزرذدخحجثتبأ؟!،ءآأإالأالإ',
    ],
    [KEYS.PARAGRAPH]: [
      `وظهر لون ساطع يشبه شفق الصباح الباكر؛ تحولت الشمس الى كتلة مقوسة من نار موقدة في الفضاء؛ بينما ظهر القمر شاحبًا في صورة شريط متقلب، ولم تميز عيني أي نجمة باستثناء دائرة تسطع باللون الأزرق من آن لآخر.`
    ],
    [KEYS.NUMERALS]: [
      '١٢٣٤٥٦٧٨٩١٠',
    ],
  };
};
