// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upgrade-modal-body {
  margin: 20px;
  clear: both;
  overflow: hidden;
}
.upgrade-modal-body .money-info {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/upgrade-modal/upgrade-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AADF;AAOE;EACE,gBAAA;AALJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.upgrade-modal-body {\n  margin: 20px;\n  clear: both;\n  overflow: hidden;\n\n  .header {\n\n  }\n\n  .money-info {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
