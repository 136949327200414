
import {Component, Input, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';

import {throwError as observableThrowError, Observable, Subject, Subscriber, throwError} from 'rxjs';
import {takeUntil, catchError, finalize} from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';

import {AppAnimations} from '../../../../app-animations';

import {VideoManagerService} from '../../../../core/services/video-manager/video-manager.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {MessageModalService} from '../../../../services/message-modal.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';
import {ObjectRemoveErrorModalService} from '../../../services/modals/object-remove-error/object-remove-error-modal.service';

import {VideoModel} from '../../../../core/models/videos/video.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';
import {ObjectRemoveErrorDto} from '../../../../core/models/errors/objects/remove/object-remove-error.dto';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';
import {ERRORS_KEYS} from '../../../../shared/services/errors/constants';
import {KEYS} from '../../modals/object-remove-error/constants';

@Component({
  selector: 'app-video-manager',
  templateUrl: './video-manager.component.html',
  styleUrls: ['./video-manager.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class VideoManagerComponent implements OnInit, OnDestroy {
  @Input() isModal = false;

  public permissions: IPermissionData[] = [
    {
      type: 'permission',
      value: PERMISSIONS.MEDIA_MANAGER_VIDEO_TAB_ACCESSIBILITY,
    },
  ];

  public isNewDragging: boolean = false;
  public isEnlarged: boolean = false;
  public isLoading: boolean = true;

  public videos: VideoModel[] = null;
  public uploadingVideos: VideoModel[] = [];
  public selectedVideos: VideoModel[] = [];
  public firstClickedVideo: VideoModel;

  public videoUri: SafeResourceUrl = null;

  public allowedVideoMimeTypes: string[] = [];

  public views = {
    list: {
      key: 'list',
      icon: 'fa-th',
    },
    enlarged: {
      key: 'enlarged',
      icon: 'fa-stop',
    },
  };

  public currentView = this.views.list.key;

  public editableVideoId: number = null;

  private keyDownEvent = this.onKeyDown.bind(this);

  private errorsMapping = {
    [ERRORS_KEYS.OBJECTS_USED]: this.onObjectsUsed.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isEmpty(): boolean {
    const { videos } = this;

    return videos && videos.length === 0;
  }

  public get modalId(): string {
    return this.service.modalId;
  }

  public get nOfVideos() {
    const { videos } = this;

    return videos ? videos.length : 0;
  }

  public get nOfSelectedVideos() {
    const { selectedVideos } = this;

    return selectedVideos ? selectedVideos.length : 0;
  }

  public get mime(): string {
    return this.service.mime;
  }

  constructor(private service: VideoManagerService,
              private eventsService: EventsService,
              private utilsService: UtilsService,
              private iFrameService: IFrameService,
              private buttonsService: ButtonsService,
              private objectRemoveErrorModalService: ObjectRemoveErrorModalService,
              private messageModalService: MessageModalService,
              private cdr: ChangeDetectorRef,
              private sanitizer: DomSanitizer) {
    this.allowedVideoMimeTypes = this.mime.split(',');
  }

  public ngOnInit(): void {
    this.service.loadVideos();

    this.service.isLoadingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;

      this.cdr.detectChanges();
    });

    this.service.videosSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((videos: VideoModel[]) => {
      this.videos = videos ? [...videos, ...(this.uploadingVideos ? this.uploadingVideos : [])] : [];

      this.cdr.detectChanges();
    });

    this.service.uploadVideosSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((files: File[]) => {
      this.uploadFiles(files);
    });
  }

  public onVideoClick(e: MouseEvent, video: VideoModel) {
    const { firstClickedVideo, videos } = this;
    const isNewSelected = !e.shiftKey || !firstClickedVideo || firstClickedVideo === video;

    videos.forEach(video => {
      video.isSelected = false;

      if (isNewSelected) video.isFirstClicked = false;
    });

    if (isNewSelected) return this.selectOneItem(video);

    return this.selectManyItems(videos, video);
  }

  private selectOneItem(video: VideoModel) {
    video.isFirstClicked = true;
    video.isSelected = !video.isSelected;

    this.firstClickedVideo = video;

    this.initSelectedVideos();
  }

  private selectManyItems(videos: VideoModel[], video: VideoModel) {
    const selectedIdx = videos.findIndex(v => v === this.firstClickedVideo);
    const clickedVideoIdx = videos.findIndex(v => v === video);

    const startIdx = selectedIdx < clickedVideoIdx ? selectedIdx : clickedVideoIdx;
    const endIdx = selectedIdx > clickedVideoIdx ? selectedIdx : clickedVideoIdx;

    for (let i = startIdx; i <= endIdx; i++) videos[i].isSelected = true;

    this.initSelectedVideos();
  }

  public onDragEnter() {
    this.isNewDragging = true;
  }

  public onDragLeave() {
    this.isNewDragging = false;
  }

  public onDrop(files: File[]) {
    this.isNewDragging = false;

    return files.forEach(file => {
      this.addMock(file).subscribe((video: VideoModel) => {
        this.uploadFile(file, video).subscribe(res => this.initVideo(video, res));
      });
    });
  }

  public onUpload(ev) {
    const files: File[] = Array.from(<File[]>ev.target.files);

    this.uploadFiles(files, ev);

    ev.target.value = null;
  }

  private uploadFiles(files: File[], ev?): void {
    if (!files) return;

    files.forEach(file => {
      this.addMock(file).subscribe((video: VideoModel) => {
        this.uploadFile(file, video).pipe(
          catchError(e => {
            console.error(e);

            if (ev) {
              ev.target.value = null;
            }
            
            return throwError(() => e);
          }),
        ).subscribe((res: VideoModel) => {
          this.initVideo(video, res);

          this.uploadingVideos = this.uploadingVideos.filter(uploadingVideo => uploadingVideo !== video);
        });
      });
    });
  }

  private uploadFile(file: File, video: VideoModel) {
    return this.service.upload(file, video).pipe(catchError((e: HttpErrorResponse) => {
      this.videos = this.videos.filter((v: VideoModel) => v !== video);

      this.showUploadingError(e);

      return observableThrowError(() => e);
    }));
  }

  private showUploadingError(e: HttpErrorResponse): void {
    try {
      const header: ModalHeader = new ModalHeader('Error', 'warning-header');
      const buttons: Button[] = [new Button('OK', null, 'neutral ok-button', this.onModalClose.bind(this))];
      const message: string = e.error ? e.error.message : 'Server error';

      this.messageModalService.addMessage(message, header, buttons);
    } catch (e) {
      console.error(e);
    }
  }

  private onModalClose(): void {
    this.messageModalService.close();
  }

  private initVideo(video: VideoModel, res: VideoModel) {
    if (!video || !res) return;

    video.id = res.id;
    video.userId = res.userId;
    video.fileName = res.fileName;
    video.originalFileName = res.originalFileName;
    video.link = res.link;
    video.thumbLink = res.thumbLink;
    video.isUploading = false;
  }

  private addMock(file: File): Observable<VideoModel> {
    return new Observable((observer: Subscriber<VideoModel>) => {
      const videoElement: HTMLVideoElement = document.createElement('video');

      videoElement.preload = 'metadata';

      videoElement.addEventListener('loadedmetadata', () => {
        const video = this.getMock(file, videoElement);

        this.videos.push(video);
        this.uploadingVideos.push(video);

        observer.next(video);
        observer.complete();
      },false);

      videoElement.src = URL.createObjectURL(file);
    });
  }

  private getMock(file: File, videoElement: HTMLVideoElement): VideoModel {
    return new VideoModel(
      void 0,
      uuidv4(),
      void 0,
      void 0,
      void 0,
      file.name,
      '',
      '',
      `${Math.round(videoElement.duration)}`,
      `${file.size}`,
      false,
      false,
      true,
    );
  }

  public selectAll() {
    this.videos.forEach(video => video.isSelected = true);

    this.initSelectedVideos();
  }

  public deselectAll() {
    this.videos.forEach(video => video.isSelected = false);

    this.initSelectedVideos();
  }

  private initSelectedVideos() {
    this.selectedVideos = this.videos.filter(video => video.isSelected);
  }

  // async ok
  public async onDelete() {
    const { selectedVideos } = this;
    const ids = selectedVideos.map(video => video.id);

    if (ids.length === 0) return;

    const isCurrentPageGood: boolean = await this.isCurrentPageGoodBeforeDelete({
      key: KEYS.VIDEO,
      objects: selectedVideos,
    });

    if (!isCurrentPageGood) {
      const err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } } = {
        key: 'CURRENT_PAGE_ERROR',
        message: null,
        data: {
          key: KEYS.VIDEO,
          usedObjectsData: null,
        },
      };
      
      this.objectRemoveErrorModalService.open(err);

      return;
    }

    selectedVideos.forEach(video => {
      video.isUploading = true;
      video.isDeleting = true;
    });

    const onSuccess = () => {
      this.videos = this.videos.filter(video => !selectedVideos.includes(video));

      this.initSelectedVideos();
    };
    
    this.service.deleteMany(ids).pipe(
      catchError(e => {
        console.dir(e);
  
        if (this.errorsMapping[e.error.key]) {
          this.errorsMapping[e.error.key](e.error);
        }
  
        selectedVideos.forEach(video => {
          video.isUploading = false;
        });

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  // async ok
  private async isCurrentPageGoodBeforeDelete(data: { key, objects: VideoModel[] }): Promise<boolean> {
    if (!this.isModal) return true;

    return new Promise<boolean>((resolve, reject) => {
      this.eventsService.addFrameListener('objectsDataByObjectsId', (e: CustomEvent) => {
        try {
          const { objects } = e.detail;

          resolve(objects.length === 0);
        } catch (e) {
          resolve(false);
        }
      }, { once: true });

      this.eventsService.dispatchRequestObjectsDataByObjectsId(data, this.iFrameService.sandboxWindow);
    });
  }

  public onSelect(video: VideoModel): void {
    const data = {
      link: video.link,
      thumbLink: video.thumbLink,
      element: this.service.selectedElement,
    };

    this.eventsService.dispatchVideoSelect(data, this.iFrameService.sandboxWindow);

    this.onClose();

    this.buttonsService.enableSaveButton();
  }

  public onLinkCopy(document: VideoModel): Promise<void> {
    return this.utilsService.copyToClipboard(document.link);
  }

  public onCancelUpload(video: VideoModel): void {
    if (!video.isUploading) return;

    this.videos = this.videos.filter((v: VideoModel) => v !== video);

    this.service.cancelUploading(video);
  }

  public onClose() {
    this.service.close();
    this.videos = [];
    this.selectedVideos = [];
    this.firstClickedVideo = null;
  }

  public onViewChange(key) {
    if (key === this.views.list.key) {
      this.currentView = key;

      return;
    }

    if (!this.firstClickedVideo) return;

    this.currentView = key;

    this.cdr.detectChanges();

    this.enlarge(this.firstClickedVideo);
  }

  public onPlayClick(e: MouseEvent, video: VideoModel): void {
    if (e.shiftKey) return;

    this.enlarge(video);
  }

  public enlarge(video: VideoModel) {
    this.isEnlarged = true;

    this.videoUri = this.sanitizer.bypassSecurityTrustResourceUrl(video.link);

    this.addEnlargementHandlers();

    this.cdr.detectChanges();
  }

  private addEnlargementHandlers(): void {
    window.addEventListener('keydown', this.keyDownEvent);
  }

  private onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode !== 27) return;

    this.closeEnlargement();

    this.removeEnlargementHandlers();
  }

  private removeEnlargementHandlers(): void {
    window.removeEventListener('keydown', this.keyDownEvent);
  }

  public onThumbLoaded(video: VideoModel) {
    video.isThumbLoaded = true;
  }

  public closeEnlargement() {
    if (!this.isEnlarged) return;

    this.currentView = this.views.list.key;

    this.isEnlarged = false;

    this.videoUri = null;

    this.cdr.detectChanges();
  }

  public editName(event, video: VideoModel): void {
    event.stopPropagation();

    const input = event.target.nextElementSibling;

    this.editableVideoId = video.id;

    input.value = video.originalFileName;

    setTimeout(() => input.focus());
  }

  public updateName(event): void {
    const input = event.target;

    if (!input.value || !this.editableVideoId) return;

    const editableVideo = this.videos.find(video => video.id === this.editableVideoId);

    if (!editableVideo || input.value === editableVideo.originalFileName) {
     this.editableVideoId = null;

     return;
    }

    this.service.updateName(this.editableVideoId, input.value).pipe(
      catchError(e => {
        console.error(e);

        input.value = editableVideo.originalFileName;
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.editableVideoId = null;
      }),
    ).subscribe(() => {
      editableVideo.originalFileName = input.value;
    });
  }

  private onObjectsUsed(err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } }): void {
    this.objectRemoveErrorModalService.open(err);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.service.close();

    this.removeEnlargementHandlers();
  }
}
