import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, BehaviorSubject, Subscription, tap, catchError, throwError} from 'rxjs';

import {AuthService} from '../../../auth/auth.service';
import {WebsitesService} from '../websites/websites.service';
import {WebsiteDesignerService} from '../../../application/main/website-designer/website-designer.service';

import {NodeModel} from '../../models/nodes/node.model';
import {WebsiteModel} from '../../models/websites/website.model';

@Injectable()
export class NodesService {
  private model: string = 'nodes';

  public nodesSubject: BehaviorSubject<NodeModel[]> = new BehaviorSubject<NodeModel[]>([]);
  public isSplashEnabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isNodeCanBeDroppedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get nodes(): NodeModel[] {
    return this.nodesSubject.value;
  }

  public get homePage(): NodeModel {
    return this.nodes ? this.nodes.find((node: NodeModel) => node.isHomePage) : null;
  }

  constructor(private http: HttpClient,
              private authService: AuthService,
              private websitesService: WebsitesService,
              private websiteDesignerService: WebsiteDesignerService) {
    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      if (!website) return this.nodesSubject.next([]);

      this.fetchNodes();
    });
  }

  public fetchNodes(): Subscription {
    if (!this.authService.account) {
      return Subscription.EMPTY;
    }

    return this.http.get(`/api/app/${this.model}`).subscribe((nodes: NodeModel[]) => {
      if (!nodes) return;

      const homePage: NodeModel = nodes.find((node: NodeModel) => node.isHomePage);

      if (homePage) this.getIsSplashVisible(homePage);

      this.nodesSubject.next(nodes.map((node: NodeModel) => NodeModel.normalize(node)));
    });
  }

  public updateNodeTitle(id: number, title: string): Observable<any> {
    return this.http.put(`/api/app/${this.model}/${id}/title`, { title }, { responseType: 'json' });
  }

  public reInitSplashVisibility(): Subscription {
    const homePage = this.homePage;

    return homePage ? this.getIsSplashVisible(homePage) : Subscription.EMPTY;
  }

  private getIsSplashVisible({ id, type }: NodeModel) {
    return this.websiteDesignerService.getSplashInfo(id, type).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      })
    ).subscribe(splashInfo => {
      this.isSplashEnabledSubject.next(splashInfo && splashInfo.showSplash);
    });
  }
}
