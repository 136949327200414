import {Component, OnDestroy} from '@angular/core';
import {Subject, throwError} from 'rxjs';

import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../auth/auth.service';
import {AppSettingsService} from '../../../../core/services/app-settings/app-settings.service';
import {AppVersionChangesService} from '../../../../core/services/app-version-changes/app-version-changes.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';
import {AdminService} from '../admin.service';

import {AppSettingsModel} from '../../../../core/models/app-settings/app-settings.model';

@Component({
  selector: 'app-admin-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class AdminAppVersionComponent implements OnDestroy {
  private key = 'AdminAppVersionComponent';

  public settings: AppSettingsModel = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private adminService: AdminService,
              private authService: AuthService,
              private appSettingsService: AppSettingsService,
              private appVersionChangesService: AppVersionChangesService,
              private loaderService: ContentLoaderService) {
    this.appSettingsService.settingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((settings: AppSettingsModel) => {
      this.settings = settings;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onSave() {
    this.appSettingsService.updateChanges(this.settings.versionChanges.id, this.settings.versionChanges.changes).subscribe(() => {
      return this.authService.updateCurrentUser().add(() => this.appSettingsService.init());
    });
  }

  public showModal() {
    this.appVersionChangesService.changes = this.settings.versionChanges.changes;
    this.appVersionChangesService.showModal();
  }

  public enablePublish() {
    this.loaderService.show(this.key);

    this.adminService.enablePublishButtonForActiveWebsites().pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      }),
    ).subscribe(() => {});
  }
}
