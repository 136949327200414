import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Observable, Subscriber, Subscription, BehaviorSubject} from 'rxjs';

import {AdminEducationTeachersHttpService} from '../../../interaction/http/admin/education/teachers/admin-education-teachers-http.service';

import {EducationTeacherModel} from '../../../../models/education/teachers/education-teacher.model';

@Injectable()
export class AdminEducationTeachersService {
  public teachersSubject: BehaviorSubject<EducationTeacherModel[]> = new BehaviorSubject<EducationTeacherModel[]>(null);
  public teacherDetailsSubject: BehaviorSubject<EducationTeacherModel> = new BehaviorSubject<EducationTeacherModel>(null);

  constructor(private httpService: AdminEducationTeachersHttpService) {
  }

  public create(item: EducationTeacherModel): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      this.httpService.create(item).subscribe(() => {
        this.fetchAll();

        observer.next(null);
        observer.complete();
      }, (res: HttpErrorResponse) => {
        observer.next(res.error ? res.error.message : res.statusText);
        observer.complete();
      });
    });
  }

  public fetchOne(id: number): Subscription {
    return this.httpService.getOne(id).subscribe((res: EducationTeacherModel) => {
      this.teacherDetailsSubject.next(res);
    });
  }

  public update(item: EducationTeacherModel): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      this.httpService.update(item).subscribe(() => {
        this.fetchAll();

        observer.next(null);
        observer.complete();
      }, (res: HttpErrorResponse) => {
        observer.next(res.error ? res.error.message : res.statusText);
        observer.complete();
      });
    });
  }

  public remove(item: EducationTeacherModel): Subscription {
    return this.httpService.remove(item.id).subscribe(() => {
      this.fetchAll();
    });
  }

  public fetchAll(): Subscription {
    return this.httpService.getAll().subscribe((res: EducationTeacherModel[]) => {
      this.teachersSubject.next(res);
    });
  }
}
