import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {DiskIsAlmostFullModalService} from '../../../services/modals/disk-is-almost-full/disk-is-almost-full-modal.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {AuthService} from '../../../../auth/auth.service';

import {Button} from '../../../../common/models/button/button.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';

@Component({
  selector: 'app-disk-is-almost-full-modal',
  templateUrl: './disk-is-almost-full-modal.component.html',
  styleUrls: ['./disk-is-almost-full-modal.component.scss']
})
export class DiskIsAlmostFullModalComponent implements OnInit, OnDestroy {
  @Input() id: string;

  public modalHeader: ModalHeader = {
    text: 'Disk Is Almost Full',
    className: 'neutral-header',
  };

  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.submitHandler.bind(this),
    }
  ];

  public planDiskSpaceMb: number;

  public isOpened: boolean = false;
  public isUserImported: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private modalsService: ModalsService,
              private authService: AuthService,
              private service: DiskIsAlmostFullModalService,
              private paymentSubscriptionsService: PaymentSubscriptionsService) {
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.isUserImported = account ? account.isUserImported : false;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.planDiskSpaceMb = subscription ? subscription.planDiskSpaceMb : 0;
    });
  }

  public onModalOpen() {
    this.isOpened = true;
  }

  public redirectToPurchase(): Promise<boolean> {
    this.submitHandler();

    return this.paymentSubscriptionsService.toPurchase();
  }

  public submitHandler() {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
