import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTE, DEFAULT_VALUE} from './constants';

export class SlideshowSlideDurationModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = Number.parseInt(this.element.getAttribute(ATTRIBUTE));
  }

  public onChange(value: number): void {
    if (!this.isEnabled) return;

    this.value = value;

    this.element.setAttribute(ATTRIBUTE, `${value}`);
  }

  public reset(): void {
    this.value = DEFAULT_VALUE;

    this.element.setAttribute(ATTRIBUTE, `${DEFAULT_VALUE}`);
  }
}
