import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDecimalNumber]',
})
export class DecimalNumberDirective {
  @Input() appDecimalNumber: boolean;

  private input: HTMLInputElement = null;

  private reBlur: RegExp = /(^\d{1,5}\.\d{1,2}$)|(^\d{0,7}$)/;
  private re: RegExp = /(^\d{1,5}\.\d{1,2}$)|(^\d{0,7}$)|(^\d{1,5}\.$)/;
  private reForMatch: RegExp = /(^\d{1,5}\.\d{1,2})|(^\d{0,7})|(^\d{1,5}\.)/;

  private lastValue: string = null;

  constructor(private elem: ElementRef) {
    this.input = <HTMLInputElement>elem.nativeElement;
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (!this.appDecimalNumber) {
      return;
    }

    const isTrailingDot = this.input.value?.endsWith('.');

    const value: number = Math.round(this.toFixed2(this.input.value) * 100) / 100;

    this.lastValue = Number.isNaN(value) ? this.input.value : `${value}${isTrailingDot ? '.' : ''}`;
  }

  private toFixed2(n: string): number {
    return Number.parseFloat(n.match(this.reForMatch)[0]);
  }

  @HostListener('keyup', ['$event']) onKeyUp(e: KeyboardEvent) {
    if (!this.appDecimalNumber) {
      return;
    }

    if (this.re.test(this.input.value)) {
      return;
    }
    
    this.input.value = `${this.lastValue}`;

    this.input.dispatchEvent(new Event('change'));
  }

  @HostListener('blur', ['$event']) onBlur(e: KeyboardEvent) {
    if (!this.appDecimalNumber) {
      return;
    }

    if (!this.reBlur.test(this.input.value)) {
      this.input.value = `${this.lastValue}`;
    }

    this.input.dispatchEvent(new Event('change'));
  }
}
