// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content-wrapper {
  margin: 20px 20px 25px 20px;
  clear: both;
  overflow: hidden;
  text-transform: initial;
}
.modal-content-wrapper .websites-limit-exceeded-text-wrapper {
  text-align: center;
}
.modal-content-wrapper .actions {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/websites-limit-exceeded/websites-limit-exceeded-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,2BAAA;EACA,WAAA;EACA,gBAAA;EACA,uBAAA;AADF;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.modal-content-wrapper {\n  margin: 20px 20px 25px 20px;\n  clear: both;\n  overflow: hidden;\n  text-transform: initial;\n\n  .websites-limit-exceeded-text-wrapper {\n    text-align: center;\n  }\n\n  .actions {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
