export class EducationStudentPortfolioModel {
  constructor(public id?: number,
              public institutionId?: number,
              public classId?: number,
              public studentId?: number,
              public userId?: number,
              public websiteId?: number,
              public nodeId?: number) {
  }
}
