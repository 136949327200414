import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {TemplatesService} from '../../../services/templates/templates.service';

import {TemplateModel} from '../../../models/templates/template.model';
import {TemplatePageModel} from '../../../models/templates/pages/template-page.model';
import {ThemeModel} from '../../../../application/main/website-designer/theme-pane/theme.model';
import {TemplatePreviewDetailsModel} from '../../../models/templates/previews/details/template-preview-details.model';

@Component({
  selector: 'app-template-example-manager',
  templateUrl: 'template-example-manager.component.html',
  styleUrls: ['./template-example-manager.component.scss']
})
export class TemplateExampleManagerComponent implements OnInit, OnDestroy {
  @Output() pageSelectHandler: EventEmitter<TemplatePageModel> = new EventEmitter<TemplatePageModel>();
  @Output() openWebsite: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeHandler: EventEmitter<void> = new EventEmitter<void>();

  public isLoaded: boolean = false;

  public template: TemplateModel = null;
  public selectedTheme: ThemeModel = { ThemeID: 1 };

  public pages: TemplatePageModel[] = [];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get themes(): TemplatePreviewDetailsModel[] {
    if (!this.template || !this.template.preview || !this.template.preview.themes || !this.selectedTheme) return null;

    return this.template.preview.themes[this.selectedTheme.ThemeID];
  }

  constructor(private service: TemplatesService) {}

  public ngOnInit(): void {
    this.service.selectedTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.template = template;
    });

    this.service.templatePagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((pages: TemplatePageModel[]) => {
      this.pages = pages;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public selectPage(page: TemplatePageModel): void {
    this.pageSelectHandler.emit(page);
  }

  public showWebsite(theme: TemplatePreviewDetailsModel): void {
    this.openWebsite.emit(theme.previewUrl);
  }

  public onImageError(theme: TemplatePreviewDetailsModel): void {
    console.error(`Can't load image for template ${theme.templateId}, theme ${theme.id}.`);

    this.onImageLoaded(theme);
  }

  public onImageLoaded(theme: TemplatePreviewDetailsModel): void {
    const themes: TemplatePreviewDetailsModel[] = this.themes;

    if (!theme || !themes) return;

    theme.isImageLoaded = true;

    this.isLoaded = themes.filter((template: TemplateModel) => template.isImageLoaded).length === themes.length;
  }

  public close(): void {
    this.isLoaded = false;

    this.closeHandler.emit();

    const themes: TemplatePreviewDetailsModel[] = this.themes;

    if (!themes) return;

    themes.forEach((theme: TemplatePreviewDetailsModel) => {
      theme.isImageLoaded = false;
    });
  }
}
