import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class IFrameClickCatcherService {

  iframe = null;
  iframeClick = new Subject();

  private iframeMouseOver = false;

  constructor() {
    this.subscribeWindowBlur();
    this.subscribeWindowMouseOver();
    this.subscribeWindowMouseOut();
  }

  subscribeWindowBlur() {
    window.addEventListener('blur', this.handleWindowBlur);
  }

  subscribeWindowMouseOver() {
    window.addEventListener('mouseover', this.handleWindowMouseOver);
  }

  subscribeWindowMouseOut() {
    window.addEventListener('mouseout', this.handleWindowMouseOut);
  }

  handleWindowBlur = () => {
    if(this.iframeMouseOver) {
      this.iframeClick.next(this.iframe);
    }
  };

  handleWindowMouseOver = (ev) => {
    if(ev.target.tagName == 'IFRAME') {
      this.iframe = ev.target;
      this.iframeMouseOver = true;
    }
  };

  handleWindowMouseOut = (ev) => {
    if(ev.target.tagName == 'IFRAME') {
      this.iframe = null;
      this.iframeMouseOver = false;
    }
  };

}
