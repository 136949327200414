import { Injectable } from '@angular/core';

import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {WebsiteDesignerService} from '../website-designer.service';
import {DetailsService} from '../../details/details.service';
import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {BlocksService} from '../../../sidebar-short/sidebar/blocks/blocks.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {WebsiteBlocksService} from '../../../../core/services/websites/blocks/website-blocks.service';

@Injectable()
export class PageEditorService {
  public isEditorLoaded: boolean = false;
  public isPageMenuCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isDraggingBlock: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isCustomMenuBlock: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public draggedBlockData: BehaviorSubject<any> = new BehaviorSubject(null);
  public reload: Subject<boolean> = new Subject();

  constructor(private websiteDesignerService: WebsiteDesignerService,
              private detailsService: DetailsService,
              private editorControlButtonsService: EditorControlButtonsService,
              private iFrameService: IFrameService,
              private blocksService: BlocksService,
              private websiteBlocksService: WebsiteBlocksService,
              private eventsService: EventsService) {
    try {
      const value = JSON.parse(localStorage.getItem('isPageMenuCollapsed')) || false;
      this.isPageMenuCollapsedSubject.next(value);
    } catch (error) {
      this.isPageMenuCollapsedSubject.next(true);
    }

    this.addHandlers();
  }

  public saveBlocksAndDetails(pageMeta, convertedBlocks): Observable<any>[] {
    const dataToSave = {
      websiteId: pageMeta.WebsiteID,
      meta: pageMeta,
      blocks: [...convertedBlocks],
      movedBlocksToAnotherPage: [...this.blocksService.blocksToMoveToAnotherPage],
    };

    this.blocksService.blocksToMoveToAnotherPage = [];
    
    return [
      this.websiteBlocksService.saveBlocks(dataToSave),
      this.detailsService.saveDetails(pageMeta.WebsiteID, pageMeta.TemplateID, pageMeta.PageID, pageMeta.PageType),
    ];
  }

  togglePageMenuCollapse() {
    this.isPageMenuCollapsedSubject.next(!this.isPageMenuCollapsedSubject.value);

    localStorage.setItem('isPageMenuCollapsed', JSON.stringify(this.isPageMenuCollapsedSubject.value));
  }

  private addHandlers() {
    this.eventsService.addFrameListener('blockDragStart', this.blockDragStartHandler.bind(this));
    this.eventsService.addFrameListener('blockDragEnd', this.blockDragEndHandler.bind(this));
    this.eventsService.addFrameListener('stylesApplied', this.onStylesApplied.bind(this));

    this.eventsService.addFrameListener('blockAdded', (e: CustomEvent) => {
      try {
        if (e.detail.block.Block_Category === 'Menus') {
          const isCustomMenuBlock: boolean = !!(e.detail.block.PageID && e.detail.block.PageType);

          this.isCustomMenuBlock.next(isCustomMenuBlock);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  private blockDragStartHandler(event) {
    this.draggedBlockData.next(event.detail);

    this.isDraggingBlock.next(true);
  }

  private blockDragEndHandler(event) {
    this.draggedBlockData.next(null);

    this.isDraggingBlock.next(false);
  }

  private onStylesApplied() {
    this.iFrameService.onStylesAppliedSubject.next(true);
  }
}
