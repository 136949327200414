import {IStyleModelOptions} from '../option/i-style-model-options';

import {BaseColorModel} from '../base/color/base-color.model';

import {PROPERTY_NAME} from './constants';

export class EnlargementIconColorModel extends BaseColorModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, null, value, isEnabled);
  }

  public init(selectedElement: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !selectedElement) {
      return;
    }

    super.init(options.block || options.portfolioEnlargement, {
      selectedElement,
      defaultValueHolderElement: selectedElement,
    });
  }
}
