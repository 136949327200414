export const KEYS = {
  PADDING_TOP_VW: 'PADDING_TOP_VW',
  PADDING_RIGHT_VW: 'PADDING_RIGHT_VW',
  PADDING_BOTTOM_VW: 'PADDING_BOTTOM_VW',
  PADDING_LEFT_VW: 'PADDING_LEFT_VW',
};

export const STYLE_PROPERTIES = {
  [KEYS.PADDING_TOP_VW]: '--padding-vw-top',
  [KEYS.PADDING_RIGHT_VW]: '--padding-vw-right',
  [KEYS.PADDING_BOTTOM_VW]: '--padding-vw-bottom',
  [KEYS.PADDING_LEFT_VW]: '--padding-vw-left',
};

export const OPTIONS: { key: string, text: string, min?: number, max?: number }[] = [
  {
    key: KEYS.PADDING_TOP_VW,
    text: 'Top',
    min: 0,
    max: 10,
  },
  {
    key: KEYS.PADDING_RIGHT_VW,
    text: 'Right',
    min: 0,
    max: 20,
  },
  {
    key: KEYS.PADDING_BOTTOM_VW,
    text: 'Bottom',
    min: 0,
    max: 10,
  },
  {
    key: KEYS.PADDING_LEFT_VW,
    text: 'Left',
    min: 0,
    max: 20,
  },
];
