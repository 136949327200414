export const PROPERTY_NAME = '--portfolio-image-title-font-size';
export const PROPERTY_NAME_PX = '--portfolio-image-title-font-size-px';

export const FONT_SIZES: Array<number> = [
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
];
