import {ImageProcessTypeModel} from './image-process-type.model';

export class ImageProcessTypeDto {
  public Id?: number;
  public Type?: string;

  public static normalize(res: ImageProcessTypeDto): ImageProcessTypeModel {
    if (!res) return null;

    return new ImageProcessTypeModel(res.Id, res.Type);
  }
}
