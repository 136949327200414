export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `ଏହା କେବଳ ଏକ ସମୟ କଥା ହିଁ ଥିଲା.`,
    ],
    [KEYS.ALPHABET]: [
      'ଅଆଇଈଉଊଋୠଌୡଏଐଓଔକଖଗଘଙଚଛଜଝଞଟଠଡଢଣତଥଦଧନପଫବଵଭମଯରଳୱଶଷସହୟଲ₹',
    ],
    [KEYS.PARAGRAPH]: [
      `ଯେକୌଣସି ପ୍ରକାରେ, ମୁଁ ମଙ୍ଗଳ ଗ୍ରହର ମୁହଁ ଦେଖିବି, ଏବଂ ତାହା ଏକ ବିରଳ ଅନୁଭୂତି ହେବ. ଏହା ମତେ ଜଣାପଡୁଛି ଯେ ଏକ ସୂକ୍ଷ୍ମ ଦୂରବିକ୍ଷଣ ଯନ୍ତ୍ର ମାଧ୍ୟମରେ ସ୍ୱର୍ଗୀୟ ପଦାର୍ଥଗୁଡିକର ଏକ ଦର୍ଶନ, ତଥା ବିଶ୍ୱ ଚାରିପଟେ ଏକ ଭ୍ରମଣ, ଏକ ସ୍ୱାଧୀନ ଶିକ୍ଷାର ଅଂଶବିଶେଷ ହେବା ଉଚିତ୍‌.`
    ],
    [KEYS.NUMERALS]: [
      '୦୧୨୩୪୫୬୭୮୯',
    ],
  };
};
