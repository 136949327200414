import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {WebsitesHttpService} from '../../../../core/services/interaction/http/websites/websites-http.service';

import {WebsiteModel} from '../../../../core/models/websites/website.model';

import {EMAIL_REGEXP} from './constants';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss'],
})
export class PayPalComponent implements OnInit, OnDestroy {
  public website: WebsiteModel = null;

  public payPalEmail: string = '';

  public successMessage: string = '';
  public errorMessage: string = '';

  public isInProgress: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: WebsitesService,
    private httpService: WebsitesHttpService,
  ) {
  }

  public ngOnInit() {
    this.service.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;

      this.payPalEmail = website ? website.payPalEmail : '';
    });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onSave(): void {
    this.successMessage = '';
    this.errorMessage = '';

    if (!this.validate()) return;

    this.isInProgress = true;

    this.clearMessages();

    const onSuccess = () => {
      this.isInProgress = false;

      this.successMessage = 'Saved!';

      this.service.fetchWebsite();

      setTimeout(() => this.clearMessages(), 5000);
    };

    this.httpService.setPayPalEmail(this.payPalEmail).pipe(
      catchError(e => {
        this.isInProgress = false;
  
        this.errorMessage = e.error.message;

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  private clearMessages() {
    this.successMessage = '';
    this.errorMessage = '';
  }

  private validate(): boolean {
    if (!this.payPalEmail || this.payPalEmail.length === 0 || EMAIL_REGEXP.test(this.payPalEmail)) {
      return true;
    }

    this.errorMessage = 'Email is not valid.';

    return false;
  }
}
