import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/title/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/title/mobile/constants';

export const PROPERTY_NAMES = {
  [DESKTOP_KEYS.SCALABLE_LETTER_SPACING]: {
    OPTION: '--scalable-subtitle-letter-spacing',
    OPTION_PX: '--scalable-subtitle-letter-spacing-px',
  },
  [MOBILE_KEYS.SCALABLE_MOBILE_LETTER_SPACING]: {
    OPTION: '--scalable-mobile-subtitle-letter-spacing',
    OPTION_PX: '--scalable-mobile-subtitle-letter-spacing-px',
  },
};
