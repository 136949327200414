import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {MultiWebsiteService} from '../../../services/multi-website/multi-website.service';
import {LoaderService} from '../../../services/loaders/loader.service';
import {AuthService} from '../../../../auth/auth.service';

import {AccountModel} from '../../../models/accounts/account.model';
import {WebsiteModel} from '../../../models/websites/website.model';

@Component({
  selector: 'app-add-website-modal',
  templateUrl: 'add-website-modal.component.html',
  styleUrls: ['./add-website-modal.component.scss']
})
export class AddWebsiteModalComponent implements OnInit, OnDestroy {
  @Output() completedHandler: EventEmitter<WebsiteModel> = new EventEmitter<WebsiteModel>();
  @Output() cancelHandler: EventEmitter<void> = new EventEmitter<void>();

  public error: string = '';

  public title: string = '';
  public email: string = '';
  
  public userName: string = '';

  public isTitleValid: boolean = true;

  private isBlocked: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: MultiWebsiteService,
              private authService: AuthService,
              private loaderService: LoaderService) {}

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.userName = account ? account.name : '';
      this.title = this.userName;
    });
  }

  public onTitleInput(): void {
    this.isTitleValid = true;
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode === 13) return this.onSubmit();
    if (e.keyCode === 27) return this.cancelHandler.emit();
  }

  public onSubmit(): void {
    if (this.isBlocked) {
      return;
    }

    this.error = '';

    this.isTitleValid = this.title && this.title.length > 0;

    if (!this.isTitleValid) {
      return;
    }

    this.service.showLoader();

    this.isBlocked = true;

    const onSuccess = (website: WebsiteModel) => {
      this.isBlocked = false;

      this.completedHandler.emit(website);
    };

    this.service.createNewWebsite(this.title).pipe(
      catchError(e => {
        this.isBlocked = false;
  
        this.error = e.error ? e.error.message : 'Something went wrong.';
  
        this.service.hideLoader();

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  public onCancelHandler(): void {
    this.cancelHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
