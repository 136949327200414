import {Component, EventEmitter, Input, Output} from '@angular/core';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class InfoModalComponent {
  @Input() header: string = '';

  @Input() confirmText: string = 'Continue';
  @Input() cancelText: string = 'Cancel';

  @Input() isConfirmable: boolean = true;
  @Input() isCancellable: boolean = true;

  @Output() confirmHandler: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelHandler: EventEmitter<void> = new EventEmitter<void>();

  public onConfirmed(): void {
    this.confirmHandler.emit();
  }

  public onCancelled(): void {
    this.cancelHandler.emit();
  }
}
