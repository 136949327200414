import {Component, Input, OnDestroy, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-floating-modal',
  templateUrl: './floating-modal.component.html',
  styleUrls: ['./floating-modal.component.scss'],
})
export class FloatingModalComponent implements OnDestroy {
  @Input() open = false;

  private removeMouseMoveHandler;

  isDraggingActive = false;

  modalPosition = {
    top: 100,
    left: 100
  };

  private _draggingTool;
  private draggingToolWidth = 100;

  @ViewChild('draggingTool')
  set draggingTool(val) {
    if (val) {
      this._draggingTool = val;
      this.draggingToolWidth = val.nativeElement.clientWidth;
    }
  }

  constructor(private renderer: Renderer2) {}

  private handleMouseWindowMove(ev) {
    if (!this.isDraggingActive) return;

    this.modalPosition.top = ev.clientY - 10;
    this.modalPosition.left = ev.clientX - this.draggingToolWidth / 2;
  }

  public handleMouseDown(ev) {
    this.isDraggingActive = true;

    this.removeMouseMoveHandler = this.renderer.listen('document', 'mousemove', this.handleMouseWindowMove.bind(this));
  }

  public handleMouseUp(ev) {
    this.isDraggingActive = false;

    if (this.removeMouseMoveHandler) this.removeMouseMoveHandler();
  }

  public ngOnDestroy(): void {
    if (this.removeMouseMoveHandler) this.removeMouseMoveHandler();
  }
}
