import {Component, OnDestroy, OnInit} from "@angular/core";

import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../auth/auth.service';
import {NavigationService} from '../../../../services/navigation.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {DomainsService} from '../../../../core/services/domains/domains.service';
import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';

import {AppAnimations} from '../../../../app-animations';

import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {DomainModel} from '../../../../core/models/domain/domain.model';
import {IPermissionData} from "../../../../core/models/permission/i-permission-data";

import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';

@Component({
  selector: 'app-website-stats',
  templateUrl: './website-stats.component.html',
  styleUrls: ['./website-stats.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class WebsiteStatsComponent implements OnInit, OnDestroy {
  public TERMS = {
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
  };

  public METRICS = {
    VISITORS: 'ga:users',
    PAGEVIEWS: 'ga:pageviews',
  };

  public domain: DomainModel;

  public website: WebsiteModel = null;

  public isPermissionsSufficient: boolean = false;

  private requiredPermissions: IPermissionData[] = [
    {
      type: 'permission',
      value: PERMISSIONS.GA_ACCESS,
    },
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get PERMISSIONS() {
    return PERMISSIONS;
  }

  constructor(
    private websitesService: WebsitesService,
    private navigationService: NavigationService,
    private domainsService: DomainsService,
    private permissionsService: PermissionsService,
    public authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    this.initPermissions();

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.domainsService.primaryDomainSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((domain: DomainModel) => {
      this.domain = domain;
    });
  }

  private initPermissions(): Subscription {
    return this.permissionsService.isUserHasPermissionsObservable(this.requiredPermissions).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isPermissionsSufficient = isPermitted;
    });
  }

  public toPurchasePlans(): void {
    this.navigationService.toPurchase();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
