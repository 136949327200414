// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool-btn {
  margin-left: 10px;
  padding: 3px 5px;
  border: 1px solid #ccc;
  background: transparent;
  color: #ccc;
  border-radius: 5px;
  font-size: 15px;
  max-height: 25px;
}
.tool-btn:hover {
  background: #fff;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/website-designer/page-editor/top-bar-button/top-bar-button.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AADF;AAGE;EACE,gBAAA;EACA,WAAA;AADJ","sourcesContent":["\n\n.tool-btn {\n  margin-left: 10px;\n  padding: 3px 5px;\n  border: 1px solid #ccc;\n  background: transparent;\n  color: #ccc;\n  border-radius: 5px;\n  font-size: 15px;\n  max-height: 25px;\n\n  &:hover {\n    background: #fff;\n    color: #000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
