import {IStyleOption} from '../../../../option/i-style-option';

import {PROPERTY, DEFAULT_VALUE} from './constants';

export class PortfolioEnlargementArrowsBackgroundTransparencyModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = <HTMLElement>element.closest('.portfolio-enlargement-wrapper');

    if (!this.element) return;

    const propertyValue: number = parseFloat(window.getComputedStyle(this.element).getPropertyValue(PROPERTY)) * 100;
    const value: number = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;

    if (value) return this.setValue(Number.isNaN(value) ? void 0 : value.toString());

    this.value = DEFAULT_VALUE;
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(PROPERTY);

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);
    const propertyValue: number = Number.parseFloat(computedStyles.getPropertyValue(PROPERTY)) * 100;

    this.value = Number.isNaN(propertyValue) ? DEFAULT_VALUE : 100 - propertyValue;
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(PROPERTY, (1 - Number.parseFloat(value) / 100).toString());
  }
}
