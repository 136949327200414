// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep app-remove-icon .remove-btn {
  left: 0;
  height: 12px;
  width: 12px;
  transform: translate(-170%, 35%);
}

.summary-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
}
.summary-wrapper .plan-row {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  text-transform: capitalize;
  line-height: normal;
}
.summary-wrapper .plan-row:first-child {
  margin-top: 0;
}
.summary-wrapper .plan-row.due-today {
  font-size: 18px;
  font-weight: 600;
}
.summary-wrapper .plan-row .amount {
  margin-left: auto;
}
.summary-wrapper .separator {
  border-bottom: 1px solid #ccc;
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/update-subscription-summary/update-subscription-summary.component.scss"],"names":[],"mappings":"AAII;EACE,OAAA;EACA,YAAA;EACA,WAAA;EACA,gCAAA;AAHN;;AAQA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,eAAA;AALF;AAOE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,0BAAA;EACA,mBAAA;AALJ;AAOI;EACE,aAAA;AALN;AAQI;EACE,eAAA;EACA,gBAAA;AANN;AASI;EACE,iBAAA;AAPN;AAWE;EACE,6BAAA;EACA,gBAAA;AATJ","sourcesContent":["@import \"../../../../styles/colors\";\n\n:host ::ng-deep {\n  app-remove-icon {\n    .remove-btn {\n      left: 0;\n      height: 12px;\n      width: 12px;\n      transform: translate(-170%, 35%);\n    }\n  }\n}\n\n.summary-wrapper {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  font-size: 16px;\n\n  .plan-row {\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    margin-top: 15px;\n    text-transform: capitalize;\n    line-height: normal;\n\n    &:first-child {\n      margin-top: 0;\n    }\n\n    &.due-today {\n      font-size: 18px;\n      font-weight: 600;\n    }\n\n    .amount {\n      margin-left: auto;\n    }\n  }\n\n  .separator {\n    border-bottom: 1px solid $primary;\n    margin-top: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
