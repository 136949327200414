import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {Subject, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {ImageManagerService} from '../../application/main/image-manager/image-manager.service';
import {WebsiteDesignerService} from '../../application/main/website-designer/website-designer.service';
import {SetupLoaderService} from '../../core/services/loaders/setup/setup-loader.service';
import {AccountsHttpService} from '../../core/services/interaction/http/accounts/accounts-http.service';
import {AuthService} from '../../auth/auth.service';

import {AccountModel} from '../../core/models/accounts/account.model';

import {AppAnimations} from '../../app-animations';

@Component({
  selector: 'app-setup-website-settings',
  templateUrl: 'setup-website-settings.component.html',
  styleUrls: ['./setup-website-settings.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SetupWebsiteSettingsComponent implements OnInit, OnDestroy {
  public isErrorsShown: boolean = false;

  public title: string = '';
  
  public userName: string = '';

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private get isFormValid(): boolean {
    return !!this.title;
  }

  constructor(private router: Router,
              private websiteDesignerService: WebsiteDesignerService,
              private imageManagerService: ImageManagerService,
              private accountsHttpService: AccountsHttpService,
              private authService: AuthService,
              private setupLoaderService: SetupLoaderService) {
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((account: AccountModel) => {
      this.userName = account ? account.name : '';
      this.title = this.userName;
    });
  }

  public continue(): void {
    if (!this.isFormValid) {
      return this.showErrors();
    }

    this.setupLoaderService.show();

    this.websiteDesignerService.updateWebsite({
      Title: this.title,
    }).pipe(
      catchError(e => {
        console.dir(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.setupLoaderService.hide();
      }),
    ).subscribe(() => {
      this.router.navigateByUrl('/setup/select-template');
    });
  }

  private showErrors() {
    this.isErrorsShown = true;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
