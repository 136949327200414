// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbnail-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.thumbnail-wrapper.dragging .hand-icon {
  opacity: 0.5;
}
.thumbnail-wrapper .hand-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 35px;
  z-index: 2;
  pointer-events: none;
}
.thumbnail-wrapper .thumbnail-element {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.thumbnail-wrapper .thumbnail-element:hover {
  cursor: grab;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/thumbnail-positioning/thumbnail-positioning.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;AAEI;EACE,YAAA;AAAN;AAIE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,oBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAHJ;AAKI;EACE,YAAA;AAHN","sourcesContent":[".thumbnail-wrapper {\n  position: relative;\n  padding-top: 56.25%;\n\n  &.dragging {\n    .hand-icon {\n      opacity: 0.5;\n    }\n  }\n\n  .hand-icon {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    height: 35px;\n    width: 35px;\n    z-index: 2;\n    pointer-events: none;\n  }\n\n  .thumbnail-element {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n\n    &:hover {\n      cursor: grab;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
