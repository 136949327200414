export const PLANS_KEYS = {
  LITE: 'Lite',
  STANDARD: 'Standard',
  PROFESSIONAL: 'Professional',
  MULTI_SITE: 'MultiSite',

  LITE_PLUS: 'Lite+',
  STANDARD_PLUS: 'Standard+',
  PROFESSIONAL_PLUS: 'Professional+',
  MULTI_SITE_PLUS: 'MultiSite+',
};

export const FEATURES_KEYS = {
  EDITOR: 'EDITOR',
  TEMPLATES: 'TEMPLATES',
  MIGRATING_CONTENT: 'MIGRATING_CONTENT',
  DRAG_DROP_BLOCKS: 'DRAG_DROP_BLOCKS',
  IMAGE_MANAGER: 'IMAGE_MANAGER',
  IMAGE_LABEL: 'IMAGE_LABEL',
  PERSONAL_DOMAINS: 'PERSONAL_DOMAINS',
  BASIC_GA: 'BASIC_GA',
  SSL: 'SSL',
  RESPONSIVE_MOBILE: 'RESPONSIVE_MOBILE',
  PDF: 'PDF',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  FONT_MANAGER: 'FONT_MANAGER',
  BLOG: 'BLOG',
  MAILCHIMP: 'MAILCHIMP',
  E_COMMERCE: 'E_COMMERCE',
  PAYPAL: 'PAYPAL',
  FIVE_PRICES: 'FIVE_PRICES',
  DETAILED_GA: 'DETAILED_GA',
  MULTIPLE_WEBSITES: 'MULTIPLE_WEBSITES',

  PHONE_SUPPORT: 'PHONE_SUPPORT',
  TRAINING: 'TRAINING',
  PERSONAL_VIDEO_TUTORIALS: 'PERSONAL_VIDEO_TUTORIALS',
  SUPPORT_DISCOUNT_20: 'SUPPORT_DISCOUNT_20',
};

export const FEATURES_ORDER = [
  FEATURES_KEYS.EDITOR,
  FEATURES_KEYS.TEMPLATES,
  FEATURES_KEYS.MIGRATING_CONTENT,
  FEATURES_KEYS.DRAG_DROP_BLOCKS,
  FEATURES_KEYS.IMAGE_MANAGER,
  FEATURES_KEYS.IMAGE_LABEL,
  FEATURES_KEYS.PERSONAL_DOMAINS,
  FEATURES_KEYS.BASIC_GA,
  FEATURES_KEYS.SSL,
  FEATURES_KEYS.RESPONSIVE_MOBILE,
  FEATURES_KEYS.PDF,
  FEATURES_KEYS.VIDEO,
  FEATURES_KEYS.AUDIO,
  FEATURES_KEYS.FONT_MANAGER,
  FEATURES_KEYS.BLOG,
  FEATURES_KEYS.MAILCHIMP,
  FEATURES_KEYS.E_COMMERCE,
  FEATURES_KEYS.PAYPAL,
  FEATURES_KEYS.FIVE_PRICES,
  FEATURES_KEYS.DETAILED_GA,
  FEATURES_KEYS.MULTIPLE_WEBSITES,
];

export const SUPPORT_FEATURES_ORDER = [
  FEATURES_KEYS.PHONE_SUPPORT,
  FEATURES_KEYS.TRAINING,
  FEATURES_KEYS.PERSONAL_VIDEO_TUTORIALS,
  FEATURES_KEYS.SUPPORT_DISCOUNT_20,
];

export const FEATURES = {
  [PLANS_KEYS.LITE]: {
    [FEATURES_KEYS.EDITOR]: true,
    [FEATURES_KEYS.TEMPLATES]: true,
    [FEATURES_KEYS.MIGRATING_CONTENT]: true,
    [FEATURES_KEYS.DRAG_DROP_BLOCKS]: true,
    [FEATURES_KEYS.IMAGE_MANAGER]: true,
    [FEATURES_KEYS.IMAGE_LABEL]: true,
    [FEATURES_KEYS.PERSONAL_DOMAINS]: true,
    [FEATURES_KEYS.BASIC_GA]: true,
    [FEATURES_KEYS.SSL]: true,
    [FEATURES_KEYS.RESPONSIVE_MOBILE]: true,
  },
  [PLANS_KEYS.STANDARD]: {
    [FEATURES_KEYS.EDITOR]: true,
    [FEATURES_KEYS.TEMPLATES]: true,
    [FEATURES_KEYS.MIGRATING_CONTENT]: true,
    [FEATURES_KEYS.DRAG_DROP_BLOCKS]: true,
    [FEATURES_KEYS.IMAGE_MANAGER]: true,
    [FEATURES_KEYS.IMAGE_LABEL]: true,
    [FEATURES_KEYS.PERSONAL_DOMAINS]: true,
    [FEATURES_KEYS.BASIC_GA]: true,
    [FEATURES_KEYS.SSL]: true,
    [FEATURES_KEYS.RESPONSIVE_MOBILE]: true,
    [FEATURES_KEYS.PDF]: true,
    [FEATURES_KEYS.VIDEO]: true,
    [FEATURES_KEYS.AUDIO]: true,
  },
  [PLANS_KEYS.PROFESSIONAL]: {
    [FEATURES_KEYS.EDITOR]: true,
    [FEATURES_KEYS.TEMPLATES]: true,
    [FEATURES_KEYS.MIGRATING_CONTENT]: true,
    [FEATURES_KEYS.DRAG_DROP_BLOCKS]: true,
    [FEATURES_KEYS.IMAGE_MANAGER]: true,
    [FEATURES_KEYS.IMAGE_LABEL]: true,
    [FEATURES_KEYS.PERSONAL_DOMAINS]: true,
    [FEATURES_KEYS.BASIC_GA]: true,
    [FEATURES_KEYS.SSL]: true,
    [FEATURES_KEYS.RESPONSIVE_MOBILE]: true,
    [FEATURES_KEYS.PDF]: true,
    [FEATURES_KEYS.VIDEO]: true,
    [FEATURES_KEYS.AUDIO]: true,
    [FEATURES_KEYS.FONT_MANAGER]: true,
    [FEATURES_KEYS.BLOG]: true,
    [FEATURES_KEYS.MAILCHIMP]: true,
    [FEATURES_KEYS.E_COMMERCE]: true,
    [FEATURES_KEYS.PAYPAL]: true,
    [FEATURES_KEYS.FIVE_PRICES]: true,
    [FEATURES_KEYS.DETAILED_GA]: true,
  },
  [PLANS_KEYS.MULTI_SITE]: {
    [FEATURES_KEYS.EDITOR]: true,
    [FEATURES_KEYS.TEMPLATES]: true,
    [FEATURES_KEYS.MIGRATING_CONTENT]: true,
    [FEATURES_KEYS.DRAG_DROP_BLOCKS]: true,
    [FEATURES_KEYS.IMAGE_MANAGER]: true,
    [FEATURES_KEYS.IMAGE_LABEL]: true,
    [FEATURES_KEYS.PERSONAL_DOMAINS]: true,
    [FEATURES_KEYS.BASIC_GA]: true,
    [FEATURES_KEYS.SSL]: true,
    [FEATURES_KEYS.RESPONSIVE_MOBILE]: true,
    [FEATURES_KEYS.PDF]: true,
    [FEATURES_KEYS.VIDEO]: true,
    [FEATURES_KEYS.AUDIO]: true,
    [FEATURES_KEYS.FONT_MANAGER]: true,
    [FEATURES_KEYS.BLOG]: true,
    [FEATURES_KEYS.MAILCHIMP]: true,
    [FEATURES_KEYS.E_COMMERCE]: true,
    [FEATURES_KEYS.PAYPAL]: true,
    [FEATURES_KEYS.FIVE_PRICES]: true,
    [FEATURES_KEYS.DETAILED_GA]: true,
    [FEATURES_KEYS.MULTIPLE_WEBSITES]: true,
  },
  [PLANS_KEYS.LITE_PLUS]: {
    [FEATURES_KEYS.PHONE_SUPPORT]: true,
    [FEATURES_KEYS.TRAINING]: true,
    [FEATURES_KEYS.PERSONAL_VIDEO_TUTORIALS]: true,
    [FEATURES_KEYS.SUPPORT_DISCOUNT_20]: true,
  },
  [PLANS_KEYS.STANDARD_PLUS]: {
    [FEATURES_KEYS.PHONE_SUPPORT]: true,
    [FEATURES_KEYS.TRAINING]: true,
    [FEATURES_KEYS.PERSONAL_VIDEO_TUTORIALS]: true,
    [FEATURES_KEYS.SUPPORT_DISCOUNT_20]: true,
  },
  [PLANS_KEYS.PROFESSIONAL_PLUS]: {
    [FEATURES_KEYS.PHONE_SUPPORT]: true,
    [FEATURES_KEYS.TRAINING]: true,
    [FEATURES_KEYS.PERSONAL_VIDEO_TUTORIALS]: true,
    [FEATURES_KEYS.SUPPORT_DISCOUNT_20]: true,
  },
  [PLANS_KEYS.MULTI_SITE_PLUS]: {
    [FEATURES_KEYS.PHONE_SUPPORT]: true,
    [FEATURES_KEYS.TRAINING]: true,
    [FEATURES_KEYS.PERSONAL_VIDEO_TUTORIALS]: true,
    [FEATURES_KEYS.SUPPORT_DISCOUNT_20]: true,
  },
};

export const FEATURES_TEXT = {
  [FEATURES_KEYS.EDITOR]: 'VSX Editor - Powerful No-Code Web Designer',
  [FEATURES_KEYS.TEMPLATES]: 'Templates Designed for Visual Artists',
  [FEATURES_KEYS.MIGRATING_CONTENT]: 'Page Content Migrates Between Templates',
  [FEATURES_KEYS.DRAG_DROP_BLOCKS]: 'Drag-and-Drop Design Blocks',
  [FEATURES_KEYS.IMAGE_MANAGER]: 'Portfolio-Based Image Manager',
  [FEATURES_KEYS.IMAGE_LABEL]: 'Image Labels',
  [FEATURES_KEYS.PERSONAL_DOMAINS]: 'Connect Personal Domains',
  [FEATURES_KEYS.BASIC_GA]: 'Basic Website Statistics',
  [FEATURES_KEYS.SSL]: 'SSL Certificate (secure site)',
  [FEATURES_KEYS.RESPONSIVE_MOBILE]: 'Responsive Mobile-Ready Website',
  [FEATURES_KEYS.PDF]: 'Link & Embed PDFs',
  [FEATURES_KEYS.VIDEO]: 'Video Hosting',
  [FEATURES_KEYS.AUDIO]: 'Audio Hosting',
  [FEATURES_KEYS.FONT_MANAGER]: 'Font Manager – 1000+ Fonts',
  [FEATURES_KEYS.BLOG]: 'Blog',
  [FEATURES_KEYS.MAILCHIMP]: 'MailChimp Integration',
  [FEATURES_KEYS.E_COMMERCE]: 'E-Commerce',
  [FEATURES_KEYS.PAYPAL]: 'PayPal Integration',
  [FEATURES_KEYS.FIVE_PRICES]: '5 Prices / Editions Per Artwork',
  [FEATURES_KEYS.DETAILED_GA]: 'Detailed Google Analytics',
  [FEATURES_KEYS.MULTIPLE_WEBSITES]: 'Manage Multiple Sites from One Account',
  [FEATURES_KEYS.PHONE_SUPPORT]: 'Phone Support',
  [FEATURES_KEYS.TRAINING]: 'One-on-One Training',
  [FEATURES_KEYS.PERSONAL_VIDEO_TUTORIALS]: 'Personalized Video Tutorials',
  [FEATURES_KEYS.SUPPORT_DISCOUNT_20]: '20% Discount for More Support',
};
