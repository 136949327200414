import {Injectable} from '@angular/core';

import * as Bowser from "bowser";

@Injectable()
export class BrowserService {
  public browser: Bowser.Parser.ParsedResult;

  public isChrome: boolean = false;
  public isSafari: boolean = false;
  public isFirefox: boolean = false;
  public isIE: boolean = false;
  public isEdge: boolean = false;
  
  public isSafariOutdated: boolean = false;

  public isBrowserWarningOverlayVisible: boolean = false;

  constructor() {
    this.init();
  }

  private init(): void {
    try {
      this.browser = Bowser.parse(window.navigator.userAgent);
  
      this.isChrome = this.browser.browser.name === 'Chrome';
      this.isSafari = this.browser.browser.name === 'Safari';
      this.isFirefox = this.browser.browser.name === 'Firefox';
      this.isIE = this.browser.browser.name === 'Internet Explorer';
      this.isEdge = this.browser.browser.name === 'Microsoft Edge';
      
      this.initIsSafariOutdated();

      this.isBrowserWarningOverlayVisible = this.isIE || this.isSafariOutdated;
    } catch (e) {
      console.error(e);
    }
  }

  private initIsSafariOutdated(): void {
    this.isSafariOutdated = false;

    if (!this.isSafari) return;

    const version: number = Number.parseInt(this.browser.browser.version.split('.')[0]);

    this.isSafariOutdated = version < 12;
  }
}
