
const BLOCK_OPTION = {
  PAGE_COVERS: {
    title: 'Page Covers',
    value: 'PAGE_COVERS',
  },

  all: function() {
    return Object.keys(this).filter(k => k !== 'all').map(k => this[k].value);
  }
};

export class BlockOptions {

  private _element: any;
  get element() { return this._element; }

  private _editableOptions: [string];
  get editableOptions() { return this._editableOptions; }

  private _options: object;
  get options() { return this._options; }

  get BLOCK_OPTION() {
    return BLOCK_OPTION;
  }

  constructor(element) {
    this._element = element;
    this.init();
  }

  private init() {
    this._editableOptions = this.parseEditableOptions();
    this._options = this.parseOptions();
    this.initializeOptions();
  }

  private parseEditableOptions() {
    return (this.element.find('.block').attr('data-editable-options') || '').split(',').filter(v => v)
  }

  private parseOptions() {
    try {
      return JSON.parse(this.element.attr('data-options'));
    } catch(err) {
      return {};
    }
  }

  private initializeOptions() {
    for(const editableOption of this._editableOptions) {
      if(!this._options[editableOption]) {
        this._options[editableOption] = {};
      }
    }
  }

  apply() {
    this.element.attr('data-options', JSON.stringify(this.options));
  }
}
