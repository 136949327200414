// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .course-ended-modal .modal-body .modal-body-content-wrapper {
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/education/course-ended/course-ended-modal.component.scss"],"names":[],"mappings":"AAKM;EACE,aAAA;AAJR","sourcesContent":["@import \"../../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .course-ended-modal {\n    .modal-body {\n      .modal-body-content-wrapper {\n        padding: 20px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
