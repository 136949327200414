import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {MenuStyleModel} from '../style/menu-style.model';
import {MenuDropdownArrowModel} from '../dropdown-arrow/dropdown-arrow.model';
import {MenuPositionModel} from '../position/menu-position.model';
import {HoverColorModel} from '../hover-color/hover-color.model';
import {HoverBackgroundModel} from '../hover-background/hover-background.model';
import {ActiveColorModel} from '../active-color/active-color-model';
import {MobileBackgroundColorModel} from '../mobile-background-color/mobile-background-color.model';
import {TextDecorationModel} from '../text-decoration/links-text-decoration.model';
import {CollapseOptionsModel} from '../collapse-options/collapse-options.model';
import {ItemSeparatorColorModel} from '../item-separator-color/item-separator-color.model';
import {ItemSeparatorWidthModel} from '../item-separator-width/item-separator-width.model';

import {KEYS, TYPES} from '../../../../../services/styles/custom-mobile-menu/constants';
import {GROUPS as COLLAPSE_OPTIONS_GROUPS} from '../collapse-options/constants';

export class MobileMenuSetupModel {
  public menu: HTMLElement;

  public style: MenuStyleModel = new MenuStyleModel(KEYS.MOBILE_MENU_STYLE, TYPES.CLASS);
  public dropdownArrow: MenuDropdownArrowModel = new MenuDropdownArrowModel(KEYS.MOBILE_MENU_DROPDOWN_ARROW, TYPES.CLASS);
  public position: MenuPositionModel = new MenuPositionModel(KEYS.MOBILE_MENU_POSITION, TYPES.CLASS);
  public backgroundColor: MobileBackgroundColorModel = new MobileBackgroundColorModel(KEYS.MOBILE_MENU_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public hoverColor: HoverColorModel = new HoverColorModel(KEYS.MOBILE_HOVER_COLOR, TYPES.STYLE_PROPERTY);
  public hoverBackground: HoverBackgroundModel = new HoverBackgroundModel(KEYS.MOBILE_HOVER_BACKGROUND, TYPES.STYLE_PROPERTY);
  public activeColor: ActiveColorModel = new ActiveColorModel(KEYS.MOBILE_ACTIVE_COLOR, TYPES.STYLE_PROPERTY);
  public textDecoration: TextDecorationModel = new TextDecorationModel(KEYS.MOBILE_LINKS_TEXT_DECORATION, TYPES.STYLE_PROPERTY);
  public collapseOptions: CollapseOptionsModel = new CollapseOptionsModel(KEYS.MOBILE_COLLAPSE_OPTIONS, TYPES.CLASS, COLLAPSE_OPTIONS_GROUPS.MOBILE);
  public itemSeparatorColor: ItemSeparatorColorModel = new ItemSeparatorColorModel(KEYS.MOBILE_ITEM_BORDER_COLOR, TYPES.STYLE_PROPERTY);
  public itemSeparatorWidth: ItemSeparatorWidthModel = new ItemSeparatorWidthModel(KEYS.MOBILE_ITEM_BORDER_WIDTH, TYPES.STYLE_PROPERTY);

  public options: IStyleOption[] = [
    this.style,
    this.dropdownArrow,
    this.position,
    this.backgroundColor,
    this.hoverColor,
    this.hoverBackground,
    this.activeColor,
    this.textDecoration,
    this.collapseOptions,
    this.itemSeparatorColor,
    this.itemSeparatorWidth,
  ];

  private handlers = {};

  private initHandlers = {
    [TYPES.CLASS]: this.initClass.bind(this),
    [TYPES.STYLE_PROPERTY]: this.initStyleProperty.bind(this),
  };

  constructor({ handlers }) {
    this.handlers = handlers;
  }

  public init(menu: HTMLElement) {
    this.menu = menu;

    if (!this.menu) return;

    const innerWrapper: HTMLElement = menu ? <HTMLElement>menu.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(menu);

    this.options.forEach(option => {
      option.onChange = this.handlers[option.key] ? this.handlers[option.key] : null;

      this.initHandlers[option.type](option, {
        innerWrapper,
        block,
      });
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.matches('[data-menu-block]') ? element : <HTMLElement>element.closest('[data-menu-block]');
  }

  private initClass(option: IStyleOption, options?: IStyleModelOptions) {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.menu, options);
  }

  private initStyleProperty(option: IStyleOption, options?: IStyleModelOptions) {
    option.isEnabled = true;

    if (!option.init) return;

    option.init(this.menu, options);
  }
}
