import {GoogleFontUsagePageModel} from './google-font-usage-page.model';

export class GoogleFontUsagePageDbo {
  constructor(public BlockId?: number,
              public WebsiteId?: number,
              public TemplateId?: number,
              public PageId?: number,
              public PageType?: string,
              public BlockTemplateId?: number,
              public UsedFonts?: string,
              public IsMenu?: boolean,
              public IsSplash?: boolean,
              public TemplateNumber?: number,
              public PageData?: { Title: string }) {}

  public static normalize(res: GoogleFontUsagePageDbo): GoogleFontUsagePageModel {
    if (!res) return null;

    return new GoogleFontUsagePageModel(
      res.BlockId,
      res.WebsiteId,
      res.TemplateId,
      res.PageId,
      res.PageType,
      res.BlockTemplateId,
      res.UsedFonts,
      res.IsMenu,
      res.IsSplash,
      res.TemplateNumber,
      res.PageData ? { title: res.PageData.Title } : null,
    );
  }
}
