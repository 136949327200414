import {Injectable} from '@angular/core';

import { Observable , Subscription} from 'rxjs';

import {EducationImagesHttpService} from '../../../../../../../interaction/http/education/teachers/institutions/classes/students/portfolios/images/education-images-http.service';

import {ImageLabelDto} from '../../../../../../../../models/images/image-label/image-label.dto';
import {ImagePriceDto} from '../../../../../../../../models/images/price/image-price.dto';
import {ImagePriceModel} from '../../../../../../../../models/images/price/image-price.model';

@Injectable()
export class EducationImagesService {
  constructor(private httpService: EducationImagesHttpService) {
  }

  public updateImageLabel(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, imageLabel: ImageLabelDto): Observable<any> {
    return this.httpService.updateImageLabel(institutionId, classId, websiteId, portfolioId, imageId, imageLabel);
  }

  public createPrice(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, price: ImagePriceDto): Observable<any> {
    return this.httpService.createPrice(institutionId, classId, websiteId, portfolioId, imageId, price);
  }

  public updatePrice(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, price: ImagePriceDto): Observable<any> {
    return this.httpService.updatePrice(institutionId, classId, websiteId, portfolioId, imageId, price);
  }

  public moveAllToLibrary(institutionId: number, classId: number, websiteId: number, portfolioId: number): Observable<any> {
    return this.httpService.moveAllToLibrary(institutionId, classId, websiteId, portfolioId);
  }

  public deletePrice(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, priceId: number): Observable<ImagePriceModel> {
    return this.httpService.deletePrice(institutionId, classId, websiteId, portfolioId, imageId, priceId)
  }
}
