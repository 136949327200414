import {ImagePriceModel} from './price/image-price.model';
import {StudentImageReviewModel} from './review/student-image-review.model';

export class ImageModel {
  public isSelected: boolean = false;

  public get key(): string {
    return `${this.widthPx}_${this.heightPx}_${this.fileSize}`;
  }

  constructor(
    public id: number,
    public sourceImageId: number,
    public userId: number,
    public websiteId: number,
    public portfolioId: number,
    public type: string,
    public title: string,
    public sequence: number,
    public container: number,
    public bin: number,
    public year: string,
    public caption: string,
    public copyright: string,
    public purchaseInfo: string,
    public pricingStructure: string,
    public fileSize: number,
    public widthPx: number,
    public heightPx: number,
    public cameraModel: string,
    public focalRatio: string,
    public exposureTime: string,
    public iso: string,
    public isPublished: boolean,
    public isDeleted: boolean,
    public isInfoVisible: boolean,
    public isAvailabilityDefault: boolean,
    public isPurchaseLinkVisible: boolean,
    public isTitleDefault: boolean,
    public isCopyrightDefault: boolean,
    public isYearDefault: boolean,
    public isPricingStructureDefault: boolean,
    public isPurchaseLinkVisibilityDefault: boolean,
    public isPurchaseInfoDefault: boolean,
    public isInquireLinkVisible: boolean,
    public isPurchaseButtonVisible: boolean,
    public isCaptionDefault: boolean,
    public exhibitedImages: ImageModel[],
    public prices: ImagePriceModel[],
    public paths: { [key: string]: string },
    public sizesUrls: { [key: string]: string },
    public reviews: StudentImageReviewModel[]) {
  }

  public static clone(item: ImageModel): ImageModel {
    if (!item) {
      return null;
    }

    return new ImageModel(
      item.id,
      item.sourceImageId,
      item.userId,
      item.websiteId,
      item.portfolioId,
      item.type,
      item.title,
      item.sequence,
      item.container,
      item.bin,
      item.year,
      item.caption,
      item.copyright,
      item.purchaseInfo,
      item.pricingStructure,
      item.fileSize,
      item.widthPx,
      item.heightPx,
      item.cameraModel,
      item.focalRatio,
      item.exposureTime,
      item.iso,
      item.isPublished,
      item.isDeleted,
      item.isInfoVisible,
      item.isAvailabilityDefault,
      item.isPurchaseLinkVisible,
      item.isTitleDefault,
      item.isCopyrightDefault,
      item.isYearDefault,
      item.isPricingStructureDefault,
      item.isPurchaseLinkVisibilityDefault,
      item.isPurchaseInfoDefault,
      item.isInquireLinkVisible,
      item.isPurchaseButtonVisible,
      item.isCaptionDefault,
      item.exhibitedImages ? item.exhibitedImages.map((image: ImageModel) => ImageModel.clone(image)) : null,
      item.prices ? item.prices.map((price: ImagePriceModel) => ImagePriceModel.clone(price)) : null,
      JSON.parse(JSON.stringify(item.paths)),
      JSON.parse(JSON.stringify(item.sizesUrls)),
      item.reviews ? item.reviews.map((review: StudentImageReviewModel) => StudentImageReviewModel.clone(review)) : null,
    );
  }
}
