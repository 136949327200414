

import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {HttpClient as Http} from '@angular/common/http';

import {Observable, Subscription, BehaviorSubject, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';

import {ContentLoaderService} from '../../../loaders/content/content-loader.service';
import {PaymentSubscriptionsService} from '../payment-subscriptions.service';
import {ReceiptService} from '../../receipt/receipt.service';
import {ModalsService} from '../../../../../shared/services/modals/modals.service';
import {NavigationService} from '../../../../../services/navigation.service';

import {CardModel} from '../../../../models/payment/card/card.model';
import {SubscriptionModel} from '../../../../models/payment/subscriptions/subscription.model';
import {PlanModel} from '../../../../models/plan/plan.model';
import {RetryPaymentDto} from '../../../../models/payment/intent/retry-payment.dto';
import {RetryPaymentModel} from '../../../../models/payment/intent/retry-payment.model';
import {ReceiptModalModel} from '../../../../../shared/models/modals/receipt-modal/receipt-modal.model';
import {ReceiptModalItemModel} from '../../../../../shared/models/modals/receipt-modal/receipt-modal-item.model';
import {RetryPaymentLineItemModel} from '../../../../models/payment/intent/retry-payment-line-item.model';

import {AMOUNT_FORMATTERS} from '../../../../models/payment/intent/constants';

@Injectable()
export class RepeatPaymentService {
  public card: CardModel;
  public currentSubscription: SubscriptionModel = null;

  public retryDataSubject: BehaviorSubject<ReceiptModalModel> = new BehaviorSubject<ReceiptModalModel>(null);

  public invoiceId: string;

  public isCardUpdated: boolean = false;

  public get plan(): PlanModel {
    return this.currentSubscription && this.currentSubscription.plan;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: Http,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private receiptService: ReceiptService,
    private modalsService: ModalsService,
    private loaderService: ContentLoaderService,
  ) {
    this.activatedRoute.queryParams.subscribe((query: Params) => {
      this.invoiceId = query['invoiceId'];
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe(subscription => {
      this.currentSubscription = subscription;
    });
  }

  public verify() {
    if (this.invoiceId || this.plan) {
      return;
    }

    return this.toHistory();
  }

  public updateSubscriptionCard(data: { subscriptionId: string, cardId: string }): Observable<any> {
    const key = 'RepeatPaymentService_updateSubscriptionCard';

    this.loaderService.show(key);

    this.isCardUpdated = false;

    return this.paymentSubscriptionsService.updateSubscriptionCard(data).pipe(
      tap(() => {
        this.isCardUpdated = true;
      }),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    );
  }

  public fetchRetryData(): Subscription {
    const done = (res: RetryPaymentDto) => {
      const payment: RetryPaymentModel = RetryPaymentModel.normalize(res);

      const isCouponExists: boolean = !!payment.discount && !!payment.discount.coupon && !!payment.discountText;

      const remainingCreditAmount: number = this.getRemainingCreditAmount(payment);

      if (res.invoiceStatus !== 'open') {
        this.paymentSubscriptionsService.toHistory();

        return;
      }

      this.retryDataSubject.next(new ReceiptModalModel(
        this.getReceiptItems(payment),
        [],
        null,
        isCouponExists ? new ReceiptModalItemModel(`Discount (${payment.discountText})`, payment.discountAmount) : null,
        null,
        remainingCreditAmount ? AMOUNT_FORMATTERS.usd(remainingCreditAmount) : null,
        payment.total,
        payment.accountBalance,
        payment.isNew,
      ));
    };

    return this.doRetryDataFetch().pipe(
      catchError(e => {
        this.paymentSubscriptionsService.handleError(e);

        return throwError(() => e);
      })
    ).subscribe(done);
  }

  private doRetryDataFetch() : Observable<any> {
    const invoiceId: string = this.invoiceId || 'last';

    return this.httpClient.get(`api/${this.paymentSubscriptionsService.model}/invoices/${invoiceId}`, {});
  }

  public retryPayment() {
    if (this.invoiceId) {
      return this.retryInvoicePayment();
    }

    return this.retrySubscriptionPayment();
  }

  private retryInvoicePayment(): Subscription {
    const key = 'RepeatPaymentService_retryInvoicePayment';

    this.loaderService.show(key);

    const done = (res: RetryPaymentDto) => {
      const payment: RetryPaymentModel = RetryPaymentModel.normalize(res);

      const isCouponExists: boolean = !!payment.discount && !!payment.discount.coupon && !!payment.discountText;

      const remainingCreditAmount: number = this.getRemainingCreditAmount(payment);

      this.receiptService.receiptSubject.next(new ReceiptModalModel(
        this.getReceiptItems(payment),
        [],
        null,
        isCouponExists ? new ReceiptModalItemModel(`Discount (${payment.discountText})`, payment.discountAmount) : null,
        null,
        remainingCreditAmount ? AMOUNT_FORMATTERS.usd(remainingCreditAmount) : null,
        payment.total,
        payment.accountBalance,
        payment.isNew,
      ));

      this.modalsService.open(this.paymentSubscriptionsService.successfullySubscribedModalId);

      this.paymentSubscriptionsService.toHistory();
    };

    return this.doInvoicePaymentRetry().pipe(
      catchError(e => {
        this.paymentSubscriptionsService.handleError(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    ).subscribe(done);
  }

  private doInvoicePaymentRetry() : Observable<any> {
    return this.httpClient.post(`api/${this.paymentSubscriptionsService.model}/invoices/${this.invoiceId}`, {
      cardId: this.card.id,
    });
  }

  private retrySubscriptionPayment(): Subscription {
    const key = 'RepeatPaymentService_retrySubscriptionPayment';

    this.loaderService.show(key);

    const done = (res: RetryPaymentDto) => {
      const payment: RetryPaymentModel = RetryPaymentModel.normalize(res);

      const isCouponExists: boolean = !!payment.discount && !!payment.discount.coupon && !!payment.discountText;
      
      const remainingCreditAmount: number = this.getRemainingCreditAmount(payment);

      this.receiptService.receiptSubject.next(new ReceiptModalModel(
        this.getReceiptItems(payment),
        [],
        null,
        isCouponExists ? new ReceiptModalItemModel(`Discount (${payment.discountText})`, payment.discountAmount) : null,
        null,
        remainingCreditAmount ? AMOUNT_FORMATTERS.usd(remainingCreditAmount) : null,
        payment.total,
        payment.accountBalance,
        payment.isNew,
      ));

      this.modalsService.open(this.paymentSubscriptionsService.successfullySubscribedModalId);

      this.paymentSubscriptionsService.toHistory();
    };

    return this.doSubscriptionPaymentRetry().pipe(
      catchError(e => {
        this.paymentSubscriptionsService.handleError(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    ).subscribe(done);
  }

  private doSubscriptionPaymentRetry() : Observable<any> {
    return this.httpClient.post(`api/${this.paymentSubscriptionsService.model}/invoices/last`, {});
  }

  private getRemainingCreditAmount(payment: RetryPaymentModel): number {
    if (!payment) {
      return 0;
    }

    if (payment.plan) {
      return payment.plan.amount - payment.subtotalValue;
    }

    if (payment.lines && payment.lines.length > 0) {
      const amount: number = payment.lines.reduce((res: number, lineItem: RetryPaymentLineItemModel) => {
        return res + lineItem.amount;
      }, 0);

      return amount - payment.subtotalValue;
    }

    return 0;
  }

  private getReceiptItems(payment: RetryPaymentModel): ReceiptModalItemModel[] {
    if (!payment) {
      return [];
    }

    if (payment.plan) {
      const planName: string = payment.plan.name || payment.plan.nickname || '';

      return [new ReceiptModalItemModel(`${planName ? `${planName} Plan — ` : ''}${payment.plan.duration} Payment`, payment.plan.amountFormatted)];
    }

    if (payment.lines && payment.lines.length > 0) {
      return payment.lines.map((line: RetryPaymentLineItemModel) => {
        return new ReceiptModalItemModel(line.description, line.amountFormatted);
      });
    }

    return [];
  }

  public initCurrentSubscription() {
    return this.paymentSubscriptionsService.initCurrent();
  }

  public toPurchase() {
    return this.paymentSubscriptionsService.toPurchase();
  }

  public toHistory() {
    return this.paymentSubscriptionsService.toHistory();
  }

  public clear() {
    this.card = null;
    this.isCardUpdated = false;

    this.retryDataSubject.next(null);
  }
}
