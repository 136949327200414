import {Injectable, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {NodesService} from '../core/services/nodes/nodes.service';
import {IFrameService} from '../core/services/iframe/iframe.service';
import {EventsService} from '../core/services/interaction/events/events.service';
import { WebsitesService } from '../core/services/websites/websites.service';

import {NodeModel} from '../core/models/nodes/node.model';

@Injectable()
export class MenuNodesManagerService implements OnDestroy {
  private websiteId: number;
  
  private ngUnsubscribe = new Subject();

  constructor(
    private nodesService: NodesService,
    private websitesService: WebsitesService,
    private iFrameService: IFrameService,
    private eventsService: EventsService,
  ) {
    this.websitesService.activeWebsiteIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websiteId: number) => {
      this.websiteId = websiteId;
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.updateTemplateMenu(nodes);
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
  }

  private updateTemplateMenu(nodes: NodeModel[]): void {
    const sandboxWindow: Window = this.iFrameService.sandboxWindow;

    if (!sandboxWindow || !nodes || nodes.length === 0) {
      return;
    }

    this.eventsService.dispatchNodesUpdated({
      nodes,
      websiteId: this.websiteId,
    }, sandboxWindow);
  }
}
