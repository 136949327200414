import {SubscriptionMetadataModel} from './subscription-metadata.model';
import {PlanModel} from '../../plan/plan.model';
import {CardModel} from '../card/card.model';
import {SubscriptionPauseCollectionModel} from './subscription-pause-collection.model';
import {InvoiceShortModel} from '../invoice/invoice-short.model';

import {
  STATUSES_KEYS,
  ACTIVE_CLASSES,
  GREEN_STATUSES_CLASSES,
  YELLOW_STATUSES_CLASSES,
  RED_STATUSES_CLASSES,
  DURATIONS,
} from './constants';

export class SubscriptionModel {
  public get canUnsubscribe(): boolean {
    return ACTIVE_CLASSES.includes(this.status);
  }

  public get billedText(): string {
    if (!this.metadata) return 'n/a';

    if (this.metadata.duration === DURATIONS.ONE_YEAR) return 'ANNUALLY';
    if (this.metadata.duration === DURATIONS.ONE_MONTH) return 'MONTHLY';
    if (this.metadata.duration === DURATIONS.ONE_DAY) return 'DAILY';

    return 'n/a';
  }

  public get isStandard(): boolean {
    return this.planId === 'Standard';
  }

  public get isProfessional(): boolean {
    return this.planId === 'Professional';
  }

  public get isMultiSite(): boolean {
    return this.planId === 'MultiSite';
  }

  public get isEducator(): boolean {
    return this.planId === 'Educator';
  }

  public get isStudentEdu(): boolean {
    return this.planId === 'StudentEdu';
  }

  public get isSupportPlan(): boolean {
    return this.plan ? this.plan.isSupportPlan : false;
  }

  public get isTrialEnded(): boolean {
    return this.isExpired && this.isTrialPeriodApplied;
  }

  public get planId(): string {
    return this.metadata ? this.metadata.planId : '';
  }

  public get planDiskSpaceMb(): number {
    return this.plan ? this.plan.diskSpaceMb : 0;
  }

  public get duration(): string {
    return this.metadata ? this.metadata.duration : null;
  }

  public get isDaily(): boolean {
    return this.metadata && this.metadata.duration === DURATIONS.ONE_DAY;
  }

  public get isMonthly(): boolean {
    return this.metadata && this.metadata.duration === DURATIONS.ONE_MONTH;
  }

  public get isAnnual(): boolean {
    return this.metadata && this.metadata.duration === DURATIONS.ONE_YEAR;
  }

  public get isActive(): boolean {
    return this.status === STATUSES_KEYS.ACTIVE;
  }

  public get isTrial(): boolean {
    return this.status === STATUSES_KEYS.TRIALING;
  }

  public get isGreen() {
    return !this.isCancelledAtPeriodEnd && GREEN_STATUSES_CLASSES.includes(this.status);
  }

  public get isYellow() {
    return this.isCancelledAtPeriodEnd || YELLOW_STATUSES_CLASSES.includes(this.status);
  }

  public get isRed() {
    return RED_STATUSES_CLASSES.includes(this.status);
  }

  public get isFree(): boolean {
    if (!this.plan) return true;

    return Object.keys(this.plan.amounts).reduce((res, key) => {
      return res + this.plan.amounts[key].amount;
    }, 0) === 0;
  }

  constructor(
    public id?: string,
    public billing?: string,
    public metadata?: SubscriptionMetadataModel,
    public status?: string,
    public statusText?: string,
    public upcomingInvoiceAmount?: string,
    public upcomingInvoiceSubtotal?: string,
    public pauseCollection?: SubscriptionPauseCollectionModel,
    public start?: string,
    public cancelAt?: string,
    public trialEndAt?: string,
    public defaultSource?: CardModel,
    public isCustom?: boolean,
    public isExpired?: boolean,
    public isCancelledAtPeriodEnd?: boolean,
    public isDiscountApplied?: boolean,
    public isTrialPeriodApplied?: boolean,
    public periodStartsAt?: string,
    public periodEndsAt?: string,
    public periodStartsAtTimestamp?: number,
    public periodEndsAtTimestamp?: number,
    public cancelAtTimestamp?: number,
    public trialEndAtTimestamp?: number,
    public accountSubscriptionId?: number,
    public plan?: PlanModel,
    public latestInvoice?: InvoiceShortModel,
    public type?: string,
    public websiteId?: number,
  ) {}
}
