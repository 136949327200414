import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {StudentImageReviewAudioModel} from '../../../../../../../../../../../models/images/review/audio/student-image-review-audio.model';
import {StudentImageReviewAudioDto} from '../../../../../../../../../../../models/images/review/audio/student-image-review-audio.dto';

@Injectable()
export class EducationStudentsImagesReviewsAudiosHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public uploadAudio(url: string, blob: Blob, mimeType: string): Observable<any> {
    const audioHeaders = new HttpHeaders().set('Content-Type', mimeType);

    return this.http.put(url, blob, {
      headers: audioHeaders,
      reportProgress: true,
      observe: 'events',
      responseType: 'text',
    });
  }

  public finishUploading(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, reviewId: number, audio: StudentImageReviewAudioModel): Observable<StudentImageReviewAudioModel> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/reviews/${reviewId}/audios`, {
      id: audio.id,
      fileName: audio.originalFileName,
    }).pipe(
      map((res: StudentImageReviewAudioDto) => StudentImageReviewAudioDto.normalize(res))
    );
  }

  public updateMany(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, reviewId: number, audios: StudentImageReviewAudioModel[]): Observable<any> {
    return this.http.put(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/reviews/${reviewId}/audios/many`, audios, { responseType: 'json' });
  }

  public deleteMany(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, reviewId: number, ids: number[]): Observable<any> {
    return this.http.request('delete', `${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/reviews/${reviewId}/audios/many`, { body: { ids }, responseType: 'json' });
  }
}
