export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `သူတို့ရဲ့ စက်ပစ္စည်းတွေ၊ ကိရိယာတွေ အားလုံး အသက်ရှင်ကြတယ်။`,
    ],
    [KEYS.ALPHABET]: [
      'ကခဂဃငစဆဇဈဉညဋဌဍဎဏတထဒဓနပဖဗဘမယရလဝသဟဠအဣဤဥဦဧဩဪဿ၌၍၎၏‘?’“!”(%)[#]{@}/&<-+×=>$:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `အဝေးက တစုံတခုကို လှမ်းချောင်းကြည့်ရတာဟာ မှော်ဝင်တေးသွားတစ်ပုဒ်လို၊ ဂန္ထဝင်ပန်းချီကားတစ်ချပ်လို၊ ကဗျာကဝိများရဲ့ လက်ရာလေးတွေလို စိတ်ကို ရင့်ကျက်တည်ငြိမ်စေတဲ့ တန်ခိုးသတ္တိမျိုး ရှိလာစေတယ်။ အမြဲတမ်း တစ်ခုခု ကောင်းတာ ရှိနေမှာပါ။`
    ],
    [KEYS.NUMERALS]: [
      '၀၁၂၃၄၅၆၇၈၉',
    ],
  };
};
