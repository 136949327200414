import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'annualToMonth'
})
export class AnnualToMonthPipe implements PipeTransform {
  transform(amount: number): number {
    if (!amount) return amount;
    const month = amount / 12;
    return parseFloat(month.toFixed(2));
  }
}
