export class ImageAvailabilityModel {
  constructor(public id?: number,
              public text?: string) {}

  public static clone(availability: ImageAvailabilityModel): ImageAvailabilityModel {
    if (!availability) return null;

    return new ImageAvailabilityModel(
      availability.id,
      availability.text,
    );
  }
}
