import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subscriber, Observable, Subscription, BehaviorSubject} from 'rxjs';

import {AdminEducationInstitutionsHttpService} from '../../../interaction/http/admin/education/institutions/admin-education-institutions-http.service';

import {EducationInstitutionModel} from '../../../../models/education/institutions/education-institution.model';
import {EducationTeacherModel} from '../../../../models/education/teachers/education-teacher.model';
import {EducationClassModel} from '../../../../models/education/classes/education-class.model';

@Injectable()
export class AdminEducationInstitutionsService {
  public institutionsSubject: BehaviorSubject<EducationInstitutionModel[]> = new BehaviorSubject<EducationInstitutionModel[]>(null);
  public institutionDetailsSubject: BehaviorSubject<EducationInstitutionModel> = new BehaviorSubject<EducationInstitutionModel>(null);

  constructor(private httpService: AdminEducationInstitutionsHttpService) {
  }

  public create(item: EducationInstitutionModel): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      this.httpService.create(item).subscribe(() => {
        this.fetchAll();

        observer.next(null);
        observer.complete();
      }, (res: HttpErrorResponse) => {
        observer.next(res.error ? res.error.message : res.statusText);
        observer.complete();
      });
    });
  }

  public fetchInstitutionClasses(id: number): Observable<EducationClassModel[]> {
    return this.httpService.getInstitutionClasses(id);
  }

  public fetchInstitutionTeachers(id: number): Observable<EducationTeacherModel[]> {
    return this.httpService.getInstitutionTeachers(id);
  }

  public fetchOne(id: number): Subscription {
    return this.httpService.getOne(id).subscribe((res: EducationInstitutionModel) => {
      this.institutionDetailsSubject.next(res);
    });
  }

  public update(item: EducationInstitutionModel): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      this.httpService.update(item).subscribe(() => {
        this.fetchAll();

        observer.next(null);
        observer.complete();
      }, (res: HttpErrorResponse) => {
        observer.next(res.error ? res.error.message : res.statusText);
        observer.complete();
      });
    });
  }

  public remove(item: EducationInstitutionModel): Subscription {
    return this.httpService.remove(item.id).subscribe(() => {
      this.fetchAll();
    });
  }

  public fetchAll(): Subscription {
    return this.httpService.getAll().subscribe((res: EducationInstitutionModel[]) => {
      this.institutionsSubject.next(res);
    });
  }
}
