// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .modal-body {
  padding: 0 !important;
  width: 90vw !important;
  max-width: 90vw !important;
  height: 90vh !important;
  max-height: 90vh !important;
  min-height: 90vh !important;
}
:host ::ng-deep app-close-icon {
  position: absolute;
  right: 0;
  top: 0;
}
:host ::ng-deep app-close-icon .close-btn {
  transform: translate(200%, 25%) scale(1.25) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/media-manager/media-manager-modal.component.scss"],"names":[],"mappings":"AAGE;EACE,qBAAA;EACA,sBAAA;EACA,0BAAA;EACA,uBAAA;EACA,2BAAA;EACA,2BAAA;AAFJ;AAKE;EACE,kBAAA;EACA,QAAA;EACA,MAAA;AAHJ;AAKI;EACE,sDAAA;AAHN","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .modal-body {\n    padding: 0 !important;\n    width: 90vw !important;\n    max-width: 90vw !important;\n    height: 90vh !important;\n    max-height: 90vh !important;\n    min-height: 90vh !important;\n  }\n\n  app-close-icon {\n    position: absolute;\n    right: 0;\n    top: 0;\n\n    .close-btn {\n      transform: translate(200%, 25%) scale(1.25) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
