import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {PreLaunchProgressItemDto} from '../../../../models/pre-launch-progress/item/pre-launch-progress-item.dto';
import {PreLaunchProgressItemModel} from '../../../../models/pre-launch-progress/item/pre-launch-progress-item.model';
import {PreLaunchProgressAccountDataModel} from '../../../../models/pre-launch-progress/account-data/pre-launch-progress-account-data.model';

@Injectable()
export class PreLaunchChecklistHttpService {
  private model: string = 'pre-launch';

  constructor(private http: HttpClient) {}

  public fetchAll(): Observable<PreLaunchProgressItemModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: PreLaunchProgressItemDto[]) => {
        return res.map((item: PreLaunchProgressItemDto) => PreLaunchProgressItemDto.normalize(item));
      })
    );
  }

  public setProgress(progress: PreLaunchProgressAccountDataModel): Observable<{}> {
    return this.http.put(`api/${this.model}/progress`, progress);
  }
}
