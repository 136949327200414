export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `红色火光映出锯齿形机翼的轮廓。`,
      `我看着暴风雨，美不胜收却也惊心动魄。`,
      `不知不觉间，我们已经离开了地面。`,
      `在飞船下方的远处，有一轮闪耀的新月。`,
      `回程会是一趟孤独的旅途。`,
      `我的两种人格拥有共同的记忆。`,
      `这艘船的甲板上弥漫着银白色的薄雾。`,
      `月亮的脸掩映在阴影中。`,
      `她透过窗户目不转睛地仰望着星星。`,
    ],
    [KEYS.ALPHABET]: [
      '一二三四五六七八九十百千万上中下左右大小春夏秋冬东南西北金木水火土天地日月星黑白红橙黄绿蓝靛紫食住衣行育乐忠孝仁爱信义和平子曰父母兄弟夫妇君臣马牛羊鸡犬豕喜怒哀惧恶目耳口手足见闻声贝车雨赤青言语鱼鸟羽电不乃之乎人以何俱伦仪先光入具初则匏协去友同名善器严执孟孙学宜容专少山师席常幼序从性恩恭情惰应成所才扬择教敬数文断方于族昔时智曾有朋本杼某梨机次欲此岁温为燕玄玉琢畜当相知石祖礼稷稻谷穷窦竹粱紊丝纲习老者而能自至与苟菽处融亲调识让贵身近运过道远迁邻长非革音顺饲养首香高麦黍龄思源谷歌 ?“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `由于各个零件都过于微小，我的制造速度受到了极大的阻碍，因此我决定违背自己的初衷，改为制造一个庞然大物，也就是说，高约 8 英尺，各部分也都按比例放大。下定决心后，我花费几个月的时间成功收集和整理了所需的材料，然后就开始实施我的计划了。`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
