export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `I love eating toasted cheese and tuna sandwiches.`,
      `You are looking great!`,
      `Malls are great places to shop.`,
      `He didn’t want to go to the dentist, yet he went anyway.`,
      `Wow, does that work?`,
      `It was getting dark, and we weren’t there yet.`,
      `He ran out of money, so he had to stop playing poker.`,
      `The quick brown fox jumps over the lazy dog.`,
      `I think I will buy the red car, or I will lease the blue one.`,
      `The sky is clear; the stars are twinkling.`,
      `The mysterious diary records the voice.`,
      `Let me help you with your baggage.`,
      `Abstraction is often one floor above you.`,
      `What is your favorite animal and why?`,
      `Do you have any suggestions for me?`,
      `My dad is so funny that he told us jokes all night long.`,
      `King size beds are really big.`,
      `What do you do for Thanksgiving?`,
      `What is your favorite movie?`,
      `Apple picking is not in season.`,
      `It was especially rainy that night.`,
      `I've got a big week ahead of me.`,
      `What is your favorite thing about school and why?`,
      `Tom wanted to ask Mary for a big favor.`,
    ],
    [KEYS.ALPHABET]: [
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz ‘?’“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `Someone I know recently combined Maple Syrup & buttered Popcorn thinking it would taste like caramel popcorn. It didn’t and they don’t recommend anyone else do it either.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
