export class Report {
  constructor(public title: string,
              public desc: string,
              public templateId: string,
              public userAgent: string,
              public isAutomated: boolean = true,
              public attachments: any[] = []) {}

  public toFormData(): FormData {
    const formData = new FormData();

    formData.append('title', this.title);
    formData.append('desc', this.desc);
    formData.append('isAutomated', this.isAutomated.toString());

    return this.appendAttachments(formData);
  }

  private appendAttachments(formData: FormData) {
    if (!this.attachments || this.attachments.length === 0) return formData;

    this.attachments.forEach(attachment => formData.append('attachments', attachment));

    return formData;
  }
}
