import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {EducationTeachersStudentsService} from '../../teachers/institutions/classes/students/education-students.service';
import {ImageManagerService} from '../../../../../application/main/image-manager/image-manager.service';
import {AuthService} from '../../../../../auth/auth.service';
import {SocketsService} from '../../../interaction/sockets/sockets.service';

import {ImagesCounterModel} from '../../../../models/image-manager/counters/images-counter.model';
import {PortfolioModel} from '../../../../models/portfolios/portfolio.model';
import {EducationStudentModel} from '../../../../models/education/students/education-student.model';
import {AccountModel} from '../../../../models/accounts/account.model';
import {ISocketImageManagerMessageDataModel} from '../../../../models/sockets/message/image-manager/i-image-manager-message-data.model';
import {EducationStudentPortfolioModel} from '../../../../models/education/portfolios/education-student-portfolio.model';
import {EducationImageManagerService} from '../education-image-manager.service';

import {LIBRARY_ID} from '../../../../../application/constants';
import {SOCKET_ACTIONS} from '../../../../../application/main/image-manager/constants';

@Injectable()
export class EducationStudentsImagesCounterService {
  public countersSubject: BehaviorSubject<ImagesCounterModel> = new BehaviorSubject<ImagesCounterModel>(new ImagesCounterModel());

  private student: EducationStudentModel;
  private studentPortfolio: EducationStudentPortfolioModel;

  private portfolios: PortfolioModel[];

  private librarySize: number = 0;

  private socketMessageHandlers = {
    [SOCKET_ACTIONS.IMAGE_REORDER_STOP]: this.onReorderStop.bind(this),
  };

  constructor(private studentsService: EducationTeachersStudentsService,
              private socketsService: SocketsService,
              private educationImageManagerService: EducationImageManagerService,
              private authService: AuthService,
              private imageManagerService: ImageManagerService) {
    this.studentsService.studentDetailsSubject.subscribe((item: EducationStudentModel) => {
      this.student = item;

      this.portfolios = item ? item.portfolios : [];

      this.initCounters();
    });

    this.educationImageManagerService.selectedStudentPortfolioSubject.subscribe((selectedPortfolio: EducationStudentPortfolioModel) => {
      this.studentPortfolio = selectedPortfolio;
    });

    this.socketsService.imageManagerDataSubject.subscribe((data: ISocketImageManagerMessageDataModel) => {
      if (!data || !this.socketMessageHandlers[data.action]) {
        return;
      }
  
      this.socketMessageHandlers[data.action](data);
    });

    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.initLibrarySize(account);
    });
  }

  private initLibrarySize(account: AccountModel) {
    this.imageManagerService.getLibrarySize().subscribe((data) => {
      this.librarySize = data ? data.size : 0;
  
      return this.initCounters();
    });
  }

  private initCounters(): void {
    if (!this.portfolios) return;

    const counters = new ImagesCounterModel();

    this.portfolios.forEach((portfolio: PortfolioModel) => {
      counters.published[portfolio.id] = portfolio.publishedImagesCount || 0;
      counters.total[portfolio.id] = portfolio.totalImagesCount || 0;
    });

    counters.total[LIBRARY_ID] = this.librarySize;

    this.countersSubject.next(counters);
  }

  private onReorderStop(data: ISocketImageManagerMessageDataModel): void {
    if (!this.student || data.userId !== this.student.userId) {
      return;
    }

    const counters: ImagesCounterModel = this.countersSubject.value;

    this.studentsService.fetchPortfolioConters(
      this.studentPortfolio.institutionId,
      this.studentPortfolio.classId,
      this.studentPortfolio.studentId,
      data.portfolioId
    ).subscribe((portfolio: PortfolioModel) => {
      if (!portfolio) {
        return;
      }

      counters.published[portfolio.id] = portfolio.publishedImagesCount || 0;
      counters.total[portfolio.id] = portfolio.totalImagesCount || 0;

      this.countersSubject.next(counters);
    });
  }
}
