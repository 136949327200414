import {IStyleModelOptions} from '../option/i-style-model-options';

import {SetupOptionModel} from '../option/setup-option.model';

import {PROPERTY_PX} from './constants';

export class ThumbDrawerBorderRadiusModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !options.block) return;

    this.element = options.block;

    const value: number = Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(PROPERTY_PX), 10);

    this.setValue(value);
  }

  public onChange(value: number): void {
    this.setValue(value);
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(PROPERTY_PX);

    this.setValue(Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(PROPERTY_PX), 10));
  }

  public setValue(value: number): void {
    this.value = value;

    this.element.style.setProperty(PROPERTY_PX, `${value}px`);
  }
}
