import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerService {

  DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please, try later.';

  getErrorMessage(err) {
    let message = err.message;
    if (!message) {
      console.error(err);
      message = this.DEFAULT_ERROR_MESSAGE;
    }
    return Promise.reject(message);
  }

}
