export class EducationExhibitionPortfolioModel {
  constructor(public id?: number,
              public institutionId?: number,
              public classId?: number,
              public websiteId?: number,
              public nodeId?: number) {
  }

  public isSameTo(other: EducationExhibitionPortfolioModel): boolean {
    if (!other) {
      return false;
    }

    return this.id === other.id
      && this.institutionId === other.institutionId
      && this.classId === other.classId
      && this.websiteId === other.websiteId
      && this.nodeId === other.nodeId;
  }
}
