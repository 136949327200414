import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class AlreadySubscribedErrorModalService {
  public readonly id = 'ALREADY_SUBSCRIBED';

  public isOpened = false;

  public constructor(private modalsService: ModalsService) {
  }

  public open(err: { key: string, message: string }): void {
    this.modalsService.open(this.id, err);
    
    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
