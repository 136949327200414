export const SRCSET_SIZES = [
  {
    width: 640,
    height: 360,
    density: '0.8x',
  },
  {
    width: 800,
    height: 450,
    density: '1x',
  },
  {
    width: 1200,
    height: 675,
    density: '1.5x',
  },
  {
    width: 1600,
    height: 900,
    density: '2x',
  },
];
