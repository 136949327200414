const COUNTRIES = require('./countries-list.json');

export const STEPS = {
  WELCOME: 'WELCOME',
  OAUTH: 'OAUTH',
  ACCOUNT_PROVISION: 'ACCOUNT_PROVISION',
  COMPLETED: 'COMPLETED',
};

export const STEPS_MAPPING = {
  [STEPS.WELCOME]: 'welcome',
  [STEPS.OAUTH]: 'oauth',
  [STEPS.ACCOUNT_PROVISION]: 'account-provision',
  [STEPS.COMPLETED]: 'completed',
};

export const COUNTRIES_LIST = COUNTRIES;
