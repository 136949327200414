import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../modals.service';

import {CardModel} from '../../../../core/models/payment/card/card.model';

@Injectable()
export class CreditCardsModalService {
  public readonly id = 'credit-cards-modal';

  public isOpened = false;

  public openedFor: any;

  public selectedCardSubject: Subject<CardModel> = new Subject<CardModel>();

  public constructor(private modalsService: ModalsService) {
  }

  public open(card?: CardModel): void {
    this.modalsService.open(this.id, card);

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.openedFor = null;

    this.modalsService.close(this.id);
  }
}
