import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import * as $ from 'jquery';
import * as _ from 'lodash';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DetailsService} from '../details.service';
import {WebsiteDesignerService} from '../../website-designer/website-designer.service';
import {SidebarCustomizerElementService} from "../../../../core/services/sidebar/customizer/element/element.service";
import {BlockElementService} from '../../../../core/services/sidebar/customizer/block-element/block-element.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {CustomStylesService} from '../../../../core/services/styles/custom/custom-styles.service';
import {BlogStylesService} from '../../../../core/services/styles/blog/blog-styles.service';
import {BlogAddCommentButtonStylesService} from '../../../../core/services/styles/blog/add-comment-button/blog-add-comment-button-styles.service';
import {BlogCommentsStylesService} from '../../../../core/services/styles/blog/comments/blog-comments-styles.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {StylesService} from '../../../../core/services/styles/styles.service';
import {ImageManagerModalService} from '../../../../shared/components/modals/image-manager-modal/image-manager-modal.service';
import {StylesSettingsService} from '../../../../core/services/styles/settings/styles-settings.service';
import {AuthService} from '../../../../auth/auth.service';
import {IFrameRoutingService} from '../../../../core/services/iframe/routing/iframe-routing.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {NativeStylesService} from '../../../../core/services/styles/native/native-styles.service';
import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';
import {AttributesService} from '../../../../core/services/attributes/attributes.service';
import {PortfolioPickerModalService} from '../../../../services/portfolio-picker-modal.service';
import {PageEditorService} from '../../website-designer/page-editor/page-editor.service';
import {VideoEmbedService} from '../../../../core/services/video-manager/embed/video-embed.service';
import {AudioManagerService} from '../../../../core/services/audio-manager/audio-manager.service';
import {EditorDevicesService} from '../../../../core/services/editor-devices/editor-devices.service';
import {LogoUploadService} from '../../../../core/services/iframe/logo-upload/logo-upload.service';
import {CustomMenuStylesService} from '../../../../core/services/styles/custom-menu/custom-menu-styles.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import { DocumentManagerService } from '../../../../core/services/document-manager/document-manager.service';

import {DividerStyles} from './divider-styles';
import {BlockOptions} from "./block-options";

import {IStyleOption} from '../../../../core/models/styles/setup/option/i-style-option';
import {SelectOption} from "../../../../core/models/select/option/option.model";
import {SetupModel} from '../../../../core/models/styles/setup/setup.model';

import {BlogSetupModel} from '../../../../core/models/styles/setup/blog/blog-setup.model';
import {BlogAddCommentButtonSetupModel} from '../../../../core/models/styles/setup/blog/add-comment-button/blog-add-comment-button-setup.model';
import {BlogCommentsSetupModel} from '../../../../core/models/styles/setup/blog/comments/blog-comments-setup.model';
import {NativeSetupModel} from '../../../../core/models/styles/native-setup/native-setup.model';
import {StyleOptionModel} from '../../../../core/models/styles/settings/option/style-option.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';
import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';
import {VideoEmbedDataModel} from '../../../../core/models/videos/video-embed-data.model';
import {MenuSetupModel} from '../../../../core/models/styles/setup/menu/desktop/menu-setup.model';
import {NativeSetupOptionModel} from '../../../../core/models/styles/native-setup/option/native-setup-option.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';
import {SelectedPageModel} from '../../../../core/models/selected-page/selected-page.model';
import {BaseScalableModel} from '../../../../core/models/styles/setup/base/scalable/base-scalable.model';

import {BORDER_STYLES} from './border.constants';
import {CONTROL_TYPE} from './constants';
import {TYPES as ELEM_TYPES, STYLE_KEYS} from '../../../../core/services/styles/constants';
import {OPTIONS as IMAGE_FIT_INTO_OPTIONS, DEFAULT_OPTION as IMAGE_FIT_INTO_DEFAULT_OPTION} from '../../../../core/models/styles/setup/image-fit-into/constants';
import {OPTIONS as IMAGE_CAPTION_POSITION_OPTIONS, DEFAULT_OPTION as IMAGE_CAPTION_POSITION_DEFAULT_OPTION} from '../../../../core/models/styles/setup/image-caption-position/constants';
import {OPTIONS as IMAGE_LABEL_BUTTON_OPTIONS, POSITION_OPTIONS as IMAGE_LABEL_BUTTON_POSITION_OPTIONS} from '../../../../core/models/styles/setup/image-label-button/constants';
import {OPTIONS as IMAGE_LABEL_POSITION_OPTIONS, DEFAULT_OPTION as IMAGE_LABEL_POSITION_DEFAULT_OPTION, RIGHT_SIDE_OPTION as IMAGE_LABEL_POSITION_RIGHT_SIDE_OPTION} from '../../../../core/models/styles/setup/image-label-position/constants';
import {OPTIONS as IMAGE_TRANSITION_OPTIONS, DEFAULT_OPTION as IMAGE_TRANSITION_DEFAULT_OPTION} from '../../../../core/models/styles/setup/image-transition/constants';
import {OPTIONS as PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION_OPTIONS, DEFAULT_OPTION as PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION_DEFAULT_OPTION} from '../../../../core/models/styles/setup/portfolio/enlargement/image-transition/constants';
import {OPTIONS as POSITION_OPTIONS, DEFAULT_OPTION as POSITION_DEFAULT_OPTION} from '../../../../core/models/styles/setup/position/constants';
import {OPTIONS as IMAGE_CROP_OPTIONS} from '../../../../core/models/styles/setup/image-crop/constants';
import {OPTIONS as BLOCK_WIDTH_TYPE_OPTIONS} from '../../../../core/models/styles/setup/block-width-type/constants';
import {OPTIONS as PORTFOLIO_IMAGE_TITLE_POSITION_OPTIONS} from '../../../../core/models/styles/setup/portfolio-image-title-position/constants';
import {OPTIONS as PORTFOLIO_ENLARGEMENT_TITLE_POSITION_OPTIONS} from '../../../../core/models/styles/setup/portfolio/enlargement/title/position/constants';
import {OPTIONS as MASONRY_TYPE_OPTIONS, KEYS as MASONRY_TYPE_KEYS} from '../../../../core/models/styles/setup/masonry-type/constants';
import {OPTIONS as BLOCK_ALIGNMENT_OPTIONS} from '../../../../core/models/styles/setup/block-alignment/constants';
import {OPTIONS as IMAGE_ALIGNMENT_OPTIONS} from '../../../../core/models/styles/setup/image-alignment/constants';
import {OPTIONS as ENLARGEMENT_PADDING_OPTIONS} from '../../../../core/models/styles/setup/enlargement-padding/constants';
import {OPTIONS as PADDING_VW_OPTIONS} from '../../../../core/models/styles/setup/padding-vw/constants';
import {OPTIONS as MENU_LOGO_PADDING_OPTIONS} from '../../../../core/models/styles/setup/menu/logo-padding/constants';
import {OPTIONS as TEXT_DECORATION_OPTIONS} from '../../../../core/models/styles/setup/portfolio-image-title-text-decoration/constants';
import {FONT_SIZES as PORTFOLIO_IMAGE_TITLE_FONT_SIZES} from '../../../../core/models/styles/setup/portfolio-image-title-font-size/constants';
import {FONT_SIZES as PORTFOLIO_SLIDE_THUMB_FONT_SIZES} from '../../../../core/models/styles/setup/portfolio-slide-thumb-font-size/constants';
import {FONT_SIZES as THUMB_DRAWER_FONT_SIZES} from '../../../../core/models/styles/setup/thumb-drawer-font-size/constants';
import {OPTIONS as THUMBS_POSITION_OPTIONS} from '../../../../core/models/styles/setup/thumb-drawer-thumbs-position/constants';
import {OPTIONS as MOBILE_ALBUM_VIEWS} from '../../../../core/models/styles/setup/mobile-album-view-setup/constants';
import {FONT_SIZES, FONT_SIZES_20, FONT_SIZES_LIMITED} from '../../../../core/models/styles/native-setup/font-size/constants';
import {TEXT_TRANSFORMATIONS} from '../../../../core/models/styles/native-setup/text-transform/constants';
import {TEXT_ALIGNMENTS} from '../../../../core/models/styles/native-setup/text-alignment/constants';
import {TEXT_DECORATIONS} from '../../../../core/models/styles/native-setup/text-decoration/constants';
import {FONT_STYLES} from '../../../../core/models/styles/native-setup/font-style/constants';
import {DEVICES} from '../../../../core/services/editor-devices/constants';
import {IS_PORTFOLIO_ENLARGEMENT_BACKGROUND_SET_ATTRIBUTE} from '../../../../core/models/styles/setup/portfolio/enlargement/background-color/constants';
import {OPTIONS as SLIDESHOW_HORIZONTAL_TEXT_ALIGNMENT} from '../../../../core/models/styles/setup/slideshow-horizontal-text-alignment/constants';
import {OPTIONS as SLIDESHOW_VERTICAL_TEXT_ALIGNMENT} from '../../../../core/models/styles/setup/slideshow-vertical-text-alignment/constants';
import {OPTIONS as BORDER_STYLES_STYLE_OPTIONS} from '../../../../core/models/styles/setup/slideshow-image-border-style/constants';
import {OPTIONS as SLIDESHOW_TRANSITION_OPTIONS} from '../../../../core/models/styles/setup/slideshow-transition/constants';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss'],
})
export class StylesComponent implements OnInit, OnDestroy {
  public account: AccountModel;

  public paddingPositions = [
    {
      text: 'Top',
      key: 'PADDING_TOP',
      styleKey: 'padding-top',
    },
    {
      text: 'Left',
      key: 'PADDING_LEFT',
      styleKey: 'padding-left',
    },
    {
      text: 'Bottom',
      key: 'PADDING_BOTTOM',
      styleKey: 'padding-bottom',
    },
    {
      text: 'Right',
      key: 'PADDING_RIGHT',
      styleKey: 'padding-right',
    },
  ];

  public isPaddingOptionEnabled: boolean = false;
  public isPaddingValuesEqual: boolean = false;
  public isPaddingSlidersExpanded: boolean = false;
  public paddingValue: number = 0;

  public isBlockElement: boolean = false;
  public isPortfolioEnlargementOpened: boolean = false;

  public sectionTitle: string = 'ELEMENT';

  private readonly maxLineHeight: number = 100;

  get CONTROL_TYPE() {
    return CONTROL_TYPE;
  }

  get BORDER_STYLES() {
    return BORDER_STYLES;
  }

  get borderSelectOptions() {
    return this.stylesService.borderSelectOptions;
  }

  public get stylePanel() {
    return this.stylesService.stylePanel;
  }

  get element(): JQuery {
    return this._element;
  }

  set element(value: JQuery | null) {
    if (this._element && (!value || this._element[0] !== value[0])) this._element.removeClass('editor-select');

    this._element = value;
  }

  public get imageFitIntoOptions() {
    return IMAGE_FIT_INTO_OPTIONS;
  }

  public get imageFitIntoDefaultOption() {
    return IMAGE_FIT_INTO_DEFAULT_OPTION;
  }

  public get imageCaptionPositionOptions() {
    return IMAGE_CAPTION_POSITION_OPTIONS;
  }

  public get imageCaptionPositionDefaultOption() {
    return IMAGE_CAPTION_POSITION_DEFAULT_OPTION;
  }

  public get imageLabelButtonOptions(): SelectOption[] {
    if (!this.customSetup || !this.customSetup.imageLabelButton) {
      return [];
    }
    
    return this.customSetup.imageLabelButton.optionsList;
  }

  public get imageLabelButtonPositionOptions(): SelectOption[] {
    return IMAGE_LABEL_BUTTON_POSITION_OPTIONS;
  }

  public get imageLabelPositionOptions() {
    return IMAGE_LABEL_POSITION_OPTIONS;
  }

  public get imageTransitionOptions(): SelectOption[] {
    return IMAGE_TRANSITION_OPTIONS;
  }

  public get portfolioEnlargementImageTransitionOptions(): SelectOption[] {
    return PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION_OPTIONS;
  }

  public get imageLabelPositionDefaultOption() {
    return IMAGE_LABEL_POSITION_DEFAULT_OPTION;
  }

  public get imageLabelPositionRightSideOption() {
    return IMAGE_LABEL_POSITION_RIGHT_SIDE_OPTION;
  }

  public get imageTransitionDefaultOption(): SelectOption {
    return IMAGE_TRANSITION_DEFAULT_OPTION;
  }

  public get portfolioEnlargementImageTransitionDefaultOption(): SelectOption {
    return PORTFOLIO_ENLARGEMENT_IMAGE_TRANSITION_DEFAULT_OPTION;
  }

  public get positionOptions() {
    return POSITION_OPTIONS;
  }

  public get imageCropOptions(): SelectOption[] {
    return IMAGE_CROP_OPTIONS;
  }

  public get positionDefaultOption() {
    return POSITION_DEFAULT_OPTION;
  }

  public get blockWidthTypeOptions(): SelectOption[] {
    return BLOCK_WIDTH_TYPE_OPTIONS;
  }

  public get portfolioImageTitlePositionOptions(): SelectOption[] {
    return PORTFOLIO_IMAGE_TITLE_POSITION_OPTIONS;
  }

  public get portfolioEnlargementTitlePositionOptions(): SelectOption[] {
    return PORTFOLIO_ENLARGEMENT_TITLE_POSITION_OPTIONS;
  }

  public get masonryTypeOptions(): SelectOption[] {
    return MASONRY_TYPE_OPTIONS;
  }

  public get blockAlignmentOptions(): SelectOption[] {
    return BLOCK_ALIGNMENT_OPTIONS;
  }

  public get imageAlignmentOptions(): SelectOption[] {
    return IMAGE_ALIGNMENT_OPTIONS;
  }

  public get slideshowBorderStylesSelectOptions(): SelectOption[] {
    return BORDER_STYLES_STYLE_OPTIONS;
  }

  public get slideshowTransitionOptions(): SelectOption[] {
    return SLIDESHOW_TRANSITION_OPTIONS;
  }

  public get initialOverlayClickBehaviors(): { key: string, label: string }[] {
    return this.customSetup.initialOverlayClickBehavior.values;
  }

  public get layers(): { key: string, label: string }[] {
    return this.customSetup.selectLayer.values;
  }

  public get loaderTypes(): { key: string, label: string }[] {
    return this.customSetup.loaderType.values;
  }

  public get customizableLayers(): string[] {
    return this.customSetup.customizableLayers.values;
  }

  public get ENLARGEMENT_PADDING_OPTIONS(): { key: string, text: string }[] {
    return ENLARGEMENT_PADDING_OPTIONS;
  }

  public get PADDING_VW_OPTIONS(): { key: string, text: string, min?: number, max?: number }[] {
    return PADDING_VW_OPTIONS;
  }

  public get MENU_LOGO_PADDING_OPTIONS() {
    return MENU_LOGO_PADDING_OPTIONS;
  }

  parseElement = () => {
    const element = $(this.element);

    Object.keys(this.stylePanel.element.styles).forEach(styleName => {
      this.stylePanel.element.styles[styleName] = this.detailsService.parseStyleProperty(element, styleName);
    });

    this.stylesService.borderSelectOptions.forEach(option => {
      option.isSelected = !element.css(option.value).includes('none');
    });

    this.handlePaddingOptions();
  };

  parseBlock = () => {
    this.stylePanel.block.styles = new BlockOptions(this.element);
  };

  parseDivider = () => {
    this.stylePanel.divider.styles = DividerStyles.parse(this.element, this.detailsService);
  };

  EDITING_TYPE = {
    ELEMENT: {
      value: 'ELEMENT',
      handle: () => {
        this.parseElement();
      }
    },
    DIVIDER: {
      value: 'DIVIDER',
      handle: this.parseDivider
    },
    BLOCK: {
      value: 'BLOCK',
      handle: this.parseBlock,
    }
  };

  editingType: any;

  frame: any;
  _element: JQuery;

  loading = false;

  public highlightedSections: { [key: string]: boolean } = {};

  isLinkDropdownVisible: boolean = false;

  public isBlog: boolean = false;
  public isPortfolioZoom: boolean = false;
  public isPortfolioIndexMasonry: boolean = false;
  public isImageLabel: boolean = false;

  public isVideoUrlCollapsed: boolean = false;
  public isEmbedVideoCollapsed: boolean = true;

  public isSlideshowTitleTextSetupCollapsed: boolean = true;
  public isSlideshowParagraphTextSetupCollapsed: boolean = true;
  public isSlideshowButtonTextSetupCollapsed: boolean = true;

  public selectedPage: SelectedPageModel;

  public isMobileDevice: boolean = false;
  public isDesktopDevice: boolean = false;

  public fontSizes: number[] = [];
  public lineHeightLimit: number = 100;
  public letterSpacingLimit: number = 100;
  public wordSpacingLimit: number = 100;

  public scalableFontSizeMin: number = 0;
  public scalableFontSizeMax: number = 100;
  public scalableLineHeightMin: number = 0;
  public scalableLineHeightMax: number = 100;
  public scalableLetterSpacingMin: number = 0;
  public scalableLetterSpacingMax: number = 50;
  public scalableWordSpacingMin: number = 0;
  public scalableWordSpacingMax: number = 50;

  private website: WebsiteModel;

  private templateId: number;

  private device: string = '';

  private isDestroyed: boolean = false;

  private titles: { [key: number]: string } = {
    5: 'AWARD / DISTINCTION',
    6: 'BLOG',
    8: 'CONTACT',
    10: 'CV',
    11: 'DIVIDER',
    14: 'EXHIBITION',
    16: 'FOOTER',
    18: 'IMAGE',
    19: 'INFO',
    20: 'INSTAGRAM FEED',
    22: 'MAILING LIST SIGN UP',
    23: 'MENU',
    26: 'PORTFOLIO',
    27: 'PORTFOLIO INDEX',
    28: 'PORTFOLIO INTRO',
    29: 'PORTFOLIO',
    30: 'PRESS',
    31: 'PUBLICATION',
    33: 'SLIDESHOW',
    34: 'SPLASH',
    35: 'STATEMENT',
    37: 'TEXT / INFO',
    38: 'UPCOMING',
    39: 'PARALLAX',
    40: 'BUTTON',
    41: 'VIDEO',
    42: 'AUDIO',
    43: 'PORTFOLIO',
    44: 'BOOK',
    45: 'EXHIBITION INDEX',
  };

  private ignoredEditableTypes: string[] = [
    'PORTFOLIO_INTRO_OVERLAY',
    'PORTFOLIO_ZOOM_VIEW_CONTAINER',
    'PORTFOLIO_4_FOOTER',
    'PORTFOLIO_5_FOOTER',
  ];

  private ngUnsubscribe = new Subject();

  public optionsMap: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();
  public disabledOptions: StyleOptionModel[] = [];

  private editableTypes = [
    'ELEMENT',
    'DIVIDER',
  ];

  private ignoredElementsSelectors: string[] = [
    '[data-is-portfolio-enlargement-arrow="true"]',
  ];

  public get nativeSetup(): NativeSetupModel {
    return this.nativeStylesService.setup;
  }

  public get customSetup(): SetupModel {
    return this.customStylesService.setup;
  }

  public get blogSetup(): BlogSetupModel {
    return this.blogStylesService.setup;
  }

  public get blogAddCommentButtonSetup(): BlogAddCommentButtonSetupModel {
    return this.blogAddCommentButtonStylesService.setup;
  }

  public get blogCommentsSetup(): BlogCommentsSetupModel {
    return this.blogCommentsStylesService.setup;
  }

  public get menuSetup(): MenuSetupModel {
    return this.customMenuStylesService.setup;
  }

  public get fontSizesLimited(): number[] {
    return FONT_SIZES_LIMITED;
  }

  public get portfolioTitleFontSizes(): number[] {
    return PORTFOLIO_IMAGE_TITLE_FONT_SIZES;
  }

  public get portfolioSlideThumbFontSizes(): number[] {
    return PORTFOLIO_SLIDE_THUMB_FONT_SIZES;
  }

  public get thumbDrawerFontSizes(): number[] {
    return THUMB_DRAWER_FONT_SIZES;
  }

  public get portfolioMobileAlbumViews(): SelectOption[] {
    return MOBILE_ALBUM_VIEWS;
  }

  public get fontStyles(): SelectOption[] {
    return FONT_STYLES;
  }

  public get fontWeightsOptions(): SelectOption[] {
    return this.nativeStylesService.fontWeightsOptions;
  }

  public get selectedFontWeightOption(): SelectOption {
    return this.nativeStylesService.fontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.nativeSetup.fontWeight.value;
    });
  }

  public get slideshowTitleFontWeightsOptions(): SelectOption[] {
    return this.customStylesService.slideshowTitleFontWeightsOptions;
  }

  public get selectedSlideshowTitleFontWeightOption(): SelectOption {
    return this.customStylesService.slideshowTitleFontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.customSetup.scalableSlideshowTitleFontWeight.value;
    });
  }

  public get slideshowParagraphFontWeightsOptions(): SelectOption[] {
    return this.customStylesService.slideshowParagraphFontWeightsOptions;
  }

  public get selectedSlideshowParagraphFontWeightOption(): SelectOption {
    return this.customStylesService.slideshowParagraphFontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.customSetup.scalableSlideshowParagraphFontWeight.value;
    });
  }

  public get slideshowButtonFontWeightsOptions(): SelectOption[] {
    return this.customStylesService.slideshowButtonFontWeightsOptions;
  }

  public get selectedSlideshowButtonFontWeightOption(): SelectOption {
    return this.customStylesService.slideshowButtonFontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.customSetup.scalableSlideshowButtonFontWeight.value;
    });
  }

  public get portfolioImageTitleFontWeightsOptions(): SelectOption[] {
    return this.customStylesService.portfolioImageTitleFontWeightsOptions;
  }

  public get selectedPortfolioImageTitleFontWeightOption(): SelectOption {
    return this.customStylesService.portfolioImageTitleFontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.customSetup.portfolioImageTitleFontWeight.value;
    });
  }

  public get portfolioEnlargementTitleFontWeightsOptions(): SelectOption[] {
    return this.customStylesService.portfolioEnlargementTitleFontWeightsOptions;
  }

  public get selectedPortfolioEnlargementTitleFontWeightOption(): SelectOption {
    return this.customStylesService.portfolioEnlargementTitleFontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.customSetup.portfolioEnlargementTitleFontWeight.value;
    });
  }

  public get portfolioSlideThumbFontWeightsOptions(): SelectOption[] {
    return this.customStylesService.portfolioSlideThumbFontWeightsOptions;
  }

  public get selectedPortfolioSlideThumbFontWeightOption(): SelectOption {
    return this.customStylesService.portfolioSlideThumbFontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.customSetup.portfolioSlideThumbFontWeight.value;
    });
  }

  public get thumbDrawerFontWeightsOptions(): SelectOption[] {
    return this.customStylesService.thumbDrawerFontWeightsOptions;
  }

  public get selectedThumbDrawerFontWeightOption(): SelectOption {
    return this.customStylesService.thumbDrawerFontWeightsOptions.find((option: SelectOption) => {
      return option.value === this.customSetup.thumbDrawerFontWeight.value;
    });
  }

  public get textDecorationOptions(): SelectOption[] {
    return TEXT_DECORATION_OPTIONS;
  }

  public get textTransformations(): string[] {
    return TEXT_TRANSFORMATIONS;
  }

  public get thumbsPositionOptions(): SelectOption[] {
    return THUMBS_POSITION_OPTIONS;
  }

  public get slideshowHorizontalTextAlignments(): SelectOption[] {
    return SLIDESHOW_HORIZONTAL_TEXT_ALIGNMENT;
  }

  public get slideshowVerticalTextAlignments(): SelectOption[] {
    return SLIDESHOW_VERTICAL_TEXT_ALIGNMENT;
  }

  public get textAlignments(): string[] {
    return TEXT_ALIGNMENTS;
  }

  public get textDecorations(): string[] {
    return TEXT_DECORATIONS;
  }

  public get openedPage() {
    return this.iFrameRoutingService.selectedPageSubject;
  }

  constructor(
    private websiteDesignerService: WebsiteDesignerService,
    private attributesService: AttributesService,
    private eventsService: EventsService,
    private nativeStylesService: NativeStylesService,
    private customStylesService: CustomStylesService,
    private blogStylesService: BlogStylesService,
    private blogAddCommentButtonStylesService: BlogAddCommentButtonStylesService,
    private blogCommentsStylesService: BlogCommentsStylesService,
    private stylesService: StylesService,
    private imageManagerModalService: ImageManagerModalService,
    private stylesSettingsService: StylesSettingsService,
    private authService: AuthService,
    private iFrameService: IFrameService,
    private iFrameRoutingService: IFrameRoutingService,
    private googleFontsService: GoogleFontsService,
    private portfolioPickerModalService: PortfolioPickerModalService,
    private videoEmbedService: VideoEmbedService,
    private audioManagerService: AudioManagerService,
    private editorDevicesService: EditorDevicesService,
    private customMenuStylesService: CustomMenuStylesService,
    private websitesService: WebsitesService,
    private templatesService: TemplatesService,
    private documentManagerService: DocumentManagerService,
    public logoUploadService: LogoUploadService,
    public pageEditorService: PageEditorService,
    public buttonsService: ButtonsService,
    public elementService: SidebarCustomizerElementService,
    public blockElementService: BlockElementService,
    public detailsService: DetailsService,
    public cdr: ChangeDetectorRef,
  ) {
    this.eventsService.addFrameListener('isPortfolioEnlargementOpened', (e: CustomEvent) => {
      this.isPortfolioEnlargementOpened = e.detail;

      if (this.isDestroyed) return;

      this.cdr.detectChanges();
    });

    this.eventsService.addFrameListener('highlightCustomizerSection', (e: CustomEvent) => {
      this.highlightedSections[e.detail.key] = true;

      this.cdr.detectChanges();

      window.setTimeout(() => {
        this.highlightedSections[e.detail.key] = false;

        this.cdr.detectChanges();
      }, 1000);
    });
  }

  public ngOnInit(): void {
    this.loading = false;

    this.frame = document.getElementById('sandbox');

    this.editorDevicesService.onDeviceChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((device: string) => {
      this.device = device;
      
      this.isDesktopDevice = device === DEVICES.DESKTOP;
      this.isMobileDevice = device === DEVICES.MOBILE;

      this.menuSetup.logoPadding.init(this.elementService.element, { device: this.device });
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;

      this.onElementChange(null);
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.stylesSettingsService.disabledOptionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((options: StyleOptionModel[]) => {
      this.disabledOptions = options;
    });

    this.stylesSettingsService.optionsMapSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((options: Map<string, StyleOptionModel>) => {
      this.optionsMap = options;

      if (this.optionsMap && this.optionsMap.get('PADDING_VW')) {
        this.optionsMap.get('PADDING_VW').max = 10;
      }

      this.cdr.detectChanges();
    });

    this.websiteDesignerService.editingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(options => {
      this.onElementChange(options);
    });

    this.googleFontsService.selectedFontSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((font: GoogleFontModel) => {
      if (this.element) this.onFontSelect(font);
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.templateId = template ? template.id : null;
    });
  }
  
  public onFontSizeChange(fontSize: string): void {
    const editingType = this.editingType.toLowerCase();
    const fontSizeValue: number = Number.parseInt(fontSize);

    this.nativeSetup.fontSize.onChange(fontSize);

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.fontSize);

    this.dispatchCustomStyleChangedEvent({
      target: this.nativeSetup.element,
      key: this.nativeSetup.fontSize.key,
      value: this.nativeSetup.fontSize.value,
    });

    this.afterChange();

    if (this.editingType !== this.EDITING_TYPE.ELEMENT.value) return;

    const expectedLineHeight: number = fontSizeValue * 1.5;
    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    return this.stylesService.onStyleChange(editingType, 'line-height', newLineHeight);
  }

  public onFontStyleChange(value: string): void {
    this.nativeSetup.fontStyle.onChange(value);

    this.afterChange();
  }

  public onFontStyleReset(): void {
    this.nativeSetup.fontStyle.reset();

    this.afterChange();
  }

  public onElementStyleChange = (styleName?, styleValue?) => this.stylesService.onStyleChange(ELEM_TYPES.ELEMENT, styleName, styleValue);
  public onDividerStyleChange = (styleName?, styleValue?) => this.stylesService.onStyleChange(ELEM_TYPES.DIVIDER, styleName, styleValue);

  private resetHandlers = {
    [STYLE_KEYS.BORDERS.POSITION]: () => this.onBordersSelect(this.stylesService.DEFAULT_BORDER_SELECT_OPTIONS),
    [STYLE_KEYS.BORDERS.COLOR]: () => this.stylesService.resetBorderColor(),
    [STYLE_KEYS.BORDERS.WIDTH]: () => this.stylesService.initBorderWidth(),
    [STYLE_KEYS.PADDING]: () => {
      this.paddingPositions.forEach(position => {
        if (this.optionsMap.has(position.key)) {
          this.resetStyle(position.styleKey);
        }
      });
    },
  };

  private specificResetHandlers = {
    [STYLE_KEYS.LINE_HEIGHT]: () => this.stylesService.setLineHeight({ newValue: 30 }),
  };

  public resetStyle(styleName) {
    if (this.resetHandlers[styleName]) {
      this.resetHandlers[styleName]();

      this.cdr.detectChanges();

      return;
    }

    if (!this.element) return;

    this.element[0].style.removeProperty(styleName);
    this.onElementStyleChange(styleName, '');
    this.parseElement();

    if (this.specificResetHandlers[styleName]) this.specificResetHandlers[styleName]();

    this.cdr.detectChanges();
  }

  private onElementChange(options): void {
    if (!options || !this.editableTypes.includes(options.editingType)) {
      this.element = null;
      this.editingType = null;

      this.initStyleService();

      this.cdr.detectChanges();

      return;
    }

    this.stylesSettingsService.onElementChange(options);

    this.frame = document.getElementById('sandbox');

    this.element = options.element ? $(options.element) : options.element;

    this.initCustomizerTitle();

    this.elementService.element = this.element && this.element[0];
    this.blockElementService.element = this.element && this.element[0];

    this.isBlockElement = this.elementService.element && this.elementService.element.classList.contains('block');
    this.isBlog = this.elementService.element && this.elementService.element.classList.contains('blog');
    this.isPortfolioZoom = this.elementService.element && this.elementService.element.matches('[data-block-template-id="99"]');
    this.isPortfolioIndexMasonry = this.elementService.element && this.elementService.element.matches('[data-block-template-id="142"]');

    this.fontSizes = this.isPortfolioZoom ? FONT_SIZES_20 : FONT_SIZES;

    this.lineHeightLimit = this.isPortfolioZoom ? 30 : 100;
    this.letterSpacingLimit = this.isPortfolioZoom ? 10 : 100;
    this.wordSpacingLimit = this.isPortfolioZoom ? 10 : 100;

    this.initScalableTextData();
    this.initServicesOnElementChange();

    this.menuSetup.logoPadding.init(this.elementService.element, { device: this.device });

    this.element.addClass('editor-select');

    this.initStyleService();

    this.editingType = options.editingType;

    if (!this.editingType || !this.EDITING_TYPE[this.editingType]) {
      this.elementService.element = null;
      this.blockElementService.element = null;
      this.editingType = null;

      this.cdr.detectChanges();

      return;
    }

    const handler = this.EDITING_TYPE[this.editingType].handle;

    if (handler) handler(options);

    this.cdr.detectChanges();
  }

  private initServicesOnElementChange(): void {
    this.nativeStylesService.init(
      this.elementService.element,
      this.stylesSettingsService.optionsMap,
    );

    this.customStylesService.init({
      element: this.elementService.element,
      enabledStyleOptions: this.stylesSettingsService.optionsMap,
      device: this.device,
    });

    this.blogStylesService.init(
      this.elementService.element,
      this.stylesSettingsService.optionsMap,
    );

    this.blogAddCommentButtonStylesService.init(
      this.elementService.element,
      this.stylesSettingsService.optionsMap,
    );

    this.blogCommentsStylesService.init(
      this.elementService.element,
      this.stylesSettingsService.optionsMap,
    );
  }

  private initCustomizerTitle(): void {
    const element: HTMLElement = this.element[0];

    if (!element) {
      this.sectionTitle = 'ELEMENT';

      return;
    }

    const editableType: string = element.getAttribute('data-editable-type');

    if (this.ignoredEditableTypes.includes(editableType)) {
      this.sectionTitle = null;

      return;
    }

    const isIgnored: boolean = this.ignoredElementsSelectors.findIndex(selector => element.matches(selector)) !== -1;

    if (isIgnored) {
      this.sectionTitle = null;

      return;
    }

    const categoryId: number = Number.parseInt(element.getAttribute('data-block-category-id'));

    this.sectionTitle = this.titles[categoryId] ? `${this.titles[categoryId]} BLOCK` : 'ELEMENT';
  }

  private initScalableTextData(): void {
    this.isImageLabel = this.elementService.element && this.elementService.element.matches('[data-editable-type="PORTFOLIO_INFO_OVERLAY"]');

    this.scalableFontSizeMin = this.isImageLabel ? 15 : 0;
    this.scalableFontSizeMax = this.isImageLabel ? 30 : 100;
    this.scalableLineHeightMin = this.isImageLabel ? 10 : 0;
    this.scalableLineHeightMax = this.isImageLabel ? 40 : 100;
    this.scalableLetterSpacingMin = this.isImageLabel ? 0 : 0;
    this.scalableLetterSpacingMax = this.isImageLabel ? 15 : 50;
    this.scalableWordSpacingMin = this.isImageLabel ? 0 : 0;
    this.scalableWordSpacingMax = this.isImageLabel ? 15 : 50;
  }

  private initStyleService() {
    this.stylesService.init(this.element ? this.element[0] : null);
  }

  openDefaultOption(element) {
    if (!element || element.tagName !== 'IMG') return;

    this.imageManagerModalService.open();
  }

  private openImageManagerModal(element): void {
    this.element = element ? $(element) : element;

    this.imageManagerModalService.open();
  }

  public openPdfManager(): void {
    this.documentManagerService.open();

    this.eventsService.dispatchDocumentManagerOpened({ element: this.element[0] }, this.iFrameService.sandboxWindow);
  }

  overrideStyle(styleName, styleValue) {
    this.stylePanel.element.styles[styleName] = styleValue;
  }

  private applyStyleToElementIfEditableFunction = null;

  applyStyleToElementIfEditable(el, styleName, styleValue) {
    if(!this.applyStyleToElementIfEditableFunction) {
      this.applyStyleToElementIfEditableFunction =
        _.get(this, 'frame.contentWindow.applyStyleToElementIfEditable');
    }

    if(this.applyStyleToElementIfEditableFunction) {
      this.applyStyleToElementIfEditableFunction(el, styleName, styleValue);
    }
  }

  onBordersSelect(options: SelectOption[]) {
    this.stylesService.borderSelectOptions.forEach(border => {
      const currentOption = options.find(option => option.value === border.value);

      border.isSelected = currentOption && currentOption.isSelected;

      if (!border.isSelected) return this.onElementStyleChange(border.value, 'none');

      this.onElementStyleChange(border.value, this.stylePanel.element.styles[STYLE_KEYS.BORDERS.STYLE]);
    });

    this.onElementStyleChange(STYLE_KEYS.BORDERS.WIDTH, this.stylePanel.element.styles[STYLE_KEYS.BORDERS.WIDTH]);

    this.stylesService.initBorderColor();
  }

  onBlockMakeLinkClick(): void {
    if (!this.blockElementService.isCouldBeWrappedInLink) return;

    if (!this.blockElementService.isWrapped) {
      this.isLinkDropdownVisible = !this.isLinkDropdownVisible;

      return;
    }

    this.blockElementService.unwrapFromAnchor();

    this.eventsService.dispatchMakeLinkAction({ element: this.blockElementService.element }, this.iFrameService.sandboxWindow);
  }

  onBlockLinkApply(options): void {
    const { uri, isCustom } = options;

    if (!uri || !this.blockElementService.isCouldBeWrappedInLink) return;

    this.isLinkDropdownVisible = false;

    this.blockElementService.wrapInAnchor(uri, isCustom);

    this.afterChange();

    this.eventsService.dispatchMakeLinkAction({ element: this.blockElementService.element }, this.iFrameService.sandboxWindow);

    this.onElementStyleChange();
  }

  onMakeLinkClick(): void {
    if (!this.elementService.isCouldBeWrappedInLink) return;

    if (!this.elementService.isWrapped) {
      this.isLinkDropdownVisible = !this.isLinkDropdownVisible;

      return;
    }

    this.elementService.unwrapFromAnchor();
    this.eventsService.dispatchMakeLinkAction({ element: this.elementService.element }, this.iFrameService.sandboxWindow);
  }

  onLinkApply(options): void {
    const { uri, isCustom } = options;

    if (!uri || !this.elementService.isCouldBeWrappedInLink) return;

    this.isLinkDropdownVisible = false;

    this.elementService.wrapInAnchor(uri, isCustom);

    this.afterChange();

    this.eventsService.dispatchMakeLinkAction({ element: this.elementService.element }, this.iFrameService.sandboxWindow);

    this.onElementStyleChange();
  }

  public onLinkDropdownClose(): void {
    this.isLinkDropdownVisible = false;
  }

  public changeGalleryImage(): void {
    const block = this.element.closest('.block');

    if (!block || !this.element || !this.element.length) return;

    const portfolioId = this.element.attr('data-portfolio-id');

    this.element = block.find(`.images-container [data-portfolio-id="${portfolioId}"]`);

    this.openImageManagerModal(this.element);
  }

  public onPortfolioInfoIconBackgroundColorChange(value: string): void {
    this.customSetup.portfolioInfoIconBackgroundColor.onChange(value);

    this.afterChange();
  }

  public onPortfolioInfoIconBackgroundColorReset(): void {
    this.customSetup.portfolioInfoIconBackgroundColor.reset();
    this.afterChange();
  }

  public onPortfolioInfoIconColorChange(value: string): void {
    this.customSetup.portfolioInfoIconColor.onChange(value);
    this.afterChange();
  }

  public onPortfolioInfoIconColorReset(): void {
    this.customSetup.portfolioInfoIconColor.reset();
    this.afterChange();
  }

  public onPortfolioInfoIconBorderColorChange(value: string): void {
    this.customSetup.portfolioInfoIconBorderColor.onChange(value);
    this.afterChange();
  }

  public onPortfolioInfoIconBorderColorReset(): void {
    this.customSetup.portfolioInfoIconBorderColor.reset();
    this.afterChange();
  }

  public onThumbDrawerFontChange(font: GoogleFontModel): void {
    this.customStylesService.onThumbDrawerFontFamilyChange(font);

    this.googleFontsService.addFontLink(font);

    this.afterChange();
  }

  public onThumbDrawerFontReset(): void {
    this.customSetup.thumbDrawerFontFamily.reset();

    this.afterChange();
  }

  public onThumbDrawerFontSizeChange(value: string): void {
    const fontSize: number = Number.parseInt(value);

    this.customSetup.thumbDrawerFontSize.onChange(fontSize);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerFontSize);

    this.afterChange();
  }

  public onThumbDrawerFontSizeReset(): void {
    this.customSetup.thumbDrawerFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerFontSize);

    this.afterChange();
  }

  public onThumbDrawerFontWeightChange(value: string): void {
    this.customSetup.thumbDrawerFontWeight.onChange(value);

    this.afterChange();
  }

  public onThumbDrawerFontWeightReset(): void {
    this.customSetup.thumbDrawerFontWeight.reset();

    this.customStylesService.initThumbDrawerFontWeight();

    this.afterChange();
  }

  public onThumbDrawerTextTransformChange(value: string): void {
    this.customSetup.thumbDrawerTextTransform.onChange(value);

    this.afterChange();
  }

  public onThumbDrawerTextTransformReset(): void {
    this.customSetup.thumbDrawerTextTransform.reset();

    this.afterChange();
  }

  public onThumbDrawerLetterSpacingChange(value: string): void {
    this.customSetup.thumbDrawerLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerLetterSpacing);

    this.afterChange();
  }

  public onThumbDrawerLetterSpacingReset(): void {
    this.customSetup.thumbDrawerLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerLetterSpacing);

    this.afterChange();
  }

  public onThumbDrawerWordSpacingChange(value: string): void {
    this.customSetup.thumbDrawerWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerWordSpacing);

    this.afterChange();
  }

  public onThumbDrawerWordSpacingReset(): void {
    this.customSetup.thumbDrawerWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerWordSpacing);

    this.afterChange();
  }

  public onThumbDrawerBorderRadiusChange(value: number): void {
    this.customSetup.thumbDrawerBorderRadius.onChange(value);

    this.afterChange();
  }

  public onThumbDrawerBorderRadiusReset(): void {
    this.customSetup.thumbDrawerBorderRadius.reset();

    this.afterChange();
  }

  public onThumbDrawerIconSizeChange(value: string): void {
    this.customSetup.thumbDrawerIconSize.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public onThumbDrawerIconSizeReset(): void {
    this.customSetup.thumbDrawerIconSize.reset();
    this.afterChange();
  }

  public onThumbDrawerIconBackgroundColorChange(value: string): void {
    this.customSetup.thumbDrawerIconBackgroundColor.onChange(value);
    this.afterChange();
  }

  public onThumbDrawerIconBackgroundColorReset(): void {
    this.customSetup.thumbDrawerIconBackgroundColor.reset();
    this.afterChange();
  }

  public onThumbDrawerIconColorChange(value: string): void {
    this.customSetup.thumbDrawerIconColor.onChange(value);
    this.afterChange();
  }

  public onThumbDrawerIconColorReset(): void {
    this.customSetup.thumbDrawerIconColor.reset();
    this.afterChange();
  }

  public onThumbDrawerIconBorderColorChange(value: string): void {
    this.customSetup.thumbDrawerIconBorderColor.onChange(value);
    this.afterChange();
  }

  public onThumbDrawerIconBorderColorReset(): void {
    this.customSetup.thumbDrawerIconBorderColor.reset();
    this.afterChange();
  }

  public onFooterOnLoadVisibilityToggle(value: boolean): void {
    this.customSetup.isFooterVisibleOnLoad.onChange(value);

    this.afterChange();
  }

  public onSlideTrackerToggle(value: boolean): void {
    this.customSetup.isSlideTrackerVisible.onChange(value);

    this.afterChange();
  }

  public onArrowsToggle(value: boolean): void {
    this.customSetup.isArrowsVisible.onChange(value);

    this.afterChange();
  }

  public onSlideshowAutoplayToggle(value: boolean): void {
    this.customSetup.isSlideshowAutoplayEnabled.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isSlideshowAutoplayEnabled);

    this.afterChange();
  }

  public onPortfolioTitleVisibilityToggle(value: boolean): void {
    this.customSetup.isPortfolioTitleAdded.onChange(value);

    this.eventsService.dispatchPortfolioTitleVisibilityToggle({
      element: this.element[0],
      isEnabled: this.customSetup.isPortfolioTitleAdded.value,
    }, this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public onSlideTextVisibilityToggle(value: boolean): void {
    this.customSetup.isSlideTextAdded.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isSlideTextAdded);
  }

  public onSlideButtonVisibilityToggle(value: boolean): void {
    this.customSetup.isSlideButtonAdded.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isSlideButtonAdded);
  }

  public onThumbDrawerArrowsVisibilityToggle(value: boolean): void {
    this.customSetup.isThumbDrawerArrowsVisible.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isThumbDrawerArrowsVisible);
  }

  public onThumbDrawerAutoscrollToggle(value: boolean): void {
    this.customSetup.isThumbDrawerAutoscrollEnabled.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isThumbDrawerAutoscrollEnabled);
  }

  public onThumbDrawerScrollbarToggle(value: boolean): void {
    this.customSetup.isThumbDrawerScrollbarEnabled.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isThumbDrawerScrollbarEnabled);
  }

  public onFullscreenIconVisibilityChange(value: boolean): void {
    this.customSetup.isFullscreenIconShown.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isFullscreenIconShown);

    this.afterChange();
  }

  public onClickToEnlargeChange(value: boolean): void {
    this.customSetup.isClickToEnlarge.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isClickToEnlarge);
  }

  public onSlideshowEnabledChange(value: boolean): void {
    this.customSetup.isSlideshowEnabled.onChange(value);

    if (!value) {
      this.onSlideshowAutoplayChange(value);
    }

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isSlideshowEnabled);
  }

  public onSlideshowAutoplayChange(value: boolean): void {
    this.customSetup.isSlideshowAutoplay.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isSlideshowAutoplay);
  }

  public onExhibitionTitleVisibilityChange(value: boolean): void {
    this.customSetup.isExhibitionTitleVisible.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: this.customSetup.isExhibitionTitleVisible.key,
      value,
    });
  }

  public onItemsPaddingChange(value) {
    const target = this.element[0];
    const key = this.customSetup.isItemsPaddingCustomizable.key;

    this.customSetup.isItemsPaddingCustomizable.onChange(value);
    this.afterChange();

    this.dispatchCustomStyleChangedEvent({ target, key, value });
  }

  public onFitIntoChange(value) {
    const target = this.element[0];
    const key = this.customSetup.imageFitInto.key;

    this.customSetup.imageFitInto.onChange(value);
    this.afterChange();

    this.dispatchCustomStyleChangedEvent({ target, key, value });
  }

  public onImageLabelButtonChange(value: string): void {
    this.customSetup.imageLabelButton.onTypeChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.imageLabelButton);

    this.afterChange();
  }

  public onImageLabelButtonReset(): void {
    this.customSetup.imageLabelButton.onTypeReset();
  }

  public onImageLabelButtonPositionChange(value: string): void {
    this.customSetup.imageLabelButton.onPositionChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.imageLabelButton);

    this.afterChange();
  }

  public onImageLabelButtonPositionReset(): void {
    this.customSetup.imageLabelButton.onPositionReset();
  }

  public onImageLabelPositionChange(value) {
    this.customSetup.imageLabelPosition.onChange(value);

    this.eventsService.dispatchImageLabelStyleChanged(this.iFrameService.sandboxWindow);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.imageLabelPosition);

    this.afterChange();

    if (value === this.imageLabelPositionDefaultOption.key) this.onImageLabelToggleChange(false);
  }

  public onImageTransitionChange(value: string): void {
    this.customSetup.imageTransition.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.imageTransition);

    this.afterChange();
  }

  public onImageTransitionDurationChange(value) {
    this.customSetup.imageTransitionDuration.onChange(value);

    this.afterChange();
  }

  public onImageCaptionPositionChange(value) {
    const target = this.element[0];
    const key = this.customSetup.imageCaptionPosition.key;

    this.customSetup.imageCaptionPosition.onChange(value);
    this.afterChange();

    this.dispatchCustomStyleChangedEvent({ target, key, value });
  }

  public onImageEnlargementChange(value) {
    this.customSetup.imageEnlargement.onChange(value);
    this.afterChange();
  }

  public onSlideshowTransitionDurationChange(value) {
    this.customSetup.slideshowImageTransitionDuration.onChange(value);

    this.afterChange();
  }

  public onSlideshowTransitionChange(value: string): void {
    this.customSetup.slideshowTransition.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.slideshowTransition);

    this.afterChange();
  }

  public onSlideshowTransitionReset(): void {
    this.customSetup.slideshowTransition.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.slideshowTransition);

    this.afterChange();
  }



  public onSlideshowHorizontalTextAlignmentChange(value: string): void {
    this.customSetup.slideshowHorizontalTextAlignmentModel.onChange(value);

    this.afterChange();
  }

  public onSlideshowHorizontalTextAlignmentRevert(): void {
    this.customSetup.slideshowHorizontalTextAlignmentModel.reset();

    this.afterChange();
  }

  public onSlideshowVerticalTextAlignmentChange(value: string): void {
    this.customSetup.slideshowVerticalTextAlignmentModel.onChange(value);

    this.afterChange();
  }

  public onSlideshowVerticalTextAlignmentRevert(): void {
    this.customSetup.slideshowVerticalTextAlignmentModel.reset();

    this.afterChange();
  }

  public onSlideshowImageOverlayBackgroundColorChange(value: string): void {
    this.customSetup.slideshowImageOverlayBackgroundColor.setValue(value);

    this.afterChange();
  }

  public onSlideshowImageOverlayBackgroundColorReset(): void {
    this.customSetup.slideshowImageOverlayBackgroundColor.reset();

    this.afterChange();
  }

  public onSlideshowImageOverlayBackgroundTransparencyChange(value: string): void {
    this.customSetup.slideshowImageOverlayBackgroundTransparency.setValue(value);

    this.afterChange();
  }

  public onSlideshowImageOverlayBackgroundTransparencyReset(): void {
    this.customSetup.slideshowImageOverlayBackgroundTransparency.reset();

    this.afterChange();
  }






  public onSlideshowTextBackgroundColorChange(value: string): void {
    this.customSetup.slideshowTextBackgroundColor.setValue(value);

    this.afterChange();
  }

  public onSlideshowTextBackgroundColorReset(): void {
    this.customSetup.slideshowTextBackgroundColor.reset();

    this.afterChange();
  }

  public onSlideshowTextBackgroundTransparencyChange(value: string): void {
    this.customSetup.slideshowTextBackgroundTransparency.setValue(value);

    this.afterChange();
  }

  public onSlideshowTextBackgroundTransparencyReset(): void {
    this.customSetup.slideshowTextBackgroundTransparency.reset();

    this.afterChange();
  }






  public onButtonBgColorChange(value): void {
    this.customSetup.buttonBgColor.onChange(value);

    this.afterChange();
  }

  public onButtonBgColorReset(): void {
    this.customSetup.buttonBgColor.reset();

    this.afterChange();
  }

  public onButtonHoverColorChange(value): void {
    this.customSetup.buttonHoverColor.onChange(value);

    this.afterChange();
  }

  public onButtonHoverColorReset(): void {
    this.customSetup.buttonHoverColor.reset();

    this.afterChange();
  }

  public onSlideshowBorderStyleChange(option: SelectOption): void {
    this.customSetup.slideshowBorderStyle.onChange(option.value);

    this.afterChange();
  }

  public onSlideshowBorderStyleReset(): void {
    this.customSetup.slideshowBorderStyle.reset();

    this.afterChange();
  }

  public onSlideshowBorderRadiusChange(value: number): void {
    this.customSetup.slideshowBorderRadius.onChange(value);

    this.afterChange();
  }

  public onSlideshowBorderRadiusReset(): void {
    this.customSetup.slideshowBorderRadius.reset();

    this.afterChange();
  }

  public onSlideshowBorderColorChange(value: string): void {
    this.customSetup.slideshowBorderColor.onChange(value);

    this.afterChange();
  }

  public onSlideshowBorderColorReset(): void {
    this.customSetup.slideshowBorderColor.reset();

    this.afterChange();
  }

  public onSlideshowBorderWidthChange(value: number): void {
    this.customSetup.slideshowBorderWidth.onChange(value);

    this.afterChange();
  }

  public onSlideshowBorderWidthReset(): void {
    this.customSetup.slideshowBorderWidth.reset();

    this.afterChange();
  }

  public onCaptionToggle(value: boolean) {
    const target = this.element[0];
    const key = this.customSetup.isCaptionEnabled.key;

    this.customSetup.isCaptionEnabled.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEvent({ target, key, value });
  }

  public onPortfolioArrowsBorderRadiusChange(value: number): void {
    this.customSetup.portfolioArrowsBorderRadius.onChange(value);
    this.afterChange();
  }

  public onPortfolioArrowsBorderRadiusReset(): void {
    this.customSetup.portfolioArrowsBorderRadius.reset();
    this.afterChange();
  }

  public onPortfolioArrowsColorChange(value): void {
    this.customSetup.portfolioArrowsColor.onChange(value);
    this.afterChange();
  }

  public onPortfolioArrowsColorReset(): void {
    this.customSetup.portfolioArrowsColor.reset();
    this.afterChange();
  }

  public onPortfolioArrowsSizeChange(value: number): void {
    this.customSetup.portfolioArrowsSize.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioArrowsSize);

    this.afterChange();
  }

  public onPortfolioArrowsSizeReset(): void {
    this.customSetup.portfolioArrowsSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioArrowsSize);

    this.afterChange();
  }

  public onPortfolioArrowsBackgroundChange(value: string): void {
    this.customSetup.portfolioArrowsBackground.setValue(value);
    this.afterChange();
  }

  public onPortfolioArrowsBackgroundReset(): void {
    this.customSetup.portfolioArrowsBackground.reset();
    this.afterChange();
  }

  public onPortfolioArrowTransparencyChange(value: string): void {
    this.customSetup.portfolioArrowsBackgroundTransparency.setValue(value);
    this.afterChange();
  }

  public onPortfolioArrowTransparencyReset(): void {
    this.customSetup.portfolioArrowsBackgroundTransparency.reset();
    this.afterChange();
  }

  public onPortfolioEnlargementArrowsBorderRadiusChange(value: number): void {
    this.customSetup.portfolioEnlargementArrowsBorderRadius.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsBorderRadius);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowsBorderRadiusReset(): void {
    this.customSetup.portfolioEnlargementArrowsBorderRadius.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsBorderRadius);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowsColorChange(value): void {
    this.customSetup.portfolioEnlargementArrowsColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsColor);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowsColorReset(): void {
    this.customSetup.portfolioEnlargementArrowsColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsColor);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowsSizeChange(value: number): void {
    this.customSetup.portfolioEnlargementArrowsSize.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsSize);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowsSizeReset(): void {
    this.customSetup.portfolioEnlargementArrowsSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsSize);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowsBackgroundChange(value: string): void {
    this.customSetup.portfolioEnlargementArrowsBackground.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsBackground);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowsBackgroundReset(): void {
    this.customSetup.portfolioEnlargementArrowsBackground.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsBackground);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowTransparencyChange(value: string): void {
    this.customSetup.portfolioEnlargementArrowsBackgroundTransparency.setValue(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsBackgroundTransparency);

    this.afterChange();
  }

  public onPortfolioEnlargementArrowTransparencyReset(): void {
    this.customSetup.portfolioEnlargementArrowsBackgroundTransparency.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementArrowsBackgroundTransparency);

    this.afterChange();
  }

  public onArrowsColorChange(value: string): void {
    this.customSetup.arrowsColor.onChange(value);
    this.afterChange();
  }

  public onArrowsColorReset(): void {
    this.customSetup.arrowsColor.onChange(null);
    this.afterChange();
  }

  public onArrowsBackgroundChange(value: string): void {
    this.customSetup.arrowBackground.setValue(value);
    this.afterChange();
  }

  public onArrowsBackgroundReset(): void {
    this.customSetup.arrowBackground.reset();
    this.afterChange();
  }

  public onArrowsBorderRadiusChange(value: number): void {
    this.customSetup.arrowsBorderRadius.onChange(value);
    this.afterChange();
  }

  public onArrowsBorderRadiusReset(): void {
    this.customSetup.arrowsBorderRadius.reset();
    this.afterChange();
  }

  public onArrowsSizeChange(value: number): void {
    this.customSetup.arrowsSize.onChange(value);
    this.afterChange();
  }

  public onArrowsSizeReset(): void {
    this.customSetup.arrowsSize.reset();
    this.afterChange();
  }

  public onImageLabelToggleChange(value: boolean) {
    this.customSetup.imageLabelToggle.onChange(value);
    
    this.afterChange();

    this.dispatchCustomStyleChangedEvent({ target: this.element[0], key: this.customSetup.imageLabelToggle.key, value });
  }

  public onAnimationDelayChange(value) {
    this.customSetup.animationDelay.onChange(value);
    this.afterChange();
  }

  public onAnimationPlay() {
    this.eventsService.dispatchBlockReInit({ element: this.element[0] }, this.iFrameService.sandboxWindow);
  }

  public onLayerChange(value: string): void {
    const target: HTMLElement = <HTMLElement>this.element[0].closest('.block');
    const key = this.customSetup.selectLayer.key;

    this.customSetup.selectLayer.onChange(value);

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.selectLayer.value });
  }

  public onInitialOverlayClickBehaviorChange(value: string): void {
    const target = this.element[0];
    const key = this.customSetup.initialOverlayClickBehavior.key;

    this.customSetup.initialOverlayClickBehavior.onChange(value);

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.initialOverlayClickBehavior.value });

    this.afterChange();
  }

  public onInitialOverlayClickBehaviorReset(): void {
    this.customSetup.initialOverlayClickBehavior.reset();

    this.afterChange();
  }

  public onBlockAlignmentChange(value: string): void {
    this.customSetup.blockAlignment.onChange(value);

    this.afterChange();
  }

  public onBlockAlignmentReset(): void {
    this.customSetup.blockAlignment.reset();

    this.afterChange();
  }

  public onImageAlignmentChange(value: string): void {
    this.customSetup.imageAlignment.onChange(value);

    this.afterChange();
  }

  public onImageAlignmentReset(): void {
    this.customSetup.imageAlignment.reset();

    this.afterChange();
  }

  public onLoaderTypeChange(value: string): void {
    const target = this.element[0];
    const key = this.customSetup.loaderType.key;

    this.customSetup.loaderType.onChange(value);
    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.loaderType.value });

    this.afterChange();
  }

  public onLoaderTypeReset(): void {
    this.customSetup.loaderType.reset();
    this.afterChange();
  }

  public onCustomizableLayerChanged(value) {
    this.customSetup.customizableLayers.onChange(value);
    this.eventsService.dispatchCustomizableLayerChanged({ element: this.element[0], value }, this.iFrameService.sandboxWindow);
  }

  public onPortfolioLinkSelect({ uri }) {
    this.isLinkDropdownVisible = false;

    this.customSetup.portfolioLink.onChange(uri);
    this.afterChange();
  }

  public onPortfolioLinkReset() {
    this.isLinkDropdownVisible = false;

    this.customSetup.portfolioLink.onChange('');
    this.afterChange();
  }

  public onHrefSet(value: string) {
    const target = this.element[0];
    const key = this.customSetup.href.key;

    this.customSetup.href.onChange(value);

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.href.value });

    this.afterChange();
  }

  public onPositionChange(value) {
    const target = this.element[0];
    const key = this.customSetup.position.key;

    this.customSetup.position.onChange(value);
    this.afterChange();

    this.dispatchCustomStyleChangedEvent({ target, key, value });
  }

  public onImageCropChange(value: string): void {
    const target: HTMLElement = this.element[0];
    const key: string = this.customSetup.imageCrop.key;

    this.customSetup.imageCrop.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEvent({ target, key, value });
  }

  public onImageCropReset(): void {
    const target: HTMLElement = this.element[0];
    const key: string = this.customSetup.imageCrop.key;

    this.customSetup.imageCrop.onReset();

    this.afterChange();

    this.dispatchCustomStyleChangedEvent({
      target,
      key,
      value: this.customSetup.imageCrop.value,
    });
  }

  public handlePaddingOptions() {
    if (!this.stylesSettingsService.optionsMap) {
      return;
    }

    const paddingOptions = this.paddingPositions.filter(position => this.stylesSettingsService.optionsMap.has(position.key));

    this.isPaddingOptionEnabled = paddingOptions.length > 0;

    if (!this.isPaddingOptionEnabled) {
      return;
    }

    this.setIsPaddingValuesEqual();
    
    this.isPaddingSlidersExpanded = !this.isPaddingValuesEqual;

    if (this.isPaddingValuesEqual) {
      const paddingPosition = this.paddingPositions.find(position => this.stylesSettingsService.optionsMap.has(position.key));

      this.paddingValue = this.stylePanel.element.styles[paddingPosition.styleKey];

      return;
    }

    this.paddingValue = 0;
  }

  public onPaddingChange(value) {
    this.paddingValue = value;

    this.paddingPositions.forEach(position => {
      if (this.optionsMap.has(position.key)) {
        this.onElementStyleChange(position.styleKey, value);
      }
    });

    this.isPaddingValuesEqual = true;
    this.isPaddingSlidersExpanded = false;
  }

  public onVerticalPaddingChange(value): void {
    this.menuSetup.verticalPadding.onChange(value);

    this.afterChange();
  }

  public onVerticalPaddingReset(): void {
    this.menuSetup.verticalPadding.reset();

    this.afterChange();
  }

  public setIsPaddingValuesEqual(): void {
    const values = new Set();

    this.paddingPositions.forEach(position => {
      if (this.stylesSettingsService.optionsMap.has(position.key)) {
        values.add(this.stylePanel.element.styles[position.styleKey]);
      }
    });

    this.isPaddingValuesEqual = values.size === 1;

    if (this.isPaddingValuesEqual) this.paddingValue = values[0];
  }

  public setBlogTitleFontSize(value: string): void {
    this.blogSetup.postNamesFontSize.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogAddCommentButtonBackground(value: string): void {
    this.blogAddCommentButtonSetup.background.onChange(value);
    this.afterChange();
  }

  public setBlogAddCommentButtonTextColor(value: string): void {
    this.blogAddCommentButtonSetup.textColor.onChange(value);
    this.afterChange();
  }

  public setBlogAddCommentButtonHoverBackground(value: string): void {
    this.blogAddCommentButtonSetup.hoverBackground.onChange(value);
    this.afterChange();
  }

  public setBlogAddCommentButtonHoverTextColor(value: string): void {
    this.blogAddCommentButtonSetup.hoverTextColor.onChange(value);
    this.afterChange();
  }

  public setBlogAddCommentButtonBorderRadius(value: string): void {
    this.blogAddCommentButtonSetup.borderRadius.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogCommentsBackground(value: string): void {
    this.blogCommentsSetup.background.onChange(value);
    this.afterChange();
  }

  public setBlogCommentsTextColor(value: string): void {
    this.blogCommentsSetup.textColor.onChange(value);
    this.afterChange();
  }

  public setBlogCommentsFontSize(value: string): void {
    const fontSize: number = Number.parseInt(value);

    this.blogCommentsSetup.fontSize.onChange(fontSize);

    this.afterChange();

    const expectedLineHeight: number = this.blogCommentsSetup.fontSize.value * 1.5;
    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.blogCommentsSetup.lineHeight.onChange(newLineHeight);

    this.afterChange();
  }

  public setBlogCommentsLineHeight(value: string): void {
    this.blogCommentsSetup.lineHeight.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogCommentsBorderColor(value: string): void {
    this.blogCommentsSetup.borderColor.onChange(value);
    this.afterChange();
  }

  public setBlogCommentsSeparatorColor(value: string): void {
    this.blogCommentsSetup.separatorColor.onChange(value);
    this.afterChange();
  }

  public setBlogMarginBetweenComments(value: string): void {
    this.blogCommentsSetup.marginBetweenComments.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogMarginBetweenChildComments(value: string): void {
    this.blogCommentsSetup.marginBetweenChildComments.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogCommentsBorderRadius(value: string): void {
    this.blogCommentsSetup.borderRadius.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogCommentsReplyColor(value: string): void {
    this.blogCommentsSetup.replyColor.onChange(value);
    this.afterChange();
  }

  public setBlogCommentsReplyHoverColor(value: string): void {
    this.blogCommentsSetup.replyHoverColor.onChange(value);
    this.afterChange();
  }

  public setBlogCommentsReplyFontSize(value: string): void {
    this.blogCommentsSetup.replyFontSize.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogCommentsTimestampColor(value: string): void {
    this.blogCommentsSetup.timestampColor.onChange(value);
    this.afterChange();
  }

  public setBlogCommentsTimestampFontSize(value: string): void {
    this.blogCommentsSetup.timestampFontSize.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public setBlogCommentsUsernameColor(value: string): void {
    this.blogCommentsSetup.usernameColor.onChange(value);
    this.afterChange();
  }

  public setBlogCommentsUsernameFontSize(value: string): void {
    this.blogCommentsSetup.usernameFontSize.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public resetBlogTitleFontSize(): void {
    this.blogSetup.postNamesFontSize.reset();
    this.afterChange();
  }

  public resetBlogAddCommentButtonBackground(): void {
    this.blogAddCommentButtonSetup.background.reset();
    this.afterChange();
  }

  public resetBlogAddCommentButtonTextColor(): void {
    this.blogAddCommentButtonSetup.textColor.reset();
    this.afterChange();
  }

  public resetBlogAddCommentButtonHoverBackground(): void {
    this.blogAddCommentButtonSetup.hoverBackground.reset();
    this.afterChange();
  }

  public resetBlogAddCommentButtonHoverTextColor(): void {
    this.blogAddCommentButtonSetup.hoverTextColor.reset();
    this.afterChange();
  }

  public resetBlogAddCommentButtonBorderRadius(): void {
    this.blogAddCommentButtonSetup.borderRadius.reset();
    this.afterChange();
  }

  public resetBlogCommentsBackground(): void {
    this.blogCommentsSetup.background.reset();
    this.afterChange();
  }

  public resetBlogCommentsTextColor(): void {
    this.blogCommentsSetup.textColor.reset();
    this.afterChange();
  }

  public resetBlogCommentsFontSize(): void {
    this.blogCommentsSetup.fontSize.reset();
    this.blogCommentsSetup.lineHeight.reset();
    this.afterChange();
  }

  public resetBlogCommentsLineHeight(): void {
    this.blogCommentsSetup.lineHeight.reset();
    this.afterChange();
  }

  public resetBlogCommentsBorderColor(): void {
    this.blogCommentsSetup.borderColor.reset();
    this.afterChange();
  }

  public resetBlogCommentsSeparatorColor(): void {
    this.blogCommentsSetup.separatorColor.reset();
    this.afterChange();
  }

  public resetBlogMarginBetweenComments(): void {
    this.blogCommentsSetup.marginBetweenComments.reset();
    this.afterChange();
  }

  public resetBlogMarginBetweenChildComments(): void {
    this.blogCommentsSetup.marginBetweenChildComments.reset();
    this.afterChange();
  }

  public resetBlogCommentsBorderRadius(): void {
    this.blogCommentsSetup.borderRadius.reset();
    this.afterChange();
  }

  public resetBlogCommentsReplyColor(): void {
    this.blogCommentsSetup.replyColor.reset();
    this.afterChange();
  }

  public resetBlogCommentsReplyHoverColor(): void {
    this.blogCommentsSetup.replyHoverColor.reset();
    this.afterChange();
  }

  public resetBlogCommentsReplyFontSize(): void {
    this.blogCommentsSetup.replyFontSize.reset();
    this.afterChange();
  }

  public resetBlogCommentsTimestampColor(): void {
    this.blogCommentsSetup.timestampColor.reset();
    this.afterChange();
  }

  public resetBlogCommentsTimestampFontSize(): void {
    this.blogCommentsSetup.timestampFontSize.reset();
    this.afterChange();
  }

  public resetBlogCommentsUsernameColor(): void {
    this.blogCommentsSetup.usernameColor.reset();
    this.afterChange();
  }

  public resetBlogCommentsUsernameFontSize(): void {
    this.blogCommentsSetup.usernameFontSize.reset();
    this.afterChange();
  }

  public resetMobileFontSize(): void {
    this.customSetup.mobileFontSize.reset();

    this.afterChange();
  }

  public resetMobileLetterSpacing(): void {
    this.customSetup.mobileLetterSpacing.reset();

    this.afterChange();
  }

  public resetMobileLineHeight(): void {
    this.customSetup.mobileLineHeight.reset();

    this.afterChange();
  }

  public resetDesktopFontSize(): void {
    this.customSetup.desktopFontSize.reset();

    this.afterChange();
  }

  public resetDesktopLetterSpacing(): void {
    this.customSetup.desktopLetterSpacing.reset();

    this.afterChange();
  }

  public resetDesktopLineHeight(): void {
    this.customSetup.desktopLineHeight.reset();

    this.afterChange();
  }

  public onLinkColorChange(value: string): void {
    this.customSetup.linkColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.linkColor);

    this.afterChange();
  }

  public resetLinkColor(): void {
    this.customSetup.linkColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.linkColor);

    this.afterChange();
  }

  public onButtonTextColorChange(value: string): void {
    this.customSetup.buttonTextColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.buttonTextColor);

    this.afterChange();
  }

  public resetButtonTextColor(): void {
    this.customSetup.buttonTextColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.buttonTextColor);

    this.afterChange();
  }

  public onPortfolioZoomIconColorChange(value: string): void {
    this.customSetup.portfolioZoomIconColor.onChange(value);
    this.afterChange();
  }

  public onPortfolioZoomIconColorReset(): void {
    this.customSetup.portfolioZoomIconColor.reset();
    this.afterChange();
  }

  public onHamburgerColorChange(value: string): void {
    this.customSetup.hamburgerColor.setValue(value);
    this.afterChange();
  }

  public onHamburgerColorReset(): void {
    this.customSetup.hamburgerColor.reset();
    this.afterChange();
  }

  public onButtonBackgroundColorChange(value: string): void {
    this.customSetup.buttonBackgroundColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.buttonBackgroundColor);

    this.afterChange();
  }

  public resetButtonBackgroundColor(): void {
    this.customSetup.buttonBackgroundColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.buttonBackgroundColor);

    this.afterChange();
  }

  public onBlogPostLinksColorChange(value: string) {
    this.blogSetup.postLinksColor.onChange(value);
    this.afterChange();
  }

  public resetBlogPostLinksColor() {
    this.blogSetup.postLinksColor.reset();
    this.afterChange();
  }

  public onBlogSocialNetworksColorChange(value: string) {
    this.blogSetup.socialNetworksColor.onChange(value);
    this.afterChange();
  }

  public resetBlogSocialNetworksColor() {
    this.blogSetup.socialNetworksColor.reset();
    this.afterChange();
  }

  public onBlogTopBarBackgroundColorChange(value: string) {
    this.blogSetup.topBarBackgroundColor.onChange(value);
    this.afterChange();
  }

  public resetBlogTopBarBackgroundColor() {
    this.blogSetup.topBarBackgroundColor.reset();
    this.afterChange();
  }

  public onBlogTopBarFontColorChange(value: string) {
    this.blogSetup.topBarFontColor.onChange(value);
    this.afterChange();
  }

  public resetBlogTopBarFontColor() {
    this.blogSetup.topBarFontColor.reset();
    this.afterChange();
  }

  public onBlogSocialIconsChange(value: SelectOption[]): void {
    this.blogSetup.socialIcons.onChange(value);

    this.eventsService.dispatchBlogReload(this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public resetBlogSocialIcons(): void {
    this.blogSetup.socialIcons.reset();

    this.eventsService.dispatchBlogReload(this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public onBlogPostTagsColorChange(value: string) {
    this.blogSetup.postTagsColor.onChange(value);
    this.afterChange();
  }

  public resetBlogPostTagsColor() {
    this.blogSetup.postTagsColor.reset();
    this.afterChange();
  }

  public onBlogPostTagsBackgroundChange(value: string) {
    this.blogSetup.postTagsBackground.onChange(value);
    this.afterChange();
  }

  public resetBlogPostTagsBackground() {
    this.blogSetup.postTagsBackground.reset();
    this.afterChange();
  }

  public onPortfolioIntroBackgroundColorChange(value: string): void {
    this.customSetup.portfolioIntroBackgroundColor.setValue(value);
    this.afterChange();
  }

  public onPortfolioIntroBackgroundColorReset(): void {
    this.customSetup.portfolioIntroBackgroundColor.reset();
    this.afterChange();
  }

  public onElementBackgroundColorChange(value: string): void {
    this.customSetup.elementBackgroundColor.setValue(value);
    this.afterChange();
  }

  public onElementBackgroundColorReset(): void {
    this.customSetup.elementBackgroundColor.reset();
    this.afterChange();
  }

  public onSpaceBetweenColumnsChange(value: string): void {
    this.customSetup.spaceBetweenColumns.setValue(value);
    this.afterChange();
  }

  public onSpaceBetweenColumnsReset(): void {
    this.customSetup.spaceBetweenColumns.reset();
    this.afterChange();
  }

  public onMasonryTypeChange(value: string): void {
    this.customSetup.masonryType.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.masonryType);

    this.initDynamicNumberOfColumns();

    this.afterChange();

    this.customSetup.numberOfColumns.initLabel();
  }

  private initDynamicNumberOfColumns(): void {
    if (!this.isPortfolioIndexMasonry) return;

    this.onNumberOfColumnsChange(`${this.customSetup.masonryType.value === MASONRY_TYPE_KEYS.HORIZONTAL ? 6 : 3}`);
  }

  public onMasonryTypeReset(): void {
    this.customSetup.masonryType.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.masonryType);

    this.afterChange();

    this.customSetup.numberOfColumns.initLabel();
  }

  public onGridGapChange(value: string): void {
    const target = this.element[0];
    const key = this.customSetup.gridGap.key;

    this.customSetup.gridGap.setValue(value);

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.gridGap.value });

    this.afterChange();
  }

  public onGridGapReset(): void {
    const target = this.element[0];
    const key = this.customSetup.gridGap.key;

    this.customSetup.gridGap.reset();

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.gridGap.value });

    this.afterChange();
  }

  public onNumberOfColumnsChange(value: string): void {
    const target = this.element[0];
    const key = this.customSetup.numberOfColumns.key;

    this.customSetup.numberOfColumns.setValue(value);

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.numberOfColumns.value });

    this.afterChange();
  }

  public onNumberOfColumnsReset(): void {
    const target = this.element[0];
    const key = this.customSetup.numberOfColumns.key;

    this.customSetup.numberOfColumns.reset();

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.numberOfColumns.value });

    this.afterChange();
  }

  public onTitleSpacingChange(value: string): void {
    const target = this.element[0];
    const key = this.customSetup.titleSpacing.key;

    this.customSetup.titleSpacing.setValue(value);

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.titleSpacing.value });

    this.afterChange();
  }

  public onTitleSpacingReset(): void {
    const target = this.element[0];
    const key = this.customSetup.titleSpacing.key;

    this.customSetup.titleSpacing.reset();

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.titleSpacing.value });

    this.afterChange();
  }

  public onBlockCenteringChange(value: boolean): void {
    const target = this.element[0];
    const key = this.customSetup.isBlockCentered.key;

    this.customSetup.isBlockCentered.onChange(value);

    this.afterChange();

    // just for padding
    this.parseElement();

    this.cdr.detectChanges();

    this.dispatchCustomStyleChangedEvent({ target, key, value: this.customSetup.isBlockCentered.value });
  }

  public onPortfolioIntroBackgroundTransparencyChange(value: string): void {
    this.customSetup.portfolioIntroBackgroundTransparency.setValue(value);

    this.afterChange();
  }

  public onPortfolioIntroBackgroundTransparencyReset(): void {
    this.customSetup.portfolioIntroBackgroundTransparency.reset();

    this.afterChange();
  }

  public onElementBackgroundTransparencyChange(value: string): void {
    this.customSetup.elementBackgroundTransparency.setValue(value);

    this.afterChange();
  }

  public onElementBackgroundTransparencyReset(): void {
    this.customSetup.elementBackgroundTransparency.reset();

    this.afterChange();
  }

  public onThumbDrawerBackgroundColorChange(value: string): void {
    this.customSetup.thumbDrawerBackground.setValue(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerBackground);

    this.afterChange();
  }

  public onThumbDrawerBackgroundColorReset(): void {
    this.customSetup.thumbDrawerBackground.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.thumbDrawerBackground);

    this.afterChange();
  }

  public onThumbDrawerBackgroundTransparencyChange(value: string): void {
    this.customSetup.thumbDrawerBackgroundTransparency.setValue(value);

    this.afterChange();
  }

  public onThumbDrawerBackgroundTransparencyReset(): void {
    this.customSetup.thumbDrawerBackgroundTransparency.reset();

    this.afterChange();
  }

  public onPortfolioSlideThumbBackgroundColorChange(value: string): void {
    this.customSetup.portfolioSlideThumbBackgroundColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbBackgroundColor);

    this.afterChange();
  }

  public onPortfolioSlideThumbBackgroundColorReset(): void {
    this.customSetup.portfolioSlideThumbBackgroundColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbBackgroundColor);

    this.afterChange();
  }

  public onPortfolioSlideThumbTextColorChange(value: string): void {
    this.customSetup.portfolioSlideThumbTextColor.setValue(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbTextColor);

    this.afterChange();
  }

  public onPortfolioSlideThumbTextColorReset(): void {
    this.customSetup.portfolioSlideThumbTextColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbTextColor);

    this.afterChange();
  }

  public onPortfolioSlideThumbFontFamilyChange(font: GoogleFontModel): void {
    this.customStylesService.onPortfolioSlideThumbFontFamilyChange(font);

    this.googleFontsService.addFontLink(font);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbFontFamily);

    this.afterChange();
  }

  public onPortfolioSlideThumbFontFamilyReset(): void {
    this.customSetup.portfolioSlideThumbFontFamily.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbFontFamily);

    this.afterChange();
  }

  public onPortfolioSlideThumbFontSizeChange(value: string): void {
    const fontSize: number = Number.parseInt(value);

    this.customSetup.portfolioSlideThumbFontSize.onChange(fontSize);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbFontSize);

    this.afterChange();

    const expectedLineHeight: number = fontSize * 1.5;
    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.onPortfolioSlideThumbLineHeightChange(`${newLineHeight}`);
  }

  public onPortfolioSlideThumbFontSizeReset(): void {
    this.customSetup.portfolioSlideThumbFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbFontSize);

    this.afterChange();
  }

  public onPortfolioSlideThumbFontWeightChange(value: string): void {
    this.customSetup.portfolioSlideThumbFontWeight.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbFontWeight);

    this.afterChange();
  }

  public onPortfolioSlideThumbFontWeightReset(): void {
    this.customSetup.portfolioSlideThumbFontWeight.reset();

    this.customStylesService.initPortfolioSlideThumbFontWeight();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbFontWeight);

    this.afterChange();
  }

  public onPortfolioSlideThumbLineHeightChange(value: string): void {
    this.customSetup.portfolioSlideThumbLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbLineHeight);

    this.afterChange();
  }

  public onPortfolioSlideThumbLineHeightReset(): void {
    this.customSetup.portfolioSlideThumbLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbLineHeight);

    this.afterChange();
  }

  public onPortfolioSlideThumbLetterSpacingChange(value: string): void {
    this.customSetup.portfolioSlideThumbLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbLetterSpacing);

    this.afterChange();
  }

  public onPortfolioSlideThumbLetterSpacingReset(): void {
    this.customSetup.portfolioSlideThumbLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideThumbLetterSpacing);

    this.afterChange();
  }

  public onThumbDrawerTextColorChange(value: string): void {
    this.customSetup.thumbDrawerTextColor.setValue(value);

    this.afterChange();
  }

  public onThumbDrawerTextColorReset(): void {
    this.customSetup.thumbDrawerTextColor.reset();

    this.afterChange();
  }

  public onThumbDrawerThumbsPositionChange(value: string): void {
    this.customSetup.thumbDrawerThumbsPosition.setValue(value);

    this.afterChange();
  }

  public onThumbDrawerThumbsPositionReset(): void {
    this.customSetup.thumbDrawerThumbsPosition.reset();

    this.afterChange();
  }

  public onOverlayColorChange(value: string): void {
    this.customSetup.overlayColor.setValue(value);

    this.afterChange();
  }

  public onOverlayColorReset(): void {
    this.customSetup.overlayColor.reset();

    this.afterChange();
  }

  public onOverlayBackgroundTransparencyChange(value: string): void {
    this.customSetup.overlayBackgroundTransparency.setValue(value);

    this.afterChange();
  }

  public onOverlayBackgroundTransparencyReset(): void {
    this.customSetup.overlayBackgroundTransparency.reset();

    this.afterChange();
  }

  public onImageOverlayTextColorChange(value: string): void {
    this.customSetup.imageOverlayTextColor.onChange(value);
    
    this.afterChange();
  }

  public onImageOverlayTextColorReset(): void {
    this.customSetup.imageOverlayTextColor.reset();

    this.afterChange();
  }

  public onImageOverlayBackgroundColorChange(value: string): void {
    this.customSetup.imageOverlayBackgroundColor.onChange(value);

    this.afterChange();
  }

  public onImageOverlayBackgroundColorReset(): void {
    this.customSetup.imageOverlayBackgroundColor.reset();

    this.afterChange();
  }

  public onImageOverlayBackgroundTransparencyChange(value: string): void {
    this.customSetup.imageOverlayBackgroundTransparency.setValue(value);

    this.afterChange();
  }

  public onImageOverlayBackgroundTransparencyReset(): void {
    this.customSetup.imageOverlayBackgroundTransparency.reset();

    this.afterChange();
  }



  public onThumbOverlayBackgroundColorChange(value: string): void {
    this.customSetup.thumbOverlayBackgroundColor.onChange(value);
    this.afterChange();
  }

  public onThumbOverlayBackgroundColorReset(): void {
    this.customSetup.thumbOverlayBackgroundColor.reset();
    this.afterChange();
  }

  public onThumbOverlayBackgroundTransparencyChange(value: string): void {
    this.customSetup.thumbOverlayBackgroundTransparency.setValue(value);
    this.afterChange();
  }

  public onThumbOverlayBackgroundTransparencyReset(): void {
    this.customSetup.thumbOverlayBackgroundTransparency.reset();
    this.afterChange();
  }



  public onImageTransparencyChange(value: string): void {
    this.customSetup.imageTransparency.setValue(value);
    this.afterChange();
  }

  public onImageTransparencyReset(): void {
    this.customSetup.imageTransparency.reset();
    this.afterChange();
  }

  public onTransparencyChange(value: string): void {
    this.nativeSetup.transparency.onChange(value);
    this.afterChange();
  }

  public onTransparencyReset(): void {
    this.nativeSetup.transparency.reset();
    this.afterChange();
  }

  public onEnlargementIconColorChange(value): void {
    this.customSetup.enlargementIconColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.enlargementIconColor);

    this.afterChange();
  }

  public onEnlargementIconColorReset(): void {
    this.customSetup.enlargementIconColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.enlargementIconColor);

    this.afterChange();
  }

  public onEnlargementIconHoverColorChange(value): void {
    this.customSetup.enlargementIconHoverColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.enlargementIconHoverColor);

    this.afterChange();
  }

  public onEnlargementIconHoverColorReset(): void {
    this.customSetup.enlargementIconHoverColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.enlargementIconHoverColor);
    
    this.afterChange();
  }

  public onPortfolioZoomBackgroundChange(value): void {
    this.customSetup.portfolioZoomBackground.onChange(value);

    this.afterChange();
  }

  public onPortfolioZoomBackgroundReset(): void {
    this.customSetup.portfolioZoomBackground.reset();

    this.afterChange();
  }

  public onFontSelect(font: GoogleFontModel): void {
    this.nativeStylesService.onFontFamilyChange(font);
    this.googleFontsService.addFontLink(font);
    this.attributesService.addAttributeToSave({ element: this.nativeStylesService.element, attributeToSave: 'data-used-font' });

    this.customSetup.portfolioImageTitleFontFamily.initValue();
    this.customSetup.thumbDrawerFontFamily.initValue();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.fontFamily);

    this.afterChange();
  }

  public onFontRevert(): void {
    this.nativeSetup.fontFamily.reset();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.fontFamily);

    this.afterChange();
  }

  public onFontWeightChange(value: string): void {
    this.nativeSetup.fontWeight.onChange(value);

    if (this.editingType === ELEM_TYPES.DIVIDER) {
      this.stylesService.stylePanel[ELEM_TYPES.DIVIDER].styles.fontWeight.value = this.nativeSetup.fontWeight.value;
    }

    this.afterChange();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.fontWeight);
  }

  public onTextTransformChange(value: string): void {
    this.nativeSetup.textTransform.onChange(value);

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.textTransform);

    this.afterChange();
  }

  public onTextTransformRevert(): void {
    this.nativeSetup.textTransform.reset();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.textTransform);

    this.afterChange();
  }

  public onTextAlignmentChange(value: string): void {
    this.nativeSetup.textAlignment.onChange(value);

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.textAlignment);

    this.afterChange();
  }

  public onTextAlignmentRevert(): void {
    this.nativeSetup.textAlignment.reset();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.textAlignment);

    this.afterChange();
  }

  public onTextDecorationChange(value: string): void {
    this.nativeSetup.textDecoration.onChange(value);

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.textDecoration);

    this.afterChange();
  }

  public onTextDecorationRevert(): void {
    this.nativeSetup.textDecoration.reset();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.textDecoration);

    this.afterChange();
  }

  public onFontSizeRevert(): void {
    this.nativeSetup.fontSize.reset();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.fontSize);

    this.resetStyle('line-height');
  }

  public onFontWeightRevert(): void {
    this.nativeSetup.fontWeight.reset();

    this.nativeStylesService.initFontWeight();

    this.afterChange();

    this.dispatchStyleChangedEventForNativeOption(this.nativeSetup.fontWeight);
  }

  public onArrowTransparencyChange(value: string): void {
    this.customSetup.arrowBackgroundTransparency.setValue(value);
    this.afterChange();
  }

  public onArrowTransparencyReset(): void {
    this.customSetup.arrowBackgroundTransparency.reset();
    this.afterChange();
  }

  public openPortfolioPickerModal() {
    this.portfolioPickerModalService.show(this.element);
  }

  public onBlockWidthTypeSelect(option: SelectOption): void {
    this.customSetup.blockWidthType.onChange(option.value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.blockWidthType);

    this.afterChange();
  }

  public onBlockWidthTypeReset(): void {
    this.customSetup.blockWidthType.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.blockWidthType);

    this.afterChange();
  }

  public onVideoControlsChange(value: boolean): void {
    this.customSetup.videoControls.onChange(value);
    this.afterChange();
  }

  public onVideoPreloadChange(value: boolean): void {
    this.customSetup.videoPreload.onChange(value);

    this.afterChange();
  }

  public onVideoAutoplayChange(value: boolean): void {
    this.customSetup.videoAutoplay.onChange(value);

    if (value) this.customSetup.videoMuted.onChange(true);
    if (value) this.customSetup.slideshowVideoMuted.onChange(true);

    this.afterChange();
  }

  public onVideoLoopChange(value: boolean): void {
    this.customSetup.videoLoop.onChange(value);
    this.afterChange();
  }

  public onVideoMutedChange(value: boolean): void {
    this.customSetup.videoMuted.onChange(value);

    this.afterChange();
  }

  public onSlideshowVideoMutedChange(value: boolean): void {
    this.customSetup.slideshowVideoMuted.onChange(value);

    this.afterChange();
  }

  public onVideoDownloadChange(value: boolean): void {
    this.customSetup.videoDownload.onChange(value);
    this.afterChange();
  }

  public onVideoThumbnailChange(value: string): void {
    this.customSetup.videoThumbnail.onChange(value);
    this.afterChange();
  }

  public onVideoEmbedControlsChange(value: boolean): void {
    this.customSetup.videoEmbedControls.onChange(value);
    this.afterChange();
  }

  public onVideoEmbedAutoplayChange(value: boolean): void {
    this.customSetup.videoEmbedAutoplay.onChange(value);

    if (value) this.customSetup.videoEmbedMuted.onChange(true);

    this.afterChange();
  }

  public onVideoEmbedMutedChange(value: boolean): void {
    this.customSetup.videoEmbedMuted.onChange(value);
    this.afterChange();
  }

  public onVideoEmbedLoopChange(value: boolean): void {
    this.customSetup.videoEmbedLoop.onChange(value);
    this.afterChange();
  }

  public onVideoUrlChange(e: KeyboardEvent): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;

    const embedData: VideoEmbedDataModel = this.videoEmbedService.getEmbedCode(null, target.value);

    if (embedData) return this.initEmbed(embedData);

    this.customSetup.videoUrl.onChange(target.value);
    this.customSetup.videoPoster.onChange(target.value);

    this.afterChange();
  }

  public onVideoEmbedChange(e: KeyboardEvent): void {
    const target: HTMLTextAreaElement = <HTMLTextAreaElement>e.target;

    this.customSetup.videoEmbed.onChange(target.value);

    const tempElement: HTMLElement = document.createElement('div');

    tempElement.innerHTML = target.value;

    const iframe: HTMLIFrameElement = <HTMLIFrameElement>tempElement.querySelector('iframe');

    if (!iframe) return;

    const embedData: VideoEmbedDataModel = this.videoEmbedService.getEmbedCode(iframe.outerHTML, iframe.src);

    this.initEmbed(embedData);

    this.afterChange();
  }

  private initEmbed(videoData: VideoEmbedDataModel): void {
    if (!videoData.html) {
      this.customSetup.videoEmbed.onChange('');
      this.customSetup.videoUrl.onChange('');
      return;
    }

    const data = {
      key: videoData.key,
      element: this.customSetup.videoEmbed.videoElement,
      html: videoData.html,
    };

    this.eventsService.dispatchVideoEmbedInsert(data, this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public toggleIsVideoUrlCollapsed(): void {
    this.isVideoUrlCollapsed = !this.isVideoUrlCollapsed;
  }

  public toggleIsEmbedVideoCollapsed(): void {
    this.isEmbedVideoCollapsed = !this.isEmbedVideoCollapsed;
  }

  public openAudioManagerModal(): void {
    this.audioManagerService.open();
  }

  public onAudioAutoplayChange(value: boolean): void {
    this.customSetup.audioAutoplay.onChange(value);
    this.afterChange();
  }

  public onAudioLoopChange(value: boolean): void {
    this.customSetup.audioLoop.onChange(value);
    this.afterChange();
  }

  public onAudioMutedChange(value: boolean): void {
    this.customSetup.audioMuted.onChange(value);
    this.afterChange();
  }

  public onMobileFontSizeChange(value: string): void {
    const fontSize: number = Number.parseInt(value);

    this.customSetup.mobileFontSize.onChange(fontSize);

    this.afterChange();

    const expectedLineHeight: number = fontSize * 1.5;
    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.onMobileLineHeightChange(`${newLineHeight}`);
  }

  public onMobileLetterSpacingChange(value: string): void {
    this.customSetup.mobileLetterSpacing.onChange(Number.parseFloat(value));
    this.afterChange();
  }

  public onMobileLineHeightChange(value: string): void {
    this.customSetup.mobileLineHeight.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public onDesktopFontSizeChange(value: string): void {
    const fontSize: number = Number.parseInt(value);

    this.customSetup.desktopFontSize.onChange(fontSize);

    this.afterChange();

    const expectedLineHeight: number = fontSize * 1.5;
    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.onDesktopLineHeightChange(`${newLineHeight}`);
  }

  public onDesktopLetterSpacingChange(value: string): void {
    this.customSetup.desktopLetterSpacing.onChange(Number.parseFloat(value));
    this.afterChange();
  }

  public onDesktopLineHeightChange(value: string): void {
    this.customSetup.desktopLineHeight.onChange(Number.parseInt(value));
    this.afterChange();
  }

  public onEnlargementPaddingChange({ key, value }: { key?: string, value: string }): void {
    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: this.customSetup.enlargementPadding.key,
      value,
    });

    this.afterChange();
  }

  public onEnlargementPaddingReset(): void {
    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: this.customSetup.enlargementPadding.key,
      value: 0,
    });

    this.afterChange();
  }

  public onPaddingVwChange({ key, value }: { key?: string, value: string }): void {
    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: this.customSetup.paddingVw.key,
      value,
    });

    this.afterChange();
  }

  public onPaddingVwReset(): void {
    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: this.customSetup.paddingVw.key,
      value: 0,
    });

    this.afterChange();
  }

  public onPaddingDropdownClick() {
    this.isPaddingSlidersExpanded = !this.isPaddingSlidersExpanded;

    this.cdr.detectChanges();
  }

  public onBorderStyleChange(value: string): void {
    this.onElementStyleChange('border-style', value);

    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: 'border-style',
      value,
    });

    this.afterChange();
  }

  public onBorderWidthChange(value: string): void {
    this.onElementStyleChange('border-width', value);

    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: 'border-width',
      value,
    });

    this.afterChange();
  }

  public onBorderWidthReset(): void {
    this.resetStyle('border-width');

    this.eventsService.dispatchStyleChangedEvent({
      target: this.element[0],
      key: null,
      styleName: 'border-width',
      value: window.getComputedStyle(this.element[0]).getPropertyValue('border-width'),
    }, this.iFrameService.sandboxWindow);

    this.afterChange();
  }

  public onMenuLogoPaddingChange({ key, value }: { key?: string, value: string }): void {
    this.dispatchCustomStyleChangedEvent({
      target: this.element[0],
      key: this.menuSetup.logoPadding.key,
      value,
    });

    this.afterChange();
  }

  public onMenuLogoPaddingReset(): void {
    this.afterChange();
  }

  public onMenuSizeReducedOnScrollChange(value: boolean): void {
    this.menuSetup.isSizeReducedOnScroll.setValue(value);

    this.afterChange();
  }

  public onChangeLogoClick(): void {
    this.logoUploadService.onLogoClick(new CustomEvent('click', {
      detail: {
        element: this.element[0],
      },
    }));
  }

  public onTitleAndYearVisibilityBelowImageChange(value: boolean): void {
    this.customSetup.isTitleAndYearVisibleBelowImage.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isTitleAndYearVisibleBelowImage);

    this.afterChange();
  }

  public onPortfolioImageTitlePositionChange(option: SelectOption): void {
    this.customSetup.portfolioImageTitlePosition.onChange(option.value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitlePosition);

    this.afterChange();
  }

  public onPortfolioImageTitlePositionReset(): void {
    this.customSetup.portfolioImageTitlePosition.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitlePosition);

    this.afterChange();
  }

  public onPortfolioImageTitleColorChange(value: string): void {
    this.customSetup.portfolioImageTitleColor.onChange(value);

    this.afterChange();
  }

  public onPortfolioImageTitleColorReset(): void {
    this.customSetup.portfolioImageTitleColor.reset();

    this.afterChange();
  }

  public onPortfolioImageTitleFontChange(font: GoogleFontModel): void {
    this.customStylesService.onPortfolioImageTitleFontFamilyChange(font);

    this.googleFontsService.addFontLink(font);

    this.afterChange();
  }

  public onPortfolioImageTitleFontReset(): void {
    this.customSetup.portfolioImageTitleFontFamily.reset();

    this.afterChange();
  }

  public onPortfolioImageTitleFontWeightChange(value: string): void {
    this.customSetup.portfolioImageTitleFontWeight.onChange(value);

    this.afterChange();
  }

  public onPortfolioImageTitleFontWeightReset(): void {
    this.customSetup.portfolioImageTitleFontWeight.reset();

    this.customStylesService.initPortfolioImageTitleFontWeight();

    this.afterChange();
  }

  public onPortfolioImageTitleFontSizeChange(fontSize: string): void {
    this.customSetup.portfolioImageTitleFontSize.onChange(Number.parseInt(fontSize));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleFontSize);

    this.afterChange();

    const expectedLineHeight: number = this.customSetup.portfolioImageTitleFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.onPortfolioImageTitleLineHeightChange(`${newLineHeight}`);
  }

  public onPortfolioImageTitleFontSizeReset(): void {
    this.customSetup.portfolioImageTitleFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleFontSize);

    this.afterChange();

    const expectedLineHeight: number = this.customSetup.portfolioImageTitleFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.onPortfolioImageTitleLineHeightChange(`${newLineHeight}`);
  }

  public onPortfolioImageTitleTextTransformChange(value: string): void {
    this.customSetup.portfolioImageTitleTextTransform.onChange(value);

    this.afterChange();
  }

  public onPortfolioImageTitleTextTransformReset(): void {
    this.customSetup.portfolioImageTitleTextTransform.reset();

    this.afterChange();
  }

  public onPortfolioImageTitleTextDecorationChange(value: string): void {
    this.customSetup.portfolioImageTitleTextDecoration.onChange(value);

    this.afterChange();
  }

  public onPortfolioImageTitleTextDecorationReset(): void {
    this.customSetup.portfolioImageTitleTextDecoration.reset();

    this.afterChange();
  }

  public onPortfolioImageTitleLineHeightChange(value: string): void {
    this.customSetup.portfolioImageTitleLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleLineHeight);

    this.afterChange();
  }

  public onPortfolioImageTitleLineHeightReset(): void {
    this.customSetup.portfolioImageTitleLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleLineHeight);

    this.afterChange();
  }

  public onPortfolioImageTitleLetterSpacingChange(value: string): void {
    this.customSetup.portfolioImageTitleLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleLetterSpacing);

    this.afterChange();
  }

  public onPortfolioImageTitleLetterSpacingReset(): void {
    this.customSetup.portfolioImageTitleLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleLetterSpacing);

    this.afterChange();
  }

  public onPortfolioImageTitleWordSpacingChange(value: string): void {
    this.customSetup.portfolioImageTitleWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleWordSpacing);

    this.afterChange();
  }

  public onPortfolioImageTitleWordSpacingReset(): void {
    this.customSetup.portfolioImageTitleWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioImageTitleWordSpacing);

    this.afterChange();
  }

  public onPortfolioEnlargementBackgroundColorChange(value: string): void {
    this.customSetup.portfolioEnlargementBackground.onChange(value);

    this.customSetup.portfolioEnlargementBackground.element.setAttribute(IS_PORTFOLIO_ENLARGEMENT_BACKGROUND_SET_ATTRIBUTE, 'true');

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementBackground);

    this.afterChange();
  }

  public onPortfolioEnlargementBackgroundColorReset(): void {
    this.customSetup.portfolioEnlargementBackground.reset();

    this.customSetup.portfolioEnlargementBackground.element.setAttribute(IS_PORTFOLIO_ENLARGEMENT_BACKGROUND_SET_ATTRIBUTE, 'false');

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementBackground);

    this.afterChange();
  }

  public onPortfolioEnlargementBackgroundTransparencyChange(value: string): void {
    this.customSetup.portfolioEnlargementBackgroundTransparency.setValue(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementBackgroundTransparency);

    this.afterChange();
  }

  public onPortfolioEnlargementBackgroundTransparencyReset(): void {
    this.customSetup.portfolioEnlargementBackgroundTransparency.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementBackgroundTransparency);

    this.afterChange();
  }

  public onPortfolioEnlargementImageTransitionChange(value: string): void {
    this.customSetup.portfolioEnlargementImageTransition.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementImageTransition);

    this.afterChange();
  }

  public onPortfolioEnlargementImageTransitionDurationChange(value) {
    this.customSetup.portfolioEnlargementImageTransitionDuration.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementImageTransitionDuration);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleAndYearVisibilityBelowImageChange(value: boolean): void {
    this.customSetup.isPortfolioEnlargementTitleAndYearVisibleBelowImage.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.isPortfolioEnlargementTitleAndYearVisibleBelowImage);

    this.afterChange();
  }

  public onPortfolioEnlargementTitlePositionChange(option: SelectOption): void {
    this.customSetup.portfolioEnlargementTitlePosition.onChange(option.value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitlePosition);

    this.afterChange();
  }

  public onPortfolioEnlargementTitlePositionReset(): void {
    this.customSetup.portfolioEnlargementTitlePosition.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitlePosition);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleColorChange(value: string): void {
    this.customSetup.portfolioEnlargementTitleColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleColor);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleColorReset(): void {
    this.customSetup.portfolioEnlargementTitleColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleColor);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleFontChange(font: GoogleFontModel): void {
    this.customStylesService.onPortfolioEnlargementTitleFontFamilyChange(font);

    this.googleFontsService.addFontLink(font);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleFontFamily);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleFontReset(): void {
    this.customSetup.portfolioEnlargementTitleFontFamily.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleFontFamily);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleFontWeightChange(value: string): void {
    this.customSetup.portfolioEnlargementTitleFontWeight.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleFontWeight);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleFontWeightReset(): void {
    this.customSetup.portfolioEnlargementTitleFontWeight.reset();

    this.customStylesService.initPortfolioEnlargementTitleFontWeight();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleFontWeight);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleFontSizeChange(fontSize: string): void {
    this.customSetup.portfolioEnlargementTitleFontSize.onChange(Number.parseInt(fontSize));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleFontSize);

    this.afterChange();

    const expectedLineHeight: number = this.customSetup.portfolioEnlargementTitleFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.onPortfolioEnlargementTitleLineHeightChange(`${newLineHeight}`);
  }

  public onPortfolioEnlargementTitleFontSizeReset(): void {
    this.customSetup.portfolioEnlargementTitleFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleFontSize);

    this.afterChange();

    const expectedLineHeight: number = this.customSetup.portfolioEnlargementTitleFontSize.value;

    const newLineHeight: number = expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;

    this.onPortfolioEnlargementTitleLineHeightChange(`${newLineHeight}`);
  }

  public onPortfolioEnlargementTitleTextTransformChange(value: string): void {
    this.customSetup.portfolioEnlargementTitleTextTransform.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleTextTransform);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleTextTransformReset(): void {
    this.customSetup.portfolioEnlargementTitleTextTransform.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleTextTransform);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleTextDecorationChange(value: string): void {
    this.customSetup.portfolioEnlargementTitleTextDecoration.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleTextDecoration);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleTextDecorationReset(): void {
    this.customSetup.portfolioEnlargementTitleTextDecoration.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleTextDecoration);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleLineHeightChange(value: string): void {
    this.customSetup.portfolioEnlargementTitleLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleLineHeight);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleLineHeightReset(): void {
    this.customSetup.portfolioEnlargementTitleLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleLineHeight);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleLetterSpacingChange(value: string): void {
    this.customSetup.portfolioEnlargementTitleLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleLetterSpacing);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleLetterSpacingReset(): void {
    this.customSetup.portfolioEnlargementTitleLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleLetterSpacing);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleWordSpacingChange(value: string): void {
    this.customSetup.portfolioEnlargementTitleWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleWordSpacing);

    this.afterChange();
  }

  public onPortfolioEnlargementTitleWordSpacingReset(): void {
    this.customSetup.portfolioEnlargementTitleWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioEnlargementTitleWordSpacing);

    this.afterChange();
  }

  public onSlideshowSlideDurationChange(value: number): void {
    this.customSetup.slideshowSlideDuration.onChange(value);

    this.afterChange();
  }

  public onSlideshowSlideDurationReset(): void {
    this.customSetup.slideshowSlideDuration.reset();

    this.afterChange();
  }

  public onAutoplaySlideDurationChange(value: number): void {
    this.customSetup.autoplaySlideDuration.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.autoplaySlideDuration);
  }

  public onAutoplaySlideDurationReset(): void {
    this.customSetup.autoplaySlideDuration.reset();

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.autoplaySlideDuration);
  }

  public onPortfolioSlideDurationChange(value: number): void {
    this.customSetup.portfolioSlideDuration.onChange(value);

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideDuration);
  }

  public onPortfolioSlideDurationReset(): void {
    this.customSetup.portfolioSlideDuration.reset();

    this.afterChange();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.portfolioSlideDuration);
  }

  public onPortfolioMobileAlbumViewChange(value: string): void {
    this.customSetup.mobileAlbumViewSetup.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.mobileAlbumViewSetup);

    this.afterChange();
  }

  public onPortfolioMobileAlbumViewReset(): void {
    this.customSetup.mobileAlbumViewSetup.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.mobileAlbumViewSetup);

    this.afterChange();
  }



  public onScalableFontSizeChange(value: string): void {
    this.customSetup.scalableFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableFontSize);

    this.afterChange();

    this.onScalableLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableFontSize)}`);
  }

  public onScalableFontSizeReset(): void {
    this.customSetup.scalableFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableFontSize);

    this.afterChange();

    this.onScalableLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableFontSize)}`);
  }

  public onScalableLineHeightChange(value: string): void {
    this.customSetup.scalableLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableLineHeight);

    this.afterChange();
  }

  public onScalableLineHeightReset(): void {
    this.customSetup.scalableLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableLineHeight);

    this.afterChange();
  }

  public onScalableLetterSpacingChange(value: string): void {
    this.customSetup.scalableLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableLetterSpacing);

    this.afterChange();
  }

  public onScalableLetterSpacingReset(): void {
    this.customSetup.scalableLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableLetterSpacing);

    this.afterChange();
  }

  public onScalableWordSpacingChange(value: string): void {
    this.customSetup.scalableWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableWordSpacing);

    this.afterChange();
  }

  public onScalableWordSpacingReset(): void {
    this.customSetup.scalableWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableWordSpacing);

    this.afterChange();
  }







  public onScalableSlideshowTitleFontSizeChange(value: string): void {
    this.customSetup.scalableSlideshowTitleFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleFontSize);

    this.afterChange();

    this.onScalableSlideshowTitleLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableSlideshowTitleFontSize)}`);
  }

  public onScalableSlideshowTitleFontSizeReset(): void {
    this.customSetup.scalableSlideshowTitleFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleFontSize);

    this.afterChange();

    this.onScalableSlideshowTitleLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableSlideshowTitleFontSize)}`);
  }

  public onScalableSlideshowTitleLineHeightChange(value: string): void {
    this.customSetup.scalableSlideshowTitleLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleLineHeight);

    this.afterChange();
  }

  public onScalableSlideshowTitleLineHeightReset(): void {
    this.customSetup.scalableSlideshowTitleLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleLineHeight);

    this.afterChange();
  }

  public onScalableSlideshowTitleLetterSpacingChange(value: string): void {
    this.customSetup.scalableSlideshowTitleLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleLetterSpacing);

    this.afterChange();
  }

  public onScalableSlideshowTitleLetterSpacingReset(): void {
    this.customSetup.scalableSlideshowTitleLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleLetterSpacing);

    this.afterChange();
  }

  public onScalableSlideshowTitleWordSpacingChange(value: string): void {
    this.customSetup.scalableSlideshowTitleWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleWordSpacing);

    this.afterChange();
  }

  public onScalableSlideshowTitleWordSpacingReset(): void {
    this.customSetup.scalableSlideshowTitleWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleWordSpacing);

    this.afterChange();
  }

  public onSlideshowTitleColorChange(value: string): void {
    this.customSetup.scalableSlideshowTitleColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleColor);

    this.afterChange();
  }

  public onSlideshowTitleColorReset(): void {
    this.customSetup.scalableSlideshowTitleColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleColor);

    this.afterChange();
  }

  public onSlideshowTitleFontChange(font: GoogleFontModel): void {
    this.customStylesService.onSlideshowTitleFontFamilyChange(font);

    this.googleFontsService.addFontLink(font);

    this.afterChange();
  }

  public onSlideshowTitleFontReset(): void {
    this.customSetup.scalableSlideshowTitleFontFamily.reset();

    this.afterChange();
  }

  public onSlideshowTitleFontWeightChange(value: string): void {
    this.customSetup.scalableSlideshowTitleFontWeight.onChange(value);

    this.afterChange();
  }

  public onSlideshowTitleFontWeightReset(): void {
    this.customSetup.scalableSlideshowTitleFontWeight.reset();

    this.customStylesService.initSlideshowTitleFontWeight();

    this.afterChange();
  }

  public onSlideshowTitleTextTransformChange(value: string): void {
    this.customSetup.scalableSlideshowTitleTextTransform.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleTextTransform);

    this.afterChange();
  }

  public onSlideshowTitleTextTransformReset(): void {
    this.customSetup.scalableSlideshowTitleTextTransform.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleTextTransform);

    this.afterChange();
  }

  public onSlideshowTitleTextAlignmentChange(value: string): void {
    this.customSetup.scalableSlideshowTitleTextAlignment.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleTextAlignment);

    this.afterChange();
  }

  public onSlideshowTitleTextAlignmentReset(): void {
    this.customSetup.scalableSlideshowTitleTextAlignment.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowTitleTextAlignment);

    this.afterChange();
  }







  public onScalableSlideshowParagraphFontSizeChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphFontSize);

    this.afterChange();

    this.onScalableSlideshowParagraphLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableSlideshowParagraphFontSize)}`);
  }

  public onScalableSlideshowParagraphFontSizeReset(): void {
    this.customSetup.scalableSlideshowParagraphFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphFontSize);

    this.afterChange();

    this.onScalableSlideshowParagraphLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableSlideshowParagraphFontSize)}`);
  }

  public onScalableSlideshowParagraphLineHeightChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphLineHeight);

    this.afterChange();
  }

  public onScalableSlideshowParagraphLineHeightReset(): void {
    this.customSetup.scalableSlideshowParagraphLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphLineHeight);

    this.afterChange();
  }

  public onScalableSlideshowParagraphLetterSpacingChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphLetterSpacing);

    this.afterChange();
  }

  public onScalableSlideshowParagraphLetterSpacingReset(): void {
    this.customSetup.scalableSlideshowParagraphLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphLetterSpacing);

    this.afterChange();
  }

  public onScalableSlideshowParagraphWordSpacingChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphWordSpacing);

    this.afterChange();
  }

  public onScalableSlideshowParagraphWordSpacingReset(): void {
    this.customSetup.scalableSlideshowParagraphWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphWordSpacing);

    this.afterChange();
  }

  public onSlideshowParagraphColorChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphColor);

    this.afterChange();
  }

  public onSlideshowParagraphColorReset(): void {
    this.customSetup.scalableSlideshowParagraphColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphColor);

    this.afterChange();
  }

  public onSlideshowParagraphFontChange(font: GoogleFontModel): void {
    this.customStylesService.onSlideshowParagraphFontFamilyChange(font);

    this.googleFontsService.addFontLink(font);

    this.afterChange();
  }

  public onSlideshowParagraphFontReset(): void {
    this.customSetup.scalableSlideshowParagraphFontFamily.reset();

    this.afterChange();
  }

  public onSlideshowParagraphFontWeightChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphFontWeight.onChange(value);

    this.afterChange();
  }

  public onSlideshowParagraphFontWeightReset(): void {
    this.customSetup.scalableSlideshowParagraphFontWeight.reset();

    this.customStylesService.initSlideshowParagraphFontWeight();

    this.afterChange();
  }

  public onSlideshowParagraphTextTransformChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphTextTransform.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphTextTransform);

    this.afterChange();
  }

  public onSlideshowParagraphTextTransformReset(): void {
    this.customSetup.scalableSlideshowParagraphTextTransform.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphTextTransform);

    this.afterChange();
  }

  public onSlideshowParagraphTextAlignmentChange(value: string): void {
    this.customSetup.scalableSlideshowParagraphTextAlignment.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphTextAlignment);

    this.afterChange();
  }

  public onSlideshowParagraphTextAlignmentReset(): void {
    this.customSetup.scalableSlideshowParagraphTextAlignment.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowParagraphTextAlignment);

    this.afterChange();
  }







  public onScalableSlideshowButtonFontSizeChange(value: string): void {
    this.customSetup.scalableSlideshowButtonFontSize.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonFontSize);

    this.afterChange();

    this.onScalableSlideshowButtonLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableSlideshowButtonFontSize)}`);
  }

  public onScalableSlideshowButtonFontSizeReset(): void {
    this.customSetup.scalableSlideshowButtonFontSize.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonFontSize);

    this.afterChange();

    this.onScalableSlideshowButtonLineHeightChange(`${this.getNewLineHeight(this.customSetup.scalableSlideshowButtonFontSize)}`);
  }

  public onScalableSlideshowButtonLineHeightChange(value: string): void {
    this.customSetup.scalableSlideshowButtonLineHeight.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonLineHeight);

    this.afterChange();
  }

  public onScalableSlideshowButtonLineHeightReset(): void {
    this.customSetup.scalableSlideshowButtonLineHeight.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonLineHeight);

    this.afterChange();
  }

  public onScalableSlideshowButtonLetterSpacingChange(value: string): void {
    this.customSetup.scalableSlideshowButtonLetterSpacing.onChange(Number.parseFloat(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonLetterSpacing);

    this.afterChange();
  }

  public onScalableSlideshowButtonLetterSpacingReset(): void {
    this.customSetup.scalableSlideshowButtonLetterSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonLetterSpacing);

    this.afterChange();
  }

  public onScalableSlideshowButtonWordSpacingChange(value: string): void {
    this.customSetup.scalableSlideshowButtonWordSpacing.onChange(Number.parseInt(value));

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonWordSpacing);

    this.afterChange();
  }

  public onScalableSlideshowButtonWordSpacingReset(): void {
    this.customSetup.scalableSlideshowButtonWordSpacing.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonWordSpacing);

    this.afterChange();
  }

  public onSlideshowButtonColorChange(value: string): void {
    this.customSetup.scalableSlideshowButtonColor.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonColor);

    this.afterChange();
  }

  public onSlideshowButtonColorReset(): void {
    this.customSetup.scalableSlideshowButtonColor.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonColor);

    this.afterChange();
  }

  public onSlideshowButtonFontChange(font: GoogleFontModel): void {
    this.customStylesService.onSlideshowButtonFontFamilyChange(font);

    this.googleFontsService.addFontLink(font);

    this.afterChange();
  }

  public onSlideshowButtonFontReset(): void {
    this.customSetup.scalableSlideshowButtonFontFamily.reset();

    this.afterChange();
  }

  public onSlideshowButtonFontWeightChange(value: string): void {
    this.customSetup.scalableSlideshowButtonFontWeight.onChange(value);

    this.afterChange();
  }

  public onSlideshowButtonFontWeightReset(): void {
    this.customSetup.scalableSlideshowButtonFontWeight.reset();

    this.customStylesService.initSlideshowButtonFontWeight();

    this.afterChange();
  }

  public onSlideshowButtonTextTransformChange(value: string): void {
    this.customSetup.scalableSlideshowButtonTextTransform.onChange(value);

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonTextTransform);

    this.afterChange();
  }

  public onSlideshowButtonTextTransformReset(): void {
    this.customSetup.scalableSlideshowButtonTextTransform.reset();

    this.dispatchCustomStyleChangedEventForOption(this.customSetup.scalableSlideshowButtonTextTransform);

    this.afterChange();
  }

  public toggleSlideshowTitleTextSetupSection(): void {
    this.isSlideshowTitleTextSetupCollapsed = !this.isSlideshowTitleTextSetupCollapsed;

    this.cdr.detectChanges();
  }

  public toggleSlideshowParagraphTextSetupSection(): void {
    this.isSlideshowParagraphTextSetupCollapsed = !this.isSlideshowParagraphTextSetupCollapsed;

    this.cdr.detectChanges();
  }

  public toggleSlideshowButtonTextSetupSection(): void {
    this.isSlideshowButtonTextSetupCollapsed = !this.isSlideshowButtonTextSetupCollapsed;

    this.cdr.detectChanges();
  }

  public selectVideoElement(): void {
    if (!this.elementService.element) return;

    const video: HTMLVideoElement = this.elementService.element.querySelector('video');

    if (!video) return;

    this.eventsService.dispatchElementSelect({
      element: video,
    }, this.iFrameService.sandboxWindow);
  }

  private getNewLineHeight(fontSize: BaseScalableModel): number {
    const expectedLineHeight: number = fontSize.value;

    return expectedLineHeight <= this.maxLineHeight ? expectedLineHeight : this.maxLineHeight;
  }

  public initColors(): void {
    this.customSetup.thumbDrawerBackground.reInit();
  }

  private dispatchCustomStyleChangedEventForOption(option: IStyleOption): void {
    this.dispatchCustomStyleChangedEvent({
      target: option.element,
      key: option.key,
      value: option.value,
    });
  }

  private dispatchStyleChangedEventForNativeOption(option: NativeSetupOptionModel): void {
    this.eventsService.dispatchStyleChangedEvent({
      target: this.nativeSetup.element,
      key: option.key,
      styleName: option.styleName,
      value: option.value,
    }, this.iFrameService.sandboxWindow);
  }

  private dispatchCustomStyleChangedEvent(data: { target: HTMLElement, key: string, value: any }): void {
    this.eventsService.dispatchCustomStyleChangedEvent(data, this.iFrameService.sandboxWindow);
  }

  private afterChange(): void {
    this.buttonsService.enableSaveButton();

    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    this.cdr.detach();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
