import {IStyleModelOptions} from '../option/i-style-model-options';

import {SetupOptionModel} from '../option/setup-option.model';

import {STYLE_PROPERTIES} from './constants';

export class HamburgerColorModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = false,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const currentValue: string = this.element.style.getPropertyValue(STYLE_PROPERTIES[this.key]);

    if (currentValue) return this.setValue(currentValue === 'undefined' ? void 0 : currentValue);

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);
    const value: string = computedValue.getPropertyValue(STYLE_PROPERTIES[this.key]) || computedValue['color'];

    this.setValue(value === 'undefined' ? void 0 : value);
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    const computedValue = window.getComputedStyle(this.element);
    const value = computedValue.getPropertyValue(STYLE_PROPERTIES[this.key]) || computedValue['color'];

    this.setValue(value);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;
    this.element.style.setProperty(STYLE_PROPERTIES[this.key], value);
  }
}
