import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../../../app-animations';

import {PaymentSubscriptionsService} from '../../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {ContentLoaderService} from '../../../../../core/services/loaders/content/content-loader.service';
import {CreditCardsModalService} from '../../../../../shared/services/modals/credit-cards/credit-cards-modal.service';
import {PaymentService} from '../../../../../core/services/payment/payment.service';

import {SubscriptionsModel} from '../../../../../core/models/payment/subscriptions/subscriptions.model';
import {CardModel} from '../../../../../core/models/payment/card/card.model';
import {PaymentModel} from '../../../../../core/models/payment/payment.model';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  public subscriptions: SubscriptionsModel;

  public paymentData: PaymentModel;

  private isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isLoaded(): boolean {
    return this.contentLoaderService.isLoaded;
  }

  constructor(private subscriptionsService: PaymentSubscriptionsService,
              private contentLoaderService: ContentLoaderService,
              private paymentService: PaymentService,
              private creditCardsModalService: CreditCardsModalService) {
  }

  public ngOnInit(): void {
    this.subscriptionsService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: SubscriptionsModel) => {
      this.subscriptions = value;
    });

    this.paymentService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paymentData: PaymentModel) => {
      this.paymentData = paymentData;
    });

    this.creditCardsModalService.selectedCardSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((card: CardModel) => {
      if (!card || !this.creditCardsModalService.openedFor) return;

      this.updateCard(card);
    });

    this.subscriptionsService.init();
  }

  private updateCard(card: CardModel): void {
    this.subscriptionsService.updateSubscriptionCard({ subscriptionId: this.creditCardsModalService.openedFor.id, cardId: card.id }).subscribe(() => {
      this.subscriptionsService.init();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onScroll(e) {
    if (!this.subscriptions || !this.subscriptions.hasMore || this.isLoading) return;

    const list = this.subscriptions.list;

    if (!list || list.length === 0) return;

    const currentPosition = e.target.scrollTop + e.target.clientHeight;

    if (currentPosition / e.target.scrollHeight < 0.5) return;

    this.isLoading = true;

    const last = list[list.length - 1];

    this.subscriptionsService.fetchMore(last.id).add(() => { this.isLoading = false; });
  }
}
