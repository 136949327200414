import {StripeCouponModel} from '../../stripe/coupon/stripe-coupon.model';

export class AccountCouponModel {
  constructor(public id?: number,
              public userId?: number,
              public stripeCouponId?: string,
              public createdAt?: string,
              public updatedAt?: string,
              public stripeCoupon?: StripeCouponModel) {}
}
