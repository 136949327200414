import {Component} from '@angular/core';

import {FroalaImageIsTooLargeModalService} from '../../../services/modals/froala-image-is-too-large/froala-image-is-too-large-modal.service';

@Component({
  selector: 'app-froala-image-is-too-large-modal',
  templateUrl: './froala-image-is-too-large-modal.component.html',
  styleUrls: ['./froala-image-is-too-large-modal.component.scss'],
})
export class FroalaImageIsTooLargeModalComponent {
  public get id(): string {
    return this.service.id;
  }

  constructor(public service: FroalaImageIsTooLargeModalService) {}

  public submitHandler(): void {
    this.service.close();
  }
}
