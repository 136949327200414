import {BaseScalableModel} from '../../base/scalable/base-scalable.model';

import {PROPERTY_NAMES, SCROLLED_PROPERTY_NAME, SCROLLED_PROPERTY_NAME_PX, MIN_SCROLLABLE_VALUE} from './constants';

export class WebsiteTitleScalableFontSizeModel extends BaseScalableModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAMES[key].OPTION, PROPERTY_NAMES[key].OPTION_PX, value, isEnabled);
  }

  public onChange(value: number): void {
    super.onChange(value);

    const expectedScrolledFontSize: number = Math.round(this.value / 1.5) < MIN_SCROLLABLE_VALUE ? MIN_SCROLLABLE_VALUE : this.value / 1.5;
    const scrolledFontSize: number = expectedScrolledFontSize > this.value ? this.value : expectedScrolledFontSize;

    this.element.style.setProperty(SCROLLED_PROPERTY_NAME, `${scrolledFontSize}`);
    this.element.style.setProperty(SCROLLED_PROPERTY_NAME_PX, `${scrolledFontSize}px`);
  }
}
