// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1;
}

.credit-cards-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.credit-cards-wrapper .list-wrapper {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/credit-cards/credit-cards.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,OAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,OAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,WAAA;AADJ","sourcesContent":["@import \"../../../../styles/colors\";\n\n:host {\n  display: flex;\n  flex: 1;\n}\n\n.credit-cards-wrapper {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n\n  .list-wrapper {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
