import {Injectable} from '@angular/core';

import {VideoEmbedDataModel} from '../../../models/videos/video-embed-data.model';

import {KEYS, PARSERS, EMBED_GENERATORS, SERVICE_RESOLVER} from './constants';

@Injectable()
export class VideoEmbedService {
  public getEmbedCode(html: string, url: string): VideoEmbedDataModel {
    const key = SERVICE_RESOLVER(url);
    const videoId = this.getVideoId(key, url);

    return new VideoEmbedDataModel(
      key || KEYS.UNKNOWN,
      EMBED_GENERATORS[key] ? EMBED_GENERATORS[key](videoId) : html,
    );
  }

  private getVideoId(key: string, url: string): string {
    return PARSERS[key] ? PARSERS[key](url) : null;
  }
}
