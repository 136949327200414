// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .firefox-audio-record-forbidden-modal {
  z-index: 100002;
  position: fixed;
}
:host ::ng-deep .firefox-audio-record-forbidden-modal > .modal > .modal-header {
  width: 550px !important;
  max-width: 550px !important;
}
:host ::ng-deep .firefox-audio-record-forbidden-modal > .modal > .modal-body {
  display: block;
  flex-direction: column;
  width: 550px !important;
  max-width: 550px !important;
  max-height: 90%;
  padding: 25px;
}
:host ::ng-deep .firefox-audio-record-forbidden-modal > .modal > .modal-body .firefox-audio-record-forbidden-modal-body {
  font-size: 16px;
  line-height: normal;
  overflow-x: auto;
}
:host ::ng-deep .firefox-audio-record-forbidden-modal > .modal > .modal-body .buttons-wrapper {
  display: block !important;
  margin: 20px 20px 5px 20px !important;
}
:host ::ng-deep .firefox-audio-record-forbidden-modal > .modal > .modal-body .buttons-wrapper .vs-button {
  display: block;
  width: 70px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/firefox-audio-record-forbidden/firefox-audio-record-forbidden-modal.component.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;EACA,eAAA;AAFJ;AAKM;EACE,uBAAA;EACA,2BAAA;AAHR;AAMM;EACE,cAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,eAAA;EACA,aAAA;AAJR;AAMQ;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAJV;AAUQ;EACE,yBAAA;EACA,qCAAA;AARV;AAUU;EACE,cAAA;EACA,WAAA;EACA,kBAAA;AARZ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .firefox-audio-record-forbidden-modal {\n    z-index: 100002;\n    position: fixed;\n\n    & > .modal {\n      & > .modal-header {\n        width: 550px !important;\n        max-width: 550px !important;\n      }\n\n      & > .modal-body {\n        display: block;\n        flex-direction: column;\n        width: 550px !important;\n        max-width: 550px !important;\n        max-height: 90%;\n        padding: 25px;\n\n        .firefox-audio-record-forbidden-modal-body {\n          font-size: 16px;\n          line-height: normal;\n          overflow-x: auto;\n\n          .message {\n          }\n        }\n\n        .buttons-wrapper {\n          display: block !important;\n          margin: 20px 20px 5px 20px !important;\n\n          .vs-button {\n            display: block;\n            width: 70px;\n            text-align: center;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
