import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges, ChangeDetectorRef
} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../../../../app-animations';

import {MessageModalService} from '../../../../../../services/message-modal.service';
import {UpgradeSubscriptionService} from '../../../../../../core/services/payment/subscriptions/upgrade/upgrade-subscription.service';
import {PlansHttpService} from '../../../../../../core/services/interaction/http/plans/plans-http.service';
import {AuthService} from '../../../../../../auth/auth.service';
import {UtilsService} from '../../../../../../core/services/utils/utils.service';
import {SidebarSectionsService} from '../../../../../../services/sidebar-sections.service';
import {CustomSubscriptionUpgradeErrorModalService} from '../../../../../../shared/services/modals/custom-subscription-upgrade-error/custom-subscription-upgrade-error-modal.service';

import {PlanModel} from '../../../../../../core/models/plan/plan.model';
import {SubscriptionModel} from '../../../../../../core/models/payment/subscriptions/subscription.model';
import {AccountModel} from '../../../../../../core/models/accounts/account.model';

import {DURATIONS} from '../../../../../../core/models/payment/subscriptions/constants';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PlanCardComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() plan: PlanModel;
  @Input() currentSubscription: SubscriptionModel;
  @Input() duration: string;
  @Input() isExpanded: boolean;

  @Output() handleSignUp: EventEmitter<{ plan: PlanModel }> = new EventEmitter<{ plan: PlanModel }>();
  @Output() toggleExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openAnnualView: EventEmitter<void> = new EventEmitter<void>();
  @Output() notifyHasLabel: EventEmitter<PlanModel> = new EventEmitter<PlanModel>();

  @ViewChild('expandedFeatures') expandedFeatures: ElementRef;
  @ViewChild('collapsedFeatures') collapsedFeatures: ElementRef;

  public account: AccountModel;

  public collapsedFeaturesHeight: number;
  public expandedFeaturesHeight: number;

  public isCollapsedFeaturesVisible: boolean = true;
  public isExpandedFeaturesVisible: boolean = false;
  public isSidebarCollapsed: boolean = false;

  private descriptionTimeoutId: number;

  private isDestroyed: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get DURATIONS(): {
    ONE_DAY: string,
    ONE_MONTH: string,
    THREE_MONTHS: string,
    ONE_YEAR: string,
  } {
    return DURATIONS;
  }

  public get isCurrent(): boolean {
    if (!this.currentSubscription || this.currentSubscription.isExpired || this.isTrial || this.isTrialEnded) return false;

    return this.currentSubscription.metadata.planId === this.plan.id && this.currentSubscription.metadata.duration === this.duration;
  }

  public get isTrial(): boolean {
    return this.currentSubscription && this.currentSubscription.isTrial;
  }

  public get isTrialEnded(): boolean {
    return this.currentSubscription && this.currentSubscription.isTrialEnded;
  }

  public get isError(): boolean {
    return !!this.upgradeSubscriptionService.statusErrorMessage && !this.isTrialEnded;
  }

  public get isUnpaid(): boolean {
    return !!this.upgradeSubscriptionService.isPaymentFailed && !this.isTrialEnded;
  }

  public get isPaymentExpired(): boolean {
    return !!this.upgradeSubscriptionService.isPaymentExpired;
  }

  public get autoRenewsWords(): string {
    return this.currentSubscription.isCustom || this.currentSubscription.isCancelledAtPeriodEnd ? 'Expires On' : 'Auto Renews';
  }

  public get autoRenews(): string {
    return this.currentSubscription.periodEndsAt;
  }

  public get trialEndAt(): string {
    return this.currentSubscription.trialEndAt;
  }

  constructor(private plansHttpService: PlansHttpService,
              private authService: AuthService,
              private utilsService: UtilsService,
              private messageModalService: MessageModalService,
              private sidebarSectionsService: SidebarSectionsService,
              private customSubscriptionUpgradeErrorModalService: CustomSubscriptionUpgradeErrorModalService,
              private cdr: ChangeDetectorRef,
              public upgradeSubscriptionService: UpgradeSubscriptionService) {
    this.onResize = this.utilsService.debounce(this.onResize.bind(this), 50);
  }

  public ngOnInit(): void {
    window.addEventListener('resize', this.onResize);

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.sidebarSectionsService.isSectionsCollapsedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCollapsed: boolean) => {
      this.isSidebarCollapsed = isCollapsed;
    });
  }

  public ngAfterViewInit(): void {
    this.handleSizes();

    this.plan.hasLabel = this.isCurrent;

    this.notifyHasLabel.emit(this.plan);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes['isExpanded']) return;

    this.isExpandedFeaturesVisible = changes['isExpanded'].currentValue;
    this.isCollapsedFeaturesVisible = !changes['isExpanded'].currentValue;

    window.clearTimeout(this.descriptionTimeoutId);

    this.descriptionTimeoutId = window.setTimeout(() => {
      this.isExpandedFeaturesVisible = this.isExpanded;
      this.isCollapsedFeaturesVisible = !this.isExpanded;
    }, 500);
  }

  private onResize(): void {
    this.handleSizes();
  }

  private handleSizes(): void {
    if (this.isDestroyed) return;

    if (this.expandedFeatures && this.expandedFeatures.nativeElement) {
      this.expandedFeaturesHeight = this.getHeight(this.expandedFeatures.nativeElement);
    }

    if (this.collapsedFeatures && this.collapsedFeatures.nativeElement) {
      this.collapsedFeaturesHeight = this.getHeight(this.collapsedFeatures.nativeElement);
    }

    this.cdr.detectChanges();
  }

  private getHeight(element: HTMLElement): number {
    element.style['height'] = 'auto';

    const height: number = element.clientHeight;

    element.style['height'] = '';

    return height;
  }

  public onPayClick(): void {
    if (!this.plan.isEnabled) return;

    if (this.currentSubscription && this.currentSubscription.isCustom) return this.customSubscriptionUpgradeErrorModalService.open();

    this.handleSignUp.emit({
      plan: this.plan,
    });
  }

  public onPaymentRedirect() {
    if (!this.isError) return;

    if (this.currentSubscription.isCustom) return this.customSubscriptionUpgradeErrorModalService.open();

    return this.upgradeSubscriptionService.toRepeatPayment();
  }

  private onModalClose(): void {
    this.messageModalService.close();
  }

  public toggleDescription(): void {
    this.toggleExpanded.emit(!this.isExpanded);
  }

  public goToAnnualView(): void {
    this.openAnnualView.emit();
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
