import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';

import {Subject} from 'rxjs';

import {AdminEducationInstitutionsService} from '../../../../../../core/services/admin/education/institutions/admin-education-institutions.service';

import {EducationNewInstitutionModel} from '../../../../../../core/models/education/institutions/education-new-institution.model';
import {EducationInstitutionModel} from '../../../../../../core/models/education/institutions/education-institution.model';

@Component({
  selector: 'app-admin-education-add-institution-modal',
  templateUrl: './add-institution-modal.component.html',
  styleUrls: ['./add-institution-modal.component.scss']
})
export class AdminEducationAddInstitutionModalComponent implements OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public institution: EducationNewInstitutionModel = new EducationNewInstitutionModel();

  public error: string = '';

  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationInstitutionsService) {
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.key !== 'Enter') return;

    this.submit();
  }

  public submit(): void {
    this.institution.validate();

    if (!this.institution.isValid) return;

    this.isLoading = true;
    
    this.service.create(new EducationInstitutionModel(
      void 0,
      this.institution.name,
    )).subscribe((err: string) => {
      if (!err) {
        this.closeHandler.emit();

        return;
      }

      this.error = err;

      this.isLoading = false;
    });
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
