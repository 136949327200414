import {BaseFileObjectDto} from '../../../base/file-object/base-file-object.dto';
import {ObjectRemoveErrorModel} from './object-remove-error.model';

export class ObjectRemoveErrorDto {
  constructor(public TemplateId?: number,
              public PageId?: number,
              public PageType?: string,
              public BlockId?: number,
              public BlockCategory?: string,
              public PageTitle?: string,
              public Object?: BaseFileObjectDto) {}

  public static normalize<Type>(res: ObjectRemoveErrorDto): ObjectRemoveErrorModel {
    if (!res) return null;

    return new ObjectRemoveErrorModel(
      res.TemplateId,
      res.PageId,
      res.PageType,
      res.BlockId,
      res.BlockCategory,
      res.PageTitle,
      res.Object,
    );
  }

  public static toRequest(model: ObjectRemoveErrorModel): ObjectRemoveErrorDto {
    if (!model) return null;

    return new ObjectRemoveErrorDto(
        model.templateId,
        model.pageId,
        model.pageType,
        model.blockId,
        model.blockCategory,
        model.pageTitle,
        model.object,
    );
  }
}
