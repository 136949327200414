import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

import {SetImageModalService} from '../../../services/modals/set-image/set-image-modal.service';
import {ImageManagerModalService} from '../image-manager-modal/image-manager-modal.service';
import {PortfoliosHttpService} from '../../../../core/services/interaction/http/portfolios/portfolios-http.service';

import {RadiobuttonModel} from '../../../models/radiobutton/radiobutton.model';
import {Button} from '../../../../common/models/button/button.model';

import {KEYS} from '../../../services/modals/set-image/constants';

@Component({
  selector: 'app-set-image-modal',
  templateUrl: './set-image-modal.component.html',
  styleUrls: ['./set-image-modal.component.scss'],
})
export class SetImageModalComponent implements OnInit {
  @Input() id: string;

  public radiobuttons: RadiobuttonModel[] = [
    new RadiobuttonModel('set-image', 'Randomize From All Portfolios', KEYS.RANDOMIZE_ALL_PORTFOLIOS),
    new RadiobuttonModel('set-image', 'Randomize Most Recent portfolio', KEYS.RANDOM),
    new RadiobuttonModel('set-image', 'Select Image From Portfolios', KEYS.SINGLE_FROM_PORTFOLIO),
  ];
  
  public buttons: Button[] = [
    {
      text: 'Save',
      className: 'neutral green',
      onClick: this.submitHandler.bind(this),
    },
    {
      text: 'Cancel',
      className: 'neutral ok-button',
      onClick: this.cancelHandler.bind(this),
    },
  ];

  public portfolios: any[];

  public get isSingleImage(): boolean {
    return this.setImageModalService.selectedValue === KEYS.SINGLE_FROM_PORTFOLIO;
  }

  constructor(public setImageModalService: SetImageModalService,
              private imageManagerModalService: ImageManagerModalService,
              private portfoliosHttpService: PortfoliosHttpService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.portfoliosHttpService.fetchList().subscribe((portfolios) => {
      this.portfolios = portfolios;
    });
  }

  public onChange(): void {
    this.cdr.detectChanges();
  }

  public openImageManager() {
    this.imageManagerModalService.open(true);
  }

  private submitHandler() {
    this.setImageModalService.onSubmit();
  }

  private cancelHandler() {
    this.setImageModalService.onCancel();
  }

  public closeModal() {
    this.setImageModalService.close();
    this.setImageModalService.onSubmit();
  }
}
