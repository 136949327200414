import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ImagesAvailabilitiesService} from '../availabilities/availabilities.service';
import {MeasureUnitsService} from '../../converters/measure-units/measure-units.service';
import {ImageDetailsService} from '../image-details/image-details.service';
import {PortfolioDefaultsService} from '../portfolio-defaults/portfolio-defaults.service';
import {CurrenciesService} from '../../currencies/currencies.service';
import {ImageProcessesService} from '../../../../services/image-processes.service';
import {ImageManagerModalService} from '../../../../shared/components/modals/image-manager-modal/image-manager-modal.service';
import {UtilsService} from '../../utils/utils.service';
import {EducationImageManagerService} from '../../education/image-manager/education-image-manager.service';
import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';
import {FullscreenImageManagerEnlargementService} from '../../../../shared/services/fullscreen-image-manager-enlargement/fullscreen-image-manager-enlargement.service';
import {CoreImageManagerService} from '../core-image-manager.service';
import {StudentImageManagerService} from '../../education/students/image-manager/student-image-manager.service';

import {ImageLabelModel} from '../../../models/images/image-label/image-label.model';
import {ImageLabelPriceModel} from '../../../models/images/image-label/price/image-label-price.model';
import {CurrencyModel} from '../../../models/currency/currency.model';
import {MeasureUnitModel} from '../../../models/measure-unit/measure-unit.model';
import {ImageAvailabilityModel} from '../../../models/images/availability/image-availability.model';
import {ImageModel} from '../../../models/images/image.model';
import {PortfolioDefaultsModel} from '../../../models/images/default/portfolio-defaults.model';
import {ImagePriceModel} from '../../../models/images/price/image-price.model';
import {ImageProcessModel} from '../../../../models/image-processes/image-process.model';
import {ImageManagerUserTypeModel} from '../../../models/image-manager/user-type/user-type.model';

import {EducatorImageManagerTabs} from '../../education/image-manager/constants';

@Injectable()
export class ImageLabelService {
  public imageLabelSubject: BehaviorSubject<ImageLabelModel> = new BehaviorSubject<ImageLabelModel>(null);

  public activeImageManagerTab: EducatorImageManagerTabs;
  public isStudentsImageManagerTab: boolean = false;
  public isExhibitionsImageManagerTab: boolean = false;
  public isUserImageManagerTab: boolean = false;

  private imageData: ImageModel = null;
  private portfolioDefaults: PortfolioDefaultsModel = null;

  private currency: CurrencyModel = new CurrencyModel();

  private mainMeasureUnit: MeasureUnitModel = new MeasureUnitModel();
  private measureUnit: MeasureUnitModel = new MeasureUnitModel();
  private oppositeMeasureUnit: MeasureUnitModel = new MeasureUnitModel();

  private availabilities: ImageAvailabilityModel[] = [];
  private processes: ImageProcessModel[] = [];

  private defaultProcess: ImageProcessModel = null;

  private currentView: string = '';
  
  private userType: ImageManagerUserTypeModel;

  private isStudent: boolean = false;

  private get title(): string {
    const title = this.imageTitle;
    const year = this.imageYear;

    return `${title ? title : ''}${title && year ? ', ' : ''}${year ? year : ''}`;
  }

  private get caption(): string {
    if (this.isLibrary) return this.imageData.caption;

    const caption: string = this.imageData.isCaptionDefault ? this.portfolioDefaults.caption : this.imageData.caption;

    return caption ? caption : '';
  }

  private get pricingStructure(): string {
    if (this.isLibrary) return this.imageData.pricingStructure || '';

    const pricingStructure = this.imageData.isPricingStructureDefault ? this.portfolioDefaults.pricingStructure : this.imageData.pricingStructure;

    return pricingStructure ? pricingStructure : '';
  }

  private get inquiryLinkText(): string {
    if (!this.imageData || !this.portfolioDefaults) return '';

    return this.portfolioDefaults.inquiryLinkText || 'To Purchase, Please Inquire';
  }

  private get purchaseText(): string {
    if (!this.imageData || !this.portfolioDefaults) return '';

    if (this.isLibrary) return 'PURCHASE';

    return this.portfolioDefaults.purchaseText ? this.portfolioDefaults.purchaseText : 'PURCHASE';
  }

  private get purchaseInfo(): string {
    if (!this.imageData) return '';

    if (this.isLibrary) return this.imageData.purchaseInfo || '';

    return this.imageData.isPurchaseInfoDefault ? this.portfolioDefaults.purchaseInfo : this.imageData.purchaseInfo;
  }

  private get copyright(): string {
    if (this.isLibrary) return this.imageData.copyright || '';

    return this.imageData.isCopyrightDefault ? this.portfolioDefaults.copyright : this.imageData.copyright;
  }

  public get prices(): ImageLabelPriceModel[] {
    return this.imageData.prices.map((price: ImagePriceModel) => {
      if (!price) return new ImageLabelPriceModel();

      const availabilityId = this.resolvePriceAvailabilityId(price);
      const isAvailable = this.isPriceAvailable(availabilityId);
      const isUponRequest = this.isPriceUponRequest(availabilityId);

      return new ImageLabelPriceModel(
        this.getPrintProcess(price),
        this.getDimensions(this.getImagePriceDimensions(price), this.getImagePriceOtherDimensions(price)),
        this.getMatDimensions(this.getImagePriceMatDimensions(price), this.getImagePriceOtherMatDimensions(price)),
        this.getEdition(price),
        this.getPrice(price),
        this.getAvailability(price),
        isAvailable,
        isUponRequest,
      );
    });
  }

  private get isLibrary(): boolean {
    if (this.isStudent || this.isStudentsImageManagerTab) {
      return false;
    }

    return this.imageManagerModalService.isLibrary;
  }

  private get imageTitle(): string {
    if (this.isLibrary) return this.imageData.title;

    return this.imageData.isTitleDefault ? this.portfolioDefaults.title : this.imageData.title;
  }

  private get imageYear(): string {
    if (this.isLibrary) return this.imageData.year;

    return this.imageData.isYearDefault ? this.portfolioDefaults.year : this.imageData.year;
  }

  private get isInquireLinkVisible(): boolean {
    if (this.isLibrary) return this.imageData.isInquireLinkVisible;

    return this.imageData.isPurchaseLinkVisibilityDefault ? this.portfolioDefaults.isInquireLinkVisible : this.imageData.isInquireLinkVisible;
  }

  private get isPurchaseButtonVisible(): boolean {
    if (this.isLibrary) return this.imageData.isInquireLinkVisible;

    return this.imageData.isPurchaseLinkVisibilityDefault ? this.portfolioDefaults.isPurchaseButtonVisible : this.imageData.isPurchaseButtonVisible;
  }

  constructor(
    private imageDetailsService: ImageDetailsService,
    private fullscreenImageManagerEnlargementService: FullscreenImageManagerEnlargementService,
    private portfolioDefaultsService: PortfolioDefaultsService,
    private imagesAvailabilitiesService: ImagesAvailabilitiesService,
    private imageProcessesService: ImageProcessesService,
    private currenciesService: CurrenciesService,
    private measureUnitsService: MeasureUnitsService,
    private imageManagerModalService: ImageManagerModalService,
    private educationImageManagerService: EducationImageManagerService,
    private imageManagerService: ImageManagerService,
    private studentImageManagerService: StudentImageManagerService,
    private coreImageManagerService: CoreImageManagerService,
    private utilsService: UtilsService,
  ) {
    this.coreImageManagerService.userTypeSubject.subscribe((data: ImageManagerUserTypeModel) => {
      this.userType = data;
      
      this.isStudent = data && data.type === 'student';
    });

    this.educationImageManagerService.activeTabSubject.subscribe((key: EducatorImageManagerTabs) => {
      this.activeImageManagerTab = key;

      this.isStudentsImageManagerTab = this.activeImageManagerTab === 'students';
      this.isExhibitionsImageManagerTab = this.activeImageManagerTab === 'exhibitions';
      this.isUserImageManagerTab = this.activeImageManagerTab === 'user';
    });

    this.imageManagerService.viewSubject.subscribe(({ key }: { key: string }) => {
      this.currentView = key;
    });

    this.imageDetailsService.imageDetailsSubject.subscribe((image: ImageModel) => {
      if (this.currentView !== 'thumbnails' && this.currentView !== 'large') {
        return;
      }

      this.imageData = image;

      this.init();
    });

    this.fullscreenImageManagerEnlargementService.currentImageSubject.subscribe((image: ImageModel) => {
      if (this.currentView !== 'full-view' && this.currentView !== 'fullscreen') {
        return;
      }

      this.imageData = image;

      this.init();
    });

    this.portfolioDefaultsService.portfolioDefaultsSubject.subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isUserImageManagerTab) return;
      
      this.portfolioDefaults = portfolioDefaults;

      this.init();
    });

    this.educationImageManagerService.studentPortfolioDefaultsSubject.subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isStudentsImageManagerTab) return;
      
      this.portfolioDefaults = portfolioDefaults;

      this.init();
    });

    this.studentImageManagerService.portfolioDefaultsSubject.subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isStudent) {
        return;
      }
      
      this.portfolioDefaults = portfolioDefaults;

      this.init();
    });

    this.imagesAvailabilitiesService.availabilitiesSubject.subscribe((availabilities: ImageAvailabilityModel[]) => {
      this.availabilities = availabilities;

      this.init();
    });

    this.imageProcessesService.imageProcessesSubject.subscribe((processes: ImageProcessModel[]) => {
      this.processes = processes;

      this.init();
    });

    this.currenciesService.websiteCurrencySubject.subscribe((currency: CurrencyModel) => {
      this.currency = currency;

      this.init();
    });

    this.measureUnitsService.mainMeasureUnitSubject.subscribe((measureUnit: MeasureUnitModel) => {
      this.mainMeasureUnit = measureUnit;

      this.init();
    });

    this.measureUnitsService.websiteMeasureUnitSubject.subscribe((measureUnit: MeasureUnitModel) => {
      this.measureUnit = measureUnit;

      this.init();
    });

    this.measureUnitsService.oppositeMeasureUnitSubject.subscribe((measureUnit: MeasureUnitModel) => {
      this.oppositeMeasureUnit = measureUnit;

      this.init();
    });
  }

  private init(): void {
    if (this.portfolioDefaults && this.processes) {
      this.defaultProcess = this.imageProcessesService.getProcessByName(this.processes, this.portfolioDefaults.printProcess);
    }

    if (!this.imageData || (!this.isLibrary && !this.portfolioDefaults)) {
      return;
    }

    this.imageLabelSubject.next(new ImageLabelModel(
      this.imageData.id,
      this.title,
      this.caption,
      this.pricingStructure,
      this.copyright,
      this.inquiryLinkText,
      this.purchaseText,
      this.purchaseInfo,
      this.imageData.isInfoVisible,
      this.isInquireLinkVisible,
      this.isPurchaseButtonVisible,
      this.prices,
    ));
  }

  private getPrintProcess(price: ImagePriceModel): string {
    const process = this.getImagePricePrintProcess(price);

    return process ? process.processName : '';
  }

  private getImagePricePrintProcess(price: ImagePriceModel): ImageProcessModel {
    if (this.isLibrary) return this.imageProcessesService.getProcessById(this.processes, price.processId);

    return price.isProcessDefault ? this.defaultProcess : this.imageProcessesService.getProcessById(this.processes, price.processId);
  }

  private getDimensions(dimensions, otherDimensions): string {
    if (!dimensions.width || !dimensions.height) return '';

    const lengthValue = dimensions.length ? `x${this.utilsService.formatImageDimension(dimensions.length)}` : '';
    const otherLengthValue = otherDimensions.length ? `x${this.utilsService.formatImageDimension(otherDimensions.length)}` : '';

    const dimensionsString = `${this.utilsService.formatImageDimension(dimensions.width)}x${this.utilsService.formatImageDimension(dimensions.height)}${lengthValue}${this.measureUnit.symbol}`;
    const otherDimensionsString = `(${this.utilsService.formatImageDimension(otherDimensions.width)}x${this.utilsService.formatImageDimension(otherDimensions.height)}${otherLengthValue}${this.oppositeMeasureUnit.symbol})`;

    return `${dimensionsString} ${otherDimensionsString}`;
  }

  private getMatDimensions(dimensions, otherDimensions): string {
    const dimensionsString = this.getDimensions(dimensions, otherDimensions);

    return dimensionsString ? `${dimensionsString} Mat` : '';
  }

  private getImagePriceDimensions(price: ImagePriceModel): { width: number, height: number, length: number } {
    return {
      width: price.isImageSizeDefault && !this.isLibrary ? this.portfolioDefaults.width : price.width,
      height: price.isImageSizeDefault && !this.isLibrary ? this.portfolioDefaults.height : price.height,
      length: price.isLengthDefault && !this.isLibrary ? this.portfolioDefaults.length : price.length,
    };
  }

  private getImagePriceOtherDimensions(price: ImagePriceModel): { width: number, height: number, length: number } {
    const width = price.isImageSizeDefault && !this.isLibrary ? this.portfolioDefaults.width : price.width;
    const height = price.isImageSizeDefault && !this.isLibrary ? this.portfolioDefaults.height : price.height;
    const length = price.isLengthDefault && !this.isLibrary ? this.portfolioDefaults.length : price.length;

    return {
      width: this.convert(width),
      height: this.convert(height),
      length: this.convert(length),
    };
  }

  private getImagePriceMatDimensions(price: ImagePriceModel): { width: number, height: number } {
    return {
      width: price.isMatSizeDefault && !this.isLibrary ? this.portfolioDefaults.matWidth : price.matWidth,
      height: price.isMatSizeDefault && !this.isLibrary ? this.portfolioDefaults.matHeight : price.matHeight,
    };
  }

  private getImagePriceOtherMatDimensions(price: ImagePriceModel): { width: number, height: number } {
    const width = price.isMatSizeDefault && !this.isLibrary ? this.portfolioDefaults.matWidth : price.matWidth;
    const height = price.isMatSizeDefault && !this.isLibrary ? this.portfolioDefaults.matHeight : price.matHeight;

    return {
      width: this.convert(width),
      height: this.convert(height),
    };
  }

  private getEdition(price: ImagePriceModel): string {
    if (!this.getImagePriceIsEditioned(price)) return '';

    const { number, size } = this.getImagePriceEdition(price);

    if (!size || (!number && !size)) return '';

    return number && size ? `Edition ${number}/${size}` : `Edition of ${size}`;
  }

  private getImagePriceIsEditioned(price: ImagePriceModel): boolean {
    if (this.isLibrary) return price.isEditioned;

    return price.isEditionedDefault ? this.portfolioDefaults.isEditioned : price.isEditioned;
  }

  private getImagePriceEdition(price: ImagePriceModel): { number: string, size: string } {
    if (this.isLibrary) {
      return {
        number: price.editionNumber,
        size: price.editionSize,
      };
    }

    return {
      number: price.isEditionedDefault ? this.portfolioDefaults.printNumber : price.editionNumber,
      size: price.isEditionedDefault ? this.portfolioDefaults.editionSize : price.editionSize,
    };
  }

  private getPrice(price: ImagePriceModel): string {
    if (!this.currency || !this.currency.symbol) return '';

    const priceValue = this.getImagePricePrice(price);

    return priceValue ? `${this.currency.symbol}${this.utilsService.formatImageDimension(priceValue)}` : '';
  }

  private getImagePricePrice(price: ImagePriceModel): number {
    if (this.isLibrary) return price.price;

    return price.isPriceDefault ? this.portfolioDefaults.priceFormatted : price.price;
  }

  private getAvailability(price: ImagePriceModel): string {
    const availabilityId = this.resolvePriceAvailabilityId(price);
    const availability: ImageAvailabilityModel = this.imagesAvailabilitiesService.getById(this.availabilities, availabilityId);

    return availability ? availability.text : '';
  }

  private resolvePriceAvailabilityId(price: ImagePriceModel): number {
    if (this.isLibrary) return price.availabilityId;

    return price.isAvailabilityDefault ? this.portfolioDefaults.availability : price.availabilityId;
  }

  private isPriceAvailable(availabilityId: number): boolean {
    return availabilityId === 0;
  }

  private isPriceUponRequest(availabilityId: number): boolean {
    return availabilityId === 4;
  }

  private convert(dimension: number) {
    if (this.mainMeasureUnit.id === this.measureUnit.id) return this.measureUnitsService.convertFromMainTo(dimension, this.oppositeMeasureUnit);

    return this.measureUnitsService.convert(dimension, this.measureUnit, this.mainMeasureUnit);
  }
}
