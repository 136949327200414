import {WebsiteModel} from './website.model';
import {WebsitesTemplatesPermissionsDto} from './templates-permissions/templates-permissions.dto';
import {WebsitesTemplatesPermissionsModel} from './templates-permissions/templates-permissions.model';
import {SubscriptionDto} from '../payment/subscriptions/subscription.dto';

export class WebsiteDto {
  constructor(
    public WebsiteId: number,
    public UserId: number,
    public InstitutionId: number,
    public ClassId: number,
    public Type: string,
    public TemplateId: number,
    public SubscriptionId: number,
    public MailChimpListId: string,
    public CurrencyId: number,
    public MeasureUnitId: number,
    public GaAccountId: number,
    public GaCode: string,
    public Ga4AccountName: string,
    public Ga4PropertyName: string,
    public Ga4MeasurementId: string,
    public Title: string,
    public Subtitle: string,
    public PublishStatus: string,
    public CopyrightNotice: string,
    public Contact: string,
    public ContactName: string,
    public ContactEmail: string,
    public ContactPhone: string,
    public ContactAddress1: string,
    public ContactAddress2: string,
    public Company: string,
    public ContactAddressCityStateZipCountry: string,
    public MetaTag_Description: string,
    public MetaTag_Keywords: string,
    public MenuStyle: string,
    public MobileMenuStyle: string,
    public MobilePortfolioKey: string,
    public MobileEnlargementKey: string,
    public PrivatePagePassword: string,
    public PrivatePagePasswordTimeUpdate: string,
    public PayPalEmail: string,
    public FaviconImage: string,
    public RandomizeFromQueue: boolean,
    public WebsitesTemplatesPermissions: WebsitesTemplatesPermissionsDto[],
    public Subscription: SubscriptionDto,
    public IsSetupCompleted: boolean,
    public IsRightClickDisabled: boolean,
    public IsCopyrightNoticeEnabled: boolean,
    public IsEverPublished: boolean,
    public IsCaptchaEnabled: boolean,
    public IsBlogCommentsApprovable: boolean,
    public IsSimpleImageLabel: boolean,
    public IsMainStudentWebsite: boolean,
    public TimeUpdatedOffset: string,
  ) {}

  public static normalize(res: WebsiteDto): WebsiteModel {
    if (!res) return null;

    return new WebsiteModel(
      res.WebsiteId,
      res.UserId,
      res.InstitutionId,
      res.ClassId,
      res.Type,
      res.TemplateId,
      res.SubscriptionId,
      res.MailChimpListId,
      res.CurrencyId,
      res.MeasureUnitId,
      res.GaAccountId,
      res.GaCode,
      res.Ga4AccountName,
      res.Ga4PropertyName,
      res.Ga4MeasurementId,
      res.Title,
      res.Subtitle,
      res.PublishStatus,
      res.CopyrightNotice,
      res.Contact,
      res.ContactName,
      res.ContactEmail,
      res.ContactPhone,
      res.ContactAddress1,
      res.ContactAddress2,
      res.Company,
      res.ContactAddressCityStateZipCountry,
      res.MetaTag_Description,
      res.MetaTag_Keywords,
      res.MenuStyle,
      res.MobileMenuStyle,
      res.MobilePortfolioKey,
      res.MobileEnlargementKey,
      res.PrivatePagePassword,
      res.PrivatePagePasswordTimeUpdate,
      res.PayPalEmail,
      res.FaviconImage,
      res.RandomizeFromQueue,
      res.WebsitesTemplatesPermissions ? res.WebsitesTemplatesPermissions.map((item: WebsitesTemplatesPermissionsDto) => WebsitesTemplatesPermissionsDto.normalize(item)) : null,
      SubscriptionDto.normalize(res.Subscription),
      res.IsSetupCompleted,
      res.IsRightClickDisabled,
      res.IsCopyrightNoticeEnabled,
      res.IsEverPublished,
      res.IsCaptchaEnabled,
      res.IsBlogCommentsApprovable,
      res.IsSimpleImageLabel,
      res.IsMainStudentWebsite,
      res.TimeUpdatedOffset,
    );
  }

  public static fromModel(res: WebsiteModel): WebsiteDto {
    if (!res) return null;

    return new WebsiteDto(
      res.id,
      res.userId,
      res.institutionId,
      res.classId,
      res.type,
      res.templateId,
      res.subscriptionId,
      res.mailChimpListId,
      res.currencyId,
      res.measureUnitId,
      res.gaAccountId,
      res.gaCode,
      res.ga4AccountName,
      res.ga4PropertyName,
      res.ga4MeasurementId,
      res.title,
      res.subtitle,
      res.publishStatus,
      res.copyrightNotice,
      res.contact,
      res.contactName,
      res.contactEmail,
      res.contactPhone,
      res.contactAddress1,
      res.contactAddress2,
      res.company,
      res.contactAddressCityStateZipCountry,
      res.metaTagDescription,
      res.metaTagKeywords,
      res.menuStyle,
      res.mobileMenuStyle,
      res.mobilePortfolioKey,
      res.mobileEnlargementKey,
      res.privatePagePassword,
      res.privatePagePasswordTimeUpdate,
      res.payPalEmail,
      res.faviconImage,
      res.randomizeFromQueue,
      res.templatesPermissions ? res.templatesPermissions.map((item: WebsitesTemplatesPermissionsModel) => WebsitesTemplatesPermissionsDto.fromModel(item)) : null,
      null,
      res.isSetupCompleted,
      res.isRightClickDisabled,
      res.isCopyrightNoticeEnabled,
      res.isEverPublished,
      res.isCaptchaEnabled,
      res.isBlogCommentsApprovable,
      res.isSimpleImageLabel,
      res.isMainStudentWebsite,
      res.timeUpdatedOffset,
    );
  }
}
