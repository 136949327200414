import {ImageFileModel} from '../images/image-file/image-file.model';

export class LogoModel implements ImageFileModel {
  constructor(public id?: number,
              public userId?: number,
              public fileName?: string,
              public originalFileName?: string,
              public fileSize?: number,
              public widthPx?: number,
              public heightPx?: number,
              public link?: string,
              public isFirstClicked: boolean = false,
              public isSelected: boolean = false,
              public isUploading: boolean = false,
              public isThumbLoaded: boolean = false,
              public isError: boolean = false,
              public errorMessage: string = '') {}
}
