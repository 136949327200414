import { TreeNode } from 'primeng/api';

import { NodeModel } from "./node.model";

export class TreeItemModel implements TreeNode {
  public styleClass: string = '';

  public get isEmpty(): boolean {
    return this.data && this.data.type === 'EMPTY';
  }

  constructor(
    public label: string,
    public data: NodeModel,
    public children: TreeItemModel[],
    public expanded: boolean,
    public selectable: boolean,
    public draggable: boolean = true,
    public droppable: boolean = true,
    public parent: TreeItemModel = null,
  ) {
    this.styleClass = data.type === 'H' ? 'home-page-wrapper' : '';
  }

  public isSameTo(other: TreeItemModel): boolean {
    if (!other) {
      return false;
    }

    return this.label === other.label
      && this.data.isSameTo(other.data)
      && this.children.length === other.children.length
      && this.children.every((child: TreeItemModel, index: number) => child.isSameTo(other.children[index]))
      && this.expanded === other.expanded
      && this.selectable === other.selectable
      && this.draggable === other.draggable
      && this.droppable === other.droppable
      && ((!this.parent && !other.parent) || this.parent.isSameTo(other.parent));
  }

  public static clone(item: TreeItemModel): TreeItemModel {
    if (!item) {
      return null;
    }

    return new TreeItemModel(
      item.label,
      NodeModel.normalize(item.data),
      item.children ? item.children.map((child: TreeItemModel) => TreeItemModel.clone(child)) : [],
      item.expanded,
      item.selectable,
      item.draggable,
      item.droppable,
    );
  }

  public static empty(): TreeItemModel {
    const item = new TreeItemModel(
      'No items',
      new NodeModel(
        null,
        null,
        null,
        null,
        null,
        'No items',
        'EMPTY',
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true,
        true,
        false,
        true,
        false,
        false,
        false,
      ),
      [],
      false,
      false,
      false,
      true,
    );

    item.styleClass = 'empty-tree-item-holder';

    return item;
  }
}
