import * as moment from 'moment';

import {EducationTeacherModel} from './education-teacher.model';
import {EducationInstitutionDto} from '../institutions/education-institution.dto';
import {EducationClassDto} from '../classes/education-class.dto';
import {AccountDto} from '../../accounts/account.dto';

import {TIME_FORMATTER} from './constants';

export class EducationTeacherDto {
  constructor(public Id?: number,
              public UserId?: number,
              public User?: AccountDto,
              public Institutions?: EducationInstitutionDto[],
              public Classes?: EducationClassDto[],
              public CreatedBy?: AccountDto,
              public DeletedBy?: AccountDto,
              public CreatedAt?: string,
              public UpdatedAt?: string,
              public DeletedAt?: string) {}

  public static normalize(res: EducationTeacherDto): EducationTeacherModel {
    if (!res) return null;

    return new EducationTeacherModel(
      res.Id,
      res.UserId,
      AccountDto.normalize(res.User),
      res.Institutions ? res.Institutions.map((institution: EducationInstitutionDto) => EducationInstitutionDto.normalize(institution)) : null,
      res.Classes ? res.Classes.map((item: EducationClassDto) => EducationClassDto.normalize(item)) : null,
      AccountDto.normalize(res.CreatedBy),
      AccountDto.normalize(res.DeletedBy),
      moment(res.CreatedAt).format(TIME_FORMATTER),
      moment(res.UpdatedAt).format(TIME_FORMATTER),
      moment(res.DeletedAt).format(TIME_FORMATTER),
    );
  }
}
