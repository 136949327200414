import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../../../../../app-animations';

import {RepeatPaymentService} from '../../../../../../../core/services/payment/subscriptions/repeat-payment/repeat-payment.service';

import {PlanModel} from '../../../../../../../core/models/plan/plan.model';
import {CardModel} from '../../../../../../../core/models/payment/card/card.model';
import {ReceiptModalModel} from '../../../../../../../shared/models/modals/receipt-modal/receipt-modal.model';

@Component({
  selector: 'app-repeat-payment-confirmation-step',
  templateUrl: './step-confirmation.component.html',
  styleUrls: ['./step-confirmation.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class RepeatPaymentConfirmationStepComponent implements OnInit, OnDestroy {
  @Output() confirmHandler: EventEmitter<void> = new EventEmitter<void>();

  public amount: string = '';

  public retryData: ReceiptModalModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isReady() {
    return (this.invoiceId || this.plan) && this.card;
  }

  public get header(): string {
    if (!this.retryData) {
      return '';
    }

    if (this.retryData.isNew) {
      return `Invoice`;
    }

    if (this.invoiceId) {
      return `Retry payment`;
    }

    if (this.plan) {
      return `Retry Payment for ${this.plan.name} Service`;
    }

    return `Retry Payment`;
  }

  public get plan(): PlanModel {
    return this.service.plan;
  }

  public get card(): CardModel {
    return this.service.card;
  }

  public get invoiceId(): string {
    return this.service.invoiceId;
  }

  constructor(public service: RepeatPaymentService) {
    this.service.fetchRetryData();
  }

  public ngOnInit(): void {
    this.service.retryDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ReceiptModalModel) => {
      this.retryData = data;
    });
  }

  public onConfirmed(): void {
    this.retryData = null;

    this.confirmHandler.emit();
  }

  public onCanceled(): void {
    this.retryData = null;

    this.service.clear();
    this.service.toHistory();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
