import {SelectOption} from '../../../select/option/option.model';

export const STYLE_PROPERTY_NAME = '--thumb-drawer-thumbs-position';

export const KEYS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('Left', KEYS.LEFT),
  new SelectOption('Center', KEYS.CENTER),
  new SelectOption('Right', KEYS.RIGHT),
];

