import {Component} from '@angular/core';
import {BrowserService} from '../../../core/services/browser/browser.service';


@Component({
  selector: 'app-browser-warning-overlay',
  templateUrl: './browser-warning-overlay.component.html',
  styleUrls: ['./browser-warning-overlay.component.scss'],
})
export class BrowserWarningOverlayComponent {
  constructor(public browserService: BrowserService) {}
}
