import * as moment from 'moment';

import {EducationStudentModel} from './education-student.model';
import {AccountDto} from '../../accounts/account.dto';
import {PortfolioDto} from '../../portfolios/portfolio.dto';

import {TIME_FORMATTER} from './constants';

export class EducationStudentDto {
  constructor(
    public Id?: number,
    public UserId?: number,
    public User?: AccountDto,
    public Portfolios?: PortfolioDto[],
    public DeletedBy?: AccountDto,
    public CreatedAt?: string,
    public UpdatedAt?: string,
    public DeletedAt?: string
  ) {}

  public static normalize(res: EducationStudentDto): EducationStudentModel {
    if (!res) return null;

    return new EducationStudentModel(
      res.Id,
      res.UserId,
      AccountDto.normalize(res.User),
      res.Portfolios ? res.Portfolios.map((portfolio: PortfolioDto) => PortfolioDto.normalize(portfolio)) : null,
      AccountDto.normalize(res.DeletedBy),
      moment(res.CreatedAt).format(TIME_FORMATTER),
      moment(res.UpdatedAt).format(TIME_FORMATTER),
      moment(res.DeletedAt).format(TIME_FORMATTER),
    );
  }
}
