import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Observable, Subscriber} from 'rxjs';

import {MailChimpService} from '../../../../services/mailchimp.service';
import {NavigationService} from '../../../../services/navigation.service';
import {SidebarSectionsService} from '../../../../services/sidebar-sections.service';
import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {KEYS} from '../../../../core/services/sidebar/constants';
import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';

@Injectable()
export class MailGuard  {
  get isConnected() {
    return this.mailChimpService.status.value === this.mailChimpService.API_STATUSES.CONNECTED;
  }

  constructor(
    private mailChimpService: MailChimpService,
    private navigationService: NavigationService,
    private sidebarSectionsService: SidebarSectionsService,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      const mailingListPermission: IPermissionData = {
        type: 'permission',
        value: PERMISSIONS.MAILING_LIST,
      };

      this.permissionsService.isUserHasPermissionsObservable([mailingListPermission]).subscribe((isAllowed: boolean) => {
        if (!isAllowed) {
          observer.next(true);
  
          return;
        }
    
        this.mailChimpService.ping().subscribe(() => {
          if (this.isConnected) {
            this.sidebarSectionsService.selectSection(KEYS.SETTINGS);
            this.navigationService.toMailSettings();
      
            observer.next(false);
    
            return;
          }
      
          const isMailPageVisited = JSON.parse(window.localStorage.getItem('isMailPageVisited')) || false;
      
          if (isMailPageVisited) {
            window.location.href = '/api/mailchimp/authorize';
      
            observer.next(false);
    
            return;
          }
      
          window.localStorage.setItem('isMailPageVisited', JSON.stringify(true));
      
          observer.next(true);
        });
      });
    });
  }
}

