import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {WebsitesIconChangeWebsiteModalService} from '../../../services/modals/websites-icon-change-website/websites-icon-change-website-modal.service';
import {MultiWebsiteService} from '../../../../core/services/multi-website/multi-website.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {EducationTeachersWebsitesService} from '../../../../core/services/education/teachers/websites/education-teachers-websites.service';
import {NavigationService} from '../../../../services/navigation.service';
import {IsPublishingService} from '../../../../services/is-publishing/is-publishing.service';

import {Button} from '../../../../common/models/button/button.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {CourseWebsiteModel} from '../../../../core/models/websites/course-website.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-websites-icon-change-website-modal',
  templateUrl: './websites-icon-change-website-modal.component.html',
  styleUrls: ['./websites-icon-change-website-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class WebsitesIconChangeWebsiteModalComponent implements OnDestroy {
  public classWebsiteButtons: Button[] = [
    new Button('Stay on Class Website', null, 'green', this.stayWithCurrent.bind(this)),
    new Button('Switch to Personal Website', null, 'neutral', this.switchToPersonal.bind(this)),
  ];

  public userWebsiteButtons: Button[] = [
    new Button('Stay on Personal Website', null, 'green', this.stayWithCurrent.bind(this)),
    new Button('Switch to Class Website', null, 'neutral', this.switchToClass.bind(this)),
  ];

  public isActiveWebsiteRelatedToUser: boolean = false;
  public isClassSelectRedirect: boolean = false;
  public isNoClassesError: boolean = false;
  public isBlocked: boolean = false;

  private personalWebsites: WebsiteModel[];
  private educatorWebsites: CourseWebsiteModel[];

  private publishingData: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  public get buttons(): Button[] {
    if (this.isClassSelectRedirect || this.isNoClassesError) {
      return [];
    }

    return this.isActiveWebsiteRelatedToUser ? this.userWebsiteButtons : this.classWebsiteButtons;
  }

  private get isPublishing(): boolean {
    if (!this.publishingData || !this.publishingData.isActiveWebsite) {
      return false;
    }

    return this.publishingData.isPublishing;
  }

  constructor(
    private modalsService: ModalsService,
    private websitesService: WebsitesService,
    private multiWebsiteService: MultiWebsiteService,
    private educationTeachersWebsitesService: EducationTeachersWebsitesService,
    private isPublishingService: IsPublishingService,
    private navigationService: NavigationService,
    private service: WebsitesIconChangeWebsiteModalService,
  ) {
    this.multiWebsiteService.websitesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: WebsiteModel[]) => {
      this.personalWebsites = websites;
    });
    
    this.educationTeachersWebsitesService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: CourseWebsiteModel[]) => {
      this.educatorWebsites = websites;
    });

    this.websitesService.isActiveWebsiteRelatedToUserSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isActiveWebsiteRelatedToUser: boolean) => {
      this.isActiveWebsiteRelatedToUser = isActiveWebsiteRelatedToUser;
    });
  }

  private stayWithCurrent(): void {
    this.navigationService.toHomePage();

    this.closeModal();
  }

  private switchToPersonal(): void {
    if (!this.personalWebsites || this.personalWebsites.length === 0) {
      return;
    }

    this.setActiveWebsite(this.personalWebsites[0].id);
  }

  private setActiveWebsite(websiteId: number): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    if (this.isBlocked) return;

    this.isBlocked = true;
    
    this.multiWebsiteService.setActiveWebsite(websiteId).add(() => {
      this.websitesService.activeWebsiteIdSubject.pipe(take(1)).subscribe((activeWebsiteId: number) => {
        this.navigationService.toHomePage().add(() => {
          this.isBlocked = false;

          this.closeModal();
        });
      });
    });
  }

  private switchToClass(): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    if (this.isBlocked) {
      return;
    }

    if (!this.educatorWebsites || this.educatorWebsites.length === 0) {
      this.isNoClassesError = true;

      return;
    }

    if (this.educatorWebsites.length === 1) {
      this.isBlocked = true;
      
      this.multiWebsiteService.setActiveWebsite(this.educatorWebsites[0].id).add(() => {
        this.websitesService.activeWebsiteIdSubject.pipe(take(1)).subscribe(() => {
          this.navigationService.toHomePage().add(() => {
            this.isBlocked = false;
  
            this.closeModal();
          });
        });
      });

      return;
    }

    this.isClassSelectRedirect = true;

    this.navigationService.toClassesManager();

    window.setTimeout(() => {
      this.closeModal();
    }, 2000);
  }

  private closeModal(): void {
    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
