import {SelectOption} from '../../../../models/select/option/option.model';

export const LABELS = {
  UNDERLINE: 'Underline',
  BOLD: 'Bold',
  SHADOW: 'Shadow',
};

export const CLASSES = {
  UNDERLINE: 'menu-active-underline',
  BOLD: 'menu-active-bold',
  SHADOW: 'menu-active-shadow',
};

export const DEFAULT_OPTIONS = () => [
  new SelectOption(LABELS.UNDERLINE, CLASSES.UNDERLINE),
  new SelectOption(LABELS.BOLD, CLASSES.BOLD, true),
  new SelectOption(LABELS.SHADOW, CLASSES.SHADOW),
];
