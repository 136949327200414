import {FontComplexityModel} from '../../../../core/models/font/complexity/font-complexity.model';

export const COMPLEXITY_KEYS = {
  FAST: 'FAST',
  MODERATE: 'MODERATE',
  SLOW: 'SLOW',
};

export const COMPLEXITIES = {
  [COMPLEXITY_KEYS.FAST]: new FontComplexityModel('Fast', 'complexity green'),
  [COMPLEXITY_KEYS.MODERATE]: new FontComplexityModel('Moderate', 'complexity yellow'),
  [COMPLEXITY_KEYS.SLOW]: new FontComplexityModel('Slow', 'complexity red'),
};

export const COMPLEXITY_RESOLVER = (nOfSelectedVariants: number) => {
  if (nOfSelectedVariants < 3) return COMPLEXITIES[COMPLEXITY_KEYS.FAST];
  if (nOfSelectedVariants < 6) return COMPLEXITIES[COMPLEXITY_KEYS.MODERATE];

  return COMPLEXITIES[COMPLEXITY_KEYS.SLOW];
};
