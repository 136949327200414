import {SetupOptionModel} from '../../option/setup-option.model';

import {IStyleModelOptions} from '../../option/i-style-model-options';

export class BaseScalableModel extends SetupOptionModel {
  public element: HTMLElement;

  private styleProperty: string = null;
  private stylePropertyPx: string = null;

  constructor(
    public key: string,
    public type: string,
    public stylePropertyName: string = null,
    public stylePropertyPxName: string = null,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.initStylesProperties();

    this.value = this.getComputed();
  }

  private initStylesProperties(): void {
    const { styleProperty, stylePropertyPx } = this.getStyleProperties();

    this.styleProperty = styleProperty;
    this.stylePropertyPx = stylePropertyPx;
  }

  private getStyleProperties(): { styleProperty: string, stylePropertyPx: string } {
    const defaultValue = {
      styleProperty: this.stylePropertyName,
      stylePropertyPx: this.stylePropertyPxName,
    };

    try {
      const customValuesJson: string = JSON.parse(this.element.getAttribute('data-scalable-text-setup'));

      return customValuesJson[this.key] || defaultValue;
    } catch (e) {
      return defaultValue;
    }
  }

  public reset(): void {
    this.element.style.removeProperty(this.styleProperty);
    this.element.style.removeProperty(this.stylePropertyPx);

    this.onChange(Number.NaN);
  }

  public onChange(value: number): void {
    this.value = !Number.isNaN(value) ? value : this.getComputed();

    if (!this.isEnabled) return;

    this.element.style.setProperty(this.styleProperty, `${this.value}`);
    this.element.style.setProperty(this.stylePropertyPx, `${this.value}px`);
  }

  private getComputed(): number {
    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: number = Number.parseFloat(computedValue.getPropertyValue(this.styleProperty));

    return Number.isNaN(value) ? 0 : value;
  }
}
