import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ChangeTypes} from './constants';

@Injectable()
export class FullscreenService {
  public isEnlargedSubject: BehaviorSubject<{ value: boolean, changeType?: ChangeTypes }> = new BehaviorSubject<{ value: boolean, changeType?: ChangeTypes }>({ value: false });
  
  private enlargeMethod: string;

  private isFullScreenAvailable: boolean = false;
  private isEnlarged: boolean = false;

  constructor() {
    this.enlargeMethod = this.getRequestFullSizeMethod();

    this.isFullScreenAvailable = !!this.enlargeMethod;

    window.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this));
  }
  
  public enlarge(element: HTMLElement): void {
    if (!this.isFullScreenAvailable || this.isEnlarged) {
      return;
    }
    
    element[this.enlargeMethod]();

    this.isEnlarged = true;

    this.isEnlargedSubject.next({
      value: true,
      changeType: 'mouse',
    });
  }

  public collapse(element: HTMLElement | Document = document): void {
    if (!this.isFullScreenAvailable || !this.isEnlarged) {
      return;
    }

    const collapseMethod: string = this.getCancelFullSizeMethod(element);

    if (!collapseMethod) {
      return;
    }

    element[collapseMethod]();

    this.isEnlarged = false;

    this.isEnlargedSubject.next({
      value: false,
      changeType: 'mouse',
    });
  }

  private onFullscreenChange(): void {
    this.isEnlarged = !!document['fullscreenElement'] || !!document['webkitFullscreenElement'];
    
    this.isEnlargedSubject.next({
      value: this.isEnlarged,
      changeType: 'keyboard',
    });
  }

  private getRequestFullSizeMethod(element: HTMLElement | Document = document.body): string {
    return this.findElementMethod([
      'requestFullscreen',
      'webkitRequestFullscreen',
      'mozRequestFullScreen',
    ], element);
  }

  private getCancelFullSizeMethod(element: HTMLElement | Document = document.body): string {
    return this.findElementMethod([
      'exitFullscreen',
      'webkitCancelFullScreen',
      'mozCancelFullScreen',
      'cancelFullScreen',
    ], element);
  }

  private findElementMethod(methodsList: string[], element: HTMLElement | Document = document.body): string {
    return methodsList.find(method => !!element[method]);
  }
}
