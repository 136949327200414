import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {OPACITY, CUSTOM_OPACITY, DEFAULT_VALUE} from './constants';

export class MenuDropdownItemOpacityModel implements IStyleOption {
  public menu: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.menu = options.block;

    if (!this.menu) return;

    const computed: CSSStyleDeclaration = window.getComputedStyle(this.menu);

    const value: number = parseFloat(computed.getPropertyValue(CUSTOM_OPACITY) || computed.getPropertyValue(OPACITY)) * 100;

    if (value) return this.setValue(isNaN(value) ? void 0 : value.toString());

    this.value = DEFAULT_VALUE;

    this.setValue(this.value);
  }

  public reset(): void {
    if (!this.isEnabled || !this.element || !this.menu) return;

    this.menu.style.removeProperty(CUSTOM_OPACITY);

    const computed = window.getComputedStyle(this.menu);

    const value = parseFloat(computed.getPropertyValue(CUSTOM_OPACITY) || computed.getPropertyValue(OPACITY)) * 100;

    this.value = isNaN(value) ? DEFAULT_VALUE : value.toString();
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.menu) return;

    this.value = value;

    this.menu.style.setProperty(CUSTOM_OPACITY, (parseFloat(value) / 100).toString());
  }
}
