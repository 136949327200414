// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ecommerce-settings {
  margin-left: 70px;
  padding-top: 40px;
}
.ecommerce-settings .ecommerce-settings-header {
  margin-bottom: 20px;
  padding: 0;
}
.ecommerce-settings .strong-link {
  color: black;
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s all linear;
}
.ecommerce-settings .strong-link:hover {
  color: #333333;
}
.ecommerce-settings .text {
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: 300;
}
.ecommerce-settings .text p {
  margin-bottom: 5px;
}
.ecommerce-settings .marked-list {
  list-style: disc;
  margin-left: 20px;
}
.ecommerce-settings .options {
  margin-top: 30px;
}
.ecommerce-settings .options .option {
  margin-bottom: 30px;
}
.ecommerce-settings .options .option .option-header {
  cursor: pointer;
  margin-bottom: 20px;
}
.ecommerce-settings .options .option .option-content .form-group {
  display: flex;
  align-items: center;
}
.ecommerce-settings .options .option .option-content .form-group label {
  margin-right: 5px;
}
.ecommerce-settings .options .option .option-content .form-group .email-input {
  width: 400px;
}
.ecommerce-settings .options .option .option-content .form-group .charge-input {
  width: 150px;
}
.ecommerce-settings .options .option .option-content .form-group .email-input,
.ecommerce-settings .options .option .option-content .form-group .charge-input {
  border-radius: 0;
  border: 1px solid #828282;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/settings/ecommerce/ecommerce-settings.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,iBAAA;AADF;AAGE;EACE,mBAAA;EACA,UAAA;AADJ;AAIE;EACE,YAAA;EACA,iBAAA;EACA,qBAAA;EACA,2BAAA;AAFJ;AAII;EACE,cAAA;AAFN;AAME;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;AAJJ;AAMI;EACE,kBAAA;AAJN;AAQE;EACE,gBAAA;EACA,iBAAA;AANJ;AASE;EACE,gBAAA;AAPJ;AASI;EACE,mBAAA;AAPN;AASM;EACE,eAAA;EACA,mBAAA;AAPR;AAWQ;EACE,aAAA;EACA,mBAAA;AATV;AAWU;EACE,iBAAA;AATZ;AAYU;EACE,YAAA;AAVZ;AAaU;EACE,YAAA;AAXZ;AAcU;;EAEE,gBAAA;EACA,yBAAA;AAZZ","sourcesContent":["\n\n.ecommerce-settings {\n  margin-left: 70px;\n  padding-top: 40px;\n\n  .ecommerce-settings-header {\n    margin-bottom: 20px;\n    padding: 0;\n  }\n\n  .strong-link {\n    color: black;\n    font-weight: bold;\n    text-decoration: none;\n    transition: 0.2s all linear;\n\n    &:hover {\n      color: lighten(black, 20%);\n    }\n  }\n\n  .text {\n    margin-bottom: 30px;\n    font-size: 17px;\n    font-weight: 300;\n\n    p {\n      margin-bottom: 5px;\n    }\n  }\n\n  .marked-list {\n    list-style: disc;\n    margin-left: 20px;\n  }\n\n  .options {\n    margin-top: 30px;\n\n    .option {\n      margin-bottom: 30px;\n\n      .option-header {\n        cursor: pointer;\n        margin-bottom: 20px;\n      }\n\n      .option-content {\n        .form-group {\n          display: flex;\n          align-items: center;\n\n          label {\n            margin-right: 5px;\n          }\n\n          .email-input {\n            width: 400px;\n          }\n\n          .charge-input {\n            width: 150px;\n          }\n\n          .email-input,\n          .charge-input {\n            border-radius: 0;\n            border: 1px solid #828282;\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
