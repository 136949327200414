import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../modals.service';

@Injectable()
export class ImageFileNameAsTitleModalService {
  public readonly id = 'image-file-name-as-title-modal';

  public isOpened = false;

  public onFileNameAsTitleSubject: BehaviorSubject<{ isImageFileNameAsTitle: boolean, images: File[] }> = new BehaviorSubject<{ isImageFileNameAsTitle: boolean, images: File[] }>(null);

  public constructor(private modalsService: ModalsService) {
  }

  public open(images: File[]): void {
    this.modalsService.open(this.id, images);

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
