import {Component} from '@angular/core';
import {AppAnimations} from '../../app-animations';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class NotFoundComponent {
  constructor() {}
}
