export class SupportStatisticBarModel {
  constructor(
    public key: string,
    public title: string,
    public min: number,
    public width: string,
    public isEnabled: boolean,
    public isDone: boolean,
  ) {}
}
