// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep ::-webkit-color-swatch {
  border: none;
}
:host ::ng-deep ::-moz-color-swatch {
  border: none;
}

.transparent-pattern {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('transparent-pattern.60c3a07acba9b8ef.png') repeat;
  background-size: contain;
  border-radius: 7px;
}
.transparent-pattern .color-picker-wrapper {
  border-radius: 5px;
  border: 1px solid #ccc;
}
.transparent-pattern .color-picker-wrapper:hover {
  cursor: pointer;
}
.transparent-pattern .color-picker-wrapper .color-picker-input {
  width: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/common/color-picker/color-picker.component.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;;AAOA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kEAAA;EACA,wBAAA;EACA,kBAAA;AAJF;AAME;EACE,kBAAA;EACA,sBAAA;AAJJ;AAMI;EACE,eAAA;AAJN;AAOI;EACE,WAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;AALN","sourcesContent":["@import \"../../../styles/colors\";\n\n:host ::ng-deep {\n  ::-webkit-color-swatch {\n    border: none;\n  }\n\n  ::-moz-color-swatch {\n    border: none;\n  }\n}\n\n.transparent-pattern {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background: url(../../../assets/images/transparent-pattern.png) repeat;\n  background-size: contain;\n  border-radius: 7px;\n\n  .color-picker-wrapper {\n    border-radius: 5px;\n    border: 1px solid $primary;\n\n    &:hover {\n      cursor: pointer;\n    }\n\n    .color-picker-input {\n      width: 100%;\n      padding: 0;\n      margin: 0;\n      opacity: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
