export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `અમને તેની જાણ થાય તે પહેલાં જ, અમે જમીન છોડી દીધી હતી.`,
      `આકાશ વાદળરહિત તથા ઘેરા વાદળી રંગનું હતું.`,
      `તેમના તમામ સાધન અને ઓજાર એક કે બીજા સ્વરૂપે કાર્યક્ષમ છે.`,
    ],
    [KEYS.ALPHABET]: [
      'અઆઇઈઉઊએઐઓઔઅંઅ:ઍઑત્રઋરૂકખગઘચછજઝટઠડઢણતથદધનપફબભમયરલવસશષહળક્ષજ્ઞ₹',
    ],
    [KEYS.PARAGRAPH]: [
      `કેમકે ભાગોની સૂક્ષ્મ રચનાને સમજવામાં મારી ગતિ બહુ મોટી મુશ્કેલી હતી, માટે મારા પહેલાં પ્રયોજનની વિપરિત મેં આ વિસ્તૃત લંબાઈને બનાવવાનો નિર્ણય કયો જે લગભગ આઠ ફૂટ ઊંચી અને આ જ પ્રમાણમાં મોટી હતી. આ સંકલ્પ પછી અને મારી સામગ્રી સફળતાપૂર્વક એકત્ર કરવા અને વ્યવસ્થિત કરવામાં કેટલાક મહિના લગાવ્યાં પછી મેં આની શરૂઆત કરી.`
    ],
    [KEYS.NUMERALS]: [
      '૦૧૨૩૪૫૬૭૮૯',
    ],
  };
};
