import {PostModel} from './post.model';

import {TagDto} from './tag/tag.dto';

export class PostDto {
  constructor(public Id: number,
              public WebsiteId: number,
              public Html: string,
              public Title: string,
              public Description: string,
              public IsPublished: boolean,
              public PublishedDate: string,
              public UpdatedAt: string,
              public Tags?: TagDto[]) {
  }

  public static normalize(res: PostDto): PostModel {
    if (!res) return null;

    return new PostModel(
      res.Id,
      res.WebsiteId,
      res.Html,
      res.Title,
      res.Description,
      res.IsPublished,
      res.PublishedDate,
      res.UpdatedAt,
      res.Tags.map(tag => TagDto.normalize(tag)),
    );
  }

  public static toRequest(post: PostModel): PostDto {
    return new PostDto(
      post.id,
      post.websiteId,
      post.html,
      post.title,
      post.description,
      post.isPublished,
      post.publishedDate,
      post.updatedAt,
    );
  }
}
