import {SelectOption} from '../../../select/option/option.model';

export const ATTRIBUTE = 'data-block-width';

export const KEYS = {
  CONTENT_WIDTH: 'CONTENT_WIDTH',
  FULL_WIDTH: 'FULL_WIDTH',
};

export const CLASSES = {
  [KEYS.FULL_WIDTH]: 'full-width',
};

export const CLASSES_LIST = Object.keys(CLASSES).map(key => CLASSES[key]);

export const OPTIONS: SelectOption[] = [
  new SelectOption('Content Width', KEYS.CONTENT_WIDTH),
  new SelectOption('Full Width', KEYS.FULL_WIDTH),
];

