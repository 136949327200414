import * as moment from 'moment';

import {SupportMetaItemModel} from './support-meta-item.model';
import {AccountDto} from '../account.dto';

import {TIME_FORMATTER, TIME_FORMATTER_DATE} from './constants';

export class SupportMetaItemDto {
  constructor(public Id?: number,
              public AdminId?: number,
              public UserId?: number,
              public Method?: string,
              public Summary?: string,
              public Notes?: string,
              public PlanTimeMin?: number,
              public AddOnTimeMin?: number,
              public Status?: string,
              public Admin?: AccountDto,
              public SupportedAt?: string,
              public CreatedAt?: string,
              public UpdatedAt?: string) {}

  public static normalize(res: SupportMetaItemDto): SupportMetaItemModel {
    if (!res) return null;

    return new SupportMetaItemModel(
      res.Id,
      res.AdminId,
      res.UserId,
      res.Method,
      res.Summary,
      res.Notes,
      res.PlanTimeMin,
      res.AddOnTimeMin,
      res.Status,
      AccountDto.normalize(res.Admin),
      moment.utc(res.SupportedAt).format(TIME_FORMATTER_DATE),
      moment(res.CreatedAt).format(TIME_FORMATTER),
      moment(res.UpdatedAt).format(TIME_FORMATTER),
    );
  }
}
