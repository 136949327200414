import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';

const SECTIONS = {
  WEBSITE: {
    title: 'WEBSITE',
    path: 'website',
  },
  CONTACT_INFO: {
    title: 'CONTACT INFO',
    path: 'contact-info',
  },
  // ECOMMERCE: {
  //   title: 'ECOMMERCE',
  //   path: 'ecommerce',
  //   disabled: true,
  // },
  // SEO: {
  //   title: 'SEO',
  //   path: 'seo',
  //   disabled: true
  // },
  MAILING_LIST: {
    title: 'MAILCHIMP',
    path: 'mailing-list',
    requiredPermissions: [
      {
        type: 'permission',
        value: PERMISSIONS.MAILING_LIST,
      },
    ],
  },
  SOCIAL_NETWORKS: {
    title: 'SOCIAL NETWORKS',
    path: 'social-networks',
  },
  SECURITY: {
    title: 'SECURITY',
    path: 'security',
  },
  PAYPAL: {
    title: 'PAYPAL',
    path: 'paypal',
    requiredPermissions: [
      {
        type: 'permission',
        value: PERMISSIONS.PAYPAL_ACCESS,
      },
    ],
  },
};

export {
  SECTIONS
};
