import {SelectOption} from '../../../core/models/select/option/option.model';
import {TabSettingsModel} from '../../../core/models/sidebar/tabs-settings.model';

import {CONTENT_TYPE_KEYS} from '../../../core/services/google/fonts/constants';

export const MAX_COLUMN_WIDTH = 330;
export const MIN_CELL_HEIGHT = 300;

export const TABS_KEYS = {
  ALL: 'ALL',
  ADDED: 'ADDED',
  DEFAULT: 'DEFAULT',
};

export const TABS: () => TabSettingsModel[] = () => [
  new TabSettingsModel(TABS_KEYS.ALL, 'ALL FONTS', false),
  new TabSettingsModel(TABS_KEYS.ADDED, 'ADDED FONTS', false),
  new TabSettingsModel(TABS_KEYS.DEFAULT, 'ALL DEFAULT FONTS', false),
];

export const CONTENT_TYPE_OPTIONS = (): SelectOption[] => [
  new SelectOption('Sentence', CONTENT_TYPE_KEYS.SENTENCE, true),
  new SelectOption('Alphabet', CONTENT_TYPE_KEYS.ALPHABET),
  new SelectOption('Paragraph', CONTENT_TYPE_KEYS.PARAGRAPH),
  new SelectOption('Numerals', CONTENT_TYPE_KEYS.NUMERALS),
  new SelectOption('Custom', CONTENT_TYPE_KEYS.CUSTOM),
];

export const LIST_OUTPUT_TYPE_KEYS = {
  GRID: 'GRID',
  LIST: 'LIST',
};

export const LIST_OUTPUT_TYPE_ICONS = {
  [LIST_OUTPUT_TYPE_KEYS.GRID]: 'fa fa-th',
  [LIST_OUTPUT_TYPE_KEYS.LIST]: 'fa fa-bars',
};

export const SORT_BY_KEYS = {
  ALPHA: 'alpha',
  DATE: 'date',
  POPULARITY: 'popularity',
  STYLE: 'style',
  TRENDING: 'trending',
};
