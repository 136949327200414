import { Injectable } from '@angular/core';
import { throttle } from 'lodash';

import {EventsService} from '../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../core/services/iframe/iframe.service';
import {ModalsService} from '../../../shared/services/modals/modals.service';
import {BlocksDeleteModalService} from '../../../shared/services/modals/blocks-delete/blocks-delete-modal.service';

@Injectable()
export class DragService {
  private zone: string;
  isDropZone: boolean;
  type: string;
  data: any;
  isDragging: boolean;
  isCopy: boolean;
  startEvent: any;
  dropped: boolean;
  isMouseDown: boolean = false;

  private dragThrottleDelay: number = 30;

  frame: any;
  framePosition: any;

  types = {
    mouse: 'mouse',
    drag: 'drag',
    touch: 'touch'
  };

  private isEditorLoaded: boolean = false;

  constructor(private eventsService: EventsService,
              private iFrameService: IFrameService,
              private modalsService: ModalsService,
              private blocksDeleteModalService: BlocksDeleteModalService) {
    this.drag = throttle(this.drag, this.dragThrottleDelay);

    this.iFrameService.isLoadedSubject.subscribe(value => {
      this.frame = this.iFrameService.iFrame;
      this.isEditorLoaded = value;
    });
  }

  startDrag(zone: string) {
    this.zone = zone;
  }

  accepts(zone: string): boolean {
    return zone === this.zone;
  }

  drag(data) {
    if (this.frame && this.framePosition) {
      data.x = data.x - this.framePosition.left;
      data.y = data.y - this.framePosition.top;

      if (this.isEditorLoaded) this.frame.contentWindow.dragEmulation(data);
    }
  }

  dragStart() {
    this.frame = this.iFrameService.iFrame;

    if (this.frame) {
      if (this.data.BlockID) this.frame.contentWindow.enableDndMode({block: this.data});
      this.frame.style.pointerEvents = 'none';
      this.framePosition = this.frame.getBoundingClientRect();
    }
  }

  drop(data) {
    if (!this.isDragging) return;

    this.isDragging = false;

    if (this.framePosition) {
      data.x = data.x - this.framePosition.left;
      data.y = data.y - this.framePosition.top;
      data.block = { ...this.data };

      if (this.frame) {
        if (this.frame.contentWindow && this.isEditorLoaded) {
          data.block.BlockTemplateId = data.block.BlockID;
          data.block.BlockID = null;

          if (data.block.BlockTemplateId === 114 && this.isDroppedInFrame(data.event.target)) return this.openPagesRearrangementWarningModal();
          if (data.block.BlockTemplateId === 135 && this.isDroppedInFrame(data.event.target)) return this.openBlocksRemovingModal();

          if (data.block.IsDefaultPortfolio) {
            data.html = data.block.Html;
          }

          this.frame.contentWindow.dropEmulation(data);
          this.frame.contentWindow.disableDndMode();
        }  //  TODO(max) => fix
        this.frame.style.pointerEvents = 'all';
      }

      this.data = null;
    }
  }

  private isDroppedInFrame(target) {
    return target && target.classList && target.classList.contains('fullscreen-wrapper');
  }

  private openPagesRearrangementWarningModal(): void {
    this.modalsService.open('pages-rearrangement-modal');
  }

  private openBlocksRemovingModal(): void {
    this.modalsService.open(this.blocksDeleteModalService.id);
  }
}
