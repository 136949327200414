import {Component} from '@angular/core';

import {ImageFileNameAsTitleModalService} from '../../../services/modals/image-file-name-as-title/image-file-name-as-title-modal.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-image-file-name-as-title-modal',
  templateUrl: './image-file-name-as-title-modal.component.html',
  styleUrls: ['./image-file-name-as-title-modal.component.scss']
})
export class ImageFileNameAsTitleModalComponent {
  public buttons: Button[] = [
    {
      text: `Yes`,
      className: 'green',
      onClick: this.onYesHandler.bind(this),
    },
    {
      text: `No`,
      className: 'neutral',
      onClick: this.onNoHandler.bind(this),
    },
  ];

  private images: File[];

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: ImageFileNameAsTitleModalService) {
  }

  public onOpen(images: File[]): void {
    this.images = images;
  }

  public onYesHandler(): void {
    this.service.onFileNameAsTitleSubject.next({
      isImageFileNameAsTitle: true,
      images: this.images,
    });

    this.close();
  }

  public onNoHandler(): void {
    this.service.onFileNameAsTitleSubject.next({
      isImageFileNameAsTitle: false,
      images: this.images,
    });

    this.close();
  }

  public close(): void {
    this.service.close();

    this.service.onFileNameAsTitleSubject.next(null);
  }
}
