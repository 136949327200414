import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {SupportHistoryService} from '../../../../../core/services/support-history/support-history.service';
import {AuthService} from '../../../../../auth/auth.service';
import {PaymentSubscriptionsService} from '../../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {NavigationService} from '../../../../../services/navigation.service';

import {SupportMetaItemModel} from '../../../../../core/models/accounts/support-meta/support-meta-item.model';
import {AccountModel} from '../../../../../core/models/accounts/account.model';
import {AccountSummaryModel} from '../../../../../core/models/accounts/summary/account-summary.model';
import {SubscriptionModel} from '../../../../../core/models/payment/subscriptions/subscription.model';

import {AppAnimations} from '../../../../../app-animations';

@Component({
  selector: 'app-support-history',
  templateUrl: './support-history.component.html',
  styleUrls: ['./support-history.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SupportHistoryComponent implements OnInit, OnDestroy {
  public history: SupportMetaItemModel[] = null;

  public currentSubscription: SubscriptionModel;

  public isAnyTimeLeft: boolean = false;
  public isFullPlanTime: boolean = false;
  public isFullAddOnTime: boolean = false;

  private account: AccountModel;
  private accountSummary: AccountSummaryModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isCurrentSubscriptionFetched(): boolean {
    return this.paymentSubscriptionsService.isCurrentSubscriptionFetched;
  }

  constructor(
    private service: SupportHistoryService,
    private authService: AuthService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private navigationService: NavigationService,
  ) {
    this.service.fetch();

    this.authService.fetchAccountSummary();
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((account: AccountModel) => !!account)
    ).subscribe((account: AccountModel) => {
      this.account = account;

      this.initTimeData();
    });

    this.authService.accountSummarySubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((accountSummary: AccountSummaryModel) => !!accountSummary)
    ).subscribe((accountSummary: AccountSummaryModel) => {
      this.accountSummary = accountSummary;

      this.initTimeData();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((subscription: SubscriptionModel) => !!subscription)
    ).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      this.initTimeData();
    });

    this.service.supportHistorySubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((history: SupportMetaItemModel[]) => {
      this.history = history;
    });
  }

  private initTimeData(): void {
    this.isAnyTimeLeft = !!this.accountSummary && (this.accountSummary.addOnSupportTimeMin > 0 || this.accountSummary.planSupportTimeMin > 0);
    this.isFullPlanTime = !!this.accountSummary && !!this.currentSubscription && this.accountSummary.planSupportTimeMin === this.currentSubscription.plan.includedSupportTimeMin && this.accountSummary.planSupportTimeMin > 0;
    this.isFullAddOnTime = !!this.account && !!this.accountSummary && this.accountSummary.addOnSupportTimeMin === this.account.totalAddOnSupportTime && this.account.totalAddOnSupportTime > 0;
  }

  public toDashboard(): void {
    this.navigationService.toStatistic();
  }

  public toPlansPurchase(): void {
    this.navigationService.toPurchase();
  }

  public toAddOnsPurchase(): void {
    this.navigationService.toAddOns();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
