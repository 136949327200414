import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AdminEducationTeachersService} from '../../../../../core/services/admin/education/teachers/admin-education-teachers.service';

import {EducationTeacherModel} from '../../../../../core/models/education/teachers/education-teacher.model';

@Component({
  selector: 'app-admin-education-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class AdminEducationTeachersComponent implements OnInit, OnDestroy {
  public list: EducationTeacherModel[];

  public itemToDelete: EducationTeacherModel = null;

  public isDetailsView: boolean = false;
  public isEditView: boolean = false;
  public isItemRemoving: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationTeachersService) {
    this.service.fetchAll();
  }

  public ngOnInit(): void {
    this.service.teachersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list: EducationTeacherModel[]) => {
      this.list = list;
    });
  }

  public openTeacher(item: EducationTeacherModel): void {
    this.service.fetchOne(item.id);

    this.isDetailsView = true;
  }

  public openEditModal(item: EducationTeacherModel): void {
    this.service.fetchOne(item.id);

    this.isEditView = true;
  }

  public onDetailsViewClose(): void {
    this.isDetailsView = false;
  }

  public onEditViewClose(): void {
    this.isEditView = false;
  }

  public openDeleteModal(item: EducationTeacherModel): void {
    this.isItemRemoving = true;

    this.itemToDelete = item;
  }

  public onItemDeleteClose(): void {
    this.isItemRemoving = false;

    this.itemToDelete = null;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
