import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, BehaviorSubject} from 'rxjs';

import {CustomSubscriptionDto} from '../../../../../models/custom-subscription/custom-subscription.dto';
import {CustomSubscriptionModel} from '../../../../../models/custom-subscription/custom-subscription.model';
import {IDefaultResponse} from '../../../../../models/responses/i-default-reponse';

@Injectable()
export class CustomSubscriptionsHttpService {
  private prefix: string = 'api/admin';
  private model: string = 'custom-subscriptions';

  public customSubscriptionsSubject: BehaviorSubject<CustomSubscriptionModel[]> = new BehaviorSubject<CustomSubscriptionModel[]>([]);

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get(`${this.prefix}/${this.model}`).subscribe((res: CustomSubscriptionDto[]) => {
      const subscriptions: CustomSubscriptionModel[] = res.map((subscription: CustomSubscriptionDto) => CustomSubscriptionDto.normalize(subscription));

      this.customSubscriptionsSubject.next(subscriptions);
    });
  }

  public create(subscription: CustomSubscriptionDto) {
    return this.http.post(`${this.prefix}/${this.model}`, subscription).subscribe(() => {
      this.getAll();
    });
  }

  public delete(subscriptionId: number): Observable<IDefaultResponse> {
    return this.http.delete(`${this.prefix}/${this.model}/${subscriptionId}`);
  }
}
