// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
:host ::ng-deep .font-size-select-wrapper {
  width: 90px;
}
:host ::ng-deep .font-size-select-wrapper .fonts-manager-select-options {
  width: 100% !important;
}
:host .font-size-slider-wrapper {
  flex-grow: 1;
  margin: 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/fonts-manager/font-size-wrapper/fonts-manager-font-size-wrapper.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AADF;AAII;EACE,WAAA;AAFN;AAIM;EACE,sBAAA;AAFR;AAUE;EACE,YAAA;EACA,cAAA;AARJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host {\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n\n  ::ng-deep {\n    .font-size-select-wrapper {\n      width: 90px;\n\n      .fonts-manager-select-options {\n        width: 100% !important;\n      }\n    }\n  }\n\n  .font-size-select-wrapper {\n  }\n\n  .font-size-slider-wrapper {\n    flex-grow: 1;\n    margin: 0 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
