import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {EditorControlButtonsService} from './editor-control-buttons.service';
import {EditorDevicesService} from '../core/services/editor-devices/editor-devices.service';
import {EventsService} from '../core/services/interaction/events/events.service';
import {SidebarSectionsService} from './sidebar-sections.service';

import {DEVICES} from '../core/services/editor-devices/constants';

// todo (evgen) replace all calls of this service by iframe service
@Injectable()
export class ResizeService {
  private windowRef: HTMLElement;
  private sidebarWrapperRef: HTMLElement;
  private appMainRef: HTMLElement;

  private isDesktopDevice: boolean;
  private isSectionsCollapsed: boolean = false;

  private minWidths = {
    desktop: 1025,
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private get window(): HTMLElement {
    if (!this.windowRef) this.windowRef = this.getElementByClassName('window');

    return this.windowRef;
  }

  private get sidebarWrapper(): HTMLElement {
    if (!this.sidebarWrapperRef) this.sidebarWrapperRef = this.getElementByClassName('sidebar-short');

    return this.sidebarWrapperRef;
  }

  private get appMain(): HTMLElement {
    if (!this.appMainRef) this.appMainRef = this.getElementByTagName('app-main');

    return this.appMainRef;
  }

  private get sidebarWidth() {
    if (this.isSectionsCollapsed) return 0;

    const { sidebarWrapper } = this;

    return sidebarWrapper ? sidebarWrapper.clientWidth : 0;
  }

  private get iFrameWrapper() {
    return this.getElementById('sandbox-wrapper');
  }

  private get windowWidth(): number {
    const { window } = this;
    if (!window) return 0;
    let minWidth = window.clientWidth;
    Object.keys(this.minWidths).forEach(key => {
      if (window.classList.contains(key) && this.minWidths[key] > minWidth) minWidth = this.minWidths[key];
    });
    return minWidth;
  }

  private get scale() {
    const { window, windowWidth } = this;

    if (!window || !windowWidth) return;

    this.setAppMainWidth();

    return window.parentElement.clientWidth / windowWidth;
  }

  private get isDesktop() {
    const { windowWidth, appMain } = this;

    if (!appMain || !windowWidth) return false;

    return appMain.clientWidth >= windowWidth;
  }

  constructor(private editorControlButtonsService: EditorControlButtonsService,
              private editorDevicesService: EditorDevicesService,
              private sidebarSectionsService: SidebarSectionsService,
              private eventsService: EventsService) {
    this.editorDevicesService.onDeviceChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((deviceKey: string) => {
      this.isDesktopDevice = deviceKey === DEVICES.DESKTOP;
    });

    this.sidebarSectionsService.isSectionsCollapsedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isSectionsCollapsed: boolean) => {
      this.isSectionsCollapsed = isSectionsCollapsed;

      this.onResize(true);
    });

    this.eventsService.addFrameListener('resize', this.onResize.bind(this, true))
  }

  public onResize(force?: boolean): boolean {
    this.setAppMainWidth();

    if (!force && this.isDesktop || !this.isDesktopDevice) return this.clearResizeChanges();

    const { iFrameWrapper, scale } = this;

    if (!iFrameWrapper || !scale) return false;

    return this.setTransform(iFrameWrapper, [
      {
        key: 'scale',
        value: scale,
      },
    ]);
  }

  private setAppMainWidth(): void {
    const { appMain } = this;

    if (!appMain) return;

    const value: string = `${appMain.parentElement.clientWidth - this.sidebarWidth}px`;

    if (appMain.style.width === value) return;

    appMain.style.width = value;
  }

  private clearResizeChanges(): boolean {
    this.resetTransform(this.iFrameWrapper);

    return true;
  }

  private setTransform(elem: HTMLElement, pairs: [{ key: string, value: any }]) {
    if (!elem) return false;

    elem.style.transform = pairs.map(pair => `${pair.key}(${pair.value})`).join(' ');

    return true;
  }

  private resetTransform(elem: HTMLElement) {
    if (!elem) return false;

    elem.style.transform = '';

    return true;
  }

  private getElementByClassName(className): HTMLElement {
    return <HTMLElement>document.getElementsByClassName(className)[0];
  }

  private getElementByTagName(tagName): HTMLElement {
    return <HTMLElement>document.getElementsByTagName(tagName)[0];
  }

  private getElementById(id): HTMLElement {
    return <HTMLElement>document.getElementById(id);
  }
}
