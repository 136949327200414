// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .publishing-in-progress-modal > .modal > .modal-body {
  padding: 25px !important;
}
:host ::ng-deep .publishing-in-progress-modal > .modal > .modal-body .publishing-in-progress-modal-body {
  font-size: 16px;
  line-height: normal;
  overflow-x: auto;
}
:host ::ng-deep .publishing-in-progress-modal > .modal > .modal-body .buttons-wrapper {
  display: block !important;
  margin: 20px 20px 5px 20px !important;
}
:host ::ng-deep .publishing-in-progress-modal > .modal > .modal-body .buttons-wrapper .vs-button {
  display: block;
  width: 70px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/publishing-in-progress/publishing-in-progress-modal.component.scss"],"names":[],"mappings":"AAKM;EACE,wBAAA;AAJR;AAMQ;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAJV;AAOQ;EACE,yBAAA;EACA,qCAAA;AALV;AAOU;EACE,cAAA;EACA,WAAA;EACA,kBAAA;AALZ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .publishing-in-progress-modal {\n    & > .modal {\n      & > .modal-body {\n        padding: 25px !important;\n\n        .publishing-in-progress-modal-body {\n          font-size: 16px;\n          line-height: normal;\n          overflow-x: auto;\n        }\n\n        .buttons-wrapper {\n          display: block !important;\n          margin: 20px 20px 5px 20px !important;\n\n          .vs-button {\n            display: block;\n            width: 70px;\n            text-align: center;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
