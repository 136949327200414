import {Component, ElementRef, Input, Renderer2} from '@angular/core';

@Component({
  selector: 'app-help-tooltip',
  templateUrl: './help-tooltip.component.html',
  styleUrls: ['./help-tooltip.component.scss'],
})
export class HelpTooltipComponent {
  @Input() text: string;
  
  isActive: boolean = false;

  private removeWheelListener;
  private removeMouseUpListener;

  constructor(private elem: ElementRef,
              private renderer: Renderer2) {
    this.closeWindow = this.closeWindow.bind(this);
  }

  closeWindow(e) {
    if (this.elem.nativeElement.firstChild.contains(e.target) && !e.target.classList.contains('hint')) return;

    this.toggleVisibility(false);
  }

  public onClick(event, element) {
    event.preventDefault();
    event.stopPropagation();

    this.setTooltipPosition(element);
    this.toggleVisibility(!this.isActive);
  }

  public setTooltipPosition(element) {
    const triangle = element.querySelector('.triangle');
    const text = element.querySelector('.text');

    if (!triangle || !text) return;

    const { top } = element.getBoundingClientRect();

    triangle.style.top = `${top + element.clientHeight / 2}px`;
    text.style.top = `${top + element.clientHeight / 2}px`;
  }

  private toggleVisibility(value) {
    this.isActive = value;

    if (this.isActive) return this.attachMouseListeners();

    return this.removeMouseListeners();
  }

  private attachMouseListeners() {
    this.removeWheelListener = this.renderer.listen('document', 'wheel', this.closeWindow);
    this.removeMouseUpListener = this.renderer.listen('document', 'mouseup', this.closeWindow);
  }

  private removeMouseListeners() {
    if (this.removeWheelListener) this.removeWheelListener();
    if (this.removeMouseUpListener) this.removeMouseUpListener();
  }
}
