// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-app-settings {
  height: 100%;
  margin: 15px;
}
.admin-app-settings .title {
  margin-bottom: 10px;
}
.admin-app-settings .maintenance-overlay-toggle {
  display: inline-block;
  border: 1px solid black;
  background: white;
  padding: 4px 8px;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 40px;
}
.admin-app-settings .maintenance-overlay-toggle:hover {
  cursor: pointer;
  background: black;
  color: white;
}
.admin-app-settings .app-settings {
  width: 500px;
}
.admin-app-settings .app-settings tr:not(:last-child) {
  border-bottom: 1px solid #c3c3c3;
}
.admin-app-settings .app-settings tr td {
  padding: 4px 8px;
}
.admin-app-settings .app-settings tr td:first-child {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/admin-panel/app-settings/app-settings.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,qBAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AAAN;AAIE;EACE,YAAA;AAFJ;AAKM;EACE,gCAAA;AAHR;AAMM;EACE,gBAAA;AAJR;AAMQ;EACE,yBAAA;AAJV","sourcesContent":[".admin-app-settings {\n  height: 100%;\n  margin: 15px;\n\n  .title {\n    margin-bottom: 10px;\n  }\n\n  .maintenance-overlay-toggle {\n    display: inline-block;\n    border: 1px solid black;\n    background: white;\n    padding: 4px 8px;\n    color: black;\n    text-align: center;\n    text-transform: uppercase;\n    font-size: 11px;\n    user-select: none;\n    margin-bottom: 40px;\n\n    &:hover {\n      cursor: pointer;\n      background: black;\n      color: white;\n    }\n  }\n\n  .app-settings {\n    width: 500px;\n\n    tr {\n      &:not(:last-child) {\n        border-bottom: 1px solid #c3c3c3;\n      }\n\n      td {\n        padding: 4px 8px;\n\n        &:first-child {\n          text-transform: uppercase;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
