import {SelectOption} from '../../../select/option/option.model';

export const KEYS = {
  FIT_INTO_VIEW: 'FIT_INTO_VIEW',
  SCROLLABLE_VIEW: 'SCROLLABLE_VIEW',
};

export const ATTRIBUTES = {
  MOBILE_VIEW_ALBUM_SETUP: 'data-mobile-view-album-setup',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('Fit Into View', KEYS.FIT_INTO_VIEW),
  new SelectOption('Scrollable View', KEYS.SCROLLABLE_VIEW),
];

export const DEFAULT_OPTION: SelectOption = OPTIONS[0];
