import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AdminEducationClassesService} from '../../../../../../core/services/admin/education/classes/admin-education-classes.service';
import {AdminEducationInstitutionsService} from '../../../../../../core/services/admin/education/institutions/admin-education-institutions.service';

import {EducationNewClassModel} from '../../../../../../core/models/education/classes/education-new-class.model';
import {EducationInstitutionModel} from '../../../../../../core/models/education/institutions/education-institution.model';
import {NestedSelectOption} from '../../../../../../core/models/select/nested/nested-select-option.model';
import {EducationClassModel} from '../../../../../../core/models/education/classes/education-class.model';

@Component({
  selector: 'app-admin-education-add-class-modal',
  templateUrl: './add-class-modal.component.html',
  styleUrls: ['./add-class-modal.component.scss']
})
export class AdminEducationAddClassModalComponent implements OnInit, OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public classItem: EducationNewClassModel = new EducationNewClassModel();

  public institutions: EducationInstitutionModel[];
  public institutionsOptions: NestedSelectOption[];

  public error: string = '';

  public isSignUpCodeFieldFocused: boolean = false;
  public isStartDateFieldFocused: boolean = false;
  public isEndDateFieldFocused: boolean = false;

  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationClassesService,
              private insitutionsService: AdminEducationInstitutionsService) {
    this.insitutionsService.fetchAll();
  }

  public ngOnInit(): void {
    this.insitutionsService.institutionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list: EducationInstitutionModel[]) => {
      this.institutions = list;

      if (!list) {
        this.institutionsOptions = null;

        return;
      }

      this.institutionsOptions = list.map((item: EducationInstitutionModel) => {
        return new NestedSelectOption(
          item.name,
          `${item.id}`,
        );
      });
    });
  }

  public onInstitutionSelect(options: NestedSelectOption[]): void {
    const institute: NestedSelectOption = options.find((item: NestedSelectOption) => item.isSelected);
    
    this.classItem.institutionId = institute ? Number.parseInt(institute.value) : void 0;
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.key !== 'Enter') return;

    this.submit();
  }

  public onSignUpCodeFieldFocus(): void {
    this.isSignUpCodeFieldFocused = true;
  }

  public onSignUpCodeFieldBlur(): void {
    this.isSignUpCodeFieldFocused = false;
  }

  public onStartDateFieldFocus(): void {
    this.isStartDateFieldFocused = true;
  }

  public onStartDateFieldBlur(): void {
    this.isStartDateFieldFocused = false;
  }

  public onEndDateFieldFocus(): void {
    this.isEndDateFieldFocused = true;
  }

  public onEndDateFieldBlur(): void {
    this.isEndDateFieldFocused = false;
  }

  public submit(): void {
    this.classItem.validate();

    if (!this.classItem.isValid) return;

    this.isLoading = true;
    
    this.service.create(new EducationClassModel(
      void 0,
      this.classItem.institutionId,
      void 0,
      void 0,
      void 0,
      void 0,
      this.classItem.name, 
      this.classItem.number,
      this.classItem.signUpCode,
      this.classItem.description,
      this.classItem.startDate,
      this.classItem.endDate,
    )).subscribe((err: string) => {
      if (!err) {
        this.closeHandler.emit();

        return;
      }

      this.error = err;

      this.isLoading = false;
    });
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
