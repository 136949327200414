import {Component, AfterViewInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';

import {Subject, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';
import {PaymentService} from '../../../../core/services/payment/payment.service';

import {PaymentModel} from '../../../../core/models/payment/payment.model';
import {CardModel} from '../../../../core/models/payment/card/card.model';

import {environment} from '../../../../../environments/environment';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class CreditCardFormComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardInfo') cardInfo: ElementRef;

  @Output() cardSaveHandler: EventEmitter<CardModel> = new EventEmitter<CardModel>();

  public error: string;

  public isCardsExists: boolean = false;

  private paymentData: PaymentModel;

  private key: string = 'CreditCardFormComponent';

  private card: any;
  private cardHandler = this.onChange.bind(this);

  private stripe: any;
  private elements: any;

  private ngUnsubscribe = new Subject<void>();

  private get window(): any {
    return window;
  }

  constructor(private cd: ChangeDetectorRef,
              private paymentService: PaymentService,
              private loaderService: ContentLoaderService) {
    this.stripe = this.window.Stripe(environment.stripe.publicKey);
    this.elements = this.stripe.elements();

    this.paymentService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: PaymentModel) => {
      this.paymentData = data;

      this.isCardsExists = !!data && !!data.cards && data.cards.length > 0;
    });
  }

  public ngAfterViewInit(): void {
    const style = {
      base: {
        lineHeight: '22px',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
      }
    };

    this.card = this.elements.create('card', { style });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  public ngOnDestroy(): void {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onChange({ error }) {
    this.error = error ? error.message : null;
    this.cd.detectChanges();
  }

  // async ok
  public async onSubmit() {
    this.loaderService.show(this.key);

    try {
      const { token, error } = await this.stripe.createToken(this.card);

      if (error) {
        return console.log('Something is wrong:', error);
      }

      this.paymentService.saveCard(token).pipe(
        catchError(e => {
          this.error = e || this.error;
          
          console.error(e);

          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.hide(this.key);
        })
      ).subscribe(() => {
        this.card.clear();
  
        this.selectAddedCard();
      });
    } catch(e) {
      this.error = e || this.error;
      
      console.error(e);
    }
  }

  private selectAddedCard() {
    const ngUnsubscribe = new Subject<void>();
    const oldCardId = this.paymentData && this.paymentData.cards[0] ? this.paymentData.cards[0].id : null;

    this.paymentService.dataSubject.pipe(takeUntil(ngUnsubscribe)).subscribe((data: PaymentModel) => {
      if (!data || !data.cards.length || oldCardId === data.cards[0].id) return;

      this.cardSaveHandler.emit(data.cards[0]);

      ngUnsubscribe.next();
      ngUnsubscribe.complete();
    });
  }
}
