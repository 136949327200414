import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {VideoUploadUrlsModel} from '../../../../models/videos/video-upload-urls.model';
import {VideoUploadUrlsDto} from '../../../../models/videos/video-upload-urls.dto';
import {AudioUploadUrlsModel} from '../../../../models/audio/audio-upload-urls.model';
import {AudioUploadUrlsDto} from '../../../../models/audio/audio-upload-urls.dto';
import {DocumentUploadUrlsModel} from '../../../../models/document/document-upload-urls.model';
import {DocumentUploadUrlsDto} from '../../../../models/document/document-upload-urls.dto';
import {StudentImageReviewAudioUploadUrlsModel } from '../../../../models/images/review/audio/student-image-review-audio-upload-urls.model';
import {StudentImageReviewAudioUploadUrlsDto} from '../../../../models/images/review/audio/student-image-review-audio-upload-urls.dto';

@Injectable()
export class S3HttpService {
  private model = 's3';

  constructor(private http: HttpClient) {
  }

  public getVideoUploadUrl(originalFileName: string, fileName: string, thumbName: string, videoMime: string, thumbMime: string, duration: string, fileSize: string): Observable<VideoUploadUrlsModel> {
    const params = new HttpParams()
      .set('originalFileName', originalFileName)
      .set('fileName', fileName)
      .set('thumbName', thumbName)
      .set('videoMime', videoMime)
      .set('thumbMime', thumbMime)
      .set('duration', duration)
      .set('fileSize', fileSize);

    const headers = new HttpHeaders().set('X-Content-Length', fileSize);

    return this.http.get(`api/${this.model}/video-upload-url`, { params, headers }).pipe(
      map((res: VideoUploadUrlsDto) => VideoUploadUrlsDto.normalize(res))
    );
  }

  public getAudioUploadUrl(originalFileName: string, fileName: string, audioMime: string, duration: string, fileSize: string): Observable<AudioUploadUrlsModel> {
    const params = new HttpParams()
      .set('originalFileName', originalFileName)
      .set('fileName', fileName)
      .set('audioMime', audioMime)
      .set('duration', duration)
      .set('fileSize', fileSize);

    const headers = new HttpHeaders().set('X-Content-Length', fileSize);

    return this.http.get(`api/${this.model}/audio-upload-url`, { params, headers }).pipe(
      map((res: AudioUploadUrlsDto) => AudioUploadUrlsDto.normalize(res))
    );
  }

  public getDocumentUploadUrl(originalFileName: string, fileName: string, thumbName: string, documentMime: string, thumbMime: string, fileSize: string): Observable<DocumentUploadUrlsModel> {
    const params = new HttpParams()
      .set('originalFileName', originalFileName)
      .set('fileName', fileName)
      .set('thumbName', thumbName)
      .set('documentMime', documentMime)
      .set('thumbMime', thumbMime)
      .set('fileSize', fileSize);

    const headers = new HttpHeaders().set('X-Content-Length', fileSize);

    return this.http.get(`api/${this.model}/document-upload-url`, { params, headers }).pipe(
      map((res: DocumentUploadUrlsDto) => DocumentUploadUrlsDto.normalize(res))
    );
  }

  public getStudentReviewAudioUploadUrl(originalFileName: string, fileName: string, audioMime: string, duration: string, fileSize: string): Observable<StudentImageReviewAudioUploadUrlsModel> {
    const params = new HttpParams()
      .set('originalFileName', originalFileName)
      .set('fileName', fileName)
      .set('audioMime', audioMime)
      .set('duration', duration)
      .set('fileSize', fileSize);

    const headers = new HttpHeaders().set('X-Content-Length', fileSize);

    return this.http.get(`api/${this.model}/student-review-audio-upload-url`, { params, headers }).pipe(
      map((res: StudentImageReviewAudioUploadUrlsDto) => StudentImageReviewAudioUploadUrlsDto.normalize(res))
    );
  }
}
