import {SubscriptionPauseCollectionDto} from './subscription-pause-collection.dto';
import * as moment from 'moment';
import {TIME_FORMATTERS} from '../../../../application/main/admin-panel/customers/customer-info/constants';

export class SubscriptionPauseCollectionModel {
  constructor(public behavior?: string,
              public resumesAt?: string) {}

  public static normalize(res: SubscriptionPauseCollectionDto): SubscriptionPauseCollectionModel {
    if (!res) return new SubscriptionPauseCollectionModel();

    return new SubscriptionPauseCollectionModel(
      res.behavior,
      res.resumes_at ? moment.unix(res.resumes_at).format(TIME_FORMATTERS.DATETIME) : null,
    );
  }

  public static toRequest(res: SubscriptionPauseCollectionModel): SubscriptionPauseCollectionDto {
    if (!res) return null;

    return new SubscriptionPauseCollectionDto(
      res.behavior,
      moment(res.resumesAt).unix(),
    );
  }
}
