import {IStyleModelOptions} from '../../option/i-style-model-options';

import {AttributeBoolModel} from '../../base/attribute-bool/attribute-bool.model';

import {ATTRIBUTES} from './constants';

export class BlogIsContactEmailUsedModel extends AttributeBoolModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, ATTRIBUTES.IS_CONTACT_EMAIL_USED, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    this.isEnabled = !!element;

    if (!this.isEnabled) return;

    if (!options.block) return;

    this.value = options.block.getAttribute(ATTRIBUTES.IS_CONTACT_EMAIL_USED) !== 'false';

    options.block.setAttribute(ATTRIBUTES.IS_CONTACT_EMAIL_USED, `${this.value}`);

    super.init(options.block);
  }

  public onChange(value: boolean): void {
    if (!this.isEnabled || !this.element) return;

    super.onChange(value);
  }
}
