import {BlocksTemplatesDto} from './blocks-templates.dto';
import {BlockTemplateDataModel} from './blocks-templates-data.model';
import {BlocksTemplatesModel} from './blocks-templates.model';

export class BlockTemplateDataDto {
  public list: BlocksTemplatesDto[];
  public isExpanded: boolean = false;

  constructor(public id: number,
              public name: string) {}

  public static normalize(res: BlockTemplateDataDto): BlockTemplateDataModel {
    if (!res) return null;

    const model = new BlockTemplateDataModel(res.id, res.name);
    model.list = res.list.map(item => BlocksTemplatesModel.normalize(item));
    return model;
  }
}
