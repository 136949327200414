import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../auth/auth.service';
import {AccountsCouponsHttpService} from '../../../core/services/interaction/http/accounts/coupons/accounts-coupons-http.service';
import {UpgradeSubscriptionService} from '../../../core/services/payment/subscriptions/upgrade/upgrade-subscription.service';
import {NavigationService} from '../../../services/navigation.service';

import {AccountModel} from '../../../core/models/accounts/account.model';
import {AccountCouponModel} from '../../../core/models/accounts/coupons/account-coupon.model';
import {SelectOption} from '../../../core/models/select/option/option.model';
import {StripeCouponModel} from '../../../core/models/stripe/coupon/stripe-coupon.model';

import {DEFAULT_COUPON_ID} from './constants';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit, OnDestroy {
  @Output() couponSubmitHandler: EventEmitter<StripeCouponModel> = new EventEmitter<StripeCouponModel>();

  public userCoupons: AccountCouponModel[] = [];
  public userCouponsOptions: SelectOption[] = [];

  public selectedCouponId: string = DEFAULT_COUPON_ID;

  public coupon: string = '';

  public error: string = '';

  private account: AccountModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isCouponsExists(): boolean {
    return this.userCoupons && this.userCoupons.length > 0;
  }

  public get defaultCouponId(): string {
    return DEFAULT_COUPON_ID;
  }

  constructor(private service: AccountsCouponsHttpService,
              private upgradeSubscriptionService: UpgradeSubscriptionService,
              private navigationService: NavigationService,
              private authService: AuthService) {
    this.upgradeSubscriptionService.isCouponValid = false;
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;

      if (!account) return;

      this.service.getUserCoupons(account.id);
    });

    this.service.userCoupons.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coupons: AccountCouponModel[]) => {
      this.userCoupons = coupons;

      const userCouponsOptions = coupons.map((coupon: AccountCouponModel) => new SelectOption(coupon.stripeCoupon.name, coupon.stripeCoupon.id, false));

      this.userCouponsOptions = [new SelectOption('Not Selected', DEFAULT_COUPON_ID), ...userCouponsOptions];
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onSelectedCouponChange(option: SelectOption): void {
    this.selectedCouponId = option.value;
  }

  public useCoupon(): void {
    this.error = '';

    if (!this.account) {
      this.error = 'No account data.';

      return;
    }

    const code: string = this.coupon || this.selectedCouponId;

    if (!code || code === DEFAULT_COUPON_ID) return this.skip();

    this.service.validateCoupon(this.account.id, code).subscribe(({ isValid, coupon }) => {
      this.upgradeSubscriptionService.isCouponValid = isValid;

      if (!isValid) {
        this.error = `Coupon is not valid.`;

        return;
      }

      this.couponSubmitHandler.emit(StripeCouponModel.normalize(coupon));
    });
  }

  private skip(): void {
    this.couponSubmitHandler.emit(null);

    this.upgradeSubscriptionService.isCouponValid = false;
  }
}
