import {AccountModel} from '../../../../../core/models/accounts/account.model';

export class LoginContactData {
  Email: string;
  FirstName: string;
  LastName: string;
  Password: string;
  ConfirmPassword: string;
  PhoneDay: string;
  Phone: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Provence: string;
  Country: string;
  Zip: string;
  isPublishInfoModalVisible: boolean;

  public init(account: AccountModel) {
    if (!account) return this.clear();

    this.Email = account.email;
    this.FirstName = account.firstName;
    this.LastName = account.lastName;
    this.Password = '';
    this.ConfirmPassword = '';
    this.PhoneDay = account.phoneDay;
    this.Phone = this.PhoneDay;
    this.Address1 = account.address1;
    this.Address2 = account.address2;
    this.City = account.city;
    this.State = account.state;
    this.Provence = account.provence;
    this.Country = account.country;
    this.Zip = account.zip;
    this.isPublishInfoModalVisible = account.isPublishInfoModalVisible;
  }

  public clear() {
    this.Email = '';
    this.FirstName = '';
    this.LastName = '';
    this.Password = '';
    this.ConfirmPassword = '';
    this.PhoneDay = '';
    this.Phone = this.PhoneDay;
    this.Address1 = '';
    this.Address2 = '';
    this.City = '';
    this.State = '';
    this.Provence = '';
    this.Country = '';
    this.Zip = '';
  }
}
