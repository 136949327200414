import {ImageAvailabilityModel} from './image-availability.model';

export class ImageAvailabilityDto {
  constructor(public Id?: number,
              public Text?: string) {}

  public static normalize(res: ImageAvailabilityDto): ImageAvailabilityModel {
    if (!res) return null;

    return new ImageAvailabilityModel(
      res.Id,
      res.Text,
    );
  }
}
