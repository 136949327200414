import {Component, EventEmitter, Input, Output} from '@angular/core';

import {AppAnimations} from '../../../../../app-animations';

import {CardModel} from '../../../../../core/models/payment/card/card.model';

@Component({
  selector: 'app-credit-cards-page',
  templateUrl: './credit-cards-page.component.html',
  styleUrls: ['./credit-cards-page.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class CreditCardsPageComponent {
  @Input() isCardSelect: boolean = false;

  @Output() cardSelectHandler: EventEmitter<CardModel> = new EventEmitter<CardModel>();

  public onCardSelected(card: CardModel): void {
    this.cardSelectHandler.emit(card);
  }
}
