// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fonts-manager-slider {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.fonts-manager-slider input[type=range] {
  height: 20px;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/fonts-manager/slider/fonts-manager-slider.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,YAAA;EACA,SAAA;AADJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.fonts-manager-slider {\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n\n  input[type=\"range\"] {\n    height: 20px;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
