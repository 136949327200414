import {Component, Input} from '@angular/core';

import {MediaManagerService} from '../../../../core/services/media-manager/media-manager.service';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-media-manager-modal',
  templateUrl: './media-manager-modal.component.html',
  styleUrls: ['./media-manager-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class MediaManagerModalComponent {
  @Input() id: string;

  constructor(private service: MediaManagerService) {
  }

  public closeModal(): void {
    this.service.closeModal();
  }

  public onClose(): void {
    this.service.close();
  }
}
