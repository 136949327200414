// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
}
:host ::ng-deep .cart-item-delete {
  z-index: 100002;
  position: fixed;
}
:host ::ng-deep .cart-item-delete > .modal > .modal-header {
  width: 550px !important;
  max-width: 550px !important;
}
:host ::ng-deep .cart-item-delete > .modal > .modal-body {
  display: flex;
  flex-direction: column;
  width: 550px !important;
  max-width: 550px !important;
  max-height: 90%;
  padding: 25px;
  flex: unset !important;
}
:host ::ng-deep .cart-item-delete > .modal > .modal-body .buttons-wrapper {
  margin: 20px 20px 5px 20px !important;
}
:host ::ng-deep .cart-item-delete > .modal > .modal-body .buttons-wrapper .vs-button {
  display: block;
  width: 180px;
  text-align: center;
  border-radius: 5px !important;
}

.cart-item-delete .text {
  padding: 20px;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/cart-item-delete/cart-item-delete-modal.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AAAF;AAEE;EACE,eAAA;EACA,eAAA;AAAJ;AAGM;EACE,uBAAA;EACA,2BAAA;AADR;AAIM;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;AAFR;AAIQ;EACE,qCAAA;AAFV;AAIU;EACE,cAAA;EACA,YAAA;EACA,kBAAA;EACA,6BAAA;AAFZ;;AAWE;EACE,aAAA;EACA,gBAAA;AARJ","sourcesContent":["\n:host ::ng-deep {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 5000;\n  \n  .cart-item-delete {\n    z-index: 100002;\n    position: fixed;\n\n    & > .modal {\n      & > .modal-header {\n        width: 550px !important;\n        max-width: 550px !important;\n      }\n\n      & > .modal-body {\n        display: flex;\n        flex-direction: column;\n        width: 550px !important;\n        max-width: 550px !important;\n        max-height: 90%;\n        padding: 25px;\n        flex: unset !important;\n\n        .buttons-wrapper {\n          margin: 20px 20px 5px 20px !important;\n\n          .vs-button {\n            display: block;\n            width: 180px;\n            text-align: center;\n            border-radius: 5px !important;\n          }\n        }\n      }\n    }\n  }\n}\n\n.cart-item-delete {\n  .text {\n    padding: 20px;\n    font-size: 0.9em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
