import {Component, OnInit, OnDestroy, Input, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {EditorControlButtonsService} from '../../../../../../services/editor-control-buttons.service';
import {NavigationService} from '../../../../../../services/navigation.service';
import {EventsService} from '../../../../../../core/services/interaction/events/events.service';
import {PageEditorService} from '../../page-editor.service';
import {IFrameRoutingService} from '../../../../../../core/services/iframe/routing/iframe-routing.service';
import {BlocksManagerService} from '../../../../../../core/services/blocks/blocks.service';
import {NodesService} from '../../../../../../core/services/nodes/nodes.service';
import {WebsitesService} from '../../../../../../core/services/websites/websites.service';
import {EducationImageManagerActionService} from '../../../../../../core/services/education/image-manager/reorder/education-image-manager-reorder.service';

import {OutdatedPagesDataModel} from '../../../../../../core/models/outdated-pages-data/outdated-pages-data.model';
import {SelectedPageModel} from '../../../../../../core/models/selected-page/selected-page.model';
import {NodeModel} from '../../../../../../core/models/nodes/node.model';
import {ImageManagerActionDataModel} from '../../../../../../core/models/education/image-manager-action/image-manager-action-data.model';
import {WebsiteModel} from '../../../../../../core/models/websites/website.model';

import {EVENTS} from './constants';

import {AppAnimations} from '../../../../../../app-animations';

@Component({
  selector: 'app-page-menu-item',
  templateUrl: './page-menu-item.component.html',
  styleUrls: ['./page-menu-item.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PageMenuItemComponent implements OnInit, OnDestroy {
  @Input() page: NodeModel;

  public pageWithOutdatedBlocksTooltip: string = 'The design or functionality of block(s) in this page has changed. To see these changes on your website you will need to replace your current block(s).';

  public isSplashEnabled: boolean = false;
  public isPageOutdated: boolean = false;

  public selectedPage: SelectedPageModel = null;
  public outdatedPages: OutdatedPagesDataModel = null;
  public homePage: NodeModel = null;

  public isDropAreaVisible: boolean = false;

  private website: WebsiteModel;

  private nodes: NodeModel[];

  private reorderData: { [key: string]: ImageManagerActionDataModel };
  
  private isBlockedByAction: boolean;

  private blockCategoriesAllowedToDrop = [
    'Text / Info',
  ];

  private draggedBlockData = null;

  private handlers: { [key: string]: any } = {
    [EVENTS.COLLAPSE_HORIZONTAL_MENU]: this.collapseHorizontalMenu.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isLoaded() {
    return this.pageEditorService.isEditorLoaded;
  }

  constructor(
    public editorControlButtonsService: EditorControlButtonsService,
    private pageEditorService: PageEditorService,
    private router: Router,
    private route: ActivatedRoute,
    private iFrameRoutingService: IFrameRoutingService,
    private blocksManagerService: BlocksManagerService,
    private navigationService: NavigationService,
    private eventsService: EventsService,
    private nodesService: NodesService,
    private websitesService: WebsitesService,
    private educationImageManagerActionService: EducationImageManagerActionService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.blocksManagerService.outdatedPagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((pageIds: OutdatedPagesDataModel) => {
      this.outdatedPages = pageIds;

      this.initIsPageOutdated();
    });

    this.eventsService.addFrameListener(EVENTS.COLLAPSE_HORIZONTAL_MENU, this.handlers[EVENTS.COLLAPSE_HORIZONTAL_MENU]);

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;

      this.initIsDropAreaVisible();
      this.initIsPageOutdated();
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.nodes = nodes;

      this.homePage = this.nodes ? this.nodes.find((n: NodeModel) => n.isHomePage) : null;
    });

    this.nodesService.isSplashEnabledSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isSplashEnabled => {
      this.isSplashEnabled = isSplashEnabled;
    });

    this.educationImageManagerActionService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { [key: string]: ImageManagerActionDataModel }) => {
      this.reorderData = data;

      this.initBlockedState();
    });

    this.educationImageManagerActionService.onStopSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageManagerActionDataModel) => {
      this.onReorderStop(data);
    });

    this.pageEditorService.draggedBlockData.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => this.handleDraggedBlockDataChanged(data));
  }

  private collapseHorizontalMenu(): void {
    this.pageEditorService.isPageMenuCollapsedSubject.next(true);
  }

  public selectPage(isSplash): void {
    if (this.isBlockedByAction) {
      return;
    }

    if (isSplash) {
      const { id, type, isPageExists } = this.homePage;

      this.navigationService.toPageEditor({ id, type, isPageExists, isSplash: true });

      return;
    }

    const resPage = Object.assign(this.page, { isSplash });

    this.navigationService.toPageEditor(resPage);
  }

  private handleDraggedBlockDataChanged(data): void {
    this.draggedBlockData = data;

    this.initIsDropAreaVisible();
  }

  private initIsDropAreaVisible(): void {
    const { id, type } = this.page;

    this.isDropAreaVisible = this.pageEditorService.isDraggingBlock && !!this.selectedPage && !!this.draggedBlockData
      && this.selectedPage.id !== id
      && (type === 'H' || type === 'E' || type === 'A' || type === 'M' || type === 'T')
      && this.blockCategoriesAllowedToDrop.includes(this.draggedBlockData.Block_Category);

    this.cdr.detectChanges();
  }

  private initIsPageOutdated(): void {
    if (!this.outdatedPages || !this.selectedPage) {
      this.isPageOutdated = false;

      return;
    }

    this.isPageOutdated = this.outdatedPages.isPageOutdated(this.page, this.selectedPage);
  }

  private initBlockedState(): void {
    if (!this.reorderData || !this.website) {
      this.isBlockedByAction = false;

      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId]) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});
    
    this.isBlockedByAction = !!websiteIds[`${this.website.id}`];
  }

  private onReorderStop(data: ImageManagerActionDataModel): void {
    if (!data) {
      return;
    }
    
    if (!this.website || data.websiteId !== this.website.id) {
      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId] && portfolioId !== `${data.portfolioId}`) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});

    this.isBlockedByAction = !!websiteIds[this.website.id];
  }

  public ngOnDestroy(): void {
    this.eventsService.removeFrameListener(EVENTS.COLLAPSE_HORIZONTAL_MENU, this.handlers[EVENTS.COLLAPSE_HORIZONTAL_MENU]);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}

