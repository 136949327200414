import {IStyleModelOptions} from '../option/i-style-model-options';
import {IPaddingStyleOption} from '../option/i-padding-style-option';

import {STYLE_PROPERTIES, OPTIONS} from './constants';

export class EnlargementPaddingModel implements IPaddingStyleOption {
  public element: HTMLElement;

  public isValuesEqual: boolean = false;

  public values = {};

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {}

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const computedStyles = window.getComputedStyle(this.element);

    OPTIONS.forEach(option => {
      this.values[option.key] = Number.parseInt(computedStyles.getPropertyValue(STYLE_PROPERTIES[option.key])) || 0;
    });

    this.setIsValuesEqual();

    if (!this.isValuesEqual) return;

    this.value = Object.values(this.values)[0];
  }

  public onChange({ key, value }): void {
    if (!this.isEnabled || !this.element) return;

    if (!key) {
      this.value = value;
      this.isValuesEqual = true;

      return OPTIONS.forEach(option => {
        this.values[option.key] = value;
        this.element.style.setProperty(STYLE_PROPERTIES[option.key], value);
      });
    }

    this.values[key] = value;

    this.element.style.setProperty(STYLE_PROPERTIES[key], value);

    this.setIsValuesEqual();
  }

  public reset(key): void {
    if (!this.isEnabled || !this.element) return;

    if (!key) return this.onChange({ key: void 0, value: 0 });

    this.values[key] = 0;
    this.element.style.removeProperty(STYLE_PROPERTIES[key]);

    this.setIsValuesEqual();

    if (!this.isValuesEqual) return;

    this.value = 0;
  }

  private setIsValuesEqual(): void {
    this.isValuesEqual = Object.values(this.values).every((value, i, arr) => value === arr[0]);
  }
}
