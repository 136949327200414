import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders} from '@angular/common/http';

import {Observable, BehaviorSubject, Subscriber, catchError, throwError} from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import {AudiosHttpService} from '../interaction/http/audios/audios-http.service';
import {EventsService} from '../interaction/events/events.service';
import {MediaManagerService} from '../media-manager/media-manager.service';
import {S3HttpService} from '../interaction/http/s3/s3-http.service';
import {PermissionsService} from '../service-permissions/permissions/permissions.service';

import {AudioModel} from '../../models/audio/audio.model';
import {AudioUploadUrlsModel} from '../../models/audio/audio-upload-urls.model';

import {MIME} from './constants';
import {SECTIONS_KEYS} from '../media-manager/constants';

@Injectable()
export class AudioManagerService {
  public modalId: string = 'audio-manager-modal';

  public audiosSubject: BehaviorSubject<AudioModel[]> = new BehaviorSubject<AudioModel[]>(null);
  public uploadAudiosSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>(null);
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public audios(): AudioModel[] {
    return this.audiosSubject.value;
  }

  public get mime(): string {
    return MIME;
  }

  constructor(
    private http: HttpClient,
    private httpService: AudiosHttpService,
    private s3HttpService: S3HttpService,
    private eventsService: EventsService,
    private permissionsService: PermissionsService,
    private mediaManagerService: MediaManagerService,
  ) {
    this.eventsService.addFrameListener('openAudioManager', this.open.bind(this));

    this.permissionsService.beforeLeaveSubject.subscribe(() => {
      this.close();
    });
  }

  public open(): void {
    this.mediaManagerService.isSectionsDisabledSubject.next(true);

    this.mediaManagerService.selectSection(SECTIONS_KEYS.AUDIOS);

    this.mediaManagerService.openModal();
  }

  public loadAudios(): void {
    this.isLoadingSubject.next(true);

    this.httpService.fetch().subscribe((audios: AudioModel[]) => {
      this.audiosSubject.next(audios);
      this.isLoadingSubject.next(false);
    });
  }

  public upload(file: File, audio: AudioModel): Observable<AudioModel> {
    const extension = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${extension}`;

    return new Observable((observer: Subscriber<AudioModel>) => {
      this.getUploadUrls(fileName, file, audio).pipe(
        catchError((err: HttpErrorResponse) => {
          observer.error(err);
          
          return throwError(() => err);
        })
      ).subscribe((urls: AudioUploadUrlsModel) => {
        audio.id = urls && urls.audio ? urls.audio.id : null;

        if (audio.isDeleting) return observer.next(null);

        audio.percent = '0%';

        this.uploadAudioHandler(urls.audioUrl, file, audio).pipe(
          catchError((err: HttpErrorResponse) => {
            observer.error(err);
            
            return throwError(() => err);
          })
        ).subscribe(() => {
          if (audio.isDeleting) return this.httpService.deleteMany([audio.id]).subscribe(() => observer.next(null));

          this.httpService.finishUploading(audio, fileName).pipe(
            catchError((err: HttpErrorResponse) => {
              observer.error(err);
              
              return throwError(() => err);
            })
          ).subscribe((res: AudioModel) => {
            observer.next(res);
            observer.complete();
          });
        });
      });
    });
  }

  private getUploadUrls(fileName: string, file: File, audio: AudioModel): Observable<AudioUploadUrlsModel> {
    return this.s3HttpService.getAudioUploadUrl(file.name, fileName, file.type, audio.duration, audio.fileSize);
  }

  private uploadAudioHandler(url: string, file: File, audio: AudioModel): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      this.uploadAudio(url, file).pipe(
        catchError((err: HttpErrorResponse) => {
          observer.error(err);
          
          return throwError(() => err);
        })
      ).subscribe((event: { type: number, loaded: number, total: number }) => {
        if (event.type === HttpEventType.UploadProgress) {
          audio.percent = `${Math.round(100 / event.total * event.loaded)}%`;
        }

        if (event.type === HttpEventType.Response) {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  private uploadAudio(url: string, file: File): Observable<any> {
    const audioHeaders = new HttpHeaders().set('Content-Type', file.type);

    return this.http.put(url, file, { headers: audioHeaders, reportProgress: true, observe: 'events', responseType: 'text' });
  }

  public deleteMany(ids: number[]): Observable<any> {
    return this.httpService.deleteMany(ids);
  }

  public cancelUploading(audio: AudioModel): void {
    audio.isUploading = true;
    audio.isDeleting = true;
  }

  public close() {
    this.uploadAudiosSubject.next(null);
    this.mediaManagerService.closeModal();
  }

  public updateName(id: number, name: string): Observable<any> {
    return this.httpService.updateName(id, name);
  }
}
