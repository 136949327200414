
import {Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef} from '@angular/core';

import {Observable, Subject, combineLatest} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {WebsiteTourService} from '../../services/website-tour/website-tour.service';
import {UtilsService} from '../../services/utils/utils.service';
import {AuthService} from '../../../auth/auth.service';
import {TourCloseModalService} from '../../../shared/services/modals/tour-close/tour-close-modal.service';

import {WebsiteTourProgressGroupModel} from '../../models/website-tour-progress/group/website-tour-progress-group.model';
import {WebsiteTourProgressItemModel} from '../../models/website-tour-progress/item/website-tour-progress-item.model';
import {AccountModel} from '../../models/accounts/account.model';

import {DESCRIPTION_POSITION} from '../../models/website-tour-progress/item/constants';

@Component({
  selector: 'app-tour-overlay',
  templateUrl: 'tour-overlay.component.html',
  styleUrls: ['./tour-overlay.component.scss']
})
export class TourOverlayComponent implements OnInit, OnDestroy {
  public orderedGroups: WebsiteTourProgressGroupModel[] = null;
  public currentGroup: WebsiteTourProgressGroupModel = null;

  public totalCount: number = 0;

  public dragDropArrow1Rect: { left: number, top: number, width: number };
  public dragDropArrow2Rect: { left: number, top: number, width: number };

  public isWelcomeSlide: boolean = false;
  public isWelcomeSlideExists: boolean = false;
  public isFinalSlide: boolean = false;
  public isPrevButtonEnabled: boolean = false;
  public isNextButtonEnabled: boolean = true;

  private visibleLocations: { [key: string]: boolean };
  private visibleItems: { [key: string]: boolean };
  private items: { [key: string]: WebsiteTourProgressItemModel[] } = null;

  private account: AccountModel;

  private readonly calculateDescriptionPositionHandlers: { [key: string]: Function };

  private saveCurrentProgressDebounced: {
    fn: Function,
    cancel: Function,
  };

  private isDestroyed: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private keyHandlers = {
    'Escape': this.openCloseModal.bind(this),
    'ArrowLeft': this.onPrev.bind(this),
    'ArrowRight': this.onNext.bind(this),
  };

  constructor(private service: WebsiteTourService,
              private closeModalService: TourCloseModalService,
              private utilsService: UtilsService,
              private authService: AuthService,
              private cdr: ChangeDetectorRef) {
    this.initItem = this.utilsService.debounce(this.initItem.bind(this), 100);
    this.saveCurrentProgressDebounced = this.utilsService.debounceCancellable(this.saveCurrentProgress.bind(this), 2500);

    this.calculateDescriptionPositionHandlers = {
      [DESCRIPTION_POSITION.BOTTOM_LEFT_CURVED]: this.calcBottomLeftCurvedDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.BOTTOM_RIGHT_CURVED]: this.calcBottomRightCurvedDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.VERTICALLY_CENTERED_RIGHT]: this.calcVerticallyCenteredRightDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.VERTICALLY_CENTERED_LEFT]: this.calcVerticallyCenteredLeftDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_BOTTOM]: this.calcHorizontallyCenteredBottomDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_BOTTOM_2X]: this.calcHorizontallyCenteredBottomDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_BOTTOM_3X]: this.calcHorizontallyCenteredBottomDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.TOP_LEFT_CURVED]: this.calcTopLeftCurvedDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.DOUBLE_DRAG_DROP_IMAGE]: this.calcDoubleDragDropImageDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.HORIZONTALLY_CENTERED_TOP]: this.calcHorizontallyCenteredTopDescriptionPosition.bind(this),
      [DESCRIPTION_POSITION.LEFT_TOP_TO_RIGHT_CENTER]: this.calcLeftTopToRightCenterDescriptionPosition.bind(this),
    };
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;

      if (!account || (!!account.websiteTourProgress && account.websiteTourProgress.isSkipped)) return;

      this.isWelcomeSlideExists = Object.keys(account.websiteTourProgress.progress).length === 0;
      this.isWelcomeSlide = this.isWelcomeSlideExists;
    });

    const visibleLocationsObservable: Observable<{ [key: string]: boolean }> = this.service.visibleLocations.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((visibleLocations: { [key: string]: boolean }) => !!visibleLocations && visibleLocations !== this.visibleLocations)
    );

    const visibleItemsObservable: Observable<{ [key: string]: boolean }> = this.service.visibleItems.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((visibleItems: { [key: string]: boolean }) => !!visibleItems && visibleItems !== this.visibleItems),
    );

    const itemsGroupedByLocationsObservable: Observable<{ [key: string]: WebsiteTourProgressItemModel[] }> = this.service.itemsGroupedByLocations.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((items: { [key: string]: WebsiteTourProgressItemModel[] }) => items && items !== this.items),
    );

    combineLatest([visibleLocationsObservable, visibleItemsObservable, itemsGroupedByLocationsObservable]).subscribe(([visibleLocations, visibleItems, items]) => {
      this.visibleLocations = JSON.parse(JSON.stringify(visibleLocations));
      this.visibleItems = JSON.parse(JSON.stringify(visibleItems));
      this.items = items;

      this.orderItems();

      this.cdr.detectChanges();

      if (this.isWelcomeSlide) return;

      this.initItem();
    });

    this.closeModalService.skipCurrentTour.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClosed: boolean) => {
      if (!isClosed) return;

      this.skipCurrent();
    });

    this.closeModalService.skipAllTours.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClosed: boolean) => {
      if (!isClosed) return;

      this.skipAll();
    });
  }

  private orderItems(): void {
    const groups: { [key: string]: WebsiteTourProgressItemModel[] } = Object.keys(this.visibleLocations).reduce((res: { [key: string]: WebsiteTourProgressItemModel[] }, key: string) => {
      if (this.items[key]) {
        this.items[key].forEach((item: WebsiteTourProgressItemModel) => {
          if (!res[item.orderNo]) {
            res[item.orderNo] = [];
          }

          if (this.visibleItems[item.key]) {
            res[item.orderNo].push(item);
          }
        });
      }

      return res;
    }, {});

    const groupKeys: string[] = Object.keys(groups)
      .filter(key => groups[key].length > 0)
      .sort((a: string, b: string) => Number.parseInt(a) - Number.parseInt(b));

    this.orderedGroups = groupKeys.reduce((res: WebsiteTourProgressGroupModel[], key: string) => {
      const group: WebsiteTourProgressGroupModel = new WebsiteTourProgressGroupModel(Number.parseInt(key));

      groups[key].forEach((item: WebsiteTourProgressItemModel) => {
        group.items.push(item);
      });

      res.push(group);

      return res;
    }, []);

    this.totalCount = this.orderedGroups.length + (this.isWelcomeSlideExists ? 2 : 1);

    this.orderedGroups.forEach((group: WebsiteTourProgressGroupModel, i: number) => {
      group.idx = i;
      group.key = group.items[0].groupKey;
      group.orderNo = group.items[0].orderNo;

      group.items.forEach((item: WebsiteTourProgressItemModel, y: number) => {
        item.idx = y;
        item.slideNo = y + 1;
      });
    });
  }

  @HostListener('window:resize', [])
  public onResize(): void {
    this.calculatePositions();
  }

  @HostListener('document:keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    if (!this.keyHandlers[e.key]) {
      return;
    }

    this.keyHandlers[e.key](e);
  }

  public onPrev(): void {
    if (!this.isPrevButtonEnabled) {
      return;
    }

    const idx: number = this.isFinalSlide ? this.orderedGroups.length : this.currentGroup.idx;

    if (idx < 1) {
      if (!this.isWelcomeSlideExists) {
        return;
      }

      this.isWelcomeSlide = true;
      this.currentGroup = null;

      this.cdr.detectChanges();

      return;
    }

    this.isFinalSlide = false;

    this.initIsViewed(idx, false);
    this.initIsViewed(idx - 1, false);

    this.initCurrentGroup(this.orderedGroups[idx - 1]);

    this.saveCurrentProgressDebounced.fn(false, true, false);

    this.cdr.detectChanges();
  }

  public onNext(): void {
    if (!this.isNextButtonEnabled) {
      return;
    }

    this.isWelcomeSlide = false;

    if (this.currentGroup && this.currentGroup.idx + 1 === this.orderedGroups.length) {
      this.handleLastSlide();

      this.cdr.detectChanges();

      return;
    }

    this.initCurrentGroup(this.getNotViewedGroup());

    this.saveCurrentProgressDebounced.fn(false, true, false);

    this.cdr.detectChanges();
  }

  public handleLastSlide(): void {
    this.isFinalSlide = true;
    this.isPrevButtonEnabled = true;
    this.isNextButtonEnabled = false;

    this.currentGroup = null;

    this.orderedGroups.forEach((group: WebsiteTourProgressGroupModel) => {
      group.items.forEach((item: WebsiteTourProgressItemModel) => {
        item.isViewed = true;
      });
    });

    this.saveCurrentProgressDebounced.cancel();

    this.saveCurrentProgress(false, true, false);
  }

  private initItem(): void {
    this.initCurrentGroup(this.currentGroup ? this.currentGroup : this.getNotViewedGroup());

    if (this.isDestroyed) return;

    this.cdr.detectChanges();
  }

  private initCurrentGroup(group: WebsiteTourProgressGroupModel) {
    if (!group || !group.items || group.items.length === 0) {
      this.service.isOpened.next(false);

      return;
    }

    group.element = <HTMLElement>document.body.querySelector(`[data-tour-group-key='${group.key}']`);

    if (!group.element) {
      this.service.isOpened.next(false);

      return;
    }

    for (let i = 0; i < group.items.length; i++) {
      group.items[i].element = <HTMLElement>document.body.querySelector(`[data-tour-location='${group.items[i].location}'][data-tour-key='${group.items[i].key}']`);

      if (!group.items[i].element) {
        this.initItem();

        return;
      }
    }

    this.handleCurrentIndex(group);

    this.selectGroup(group);
  }

  private handleCurrentIndex(group: WebsiteTourProgressGroupModel): void {
    const idx: number = this.getGroupIndex(group);

    this.orderedGroups.forEach((orderedGroup: WebsiteTourProgressGroupModel, i: number) => {
      this.initIsViewed(i, i < idx);
    });

    this.isPrevButtonEnabled = this.isWelcomeSlideExists ? true : idx > 0;
    this.isNextButtonEnabled = idx < this.totalCount;
  }

  private initIsViewed(groupIdx: number, isViewed: boolean): void {
    if (!this.orderedGroups[groupIdx]) return;

    this.orderedGroups[groupIdx].isViewed = isViewed;

    this.orderedGroups[groupIdx].items.forEach((item: WebsiteTourProgressItemModel) => {
      item.isViewed = isViewed;
    });
  }

  private selectGroup(group: WebsiteTourProgressGroupModel): void {
    this.currentGroup = group;

    this.calculatePositions();
  }

  private calculatePositions(): void {
    if (!this.currentGroup) return;

    this.dragDropArrow1Rect = null;
    this.dragDropArrow2Rect = null;

    const rect: ClientRect = this.currentGroup.element.getBoundingClientRect();

    const width: number = Math.sqrt(2) * rect.width;
    const height: number = Math.sqrt(2) * rect.height;

    this.currentGroup.top = rect.top - ((height - rect.height) / 2);
    this.currentGroup.left = rect.left - ((width - rect.width) / 2);

    this.currentGroup.width = width;
    this.currentGroup.height = height;

    this.currentGroup.items.forEach((item: WebsiteTourProgressItemModel) => {
      if (!this.calculateDescriptionPositionHandlers[item.descriptionPosition]) return;

      this.calculateDescriptionPositionHandlers[item.descriptionPosition](item);
    });
  }

  private calcBottomLeftCurvedDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    item.descriptionTop = rect.top + rect.height;
    item.descriptionLeft = rect.left + rect.width / 2;
  }

  private calcBottomRightCurvedDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    const width: number = window.innerWidth - rect.left > rect.width ? rect.width : window.innerWidth - rect.left;

    item.descriptionTop = rect.top + rect.height;
    item.descriptionLeft = rect.left + width / 2;
  }

  private calcVerticallyCenteredRightDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    const height: number = window.innerHeight - rect.top > rect.height ? rect.height : window.innerHeight - rect.top;

    item.descriptionTop = rect.top + height / 2;
    item.descriptionLeft = rect.left + rect.width;
  }

  private calcVerticallyCenteredLeftDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    const height: number = window.innerHeight - rect.top > rect.height ? rect.height : window.innerHeight - rect.top;

    item.descriptionTop = rect.top + height / 2;
    item.descriptionLeft = rect.left;
  }

  private calcHorizontallyCenteredBottomDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    item.descriptionTop = rect.top + rect.height;
    item.descriptionLeft = rect.left + rect.width / 2;
  }

  private calcTopLeftCurvedDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    item.descriptionTop = rect.top - rect.height - 30;
    item.descriptionLeft = rect.left + rect.width / 2 - 10;
  }

  private calcDoubleDragDropImageDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const images: HTMLElement[] = <HTMLElement[]>Array.from(item.element.querySelectorAll('.dz-image-preview'));
    const rect: ClientRect = item.element.getBoundingClientRect();

    item.descriptionTop = rect.top + rect.height / 2;
    item.descriptionLeft = rect.left + rect.width / 2;

    if (images.length < 2) return;

    const image1: HTMLElement = images[0];
    const image2: HTMLElement = images.length > 2 ? images[2] : images[1];

    const rect1: ClientRect = image1.getBoundingClientRect();
    const rect2: ClientRect = image2.getBoundingClientRect();

    if (rect1.top !== rect2.top) return;

    this.dragDropArrow1Rect = {
      left: rect1.left + rect1.width / 2,
      top: rect1.top + rect1.height,
      width: rect2.left - rect1.left - rect1.width / 2 + rect2.width,
    };

    this.dragDropArrow2Rect = {
      left: rect1.left,
      top: rect1.top + rect1.height,
      width: rect2.left - rect1.left,
    };
  }

  private calcHorizontallyCenteredTopDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    item.descriptionTop = rect.top - rect.height - 20;
    item.descriptionLeft = rect.left + rect.width / 2;
  }

  private calcLeftTopToRightCenterDescriptionPosition(item: WebsiteTourProgressItemModel): void {
    const rect: ClientRect = item.element.getBoundingClientRect();

    item.descriptionTop = rect.top + rect.height - 15;
    item.descriptionLeft = rect.left + rect.width / 2;
  }

  private getNotViewedGroup(): WebsiteTourProgressGroupModel {
    if (!this.orderedGroups) return null;

    if (!this.currentGroup) return this.orderedGroups[0];

    return this.orderedGroups[this.currentGroup.idx + 1];
  }

  private getGroupIndex(group: WebsiteTourProgressGroupModel): number {
    if (!group) return -1;

    return this.orderedGroups.findIndex((orderedGroup: WebsiteTourProgressGroupModel) => {
      return orderedGroup.key === group.key;
    });
  }

  public replay(): void {
    const idx: number = this.isFinalSlide ? this.orderedGroups.length : this.currentGroup.idx;

    for (let i = idx; i >= 0; i--) {
      this.onPrev();
    }

    this.saveCurrentProgress(false, true, false);
  }

  public closeCurrent(): void {
    this.service.isOpened.next(false);

    this.service.initList();
  }

  public openCloseModal(): void {
    this.closeModalService.open();
  }

  private skipCurrent(): void {
    this.service.isOpened.next(false);

    this.orderedGroups.forEach((group: WebsiteTourProgressGroupModel) => {
      group.items.forEach((item: WebsiteTourProgressItemModel) => {
        item.isViewed = true;
      });
    });

    this.saveCurrentProgress(false, true, false);

    this.service.reInitList();

    this.closeModalService.close();
  }

  private skipAll(): void {
    this.service.isOpened.next(false);

    Object.keys(this.service.items).forEach((key: string) => {
      const item: WebsiteTourProgressItemModel = this.service.items[key];

      this.account.websiteTourProgress.progress[item.id] = true;
    });

    this.saveCurrentProgress(true, false, true);

    this.service.reInitList();

    this.closeModalService.close();
  }

  private saveCurrentProgress(isSkipped: boolean, isNeedToInitProgress: boolean = true, isNeedToReloadList: boolean = false): void {
    if (!this.orderedGroups) {
      return;
    }

    this.account.websiteTourProgress.isSkipped = isSkipped;

    if (isNeedToInitProgress) {
      this.orderedGroups.forEach((group: WebsiteTourProgressGroupModel) => {
        group.items.forEach((item: WebsiteTourProgressItemModel) => {
          this.account.websiteTourProgress.progress[item.id] = item.isViewed;
        });
      });
    }

    this.service.setProgress(this.account.websiteTourProgress).add(() => {
      if (!isNeedToReloadList) return;

      this.service.initList();
    });
  }

  public openVideoLink(url: string): void {
    window.open(url, '_blank');
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
