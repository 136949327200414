import {Component, OnInit, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';
import {FontVariantModel} from '../../../../core/models/font/variant/font-variant.model';
import {FontSubsetModel} from '../../../../core/models/font/subset/font-subset.model';
import {FontComplexityModel} from '../../../../core/models/font/complexity/font-complexity.model';

import {COMPLEXITY_RESOLVER} from './constants';
import {SUBSETS_KEYS, VARIANTS_KEYS} from '../../../../core/services/google/fonts/constants';

@Component({
  selector: 'app-fonts-manager-font-add-modal',
  templateUrl: './fonts-manager-font-add-modal.component.html',
  styleUrls: ['./fonts-manager-font-add-modal.component.scss'],
})
export class FontsManagerFontAddModalComponent implements OnInit, OnDestroy {
  public modalHeader: ModalHeader = new ModalHeader('ADD FONT');

  public isProcessing: boolean = false;

  public font: GoogleFontModel = null;

  public complexity: FontComplexityModel = null;

  public variants: FontVariantModel[] = [];
  public subsets: FontSubsetModel[] = [];

  public errorKey: string = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get modalId(): string {
    return this.googleFontsService.addModalId;
  }

  private get nOfSelectedVariants(): number {
    return this.variants ? this.variants.filter(variant => variant.isSelected).length : 0;
  }

  constructor(private googleFontsService: GoogleFontsService) {
  }

  public ngOnInit(): void {
    this.googleFontsService.fontToAddSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((font: GoogleFontModel) => {
      this.font = GoogleFontModel.clone(font);

      if (!this.font) return;

      this.font.isLoaded = false;

      this.initVariants();
      this.initSubsets();

      this.loadFont();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private initVariants(): void {
    this.variants = this.googleFontsService.getVariantsModels(this.font);

    const regular = this.variants.find((variant: FontVariantModel) => variant.key === VARIANTS_KEYS.REGULAR);

    if (!regular) {
      this.variants[0].isSelected = true;
      return;
    }

    regular.isSelected = true;
  }

  private initSubsets(): void {
    this.subsets = this.googleFontsService.getSubsetsModels(this.font);

    const latinSubset = this.subsets.find((subset: FontSubsetModel) => subset.key === SUBSETS_KEYS.LATIN);
    const latinExtSubset = this.subsets.find((subset: FontSubsetModel) => subset.key === SUBSETS_KEYS.LATIN_EXT);

    if (latinSubset) {
      latinSubset.isSelected = true;
      latinSubset.canBeToggled = false;
    }

    if (latinExtSubset) {
      latinExtSubset.isSelected = true;
      latinExtSubset.canBeToggled = true;
    }

    this.initComplexity();
  }

  public onVariantClick(variant: FontVariantModel) {
    variant.isSelected = !variant.isSelected;

    this.initComplexity();
  }

  private initComplexity() {
    this.complexity = COMPLEXITY_RESOLVER(this.nOfSelectedVariants);
  }

  public onSubsetClick(subset: FontSubsetModel) {
    if (!subset.canBeToggled) return;

    subset.isSelected = !subset.isSelected;
  }

  // async ok
  private async loadFont() {
    await this.googleFontsService.loadFont(this.font);

    this.font.isLoaded = true;
  }

  public onAdd() {
    if (this.googleFontsService.processingFonts[this.font.family]) return;

    this.isProcessing = true;
    this.googleFontsService.processingFonts[this.font.family] = true;

    const item = this.googleFontsService.findFontByFamily(this.font.family);

    const variants = this.getSelectedVariants();
    const subsets = this.getSelectedSubsets();

    return this.googleFontsService.addFont({ fontFamily: this.font.family, variants, subsets }).subscribe(this.onFontAdded.bind(this, item), this.onFontAddError.bind(this));
  }

  private onFontAdded(item: GoogleFontModel) {
    const font = this.googleFontsService.fullListSubject.value.find(f => f.family === this.font.family);

    this.isProcessing = false;
    this.googleFontsService.processingFonts[this.font.family] = false;

    this.font.isAdded = true;
    font.isAdded = true;
    item.isAdded = true;

    this.googleFontsService.addFontLink(item);

    this.googleFontsService.fetchWebsiteData();
    this.googleFontsService.closeAddFontModal();
  }

  private onFontAddError(e: HttpErrorResponse) {
    this.isProcessing = false;
    this.googleFontsService.processingFonts[this.font.family] = false;

    this.font.isAdded = false;

    try {
      const err = JSON.parse(e.error);

      console.error(err);

      this.errorKey = err.key;
    } catch (e) {
      console.error(e);

      this.errorKey = 'SERVER';
    } finally {
    }
  }

  private getSelectedVariants(): string {
    return this.variants.filter((variant: FontVariantModel) => variant.isSelected).map((variant: FontVariantModel) => variant.key).join(',');
  }

  private getSelectedSubsets(): string {
    return this.subsets.filter((subset: FontSubsetModel) => subset.isSelected).map((subset: FontSubsetModel) => subset.key).join(',');
  }

  public onClose() {
    this.googleFontsService.closeAddFontModal();
  }
}
