import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

import {SurveySelectableItemModel} from '../../../../../shared/models/survey/survey-selectable-item.model';

@Injectable()
export class ExitSurveyResultsHttpService {
  private model = 'exit-survey-results';

  constructor(private http: HttpClient) {}

  public isExists(key): Observable<{ isExists: boolean }> {
    return this.http.get<{ isExists: boolean }>(`api/${this.model}/by-key/${key}`);
  }

  public saveSurvey(surveyItems: SurveySelectableItemModel[]): Observable<Object> {
    return this.http.post(`api/${this.model}`, {
      surveyItems,
      userAgent: window.navigator.userAgent,
    });
  }
}
