import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject, Subscription, BehaviorSubject, Observable} from 'rxjs';

import {map} from 'rxjs/operators';

import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {WebsitesService} from '../../websites/websites.service';
import {IFrameService} from '../../iframe/iframe.service';
import {EventsService} from '../../interaction/events/events.service';
import {AuthService} from '../../../../auth/auth.service';
import { PaymentSubscriptionsService } from '../../payment/subscriptions/payment-subscriptions.service';

import {GoogleFontModel} from '../../../models/google/fonts/google-font.model';
import {GoogleFontsDataModel} from '../../../models/google/fonts/google-fonts-data.model';
import {SelectOption} from '../../../models/select/option/option.model';
import {FontVariantModel} from '../../../models/font/variant/font-variant.model';
import {FontSubsetModel} from '../../../models/font/subset/font-subset.model';
import {WebsiteFontsDataModel} from '../../../models/google/fonts/website-fonts-data.model';
import {GoogleFontUsageModel} from '../../../models/google/fonts/google-font-usage.model';
import {GoogleFontUsageDbo} from '../../../models/google/fonts/google-font-usage.dbo';
import {AccountModel} from '../../../models/accounts/account.model';
import { SubscriptionModel } from '../../../models/payment/subscriptions/subscription.model';

import {CONTENT_TYPE_KEYS, FONT_BASE, STRINGS_SETS, VARIANTS, VARIANTS_MAPPING, VARIANTS_MODELS, VARIANTS_ORDER} from './constants';
import {FONT_SIZE_OPTIONS, DEFAULT_FONT_SIZE, SUBSETS_KEYS, SUBSETS_MODELS, SUBSETS_ORDER, SUBSETS_DIRECTIONS} from './constants';

@Injectable()
export class GoogleFontsService {
  private model = 'fonts';

  public modalId: string = 'FontsManager';
  public addModalId: string = 'AddFontModal';
  public removeModalId: string = 'RemoveFontModal';

  public isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dataSubject: BehaviorSubject<GoogleFontsDataModel> = new BehaviorSubject<GoogleFontsDataModel>(null);
  public fullListSubject: BehaviorSubject<GoogleFontModel[]> = new BehaviorSubject<GoogleFontModel[]>([]);
  public listSubject: BehaviorSubject<GoogleFontModel[]> = new BehaviorSubject<GoogleFontModel[]>([]);
  public visibleSubject: BehaviorSubject<GoogleFontModel[]> = new BehaviorSubject<GoogleFontModel[]>([]);
  public fullFontLinksSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public fontToAddSubject: BehaviorSubject<GoogleFontModel> = new BehaviorSubject<GoogleFontModel>(null);
  public fontToRemoveSubject: BehaviorSubject<GoogleFontModel> = new BehaviorSubject<GoogleFontModel>(null);
  public websiteDataSubject: BehaviorSubject<WebsiteFontsDataModel> = new BehaviorSubject<WebsiteFontsDataModel>(null);
  public selectedFontSubject: Subject<GoogleFontModel> = new Subject<GoogleFontModel>();
  public isOpenedFromAdminPanel: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public processingFonts: { [key: string]: boolean } = {};

  public selectedContentTypeKey: string = null;

  private fontsMap: { [key: string]: GoogleFontModel } = {};

  private requestId: number;

  private templateId: number;

  private account: AccountModel;

  private get fullList(): GoogleFontModel[] {
    return this.fullListSubject.value;
  }

  public get list(): GoogleFontModel[] {
    return this.listSubject.value;
  }

  private get userFonts(): GoogleFontModel[] {
    return this.filterUserFonts(this.list);
  }

  private get visible(): GoogleFontModel[] {
    return this.visibleSubject.value;
  }

  private get fullFontLinks(): string[] {
    return this.fullFontLinksSubject.value;
  }

  private get websiteFontsData(): WebsiteFontsDataModel {
    return this.websiteDataSubject.value;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private modalsService: ModalsService,
    private websitesService: WebsitesService,
    private eventsService: EventsService,
    private iFrameService: IFrameService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      if (!account || (this.account && this.account.id === account.id)) {
        return;
      }
      
      this.account = account;

      this.init();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe((subscription: SubscriptionModel) => {
      this.init();
    });

    this.websitesService.activeTemplateIdSubject.subscribe((templateId: number) => {
      if (!templateId) return;

      this.templateId = templateId;

      this.fetchWebsiteData();
    });
  }

  private init(): void {
    this.fetchData();
    this.fetchAllFonts();
  }

  private fetchData(): Subscription {
    return this.http.get(`api/${this.model}/data`).subscribe((data: GoogleFontsDataModel) => {
      this.dataSubject.next(data);
    });
  }

  private fetchAllFonts(): Subscription {
    return this.http.get(`api/${this.model}`).subscribe((fonts: GoogleFontModel[]) => {
      this.initFontsMap(fonts);

      this.fullListSubject.next(fonts);
    });
  }

  private initFontsMap(fonts: GoogleFontModel[]) {
    fonts.forEach((font: GoogleFontModel) => {
      this.fontsMap[font.family] = font;
    });
  }

  public fetchWebsiteData(): Subscription {
    const params = new HttpParams().set('templateId', `${this.templateId}`);

    return this.http.get(`api/${this.model}/website-data`, { params }).subscribe((data: WebsiteFontsDataModel) => {
      data.websiteFonts.forEach((font: GoogleFontModel) => {
        this.initFontUri(font);
        this.initFontUriShort(font);
      });

      data.addedFonts.forEach((font: GoogleFontModel) => {
        this.initFontUri(font);
        this.initFontUriShort(font);
      });

      data.defaultFonts.forEach((font: GoogleFontModel) => {
        this.initFontUri(font);
        this.initFontUriShort(font);
      });

      this.websiteDataSubject.next(data);
    });
  }

  public fetch(params: HttpParams, isUserFonts: boolean): Subscription {
    if (!params.get('categories')) {
      this.initFontsList([]);
      
      this.visibleSubject.next([]);
      
      return Subscription.EMPTY;
    }

    this.requestId = Math.random();

    const requestId = this.requestId;

    return this.http.get(`api/${this.model}`, { params }).subscribe((fonts: GoogleFontModel[]) => {
      if (requestId !== this.requestId) return;

      this.initFonts(isUserFonts ? this.filterUserFonts(fonts) : fonts);

      this.isLoadedSubject.next(true);
    });
  }

  public search(query: string, subset: string, categories: string[], isUserFonts: boolean) {
    const isQueryExists = !!query;
    const regexp = isQueryExists ? new RegExp(query, 'gi') : null;

    const isAllSubsets = subset === SUBSETS_KEYS.ALL;

    const fonts = this.fullList.filter((font: GoogleFontModel) => {
      if (isQueryExists && !regexp.test(font.family)) return false;
      if (!isAllSubsets && !font.subsets.includes(subset)) return false;

      return categories.findIndex(category => font.category === category) !== -1;
    });

    this.initFonts(isUserFonts ? this.filterUserFonts(fonts) : fonts);
  }

  public initVisibleFonts(fromIdx: number, toIdx: number) {
    const visibleFonts = this.list.slice(fromIdx, toIdx);

    console.log(`Showing fonts from ${fromIdx} to ${toIdx}.`);

    this.visibleSubject.next(visibleFonts);
  }

  public isCustomString(subset: string, key: string): boolean {
    return !STRINGS_SETS[subset] || !STRINGS_SETS[subset][key];
  }

  public getStrings(subset: string, key: string): string[] {
    return STRINGS_SETS[subset] ? STRINGS_SETS[subset][key] : [];
  }

  public getVariantsForEditor(font: GoogleFontModel): SelectOption[] {
    const variantsTemplates = VARIANTS();

    const variants: SelectOption[] = font.variants.map((subset: string) => variantsTemplates[subset]);

    return variants.reduce((res: SelectOption[], variant: SelectOption) => {
      const isExists = res.findIndex(item => item.value === VARIANTS_MAPPING[variant.value]) !== -1;

      if (isExists) return res;

      variant.value = VARIANTS_MAPPING[variant.value];

      res.push(variant);

      return res;
    }, []);
  }

  public getVariants(font: GoogleFontModel): SelectOption[] {
    const variants = VARIANTS();

    return VARIANTS_ORDER.reduce((res, variant) => {
      if (!font.files[variant]) return res;

      res.push(variants[variant]);

      return res;
    }, []);
  }

  public getVariantsModels(font: GoogleFontModel): FontVariantModel[] {
    return VARIANTS_ORDER.reduce((res, variant) => {
      if (!font.files[variant]) return res;

      res.push(VARIANTS_MODELS[variant]);

      return res;
    }, []);
  }

  public getSubsetsModels(font: GoogleFontModel): FontSubsetModel[] {
    return SUBSETS_ORDER.reduce((res, subset) => {
      if (!font.subsets.includes(subset)) return res;

      res.push(SUBSETS_MODELS[subset]);

      return res;
    }, []);
  }

  public initUserFonts() {
    this.initFonts(this.userFonts);
    
    this.isLoadedSubject.next(true);
  }

  private initFonts(fonts: GoogleFontModel[]) {
    fonts.forEach((font: GoogleFontModel, idx: number) => {
      font.index = idx;
    });

    this.initFontsList(fonts);
  }

  public onStatusButtonClick(font: GoogleFontModel) {
    if (font.isAdded) return this.openRemoveFontModal(font);

    this.initFullFontLink(font);

    return this.openAddFontModal(font);
  }

  public addFont({ fontFamily, variants, subsets }): Observable<any> {
    return this.http.post(
      `api/${this.model}/${fontFamily}`,
      { fontFamily, variants, subsets },
      { responseType: 'text' }
    );
  }

  public getIsFontUsed(fontFamily: string): Observable<GoogleFontUsageModel> {
    const params = new HttpParams().set('templateId', `${this.templateId}`);

    return this.http.get(`api/${this.model}/${fontFamily}/usage`, { params }).pipe(
      map((res: GoogleFontUsageDbo) => GoogleFontUsageDbo.normalize(res)),
    );
  }

  public removeFont(fontFamily: string): Observable<any> {
    return this.http.delete(`api/${this.model}/${fontFamily}`, { responseType: 'text' });
  }

  public getFontSize(contentType: string) {
    return FONT_SIZE_OPTIONS[contentType] || DEFAULT_FONT_SIZE;
  }

  public calcLineHeight(fontSize: number): number {
    return Math.log2(fontSize) / Math.sqrt(fontSize) * 1.5;
  }

  // async ok
  public async loadFont(font: GoogleFontModel) {
    const fontFace = new FontFace(font.family, `url(${font.files[font.variant]})`);

    await fontFace.load();

    (<any> document).fonts.add(fontFace);
  }

  public initFullFontLink(font: GoogleFontModel) {
    this.initFullFontUri(font);

    this.fullFontLinksSubject.next([...this.fullFontLinks, font.fullUri]);
  }

  public addFontLink(font: GoogleFontModel) {
    this.initFontUri(font);

    font.isAdded = true;
    font.isRemoved = false;

    this.eventsService.dispatchFontsInit({ fonts: [font] }, this.iFrameService.sandboxWindow);
  }

  public removeFontLink(font: GoogleFontModel) {
    this.initFontUri(font);

    font.isAdded = false;
    font.isRemoved = true;

    this.eventsService.dispatchFontLinkRemove({ font }, this.iFrameService.sandboxWindow);
  }

  public initFontUri(font: GoogleFontModel) {
    font.uri = `${FONT_BASE}${font.family.replace(/ /g, '+')}:${font.variants.join(',')}`;
  }

  private initFontUriShort(font: GoogleFontModel) {
    font.uriShort = `${FONT_BASE}${font.family.replace(/ /g, '+')}:${font.variant}`;
  }

  public initFullFontUri(font: GoogleFontModel) {
    font.fullUri = `${FONT_BASE}${font.family.replace(/ /g, '+')}:${font.variants.join(',')}`;
  }

  public removeFullFontLink(font: GoogleFontModel) {
    this.fullFontLinksSubject.next([...this.fullFontLinks.filter(link => link !== font.fullUri)]);
  }

  public openModal(isOpenedFromAdminPanel: boolean = false) {
    this.modalsService.open(this.modalId, { isOpenedFromAdminPanel });
  }

  public openAddFontModal(font: GoogleFontModel) {
    this.fontToAddSubject.next(font);

    this.modalsService.open(this.addModalId);
  }

  public openRemoveFontModal(font: GoogleFontModel) {
    this.fontToRemoveSubject.next(font);

    this.modalsService.open(this.removeModalId);
  }

  public getDir(subset: string) {
    return SUBSETS_DIRECTIONS[subset];
  }

  public getFontsForEditor(fonts: string[]): GoogleFontModel[] {
    const res: GoogleFontModel[] = [];

    fonts.forEach((fontFamily: string) => {
      if (res.findIndex(font => font.family === fontFamily) !== -1) return;

      const font = this.getFontForEditor(fontFamily);

      if (font) res.push(font);
    });

    if (!this.websiteFontsData) return res;

    this.websiteFontsData.websiteFonts.forEach((font: GoogleFontModel) => {
      if (res.findIndex(item => item.family === font.family) !== -1) return;

      res.push(font);
    });

    return res;
  }

  public getFontForEditor(fontFamily: string): GoogleFontModel {
    if (!fontFamily) {
      return null;
    }

    const userFont = this.websiteFontsData.addedFonts.find((font: GoogleFontModel) => font.family === fontFamily);

    if (userFont) {
      return userFont;
    }

    const defaultFont = this.websiteFontsData.defaultFonts.find((font: GoogleFontModel) => font.family === fontFamily);

    if (defaultFont) {
      return defaultFont;
    }

    return this.getFontByFamily(fontFamily);
  }

  public getFontByFamily(fontFamily: string): GoogleFontModel {
    return this.fontsMap[fontFamily] || null;
  }

  public findFontByFamily(fontFamily: string): GoogleFontModel {
    return this.list.find(font => font.family === fontFamily);
  }

  private initFontsList(fonts: GoogleFontModel[]): void {
    this.requestId = null;

    this.listSubject.next(fonts);
  }

  public getAlphabet(subset: string): string {
    return STRINGS_SETS[subset] ? STRINGS_SETS[subset][CONTENT_TYPE_KEYS.ALPHABET] : '';
  }

  public closeModal() {
    this.modalsService.close(this.modalId);
  }

  public closeAddFontModal() {
    this.fontToAddSubject.next(null);

    this.modalsService.close(this.addModalId);
  }

  public closeRemoveFontModal() {
    this.fontToRemoveSubject.next(null);

    this.modalsService.close(this.removeModalId);
  }

  public addDefaultFont(font: GoogleFontModel): Observable<any> {
    return this.http.post(`api/${this.model}/default/${font.family}`, void 0, { responseType: 'text' });
  }

  private filterUserFonts(fonts: GoogleFontModel[]): GoogleFontModel[] {
    return fonts ? fonts.filter((font: GoogleFontModel) => font.isAdded) : [];
  }
}
