import {Injectable} from '@angular/core';

import { Subject } from 'rxjs';

import {ModalsService} from '../modals.service';

@Injectable()
export class SaveOptionsModalService {
  public readonly id: string = 'save-options-modal';

  // public promise: {
  //   promise: Promise<string>,
  //   resolve: (value: any) => void,
  //   reject: (reason?: any) => void,
  // };

  public observable: Subject<{
    value?: string,
    isRejected?: boolean,
  }>;

  public isOpened: boolean = false;

  constructor(
    private modalsService: ModalsService,
  ) {
  }

  public open(data: { isThisPageSave: boolean }): void {
    this.modalsService.open(this.id, data);

    this.observable = new Subject();

    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.observable.next({
      isRejected: true,
    })

    this.modalsService.close(this.id);
  }
}
