import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../../modals.service';

import {ImageModel} from '../../../../../core/models/images/image.model';
import {EducationStudentPortfolioModel} from '../../../../../core/models/education/portfolios/education-student-portfolio.model';

@Injectable()
export class ReviewStudentImageModalService {
  public readonly id: string = 'review-student-image-modal';

  public dataSubject: BehaviorSubject<{ image: ImageModel, studentPortfolio: EducationStudentPortfolioModel }> = new BehaviorSubject<{ image: ImageModel, studentPortfolio: EducationStudentPortfolioModel }>(null);

  public imageIndex: number;

  public isOpened: boolean = false;

  constructor(private modalsService: ModalsService) {
  } 

  public open({ imageIndex }: { imageIndex: number }): void {
    this.isOpened = true;

    this.imageIndex = imageIndex;

    this.modalsService.open(this.id);
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
