import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {EducationImageManagerService} from '../education/image-manager/education-image-manager.service';
import {StudentImageManagerService} from '../education/students/image-manager/student-image-manager.service';
import {PaymentSubscriptionsService} from '../payment/subscriptions/payment-subscriptions.service';
import {PermissionsService} from '../service-permissions/permissions/permissions.service';

import {ImageManagerUserTypeModel} from '../../models/image-manager/user-type/user-type.model';
import {SubscriptionModel} from '../../models/payment/subscriptions/subscription.model';
import {IPermissionData} from '../../models/permission/i-permission-data';

import {EducatorImageManagerTabs} from '../education/image-manager/constants';
import {StudentImageManagerTabs} from '../education/students/image-manager/constants';
import {PERMISSIONS} from '../service-permissions/constants';

@Injectable()
export class CoreImageManagerService {
  public userTypeSubject: BehaviorSubject<ImageManagerUserTypeModel> = new BehaviorSubject<ImageManagerUserTypeModel>(null);

  private activeEducatorTab: EducatorImageManagerTabs;
  private activeStudentTab: StudentImageManagerTabs;
  
  private isEducatorSubscription: boolean;
  private isStudent: boolean;

  constructor(
    private educationImageManagerService: EducationImageManagerService,
    private studentImageManagerService: StudentImageManagerService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private permissionsService: PermissionsService,
  ) {
    this.initPermissions();

    this.paymentSubscriptionsService.currentSubscriptionSubject.subscribe((subscription: SubscriptionModel) => {
      this.isEducatorSubscription = subscription ? subscription.isEducator : false;
      
      this.initUserType();
    });

    this.educationImageManagerService.activeTabSubject.subscribe((key: EducatorImageManagerTabs) => {
      this.activeEducatorTab = key;
      
      this.initUserType();
    });

    this.studentImageManagerService.activeTabSubject.subscribe((key: StudentImageManagerTabs) => {
      this.activeStudentTab = key;
      
      this.initUserType();
    });
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
      
      this.initUserType();
    });
  }

  private initUserType(): void {
    if (this.isEducatorSubscription) {
      this.userTypeSubject.next(new ImageManagerUserTypeModel(
        'educator',
        this.activeEducatorTab,
      ));

      return;
    }

    if (this.isStudent) {
      this.userTypeSubject.next(new ImageManagerUserTypeModel(
        'student',
        this.activeStudentTab,
      ));

      return;
    }

    this.userTypeSubject.next(new ImageManagerUserTypeModel(
      'user',
      null,
    ));
  }
}
