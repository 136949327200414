import {IStyleModelOptions} from '../../option/i-style-model-options';
import {IPaddingStyleOption} from '../../option/i-padding-style-option';

import {STYLE_PROPERTIES, OPTIONS} from './constants';

export class MenuPaddingModel implements IPaddingStyleOption {
  public element: HTMLElement;

  public isValuesEqual: boolean = false;

  public values = {};

  private device: string = '';

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {}

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.device = options.device;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    OPTIONS.forEach(option => {
      this.values[option.key] = Number.parseInt(computedStyles.getPropertyValue(STYLE_PROPERTIES[this.device][option.key])) || 0;
    });

    this.setIsValuesEqual();

    if (!this.isValuesEqual) return;

    this.value = Object.values(this.values)[0];
  }

  public onChange({ key, value }): void {
    if (!this.isEnabled || !this.element) return;

    if (!key) {
      this.value = value;
      this.isValuesEqual = true;

      return OPTIONS.forEach(option => {
        this.values[option.key] = value;
        this.element.style.setProperty(STYLE_PROPERTIES[this.device][option.key], value);
        this.element.style.setProperty(`${STYLE_PROPERTIES[this.device][option.key]}-px`, `${value}px`);
      });
    }

    this.values[key] = value;

    this.element.style.setProperty(STYLE_PROPERTIES[this.device][key], value);
    this.element.style.setProperty(`${STYLE_PROPERTIES[this.device][key]}-px`, `${value}px`);

    this.setIsValuesEqual();
  }

  public reset(key?: string): void {
    if (!this.isEnabled || !this.element) return;

    const computedStyles = window.getComputedStyle(this.element);

    if (!key) {
      OPTIONS.forEach(option => {
        this.element.style.removeProperty(STYLE_PROPERTIES[this.device][option.key]);
        this.element.style.removeProperty(`${STYLE_PROPERTIES[this.device][option.key]}-px`);

        this.values[option.key] = Number.parseInt(computedStyles.getPropertyValue(STYLE_PROPERTIES[this.device][option.key])) || 0;

        this.value = this.values[option.key];
      });

      this.value = Object.values(this.values)[0];

      this.setIsValuesEqual();

      return;
    }

    this.element.style.removeProperty(STYLE_PROPERTIES[this.device][key]);
    this.element.style.removeProperty(`${STYLE_PROPERTIES[this.device][key]}-px`);

    this.values[key] = Number.parseInt(computedStyles.getPropertyValue(STYLE_PROPERTIES[this.device][key])) || 0;

    this.setIsValuesEqual();

    if (!this.isValuesEqual) return;

    this.value = Object.values(this.values)[0];
  }

  private setIsValuesEqual(): void {
    this.isValuesEqual = Object.values(this.values).every((value, i, arr) => value === arr[0]);
  }
}
