import {Component, Input} from '@angular/core';
import {ModalsService} from '../../../services/modals/modals.service';
import { TextAlertModalService } from "../../../../services/text-alert-modal.service";

@Component({
  selector: 'app-text-alert-modal',
  templateUrl: './text-alert-modal.component.html',
  styleUrls: ['./text-alert-modal.component.scss'],
})
export class TextAlertModalComponent {
  @Input() id: string;

  constructor(private modalsService: ModalsService,
              public textAlertModalService: TextAlertModalService) {}

  submitHandler(value) {
    if (value) {
      this.textAlertModalService.onSubmit();

      this.modalsService.close(this.textAlertModalService.modalId);

      return;
    }

    this.textAlertModalService.onCancel();

    this.modalsService.close(this.textAlertModalService.modalId);
  }

  onClose() {
    this.textAlertModalService.onClose();

    this.modalsService.close(this.textAlertModalService.modalId);
  }
}
