export function getBase64(file) {
  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (err) {
      reject(err);
    };

  });
}

const svgDataMark = 'data:image/svg+xml,';

export function wrapToUrl(str) {
  return `url("${str}")`;
}

export function getSvgFromSvgDataUri(svgDataUri) {
  return svgDataUri.replace(svgDataMark, '');
}

export function getSvgDataUriFromSvg(svgCode) {
  return svgDataMark + svgCode.replace(/"/g, '\'');
}

export function svgToDoc(svgCode) {
  const parser = new DOMParser();
  const svgDocument = parser.parseFromString(svgCode, "image/svg+xml");
  return svgDocument;
}
