import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AudioService} from '../../../core/services/audio/audio.service';
import {AuthService} from '../../../auth/auth.service';
import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {BrowserService} from '../../../core/services/browser/browser.service';
import {FirefoxAudioRecordForbiddenModalService} from '../../services/modals/firefox-audio-record-forbidden/firefox-audio-record-forbidden-modal.service';
import {EducationStudentsService} from '../../../core/services/education/students/education-students.service';

import {IBaseAudioModel} from '../../../core/models/base/audio/i-audio.model';
import {IPermissionData} from '../../../core/models/permission/i-permission-data';
import {IRecordedAudioModel} from '../../../core/models/base/audio/i-recorded-audio.model';
import {AccountModel} from '../../../core/models/accounts/account.model';
import {StudentImageReviewModel} from '../../../core/models/images/review/student-image-review.model';

import {PERMISSIONS} from '../../../core/services/service-permissions/constants';
import {AudioState} from '../../../core/services/audio/constants';

type AudioSectionModes = 'upload' | 'list';

@Component({
  selector: 'app-audio-section',
  templateUrl: './audio-section.component.html',
  styleUrls: ['./audio-section.component.scss']
})
export class AudioSectionComponent implements OnInit, OnDestroy {
  @Input() review: StudentImageReviewModel;
  @Input() list: IBaseAudioModel[];
  @Input() type: AudioSectionModes;

  @Output() recordedHandler: EventEmitter<IRecordedAudioModel> = new EventEmitter<IRecordedAudioModel>();
  @Output() removeHandler: EventEmitter<IBaseAudioModel> = new EventEmitter<IBaseAudioModel>();

  public currentUserId: number;

  public state: AudioState;

  public error: string = '';

  public isFirefox: boolean = false;

  public isRecordPermitted: boolean = false;
  public isListenPermitted: boolean = false;
  
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: AudioService,
    private educationStudentsService: EducationStudentsService,
    private firefoxAudioRecordForbiddenModalService: FirefoxAudioRecordForbiddenModalService,
    private permissionsService: PermissionsService,
    private browserService: BrowserService,
    private authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    this.isFirefox = this.browserService.isFirefox;

    this.initPermissions();

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.currentUserId = account ? account.id : null;
    });

    this.service.stateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: AudioState) => {
      this.state = state;
    });

    this.service.onRecordedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((audio: IRecordedAudioModel) => {
      if (!audio) {
        return;
      }

      this.recordedHandler.emit(audio);
    });

    this.service.errorSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error: string) => {
      this.error = error;
    });
  }

  private initPermissions(): void {
    const audioRecordPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.AUDIO_REVIEW_RECORD,
    };
    
    const audioListenPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.AUDIO_REVIEW_LISTEN,
    };

    this.permissionsService.isUserHasPermissionsObservable([audioRecordPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isRecordPermitted = isPermitted;
    });

    this.permissionsService.isUserHasPermissionsObservable([audioListenPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isListenPermitted = isPermitted;
    });
  }

  public onRecordButtonClick(): void {
    if (this.isFirefox) {
      this.firefoxAudioRecordForbiddenModalService.open();

      return;
    }

    this.service.onRecordButtonClick();
  }

  public removeOne(item: IBaseAudioModel): void {
    this.removeHandler.emit(item);
  }

  public onPlay(item: IBaseAudioModel): void {
    if (!this.isListenPermitted || !this.review) {
      return;
    }

    this.educationStudentsService.sendImageReviewAudioAction(
      this.review.imageId,
      item.id,
      'listen'
    ).subscribe(() => {});
  }

  public onStalled(e: Event): void {
    const target: HTMLAudioElement = <HTMLAudioElement>e.currentTarget;

    target.load();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}

