import {Component, OnDestroy} from '@angular/core';

import {Subject, forkJoin, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {PlansHttpService} from '../../../../core/services/interaction/http/plans/plans-http.service';
import {AppSettingsService} from '../../../../core/services/app-settings/app-settings.service';
import {PlansService} from '../../../../core/services/plans/plans.service';
import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';

import {AppSettingsModel} from '../../../../core/models/app-settings/app-settings.model';

import {PlanModel} from '../../../../core/models/plan/plan.model';
import {PlanDto} from '../../../../core/models/plan/plan.dto';

@Component({
  selector: 'app-admin-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class AdminPlansComponent implements OnDestroy {
  private key = 'AdminPlansComponent';

  public plans: PlanModel[] = [];

  public plansToSave: { [key: string]: PlanModel } = {};

  public settings: AppSettingsModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private buttonHandlers = {};

  constructor(
    private plansService: PlansService,
    private appSettingsService: AppSettingsService,
    private controlButtonsService: EditorControlButtonsService,
    private buttonsService: ButtonsService,
    private loaderService: ContentLoaderService,
    public planService: PlansHttpService,
  ) {
    this.buttonHandlers = {
      [this.controlButtonsService.CONTROL_BUTTONS.SAVE]: this.onSave.bind(this),
    };

    this.loaderService.show(this.key);

    this.addSubscriptions();

    this.buttonsService.setSaveButtonVisibility(true);
  }

  addSubscriptions() {
    this.appSettingsService.settingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.settings = value;
    });

    this.plansService.plansSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(plans => {
      this.plans = plans;

      if (!plans || plans.length === 0) return;

      this.loaderService.hide(this.key);
    });

    this.controlButtonsService.controlBtnClick.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ buttonName }) => {
      if (!this.buttonHandlers.hasOwnProperty(buttonName)) {
        return;
      }

      this.buttonHandlers[buttonName]();
    });
  }

  public save(plan: PlanModel): void {
    if (this.plansToSave[plan.id]) return;

    this.plansToSave[plan.id] = plan;

    this.buttonsService.enableSaveButton();
  }

  private onSave() {
    if (!this.plansToSave) {
      return;
    }
    
    const keys = Object.keys(this.plansToSave);

    this.loaderService.show(this.key);

    forkJoin(keys.map(key => {
      const plan = PlanDto.toRequest(this.plansToSave[key]);

      return this.planService.save(plan);
    })).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      }),
    ).subscribe(() => {
      this.plansToSave = {};

      this.buttonsService.disableSaveButton();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.buttonsService.setSaveButtonVisibility(false);

    this.loaderService.hide(this.key);
  }
}
