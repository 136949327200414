export class CardModel {
  constructor(public id?: string,
              public brand?: string,
              public last4?: string,
              public expiresAt?: string,
              public isDefault?: boolean) {}

  public static normalize(res: CardModel, defaultId?: string): CardModel {
    if (!res) return null;

    return new CardModel(
      res.id,
      res.brand,
      res.last4,
      res.expiresAt,
      res.id === defaultId,
    );
  }
}
