import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject, Subscription, Observable} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

import {AuthService} from '../../../auth/auth.service';
import {WebsitesHttpService} from '../interaction/http/websites/websites-http.service';
import {RoutingService} from '../routing/routing.service';
import {SocketsService} from '../interaction/sockets/sockets.service';

import {AccountModel} from '../../models/accounts/account.model';
import {WebsiteModel} from '../../models/websites/website.model';
import {DomainModel} from '../../models/domain/domain.model';
import {ISocketWebsitesMessageDataModel} from '../../models/sockets/message/websites/i-websites-message-data.model';


@Injectable()
export class WebsitesService {
  public activeWebsiteSubject: BehaviorSubject<WebsiteModel> = new BehaviorSubject<WebsiteModel>(null);
  public activeWebsiteIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public activeTemplateIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public isActiveWebsiteRelatedToUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onForceWebsiteSwitchSubject: Subject<WebsiteModel> = new Subject<WebsiteModel>();

  private account: AccountModel;

  private websitesSocketDataHandlers = {
    'ACTIVE_WEBSITE_CHANGED': this.onActiveWebsiteForceChange.bind(this),
  };

  public get activeWebsiteId(): number {
    return this.activeWebsiteIdSubject.value;
  }

  constructor(private routingService: RoutingService,
              private httpService: WebsitesHttpService,
              private socketsService: SocketsService,
              private authService: AuthService) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;

      if (!account) {
        this.activeWebsiteIdSubject.next(null);

        return;
      }

      this.activeWebsiteIdSubject.next(account.activeWebsiteId);

      this.fetchWebsite();

      this.socketsService.requestInitialData();
    });

    this.socketsService.websitesDataSubject.subscribe((data: ISocketWebsitesMessageDataModel) => {
      this.onWebsitesSocketData(data);
    });
  }

  private onWebsitesSocketData(data: ISocketWebsitesMessageDataModel): void {
    if (!data || !this.websitesSocketDataHandlers[data.key]) {
      return;
    }

    this.websitesSocketDataHandlers[data.key](data);
  }

  private onActiveWebsiteForceChange(data: ISocketWebsitesMessageDataModel): void {
    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    this.activeWebsiteSubject.pipe(takeUntil(unsubscribe)).subscribe((website: WebsiteModel) => {
      if (!website || website.id !== data.websiteId) {
        return;
      }

      this.onForceWebsiteSwitchSubject.next(website);

      unsubscribe.next(true);
      unsubscribe.complete();
    });
  }

  public fetchWebsite(): Subscription {
    return this.httpService.fetchActiveWebsite().subscribe((website: WebsiteModel) => {
      this.activeTemplateIdSubject.next(website ? website.templateId : null);

      if (website && !website.isSetupCompleted) this.routingService.toSetup();

      this.activeWebsiteSubject.next(website);
      this.isActiveWebsiteRelatedToUserSubject.next(website && this.account ? website.userId === this.account.id : false);
    });
  }

  public getWebsiteLink(websiteId: number): Observable<string> {
    return new Observable(observer => {
      this.httpService.getWebsitePrimaryDomain(websiteId).subscribe((domain: DomainModel) => {
        const url = domain ? `https://${domain.name}?isUserPreview=true` : '';

        observer.next(url);
        observer.complete();
      });
    });
  }
}
