import {Component, Input, Output, EventEmitter} from '@angular/core';

import {ModalsService} from "../../../services/modals/modals.service";

import {ModalHeader} from "../../../../common/models/modal/header/header.model";
import {Button} from "../../../../common/models/button/button.model";

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
  @Input() id: string;
  @Input() header: string;
  @Input() errorMessage: string;

  @Output() openHandler = new EventEmitter();
  @Output() doSubmit = new EventEmitter();

  get modalHeader(): ModalHeader {
    return {
      text: this.header || 'Error!',
      className: 'error-header',
    };
  }
  buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.submitHandler.bind(this),
    }
  ];

  constructor(private modalsService: ModalsService) {}

  submitHandler() {
    this.doSubmit.emit();

    this.modalsService.close(this.id);
  }
}
