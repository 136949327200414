import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';

import {PlanModel} from '../../models/plan/plan.model';
import {PlanDto} from '../../models/plan/plan.dto';

@Injectable()
export class PlansService {
  private readonly model: string = 'plans';

  public plansSubject: BehaviorSubject<PlanModel[]> = new BehaviorSubject<PlanModel[]>(null);

  constructor(private httpClient: HttpClient) {
    this.fetch().subscribe((res: PlanDto[]) => {
      if (!res) {
        this.plansSubject.next([]);

        return;
      }

      const plans = res.map((item: PlanDto) => {
        return PlanModel.normalize(item);
      });

      this.plansSubject.next(plans);
    });
  }

  private fetch(): Observable<PlanDto[]> {
    return this.httpClient.get<PlanDto[]>(`api/${this.model}`);
  }
}
