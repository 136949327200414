import {IStyleOption} from '../../option/i-style-option';

import {CLASS_NAMES} from './constants';

export class IsSizeReducedOnScrollModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement): void {
    this.element = element;

    this.isEnabled = !!element;

    if (!this.isEnabled) return;

    this.value = element.classList.contains(CLASS_NAMES.SIZE_REDUCED_ON_SCROLL);
  }

  public setValue(value: boolean): void {
    if (!this.isEnabled) return;

    this.value = value;

    this.element.classList.toggle(CLASS_NAMES.SIZE_REDUCED_ON_SCROLL, value);
  }
}
