import {Component, Output, Input, EventEmitter} from '@angular/core';

import {PopupInputConfigModel} from './popup-input.model';

const KEYS = {
  ENTER: 13,
  ESC: 27,
};

@Component({
  selector: 'app-vs-popup-input',
  templateUrl: './popup-input.component.html',
  styleUrls: ['./popup-input.component.scss'],
})
export class PopupInputComponent {
  public inputValue = '';

  @Input() config: PopupInputConfigModel;
  
  @Output() enterHandler = new EventEmitter<String>();
  @Output() clickOut = new EventEmitter<String>();

  private keyHandlers: {};

  constructor() {
    this.keyHandlers = {
      [KEYS.ENTER]: this.handleEnter.bind(this),
      [KEYS.ESC]: this.handleEsc.bind(this),
    };
  }

  public onKeyDown(e: KeyboardEvent) {
    if (!this.keyHandlers[e.keyCode]) return;

    this.keyHandlers[e.keyCode]();
  }

  public handleEnter() {
    this.enterHandler.next(this.inputValue);

    this.handleEsc();
  }

  private handleEsc() {
    this.inputValue = '';
    this.clickOut.emit();
  }
}
