// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-stripe {
  height: 100%;
  padding: 18px;
  overflow: auto;
}
.admin-stripe .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/admin-panel/stripe/admin-stripe.component.scss"],"names":[],"mappings":"AAIA;EACE,YAAA;EACA,aAAA;EACA,cAAA;AAHF;AAKE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAHJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n$line-height: 3em;\n\n.admin-stripe {\n  height: 100%;\n  padding: 18px;\n  overflow: auto;\n\n  .title {\n    font-size: 24px;\n    font-weight: 600;\n    margin-bottom: 30px;\n  }\n\n  .section {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
