import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BlocksChangesHttpService} from '../../../../core/services/interaction/http/admin/blocks-changes/blocks-changes-http.service';
import {SeveritiesHttpService} from '../../../../core/services/interaction/http/severities/severities-http.service';

import {BlocksChangesModel} from '../../../../core/models/blocks-changes/blocks-changes.model';
import {SeverityModel} from '../../../../core/models/severity/severity.model';

@Component({
  selector: 'app-blocks-changes',
  templateUrl: './blocks-changes.component.html',
  styleUrls: ['./blocks-changes.component.scss']
})
export class AdminBlocksChangesComponent implements OnDestroy {
  public blocksChanges: BlocksChangesModel[] = [];
  public severities: SeverityModel[] = [];

  public isLoaded: boolean = false;

  private ngUnsubscribe = new Subject();

  constructor(private blocksChangesHttpService: BlocksChangesHttpService,
              private severitiesHttpService: SeveritiesHttpService) {
    this.blocksChangesHttpService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
    });

    this.blocksChangesHttpService.blocksChangesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((blocks: BlocksChangesModel[]) => {
      this.blocksChanges = blocks;
    });

    this.severitiesHttpService.severitiesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((severities: SeverityModel[]) => {
      this.severities = severities;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onSaveAll() {
    return this.blocksChangesHttpService.update(this.blocksChanges);
  }

  public onSave(block: BlocksChangesModel) {
    return this.blocksChangesHttpService.update([block]);
  }
}
