import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {AuthService} from '../../auth/auth.service';
import {EditorControlButtonsService} from '../../services/editor-control-buttons.service';
import {WebsiteDesignerService} from '../../application/main/website-designer/website-designer.service';
import {PublishWebsiteService} from '../../services/publish-website/publish-website.service';
import {ModalsService} from '../../shared/services/modals/modals.service';
import {PagesService} from '../../application/sidebar-short/sidebar/pages/pages.service';
import {ButtonsService} from '../../core/services/buttons/buttons.service';
import {SetupService} from '../../core/services/setup/setup.service';
import {NavigationService} from '../../services/navigation.service';
import {WebsitesService} from '../../core/services/websites/websites.service';
import {PermissionsService} from '../../core/services/service-permissions/permissions/permissions.service';
import {MultiWebsiteService} from '../../core/services/multi-website/multi-website.service';
import {EducationTeachersWebsitesService} from '../../core/services/education/teachers/websites/education-teachers-websites.service';
import {WebsitesLimitExceededModalService} from '../../shared/services/modals/websites-limit-exceeded/websites-limit-exceeded-modal.service';
import {PaymentSubscriptionsService} from '../../core/services/payment/subscriptions/payment-subscriptions.service';
import {StudentWebsitesService} from '../../core/services/education/students/websites/student-websites.service';
import {IsPublishingService} from '../../services/is-publishing/is-publishing.service';

import {AccountModel} from '../../core/models/accounts/account.model';
import {WebsiteModel} from '../../core/models/websites/website.model';
import {CourseWebsiteModel} from '../../core/models/websites/course-website.model';
import {SubscriptionModel} from '../../core/models/payment/subscriptions/subscription.model';
import {EducationStudentWebsiteModel} from '../../core/models/education/students/websites/education-student-website.model';
import {IPermissionData} from '../../core/models/permission/i-permission-data';

import {TYPES} from './constants';
import {PERMISSIONS} from '../../core/services/service-permissions/constants';

import {AppAnimations} from '../../app-animations';

@Component({
  selector: 'app-websites-dropdown',
  templateUrl: './websites-dropdown.component.html',
  styleUrls: ['./websites-dropdown.component.scss'],
  animations: [
    AppAnimations.fadeIn(),
  ],
})
export class WebsitesDropdownComponent implements OnInit, OnDestroy {
  @ViewChild('websitesDropdown') websitesDropdown: ElementRef;

  @Input() isSaveButtonEnabled: boolean;

  public type: string = TYPES.DROPDOWN;

  public account: AccountModel;

  public website: WebsiteModel = null;
  
  public websites: WebsiteModel[] = null;
  public paidWebsites: WebsiteModel[] = null;
  public studentWebsites: EducationStudentWebsiteModel[] = null;
  public educatorWebsites: CourseWebsiteModel[] = null;
  public mainWebsite: WebsiteModel = null;

  public mainWebsiteTitle: string = '';
  public currentWebsiteTitle: string = '';

  public isImageManager: boolean = false;
  public isStudent: boolean = false;
  public isEducator: boolean = false;
  public isAddWebsiteButtonPermitted: boolean = false;
  public isWebsitesDropdownVisible: boolean = false;
  public isWebsitesDropdownBlocked: boolean = false;
  public isWebsiteButtonBlocked: boolean = false;

  private publishingData: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get TYPES(): { DROPDOWN: string, BUTTON: string } {
    return TYPES;
  }

  public get isDropdownVisible(): boolean {
    if (!this.account || !this.websites || this.account.isAdmin) return false;

    return this.websites.length >= this.account.limits.websites;
  }

  private get isLimitExceeded(): boolean {
    if (!this.account || !this.websites || this.account.isAdmin) {
      return false;
    }

    return this.websites.length >= this.account.limits.websites;
  }

  private get isPublishing(): boolean {
    if (!this.publishingData || !this.publishingData.isActiveWebsite) {
      return false;
    }

    return this.publishingData.isPublishing;
  }

  constructor(
    private multiWebsiteService: MultiWebsiteService,
    private educationTeachersWebsitesService: EducationTeachersWebsitesService,
    private websitesLimitExceededModalService: WebsitesLimitExceededModalService,
    private permissionsService: PermissionsService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private studentWebsitesService: StudentWebsitesService,
    private isPublishingService: IsPublishingService,
    public modalService: ModalsService,
    public router: Router,
    public buttonsService: ButtonsService,
    public setupService: SetupService,
    public modalsService: ModalsService,
    public websiteDesignerService: WebsiteDesignerService,
    public authService: AuthService,
    public editorControlButtonsService: EditorControlButtonsService,
    public publishWebsiteService: PublishWebsiteService,
    public pagesService: PagesService,
    public websitesService: WebsitesService,
    public navigationService: NavigationService,
  ) {
  }

  public ngOnInit(): void {
    this.initPermissions();
    
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;

      this.initCurrentWebsiteTitle();
    });

    this.studentWebsitesService.websitesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: EducationStudentWebsiteModel[]) => {
      this.studentWebsites = websites;

      this.initMainWebsiteTitle();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.type = TYPES.DROPDOWN;
      this.isEducator = false;

      if (!subscription) {
        return;
      }

      this.isEducator = subscription.isEducator;

      this.initType();
    });

    this.navigationService.isImageManagerSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isImageManager = value;

      this.initType();
    });
    
    this.multiWebsiteService.websitesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: WebsiteModel[]) => {
      this.websites = websites;

      this.initWebsites();
      this.initType();

      this.initCurrentWebsiteTitle();
    });
    
    this.educationTeachersWebsitesService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: CourseWebsiteModel[]) => {
      this.educatorWebsites = websites;

      this.initCurrentWebsiteTitle();
    });

    this.isPublishingService.isPublishingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean }) => {
      this.publishingData = data;
    });
  }

  private initStudentWebsites(): void {
    if (!this.isStudent) {
      return;
    }

    this.studentWebsitesService.fetchAllForCurrentStudent();

    this.studentWebsitesService.fetchMainWebsite().subscribe((website: WebsiteModel) => {
      this.mainWebsite = website;

      this.initMainWebsiteTitle();
    });
  }

  private initMainWebsiteTitle(): void {
    this.mainWebsiteTitle = 'N/A';

    if (!this.mainWebsite || !this.websites) {
      return;
    }

    const website: WebsiteModel = this.websites.find((website: WebsiteModel) => {
      return website.id === this.mainWebsite.id;
    });

    if (website && website.subscription && (website.subscription.type === 'STRIPE' || (website.subscription.metadata && website.subscription.metadata.planId !== 'StudentEdu'))) {
      this.mainWebsiteTitle = website.title;

      return;
    }

    if (!this.studentWebsites) {
      return;
    }

    const studentWebsite: EducationStudentWebsiteModel = this.studentWebsites.find((website: EducationStudentWebsiteModel) => {
      return website.id === this.mainWebsite.id;
    });

    if (!studentWebsite || !studentWebsite.course) {
      return;
    }

    this.mainWebsiteTitle = `${studentWebsite.course.name} "${studentWebsite.course.number}"`;
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    const addWebsiteButtonPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.ADD_WEBSITE_BUTTON,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isStudent = isPermitted;

      this.initStudentWebsites();
      
      this.initType();
    });
    
    this.permissionsService.isUserHasPermissionsObservable([addWebsiteButtonPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isAddWebsiteButtonPermitted = isPermitted;
    });
  }

  private initType(): void {
    if (this.isStudent) {
      if (this.paidWebsites && this.paidWebsites.length > 1) {
        this.type = TYPES.DROPDOWN;

        return;
      }

      this.type = this.isImageManager ? TYPES.BUTTON : TYPES.NONE;

      return;
    }

    this.type = TYPES.DROPDOWN;
  }

  private initWebsites(): void {
    if (!this.websites) {
      this.paidWebsites = [];

      return;
    }

    this.paidWebsites = this.websites.filter((website: WebsiteModel) => {
      if (this.account && this.account.isAdmin) {
        return true;
      }

      return website.subscription && !website.subscription.isStudentEdu;
    });
  }

  private initCurrentWebsiteTitle(): void {
    this.currentWebsiteTitle = '';

    if (!this.website) {
      return;
    }

    const currentWebsite: WebsiteModel = this.websites ? this.websites.find((website: WebsiteModel) => website.id === this.website.id) : null;

    if (currentWebsite) {
      this.currentWebsiteTitle = this.isEducator ? 'MY WEBSITE' : currentWebsite.title;

      return;
    }

    const currentEducatorWebsite: CourseWebsiteModel = this.educatorWebsites ? this.educatorWebsites.find((website: CourseWebsiteModel) => website.id === this.website.id) : null;

    if (currentEducatorWebsite) {
      this.currentWebsiteTitle = `CLASS WEBSITE`;
      
      return;
    }
  }

  public onWebsiteButtonClick(): void {
    if (this.isWebsiteButtonBlocked || !this.isStudent) {
      return;
    }

    if (this.paidWebsites && this.paidWebsites.length === 1) {
      this.selectWebsite(this.paidWebsites[0]);
      
      return;
    }

    if (!this.mainWebsite) {
      return;
    }

    this.selectWebsite(this.mainWebsite);
  }

  public toggleWebsitesDropdown(): void {
    this.isWebsitesDropdownVisible = !this.isWebsitesDropdownVisible;

    this.scrollDropdownToSelectedWebsite();
  }

  private scrollDropdownToSelectedWebsite(): void {
    if (!this.isWebsitesDropdownVisible) {
      return;
    }
    
    const intervalId: number = window.setInterval(() => {
      if (!this.websitesDropdown || !this.websitesDropdown.nativeElement) {
        return;
      }

      const activeElement: HTMLElement = <HTMLElement>this.websitesDropdown.nativeElement.querySelector('.website-item-wrapper.active');

      if (!activeElement) {
        return;
      }

      window.clearInterval(intervalId);
      
      activeElement.scrollIntoView({ behavior: 'instant' });
    });
  }

  public selectWebsite(website: WebsiteModel): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    if (this.isWebsitesDropdownBlocked) {
      return;
    }

    this.isWebsitesDropdownBlocked = true;

    if (!this.isSaveButtonEnabled) {
      this.doWebsiteChange(website);

      return;
    }

    this.websiteDesignerService.forceSaveChangesSubject.next(true);

    this.websiteDesignerService.onChangesSaved.pipe(take(1)).subscribe(() => {
      this.doWebsiteChange(website);
    });
  }

  private doWebsiteChange(website: WebsiteModel): void {
    const isActive: boolean = this.website && this.website.id === website.id;

    if (isActive) {
      this.navigationService.handleWebsiteChange(website).add(() => {
        this.isWebsitesDropdownVisible = false;
        this.isWebsitesDropdownBlocked = false;
      });

      return;
    }

    this.multiWebsiteService.setActiveWebsite(website.id).add(() => {
      this.websitesService.activeWebsiteIdSubject.pipe(take(1)).subscribe(() => {
        this.navigationService.handleWebsiteChange(website).add(() => {
          this.isWebsitesDropdownVisible = false;
          this.isWebsitesDropdownBlocked = false;
        });
      });
    });
  }

  public addNewWebsite(): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }
    
    if (this.isWebsitesDropdownBlocked) {
      return;
    }

    if (this.isLimitExceeded) {
      return this.websitesLimitExceededModalService.open();
    }

    this.isWebsitesDropdownBlocked = true;

    this.multiWebsiteService.isNewWebsiteAddingSubject.next(true);
    
    if (!this.isSaveButtonEnabled) {
      this.navigationService.toWebsitesManager();

      this.isWebsitesDropdownVisible = false;
      this.isWebsitesDropdownBlocked = false;

      return;
    }

    this.websiteDesignerService.forceSaveChangesSubject.next(true);

    this.websiteDesignerService.onChangesSaved.pipe(take(1)).subscribe(() => {
      this.isWebsitesDropdownVisible = false;
      this.isWebsitesDropdownBlocked = false;

      this.navigationService.toWebsitesManager();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
