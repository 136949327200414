import {SetupOptionModel} from '../option/setup-option.model';

import {CLASSES, CLASSES_LIST, CLASSES_KEYS, DEFAULT_CLASS_NAME} from './constants';

export class PositionModel extends SetupOptionModel {
  public element: HTMLElement;

  public get className() {
    return CLASSES[this.value];
  }

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const className = this.getClassName(this.element) || DEFAULT_CLASS_NAME;

    this.onChange(CLASSES_KEYS[className]);
  }

  private getClassName(element: HTMLElement) {
    return CLASSES_LIST.find(className => element.classList.contains(className));
  }

  public onChange(key: string) {
    const className = CLASSES[key];

    this.value = key;

    if (!this.isEnabled) return;

    this.removeClassNames(this.element);

    return this.element.classList.add(className);
  }

  private removeClassNames(element: HTMLElement) {
    CLASSES_LIST.forEach(className => element.classList.remove(className));
  }
}
