import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../auth/auth.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {ModalsService} from '../../../services/modals/modals.service';
import {BlogSetupService} from '../../../../core/services/blog-setup/blog-setup.service';
import {CaptchaHttpService} from '../../../../core/services/interaction/http/captcha/captcha-http.service';
import {BlogStylesService} from '../../../../core/services/styles/blog/blog-styles.service';
import {StylesSettingsService} from '../../../../core/services/styles/settings/styles-settings.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {WebsitesHttpService} from '../../../../core/services/interaction/http/websites/websites-http.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';
import {Button} from '../../../../common/models/button/button.model';
import {CaptchaDataModel} from '../../../../core/models/captcha-data/captcha-data.model';
import {StyleOptionModel} from '../../../../core/models/styles/settings/option/style-option.model';
import {BlogSetupModel} from '../../../../core/models/styles/setup/blog/blog-setup.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';

@Component({
  selector: 'app-blog-setup-modal',
  templateUrl: './blog-setup-modal.component.html',
  styleUrls: ['./blog-setup-modal.component.scss']
})
export class BlogSetupModalComponent implements OnInit, OnDestroy {
  @Input() id: string;

  public modalHeader: ModalHeader = {
    text: 'BLOG SETUP',
    className: 'neutral-header',
  };

  public buttons: Button[] = [
    {
      text: 'SAVE CAPTCHA KEYS',
      className: 'neutral green',
      onClick: this.onSave.bind(this),
    },
  ];

  public data: CaptchaDataModel;

  public isCaptchaVisibilityToggled: boolean = false;

  public isCommentsApprovable: boolean = false;

  public isSaved: boolean = false;
  public isOpened: boolean = false;
  public isFetched: boolean = true;
  public isInProgress: boolean = false;

  private account: AccountModel;
  private website: WebsiteModel;

  private optionsMap: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isButtonsExists(): boolean {
    return !!this.buttons && this.buttons.length > 0;
  }

  public get setup(): BlogSetupModel {
    return this.blogStylesService.setup;
  }

  constructor(private cdr: ChangeDetectorRef,
              private modalsService: ModalsService,
              private authService: AuthService,
              private buttonsService: ButtonsService,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private stylesSettingsService: StylesSettingsService,
              private websitesService: WebsitesService,
              private websitesHttpService: WebsitesHttpService,
              private service: BlogSetupService,
              private blogStylesService: BlogStylesService,
              private captchaHttpService: CaptchaHttpService) {
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;

      this.isCommentsApprovable = this.website && this.website.isBlogCommentsApprovable;
    });

    this.stylesSettingsService.optionsMapSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((options: Map<string, StyleOptionModel>) => {
      this.optionsMap = options;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onModalOpen({ block }: { block: HTMLElement }): void {
    this.isOpened = true;

    this.blogStylesService.init(block, this.optionsMap);

    this.fetchData();
  }

  private fetchData(): void {
    if (!this.isFetched) return;

    if (!this.account) return console.error(`BlogSetupModalComponent: can't fetch data.`);

    this.isFetched = false;

    this.captchaHttpService.fetchExisting(this.account.activeWebsiteId).subscribe((data: CaptchaDataModel) => {
      this.data = data;
      this.isFetched = true;

      this.cdr.detectChanges();
    });
  }

  public toggleCaptchaVisibility(): void {
    if (this.isCaptchaVisibilityToggled) return;

    if (!this.account || !this.data) return console.error(`BlogSetupModalComponent: can't toggle captcha's visibility.`);

    this.isCaptchaVisibilityToggled = true;

    this.captchaHttpService.toggleCaptchaVisibility(this.account.activeWebsiteId, !this.data.isCaptchaEnabled).subscribe(() => {
      this.data.isCaptchaEnabled = !this.data.isCaptchaEnabled;
      this.isCaptchaVisibilityToggled = false;

      this.cdr.detectChanges();

      this.eventsService.dispatchCaptchaVisibilitySet({ isCaptchaEnabled: this.data.isCaptchaEnabled }, this.iFrameService.sandboxWindow);
    });
  }

  public onSave(): void {
    if (this.isInProgress || !this.isFetched) return;

    if (!this.account || !this.data) return console.error(`BlogSetupModalComponent: can't save domains.`);

    this.isInProgress = true;

    this.captchaHttpService.saveDomains(this.account.activeWebsiteId, this.data.domains).subscribe(() => {
      this.isInProgress = false;
      this.isSaved = true;

      setTimeout(() => { this.isSaved = false }, 5000);
    });
  }

  public onClose(): void {
    this.isOpened = false;

    this.eventsService.dispatchBlogReload();

    this.modalsService.close(this.id);
  }

  public copy(e: Event, str: string): void {
    const target = <HTMLElement>e.currentTarget;

    target.classList.add('copied');

    const input = document.createElement('input');

    document.body.appendChild(input);

    input.value = str;

    input.select();

    document.execCommand('copy');

    input.remove();

    setTimeout(() => target.classList.remove('copied'), 500);
  }

  public onContactEmailUsedToggle(value: boolean): void {
    this.setup.isContactEmailUsed.onChange(value);

    this.buttonsService.enableSaveButton();
  }

  public onCommentsVisibilityToggle(value: boolean): void {
    this.setup.isCommentsEnabled.onChange(value);

    this.buttonsService.enableSaveButton();
  }

  public onCommentsApproveToggle(value: boolean): void {
    this.isCommentsApprovable = value;

    this.websitesHttpService.setIsBlogCommentsApprovable(this.isCommentsApprovable).pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.websitesService.fetchWebsite();
      })
    ).subscribe(() => {});
  }
}
