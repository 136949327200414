import {AccountCouponModel} from './account-coupon.model';
import {StripeCouponModel} from '../../stripe/coupon/stripe-coupon.model';
import {StripeCouponDto} from '../../stripe/coupon/stripe-coupon.dto';

export class AccountCouponDto {
  constructor(public Id?: number,
              public UserId?: number,
              public StripeCouponId?: string,
              public CreatedAt?: string,
              public UpdatedAt?: string,
              public StripeCoupon?: StripeCouponDto) {}

  public static normalize(res: AccountCouponDto): AccountCouponModel {
    if (!res) return null;

    return new AccountCouponModel(
      res.Id,
      res.UserId,
      res.StripeCouponId,
      res.CreatedAt,
      res.UpdatedAt,
      StripeCouponModel.normalize(res.StripeCoupon),
    );
  }
}
