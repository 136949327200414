import {SelectOption} from '../../../../select/option/option.model';

export const KEYS = {
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  LINKED_IN: 'LINKED_IN',
};

export const ATTRIBUTE = 'data-blog-social-icons';

export const OPTIONS = () => [
  new SelectOption('Facebook', KEYS.FACEBOOK, true),
  new SelectOption('Twitter', KEYS.TWITTER, true),
  new SelectOption('LinkedIn', KEYS.LINKED_IN, true),
];

export const SEPARATOR = ',';
