import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {WebsiteBlocksHttpService} from './http/website-blocks.http.service';

import {OutdatedBlocksDataModel} from '../../../models/outdated-blocks-data/outdated-blocks-data.model';

@Injectable()
export class WebsiteBlocksService {
  constructor(private httpService: WebsiteBlocksHttpService) {
  }

  public getCurrentPageBlocks({ websiteId, templateId, pageId, pageType }: { websiteId: number, templateId: number, pageId: number, pageType: string }): Observable<any> {
    return this.httpService.getCurrentPageBlocks({ websiteId, templateId, pageId, pageType });
  }

  public getOutdatedBlocks({ websiteId }: { websiteId: number }): Observable<OutdatedBlocksDataModel> {
    return this.httpService.getOutdatedBlocks({ websiteId });
  }

  public getIsWebsiteHasCustomPages({ websiteId, templateId, currentPageId }: { websiteId: number, templateId: number, currentPageId: number }): Observable<{ isWebsiteHasCustomPages: boolean }> {
    return this.httpService.getIsWebsiteHasCustomPages({ websiteId, templateId, currentPageId });
  }

  public saveBlocks({ websiteId, meta, blocks, movedBlocksToAnotherPage }: { websiteId: number, meta: { WebsiteID: number, TemplateID: number, PageID: number, PageType: string, saveType: string }, blocks: any, movedBlocksToAnotherPage: any }): Observable<any> {
    return this.httpService.saveBlocks({ websiteId, meta, blocks, movedBlocksToAnotherPage });
  }

  public deleteOneBlock({ blockId, websiteId, pageId, pageType }: { blockId: number, websiteId: number, pageId: number, pageType: string }): Observable<any> {
    return this.httpService.deleteOneBlock({ blockId, websiteId, pageId, pageType });
  }
}
