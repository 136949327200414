import {AddOnModel} from '../../../add-ons/add-on.model';
import {AddOnCartItemOptionsDto} from './add-on-cart-item-options.dto';

export class AddOnCartItemOptionsModel {
  constructor(
    public addOn?: AddOnModel,
  ) {
  }

  public static normalize(res: AddOnCartItemOptionsDto): AddOnCartItemOptionsModel {
    if (!res) return null;

    return new AddOnCartItemOptionsModel(
      AddOnModel.normalize(res.addOn),
    );
  }
}
