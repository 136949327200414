import {EducatorImageManagerTabs} from "../../../services/education/image-manager/constants";
import {StudentImageManagerTabs} from "../../../services/education/students/image-manager/constants";
import {ImageManagerUserTypes} from "./constants";

export class ImageManagerUserTypeModel {
  constructor(
    public type: ImageManagerUserTypes,
    public tab: EducatorImageManagerTabs | StudentImageManagerTabs,
  ) {}
}
