import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-circle-progress-bar',
  templateUrl: './circle-progress-bar.component.html',
  styleUrls: ['./circle-progress-bar.component.scss']
})
export class CircleProgressBarComponent {

  _isLoading = true;
  @Input()
  set isLoading(value) {
    if (!value && this._isLoading) {
      this.restart = true;
    } else if (value && !this._isLoading) {
      this.restart = false;
    }

    this._isLoading = value;
  }
  get isLoading() {
    return this._isLoading;
  }
  restart = false;

  constructor() {}
}
