import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EducationStudentWebsiteDto} from '../../../../../../models/education/students/websites/education-student-website.dto';
import {EducationStudentWebsiteModel} from '../../../../../../models/education/students/websites/education-student-website.model';
import {WebsiteModel} from '../../../../../../models/websites/website.model';
import {WebsiteDto} from '../../../../../../models/websites/website.dto';

@Injectable()
export class EducationStudentsWebsitesHttpService {
  private prefix: string = 'api/education/students/current';

  constructor(private http: HttpClient) {
  }

  public getAll(): Observable<EducationStudentWebsiteModel[]> {
    return this.http.get(`${this.prefix}/websites`).pipe(
      map((list: EducationStudentWebsiteDto[]) => {
        if (!list) {
          return [];
        }

        return list.map((item: EducationStudentWebsiteDto) => EducationStudentWebsiteDto.normalize(item));
      })
    );
  }

  public getMainWebsite(): Observable<WebsiteModel> {
    return this.http.get(`${this.prefix}/websites/main`).pipe(
      map((website: WebsiteDto) => {
        return WebsiteDto.normalize(website);
      })
    );
  }

  public getShortById(websiteId: number): Observable<EducationStudentWebsiteModel> {
    return this.http.get(`${this.prefix}/websites/${websiteId}/short`).pipe(
      map((website: EducationStudentWebsiteDto) => {
        return website ? EducationStudentWebsiteDto.normalize(website) : null;
      })
    );
  }

  // public getOne(websiteId: number): Observable<EducationStudentWebsiteModel> {
  //   return this.http.get(`${this.prefix}/websites/${websiteId}`).pipe(
  //     map((item: EducationStudentWebsiteDto) => EducationStudentWebsiteDto.normalize(item))
  //   );
  // }
}
