import {ChangeDetectorRef, Component, ElementRef, OnInit, OnDestroy, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ActivatedRoute, Router} from '@angular/router';

import {EditorControlButtonsService} from '../../../../../services/editor-control-buttons.service';
import {WebsiteDesignerService} from '../../website-designer.service';
import {NavigationService} from '../../../../../services/navigation.service';
import {MenuNodesManagerService} from '../../../../../services/menu-nodes-manager.service';
import {EventsService} from '../../../../../core/services/interaction/events/events.service';
import {PageEditorService} from '../page-editor.service';
import {BlocksManagerService} from '../../../../../core/services/blocks/blocks.service';
import {IFrameService} from '../../../../../core/services/iframe/iframe.service';
import {NodesService} from '../../../../../core/services/nodes/nodes.service';
import {WebsitesService} from '../../../../../core/services/websites/websites.service';
import {WebsiteTourService} from '../../../../../core/services/website-tour/website-tour.service';

import {OutdatedPagesDataModel} from '../../../../../core/models/outdated-pages-data/outdated-pages-data.model';
import {WebsiteModel} from '../../../../../core/models/websites/website.model';

import {NodeModel} from '../../../../../core/models/nodes/node.model';

import {AppAnimations} from '../../../../../app-animations';

import {TOUR_KEY} from './constants';
import {KEYS} from '../../../../../core/services/website-tour/constants';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PageMenuComponent implements OnInit, OnDestroy {
  @ViewChild('pageListElement') pageListElement: ElementRef;
  @ViewChild('pageMenu') pageMenu: ElementRef;

  private website: WebsiteModel;

  private ngUnsubscribe = new Subject();

  public pageList: NodeModel[];

  public draggedBlockData = null;

  public isCollapsed: boolean = true;
  public isArrowBlinking: boolean = false;
  public isSplashEnabled: boolean = false;

  public outdatedPages: OutdatedPagesDataModel = null;

  public get isLoaded() {
    return this.pageEditorService.isEditorLoaded;
  }

  constructor(
    public editorControlButtonsService: EditorControlButtonsService,
    public pageEditorService: PageEditorService,
    private chRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private websiteDesignerService: WebsiteDesignerService,
    private websitesService: WebsitesService,
    private menuNodesManagerService: MenuNodesManagerService,
    private blocksManagerService: BlocksManagerService,
    private navigationService: NavigationService,
    private eventsService: EventsService,
    private nodesService: NodesService,
    private websiteTourService: WebsiteTourService,
    private iFrameService: IFrameService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.websiteTourService.addVisibleLocation(TOUR_KEY);
    this.websiteTourService.addVisibleItem(KEYS.PAGES_TOP_MENU);

    this.blocksManagerService.outdatedPagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((pageIds: OutdatedPagesDataModel) => {
      this.outdatedPages = pageIds;
      this.chRef.detectChanges();
    });

    this.eventsService.addFrameListener('collapseHorizontalMenu', () => {
      this.pageEditorService.isPageMenuCollapsedSubject.next(true);
    });

    this.pageEditorService.isPageMenuCollapsedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCollapsed: boolean) => {
      this.isCollapsed = isCollapsed;

      this.cdr.detectChanges();
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.pageList = nodes;
    });

    this.nodesService.isSplashEnabledSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isSplashEnabled => {
      this.isSplashEnabled = isSplashEnabled;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.pageEditorService.draggedBlockData.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => this.handleDraggedBlockDataChanged(data));
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(TOUR_KEY);
    this.websiteTourService.removeVisibleItem(KEYS.PAGES_TOP_MENU);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public handleDraggedBlockDataChanged(data) {
    this.draggedBlockData = data;
  }
}

