import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'mapValue'
})
export class ValueMapperPipe implements PipeTransform {

  transform(value: any, source: any, target: any): any {
    return value === source ? target : value;
  }
}
