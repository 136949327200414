import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { of } from 'rxjs';

import {environment} from '../../environments/environment';

import {InstagramAccessTokenResponse} from '../../models/instagram/access.token.response';
import {UserSocialMediaDto} from '../../models/user-social-media/user-social-media.dto';
import {UserSocialMediaModel} from '../../models/user-social-media/user-social-media.model';
import {SocialNetwork} from '../core/models/social-networks/social-network/social-network.model';

@Injectable()
export class InstagramService {
  private AUTH_URI =
    `oauth/authorize/?client_id=${environment.instagram.clientId}&redirect_uri=${environment.instagram.redirectUri}&response_type=code`;


  get apiBase() {
    return environment.instagram.apiBase;
  }

  get model() {
    return environment.instagram.model;
  }

  constructor(
    private httpClient: HttpClient,
    private zone: NgZone,
  ) {}

  public onSignIn(socialNetwork?: SocialNetwork, onSuccess?: (socialNetwork: SocialNetwork) => {}) {
    const options = 'top=300,left=600,width=430,height=360,resizable=yes,scrollbars=yes';
    (<any> window).angularInstagramComponent = {
      zone: this.zone,
      setInstagramToken: this.setInstagramToken.bind(this, socialNetwork, onSuccess)
    };
    const url = this.getInstagramSingInUrl();
    window.open(url, '', options);
  }

  private setInstagramToken(socialNetwork?: SocialNetwork, onSuccess?: (socialNetwork: SocialNetwork) => {}, code?: string) {
    if (!code || code.length === 0) return;
    return this.getAccessToken(socialNetwork, onSuccess, code);
  }

  public getAccessToken(socialNetwork?: SocialNetwork, onSuccess?: (socialNetwork: SocialNetwork) => {}, code?: string) {
    return of('');
    // return this.httpClient.post(`${environment.apiBase}/${this.model}/api/access-token`, { code })
    // .then((res: InstagramAccessTokenResponse) => {
    //   if (res.code && res.code !== 200) return;
    //   socialNetwork.userSocialNetwork.token = res.access_token;
    //   socialNetwork.userSocialNetwork.userName = res.user.username;
    //   return onSuccess(socialNetwork);
    // })
    // .then((res: UserSocialMediaDto) => {
    //   const user = UserSocialMediaModel.fromDto(res);
    // });
  }

  getInstagramSingInUrl() {
    return `${this.apiBase}/${this.AUTH_URI}`;
  }
}
