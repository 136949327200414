import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {STYLE_PROPERTIES} from './constants';
import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/custom-menu/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/custom-mobile-menu/constants';

export class TextDecorationModel implements IStyleOption {
  public element: HTMLElement;

  private anotherKey: string;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
    this.anotherKey = this.key === DESKTOP_KEYS.LINKS_TEXT_DECORATION ? MOBILE_KEYS.MOBILE_LINKS_TEXT_DECORATION : DESKTOP_KEYS.LINKS_TEXT_DECORATION;
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = options.block;

    if (!this.element) return;

    const value: string = this.element.style.getPropertyValue(STYLE_PROPERTIES[this.key]) || window.getComputedStyle(this.element)['text-decoration'].split(' ')[0] || 'none';

    this.setValue(value === 'undefined' ? void 0 : value.trim());
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(STYLE_PROPERTIES[this.key]);

    const value: string = this.element.style.getPropertyValue(STYLE_PROPERTIES[this.key]) || this.element.style.getPropertyValue(STYLE_PROPERTIES[this.anotherKey]) || window.getComputedStyle(this.element)['text-decoration'].split(' ')[0] || 'none'

    this.setValue(value);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(STYLE_PROPERTIES[this.key], value);
  }
}
