import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';

import {Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../../app-animations';
import {WebsiteDesignerService} from '../../../../application/main/website-designer/website-designer.service';
import {ModalsService} from '../../../services/modals/modals.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';

@Component({
  selector: 'app-bug-report-modal',
  templateUrl: './bug-report-modal.component.html',
  styleUrls: ['./bug-report-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class BugReportModalComponent implements OnInit, OnDestroy {
  @Input() id: string = 'bug-report-modal';

  @ViewChild('files')
  public files: ElementRef;

  report = {
    title: '',
    desc: '',
    isAutomated: false,
    attachments: [],
  };

  thanksMessage = 'Thanks! Your report has been sent.';
  isSent = false;

  private templateId: number;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private modalsService: ModalsService,
              private websiteDesignerService: WebsiteDesignerService,
              private websitesService: WebsitesService) {
  }

  public ngOnInit(): void {
    this.websitesService.activeTemplateIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((templateId: number) => {
      if (!templateId) return;

      this.templateId = templateId;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  closeBugReport() {
    this.modalsService.close(this.id);
  }

  addFiles(event) {
    const target = event.target || event.srcElement;

    this.report.attachments = (<any>target).files;
  }

  sendReport(form: NgForm): void {
    const formData = new FormData();

    formData.append('title', this.report.title);
    formData.append('desc', this.report.desc);
    formData.append('templateId', `${this.templateId}`);
    formData.append('userAgent', navigator.userAgent || navigator.vendor || (<any>window).opera);

    for (let i = 0; i < this.report.attachments.length; i++) {
      formData.append('attachments', this.report.attachments[i]);
    }

    this.websiteDesignerService.sendReport(formData).pipe(
      catchError(e => {
        console.log(e);

        this.isSent = false;

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.isSent = true;

      window.setTimeout(() => {
        this.clear(form);
        this.closeBugReport();
      }, 3000);
    });
  }

  clear(form: NgForm) {
    this.report.title = '';
    this.report.desc = '';
    this.report.attachments = [];
    this.files.nativeElement.value = null;
    this.isSent = false;

    form.resetForm();
  }
}
