import {ImagePriceModel} from './image-price.model';
import {ImageAvailabilityDto} from '../availability/image-availability.dto';
import {ImageProcessDto} from '../../../../models/image-processes/image-process.dto';

export class ImagePriceDto {
  constructor(public Id: number,
              public ImageId: number,
              public MediumId: number,
              public ProcessId: number,
              public AvailabilityId: number,
              public Price: number, // cents
              public Width: string,
              public Height: string,
              public Length: string,
              public MatWidth: string,
              public MatHeight: string,
              public EditionNumber: string,
              public EditionSize: string,
              public IsEditioned: boolean,
              public IsPriceDefault: boolean,
              public IsProcessDefault: boolean,
              public IsImageSizeDefault: boolean,
              public IsMatSizeDefault: boolean,
              public IsLengthDefault: boolean,
              public IsEditionedDefault: boolean,
              public IsAvailabilityDefault: boolean,
              public ImageProcess?: ImageProcessDto,
              public ImageAvailability?: ImageAvailabilityDto) {}

  public static normalize(res: ImagePriceDto): ImagePriceModel {
    if (!res) return null;

    return new ImagePriceModel(
      res.Id,
      res.ImageId,
      res.MediumId,
      res.ProcessId,
      res.AvailabilityId,
      res.Price / 100,
      res.Width ? parseFloat(res.Width) : null,
      res.Height ? parseFloat(res.Height) : null,
      res.Length ? parseFloat(res.Length) : null,
      res.MatWidth ? parseFloat(res.MatWidth) : null,
      res.MatHeight ? parseFloat(res.MatHeight) : null,
      res.EditionNumber,
      res.EditionSize,
      res.IsEditioned,
      res.IsPriceDefault,
      res.IsProcessDefault,
      res.IsImageSizeDefault,
      res.IsMatSizeDefault,
      res.IsLengthDefault,
      res.IsEditionedDefault,
      res.IsAvailabilityDefault,
      ImageProcessDto.normalize(res.ImageProcess),
      ImageAvailabilityDto.normalize(res.ImageAvailability),
    );
  }

  public static toRequest(res: ImagePriceModel): ImagePriceDto {
    return new ImagePriceDto(
      res.id,
      res.imageId,
      res.mediumId,
      res.processId,
      res.availabilityId,
      Math.round(res.price * 100),
      res.width ? String(res.width) : null,
      res.height ? String(res.height) : null,
      res.length ? String(res.length) : null,
      res.matWidth ? String(res.matWidth) : null,
      res.matHeight ? String(res.matHeight) : null,
      res.editionNumber,
      res.editionSize,
      res.isEditioned,
      res.isPriceDefault,
      res.isProcessDefault,
      res.isImageSizeDefault,
      res.isMatSizeDefault,
      res.isLengthDefault,
      res.isEditionedDefault,
      res.isAvailabilityDefault,
    );
  }
}
