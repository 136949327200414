import {TeacherPortfolioDataModel} from "../education/teacher-portfolio-data/teacher-portfolio-data.model";

export class PortfolioModel {
  public initialTitle?: string;
  
  public isEditing?: boolean;

  constructor(
    public id?: number,
    public websiteId?: number,
    public nodeId?: number,
    public userId?: number,
    public classId?: number,
    public type?: string,
    public title?: string,
    public totalImagesCount?: number,
    public publishedImagesCount?: number,
    public teacherData?: TeacherPortfolioDataModel,
    public isShared?: boolean,
    public isDesktopPageVisible?: boolean,
    public isMobilePageVisible?: boolean,
  ) {
  }
}
