import {BaseFontWeightModel} from '../base/font-weight/base-font-weight.model';

import {STYLE_PROPERTY_NAME} from './constants';

export class PortfolioImageTitleFontWeightModel extends BaseFontWeightModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled, STYLE_PROPERTY_NAME);
  }
}
