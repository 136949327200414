import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';

import {EventsService} from '../interaction/events/events.service';

import {BUTTONS_KEYS} from './constants';

@Injectable()
export class ButtonsService {
  public onButtonChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onPublishButtonChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onButtonVisibilityChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onButtonClickSubject: Subject<string> = new Subject<string>();

  private buttonsStates: Map<string, boolean> = new Map<string, boolean>();
  private buttonsVisibilities: Map<string, boolean> = new Map<string, boolean>();

  private get values(): string[] {
    return Array.from(this.buttonsStates.keys());
  }

  constructor(private eventsService: EventsService) {
    this.addListeners();
  }

  public addListeners() {
    this.eventsService.addFrameListener('setButtonState', (e: CustomEvent) => {
      if (!e.detail) return;

      const { key, value } = e.detail;
      this.setButtonState(key, value);
    });

    this.eventsService.addFrameListener('setButtonVisibility', (e: CustomEvent) => {
      if (!e.detail) return;

      const { key, value } = e.detail;
      this.setButtonVisibility(key, value);
    });
  }

  public disableAllButtons() {
    this.values.forEach(key => this.setButtonState(key, false));
  }

  public getButtonState(key: string): boolean {
    if (!this.buttonsStates.has(key)) return false;

    return this.buttonsStates.get(key);
  }

  public enableSaveButton() {
    this.setButtonState(BUTTONS_KEYS.SAVE, true);

    this.enableCancelButton();
  }

  public disableSaveButton() {
    this.setButtonState(BUTTONS_KEYS.SAVE, false);
  }

  public enableCancelButton() {
    this.setButtonState(BUTTONS_KEYS.CANCEL, true);
  }

  public disableCancelButton() {
    this.setButtonState(BUTTONS_KEYS.CANCEL, false);
  }

  public setButtonState(key: string, value: boolean) {
    this.buttonsStates.set(key, value);

    this.onButtonChangeSubject.next(key);
  }

  public getButtonVisibility(key: string): boolean {
    if (!this.buttonsVisibilities.has(key)) return false;

    return this.buttonsVisibilities.get(key);
  }

  public setSaveButtonVisibility(value: boolean) {
    this.setButtonVisibility(BUTTONS_KEYS.SAVE, value);
  }

  public setCancelButtonVisibility(value: boolean) {
    this.setButtonVisibility(BUTTONS_KEYS.CANCEL, value);
  }

  public setButtonVisibility(key: string, value: boolean) {
    this.buttonsVisibilities.set(key, value);
    this.onButtonVisibilityChangeSubject.next(key);
  }

  public onClick(key: string) {
    this.onButtonClickSubject.next(key);
  }
}
