import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Subscription, BehaviorSubject, Observable, throwError, catchError} from 'rxjs';

import {AccountCouponDto} from '../../../../../models/accounts/coupons/account-coupon.dto';
import {AccountCouponModel} from '../../../../../models/accounts/coupons/account-coupon.model';
import {StripeCouponDto} from '../../../../../models/stripe/coupon/stripe-coupon.dto';

@Injectable()
export class AccountsCouponsHttpService {
  public userCoupons: BehaviorSubject<AccountCouponModel[]> = new BehaviorSubject<AccountCouponModel[]>([]);
  public errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private model: string = 'accounts';

  constructor(private http: HttpClient) {}

  public createUserCoupon(userId: number, stripeCouponId: string): Subscription {
    return this.http.post(`api/${this.model}/${userId}/coupons`, { stripeCouponId }, { responseType: 'text' }).pipe(
      catchError(e => {
        console.error(e);

        this.errorMessage.next(`Can't add this coupon to user. Probably, this user already has it.`);
        
        return throwError(() => e);
      })
    ).subscribe(() => {
      this.getUserCoupons(userId);
    });
  }

  public deleteUserCoupon(coupon: AccountCouponModel): Subscription {
    return this.http.delete(`api/${this.model}/${coupon.userId}/coupons/${coupon.id}`, { responseType: 'text' }).subscribe(() => {
      this.getUserCoupons(coupon.userId);
    });
  }

  public getUserCoupons(userId: number): Subscription {
    return this.http.get(`api/${this.model}/${userId}/coupons`).subscribe((res: AccountCouponDto[]) => {
      if (!res) return;

      this.userCoupons.next(res.map(item => AccountCouponDto.normalize(item)));
    });
  }

  public validateCoupon(userId: number, code: string): Observable<{ isValid: boolean, coupon: StripeCouponDto }> {
    return this.http.get<{ isValid: boolean, coupon: StripeCouponDto }>(`api/${this.model}/${userId}/coupons/${code}/validation`);
  }
}
