import { SelectOption } from '../../../../select/option/option.model';
import { IStyleModelOptions } from '../../option/i-style-model-options';
import {SetupOptionModel} from '../../option/setup-option.model';

import {ATTRIBUTE, OPTIONS, SEPARATOR} from './constants';

export class BlogSocialIconsModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) {
      return;
    }

    this.isEnabled = options.block && options.block.classList.contains('blog');

    this.element = element;

    if (!this.isEnabled) {
      return;
    }

    this.value = this.parseString();
  }

  public reset(): void {
    this.onChange(OPTIONS());
  }

  public onChange(options: SelectOption[]): void {
    this.value = options;

    if (!this.isEnabled) {
      return;
    }

    this.element.setAttribute(ATTRIBUTE, this.getString());
  }

  private getString(): string {
    const selectedItems: SelectOption[] = this.value.filter((item: SelectOption) => item.isSelected);

    if (selectedItems.length === 0) {
      return 'null';
    }

    return selectedItems
      .map((item: SelectOption) => item.value)
      .join(SEPARATOR);
  }

  private parseString(): SelectOption[] {
    const options: SelectOption[] = OPTIONS();

    if (!this.element.hasAttribute(ATTRIBUTE)) {
      return options;
    }

    const enabledSocialNetworks: string[] = this.element.getAttribute(ATTRIBUTE).split(SEPARATOR);

    options.forEach((item: SelectOption) => {
      item.isSelected = enabledSocialNetworks.includes(item.value);
    });

    return options;
  }
}
