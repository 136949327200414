export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `ತಮ್ಮ ಎಲ್ಲಾ ಉಪಕರಣಗಳು ಮತ್ತು ಸಲಕರಣೆಗಳು ಒಂದು ಅಥವಾ ಇನ್ನೊಂದು ರೂಪದಲ್ಲಿ ಜೀವಂತವಾಗಿದ್ದವು.`,
    ],
    [KEYS.ALPHABET]: [
      'ಅಆಇಈಉಊಋೠಎಏಐಒಓಔಅಂಅಃಕಖಗಘಙಚಛಜಝಞಟಠಡಢಣತಥದಧನಪಫಬಭಮಯರಲವಶಷಸಹಳಕ್ಷಜ್ಞ§₹',
    ],
    [KEYS.PARAGRAPH]: [
      `ಆದರೂ ನಾನು ಮಂಗಳನ ಮುಖ ನೋಡಿದೆ ಹಾಗೂ ಅದು ಒಂದು ಅಪರೂಪದ ಅನುಭವ ಆಗಿತ್ತು. ಉತ್ತಮ ದೂರದರ್ಶಕದ ಮೂಲಕ ಆಕಾಶಕಾಯಗಳನ್ನು ವೀಕ್ಷಿಸಬಹುದು ಹಾಗೂ ಜಗತ್ತಿನ ಸುತ್ತ ಒಂದು ಪ್ರವಾಸವು ಒಂದು ಶಿಕ್ಷಣದ ಒಂದು ಭಾಗವಾಗಿ ರೂಪುಗೊಳ್ಳುವಂತೆ ನನಗೆ ತೋರುತ್ತದೆ.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
