import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {SelectOption} from '../../../../select/option/option.model';

import {CLASSES, DEFAULT_OPTIONS} from './constants';

export class MenuDropdownItemHoverDesignModel implements IStyleOption {
  public options: SelectOption[] = DEFAULT_OPTIONS();

  public isColorDesign: boolean = false;

  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled) return;

    this.element = options.block;

    this.isEnabled = !!this.element;

    if (!this.isEnabled) return;

    this.options.forEach((option: SelectOption) => {
      option.isSelected = this.element.classList.contains(option.value);
    });

    this.initIsColorDesign();
  }

  public reset(): void {
    this.onSelect(DEFAULT_OPTIONS());
  }

  public onSelect(options: SelectOption[]): void {
    if (!this.isEnabled) return;

    this.options = options;

    this.options.forEach((option: SelectOption) => {
      this.element.classList.toggle(option.value, option.isSelected);
    });

    this.initIsColorDesign();
  }

  private initIsColorDesign(): void {
    this.options.forEach((option: SelectOption) => {
      if (option.value !== CLASSES.COLOR) return;

      this.isColorDesign = option.isSelected;
    });
  }
}
