import {STEPS} from '../../../../core/services/default-portfolio/setup/constants';

export const NUMBERS: { [key: string]: string } = {
  [STEPS.UPDATE]: '1',
  [STEPS.APPLY]: '2',
  [STEPS.NOTE]: '3',
  [STEPS.CONFIRMATION]: null,
};

export const TITLES: { [key: string]: string } = {
  [STEPS.UPDATE]: 'UPDATE',
  [STEPS.APPLY]: 'APPLY',
  [STEPS.NOTE]: 'NOTE',
  [STEPS.CONFIRMATION]: 'CONFIRMATION',
};
