import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {CLASSES, CLASSES_LIST, CLASSES_KEYS, DEFAULT_CLASS_NAME} from './constants';

export class MenuPositionModel implements IStyleOption {
  private innerWrapper: HTMLElement;
  public element: HTMLElement;

  public get className() {
    return CLASSES[this.value];
  }

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = options.block;
    this.innerWrapper = options.innerWrapper;

    if (!this.isEnabled || !this.innerWrapper) return this.clear();

    const className = CLASSES_LIST.find(className => this.element.classList.contains(className)) || DEFAULT_CLASS_NAME;

    this.setValue(CLASSES_KEYS[className]);
  }

  public setValue(key: string) {
    const className = CLASSES[key];

    this.value = key;

    this.removeClasses();

    this.innerWrapper.classList.add(className);
    this.element.classList.add(className);
  }

  private removeClasses() {
    CLASSES_LIST.forEach(className => {
      this.innerWrapper.classList.remove(className);
      this.element.classList.remove(className);
    });
  }

  private clear() {
    this.value = null;
    this.isEnabled = false;
  }
}
