import {Injectable} from '@angular/core';

import {IFrameBaseStylesService} from '../base/base-styles.service';

import {CLASSES, DEFAULT_OPTIONS} from './constants';

@Injectable()
export class IFrameMobileActiveStylesService extends IFrameBaseStylesService {
  constructor() {
    super();

    this.classes = [CLASSES.BOLD];
    this.getDefaultOptions = DEFAULT_OPTIONS;
    this.options = DEFAULT_OPTIONS();
  }
}
