import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {SocialNetworksService} from '../../../../../core/services/social-networks/social-networks.service';
import {IFrameService} from '../../../../../core/services/iframe/iframe.service';
import {EventsService} from '../../../../../core/services/interaction/events/events.service';
import {ButtonsService} from '../../../../../core/services/buttons/buttons.service';
import {WebsiteDesignerService} from '../../../website-designer/website-designer.service';

import {SocialNetwork} from '../../../../../core/models/social-networks/social-network/social-network.model';

import {FONT_SIZES} from '../../../../../core/models/styles/native-setup/font-size/constants';

@Component({
  selector: 'app-share-social-media',
  templateUrl: './share-social-media.component.html',
  styleUrls: ['./share-social-media.component.scss'],
})
export class ShareSocialMediaComponent implements OnInit, OnDestroy {
  public isOpened: boolean = false;

  public socialNetworks: SocialNetwork[];
  public selectedSocialNetworks: SocialNetwork[];

  public element: HTMLElement = null;

  public color: string = '';
  public fontSize: Number = 14;

  private mousedownHandler = this.closeDropdown.bind(this);
  private removeMouseDownHandler = null;

  private socialNetworksWithShareLinks: string[] = ['Facebook', 'Twitter', 'LinkedIn'];

  private ngUnsubscribe = new Subject<void>();

  public get fontSizes(): number[] {
    return FONT_SIZES;
  }

  constructor(private websiteDesignerService: WebsiteDesignerService,
              private iFrameService: IFrameService,
              private buttonsService: ButtonsService,
              private socialNetworksService: SocialNetworksService,
              private eventsService: EventsService,
              private cdr: ChangeDetectorRef,
              private renderer: Renderer2) {
    this.socialNetworksService.list.pipe(takeUntil(this.ngUnsubscribe)).subscribe(list => {
      this.socialNetworks = list.filter(socialNetwork => this.socialNetworksWithShareLinks.includes(socialNetwork.name));
    });
  }

  public ngOnInit(): void {
    this.websiteDesignerService.editingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(options => {
      this.element = options && options.element && options.element.matches('[data-editable-type="SHARE_SOCIAL_ICON_CONTAINER"]') ? options.element : null;

      if (!this.element) return;

      this.initStyles();
      this.initSelectedSocialNetworks();
    });
  }

  public ngOnDestroy(): void {
    if (this.removeMouseDownHandler) this.removeMouseDownHandler();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeDropdown(event) {
    if (event.target.classList.contains('dropdown-head')) return;

    this.isOpened = false;

    this.cdr.detectChanges();

    if (this.removeMouseDownHandler) this.removeMouseDownHandler();
  }

  private initSelectedSocialNetworks() {
    if (!this.socialNetworks) return;

    this.selectedSocialNetworks = [];

    const shareSocialIcons = this.element.querySelectorAll('[data-share-social-icon]');

    const socialNetworksIds = Array.from(shareSocialIcons).map(socialIcon => parseInt(socialIcon.getAttribute('data-share-social-icon'), 10));

    socialNetworksIds.forEach(socialNetworkId => {
      const network = this.socialNetworks.find(socialNetwork => socialNetwork.id === socialNetworkId);

      if (network) this.selectedSocialNetworks.push(network);
    });
  }

  public addSocialNetwork(socialNetwork: SocialNetwork) {
    this.selectedSocialNetworks = this.selectedSocialNetworks.filter(network => network.name !== socialNetwork.name);

    this.selectedSocialNetworks.unshift(socialNetwork);

    this.dispatchShareSocialNetworksChanged();

    this.cdr.detectChanges();
  }

  public removeSocialNetwork(socialNetwork: SocialNetwork) {
    this.selectedSocialNetworks = this.selectedSocialNetworks.filter(network => network.name !== socialNetwork.name);

    this.dispatchShareSocialNetworksChanged();

    this.cdr.detectChanges();
  }

  private dispatchShareSocialNetworksChanged() {
    this.eventsService.dispatchShareSocialNetworksChanged({ container: this.element, socialNetworks: this.selectedSocialNetworks }, this.iFrameService.sandboxWindow);
    this.buttonsService.enableSaveButton();
  }

  public onFontSizeChange() {
    if (!this.element) return;

    this.element.style.fontSize = `${this.fontSize}px`;
    this.eventsService.dispatchResize(this.iFrameService.sandboxWindow);
    this.buttonsService.enableSaveButton();
  }

  public onFontSizeReset() {
    if (!this.element) return;

    this.element.style.removeProperty('font-size');
    this.buttonsService.enableSaveButton();

    this.initStyles();

    this.cdr.detectChanges();
  }

  public onColorChange() {
    if (!this.element) return;

    this.element.style.color = this.color;
    this.buttonsService.enableSaveButton();
  }

  public onColorReset() {
    if (!this.element) return;

    this.element.style.removeProperty('color');
    this.buttonsService.enableSaveButton();

    this.initStyles();

    this.cdr.detectChanges();
  }

  private initStyles() {
    const styles = window.getComputedStyle(this.element);

    this.color = styles['color'];
    this.fontSize = Number.parseInt(styles['font-size']);
  }

  public onDropdownHeadClick() {
    this.isOpened = !this.isOpened;

    this.cdr.detectChanges();

    if (this.isOpened) {
      this.removeMouseDownHandler = this.renderer.listen('document', 'mouseup', this.mousedownHandler);

      return;
    }

    if (this.removeMouseDownHandler) this.removeMouseDownHandler();
  }
}
