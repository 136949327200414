import {Injectable} from '@angular/core';

import {MobileSubtitleSetupModel} from '../../../../models/styles/setup/website-subtitle/mobile/mobile-subtitle-setup.model';

@Injectable()
export class MobileSubtitleStylesService {
  public setup: MobileSubtitleSetupModel;

  public isModernMenu: boolean = false;

  constructor() {
    this.setup = new MobileSubtitleSetupModel();
  }

  public init(menu: HTMLElement): void {
    if (!menu) return;

    const menuBlock: HTMLElement = <HTMLElement>menu.closest('[data-menu-block]');

    this.isModernMenu = menuBlock.matches('[data-menu-type="modern"]');

    const subtitle: HTMLElement = <HTMLElement>menuBlock.querySelector(this.isModernMenu ? '[data-website-subtitle]' : '[data-website-subtitle-mobile]');

    this.setup.init(subtitle);
  }
}
