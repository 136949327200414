
import {Injectable} from '@angular/core';
import {RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {Observable, Subject, Subscriber, combineLatest} from 'rxjs';
import {takeUntil, debounceTime} from 'rxjs/operators';

import {PaymentSubscriptionsService } from '../../../services/payment/subscriptions/payment-subscriptions.service';
import {WebsitesService } from '../../../services/websites/websites.service';
import {EducationTeachersWebsitesService } from '../../../services/education/teachers/websites/education-teachers-websites.service';
import {NavigationService} from '../../../../services/navigation.service';
import {ClassEndedModalService} from '../../../../shared/services/modals/class-ended/class-ended-modal.service';
import {AuthService} from '../../../../auth/auth.service';

import {SubscriptionModel} from '../../../models/payment/subscriptions/subscription.model';
import {WebsiteModel} from '../../../models/websites/website.model';
import {CourseWebsiteModel} from '../../../models/websites/course-website.model';
import {AccountModel} from '../../../models/accounts/account.model';

@Injectable()
export class IsClassActiveGuard  {
  constructor(
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private educationTeachersWebsitesService: EducationTeachersWebsitesService,
    private websitesService: WebsitesService,
    private authService: AuthService,
    private classEndedModalService: ClassEndedModalService,
    private navigationService: NavigationService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let unsubscribe: Subject<boolean> = new Subject<boolean>();

    return new Observable((subscriber: Subscriber<boolean>) => {
      this.authService.accountSubject.subscribe((account: AccountModel) => {
        if (!this.authService.isFetched) {
          return;
        }

        unsubscribe.next(true);
        unsubscribe.complete();

        if (account.isAdmin) {
          subscriber.next(true);

          return;
        }

        unsubscribe = new Subject<boolean>();

        this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(unsubscribe)).subscribe((subscription: SubscriptionModel) => {
          if (!subscription) {
            if (this.paymentSubscriptionsService.isCurrentSubscriptionFetched) {
              subscriber.next(false);
            }
  
            return;
          }
  
          if (!subscription.isEducator) {
            subscriber.next(true);
  
            return;
          }
  
          combineLatest([
            this.websitesService.activeWebsiteIdSubject,
            this.websitesService.activeWebsiteSubject,
            this.educationTeachersWebsitesService.listSubject,
          ]).pipe(debounceTime(100)).pipe(takeUntil(unsubscribe)).subscribe(([websiteId, website, educatorWebsites]: [number, WebsiteModel, CourseWebsiteModel[]]) => {
            if (!website || !educatorWebsites || website.id !== websiteId) {
              return;
            }
  
            const currentEducatorWebsite: CourseWebsiteModel = educatorWebsites.find((educatorWebsite: CourseWebsiteModel) => educatorWebsite.id === website.id);
  
            if (!currentEducatorWebsite || !currentEducatorWebsite.course) {
              subscriber.next(true);

              unsubscribe.next(true);
              unsubscribe.complete();
  
              return;
            }
        
            const isClassActive: boolean = !currentEducatorWebsite.course.isExpired;
  
            if (!isClassActive) {
              this.navigationService.toFirstPortfolio();
  
              this.classEndedModalService.open({
                website: currentEducatorWebsite,
              });
            }
  
            subscriber.next(isClassActive);

            unsubscribe.next(true);
            unsubscribe.complete();
          });
        });
      });
    });
  }
}
