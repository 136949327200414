import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

import {PlanModel} from '../../../../core/models/plan/plan.model';
import {PurchaseValidationModel} from '../../../../core/models/validation/purchase/purchase-validation.model';

@Injectable()
export class PurchaseValidationModalService {
  public readonly id = 'purchase-validation-modal';

  public isOpened = false;

  public constructor(private modalsService: ModalsService) {
  }

  public open(data: { plan: PlanModel, validation: PurchaseValidationModel }): void {
    this.modalsService.open(this.id, data);

    this.isOpened = true;
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
