import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';

import {Observable, BehaviorSubject, Subscriber, forkJoin, throwError, catchError, tap, Subscription} from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import {PdfThumbnailsService} from './pdf-thumbnails/pdf-thumbnails.service';
import {EventsService} from '../interaction/events/events.service';
import {MediaManagerService} from '../media-manager/media-manager.service';
import {DocumentsHttpService} from '../interaction/http/documents/documents-http.service';
import {S3HttpService} from '../interaction/http/s3/s3-http.service';
import {PermissionsService} from '../service-permissions/permissions/permissions.service';

import {DocumentDto} from '../../models/document/document.dto';
import {DocumentModel} from '../../models/document/document.model';
import {DocumentUploadUrlsDto} from '../../models/document/document-upload-urls.dto';
import {DocumentUploadUrlsModel} from '../../models/document/document-upload-urls.model';

import {SECTIONS_KEYS} from '../media-manager/constants';

@Injectable()
export class DocumentManagerService {
  private model = 'documents';

  public selectedCategory: { text: string, textPlural: string };

  public documentsSubject: BehaviorSubject<DocumentModel[]> = new BehaviorSubject<DocumentModel[]>(null);
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public documents(): DocumentModel[] {
    return this.documentsSubject.value;
  }

  constructor(
    private http: HttpClient,
    private httpService: DocumentsHttpService,
    private s3HttpService: S3HttpService,
    private thumbnailsService: PdfThumbnailsService,
    private eventsService: EventsService,
    private permissionsService: PermissionsService,
    private mediaManagerService: MediaManagerService,
  ) {
    this.eventsService.addFrameListener('openDocumentManager', this.open.bind(this));

    this.permissionsService.beforeLeaveSubject.subscribe(() => {
      this.close();
    });
  }

  public open(): void {
    this.mediaManagerService.isSectionsDisabledSubject.next(true);

    this.mediaManagerService.selectSection(SECTIONS_KEYS.DOCUMENTS);

    this.mediaManagerService.openModal();
  }

  public close(): void {
    this.mediaManagerService.closeModal();
  }

  public loadDocuments(): Subscription {
    this.isLoadingSubject.next(true);

    return this.http.get<DocumentDto[]>(`api/${this.model}`).pipe(
      catchError(e => {
        console.error(e);

        this.isLoadingSubject.next(false);

        return throwError(() => e);
      }),
    ).subscribe((documents: DocumentDto[]) => {
      this.setDocuments(documents);
    });
  }

  public upload(file: File, document: DocumentModel): Observable<DocumentModel> {
    const extension = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${extension}`;
    const thumbName = `${uuidv4()}`;

    return new Observable((observer: Subscriber<DocumentModel>) => {
      this.thumbnailsService.getThumb(file).pipe(
        catchError(e => {
          return throwError(() => e);
        })
      ).subscribe((thumb: Blob) => {
        this.getUploadUrls(fileName, thumbName, file, thumb, document).pipe(
          catchError(e => {
            return throwError(() => e);
          })
        ).subscribe((urls: DocumentUploadUrlsModel) => {
          document.id = urls && urls.document ? urls.document.id : null;

          if (document.isDeleting) {
            return observer.next(null);
          }

          document.percent = '0%';

          this.uploadHandler(urls, file, thumb, document).pipe(
            catchError(e => {
              return throwError(() => e);
            })
          ).subscribe(() => {
            if (document.isDeleting) {
              return this.httpService.deleteMany([document.id]).subscribe(() => observer.next(null));
            }

            this.httpService.finishUploading(document, fileName).pipe(
              catchError(e => {
                return throwError(() => e);
              })
            ).subscribe((res: DocumentModel) => {
              observer.next(res);
              observer.complete();
            });
          });
        });
      });
    });
  }

  private getUploadUrls(fileName: string, thumbName: string, file: File, thumb: Blob, document: DocumentModel): Observable<DocumentUploadUrlsModel> {
    return this.s3HttpService.getDocumentUploadUrl(file.name, fileName, thumbName, file.type, thumb.type, document.fileSize);
  }

  private uploadHandler(urls: DocumentUploadUrlsModel, file: File, thumb: Blob, document: DocumentModel): Observable<any> {
    return forkJoin([
      this.uploadDocumentHandler(urls.documentUrl, file, document),
      this.uploadThumb(urls.thumbUrl, thumb),
    ]);
  }

  private uploadDocumentHandler(url: string, file: File, document: DocumentModel): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      this.uploadDocument(url, file).pipe(
        catchError(e => {
          return throwError(() => e);
        })
      ).subscribe((event: { type: number, loaded: number, total: number }) => {
        if (event.type === HttpEventType.UploadProgress) {
          document.percent = `${Math.round(100 / event.total * event.loaded)}%`;
        }

        if (event.type === HttpEventType.Response) {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  private uploadDocument(url: string, file: File): Observable<any> {
    const documentHeaders = new HttpHeaders().set('Content-Type', file.type);

    return this.http.put(url, file, { headers: documentHeaders, reportProgress: true, observe: 'events', responseType: 'text' });
  }

  private uploadThumb(url: string, thumb: Blob): Observable<any> {
    const thumbHeaders = new HttpHeaders().set('Content-Type', thumb.type);

    return this.http.put(url, thumb, { headers: thumbHeaders, responseType: 'text' });
  }

  public deleteDocuments(ids: number[]): Observable<any> {
    return this.http.request('delete', `api/${this.model}`, { body: ids, responseType: 'json' });
  }

  public cancelUploading(document: DocumentModel): void {
    document.isUploading = true;
    document.isDeleting = true;
  }

  private setDocuments(documents: DocumentDto[]) {
    this.documentsSubject.next(documents.map(document => DocumentDto.normalize(document)));
    this.isLoadingSubject.next(false);
  }

  public updateName(id: number, name: string): Observable<any> {
    return this.http.request('put', `api/${this.model}/${id}/name`, { body: { name }, responseType: 'text' });
  }
}
