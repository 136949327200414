import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class IsSlideButtonVisibleModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value: boolean = false,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = this.element.matches(`[${ATTRIBUTES.IS_SLIDE_BUTTON_VISIBLE}="true"]`);
  }

  public onChange(value: boolean): void {
    this.value = value;

    this.element.setAttribute(ATTRIBUTES.IS_SLIDE_BUTTON_VISIBLE, `${value}`);

    const children: HTMLElement[] = <HTMLElement[]>Array.from(this.element.querySelectorAll(`[${ATTRIBUTES.IS_SLIDE_BUTTON_VISIBLE}]`));

    children.forEach((child: HTMLElement) => {
      child.setAttribute(ATTRIBUTES.IS_SLIDE_BUTTON_VISIBLE, `${value}`);
    });
  }
}
