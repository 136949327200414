import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Subscription, Observable} from 'rxjs';

import {AddOnModel} from '../../../../models/add-ons/add-on.model';
import {AddOnDto} from '../../../../models/add-ons/add-on.dto';

@Injectable()
export class AddOnsHttpService {
  private model = 'add-ons';

  public addOnsSubject: BehaviorSubject<AddOnModel[]> = new BehaviorSubject<AddOnModel[]>(null);

  constructor(private httpClient: HttpClient) {
  }

  public fetch(): Subscription {
    return this.httpClient.get(`api/${this.model}`).subscribe((res: AddOnDto[]) => {
      this.addOnsSubject.next(res.map((item: AddOnDto) => AddOnModel.normalize(item)));
    });
  }

  public purchase(body: { cardId: string, coupon: string }): Observable<AddOnDto[]> {
    return this.httpClient.post<AddOnDto[]>(`api/${this.model}`, body);
  }
}
