import {Component} from '@angular/core';

import {CourseEndedModalService} from '../../../../services/modals/education/course-ended/course-ended-modal.service';

import {Button} from '../../../../../common/models/button/button.model';

@Component({
  selector: 'app-course-ended-modal',
  templateUrl: './course-ended-modal.component.html',
  styleUrls: ['./course-ended-modal.component.scss']
})
export class CourseEndedModalComponent {
  public isSameWebsiteActive: boolean;
  
  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.onCancel.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: CourseEndedModalService,
  ) {
  }

  public onOpen({ isSameWebsiteActive }: { isSameWebsiteActive: boolean }): void {
    this.isSameWebsiteActive = isSameWebsiteActive;
  }

  public onCancel(): void {
    this.service.close();
  }
}

