import {Injectable} from '@angular/core';

import {ModalsService} from "../shared/services/modals/modals.service";
import {EventsService} from '../core/services/interaction/events/events.service';

import {ModalHeader} from "../common/models/modal/header/header.model";
import {Button} from "../common/models/button/button.model";
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class MessageModalService {
  public modalId = 'service-message-modal';
  public header: ModalHeader;
  public buttons: Button[];
  public message: string;

  private errorHeader: ModalHeader = new ModalHeader('Error!', 'error-header');
  private errorButtons: Button[] = [
    new Button('OK', null, 'neutral', this.close.bind(this)),
  ];

  constructor(private modalsService: ModalsService,
              private eventsService: EventsService) {
    this.eventsService.addFrameListener('showError', (e: CustomEvent) => {
      this.showError(e.detail.message);
    });
  }

  public addMessage(message: string, header?: ModalHeader, buttons?: Button[]): void {
    this.message = this.resolveMessage(message);
    this.header = header;
    this.buttons = buttons;

    this.show();
  }

  private resolveMessage(e: any): string {
    try {
      if (typeof e === 'string') {
        return e;
      }

      if (e instanceof HttpErrorResponse && e.error) {
        return e.error.message;
      }

      return e;
    } catch (err) {
      console.error(err);

      return e;
    }
  }

  public showError(message: string): void {
    this.addMessage(message, this.errorHeader, this.errorButtons);
  }

  public show(): void {
    this.modalsService.open(this.modalId);
  }

  public close(): void {
    this.modalsService.close(this.modalId);
  }
}
