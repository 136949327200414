import {Injectable} from '@angular/core';

import {ModalsService} from '../../modals.service';

@Injectable()
export class CourseEndedModalService {
  public readonly id: string = 'course-ended-modal';

  public isOpened: boolean = false;

  private lastOpenedFor: number = null;

  constructor(private modalsService: ModalsService) {
  } 

  public open({ websiteId, isSameWebsiteActive }: { websiteId: number, isSameWebsiteActive: boolean }): void {
    if (this.lastOpenedFor === websiteId) {
      return;
    }

    this.isOpened = true;

    this.lastOpenedFor = websiteId;

    this.modalsService.open(this.id, { isSameWebsiteActive });
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
