import {DeployModel} from './deploy.model';

export class DeployDto {
  constructor(public Id?: number,
              public Version?: string,
              public CreatedAt?: string) {}

  public static normalize(res: DeployDto): DeployModel {
    if (!res) return null;

    return new DeployModel(
      res.Id,
      res.Version,
      res.CreatedAt,
    );
  }
}
