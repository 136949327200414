import {OutdatedBlocksTemplatesModel} from './templates/outdated-blocks-templates.model';
import {SelectedPageModel} from '../selected-page/selected-page.model';
import {OutdatedBlocksPageDataModel} from './templates/pages/outdated-blocks-page-data.model';
import {OutdatedBlocksPagesModel} from './templates/pages/outdated-blocks-pages.model';

import {ANY_PAGE_KEY, ANY_TEMPLATE_KEY} from './constants';

export class OutdatedBlocksDataModel {
  constructor(public blocks: { [key: number]: OutdatedBlocksTemplatesModel }) {}

  public setIsBlockOutdated(block, page: SelectedPageModel, value: boolean) {
    if (!block) return;

    const blocks = this.getBlocks(block.BlockTemplateId, page);

    if (!blocks) return;

    const idx = block.IsSingle ? this.getByBlockTemplateId(blocks, block.BlockTemplateId) : this.getByBlockId(blocks, block.BlockID);

    if (idx === -1) return;

    blocks[idx].isOutdated = value;
  }

  public addOutdatedBlock(fromBlockId: number, block, selectedPage: SelectedPageModel) {
    const sibling: OutdatedBlocksPageDataModel = this.getOutdatedBlock(fromBlockId, block.BlockTemplateId, selectedPage);

    if (!block || !sibling) return;

    const blockTemplateId = sibling.blockTemplateId;
    const templateId = sibling.templateId || ANY_TEMPLATE_KEY;
    const pageId = sibling.pageId || ANY_TEMPLATE_KEY;

    this.blocks[blockTemplateId].templates[templateId].pages[pageId].push({
      id: block.BlockID,
      createdAt: block.CreatedAt,
      version: block.Version,
      isOutdated: true,
      pageId,
      templateId,
      blockTemplateId,
    });
  }

  private getByBlockTemplateId(blocks: OutdatedBlocksPageDataModel[], blockTemplateId) {
    return blocks.findIndex(block => block.blockTemplateId === blockTemplateId);
  }

  private getByBlockId(blocks: OutdatedBlocksPageDataModel[], blockId) {
    return blocks.findIndex(block => block.id === blockId);
  }

  public getOutdatedBlock(blockId: number, blockTemplateId: number, selectedPage: SelectedPageModel) {
    const blocks = this.getBlocks(blockTemplateId, selectedPage);

    if (!blocks) return null;

    const idx = blocks.findIndex(block => block.id === blockId);

    if (idx === -1) return null;

    return blocks[idx].isOutdated ? blocks[idx] : null;
  }

  public isSomeOutdated(blocks: any[], selectedPage: SelectedPageModel) {
    if (!blocks || !blocks.length) return false;

    return selectedPage && blocks.some(block => this.isBlockTemplateOutdated(block.BlockTemplateId, selectedPage));
  }

  public getCurrentPageOutdatedBlocks(selectedPage: SelectedPageModel): OutdatedBlocksPageDataModel[] {
    if (!this.blocks) return [];

    const ids: number[] = Object.keys(this.blocks).map(id => +id);
    const items: OutdatedBlocksPageDataModel[][] = ids.map(id => this.getOutdatedBlocks(id, selectedPage));

    return items.reduce((res, curr) => {
      res.push(...curr);

      return res;
    }, []);
  }

  public getOutdatedBlocks(blockTemplateId: number, page: SelectedPageModel): OutdatedBlocksPageDataModel[] {
    const blocks = this.getBlocks(blockTemplateId, page);

    if (!blocks) {
      return [];
    }

    return blocks.some(block => block.isOutdated) ? blocks : [];
  }

  public isBlockTemplateOutdated(blockTemplateId: number, page: SelectedPageModel): boolean {
    const blocks = this.getBlocks(blockTemplateId, page);

    return blocks && blocks.some(block => block.isOutdated);
  }

  public getBlocks(blockTemplateId: number, page: SelectedPageModel): OutdatedBlocksPageDataModel[] {
    if (!page || !this.blocks[blockTemplateId]) {
      return [];
    }

    const { templates } = this.blocks[blockTemplateId];

    const exactTemplateExactPage = this.getPageData(templates, page.templateId, page.id);

    if (exactTemplateExactPage) {
      return exactTemplateExactPage;
    }

    const exactTemplateAnyPage = this.getPageData(templates, page.templateId, ANY_PAGE_KEY);

    if (exactTemplateAnyPage) {
      return exactTemplateAnyPage;
    }

    const anyTemplateExactPage = this.getPageData(templates, ANY_TEMPLATE_KEY, page.id);

    if (anyTemplateExactPage) {
      return anyTemplateExactPage;
    }

    return this.getPageData(templates, ANY_TEMPLATE_KEY, ANY_PAGE_KEY);
  }

  private getPageData(templates: { [key: number]: OutdatedBlocksPagesModel }, templateId: number|string, pageId: number|string): OutdatedBlocksPageDataModel[] | null {
    return templates[templateId] ? templates[templateId].pages[pageId] : null;
  }
}
