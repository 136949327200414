import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ImagesHttpService} from '../../interaction/http/images/images-http.service';
import {AuthService} from '../../../../auth/auth.service';

import {AccountModel} from '../../../models/accounts/account.model';

@Injectable()
export class ImagesBucketService {
  public imagesBucketSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private httpService: ImagesHttpService,
              private authService: AuthService) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      if (!account) return;

      this.fetchImagesBucket();
    });
  }

  private fetchImagesBucket(): Subscription {
    return this.httpService.fetchImagesBucket().subscribe((bucket: string) => {
      this.imagesBucketSubject.next(bucket);
    });
  }
}
