import {SetupOptionModel} from '../../option/setup-option.model';

export class AttributeModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public attribute: string,
    public value?: boolean,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = this.getAttributeValue();
  }

  public onChange(value: boolean): void {
    this.value = value;

    if (!this.isEnabled) return;

    if (!value) return this.element.removeAttribute(this.attribute);

    this.element.setAttribute(this.attribute, '');
  }

  private getAttributeValue(): boolean {
    return this.element.hasAttribute(this.attribute);
  }
}
