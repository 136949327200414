import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IFrameRoutingService} from '../../../../core/services/iframe/routing/iframe-routing.service';
import {BagService} from '../../../../bag.service';
import {ImagesCounterService} from '../../../../core/services/image-manager/counters/images-counter.service';
import {EducationStudentsImagesCounterService} from '../../../../core/services/education/image-manager/students-counters/education-students-images-counter.service';
import {EducationExhibitionsImagesCounterService} from '../../../../core/services/education/image-manager/exhibitions-counters/education-exhibitions-images-counter.service';
import {ImageManagerService} from '../image-manager.service';
import {ImageSpecificationsModalService} from '../../../../shared/services/modals/image-specifications/image-specifications-modal.service';
import {WebsiteTourService} from '../../../../core/services/website-tour/website-tour.service';
import {EducationImageManagerService} from '../../../../core/services/education/image-manager/education-image-manager.service';
import {AuthService} from '../../../../auth/auth.service';
import {StudentImageManagerService} from '../../../../core/services/education/students/image-manager/student-image-manager.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';
import {StudentImagesCounterService} from '../../../../core/services/education/students/image-manager/student-counters/student-images-counter.service';
import {StudentPortfoliosService} from '../../../../core/services/education/students/websites/portfolios/student-portfolios.service';

import {ImagesCounterModel} from '../../../../core/models/image-manager/counters/images-counter.model';
import {SelectedPageModel} from '../../../../core/models/selected-page/selected-page.model';
import {ImageModel} from '../../../../core/models/images/image.model';
import {EducationStudentPortfolioModel} from '../../../../core/models/education/portfolios/education-student-portfolio.model';
import {EducationExhibitionPortfolioModel} from '../../../../core/models/education/portfolios/education-exhibition-portfolio.model';
import {StudentImageReviewModel} from '../../../../core/models/images/review/student-image-review.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';
import {SubscriptionModel } from '../../../../core/models/payment/subscriptions/subscription.model';
import {PortfolioModel } from '../../../../core/models/portfolios/portfolio.model';
import {IPermissionData } from '../../../../core/models/permission/i-permission-data';

import {EducatorImageManagerTabs} from '../../../../core/services/education/image-manager/constants';
import {StudentImageManagerTabs } from '../../../../core/services/education/students/image-manager/constants';
import {KEYS} from '../../../../core/services/website-tour/constants';
import {PERMISSIONS } from '../../../../core/services/service-permissions/constants';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() images: ImageModel[];
  @Input() selected: ImageModel[];
  @Input() view: string;
  @Input() selectedImageReview: StudentImageReviewModel;
  @Input() isExhibitButtonDone: boolean;
  @Input() isExhibitButtonBlocked: boolean;
  @Input() isSelectedImageExhibited: boolean;

  @Output() handleSelect = new EventEmitter();
  @Output() handleEnlarge: EventEmitter<ImageModel> = new EventEmitter();
  @Output() handleReview: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleExhibit: EventEmitter<void> = new EventEmitter<void>();

  public account: AccountModel;

  public currentIndex: number = 0;

  public thumbSize: number = 65;

  public totalCount: number = 0;
  public studentTabTotalCount: number = 0;
  public studentsTabTotalCount: number = 0;
  public exhibitionsTabTotalCount: number = 0;

  public isEducator: boolean = false;
  public isStudent: boolean = false;

  public isStudentTab: boolean = false;
  public isStudentUserTab: boolean = false;

  public isStudentsTeacherTab: boolean = false;
  public isExhibitionsTeacherTab: boolean = false;
  public isUserTab: boolean = false;

  private activeTeacherTab: EducatorImageManagerTabs;
  private activeStudentTab: StudentImageManagerTabs;

  private counters: ImagesCounterModel;
  private selectedPage: SelectedPageModel;
  
  private studentsCounters: ImagesCounterModel;
  private selectedEducatorStudentPortfolio: EducationStudentPortfolioModel;
  
  private exhibitionCounters: ImagesCounterModel;
  private selectedExhibition: EducationExhibitionPortfolioModel;
  
  private studentCounters: ImagesCounterModel;
  private studentPortfolio: PortfolioModel;
  
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isSingleImageSelected(): boolean {
    if (!this.selected) return false;

    return this.selected.length === 1;
  }

  constructor(
    private iFrameRoutingService: IFrameRoutingService,
    private imageManagerService: ImageManagerService,
    private imagesCounterService: ImagesCounterService,
    private educationStudentsImagesCounterService: EducationStudentsImagesCounterService,
    private educationExhibitionsImagesCounterService: EducationExhibitionsImagesCounterService,
    private studentImagesCounterService: StudentImagesCounterService,
    private studentImageManagerService: StudentImageManagerService,
    private studentPortfoliosService: StudentPortfoliosService,
    private websiteTourService: WebsiteTourService,
    private educationImageManagerService: EducationImageManagerService,
    private imageSpecificationsModalService: ImageSpecificationsModalService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private permissionsService: PermissionsService,
    private authService: AuthService,
    public bag: BagService,
  ) {
  }

  public ngOnInit(): void {
    this.initPermissions();

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isEducator = subscription ? subscription.isEducator : false;
    });

    this.imageManagerService.viewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ key, forceIndex }: { key: string, forceIndex: number }) => {
      this.setView({ key, forceIndex });
    });

    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      this.activeTeacherTab = key;

      this.isStudentsTeacherTab = this.activeTeacherTab === 'students';
      this.isExhibitionsTeacherTab = this.activeTeacherTab === 'exhibitions';
      this.isUserTab = this.activeTeacherTab === 'user';
    });

    this.studentImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: StudentImageManagerTabs) => {
      this.activeStudentTab = key;

      this.isStudentTab = this.activeStudentTab === 'student';
      this.isStudentUserTab = this.activeStudentTab === 'user';
    });
    
    this.studentPortfoliosService.selectedPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolio: PortfolioModel) => {
      this.studentPortfolio = portfolio;

      this.initStudentTabTotalCount();
    });

    this.imagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      this.counters = counters;

      this.initTotalCount();
    });

    this.educationStudentsImagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      if (!this.isStudentsTeacherTab) return;
      
      this.studentsCounters = counters;

      this.initStudentsTabTotalCount();
    });

    this.educationExhibitionsImagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      if (!this.isExhibitionsTeacherTab) return;
      
      this.exhibitionCounters = counters;

      this.initExhibitionsTabTotalCount();
    });

    this.studentImagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      if (!this.isStudent || !this.isStudentTab) {
        return;
      }

      this.studentCounters = counters;

      this.initStudentTabTotalCount();
    })

    this.imageManagerService.onImageClickSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(idx => {
      this.currentIndex = idx;
    });

    this.imageManagerService.thumbSizeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((thumbSize: number) => {
      this.thumbSize = thumbSize;
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selected = [];

      this.selectedPage = selectedPage;

      if (!this.isUserTab) {
        return;
      }

      this.initTotalCount();
    });

    this.educationImageManagerService.selectedStudentPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPortfolio: EducationStudentPortfolioModel) => {
      if (!this.isStudentsTeacherTab) {
        return;
      }

      if (!selectedPortfolio && !this.selectedEducatorStudentPortfolio) {
        return;
      }

      if (selectedPortfolio && this.selectedEducatorStudentPortfolio && selectedPortfolio.id === this.selectedEducatorStudentPortfolio.id) {
        return;
      }

      this.selectedEducatorStudentPortfolio = selectedPortfolio;

      this.initStudentsTabTotalCount();
    });

    this.educationImageManagerService.selectedExhibitionPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedExhibition: EducationExhibitionPortfolioModel) => {
      if (!selectedExhibition && !this.selectedExhibition) return;
      if (selectedExhibition && this.selectedExhibition && selectedExhibition.id === this.selectedExhibition.id) return;

      this.selectedExhibition = selectedExhibition;

      this.initExhibitionsTabTotalCount();
    });
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.images || this.images.length === 0) {
      this.websiteTourService.removeVisibleItem(KEYS.IMAGE_MANAGER_BOTTOM_BUTTONS);
      this.websiteTourService.removeVisibleItem(KEYS.BOTTOM_BUTTONS_SIZE_SLIDER);
      this.websiteTourService.removeVisibleItem(KEYS.BOTTOM_BUTTONS_OUTPUT_SWITCH_BUTTON);

      return;
    }

    this.websiteTourService.addVisibleItem(KEYS.IMAGE_MANAGER_BOTTOM_BUTTONS);
    this.websiteTourService.addVisibleItem(KEYS.BOTTOM_BUTTONS_SIZE_SLIDER);
    this.websiteTourService.addVisibleItem(KEYS.BOTTOM_BUTTONS_OUTPUT_SWITCH_BUTTON);
  }

  private initTotalCount(): void {
    this.totalCount = this.getCount(this.counters, this.selectedPage ? this.selectedPage.id : void 0)
  }

  private initStudentTabTotalCount(): void {
    this.studentTabTotalCount = this.getCount(this.studentCounters, this.studentPortfolio ? this.studentPortfolio.id : void 0)
  }

  private initStudentsTabTotalCount(): void {
    this.studentsTabTotalCount = this.getCount(this.studentsCounters, this.selectedEducatorStudentPortfolio ? this.selectedEducatorStudentPortfolio.id : void 0)
  }

  private initExhibitionsTabTotalCount(): void {
    this.exhibitionsTabTotalCount = this.getCount(this.exhibitionCounters, this.selectedExhibition ? this.selectedExhibition.id : void 0)
  }

  private getCount(counters: ImagesCounterModel, pageId: number): number {
    if (!counters) {
      return 0;
    }

    return counters.total[pageId] || 0;
  }

  private setView({ key, forceIndex }: { key: string, forceIndex: number }): void {
    this.view = key;

    if (key === 'full-view') {
      return;
    }

    this.handleEnlarge.emit(key === 'large' ? this.images[Number.isInteger(forceIndex) ? forceIndex : this.currentIndex] : null);

    if (this.view === 'large' && !this.selected[0]) {
      $('#dropzone .dz-preview').removeClass('selected');
      $('#dropzone .dz-preview:first-child').addClass('selected');
    }
  }

  public onViewChange(key: string): void {
    const forceIndex: number = key === 'large' ? this.images.findIndex((i: ImageModel) => i === this.selected[0]) : void 0;

    this.imageManagerService.viewSubject.next({ key, forceIndex });
  }

  public selectImage(index: number): void {
    index = this.currentIndex + index;

    if (index < 0) {
      index = this.images.length - 1; // select the last one
    } else if (index >= this.images.length) {
      index = 0; // select the fist one
    }

    $('#dropzone .dz-preview').removeClass('selected');
    $('#dropzone .dz-preview:nth-child(' + (index + 1) + ')').addClass('selected');

    this.handleEnlarge.emit(this.images[index]);
  }

  selectAll() {
    this.handleSelect.next(this.images);

    $('#dropzone .dz-preview').addClass('selected');
  }

  deselectAll() {
    this.handleSelect.next([]);

    $('#dropzone .dz-preview').removeClass('selected');
  }

  public onThumbSizeChange(value: string): void {
    this.imageManagerService.thumbSizeSubject.next(Number.parseInt(value));
  }

  public viewImageSpecs(): void {
    this.imageSpecificationsModalService.open();
  }

  public review(): void {
    this.handleReview.emit();
  }

  public exhibit(): void {
    if (this.isExhibitButtonBlocked) return;

    this.handleExhibit.emit();
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleItem(KEYS.IMAGE_MANAGER_BOTTOM_BUTTONS);
    this.websiteTourService.removeVisibleItem(KEYS.BOTTOM_BUTTONS_SIZE_SLIDER);
    this.websiteTourService.removeVisibleItem(KEYS.BOTTOM_BUTTONS_OUTPUT_SWITCH_BUTTON);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
