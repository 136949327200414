// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .website-subscription-select-modal > .modal > .modal-header {
  width: 600px !important;
  max-width: 600px !important;
}
:host ::ng-deep .website-subscription-select-modal > .modal > .modal-body {
  width: 600px !important;
  max-width: 600px !important;
  max-height: 70vh !important;
  padding: 20px 0 20px 20px;
  font-size: 16px !important;
  line-height: normal !important;
}
:host ::ng-deep .website-subscription-select-modal > .modal > .modal-body .buttons-wrapper {
  display: block !important;
  margin: 0px 20px 15px 20px !important;
  text-align: center;
}
:host ::ng-deep .website-subscription-select-modal > .modal > .modal-body .buttons-wrapper .vs-button {
  display: inline-block;
  width: 140px;
}
:host ::ng-deep .website-subscription-select-modal > .modal > .modal-body .buttons-wrapper .vs-button:first-child {
  margin-right: 2px !important;
}
:host ::ng-deep .website-subscription-select-modal > .modal > .modal-body .buttons-wrapper .vs-button:last-child {
  margin-left: 2px !important;
}

.website-subscription-select-modal-body .content-wrapper {
  padding: 20px;
  font-size: 0.9em;
}
.website-subscription-select-modal-body .content-wrapper .label .website-title {
  font-weight: bold;
}
.website-subscription-select-modal-body .content-wrapper .value-wrapper {
  margin-top: 5px;
  line-height: 26px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/website-subscription-select/website-subscription-select-modal.component.scss"],"names":[],"mappings":"AAIM;EACE,uBAAA;EACA,2BAAA;AAHR;AAMM;EACE,uBAAA;EACA,2BAAA;EACA,2BAAA;EACA,yBAAA;EACA,0BAAA;EACA,8BAAA;AAJR;AAMQ;EACE,yBAAA;EACA,qCAAA;EACA,kBAAA;AAJV;AAMU;EACE,qBAAA;EACA,YAAA;AAJZ;AAMY;EACE,4BAAA;AAJd;AAOY;EACE,2BAAA;AALd;;AAeE;EACE,aAAA;EACA,gBAAA;AAZJ;AAeM;EACE,iBAAA;AAbR;AAiBI;EACE,eAAA;EACA,iBAAA;AAfN","sourcesContent":["\n:host ::ng-deep {\n  .website-subscription-select-modal {\n    & > .modal {\n      & > .modal-header {\n        width: 600px !important;\n        max-width: 600px !important;;\n      }\n\n      & > .modal-body {\n        width: 600px !important;\n        max-width: 600px !important;\n        max-height: 70vh !important;\n        padding: 20px 0 20px 20px;\n        font-size: 16px !important;\n        line-height: normal !important;\n\n        .buttons-wrapper {\n          display: block !important;\n          margin: 0px 20px 15px 20px !important;\n          text-align: center;\n\n          .vs-button {\n            display: inline-block;\n            width: 140px;\n\n            &:first-child {\n              margin-right: 2px !important;\n            }\n\n            &:last-child {\n              margin-left: 2px !important;\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\n.website-subscription-select-modal-body {\n  .content-wrapper {\n    padding: 20px;\n    font-size: 0.9em;\n\n    .label {\n      .website-title {\n        font-weight: bold;\n      }\n    }\n\n    .value-wrapper {\n      margin-top: 5px;\n      line-height: 26px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
