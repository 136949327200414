import {TemplatePreviewTypeModel} from './template-preview-type.model';

export class TemplatePreviewTypeDto {
  constructor(public Id?: number,
              public Title?: string) {}

  public static normalize(res: TemplatePreviewTypeDto): TemplatePreviewTypeModel {
    if (!res) return null;

    return new TemplatePreviewTypeModel(
      res.Id,
      res.Title,
    );
  }
}
