export const ATTRIBUTES = {
  CURRENT_LAYER: 'data-current-layer',
};

export const VALUES = [
  {
    key: 'INTRO_OVERLAY',
    label: 'Intro Overlay',
  },
  {
    key: 'PORTFOLIO',
    label: 'Portfolio',
  },
];

export const DEFAULT_KEY = VALUES[0].key;
