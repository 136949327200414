import {Component, Input, OnInit, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {MediaManagerService} from '../../../core/services/media-manager/media-manager.service';

import {SectionModel} from '../../../core/models/media/section/section.model';

import {SECTIONS_KEYS} from '../../../core/services/media-manager/constants';

@Component({
  selector: 'app-media-manager',
  templateUrl: './media-manager.component.html',
  styleUrls: ['./media-manager.component.scss'],
})
export class MediaManagerComponent implements OnInit, OnDestroy {
  @Input() isModal: boolean = false;

  public activeSection: SectionModel = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get sectionsKeys() {
    return SECTIONS_KEYS;
  }

  constructor(private service: MediaManagerService) {
  }

  public ngOnInit(): void {
    this.service.activeSectionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((section: SectionModel) => {
      this.activeSection = section;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
