import * as moment from 'moment';

import {PaymentDto} from './payment.dto';
import {DiscountModel} from '../discount/discount.model';

import {AMOUNT_FORMATTERS, STATUSES} from './constants';
import {TIME_FORMATTER} from '../constants';
import {DISCOUNT_TYPES_MAPPING} from '../invoice/constants';

export class PaymentModel {
  public discountText: string = '';

  public isRepeatable: boolean;
  public isNew: boolean;

  constructor(
    public id?: string,
    public amount?: string,
    public amountDue?: string,
    public discount?: DiscountModel,
    public description?: string,
    public invoiceStatus?: string,
    public status?: string,
    public statusText?: string,
    public created?: string,
    public receiptUrl?: string,
    public invoiceUrl?: string,
    public fromPlan?: string,
    public toPlan?: string,
    public isSubscriptionPurchase?: boolean,
    public isPaid?: boolean,
  ) {
    this.isRepeatable = !this.isPaid && this.invoiceStatus === 'open' && this.status === 'requires_payment_method';
    this.isNew = this.invoiceStatus === 'open' && !this.status;

    if (!this.discount || !this.discount.coupon || !this.discount.coupon.metadata) {
      return;
    }

    if (!DISCOUNT_TYPES_MAPPING[this.discount.coupon.metadata.discountType]) {
      return;
    }

    this.discountText = DISCOUNT_TYPES_MAPPING[this.discount.coupon.metadata.discountType](this.discount.coupon);
  }

  public static normalize(res: PaymentDto): PaymentModel {
    if (!res) return null;

    const createdAt = moment.unix(res.created);

    return new PaymentModel(
      res.id,
      AMOUNT_FORMATTERS[res.currency] ? AMOUNT_FORMATTERS[res.currency](res.amount) : ``,
      AMOUNT_FORMATTERS[res.currency] ? AMOUNT_FORMATTERS[res.currency](res.amountDue) : ``,
      DiscountModel.normalize(res.discount),
      res.description,
      res.invoiceStatus,
      res.status,
      STATUSES[res.status] || '',
      createdAt.format(TIME_FORMATTER),
      res.receiptUrl,
      res.invoiceUrl,
      res.fromPlan,
      res.toPlan,
      res.isSubscriptionPurchase,
      res.isPaid,
    );
  }
}
