import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-vsx-text-input',
  templateUrl: 'vsx-text-input.component.html',
  styleUrls: ['./vsx-text-input.component.scss']
})
export class VsxTextInputComponent {
  @Input() value: string = '';
  @Input() placeholder: string = ' ';
  @Input() maxlength: number = 0;
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() autofocus: boolean = false;
  @Input() showError: boolean = null;
  @Input() tabIndex: number = -1;
  @Input() colorSchema: string = 'blue';

  @Output() valueChange = new EventEmitter();
  @Output() inputHandler = new EventEmitter();
  @Output() keydownHandler = new EventEmitter();

  constructor() {}
}
