import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';

import {NodeModel} from '../../../../core/models/nodes/node.model';
import { TreeItemModel } from '../../../../core/models/nodes/tree-item.model';

@Injectable()
export class CustomHomePageSetupModalService {
  public readonly id = 'custom-home-page-setup-modal';

  public homePage: NodeModel;

  public upcomingHomePage: TreeItemModel;

  public isOpened: boolean = false;
  public isOriginalHomePageSetAsHome: boolean = false;

  public constructor(private modalsService: ModalsService,
                     private nodesService: NodesService) {
    this.nodesService.nodesSubject.subscribe((nodes: NodeModel[]) => {
      this.homePage = nodes ? nodes.find((node: NodeModel) => node.isHomePage) : null;

      this.isOriginalHomePageSetAsHome = this.homePage ? this.homePage.type === 'H' : false;
    });
  }

  public open(node: TreeItemModel): void {
    this.modalsService.open(this.id);

    this.upcomingHomePage = node;

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
