import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BlocksService} from '../blocks.service';
import {BlocksManagerService} from '../../../../../core/services/blocks/blocks.service';
import {IFrameRoutingService} from '../../../../../core/services/iframe/routing/iframe-routing.service';
import {SelectedBlocksService} from '../../../../../services/selected-blocks.service';
import {IFrameService} from '../../../../../core/services/iframe/iframe.service';
import {WebsiteTourService} from '../../../../../core/services/website-tour/website-tour.service';
import {UtilsService} from '../../../../../core/services/utils/utils.service';

import {OutdatedBlocksDataModel} from '../../../../../core/models/outdated-blocks-data/outdated-blocks-data.model';
import {SelectedPageModel} from '../../../../../core/models/selected-page/selected-page.model';

import {AppAnimations} from '../../../../../app-animations';

import {KEYS} from '../../../../../core/services/website-tour/constants';
import {TOUR_KEY} from './constants';

@Component({
  selector: 'app-block-item',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class BlockComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('thumbnail') thumbnail;
  @ViewChild('gifThumbnail') gifThumbnail;

  @Input() dragTemplate;
  @Input() block;
  @Input() index: number;
  @Input() isDefaultPortfolio: boolean;

  @Output() imageLoadedHandler = new EventEmitter();
  @Output() dragStartHandler = new EventEmitter();
  @Output() dropHandler = new EventEmitter();

  public outdatedTooltipText = 'The design or functionality of this block has changed. To see these changes on your website you will need to replace your current block.';

  public thumbSrc: string = null;

  public tourGroupKey: string = null;
  public tourKey: string = null;
  public tourLocation: string = null;

  public isFirst: boolean = false;
  public isOutdated: boolean;
  public isContentLoaded: boolean = false;
  public isDefaultPortfolioLabelHovered: boolean = false;

  private outerCategories = [
    'Portfolio',
    'Portfolio Zoom',
    'Exhibition Portfolios',
  ];

  private outdatedBlocksData: OutdatedBlocksDataModel;

  private selectedPage: SelectedPageModel;
  private selectedBlockTemplateIds = [];

  private ngUnsubscribe = new Subject<void>();

  public get isSelected(): boolean {
    return !this.isDefaultPortfolio && this.selectedBlockTemplateIds.includes(this.block.BlockID);
  }

  public get isDraggable(): boolean {
    return this.isContentLoaded && this.getBlockDropTarget() === 'droparea-in-portfolio';
  }

  constructor(
    public blocksService: BlocksService,
    private chRef: ChangeDetectorRef,
    private blocksManagerService: BlocksManagerService,
    private selectedBlocksService: SelectedBlocksService,
    private websiteTourService: WebsiteTourService,
    private utilsService: UtilsService,
    private iFrameService: IFrameService,
    private iFrameRoutingService: IFrameRoutingService,
  ) {
    this.handleTours = this.utilsService.debounce(this.handleTours.bind(this), 500);
  }

  public ngOnInit(): void {
    this.isFirst = this.index === 0;

    if (this.isFirst) {
      this.handleTours();
    }

    this.blocksManagerService.outdatedBlocksSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: OutdatedBlocksDataModel) => {
      this.outdatedBlocksData = data;

      this.initIsOutdated();
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;

      this.thumbSrc = this.getThumbSrc();

      this.initIsOutdated();
    });

    this.selectedBlocksService.selectedBlocksSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedBlocks => {
      this.selectedBlockTemplateIds = selectedBlocks.map(selectedBlock => selectedBlock.blockTemplateId);
    });

    this.iFrameService.onContentLoad.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.isContentLoaded = value;
    });

    this.initIsOutdated();
  }

  private handleTours(): void {
    this.tourGroupKey = 'SIDEBAR_BLOCKS_THUMBNAIL';
    this.tourKey = 'SIDEBAR_BLOCKS_THUMBNAIL';
    this.tourLocation = 'SIDEBAR_BLOCKS_CATEGORY';

    this.websiteTourService.addVisibleLocation(TOUR_KEY);
    this.websiteTourService.addVisibleItem(KEYS.SIDEBAR_BLOCKS_THUMBNAIL);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.block && changes.block.currentValue) {
      this.thumbSrc = this.getThumbSrc();
    }
  }

  public getBlockDropTarget(): string {
    if (!this.block || this.block.Block_Category === 'Menus' || !this.blocksService.isExhibitionPortfolio && this.blocksService.openedPageType !== 'P') return null;

    return this.outerCategories.includes(this.block.Block_Category) || !this.blocksService.isSlidePortfolio ? '' : 'droparea-in-portfolio';
  }

  public onMouseEnter(): void {
    if (!this.gifThumbnail || !this.block.GifThumbnail) return;

    this.gifThumbnail.nativeElement.src = this.block.GifThumbnail;
  }

  public onMouseLeave(): void {
    this.isDefaultPortfolioLabelHovered = false;
  }

  public onDefaultPortfolioLabelMouseEnter(): void {
    this.isDefaultPortfolioLabelHovered = true;
  }

  private getThumbSrc(): string {
    if (!this.block || !this.block.Thumb || !this.selectedPage) return null;

    const { ThumbsTemplatesPaths: paths } = this.block;

    return paths && paths[this.selectedPage.templateId] ? paths[this.selectedPage.templateId] : this.block.Thumb;
  }

  private initIsOutdated(): void {
    if (!this.block || !this.selectedPage || !this.outdatedBlocksData) {
      this.isOutdated = false;

      this.chRef.detectChanges();

      return;
    }

    this.isOutdated = this.outdatedBlocksData.isBlockTemplateOutdated(this.block.BlockTemplateId, this.selectedPage);

    this.chRef.detectChanges();
  }

  public ngOnDestroy(): void {
    if (this.isFirst) {
      this.websiteTourService.removeVisibleLocation(TOUR_KEY);
      this.websiteTourService.removeVisibleItem(KEYS.SIDEBAR_BLOCKS_THUMBNAIL);
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
