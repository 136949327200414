import {SelectOption} from '../../../../models/select/option/option.model';

export const LABELS = {
  UNDERLINE: 'Underline',
  BOLD: 'Bold',
  SHADOW: 'Shadow',
};

export const CLASSES = {
  UNDERLINE: 'menu-hover-underline',
  BOLD: 'menu-hover-bold',
  SHADOW: 'menu-hover-shadow',
};

export const DEFAULT_OPTIONS = () => [
  new SelectOption(LABELS.UNDERLINE, CLASSES.UNDERLINE, true),
  new SelectOption(LABELS.BOLD, CLASSES.BOLD),
  new SelectOption(LABELS.SHADOW, CLASSES.SHADOW),
];
