
import {Component, Input, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';

import {throwError as observableThrowError, Observable, Subject, throwError} from 'rxjs';
import {takeUntil, catchError, finalize} from 'rxjs/operators';

import {AppAnimations} from '../../../../app-animations';

import {DocumentManagerService} from '../../../../core/services/document-manager/document-manager.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {MessageModalService} from '../../../../services/message-modal.service';
import {ObjectRemoveErrorModalService} from '../../../services/modals/object-remove-error/object-remove-error-modal.service';
import {BrowserService} from '../../../../core/services/browser/browser.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';

import {DocumentModel} from '../../../../core/models/document/document.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';
import {ObjectRemoveErrorDto} from '../../../../core/models/errors/objects/remove/object-remove-error.dto';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';
import {ERRORS_KEYS} from '../../../../shared/services/errors/constants';
import {KEYS} from '../../modals/object-remove-error/constants';

@Component({
  selector: 'app-document-manager',
  templateUrl: './document-manager.component.html',
  styleUrls: ['./document-manager.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class DocumentManagerComponent implements OnInit, OnDestroy {
  @Input() isModal = false;

  public permissions: IPermissionData[] = [
    {
      type: 'permission',
      value: PERMISSIONS.MEDIA_MANAGER_PDF_TAB_ACCESSIBILITY,
    },
  ];

  public isNewDragging: boolean = false;
  public isEnlarged: boolean = false;
  public isLoading: boolean = true;

  public documents: DocumentModel[] = null;
  public selectedDocuments: DocumentModel[] = [];
  public firstClickedDocument: DocumentModel;

  public documentUri: SafeResourceUrl = null;

  public categories = [
    {
      text: 'PDF',
      textPlural: 'PDFs',
      allowedMimeTypes: ['application/pdf'],
    },
  ];

  public views = {
    list: {
      key: 'list',
      icon: 'fa-th',
    },
    enlarged: {
      key: 'enlarged',
      icon: 'fa-stop',
    },
  };

  public currentView = this.views.list.key;

  public editableDocumentId: number = null;

  private keyDownEvent = this.onKeyDown.bind(this);

  private errorsMapping = {
    [ERRORS_KEYS.OBJECTS_USED]: this.onObjectsUsed.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isEmpty(): boolean {
    const { documents } = this;
    return documents && documents.length === 0;
  }

  public get selectedCategory() {
    return this.service.selectedCategory;
  }
  public set selectedCategory(value) {
    this.service.selectedCategory = value;
  }

  public get nOfDocuments() {
    const { documents } = this;
    return documents ? documents.length : 0;
  }

  public get nOfSelectedDocuments() {
    const { selectedDocuments } = this;
    return selectedDocuments ? selectedDocuments.length : 0;
  }

  constructor(public browserService: BrowserService,
              private service: DocumentManagerService,
              private messageModalService: MessageModalService,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private buttonsService: ButtonsService,
              private objectRemoveErrorModalService: ObjectRemoveErrorModalService,
              private utilsService: UtilsService,
              private cdr: ChangeDetectorRef,
              private sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    this.initCategory();

    this.service.isLoadingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;

      this.cdr.detectChanges();
    });

    this.service.documentsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((documents: DocumentModel[]) => {
      this.documents = documents;

      this.cdr.detectChanges();
    });
  }

  private initCategory() {
    const pdfCategory = this.categories.find(category => category.text === 'PDF');
    this.selectCategory(pdfCategory);
  }

  public selectCategory(category) {
    this.selectedCategory = category;
    
    this.service.loadDocuments();
  }

  public onDocumentClick(e: MouseEvent, document: DocumentModel) {
    const { firstClickedDocument, documents } = this;
    const isNewSelected = !e.shiftKey || !firstClickedDocument || firstClickedDocument === document;

    documents.forEach(document => {
      document.isSelected = false;

      if (isNewSelected) document.isFirstClicked = false;
    });

    if (isNewSelected) return this.selectOneItem(document);

    return this.selectManyItems(documents, document);
  }

  private selectOneItem(document: DocumentModel) {
    document.isFirstClicked = true;
    document.isSelected = !document.isSelected;
    this.firstClickedDocument = document;

    this.initSelectedDocuments();
  }

  private selectManyItems(documents: DocumentModel[], document: DocumentModel) {
    const selectedIdx = documents.findIndex(doc => doc === this.firstClickedDocument);
    const clickedDocumentIdx = documents.findIndex(doc => doc === document);

    const startIdx = selectedIdx < clickedDocumentIdx ? selectedIdx : clickedDocumentIdx;
    const endIdx = selectedIdx > clickedDocumentIdx ? selectedIdx : clickedDocumentIdx;

    for (let i = startIdx; i <= endIdx; i++) documents[i].isSelected = true;

    this.initSelectedDocuments();
  }

  public onDragEnter() {
    this.isNewDragging = true;
  }

  public onDragLeave() {
    this.isNewDragging = false;
  }

  public onDrop(files: File[]) {
    this.isNewDragging = false;

    return files.forEach(file => {
      const document = this.addDocumentMock(file);
      this.uploadFile(file, document).subscribe(res => this.initDocument(document, res));
    });
  }

  public onUpload(ev) {
    const files = Array.from(<File[]>ev.target.files);

    files.forEach(file => {
      const document = this.addDocumentMock(file);

      this.uploadFile(file, document).pipe(
        catchError(e => {
          console.error(e);
          
          return throwError(() => e);
        }),
        finalize(() => {
          ev.target.value = null;
        }),
      ).subscribe((res: DocumentModel) => {
        this.initDocument(document, res);
      });
    });
  }

  private uploadFile(file: File, document: DocumentModel) {
    return this.service.upload(file, document).pipe(catchError(e => {
      this.documents = this.documents.filter((d: DocumentModel) => d !== document);

      this.showUploadingError(e);

      return observableThrowError(() => e);
    }));
  }

  private showUploadingError(e: HttpErrorResponse): void {
    try {
      const header: ModalHeader = new ModalHeader('Error', 'warning-header');
      const buttons: Button[] = [new Button('OK', null, 'neutral ok-button', this.onModalClose.bind(this))];
      const message: string = e.error ? e.error.message : 'Server error';

      this.messageModalService.addMessage(message, header, buttons);
    } catch (e) {
      console.error(e);
    }
  }

  private onModalClose(): void {
    this.messageModalService.close();
  }

  private initDocument(document: DocumentModel, res: DocumentModel) {
    document.id = res.id;
    document.userId = res.userId;
    document.fileName = res.fileName;
    document.originalFileName = res.originalFileName;
    document.link = res.link;
    document.thumbLink = res.thumbLink;
    document.isUploading = false;
  }

  private addDocumentMock(file: File): DocumentModel {
    const document = new DocumentModel(
      void 0,
      void 0,
      void 0,
      void 0,
      file.name,
      '',
      '',
      `${file.size}`,
      false,
      false,
      true,
    );

    this.documents.push(document);

    return document;
  }

  public selectAll() {
    this.documents.forEach(document => document.isSelected = true);
    this.initSelectedDocuments();
  }

  public deselectAll() {
    this.documents.forEach(document => document.isSelected = false);
    this.initSelectedDocuments();
  }

  private initSelectedDocuments() {
    this.selectedDocuments = this.documents.filter(document => document.isSelected);
  }

  // async ok
  public async onDelete() {
    const { selectedDocuments } = this;
    const ids = selectedDocuments.map(document => document.id);

    if (ids.length === 0) return;

    const isCurrentPageGood: boolean = await this.isCurrentPageGoodBeforeDelete({
      key: KEYS.DOCUMENT,
      objects: selectedDocuments,
    });

    if (!isCurrentPageGood) {
      const err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } } = {
        key: 'CURRENT_PAGE_ERROR',
        message: null,
        data: {
          key: KEYS.DOCUMENT,
          usedObjectsData: null,
        },
      };
      
      this.objectRemoveErrorModalService.open(err);

      return;
    }

    selectedDocuments.forEach(document => {
      document.isUploading = true;
    });

    const onSuccess = () => {
      this.documents = this.documents.filter(document => !selectedDocuments.includes(document));

      this.initSelectedDocuments();
    };

    this.service.deleteDocuments(ids).pipe(
      catchError(e => {
        console.dir(e);
  
        if (this.errorsMapping[e.error.key]) {
          this.errorsMapping[e.error.key](e.error);
        }
  
        selectedDocuments.forEach(document => {
          document.isUploading = false;
        });
        
        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  // async ok
  private async isCurrentPageGoodBeforeDelete(data: { key, objects: DocumentModel[] }): Promise<boolean> {
    if (!this.isModal) return true;

    return new Promise<boolean>((resolve, reject) => {
      this.eventsService.addFrameListener('objectsDataByObjectsId', (e: CustomEvent) => {
        try {
          const { objects } = e.detail;

          resolve(objects.length === 0);
        } catch (e) {
          resolve(false);
        }
      }, { once: true });

      this.eventsService.dispatchRequestObjectsDataByObjectsId(data, this.iFrameService.sandboxWindow);
    });
  }

  public onSelect(document: DocumentModel) {
    const data = {
      link: document.link,
      thumbLink: document.thumbLink,
    };

    this.eventsService.dispatchDocumentSelect(data, this.iFrameService.sandboxWindow);

    this.onClose();

    this.buttonsService.enableSaveButton();
  }

  public onLinkCopy(document: DocumentModel): Promise<void> {
    return this.utilsService.copyToClipboard(document.link);
  }

  public onCancelUpload(document: DocumentModel): void {
    if (!document.isUploading) return;

    this.documents = this.documents.filter((d: DocumentModel) => d !== document);

    this.service.cancelUploading(document);
  }

  public onClose() {
    this.service.close();
    this.documents = [];
    this.selectedDocuments = [];
    this.firstClickedDocument = null;
  }

  public onViewChange(key) {
    if (key === this.views.list.key) {
      this.currentView = key;

      return;
    }

    if (!this.firstClickedDocument) return;

    this.currentView = key;

    this.cdr.detectChanges();

    this.enlarge(this.firstClickedDocument);
  }

  public enlarge(document: DocumentModel) {
    this.isEnlarged = true;

    this.documentUri = this.sanitizer.bypassSecurityTrustResourceUrl(document.link);

    this.addEnlargementHandlers();

    this.cdr.detectChanges();
  }

  private addEnlargementHandlers(): void {
    window.addEventListener('keydown', this.keyDownEvent);
  }

  private onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode !== 27) return;

    this.closeEnlargement();

    this.removeEnlargementHandlers();
  }

  private removeEnlargementHandlers(): void {
    window.removeEventListener('keydown', this.keyDownEvent);
  }

  public onThumbLoaded(document: DocumentModel) {
    document.isThumbLoaded = true;
  }

  public closeEnlargement() {
    if (!this.isEnlarged) return;

    this.currentView = this.views.list.key;

    this.isEnlarged = false;

    this.documentUri = null;

    this.cdr.detectChanges();
  }

  public editName(event, document: DocumentModel): void {
    event.stopPropagation();

    const input = event.target.nextElementSibling;

    this.editableDocumentId = document.id;

    input.value = document.originalFileName;

    setTimeout(() => input.focus());
  }

  public updateName(event): void {
    const input = event.target;

    if (!input.value || !this.editableDocumentId) return;

    const editableDocument = this.documents.find(document => document.id === this.editableDocumentId);

    if (!editableDocument || input.value === editableDocument.originalFileName) {
      this.editableDocumentId = null;

      return;
    }

    this.service.updateName(this.editableDocumentId, input.value).pipe(
      catchError(e => {
        console.error(e);
        
        input.value = editableDocument.originalFileName;

        return throwError(() => e);
      }),
      finalize(() => {
        this.editableDocumentId = null;
      }),
    ).subscribe(() => {
      editableDocument.originalFileName = input.value;
    });
  }

  private onObjectsUsed(err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } }): void {
    this.objectRemoveErrorModalService.open(err);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.removeEnlargementHandlers();
  }
}
