import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

// Main App Component
import { AppComponent } from './app.component';

// Modules
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

// Application Components
import { ApplicationComponent } from './application/application.component';
import { NavigationComponent } from './navigation/navigation.component';
import { WebsitesDropdownComponent } from './navigation/websites-dropdown/websites-dropdown.component';
import { SidebarComponent } from './application/sidebar-short/sidebar/sidebar.component';
import { SidebarShortComponent } from './application/sidebar-short/sidebar-short.component';
import { AppTreeComponent } from './application/sidebar-short/sidebar/pages/tree/tree.component';

// import { DesignerComponent } from './application/sidebar-short/sidebar/designer/designer.component';
import { PagesSectionComponent } from './application/sidebar-short/sidebar/pages/pages.component';
import { EducatorImageManagerSidebarComponent } from './application/sidebar-short/sidebar/pages/educator-image-manager/educator-image-manager-sidebar.component';
import { EducatorExhibitionsSidebarComponent } from './application/sidebar-short/sidebar/pages/educator-exhibitions/educator-exhibitions-sidebar.component';
import { EducatorExhibitionSidebarItemComponent } from './application/sidebar-short/sidebar/pages/educator-exhibitions/educator-exhibition-sidebar-item/educator-exhibition-sidebar-item.component';
import { StudentImageManagerSidebarComponent } from './application/sidebar-short/sidebar/pages/student-image-manager/student-image-manager-sidebar.component';
import { StudentImageManagerSidebarItemComponent } from './application/sidebar-short/sidebar/pages/student-image-manager/student-image-manager-sidebar-item/student-image-manager-sidebar-item.component';
import { BlocksSectionComponent } from './application/sidebar-short/sidebar/blocks/blocks.component';
import { BlockComponent } from './application/sidebar-short/sidebar/blocks/item/block.component';
import { MainComponent } from './application/main/main.component';
import { StylesComponent } from './application/main/details/styles/styles.component';
import { LinkDropdownComponent } from './application/main/details/styles/link-dropdown/link-dropdown.component';
import { ImageManagerComponent } from './application/main/image-manager/image-manager.component';
import { BottomBarComponent } from './application/main/image-manager/bottom-bar/bottom-bar.component';
import { WebsiteDesignerComponent } from './application/main/website-designer/website-designer.component';
import { DetailsComponent } from './application/main/details/details.component';
import { PageEditorComponent } from './application/main/website-designer/page-editor/page-editor.component';
import { PageMenuComponent } from './application/main/website-designer/page-editor/page-menu/page-menu.component';
import { PageMenuItemComponent } from './application/main/website-designer/page-editor/page-menu/page-menu-item/page-menu-item.component';
import { SettingsSectionComponent } from './application/sidebar-short/sidebar/settings/settings.component';
import { WebsiteSettingsComponent } from './application/main/settings/website/website-settings.component';
import { ContactInfoSettingsComponent } from './application/main/settings/contact-info/contact-info-settings.component';
import { SettingsComponent } from './application/main/settings/settings.component';
import { WebsiteDetailsComponent } from './application/main/details/website-details/website-details.component';
import { MobileTemplatesEnlargementsComponent } from './application/main/details/website-details/mobile/templates/enlargements/mobile-templates-enlargements.component';
import { MobileTemplatesPortfoliosComponent } from './application/main/details/website-details/mobile/templates/portfolios/mobile-templates-portfolios.component';
import { PurchaseComponent } from './application/main/settings/account/purchase/purchase.component';
import { PurchaseAddOnsComponent } from './application/main/settings/account/purchase-add-ons/purchase-add-ons.component';
import { CreditCardsPageComponent } from './application/main/settings/account/credit-cards-page/credit-cards-page.component';
import { SubscriptionOrderStepCardsComponent } from './application/main/settings/account/purchase/steps/cards/subscription-order-step-cards.component';
import { RepeatPaymentComponent } from './application/main/settings/account/purchase/repeat-payment/repeat-payment.component';
import { RepeatPaymentConfirmationStepComponent } from './application/main/settings/account/purchase/repeat-payment/step-confirmation/step-confirmation.component';
import { PlanCardComponent } from './application/main/settings/account/purchase/plan-card/plan-card.component';
import { SupportHistoryComponent } from './application/main/settings/account/support-history/support-history.component';
import { WarningMessageComponent } from './common/warning-message/warning-message.component';
import { ControlButtonComponent } from './common/control-button/control-button.component';
import { MailingListComponent } from './application/main/settings/mailing-list/mailing-list.component';
import { SocialNetworksComponent } from './application/main/settings/social-networks/social-networks.component';
import { SecuritySettingsComponent } from './application/main/settings/security-settings/security-settings.component';
import { PayPalComponent } from './application/main/settings/paypal/paypal.component';
import { EducationSettingsComponent } from './application/main/settings/education/education.component';
import { CollapsibleSectionComponent } from './common/collapsible-section/collapsible-section.component';
import { TopBarComponent } from './application/main/image-manager/top-bar/top-bar.component';
import { CircleProgressBarComponent } from './common/circle-progress-bar/circle-progress-bar.component';
import { EditorLoaderComponent } from './common/editor-loader/editor-loader.component';

// Services
import { BagService } from './bag.service';
import { UserResolver } from './auth/auth-resolver.service';
import { AuthService } from './auth/auth.service';
import { PagesService } from './application/sidebar-short/sidebar/pages/pages.service';
import { ImageManagerService } from './application/main/image-manager/image-manager.service';
import { WebsiteDesignerService } from './application/main/website-designer/website-designer.service';
import { BlocksService } from './application/sidebar-short/sidebar/blocks/blocks.service';
import { DetailsService } from './application/main/details/details.service';
import { EditorControlButtonsService } from './services/editor-control-buttons.service';
import { PublishWebsiteService } from './services/publish-website/publish-website.service';
import { IsPublishingService } from './services/is-publishing/is-publishing.service';
import { TreeService } from './services/tree/tree.service';
import { MailChimpService } from './services/mailchimp.service';
import { ResizeService } from './services/editor-resize-handler.service';
import { InstagramService } from './services/instagram.service';
import { ImagesService } from './services/images.service';
import { ThemesService } from './application/main/website-designer/theme-pane/themes.service';
import { ImageResolutionService } from './services/image-resolution.service';
import { PageEditorService } from './application/main/website-designer/page-editor/page-editor.service';
import { SidebarSectionsService } from './services/sidebar-sections.service';
import { SidebarLoaderService } from './services/sidebar-loader.service';


// Pipes
import { MapPipe, FilterPipe, CapitalizePipe, SafePipe, SafeHtmlPipe, Base64PngPipe, FontWeightPipe, ChangeCasePipe } from './app-pipes';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Error Handling
import { ErrorComponent } from './error/error.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { InternalServerErrorComponent } from './error/internal-server-error/internal-server-error.component';

// 3rd Party Modules
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { CookieService } from 'ngx-cookie-service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TreeModule } from 'primeng/tree';

// directives
import { DraggableDirective } from './application/directives/drag-n-drop/draggable.directive';
import { DragService } from './application/directives/drag-n-drop/drag.service';

// Components
import { EcommerceSettingsComponent } from './application/main/settings/ecommerce/ecommerce-settings.component';
import { LoginContactComponent } from './application/main/settings/account/login-contact/login-contact.component';
import { AccountSectionComponent } from './application/sidebar-short/sidebar/account/account.component';
import { SidebarDescriptionComponent } from './application/sidebar-short/sidebar/common/description/description.component';
import { DomainsComponent } from './application/main/settings/account/domains/domains.component';
import { PaymentsComponent } from './application/main/settings/account/payments/payments.component';
import { PaymentInvoiceRowComponent } from './application/main/settings/account/payments/invoice-row/payment-invoice-row.component';
import { SubscriptionsComponent } from './application/main/settings/account/subscriptions/subscriptions.component';
import { SubscriptionComponent } from './application/main/settings/account/subscriptions/subscription/subscription.component';
import { WebsiteStatsComponent } from './application/main/dashboard/website-stats/website-stats.component';
import { DashboardComponent } from './application/main/dashboard/dashboard.component';
import { SocialMediaComponent } from './application/main/details/styles/social-media/social-media.component';
import { ShareSocialMediaComponent } from './application/main/details/styles/share-social-media/share-social-media.component';
import { TopBarButtonComponent } from './application/main/website-designer/page-editor/top-bar-button/top-bar-button.component';
import { AdminImportListComponent } from "./application/main/admin-panel/import/admin-import-list.component";
import { AdminImportFilterComponent } from "./application/main/admin-panel/import/filter/import-filter.component";
import { AdminCustomerListComponent } from "./application/main/admin-panel/customers/admin-customer-list.component";
import { AdminCustomerFilterComponent } from "./application/main/admin-panel/customers/customer-filter/customer-filter.component";
import { AdminService } from "./application/main/admin-panel/admin.service";
import { PaginationComponent } from "./application/main/admin-panel/customers/pagination/pagination.component";
import { AdminPanelComponent } from "./application/main/admin-panel/admin-panel.component";
import { EducatorAdminComponent } from "./application/main/educator/educator-admin.component";
import { EducatorAdminStudentsComponent } from "./application/main/educator/students/educator-admin-students.component";
import { EducatorAdminDeleteStudentModalComponent } from './application/main/educator/students/delete/delete-student-modal.component';
import { AdminCustomerInfoComponent } from "./application/main/admin-panel/customers/customer-info/customer-info.component";
import { AdminEducationComponent } from "./application/main/admin-panel/education/education.component";
import { AdminEducationTeachersComponent } from "./application/main/admin-panel/education/teachers/teachers.component";
import { AdminEducationAddTeacherModalComponent } from "./application/main/admin-panel/education/teachers/add/add-teacher-modal.component";
import { AdminEducationTeacherDetailsComponent } from "./application/main/admin-panel/education/teachers/details/teacher-details.component";
import { AdminEducationEditTeacherModalComponent } from "./application/main/admin-panel/education/teachers/edit/edit-teacher-modal.component";
import { AdminEducationDeleteTeacherModalComponent } from "./application/main/admin-panel/education/teachers/delete/delete-teacher-modal.component";
import { AdminEducationClassesComponent } from "./application/main/admin-panel/education/classes/classes.component";
import { AdminEducationAddClassModalComponent } from "./application/main/admin-panel/education/classes/add/add-class-modal.component";
import { AdminEducationClassDetailsComponent } from "./application/main/admin-panel/education/classes/details/class-details.component";
import { AdminEducationEditClassModalComponent } from "./application/main/admin-panel/education/classes/edit/edit-class-modal.component";
import { AdminEducationDeleteClassModalComponent } from "./application/main/admin-panel/education/classes/delete/delete-class-modal.component";
import { AdminEducationInstitutionsComponent } from "./application/main/admin-panel/education/institutions/institutions.component";
import { AdminEducationAddInstitutionModalComponent } from "./application/main/admin-panel/education/institutions/add/add-institution-modal.component";
import { AdminEducationInstitutionDetailsComponent } from "./application/main/admin-panel/education/institutions/details/institution-details.component";
import { AdminEducationEditInstitutionModalComponent } from "./application/main/admin-panel/education/institutions/edit/edit-institution-modal.component";
import { AdminEducationDeleteInstitutionModalComponent } from "./application/main/admin-panel/education/institutions/delete/delete-institution-modal.component";
import { AdminFreeTrialComponent } from "./application/main/admin-panel/free-trial/free-trial.component";
import { AdminGaAccountsComponent } from "./application/main/admin-panel/ga-accounts/ga-accounts.component";
import { AdminAppVersionComponent } from "./application/main/admin-panel/app-version/app-version.component";
import { DiskSpaceComponent } from './application/main/dashboard/disk-space/disk-space.component';
import { SupportStatisticComponent } from './application/main/dashboard/support/support-statistic.component';
import { DashboardInstructionalToursComponent } from './application/main/dashboard/instructional-tours/instructional-tours.component';
import { PreLaunchChecklistComponent } from './application/main/dashboard/pre-launch-checklist/pre-launch-checklist.component';
import { DashboardSectionWrapperComponent } from './application/main/dashboard/section-wrapper/dashboard-section-wrapper.component';

import { SetupComponent } from "./setup/setup.component";
import { SetupIntroductionComponent } from "./setup/introduction/setup-introduction.component";
import { SetupWebsiteSettingsComponent } from './setup/website-settings/setup-website-settings.component';
import { SetupUploadImagesComponent } from "./setup/upload-images/setup-upload-images.component";
import { ImageDropzoneComponent } from "./setup/upload-images/image-dropzone/image-dropzone.component";

import { ColorPickerComponent } from "./common/color-picker/color-picker.component";
import { IFrameClickCatcherService } from "./services/iframe-click-catcher.service";
import { ImageProcessesService } from "./services/image-processes.service";
import { BorderIconPickerComponent } from "./application/main/details/styles/border-icon-picker/border-icon-picker.component";
import { FullscreenIconComponent } from "./application/main/website-designer/page-editor/fullscreen-icon/fullscreen-icon.component";
import { FullscreenManager } from "./services/fullscreen-manager.service";
import { MenuNodesManagerService } from "./services/menu-nodes-manager.service";
import { NavigationService } from "./services/navigation.service";
import { MessageModalService } from "./services/message-modal.service";
import { ProgressBarButtonComponent } from './common/progress-bar-button/progress-bar-button.component';
import { TextAlertModalService } from "./services/text-alert-modal.service";
import { BlocksChangelogsService } from './services/blocks-changelogs.service';
import { SelectedBlocksService } from './services/selected-blocks.service';
import { FullscreenWrapperComponent } from "./common/fullscreen-wrapper/fullscreen-wrapper.component";
import { LogoComponent } from "./common/logo/logo.component";
import { ThemePaneComponent } from './application/main/website-designer/theme-pane/theme-pane.component';
import { BlocksCategoriesComponent } from './application/sidebar-short/sidebar/blocks/blocks-categories/blocks-categories.component';
import { PortfolioPickerModalService } from './services/portfolio-picker-modal.service';
import { BasicTreeNodeComponent } from './application/sidebar-short/sidebar/pages/tree/basic-tree-node-wrapper/basic-tree-node/basic-tree-node.component';
import { BasicTreeNodeWrapperComponent } from './application/sidebar-short/sidebar/pages/tree/basic-tree-node-wrapper/basic-tree-node-wrapper.component';
import { BlocksOptionsComponent } from './application/sidebar-short/sidebar/blocks/options/blocks-options.component';
import { SidebarIconComponent } from './application/sidebar-short/sidebar-icon/sidebar-icon.component';
import { AdminSectionComponent } from './application/sidebar-short/sidebar/admin/admin-section.component';
import { EducatorAdminSectionComponent } from './application/sidebar-short/sidebar/educator-admin/educator-admin-section.component';
import { ForceFocusDirective } from './application/directives/force-focus/force-focus.directive';
import { AdminPlansComponent } from './application/main/admin-panel/plans/plans.component';
import { EditableContentComponent } from './application/main/admin-panel/plans/editable-content/editable-content.component';
import { AdminPlansPermissionsComponent } from './application/main/admin-panel/plans-permissions/plans-permissions.component';
import { AdminCustomSubscriptionsComponent } from './application/main/admin-panel/custom-subscriptions/custom-subscriptions.component';
import { AdminBlocksTemplatesComponent } from './application/main/admin-panel/blocks-templates/blocks-templates.component';
import { AdminBlocksChangesComponent } from './application/main/admin-panel/blocks-changes/blocks-changes.component';
import { BlockTemplateChangelogComponent } from './application/main/admin-panel/blocks-templates/block-template-changelog/block-template-changelog.component';
import { AppModalContainerComponent } from './application/main/modal-containers/app-modal-container/app-modal-container.component';
import { PageEditorModalContainerComponent } from './application/main/modal-containers/page-editor-modal-container/page-editor-modal-container.component';
import { AdminDefaultFontsComponent } from './application/main/admin-panel/default-fonts/default-fonts.component';
import { AdminStripeComponent } from './application/main/admin-panel/stripe/admin-stripe.component';
import { AdminStripeCouponsComponent } from './application/main/admin-panel/stripe/coupons/admin-stripe-coupons.component';
import { AdminAppSettingsComponent } from './application/main/admin-panel/app-settings/app-settings.component';

const directives = [
  DraggableDirective,
  ForceFocusDirective,
];
const pipes = [
  MapPipe,
  FilterPipe,
  CapitalizePipe,
  SafePipe,
  SafeHtmlPipe,
  Base64PngPipe,
  FontWeightPipe,
  ChangeCasePipe,
];
const components = [
  AppComponent,
  ApplicationComponent,
  NavigationComponent,
  WebsitesDropdownComponent,
  SidebarComponent,
  SidebarShortComponent,
  AppTreeComponent,
  SettingsSectionComponent,
  PagesSectionComponent,
  EducatorImageManagerSidebarComponent,
  EducatorExhibitionsSidebarComponent,
  EducatorExhibitionSidebarItemComponent,
  StudentImageManagerSidebarComponent,
  StudentImageManagerSidebarItemComponent,
  BlocksSectionComponent,
  BlockComponent,
  MainComponent,
  StylesComponent,
  LinkDropdownComponent,
  ImageManagerComponent,
  BottomBarComponent,
  WebsiteDesignerComponent,
  DetailsComponent,
  PageEditorComponent,
  PageMenuComponent,
  PageMenuItemComponent,
  WebsiteSettingsComponent,
  ContactInfoSettingsComponent,
  SettingsComponent,
  EcommerceSettingsComponent,
  LoginContactComponent,
  AccountSectionComponent,
  SidebarDescriptionComponent,
  DomainsComponent,
  SubscriptionsComponent,
  SubscriptionComponent,
  PaymentsComponent,
  PaymentInvoiceRowComponent,
  WebsiteStatsComponent,
  DashboardComponent,
  WebsiteDetailsComponent,
  MobileTemplatesEnlargementsComponent,
  MobileTemplatesPortfoliosComponent,
  PurchaseComponent,
  PurchaseAddOnsComponent,
  CreditCardsPageComponent,
  SubscriptionOrderStepCardsComponent,
  RepeatPaymentComponent,
  RepeatPaymentConfirmationStepComponent,
  TopBarButtonComponent,
  AdminImportListComponent,
  AdminImportFilterComponent,
  AdminCustomerListComponent,
  AdminCustomerFilterComponent,
  PaginationComponent,
  AdminPanelComponent,
  EducatorAdminComponent,
  EducatorAdminStudentsComponent,
  EducatorAdminDeleteStudentModalComponent,
  SocialMediaComponent,
  ShareSocialMediaComponent,
  AdminCustomerInfoComponent,
  AdminEducationComponent,
  AdminEducationTeachersComponent,
  AdminEducationAddTeacherModalComponent,
  AdminEducationTeacherDetailsComponent,
  AdminEducationEditTeacherModalComponent,
  AdminEducationDeleteTeacherModalComponent,
  AdminEducationClassesComponent,
  AdminEducationAddClassModalComponent,
  AdminEducationClassDetailsComponent,
  AdminEducationEditClassModalComponent,
  AdminEducationDeleteClassModalComponent,
  AdminEducationInstitutionsComponent,
  AdminEducationAddInstitutionModalComponent,
  AdminEducationInstitutionDetailsComponent,
  AdminEducationEditInstitutionModalComponent,
  AdminEducationDeleteInstitutionModalComponent,
  AdminFreeTrialComponent,
  AdminGaAccountsComponent,
  AdminAppVersionComponent,
  AdminAppSettingsComponent,
  DiskSpaceComponent,
  SupportStatisticComponent,
  DashboardInstructionalToursComponent,
  PreLaunchChecklistComponent,
  DashboardSectionWrapperComponent,
  SetupComponent,
  SetupIntroductionComponent,
  SetupWebsiteSettingsComponent,
  SetupUploadImagesComponent,
  ImageDropzoneComponent,
  WarningMessageComponent,
  ColorPickerComponent,
  BorderIconPickerComponent,
  FullscreenIconComponent,
  ControlButtonComponent,
  ProgressBarButtonComponent,
  MailingListComponent,
  SocialNetworksComponent,
  SecuritySettingsComponent,
  PayPalComponent,
  EducationSettingsComponent,
  CollapsibleSectionComponent,
  PlanCardComponent,
  SupportHistoryComponent,
  TopBarComponent,
  CircleProgressBarComponent,
  EditorLoaderComponent,
  ThemePaneComponent,
  BlocksCategoriesComponent,
  BasicTreeNodeComponent,
  BasicTreeNodeWrapperComponent,
  BlocksOptionsComponent,
  SidebarIconComponent,
  AdminSectionComponent,
  EducatorAdminSectionComponent,
  AdminPlansComponent,
  EditableContentComponent,
  AdminPlansPermissionsComponent,
  AdminCustomSubscriptionsComponent,
  AdminBlocksTemplatesComponent,
  AdminBlocksChangesComponent,
  BlockTemplateChangelogComponent,
  AppModalContainerComponent,
  PageEditorModalContainerComponent,
  AdminDefaultFontsComponent,
  AdminStripeComponent,
  AdminStripeCouponsComponent,
];
const errorComponents = [
  ErrorComponent,
  NotFoundComponent,
  InternalServerErrorComponent,
];
const commonComponents = [
  FullscreenWrapperComponent,
  LogoComponent,
];

@NgModule({
  imports: [
    // angular modules
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    SharedModule,
    CoreModule,

    // 3rd party modules
    ColorPickerModule,
    DropzoneModule,
    AngularSvgIconModule.forRoot(),
    TreeModule,

    // application modules
    AppRoutingModule,

    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  declarations: [
    ...components,
    ...errorComponents,
    ...directives,
    ...pipes,
    ...commonComponents,
  ],
  providers: [
    BagService,
    UserResolver,
    AuthService,
    PagesService,
    ImageManagerService,
    WebsiteDesignerService,
    CookieService,
    DragService,
    BlocksService,
    AdminService,
    DetailsService,
    IFrameClickCatcherService,
    ImageProcessesService,
    EditorControlButtonsService,
    FullscreenManager,
    NavigationService,
    PublishWebsiteService,
    IsPublishingService,
    TreeService,
    MessageModalService,
    MenuNodesManagerService,
    MailChimpService,
    ResizeService,
    InstagramService,
    ImagesService,
    TextAlertModalService,
    BlocksChangelogsService,
    SelectedBlocksService,
    ThemesService,
    ImageResolutionService,
    PageEditorService,
    PortfolioPickerModalService,
    SidebarSectionsService,
    SidebarLoaderService,
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AppModule { }
