import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-publish-image-button',
  templateUrl: './publish-image-button.component.html',
  styleUrls: ['./publish-image-button.component.scss'],
})
export class PublishImageButtonComponent {
  @Input() disabled: boolean = false;

  @Output() isPublishedChange = new EventEmitter();
  @Output() changeHandler = new EventEmitter();

  public isHovered: boolean = false;

  private isImagePublished: boolean;

  @Input()
  get isPublished() {
    return this.isImagePublished;
  }

  set isPublished(value) {
    this.isImagePublished = value;
  }

  public get text() {
    return this.isPublished ? 'Published' : 'Not Published';
  }

  onClick() {
    this.isPublished = !this.isImagePublished;
    this.isPublishedChange.emit(this.isImagePublished);
    this.changeHandler.emit();
  }

  public onMouseEnter(): void {
    this.isHovered = true;
  }

  public onMouseLeave(): void {
    this.isHovered = false;
  }
}
