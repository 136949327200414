// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.direct-link-input-modal-body {
  padding: 15px;
  text-transform: none;
}
.direct-link-input-modal-body .tip {
  color: #eee;
}
.direct-link-input-modal-body .error-message {
  font-size: 14px;
  color: red;
}
.direct-link-input-modal-body .form-group-sm {
  position: relative;
  width: 100%;
}
.direct-link-input-modal-body .form-group-sm .link-input {
  width: 100%;
  margin: 10px 0;
  border-radius: 0;
  padding-right: 25px;
}
.direct-link-input-modal-body .form-group-sm .trash-wrapper {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  height: 45px;
  margin: auto;
}
.direct-link-input-modal-body .form-group-sm .trash-wrapper .trash-icon:hover {
  cursor: pointer;
  color: #000;
}
.direct-link-input-modal-body .form-group-sm .new-tab-checkbox-label {
  display: flex;
  justify-content: flex-start;
}
.direct-link-input-modal-body .form-group-sm .new-tab-checkbox-label .new-tab-checkbox-input {
  margin-right: 10px;
}
.direct-link-input-modal-body .btn-bar {
  height: 40px;
}
.direct-link-input-modal-body .btn-bar .btn {
  border-radius: 0;
  border: 1px solid #333;
}
.direct-link-input-modal-body .btn-bar .btn.disabled {
  opacity: 0.7;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/link-input-modal/link-input-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,oBAAA;AADF;AAGE;EACE,WAAA;AADJ;AAIE;EACE,eAAA;EACA,UAAA;AAFJ;AAKE;EACE,kBAAA;EACA,WAAA;AAHJ;AAKI;EACE,WAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AAHN;AAMI;EACE,kBAAA;EACA,UAAA;EACA,MAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;AAJN;AAOQ;EACE,eAAA;EACA,WAAA;AALV;AAUI;EACE,aAAA;EACA,2BAAA;AARN;AAUM;EACE,kBAAA;AARR;AAaE;EACE,YAAA;AAXJ;AAaI;EACE,gBAAA;EACA,sBAAA;AAXN;AAaM;EACE,YAAA;EACA,oBAAA;AAXR","sourcesContent":["\n\n.direct-link-input-modal-body {\n  padding: 15px;\n  text-transform: none;\n\n  .tip {\n    color: #eee;\n  }\n\n  .error-message {\n    font-size: 14px;\n    color: red;\n  }\n\n  .form-group-sm {\n    position: relative;\n    width: 100%;\n\n    .link-input {\n      width: 100%;\n      margin: 10px 0;\n      border-radius: 0;\n      padding-right: 25px;\n    }\n\n    .trash-wrapper {\n      position: absolute;\n      right: 5px;\n      top: 0;\n      bottom: 0;\n      height: 45px;\n      margin: auto;\n\n      .trash-icon {\n        &:hover {\n          cursor: pointer;\n          color: #000;\n        }\n      }\n    }\n\n    .new-tab-checkbox-label {\n      display: flex;\n      justify-content: flex-start;\n\n      .new-tab-checkbox-input {\n        margin-right: 10px;\n      }\n    }\n  }\n\n  .btn-bar {\n    height: 40px;\n\n    .btn {\n      border-radius: 0;\n      border: 1px solid #333;\n\n      &.disabled {\n        opacity: 0.7;\n        pointer-events: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
