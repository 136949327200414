import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../../../shared/services/modals/modals.service';

import {SectionModel} from '../../models/media/section/section.model';

import {SECTIONS_KEYS, SECTIONS} from './constants';

@Injectable()
export class MediaManagerService {
  public sectionsSubject: BehaviorSubject<SectionModel[]> = new BehaviorSubject<SectionModel[]>(SECTIONS);
  public activeSectionSubject: BehaviorSubject<SectionModel> = new BehaviorSubject<SectionModel>(null);
  public isSectionsDisabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public modalId: string = 'media-manager-modal';

  constructor(private modalsService: ModalsService) {
    this.selectSection(SECTIONS_KEYS.VIDEOS);
  }

  public openModal(): void {
    this.modalsService.open(this.modalId);
  }

  public close(): void {
    this.isSectionsDisabledSubject.next(false);

    this.selectSection(SECTIONS_KEYS.VIDEOS);
  }

  public closeModal(): void {
    this.isSectionsDisabledSubject.next(false);

    this.modalsService.close(this.modalId);
  }

  public selectSection(key: string): void {
    this.activeSectionSubject.next(SECTIONS.find((section: SectionModel) => section.key === key));
  }
}
