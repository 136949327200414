import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ImageManagerService} from '../../../../../../application/main/image-manager/image-manager.service';

import {EducationTeacherModel} from '../../../../../models/education/teachers/education-teacher.model';
import {EducationTeacherDto} from '../../../../../models/education/teachers/education-teacher.dto';
import {PortfolioDefaultsModel} from '../../../../../models/images/default/portfolio-defaults.model';
import {PortfolioDefaultsV2Dto} from '../../../../../models/images/default/portfolio-defaults-v2.dto';
import {ImageDto} from '../../../../../models/images/image.dto';
import {EducationStudentPortfolioModel} from '../../../../../models/education/portfolios/education-student-portfolio.model';
import {EducationExhibitionPortfolioModel} from '../../../../../models/education/portfolios/education-exhibition-portfolio.model';
import {PortfolioDefaultsRequest} from '../../../../../models/images/default/portfolio-defaults.request';
import {ImageModel} from '../../../../../models/images/image.model';

import {LIBRARY_ID} from '../../../../../../application/constants';

@Injectable()
export class  EducationTeachersHttpService {
  private prefix: string = 'api/education/teachers';

  constructor(private http: HttpClient,
              private imageManagerService: ImageManagerService) {
  }

  public getCurrent(): Observable<EducationTeacherModel> {
    return this.http.get(`${this.prefix}/current`).pipe(
      map((item: EducationTeacherDto) => EducationTeacherDto.normalize(item))
    );
  }

  public getStudentPortfolioDefaults(portfolioData: EducationStudentPortfolioModel): Observable<PortfolioDefaultsModel> {
    return this.http.get(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/students/${portfolioData.studentId}/portfolios/${portfolioData.id}/defaults`).pipe(
      map((res: PortfolioDefaultsV2Dto) => PortfolioDefaultsV2Dto.normalize(res)),
      map((res: PortfolioDefaultsModel) => this.imageManagerService.convertToWebsiteMeasureUnit(res)),
    );
  }

  public getStudentPortfolioImages(portfolioData: EducationStudentPortfolioModel) {
    return this.http.get(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/students/${portfolioData.studentId}/portfolios/${portfolioData.id === LIBRARY_ID ? 'library' : portfolioData.id}/images`).pipe(map((images: ImageDto[]) => {
      return images.map((image: ImageDto) => {
        return ImageDto.normalize(image);
      });
    }));
  }

  public getExhibitionPortfolioDefaults(portfolioData: EducationExhibitionPortfolioModel): Observable<PortfolioDefaultsModel> {
    return this.http.get(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/websites/${portfolioData.websiteId}/portfolios/${portfolioData.id}/defaults`).pipe(
      map((res: PortfolioDefaultsV2Dto) => PortfolioDefaultsV2Dto.normalize(res)),
      map((res: PortfolioDefaultsModel) => this.imageManagerService.convertToWebsiteMeasureUnit(res)),
    );
  }

  public getExhibitionPortfolioImages(portfolioData: EducationExhibitionPortfolioModel) {
    return this.http.get(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/websites/${portfolioData.websiteId}/portfolios/${portfolioData.id === LIBRARY_ID ? 'library' : portfolioData.id}/images`).pipe(map((images: ImageDto[]) => {
      return images.map((image: ImageDto) => {
        return ImageDto.normalize(image);
      });
    }));
  }

  public updateExhibitionPortfolioDefaults(portfolioData: EducationExhibitionPortfolioModel, portfolioDefaults: PortfolioDefaultsRequest): Observable<PortfolioDefaultsModel> {
    return this.http.put(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/websites/${portfolioData.websiteId}/portfolios/${portfolioData.id}/defaults`, portfolioDefaults).pipe(
      map((res: PortfolioDefaultsV2Dto) => PortfolioDefaultsV2Dto.normalize(res)),
      map((res: PortfolioDefaultsModel) => this.imageManagerService.convertToWebsiteMeasureUnit(res)),
    );
  }
 
  public replaceImage(portfolioData: EducationExhibitionPortfolioModel, imageId: number, formData: FormData): Observable<ImageModel> {
    return this.http.put(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/websites/${portfolioData.websiteId}/portfolios/${portfolioData.id}/images/${imageId}/file`, formData).pipe(
      map((image: ImageDto) => ImageDto.normalize(image))
    );
  }

  public updateExhibitionImagePublishState(portfolioData: EducationExhibitionPortfolioModel, imageId: number, isPublished: boolean): Observable<any> {
    return this.http.put(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/websites/${portfolioData.websiteId}/portfolios/${portfolioData.id}/images/${imageId}/publishing`, { isPublished });
  }

  public moveImageToLibrary(portfolioData: EducationExhibitionPortfolioModel, imageId: number): Observable<any> {
    return this.http.delete(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/websites/${portfolioData.websiteId}/portfolios/${portfolioData.id}/images/${imageId}`, {});
  }

  public moveMultipleImagesToLibrary(portfolioData: EducationExhibitionPortfolioModel, imagesIds: number[]): Observable<any> {
    const params = new HttpParams().set('imagesIds', imagesIds.join(','));

    return this.http.delete(`${this.prefix}/current/institutions/${portfolioData.institutionId}/classes/${portfolioData.classId}/websites/${portfolioData.websiteId}/portfolios/${portfolioData.id}/images/multiple`, { params });
  }
}
