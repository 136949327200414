import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-control-button',
  templateUrl: './control-button.component.html',
  styleUrls: ['./control-button.component.scss'],
})
export class ControlButtonComponent {
  @Input() text: string;
  @Input() enabled = true;
  @Input() tourGroupKey: string = null;
  @Input() tourKey: string = null;
  @Input() tourLocation: string = null;

  @Input() enabledColor: boolean;

  @Output() clickHandler = new EventEmitter();

  constructor() {}

  onClick(event) {
    this.clickHandler.emit(event);
  }
}
