import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../../../app-animations';

import {PaymentHistoryService} from '../../../../../core/services/payment/history/payment-history.service';
import {ContentLoaderService} from '../../../../../core/services/loaders/content/content-loader.service';

import {PaymentIntentModel} from '../../../../../core/models/payment/intent/payment-intent.model';
import {PaymentModel} from '../../../../../core/models/payment/intent/payment.model';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PaymentsComponent implements OnInit, OnDestroy {
  public payments: PaymentIntentModel = null;

  private isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isLoaded(): boolean {
    return this.contentLoaderService.isLoaded;
  }

  constructor(
    private router: Router,
    private paymentService: PaymentHistoryService,
    private contentLoaderService: ContentLoaderService,
  ) {
    this.paymentService.init();
  }

  public ngOnInit(): void {
    this.paymentService.paymentsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: PaymentIntentModel) => {
      this.payments = value;
    });
  }

  public retryPayment(invoiceId: number): void {
    this.router.navigate([
      '/app',
      {
        outlets: {
          primary: [
            'settings',
            'repeat-payment',
          ],
          sidebar: [
            'account',
            'credit-cards',
          ],
          'over-sidebar': null,
        },
      },
    ],
    {
      queryParams: {
        invoiceId,
      },
    });
  }

  public onScroll(e) {
    if (!this.payments || !this.payments.hasMore || this.isLoading) return;

    const list = this.payments.list;

    if (!list || list.length === 0) return;

    const currentPosition = e.target.scrollTop + e.target.clientHeight;

    if (currentPosition / e.target.scrollHeight < 0.5) return;

    this.isLoading = true;

    const last: PaymentModel[] = list[list.length - 1];

    this.paymentService.fetchMore(last[0].id).add(() => { this.isLoading = false; });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
