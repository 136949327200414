import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {WebsiteDto} from '../../../../models/websites/website.dto';
import {WebsiteModel} from '../../../../models/websites/website.model';
import {DomainDto} from '../../../../models/domain/domain.dto';
import {DomainModel} from '../../../../models/domain/domain.model';

@Injectable()
export class WebsitesHttpService {
  private model: string = 'websites';

  constructor(private http: HttpClient) {}

  public fetchWebsites(): Observable<WebsiteModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: WebsiteDto[]) => res.map(item => WebsiteDto.normalize(item)))
    );
  }

  public fetchActiveWebsite(): Observable<WebsiteModel> {
    return this.http.get(`api/${this.model}/active`).pipe(
      map((res: WebsiteDto) => WebsiteDto.normalize(res))
    );
  }

  public fetchIsExclamationIconVisible(): Observable<boolean> {
    return this.http.get<boolean>(`api/${this.model}/current/exclamation-icon`);
  }

  public fetchIsExclamationIconButtonVisible(): Observable<boolean> {
    const params = new HttpParams().set('edge', '1400');

    return this.http.get<boolean>(`api/${this.model}/current/exclamation-icon/button-visibility`, { params });
  }

  public createNewWebsite({ title }: { title: string }): Observable<WebsiteModel> {
    return this.http.post(`api/${this.model}`, { title }).pipe(
      map((res: WebsiteDto) => WebsiteDto.normalize(res)),
    );
  }

  public updateWebsite(websiteId: number, website: WebsiteDto): Observable<any> {
    return this.http.put(`api/${this.model}/${websiteId}`, website, { responseType: 'text' });
  }

  public setPayPalEmail(payPalEmail: string): Observable<any> {
    return this.http.put(`api/${this.model}/current/paypal-email`, { payPalEmail }, { responseType: 'text' });
  }

  public setIsSetupCompleted(isSetupCompleted: boolean): Observable<any> {
    return this.http.put(`api/${this.model}/current/setup`, { isSetupCompleted }, { responseType: 'text' });
  }

  public setIsBlogCommentsApprovable(isApprovable: boolean): Observable<any> {
    return this.http.put(`api/${this.model}/current/comments-approvement`, { isApprovable }, { responseType: 'text' });
  }

  public setIsExclamationIconVisible(isExclamationIconVisible: boolean): Observable<boolean> {
    return this.http.put<boolean>(`api/${this.model}/current/exclamation-icon`, { isExclamationIconVisible });
  }

  public setIsSimpleImageLabel(isSimpleImageLabel: boolean): Observable<boolean> {
    return this.http.put<boolean>(`api/${this.model}/current/simple-image-label`, { isSimpleImageLabel });
  }

  public getWebsitePrimaryDomain(websiteId: number): Observable<DomainModel> {
    return this.http.get(`api/${this.model}/${websiteId}/domains/primary`).pipe(
      map((res: DomainDto) => DomainDto.normalize(res))
    );
  }
}
