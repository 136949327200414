import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';

import {CouponsModalService} from '../../../services/modals/coupons/coupons-modal.service';

import {StripeCouponModel} from '../../../../core/models/stripe/coupon/stripe-coupon.model';

@Component({
  selector: 'app-coupons-modal',
  templateUrl: './coupons-modal.component.html',
  styleUrls: ['./coupons-modal.component.scss']
})
export class CouponsModalComponent implements OnDestroy {
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: CouponsModalService) {
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onCouponSubmit(coupon: StripeCouponModel): void {
    this.service.selectedCouponSubject.next(coupon);

    this.close();
  }

  public close(): void {
    this.service.close();
  }
}
