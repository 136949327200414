import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../modals.service';

@Injectable()
export class DirectLinkInputModalService {
  public readonly id = 'direct-link-input-modal';

  public isOpened = false;

  public onSaveSubject: BehaviorSubject<{ websiteId: number, nodeId: number, link: string, isInNewTab: boolean }> = new BehaviorSubject<{ websiteId: number, nodeId: number, link: string, isInNewTab: boolean }>(null);

  public constructor(private modalsService: ModalsService) {
  }

  public open(data: { websiteId: number, nodeId: number, link: string, isInNewTab: boolean }): void {
    this.modalsService.open(this.id, data);

    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
