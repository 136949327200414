export const KEYS = {
  MOBILE_MENU_BACKGROUND_COLOR: 'MOBILE_MENU_BACKGROUND_COLOR',
  MOBILE_MENU_STYLE: 'MOBILE_MENU_STYLE',
  MOBILE_MENU_DROPDOWN_ARROW: 'MOBILE_MENU_DROPDOWN_ARROW',
  MOBILE_MENU_POSITION: 'MOBILE_MENU_POSITION',
  MOBILE_ACTIVE_COLOR: 'MOBILE_ACTIVE_COLOR',
  MOBILE_HOVER_COLOR: 'MOBILE_HOVER_COLOR',
  MOBILE_HOVER_BACKGROUND: 'MOBILE_HOVER_BACKGROUND',
  MOBILE_LINKS_TEXT_DECORATION: 'MOBILE_LINKS_TEXT_DECORATION',
  MOBILE_COLLAPSE_OPTIONS: 'MOBILE_COLLAPSE_OPTIONS',
  MOBILE_ITEM_BORDER_COLOR: 'MOBILE_ITEM_BORDER_COLOR',
  MOBILE_ITEM_BORDER_WIDTH: 'MOBILE_ITEM_BORDER_WIDTH',
};

export const TYPES = {
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
};
