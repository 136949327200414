type PermissionType = 'permission';

const PERMISSIONS = {
  ADMIN: 'ADMIN',
  BLOG: 'BLOG',
  MAILING_LIST: 'MAILING_LIST',
  DEFAULT_FONTS: 'DEFAULT_FONTS',
  NON_DEFAULT_FONTS: 'NON_DEFAULT_FONTS',
  FULL_GA_STATS: 'FULL_GA_STATS',
  MULTI_WEBSITE_ADMIN_PANEL: 'MULTI_WEBSITE_ADMIN_PANEL',
  CLASSES_MANAGER: 'CLASSES_MANAGER',
  MEDIA_MANAGER_PDF_TAB_ACCESSIBILITY: 'MEDIA_MANAGER_PDF_TAB_ACCESSIBILITY',
  MEDIA_MANAGER_VIDEO_TAB_ACCESSIBILITY: 'MEDIA_MANAGER_VIDEO_TAB_ACCESSIBILITY',
  MEDIA_MANAGER_AUDIO_TAB_ACCESSIBILITY: 'MEDIA_MANAGER_AUDIO_TAB_ACCESSIBILITY',
  GA_ACCESS: 'GA_ACCESS',
  PAYPAL_ACCESS: 'PAYPAL_ACCESS',
  EDUCATOR_ADMIN: 'EDUCATOR_ADMIN',
  EDUCATOR_IMAGE_MANAGER: 'EDUCATOR_IMAGE_MANAGER',
  EDUCATOR_CLASSES_WEBSITES_LIST: 'EDUCATOR_CLASSES_WEBSITES_LIST',
  STUDENT: 'STUDENT',
  STUDENT_ADMIN: 'STUDENT_ADMIN',
  STUDENT_IMAGE_MANAGER: 'STUDENT_IMAGE_MANAGER',
  ADD_WEBSITE_BUTTON: 'ADD_WEBSITE_BUTTON',
  ADD_SHARED_PORTFOLIO: 'ADD_SHARED_PORTFOLIO',
  IMAGE_REVIEWS: 'IMAGE_REVIEWS',
  SHARE_PAGE: 'SHARE_PAGE',
  IMAGE_RATING: 'IMAGE_RATING',
  IMAGE_EDITOR: 'IMAGE_EDITOR',
  IMAGE_EDIT_SUGGESTIONS: 'IMAGE_EDIT_SUGGESTIONS',
  ADVANCED_IMAGE_LABEL: 'ADVANCED_IMAGE_LABEL',
  WEBSITES_DROPDOWN: 'WEBSITES_DROPDOWN',
  AUDIO_REVIEW_RECORD: 'AUDIO_REVIEW_RECORD',
  AUDIO_REVIEW_LISTEN: 'AUDIO_REVIEW_LISTEN',
};

export {
  PermissionType,
  PERMISSIONS,
};
