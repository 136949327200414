import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTE} from './constants';

export class VideoPreloadModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = !element.matches(`[${ATTRIBUTE}="none"]`);
  }

  public onChange(value: boolean): void {
    this.value = value;

    if (!this.isEnabled) return;

    this.element.setAttribute(ATTRIBUTE, value ? 'metadata' : 'none');
  }
}
