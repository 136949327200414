import {Component, OnInit, OnDestroy, Input} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ButtonsService} from '../../core/services/buttons/buttons.service';
import {PublishWebsiteService} from '../../services/publish-website/publish-website.service';

import {PUBLISH_BUTTON_STATES} from '../../services/publish-website/constants';

@Component({
  selector: 'app-progress-bar-button',
  templateUrl: './progress-bar-button.component.html',
  styleUrls: ['./progress-bar-button.component.scss']
})
export class ProgressBarButtonComponent implements OnInit, OnDestroy {
  @Input() tourKey: string = null;
  @Input() tourLocation: string = null;

  public buttonLabel: string = '';

  public isWaiting: boolean = false;
  public isPublishPossible: boolean = false;
  public isPublished: boolean = false;
  public isPublishing: boolean = false;

  public progress: number = 0;
  public progressBarWidth: string = `0`;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private buttonsService: ButtonsService,
    private publishWebsiteService: PublishWebsiteService
  ) {
  }

  public ngOnInit(): void {
    this.buttonsService.onPublishButtonChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string) => {
      this.isWaiting = state === PUBLISH_BUTTON_STATES.DISABLED;
      this.isPublishPossible = state === PUBLISH_BUTTON_STATES.ENABLED;
      this.isPublished = state === PUBLISH_BUTTON_STATES.SUCCESS;
      this.isPublishing = state === PUBLISH_BUTTON_STATES.PUBLISHING;

      this.buttonLabel = this.getButtonText();
    });

    this.publishWebsiteService.progressSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: number) => {
      this.progress = value || 0;
      this.progressBarWidth = `${value || 0}px`;

      this.buttonLabel = this.getButtonText();
    });
  }

  private getButtonText(): string {
    if (this.isWaiting || this.isPublished) return 'PUBLISHED';
    if (this.isPublishPossible) return 'PUBLISH WEBSITE';
    if (this.isPublishing) return `${this.progress}%`;

    return '';
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
