import { DEVICES } from '../../../../../services/editor-devices/constants';

export const KEYS = {
  MENU_LOGO_PADDING_TOP: 'MENU_LOGO_PADDING_TOP',
  MENU_LOGO_PADDING_RIGHT: 'MENU_LOGO_PADDING_RIGHT',
  MENU_LOGO_PADDING_BOTTOM: 'MENU_LOGO_PADDING_BOTTOM',
  MENU_LOGO_PADDING_LEFT: 'MENU_LOGO_PADDING_LEFT',
};

export const STYLE_PROPERTIES = {
  [DEVICES.MOBILE]: {
    [KEYS.MENU_LOGO_PADDING_TOP]: '--menu-logo-mobile-padding-top',
    [KEYS.MENU_LOGO_PADDING_RIGHT]: '--menu-logo-mobile-padding-right',
    [KEYS.MENU_LOGO_PADDING_BOTTOM]: '--menu-logo-mobile-padding-bottom',
    [KEYS.MENU_LOGO_PADDING_LEFT]: '--menu-logo-mobile-padding-left',
  },
  [DEVICES.TABLET]: {
    [KEYS.MENU_LOGO_PADDING_TOP]: '--menu-logo-tablet-padding-top',
    [KEYS.MENU_LOGO_PADDING_RIGHT]: '--menu-logo-tablet-padding-right',
    [KEYS.MENU_LOGO_PADDING_BOTTOM]: '--menu-logo-tablet-padding-bottom',
    [KEYS.MENU_LOGO_PADDING_LEFT]: '--menu-logo-tablet-padding-left',
  },
  [DEVICES.DESKTOP]: {
    [KEYS.MENU_LOGO_PADDING_TOP]: '--menu-logo-padding-top',
    [KEYS.MENU_LOGO_PADDING_RIGHT]: '--menu-logo-padding-right',
    [KEYS.MENU_LOGO_PADDING_BOTTOM]: '--menu-logo-padding-bottom',
    [KEYS.MENU_LOGO_PADDING_LEFT]: '--menu-logo-padding-left',
  },
};

export const OPTIONS = [{
  text: 'Top',
  key: KEYS.MENU_LOGO_PADDING_TOP,
}, {
  text: 'Right',
  key: KEYS.MENU_LOGO_PADDING_RIGHT,
}, {
  text: 'Bottom',
  key: KEYS.MENU_LOGO_PADDING_BOTTOM,
}, {
  text: 'Left',
  key: KEYS.MENU_LOGO_PADDING_LEFT,
}];
