import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-cart-item-delete-modal',
  templateUrl: './cart-item-delete-modal.component.html',
  styleUrls: ['./cart-item-delete-modal.component.scss'],
})
export class CartItemDeleteModalComponent {
  @Input() id: string;

  @Output() confirmHandler: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelHandler: EventEmitter<void> = new EventEmitter<void>();

  public header = {
    className: 'warning-header',
  };

  public buttons: Button[] = [
    {
      text: `Delete`,
      className: 'red',
      onClick: this.onDeleteHandler.bind(this),
    },
    {
      text: `Cancel`,
      className: 'neutral',
      onClick: this.onCancelHandler.bind(this),
    },
  ];

  constructor() {
  }

  public onDeleteHandler(): void {
    this.confirmHandler.emit();
  }

  public onCancelHandler(): void {
    this.cancelHandler.emit();
  }
}
