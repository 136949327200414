// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-cards-list-wrapper {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 35px;
}

.no-cards {
  font-size: 12pt;
  margin-top: 35px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/credit-cards/credit-cards-list/credit-cards-list.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AADF","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.credit-cards-list-wrapper {\n  width: 100%;\n  text-align: center;\n  font-size: 16px;\n  margin-top: 35px;\n}\n\n.no-cards {\n  font-size: 12pt;\n  margin-top: 35px;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
