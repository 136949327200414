import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {DefaultPortfolioSetupModalService} from '../../../services/modals/default-portfolio-setup/default-portfolio-setup-modal.service';
import {DefaultPortfolioSetupService} from '../../../../core/services/default-portfolio/setup/default-portfolio-setup.service';
import {PortfolioService} from '../../../../core/services/portfolios/portfolio.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';

import {Button} from '../../../../common/models/button/button.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';

import {NUMBERS, TITLES} from './constants';
import {STEPS} from '../../../../core/services/default-portfolio/setup/constants';

@Component({
  selector: 'app-default-portfolio-setup-modal',
  templateUrl: './default-portfolio-setup-modal.component.html',
  styleUrls: ['./default-portfolio-setup-modal.component.scss']
})
export class DefaultPortfolioSetupModalComponent implements OnInit, OnDestroy {
  @Input() isUpcomingDefaultPortfolioSupportsSlides: boolean;

  @Output() saveHandler: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isLoading: boolean = false;

  public isUpdateStep: boolean = false;
  public isApplyStep: boolean = false;
  public isNoteStep: boolean = false;
  public isConfirmationStep: boolean = false;

  public isApplyToAll: boolean = false;

  public stepNumber: string = null;
  public stepTitle: string = '';
  public stepButtons: Button[] = [];

  private website: WebsiteModel;
  private templateId: number;

  public header: ModalHeader = {
    text: 'DEFAULT PORTFOLIO BLOCK SET UP',
    className: 'warning-header',
  };

  private buttons: { [key: string]: Button[] } = {
    [STEPS.UPDATE]: [
      new Button('YES', null, 'green', this.onUpdateAgree.bind(this)),
      new Button('NO', null, 'red', this.close.bind(this)),
    ],
    [STEPS.APPLY]: [
      new Button('YES', null, 'green', this.onApplyAgree.bind(this)),
      new Button('NO', null, 'red', this.onApplyDecline.bind(this)),
    ],
    [STEPS.NOTE]: [
      new Button('YES', null, 'green', this.onNoteAgree.bind(this)),
      new Button('NO', null, 'red', this.onNoteDecline.bind(this)),
    ],
    [STEPS.CONFIRMATION]: [
      new Button('OK', null, 'neutral', this.close.bind(this)),
    ],
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(private modalsService: ModalsService,
              private service: DefaultPortfolioSetupModalService,
              private setupService: DefaultPortfolioSetupService,
              private portfolioService: PortfolioService,
              private websitesService: WebsitesService,
              private templatesService: TemplatesService,
              private cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.setupService.step.pipe(takeUntil(this.ngUnsubscribe)).subscribe((step: string) => {
      this.stepNumber = NUMBERS[step];
      this.stepTitle = TITLES[step];
      this.stepButtons = this.buttons[step];

      this.isUpdateStep = step === STEPS.UPDATE;
      this.isApplyStep = step === STEPS.APPLY;
      this.isNoteStep = step === STEPS.NOTE;
      this.isConfirmationStep = step === STEPS.CONFIRMATION;

      this.cdr.detectChanges();
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.templateId = template ? template.id : null;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onUpdateAgree(): void {
    this.setupService.step.next(STEPS.APPLY);
  }

  public onApplyAgree(): void {
    this.isApplyToAll = true;

    if (this.isUpcomingDefaultPortfolioSupportsSlides) {
      this.saveHandler.emit(true);

      this.setupService.step.next(STEPS.CONFIRMATION);

      return;
    }

    this.isLoading = true;

    this.cdr.detectChanges();

    this.portfolioService.fetchIsPortfolioWithTextSlidesExists({ websiteId: this.website.id, templateId: this.templateId }).subscribe(({ isPortfolioWithTextSlidesExists }: { isPortfolioWithTextSlidesExists: boolean }) => {
      this.isLoading = false;

      if (isPortfolioWithTextSlidesExists) {
        this.setupService.step.next(STEPS.NOTE);

        return;
      }

      this.saveHandler.emit(true);

      this.setupService.step.next(STEPS.CONFIRMATION);
    });
  }

  public onApplyDecline(): void {
    this.isApplyToAll = false;

    this.saveHandler.emit(false);

    this.setupService.step.next(STEPS.CONFIRMATION);
  }

  public onNoteAgree(): void {
    this.isApplyToAll = true;

    this.saveHandler.emit(true);

    this.setupService.step.next(STEPS.CONFIRMATION);
  }

  public onNoteDecline(): void {
    this.isApplyToAll = false;

    this.saveHandler.emit(false);

    this.setupService.step.next(STEPS.CONFIRMATION);
  }

  private close(): void {
    this.service.close();

    this.cancelHandler();
  }

  public cancelHandler(): void {
    this.setupService.step.next(STEPS.UPDATE);
  }
}

