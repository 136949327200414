// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-with-value-on-thumb {
  position: relative;
}
.slider-with-value-on-thumb .off-point {
  height: 7px;
  width: 7px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #3f3e3e;
  position: absolute;
  top: 17px;
  pointer-events: none;
  border: 1px solid #ccc;
}
.slider-with-value-on-thumb .slider-thumb {
  font-size: 8px;
  line-height: 20px;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #666;
  position: absolute;
  top: 1px;
  pointer-events: none;
  color: #fff;
  transition: transform 0.1s ease-in-out;
}
.slider-with-value-on-thumb .slider-thumb.active {
  transform: scale(1.25);
}
.slider-with-value-on-thumb input[type=range] {
  height: 20px;
  margin: 0;
}
.slider-with-value-on-thumb input[type=range]::-webkit-slider-thumb {
  opacity: 0;
}
.slider-with-value-on-thumb input[type=range]::-moz-range-thumb {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/sliders/slider-with-value-on-thumb/slider-with-value-on-thumb.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,WAAA;EACA,UAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,oBAAA;EACA,sBAAA;AADJ;AAIE;EACE,cAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,oBAAA;EACA,WAAA;EACA,sCAAA;AAFJ;AAII;EACE,sBAAA;AAFN;AAME;EACE,YAAA;EACA,SAAA;AAJJ;AAMI;EACE,UAAA;AAJN;AAOI;EACE,UAAA;AALN","sourcesContent":["@import \"../../../../../../node_modules/compass-mixins/lib/compass\";\n\n.slider-with-value-on-thumb {\n  position: relative;\n\n  .off-point {\n    height: 7px;\n    width: 7px;\n    display: inline-block;\n    text-align: center;\n    border-radius: 50%;\n    background: #3f3e3e;\n    position: absolute;\n    top: 17px;\n    pointer-events: none;\n    border: 1px solid #ccc;\n  }\n\n  .slider-thumb {\n    font-size: 8px;\n    line-height: 20px;\n    height: 20px;\n    width: 20px;\n    display: inline-block;\n    text-align: center;\n    border-radius: 50%;\n    background: #666;\n    position: absolute;\n    top: 1px;\n    pointer-events: none;\n    color: #fff;\n    transition: transform 0.1s ease-in-out;\n\n    &.active {\n      transform: scale(1.25);\n    }\n  }\n\n  input[type=\"range\"] {\n    height: 20px;\n    margin: 0;\n\n    &::-webkit-slider-thumb {\n      opacity: 0;\n    }\n\n    &::-moz-range-thumb {\n      opacity: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
