import {VideoDto} from './video.dto';
import {VideoUploadUrlsModel} from './video-upload-urls.model';

export class VideoUploadUrlsDto {
  constructor(public videoUrl?: string,
              public thumbUrl?: string,
              public video?: VideoDto) {}

  public static normalize(res: VideoUploadUrlsDto): VideoUploadUrlsModel {
    if (!res) return null;

    return new VideoUploadUrlsModel(
      res.videoUrl,
      res.thumbUrl,
      VideoDto.normalize(res.video),
    );
  }
}
