export const ATTRIBUTES = {
  LOADER_KEY: 'data-loader-key',
};

export const VALUES = [
  {
    key: 'CIRCLING',
    label: 'Circling',
  },
  {
    key: 'THREE_DOTS',
    label: 'Three Dots',
  },
];

export const DEFAULT_KEY = VALUES[0].key;
