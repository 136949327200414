// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.app.blurred {
  filter: blur(5px);
}
.app .navigation {
  max-height: 35px;
  height: 35px;
  min-height: 35px;
  width: 100%;
  position: absolute;
}
.app .main-wrapper {
  position: relative;
  margin-top: 35px;
  height: calc(100% - 35px);
  width: 100%;
  z-index: 1;
}

#session-expiring-modal .modal-content-wrapper {
  margin: 20px;
  clear: both;
  overflow: hidden;
}
#session-expiring-modal .modal-content-wrapper .delete-text-wrapper .continue-text {
  margin-top: 20px;
}
#session-expiring-modal .modal-content-wrapper .actions {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAKE;EACE,2BAAA;EACA,yBAAA;EAIA,iBAAA;AAJJ;;AAQA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AALF;AAOE;EACE,iBAAA;AALJ;AAQE;EACE,gBAxBgB;EAyBhB,YAzBgB;EA0BhB,gBA1BgB;EA2BhB,WAAA;EACA,kBAAA;AANJ;AASE;EACE,kBAAA;EACA,gBAjCgB;EAkChB,yBAAA;EACA,WAAA;EACA,UAAA;AAPJ;;AAYE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AATJ;AAeM;EACE,gBAAA;AAbR;AAiBI;EACE,gBAAA;AAfN","sourcesContent":["\n\n$navigation-height: 35px;\n\n:host ::ng-deep {\n  .no-select {\n    -webkit-touch-callout: none; // iOS Safari\n    -webkit-user-select: none; // Safari\n    -khtml-user-select: none; // Konqueror HTML\n    -moz-user-select: none; // Old versions of Firefox\n    -ms-user-select: none; // Internet Explorer/Edge\n    user-select: none; // Non-prefixed version, currently supported by Chrome, Opera and Firefox\n  }\n}\n\n.app {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  &.blurred {\n    filter: blur(5px);\n  }\n\n  .navigation {\n    max-height: $navigation-height;\n    height: $navigation-height;\n    min-height: $navigation-height;\n    width: 100%;\n    position: absolute;\n  }\n\n  .main-wrapper {\n    position: relative;\n    margin-top: $navigation-height;\n    height: calc(100% - 35px); // $navigation-height\n    width: 100%;\n    z-index: 1;\n  }\n}\n\n#session-expiring-modal {\n  .modal-content-wrapper {\n    margin: 20px;\n    clear: both;\n    overflow: hidden;\n\n    .delete-text-wrapper {\n      .question-text {\n      }\n\n      .continue-text {\n        margin-top: 20px;\n      }\n    }\n\n    .actions {\n      margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
