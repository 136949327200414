import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {STYLE_PROPERTY, CUSTOM_STYLE_PROPERTIES} from './constants';

export class MenuVerticalPaddingModel implements IStyleOption {
  public menu: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.menu = options.block;

    if (!this.menu) return;

    const computed: CSSStyleDeclaration = window.getComputedStyle(this.menu);

    this.value = Number.parseInt(computed.getPropertyValue(CUSTOM_STYLE_PROPERTIES.TOP) || computed.getPropertyValue(STYLE_PROPERTY));
  }

  public onChange(value: string): void {
    if (!this.isEnabled || !this.menu) return;

    this.value = Number.parseInt(value);

    this.menu.style.setProperty(CUSTOM_STYLE_PROPERTIES.TOP, `${value}px`);
    this.menu.style.setProperty(CUSTOM_STYLE_PROPERTIES.BOTTOM, `${value}px`);
  }

  public reset(): void {
    if (!this.isEnabled || !this.element || !this.menu) return;

    this.menu.style.removeProperty(CUSTOM_STYLE_PROPERTIES.TOP);
    this.menu.style.removeProperty(CUSTOM_STYLE_PROPERTIES.BOTTOM);

    const computed = window.getComputedStyle(this.menu);

    this.value = Number.parseInt(computed.getPropertyValue(CUSTOM_STYLE_PROPERTIES.TOP) || computed.getPropertyValue(STYLE_PROPERTY));
  }
}
