import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../modals.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {ImageManagerModalService} from '../../../components/modals/image-manager-modal/image-manager-modal.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';
import {WebsiteTourService} from '../../../../core/services/website-tour/website-tour.service';
import {ImagesCounterService} from '../../../../core/services/image-manager/counters/images-counter.service';

import {NodeModel} from '../../../../core/models/nodes/node.model';
import {ImagesCounterModel} from '../../../../core/models/image-manager/counters/images-counter.model';

import {HANDLERS_KEYS} from './constants';

@Injectable()
export class HomepageImageSelectModalService {
  public readonly id: string = 'homepage-image-select-modal';

  public isOpened: boolean = false;

  private key: string= null;
  private block: HTMLElement = null;

  private readonly blocksSelectors = {
    [HANDLERS_KEYS.IMAGE_12]: `[data-block-template-id="136"]`,
    [HANDLERS_KEYS.PARALLAX_LARGE_SIMPLE]: `[data-block-template-id="110"]`,
  };

  private readonly imageSelectors = {
    [HANDLERS_KEYS.IMAGE_12]: '#image-full-bleed-image[src="./images/default.png"]',
    [HANDLERS_KEYS.PARALLAX_LARGE_SIMPLE]: 'img[src="./images/default.png"]',
  };

  public constructor(
      private modalsService: ModalsService,
      private iFrameService: IFrameService,
      private nodesService: NodesService,
      private imageManagerModalService: ImageManagerModalService,
      private imagesCounterService: ImagesCounterService,
      private eventsService: EventsService,
      private websiteTourService: WebsiteTourService,
    ) {
    this.onBlocksLoaded = this.onBlocksLoaded.bind(this);
  }

  public open(): void {
    this.block = null;

    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    this.imagesCounterService.countersSubject.pipe(takeUntil(unsubscribe)).subscribe((counters: ImagesCounterModel) => {
      if (!counters || !counters.total) {
        return;
      }

      unsubscribe.next(true);
      unsubscribe.complete();
      
      const nOfImages: number = Object.keys(counters.total).reduce((res: number, key: string) => {
        return res + counters.total[key];
      }, 0);

      if (nOfImages === 0) {
        return;
      }

      this.eventsService.addFrameListener('blocksLoaded', this.onBlocksLoaded);
    });
  }

  private onBlocksLoaded(): void {
    const data: { key: string, block: HTMLElement } = this.getData();

    if (!data || !data.block) return;

    this.key = data.key;
    this.block = data.block;

    const isNeedToHandle: boolean = this.isNeedToHandle(data.key);

    if (!isNeedToHandle) {
      this.removeListeners();
      
      return;
    }

    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    this.websiteTourService.isOpened.pipe(takeUntil(unsubscribe)).subscribe((isOpened: boolean) => {
      if (isOpened) return;

      this.modalsService.open(this.id);

      this.isOpened = true;

      unsubscribe.next(true);
      unsubscribe.complete();
    });
  }

  private getData(): { key: string, block: HTMLElement } {
    const blocks: HTMLElement[] = this.iFrameService.blocks;

    const keys: string[] = Object.keys(this.blocksSelectors);

    for (let i = 0; i < keys.length; i++) {
      const block: HTMLElement = blocks.find((block: HTMLElement) => block.matches(this.blocksSelectors[keys[i]]));

      if (!block) continue;

      return {
        key: keys[i],
        block,
      };
    }

    return null;
  }

  public onConfirm(): void {
    if (this.block) {
      this.handle(this.key);
    }

    this.close();
  }

  private isNeedToHandle(key: string): boolean {
    const image: HTMLImageElement = <HTMLImageElement>this.block.querySelector(this.imageSelectors[key]);

    return !!image;
  }

  private handle(key: string): void {
    const image: HTMLImageElement = <HTMLImageElement>this.block.querySelector(this.imageSelectors[key]);

    if (!image) return;

    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    this.nodesService.nodesSubject.pipe(takeUntil(unsubscribe)).subscribe((nodes: NodeModel[]) => {
      if (!nodes || nodes.length === 0) return;

      const portfolio: NodeModel = nodes.find((node: NodeModel) => node.type === 'P');

      if (!portfolio) return;

      unsubscribe.next(true);
      unsubscribe.complete();

      this.imageManagerModalService.onPortfolioSelect.next({ id: portfolio.id, nodeId: portfolio.nodeId });
      this.imageManagerModalService.portfolioInitPrevented.next(true);

      const event: MouseEvent = new MouseEvent('dblclick', {
        'view': window,
        'bubbles': true,
        'cancelable': true,
      });

      image.dispatchEvent(event);

      window.setTimeout(() => {
        this.imageManagerModalService.portfolioInitPrevented.next(false);
      }, 100);
    });
  }

  public close(): void {
    this.isOpened = false;

    this.removeListeners();

    this.modalsService.close(this.id);
  }

  private removeListeners(): void {
    this.eventsService.removeFrameListener('blocksLoaded', this.onBlocksLoaded);
  }
}
