import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {SetupLoaderService} from '../../core/services/loaders/setup/setup-loader.service';

import {AppAnimations} from '../../app-animations';

@Component({
  selector: 'app-setup-introduction',
  templateUrl: 'setup-introduction.component.html',
  styleUrls: ['./setup-introduction.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SetupIntroductionComponent implements OnInit {
  constructor(private router: Router,
              private setupLoaderService: SetupLoaderService) {
  }

  public ngOnInit(): void {
    this.setupLoaderService.hide();
  }

  handleContinueClick() {
    this.router.navigateByUrl('/setup/website-settings');
  }
}
