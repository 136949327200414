import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {STYLE_PROPERTIES, STYLE_PROPERTY_NAME} from './constants';

export class MenuDropdownTextAlignmentModel implements IStyleOption {
  public menu: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.menu = options.block;

    if (!this.menu) return;

    const computed: CSSStyleDeclaration = window.getComputedStyle(this.menu);

    const value: string = computed.getPropertyValue(STYLE_PROPERTIES[this.key]) || computed.getPropertyValue(STYLE_PROPERTY_NAME);

    this.setValue(value.trim());
  }

  public reset() {
    if (!this.isEnabled || !this.element || !this.menu) return;

    this.menu.style.removeProperty(STYLE_PROPERTIES[this.key]);

    this.value = this.menu.style.getPropertyValue(STYLE_PROPERTIES[this.key]) || getComputedStyle(this.menu).getPropertyValue(STYLE_PROPERTY_NAME);
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.menu) return;

    this.value = value;

    this.menu.style.setProperty(STYLE_PROPERTIES[this.key], value);
  }
}
