import {IStyleModelOptions} from '../option/i-style-model-options';

import {BaseFontFamilyModel} from '../base/font-family/base-font-family.model';

import {STYLE_PROPERTY_NAME, USED_FONT_ATTR} from './constants';

export class ThumbDrawerFontFamilyModel extends BaseFontFamilyModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled, STYLE_PROPERTY_NAME, USED_FONT_ATTR);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions): void {
    super.init(options.block);
  }
}
