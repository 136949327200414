import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import { NodesService } from '../../core/services/nodes/nodes.service';
import { NavigationService } from '../navigation.service';

import { NodeModel } from '../../core/models/nodes/node.model';
import { TreeItemModel } from '../../core/models/nodes/tree-item.model';

import { LIBRARY_ID } from '../../application/constants';

@Injectable()
export class TreeService {
  public treeNodesSubject: BehaviorSubject<{
    nodes: TreeItemModel[],
    isImageManager: boolean,
  }> = new BehaviorSubject<{
    nodes: TreeItemModel[],
    isImageManager: boolean,
  }>(null);

  private allNodes: NodeModel[];

  private isImageManager: boolean;

  private get imageManagerNodes(): NodeModel[] {
    if (!this.allNodes) {
      return [];
    }

    return this.allNodes.filter((node: NodeModel) => {
      return node.type === 'P';
    });
  }
  
  constructor(
    private nodesService: NodesService,
    private navigationService: NavigationService,
  ) {
    this.navigationService.isImageManagerSubject.subscribe((value: boolean) => {
      this.isImageManager = value;

      this.handleTreeNodes();
    });
    
    this.nodesService.nodesSubject.subscribe((nodes: NodeModel[]) => {
      this.allNodes = nodes || [];
  
      this.handleTreeNodes();
    });
  }

  private handleTreeNodes(): void {
    const nodes: NodeModel[] = this.currentTreeNodes();

    if (!nodes) {
      this.treeNodesSubject.next({
        nodes: [],
        isImageManager: this.isImageManager,
      });

      return;
    }

    const treeNodes: NodeModel[] = this.wrapNodes(nodes);

    const mappedTreeNodes: TreeItemModel[] = treeNodes.map((node: NodeModel) => {
      return this.mapNode(node);
    });

    if (this.isNodesSameToCurrent(mappedTreeNodes)) {
      return;
    }

    this.treeNodesSubject.next({
      nodes: mappedTreeNodes,
      isImageManager: this.isImageManager,
    });
  }

  private isNodesSameToCurrent(newNodes: TreeItemModel[]): boolean {
    const oldNodes: TreeItemModel[] =this.treeNodesSubject.value ? this.treeNodesSubject.value.nodes : null;

    if (!oldNodes || !newNodes || oldNodes.length !== newNodes.length) {
      return false;
    }

    return newNodes.every((node: TreeItemModel, index: number) => node.isSameTo(oldNodes[index]));
  }

  private currentTreeNodes(): NodeModel[] {
    if (!this.isImageManager) {
      return this.allNodes;
    }

    const libraryNode: NodeModel = new NodeModel(
      LIBRARY_ID,
      LIBRARY_ID,
      0,
      -1,
      null,
      'Library',
      'P',
      'Portfolio',
      null,
      null,
      null,
      null,
      true,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
    );

    return [
      ...this.imageManagerNodes,
      libraryNode,
    ];
  }

  private mapNode(node: NodeModel): TreeItemModel {
    return new TreeItemModel(
      node.title,
      node,
      node.children.map(node => this.mapNode(node)),
      true,
      node.isPageExists,
      node.id !== LIBRARY_ID && node.type !== 'H',
      node.type === 'C',
    );
  }

  private wrapNodes(nodes: NodeModel[]): NodeModel[] {
    const res: NodeModel[] = [];

    for (let i = 0; i < nodes.length; i++) {
      const node: NodeModel = NodeModel.normalize(nodes[i]);

      if (node.type === 'C') {
        const { j, children } = this.getChildren(nodes, i);

        i = j;

        node.children = children;
      }

      res.push(node);
    }

    return res;
  }

  private getChildren(nodes: NodeModel[], i: number): { j: number, children: NodeModel[] } {
    const children: NodeModel[] = [];

    const category: NodeModel = NodeModel.normalize(nodes[i]);

    let j = i + 1;

    if (j >= nodes.length) {
      return {
        children,
        j: j - 1,
      };
    }

    const level: number = nodes[j].nodeLevel;

    if (category.nodeLevel + 1 !== level) {
      return {
        children,
        j: j - 1,
      };
    }

    for (; j < nodes.length && nodes[j].nodeLevel === level; j++) {
      const node: NodeModel = NodeModel.normalize(nodes[j]);

      if (node.type === 'C') {
        const { j: k, children: nodeChildren } = this.getChildren(nodes, j);

        j = k;

        node.children = nodeChildren;
      }

      children.push(node);
    }

    return {
      children,
      j: j - 1,
    };
  }
}
