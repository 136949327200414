import {Component, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';

import {Subject} from 'rxjs';

import {EducationStudentModel} from '../../../../../../core/models/education/students/education-student.model';
import {PortfolioModel} from '../../../../../../core/models/portfolios/portfolio.model';

@Component({
  selector: 'app-student-image-manager-sidebar-item',
  templateUrl: './student-image-manager-sidebar-item.component.html',
  styleUrls: ['./student-image-manager-sidebar-item.component.scss'], 
})
export class StudentImageManagerSidebarItemComponent implements OnDestroy {
  @ViewChild('studentPortfolioWrapper')
  public studentPortfolioWrapper: ElementRef;

  @ViewChild('titleInput')
  public titleInput: ElementRef;

  @Input()
  public portfolio: PortfolioModel;
  
  @Input()
  public isSelected: boolean;

  @Output()
  public clickHandler: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();

  @Output()
  public shareToggleHandler: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();

  @Output()
  public updateHandler: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();

  @Output()
  public deleteHandler: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();

  public student: EducationStudentModel;
  
  public isWebsitesDropdownVisible: boolean = false;

  public isBlockedByAction: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  public selectPortfolio(portfolio: PortfolioModel): void {
    this.clickHandler.emit(portfolio);
  }

  public toggleShare(e: MouseEvent, portfolio: PortfolioModel): void {
    this.stopEvent(e);

    this.shareToggleHandler.emit(portfolio);
  }

  public editPortfolio(e: MouseEvent, portfolio: PortfolioModel): void {
    this.stopEvent(e);

    portfolio.isEditing = true;

    portfolio.initialTitle = portfolio.title;

    this.cdr.detectChanges();

    window.setTimeout(() => {
      const element: HTMLInputElement = this.titleInput.nativeElement;

      element.focus();
      element.select();
    }, 50);
  }

  public onTitleKeyDown(e: KeyboardEvent, portfolio: PortfolioModel): void {
    if (e.key === 'Enter') {
      this.submitEdit(e, portfolio);
      
      return;
    }

    if (e.key === 'Escape') {
      portfolio.isEditing = false;

      portfolio.title = portfolio.initialTitle;
      
      return;
    }
  }

  public removePortfolio(e: MouseEvent, portfolio: PortfolioModel): void {
    this.stopEvent(e);

    this.deleteHandler.emit(portfolio);
  }

  public submitEdit(e: MouseEvent | KeyboardEvent, portfolio: PortfolioModel): void {
    this.stopEvent(e);

    portfolio.isEditing = false;

    this.updateHandler.emit(portfolio);
  }

  public cancelEdit(e: MouseEvent, portfolio: PortfolioModel): void {
    this.stopEvent(e);

    portfolio.isEditing = false;
  }

  private stopEvent(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
