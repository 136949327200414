import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {WebsiteDesignerService} from '../../../application/main/website-designer/website-designer.service';
import {GoogleAnalytics4ApiService} from '../../../core/services/google/apis/ga4/ga-4.service';
import {NavigationService} from '../../../services/navigation.service';
import {WebsitesService} from '../../../core/services/websites/websites.service';

import {WebsiteModel} from '../../../core/models/websites/website.model';

@Component({
  selector: 'app-google-analytics-button',
  templateUrl: './google-analytics-button.component.html',
  styleUrls: ['./google-analytics-button.component.scss'],
})
export class GoogleAnalyticsButtonComponent implements OnInit, OnDestroy {
  public isGoogleAnalyticsEnabled: boolean = false;

  public websiteId: number;

  private href: string = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  
  public get text(): string {
    return this.isGoogleAnalyticsEnabled ? 'Open Analytics' : 'Enable Google Analytics';
  }

  constructor(
    public googleAnalytics4ApiService: GoogleAnalytics4ApiService,
    public websiteDesignerService: WebsiteDesignerService,
    private websitesService: WebsitesService,
    private navigationService: NavigationService,
  ) {
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.isGoogleAnalyticsEnabled = website ? website.isGa4SetupCompleted : false;

      this.initHref(website);
    });
  }

  private initHref(website: WebsiteModel): void {
    if (!this.isGoogleAnalyticsEnabled) {
      this.href = null;
  
      return;
    }

    if (!website.ga4PropertyName) {
      this.href = `https://analytics.google.com/analytics/web/`;
      
      return;
    }

    const propertyId: string = website.ga4PropertyName.split('/')[1];
    
    this.href = `https://analytics.google.com/analytics/web/?#/p${propertyId}`;
  }

  public enableGoogleAnalytics() {
    if (this.isGoogleAnalyticsEnabled) {
      window.open(this.href, '_blank');

      return;
    }

    this.navigationService.toGoogleAnalyticsSetup();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
