import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AdminEducationInstitutionsService} from '../../../../../core/services/admin/education/institutions/admin-education-institutions.service';

import {EducationInstitutionModel} from '../../../../../core/models/education/institutions/education-institution.model';

@Component({
  selector: 'app-admin-education-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss']
})
export class AdminEducationInstitutionsComponent implements OnInit, OnDestroy {
  public list: EducationInstitutionModel[];

  public itemToDelete: EducationInstitutionModel = null;

  public isDetailsView: boolean = false;
  public isEditView: boolean = false;
  public isItemRemoving: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationInstitutionsService) {
    this.service.fetchAll();
  }

  public ngOnInit(): void {
    this.service.institutionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list: EducationInstitutionModel[]) => {
      this.list = list;
    });
  }

  public openInstitution(item: EducationInstitutionModel): void {
    this.service.fetchOne(item.id);

    this.isDetailsView = true;
  }

  public openEditModal(item: EducationInstitutionModel): void {
    this.service.fetchOne(item.id);

    this.isEditView = true;
  }

  public openDeleteModal(item: EducationInstitutionModel): void {
    this.isItemRemoving = true;

    this.itemToDelete = item;
  }

  public onDetailsViewClose(): void {
    this.isDetailsView = false;
  }

  public onEditViewClose(): void {
    this.isEditView = false;
  }

  public onItemDeleteClose(): void {
    this.isItemRemoving = false;

    this.itemToDelete = null;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
