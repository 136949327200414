import {AttributeWithValueModel} from '../base/attribute-with-value/attribute-with-value.model';

import {ATTRIBUTE} from './constants';

export class AudioUrlModel extends AttributeWithValueModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, ATTRIBUTE, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    const audioElement = element.querySelector('audio');

    if (!audioElement) return;

    super.init(audioElement);
  }
}
