export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `אז הגיע הלילה של כוכב השביט הראשון.`,
      `גלים התנפצו אל תוך הערב הכחול.`,
      `הטבע האחד שלי והטבע האחר חלקו זיכרון משותף.`,
    ],
    [KEYS.ALPHABET]: [
      'אבגדהוזחטיכךלמםנןסעפףצץקרשת ₪',
    ],
    [KEYS.PARAGRAPH]: [
      `אראה את פני מאדים, כך או כך, וזו תהיה חוויה נדירה. דומני שהמחזה של הגופים השמיימים מבעד לטלסקופ מדוייק, כמו גם טיול מסביב לעולם, צריכים להיות חלק מחינוך ליברלי.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
