import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {BlockModel} from '../../../models/blocks/block.model';

@Injectable()
export class PortfolioHttpService {
  constructor(private httpClient: HttpClient) {
  }

  public fetchAllWebsitePortfolios({ websiteId, templateId }: { websiteId: number, templateId: number }): Observable<BlockModel[]> {
    const params = new HttpParams().set('templateId', `${templateId}`);

    return this.httpClient.get<BlockModel[]>(`api/websites/${websiteId}/portfolios/all`, { params });
  }

  public fetchIsPortfolioWithTextSlidesExists({ websiteId, templateId }: { websiteId: number, templateId: number }): Observable<{ isPortfolioWithTextSlidesExists: boolean }> {
    const params = new HttpParams().set('templateId', `${templateId}`);

    return this.httpClient.get<{ isPortfolioWithTextSlidesExists: boolean }>(`api/websites/${websiteId}/portfolios/any-with-text-slides`, { params });
  }

  public updateShare({ id, isShared }: { id: number, isShared: boolean }): Observable<any> {
    return this.httpClient.put(`api/portfolios/${id}/share`, { isShared });
  }
}
