import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {CustomSubscriptionsHttpService} from '../../../../core/services/interaction/http/admin/custom-subscriptions/custom-subscriptions-http.service';
import {PlansService} from '../../../../core/services/plans/plans.service';
import {CustomSubscriptionRemoveErrorModalService} from '../../../../shared/services/modals/custom-subscription-remove-error/custom-subscription-remove-error-modal.service';

import {CustomSubscriptionModel} from '../../../../core/models/custom-subscription/custom-subscription.model';
import {CustomSubscriptionDto} from '../../../../core/models/custom-subscription/custom-subscription.dto';
import {PlanModel} from '../../../../core/models/plan/plan.model';

import {ALLOWED_PLANS} from './constants';

@Component({
  selector: 'app-custom-subscriptions',
  templateUrl: './custom-subscriptions.component.html',
  styleUrls: ['./custom-subscriptions.component.scss']
})
export class AdminCustomSubscriptionsComponent implements OnInit, OnDestroy {
  public subscriptions: CustomSubscriptionModel[];
  public plans: PlanModel[];

  public newSubscription: CustomSubscriptionModel = new CustomSubscriptionModel(null, 'Standard');

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpService: CustomSubscriptionsHttpService,
    private plansService: PlansService,
    private customSubscriptionRemoveErrorModalService: CustomSubscriptionRemoveErrorModalService,
  ) {
    this.httpService.getAll();
  }

  public ngOnInit(): void {
    this.httpService.customSubscriptionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscriptions: CustomSubscriptionModel[]) => {
      this.subscriptions = subscriptions ? subscriptions.filter((subscription: CustomSubscriptionModel) => subscription.planId !== 'Educator' && subscription.planId !== 'StudentEdu') : subscriptions;
    });

    this.plansService.plansSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((plans: PlanModel[]) => {
      if (!plans) {
        this.plans = null;

        return;
      }

      this.plans = plans.filter((plan: PlanModel) => {
        return ALLOWED_PLANS.includes(plan.id);
      });
    });
  }

  public onNewSubscriptionCreate(): void {
    this.newSubscription.validate();

    if (!this.newSubscription.isValid) return;

    this.httpService.create(CustomSubscriptionDto.fromModel(this.newSubscription));

    this.newSubscription = new CustomSubscriptionModel(null, 'Standard');
  }

  public onDelete(id: number): void {
    this.httpService.delete(id).pipe(
      catchError(e => {
        console.error(e);

        this.customSubscriptionRemoveErrorModalService.open(e.error);

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.httpService.getAll();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
