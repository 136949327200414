import {AMOUNT_FORMATTERS} from '../intent/constants';

export class UserModel {
  constructor(public id?: string,
              public defaultSource?: string,
              public balance?: number,
              public balanceAmount?: string) {}

  public static normalize(res: UserModel): UserModel {
    if (!res) return null;

    return new UserModel(
      res.id,
      res.defaultSource,
      res.balance,
      AMOUNT_FORMATTERS.usd(res.balance * -1),
    );
  }
}
