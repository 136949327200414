import * as moment from 'moment';

import {DiscountModel} from '../discount/discount.model';
import {RetryPaymentDto} from './retry-payment.dto';
import {RetryPaymentPlanModel} from './retry-payment-plan.model';
import {RetryPaymentLineItemModel} from './retry-payment-line-item.model';
import {RetryPaymentLineItemDto} from './retry-payment-line-item.dto';

import {AMOUNT_FORMATTERS} from './constants';
import {TIME_FORMATTER} from '../constants';
import {DISCOUNT_TYPES_MAPPING} from '../invoice/constants';
import { DISCOUNT_TYPES } from '../../stripe/coupon/constants';

export class RetryPaymentModel {
  public discountText: string = '';
  public discountAmount: string = '';
  public discountAmountValue: number;

  public isNew: boolean;

  constructor(
    public id?: string,
    public accountBalance?: string,
    public amountValue?: number,
    public amount?: string,
    public subtotal?: string,
    public subtotalValue?: number,
    public total?: string,
    public currency?: string,
    public charge?: string,
    public discount?: DiscountModel,
    public description?: string,
    public status?: string,
    public invoiceStatus?: string,
    public created?: string,
    public receiptUrl?: string,
    public invoiceUrl?: string,
    public plan?: RetryPaymentPlanModel,
    public lines?: RetryPaymentLineItemModel[],
  ) {
    this.isNew = this.invoiceStatus === 'open' && !this.charge;

    if (!this.discount || !this.discount.coupon || !this.discount.coupon.metadata) {
      return;
    }

    if (!DISCOUNT_TYPES_MAPPING[this.discount.coupon.metadata.discountType]) {
      return;
    }

    this.discountText = DISCOUNT_TYPES_MAPPING[this.discount.coupon.metadata.discountType](this.discount.coupon);

    this.discountAmountValue = this.getDiscountAmountValue();

    this.discountAmount = `-${AMOUNT_FORMATTERS[this.currency](this.discountAmountValue)}`;
  }

  private getDiscountAmountValue(): number {
    if (this.discount.coupon.metadata.discountType === DISCOUNT_TYPES.AMOUNT && this.discount.coupon.amountOff) {
      return this.discount.coupon.amountOff * 100;
    }

    if (this.discount.coupon.metadata.discountType !== DISCOUNT_TYPES.PERCENT) {
      return 0;
    }

    const totalAmount: number = this.lines.reduce((res: number, item: RetryPaymentLineItemModel) => res + item.amount, 0);

    return totalAmount / 100 * this.discount.coupon.percentOff;
  }

  public static normalize(res: RetryPaymentDto): RetryPaymentModel {
    if (!res) return null;

    const createdAt = moment.unix(res.created);

    return new RetryPaymentModel(
      res.id,
      res.accountBalance && AMOUNT_FORMATTERS[res.currency] ? AMOUNT_FORMATTERS[res.currency](res.accountBalance) : ``,
      res.amount,
      AMOUNT_FORMATTERS[res.currency] ? AMOUNT_FORMATTERS[res.currency](res.amount) : ``,
      AMOUNT_FORMATTERS[res.currency] ? AMOUNT_FORMATTERS[res.currency](res.subtotal) : ``,
      res.subtotal,
      AMOUNT_FORMATTERS[res.currency] ? AMOUNT_FORMATTERS[res.currency](res.total) : ``,
      res.currency,
      res.charge,
      DiscountModel.normalize(res.discount),
      res.description,
      res.status,
      res.invoiceStatus,
      createdAt.format(TIME_FORMATTER),
      res.receiptUrl,
      res.invoiceUrl,
      RetryPaymentPlanModel.normalize(res.plan),
      res.lines ? res.lines.map((item: RetryPaymentLineItemDto) => RetryPaymentLineItemModel.normalize(item)) : [],
    );
  }
}
