import {Component, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject, catchError, throwError} from 'rxjs';

import {WebsiteSubscriptionSelectModalService} from '../../../services/modals/website-subscription-select/website-subscription-select-modal.service';
import {AdminService} from '../../../../application/main/admin-panel/admin.service';

import {Button} from '../../../../common/models/button/button.model';
import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {NestedSelectOption} from '../../../../core/models/select/nested/nested-select-option.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';

@Component({
  selector: 'app-website-subscription-select-modal',
  templateUrl: './website-subscription-select-modal.component.html',
  styleUrls: ['./website-subscription-select-modal.component.scss'],
})
export class WebsiteSubscriptionSelectModalComponent implements OnDestroy {
  public userId: number;
  public website: WebsiteModel;

  public subscriptions: SubscriptionModel[];
  public subscriptionsOptions: NestedSelectOption[];

  public error: string = null;

  public isLoading: boolean = true;

  public header = {
    className: 'warning-header',
  };

  public buttons: Button[] = [
    {
      text: `Submit`,
      className: 'green',
      onClick: this.submit.bind(this),
    },
    {
      text: `Cancel`,
      className: 'red',
      onClick: this.cancel.bind(this),
    },
  ];

  public get id(): string {
    return this.service.id;
  }

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    private service: WebsiteSubscriptionSelectModalService,
    private adminService: AdminService,
  ) {
  }

  public onOpen({ userId, website }: { userId: number, website: WebsiteModel }): void {
    this.isLoading = true;

    this.userId = userId;
    this.website = website;

    this.adminService.getUserSubscriptions(userId).pipe(
      catchError(e => {
        console.error(e);
      
        this.isLoading = false;
        
        return throwError(() => e);
      })
    ).subscribe((subscriptions: SubscriptionModel[]) => {
      this.subscriptions = subscriptions;
  
      this.subscriptionsOptions = this.subscriptions.map((item: SubscriptionModel) => {
        return new NestedSelectOption(
          `[${item.type}] ${item.metadata.planName}`,
          `${item.accountSubscriptionId}`,
        );
      });

      this.subscriptionsOptions.push(new NestedSelectOption(
        `No subscription`,
        `null`,
      ));

      if (website) {
        const selectedOption: NestedSelectOption = this.subscriptionsOptions.find((option: NestedSelectOption) => {
          return option.value === `${website.subscriptionId}`;
        });
  
        if (selectedOption) {
          selectedOption.isSelected = true;
        }
      }

      this.isLoading = false;
    });
  }

  public onSubscriptionSelect(item: SubscriptionModel): void {
  }

  public submit(): void {
    const selectedOption: NestedSelectOption = this.subscriptionsOptions.find((option: NestedSelectOption) => {
      return option.isSelected;
    });

    if (!selectedOption) {
      this.error = `Please, select subscription.`;

      return;
    }

    this.isLoading = true;

    this.adminService.setWebsiteSubscription({
      userId: this.userId,
      websiteId: this.website.id,
      accountSubscriptionId: Number.parseInt(selectedOption.value),
    }).pipe(
      catchError(e => {
        console.error(e);

        this.error = e && e.error ? e.error.message : 'Something went wrong.';
  
        this.isLoading = false;
        
        return throwError(() => e);
      })
    ).subscribe(() => {
      this.isLoading = false;

      this.service.onUpdate.next(true);

      this.cancel();
    });
  }

  public cancel(): void {
    this.service.close();
  }

  public ngOnDestroy(): void {
    this.userId = null;
    this.website = null;

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
