import {Component, OnInit, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';
import {GoogleFontUsageModel} from '../../../../core/models/google/fonts/google-font-usage.model';

@Component({
  selector: 'app-fonts-manager-font-remove-modal',
  templateUrl: './fonts-manager-font-remove-modal.component.html',
  styleUrls: ['./fonts-manager-font-remove-modal.component.scss'],
})
export class FontsManagerFontRemoveModalComponent implements OnInit, OnDestroy {
  public modalHeader: ModalHeader = new ModalHeader('REMOVE FONT');

  public isProcessing: boolean = false;
  public isInitCompleted: boolean = false;
  public isFontUsed: boolean = false;

  public font: GoogleFontModel = null;
  public usageData: GoogleFontUsageModel = null;

  public errorKey: string = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get modalId(): string {
    return this.googleFontsService.removeModalId;
  }

  constructor(private googleFontsService: GoogleFontsService) {
  }

  public ngOnInit(): void {
    this.googleFontsService.fontToRemoveSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((font: GoogleFontModel) => {
      this.font = GoogleFontModel.clone(font);

      this.initIsFontUsed();
    });
  }

  private initIsFontUsed(): void {
    if (!this.font) return;

    this.isInitCompleted = false;

    this.googleFontsService.getIsFontUsed(this.font.family).subscribe((res: GoogleFontUsageModel) => {
      this.usageData = res;

      this.isFontUsed = res && (res.isDefaultWebsiteFont || res.isUsedInBlocks);

      this.isInitCompleted = true;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onConfirm() {
    if (this.googleFontsService.processingFonts[this.font.family]) return;

    this.isProcessing = true;
    this.googleFontsService.processingFonts[this.font.family] = true;

    const item = this.googleFontsService.findFontByFamily(this.font.family);

    return this.googleFontsService.removeFont(this.font.family).subscribe(this.onFontRemoved.bind(this, item), this.onFontRemoveError.bind(this));
  }

  private onFontRemoved(item: GoogleFontModel) {
    const font = this.googleFontsService.fullListSubject.value.find(f => f.family === this.font.family);

    this.isProcessing = false;
    this.googleFontsService.processingFonts[this.font.family] = false;

    this.font.isAdded = false;
    font.isAdded = false;
    item.isAdded = false;

    this.googleFontsService.removeFontLink(item);

    this.googleFontsService.fetchWebsiteData();
    this.googleFontsService.closeRemoveFontModal();

    this.isInitCompleted = false;
  }

  private onFontRemoveError(e: HttpErrorResponse) {
    this.isProcessing = false;
    this.googleFontsService.processingFonts[this.font.family] = false;

    this.font.isAdded = true;

    try {
      const err = JSON.parse(e.error);

      console.error(err);

      this.errorKey = err.key;
    } catch (e) {
      console.error(e);

      this.errorKey = 'SERVER';
    } finally {
    }
  }

  public onClose() {
    this.googleFontsService.closeRemoveFontModal();
  }
}
