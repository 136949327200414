import { Injectable, Input, Directive } from '@angular/core';
import {ModalsService} from '../shared/services/modals/modals.service';
import {Subject} from 'rxjs';

@Directive()
@Injectable()
export class PortfolioPickerModalService {
  @Input() modalId = 'portfolio-picker-modal';

  selectedPortfolioId = new Subject();

  constructor(private modalsService: ModalsService) {}

  show(portfolioBlock?) {
    this.modalsService.open(this.modalId, portfolioBlock);
  }

  close(portfolioId?) {
    if (portfolioId) this.selectedPortfolioId.next(portfolioId);

    this.modalsService.close(this.modalId);
  }
}
