import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {OutdatedBlocksDataModel} from '../../../../models/outdated-blocks-data/outdated-blocks-data.model';

@Injectable()
export class WebsiteBlocksHttpService {
  constructor(private httpClient: HttpClient) {
  }

  public getCurrentPageBlocks({ websiteId, templateId, pageId, pageType }: { websiteId: number, templateId: number, pageId: number, pageType: string }): Observable<any> {
    const params = new HttpParams()
      .set('websiteId', `${websiteId}`)
      .set('templateId', `${templateId}`)
      .set('pageId', `${pageId}`)
      .set('pageType', `${pageType}`);

    return this.httpClient.get(`api/websites/${websiteId}/blocks`, { params });
  }

  public getOutdatedBlocks({ websiteId }: { websiteId: number }): Observable<OutdatedBlocksDataModel> {
    return this.httpClient.get<OutdatedBlocksDataModel>(`api/websites/${websiteId}/blocks/outdated`);
  }

  public getIsWebsiteHasCustomPages({ websiteId, templateId, currentPageId }: { websiteId: number, templateId: number, currentPageId: number }): Observable<{ isWebsiteHasCustomPages: boolean }> {
    const params = new HttpParams()
      .set('websiteId', `${websiteId}`)
      .set('templateId', `${templateId}`)
      .set('currentPageId', `${currentPageId}`);
    
    return this.httpClient.get<{ isWebsiteHasCustomPages: boolean }>(`api/websites/${websiteId}/blocks/has-custom-pages`, { params });
  }

  public saveBlocks({ websiteId, meta, blocks, movedBlocksToAnotherPage }: { websiteId: number, meta: { WebsiteID: number, TemplateID: number, PageID: number, PageType: string }, blocks: any, movedBlocksToAnotherPage: any }): Observable<any> {
    return this.httpClient.put(`api/websites/${websiteId}/blocks`, { meta, blocks, movedBlocksToAnotherPage });
  }
  public deleteOneBlock({ blockId, websiteId, pageId, pageType }: { blockId: number, websiteId: number, pageId: number, pageType: string }): Observable<any> {
    const params = new HttpParams()
      .set('websiteId', `${websiteId}`)
      .set('pageId', `${pageId}`)
      .set('pageType', `${pageType}`);

    return this.httpClient.delete(`api/websites/${websiteId}/blocks/${blockId}`, { params });
  }
}
