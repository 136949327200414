import {Injectable} from '@angular/core';

import {MIME_TO_EXTENSION_MAPPING} from './constants';

@Injectable()
export class MimeTypesService {
  public getExtensionFromMime(mimeType: string): string {
    return MIME_TO_EXTENSION_MAPPING[mimeType];
  }
}
