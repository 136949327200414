import {Component, ElementRef, OnInit, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../auth/auth.service';
import {BlocksDragService} from '../core/services/blocks/drag/blocks-drag.service';
import {GoogleFontsService} from '../core/services/google/fonts/google-fonts.service';
import {WebsitesService} from '../core/services/websites/websites.service';
import {SidebarService} from '../core/services/sidebar/sidebar.service';

import {WebsiteFontsDataModel} from '../core/models/google/fonts/website-fonts-data.model';
import {AccountModel} from '../core/models/accounts/account.model';
import {WebsiteModel} from '../core/models/websites/website.model';
import {TabSettingsModel} from '../core/models/sidebar/tabs-settings.model';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidebar') sidebar;
  @ViewChild('dragTemplate') dragTemplate: ElementRef;

  public account: AccountModel;

  public fullFontsLinks: string[] = [];

  public websiteFontsData: WebsiteFontsDataModel;

  public selectedBlock: any;

  public isDragging: boolean = false;
  public isSetupFinished: boolean = false;

  private isSidebarTabSelected: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(public authService: AuthService,
              public googleFontsService: GoogleFontsService,
              private sidebarService: SidebarService,
              private websitesService: WebsitesService,
              private blocksDragService: BlocksDragService) {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.blocksDragService.onSelectSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.selectedBlock = data;
    });

    this.blocksDragService.isDraggingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isDragging: boolean) => {
      this.isDragging = isDragging;
    });
    this.sidebarService.currentTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tab: TabSettingsModel) => {
      this.isSidebarTabSelected = !!tab;
    });
  }

  ngOnInit() {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.isSetupFinished = website && website.isSetupCompleted;
    });

    this.googleFontsService.fullFontLinksSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((links: string[]) => {
      this.fullFontsLinks = links;
    });

    this.googleFontsService.websiteDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: WebsiteFontsDataModel) => {
      this.websiteFontsData = data;
    });
  }

  ngAfterViewInit(): void {
    this.blocksDragService.dragTemplateSubject.next(this.dragTemplate);
  }

  // TODO: replace with css (flex may not work cause of fixed min width of iFrame container)
  getMainWidth() {
    const width = this.isSidebarTabSelected ? 340 : 60;

    return {'width': `calc(100% - ${width}px)`};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
