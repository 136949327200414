import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss'],
})
export class WarningMessageComponent {
  @Input() text: String;
  @Input() isOpened: boolean;

  @Output() closeHandler = new EventEmitter();

  constructor() { }

  onCloseClick() {
    this.isOpened = false;
    
    this.closeHandler.emit(true);
  }
}
