import {Component, Input, Output, EventEmitter} from '@angular/core';

import {ModalsService} from '../../../services/modals/modals.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';

import * as _ from 'lodash';

@Component({
  selector: 'app-link-input-modal',
  templateUrl: './link-input-modal.component.html',
  styleUrls: ['./link-input-modal.component.scss']
})
export class LinkInputModalComponent {
  @Input() id = 'direct-link-input-modal';

  DIRECT_LINK_PURPOSE_MODAL_TEXT = 'Here you can change the primary purpose of this link. Upon saving, this menu item will now be a direct link to another page or another website.';

  URL_ERROR = {
    INCORRECT_URL: 'Incorrect url',
    URL_IS_NOT_AVAILABLE: 'Url is not available',
  };

  @Input() headerText: string;

  @Output() doSave: EventEmitter<{ websiteId: number, nodeId: number, link: string, isInNewTab: boolean }> = new EventEmitter();
  @Output() doCancel = new EventEmitter();

  public websiteId: number;
  public nodeId: number;
  public link: string;
  public isInNewTab: boolean;

  get modalHeader(): ModalHeader {
    return {
      text: this.headerText,
    };
  }

  debounceValidate = _.debounce(value => this.validateLink(value), 400);
  urlErrorMessage = null;

  constructor(private modalsService: ModalsService) {
  }

  public handleOpen(data: { nodeId: number, websiteId: number, link: string, isInNewTab: boolean }): boolean {
    if (!data) return;

    this.websiteId = data.websiteId;
    this.nodeId = data.nodeId;
    this.link = data.link;
    this.isInNewTab = data.isInNewTab;

    this.setUrlErrorMessage(null);
  }

  saveHandler() {
    this.validateLink(this.link);

    if (this.urlErrorMessage) return;

    const link = this.link.length === 0 ? null : this.link;

    this.doSave.emit({
      websiteId: this.websiteId,
      nodeId: this.nodeId,
      link,
      isInNewTab: this.isInNewTab,
    });

    this.modalsService.close(this.id);
  }

  cancelHandler() {
    this.doCancel.emit();

    this.modalsService.close(this.id);
  }

  handleLinkChange(event) {
    this.debounceValidate(event.target.value);
  }

  public removeLink(): void {
    this.isInNewTab = false;
    this.link = '';

    this.validateLink(this.link);
  }

  private validateLink(link): void {
    if (link.length === 0) return this.setUrlErrorMessage(null);
    if (!this.isUrl(link)) return this.setUrlErrorMessage(this.URL_ERROR.INCORRECT_URL);

    this.setUrlErrorMessage(null);
  }

  private setUrlErrorMessage(value): void {
    this.urlErrorMessage = value;
  }

  private isUrl(str): boolean {
    return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(str);
  }
}
