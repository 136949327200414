import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../../modals.service';

import {ImagesHttpService} from '../../../../../core/services/interaction/http/images/images-http.service';
import {EducationStudentsService} from '../../../../../core/services/education/students/education-students.service';
import {PermissionsService} from '../../../../../core/services/service-permissions/permissions/permissions.service';

import {ImageModel} from '../../../../../core/models/images/image.model';
import {StudentImageReviewModel} from '../../../../../core/models/images/review/student-image-review.model';
import {IPermissionData} from '../../../../../core/models/permission/i-permission-data';

import {PERMISSIONS} from '../../../../../core/services/service-permissions/constants';

@Injectable()
export class ReviewsListModalService {
  public readonly id: string = 'reviews-list-modal';

  public reviewsSubject: BehaviorSubject<StudentImageReviewModel[]> = new BehaviorSubject<StudentImageReviewModel[]>(null);
  
  public imageId: number;
  public imageIndex: number;

  public isOpened: boolean = false;

  private isStudent: boolean;

  constructor(
    private imagesHttpService: ImagesHttpService,
    private educationStudentsService: EducationStudentsService,
    private permissionsService: PermissionsService,
    private modalsService: ModalsService,
  ) {
    this.initPermissions();
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
    });
  }

  public open({ imageId, imageIndex }: { imageId: number, imageIndex: number }): void {
    this.isOpened = true;
    this.imageId = imageId;
    this.imageIndex = imageIndex;

    this.imagesHttpService.fetchImageDetails(this.imageId).subscribe((image: ImageModel) => {
      this.reviewsSubject.next(image ? image.reviews : null);

      if (!image) {
        return;
      }

      this.sendImageReviewAction(image);
    });

    this.modalsService.open(this.id);
  }

  private sendImageReviewAction(image: ImageModel): void {
    if (!this.isStudent) {
      return;
    }

    this.educationStudentsService.sendImageReviewAction(
      image.id,
      image.reviews.map((review: StudentImageReviewModel) => review.id),
      'view',
    ).subscribe(() => {});
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.reviewsSubject.next(null);

    this.modalsService.close(this.id);
  }
}
