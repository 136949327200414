import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {BehaviorSubject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {WebsitesService} from '../websites/websites.service';

import {PostModel} from '../../models/post/post.model';
import {TagModel} from '../../models/post/tag/tag.model';
import {PostDto} from '../../models/post/post.dto';

const LIMIT = 10;

@Injectable()
export class PostsService {
  private prefix: string;

  public postsSubject = new BehaviorSubject<PostModel[]>([]);
  public isPostsLoadedSubject = new BehaviorSubject<boolean>(false);

  private isFullyLoaded: boolean = false;
  private isFetching: boolean = false;

  public get posts() {
    return this.postsSubject.value;
  }

  constructor(private http: HttpClient,
              private websitesService: WebsitesService,
  ) {
    this.websitesService.activeWebsiteIdSubject.subscribe((id: number) => {
      if (!id) return;

      this.prefix = `api/websites/${id}/posts`;

      this.fetch();
    });
  }

  public createPost(data: PostModel): Subscription {
    const post = PostDto.toRequest(data);

    return this.http.post(this.prefix, post).pipe(take(1)).subscribe(() => this.fetch());
  }

  public publish(post: PostModel): Subscription {
    const uri = `${this.prefix}/${post.id}/published`;

    return this.http.post(uri, { isPublished: !post.isPublished }).pipe(take(1)).subscribe(() => this.fetch());
  }

  public deleteOne(postId: number): Subscription {
    return this.http.delete(`${this.prefix}/${postId}`).pipe(take(1)).subscribe(() => this.fetch());
  }

  public updatePost(data: PostModel): Subscription {
    const post = PostDto.toRequest(data);
    
    return this.http.put(`${this.prefix}/${post.Id}`, post).pipe(take(1)).subscribe(() => this.getOne(post.Id));
  }

  public addTags(postId: number, tagsList: string[]): Subscription {
    const tags = tagsList.map(tag => { return { Name: tag }; });

    return this.http.post(`${this.prefix}/${postId}/tags`, { tags }).pipe(take(1)).subscribe(() => this.getOne(postId));
  }

  public deleteTag(postId: number, tag: TagModel): Subscription {
    return this.http.delete(`${this.prefix}/${postId}/tags/${tag.id}`).pipe(take(1)).subscribe(() => this.getOne(postId));
  }

  private fetch(): Subscription {
    this.isPostsLoadedSubject.next(false);

    const params = new HttpParams().set('limit', `${LIMIT}`);

    return this.http.get(this.prefix, { params }).pipe(take(1)).subscribe((res: PostDto[]) => {
      const posts: PostModel[] = res.map(item => PostDto.normalize(item));
      this.postsSubject.next(posts);
      this.isFullyLoaded = posts.length !== LIMIT;
      this.isPostsLoadedSubject.next(true);
    });
  }

  public fetchMore(): Subscription {
    if (this.isFullyLoaded || this.isFetching) {
      return Subscription.EMPTY;
    }

    this.isFetching = true;

    const params = new HttpParams().set('offset', `${this.posts.length}`).set('limit', `${LIMIT}`);

    return this.http.get(this.prefix, { params }).pipe(take(1)).subscribe((res: PostDto[]) => {
      const posts: PostModel[] = res.map(item => PostDto.normalize(item));
      this.postsSubject.next(this.posts.concat(posts));
      this.isFullyLoaded = posts.length !== LIMIT;
      this.isFetching = false;
    });
  }

  public getOne(postId: number): Subscription {
    return this.http.get(`${this.prefix}/${postId}`).pipe(take(1)).subscribe((res: PostDto) => this.renewPost(res));
  }

  private renewPost(res: PostDto) {
    const posts = this.posts;
    const idx = posts.findIndex(post => post.id === res.Id);

    if (idx === -1) return console.error(`Can't update post locally.`);

    posts[idx] = PostDto.normalize(res);

    this.postsSubject.next(posts);
  }

  public getPostData(id: number): PostModel {
    if (!this.posts) return null;

    return this.posts.find(post => post.id === id);
  }
}
