import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PermissionsHttpService {
  private model = 'permissions';

  constructor(private http: HttpClient) {}

  getPermissions() {
    return this.http.get(`/api/app/${this.model}`);
  }
}
