import {AccountModel} from "../../accounts/account.model";

export class EducationInstitutionModel {
  constructor(public id?: number,
              public name?: string,
              public createdBy?: AccountModel,
              public deletedBy?: AccountModel,
              public createdAt?: string,
              public updatedAt?: string,
              public deletedAt?: string) {
  }
}
