import {Injectable} from '@angular/core';
import {groupBy, uniqBy} from 'lodash';

import {BlocksTemplatesHttpService} from '../core/services/interaction/http/admin/blocks-templates/blocks-templates-http.service';
import {ModalsService} from '../shared/services/modals/modals.service';
import {BlocksChangelogsModel} from '../core/models/blocks-changelogs/blocks-changelogs.model';

@Injectable()
export class BlocksChangelogsService {
  public modalId = 'BlocksChangelogsService';

  public blockData: any = { };
  public changelogs: BlocksChangelogsModel[] = [];
  public groupedChangelogs: any;
  public dates: any;

  constructor(
    private blocksTemplatesHttpService: BlocksTemplatesHttpService,
    private modalsService: ModalsService,
  ) {}

  public showChangelog({ blockTemplateId, createdAt }) {
    this.blockData = { blockTemplateId, createdAt };

    this.blocksTemplatesHttpService.getBlockTemplateChangelogs(blockTemplateId, createdAt).subscribe(changelogs => {
      this.changelogs = changelogs;

      this.groupedChangelogs = groupBy(this.changelogs, (changelog) => new Date(changelog.createdAt).toDateString());

      this.dates = Object.keys(this.groupedChangelogs);
  
      this.dates.forEach(key => {
        this.groupedChangelogs[key] = uniqBy(this.groupedChangelogs[key], (changelog: BlocksChangelogsModel) => changelog.message);
      });
  
      this.modalsService.open(this.modalId);
    });
  }
}
