import {EMAIL_REGEXP} from './constants';

export class EducationNewTeacherModel {
  public isFirstNameValid: boolean = true;
  public isLastNameValid: boolean = true;
  public isEmailValid: boolean = true;
  public isRepeatEmailValid: boolean = true;
  public isInstitutionValid: boolean = true;
  public isClassValid: boolean = true;

  public get isValid(): boolean {
    return this.isFirstNameValid
      && this.isLastNameValid
      && this.isEmailValid
      && this.isRepeatEmailValid
      && this.isInstitutionValid
      && this.isClassValid;
  }

  constructor(public firstName: string = '',
              public lastName: string = '',
              public email: string = '',
              public repeatEmail: string = '',
              public institutionsIds: number[] = null,
              public classesIds: number[] = null) {
  }

  public validate(): void {
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();
    this.email = this.email.trim();
    this.repeatEmail = this.repeatEmail.trim();

    this.isFirstNameValid = this.firstName.length > 0;
    this.isLastNameValid = this.lastName.length > 0;
    this.isEmailValid = this.email.length > 0 && EMAIL_REGEXP.test(this.email);
    this.isRepeatEmailValid = this.isEmailValid && this.repeatEmail === this.email;
    this.isInstitutionValid = !!this.institutionsIds && this.institutionsIds.length > 0;
    this.isClassValid = !!this.classesIds && this.classesIds.length > 0;
  }
}
