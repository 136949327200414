import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Observable, Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import 'jqueryui';

import {AppAnimations} from '../../../../../app-animations';

import {PagesService} from "../../../../../application/sidebar-short/sidebar/pages/pages.service";
import {ImageManagerService} from "../../../../../application/main/image-manager/image-manager.service";
import {BagService} from "../../../../../bag.service";
import {WebsiteDesignerService} from "../../../../../application/main/website-designer/website-designer.service";
import {ImageManagerModalService} from "../image-manager-modal.service";
import {NodesService} from '../../../../../core/services/nodes/nodes.service';
import {ImageDetailsService} from '../../../../../core/services/image-manager/image-details/image-details.service';

import {NodeModel} from '../../../../../core/models/nodes/node.model';
import {ImageModel} from '../../../../../core/models/images/image.model';

@Component({
  selector: 'app-portfolio-list',
  templateUrl: './portfolio-list.component.html',
  styleUrls: ['./portfolio-list.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PortfolioListComponent implements OnInit, OnDestroy {
  @Input() isPortfolioBlocked: boolean = false;

  public portfolioId: number;

  public portfolios: NodeModel[] = [];

  public isPageBlocked: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private pagesService: PagesService,
              private nodesService: NodesService,
              private imageManagerService: ImageManagerService,
              private websiteDesigner: WebsiteDesignerService,
              private imageDetailsService: ImageDetailsService,
              private bag: BagService,
              private cdr: ChangeDetectorRef,
              public imageManagerModalService: ImageManagerModalService) {
  }

  public ngOnInit(): void {
    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      if (!nodes) return;

      this.portfolios = nodes.filter(node => node.type === 'P');
    });

    this.imageManagerModalService.onPortfolioSelect.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ id }) => {
      this.portfolioId = id;

      this.cdr.detectChanges();
    });

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPageBlocked: boolean) => {
      this.isPageBlocked = isPageBlocked;

      this.cdr.detectChanges();
    });
  }

  public handleDroppedImages(ui) {
    try {
      const imageIds = this.bag.data.selectedImages.map((im: ImageModel) => im.id);

      const targetId = ui.item.parent().data('portfolioid');

      ui.item.remove();

      this.handleImage(targetId, imageIds).pipe(
        catchError(e => {
          console.error(e);

          return throwError(() => e);
        }),
      ).subscribe(() => {
        this.bag.change.next({ portfoliosChange: true });
      });
    } catch (e) {
      console.error(e);

      throw e;
    }
  }

  private handleImage(targetId, imageIds): Observable<any> {
    const targetNode: NodeModel = targetId === 0 ? new NodeModel(0, 0) : this.portfolios.find(portfolio => portfolio.id === targetId);

    if (!targetNode) {
      return throwError(() => `Can't find target node`);
    }

    const params = {
      nodeid: targetNode.nodeId,
      images: imageIds,
      portfolioId: targetNode.id,
      imageIds,
    };

    return this.bag.data.isMetaKey ? this.imageManagerService.copyImage(params) : this.imageManagerService.moveImage(params);
  }

  public selectPortfolio({ id, nodeId }: { id: number, nodeId: number }) {
    this.imageDetailsService.imageDetailsSubject.next(null);

    this.imageManagerModalService.onPortfolioSelect.next({ id, nodeId });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
