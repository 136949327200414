export const KEYS = {
  BLOG_ADD_COMMENT_BUTTON_BACKGROUND: 'BLOG_ADD_COMMENT_BUTTON_BACKGROUND',
  BLOG_ADD_COMMENT_BUTTON_TEXT_COLOR: 'BLOG_ADD_COMMENT_BUTTON_TEXT_COLOR',
  BLOG_ADD_COMMENT_BUTTON_HOVER_BACKGROUND: 'BLOG_ADD_COMMENT_BUTTON_HOVER_BACKGROUND',
  BLOG_ADD_COMMENT_BUTTON_HOVER_TEXT_COLOR: 'BLOG_ADD_COMMENT_BUTTON_HOVER_TEXT_COLOR',
  BLOG_ADD_COMMENT_BUTTON_BORDER_RADIUS: 'BLOG_ADD_COMMENT_BUTTON_BORDER_RADIUS',
};

export const TYPES = {
  ATTRIBUTE: 'ATTRIBUTE',
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
};
