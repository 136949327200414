import {IStyleModelOptions} from '../option/i-style-model-options';

import {SetupOptionModel} from '../option/setup-option.model';

import {PROPERTY_NAME, STYLE_PROPERTY_NAME, SELECTORS} from './constants';

export class SlideshowTitleTextTransformModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled) return;

    this.isEnabled = !!element;

    if (!this.isEnabled) return;

    this.element = <HTMLElement>(element.closest(SELECTORS.SLIDESHOW_SLIDE) || element.closest(SELECTORS.BLOCK));

    this.value = this.getComputed();
  }

  public reset(): void {
    this.element.style.removeProperty(PROPERTY_NAME);
    this.element.style.removeProperty(STYLE_PROPERTY_NAME);

    this.onChange(null);
  }

  public onChange(value: string): void {
    this.value = value || this.getComputed();

    if (!this.isEnabled) return;

    this.element.style.setProperty(PROPERTY_NAME, this.value);
  }

  private getComputed(): string {
    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    return (computedStyles.getPropertyValue(PROPERTY_NAME) || computedStyles.getPropertyValue(STYLE_PROPERTY_NAME)).trim();
  }
}
