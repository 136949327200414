import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PaymentModel} from '../../../../core/models/payment/payment.model';
import {PaymentService} from '../../../../core/services/payment/payment.service';
import {UpgradeSubscriptionService} from '../../../../core/services/payment/subscriptions/upgrade/upgrade-subscription.service';

import {CardModel} from '../../../../core/models/payment/card/card.model';

@Component({
  selector: 'app-credit-cards-list',
  templateUrl: './credit-cards-list.component.html',
  styleUrls: ['./credit-cards-list.component.scss'],
})
export class CreditCardsListComponent implements OnInit, OnDestroy {
  @Input() isCardSelect: boolean = false;
  @Input() selectedCard: CardModel;

  @Output() cardSelectHandler: EventEmitter<CardModel> = new EventEmitter<CardModel>();

  public paymentData: PaymentModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private paymentService: PaymentService,
              private upgradeSubscriptionService: UpgradeSubscriptionService) {
  }

  public ngOnInit(): void {
    this.paymentService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paymentData: PaymentModel) => {
      this.paymentData = paymentData;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onClick(card: CardModel): void {
    if (!this.isCardSelect) return;

    this.upgradeSubscriptionService.card = card;

    this.cardSelectHandler.emit(card);
  }
}
