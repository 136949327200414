import {Injectable} from '@angular/core';

import { Subject } from 'rxjs';

import {ModalsService} from '../modals.service';

@Injectable()
export class RevertBlockToDefaultModalService {
  public readonly id = 'revert-block-to-default-modal';

  public answerSubject: Subject<boolean> = new Subject();

  public isOpened = false;

  public constructor(private modalsService: ModalsService) {
  }

  public open(data: {
    block: {
      blockId: number,
      websiteId: number,
      pageId: number,
      pageType: string,
    },
  }): void {
    this.modalsService.open(this.id, data);

    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
