// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-replace-invalidation-info-body {
  margin: 20px;
  clear: both;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/image-replace-invalidation-info/image-replace-invalidation-info-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AADF","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.image-replace-invalidation-info-body {\n  margin: 20px;\n  clear: both;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
