import { Component } from '@angular/core';
import { AppAnimations } from './../../app-animations';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class InternalServerErrorComponent {
  constructor() { }
}
