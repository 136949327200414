import {SubscriptionModel} from './subscription.model';
import {SubscriptionsDto} from './subscriptions.dto';
import {SubscriptionDto} from './subscription.dto';

import {STATUSES_KEYS} from './constants';

export class SubscriptionsModel {
  constructor(public hasMore?: boolean,
              public list?: SubscriptionModel[]) {
    if (!this.list) return;

    this.list = this.list.filter((item: SubscriptionModel) => {
      if (!item.metadata || (item.metadata.addOnKey !== 'SUPPORT_INCLUDED_ANNUAL_ONE_HOUR' && item.metadata.addOnKey !== 'SUPPORT_INCLUDED_ANNUAL_TWO_HOURS' && item.metadata.addOnKey !== 'SUPPORT_INCLUDED_DAILY_ONE_HOUR')) return true;

      return item.status !== STATUSES_KEYS.CANCELLED;
    });
  }

  public static normalize(res: SubscriptionsDto): SubscriptionsModel {
    if (!res) return null;

    return new SubscriptionsModel(
      res.hasMore,
      res.list.map(item => SubscriptionDto.normalize(item)),
    );
  }
}
