import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {PortfoliosHttpService} from '../../../interaction/http/portfolios/portfolios-http.service';
import {ImagesHttpService} from '../../../interaction/http/images/images-http.service';
import {LoaderService} from '../../../loaders/loader.service';
import {AuthService} from '../../../../../auth/auth.service';
import {WebsitesService} from '../../../websites/websites.service';

import {WebsiteModel} from '../../../../models/websites/website.model';
import {IsValidImagesSetupModel} from '../../../../models/setup/images/is-valid-images-setup.model';
import {ImageModel} from '../../../../models/images/image.model';

@Injectable()
export class UserSetupImagesService {
  private key = 'UserSetupImagesService';

  private portfolio: any = null;
  private images: ImageModel[] = [];

  public minImagesCount = 0;
  public maxImagesCount = 300;

  public imagesCount: number = 0;
  public uploadedImagesCount: number = 0;

  public isValidSubject: BehaviorSubject<IsValidImagesSetupModel> = new BehaviorSubject<IsValidImagesSetupModel>(null);
  public portfolioSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.portfolio);
  public portfolioIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public imagesSubject: BehaviorSubject<ImageModel[]> = new BehaviorSubject<ImageModel[]>(this.images);

  private isEnoughImages: boolean = false;
  private isImagesLoaded: boolean = false;

  private website: WebsiteModel;

  private get portfolioId(): number {
    return this.portfolio && this.portfolio.NodeKey;
  }

  constructor(private portfoliosHttpService: PortfoliosHttpService,
              private websitesService: WebsitesService,
              private imagesService: ImagesHttpService,
              private loaderService: LoaderService,
              private authService: AuthService) {
    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      const isNewWebsite = website && !website.isSetupCompleted;

      this.website = website;

      if (!isNewWebsite) return;

      this.clear();
      this.init();
    });

    this.authService.onSignOut.subscribe(() => {
      this.clear();
    });
  }

  public init(): void {
    return this.initPortfolio().add(() => {
      const { portfolioId } = this;

      if (!portfolioId) return;

      this.loaderService.show(this.key);

      return this.imagesService.fetchForUser(portfolioId).subscribe((images: ImageModel[]) => {
        this.images = images;

        this.imagesCount = images.length;
        this.uploadedImagesCount = images.length;

        this.imagesSubject.next(this.images);

        this.afterLoad();

        this.loaderService.hide(this.key);
      });
    });
  }

  private initPortfolio(): Subscription {
    return this.portfoliosHttpService.fetchRecent().subscribe((portfolio) => {
      this.portfolio = portfolio;

      this.portfolioSubject.next(this.portfolio);
      this.portfolioIdSubject.next(this.portfolioId);
    });
  }

  public onUploadStarted(count) {
    this.imagesCount = count;

    this.afterLoad();
  }

  public afterUpload() {
    this.uploadedImagesCount++;

    this.afterLoad();

    if (this.isEnoughImages && this.isImagesLoaded) {
      this.portfoliosHttpService.reorder({ id: this.portfolioId }).subscribe(() => {});
    }
  }

  public onDelete(count) {
    this.imagesCount = count;
    this.uploadedImagesCount--;

    this.afterLoad();
  }

  public afterDelete() {
    this.portfoliosHttpService.reorder({ id: this.portfolioId }).subscribe(() => {});
  }

  public clear(): void {
    this.portfolio = null;
    this.images = null;

    this.portfolio = -1;

    this.portfolioSubject.next(this.portfolio);
    this.portfolioIdSubject.next(this.portfolioId);
    this.imagesSubject.next(this.images);
  }

  private afterLoad(): void {
    this.isEnoughImages = this.imagesCount >= this.minImagesCount;
    this.isImagesLoaded = this.uploadedImagesCount >= this.imagesCount;

    this.initIsValidSubject();
  }

  private initIsValidSubject(): void {
    this.isValidSubject.next({
      isEnoughImages: this.isEnoughImages,
      isImagesLoaded: this.isImagesLoaded,
    });
  }
}
