import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {CLASSES, CLASSES_LIST, DEFAULT_KEYS, CLASSES_TO_KEYS} from './constants';

export class MenuStyleModel implements IStyleOption {
  public element: HTMLElement;

  private get classesList(): string[] {
    return CLASSES_LIST[this.key] || [];
  }

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = options.block;

    if (!this.element) return this.clear();

    const className: string = this.classesList.find(className => this.element.classList.contains(className)) || DEFAULT_KEYS[this.key];

    this.setValue(CLASSES_TO_KEYS[this.key][className]);
  }

  public setValue(classKey: string) {
    const className = CLASSES[this.key][classKey];
    this.value = classKey;

    this.removeClasses();

    this.element.classList.add(className);
  }

  private removeClasses() {
    this.classesList.forEach(className => {
      this.element.classList.remove(className);
    });
  }

  private clear() {
    this.value = null;
    this.isEnabled = false;
  }
}
