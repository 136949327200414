import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';

import {Observable, Subscriber, Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {EducationStudentsImagesService} from '../../../../../core/services/education/teachers/institutions/classes/students/images/education-students-images.service';
import {ReviewStudentImageModalService} from '../../../../services/modals/education/review-student-image/review-student-image-modal.service';
import {EducationStudentsImagesReviewsAudiosService} from '../../../../../core/services/education/teachers/institutions/classes/students/images/reviews/audio/education-students-images-reviews-audios.service';
import {AudioService} from '../../../../../core/services/audio/audio.service';
import {ImageManagerService} from '../../../../../application/main/image-manager/image-manager.service';

import {ImageModel} from '../../../../../core/models/images/image.model';
import {EducationStudentPortfolioModel} from '../../../../../core/models/education/portfolios/education-student-portfolio.model';
import {StudentImageReviewModel} from '../../../../../core/models/images/review/student-image-review.model';
import {IRecordedAudioModel} from '../../../../../core/models/base/audio/i-recorded-audio.model';
import {StudentImageReviewAudioModel} from '../../../../../core/models/images/review/audio/student-image-review-audio.model';
import {IBaseAudioModel} from '../../../../../core/models/base/audio/i-audio.model';
import {AudioState} from '../../../../../core/services/audio/constants';
import {IUpdateResponse} from '../../../../../core/models/responses/i-update-response';

@Component({
  selector: 'app-review-student-image-modal',
  templateUrl: './review-student-image-modal.component.html',
  styleUrls: ['./review-student-image-modal.component.scss']
})
export class ReviewStudentImageModalComponent implements OnInit, OnDestroy {
  @Input() id = 'review-student-image';

  public header = {
    className: 'warning-header',
  };

  public image: ImageModel;

  public review: StudentImageReviewModel = null;

  public hoveredStarIndex: number = 0;

  public state: AudioState;

  public error: string = '';
  
  public isLoading: boolean = false;

  private studentPortfolio: EducationStudentPortfolioModel;

  private removedAudios: StudentImageReviewAudioModel[] = [];
  
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: ReviewStudentImageModalService,
    private educationStudentsImagesService: EducationStudentsImagesService,
    private educationStudentsImagesReviewsAudiosService: EducationStudentsImagesReviewsAudiosService,
    private audioService: AudioService,
    private imageManagerService: ImageManagerService,
    private sanitizer: DomSanitizer,
  ) {
  }

  public ngOnInit(): void {
    this.service.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { image: ImageModel, studentPortfolio: EducationStudentPortfolioModel }) => {
      this.image = data ? data.image : null;
      this.studentPortfolio = data ? data.studentPortfolio : null;

      this.initStudentImageData();
    });

    this.educationStudentsImagesService.currentImageReviewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((review: StudentImageReviewModel) => {
      this.review = StudentImageReviewModel.clone(review);

      if (!this.review) {
        this.educationStudentsImagesService.initEmptyReview(this.image ? this.image.id : null);

        return;
      }

      this.review.imageId = this.image ? this.image.id : null;
    });

    this.audioService.stateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: AudioState) => {
      this.state = state;
    });
  }

  private initStudentImageData(): void {
    if (!this.image || !this.studentPortfolio) {
      this.educationStudentsImagesService.initEmptyReview(this.image ? this.image.id : null);

      return;
    }

    this.educationStudentsImagesService.getReview(
      this.studentPortfolio.institutionId,
      this.studentPortfolio.classId,
      this.studentPortfolio.studentId,
      this.studentPortfolio.id,
      this.image.id,
    );

    this.educationStudentsImagesService.getExhibitedImages(
      this.studentPortfolio.institutionId,
      this.studentPortfolio.classId,
      this.studentPortfolio.studentId,
      this.studentPortfolio.id,
      this.image.id,
    );
  }

  public onStarClick(idx: number): void {
    this.review.rate = idx;
  }

  public onStarMouseEnter(idx: number): void {
    this.hoveredStarIndex = idx;
  }

  public onStarMouseLeave(): void {
    this.hoveredStarIndex = 0;
  }

  public onAudioRecorded(recordData: IRecordedAudioModel): void {
    this.review.audios = this.review.audios || [];
    
    this.getAudioModelFromRecord(recordData).subscribe((audio: StudentImageReviewAudioModel) => {
      this.review.audios.push(audio);
    })
  }

  public onAudioRemove(removedAudio: IBaseAudioModel): void {
    this.review.audios = this.review.audios.filter((audio: StudentImageReviewAudioModel) => {
      return audio !== removedAudio;
    });

    if (!removedAudio.id) {
      return;
    }

    this.removedAudios.push(<StudentImageReviewAudioModel>removedAudio);
  }

  private getAudioModelFromRecord(recordData: IRecordedAudioModel): Observable<StudentImageReviewAudioModel> {
    return new Observable((subscriber: Subscriber<StudentImageReviewAudioModel>) => {
      const reader: FileReader = new FileReader();

      reader.onload = () => {
        const audio: StudentImageReviewAudioModel = new StudentImageReviewAudioModel(
          null,
          null,
          null,
          `Record Title`,
          recordData.fileName,
          recordData.mimeType,
          recordData.duration,
          recordData.fileSize,
          <string>this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result),
          null,
          null,
          null
        );

        audio.blob = recordData.blob;

        subscriber.next(audio);
        subscriber.complete();
      };

      reader.readAsDataURL(recordData.blob);
    });
  }

  public onSubmit(): void {
    this.error = '';

    this.audioService.killMediaRecorder();

    if (!this.image || !this.studentPortfolio) {
      this.error = `Can't load image data.`;

      return;
    }

    if (!this.review) {
      this.error = `Something went wrong on init.`;

      return;
    }

    this.isLoading = true;
    
    return this.review.id ? this.updateReview() : this.addReview();
  }

  private updateReview(): void {
    this.educationStudentsImagesReviewsAudiosService.handleAudios(
      this.studentPortfolio.institutionId,
      this.studentPortfolio.classId,
      this.studentPortfolio.studentId,
      this.studentPortfolio.id,
      this.image.id,
      this.removedAudios,
      this.review
    ).pipe(
      catchError(e => {
        console.error(e);

        this.error = e.error && e.error.message ? e.error.message : 'Something went wrong.';
  
        this.isLoading = false;
        
        return throwError(() => e);
      })
    ).subscribe(() => {
      this.educationStudentsImagesService.updateReview(
        this.studentPortfolio.institutionId,
        this.studentPortfolio.classId,
        this.studentPortfolio.studentId,
        this.studentPortfolio.id,
        this.image.id,
        this.review
      ).pipe(
        catchError(e => {
          console.error(e);

          this.error = e.error && e.error.message ? e.error.message : 'Something went wrong.';
  
          this.isLoading = false;
          
          return throwError(() => e);
        })
      ).subscribe((res: IUpdateResponse) => {
        if (res.isDeleted) {
          this.educationStudentsImagesService.imageReviewDeleteSubject.next(this.review);
        }

        this.initStudentImageData();
  
        this.service.close();
      });
    });
  }

  private addReview(): void {
    this.educationStudentsImagesService.addReview(
      this.studentPortfolio.institutionId,
      this.studentPortfolio.classId,
      this.studentPortfolio.studentId,
      this.studentPortfolio.id,
      this.image.id,
      this.review,
    ).pipe(
      catchError(e => {
        console.error(e);

        this.error = e.error && e.error.message ? e.error.message : 'Something went wrong.';

        this.isLoading = false;
        
        return throwError(() => e);
      })
    ).subscribe((review: StudentImageReviewModel) => {
      this.review.id = review.id;

      this.educationStudentsImagesReviewsAudiosService.handleAudios(
        this.studentPortfolio.institutionId,
        this.studentPortfolio.classId,
        this.studentPortfolio.studentId,
        this.studentPortfolio.id,
        this.image.id,
        [],
        this.review,
        true
      ).pipe(
        catchError(e => {
          console.error(e);

          this.error = e.error && e.error.message ? e.error.message : 'Something went wrong.';
  
          this.isLoading = false;
          
          return throwError(() => e);
        })
      ).subscribe(() => {
        this.initStudentImageData();
        
        this.service.close();
      });
    });
  }

  public openUpdateSuggestions(review: StudentImageReviewModel): void {
    this.imageManagerService.viewSubject.next({
      key: 'full-view',
      forceIndex: this.service.imageIndex,
      forceSuggestionsView: {
        userId: review.reviewedByUserId,
      },
    });

    this.onCancel();
  }

  public onCancel(): void {
    this.audioService.killMediaRecorder();

    this.service.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}

