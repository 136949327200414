
import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {RequestInterceptorService} from '../../../services/interaction/requests/interceptor/request-interceptor.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private handlers = {
    401: () => {
      this.isLogout = true;

      this.requestInterceptorService.onForceLogoutSubject.next(true);
    },
    403: (err: HttpErrorResponse) => {
      if (!err.error) {
        return;
      }

      if (err.error.key === 'COURSE_ENDED') {
        this.requestInterceptorService.onCourseEndedSubject.next(err.error);
      }
    },
    504: () => {
      this.requestInterceptorService.onUnhandledRequestSubject.next(true);
    },
  };

  private isLogout: boolean = false;

  constructor(
    private requestInterceptorService: RequestInterceptorService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isLogout) {
      return of(null);
    }
    
    return next.handle(request).pipe(
      catchError((res: HttpErrorResponse) => {
        if (!(res instanceof HttpErrorResponse) || !this.handlers[res.status]) {
          console.error(res);
          
          return throwError(() => res);
        }

        this.handlers[res.status](res);

        return throwError(() => res);
      }),
    );
  }
}
