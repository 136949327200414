// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-customer-filter {
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.admin-customer-filter .field-wrapper {
  margin: 5px;
  min-width: 100px;
}
.admin-customer-filter .field-wrapper input {
  max-height: 30px;
}
.admin-customer-filter .field-wrapper label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 3px;
}
.admin-customer-filter .submit-button {
  margin: auto 0 4px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/admin-panel/customers/customer-filter/customer-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,gBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,iBAAA;EACA,qBAAA;EACA,kBAAA;AAAN;AAIE;EACE,uBAAA;AAFJ","sourcesContent":[".admin-customer-filter {\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n\n  .field-wrapper {\n    margin: 5px;\n    min-width: 100px;\n\n    input {\n      max-height: 30px;\n    }\n\n    label {\n      font-weight: bold;\n      display: inline-block;\n      margin-bottom: 3px;\n    }\n  }\n\n  .submit-button {\n    margin: auto 0 4px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
