import {IStyleModelOptions} from '../../option/i-style-model-options';

import {SetupOptionModel} from '../../option/setup-option.model';
import {ColorModel} from '../../../color/color.model';

export class BaseColorRgbModel extends SetupOptionModel {
  public element: HTMLElement;
  public selectedElement: HTMLElement;
  public defaultValueHolderElement: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public propertyName: string,
    public stylePropertyName: string,
    public rgbStylePropertyName: string,
    public value?: string,
    public isEnabled: boolean = true,
    public isPropertyNameShouldBeSet: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.isEnabled = this.isEnabled && !!element;

    if (!this.isEnabled) return;

    this.element = element;
    this.selectedElement = options && options.selectedElement ? options.selectedElement : null;
    this.defaultValueHolderElement = options && options.defaultValueHolderElement ? options.defaultValueHolderElement : null;

    this.value = this.getDefaultValue();
  }

  public reset(): void {
    if (this.propertyName) this.element.style.removeProperty(this.propertyName);
    if (this.stylePropertyName) this.element.style.removeProperty(this.stylePropertyName);
    if (this.rgbStylePropertyName) this.element.style.removeProperty(this.rgbStylePropertyName);

    const computed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.onChange(computed.getPropertyValue(this.stylePropertyName) || computed.getPropertyValue(this.propertyName));
  }

  public onChange(value: string): void {
    this.value = value || this.getDefaultValue();

    if (!this.isEnabled) return;

    if (this.isPropertyNameShouldBeSet && this.propertyName) this.element.style.setProperty(this.propertyName, this.value);

    if (this.stylePropertyName) this.element.style.setProperty(this.stylePropertyName, this.value);

    if (!this.rgbStylePropertyName) return;

    const colorModel: ColorModel = new ColorModel(this.value);

    this.element.style.setProperty(this.rgbStylePropertyName, colorModel.getRGBstring());
  }

  private getDefaultValue(): string {
    const computed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: string = computed.getPropertyValue(this.stylePropertyName) || computed.getPropertyValue(this.propertyName);

    if (!this.defaultValueHolderElement) return value || '';

    const defaultComputed: CSSStyleDeclaration = window.getComputedStyle(this.defaultValueHolderElement);

    return value || defaultComputed.getPropertyValue(this.stylePropertyName) || defaultComputed.getPropertyValue(this.propertyName);
  }
}
