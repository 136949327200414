export class NodeMoveDto {
  constructor(
    public id?: number,
    public nodeId?: number,
    public type?: string,
    public oldLevel?: number,
    public oldSequence?: number,
    public newLevel?: number,
    public newSequence?: number,
    public title?: string,
    public groupType?: string,
  ) {}
}
