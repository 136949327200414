
import {Component, Input, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';

import {throwError as observableThrowError, Observable, Subject, Subscriber, throwError} from 'rxjs';
import {takeUntil, catchError, finalize} from 'rxjs/operators';

import {AppAnimations} from '../../../../app-animations';

import {AudioManagerService} from '../../../../core/services/audio-manager/audio-manager.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {CustomStylesService} from '../../../../core/services/styles/custom/custom-styles.service';
import {MessageModalService} from '../../../../services/message-modal.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';
import {ObjectRemoveErrorModalService} from '../../../services/modals/object-remove-error/object-remove-error-modal.service';

import {AudioModel} from '../../../../core/models/audio/audio.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';
import {ObjectRemoveErrorDto} from '../../../../core/models/errors/objects/remove/object-remove-error.dto';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';
import {ERRORS_KEYS} from '../../../../shared/services/errors/constants';
import {KEYS} from '../../modals/object-remove-error/constants';

@Component({
  selector: 'app-audio-manager',
  templateUrl: './audio-manager.component.html',
  styleUrls: ['./audio-manager.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class AudioManagerComponent implements OnInit, OnDestroy {
  @Input() isModal = false;

  public permissions: IPermissionData[] = [
    {
      type: 'permission',
      value: PERMISSIONS.MEDIA_MANAGER_AUDIO_TAB_ACCESSIBILITY,
    },
  ];

  public isNewDragging: boolean = false;
  public isEnlarged: boolean = false;
  public isLoading: boolean = true;

  public audios: AudioModel[] = null;
  public uploadingAudios: AudioModel[] = [];
  public selectedAudios: AudioModel[] = [];
  public firstClickedAudio: AudioModel;

  public audioUri: SafeResourceUrl = null;

  public views = {
    list: {
      key: 'list',
      icon: 'fa-th',
    },
    enlarged: {
      key: 'enlarged',
      icon: 'fa-stop',
    },
  };

  public currentView = this.views.list.key;

  public editableAudioId: number = null;

  private keyDownEvent = this.onKeyDown.bind(this);

  private errorsMapping = {
    [ERRORS_KEYS.OBJECTS_USED]: this.onObjectsUsed.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isEmpty(): boolean {
    return this.audios && this.audios.length === 0;
  }

  public get modalId(): string {
    return this.service.modalId;
  }

  public get nOfAudios() {
    return this.audios ? this.audios.length : 0;
  }

  public get nOfSelectedAudios() {
    return this.selectedAudios ? this.selectedAudios.length : 0;
  }

  public get mime(): string {
    return this.service.mime;
  }

  constructor(private service: AudioManagerService,
              private eventsService: EventsService,
              private utilsService: UtilsService,
              private iFrameService: IFrameService,
              private buttonsService: ButtonsService,
              private objectRemoveErrorModalService: ObjectRemoveErrorModalService,
              private messageModalService: MessageModalService,
              private customStylesService: CustomStylesService,
              private cdr: ChangeDetectorRef,
              private sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    this.service.loadAudios();

    this.service.isLoadingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;

      this.cdr.detectChanges();
    });

    this.service.audiosSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((audios: AudioModel[]) => {
      this.audios = audios ? [...audios, ...(this.uploadingAudios ? this.uploadingAudios : [])] : [];

      this.cdr.detectChanges();
    });

    this.service.uploadAudiosSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((files: File[]) => {
      this.uploadFiles(files);
    });
  }

  public onAudioClick(e: MouseEvent, audio: AudioModel) {
    const { firstClickedAudio, audios } = this;
    const isNewSelected = !e.shiftKey || !firstClickedAudio || firstClickedAudio === audio;

    audios.forEach(audio => {
      audio.isSelected = false;

      if (isNewSelected) audio.isFirstClicked = false;
    });

    if (isNewSelected) return this.selectOneItem(audio);

    return this.selectManyItems(audios, audio);
  }

  private selectOneItem(audio: AudioModel) {
    audio.isFirstClicked = true;
    audio.isSelected = !audio.isSelected;
    this.firstClickedAudio = audio;

    this.initSelectedAudios();
  }

  private selectManyItems(audios: AudioModel[], audio: AudioModel) {
    const selectedIdx = audios.findIndex(v => v === this.firstClickedAudio);
    const clickedAudioIdx = audios.findIndex(v => v === audio);

    const startIdx = selectedIdx < clickedAudioIdx ? selectedIdx : clickedAudioIdx;
    const endIdx = selectedIdx > clickedAudioIdx ? selectedIdx : clickedAudioIdx;

    for (let i = startIdx; i <= endIdx; i++) audios[i].isSelected = true;

    this.initSelectedAudios();
  }

  public onDragEnter() {
    this.isNewDragging = true;
  }

  public onDragLeave() {
    this.isNewDragging = false;
  }

  public onDrop(files: File[]) {
    this.isNewDragging = false;

    return files.forEach(file => {
      this.addMock(file).subscribe((audio: AudioModel) => {
        this.uploadFile(file, audio).subscribe(res => this.initAudio(audio, res));
      });
    });
  }

  public onUpload(ev) {
    const files: File[] = Array.from(<File[]>ev.target.files);

    this.uploadFiles(files);

    ev.target.value = null;
  }

  private uploadFiles(files: File[], ev?): void {
    if (!files) return;

    files.forEach(file => {
      this.addMock(file).subscribe((audio: AudioModel) => {
        this.uploadFile(file, audio).pipe(
          catchError(err => {
            return throwError(() => err);
          }),
          finalize(() => {
            if (ev && ev.target) {
              ev.target.value = null;
            }
          })
        ).subscribe((res: AudioModel) => {
          this.initAudio(audio, res);

          this.uploadingAudios = this.uploadingAudios.filter(uploadingAudio => uploadingAudio !== audio);
        });
      });
    });
  }

  private uploadFile(file: File, audio: AudioModel) {
    return this.service.upload(file, audio).pipe(catchError(e => {
      this.audios = this.audios.filter((a: AudioModel) => a !== audio);

      this.showUploadingError(e);

      return observableThrowError(() => e);
    }));
  }

  private showUploadingError(e: HttpErrorResponse): void {
    try {
      const header: ModalHeader = new ModalHeader('Error', 'warning-header');
      const buttons: Button[] = [new Button('OK', null, 'neutral ok-button', this.onModalClose.bind(this))];
      const message: string = e.error ? e.error.message : 'Server error';

      this.messageModalService.addMessage(message, header, buttons);
    } catch (e) {
      console.error(e);
    }
  }

  private onModalClose(): void {
    this.messageModalService.close();
  }

  private initAudio(audio: AudioModel, res: AudioModel) {
    audio.id = res.id;
    audio.userId = res.userId;
    audio.fileName = res.fileName;
    audio.originalFileName = res.originalFileName;
    audio.link = res.link;
    audio.isUploading = false;
  }

  private addMock(file: File): Observable<AudioModel> {
    return new Observable((observer: Subscriber<AudioModel>) => {
      const audioElement: HTMLAudioElement = document.createElement('audio');

      const reader: FileReader = new FileReader();

      reader.onload = () => {
        audioElement.src = <string>reader.result;

        audioElement.addEventListener('loadedmetadata', () => {
          const audio: AudioModel = this.getMock(file, audioElement);

          this.audios.push(audio);
          this.uploadingAudios.push(audio);

          observer.next(audio);
          observer.complete();
        }, false);
      };

      reader.readAsDataURL(file);
    });
  }

  private getMock(file: File, audioElement: HTMLAudioElement): AudioModel {
    return new AudioModel(
      void 0,
      void 0,
      void 0,
      file.name,
      `${Math.round(audioElement.duration)}`,
      `${file.size}`,
      '',
      false,
      false,
      true,
    );
  }

  public selectAll() {
    this.audios.forEach(audio => audio.isSelected = true);

    this.initSelectedAudios();
  }

  public deselectAll() {
    this.audios.forEach(audio => audio.isSelected = false);

    this.initSelectedAudios();
  }

  private initSelectedAudios() {
    this.selectedAudios = this.audios.filter(audio => audio.isSelected);
  }

  // async ok
  public async onDelete() {
    const { selectedAudios } = this;
    const ids = selectedAudios.map(audio => audio.id);

    if (ids.length === 0) return;

    const isCurrentPageGood: boolean = await this.isCurrentPageGoodBeforeDelete({
      key: KEYS.AUDIO,
      objects: selectedAudios,
    });

    if (!isCurrentPageGood) {
      const err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } } = {
        key: 'CURRENT_PAGE_ERROR',
        message: null,
        data: {
          key: KEYS.AUDIO,
          usedObjectsData: null,
        },
      };
      
      this.objectRemoveErrorModalService.open(err);

      return;
    }

    selectedAudios.forEach(audio => {
      audio.isUploading = true;
    });

    const onSuccess = () => {
      this.audios = this.audios.filter(audio => !selectedAudios.includes(audio));

      this.initSelectedAudios();
    };

    const onError = (e: HttpErrorResponse) => {
      console.dir(e);

      if (this.errorsMapping[e.error.key]) {
        this.errorsMapping[e.error.key](e.error);
      }

      selectedAudios.forEach(audio => {
        audio.isUploading = false;
      });

      return throwError(() => e);
    };

    this.service.deleteMany(ids).pipe(
      catchError(onError)
    ).subscribe(onSuccess);
  }

  // async ok
  private async isCurrentPageGoodBeforeDelete(data: { key, objects: AudioModel[] }): Promise<boolean> {
    if (!this.isModal) return true;

    return new Promise<boolean>((resolve, reject) => {
      this.eventsService.addFrameListener('objectsDataByObjectsId', (e: CustomEvent) => {
        try {
          const { objects } = e.detail;

          resolve(objects.length === 0);
        } catch (e) {
          resolve(false);
        }
      }, { once: true });

      this.eventsService.dispatchRequestObjectsDataByObjectsId(data, this.iFrameService.sandboxWindow);
    });
  }

  public onSelect(audio: AudioModel): void {
    const data = {
      link: audio.link,
    };

    this.customStylesService.setup.audioUrl.onChange(audio.link);
    this.customStylesService.setup.audioUrl.element.setAttribute('data-is-audio-set', 'true');

    this.eventsService.dispatchAudioSelect(data, this.iFrameService.sandboxWindow);
    
    this.onClose();

    this.buttonsService.enableSaveButton();
  }

  public onLinkCopy(document: AudioModel): Promise<void> {
    return this.utilsService.copyToClipboard(document.link);
  }

  public onCancelUpload(audio: AudioModel): void {
    if (!audio.isUploading) return;

    this.audios = this.audios.filter((a: AudioModel) => a !== audio);

    this.service.cancelUploading(audio);
  }

  public onClose() {
    this.service.close();
    this.audios = [];
    this.selectedAudios = [];
    this.firstClickedAudio = null;
  }

  public onViewChange(key) {
    if (key === this.views.list.key) {
      this.currentView = key;

      return;
    }

    if (!this.firstClickedAudio) return;

    this.currentView = key;

    this.cdr.detectChanges();

    this.enlarge(this.firstClickedAudio);
  }

  public onPlayClick(e: MouseEvent, audio: AudioModel): void {
    if (e.shiftKey) return;

    this.enlarge(audio);
  }

  public enlarge(audio: AudioModel) {
    this.isEnlarged = true;

    this.audioUri = this.sanitizer.bypassSecurityTrustResourceUrl(audio.link);

    this.addEnlargementHandlers();

    this.cdr.detectChanges();
  }

  private addEnlargementHandlers(): void {
    window.addEventListener('keydown', this.keyDownEvent);
  }

  private onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode !== 27) return;

    this.closeEnlargement();

    this.removeEnlargementHandlers();
  }

  private removeEnlargementHandlers(): void {
    window.removeEventListener('keydown', this.keyDownEvent);
  }

  public closeEnlargement() {
    if (!this.isEnlarged) return;

    this.currentView = this.views.list.key;

    this.isEnlarged = false;

    this.audioUri = null;

    this.cdr.detectChanges();
  }

  public editName(event, audio: AudioModel): void {
    event.stopPropagation();

    const input = event.target.nextElementSibling;

    this.editableAudioId = audio.id;

    input.value = audio.originalFileName;

    setTimeout(() => input.focus());
  }

  public updateName(event): void {
    const input = event.target;

    if (!input.value || !this.editableAudioId) return;

    const editableAudio = this.audios.find(audio => audio.id === this.editableAudioId);

    if (!editableAudio || input.value === editableAudio.originalFileName) {
      this.editableAudioId = null;

      return;
    }

    this.service.updateName(this.editableAudioId, input.value).pipe(
      catchError(e => {
        input.value = editableAudio.originalFileName;

        this.editableAudioId = null;

        return throwError(() => e);
      })
    ).subscribe(() => {
      editableAudio.originalFileName = input.value;
      this.editableAudioId = null;
    });
  }

  private onObjectsUsed(err: { key: string, message: string, data: { key: string, usedObjectsData: { [key: string]: ObjectRemoveErrorDto[] } } }): void {
    this.objectRemoveErrorModalService.open(err);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.service.close();

    this.removeEnlargementHandlers();
  }
}
