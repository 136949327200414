import {Component, ElementRef, HostListener, Input} from '@angular/core';

@Component({
  selector: 'app-exclamation-icon',
  templateUrl: './exclamation-icon.component.html',
  styleUrls: ['./exclamation-icon.component.scss']
})
export class ExclamationIconComponent {
  @Input() tooltip: string;
  @Input() title: string;
  @Input() maxWidth: number;
  @Input() width: number;
  @Input() textX: number;
  @Input() isLeft: boolean = false;

  constructor(private elementRef: ElementRef) {}

  public onMouseOver() {
    this.elementRef.nativeElement.style['z-index'] = 99999;
  }

  public onMouseLeave() {
    this.elementRef.nativeElement.style['z-index'] = '';
  }
}
