import {IStyleModelOptions} from '../../option/i-style-model-options';

import {AttributeBoolModel} from '../../base/attribute-bool/attribute-bool.model';

import {ATTRIBUTE} from './constants';

export class BlogIsCommentsEnabledModel extends AttributeBoolModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, ATTRIBUTE, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.isEnabled = !!element;

    if (!this.isEnabled || !options.block) return;

    super.init(options.block);
  }

  public onChange(value: boolean): void {
    if (!this.isEnabled || !this.element) return;

    super.onChange(value);
  }
}
