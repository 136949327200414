import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

import {take} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';

import {Button} from '../../../../common/models/button/button.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  private modalHeader: ModalHeader = { text: 'VISUALSERVER X' };

  public defaultHeader: string = 'VISUALSERVER X';

  @Input() id: string;
  @Input() buttons: Button[];

  @Input() isWithCloseIcon = false;
  @Input() isHeaderShown = true;
  @Input() canBeClosedByBackgroundClick: boolean = true;
  @Input() initiatesClose: boolean = true;

  @Output() openHandler = new EventEmitter();
  @Output() cancelHandler = new EventEmitter();

  get headerClassName(): string {
    if (this.header.className) return this.header.className;

    if (this.header.text === this.defaultHeader) return 'warning-header';

    return '';
  }

  get isButtonsExists() {
    return !!this.buttons && this.buttons.length > 0;
  }

  get header() {
    return this.modalHeader;
  }

  @Input()
  set header(value: any) {
    let res = value;
    if (!value || typeof value === 'string') {
      res = {text: value || this.defaultHeader};
    } else if (!value.text) {
      res = Object.assign({}, value, {text: this.defaultHeader});
    }
    this.modalHeader = res;
  }

  constructor(private modalsService: ModalsService) {}

  ngOnInit() {
    if (!this.id) return console.error('Modal must have an id!');

    this.modalsService.statusSubject.pipe(take(1)).subscribe(modalsStatus => {
      if (!modalsStatus[this.id]) return;

      this.open(modalsStatus[this.id].data);
    });
  }

  public onClick(e: MouseEvent): void {
    e.stopPropagation();

    if (!this.canBeClosedByBackgroundClick) {
      return;
    }

    const target: HTMLElement = <HTMLElement>e.target;

    if (!target || !target.classList.contains('modal-background')) {
      return;
    }

    this.handleClose();
  }

  open(data?: any) {
    this.openHandler.emit(data);
  }

  handleClose() {
    this.cancelHandler.emit();

    if (!this.initiatesClose) return;

    this.modalsService.close(this.id);
  }
}
