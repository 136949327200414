import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/custom-menu/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/custom-mobile-menu/constants';

export const KEYS = {
  FLAT: 'FLAT',
  INDENTED: 'INDENTED',
  EXPANDED_FLAT: 'EXPANDED_FLAT',
  EXPANDED_INDENTED: 'EXPANDED_INDENTED',
};

export const CLASSES = {
  [DESKTOP_KEYS.MENU_STYLE]: {
    [KEYS.FLAT]: 'menu-flat-style',
    [KEYS.INDENTED]: 'menu-indented-style',
    [KEYS.EXPANDED_FLAT]: 'menu-expanded-flat-style',
    [KEYS.EXPANDED_INDENTED]: 'menu-expanded-indented-style',
  },
  [MOBILE_KEYS.MOBILE_MENU_STYLE]: {
    [KEYS.FLAT]: 'mobile-menu-flat-style',
    [KEYS.INDENTED]: 'mobile-menu-indented-style',
    [KEYS.EXPANDED_FLAT]: 'mobile-menu-expanded-flat-style',
    [KEYS.EXPANDED_INDENTED]: 'mobile-menu-expanded-indented-style',
  },
};

export const CLASSES_TO_KEYS = Object.keys(CLASSES).reduce((res, key) => {
  res[key] = Object.keys(CLASSES[key]).reduce((resInner, keyInner) => {
    resInner[CLASSES[key][keyInner]] = keyInner;
    return resInner;
  }, {});

  return res;
}, {});

export const DEFAULT_KEYS = {
  [DESKTOP_KEYS.MENU_STYLE]: CLASSES[DESKTOP_KEYS.MENU_STYLE][KEYS.EXPANDED_INDENTED],
  [MOBILE_KEYS.MOBILE_MENU_STYLE]: CLASSES[MOBILE_KEYS.MOBILE_MENU_STYLE][KEYS.EXPANDED_INDENTED],
};

export const CLASSES_LIST = Object.keys(CLASSES).reduce((res, key) => {
  res[key] = Object.values(CLASSES[key]);
  return res;
}, {});

export const OPTIONS = [
  {
    key: KEYS.FLAT,
    text: 'Flat',
  },
  {
    key: KEYS.INDENTED,
    text: 'Indented',
  },
  {
    key: KEYS.EXPANDED_FLAT,
    text: 'Expanded Flat',
  },
  {
    key: KEYS.EXPANDED_INDENTED,
    text: 'Expanded Indented',
  },
];
