import {Injectable} from '@angular/core';

import {ButtonsService} from '../buttons/buttons.service';
import {EventsService} from '../interaction/events/events.service';
import {StylesDimensionsService} from './dimensions/styles-dimensions.service';
import {IFrameService} from '../iframe/iframe.service';

import {TYPES, STYLE_KEYS, DEFAULT_BORDER_WIDTH, TYPES as ELEM_TYPES} from './constants';
import {SelectOption} from '../../models/select/option/option.model';

@Injectable()
export class StylesService {
  private element: HTMLElement = null;

  public stylePanel: any = {
    element: {
      styles: {
        'font-family': '',
        'font-weight': '',
        'font-size': '',
        'color': '',
        'background-color': '',
        'text-align': '',
        'text-transform': '',
        'letter-spacing': '',
        'word-spacing': '',
        'line-height': '',
        'text-decoration': '',
        'padding-top': '',
        'padding-right': '',
        'padding-bottom': '',
        'padding-left': '',
        'border-width': '',
        'border-style': '',
        'border-color': '',
        'border-radius': '',
      },
    },
    divider: {},
    block: {}
  };

  public borderSelectOptions: SelectOption[] = this.DEFAULT_BORDER_SELECT_OPTIONS;

  private styleHandlers = {
    [TYPES.ELEMENT]: this.onElementStylesChange.bind(this),
    [TYPES.DIVIDER]: this.onDividerStylesChange.bind(this),
  };

  private specialHandlers = {
    [STYLE_KEYS.BORDERS.STYLE]: this.initBorder.bind(this),
    [STYLE_KEYS.LINE_HEIGHT]: this.setLineHeight.bind(this),
  };

  get DEFAULT_BORDER_SELECT_OPTIONS(): SelectOption[] {
    return [
      new SelectOption('Top', 'border-top', true),
      new SelectOption('Right', 'border-right', true),
      new SelectOption('Bottom', 'border-bottom', true),
      new SelectOption('Left', 'border-left', true),
    ];
  }

  constructor(private eventsService: EventsService,
              private stylesDimensionsService: StylesDimensionsService,
              private iFrameService: IFrameService,
              public buttonsService: ButtonsService) {
  }

  public init(element?) {
    this.element = element;
  }

  public onStyleChange(key, styleName?, styleValue?) {
    if (!this.element) return;

    const isStyleSet = styleName !== void 0 && styleValue !== void 0;
    const oldValue = this.stylePanel[key].styles[styleName];

    if (isStyleSet) {
      this.styleHandlers[key](styleName, styleValue);
      this.eventsService.dispatchStyleChangedEvent({ key, target: this.element, styleName, styleValue }, this.iFrameService.sandboxWindow);
    }

    this.buttonsService.enableSaveButton();

    if (!this.specialHandlers[styleName]) return;

    this.specialHandlers[styleName]({ newValue: styleValue, oldValue });
  }

  public onBordersSelect(options: SelectOption[]): void {
    this.borderSelectOptions.forEach(border => {
      const currentOption = options.find(option => option.value === border.value);

      if (!currentOption) return this.onElementStyleChange(border.value, 'none');

      border.isSelected = currentOption && currentOption.isSelected;

      this.onElementStyleChange(border.value, this.stylePanel.element.styles[STYLE_KEYS.BORDERS.STYLE]);
    });

    this.onElementStyleChange(STYLE_KEYS.BORDERS.WIDTH, this.stylePanel.element.styles[STYLE_KEYS.BORDERS.WIDTH]);

    this.initBorderColor();
  }

  public onElementStyleChange(styleName: string, styleValue: string) {
    this.onStyleChange(ELEM_TYPES.ELEMENT, styleName, styleValue);
  }

  private onDividerStylesChange(styleName: string, styleValue: string) {
    this.stylePanel[TYPES.DIVIDER].styles[styleName].value = styleValue;

    if (!this.element) return;

    this.stylePanel[TYPES.DIVIDER].styles.apply();
  }

  private initBorder({ newValue, oldValue }) {
    const { styles } = this.stylePanel.element;

    if (newValue === 'none') return;

    const oldWidth = styles[STYLE_KEYS.BORDERS.WIDTH];

    if (oldWidth === '0px' || oldWidth === 0) this.initBorderWidth();

    if (oldValue === 'none') {
      this.initBorderPosition();
      this.onBordersSelect(this.borderSelectOptions);
    }

    this.initBorderColor();
  }

  public initBorderWidth() {
    this.onElementStylesChange(STYLE_KEYS.BORDERS.WIDTH, DEFAULT_BORDER_WIDTH);
  }

  public initBorderPosition() {
    this.borderSelectOptions.forEach(option => {
      option.isSelected = true;
    });
  }

  public initBorderColor() {
    this.onElementStylesChange(STYLE_KEYS.BORDERS.COLOR, this.stylePanel.element.styles[STYLE_KEYS.BORDERS.COLOR]);
  }

  public setLineHeight({ newValue }) {
    if (!this.element) return;

    this.element.style.setProperty('--line-height-value', `${newValue}px`);
  }

  public resetBorderColor() {
    this.onElementStylesChange(STYLE_KEYS.BORDERS.COLOR, this.stylePanel.element.styles[STYLE_KEYS.COLOR]);
  }

  private onElementStylesChange(styleName: string, styleValue: string) {
    const value = this.stylesDimensionsService.toStyleString(styleName, styleValue);

    this.stylePanel[TYPES.ELEMENT].styles[styleName] = styleValue;

    if (!this.element) return;

    if (styleName !== 'border-style') return this.element.style.setProperty(styleName, value);

    this.borderSelectOptions.forEach(option => {
      if (!option.isSelected) return;

      this.element.style[option.value] = styleValue;
    });

    this.element.style['border-width'] = this.stylePanel[TYPES.ELEMENT].styles['border-width'];
  }

  public remove(key: string) {
    if (!this.element) return;

    this.element.style.removeProperty(key);
  }
}
