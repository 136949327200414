import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable, Subscription, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {EducationStudentsHttpService} from '../../interaction/http/education/students/education-students-http.service';

import {ImageUpdateSuggestionDto} from '../../../models/education/image-update-suggestion/image-update-suggestion.dto';
import {ImageUpdateSuggestionModel} from '../../../models/education/image-update-suggestion/image-update-suggestion.model';
import {EducationStudentModel} from '../../../models/education/students/education-student.model';

import {StudentImageAction, StudentImageReviewAction, StudentImageReviewAudioAction, StudentImageUpdateAction} from './constants';

@Injectable()
export class EducationStudentsService {
  public studentDetailsSubject: BehaviorSubject<EducationStudentModel> = new BehaviorSubject<EducationStudentModel>(null);

  constructor(
    private httpService: EducationStudentsHttpService,
  ) {
  }

  public fetchCurrent(websiteId: number): Subscription {
    return this.httpService.getCurrent(websiteId).pipe(
      catchError(e => {
        console.error(e);

        this.studentDetailsSubject.next(null);
        
        return throwError(() => e);
      })
    ).subscribe((res: EducationStudentModel) => {
      this.studentDetailsSubject.next(res);
    });
  }

  public sendAction(action: string, userId: number, websiteId: number, portfolioId: number): Subscription {
    return this.httpService.sendAction(action, userId, websiteId, portfolioId).subscribe(() => {});
  }

  public sendImageAction(portfolioId: number, imageId: number, action: StudentImageAction, file: Blob): Observable<any> {
    return this.httpService.sendImageAction(portfolioId, imageId, action, file);
  }

  public sendImageReviewAction(imageId: number, reviewsIds: number[], action: StudentImageReviewAction): Observable<any> {
    return this.httpService.sendImageReviewAction(imageId, reviewsIds, action);
  }

  public sendImageReviewAudioAction(imageId: number, audioId: number, action: StudentImageReviewAudioAction): Observable<any> {
    return this.httpService.sendImageReviewAudioAction(imageId, audioId, action);
  }

  public sendManyImageUpdateSuggestionsAction(imageId: number, updateSuggestionsIds: number[], action: StudentImageUpdateAction): Observable<any> {
    return this.httpService.sendManyImageUpdateSuggestionsAction(imageId, updateSuggestionsIds, action);
  }

  public getUpdateSuggestionsForImage(imageId: number): Observable<ImageUpdateSuggestionModel[]> {
    return this.httpService.getUpdateSuggestionsForImage(imageId).pipe(
      map((res: ImageUpdateSuggestionDto[]) => res ? res.map((item: ImageUpdateSuggestionDto) => ImageUpdateSuggestionDto.normalize(item)) : null)
    )
  }
}
