
export const CONTROL_TYPE = {
  COLOR: 'COLOR',
  SLIDER: 'SLIDER',
  FONT: 'FONT',
  SELECT: 'SELECT',
  TEXT: 'TEXT',
  WEIGHT: 'WEIGHT',
  ICON: 'ICON',
};

export const SELECTORS = {
  WEBSITE_BACKGROUND_COLOR: 'body, [data-style-backgroundcolor]',
};


