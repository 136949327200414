import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-slider-with-value-on-thumb',
  templateUrl: './slider-with-value-on-thumb.component.html',
  styleUrls: ['./slider-with-value-on-thumb.component.scss']
})
export class SliderWithValueOnThumbComponent implements OnChanges {
  @Input() max: number = 100;
  @Input() min: number = 0;
  @Input() thumbDividedBy: number = 1;
  @Input() isValueVisible: boolean = true;
  @Input() stepSetup: [{ min: number, max: number, step: number }] = null;

  @Output() mouseDownHandler = new EventEmitter();
  @Output() mouseUpHandler = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  public leftOffset: number = 0;
  public isActive: boolean = false;
  public text: any = 0;

  private _value: number = 0;

  @Input()
  public get value() {
    return this._value;
  }
  public set value(val: any) {
    this._value = Number.parseFloat(val) || 0;
    
    this.text = this.isValueVisible ? this.roundedValue : '';

    this.leftOffset = this.getLeftOffset();
  }

  private get roundedValue(): number {
    if (!this.isValueVisible) {
      return 0;
    }

    const textValue: number = Number.parseFloat((this._value / this.thumbDividedBy).toFixed(2));
    
    return textValue >= 100 ? Math.round(textValue) : textValue;
  }

  public get step(): number {
    if (!this.stepSetup) return 1;

    const value: number = this.value;

    const range: { min: number, max: number, step: number } = this.stepSetup.find((item: { min: number, max: number, step: number }) => {
      return item.min <= value && value <= item.max;
    });

    return range ? range.step : 1;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['max']) {
      if (changes['max'].previousValue && changes['max'].currentValue === changes['max'].previousValue) return;

      this.leftOffset = this.getLeftOffset();
    }
  }

  public handleChange(value) {
    this.value = value;

    this.valueChange.emit(value);

    this.cdr.detectChanges();
  }

  public onMouseDown(e: MouseEvent): void {
    this.isActive = true;

    this.mouseDownHandler.emit(e);
  }

  public onMouseUp(e: MouseEvent): void {
    this.isActive = false;

    this.mouseUpHandler.emit(e);
  }

  private getLeftOffset(): number {
    const value = this.value === null ? this.max / 2 : this.value;

    return Math.round((value - this.min) / (this.max - this.min) * 82);
  }
}

