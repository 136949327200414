import {IStyleOption} from '../option/i-style-option';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {DEFAULT_VALUE, OPACITY_PROPERTY} from './constants';

export class ThumbOverlayBackgroundTransparencyModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !options.block) return;

    this.element = options.block;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const propertyValue = Number.parseFloat(computedStyles.getPropertyValue(OPACITY_PROPERTY)) * 100;
    const value = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;

    if (value) return this.setValue(Number.isNaN(value) ? void 0 : value.toString());

    this.value = DEFAULT_VALUE;
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(OPACITY_PROPERTY);

    const value: number = Number.parseFloat(window.getComputedStyle(this.element).getPropertyValue(OPACITY_PROPERTY)) * 100;

    this.setValue(`${Number.isNaN(value) ? DEFAULT_VALUE : 100 - value}`);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(OPACITY_PROPERTY, (1 - Number.parseFloat(value) / 100).toString());
  }
}
