import {Component} from '@angular/core';

import {ClassEndedModalService} from '../../../services/modals/class-ended/class-ended-modal.service';

import {Button} from '../../../../common/models/button/button.model';
import {CourseWebsiteModel} from '../../../../core/models/websites/course-website.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-class-ended-modal',
  templateUrl: './class-ended-modal.component.html',
  styleUrls: ['./class-ended-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ClassEndedModalComponent {
  public website: CourseWebsiteModel;

  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.close.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: ClassEndedModalService,
  ) {
  }

  public onOpen(data: { website: CourseWebsiteModel }): void {
    this.website = data.website;
  }

  public close(): void {
    this.service.close();
  }
}
