import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';

import {SocketsService} from '../../../interaction/sockets/sockets.service';

import {ISocketImageManagerMessageDataModel} from '../../../../models/sockets/message/image-manager/i-image-manager-message-data.model';
import {ImageManagerActionDataModel} from '../../../../models/education/image-manager-action/image-manager-action-data.model';

import {SOCKET_ACTIONS} from '../../../../../application/main/image-manager/constants';

@Injectable()
export class EducationImageManagerActionService {
  public dataSubject: BehaviorSubject<{ [key: number]: ImageManagerActionDataModel }> = new BehaviorSubject<{ [key: number]: ImageManagerActionDataModel }>({});
  
  public onStopSubject: Subject<ImageManagerActionDataModel> = new Subject<ImageManagerActionDataModel>();

  // key - portfolioId
  private data: { [key: number]: ImageManagerActionDataModel } = {};

  private socketMessageHandlers = {
    [SOCKET_ACTIONS.IMAGE_UPLOAD_START]: this.onActionStart.bind(this),
    [SOCKET_ACTIONS.IMAGE_UPLOAD_STOP]: this.onActionStop.bind(this),

    [SOCKET_ACTIONS.IMAGE_REORDER_START]: this.onActionStart.bind(this),
    [SOCKET_ACTIONS.IMAGE_REORDER_STOP]: this.onActionStop.bind(this),

    [SOCKET_ACTIONS.IMAGE_REPLACE_START]: this.onActionStart.bind(this),
    [SOCKET_ACTIONS.IMAGE_REPLACE_STOP]: this.onActionStop.bind(this),

    [SOCKET_ACTIONS.IMAGE_CROP_START]: this.onActionStart.bind(this),
    [SOCKET_ACTIONS.IMAGE_CROP_STOP]: this.onActionStop.bind(this),

    [SOCKET_ACTIONS.IMAGE_UNCROP_START]: this.onActionStart.bind(this),
    [SOCKET_ACTIONS.IMAGE_UNCROP_STOP]: this.onActionStop.bind(this),

    [SOCKET_ACTIONS.IMAGE_DELETE_START]: this.onActionStart.bind(this),
    [SOCKET_ACTIONS.IMAGE_DELETE_STOP]: this.onActionStop.bind(this),
  };

  constructor(private socketsService: SocketsService) {
    this.socketsService.imageManagerDataSubject.subscribe((data: ISocketImageManagerMessageDataModel) => {
      if (!data || !this.socketMessageHandlers[data.action]) {
        return;
      }
  
      this.socketMessageHandlers[data.action](data);
    });
  }

  private onActionStart(data: ISocketImageManagerMessageDataModel): void {
    this.data[data.portfolioId] = new ImageManagerActionDataModel(
      data.userId,
      data.websiteId,
      data.portfolioId,
      data.action,
      data.timestamp,
    );

    this.notify();
  }

  private onActionStop(data: ISocketImageManagerMessageDataModel): void {
    if (!this.data[data.portfolioId] || this.data[data.portfolioId].startedAt < data.timestamp) {
      this.onStopSubject.next(this.data[data.portfolioId]);

      this.data[data.portfolioId] = null;
    }

    this.notify();
  }

  private notify(): void {
    this.dataSubject.next(this.data);
  }
}
