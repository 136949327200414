import {Component, Output, EventEmitter} from "@angular/core";

import {CustomerFilterOptions} from "./customer-filter-options";

@Component({
  selector: 'app-admin-customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.scss'],
})
export class AdminCustomerFilterComponent {
  @Output() doSearch = new EventEmitter();

  public filterOptions: CustomerFilterOptions = new CustomerFilterOptions();

  public onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode !== 13) return;

    this.handleSearch();
  }

  public handleSearch(): void {
    this.doSearch.emit(this.filterOptions.value);
  }
}
