import {Component} from '@angular/core';
import {AppAnimations} from '../../../../app-animations';

// Old component. Not used now. (Could be used in future)
@Component({
  selector: 'app-ecommerce-settings',
  templateUrl: './ecommerce-settings.component.html',
  styleUrls: ['./ecommerce-settings.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class EcommerceSettingsComponent {
  sections = {
    ecommerce: 'ecommerce',
    purchase: 'purchase'
  };

  activeSection: string;

  constructor() { }

  selectSection(section) {
    if (this.activeSection === section) {
      this.activeSection = null;
    } else {
      this.activeSection = section;
    }
  }
}
