import {BaseScalableModel} from '../../../../base/scalable/base-scalable.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX} from './constants';

export class PortfolioEnlargementTitleFontSizeModel extends BaseScalableModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, value, isEnabled);
  }
}
