export class BlockCategoryDto {
  constructor(public ID?: number,
              public Folder?: string,
              public Name?: string,
              public Order?: number,
              public IsEnabled?: boolean,
              public IsSingle?: boolean) {}

  public static normalize(res: BlockCategoryDto): BlockCategoryDto {
    if (!res) return null;

    return new BlockCategoryDto(
      res.ID,
      res.Folder,
      res.Name,
      res.Order,
      res.IsEnabled,
      res.IsSingle,
    );
  }
}
