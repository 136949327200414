export const COLORS = {
  PRIMARY_GREEN: '#ddfec6',
  PRIMARY_YELLOW: '#f7f3c9',
  PRIMARY_RED: '#ffc8c8',
  BACKGROUND: '#d8d8d8',
  SHADOW: '#b9b9b9',
};

export const PLUGINS = {
  SHADOW: {
    beforeDraw: (chart) => {
      const { ctx } = chart;

      ctx.shadowColor = COLORS.SHADOW;

      ctx.shadowBlur = 4;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 4;
    },
  },
};
