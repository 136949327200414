export class EducationNewClassModel {
  public isInstitutionValid: boolean = true;
  public isNameValid: boolean = true;
  public isNumberValid: boolean = true;

  public get isValid(): boolean {
    return this.isInstitutionValid
      && this.isNameValid
      && this.isNumberValid;
  }

  constructor(public id: number = void 0,
              public institutionId: number = null,
              public name: string = '',
              public number: string = '',
              public signUpCode: string = '',
              public description: string = '',
              public startDate: string = '',
              public endDate: string = '') {
  }

  public validate(): void {
    this.name = this.name.trim();
    this.number = this.number.trim();
    this.signUpCode = this.signUpCode.trim();

    this.isInstitutionValid = !!this.institutionId && this.institutionId > 0;
    this.isNameValid = this.name.length > 0;
    this.isNumberValid = this.number.length > 0;
  }
}
