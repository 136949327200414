import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../modals.service';

@Injectable()
export class TourCloseModalService {
  public readonly id = 'tour-close-modal';

  public skipCurrentTour: Subject<boolean> = new Subject<boolean>();
  public skipAllTours: Subject<boolean> = new Subject<boolean>();

  public isOpened: boolean = false;

  public constructor(private modalsService: ModalsService) {
  }

  public open(): void {
    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
