import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../../../services/modals/modals.service';
import {SetImageModalService} from '../../../services/modals/set-image/set-image-modal.service';

@Injectable()
export class ImageManagerModalService {
  public isLibrary: boolean = false;

  public onPortfolioSelect: BehaviorSubject<{ id: number, nodeId: number }> = new BehaviorSubject<{ id: number, nodeId: number }>({ id: null, nodeId: null });
  public blockedPortfolioId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public portfolioInitPrevented: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isRandomizedSubject: BehaviorSubject<{ element: HTMLElement, block: HTMLElement, isRandomizable: boolean, isRandomized: boolean }> = new BehaviorSubject<{ element: HTMLElement, block: HTMLElement, isRandomizable: boolean, isRandomized: boolean }>(null);

  constructor(private setImageModalService: SetImageModalService, private modalsService: ModalsService) {}

  public open(force: boolean = false): void {
    if (!force && this.setImageModalService.isSplash) return this.setImageModalService.open();

    this.modalsService.open('image-manager-modal');
  }

  public close(): void {
    this.modalsService.close('image-manager-modal');
  }
}
