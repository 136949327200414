import {ImageModel} from "../../../core/models/images/image.model";

export class ImageEditorImageUpdateModel {
  public get startX(): number {
    return this.image.widthPx / 100 * this.cropStartX;
  }
  
  public get startY(): number {
    return this.image.heightPx / 100 * this.cropStartY;
  }

  public get width(): number {
    if (!this.image) {
      return 0;
    }

    return this.image.widthPx / 100 * (this.cropEndX - this.cropStartX);
  }

  public get height(): number {
    if (!this.image) {
      return 0;
    }

    return this.image.heightPx / 100 * (this.cropEndY - this.cropStartY);
  }

  constructor(
    public image?: ImageModel,
    public rotate?: number,
    public cropStartX?: number,
    public cropStartY?: number,
    public cropEndX?: number,
    public cropEndY?: number,
  ) {}
}
