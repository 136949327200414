import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {SidebarSectionsService} from '../../../services/sidebar-sections.service';
import {AuthService} from '../../../auth/auth.service';
import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {NavigationService} from '../../../services/navigation.service';
import {NodesService} from '../../../core/services/nodes/nodes.service';
import {AddBlogModalService} from '../../../shared/services/modals/add-blog/add-blog-modal.service';
import {SocketsService} from '../../../core/services/interaction/sockets/sockets.service';
import {SidebarService} from '../../../core/services/sidebar/sidebar.service';
import {WebsiteTourService} from '../../../core/services/website-tour/website-tour.service';
import {UtilsService} from '../../../core/services/utils/utils.service';
import {PreLaunchChecklistService} from '../../../core/services/pre-launch-checklist/pre-launch-checklist.service';
import {WebsitesService} from '../../../core/services/websites/websites.service';
import {WebsitesIconChangeWebsiteModalService} from '../../../shared/services/modals/websites-icon-change-website/websites-icon-change-website-modal.service';
import {PaymentSubscriptionsService} from '../../../core/services/payment/subscriptions/payment-subscriptions.service';

import {CategoryModel} from '../categories.model';
import {AccountModel} from '../../../core/models/accounts/account.model';
import {NodeModel} from '../../../core/models/nodes/node.model';
import {IBlogUnapprovedCommentsCountModel} from '../../../core/models/sockets/message/blog-unapproved-comments-count/i-blog-unapproved-comments-count.model';
import {TabSettingsModel} from '../../../core/models/sidebar/tabs-settings.model';
import {PreLaunchProgressItemModel} from '../../../core/models/pre-launch-progress/item/pre-launch-progress-item.model';
import {IPermissionData} from '../../../core/models/permission/i-permission-data';
import {SubscriptionModel} from '../../../core/models/payment/subscriptions/subscription.model';

import {TOUR_KEYS, TOUR_DATA} from './constants';
import {Sections} from '../constants';
import {PERMISSIONS} from '../../../core/services/service-permissions/constants';
import {KEYS as WEBSITE_TOUR_KEYS} from '../../../core/services/website-tour/constants';
import {ACCESS_KEYS, KEYS} from '../../../core/services/sidebar/constants';

@Component({
  selector: 'app-sidebar-icon',
  templateUrl: './sidebar-icon.component.html',
  styleUrls: ['./sidebar-icon.component.scss']
})
export class SidebarIconComponent implements OnInit, OnChanges, OnDestroy {
  @Input() category: CategoryModel;
  @Input() isDisabled: boolean;

  public blogUnapprovedCommentsCount: number = 0;
  public preLaunchChecklistCount: number = 0;

  public tourGroupKey: string = null;
  public tourKey: string = null;
  public tourLocation: string = null;

  public isRenderAllowed: boolean = false;
  public isPermitted: boolean = false;
  public isSubscriptionValid: boolean = false;
  public isUserHasBlog: boolean = false;
  public isBlog: boolean = false;
  public isWebsite: boolean = false;
  public isDashboard: boolean = false;
  public isSectionActive: boolean = false;
  public isImageManagerIcon: boolean = false;
  public isCustomizerIcon: boolean = false;
  public isCustomizerCollapsed: boolean = true;
  public isBlocksIcon: boolean = false;
  public isActiveOrange: boolean = false;

  private account: AccountModel;
  private currentSubscription: SubscriptionModel;

  private tourIconHandlers = {
    [TOUR_KEYS.IMAGE_MANAGER]: null,
    [TOUR_KEYS.CUSTOMIZER]: null,
    [TOUR_KEYS.BLOCKS]: null,
  };

  private tourIconVisibilityHandlers = {
    [TOUR_KEYS.IMAGE_MANAGER]: () => this.isImageManagerIcon,
    [TOUR_KEYS.CUSTOMIZER]: () => this.isCustomizerIcon,
    [TOUR_KEYS.BLOCKS]: () => this.isBlocksIcon,
  };

  private tourIconActiveHandlers = {
    [TOUR_KEYS.IMAGE_MANAGER]: () => this.isSectionActive,
    [TOUR_KEYS.CUSTOMIZER]: () => !this.isCustomizerCollapsed,
    [TOUR_KEYS.BLOCKS]: () => this.isSectionActive,
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get KEYS() {
    return KEYS;
  }

  constructor(
    public sidebarSectionsService: SidebarSectionsService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private nodesService: NodesService,
    private websitesService: WebsitesService,
    private socketsService: SocketsService,
    private websiteTourService: WebsiteTourService,
    private sidebarService: SidebarService,
    private utilsService: UtilsService,
    private preLaunchChecklistService: PreLaunchChecklistService,
    private websitesIconChangeWebsiteModalService: WebsitesIconChangeWebsiteModalService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private addBlogModalService: AddBlogModalService) {
      this.tourIconHandlers[TOUR_KEYS.IMAGE_MANAGER] = this.handleIconForTour.bind(this, TOUR_KEYS.IMAGE_MANAGER);
      this.tourIconHandlers[TOUR_KEYS.CUSTOMIZER] = this.utilsService.debounce(this.handleIconForTour.bind(this, TOUR_KEYS.CUSTOMIZER), 500);
      this.tourIconHandlers[TOUR_KEYS.BLOCKS] = this.handleIconForTour.bind(this, TOUR_KEYS.BLOCKS);
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;

      this.isSubscriptionValid = !this.category.isSubscriptionRequired || !this.authService.isSubscriptionExpired;

      this.isUserHasPermissionsForRender().pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
        this.isRenderAllowed = isAllowed;
      });
    });

    this.permissionsService.isUserHasPermissionsObservable(this.category.requiredPermissions, { isForbiddenForAdmins: this.category.isForbiddenForAdmins }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isPermitted = isAllowed;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      if (!nodes) return;

      this.isUserHasBlog = nodes.findIndex((node: NodeModel) => node.type === 'B') !== -1;
    });

    this.socketsService.blogUnapprovedCommentsCountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((countData: IBlogUnapprovedCommentsCountModel[]) => {
      this.initBlogUnapprovedCommentsData(countData);
    });

    this.preLaunchChecklistService.itemsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((items: PreLaunchProgressItemModel[]) => {
      this.initPreLaunchChecklistCount(items);
    });

    this.sidebarService.currentTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tab: TabSettingsModel) => {
      this.handleTabChange(tab);
    });

    this.sidebarSectionsService.isCustomizerCollapsedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCollapsed: boolean) => {
      this.isCustomizerCollapsed = isCollapsed;

      if (isCollapsed) return;

      this.handleTours();
    });
    
    this.sidebarService.accessKeySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((accessKey: string) => {
      this.isActiveOrange = accessKey === ACCESS_KEYS.STUDENT || accessKey === ACCESS_KEYS.EDUCATOR;
    });
  }

  private initBlogUnapprovedCommentsData(countData: IBlogUnapprovedCommentsCountModel[]): void {
    if (!countData) {
      this.blogUnapprovedCommentsCount = 0;

      return;
    }

    this.blogUnapprovedCommentsCount = countData.reduce((res, curr) => res + curr.count, 0);
  }

  private initPreLaunchChecklistCount(items: PreLaunchProgressItemModel[]): void {
    if (!items) {
      this.preLaunchChecklistCount = 0;

      return;
    }

    this.preLaunchChecklistCount = items.reduce((res, curr: PreLaunchProgressItemModel) => res + (curr.isCompleted ? 0 : 1), 0);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['category'] && changes['category'].currentValue) {
      this.isBlog = changes['category'].currentValue.section === Sections.Blog;
      this.isWebsite = changes['category'].currentValue.section === Sections.Website;
      this.isDashboard = changes['category'].currentValue.section === Sections.Dashboard;
      this.isImageManagerIcon = changes['category'].currentValue.section === Sections.Images;
      this.isCustomizerIcon = changes['category'].currentValue.section === Sections.Customizer;
      this.isBlocksIcon = changes['category'].currentValue.section === Sections.Blocks;
    }
  }

  private handleTabChange(tab: TabSettingsModel): void {
    if (!tab) return;

    this.isSectionActive = this.category.section === tab.key;

    this.handleTours();
  }

  private handleTours(): void {
    this.tourIconHandlers[TOUR_KEYS.IMAGE_MANAGER]();
    this.tourIconHandlers[TOUR_KEYS.CUSTOMIZER]();
    this.tourIconHandlers[TOUR_KEYS.BLOCKS]();
  }

  private handleIconForTour(key: string): void {
    if (!this.tourIconVisibilityHandlers[key] || !this.tourIconVisibilityHandlers[key]()) return;

    this.websiteTourService.removeVisibleLocation(key);

    this.websiteTourService.removeVisibleItem(TOUR_DATA[key].TOUR_KEY);

    if (!this.tourIconActiveHandlers[key]()) return;

    this.tourGroupKey = TOUR_DATA[key].TOUR_GROUP_KEY;
    this.tourKey = TOUR_DATA[key].TOUR_KEY;
    this.tourLocation = TOUR_DATA[key].TOUR_LOCATION;

    this.websiteTourService.addVisibleLocation(key);

    this.websiteTourService.addVisibleItem(TOUR_DATA[key].TOUR_KEY);
  }

  public isUserHasPermissionsForRender(): Observable<boolean> {
    const isAdminPermissionsRequired: boolean = this.category.requiredPermissions && !!this.category.requiredPermissions.find((item: IPermissionData) => {
      return item.type === 'permission' && item.value === PERMISSIONS.ADMIN;
    });

    return new Observable<boolean>(observer => {
      if (isAdminPermissionsRequired) {
        observer.next(!!this.account && this.account.isAdmin);
        observer.complete();

        return;
      }
      
      if (!this.category.requiredPermissionsForRender || this.category.requiredPermissionsForRender.length === 0) {
        observer.next(true);
        observer.complete();

        return;
      }

      this.permissionsService.isUserHasPermissionsObservable(this.category.requiredPermissionsForRender, { isForbiddenForAdmins: this.category.isForbiddenForAdmins }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
        observer.next(isAllowed);
      });
    });
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(TOUR_KEYS.IMAGE_MANAGER);
    this.websiteTourService.removeVisibleLocation(TOUR_KEYS.CUSTOMIZER);
    this.websiteTourService.removeVisibleLocation(TOUR_KEYS.BLOCKS);

    this.websiteTourService.removeVisibleItem(WEBSITE_TOUR_KEYS.SIDEBAR_IMAGE_MANAGER_ICON_ACTIVE);
    this.websiteTourService.removeVisibleItem(WEBSITE_TOUR_KEYS.SIDEBAR_CUSTOMIZER_ICON_ACTIVE);
    this.websiteTourService.removeVisibleItem(WEBSITE_TOUR_KEYS.SIDEBAR_BLOCKS_ICON_ACTIVE);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onClick(): void {
    if (this.category.link) {
      window.open(this.category.link, '_blank');

      return;
    }

    const isBlog = this.isBlog;

    if (isBlog && (!this.isSubscriptionValid || !this.isPermitted)) {
      this.navigationService.toBlogPermissionsPage();

      return;
    }

    if (isBlog) {
      if (!this.isSubscriptionValid || !this.isPermitted) {
        this.navigationService.toBlogPermissionsPage();

        return;
      }

      if (!this.isUserHasBlog) {
        this.addBlogModalService.open();

        return;
      }
    }

    if (this.isWebsite && this.currentSubscription && this.currentSubscription.isEducator) {
      this.websitesIconChangeWebsiteModalService.open();

      return;
    }

    this.sidebarSectionsService.selectSection(this.category.section);
  }
}
