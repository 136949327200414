import {Injectable} from '@angular/core';

import {Observable, Subject, Subscription, BehaviorSubject, throwError, catchError} from 'rxjs';

import {EducationStudentsPortfoliosHttpService} from '../../../../interaction/http/education/students/websites/portfolios/education-students-portfolios-http.service';

import {PortfolioModel} from '../../../../../models/portfolios/portfolio.model';
import {NodeModel} from '../../../../../models/nodes/node.model';
import {IDefaultResponse} from '../../../../../models/responses/i-default-reponse';
import {ImageLabelDto} from '../../../../../models/images/image-label/image-label.dto';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class StudentPortfoliosService {
  public portfoliosSubject: BehaviorSubject<PortfolioModel[]> = new BehaviorSubject<PortfolioModel[]>(null);
  public selectedPortfolioSubject: BehaviorSubject<PortfolioModel> = new BehaviorSubject<PortfolioModel>(null);
  public submitPageCreateSubject: Subject<NodeModel> = new Subject<NodeModel>();
  public errorSubject: Subject<string> = new Subject<string>();
  public endedCourse: Subject<number> = new Subject<number>();
  public isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpService: EducationStudentsPortfoliosHttpService) {
  }

  public create({ websiteId, node }: { websiteId: number, node: NodeModel }): Observable<NodeModel> {
    return this.httpService.create({ websiteId, node });
  }

  public fetchForWebsite({ websiteId }: { websiteId: number }): Subscription {
    this.isLoadedSubject.next(false);

    return this.httpService.getAll({ websiteId }).pipe(
      catchError(e => {
        console.error(e);

        this.portfoliosSubject.next(null);
  
        this.isLoadedSubject.next(true);
  
        if (!e || !e.error) {
          return;
        }
  
        if (e.error.key === 'COURSE_ENDED') {
          this.endedCourse.next(websiteId);
        }
        
        return throwError(() => e);
      })
    ).subscribe((res: PortfolioModel[]) => {
      this.portfoliosSubject.next(res);

      this.isLoadedSubject.next(true);
    });
  }

  public fetchCountersForPortfolio({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Observable<PortfolioModel> {
    return this.httpService.getCounters({ websiteId, portfolioId });
  }

  public toggleShare({ websiteId, portfolioId, isShared }: { websiteId: number, portfolioId: number, isShared: boolean }): Observable<IDefaultResponse> {
    return this.httpService.toggleShare({ websiteId, portfolioId, isShared });
  }

  public saveImageDetails({ details }: { details: ImageLabelDto }): Observable<IDefaultResponse> {
    return this.httpService.saveImageDetails({ details });
  }

  public updateTitle({ websiteId, portfolioId, title }: { websiteId: number, portfolioId: number, title: string }): Observable<IDefaultResponse> {
    return this.httpService.updateTitle({ websiteId, portfolioId, title });
  }

  public removeOne(portfolio: PortfolioModel): Observable<IDefaultResponse> {
    return this.httpService.removeOne(portfolio);
  }
}
