import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-option-wrapper',
  templateUrl: './option-wrapper.component.html',
  styleUrls: ['./option-wrapper.component.scss']
})
export class OptionWrapperComponent {
  @Input() name: string;
  @Input() bottomPadding: string;
  @Input() isBold: boolean = false;
  @Input() isDisabled: boolean = false;
}
