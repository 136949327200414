import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';

import {NavigationService} from '../../../../services/navigation.service';

import {EducationKeys, NAVIGATION_MAPPING, ADD_BUTTON_TEXT} from './constants';

@Component({
  selector: 'app-admin-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class AdminEducationComponent implements OnDestroy {
  public activeTab: EducationKeys = 'teachers';

  public visibleAddItemModalKey: EducationKeys = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get addButtonText(): string {
    return `ADD ${ADD_BUTTON_TEXT[this.activeTab]}`;
  }

  constructor(private navigationService: NavigationService) {
    this.initCurrentTab();
  }

  private initCurrentTab(): void {
    const isTeachers: boolean = /education\/teachers/i.test(window.location.pathname);

    if (isTeachers) {
      this.activeTab = 'teachers';

      return;
    }

    const isInstitutions: boolean = /education\/institutions/i.test(window.location.pathname);

    if (isInstitutions) {
      this.activeTab = 'institutions';

      return;
    }

    const isClasses: boolean = /education\/classes/i.test(window.location.pathname);

    if (isClasses) {
      this.activeTab = 'classes';

      return;
    }
  }

  public goToTab(tab: EducationKeys): void {
    this.activeTab = tab;
    
    this.navigationService.toAdminEducatorTab(NAVIGATION_MAPPING[tab]);
  }

  public openAddItemModal(): void {
    this.visibleAddItemModalKey = this.activeTab;
  }

  public onAddModalClose(): void {
    this.visibleAddItemModalKey = null;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
