import {SetupOptionModel} from '../option/setup-option.model';

import {CLASS_NAME} from './constants';

export class IsClickToEnlargeModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value: boolean = false,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) {
      return;
    }

    this.element = element;

    this.value = this.element.classList.contains(CLASS_NAME);
  }

  public onChange(value: boolean): void {
    this.value = value;

    this.element.classList.toggle(CLASS_NAME, value);
  }
}
