import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {ATTRIBUTES, STYLE_PROPERTIES} from './constants';

export class HamburgerColorModel implements IStyleOption {
  public element: HTMLElement;
  public hamburger: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.hamburger = <HTMLElement>(element.querySelector('#left-menu-toggle') || element.querySelector('#right-menu-toggle') || element.querySelector('#sidebar-toggle'));

    const computedValue = window.getComputedStyle(this.element);

    this.value = computedValue.getPropertyValue(STYLE_PROPERTIES[this.key]) || computedValue.getPropertyValue('color');
  }

  public reInit(): void {
    const computedValue = window.getComputedStyle(this.element);

    this.value = computedValue.getPropertyValue(STYLE_PROPERTIES[this.key]) || computedValue.getPropertyValue('color');
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(STYLE_PROPERTIES[this.key]);
    this.element.setAttribute(ATTRIBUTES.IS_HAMBURGER_COLOR_SET, 'false');

    this.value = window.getComputedStyle(this.hamburger).getPropertyValue('color');


    this.element.style.setProperty(STYLE_PROPERTIES[this.key], this.value);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(STYLE_PROPERTIES[this.key], value);

    this.element.setAttribute(ATTRIBUTES.IS_HAMBURGER_COLOR_SET, 'true');
  }
}
