import {Injectable} from '@angular/core';

@Injectable()
export class AttributesService {
  addAttributeToSave({ element, attributeToSave }) {
    const isAttrExists = element.hasAttribute('data-attributes-to-save');

    if (!isAttrExists) return element.setAttribute('data-attributes-to-save', attributeToSave);

    const value = element.getAttribute('data-attributes-to-save');

    if (value.includes(attributeToSave)) return;

    const items = value.split(' ');

    items.push(attributeToSave);

    element.setAttribute('data-attributes-to-save', items.join(' '));
  }
}
