import {StripeCouponModel} from '../../stripe/coupon/stripe-coupon.model';
import {DiscountDto} from './discount.dto';

export class DiscountModel {
  constructor(public id?: string,
              public coupon?: StripeCouponModel) {}

  public static normalize(res: DiscountDto): DiscountModel {
    if (!res) return null;

    return new DiscountModel(
      res.id,
      StripeCouponModel.normalize(res.coupon),
    );
  }
}
