import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AdminEducationInstitutionsService} from '../../../../../../core/services/admin/education/institutions/admin-education-institutions.service';

import {EducationInstitutionModel} from '../../../../../../core/models/education/institutions/education-institution.model';
import {EducationTeacherModel} from '../../../../../../core/models/education/teachers/education-teacher.model';
import {EducationClassModel} from '../../../../../../core/models/education/classes/education-class.model';

@Component({
  selector: 'app-admin-education-institution-details',
  templateUrl: './institution-details.component.html',
  styleUrls: ['./institution-details.component.scss']
})
export class AdminEducationInstitutionDetailsComponent implements OnInit, OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public institution: EducationInstitutionModel;

  public classes: EducationClassModel[];
  public teachers: EducationTeacherModel[];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationInstitutionsService) {
  }

  public ngOnInit(): void {
    this.service.institutionDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((institution: EducationInstitutionModel) => {
      this.institution = institution;

      if (!institution) {
        this.classes = null;
        this.teachers = null;

        return;
      }

      this.service.fetchInstitutionClasses(institution.id).subscribe((classes: EducationClassModel[]) => {
        this.classes = classes;
      });

      this.service.fetchInstitutionTeachers(institution.id).subscribe((teachers: EducationTeacherModel[]) => {
        this.teachers = teachers;
      });
    });
  }

  public cancel(): void {
    this.closeHandler.emit();

    this.service.institutionDetailsSubject.next(null);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
