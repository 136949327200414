import {Component} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {EducationStudentsHttpService} from '../../../../core/services/interaction/http/education/students/education-students-http.service';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationSettingsComponent {
  public code: string = '';

  public successMessage: string = '';
  public errorMessage: string = '';

  public isLoading: boolean = false;

  constructor(
    private educationStudentsHttpService: EducationStudentsHttpService,
  ) {
  }

  public onSave(): void {
    this.successMessage = '';
    this.errorMessage = '';

    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    this.clearMessages();

    const onSuccess = () => {
      this.isLoading = false;

      this.successMessage = 'Successfully joined to class. Please wait.';

      window.location.href = '/';
    };

    this.educationStudentsHttpService.joinClass(this.code).pipe(
      catchError(e => {
        this.isLoading = false;
  
        this.errorMessage = e.error.message;

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  private clearMessages() {
    this.successMessage = '';
    this.errorMessage = '';
  }

  private validate(): boolean {
    if (this.code.trim().length > 0) {
      return true;
    }

    this.errorMessage = 'Invite Code is not valid.';

    return false;
  }
}
