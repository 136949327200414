import {PlanModel} from '../../plan/plan.model';
import {AccountSummaryModel} from '../../accounts/summary/account-summary.model';

export class PurchaseValidationModel {
  private errorsHandlers: ((plan: PlanModel, accountSummary: AccountSummaryModel) => string)[] = [
    (plan: PlanModel, accountSummary: AccountSummaryModel) => this.isWebsitesLimitValid ? '' : `plan provides ${plan.websiteLimit} websites, but you have ${accountSummary.websitesCount}`,
    (plan: PlanModel, accountSummary: AccountSummaryModel) => this.isPagesLimitValid ? '' : `plan provides ${plan.pageLimit} pages, but you have ${accountSummary.pagesCount}`,
    (plan: PlanModel, accountSummary: AccountSummaryModel) => this.isPortfoliosLimitValid ? '' : `plan provides ${plan.portfolioLimit} portfolios, but you have ${accountSummary.portfoliosCount}`,
    (plan: PlanModel, accountSummary: AccountSummaryModel) => this.isDiskSpaceMbValid ? '' : `plan provides ${plan.diskSpaceMb} MB, but you have ${accountSummary.diskSpaceMb} MB`,
  ];

  public get isValid(): boolean {
    return this.isWebsitesLimitValid && this.isPagesLimitValid && this.isPortfoliosLimitValid && this.isDiskSpaceMbValid;
  }

  constructor(public isWebsitesLimitValid: boolean,
              public isPagesLimitValid: boolean,
              public isPortfoliosLimitValid: boolean,
              public isDiskSpaceMbValid: boolean) {
  }

  public getErrorsList(plan: PlanModel, accountSummary: AccountSummaryModel): string[] {
    const errors = this.errorsHandlers.map(handler => handler(plan, accountSummary));

    return errors.filter(error => !!error);
  }
}
