import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {EducationTeachersService} from '../../../../core/services/education/teachers/education-teachers.service';
import {EducationInstitutionsService} from '../../../../core/services/education/teachers/institutions/education-institutions.service';
import {EducationClassesService} from '../../../../core/services/education/teachers/institutions/classes/education-classes.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';

import {EducationTeacherModel} from '../../../../core/models/education/teachers/education-teacher.model';
import {EducationInstitutionModel} from '../../../../core/models/education/institutions/education-institution.model';
import {EducationClassModel} from '../../../../core/models/education/classes/education-class.model';
import {NestedSelectOption} from '../../../../core/models/select/nested/nested-select-option.model';
import {EducationStudentModel} from '../../../../core/models/education/students/education-student.model';

import {environment} from '../../../../../environments/environment';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-educator-students',
  templateUrl: './educator-admin-students.component.html',
  styleUrls: ['./educator-admin-students.component.scss'],
  animations: [
    AppAnimations.fadeIn(200, 'fadeIn200'),
  ],
})
export class EducatorAdminStudentsComponent implements OnInit, OnDestroy {
  public teacher: EducationTeacherModel;

  public institutions: EducationInstitutionModel[];
  public institutionsOptions: NestedSelectOption[];
  public selectedInstitution: EducationInstitutionModel;

  public classes: EducationClassModel[];
  public classesOptions: NestedSelectOption[];
  public selectedClass: EducationClassModel;

  public students: EducationStudentModel[] = [];

  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';

  public hoveredInviteCodeItemId: number;
  public copiedInviteCodeItemId: number;

  public studentToDelete: EducationStudentModel = null;

  public isInstitutionsDropdownVisible: boolean = false;
  public isClassesDropdownVisible: boolean = false;
  public isStudentRemoving: boolean = false;
  
  public isInstitutionsLoaded: boolean = false;
  public isClassesLoaded: boolean = false;
  public isLoaded: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get studentSignUpUrl(): string {
    return environment.education.studentSignUpUrl;
  }

  constructor(private teachersService: EducationTeachersService,
              private institutionsService: EducationInstitutionsService,
              private classesService: EducationClassesService,
              private cdr: ChangeDetectorRef,
              private utilsService: UtilsService) {
    this.teachersService.fetchCurrent();

    this.onSearchInputKeyDown = this.utilsService.debounce(this.onSearchInputKeyDown.bind(this), 50);
  }

  public ngOnInit(): void {
    this.classesService.classDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationClassModel) => {
      this.selectedClass = item;

      this.initStudents();
    });

    this.classesService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isClassesLoaded = value;

      this.initIsLoaded();

      this.cdr.detectChanges();
    });

    this.institutionsService.institutionDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationInstitutionModel) => {
      this.selectedInstitution = item;

      this.initClasses();
      
      this.onClassesSelect(this.classesOptions);
    });

    this.institutionsService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isInstitutionsLoaded = value;

      this.initIsLoaded();

      this.cdr.detectChanges();
    });

    this.teachersService.teacherDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((teacher: EducationTeacherModel) => {
      this.teacher = teacher;

      this.initInstitutions();
      
      this.onInstitutionSelect(this.institutionsOptions);
    });
  }

  private initInstitutions(): void {
    this.institutions = this.teacher ? this.teacher.institutions : [];

    this.institutionsOptions = this.institutions.map((item: EducationInstitutionModel) => {
      return new NestedSelectOption(
        item.name,
        `${item.id}`,
      );
    });

    this.isInstitutionsDropdownVisible = this.institutionsOptions.length > 1;

    if (this.institutionsOptions.length === 0) return;

    this.institutionsOptions[0].isSelected = true;
  }

  public onInstitutionSelect(options: NestedSelectOption[]): void {
    const institutions: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedInstitution: EducationInstitutionModel = this.institutions.find((item: EducationInstitutionModel) => {
      return institutions.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!selectedInstitution) {
      return;
    }

    this.institutionsService.fetchOne(selectedInstitution.id);
  }

  private initClasses(): void {
    const allClasses: EducationClassModel[] = this.teacher ? this.teacher.classes : [];

    this.classes = this.selectedInstitution ? allClasses.filter((item: EducationClassModel) => {
      return item.institutionId === this.selectedInstitution.id;
    }) : [];

    this.classesOptions = this.classes.map((item: EducationClassModel) => {
      return new NestedSelectOption(
        `${item.name} "${item.number}"`,
        `${item.id}`,
      );
    });

    this.isClassesDropdownVisible = this.isInstitutionsDropdownVisible || this.classesOptions.length > 1;

    if (this.classesOptions.length === 0) return;

    this.classesOptions[0].isSelected = true;
  }

  public onClassesSelect(options: NestedSelectOption[]): void {
    const classes: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedClass: EducationClassModel = this.classes.find((item: EducationClassModel) => {
      return classes.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!selectedClass) {
      this.selectedClass = null;

      this.isClassesLoaded = true;

      this.initIsLoaded();

      this.initStudents();

      return;
    }

    this.classesService.fetchOne(this.selectedInstitution.id, selectedClass.id);
  }

  private initIsLoaded(): void {
    this.isLoaded = this.isInstitutionsLoaded && this.isClassesLoaded;

    if (this.isInstitutionsLoaded && (!this.institutions || this.institutions.length === 0)) {
      this.isLoaded = true;
    }
  }

  public onSearchInputKeyDown(e: KeyboardEvent): void {
    return e.key === 'Escape' ? this.clearSearch() : this.initStudents();
  }

  public clearSearch(): void {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    
    this.initStudents();
  }

  public initStudents(): void {
    if (!this.selectedClass) {
      this.students = [];

      return;
    }

    this.students = this.selectedClass.students.filter((student: EducationStudentModel) => {
      return student.user.firstName.includes(this.firstName)
        && student.user.lastName.includes(this.lastName)
        && student.user.email.includes(this.email);
    });
  }

  public onInviteCodeMouseEnter(item: EducationClassModel): void {
    this.hoveredInviteCodeItemId = item.id;
  }

  public onInviteCodeMouseLeave(): void {
    this.hoveredInviteCodeItemId = null;
  }

  // async ok
  public async onInviteCodeCopy(item: EducationClassModel) {
    this.copiedInviteCodeItemId = item.id;

    await this.utilsService.copyToClipboard(item.signUpCode);

    window.setTimeout(() => {
      this.copiedInviteCodeItemId = null;
    }, 1000);
  }

  public openDeleteStudentModal(item: EducationStudentModel): void {
    this.studentToDelete = item;

    this.isStudentRemoving = true;
  }

  public onStudentDeleteCompleted(): void {
    this.isStudentRemoving = false;

    this.studentToDelete = null;

    this.classesService.fetchOne(this.selectedInstitution.id, this.selectedClass.id);
  }

  public onDeleteStudentModalClose(): void {
    this.isStudentRemoving = false;

    this.studentToDelete = null;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.institutionsService.isLoadedSubject.next(false);
    this.classesService.isLoadedSubject.next(false);
  }
}
