import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Report} from "../../../../models/report/report.model";

@Injectable()
export class ReportsHttpService {
  constructor(private http: HttpClient) {}

  public send(report: Report) {
    const data = report.toFormData();

    return this.http.post('api/report', data);
  }
}
