import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {AuthService} from '../../../auth/auth.service';

import {ModalDataModel} from '../../../core/models/modals/modal-data.model';

@Injectable()
export class ModalsService {
  public statusSubject: BehaviorSubject<{ [key: string]: ModalDataModel }> = new BehaviorSubject({});

  private get status(): {} {
    return this.statusSubject.value;
  }

  public constructor(private authService: AuthService) {
    this.authService.onSignOut.subscribe(() => this.closeAll());
  }

  public open(id: string, data?: any): void {
    this.status[id] = {
      isVisible: true,
      data,
    };

    this.statusSubject.next(this.status);
  }

  public closeAll(): void {
    this.statusSubject.next({});
  }

  public close(id: string): void {
    this.status[id] = null;

    this.statusSubject.next(this.status);
  }
}
