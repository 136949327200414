import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {take, takeUntil, filter} from 'rxjs/operators';

import {PreLaunchChecklistService} from '../../../../core/services/pre-launch-checklist/pre-launch-checklist.service';
import {NavigationService} from '../../../../services/navigation.service';
import {EditorDevicesService} from '../../../../core/services/editor-devices/editor-devices.service';
import {AuthService} from '../../../../auth/auth.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';
import {PublishingWebsiteInfoModalService} from '../../../../shared/services/modals/publishing-website-info/publishing-website-info-modal.service';

import {PreLaunchProgressItemModel} from '../../../../core/models/pre-launch-progress/item/pre-launch-progress-item.model';
import {PreLaunchProgressAccountDataModel} from '../../../../core/models/pre-launch-progress/account-data/pre-launch-progress-account-data.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';

import {AppAnimations} from '../../../../app-animations';

import {KEYS, DATA} from './constants';
import {DEVICES} from '../../../../core/services/editor-devices/constants';
import {COLORS} from '../../../../shared/components/charts/doughnut/constants';

@Component({
  selector: 'app-pre-launch-checklist',
  templateUrl: './pre-launch-checklist.component.html',
  styleUrls: ['./pre-launch-checklist.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PreLaunchChecklistComponent implements OnInit, OnDestroy {
  public items: PreLaunchProgressItemModel[];

  public chartTitle: string = '';

  public chartData: number[] = [0, 100];

  public chartColors: Array<any> = [
    COLORS.PRIMARY_YELLOW,
    COLORS.BACKGROUND,
  ];

  public nOfItemsLeft: number = 0;
  public percentCompleted: number = 0;

  public isChartRenderEnabled: boolean = false;
  public isCollapsedByDefault: boolean = false;

  private account: AccountModel;

  private clickHandlers: { [key: string]: Function } = {
    [KEYS.ADD_PAGE]: this.onAddPageGo.bind(this),
    [KEYS.UPLOAD_IMAGES]: this.onUploadImagesGo.bind(this),
    [KEYS.CONTACT_PAGE]: this.onContactPageGo.bind(this),
    [KEYS.SEARCH_ENGINE_OPTIMIZATION]: this.onSearchEngineOptimizationGo.bind(this),
    [KEYS.COPYRIGHT]: this.onCopyrightGo.bind(this),
    [KEYS.MOBILE_PREVIEW]: this.onMobilePreviewGo.bind(this),
    [KEYS.PURCHASE_PLAN]: this.onPurchasePlanGo.bind(this),
    [KEYS.PUBLISH_WEBSITE]: this.onPublishPlanGo.bind(this),
  };

  private percentAnimationTimeoutId: number;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get keys(): { [key: string]: string } {
    return KEYS;
  }

  public get data(): { [key: string]: { title: string, isGoButtonVisible: boolean } } {
    return DATA;
  }

  constructor(private service: PreLaunchChecklistService,
              private navigationService: NavigationService,
              private editorDevicesService: EditorDevicesService,
              private publishingWebsiteInfoModalService: PublishingWebsiteInfoModalService,
              private authService: AuthService,
              private utilsService: UtilsService) {
    this.renderChart = this.utilsService.debounce(this.renderChart.bind(this), 250);
  }

  public ngOnInit(): void {
    this.service.itemsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((items: PreLaunchProgressItemModel[]) => !!items)
    ).subscribe((items: PreLaunchProgressItemModel[]) => {
      this.items = items;

      this.initNumberOfItemsLeft();
      this.initPercentCompleted();
      this.initChartText();

      this.isCollapsedByDefault = this.nOfItemsLeft === 0;

      this.renderChart();
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });
  }

  private renderChart(): void {
    this.isChartRenderEnabled = true;
  }

  public onToggle(item: PreLaunchProgressItemModel): void {
    item.isCompleted = !item.isCompleted;

    this.account.preLaunchProgress.progress[item.id] = item.isCompleted;

    this.setProgress(this.account.preLaunchProgress);

    this.service.itemsSubject.next(this.items);
  }

  public setProgress(progress: PreLaunchProgressAccountDataModel): void {
    this.service.setProgress(progress);
  }

  public onGo(key: string): void {
    if (!this.clickHandlers[key]) return;

    this.clickHandlers[key]();
  }

  private onAddPageGo(): void {
    this.navigationService.toHomePage({ sidebar: ['pages'], isSidebarClosed: false }).add(() => {
      this.editorDevicesService.onDeviceChange(DEVICES.DESKTOP);
    });
  }

  private onUploadImagesGo(): void {
    this.navigationService.toFirstPortfolio();
  }

  private onContactPageGo(): void {
    this.navigationService.toContactPage({ sidebar: ['pages'], isSidebarClosed: false }).pipe(take(1)).subscribe((isRedirected: boolean) => {
      this.editorDevicesService.onDeviceChange(DEVICES.DESKTOP);

      if (!isRedirected) {
        this.navigationService.toHomePage({ sidebar: ['pages'], isSidebarClosed: false });

        return;
      }
    });
  }

  private onSearchEngineOptimizationGo(): void {
    this.navigationService.toWebsiteSettings();
  }

  private onCopyrightGo(): void {
    this.navigationService.toWebsiteSettings();
  }

  private onMobilePreviewGo(): void {
    this.navigationService.toHomePage({ sidebar: ['pages'], isSidebarClosed: false }).add(() => {
      this.editorDevicesService.onDeviceChange(DEVICES.MOBILE);
    });
  }

  private onPurchasePlanGo(): void {
    this.navigationService.toPurchase();
  }

  private onPublishPlanGo(): void {
    this.publishingWebsiteInfoModalService.open({ isDashboard: true });
  }

  private initNumberOfItemsLeft(): void {
    this.nOfItemsLeft = this.items ? this.items.reduce((res: number, item: PreLaunchProgressItemModel) => res + (item.isCompleted ? 0 : 1), 0) : 0;
  }

  private initPercentCompleted(): void {
    const prev: number = this.percentCompleted;
    const next: number = this.items ? 100 - Math.ceil(this.nOfItemsLeft / this.items.length * 100) : 0;

    window.clearTimeout(this.percentAnimationTimeoutId);

    this.animatePercentCompleted(prev, next, next > prev, 250 / Math.abs(prev - next));

    this.chartData = [
      next,
      100 - next,
    ];

    this.chartColors[0] = next === 100 ? COLORS.PRIMARY_GREEN : COLORS.PRIMARY_YELLOW;
  }

  private animatePercentCompleted(current: number, final: number, isUp: boolean, stepMs: number): void {
    if (current < 0 || current > 100 || (isUp && current > final) || (!isUp && current < final)) return;

    this.percentCompleted = current;

    this.percentAnimationTimeoutId = window.setTimeout(() => {
      this.animatePercentCompleted(current + (isUp ? 1 : -1), final, isUp, stepMs);
    }, stepMs);
  }

  private initChartText(): void {
    if (!this.items) {
      this.chartTitle = null;

      return;
    }

    if (this.nOfItemsLeft === 0) {
      this.chartTitle = `CONGRATULATIONS!`;

      return;
    }

    if (this.nOfItemsLeft === this.items.length) {
      this.chartTitle = `LET'S GO!`;

      return;
    }

    this.chartTitle = `GREAT WORK!`;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
