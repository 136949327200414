export class PreLaunchProgressItemModel {
  public isCompleted: boolean = false;

  constructor(public id?: number,
              public key?: string,
              public orderNo?: number) {
  }

  public static clone(item: PreLaunchProgressItemModel): PreLaunchProgressItemModel {
    return new PreLaunchProgressItemModel(
      item.id,
      item.key,
      item.orderNo,
    );
  }
}
