import {environment} from '../../../../../../environments/environment';

export const AUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';

export const REQUEST_PARAMS = {
  client_id: environment.ga4.clientId,
  redirect_uri: environment.ga4.oAuthRedirectUri,
  response_type: 'token',
  scope: environment.ga4.scopes.join(' '),
  include_granted_scopes: 'true',
  prompt: 'select_account',
};
