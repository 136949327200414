import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../modals.service';

import {PortfolioModel} from '../../../../core/models/portfolios/portfolio.model';

@Injectable()
export class DeleteExhibitionModalService {
  public readonly id: string = 'delete-exhibition-modal';

  public exhibition: PortfolioModel;

  public onConfirm: Subject<boolean> = new Subject<boolean>();
  public onCancel: Subject<boolean> = new Subject<boolean>();

  constructor(private modalsService: ModalsService) {
  }

  public open(exhibition: PortfolioModel): void {
    this.exhibition = exhibition;

    this.modalsService.open(this.id);
  }

  public close(): void {
    this.exhibition = null;

    this.modalsService.close(this.id);
  }
}
