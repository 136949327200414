import {TemplatePreviewDetailsModel} from './template-preview-details.model';
import {TemplatePreviewTypeDto} from './type/template-preview-type.dto';
import {TemplatePreviewDescriptionDto} from '../description/template-preview-description.dto';

export class TemplatePreviewDetailsDto {
  constructor(public Id?: number,
              public TemplateId?: number,
              public ThemeId?: number,
              public TypeId?: number,
              public ImageName?: string,
              public ImagePath?: string,
              public PreviewUrl?: string,
              public TemplatesPreviewType?: TemplatePreviewTypeDto,
              public TemplatesPreviewsDescriptions?: TemplatePreviewDescriptionDto[]) {}

  public static normalize(res: TemplatePreviewDetailsDto): TemplatePreviewDetailsModel {
    if (!res) return null;

    return new TemplatePreviewDetailsModel(
      res.Id,
      res.TemplateId,
      res.ThemeId,
      res.TypeId,
      res.ImageName,
      res.ImagePath,
      res.PreviewUrl,
      TemplatePreviewTypeDto.normalize(res.TemplatesPreviewType),
      res.TemplatesPreviewsDescriptions ? res.TemplatesPreviewsDescriptions.map(description => TemplatePreviewDescriptionDto.normalize(description)) : null,
    );
  }
}
