import {INativeStyleOption} from './i-native-style-option';
import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

export class NativeSetupOptionModel implements INativeStyleOption {
  public value?: any;
  public element: HTMLElement;

  constructor(
    public key: string,
    public styleName: string,
    public isEnabled: boolean = false,
  ) {}

  public init(element: HTMLElement, options?: IStyleModelOptions) {
  }
}
