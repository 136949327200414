import {Component, Input, Output, EventEmitter} from '@angular/core';

import {ModalsService} from "../../../services/modals/modals.service";
import { PrivatePagePasswordModalService } from '../../../services/modals/private-page-password/private-page-password-modal.service';

import {ModalHeader} from "../../../../common/models/modal/header/header.model";

import {KEY_CODES} from './constants';

@Component({
  selector: 'app-private-page-password-input-modal',
  templateUrl: 'private-page-password-input-modal.component.html',
  styleUrls: ['private-page-password-input-modal.component.scss']
})
export class PrivatePagePasswordInputModalComponent {
  @Input() inputType: string = 'text';
  @Input() headerText: string;
  @Input() headerClassName: string;

  @Output() doSave = new EventEmitter<{ isLocked: boolean, password: string }>();
  @Output() doCancel = new EventEmitter();

  public text: string = '';

  public isValid: boolean = true;
  public isPrivate: boolean;

  public get id(): string {
    return this.service.id;
  }

  public get modalHeader(): ModalHeader {
    return {
      text: this.headerText,
      className: this.headerClassName
    };
  }

  constructor(
    private service: PrivatePagePasswordModalService,
    private modalsService: ModalsService,
  ) {}

  public onOpen(data: { isPrivate: boolean }): void {
    this.isPrivate = data.isPrivate;
  }

  public onKeyDown(e) {
    if (e.keyCode === KEY_CODES.ENTER) return this.saveHandler();
    if (e.keyCode === KEY_CODES.ESC) return this.cancelHandler();
  }

  public saveHandler() {
    this.isValid = this.text.length > 0;

    if (!this.isValid) {
      return;
    }

    this.doSave.emit({
      isLocked: this.isPrivate,
      password: this.text,
    });

    this.modalsService.close(this.id);
  }

  public cancelHandler() {
    this.doCancel.emit();

    this.modalsService.close(this.id);
  }
}
