import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {WebsiteTourProgressItemDto} from '../../../../models/website-tour-progress/item/website-tour-progress-item.dto';
import {WebsiteTourProgressItemModel} from '../../../../models/website-tour-progress/item/website-tour-progress-item.model';
import {WebsiteTourProgressAccountDataModel} from '../../../../models/website-tour-progress/account-data/website-tour-progress-account-data.model';

@Injectable()
export class WebsiteTourHttpService {
  private model: string = 'website-tour';

  constructor(private http: HttpClient) {}

  public fetchAll(): Observable<{ [key: number]: WebsiteTourProgressItemModel }> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: { [key: number]: WebsiteTourProgressItemDto }) => {
        return Object.keys(res).reduce((data: { [key: number]: WebsiteTourProgressItemModel }, key: string) => {
          data[key] = WebsiteTourProgressItemDto.normalize(res[key]);

          return data;
        }, {});
      })
    );
  }

  public setProgress(tourProgress: WebsiteTourProgressAccountDataModel): Observable<{}> {
    return this.http.put(`api/${this.model}/progress`, tourProgress);
  }
}
