import {IStyleOption} from '../option/i-style-option';

import {DEFAULT_VALUE, PROPERTY, PROPERTY_PX} from './constants';

export class GridGapModel implements IStyleOption {
  public element: HTMLElement;

  public maxValue: number = 50;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const isPortfolioIndex = this.element.matches('[data-block-template-id="141"]') || this.element.matches('[data-block-template-id="142"]');

    this.maxValue = isPortfolioIndex ? 100 : 50;

    const value: string = window.getComputedStyle(this.element).getPropertyValue(PROPERTY);

    return this.setValue(value);
  }

  public reset() {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(PROPERTY);

    const value: string = window.getComputedStyle(this.element).getPropertyValue(PROPERTY);
    const numberValue: number = Number.parseInt(value);

    this.setValue(`${Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue}`);
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.element) return;

    const numberValue: number = Number.parseInt(value);

    this.value = Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue;

    this.element.style.setProperty(PROPERTY, `${this.value}`);
    this.element.style.setProperty(PROPERTY_PX, `${this.value}px`);
  }
}
