import {Injectable} from '@angular/core';

import {Subscription, BehaviorSubject} from 'rxjs';

import {EducationTeachersHttpService} from '../../interaction/http/education/teachers/education-teachers-http.service';

import {EducationTeacherModel} from '../../../models/education/teachers/education-teacher.model';

@Injectable()
export class EducationTeachersService {
  public teachersSubject: BehaviorSubject<EducationTeacherModel[]> = new BehaviorSubject<EducationTeacherModel[]>(null);
  public teacherDetailsSubject: BehaviorSubject<EducationTeacherModel> = new BehaviorSubject<EducationTeacherModel>(null);

  constructor(private httpService: EducationTeachersHttpService) {
  }

  public fetchCurrent(): Subscription {
    return this.httpService.getCurrent().subscribe((res: EducationTeacherModel) => {
      this.teacherDetailsSubject.next(res);
    });
  }
}
