// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  position: relative;
  color: #fff;
  width: 280px;
  height: 100%;
  background: #3f3e3e;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.sidebar .sidebar-header {
  display: flex;
  flex-direction: row;
  padding: 22px 14px 0 16px;
  position: relative;
}
.sidebar .sidebar-header .title {
  font-size: 18px;
  text-transform: uppercase;
}
.sidebar .sidebar-header .page-type-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 300;
}
.sidebar .sidebar-header .page-type-title .dash {
  margin: 0 8px;
}
.sidebar.large-screen .sidebar-outlet {
  height: 100%;
  width: 100%;
  padding-top: 10px;
  overflow: hidden;
}
.sidebar.medium-screen .sidebar-outlet {
  height: calc(100% - 41px);
  width: 100%;
  padding-top: 10px;
  overflow: hidden;
}
.sidebar.medium-screen .close-btn {
  position: absolute;
  top: 20px;
  right: 0;
  width: 20px;
  height: 20px;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/application/sidebar-short/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EAEA,aAAA;EACA,sBAAA;AAHF;AAKE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;AAHJ;AAKI;EACE,eAAA;EACA,yBAAA;AAHN;AAMI;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;AAJN;AAMM;EACE,aAAA;AAJR;AAUI;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AARN;AAaI;EACE,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AAXN;AAcI;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAZN","sourcesContent":["@import \"../../../../styles/colors\";\n\n\n.sidebar {\n  position: relative;\n  color: #fff;\n  width: 280px;\n  height: 100%;\n  background: #3f3e3e;\n  overflow: hidden;\n\n  display: flex;\n  flex-direction: column;\n\n  .sidebar-header {\n    display: flex;\n    flex-direction: row;\n    padding: 22px 14px 0 16px;\n    position: relative;\n\n    .title {\n      font-size: 18px;\n      text-transform: uppercase;\n    }\n\n    .page-type-title {\n      display: flex;\n      flex-direction: row;\n      align-items: flex-end;\n      font-size: 12px;\n      font-weight: 300;\n\n      .dash {\n        margin: 0 8px;\n      }\n    }\n  }\n\n  &.large-screen {\n    .sidebar-outlet {\n      height: 100%;\n      width: 100%;\n      padding-top: 10px;\n      overflow: hidden;\n    }\n  }\n\n  &.medium-screen {\n    .sidebar-outlet {\n      height: calc(100% - 41px);\n      width: 100%;\n      padding-top: 10px;\n      overflow: hidden;\n    }\n\n    .close-btn {\n      position: absolute;\n      top: 20px;\n      right: 0;\n      width: 20px;\n      height: 20px;\n      margin-left: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
