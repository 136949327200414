export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `अंतरिक्ष यान से दूर नीचे पृथ्वी शानदार ढंग से जगमगा रही थी ।`,
      `आकाश में बादल नहीं थे और उसका रंग गहरा नीला था ।`,
      `इससे पहले कि हम कुछ जान पाते, हम धरती से रवाना हो चुके थे ।`,
      `उनके सभी औज़ार और उपकरण किसी ना किसी स्वरूप में ज़िंदा हैं ।`,
      `चमकीला कोहरा जहाज़ के पूरे डेक पर छा गया ।`,
    ],
    [KEYS.ALPHABET]: [
      'आईऊऋॠऌॡऐऔऎअंअँकखगघङचछजझञटठडढणतथदधनपफबभयरवळशषसह₹',
    ],
    [KEYS.PARAGRAPH]: [
      `चूंकि भागों की सूक्ष्म रचना से मेरी गति में बहुत बड़ी बाधा हो रही थी, इसलिए अपने पहले प्रयोजन के विपरीत मैंने इस विशाल आकृति को बनाने का निर्णय लिया जो लगभग आठ फ़ुट ऊंची और इसी के अनुपात में बड़ी थी । इस संकल्प के बाद और अपनी सामग्री सफलतापूर्वक एकत्र करने तथा व्यवस्थित करने में कुछ माह लगाने के बाद मैंने इसकी शुरुआत की ।`
    ],
    [KEYS.NUMERALS]: [
      '०१२३४५६७८९',
    ],
  };
};
