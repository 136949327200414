import {Injectable} from '@angular/core';
import {RouterStateSnapshot, ActivatedRouteSnapshot, Router, NavigationExtras} from '@angular/router';

import {Observable, Subject, Subscriber} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../auth/auth.service';
import {PaymentSubscriptionsService} from '../../../services/payment/subscriptions/payment-subscriptions.service';

import {AccountModel} from '../../../models/accounts/account.model';
import {SubscriptionModel} from '../../../models/payment/subscriptions/subscription.model';

@Injectable()
export class SubscriptionActiveGuard  {
  private account: AccountModel;

  constructor(
    private router: Router,
    private authService: AuthService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;
    });
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      if (this.authService.isTrialSubscription || !this.authService.isSubscriptionExpired) {
        observer.next(true);
        observer.complete();

        return;
      }
  
      if (this.account && this.account.isUserImported) {
        this.toAddOnsPurchase();
  
        observer.next(false);
        observer.complete();

        return;
      }

      const unsubscribe: Subject<boolean> = new Subject<boolean>();

      this.paymentSubscriptionsService.currentSubscriptionExpandedSubject.pipe(takeUntil(unsubscribe)).subscribe((res: { isFetched: boolean, data: SubscriptionModel }) => {
        if (!res || !res.isFetched) {
          return;
        }
        
        unsubscribe.next(true);
        unsubscribe.complete();
    
        observer.next(false);
        observer.complete();

        if (!res.data) {
          this.toPurchase();

          return;
        }

        if (res.data.latestInvoice && res.data.latestInvoice.invoiceStatus === 'open') {
          this.toPayments();
        } else {
          this.toPurchase();
        }
      });
    });
  }

  private toPurchase(): void {
    this.toSettings({
      page: 'purchase'
    });
  }

  private toAddOnsPurchase(): void {
    this.toSettings({
      page: 'purchase-add-ons'
    });
  }

  private toPayments(): void {
    this.toSettings({
      page: 'payments',
      navigationExtras: {
        queryParamsHandling: 'merge',
      },
    });
  }

  private toSettings({
    page,
    navigationExtras,
  }: {
    page: string,
    navigationExtras?: NavigationExtras,
  }): void {
    this.router.navigate([
      '/app',
      {
        outlets: {
          primary: [
            'settings',
            page,
          ],
          sidebar: [
            'account',
            page
          ],
          'over-sidebar': null,
        },
      },
    ], navigationExtras);
  }
}
