const general = {
  url: '/api/app/image-manager',
  paramName: 'image',
  maxFilesize: 10,
  autoProcessQueue: true,
  createImageThumbnails: true,
  acceptedFiles: 'image/*',
  thumbnailMethod: 'contain',
  timeout: 480000,
};

const initialSetup = {
  clickable: '.browse-disk',
  addRemoveLinks: false,
  uploadMultiple: false,
};

const imageManager = {
  maxFiles: 1000,
  clickable: '#dropzone-hidden-input', // for disabling clicking dropzone to upload file, but still need some element to mockup dropzone.hiddenFileInput
  addRemoveLinks: true,
  parallelUploads: 1,
};

const imageManagerModal = {
  clickable: '.portfolio-images .upload-images',
  uploadMultiple: false,
  addRemoveLinks: false,
};

const dropzoneConfig = {
  initialSetup: Object.assign({}, general, initialSetup),
  imageManager: Object.assign({}, general, imageManager),
  imageManagerModal: Object.assign({}, general, imageManager, imageManagerModal),
};

export {dropzoneConfig};
