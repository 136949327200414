export enum CustomizerSections {
  General,
  Pages,
  Title,
  Subtitle,
  MobileTitle,
  MobileSubtitle,
  Media,
  Mobile,
  MobileMedia,
  MobileTemplates,
  MobileTemplatesPortfolios,
  MobileTemplatesEnlargements,
}

export const StylePanel: any = {
  [CustomizerSections.Title]: {
    styles: {
      'font-family': [],
      'font-weight': '',
      'font-size': '',
      'color': '',
      'text-transform': '',
      'letter-spacing': '',
      'word-spacing': '',
      'line-height': '',
      'text-decoration': '',
    },
    attr: 'data-website-title',
    nodes: [],
  },
  [CustomizerSections.Subtitle]: {
    styles: {
      'font-family': [],
      'font-weight': '',
      'font-size': '',
      'color': '',
      'text-align': '',
      'text-transform': '',
      'letter-spacing': '',
      'word-spacing': '',
      'line-height': '',
      'text-decoration': '',
    },
    attr: 'data-website-subtitle',
    nodes: [],
  },
  [CustomizerSections.MobileTitle]: {
    styles: {
      'font-family': [],
      'font-weight': '',
      'font-size': '',
      'color': '',
      'text-align': '',
      'text-transform': '',
      'letter-spacing': '',
      'word-spacing': '',
      'line-height': '',
      'text-decoration': '',
    },
    attr: 'data-website-title-mobile',
    nodes: [],
  },
  [CustomizerSections.MobileSubtitle]: {
    styles: {
      'font-family': [],
      'font-weight': '',
      'font-size': '',
      'color': '',
      'text-align': '',
      'text-transform': '',
      'letter-spacing': '',
      'word-spacing': '',
      'line-height': '',
      'text-decoration': '',
    },
    attr: 'data-website-subtitle-mobile',
    nodes: [],
  },
  [CustomizerSections.Pages]: {
    prefix: '--menu-links-',
    styles: {
      'font-family': [],
      'font-weight': '',
      'font-size': '',
      'color': '',
      'text-align': '',
      'text-transform': '',
      'letter-spacing': '',
      'word-spacing': '',
      '--menu-links-line-height': '',
      '--menu-links-padding-left': '',
      '--menu-links-padding-right': '',
    },
    attr: 'data-menu-block',
    nodes: [],
  },
  [CustomizerSections.Mobile]: {
    styles: {
      'font-family': [],
      'font-weight': '',
      'font-size': '',
      'color': '',
      'text-align': '',
      'text-transform': '',
      'letter-spacing': '',
      'word-spacing': '',
      'line-height': '',
      'text-decoration': '',
    },
    attr: 'data-side-menu',
    nodes: [],
  },
  [CustomizerSections.Media]: {
    attr: 'data-media-wrapper',
    nodes: [],
  },
  [CustomizerSections.MobileMedia]: {
    attr: 'data-media-wrapper-mobile',
    nodes: [],
  },
  [CustomizerSections.MobileTemplates]: {
    attr: 'data-mobile-templates',
    nodes: [],
    children: [
      CustomizerSections.MobileTemplatesPortfolios,
      CustomizerSections.MobileTemplatesEnlargements,
    ],
  },
  [CustomizerSections.MobileTemplatesPortfolios]: {
    parent: CustomizerSections.MobileTemplates,
    attr: 'data-mobile-templates-portfolios',
    nodes: [],
  },
  [CustomizerSections.MobileTemplatesEnlargements]: {
    parent: CustomizerSections.MobileTemplates,
    attr: 'data-mobile-templates-enlargements',
    nodes: [],
  },
};

export const WebsiteStyles = {
  styles: {
    'background-color': '',
    'font-family': [],
    'font-weight': '',
    'font-size': '',
    'color': '',
    'text-transform': '',
    'letter-spacing': '',
    'word-spacing': '',
    'line-height': '',
  }
};

// Map between css styles and db fields
export const WebsiteStylesMap = {
  'background-color': 'backgroundcolor',
  'color': 'pagefontcolor',
  'font-family': 'pagefontfamily',
  'font-size': 'pagefontsize',
  'font-weight': 'pagefontweight',
  'letter-spacing': 'pageletterspacing',
  'line-height': 'pagelineheight',
  'text-transform': 'pagetexttransform',
  'word-spacing': 'pagewordspacing',
};

export const StylesPropUnits = {
  'font-size': 'px',
  'word-spacing': 'px',
  'line-height': 'px',
  'letter-spacing': 'px',
};
