export class AccountSummaryModel {
  constructor(public websitesCount?: number,
              public pagesCount?: number,
              public portfoliosCount?: number,
              public imagesCount?: number,
              public diskSpaceMb?: number,
              public planSupportTimeMin?: number,
              public addOnSupportTimeMin?: number) {}

  public static clone(summary: AccountSummaryModel): AccountSummaryModel {
    if (!summary) return null;

    return new AccountSummaryModel(
      summary.websitesCount,
      summary.pagesCount,
      summary.portfoliosCount,
      summary.imagesCount,
      summary.diskSpaceMb,
      summary.planSupportTimeMin,
      summary.addOnSupportTimeMin,
    );
  }
}
