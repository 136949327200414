import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

import {Subject} from 'rxjs';

import {EducationTeachersStudentsService} from '../../../../../core/services/education/teachers/institutions/classes/students/education-students.service';

import {EducationInstitutionModel} from '../../../../../core/models/education/institutions/education-institution.model';
import {EducationClassModel} from '../../../../../core/models/education/classes/education-class.model';
import {EducationStudentModel} from '../../../../../core/models/education/students/education-student.model';

@Component({
  selector: 'app-educator-admin-delete-student-modal',
  templateUrl: './delete-student-modal.component.html',
  styleUrls: ['./delete-student-modal.component.scss']
})
export class EducatorAdminDeleteStudentModalComponent implements OnDestroy {
  @Input() institution: EducationInstitutionModel;
  @Input() course: EducationClassModel;
  @Input() student: EducationStudentModel;

  @Output() submitHandler = new EventEmitter<void>();
  @Output() closeHandler = new EventEmitter<void>();

  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: EducationTeachersStudentsService) {
  }

  public submit(): void {
    if (this.isLoading) return;
    
    this.isLoading = true;

    this.service.remove(this.institution.id, this.course.id, this.student.id).add(() => {
      this.isLoading = false;

      this.submitHandler.emit();
    });
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
