import {BasePxStyleOptionModel} from '../base/base-px-style-option/base-px-style-option.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX, STYLE_PROPERTY_NAME} from './constants';

export class DesktopLetterSpacingModel extends BasePxStyleOptionModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, STYLE_PROPERTY_NAME, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    this.element = element && <HTMLElement>element.closest(`[data-scalable-text="true"]`);

    this.isEnabled = !!this.element;

    if (!this.isEnabled) return;

    super.init(this.element);
  }
}
