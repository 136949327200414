import {Component, Input, forwardRef, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-vs-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadiobuttonComponent),
    multi: true,
  }]
})
export class RadiobuttonComponent implements ControlValueAccessor {
  @ViewChild('inputElement') inputElement;

  private isCheckboxSelected: any;

  onChange = (value: any) => {};
  onTouched = () => {};

  @Input() disabled: boolean = false;
  @Input() isBorderDisabled: boolean = false;
  @Input() name: any;
  @Input() selectedValue: any;
  @Input() value: any;
  @Input() label: any;

  @Input()
  get isChecked() {
    return this.isCheckboxSelected;
  }

  set isChecked(value) {
    this.isCheckboxSelected = value;
    this.writeValue(this.value);
  }

  onClick(e: Event) {
    if (this.disabled) return;

    this.isChecked = true;

    if (e.target === this.inputElement.nativeElement) return;

    this.inputElement.nativeElement.click();
  }

  writeValue(value: any): void {
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
