import {Injectable} from '@angular/core';
import {HttpClient as Http} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

import {BehaviorSubject, Observable, Subject, of, tap} from 'rxjs';

import {AuthService} from '../../../../auth/auth.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {BlocksManagerService} from '../../../../core/services/blocks/blocks.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {IFrameRoutingService} from '../../../../core/services/iframe/routing/iframe-routing.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {WebsiteBlocksService} from '../../../../core/services/websites/blocks/website-blocks.service';

import {OutdatedBlocksDataModel} from '../../../../core/models/outdated-blocks-data/outdated-blocks-data.model';
import {SelectedPageModel} from '../../../../core/models/selected-page/selected-page.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';

@Injectable()
export class BlocksService {
  private templateId: number = null;

  private portfolioCategories = [
    'Portfolio',
    'Portfolio Intros',
    'Portfolio Zoom',
    'Exhibition Portfolios',
    'Menus',
  ];

  private defaultPortfolioCategories = [
    'Portfolio',
    'Portfolio Zoom',
    'Exhibition Portfolios',
  ];

  private slidePortfolioCategories = [
    'Portfolio Intros',
    'New Slideshow',
    'Text / Info',
    'Images',
    'Videos',
    'Audio',
    'Dividers',
    'CV',
    'Upcoming',
    'Statements',
    'Exhibitions',
    'Publications',
    'Awards / Distinctions',
    'Press',
    'Info',
    'Buttons',
  ];

  private blocksWithSlidesIds = [
    23,
    72,
    98,
    99,
    135,
  ];

  public isArtistBlocksEnabled: boolean = true;

  blocksPromise = null;

  // blocks categories
  droppedBlocksCategories: any = { };

  // available to drag-n-drop blocks
  private _availableBlocksSubject: any = {};
  availableBlocksSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this._availableBlocksSubject);
  toggleLoadingSubject: Subject<any> = new Subject();

  // current page
  openedPageType = '';
  openedTemplate: number;
  openedPageId: number;

  private droppedBlocksIds: number[] = [];

  // existing blocks categories
  existingBlocks = null;
  existingBlocksCategories: any = [];
  public blocksToMoveToAnotherPage = [];

  public selectedPage: SelectedPageModel;

  public get isSlidePortfolio(): boolean {
    return this.openedPageType === 'S' ? false : this.droppedBlocksIds.some(id => this.blocksWithSlidesIds.includes(id));
  }

  public get isExhibitionPortfolio(): boolean {
    return this.droppedBlocksIds.some(id => id === 135);
  }

  public get isPortfolioZoom(): boolean {
    return this.droppedBlocksIds.some(id => id === 99);
  }

  constructor(
    private httpClient: Http,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private iFrameService: IFrameService,
    private iFrameRoutingService: IFrameRoutingService,
    private blocksManagerService: BlocksManagerService,
    private websiteBlocksService: WebsiteBlocksService,
    private websitesService: WebsitesService,
    private templatesService: TemplatesService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.blocksToMoveToAnotherPage = [];

      const pageId: number = Number.parseInt(params.id, 10);
      const pageType: string = params.type;
      const isSplash: boolean = params.isSplash === 'true';

      this.updateExistingBlocksCategories();
      this.updatePageData(null, pageId, pageType, isSplash);
    });

    this.authService.userSubject.subscribe(user => this.updateExistingBlocksCategories(user, true));

    let isFirstPageFetch = true;

    this.iFrameRoutingService.selectedPageSubject.subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;

      if (!selectedPage || !selectedPage.id) return;

      if (isFirstPageFetch) {
        isFirstPageFetch = false;

        return;
      }

      this.fetchOutdatedBlocks();
    });

    this.websitesService.activeWebsiteSubject.subscribe(website => {
      if (!website) return this.blocksManagerService.outdatedBlocksSubject.next(null);

      this.fetchOutdatedBlocks();
    });

    this.blocksManagerService.onBlocksListChange.subscribe((blocksIds: number[]) => {
      if (blocksIds === void 0) return;

      this.droppedBlocksIds = blocksIds;

      this.updateAvailableBlocksCategories();
    });

    this.templatesService.activeTemplateSubject.subscribe((template: TemplateModel) => {
      this.templateId = template ? template.id : null;
    });
  }

  // TODO: refactor with reactive style
  loadBlocks(force?: boolean): Observable<any> {
    if (!this.blocksPromise || force) {
      this.blocksPromise = this.httpClient.get('/api/app/blocks');
    }

    return this.blocksPromise;
  }

  private updateExistingBlocksCategories(user?, force?: boolean) {
    if (!user) {
      return;
    }

    return this.getCategories(force).subscribe(categories => {
      this.existingBlocksCategories = categories.map(category => {
        return {
          id: category.id,
          name: category.category,
        };
      });
    });
  }

  public getCategories(force?: boolean): Observable<any> {
    if (!force && this.existingBlocks) {
      return of(this.existingBlocks);
    }

    return this.loadBlocks(force).pipe(
      tap(data => {
        this.existingBlocks = data;
      })
    );
  }

  updatePageData(pageTemplate: number, pageId: number, pageType: string, isSplash: boolean) {
    this.openedPageType = isSplash ? 'S' : pageType;
    this.openedTemplate = pageTemplate;
    this.openedPageId = pageId;

    this.updateAvailableBlocksCategories();
  }

  updateDroppedBlocks(categories: any) {
    this.droppedBlocksCategories = categories;
    
    this.updateAvailableBlocksCategories();
  }

  updateAvailableBlocksCategories() {
    this.toggleLoadingSubject.next({key: 'updating', value: true});

    const availableBlocks = this.getAvailableBlocksCategories();

    this.availableBlocksSubject.next(availableBlocks);
  }

  getAvailableBlocksCategories(): any {
    const artistBlockCategories = ['cv', 'upcoming', 'statements', 'exhibitions', 'publications', 'awards / distinctions', 'press', 'info'];

    const categories = this.existingBlocksCategories.reduce((res, category) => {
      res[category.name] = {
        available: true,
        isArtistBlockCategory: artistBlockCategories.includes(category.name.toLowerCase()),
      };
      return res;
    }, {});

    const availableCategoriesMap = this.pageTypeFilter(categories);
    // Includes 'category':true/false to show available categories
    const availableCategoriesValues = this.blockCountFilter(categories);

    return {
      availableMap: availableCategoriesMap,
      available: availableCategoriesValues,
    };
  }

  pageTypeFilter(categories) {
    this.isArtistBlocksEnabled = true;

    if (this.openedPageType !== 'S' && this.isExhibitionPortfolio) return this.filterExhibitionPortfolio(categories);

    switch (this.openedPageType) {
      case 'P':
        return this.filterPortfolioPage(categories);
      case 'C':
        return this.filterCategoryPage(categories);
      case 'S':
        return this.filterSplashPage(categories);
      case 'T':
        return this.filterTextPage(categories);
      case 'E':
        return this.filterBlankPage(categories);
      case 'A':
        return this.filterTextPage(categories);
      case 'M':
        return this.filterTextPage(categories);
      case 'H':
        return this.filterHomePage(categories);
    }

    return [];
  }

  private filterExhibitionPortfolio(categories) {
    const enabledCategories = [
      'Awards / Distinctions',
      'CV',
      'Dividers',
      'Exhibition Portfolios',
      'Exhibitions',
      'Footers',
      'Images',
      'Info',
      'Portfolio Intros',
      'Press',
      'Publications',
      'Statements',
      'Text / Info',
      'Upcoming',
      'Buttons',
      'Videos',
      'Audio',
      'Exhibition Index',
    ];

    if (this.openedPageType === 'P') {
      enabledCategories.push('Portfolio');
      enabledCategories.push('Portfolio Zoom');
    }

    this.isArtistBlocksEnabled = true;

    Object.keys(categories).forEach(category => {
      categories[category].available = enabledCategories.includes(category);
    });

    return categories;
  }

  private filterHomePage(categories) {
    const disabledCategories = [
      'New Slideshow',
      'Splash',
      'Category',
      'Portfolio Zoom',
      'Exhibition Index',
    ];

    return this.filterCategories(categories, disabledCategories);
  }

  private filterTextPage(categories) {
    const disabledCategories = [
      'Portfolio',
      'Portfolio Index',
      'Portfolio Intros',
      'New Slideshow',
      'Splash',
      'Category',
      'Portfolio Zoom',
      'Exhibition Index',
    ];

    return this.filterCategories(categories, disabledCategories);
  }

  private filterBlankPage(categories) {
    const disabledCategories = [
      'New Slideshow',
      'Splash',
      'Category',
      'Portfolio Zoom',
      'Exhibition Index',
    ];

    return this.filterCategories(categories, disabledCategories);
  }

  private filterCategories(categories, disabledCategories) {
    const filteredCategories = { ...categories };

    Object.keys(filteredCategories).forEach(category => {
      filteredCategories[category].available = disabledCategories.includes(category) ? false : filteredCategories[category].available;
    });

    return filteredCategories;
  }

  filterPortfolioPage(categories) {
    const isSlidePortfolio = this.isSlidePortfolio;

    const availableCategories = [
      ...this.portfolioCategories,
      ...isSlidePortfolio ? this.slidePortfolioCategories : [],
    ];

    this.isArtistBlocksEnabled = isSlidePortfolio;

    Object.keys(categories).forEach(category => {
      if (categories[category].available) {

        if (!availableCategories.includes(category)) {
          categories[category].available = false;
        }
      }
    });

    return categories;
  }

  filterCategoryPage(categories) {
    const filteredCategories = categories;
    const availableCategories = [
      'Menus',
      'Portfolio Index',
      'Images',
      'Videos',
      'Audio',
      'Parallax',
      'Text / Info',
      'Dividers',
      'Footers',
      'Buttons',
    ];

    this.isArtistBlocksEnabled = false;

    Object.keys(filteredCategories).forEach((category) => {
      if (filteredCategories[category].available) {
        if (!availableCategories.includes(category)) {
          filteredCategories[category].available = false;
        }
      }
    });

    return filteredCategories;
  }

  filterSplashPage(categories) {
    const filteredCategories = categories;
    const availableCategories = [
      'Splash'
    ];

    Object.keys(filteredCategories).forEach((category) => {
      if (filteredCategories[category].available) {
        if (!availableCategories.includes(category)) {
          filteredCategories[category].available = false;
        }
      }
    });

    return filteredCategories;
  }

  blockCountFilter(categories) {
    const filteredCategories = [];

    Object.keys(categories).forEach((category) => {
      const categoryData = categories[category];

      filteredCategories[category] = {
        available: categoryData.available,
        isArtistBlockCategory: categoryData.isArtistBlockCategory,
        disabled: false,
      };
    });

    return filteredCategories;
  }

  public convertBlockDataToSave(blocks, page?) {
    return blocks.map(item => {
      return {
        PageID: item.data.Block_Category === 'Menus' ? null : page && page.id || this.openedPageId,
        PageType: item.data.Block_Category === 'Menus' ? null : page && page.type || this.openedPageType,
        TemplateID: this.templateId,
        WebsiteID: this.websitesService.activeWebsiteId,
        Block_HTML: item.html,
        Block_Category: item.data.Block_Category,
        Options: item.data.Options || item.options,
        Name: item.data.Name,
        Version: item.data.Version,
        BlockTemplateId: item.data.BlockTemplateId,
        RootPageType: item.data.RootPageType,
        UsedFonts: this.getBlockUsedFonts(item),
        CreatedAt: item.data.CreatedAt,
      };
    });
  }

  private getBlockUsedFonts(block: any): string {
    if (!block || !block.data.UsedFonts) return null;

    if (typeof block.data.UsedFonts === 'string') return block.data.UsedFonts;

    return block.data.UsedFonts.filter((value, index, self) => self.indexOf(value) === index).join(';') || null;
  }

  public fetchOutdatedBlocks() {
    return this.websiteBlocksService.getOutdatedBlocks({ websiteId: this.websitesService.activeWebsiteId }).subscribe((res: OutdatedBlocksDataModel) => {
      this.blocksManagerService.outdatedBlocksSubject.next(new OutdatedBlocksDataModel(res.blocks));
    });
  }

  public getDefaultPortfolio(): { blockTemplateId: number, blockCategory: string, name: string, version: number, usedFonts: string, html: string } {
    const blocks = this.getBlocksToSave(true);

    const defaultPortfolio = blocks.find(block => block.data.Block_Category === 'Portfolio');

    if (!defaultPortfolio) return null;

    return {
      blockTemplateId: Number.parseInt(defaultPortfolio.data.BlockTemplateId),
      blockCategory: defaultPortfolio.data.Block_Category,
      name: defaultPortfolio.data.Name,
      version: defaultPortfolio.data.Version ? Number.parseInt(defaultPortfolio.data.Version) : null,
      usedFonts: defaultPortfolio.data.UsedFonts,
      html: defaultPortfolio.html,
    };
  }

  private getBlocksToSave(isDefaultPortfolio: boolean) {
    return (<any>this.iFrameService.sandboxWindow).getBlocksToSave({ isDefaultPortfolio });
  }
}
