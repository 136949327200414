import {Component, Input, Output, EventEmitter, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PortfolioPickerModalService} from '../../../../services/portfolio-picker-modal.service';
import {ImagesCounterService} from '../../../../core/services/image-manager/counters/images-counter.service';

import {ImagesCounterModel} from '../../../../core/models/image-manager/counters/images-counter.model';
import {NodesService} from '../../../../core/services/nodes/nodes.service';

@Component({
  selector: 'app-portfolio-picker-modal',
  templateUrl: './portfolio-picker-modal.component.html',
  styleUrls: ['./portfolio-picker-modal.component.scss']
})
export class PortfolioPickerModalComponent implements OnDestroy {
  @Input() id = 'portfolio-picker-modal';
  @Output() clickHandler = new EventEmitter();

  selectedPortfolio = null;
  portfolios = null;
  ngUnsubscribe = new Subject();

  public header = {
    className: 'warning-header',
  };

  private counters: ImagesCounterModel;

  constructor(private portfolioPickerModalService: PortfolioPickerModalService,
              private nodesService: NodesService,
              private imagesCounterService: ImagesCounterService) {
    this.imagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      this.counters = counters;
    });

    nodesService
      .nodesSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(pages => {
        if (pages) {
         this.portfolios = pages.filter(page => page.type === 'P');
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
  }

  public onOpen($portfolio) {
    try {
      if (!$portfolio) return;

      const options = $portfolio.attr('data-options');

      const { portfolioId } = JSON.parse(options);

      this.selectedPortfolio = this.portfolios.find(portfolio => portfolio.id === portfolioId);
    } catch (e) {}
  }

  public handleClick(portfolio) {
    if (portfolio && !this.isPortfolioHasPublishedImages(portfolio.id)) return;

    if (!portfolio) return this.portfolioPickerModalService.close();

    this.selectedPortfolio = portfolio;

    const portfolioId = portfolio ? portfolio.id : null;

    this.portfolioPickerModalService.close(portfolioId);

    this.clickHandler.emit(portfolioId);

    this.selectedPortfolio = null;
  }

  public isPortfolioHasPublishedImages(id: number): boolean {
    return this.counters && !!this.counters.published[id];
  }
}

