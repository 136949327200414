import {SetupOptionModel} from '../../option/setup-option.model';

import {PROPERTY_NAME, FONT_WEIGHT_MAPPING} from './constants';

export class BaseFontWeightModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public stylePropertyName: string = null,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.initValue();
  }

  public setValue(value: string): void {
    if (!this.isEnabled) return;

    this.value = value;
  }

  public onChange(value: string): void {
    if (!this.isEnabled) return;

    this.value = value;

    this.element.style.setProperty(this.stylePropertyName, value);
  }

  public reset(): void {
    this.element.style.removeProperty(this.stylePropertyName);

    this.initValue();
  }

  private initValue(): void {
    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.value = computedStyles.getPropertyValue(this.stylePropertyName) || computedStyles.getPropertyValue(PROPERTY_NAME);

    if (FONT_WEIGHT_MAPPING[this.value]) this.value = FONT_WEIGHT_MAPPING[this.value];
  }
}
