export const PUBLISH_STATUSES = {
  WAITING_FOR_CHANGES: 'WAITING_FOR_CHANGES',
  READY: 'READY',
  STARTED: 'STARTED',
  PREPARING_DATA: 'PREPARING_DATA',
  PAGES_GENERATING: 'PAGES_GENERATING',
  BUILDING: 'BUILDING',
  UPLOADING: 'UPLOADING',
  DONE: 'DONE',
  FAILED: 'FAILED',
  NEW_SESSION_FAILED: 'NEW_SESSION_FAILED',
};

export const SOCKET_PUBLISHING_STATUSES: string[] = [
  PUBLISH_STATUSES.STARTED,
  PUBLISH_STATUSES.PREPARING_DATA,
  PUBLISH_STATUSES.PAGES_GENERATING,
  PUBLISH_STATUSES.BUILDING,
  PUBLISH_STATUSES.UPLOADING,
];

export const WEBSITE_PUBLISHING_STATUSES: string[] = [
  'PUBLISHING',
];

export const PUBLISH_BUTTON_STATES = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  SUCCESS: 'SUCCESS',
  PUBLISHING: 'PUBLISHING',
};

export const SOCKET_STATUSES_TO_BUTTON_STATES = {
  [PUBLISH_STATUSES.WAITING_FOR_CHANGES]: PUBLISH_BUTTON_STATES.DISABLED,
  [PUBLISH_STATUSES.READY]: PUBLISH_BUTTON_STATES.ENABLED,
  [PUBLISH_STATUSES.STARTED]: PUBLISH_BUTTON_STATES.PUBLISHING,
  [PUBLISH_STATUSES.PREPARING_DATA]: PUBLISH_BUTTON_STATES.PUBLISHING,
  [PUBLISH_STATUSES.PAGES_GENERATING]: PUBLISH_BUTTON_STATES.PUBLISHING,
  [PUBLISH_STATUSES.BUILDING]: PUBLISH_BUTTON_STATES.PUBLISHING,
  [PUBLISH_STATUSES.UPLOADING]: PUBLISH_BUTTON_STATES.PUBLISHING,
  [PUBLISH_STATUSES.DONE]: PUBLISH_BUTTON_STATES.SUCCESS,
  [PUBLISH_STATUSES.FAILED]: PUBLISH_BUTTON_STATES.ENABLED,
  [PUBLISH_STATUSES.NEW_SESSION_FAILED]: PUBLISH_BUTTON_STATES.ENABLED,
};

export const START_PUBLISH_MESSAGE = `Your changes are now being published to your live site. This typically takes less than a minute. There is a green progress bar at the top of your app. While publishing is in progress you may continue to work on your app. We will notify you when your website is ready for viewing!`;
export const PUBLISH_ERROR_MESSAGE = `Your website wasn't published. An email has been sent to our support team and will be contacting you shortly.`;
export const NEW_SESSION_PUBLISH_ERROR_MESSAGE = `Saved Changes to your website were unable to be published during your previous session. Please try to republish before continuing to edit your website.`;
export const PUBLISH_SUCCESS_MESSAGE = `Great job! Your website was successfully published! Press the VIEW WEBSITE button at the top of the screen to see the results of your efforts!`;

