import {GaAccountsModel} from './ga-accounts.model';

export class GaAccountsDto {
  constructor(public Id?: number,
              public AccountId?: string,
              public WebsitesCount?: number) {}

  public static normalize(res: GaAccountsDto): GaAccountsModel {
    if (!res) return null;

    return new GaAccountsModel(
      res.Id,
      res.AccountId,
      res.WebsitesCount,
    );
  }
}
