import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Observable, Subject, Subscriber, Subscription, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {FullscreenImageManagerEnlargementService} from '../../services/fullscreen-image-manager-enlargement/fullscreen-image-manager-enlargement.service';
import {EducationStudentsImagesService} from '../../../core/services/education/teachers/institutions/classes/students/images/education-students-images.service';
import {EducationStudentsImagesHttpService} from '../../../core/services/interaction/http/education/teachers/institutions/classes/students/images/education-students-images-http.service';
import {EducationImageManagerService} from '../../../core/services/education/image-manager/education-image-manager.service';
import {ImageDetailsService} from '../../../core/services/image-manager/image-details/image-details.service';
import {PortfolioDefaultsService} from '../../../core/services/image-manager/portfolio-defaults/portfolio-defaults.service';
import {ImageManagerService} from '../../../application/main/image-manager/image-manager.service';
import {ReviewStudentImageModalService} from '../../services/modals/education/review-student-image/review-student-image-modal.service';
import {SelectExhibitionModalService} from '../../services/modals/education/select-exhibition/select-exhibition-modal.service';
import {EducationWebsiteExhibitionsService} from '../../../core/services/education/teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';
import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {FullscreenService} from '../../../core/services/fullscreen/fullscreen.service';
import {ImageEditorCropService} from '../../services/image-editor/crop/image-editor-crop.service';
import {PaymentSubscriptionsService} from '../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {EducationStudentsService} from '../../../core/services/education/students/education-students.service';
import {AuthService} from '../../../auth/auth.service';
import {IFrameRoutingService} from '../../../core/services/iframe/routing/iframe-routing.service';
import {ImagesCounterService} from '../../../core/services/image-manager/counters/images-counter.service';
import {CanvasService} from '../../../core/services/canvas/canvas.service';
import {PortfolioViewsService} from '../../../core/services/portfolios/views/portfolio-views.service';
import {ReviewsListModalService} from '../../services/modals/education/reviews-list/reviews-list-modal.service';
import {StudentImageManagerService} from '../../../core/services/education/students/image-manager/student-image-manager.service';
import {StudentPortfoliosService} from '../../../core/services/education/students/websites/portfolios/student-portfolios.service';
import {EducationTeachersStudentsService} from '../../../core/services/education/teachers/institutions/classes/students/education-students.service';

import {ImageModel} from '../../../core/models/images/image.model';
import {StudentImageReviewModel} from '../../../core/models/images/review/student-image-review.model';
import {EducationStudentPortfolioModel} from '../../../core/models/education/portfolios/education-student-portfolio.model';
import {PortfolioDefaultsModel} from '../../../core/models/images/default/portfolio-defaults.model';
import {PortfolioModel} from '../../../core/models/portfolios/portfolio.model';
import {IPermissionData} from '../../../core/models/permission/i-permission-data';
import {ImageEditorImageUpdateModel} from '../../models/image-editor/image-editor-crop.model';
import {ImageUpdateSuggestionModel} from '../../../core/models/education/image-update-suggestion/image-update-suggestion.model';
import {SubscriptionModel} from '../../../core/models/payment/subscriptions/subscription.model';
import {AccountModel} from '../../../core/models/accounts/account.model';
import {SelectedPageModel} from '../../../core/models/selected-page/selected-page.model';
import {IUpdateResponse} from '../../../core/models/responses/i-update-response';
import {INewReviewsData} from '../../../core/models/education/students/images/reviews/i-new-reviews-data.model';
import {ImageDataModel} from '../../../core/models/image-manager/counters/image-data.model';
import {EducationStudentModel} from '../../../core/models/education/students/education-student.model';

import {EducatorImageManagerTabs} from '../../../core/services/education/image-manager/constants';
import {ChangeTypes} from '../../../core/services/fullscreen/constants';
import {StudentImageManagerTabs} from '../../../core/services/education/students/image-manager/constants';

import {PERMISSIONS} from '../../../core/services/service-permissions/constants';
import {SOCKET_ACTIONS} from '../../../application/main/image-manager/constants';
import {LIBRARY_ID} from '../../../application/constants';

import {CursorKeys} from '../../services/image-editor/crop/constants';

import {environment} from '../../../../environments/environment';

import {AppAnimations} from '../../../app-animations';

@Component({
  selector: 'app-fullscreen-image-manager-enlargement',
  templateUrl: './fullscreen-image-manager-enlargement.component.html',
  styleUrls: ['./fullscreen-image-manager-enlargement.component.scss'],
  animations: [
    AppAnimations.fadeIn(500),
    AppAnimations.fadeIn(200, 'fadeIn200'),
  ],
})
export class FullscreenImageManagerEnlargementComponent implements OnInit, OnDestroy {
  @ViewChild('imageElement') imageElement: ElementRef;

  public view: string = '';

  public image: ImageModel;
  public imageLabel: ImageModel;
  public review: StudentImageReviewModel;
  public studentReview: StudentImageReviewModel;

  public src: string = '';
  public srcset: string = '';

  public currentIndex: number;
  public totalNumberOfImages: number;

  public hoveredStarIndex: number = 0;

  public suggestions: ImageUpdateSuggestionModel[];
  public hoveredEditSuggestion: ImageUpdateSuggestionModel;
  public selectedEditSuggestion: ImageUpdateSuggestionModel;

  public cursorKey: CursorKeys;
  
  public rotate: number = 0;
  public initialRotate: number = 0;
  public angle: number = 0;
  public angleFormatted: string = '0';

  public newReviewsData: INewReviewsData;

  public isEducator: boolean = false;
  public isStudent: boolean = false;

  public isLoading: boolean = true;
  public isSuggestionsLoading: boolean = false;
  public isImageUpdateInProgress: boolean = false;
  public isCropStarted: boolean = false;
  public isLibrary: boolean = false;
  public isEnlarged: boolean = false;
  public isImageLabelOpened: boolean = false;
  public isImageEditMode: boolean = false;
  public isImageRotateMode: boolean = false;
  public isSuggestionsMode: boolean = false;
  public isSelectedImageExhibited: boolean = false;
  public isExhibitButtonBlocked: boolean = false;
  public isExhibitButtonDone: boolean = false;
  public isImageEditButtonBlocked: boolean = false;
  public isImageEditSuggested: boolean = false;
  public isImageEditFailed: boolean = false;
  public isReviewStarsBlocked: boolean = false;
  public isImageRatingPermitted: boolean = false;
  public isImageEditorPermitted: boolean = false;
  public isImageEditSuggestionsPermitted: boolean = false;
  public isImageReviewsPermitted: boolean = false;

  public isStudentTab: boolean = false;
  public isStudentsTab: boolean = false;
  public isExhibitionsTab: boolean = false;
  public isUserTab: boolean = false;

  private account: AccountModel;

  private websiteId: number;

  private selectedPage: SelectedPageModel;

  private activeTeacherTab: EducatorImageManagerTabs;
  private activeStudentTab: StudentImageManagerTabs;

  private selectedStudent: EducationStudentModel;

  private studentPortfolio: PortfolioModel;

  private teacherExhibitions: PortfolioModel[];

  private educatorStudentPortfolio: EducationStudentPortfolioModel;

  private portfolioDefaults: PortfolioDefaultsModel = null;

  private currentImageExhibitedImages: ImageModel[] = null;

  private imageCropData: ImageEditorImageUpdateModel;

  private rect: any;
  private centerX: number;
  private centerY: number;
  private startAtAngle: number;

  private forceSuggestionsView: { userId: number } = null;
  private isDestroyed: boolean = false;

  private handlers = {
    resize: this.onResize.bind(this),
    keydown: this.onKeyDown.bind(this),
    mouseup: this.onMouseUp.bind(this),
    mousemove: this.onMouseMove.bind(this),
  };

  private keyHandlers = {
    'Escape': this.onEsc.bind(this),
    'ArrowLeft': this.onPrev.bind(this),
    'ArrowRight': this.onNext.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  
  public get title(): string {
    if (!this.imageLabel) {
      return 'Title not set';
    }

    if (this.imageLabel.isTitleDefault) {
      return this.portfolioDefaults ? this.portfolioDefaults.title : '';
    }

    return this.imageLabel.title;
  }
  
  public get rotateString(): string {
    if (this.hoveredEditSuggestion) {
      return this.hoveredEditSuggestion.rotate ? `rotate(${this.hoveredEditSuggestion.rotate}deg)` : null;
    }
    
    if (this.selectedEditSuggestion) {
      return this.selectedEditSuggestion.rotate ? `rotate(${this.selectedEditSuggestion.rotate}deg)` : null;
    }
    
    return `rotate(${this.rotate}deg)`;
  }

  public get isSelectedEditSuggestionRelatedToCurrentUser(): boolean {
    if (!this.account || !this.selectedEditSuggestion) {
      return false;
    }

    return this.account.id === this.selectedEditSuggestion.suggestedByUserId;
  }

  public get isCropButtonVisible(): boolean {
    if (this.isStudent && this.isStudentTab) {
      return true;
    }

    if (!this.isImageEditorPermitted) {
      return false;
    }

    return this.isEducator && this.isStudentsTab && !this.isLibrary;
  }
  
  constructor(
    public service: FullscreenImageManagerEnlargementService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private imageDetailsService: ImageDetailsService,
    private imageManagerService: ImageManagerService,
    private portfolioDefaultsService: PortfolioDefaultsService,
    private educationImageManagerService: EducationImageManagerService,
    private educationWebsiteExhibitionsService: EducationWebsiteExhibitionsService,
    private educationTeacherStudentsService: EducationTeachersStudentsService,
    private educationStudentsImagesService: EducationStudentsImagesService,
    private educationStudentsImagesHttpService: EducationStudentsImagesHttpService,
    private studentImageManagerService: StudentImageManagerService,
    private reviewStudentImageModalService: ReviewStudentImageModalService,
    private selectExhibitionModalService: SelectExhibitionModalService,
    private imageEditorCropService: ImageEditorCropService,
    private educationStudentsService: EducationStudentsService,
    private imagesCounterService: ImagesCounterService,
    private studentPortfoliosService: StudentPortfoliosService,
    private fullscreenService: FullscreenService,
    private canvasService: CanvasService,
    private reviewsListModalService: ReviewsListModalService,
    private portfolioViewsService: PortfolioViewsService,
    private iFrameRoutingService: IFrameRoutingService,
    private permissionsService: PermissionsService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
    window.addEventListener('keydown', this.handlers.keydown);
  }

  public ngOnInit(): void {
    this.initPermissions();

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isEducator = subscription ? subscription.isEducator : false;
    });
    
    this.service.currentImageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((image: ImageModel) => {
      this.image = image;

      this.handleLoadSuggestions();
      
      this.initStudentImageReviews();

      if (!image) {
        return;
      }
      
      this.initSrcSet();
      this.initImageReview();

      if (this.forceSuggestionsView) {
        this.onImageEditButtonClick();
        this.onSuggestionsClick({ forceSelectMostRecent: true, suggestionAddedByUserId: this.forceSuggestionsView.userId });

        this.forceSuggestionsView = null;
      }
    });

    this.service.currentImageIndexSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((index: { current: number, total: number }) => {
      this.currentIndex = index ? index.current : 0;
      this.totalNumberOfImages = index ? index.total : 0;
    });

    this.studentPortfoliosService.selectedPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolio: PortfolioModel) => {
      this.studentPortfolio = portfolio;
    });

    this.imageManagerService.viewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ key, forceSuggestionsView }: { key: string, forceSuggestionsView?: { userId: number } }) => {
      const isSameKey: boolean = this.view === key;

      this.view = key;

      this.forceSuggestionsView = forceSuggestionsView;

      if (isSameKey || this.view !== 'thumbnails') {
        return;
      }
     
      this.close(); 
    });

    this.imageDetailsService.imageDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((imageLabel: ImageModel) => {
      this.imageLabel = imageLabel;
    });

    this.educationImageManagerService.studentPortfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isStudentsTab) return;
      
      this.portfolioDefaults = portfolioDefaults;
    });

    this.educationImageManagerService.exhibitionPortfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isExhibitionsTab) return;
      
      this.portfolioDefaults = portfolioDefaults;
    });

    this.selectExhibitionModalService.onSelect.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedExhibition: PortfolioModel) => {
      if (!selectedExhibition || !this.isStudentsTab) {
        return;
      }

      this.onImageExhibit(selectedExhibition.id, { isSingle: false });
    });

    this.portfolioDefaultsService.portfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isUserTab) return;

      this.portfolioDefaults = portfolioDefaults;
    });

    this.studentImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: StudentImageManagerTabs) => {
      this.activeStudentTab = key;

      this.isStudentTab = this.activeStudentTab === 'student';
    });
    
    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      this.activeTeacherTab = key;

      this.isStudentsTab = this.activeTeacherTab === 'students';
      this.isExhibitionsTab = this.activeTeacherTab === 'exhibitions';
      this.isUserTab = this.activeTeacherTab === 'user';

      this.initIsLibrary();
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;

      this.initIsLibrary();
    });
    
    this.educationWebsiteExhibitionsService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((exhibitions: PortfolioModel[]) => {
      this.teacherExhibitions = exhibitions;
    });

    this.educationStudentsImagesService.currentImageReviewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((review: StudentImageReviewModel) => {
      this.review = review;

      if (this.review) {
        return;
      }

      this.educationStudentsImagesService.initEmptyReview(this.image ? this.image.id : null);
    });

    this.educationStudentsImagesService.currentImageExhibitedImagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      this.currentImageExhibitedImages = images;

      this.initIsSelectedImageExhibited();
    });

    this.educationImageManagerService.selectedStudentPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((studentPortfolio: EducationStudentPortfolioModel) => {
      this.educatorStudentPortfolio = studentPortfolio;

      this.handleLoadSuggestions();
    });

    this.fullscreenService.isEnlargedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ value, changeType }: { value: boolean, changeType?: ChangeTypes }) => {
      if (this.isEnlarged && !value && changeType === 'keyboard') {
        this.onEsc();
      }

      this.isEnlarged = value;
    });

    this.imageEditorCropService.cursorSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cursor: CursorKeys) => {
      this.cursorKey = cursor;
    });

    this.portfolioViewsService.newReviewsDataSubject.subscribe((newReviewsData: INewReviewsData) => {
      this.newReviewsData = newReviewsData;
    });

    this.selectExhibitionModalService.isExhibitButtonBlocked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isBlocked: boolean) => {
      return isBlocked ? this.blockExhibitButton() : this.unblockExhibitButton();
    });

    this.educationTeacherStudentsService.studentDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedStudent: EducationStudentModel) => {
      this.selectedStudent = selectedStudent;

      this.handleOpenedImage();
    });
  }

  private initPermissions(): void {
    const imageRatingPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.IMAGE_RATING,
    };

    const imageEditorPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.IMAGE_EDITOR,
    };

    const imageEditSuggestionsPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.IMAGE_EDIT_SUGGESTIONS,
    };

    const imageReviewsPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.IMAGE_REVIEWS,
    };

    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([imageRatingPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isImageRatingPermitted = isPermitted;
    });

    this.permissionsService.isUserHasPermissionsObservable([imageEditorPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isImageEditorPermitted = isPermitted;
    });

    this.permissionsService.isUserHasPermissionsObservable([imageEditSuggestionsPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isImageEditSuggestionsPermitted = isPermitted;
    });

    this.permissionsService.isUserHasPermissionsObservable([imageReviewsPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isImageReviewsPermitted = isPermitted;

      this.initStudentImageReviews();
    });

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
    });
  }

  private initIsLibrary(): void {
    this.isLibrary = this.selectedPage && this.isUserTab ? this.selectedPage.id === -1 : false;
  }

  private initSrcSet(): void {
    if (!this.image) {
      this.src = null;
      this.srcset = null;

      return;
    }

    const keys: string[] = Object.keys(this.image.sizesUrls);

    this.src = `${environment.protocol}${this.image.sizesUrls['null']}`;

    this.srcset = keys.reduce((res: string, key: string, idx: number) => {
      return res += `${environment.protocol}${this.image.sizesUrls[key]} ${key}${idx === keys.length - 1 ? '' : 'w, '}`;
    }, '');
  }

  private initImageReview(): void {
    if (!this.isStudentsTab) {
      return;
    }

    if (!this.image || !this.educatorStudentPortfolio || this.image.portfolioId !== this.educatorStudentPortfolio.id) {
      this.educationStudentsImagesService.initEmptyReview(this.image ? this.image.id : null);

      return;
    }

    this.educationStudentsImagesService.getReview(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      this.image.id,
    );

    this.educationStudentsImagesService.getExhibitedImages(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      this.image.id,
    );
  }

  private initStudentImageReviews(): void {
    if (this.view === 'thumbnails' || this.view === 'large') {
      return;
    }

    if (!this.isImageReviewsPermitted || !this.image || !this.image.reviews || this.image.reviews.length === 0) {
      this.studentReview = null;

      return;
    }

    this.studentReview = new StudentImageReviewModel(
      null,
      this.image.id,
      null,
      null,
      this.image.reviews.reduce((res, curr) => res + curr.rate, 0) / this.image.reviews.length,
      null,
      null,
    );

    this.studentReview.isRated = this.studentReview.rate > 0;
    this.studentReview.isCommented = this.image.reviews.some((review: StudentImageReviewModel) => review.text && review.text.length > 0);
    this.studentReview.hasAudio = this.image.reviews.some((review: StudentImageReviewModel) => review.audios && review.audios.length > 0);
    this.studentReview.hasUpdateSuggestion = this.image.reviews.some((review: StudentImageReviewModel) => review.imageUpdateSuggestions && review.imageUpdateSuggestions.length > 0);
  }

  private initIsSelectedImageExhibited(): void {
    this.isSelectedImageExhibited = this.currentImageExhibitedImages && this.currentImageExhibitedImages.length > 0;
  }

  private handleOpenedImage(): void {
    if (!this.image || !this.isEducator || !this.selectedStudent) {
      return;
    }

    const isImagePortfolioAllowed: boolean = this.selectedStudent.portfolios.findIndex((portfolio: PortfolioModel) => {
      return portfolio.id === this.image.portfolioId;
    }) !== -1;

    if (isImagePortfolioAllowed) {
      return;
    }

    this.onEsc();
  }
  
  public onKeyDown(e: KeyboardEvent): void {
    if (!this.keyHandlers[e.key]) {
      return;
    }

    this.keyHandlers[e.key](e);
  }

  public onEsc(): void {
    if (this.isImageRotateMode) {
      this.onMouseUp();
    }

    if (this.isSuggestionsMode) {
      this.onSuggestionsClick();

      return;
    }

    if (this.isImageEditMode) {
      this.onImageEditButtonClick();

      if (this.isEnlarged) {
        this.setView({
          key: 'full-view',
        });
      }

      return;
    }
    
    this.setView({ key: this.service.lastRegularViewKey });
  }

  public onPrev(): void {
    if (this.isLoading || this.isImageEditMode || this.isSuggestionsMode || this.totalNumberOfImages <= 1) {
      return;
    }

    this.isLoading = true;

    this.src = null;
    this.srcset = null;

    this.isImageLabelOpened = false;

    this.service.onPrev();
  }

  public onNext(): void {
    if (this.isLoading || this.isImageEditMode || this.isSuggestionsMode || this.totalNumberOfImages <= 1) {
      return;
    }
    
    this.isLoading = true;

    this.src = null;
    this.srcset = null;

    this.isImageLabelOpened = false;

    this.service.onNext();
  }

  public onInfoClick(): void {
    this.isImageLabelOpened = !this.isImageLabelOpened;

    this.cdr.detectChanges();
  }

  public onStarClick(idx: number): Subscription {
    if (this.isReviewStarsBlocked) {
      return Subscription.EMPTY;
    }

    this.isReviewStarsBlocked = true;

    this.review.rate = idx;

    return this.handleReviewSubmit().pipe(
      catchError(e => {
        console.error(e);
        
        alert(e.error && e.error.message ? e.error.message : 'Something went wrong.');

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.initImageReview();

      this.isReviewStarsBlocked = false;
    });
  }

  private handleReviewSubmit(): Observable<number> {
    const isEmpty: boolean = this.review.isEmpty;

    const req: Observable<StudentImageReviewModel | IUpdateResponse> = isEmpty ? this.addReview() : this.updateReview();

    return new Observable((subscriber: Subscriber<number>) => {
      req.subscribe((res: StudentImageReviewModel | IUpdateResponse) => {
        if (isEmpty) {
          subscriber.next((<StudentImageReviewModel>res).id);
          subscriber.complete();

          return;
        }

        subscriber.next(this.review.id);
        subscriber.complete();
      })
    });
  }

  private addReview(): Observable<StudentImageReviewModel> {
    return this.educationStudentsImagesService.addReview(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      this.image.id,
      this.review,
    );
  }

  private updateReview(): Observable<IUpdateResponse> {
    return this.educationStudentsImagesService.updateReview(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      this.image.id,
      this.review,
    );
  }

  public onStarMouseEnter(idx: number): void {
    this.hoveredStarIndex = idx;
  }

  public onStarMouseLeave(): void {
    this.hoveredStarIndex = 0;
  }

  public onReview(): void {
    if (!this.image || !this.educatorStudentPortfolio) {
      return;
    }

    this.reviewStudentImageModalService.dataSubject.next({
      image: this.image,
      studentPortfolio: this.educatorStudentPortfolio,
    });

    this.reviewStudentImageModalService.open({
      imageIndex: this.currentIndex,
    });
  }

  public onExhibit(): void {
    if (this.isExhibitButtonBlocked || !this.educatorStudentPortfolio || !this.image) {
      return;
    }

    this.selectExhibitionModalService.imageToClone = this.image;
    this.selectExhibitionModalService.studentPortfolio = this.educatorStudentPortfolio;
     
    if (this.teacherExhibitions.length === 1) {
      this.onImageExhibit(this.teacherExhibitions[0].id, { isSingle: true });

      return;
    }

    this.selectExhibitionModalService.open();
  }

  private onImageExhibit(exhibitionId: number, { isSingle }: { isSingle: boolean }): void {
    this.selectExhibitionModalService.onImageExhibit(exhibitionId, { isSingle });
  }

  private blockExhibitButton(): void {
    this.isExhibitButtonBlocked = true;
    this.isExhibitButtonDone = true;

    this.cdr.detectChanges();
  }

  private unblockExhibitButton(): void {
    this.isExhibitButtonBlocked = false;

    window.setTimeout(() => {
      if (this.isDestroyed) {
        return;
      }

      this.isExhibitButtonDone = false;

      this.cdr.detectChanges();
    }, 2000);
  }

  public onImageEditSubmit(): void {
    if (this.isImageEditButtonBlocked || !this.isImageEditSuggestionsPermitted) {
      return;
    }

    if (!this.isStudent && this.isLibrary) {
      this.onImageEditFailed();

      return;
    }

    if (this.isEducator) {
      this.addUpdateSuggestion();

      return;
    }

    if (this.isStudent) {
      const pageId: number = this.studentPortfolio.id;

      this.blockImageEditButton();

      this.sendStudentSocketAction(SOCKET_ACTIONS.IMAGE_CROP_START, pageId);

      this.editImage(
        new ImageEditorImageUpdateModel(
          this.image,
          this.rotate,
          this.imageCropData.cropStartX,
          this.imageCropData.cropStartY,
          this.imageCropData.cropEndX,
          this.imageCropData.cropEndY
        )
      ).subscribe(() => {});
      
      return;
    }
  }

  private addUpdateSuggestion(): void {
    this.blockImageEditButton();

    const updateData: ImageUpdateSuggestionModel = new ImageUpdateSuggestionModel(
      null,
      this.image.id,
      null,
      null,
      null,
      null,
      this.rotate,
      this.imageCropData.cropStartX,
      this.imageCropData.cropStartY,
      this.imageCropData.cropEndX,
      this.imageCropData.cropEndY
    );

    this.review.imageUpdateSuggestions = this.review.imageUpdateSuggestions || [];

    this.review.imageUpdateSuggestions.push(updateData);
    
    this.handleReviewSubmit().subscribe((reviewId: number) => {
      updateData.reviewId = reviewId;

      this.initImageReview();
      
      this.educationStudentsImagesHttpService.addUpdateSuggestion(
        this.educatorStudentPortfolio.institutionId,
        this.educatorStudentPortfolio.classId,
        this.educatorStudentPortfolio.studentId,
        this.educatorStudentPortfolio.id,
        this.image.id,
        updateData
      ).pipe(
        catchError(e => {
          console.error(e);
          
          return throwError(() => e);
        })
      ).subscribe((createdSuggestion: ImageUpdateSuggestionModel) => {
        this.unblockImageEditButton();
        
        if (!createdSuggestion) {
          return;
        }
        
        this.handleLoadSuggestions({
          forceSelectById: true,
          suggestionId: createdSuggestion.id,
        });
      }, () => {
        this.onImageEditFailed();
      });
    });
  }

  private sendStudentSocketAction(action: string, pageId: number): void {
    if (Number.isNaN(pageId) || !this.isStudent) {
      return;
    }

    this.educationStudentsService.sendAction(
      action,
      this.account.id,
      this.studentPortfolio.websiteId,
      pageId,
    );
  }

  public applySuggestedCrop(): void {
    if (!this.isImageEditSuggestionsPermitted || !this.isImageEditorPermitted || !this.selectedEditSuggestion) {
      return;
    }
    
    const pageId: number = this.studentPortfolio.id;

    this.blockImageEditButton();

    this.sendStudentSocketAction(SOCKET_ACTIONS.IMAGE_CROP_START, pageId);

    this.editImage(
      new ImageEditorImageUpdateModel(
        this.image,
        this.selectedEditSuggestion.rotate,
        this.selectedEditSuggestion.cropStartX,
        this.selectedEditSuggestion.cropStartY,
        this.selectedEditSuggestion.cropEndX,
        this.selectedEditSuggestion.cropEndY
      )
    ).subscribe(() => {});
  }

  private editImage(data: ImageEditorImageUpdateModel): Observable<boolean> {
    const pageId: number = this.studentPortfolio.id;

    this.isImageUpdateInProgress = true;

    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      this.canvasService.editImage(this.src, data).subscribe((blob: Blob) => {
        this.educationStudentsService.sendImageAction(this.image.portfolioId, this.image.id, 'crop', blob).pipe(
          catchError(e => {
            console.error(e);
            
            return throwError(() => e);
          })
        ).subscribe(() => {
          this.service.onCurrentPortfolioReload.next();
  
          this.setView({ key: 'thumbnails' });
  
          this.sendStudentSocketAction(SOCKET_ACTIONS.IMAGE_CROP_STOP, pageId);

          this.imagesCounterService.onImageAdd(LIBRARY_ID, 1);
  
          this.service.close();
          
          subscriber.next(true);
          subscriber.complete();
        }, () => {
          this.onImageEditFailed();
  
          this.isImageUpdateInProgress = false;
  
          this.sendStudentSocketAction(SOCKET_ACTIONS.IMAGE_CROP_STOP, pageId);
          
          subscriber.next(false);
          subscriber.complete();
        });
      });
    });
  }

  private blockImageEditButton(): void {
    this.isImageEditButtonBlocked = true;
    this.isImageEditSuggested = true;

    this.cdr.detectChanges();
  }

  private unblockImageEditButton(): void {
    this.isImageEditButtonBlocked = false;

    window.setTimeout(() => {
      if (this.isDestroyed) {
        return;
      }

      this.isImageEditSuggested = false;

      this.cdr.detectChanges();
    }, 2000);
  }

  private onImageEditFailed(): void {
    this.isImageEditButtonBlocked = false;
    this.isImageEditFailed = true;

    window.setTimeout(() => {
      if (this.isDestroyed) {
        return;
      }

      this.isImageEditFailed = false;

      this.cdr.detectChanges();
    }, 2000);
  }

  public onImageEditCancel(): void {
    this.onImageEditButtonClick();
  }

  public onImageEditButtonClick(): void {
    this.isCropStarted = false;

    if (this.isStudent && !this.isImageEditMode && this.image && this.image.sourceImageId) {
      return this.uncrop();
    }

    this.isImageEditMode = !this.isImageEditMode;
    
    if (!this.isImageEditMode) {
      return this.onImageEditReset();
    }

    this.onResize();
  }

  private uncrop(): void {
    this.isImageUpdateInProgress = true;

    const pageId: number = this.studentPortfolio.id;
    
    this.sendStudentSocketAction(SOCKET_ACTIONS.IMAGE_UNCROP_START, pageId);

    this.educationStudentsService.sendImageAction(this.image.portfolioId, this.image.id, 'uncrop', null).pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      })
    ).subscribe((res: { id: number, portfolioId: number }) => {
      this.service.onCurrentPortfolioReload.next();

      this.setView({ key: 'thumbnails' });

      this.sendStudentSocketAction(SOCKET_ACTIONS.IMAGE_UNCROP_STOP, pageId);

      this.isImageUpdateInProgress = false;
      
      this.imagesCounterService.onImageRemove(res.portfolioId || LIBRARY_ID, [
        new ImageDataModel(res.id, false)
      ]);

      this.service.close();
    }, () => {
      this.onImageEditFailed();

      this.isImageUpdateInProgress = false;

      this.sendStudentSocketAction(SOCKET_ACTIONS.IMAGE_UNCROP_STOP, pageId);
    });
  }

  public onImageEditReset(): void {
    this.angle = 0;
    this.angleFormatted = '0';
    this.initialRotate = 0;
    this.rotate = 0;
    
    this.imageEditorCropService.resetSubject.next(true);
  }

  public onSuggestionsClick(options?: { forceSelectMostRecent?: boolean, suggestionAddedByUserId?: number }): void {
    this.isSuggestionsMode = !this.isSuggestionsMode;

    if (!this.isSuggestionsMode) {
      this.hoveredEditSuggestion = null;
      this.selectedEditSuggestion = null;
      
      return;
    }

    this.loadEditSuggestions(options);
  }

  public deleteEditSuggestion(): void {
    if (!this.isImageEditSuggestionsPermitted || !this.selectedEditSuggestion) {
      return;
    }
    
    this.isSuggestionsLoading = true;

    this.review.imageUpdateSuggestions = this.review.imageUpdateSuggestions.filter((suggestion: ImageUpdateSuggestionModel) => {
      return suggestion.id !== this.selectedEditSuggestion.id;
    });

    this.educationStudentsImagesHttpService.deleteUpdateSuggestion(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      this.image.id,
      this.selectedEditSuggestion.id
    ).pipe(
      catchError(e => {
        console.error(e);

        this.loadEditSuggestions();
        
        return throwError(() => e);
      }),
    ).subscribe(() => {
      this.selectedEditSuggestion = null;
    
      this.handleReviewSubmit().pipe(
        catchError(e => {
          console.error(e);
          
          return throwError(() => e);
        }),
        finalize(() => {
          this.loadEditSuggestions();
        }),
      ).subscribe(() => {
        this.initImageReview();
      });
    });
  }

  private handleLoadSuggestions(options?: { forceSelectMostRecent?: boolean, suggestionAddedByUserId?: number, forceSelectById?: boolean, suggestionId?: number }): void {
    if ((this.isEducator && !this.educatorStudentPortfolio) || !this.image) {
      return;
    }

    this.loadEditSuggestions(options);
  }

  private loadEditSuggestions(options?: { forceSelectMostRecent?: boolean, suggestionAddedByUserId?: number, forceSelectById?: boolean, suggestionId?: number }): void {
    if (!this.isImageEditSuggestionsPermitted) {
      return;
    }
    
    const reviewId: number = this.review ? this.review.id : null;

    const forceSelectMostRecent: boolean = options ? options.forceSelectMostRecent : false;
    const forceSelectById: boolean = options ? options.forceSelectById : false;

    this.isSuggestionsLoading = true;

    this.handleImageEditSuggestionsFetch().subscribe((res: ImageUpdateSuggestionModel[]) => {
      this.suggestions = res;
      
      if (this.review && this.review.id === reviewId) {
        this.review.imageUpdateSuggestions = res;
      }

      this.isSuggestionsLoading = false;

      if (!this.suggestions || this.suggestions.length === 0) {
        return;
      }

      if (this.isEducator && forceSelectMostRecent) {
        this.selectMostRecentSuggestion();
      }

      if (this.isStudent && forceSelectMostRecent) {
        this.selectMostRecentSuggestionForUser(options.suggestionAddedByUserId);
        
        this.portfolioViewsService.viewUpdateSuggestions(this.image.portfolioId, this.image.id);
      
        this.educationStudentsService.sendManyImageUpdateSuggestionsAction(
          this.image.id,
          this.suggestions.map((suggestion: ImageUpdateSuggestionModel) => suggestion.id),
          'view'
        ).subscribe(() => {});
      }

      if (forceSelectById) {
        this.isSuggestionsMode = !this.isSuggestionsMode;

        this.selectSuggestionById(options.suggestionId);

        this.onImageEditReset();
      }
    });
  }

  private handleImageEditSuggestionsFetch(): Observable<ImageUpdateSuggestionModel[]> {
    if (this.isEducator) {
      return this.educationStudentsImagesHttpService.getUpdateSuggestionsForImage(
        this.educatorStudentPortfolio.institutionId,
        this.educatorStudentPortfolio.classId,
        this.educatorStudentPortfolio.studentId,
        this.educatorStudentPortfolio.id,
        this.image.id
      );
    }

    return this.educationStudentsService.getUpdateSuggestionsForImage(this.image.id);
  }

  private selectMostRecentSuggestion(): void {
    if (!this.suggestions) {
      return;
    }

    this.selectEditSuggestion(this.suggestions[0]);
  }

  private selectMostRecentSuggestionForUser(userId: number): void {
    if (!this.suggestions) {
      return;
    }

    const suggestion: ImageUpdateSuggestionModel = this.suggestions.find((s: ImageUpdateSuggestionModel) => {
      return s.suggestedByUserId === userId;
    });

    this.selectEditSuggestion(suggestion);
  }

  private selectSuggestionById(suggestionId: number): void {
    if (!this.suggestions) {
      return;
    }

    const suggestion: ImageUpdateSuggestionModel = this.suggestions.find((s: ImageUpdateSuggestionModel) => {
      return s.id === suggestionId;
    });

    this.selectEditSuggestion(suggestion);
  }
  
  public onImageLoad(): void {
    if (!this.imageElement || !this.imageElement.nativeElement) {
      return;
    }

    this.isLoading = false;
  }

  public setView({ key }: { key: string }): void {
    if (key === this.view) {
      return;
    }

    this.imageManagerService.viewSubject.next({
      key,
      forceIndex: this.service.currentIndex,
    });
    
    if (key === 'full-view') {
      this.isEnlarged = false;

      this.fullscreenService.collapse(document);
      
      return;
    }

    if (key === 'fullscreen') {
      this.isEnlarged = true;

      this.fullscreenService.enlarge(document.body);

      return;
    }

    this.close();
  }

  private close(): void {
    this.fullscreenService.collapse(document);

    this.service.close();
  }

  public onCropChange(imageCropData: ImageEditorImageUpdateModel): void {
    this.isCropStarted = imageCropData && (
      imageCropData.cropStartX !== 0 ||
      imageCropData.cropStartY !== 0 ||
      imageCropData.cropEndX !== 100 ||
      imageCropData.cropEndY !== 100
    );

    this.imageCropData = imageCropData;
  }

  public onEditSuggestionMouseEnter(suggestion: ImageUpdateSuggestionModel): void {
    this.hoveredEditSuggestion = suggestion;
  }

  public onEditSuggestionMouseLeave(): void {
    this.hoveredEditSuggestion = null;
  }

  public selectEditSuggestion(suggestion: ImageUpdateSuggestionModel): void {
    this.selectedEditSuggestion = suggestion;

    if (!suggestion) {
      return;
    }

    suggestion.isNew = false;
  }

  public onSuggestionCancel(): void {
    this.selectedEditSuggestion = null;
  }

  private onResize(): void {
    if (!this.imageElement || !this.imageElement.nativeElement) {
      return;
    }

    this.rect = this.imageElement.nativeElement.getBoundingClientRect();

    this.centerX = this.rect.x + this.rect.width / 2;
    this.centerY = this.rect.y + this.rect.height / 2;
  }

  public onMouseDown(e: MouseEvent): void {
    if (this.isSuggestionsMode || !this.isImageEditMode || this.cursorKey !== 'default') {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.isImageRotateMode = true;

    this.startAtAngle = this.getAngleDegrees(e);
    
    this.removeMouseListeners();
    this.addMouseListeners();
  }

  private onMouseMove(e: MouseEvent): void {
    if (this.isImageUpdateInProgress) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const angle: number = this.getAngleDegrees(e) - this.startAtAngle;

    this.angle = this.convertTo180(angle);
    this.rotate = this.convertTo180(this.initialRotate + this.angle);
    this.angleFormatted = `${Math.round(this.angle * 100) / 100}`;
  }

  private getAngleDegrees(e: MouseEvent): number {
    const value: number = Math.atan2(e.clientY - this.centerY, e.clientX - this.centerX);
    const angle: number = ((value / (2 * Math.PI)) * 360) % 360;

    return this.convertTo180(angle);
  }

  private onMouseUp(): void {
    this.isImageRotateMode = false;

    this.initialRotate = this.convertTo180(this.initialRotate + this.angle);
    this.rotate = this.initialRotate;

    this.isCropStarted = this.isCropStarted || this.angle !== 0;

    this.angle = 0;
    this.angleFormatted = '0';

    this.removeMouseListeners();
  }

  private convertTo180(value: number): number {
    if (value > -180 && value <= 180) {
      return value;
    }

    return value % 180 + (value <= -180 ? 180 : -180);
  }

  public openCurrentImageReviews(): void {
    if (!this.image) {
      return;
    }

    this.portfolioViewsService.viewImage(this.image.portfolioId, this.image.id);

    this.openReviewsList(this.image);
  }

  private openReviewsList(image: ImageModel): void {
    if (!image) {
      return;
    }

    this.reviewsListModalService.open({
      imageId: image.id,
      imageIndex: this.currentIndex,
    });
  }
  
  private removeMouseListeners(): void {
    window.removeEventListener('mousemove', this.handlers['mousemove']);
    window.removeEventListener('mouseup', this.handlers['mouseup']);
  }

  private addMouseListeners(): void {
    window.addEventListener('mousemove', this.handlers['mousemove']);
    window.addEventListener('mouseup', this.handlers['mouseup']);
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    window.removeEventListener('keydown', this.handlers.keydown);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
