import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {PermissionsService} from '../permissions/permissions.service';

import {IPermissionData} from '../../../models/permission/i-permission-data';

@Injectable()
export class PermissionGuard  {
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const requiredPermissions: IPermissionData[] = route.data['requiredPermissions'];
    const isForbiddenForAdmins: boolean = route.data['isForbiddenForAdmins'];

    if (!requiredPermissions) {
      return new Observable<boolean>(observer => observer.next(true));
    }

    return this.permissionsService.isUserHasPermissionsObservable(requiredPermissions, { isForbiddenForAdmins }).pipe(
      tap((value: boolean) => {
        if (!value) {
          this.router.navigateByUrl('/app');
        }
      })
    );
  }
}
