import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ModalsService} from '../../../services/modals/modals.service';
import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-view-website-warning-modal',
  templateUrl: './view-website-warning-modal.component.html',
  styleUrls: ['./view-website-warning-modal.component.scss']
})
export class ViewWebsiteWarningModalComponent {
  @Input() id = 'view-website-warning-modal';

  @Output() saveAndPublishHandler = new EventEmitter();
  @Output() openWebsiteHandler = new EventEmitter();
  @Output() backHandler = new EventEmitter();

  header = {
    className: 'warning-header',
  };

  buttons: Button[] = [
    {
      text: `Save And Publish`,
      className: 'neutral smaller-button',
      onClick: this.handleSaveAndPublish.bind(this),
    },
    {
      text: `Open Anyway`,
      className: 'neutral smaller-button',
      onClick: this.handleOpenWebsite.bind(this),
    },
    {
      text: `Cancel`,
      className: 'neutral smaller-button',
      onClick: this.handleBack.bind(this),
    },
  ];

  constructor(private modalsService: ModalsService) {}

  public handleSaveAndPublish(): void {
    this.saveAndPublishHandler.emit();

    this.modalsService.close(this.id);
  }

  public handleOpenWebsite(): void {
    this.openWebsiteHandler.emit();

    this.modalsService.close(this.id);
  }

  public handleBack(): void {
    this.backHandler.emit();

    this.modalsService.close(this.id);
  }
}

