import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class DashboardSupportCallService {
  public id = 'dashboard-support-call';

  constructor(private modalsService: ModalsService) {
  }

  public open() {
    this.modalsService.open(this.id);
  }
}
