import {Injectable} from '@angular/core';

import {MenuSetupModel} from '../../../models/styles/setup/menu/desktop/menu-setup.model';

import {KEYS} from './constants';
import {KEYS as MENU_STYLE_KEYS} from '../../../models/styles/setup/menu/style/constants';

@Injectable()
export class CustomMenuStylesService {
  public setup: MenuSetupModel;

  public get menu(): HTMLElement {
    return this.setup.menu;
  }

  private handlers = {
    [KEYS.MENU_STYLE]: this.onMenuStyleChange.bind(this),
    [KEYS.MENU_DROPDOWN_ARROW]: this.onMenuDropdownArrowChange.bind(this),
  };

  public get isMenuCategoryExpandable(): boolean {
    return this.setup.style.value === MENU_STYLE_KEYS.EXPANDED_FLAT || this.setup.style.value === MENU_STYLE_KEYS.EXPANDED_INDENTED;
  }

  constructor() {
    this.setup = new MenuSetupModel({ handlers: this.handlers });
  }

  public init({ menu, device }: { menu: HTMLElement, device: string }) {
    this.setup.init({ element: menu, device });
  }

  private onMenuStyleChange(value: string) {
    this.setup.style.setValue(value);
  }

  private onMenuDropdownArrowChange(value: string) {
    this.setup.dropdownArrow.setValue(value);
  }
}
