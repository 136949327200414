import {IColorSchemeModel} from './icolor.scheme.model';
import {RgbColorSchemeModel} from './rgb.color.scheme.model';

export class HexColorSchemeModel implements IColorSchemeModel {
  public r: string;
  public g: string;
  public b: string;
  public a: string;

  public constructor(color: string) {
    if (!HexColorSchemeModel.isHex(color)) return;

    color = color.startsWith('#') ? color.substring(1) : color;

    this.r = color.substr(0,2);
    this.g = color.substr(2,2);
    this.b = color.substr(4,2);

    if (color.length === 6) return;

    this.a = color.substr(6,2);
  }

  public static isHex(color: string) {
    return color && ((color.startsWith('#') && color.length === 7) || (color.length === 6));
  }

  public toString() : string {
    const rgb = `#${this.r}${this.g}${this.b}`;

    return this.a === void 0 ? rgb : `${rgb}${this.a}`;
  }

  public static fromRgb(rgb: RgbColorSchemeModel): HexColorSchemeModel {
    const hex = new HexColorSchemeModel('');

    hex.r = HexColorSchemeModel.toHex(rgb.r);
    hex.g = HexColorSchemeModel.toHex(rgb.g);
    hex.b = HexColorSchemeModel.toHex(rgb.b);
    hex.a = HexColorSchemeModel.toHex(rgb.a);

    return hex;
  }

  private static toHex(value) {
    if (value === void 0 || Number.isNaN(value)) return '';

    const hex = Number(value).toString(16);

    return hex.length < 2 ? `0${hex}` : hex;
  }
}
