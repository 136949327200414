import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';
import {NavigationService} from '../../../../services/navigation.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {ExitSurveyResultsHttpService} from '../../../../core/services/interaction/http/exit-survey-results/exit-survey-results-http.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';

import {SubscriptionsModel} from '../../../../core/models/payment/subscriptions/subscriptions.model';
import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {ISocketSubscriptionsMessageDataModel} from '../../../../core/models/sockets/message/subscriptions/i-subscriptions-message-data.model';

@Injectable()
export class TrialEndSurveyModalService {
  public readonly id = 'trial-end-survey-modal';

  public isOpened = false;

  private data: SubscriptionsModel;
  private activeWebsiteId: number;
  
  private lastOpenedKey: string = null;

  private isEverOpened: boolean = false;
  private isSubscriptionsListFetched: boolean = false;

  public constructor(
    private modalsService: ModalsService,
    private navigationService: NavigationService,
    private websitesService: WebsitesService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private socketsService: SocketsService,
    private httpService: ExitSurveyResultsHttpService
  ) {
    this.socketsService.subscriptionsDataSubject.subscribe((data: ISocketSubscriptionsMessageDataModel) => {
      if (!data) {
        return;
      }

      if (!this.isEverOpened && !this.isSubscriptionsListFetched && data.action === 'EXPIRED') {
        this.isSubscriptionsListFetched = true;

        this.paymentSubscriptionsService.initList();
      }
    });

    this.websitesService.activeWebsiteIdSubject.subscribe((value: number) => {
      this.activeWebsiteId = value;

      this.handleSubscriptions();
    });
    
    this.paymentSubscriptionsService.dataSubject.subscribe((data: SubscriptionsModel) => {
      this.data = data;
      
      if (!data || data.list.length === 0) {
        return;
      }

      this.handleSubscriptions();
    });
  }

  private handleSubscriptions(): void {
    if (!this.data || !this.activeWebsiteId) {
      return;
    }

    const activeSubscription: SubscriptionModel = this.data.list.find((item: SubscriptionModel) => item.isActive);

    if (activeSubscription) {
      this.handle(activeSubscription);

      return;
    }

    const ordered: SubscriptionModel[] = this.data.list.filter((item: SubscriptionModel) => {
      return item.websiteId === this.activeWebsiteId;
    }).sort((a: SubscriptionModel, b: SubscriptionModel) => {
      return b.cancelAtTimestamp - a.cancelAtTimestamp;
    });

    if (!ordered[0]) {
      return;
    }

    this.handle(ordered[0]);
  }

  private handle(subscription: SubscriptionModel): void {
    if (!subscription.isTrialEnded) return;

    const key: string = this.getKey(subscription);

    if (this.lastOpenedKey === key) return;

    this.lastOpenedKey = key;

    this.httpService.isExists(key).subscribe(({ isExists }: { isExists: boolean }) => {
      this.navigationService.toPurchase();

      this.open({ isExists, key });
    });
  }

  public open({ isExists, key } : { isExists: boolean, key: string }): void {
    this.modalsService.open(this.id, { isExists, key });

    this.isOpened = true;
    this.isEverOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }

  public getKey(subscription: SubscriptionModel): string {
    return `trial_end_${subscription.id}_${subscription.trialEndAtTimestamp}`;
  }
}
