import {AccountModel} from "../../accounts/account.model";

export class ImageUpdateSuggestionModel {
  public isNew: boolean = false;

  constructor(
    public id?: number,
    public imageId?: number,
    public portfolioId?: number,
    public suggestedByUserId?: number,
    public reviewId?: number,
    public suggestedBy?: AccountModel,
    public rotate?: number,
    public cropStartX?: number,
    public cropStartY?: number,
    public cropEndX?: number,
    public cropEndY?: number,
    public createdAt?: string,
    public createdAtFormatted?: string,
    public lastViewedByStudentAt?: string
  ) {
    this.isNew = !this.lastViewedByStudentAt;
  }

  public static clone(item: ImageUpdateSuggestionModel): ImageUpdateSuggestionModel {
    if (!item) {
      return null;
    }

    return new ImageUpdateSuggestionModel(
      item.id,
      item.imageId,
      item.portfolioId,
      item.suggestedByUserId,
      item.reviewId,
      item.suggestedBy ? AccountModel.clone(item.suggestedBy) : null,
      item.rotate,
      item.cropStartX,
      item.cropStartY,
      item.cropEndX,
      item.cropEndY,
      item.createdAt,
      item.createdAtFormatted,
      item.lastViewedByStudentAt,
    );
  }
}
