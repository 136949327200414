export const KEYS = {
  ENLARGEMENT_PADDING_TOP: 'ENLARGEMENT_PADDING_TOP',
  ENLARGEMENT_PADDING_RIGHT: 'ENLARGEMENT_PADDING_RIGHT',
  ENLARGEMENT_PADDING_BOTTOM: 'ENLARGEMENT_PADDING_BOTTOM',
  ENLARGEMENT_PADDING_LEFT: 'ENLARGEMENT_PADDING_LEFT',
};

export const STYLE_PROPERTIES = {
  [KEYS.ENLARGEMENT_PADDING_TOP]: '--enlargement-padding-top',
  [KEYS.ENLARGEMENT_PADDING_RIGHT]: '--enlargement-padding-right',
  [KEYS.ENLARGEMENT_PADDING_BOTTOM]: '--enlargement-padding-bottom',
  [KEYS.ENLARGEMENT_PADDING_LEFT]: '--enlargement-padding-left',
};

export const OPTIONS = [{
    text: 'Top',
    key: KEYS.ENLARGEMENT_PADDING_TOP,
  }, {
    text: 'Right',
    key: KEYS.ENLARGEMENT_PADDING_RIGHT,
  }, {
    text: 'Bottom',
    key: KEYS.ENLARGEMENT_PADDING_BOTTOM,
  }, {
    text: 'Left',
    key: KEYS.ENLARGEMENT_PADDING_LEFT,
}];
