import {TemplatePreviewTypeModel} from './type/template-preview-type.model';
import {TemplatePreviewDescriptionModel} from '../description/template-preview-description.model';

import {SRCSET_SIZES} from '../../constants';

export class TemplatePreviewDetailsModel {
  public isImageLoaded: boolean = false;

  public srcSet: string = '';

  constructor(public id?: number,
              public templateId?: number,
              public themeId?: number,
              public typeId?: number,
              public imageName?: string,
              public imagePath?: string,
              public previewUrl?: string,
              public type?: TemplatePreviewTypeModel,
              public descriptions?: TemplatePreviewDescriptionModel[]) {
    const extension = this.imagePath.match(/.(\w|\d)+$/gi)[0];
    const src = this.imagePath.replace(extension, '');

    this.srcSet = SRCSET_SIZES.map(({ height, width, density }) => `${src}_${width}_${height}${extension} ${density}`).join(',');
  }
}
