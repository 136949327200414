// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .view-website-warning {
  position: fixed;
}

.view-website-warning-body .text {
  padding: 20px;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/view-website-warning-modal/view-website-warning-modal.component.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;AAFJ;;AAOE;EACE,aAAA;EACA,gBAAA;AAJJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .view-website-warning {\n    position: fixed;\n  }\n}\n\n.view-website-warning-body {\n  .text {\n    padding: 20px;\n    font-size: 0.9em;\n  }\n\n  .buttons-wrapper {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
