import {Injectable} from '@angular/core';

import {IFrameService} from '../iframe.service';
import {EventsService} from '../../interaction/events/events.service';
import {LogoManagerService} from '../../images/logos/logo-manager.service';

@Injectable()
export class LogoUploadService {
  constructor(private iFrameService: IFrameService,
              private eventsService: EventsService,
              private logoManagerService: LogoManagerService) {
    this.eventsService.addFrameListener('logoClick', this.onLogoClick.bind(this));
    this.eventsService.addFrameListener('logoDelete', this.onLogoDelete.bind(this));
  }

  public onLogoClick(e: CustomEvent): void {
    this.logoManagerService.open({
      element: e.detail.element,
    });
  }

  private onLogoDelete(e: CustomEvent): void  {
    this.eventsService.dispatchLogoDelete(e.detail);
  }
}
