import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as $ from 'jquery';
import 'jqueryui';

import {AppAnimations} from '../../../../../../app-animations';

import {PagesService} from '../../../../../../application/sidebar-short/sidebar/pages/pages.service';
import {ImageManagerService} from '../../../../../../application/main/image-manager/image-manager.service';
import {ImagesCounterService} from '../../../../../../core/services/image-manager/counters/images-counter.service';
import {ImagesCounterModel} from '../../../../../../core/models/image-manager/counters/images-counter.model';

import {LIBRARY_ID} from '../../../../../../application/constants';

@Component({
  selector: 'app-portfolio-list-item',
  templateUrl: './portfolio-list-item.component.html',
  styleUrls: ['./portfolio-list-item.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PortfolioListItemComponent implements OnInit, OnDestroy {
  @ViewChild('portfolioItem') portfolioItem: ElementRef;

  @Input() isBlocked: boolean = false;
  @Input() isPortfolioBlocked: boolean = false;

  @Input() portfolio: { id: number, nodeId: number, title: string };
  @Input() selectedPortfolioId: number;

  @Input() isLibrary: boolean = false;

  @Output() selectHandler: EventEmitter<{ id: number, nodeId: number }> = new EventEmitter<{ id: number, nodeId: number }>();
  @Output() receiveHandler: EventEmitter<number> = new EventEmitter<number>();

  private counters: ImagesCounterModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get text(): string {
    if (this.isLibrary) {
      if (!this.counters) return 'LIBRARY';

      return `LIBRARY (${this.counters.getCount(-1, true)})`;
    }

    if (!this.portfolio) return '';

    if (!this.counters) return this.portfolio.title;

    return `${this.portfolio.title} (${this.counters.getCount(this.portfolio.id, true)})`;
  }

  public get isSelected(): boolean {
    return (this.isLibrary && this.selectedPortfolioId === 0) || (this.portfolio && this.selectedPortfolioId === this.portfolio.id);
  }

  public get isDisabled(): boolean {
    return this.isPortfolioBlocked && ((this.isLibrary && this.selectedPortfolioId !== 0) || (this.portfolio && this.selectedPortfolioId !== this.portfolio.id));
  }

  constructor(private pagesService: PagesService,
              private imageManager: ImageManagerService,
              private imagesCounterService: ImagesCounterService) {
    this.imagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      this.counters = counters;
    });
  }

  ngOnInit() {
    this.initDroppable();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private initDroppable() {
    if (!this.portfolioItem) {
      return;
    }
    
    $(this.portfolioItem.nativeElement).sortable({
      receive: this.handleDroppedImages.bind(this),
    });
  }

  private handleDroppedImages(event, ui) {
    if (this.isSelected) {
      ui.sender.sortable('cancel');

      return;
    }

    this.receiveHandler.next(ui);

    this.imagesCounterService.onImageAdd(this.isLibrary ? LIBRARY_ID : this.portfolio.id, ui.sender.find('.selected[data-image-id]').length + 1);
  }

  public selectPortfolio() {
    const portfolio = this.isLibrary ? { id: 0, nodeId: 0 } : this.portfolio;

    this.selectHandler.emit(portfolio);
    this.pagesService.setSelectedNode(portfolio.nodeId);
  }
}
