import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {Subject} from 'rxjs';

import {AddOnCardDeclinedModalService} from '../../../services/modals/add-on-card-declined/add-on-card-declined-modal.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-add-on-card-declined-modal',
  templateUrl: './add-on-card-declined-modal.component.html',
  styleUrls: ['./add-on-card-declined-modal.component.scss']
})
export class AddOnCardDeclinedModalComponent implements OnDestroy {
  public header: ModalHeader = {
    text: 'ERROR!',
    className: 'error-header',
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(private router: Router,
              private service: AddOnCardDeclinedModalService) {
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public close(): void {
    this.service.close();

    this.router.navigate(['/app', { outlets: { primary: ['settings', 'credit-cards'], sidebar: ['account', 'credit-cards'], 'over-sidebar': null } }]);
  }
}
