import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EducationClassModel} from '../../../../../../../models/education/classes/education-class.model';
import {EducationClassDto} from '../../../../../../../models/education/classes/education-class.dto';

@Injectable()
export class EducationClassesHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public getOne(institutionId: number, classId: number): Observable<EducationClassModel> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}`).pipe(
      map((item: EducationClassDto) => EducationClassDto.normalize(item))
    );
  }
}
