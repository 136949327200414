export const ATTRIBUTES = {
  IS_PORTFOLIO_TITLE_ADDED: 'data-is-portfolio-title-added',
  IS_TITLE_BLOCK_ADDED: 'data-is-title-block-added',
};

export const TYPES = {
  BLOCK: 'BLOCK',
  ELEMENT: 'ELEMENT',
};

export const BLOCKS_TEMPLATE_ID = {
  PORTFOLIO_1: 23,
  PORTFOLIO_2: 47,
  PORTFOLIO_3: 138,
  PORTFOLIO_4: 72,
  PORTFOLIO_5: 98,
  PORTFOLIO_6: 112,
  PORTFOLIO_7: 116,
  PORTFOLIO_8: 134,

  TITLE_1: 143,
};

export const BLOCKS_TYPES_MAPPING = {
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_1]: TYPES.ELEMENT,
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_2]: TYPES.ELEMENT,
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_3]: TYPES.BLOCK,
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_4]: TYPES.ELEMENT,
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_5]: TYPES.ELEMENT,
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_6]: TYPES.BLOCK,
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_7]: TYPES.BLOCK,
  [BLOCKS_TEMPLATE_ID.PORTFOLIO_8]: TYPES.BLOCK,
};
