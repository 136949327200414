import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class IsTitleAndYearVisibleBelowImageModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value: boolean = false,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = !this.element.matches(`[${ATTRIBUTES.IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE}="false"]`);
  }

  public onChange(value: boolean): void {
    this.value = value;

    this.element.setAttribute(ATTRIBUTES.IS_TITLE_AND_YEAR_VISIBLE_BELOW_IMAGE, `${value}`);
  }
}
