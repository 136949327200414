import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appIntegerNumber]',
})
export class IntegerNumberDirective {
  @Input() appIntegerNumber: boolean;

  private input: HTMLInputElement = null;

  private re: RegExp = /^(\d{1,7})?$/;

  private lastValue: number = null;

  constructor(private elem: ElementRef) {
    this.input = <HTMLInputElement>elem.nativeElement;
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (!this.appIntegerNumber) return;

    this.lastValue = Number.parseInt(this.input.value);
  }

  @HostListener('keyup', ['$event']) onKeyUp(e: KeyboardEvent) {
    if (!this.appIntegerNumber) return;

    if (this.re.test(this.input.value)) return;

    this.input.value = `${this.lastValue}`;

    this.input.dispatchEvent(new Event('change'));
  }
}
