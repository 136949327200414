import {Component, Output, EventEmitter, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';

import {SelectOption} from "../../../../core/models/select/option/option.model";

import {FONT_SIZE_OPTIONS} from './constants';

@Component({
  selector: 'app-fonts-manager-font-size-wrapper',
  templateUrl: './fonts-manager-font-size-wrapper.component.html',
  styleUrls: ['./fonts-manager-font-size-wrapper.component.scss'],
})
export class FontsManagerFontSizeWrapperComponent implements OnInit, OnChanges {
  @Input() fontSizeInt: number = 0;

  @Output() changeHandler = new EventEmitter<number>();

  public fontSizeOptions: SelectOption[] = FONT_SIZE_OPTIONS();
  public selectedFontSizeOption: SelectOption = null;

  public isExpanded: boolean = false;

  public fontSizeNumberValue: number;
  public fontSizeValue: string;
  public fontSizeLabel: string = '';

  public get fontSize(): string {
    return this.fontSizeValue;
  }

  public set fontSize(value: string) {
    this.fontSizeNumberValue = Number.parseInt(value);
    this.fontSizeValue = value;
    this.fontSizeLabel = `${value}px`;
  }

  constructor() {
    this.selectedFontSizeOption = this.fontSizeOptions.find(option => option.isSelected);
  }

  public ngOnInit(): void {
    this.onFontSizeChange(this.selectedFontSizeOption);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['fontSizeInt']) {
      this.fontSizeNumberValue = changes['fontSizeInt'].currentValue;
      this.fontSizeValue = `${this.fontSizeNumberValue}`;
      this.fontSizeLabel = `${this.fontSizeNumberValue}px`;
    }
  }

  public onFontSizeChange(selectedOption: SelectOption): void {
    this.selectedFontSizeOption.isSelected = false;
    this.selectedFontSizeOption = selectedOption;
    this.selectedFontSizeOption.isSelected = true;

    this.fontSize = this.selectedFontSizeOption.value;

    this.changeHandler.emit(this.fontSizeNumberValue);
  }

  public onFontSizeSliderChange(value: string): void {
    this.fontSize = value;

    this.changeHandler.emit(this.fontSizeNumberValue);

    this.fontSizeOptions.forEach((option: SelectOption) => {
      option.isSelected = option.value === value;
    });
  }
}
