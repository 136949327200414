import {Injectable} from '@angular/core';

import {BehaviorSubject, Subscription} from 'rxjs';

import {SupportHistoryHttpService} from '../interaction/http/support-history/support-history-http.service';
import {ContentLoaderService} from '../loaders/content/content-loader.service';

import {SupportMetaItemModel} from '../../models/accounts/support-meta/support-meta-item.model';

@Injectable()
export class SupportHistoryService {
  private readonly key: string = 'SupportHistoryService';

  public supportHistorySubject: BehaviorSubject<SupportMetaItemModel[]> = new BehaviorSubject<SupportMetaItemModel[]>(null);

  constructor(private httpService: SupportHistoryHttpService,
              private loaderService: ContentLoaderService) {
  }

  public fetch(): Subscription {
    this.loaderService.show(this.key);

    return this.httpService.fetch().subscribe((res: SupportMetaItemModel[]) => {
      this.supportHistorySubject.next(res);

      this.loaderService.hide(this.key);
    });
  }
}
