import { BaseFileObjectDto } from '../base/file-object/base-file-object.dto';
import {LogoModel} from './logo.model';

export class LogoDto extends BaseFileObjectDto {
  constructor(public Id?: number,
              public UserId?: number,
              public FileName?: string,
              public OriginalFileName?: string,
              public FileSize?: number,
              public WidthPx?: number,
              public HeightPx?: number,
              public Link?: string) {
    super();
  }

  public static normalize(res: LogoDto): LogoModel {
    if (!res) return null;

    return new LogoModel(
      res.Id,
      res.UserId,
      res.FileName,
      res.OriginalFileName,
      res.FileSize,
      res.WidthPx,
      res.HeightPx,
      res.Link,
    );
  }
}
