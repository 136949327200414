import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CustomHomePageModalService} from '../../../services/modals/custom-home-page/custom-home-page-modal.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';

import {Button} from '../../../../common/models/button/button.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';

@Component({
  selector: 'app-custom-home-page-modal',
  templateUrl: './custom-home-page-modal.component.html',
  styleUrls: ['./custom-home-page-modal.component.scss'],
})
export class CustomHomePageModalComponent implements OnInit, OnDestroy {
  public template: string;

  public buttons: Button[] = [
    new Button('OK', null, 'neutral ok-button', this.close.bind(this)),
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(public service: CustomHomePageModalService,
              private templatesService: TemplatesService) {}

  public ngOnInit(): void {
    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.template = template ? `${template.name}` : '';
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public close(): void {
    this.service.close();
  }
}
