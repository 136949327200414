import {SetupOptionModel} from '../option/setup-option.model';

import {QUERY_PARAMS, VALUES} from './constants';
import {SERVICE_RESOLVER} from '../../../../services/video-manager/embed/constants';

export class VideoEmbedMutedModel extends SetupOptionModel {
  public element: HTMLElement;
  public iFrame: HTMLIFrameElement;

  public isOptionShown: boolean = false;

  private serviceKey: string = '';
  private queryParamKey: string = '';

  constructor(
    public key: string,
    public type: string,
    public value?: boolean,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.iFrame = <HTMLIFrameElement>element.querySelector('iframe');

    this.isOptionShown = !!this.iFrame;

    if (!this.isOptionShown) return;

    this.serviceKey = SERVICE_RESOLVER(this.iFrame.src);

    this.queryParamKey = QUERY_PARAMS[this.serviceKey];

    this.isOptionShown = !!this.queryParamKey;

    if (!this.isOptionShown) return;

    const url = new URL(this.iFrame.src);
    const searchParams = new URLSearchParams(url.search);

    this.value = searchParams.get(QUERY_PARAMS[this.serviceKey]) === VALUES[this.serviceKey].ENABLED;
  }

  public onChange(value: boolean): void {
    this.value = value;

    if (!this.isEnabled || !this.iFrame) return;

    const url = new URL(this.iFrame.src);
    const searchParams = new URLSearchParams(url.search);

    searchParams.set(QUERY_PARAMS[this.serviceKey], value ? VALUES[this.serviceKey].ENABLED : VALUES[this.serviceKey].DISABLED);

    url.search = searchParams.toString();

    this.iFrame.src = url.toString();
  }
}
