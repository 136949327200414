import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SetupService {
  public onSelectTemplateOpen: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);

  constructor() {}

  onSelectTemplateClick() {
    this.onSelectTemplateOpen.next(void 0);
  }
}
