import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

// Components
import {OptionWrapperComponent} from './components/options/option-wrapper/option-wrapper.component';
import {SliderOptionComponent} from './components/options/slider-option/slider-option.component';
import {CheckboxOptionComponent} from './components/options/checkbox-option/checkbox-option.component';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [
    OptionWrapperComponent,
    SliderOptionComponent,
    CheckboxOptionComponent
  ],
  providers: [

  ],
  exports: [
    CommonModule,
    FormsModule,

    // Components
    OptionWrapperComponent,
    SliderOptionComponent,
    CheckboxOptionComponent
  ],
})
export class CustomizerModule {}
