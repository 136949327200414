import {Injectable} from '@angular/core';

import {StudentPortfoliosService} from '../../websites/portfolios/student-portfolios.service';
import {CoreImageManagerService} from '../../../../image-manager/core-image-manager.service';
import {EducationStudentsPortfoliosHttpService} from '../../../../interaction/http/education/students/websites/portfolios/education-students-portfolios-http.service';
import {ImageManagerService} from '../../../../../../application/main/image-manager/image-manager.service';
import {StudentImagesCounterService} from '../student-counters/student-images-counter.service';
import {StudentImageManagerService} from '../student-image-manager.service';
import {EducationStudentsService} from '../../education-students.service';
import {WebsitesService} from '../../../../websites/websites.service';
import {ImagesCounterService} from '../../../../image-manager/counters/images-counter.service';

import {PortfolioModel} from '../../../../../models/portfolios/portfolio.model';
import {ImageManagerUserTypeModel} from '../../../../../models/image-manager/user-type/user-type.model';
import {ImageDataModel} from '../../../../../models/image-manager/counters/image-data.model';
import {ImageModel} from '../../../../../models/images/image.model';

import {SOCKET_ACTIONS} from '../../../../../../application/main/image-manager/constants';
import { catchError, throwError } from 'rxjs';

@Injectable()
export class StudentImageMoveService {
  private userData: ImageManagerUserTypeModel;

  private studentPortfolio: PortfolioModel;

  private images: ImageModel[];

  private activeWebsiteId: number;

  private get isStudent(): boolean {
    return this.userData && this.userData.type === 'student';
  }

  private get isStudentTab(): boolean {
    return this.userData && this.userData.tab === 'student';
  }

  constructor(
    private studentPortfoliosService: StudentPortfoliosService,
    private studentImagesCounterService: StudentImagesCounterService,
    private studentImageManagerService: StudentImageManagerService,
    private educationStudentsService: EducationStudentsService,
    private coreImageManagerService: CoreImageManagerService,
    private websitesService: WebsitesService,
    private imagesCounterService: ImagesCounterService,
    private imageManagerService: ImageManagerService,
    private httpService: EducationStudentsPortfoliosHttpService,
  ) {
    this.coreImageManagerService.userTypeSubject.subscribe((userData: ImageManagerUserTypeModel) => {
      this.userData = userData;
    });

    this.studentPortfoliosService.selectedPortfolioSubject.subscribe((portfolio: PortfolioModel) => {
      this.studentPortfolio = portfolio;
    });
    
    this.studentImageManagerService.portfolioImagesSubject.subscribe((images: ImageModel[]) => {
      this.images = this.isStudent && this.isStudentTab ? images : null;
    });

    this.websitesService.activeWebsiteIdSubject.subscribe((websiteId: number) => {
      this.activeWebsiteId = websiteId;
    });
  }

  public onDrop(event, ui): void {
    if (!this.studentPortfolio || !this.isStudent || !this.isStudentTab) {
      return;
    }

    const { elements, ids, data }: { elements: HTMLElement[], ids: number[], data: ImageDataModel[] } = this.getImages(ui);

    if (!data || data.length === 0) {
      return;
    }

    const fromPortfolioId: number = this.studentPortfolio.id
    const toPortfolioId: number = this.getTargetPortfolioId(event.target);

    const userId: number = this.studentPortfolio.userId;
    const websiteId: number = this.studentPortfolio.websiteId;

    this.imageManagerService.isPageBlockedSubject.next(true);

    this.educationStudentsService.sendAction(
      SOCKET_ACTIONS.IMAGE_REORDER_START,
      userId,
      websiteId,
      toPortfolioId,
    );

    this.startInsertImageToPortfolioAnimation(event, ui);

    elements.forEach((element: HTMLElement) => {
      element.setAttribute('data-moved', 'true');
    });

    this.httpService.moveManyImagesToPortfolio({
      websiteId: this.studentPortfolio.websiteId,
      portfolioId: fromPortfolioId,
      toPortfolioId,
      ids,
    }).pipe(
      catchError(e => {
        console.error(e);
        
        this.imageManagerService.isPageBlockedSubject.next(false);

        this.educationStudentsService.sendAction(
          SOCKET_ACTIONS.IMAGE_REORDER_START,
          userId,
          websiteId,
          toPortfolioId,
        );
        
        return throwError(() => e);
      })
    ).subscribe(() => {
      this.studentImagesCounterService.onImageRemove(fromPortfolioId, data);
      this.studentImagesCounterService.onImageAdd(toPortfolioId, ids.length);

      if (this.studentPortfolio.websiteId === this.activeWebsiteId) {
        this.imagesCounterService.onImageRemove(fromPortfolioId, data);
        this.imagesCounterService.onImageAdd(toPortfolioId, ids.length);
      }

      this.imageManagerService.isPageBlockedSubject.next(false);

      this.educationStudentsService.sendAction(
        SOCKET_ACTIONS.IMAGE_REORDER_STOP,
        userId,
        websiteId,
        toPortfolioId,
      );
    });
  }

  private getImages(ui): {
    elements: HTMLElement[],
    ids: number[],
    data: ImageDataModel[],
  } {
    try {
      const selectedElements: HTMLElement[] = <HTMLElement[]>Array.from(ui.draggable[0].parentElement.querySelectorAll('.dz-preview.selected'));

      const ids: number[] = selectedElements.map((element: HTMLElement) => {
        return this.getImageId(element);
      });

      const data: ImageDataModel[] = ids.map((id: number) => {
        return this.getImageDataModel(id);
      });

      return {
        elements: selectedElements,
        ids,
        data,
      };
    } catch (e) {
      console.error(e);
    }
  }

  private getImageDataModel(imageId: number): ImageDataModel {
    if (!this.images) {
      return null;
    }

    const image: ImageModel = this.images.find((image: ImageModel) => {
      return image.id === imageId;
    });

    if (!image) {
      return null;
    }

    return new ImageDataModel(
      image.id,
      image.isPublished,
    );
  }

  private getTargetPortfolioId(target: HTMLElement): number {
    return Number.parseInt(target.getAttribute('data-portfolio-id'));
  }

  private getImageId(image: HTMLElement): number {
    return Number.parseInt(image.getAttribute('data-imageid'));
  }

  private startInsertImageToPortfolioAnimation(event, ui): void {
    const element: HTMLElement = ui.helper[0]
    const clone: HTMLElement = <HTMLElement>element.cloneNode(true);

    this.setInsertAnimationDefaultStyles(clone, ui.position);

    document.body.appendChild(clone);

    this.startInsertElementAnimation(clone);
  }

  private setInsertAnimationDefaultStyles(element: HTMLElement, startPosition: { top: number, left: number }): void {
    element.style.position = 'absolute';
    element.style.top = `${startPosition.top}px`;
    element.style.left = `${startPosition.left}px`;
    element.style.opacity = '0.7';
    element.style.zIndex = '9999999';
    element.style.maxHeight = '90px';
    element.style.transition = 'transform .3s';
  }

  private startInsertElementAnimation(element: HTMLElement): void {
    element.addEventListener('transitionend', () => {
      element.remove();
    });

    window.setTimeout(() => {
      element.style.transform = 'translate(-50%, -50%) scale(0.01, 0.01)';
    });
  }
}
