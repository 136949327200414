import {Injectable} from '@angular/core';

import {SubtitleSetupModel} from '../../../models/styles/setup/website-subtitle/subtitle-setup.model';

@Injectable()
export class SubtitleStylesService {
  public setup: SubtitleSetupModel;

  public isModernMenu: boolean = false;

  constructor() {
    this.setup = new SubtitleSetupModel();
  }

  public init(menu: HTMLElement): void {
    if (!menu) return;

    this.isModernMenu = menu.matches('[data-menu-type="modern"]');

    const subtitle: HTMLElement = <HTMLElement>menu.querySelector('[data-website-subtitle]');

    this.setup.init(subtitle);
  }
}
