import {Component, Input} from '@angular/core';

import {SidebarContentList} from "../../../models/sidebar/content/list/list.model";

@Component({
  selector: 'app-vs-sidebar-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class SidebarContentComponent {
  @Input() data: SidebarContentList;
}
