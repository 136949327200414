
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';

import {Subscriber, Observable, of, concat, throwError} from 'rxjs';
import {catchError, toArray} from 'rxjs/operators';

import {EducationStudentsImagesReviewsAudiosHttpService} from '../../../../../../../../interaction/http/education/teachers/institutions/classes/students/images/reviews/audios/education-students-images-reviews-audios-http.service';
import {S3HttpService} from '../../../../../../../../interaction/http/s3/s3-http.service';
import {MimeTypesService} from '../../../../../../../../mime-types/mime-types.service';
import {EducationStudentsImagesService} from '../../education-students-images.service';

import {StudentImageReviewModel} from '../../../../../../../../../models/images/review/student-image-review.model';
import {StudentImageReviewAudioModel} from '../../../../../../../../../models/images/review/audio/student-image-review-audio.model';
import {StudentImageReviewAudioUploadUrlsModel} from '../../../../../../../../../models/images/review/audio/student-image-review-audio-upload-urls.model';

@Injectable()
export class EducationStudentsImagesReviewsAudiosService {
  constructor(
    private httpService: EducationStudentsImagesReviewsAudiosHttpService,
    private s3HttpService: S3HttpService,
    private mimeTypesService: MimeTypesService,
    private educationStudentsImagesService: EducationStudentsImagesService,
  ) {
  }

  public handleAudios(
    institutionId: number,
    classId: number,
    studentId: number,
    portfolioId: number,
    imageId: number,
    toDelete: StudentImageReviewAudioModel[],
    review: StudentImageReviewModel,
    isUpdateNotificationRequired: boolean = false,
  ): Observable<StudentImageReviewAudioModel[]> {
    if (!review.audios) {
      return of([]);
    }

    const toUpdate: StudentImageReviewAudioModel[] = review.audios.filter((audio: StudentImageReviewAudioModel) => {
      return !!audio.id;
    });
    
    const toCreate: StudentImageReviewAudioModel[] = review.audios.filter((audio: StudentImageReviewAudioModel) => {
      return !audio.id;
    });
    
    return new Observable<StudentImageReviewAudioModel[]>((subscriber: Subscriber<StudentImageReviewAudioModel[]>) => {
      this.removeAudios(
        institutionId,
        classId,
        studentId,
        portfolioId,
        imageId,
        review.id,
        toDelete
      ).pipe(
        catchError(e => {
          console.error(e);

          subscriber.error(e);
          subscriber.complete();
          
          return throwError(() => e);
        })
      ).subscribe(() => {
        this.updateAudios(
          institutionId,
          classId,
          studentId,
          portfolioId,
          imageId,
          review.id,
          toUpdate
        ).pipe(
          catchError(e => {
            console.error(e);

            subscriber.error(e);
            subscriber.complete();
            
            return throwError(() => e);
          })
        ).subscribe(() => {
          this.createAudios(
            institutionId,
            classId,
            studentId,
            portfolioId,
            imageId,
            review.id,
            toCreate
          ).pipe(
            catchError(e => {
              console.error(e);
              
              subscriber.error(e);
              subscriber.complete();
              
              return throwError(() => e);
            })
          ).subscribe((res: StudentImageReviewAudioModel[]) => {
            if (isUpdateNotificationRequired) {
              this.educationStudentsImagesService.sendImageReviewUpdateNotification(
                institutionId,
                classId,
                studentId,
                portfolioId,
                imageId
              );
            }
            
            subscriber.next(res);
            subscriber.complete();
          });
        });
      });
    });
  }

  private removeAudios(
    institutionId: number,
    classId: number,
    studentId: number,
    portfolioId: number,
    imageId: number,
    reviewId: number,
    audios: StudentImageReviewAudioModel[]
  ): Observable<any> {
    if (!audios || audios.length === 0) {
      return of(true);
    }

    return this.httpService.deleteMany(
      institutionId,
      classId,
      studentId,
      portfolioId,
      imageId,
      reviewId,
      audios.map((audio: StudentImageReviewAudioModel) => audio.id)
    );
  }

  private updateAudios(
    institutionId: number,
    classId: number,
    studentId: number,
    portfolioId: number,
    imageId: number,
    reviewId: number,
    audios: StudentImageReviewAudioModel[]
  ): Observable<any> {
    if (!audios || audios.length === 0) {
      return of(true);
    }

    return this.httpService.updateMany(
      institutionId,
      classId,
      studentId,
      portfolioId,
      imageId,
      reviewId,
      audios.map((audio: StudentImageReviewAudioModel) => {
        return new StudentImageReviewAudioModel(
          audio.id,
          null,
          null,
          audio.fileName,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
      })
    );
  }

  private createAudios(
    institutionId: number,
    classId: number,
    studentId: number,
    portfolioId: number,
    imageId: number,
    reviewId: number,
    audios: StudentImageReviewAudioModel[]
  ): Observable<StudentImageReviewAudioModel[]> {
    if (!audios || audios.length === 0) {
      return of([]);
    }

    audios.forEach((audio: StudentImageReviewAudioModel) => {
      const extension: string = this.mimeTypesService.getExtensionFromMime(audio.mimeType);

      audio.originalFileName = `${audio.originalFileName}${extension ? `.${extension}` : ''}`;
    });

    const observables: (Observable<StudentImageReviewAudioModel>)[] = audios.map((audio: StudentImageReviewAudioModel) => {
      return this.createAudio(
        institutionId,
        classId,
        studentId,
        portfolioId,
        imageId,
        reviewId,
        audio
      );
    })

    return concat(...observables).pipe(toArray());
  }

  private createAudio(
    institutionId: number,
    classId: number,
    studentId: number,
    portfolioId: number,
    imageId: number,
    reviewId: number,
    audio: StudentImageReviewAudioModel
  ): Observable<StudentImageReviewAudioModel> {
    return new Observable((subscriber: Subscriber<StudentImageReviewAudioModel>) => {
      this.getUploadUrls(audio).pipe(
        catchError(e => {
          console.error(e);

          subscriber.error(e);
          
          return throwError(() => e);
        })
      ).subscribe((urls: StudentImageReviewAudioUploadUrlsModel) => {
        audio.id = urls && urls.audio ? urls.audio.id : null;

        if (audio.isDeleting) {
          return subscriber.next(null);
        }

        audio.percent = '0%';

        this.uploadAudioHandler(urls.audioUrl, audio).pipe(
          catchError(e => {
            console.error(e);

            subscriber.error(e);
            
            return throwError(() => e);
          })
        ).subscribe(() => {
          if (audio.isDeleting) {
            return this.httpService.deleteMany(
              institutionId,
              classId,
              studentId,
              portfolioId,
              imageId,
              reviewId,
              [audio.id]
            ).subscribe(() => {
              subscriber.next(null);
            });
          }

          this.httpService.finishUploading(
            institutionId,
            classId,
            studentId,
            portfolioId,
            imageId,
            reviewId,
            audio
          ).pipe(
            catchError(e => {
              console.error(e);

              subscriber.error(e);
              
              return throwError(() => e);
            })
          ).subscribe((res: StudentImageReviewAudioModel) => {
            subscriber.next(res);
            subscriber.complete();
          });
        });
      });
    });
  }

  private uploadAudioHandler(url: string, audio: StudentImageReviewAudioModel): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.httpService.uploadAudio(url, audio.blob, audio.mimeType).pipe(
        catchError(e => {
          console.error(e);

          subscriber.error(e);

          return throwError(() => e);
        })
      ).subscribe((event: { type: number, loaded: number, total: number }) => {
        if (event.type === HttpEventType.UploadProgress) {
          audio.percent = `${Math.round(100 / event.total * event.loaded)}%`;
        }

        if (event.type === HttpEventType.Response) {
          subscriber.next(true);
          subscriber.complete();
        }
      });
    });
  }

  private getUploadUrls(audio: StudentImageReviewAudioModel): Observable<StudentImageReviewAudioUploadUrlsModel> {
    return this.s3HttpService.getStudentReviewAudioUploadUrl(
      audio.originalFileName,
      audio.fileName,
      audio.mimeType,
      audio.duration,
      audio.fileSize,
    );
  }
}
