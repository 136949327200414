import {Component, Input, Output, EventEmitter} from '@angular/core';

import {ModalsService} from '../../../services/modals/modals.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';

import {Button} from '../../../../common/models/button/button.model';

import {PUBLISH_BUTTON_STATES} from '../../../../services/publish-website/constants';

@Component({
  selector: 'app-save-request-modal',
  templateUrl: './save-request-modal.component.html',
  styleUrls: ['./save-request-modal.component.scss']
})
export class SaveRequestModalComponent {
  @Input()  id = 'save-request-modal';

  @Output() dontSave = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() continueEditing = new EventEmitter();

  public isPublishing: boolean = false;

  header = {
    className: 'warning-header',
  };

  private generalButtons: Button[] = [
    {
      text: `Don't Save`,
      className: 'red dont-save-button',
      onClick: this.dontSaveHandler.bind(this),
    },
    {
      text: `Continue Editing`,
      className: 'neutral continue-editing-button',
      onClick: this.continueEditingHandler.bind(this),
    },
    {
      text: `Save Changes`,
      className: 'green save-changes-button',
      onClick: this.saveHandler.bind(this),
    },
  ];

  private publishButtons: Button[] = [
    {
      text: 'Yes',
      className: 'red dont-save-button',
      onClick: this.dontSaveHandler.bind(this),
    },
    {
      text: 'No',
      className: 'neutral continue-editing-button',
      onClick: this.continueEditingHandler.bind(this),
    }
  ];

  public get buttons(): Button[] {
    return this.isPublishing ? this.publishButtons : this.generalButtons;
  }

  public get warningText(): string {
    return this.isPublishing ? 'You have unsaved changes that will be lost. Do you want to continue?' : 'Would you like to save your changes?';
  }

  constructor(private modalsService: ModalsService,
              private buttonsService: ButtonsService) {
    this.buttonsService.onPublishButtonChangeSubject.subscribe((state: string) => this.isPublishing = state === PUBLISH_BUTTON_STATES.PUBLISHING);
  }

  public dontSaveHandler(): void {
    this.dontSave.emit();

    this.modalsService.close(this.id);
  }

  public saveHandler(): void {
    this.save.emit();

    this.modalsService.close(this.id);
  }

  public continueEditingHandler(): void {
    this.continueEditing.emit();

    this.modalsService.close(this.id);
  }
}

