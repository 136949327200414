import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {CurrenciesHttpService} from '../interaction/http/currencies/currencies-http.service';
import {WebsitesService} from '../websites/websites.service';

import {CurrencyModel} from '../../models/currency/currency.model';
import {WebsiteModel} from '../../models/websites/website.model';

@Injectable()
export class CurrenciesService {
  public websiteCurrencySubject: BehaviorSubject<CurrencyModel> = new BehaviorSubject<CurrencyModel>(null);
  public currenciesSubject: BehaviorSubject<CurrencyModel[]> = new BehaviorSubject<CurrencyModel[]>(null);

  constructor(private currenciesHttpService: CurrenciesHttpService,
              private websitesService: WebsitesService) {
    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      if (!website) return;

      this.fetch();
    });
  }

  private fetch(): void {
    this.fetchAll();
    this.fetchWebsiteCurrency();
  }

  private fetchWebsiteCurrency() {
    this.currenciesHttpService.getForWebsite().subscribe((currency: CurrencyModel) => {
      this.websiteCurrencySubject.next(currency);
    });
  }

  private fetchAll() {
    this.currenciesHttpService.getAll().subscribe((currencies: CurrencyModel[]) => {
      this.currenciesSubject.next(currencies);
    });
  }
}
