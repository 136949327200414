import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {EventsService} from '../../services/interaction/events/events.service';
import {IFrameService} from '../../services/iframe/iframe.service';
import {EditorDevicesService} from '../../services/editor-devices/editor-devices.service';

import {EDGES, DEVICES_TO_CLASSES, DEVICES_SIZES, MIN_WIDTH, MIN_HEIGHT} from './constants';
import {DEVICES} from '../../services/editor-devices/constants';

@Component({
  selector: 'app-device-window-wrapper',
  templateUrl: './device-window-wrapper.component.html',
  styleUrls: ['./device-window-wrapper.component.scss'],
})
export class DeviceWindowWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild('windowWrapperElement') windowWrapper: ElementRef;
  @ViewChild('windowElement') window: ElementRef;

  @ViewChild('topResizeBar') topResizeBar: ElementRef;
  @ViewChild('rightResizeBar') rightResizeBar: ElementRef;
  @ViewChild('bottomResizeBar') bottomResizeBar: ElementRef;
  @ViewChild('leftResizeBar') leftResizeBar: ElementRef;

  public className: string;

  public isResizeBarsVisible: boolean = false;

  public size = {
    height: void 0,
    width: void 0,
  };

  public startX: number = 0;
  public startY: number = 0;

  private deviceKey: string;
  private draggingEdge: string;

  private isDragging: boolean = false;

  private onTransitionEnd = this.dispatchResize.bind(this);

  private removeMouseUpHandler;
  private removeMouseMoveHandler;

  private dragHandlers = {
    [EDGES.TOP]: this.onTopEdgeDrag.bind(this),
    [EDGES.RIGHT]: this.onRightEdgeDrag.bind(this),
    [EDGES.BOTTOM]: this.onBottomEdgeDrag.bind(this),
    [EDGES.LEFT]: this.onLeftEdgeDrag.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get edges() {
    return EDGES;
  }

  private get maxWidth(): number {
    return this.windowWrapper.nativeElement.clientWidth - 80;
  }

  private get maxHeight(): number {
    return this.windowWrapper.nativeElement.clientHeight;
  }

  constructor(private renderer: Renderer2,
              private cdr: ChangeDetectorRef,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private editorDevicesService: EditorDevicesService) {
    this.editorDevicesService.onDeviceChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((deviceKey: string) => {
      this.deviceKey = deviceKey;
      this.isResizeBarsVisible = deviceKey === DEVICES.MOBILE || deviceKey === DEVICES.TABLET;
      this.className = DEVICES_TO_CLASSES[deviceKey];

      this.initWindowSize();
    });
  }

  public ngAfterViewInit(): void {
    this.window.nativeElement.addEventListener('transitionend', this.onTransitionEnd);
  }

  public dispatchResize(event: TransitionEvent): void {
    if (event.target !== this.window.nativeElement) return;

    this.eventsService.dispatchResize(this.iFrameService.sandboxWindow);
  }

  public onMouseDown(e: MouseEvent, edge: string): void {
    this.isDragging = true;
    this.draggingEdge = edge;

    this.startX = e.clientX;
    this.startY = e.clientY;

    this.removeMouseUpHandler = this.renderer.listen('document', 'mouseup', this.onMouseUp.bind(this));
    this.removeMouseMoveHandler = this.renderer.listen('document', 'mousemove', this.onMouseMove.bind(this));

    if (this.windowWrapper) this.windowWrapper.nativeElement.classList.add('no-animation');
  }

  public onMouseUp(): void {
    this.isDragging = false;

    if (this.removeMouseUpHandler) this.removeMouseUpHandler();
    if (this.removeMouseMoveHandler) this.removeMouseMoveHandler();

    if (this.windowWrapper) this.windowWrapper.nativeElement.classList.remove('no-animation');
  }

  private onMouseMove(e: MouseEvent): void {
    if (!this.isDragging || !this.dragHandlers[this.draggingEdge]) return;

    this.dragHandlers[this.draggingEdge](e);

    this.setSizes();

    this.cdr.detectChanges();
  }

  private onTopEdgeDrag(e: MouseEvent): void {
    const nextValue = this.size.height + this.startY * 2 - e.clientY * 2;
    const isValid = this.isHeightValid(nextValue);

    this.size.height = isValid ? nextValue : this.getHeight(nextValue);

    this.startY = isValid ? e.clientY : this.topResizeBar.nativeElement.getBoundingClientRect().top;
  }

  private onRightEdgeDrag(e: MouseEvent): void {
    const nextValue = this.size.width + e.clientX * 2 - this.startX * 2;
    const isValid = this.isWidthValid(nextValue);

    this.size.width = isValid ? nextValue : this.getWidth(nextValue);

    this.startX = isValid ? e.clientX : this.rightResizeBar.nativeElement.getBoundingClientRect().left;
  }

  private onBottomEdgeDrag(e: MouseEvent): void {
    const nextValue = this.size.height + e.clientY * 2 - this.startY * 2;
    const isValid = this.isHeightValid(nextValue);

    this.size.height = isValid ? nextValue : this.getHeight(nextValue);

    this.startY = isValid ? e.clientY : this.bottomResizeBar.nativeElement.getBoundingClientRect().top;
  }

  private onLeftEdgeDrag(e: MouseEvent): void {
    const nextValue = this.size.width + this.startX * 2 - e.clientX * 2;
    const isValid = this.isWidthValid(nextValue);

    this.size.width = isValid ? nextValue : this.getWidth(nextValue);

    this.startX = isValid ? e.clientX : this.leftResizeBar.nativeElement.getBoundingClientRect().left;
  }

  private initWindowSize(): void {
    const isDesktop = this.deviceKey === DEVICES.DESKTOP;

    const width = isDesktop ? void 0 : DEVICES_SIZES[this.deviceKey].maxWidth;
    const height = isDesktop ? void 0 : DEVICES_SIZES[this.deviceKey].maxHeight;

    this.size.width = this.getWidth(width);
    this.size.height = this.getHeight(height);

    this.setSizes();
  }

  private getWidth(value: number): number {
    if (!this.windowWrapper) return value;

    const maxWidth = this.maxWidth;

    if (value > maxWidth) return maxWidth;
    if (value < MIN_WIDTH) return MIN_WIDTH;

    return value;
  }

  private getHeight(value: number): number {
    if (!this.windowWrapper) return value;

    const maxHeight = this.maxHeight;

    if (value > maxHeight) return maxHeight;
    if (value < MIN_HEIGHT) return MIN_HEIGHT;

    return value;
  }

  private isWidthValid(value: number): boolean {
    if (!this.windowWrapper) return false;

    return value >= MIN_WIDTH && value <= this.maxWidth;
  }

  private isHeightValid(value: number): boolean {
    if (!this.windowWrapper) return false;

    return value >= MIN_HEIGHT && value <= this.maxHeight;
  }

  private setSizes(): void {
    if (!this.window) return;

    this.window.nativeElement.style.maxWidth = this.size.width ? `${this.size.width}px` : '';
    this.window.nativeElement.style.maxHeight = this.size.height ? `${this.size.height}px` : '';

    this.editorDevicesService.onWidthChange(this.size.width);
  }

  public ngOnDestroy(): void {
    this.window.nativeElement.removeEventListener('transitionend', this.onTransitionEnd);
    
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
