import {TemplatePreviewModel} from './template-preview.model';
import {TemplatePreviewDetailsDto} from './details/template-preview-details.dto';
import {TemplatePreviewDetailsModel} from './details/template-preview-details.model';

export class TemplatePreviewDto {
  constructor(public Themes?: { [key: string]: TemplatePreviewDetailsDto[] },
              public MainImagePath?: string) {}

  public static normalize(res: TemplatePreviewDto): TemplatePreviewModel {
    if (!res) return null;

    return new TemplatePreviewModel(
      TemplatePreviewDto.normalizeThemes(res),
      res.MainImagePath,
    );
  }

  public static normalizeThemes(res: TemplatePreviewDto): { [key: string]: TemplatePreviewDetailsModel[] } {
    if (!res || !res.Themes) return null;

    return Object.keys(res.Themes).reduce((preview, key) => {
      if (!res.Themes[key]) return preview;

      preview[key] = res.Themes[key].map((item: TemplatePreviewDetailsDto) => TemplatePreviewDetailsDto.normalize(item));

      return preview;
    }, {});
  }
}
