import {Injectable} from '@angular/core';

import {IFrameService} from '../../iframe/iframe.service';

import {GoogleFontModel} from '../../../models/google/fonts/google-font.model';
import {IBlogUnapprovedCommentsCountModel} from '../../../models/sockets/message/blog-unapproved-comments-count/i-blog-unapproved-comments-count.model';
import {NodeModel} from '../../../models/nodes/node.model';
import {BlockModel} from '../../../models/blocks/block.model';

@Injectable()
export class EventsService {
  private listeners: {
    key: string,
    listeners: {
      handler: EventListenerOrEventListenerObject,
      options?: any,
    }[],
  }[] = [];

  private isLoaded: boolean = false;

  constructor(private iFrameService: IFrameService) {
    this.iFrameService.isLoadedSubject.subscribe(isLoaded => {
      this.isLoaded = isLoaded;

      if (!isLoaded || !this.iFrameService.sandboxWindow) return;

      this.listeners.forEach(({ key, listeners }) => {
        listeners.forEach(({ handler, options }) => {
          this.addListener(key, handler, options);
        });
      });
    });
  }

  public dispatchResize(container: HTMLElement | Window = window) {
    if (!container) return;

    container.dispatchEvent(new Event('resize'));
  }

  public dispatchCustomStyleChangedEvent(data?: { target: HTMLElement, key: string, value: any }, container: HTMLElement | Window = window) {
    this.dispatchEvent('customStylesChanged', data, container);
  }

  public dispatchStyleChangedEvent(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('styleChanged', data, container);
  }

  public dispatchLogoImageSelected(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('logoImageSelected', data, container);
  }

  public dispatchSocialNetworksChanged(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('socialNetworksChanged', data, container);
  }

  public dispatchShareSocialNetworksChanged(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('shareSocialNetworksChanged', data, container);
  }

  public dispatchLogoDelete(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('logoDelete', data, container);
  }

  public dispatchLogoVisibilityChanged(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('logoVisibilityChanged', data, container);
  }

  public dispatchTitleVisibilityChanged(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('titleVisibilityChanged', data, container);
  }

  public dispatchSubtitleVisibilityChanged(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('subtitleVisibilityChanged', data, container);
  }

  public dispatchMobilePortfolioChange(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('mobilePortfolioChanged', data, container);
  }

  public dispatchMobileEnlargementChange(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('mobileEnlargementChanged', data, container);
  }

  public dispatchDocumentSelect(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('documentSelected', data, container);
  }

  public dispatchVideoSelect(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('videoSelected', data, container);
  }

  public dispatchVideoEmbedInsert(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('videoEmbedInsert', data, container);
  }

  public dispatchAudioSelect(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('audioSelected', data, container);
  }

  public dispatchAttachDragEvents(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('attachDragEvents', data, container);
  }

  public dispatchCancelChanges(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('onCancelChanges', data, container);
  }

  public dispatchContentLoad(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('onContentLoad', data, container);
  }

  public dispatchImageChanged(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('imageChanged', data, container);
  }

  public dispatchBlockReInit(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('blockReInit', data, container);
  }

  public dispatchCustomizableLayerChanged(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('onCustomizableLayerChanged', data, container);
  }

  public dispatchOutdatedBlocks(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('outdatedBlocks', data, container);
  }

  public dispatchMakeLinkAction(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('onMakeLink', data, container);
  }

  public dispatchPortfolioSelect(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('onPortfolioSelect', data, container);
  }

  public dispatchImageLabelStyleChanged(container: HTMLElement | Window = window) {
    container.dispatchEvent(new Event('imageLabelStyleChanged'));
  }

  public dispatchCaptchaVisibilitySet(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('captchaVisibilitySet', data, container);
  }

  public dispatchContactInfoUsedChange(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('contactInfoUsedChange', data, container);
  }

  public dispatchFocusOnSubtitle(data?: any, container: HTMLElement | Window = window) {
    this.dispatchEvent('focusOnSubtitle', data, container);
  }

  public dispatchFontsInit(data?: { fonts: GoogleFontModel[] }, container: HTMLElement | Window = window) {
    this.dispatchEvent('initFonts', data, container);
  }

  public dispatchPageRemove(data?: { pageId: number, pageType: string }, container: HTMLElement | Window = window) {
    this.dispatchEvent('pageRemove', data, container);
  }

  public dispatchFontLinkRemove(data?: { font: GoogleFontModel }, container: HTMLElement | Window = window) {
    this.dispatchEvent('removeFontLink', data, container);
  }

  public dispatchWebsiteStylesChanged(data?: { key: string, value: any }, container: HTMLElement | Window = window) {
    this.dispatchEvent('websiteStylesChanged', data, container);
  }

  public dispatchStickyMenuChanged(container: HTMLElement | Window = window) {
    this.dispatchEvent('stickyMenuChanged', null, container);
  }

  public dispatchLeftMenuClose(container: HTMLElement | Window = window) {
    this.dispatchEvent('mobileLeftMenuClose', null, container);
  }

  public dispatchRightMenuClose(container: HTMLElement | Window = window) {
    this.dispatchEvent('mobileRightMenuClose', null, container);
  }

  public dispatchAllMenuItemsCollapse(data?: { target: HTMLElement }, container: HTMLElement | Window = window) {
    this.dispatchEvent('collapseAllMenuItems', data, container);
  }

  public dispatchBlogReload(container: HTMLElement | Window = window) {
    this.dispatchEvent('reloadBlog', null, container);
  }

  public dispatchNodesUpdated(data?: { nodes: NodeModel[], websiteId: number }, container: HTMLElement | Window = window) {
    this.dispatchEvent('nodesUpdated', data, container);
  }

  public dispatchPortfolioTitleVisibilityToggle(data?: { element: HTMLElement, isEnabled: boolean }, container: HTMLElement | Window = window) {
    this.dispatchEvent('portfolioTitleVisibilityToggle', data, container);
  }

  public dispatchResetToFactoryDefaultsCompleted(container: HTMLElement | Window = window) {
    this.dispatchEvent('resetToFactoryDefaultsCompleted', null, container);
  }

  public dispatchMigratePortfolioSlidesForDefaultPortfolio(data?: { portfolios: BlockModel[], defaultPortfolioHtml: string }, container: HTMLElement | Window = window) {
    this.dispatchEvent('migratePortfolioSlidesForDefaultPortfolio', data, container);
  }

  public dispatchNewBlockDragStart(container: HTMLElement | Window = window) {
    this.dispatchEvent('newBlockDragStart', null, container);
  }

  public dispatchBlockRemoveByIndex(index: number, container: HTMLElement | Window = window) {
    this.dispatchEvent('blockRemoveByIndex', { index }, container);
  }

  public dispatchRequestImagesDataByImagesId(data?: { imageIds: number[] }, container: HTMLElement | Window = window) {
    this.dispatchEvent('requestImagesDataByImagesId', data, container);
  }

  public dispatchRequestObjectsDataByObjectsId(data?: { key: string, objects: Array<any> }, container: HTMLElement | Window = window) {
    this.dispatchEvent('requestObjectsDataByObjectsId', data, container);
  }

  public dispatchDocumentManagerOpened(data?: { element: HTMLElement }, container: HTMLElement | Window = window) {
    this.dispatchEvent('documentManagerOpened', data, container);
  }

  public dispatchEditorFullscreenToggle(data?: { isFullscreen: boolean, root?: HTMLElement }, container: HTMLElement | Window = window) {
    this.dispatchEvent('onEditorFullscreenToggle', data, container);
  }

  public dispatchElementSelect(data?: { element: HTMLElement }, container: HTMLElement | Window = window) {
    this.dispatchEvent('selectElement', data, container);
  }

  public dispatchBlogUnapprovedCommentsCount(data: IBlogUnapprovedCommentsCountModel[], container: HTMLElement | Window = window) {
    if (!data) return;

    const reducedData = data.reduce((res: { [key: number]: number }, curr: IBlogUnapprovedCommentsCountModel) => {
      res[curr.postId] = curr.count;

      return res;
    }, {});

    this.dispatchEvent('blogUnapprovedCommentsCount', reducedData, container);
  }

  public dispatchEvent(name?: string, data?: any, container: HTMLElement | Window = window) {
    if (!container) return console.error('Events Service: Container not found');

    const e = new CustomEvent(name, { detail: data });
    container.dispatchEvent(e);
  }

  public addFrameListener(event: string, handler: EventListenerOrEventListenerObject, options?: any) {
    const i: number = this.getItemIdx(event);

    if (i === -1) return;

    this.listeners[i].listeners.push({
      handler,
      options,
    });

    this.addListener(event, handler, options);
  }

  public removeFrameListener(event: string, handlerToRemove: EventListenerOrEventListenerObject, optionsToRemove?: any): void {
    if (!this.isLoaded) return;

    const sandboxWindow: Window = this.iFrameService.sandboxWindow;

    if (!sandboxWindow) return;

    const i: number = this.getItemIdx(event);

    if (i === -1) return;

    const y = this.listeners[i].listeners.findIndex(({ handler, options }) => handler === handlerToRemove && options === optionsToRemove);

    if (y === -1) return;

    sandboxWindow.removeEventListener(event, this.listeners[i].listeners[y].handler, this.listeners[i].listeners[y].options);

    this.listeners[i].listeners = this.listeners[i].listeners.filter(({ handler, options }) => handler !== handlerToRemove && options !== optionsToRemove);
  }

  private getItemIdx(event: string): number {
    const i: number = this.listeners.findIndex(({ key }) => key === event);

    if (i !== -1) return i;

    this.listeners.push({
      key: event,
      listeners: [],
    });

    return this.listeners.length - 1;
  }

  private addListener(key: string, handler: EventListenerOrEventListenerObject, options?: any): void {
    if (!this.isLoaded) return;

    const sandboxWindow: Window = this.iFrameService.sandboxWindow;

    if (!sandboxWindow) return;

    sandboxWindow.removeEventListener(key, handler, options);
    sandboxWindow.addEventListener(key, handler, options);
  }
}
