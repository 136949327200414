import {SetupOptionModel} from '../../option/setup-option.model';
import {GoogleFontModel} from '../../../../google/fonts/google-font.model';

import {PROPERTY_NAME} from './constants';

export class BaseFontFamilyModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public stylePropertyName: string = null,
    public usedFontAttribute: string = null,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.initValue();
  }

  public onChange(font: GoogleFontModel): void {
    if (!this.isEnabled) return;

    this.setValue(font);

    this.element.setAttribute(this.usedFontAttribute, font.family);

    this.element.style.setProperty(this.stylePropertyName, font.family);
  }

  public setValue(font: GoogleFontModel): void {
    this.value = font.family.replace(/"/gi, '');
  }

  public reset(): void {
    this.element.style.removeProperty(this.stylePropertyName);
    this.element.removeAttribute(this.usedFontAttribute);

    this.initValue();
  }

  public initValue(): void {
    if (!this.isEnabled) return;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    // >> Possible values:
    // "Font Name", sans-serif;
    // "Font Name"; Font Name
    const value = (computedStyles.getPropertyValue(this.stylePropertyName) || computedStyles.getPropertyValue(PROPERTY_NAME)).trim();

    if (!value.includes('"')) {
      this.value = value.match(/(\w|\s)+/gi)[0];

      return; 
    }

    this.value = value.match(/"(\w|\s)+"/gi)[0].replace(/"/gi, '');
  }
}
