import {EducationClassDto} from '../../classes/education-class.dto';
import {EducationStudentWebsiteModel} from './education-student-website.model';

export class EducationStudentWebsiteDto {
  constructor(
    public Id?: number,
    public UserId?: number,
    public ClassId?: number,
    public AccountSubscriptionId?: number,
    public Title?: string,
    public Type?: string,
    public Course?: EducationClassDto,
  ) {}

  public static normalize(res: EducationStudentWebsiteDto): EducationStudentWebsiteModel {
    if (!res) return null;

    return new EducationStudentWebsiteModel(
      res.Id,
      res.UserId,
      res.ClassId,
      res.AccountSubscriptionId,
      res.Title,
      res.Type,
      EducationClassDto.normalize(res.Course),
    );
  }
}
