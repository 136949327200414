export const KEYS = {
  IS_MOBILE_TITLE_VISIBLE: 'IS_MOBILE_TITLE_VISIBLE',
  IS_DESKTOP_SETUP_MATCHED: 'IS_DESKTOP_SETUP_MATCHED',
  MOBILE_TEXT_COLOR: 'MOBILE_TEXT_COLOR',
  FONT_SIZE: 'FONT_SIZE',
  LINE_HEIGHT: 'LINE_HEIGHT',
  MOBILE_TITLE_LOGO_POSITION: 'MOBILE_TITLE_LOGO_POSITION',
  SCALABLE_MOBILE_FONT_SIZE: 'SCALABLE_MOBILE_FONT_SIZE',
  SCALABLE_MOBILE_LINE_HEIGHT: 'SCALABLE_MOBILE_LINE_HEIGHT',
  SCALABLE_MOBILE_LETTER_SPACING: 'SCALABLE_MOBILE_LETTER_SPACING',
  SCALABLE_MOBILE_WORD_SPACING: 'SCALABLE_MOBILE_WORD_SPACING',
};

export const TYPES = {
  ATTRIBUTE: 'ATTRIBUTE',
  CLASS: 'CLASS',
  STYLE_PROPERTY: 'STYLE_PROPERTY',
};
