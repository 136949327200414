import {BaseFileObjectDto} from '../base/file-object/base-file-object.dto';

import {AudioModel} from './audio.model';

export class AudioDto extends BaseFileObjectDto {
  constructor(public Id?: number,
              public UserId?: number,
              public FileName?: string,
              public OriginalFileName?: string,
              public Duration?: string,
              public FileSize?: string,
              public Link?: string) {
    super();
  }

  public static normalize(res: AudioDto): AudioModel {
    if (!res) return null;

    return new AudioModel(
      res.Id,
      res.UserId,
      res.FileName,
      res.OriginalFileName,
      res.Duration,
      res.FileSize,
      res.Link,
    );
  }
}
