// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings {
  height: 100%;
  width: 100%;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/settings/settings.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,cAAA;AADF","sourcesContent":["\n\n.settings {\n  height: 100%;\n  width: 100%;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
