import {Injectable} from '@angular/core';

import {Subscription, BehaviorSubject, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';

import {AuthService} from '../../auth/auth.service';
import {MessageModalService} from '../message-modal.service';
import {WebsiteDesignerService} from '../../application/main/website-designer/website-designer.service';
import {ReportsHttpService} from '../../core/services/interaction/http/report/reports-http.service';
import {SocketsService} from '../../core/services/interaction/sockets/sockets.service';
import {ButtonsService} from '../../core/services/buttons/buttons.service';
import {EditorControlButtonsService} from '../editor-control-buttons.service';
import {PublishingStatusModalService} from '../../shared/services/modals/publishing-status/publishing-status-modal.service';
import {WebsitesService} from '../../core/services/websites/websites.service';
import {IsPublishingService} from '../is-publishing/is-publishing.service';

import {Report} from '../../core/models/report/report.model';
import {AccountModel} from '../../core/models/accounts/account.model';
import {ModalHeader} from '../../common/models/modal/header/header.model';
import {Button} from '../../common/models/button/button.model';
import {ISocketPublishMessageDataModel} from '../../core/models/sockets/message/publish/i-publish-message-data.model';
import {WebsiteModel} from '../../core/models/websites/website.model';

import {
  PUBLISH_STATUSES,
  PUBLISH_ERROR_MESSAGE,
  NEW_SESSION_PUBLISH_ERROR_MESSAGE,
  PUBLISH_SUCCESS_MESSAGE,
  START_PUBLISH_MESSAGE, SOCKET_STATUSES_TO_BUTTON_STATES, PUBLISH_BUTTON_STATES,
} from './constants';

@Injectable()
export class PublishWebsiteService {
  public progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public publishingModalHeader: ModalHeader = new ModalHeader(`Hang On! We're Publishing your Site!`, 'warning-header');

  public publishingModalButtons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onModalClose.bind(this),
    },
  ];

  public errorModalHeader : ModalHeader = new ModalHeader('Oops! Something went wrong...');

  public errorModalButtons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onModalClose.bind(this),
    },
  ];

  public successModalHeader: ModalHeader = new ModalHeader('Success!', 'success-header');

  public successModalButtons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onModalClose.bind(this),
    },
  ];

  public isWaiting: boolean = false;
  public isPublishPossible: boolean = false;
  public isPublished: boolean = false;
  public isPublishing: boolean = false;

  private websiteId: number;
  private windowWebsiteId: number;
  private site: Window;

  private account: AccountModel;

  private status: string;

  private statusesHandlers = {
    [PUBLISH_STATUSES.WAITING_FOR_CHANGES]: () => {},
    //[PUBLISH_STATUSES.READY]: this.onPublishReady.bind(this),
    [PUBLISH_STATUSES.STARTED]: this.onPublishStarted.bind(this),
    //[PUBLISH_STATUSES.PREPARING_DATA]: this.onPreparing.bind(this),
    //[PUBLISH_STATUSES.PAGES_GENERATING]: this.onPagesGenerating.bind(this),
    //[PUBLISH_STATUSES.BUILDING]: this.onBuilding.bind(this),
    //[PUBLISH_STATUSES.UPLOADING]: this.onUploading.bind(this),
    [PUBLISH_STATUSES.DONE]: this.onDone.bind(this),
    [PUBLISH_STATUSES.FAILED]: this.onFailed.bind(this),
    [PUBLISH_STATUSES.NEW_SESSION_FAILED]: this.onNewSessionFailed.bind(this),
  };

  private publishDataHandlers = [
    PUBLISH_STATUSES.STARTED,
    PUBLISH_STATUSES.PREPARING_DATA,
    PUBLISH_STATUSES.PAGES_GENERATING,
    PUBLISH_STATUSES.BUILDING,
    PUBLISH_STATUSES.UPLOADING,
    PUBLISH_STATUSES.DONE,
  ];

  private templateId: number;

  constructor(
    private websiteDesignerService: WebsiteDesignerService,
    private isPublishingService: IsPublishingService,
    private websitesService: WebsitesService,
    private editorControlButtonsService: EditorControlButtonsService,
    private authService: AuthService,
    private messageModalService: MessageModalService,
    private publishingStatusModalService: PublishingStatusModalService,
    private buttonsService: ButtonsService,
    private socketsService: SocketsService,
    private reportsService: ReportsHttpService,
  ) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      if (!website) {
        return;
      }

      this.isPublishingService.handleActiveWebsite(website);
    });

    this.websitesService.activeWebsiteIdSubject.subscribe((websiteId: number) => {
      this.websiteId = websiteId;
    });

    this.websitesService.activeTemplateIdSubject.subscribe((templateId: number) => {
      if (!templateId) {
        return;
      }

      this.templateId = templateId;
    });

    this.socketsService.publishDataSubject.subscribe((data: ISocketPublishMessageDataModel) => {
      if (!data || !data.status || data.websiteId !== this.websitesService.activeWebsiteId) {
        return;
      }

      this.handlePublishData(data);
    });

    this.buttonsService.onPublishButtonChangeSubject.subscribe((state: string) => {
      this.isWaiting = state === PUBLISH_BUTTON_STATES.DISABLED;
      this.isPublishPossible = state === PUBLISH_BUTTON_STATES.ENABLED;
      this.isPublished = state === PUBLISH_BUTTON_STATES.SUCCESS;
      this.isPublishing = state === PUBLISH_BUTTON_STATES.PUBLISHING;
    });
  }

  public publishWebsite(): void {
    this.websiteDesignerService.publishWebsite().pipe(
      catchError(e => {
        console.error(e);

        this.onPublishError(e);

        return throwError(() => e);
      }),
    ).subscribe(() => {});
  }

  public openWebsite(link: string): void {
    if (this.site && !this.site.closed && this.windowWebsiteId === this.websiteId) {
      return this.site.focus();
    }

    this.windowWebsiteId = this.websiteId;

    this.site = window.open(link, '_blank');
  }

  private getErr(err): string {
    try {
      return JSON.stringify(err);
    } catch (e) {
      return '';
    }
  }

  private onModalClose(): void {
    this.messageModalService.close();
  }

  private handlePublishData(data: ISocketPublishMessageDataModel): void {
    this.handleStatus(data);

    if (!this.publishDataHandlers.includes(data.status)) {
      return;
    }

    this.progressSubject.next(data.progress);
  }

  private handleStatus(data: ISocketPublishMessageDataModel): void {
    const status: string = data.status;

    if (this.status === status) {
      return;
    }

    this.status = status;

    this.isPublishingService.handleSocketPublishData(data);

    if (!SOCKET_STATUSES_TO_BUTTON_STATES[this.status]) {
      return;
    }

    this.buttonsService.onPublishButtonChangeSubject.next(SOCKET_STATUSES_TO_BUTTON_STATES[this.status]);

    if (!this.statusesHandlers[this.status]) {
      return;
    }

    this.statusesHandlers[this.status]();
  }

  private onPublishStarted(): void {
    this.messageModalService.addMessage(START_PUBLISH_MESSAGE, this.publishingModalHeader, this.publishingModalButtons);
  }

  private onDone(): void {
    this.messageModalService.addMessage(PUBLISH_SUCCESS_MESSAGE, this.successModalHeader, this.successModalButtons);
    this.publishingStatusModalService.close();

    this.editorControlButtonsService.websiteLinkSubject.pipe(take(1)).subscribe((link: string) => {
      this.onSuccessPublishing(link);
    });

    this.websitesService.fetchWebsite();
  }

  private onSuccessPublishing(link: string): void {
    if (this.site && !this.site.closed) {
      this.site.close();
    }

    return this.openWebsite(link);
  }

  private onFailed(): void {
    this.onPublishError('Error on publish');
  }

  private onPublishError(err?: any): Subscription {
    this.messageModalService.addMessage(PUBLISH_ERROR_MESSAGE, this.errorModalHeader, this.errorModalButtons);

    if (!this.account) {
      return Subscription.EMPTY;
    }

    const title = `Urgent, website couldn't be published for ${this.account.name}.`;
    const description = `Happens at: ${window.location.href}. Error: ${this.getErr(err)}`;
    const userAgent: string = navigator.userAgent || navigator.vendor || (<any>window).opera;

    return this.reportsService.send(new Report(title, description, `${this.templateId}`, userAgent, true)).subscribe(() => {});
  }

  private onNewSessionFailed(): void {
    this.messageModalService.addMessage(NEW_SESSION_PUBLISH_ERROR_MESSAGE, this.errorModalHeader, this.errorModalButtons);
  }
}
