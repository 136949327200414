import {Component} from '@angular/core';

import {ReviewDeleteConfirmationModalService} from '../../../../services/modals/education/review-delete-confirmation/review-delete-confirmation-modal.service';

@Component({
  selector: 'app-delete-review-confirmation-modal',
  templateUrl: './delete-review-confirmation-modal.component.html',
  styleUrls: ['./delete-review-confirmation-modal.component.scss']
})
export class EducationDeleteReviewConfirmationModalComponent {
  public header = {
    className: 'warning-header',
  };

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: ReviewDeleteConfirmationModalService,
  ) {
  }

  public onSubmit(): void {
    this.service.onSubmit.next(true);

    this.service.close();
  }

  public onCancel(): void {
    this.service.onSubmit.next(false);

    this.service.close();
  }
}

