import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Observable, Subject, combineLatest} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {AdminEducationTeachersService} from '../../../../../../core/services/admin/education/teachers/admin-education-teachers.service';
import {AdminEducationInstitutionsService} from '../../../../../../core/services/admin/education/institutions/admin-education-institutions.service';
import {AdminEducationClassesService} from '../../../../../../core/services/admin/education/classes/admin-education-classes.service';

import {EducationEditTeacherModel} from '../../../../../../core/models/education/teachers/education-edit-teacher.model';
import {EducationTeacherModel} from '../../../../../../core/models/education/teachers/education-teacher.model';
import {EducationInstitutionModel} from '../../../../../../core/models/education/institutions/education-institution.model';
import {EducationClassModel} from '../../../../../../core/models/education/classes/education-class.model';
import {NestedSelectOption} from '../../../../../../core/models/select/nested/nested-select-option.model';

@Component({
  selector: 'app-admin-education-edit-teacher-modal',
  templateUrl: './edit-teacher-modal.component.html',
  styleUrls: ['./edit-teacher-modal.component.scss']
})
export class AdminEducationEditTeacherModalComponent implements OnInit, OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public teacher: EducationEditTeacherModel = null;

  public institutions: EducationInstitutionModel[];
  public institutionsOptions: NestedSelectOption[];

  public classes: EducationClassModel[];
  public classesOptions: NestedSelectOption[];

  public error: string = '';

  public isSignUpCodeFieldFocused: boolean = false;

  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationTeachersService,
              private insitutionsService: AdminEducationInstitutionsService,
              private classesService: AdminEducationClassesService) {
    this.insitutionsService.fetchAll();
    this.classesService.fetchAll();
  }

  public ngOnInit(): void {
    const detailsObservable: Observable<EducationTeacherModel> = this.service.teacherDetailsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(Boolean)
    );

    const institutionsObservable: Observable<EducationInstitutionModel[]> = this.insitutionsService.institutionsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((list: EducationInstitutionModel[]) => !!list && list.length > 0)
    );

    const classesObservable: Observable<EducationClassModel[]> = this.classesService.classesSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((list: EducationClassModel[]) => !!list && list.length > 0)
    );

    combineLatest([
      detailsObservable,
      institutionsObservable,
      classesObservable,
    ]).subscribe(([teacher, institutions, classes]: [EducationTeacherModel, EducationInstitutionModel[], EducationClassModel[]]) => {
      this.initTeacher(teacher);
      this.initInstitutions(institutions);
      this.initClasses(classes);

      this.initClassesOptions();
    });
  }

  private initTeacher(teacher: EducationTeacherModel): void {
    this.teacher = new EducationEditTeacherModel();

    this.teacher.id = teacher.id;
    this.teacher.firstName = teacher.user.firstName;
    this.teacher.lastName = teacher.user.lastName;
    this.teacher.institutionsIds = teacher.institutions ? teacher.institutions.map((institution: EducationInstitutionModel) => institution.id) : [];
    this.teacher.classesIds = teacher.classes ? teacher.classes.map((item: EducationClassModel) => item.id) : [];
  }

  private initInstitutions(institutions: EducationInstitutionModel[]): void {
    this.institutions = institutions;

    if (!this.teacher.institutionsIds) return;

    this.institutionsOptions = this.institutions.map((item: EducationInstitutionModel) => {
      return new NestedSelectOption(
        item.name,
        `${item.id}`,
        void 0,
        void 0,
        this.teacher.institutionsIds.includes(item.id),
      );
    });
  }

  private initClasses(classes: EducationClassModel[]): void {
    this.classes = classes;
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.key !== 'Enter') return;

    this.submit();
  }

  public onInstitutionSelect(options: NestedSelectOption[]): void {
    const institutions: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    this.teacher.institutionsIds = institutions ? institutions.map((institute: NestedSelectOption) => Number.parseInt(institute.value)) : void 0;
    
    this.initClassesOptions();
  }

  private initClassesOptions(): void {
    if (!this.classes) {
      this.classesOptions = null;

      return;
    }

    const filteredClasses: EducationClassModel[] = this.classes.filter((item: EducationClassModel) => {
      return this.teacher.institutionsIds.includes(item.institutionId);
    });

    this.classesOptions = filteredClasses.map((item: EducationClassModel) => {
      return new NestedSelectOption(
        `${item.name} "${item.number}"`,
        `${item.id}`,
        void 0,
        void 0,
        this.teacher.classesIds.includes(item.id),
      );
    });

    this.teacher.classesIds = this.teacher.classesIds ? this.teacher.classesIds.filter((classId: number) => filteredClasses.findIndex((item: EducationClassModel) => item.id === classId) !== -1) : [];
  }

  public onClassesSelect(options: NestedSelectOption[]): void {
    const classes: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    this.teacher.classesIds = classes ? classes.map((institute: NestedSelectOption) => Number.parseInt(institute.value)) : void 0;
  }

  public submit(): void {
    this.teacher.validate();

    if (!this.teacher.isValid) return;

    this.isLoading = true;
    
    this.service.update(new EducationTeacherModel(
      this.teacher.id,
      void 0,
      void 0,
      this.teacher.institutionsIds ? this.teacher.institutionsIds.map((id: number) => new EducationInstitutionModel(id)) : [],
      this.teacher.classesIds ? this.teacher.classesIds.map((id: number) => new EducationClassModel(id)) : [],
    )).subscribe((err: string) => {
      if (!err) {
        this.closeHandler.emit();

        return;
      }

      this.error = err;

      this.isLoading = false;
    });
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
