import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-slider-option',
  templateUrl: './slider-option.component.html',
  styleUrls: ['./slider-option.component.scss']
})
export class SliderOptionComponent {
  @Input() name: string;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() value: number = 0;
  @Output() valueChange = new EventEmitter();

  handleChange(value) {
    this.value = value;
    this.valueChange.emit(value);
  }
}
