import {KEYS} from '../../../../services/video-manager/embed/constants';

export const QUERY_PARAMS = {
  [KEYS.VIMEO_1]: 'loop',
  [KEYS.VIMEO_2]: 'loop',
};

export const VALUES = {
  [KEYS.VIMEO_1]: {
    DISABLED: '0',
    ENABLED: '1',
  },
  [KEYS.VIMEO_2]: {
    DISABLED: '0',
    ENABLED: '1',
  },
};
