import {NativeSetupOptionModel} from '../option/native-setup-option.model';

import {PROPERTY} from './constants';

export class TransparencyModel extends NativeSetupOptionModel {
  constructor(
    public key: string,
    public isEnabled: boolean = true,
  ) {
    super(key, PROPERTY, isEnabled);
  }

  public init(element: HTMLElement) {
    this.element = element;

    if (!this.element) return;

    this.initValue();
  }

  public onChange(value: string) {
    this.value = value;

    this.element.style[PROPERTY] = `${1 - Number.parseInt(value) / 100}`;
  }

  public reset(): void {
    this.element.style.removeProperty(PROPERTY);

    this.initValue();
  }

  private initValue(): void {
    this.value = 100 - Number.parseFloat(window.getComputedStyle(this.element)[PROPERTY]) * 100;
  }
}
