import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {Subject} from 'rxjs';

import {CardDeclinedModalService} from '../../../services/modals/card-declined/card-declined-modal.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-card-declined-modal',
  templateUrl: './card-declined-modal.component.html',
  styleUrls: ['./card-declined-modal.component.scss']
})
export class CardDeclinedModalComponent implements OnDestroy {
  public header: ModalHeader = {
    text: 'ERROR',
    className: 'error-header',
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private router: Router,
    private service: CardDeclinedModalService,
  ) {
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public close(): void {
    this.service.close();
  }

  public retryPayment(): void {
    this.service.close();

    this.router.navigate([
      '/app',
      {
        outlets: {
          primary: [
            'settings',
            'repeat-payment',
          ],
          sidebar: [
            'account',
            'credit-cards',
          ],
          'over-sidebar': null,
        },
      },
    ],
    {
      queryParamsHandling: 'merge',
    });
  }
}
