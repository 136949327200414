export type EducationKeys = 'institutions' | 'teachers' | 'classes';

export const NAVIGATION_MAPPING: Record<EducationKeys, string> = {
  'institutions': 'institutions',
  'teachers': 'teachers',
  'classes': 'classes',
};

export const ADD_BUTTON_TEXT = {
  'institutions': 'INSTITUTION',
  'teachers': 'EDUCATOR',
  'classes': 'COURSE',
};
