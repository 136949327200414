import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../modals.service';

@Injectable()
export class RemoveSupportRowModalService {
  public readonly id: string = 'remove-support-row-modal';

  public isOpened: boolean = false;

  public isDeleteConfirmed: Subject<boolean> = new Subject<boolean>();

  public constructor(private modalsService: ModalsService) {
  }

  public open(): void {
    this.isOpened = true;

    this.modalsService.open(this.id);
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
