import {SetupOptionModel} from '../../option/setup-option.model';

export class AttributeWithValueModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public attribute: string,
    public value?: string,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = this.getAttributeValue();
  }

  public onChange(value: string): void {
    this.value = value;

    if (!this.isEnabled) return;

    this.element.setAttribute(this.attribute, this.value);
  }

  private getAttributeValue(): string {
    const value = this.element.getAttribute(this.attribute);

    return !value || value === 'null' ? '' : value;
  }
}
