import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {STYLE_PROPERTY, CUSTOM_STYLE_PROPERTY} from './constants';

export class TitleHoverColorModel implements IStyleOption {
  public menu: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.isEnabled = !!element && !!options.block;

    if (!this.isEnabled) return;

    this.element = element;
    this.menu = options.block;

    const currentValue: string = window.getComputedStyle(this.menu).getPropertyValue(CUSTOM_STYLE_PROPERTY);

    if (currentValue) return this.setValue(currentValue === 'undefined' ? void 0 : currentValue);

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);
    const value: string = computedValue.getPropertyValue(CUSTOM_STYLE_PROPERTY) || computedValue.getPropertyValue(STYLE_PROPERTY);

    this.setValue(value === 'undefined' ? void 0 : value);
  }

  public reset(): void {
    if (!this.isEnabled) return;

    this.menu.style.removeProperty(CUSTOM_STYLE_PROPERTY);

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);
    const value: string = computedValue.getPropertyValue(CUSTOM_STYLE_PROPERTY) || computedValue.getPropertyValue(STYLE_PROPERTY);

    this.setValue(value);
  }

  public setValue(value: string): void {
    if (!this.isEnabled) return;

    this.value = value;
    this.menu.style.setProperty(CUSTOM_STYLE_PROPERTY, value);
  }
}
