import {SelectOption} from '../../../select/option/option.model';

export const PROPERTY = '--slideshow-image-border-style';
export const DEFAULT_VALUE: string = 'none';

export const OPTIONS: SelectOption[] = [
  new SelectOption('None', 'none', false),
  new SelectOption('Hidden', 'hidden', false),
  new SelectOption('Dotted', 'dotted', false),
  new SelectOption('Dashed', 'dashed', false),
  new SelectOption('Solid', 'solid', false),
  new SelectOption('Double', 'double', false),
  new SelectOption('Groove', 'groove', false),
  new SelectOption('Ridge', 'ridge', false),
  new SelectOption('Inset', 'inset', false),
  new SelectOption('Outset', 'outset', false),
];
