import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {CLASS_NAME} from './constants';

export class IsMenuTransparentOnHomePageModel implements IStyleOption {
  public element: HTMLElement;

  private innerWrapper: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.innerWrapper = options.innerWrapper;

    if (!this.innerWrapper) return;

    this.setValue(this.element.classList.contains(CLASS_NAME));
  }

  public setValue(value: boolean) {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.classList.toggle(CLASS_NAME, value);
    this.innerWrapper.classList.toggle(CLASS_NAME, value);
  }
}
