import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, BehaviorSubject} from 'rxjs';

import {Ga4AccountSetupModel} from '../../../../models/google/ga-4/account-setup.model';

import {AUTH_ENDPOINT, REQUEST_PARAMS} from './constants';

@Injectable()
export class GoogleAnalytics4ApiService {
  private model = 'google/analytics4';

  public isEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public authorize(): void {
    const form: HTMLFormElement = document.createElement('form');

    form.setAttribute('method', 'GET');
    form.setAttribute('action', AUTH_ENDPOINT);
  
    for (var p in REQUEST_PARAMS) {
      const input: HTMLInputElement = document.createElement('input');

      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', REQUEST_PARAMS[p]);

      form.appendChild(input);
    }
  
    document.body.appendChild(form);

    form.submit();
  }

  public saveData({ accountTicketId }: { accountTicketId: string }): Observable<any> {
    return this.httpClient.post(`api/google/analytics-4/data`, {
      accountTicketId,
    });
  }

  public createAccount({ accessToken, tokenType, userName, regionCode, redirectUri }: { accessToken: string, tokenType: string, userName: string, regionCode: string, redirectUri: string }): Observable<any> {
    return this.httpClient.post(`api/google/analytics-4/account`, {
      accessToken,
      tokenType,
      userName,
      regionCode,
      redirectUri,
    });
  }

  public finishSetup({ accessToken, tokenType, websiteTitle }: {
    accessToken: string,
    tokenType: string,
    websiteTitle: string,
  }): Observable<Ga4AccountSetupModel> {
    return this.httpClient.post<Ga4AccountSetupModel>(`api/google/analytics-4/account-setup`, {
      accessToken,
      tokenType,
      websiteTitle,
    });
  }
}
