import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

@Injectable()
export class IFrameService {
  public isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public onContentLoad: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public onStylesAppliedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isLoaded(): boolean {
    return this.isLoadedSubject.value;
  }

  public get iFrame(): HTMLIFrameElement {
    return <HTMLIFrameElement>document.getElementById('sandbox');
  }

  public get sandbox(): Document {
    const { iFrame } = this;

    return iFrame && iFrame.contentDocument;
  }

  public get sandboxWindow(): Window {
    const { iFrame } = this;

    return iFrame && iFrame.contentWindow;
  }

  public get innerWrapper(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('.innerWrapper');
  }

  public get links(): Element[] {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return [];

    return Array.from(sandbox.head.getElementsByTagName('link'));
  }

  public get title(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('nav [data-website-title]');
  }

  public get titleText(): HTMLElement {
    const { title } = this;

    if (!title) return null;

    return <HTMLElement>title.querySelector('a[data-edit-website-title]');
  }

  public get subtitle(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('[data-website-subtitle]');
  }

  public get subtitleText(): HTMLElement {
    const { subtitle } = this;

    if (!subtitle) return null;

    return <HTMLElement>subtitle.querySelector('a[data-edit-website-subtitle]');
  }

  public get menuItemsWrapper(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('nav .page-list-wrapper');
  }

  public get menuItemInactive(): HTMLElement {
    const { menuItemsWrapper } = this;

    if (!menuItemsWrapper) return null;

    return <HTMLElement>menuItemsWrapper.querySelector('.menu-item-wrapper:not(.active) a[data-menu-item-link="true"]');
  }

  public get mobileTitle(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('[data-website-title-mobile]');
  }

  public get mobileTitleText(): HTMLElement {
    const { mobileTitle } = this;

    if (!mobileTitle) return null;

    return <HTMLElement>mobileTitle.querySelector('a[data-edit-website-title-mobile]');
  }

  public get mobileSubtitle(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('[data-website-subtitle-mobile]');
  }

  public get mobileSubtitleText(): HTMLElement {
    const { mobileSubtitle } = this;

    if (!mobileSubtitle) return null;

    return <HTMLElement>mobileSubtitle.querySelector('a[data-edit-website-subtitle-mobile]');
  }

  public get mobileMenuItemsWrapper(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('aside .sidebar-list');
  }

  public get mobileMenuItemInactive(): HTMLElement {
    const { mobileMenuItemsWrapper } = this;

    if (!mobileMenuItemsWrapper) return null;

    return <HTMLElement>mobileMenuItemsWrapper.querySelector('.mobile-menu-item-wrapper:not(.active) a[data-mobile-menu-item-link="true"]');
  }

  public get socialMediaWrappers(): HTMLElement[] {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return Array.from(sandbox.querySelectorAll('[data-media-wrapper]')) as HTMLElement[];
  }

  public get socialMediaWrapper(): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector('nav [data-media-wrapper]');
  }

  public get socialMediaIconTemplate(): HTMLElement {
    const { socialMediaWrapper } = this;

    if (!socialMediaWrapper) return;

    return <HTMLElement>socialMediaWrapper.querySelector('a[data-icon-example]');
  }

  public get blocks(): HTMLElement[] {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement[]>Array.from(sandbox.querySelectorAll('.innerWrapper .dropzone > .block'));
  }

  constructor() {
    this.initListeners();
  }

  public initListeners() {
    window.document.addEventListener('onEditorReady', () => {
      this.isLoadedSubject.next(true);
    });
  }

  public reload() {
    this.sandbox.location.reload();
  }

  public getItem(selector: string): HTMLElement {
    const { sandbox } = this;

    if (!sandbox || !sandbox.body) return null;

    return <HTMLElement>sandbox.querySelector(selector);
  }

  public getItems(selector: string): Element[] {
    if (!this.sandbox) return [];
    return Array.from(this.sandbox.querySelectorAll(selector));
  }
}
