import {IBaseAudioModel} from "../../../base/audio/i-audio.model";

export class StudentImageReviewAudioModel implements IBaseAudioModel {
  public sizeFormatted?: string = null;
  public durationFormatted?: string = null;

  public blob?: Blob;

  public percent?: string = '';

  public isDeleting?: boolean = false;
  
  constructor(
    public id: number,
    public userId: number,
    public reviewId: number,
    public fileName: string,
    public originalFileName: string,
    public mimeType: string,
    public duration: string,
    public fileSize: string,
    public link: string,
    public listenedAt: string,
    public createdAt: string,
    public updatedAt: string
  ) {
    this.initSize();
    this.initDuration();
  }

  private initSize(): void {
    if (!this.fileSize) return;

    try {
      const size: number = Number.parseInt(this.fileSize);

      if (Number.isNaN(size)) return;

      this.sizeFormatted = `${Math.round((size / 1048576 + Number.EPSILON) * 10) / 10} MB`;
    } catch (e) {}
  }

  private initDuration(): void {
    if (!this.duration) return;

    try {
      const duration: number = Number.parseInt(this.duration);

      if (Number.isNaN(duration)) return;

      const h: number = Math.floor(duration / 3600);
      const m: number = Math.floor(duration % 3600 / 60);
      const s: number = duration % 60;

      this.durationFormatted = `${h ? `${h}:` : ''}${this.formatNumber(m)}:${this.formatNumber(s)}`;
    } catch (e) {}
  }

  private formatNumber(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }

  public static clone(item: StudentImageReviewAudioModel): StudentImageReviewAudioModel {
    if (!item) {
      return null;
    }

    return new StudentImageReviewAudioModel(
      item.id,
      item.userId,
      item.reviewId,
      item.fileName,
      item.originalFileName,
      item.mimeType,
      item.duration,
      item.fileSize,
      item.link,
      item.listenedAt,
      item.createdAt,
      item.updatedAt,
    );
  }
}
