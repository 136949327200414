import {Injectable} from '@angular/core';

@Injectable()
export class MathService {
  constructor() {}

  public closest(arr: number[], goal: number) {
    return arr.reduce((prev, curr) => {
      return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    });
  }
}
