import {Component} from '@angular/core';

import {AlreadySubscribedErrorModalService} from '../../../../services/modals/already-subscribed-error/already-subscribed-error-modal.service';

import {Button} from '../../../../../common/models/button/button.model';

import {AppAnimations} from '../../../../../app-animations';

@Component({
  selector: 'app-already-subscribed-error-modal',
  templateUrl: './already-subscribed-error-modal.component.html',
  styleUrls: ['./already-subscribed-error-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class AlreadySubscribedErrorModalComponent {
  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.close.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: AlreadySubscribedErrorModalService,
  ) {
  }

  public close(): void {
    this.service.close();
  }
}
