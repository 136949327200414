import {IStyleModelOptions} from '../option/i-style-model-options';

import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class IsFooterVisibleOnLoadModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value: boolean = false,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !options.block) return;

    this.element = options.block;

    this.value = !this.element.matches(`[${ATTRIBUTES.IS_FOOTER_VISIBLE_ON_LOAD}="false"]`);
  }

  public onChange(value: boolean): void {
    this.value = value;

    this.element.setAttribute(ATTRIBUTES.IS_FOOTER_VISIBLE_ON_LOAD, `${value}`);
  }
}
