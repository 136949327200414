import {IStyleOption} from '../../option/i-style-option';
import {IStyleModelOptions} from '../../option/i-style-model-options';

import {CLASS_NAMES} from './constants';

export class IsSubtitleVisibleModel implements IStyleOption {
  public menu: HTMLElement;
  private elements: HTMLElement[];

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.menu = options.block;
    this.elements = this.menu ? <HTMLElement[]>Array.from(this.menu.querySelectorAll('[data-website-subtitle], [data-website-subtitle-mobile]')) : null;

    this.isEnabled = this.elements && this.elements.length > 0;

    if (!this.isEnabled) {
      return;
    }

    this.value = !element.classList.contains(CLASS_NAMES.HIDDEN);
  }

  public setValue(value: boolean): void {
    if (!this.isEnabled) {
      return;
    }

    this.value = value;

    this.menu.classList.toggle(CLASS_NAMES.SUBTITLE_VISIBLE, value);

    this.elements.forEach((element: HTMLElement) => {
      element.classList.toggle(CLASS_NAMES.HIDDEN, !value);

      const container: HTMLElement = element.querySelector('[data-edit-website-subtitle]');

      if (container && !container.innerText) {
        container.innerText = 'Subtitle';
      }
    });
  }
}
