import {SelectOption} from '../../../../select/option/option.model';

export const GROUPS = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
};

export const KEYS = {
  REMAIN_OPEN: 'REMAIN_OPEN',
  AUTO_COLLAPSE: 'AUTO_COLLAPSE',
};

export const CLASS_NAMES = {
  [GROUPS.DESKTOP]: {
    [KEYS.REMAIN_OPEN]: 'remain-open',
    [KEYS.AUTO_COLLAPSE]: 'auto-collapse',
  },
  [GROUPS.MOBILE]: {
    [KEYS.REMAIN_OPEN]: 'remain-open-mobile',
    [KEYS.AUTO_COLLAPSE]: 'auto-collapse-mobile',
  },
};

export const DEFAULT_KEY = KEYS.REMAIN_OPEN;

export const OPTIONS = [
  new SelectOption('Remain Open', KEYS.REMAIN_OPEN),
  new SelectOption('Auto Collapse', KEYS.AUTO_COLLAPSE),
];

