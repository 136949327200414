export const KEYS = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  LOGO: 'LOGO',
  DOCUMENT: 'DOCUMENT',
};

export const OBJECT_NAMES_MAPPING = {
  [KEYS.AUDIO]: 'Audio',
  [KEYS.VIDEO]: 'Video',
  [KEYS.LOGO]: 'Logo',
  [KEYS.DOCUMENT]: 'Documents',
};
