import {Injectable} from '@angular/core';

import {Observable, Subscription, BehaviorSubject} from 'rxjs';

import {EducationStudentsWebsitesHttpService} from '../../../interaction/http/education/students/websites/education-students-websites-http.service';

import {EducationStudentWebsiteModel} from '../../../../models/education/students/websites/education-student-website.model';
import {WebsiteModel} from '../../../../models/websites/website.model';

@Injectable()
export class StudentWebsitesService {
  public websitesSubject: BehaviorSubject<EducationStudentWebsiteModel[]> = new BehaviorSubject<EducationStudentWebsiteModel[]>(null);
  public websiteDetailsSubject: BehaviorSubject<EducationStudentWebsiteModel> = new BehaviorSubject<EducationStudentWebsiteModel>(null);
  public isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpService: EducationStudentsWebsitesHttpService) {
  }

  public fetchAllForCurrentStudent(): Subscription {
    this.isLoadedSubject.next(false);

    return this.httpService.getAll().subscribe((res: EducationStudentWebsiteModel[]) => {
      this.websitesSubject.next(res);

      this.isLoadedSubject.next(true);
    });
  }

  public fetchMainWebsite(): Observable<WebsiteModel> {
    return this.httpService.getMainWebsite();
  }

  // public fetchOne(websiteId: number): Subscription {
  //   this.isLoadedSubject.next(false);

  //   return this.httpService.getOne(websiteId).subscribe((res: EducationStudentWebsiteModel) => {
  //     this.websiteDetailsSubject.next(res);

  //     this.isLoadedSubject.next(true);
  //   });
  // }
}
