import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';

export const inputLabel = 'Page Title (This Will Appear In the Menu System)';

export const PAGE_TYPES = [
  {
    value: 'C',
    label: 'Category (group pages under heading)',
  },
  {
    value: 'P',
    label: 'Portfolio (contains mainly images)',
  },
  {
    value: 'T',
    label: 'Text (contains mainly text)',
  },
  {
    value: 'E',
    label: 'Blank (create your own)',
  },
  {
    value: 'A',
    label: 'About Page',
  },
  {
    value: 'M',
    label: 'Contact Page',
  },
  {
    value: 'B',
    label: 'Blog',
    requiredPermissions: [
      {
        type: 'permission',
        value: PERMISSIONS.BLOG,
      },
    ],
  },
];

export const TOUR_KEYS: { [key: string]: string } = {
  SIDEBAR_PAGES: 'SIDEBAR_PAGES',
  SIDEBAR_IMAGE_MANAGER: 'SIDEBAR_IMAGE_MANAGER',
};
