import {IStyleOption} from '../option/i-style-option';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {ColorModel} from '../../color/color.model';

import {COLOR, CUSTOM_TEXT_COLOR, CUSTOM_TEXT_COLOR_RGB} from './constants';

export class ThumbDrawerTextColorModel implements IStyleOption {
  public element: HTMLElement;
  public thumbDrawer: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !options.block) return;

    this.element = options.block;
    this.thumbDrawer = <HTMLElement>this.element.querySelector('[data-thumb-drawer]');

    this.reInit();
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(CUSTOM_TEXT_COLOR);
    this.element.style.removeProperty(CUSTOM_TEXT_COLOR_RGB);

    this.value = window.getComputedStyle(this.thumbDrawer).getPropertyValue(COLOR);

    this.element.style.setProperty(CUSTOM_TEXT_COLOR, this.value);

    const colorModel: ColorModel = new ColorModel(this.value);

    this.element.style.setProperty(CUSTOM_TEXT_COLOR_RGB, colorModel.getRGBstring());
  }

  public reInit(): void {
    if (!this.isEnabled) return;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.value = computedStyles.getPropertyValue(CUSTOM_TEXT_COLOR) || computedStyles.getPropertyValue(COLOR);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(CUSTOM_TEXT_COLOR, value);

    const colorModel: ColorModel = new ColorModel(value);

    this.element.style.setProperty(CUSTOM_TEXT_COLOR_RGB, colorModel.getRGBstring());

  }
}
