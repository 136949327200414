import {Component, Input} from '@angular/core';

import {AppAnimations} from '../../../../app-animations';
import {ImageProcessesService} from '../../../../services/image-processes.service';
import {ModalsService} from '../../../services/modals/modals.service';
import {ImageProcessModel} from '../../../../models/image-processes/image-process.model';

@Component({
  selector: 'app-image-processes-modal',
  templateUrl: './image-processes-modal.component.html',
  styleUrls: ['./image-processes-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageProcessesModalComponent {
  @Input() id: string;

  public imageProcessesToRemove: Array<ImageProcessModel> = [];

  public notRemovedProcesses: ImageProcessModel[] = [];

  public get imageProcesses() {
    return this.imageProcessesService.imageProcessesSubject.value;
  }

  public get imageProcessesTypesMap() {
    return this.imageProcessesService.imageProcessesTypesMap;
  }

  constructor(public imageProcessesService: ImageProcessesService,
              private modalsService: ModalsService) {}

  public addToRemoveList(imageProcess) {
    if (this.imageProcessesToRemove.includes(imageProcess)) {
      this.imageProcessesToRemove = this.imageProcessesToRemove.filter(process => process.processId !== imageProcess.processId);

      return;
    }

    this.imageProcessesToRemove.push(imageProcess);
  }

  public onSubmitHandler() {
    this.imageProcessesService.deleteImageProcesses(this.imageProcessesToRemove).subscribe(({ notRemovedIds }) => {
      if (!notRemovedIds || notRemovedIds.length === 0) return this.onCancelHandler();

      this.imageProcessesService.updateImageProcesses();

      this.notRemovedProcesses = notRemovedIds.map(id => this.imageProcesses.find(process => process.processId === id)).filter(process => process);
    }, console.error);
  }

  public onCancelHandler() {
    this.imageProcessesService.updateImageProcesses();

    this.imageProcessesToRemove = [];

    this.modalsService.close(this.imageProcessesService.modalId);
  }

  public getProcessName({ processName, type }) {
    return `${this.imageProcessesTypesMap ? `${this.imageProcessesTypesMap.get(type)} / ` : ''}${processName}`;
  }
}
