import {SelectOption} from '../../../../models/select/option/option.model';

export const LABELS = {
  BOLD: 'Bold',
};

export const CLASSES = {
  BOLD: 'menu-active-bold',
};

export const DEFAULT_OPTIONS = () => [
  new SelectOption(LABELS.BOLD, CLASSES.BOLD, true),
];
