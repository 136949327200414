import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CustomizerMobileTemplatesService} from '../../../../../../../core/services/customizer/mobile/templates/customizer-mobile-templates.service';
import {ButtonsService} from '../../../../../../../core/services/buttons/buttons.service';
import {EventsService} from '../../../../../../../core/services/interaction/events/events.service';

import {MobileTemplatesPortfolio} from '../../../../../../../core/models/customizer/mobile/templates/portfolios/mobile-templates-portfolio';

import {MobilePortfolioModel} from '../../../../../../../core/models/portfolios/mobile/mobile-portfolio.model';

@Component({
  selector: 'app-mobile-templates-portfolios',
  templateUrl: './mobile-templates-portfolios.component.html',
  styleUrls: ['./mobile-templates-portfolios.component.scss']
})
export class MobileTemplatesPortfoliosComponent implements OnInit, OnDestroy {
  public list: MobileTemplatesPortfolio[] = [
    new MobileTemplatesPortfolio('one-column', '/assets/images/customizer/mobile/templates/portfolios/one-column.jpg'),
    new MobileTemplatesPortfolio('two-square-columns', '/assets/images/customizer/mobile/templates/portfolios/two-square-columns.jpg'),
    new MobileTemplatesPortfolio('three-square-columns', '/assets/images/customizer/mobile/templates/portfolios/three-square-columns.jpg'),
  ];

  public selectedItem: MobileTemplatesPortfolio;
  public isLocked: boolean = false;

  @Output() closeHandler: EventEmitter<void> = new EventEmitter<void>();

  private ngUnsubscribe = new Subject<void>();

  constructor(private customizerMobileTemplatesService: CustomizerMobileTemplatesService,
              private eventsService: EventsService,
              private buttonsService: ButtonsService) {
  }

  ngOnInit(): void {
    this.customizerMobileTemplatesService.mobilePortfolio.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedItem: MobilePortfolioModel) => {
      this.selectPortfolio(selectedItem);
    });
  }

  public onCloseHandler() {
    this.closeHandler.emit();
  }

  public onSelect(selectedItem: MobilePortfolioModel) {
    this.customizerMobileTemplatesService.setMobilePortfolio(selectedItem);

    this.buttonsService.enableSaveButton();
  }

  private selectPortfolio(selectedItem: MobilePortfolioModel) {
    this.selectedItem = this.list.find(item => item.key === selectedItem.key);
    
    this.isLocked = selectedItem.isLocked;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
  }
}
