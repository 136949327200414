import {Component, OnInit} from '@angular/core';

import {DeleteExhibitionModalService} from '../../../services/modals/delete-exhibition/delete-exhibition-modal.service';

@Component({
  selector: 'app-delete-exhibition-modal',
  templateUrl: './delete-exhibition-modal.component.html',
  styleUrls: ['./delete-exhibition-modal.component.scss'],
})
export class DeleteExhibitionModalComponent implements OnInit {
  public exhibitionTitle: string = '';

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: DeleteExhibitionModalService) {
  }

  public ngOnInit(): void {
    this.exhibitionTitle = this.service.exhibition ? this.service.exhibition.title : '';
  }

  public onConfirm(): void {
    this.service.onConfirm.next(true);

    this.service.close();
  }

  public onCancel(): void {
    this.service.onCancel.next(true);

    this.service.close();
  }
}
