import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ComparePlansModalService} from '../../../services/modals/compare-plans/compare-plans-modal.service';
import {PlansService} from '../../../../core/services/plans/plans.service';
import {ModalsService} from '../../../services/modals/modals.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {MessageModalService} from '../../../../services/message-modal.service';
import {UpgradeSubscriptionModalService} from '../../../services/modals/upgrade-subscription/upgrade-subscription-modal.service';
import {NavigationService} from '../../../../services/navigation.service';
import {PurchaseValidationModalService} from '../../../services/modals/purchase-validation/purchase-validation-modal.service';
import {CustomSubscriptionUpgradeErrorModalService} from '../../../services/modals/custom-subscription-upgrade-error/custom-subscription-upgrade-error-modal.service';

import {PlanModel} from '../../../../core/models/plan/plan.model';
import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {PurchaseValidationModel} from '../../../../core/models/validation/purchase/purchase-validation.model';
import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

import {PLANS_KEYS, FEATURES_ORDER, SUPPORT_FEATURES_ORDER, FEATURES, FEATURES_TEXT} from './constants';
import {DURATIONS} from '../../../../core/models/payment/subscriptions/constants';

@Component({
  selector: 'app-compare-plans-modal',
  templateUrl: './compare-plans-modal.component.html',
  styleUrls: ['./compare-plans-modal.component.scss']
})
export class ComparePlansModalComponent implements OnInit, OnDestroy {
  @ViewChild('plusPlanWrapper') plusPlanWrapper: ElementRef;

  public plans: PlanModel[] = [];
  public regularPlans: PlanModel[] = [];
  public supportPlans: PlanModel[] = [];

  public regularPlansGridTemplateColumns: string = '';
  public supportPlansGridTemplateColumns: string = '';

  public validation: PurchaseValidationModel;

  public currentPlan: PlanModel;

  public isCurrentPlanMonthly: boolean =false;

  private currentSubscription: SubscriptionModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  public get PLANS_KEYS() {
    return PLANS_KEYS;
  }

  public get FEATURES_ORDER() {
    return FEATURES_ORDER;
  }

  public get SUPPORT_FEATURES_ORDER() {
    return SUPPORT_FEATURES_ORDER;
  }

  public get FEATURES() {
    return FEATURES;
  }

  public get FEATURES_TEXT() {
    return FEATURES_TEXT;
  }

  public get DURATIONS() {
    return DURATIONS;
  }

  constructor(private service: ComparePlansModalService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private upgradeSubscriptionModalService: UpgradeSubscriptionModalService,
              private navigationService: NavigationService,
              private messageModalService: MessageModalService,
              private purchaseValidationModalService: PurchaseValidationModalService,
              private customSubscriptionUpgradeErrorModalService: CustomSubscriptionUpgradeErrorModalService,
              private modalsService: ModalsService,
              private plansService: PlansService) {
  }

  public ngOnInit(): void {
    this.plansService.plansSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((plans: PlanModel[]) => {
      this.plans = plans;

      if (!plans) return;

      this.regularPlans = plans.filter((plan: PlanModel) => !plan.isSupportPlan && plan.id !== 'OneDay' && plan.id !== 'OneDay+');
      this.supportPlans = plans.filter((plan: PlanModel) => plan.isSupportPlan && plan.id !== 'OneDay' && plan.id !== 'OneDay+');

      this.regularPlansGridTemplateColumns = `minmax(130px, auto) repeat(${this.regularPlans.length}, 220px)`;
      this.supportPlansGridTemplateColumns = `minmax(130px, auto) repeat(${this.supportPlans.length}, 220px)`;

      this.initCurrentPlan();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      this.initCurrentPlan();
    });
  }

  private initCurrentPlan(): void {
    if (!this.plans || !this.currentSubscription) return;

    this.currentPlan = this.plans.find((plan: PlanModel) => {
      return this.currentSubscription && !this.currentSubscription.isExpired && !this.currentSubscription.isTrial && !this.currentSubscription.isTrialEnded && this.currentSubscription.metadata.planId === plan.id;
    });

    this.isCurrentPlanMonthly = this.currentSubscription.isMonthly;
  }

  public scrollToSupportPlans(): void {
    if (!this.plusPlanWrapper || !this.plusPlanWrapper.nativeElement) return;

    this.plusPlanWrapper.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public onPayClick(plan: PlanModel, duration: string): void {
    if (!plan.isEnabled) return;

    if (this.currentSubscription && this.currentSubscription.isCustom) return this.showCustomSubscriptionUpdateError();

    this.signUp(plan, duration);
  }

  private signUp(plan: PlanModel, duration: string) {
    const planToPurchase: PlanModel = this.plans.find(p => p.id === plan.id);

    this.validation = this.paymentSubscriptionsService.validate(planToPurchase);

    if (!this.validation.isValid) return this.purchaseValidationModalService.open({ plan: planToPurchase, validation: this.validation });

    this.service.close();

    this.paymentSubscriptionsService.signUp(planToPurchase, duration).add(() => {
      this.upgradeSubscriptionModalService.isPlanPurchaseSubject.next(true);

      this.navigationService.toAddOns();
    });
  }

  private showCustomSubscriptionUpdateError(): void {
    this.customSubscriptionUpgradeErrorModalService.open();

    const unsubscribe: Subject<boolean> = new Subject<boolean>();

    this.modalsService.statusSubject.pipe(takeUntil(unsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      if (modalsStatus[this.customSubscriptionUpgradeErrorModalService.id]) return;

      unsubscribe.next(true);
      unsubscribe.complete();

      this.onMessageModalClose();
    });
  }

  private onMessageModalClose(): void {
    this.onClose();
  }

  public onClose(): void {
    this.service.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
