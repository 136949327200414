import {Component} from '@angular/core';

import {AppAnimations} from '../../../app-animations';

@Component({
  selector: 'app-website-designer',
  templateUrl: './website-designer.component.html',
  styleUrls: ['./website-designer.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class WebsiteDesignerComponent {
  constructor() {}
}
