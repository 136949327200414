import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CustomHomePageSetupModalService} from '../../../services/modals/custom-home-page-setup/custom-home-page-setup-modal.service';
import {WebsitesNodesService} from '../../../../core/services/websites-nodes/websites-nodes.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';

import {Button} from '../../../../common/models/button/button.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-custom-home-page-setup-modal',
  templateUrl: './custom-home-page-setup-modal.component.html',
  styleUrls: ['./custom-home-page-setup-modal.component.scss']
})
export class CustomHomePageSetupModalComponent implements OnInit, OnDestroy {
  public header: ModalHeader = {
    text: 'HOMEPAGE CHANGE',
    className: 'warning-header',
  };

  public buttons: Button[] = [
    new Button('YES', null, 'green', this.onUpdateAgree.bind(this)),
    new Button('NO', null, 'red', this.close.bind(this)),
  ];

  private activeWebsiteId: number;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  public get title(): string {
    return this.service.upcomingHomePage ? this.service.upcomingHomePage.data.title : '';
  }

  constructor(private service: CustomHomePageSetupModalService,
              private websitesNodesService: WebsitesNodesService,
              private websitesService: WebsitesService) {
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: number) => {
      this.activeWebsiteId = value;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onUpdateAgree(): void {
    if (!this.service.upcomingHomePage) return;

    this.websitesNodesService.updateHomePage({
      websiteId: this.activeWebsiteId,
      nodeId: this.service.upcomingHomePage.data.nodeId,
      isReFetchRequired: !this.service.homePage || this.service.homePage.type !== 'H',
    }).add(() => {
      if (!this.service.homePage || this.service.homePage.type !== 'H') return;

      this.websitesNodesService.updateIsOriginalHomePageRemoved({
        websiteId: this.activeWebsiteId,
        isRemoved: true,
      });
    });

    this.close();
  }

  public close(): void {
    this.service.close();
  }
}

