import {IStyleOption} from '../option/i-style-option';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {ColorModel} from '../../color/color.model';

import {BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB} from './constants';

export class PortfolioIntroBackgroundColorModel implements IStyleOption {
  public block: HTMLElement;
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.block = <HTMLElement>element.closest('.block');

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.value = computedValue.getPropertyValue(CUSTOM_BACKGROUND_COLOR) || computedValue.getPropertyValue(BACKGROUND_COLOR);
  }

  public reset(): void {
    if (!this.isEnabled || !this.block || !this.element) return;

    this.block.style.removeProperty(CUSTOM_BACKGROUND_COLOR);
    this.block.style.removeProperty(CUSTOM_BACKGROUND_COLOR_RGB);

    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.value = computedValue.getPropertyValue(CUSTOM_BACKGROUND_COLOR) || computedValue.getPropertyValue(BACKGROUND_COLOR);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.block) return;

    this.value = value;

    this.block.style.setProperty(CUSTOM_BACKGROUND_COLOR, value);

    const colorModel: ColorModel = new ColorModel(value);

    this.block.style.setProperty(CUSTOM_BACKGROUND_COLOR_RGB, colorModel.getRGBstring());
  }
}
