import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-remove-icon',
  templateUrl: './remove-icon.component.html',
  styleUrls: ['./remove-icon.component.scss'],
})
export class RemoveIconComponent {
  @Input() tooltip: string;

  @Output() clickHandler = new EventEmitter();

  public handleClick(event): void {
    this.clickHandler.emit(event);
  }
}
