export class EducationEditTeacherModel {
  public isInstitutionValid: boolean = true;

  public get isValid(): boolean {
    return this.isInstitutionValid;
  }

  constructor(public id: number = void 0,
              public firstName: string = '',
              public lastName: string = '',
              public institutionsIds: number[] = null,
              public classesIds: number[] = null) {
  }

  public validate(): void {
    this.isInstitutionValid = !!this.institutionsIds && this.institutionsIds.length > 0;
  }
}
