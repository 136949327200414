import {Injectable} from '@angular/core';

import {Subscription} from 'rxjs';

import {WebsitesNodesHttpService} from './http/websites-nodes.http.service';
import {NodesService} from '../nodes/nodes.service';
import {PagesService} from '../../../application/sidebar-short/sidebar/pages/pages.service';
import {NavigationService} from '../../../services/navigation.service';

@Injectable()
export class WebsitesNodesService {
  constructor(private httpService: WebsitesNodesHttpService,
              private nodesService: NodesService,
              private pagesService: PagesService,
              private navigationService: NavigationService) {
  }

  public updateHomePage({ websiteId, nodeId, isReFetchRequired = true }: { websiteId: number, nodeId: number, isReFetchRequired?: boolean }): Subscription {
    return this.httpService.updateHomePage({ websiteId, nodeId }).subscribe(() => {
      if (!isReFetchRequired) return;

      this.updateNodes();
    });
  }

  public updateIsOriginalHomePageRemoved({ websiteId, isRemoved }: { websiteId: number, isRemoved: boolean }): Subscription {
    return this.httpService.updateIsOriginalHomePageRemoved({ websiteId, isRemoved }).subscribe(() => {
      this.updateNodes();
    });
  }

  private updateNodes(): void {
    this.nodesService.fetchNodes().add(() => {
      this.navigationService.toHomePage({ isSidebarClosed: false });
    });

    this.pagesService.getPageNodes().subscribe(() => {});
  }
}
