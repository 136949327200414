import {Component, EventEmitter, Input, Output} from "@angular/core";
import { BagService } from "../../../../../../bag.service";

@Component({
  selector: 'app-image-manager-bottom-bar',
  templateUrl: './image-manager-bottom-bar.component.html',
  styleUrls: ['./image-manager-bottom-bar.component.scss']
})
export class ImageManagerBottomBarComponent {
  @Input() images: Array<any>;
  @Input() selectedCount: number;

  @Input() view: string;
  @Output() viewChange = new EventEmitter();

  @Input() thumbSize = 65;
  @Output() thumbSizeChange = new EventEmitter();

  @Input() imageIndex: number;
  @Output() imageIndexChange = new EventEmitter();

  @Output() selectAllHandler = new EventEmitter();
  @Output() deselectAllHandler = new EventEmitter();

  constructor(public bag: BagService) { }

  selectView(value) {
    if(this.view == value) return;

    this.view = value;
    this.viewChange.emit(this.view);
  }

  nextImage() {
    this.imageIndex++;
    if(this.imageIndex > this.images.length) this.imageIndex = 1;
    this.imageIndexChange.emit(this.imageIndex);
  }

  prevImage() {
    this.imageIndex--;
    if(this.imageIndex < 1) this.imageIndex = this.images.length;
    this.imageIndexChange.emit(this.imageIndex);
  }

  selectAll() {
    this.selectAllHandler.emit();
  }

  deselectAll() {
    this.deselectAllHandler.emit();
  }

  onThumbSizeChange(event) {
    this.thumbSize = event.target.value;
    this.thumbSizeChange.emit(this.thumbSize);
  }

}
