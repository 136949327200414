import {Component, Input, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../../../services/modals/modals.service';
import {FirefoxAudioRecordForbiddenModalService} from '../../../services/modals/firefox-audio-record-forbidden/firefox-audio-record-forbidden-modal.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-firefox-audio-record-forbidden-modal',
  templateUrl: './firefox-audio-record-forbidden-modal.component.html',
  styleUrls: ['./firefox-audio-record-forbidden-modal.component.scss']
})
export class FirefoxAudioRecordForbiddenModalComponent implements OnDestroy {
  @Input() id: string;

  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onCancel.bind(this),
    }
  ];

  public isOpened: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private modalsService: ModalsService,
              private service: FirefoxAudioRecordForbiddenModalService) {
  }

  public onModalOpen() {
    this.isOpened = true;
  }

  public onCancel() {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
