import {BlocksTemplatesDto} from './blocks-templates.dto';

export class BlocksTemplatesModel {
  constructor(public id?: number,
              public category?: string,
              public name?: string,
              public pageType?: string,
              public thumb?: string,
              public isDynamic?: boolean,
              public categoryId?: number,
              public updatedAt?: string) {}

  public static normalize(res: BlocksTemplatesDto): BlocksTemplatesModel {
    if (!res) return null;

    return new BlocksTemplatesModel(
      res.BlockID,
      res.Category,
      res.Name,
      res.PageType,
      res.Thumb,
      res.Dynamic,
      res.CategoryID,
      res.UpdatedAt,
    );
  }
}
