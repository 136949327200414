import {Injectable} from '@angular/core';

import {Observable, Subscriber} from 'rxjs';

import {ImageEditorImageUpdateModel} from '../../../shared/models/image-editor/image-editor-crop.model';

@Injectable()
export class CanvasService {
  constructor() {
  }

  public editImage(
    src: string,
    data: ImageEditorImageUpdateModel
  ): Observable<Blob> {
    return new Observable((observer: Subscriber<Blob>) => {
      const img: HTMLImageElement = new Image();
      
      img.crossOrigin = 'anonymous';

      img.addEventListener('load', () => {
        const canvas: HTMLCanvasElement = this.rotateAndCrop(
          data.rotate,
          img,
          data.startX,
          data.startY,
          data.width,
          data.height,
        );

        this.canvasToBlob(canvas).then((blob: Blob) => {
          observer.next(blob);
          observer.complete();
        });
      });
      
      img.src = src;
    });
  }

  private rotateAndCrop(angle: number, image: HTMLImageElement, x: number, y: number, width: number, height: number): HTMLCanvasElement {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    
    if (!angle) {
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(
        image,
        x, y, width, height,
        0, 0, width, height
      );

      return canvas;
    }

    canvas.width = image.width;
    canvas.height = image.height;

    const croppedCanvas: HTMLCanvasElement = document.createElement('canvas');
    const croppedCtx: CanvasRenderingContext2D = croppedCanvas.getContext('2d');

    ctx.translate(image.width / 2, image.height / 2);
    ctx.rotate(angle * Math.PI / 180);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);

    croppedCanvas.width = width;
    croppedCanvas.height = height;

    croppedCtx.drawImage(
      canvas,
      x, y, width, height,
      0, 0, width, height
    );

    return croppedCanvas;
  }

  private canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise(resolve => {
      canvas.toBlob(resolve, 'image/jpeg');
    });
  }
}
