import {Component} from '@angular/core';

import {SubscriptionsDisabledForImportedUsersModalService} from '../../../services/modals/subscriptions-disabled-for-imported-users-modal/subscriptions-disabled-for-imported-users-modal.service';

import {Button} from "../../../../common/models/button/button.model";

@Component({
  selector: 'app-subscriptions-disabled-for-imported-users-modal',
  templateUrl: './subscriptions-disabled-for-imported-users-modal.component.html',
  styleUrls: ['./subscriptions-disabled-for-imported-users-modal.component.scss']
})
export class SubscriptionsDisabledForImportedUsersModalComponent {
  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.onModalClose.bind(this)),
  ];
  
  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: SubscriptionsDisabledForImportedUsersModalService,
  ) {}

  public onModalClose(): void {
    this.service.close();
  }
}

