import {VARIANTS_MAPPING_NUMBERS} from '../../../services/google/fonts/constants';

export class GoogleFontModel {
  constructor(public index?: number,
              public family?: string,
              public fullUri?: string,
              public category?: string,
              public variant?: string,
              public variants?: string[],
              public subsets?: string[],
              public version?: string[],
              public lastModified?: string,
              public outputTypeKey?: string,
              public uri?: string,
              public uriShort?: string,
              public isLoaded?: boolean,
              public isDefault?: boolean,
              public isAdded?: boolean,
              public isRemoved?: boolean,
              public files?: { [key: string]: string }) {}

  public static clone(font: GoogleFontModel): GoogleFontModel {
    if (!font) return null;

    return new GoogleFontModel(
      font.index,
      font.family,
      font.fullUri,
      font.category,
      font.variant,
      font.variants,
      font.subsets,
      font.version,
      font.lastModified,
      font.outputTypeKey,
      font.uri,
      font.uriShort,
      font.isLoaded,
      font.isDefault,
      font.isAdded,
      font.isRemoved,
      font.files,
    );
  }

  public static getClosestVariant(font: GoogleFontModel, variantString: string): number {
    const variant = Number.parseInt(variantString);
    const variants = font.variants.map(variant => VARIANTS_MAPPING_NUMBERS[variant]);

    return variants.reduce((prev, curr) => {
      return (Math.abs(curr - variant) <= Math.abs(prev - variant) ? curr : prev);
    });
  }
}
