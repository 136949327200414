import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BlocksChangelogsService} from '../../../../services/blocks-changelogs.service';
import {DeleteNodeModalService} from '../../../../shared/services/modals/delete-node/delete-node-modal.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {PrivatePagePasswordModalService} from '../../../../shared/services/modals/private-page-password/private-page-password-modal.service';
import {ImageResolutionService} from '../../../../services/image-resolution.service';
import {FullscreenManager} from '../../../../services/fullscreen-manager.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {BlogSetupService} from '../../../../core/services/blog-setup/blog-setup.service';
import {ContactBlockSetupService} from '../../../../core/services/contact-block-setup/contact-block-setup.service';
import {DirectLinkInputModalService} from '../../../../shared/services/modals/direct-link-input/direct-link-input-modal.service';
import {SetImageModalService} from '../../../../shared/services/modals/set-image/set-image-modal.service';
import {BlocksDeleteModalService} from '../../../../shared/services/modals/blocks-delete/blocks-delete-modal.service';
import {RemoveHomepageErrorModalService} from '../../../../shared/services/modals/remove-homepage-error/remove-homepage-error-modal.service';
import { SaveOptionsModalService } from '../../../../shared/services/modals/save-options/save-options-modal.service';
import { RevertBlockToDefaultModalService } from '../../../../shared/services/modals/revert-block-to-default/revert-block-to-default-modal.service';
import { SaveThroughoutWebsiteOptionsModalService } from '../../../../shared/services/modals/save-throughout-website-options/save-throughout-website-options-modal.service';
import {PageLockAlertModalService} from '../../../../shared/services/modals/page-lock-alert/page-lock-alert-modal.service';
import {OriginalHomePageSetupModalService} from '../../../../shared/services/modals/original-home-page-setup/original-home-page-setup-modal.service';
import {CustomHomePageSetupModalService} from '../../../../shared/services/modals/custom-home-page-setup/custom-home-page-setup-modal.service';

@Component({
  selector: 'app-page-editor-modal-container',
  templateUrl: './page-editor-modal-container.component.html',
  styleUrls: ['./page-editor-modal-container.component.scss'],
})
export class PageEditorModalContainerComponent implements OnInit, OnDestroy {
  private deleteLogoCallback: () => {};

  public LOGO_DELETE_MODAL_ID = 'logo-delete-modal';
  public deleteLogoModalHeader = { className: 'warning-header' };

  public modalsStatus = {};

  public isOpen: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get privatePasswordInputModalId(): string {
    return this.privatePagePasswordModalService.id;
  }

  public get directLinkInputModalId(): string {
    return this.directLinkInputModalService.id;
  }

  public get removeHomePageErrorModalId(): string {
    return this.removeHomepageErrorModalService.id;
  }

  public get pageLockAlertModalModalId(): string {
    return this.pageLockAlertModalService.id;
  }

  public get originalHomePageSetupModalId(): string {
    return this.originalHomePageSetupModalService.id;
  }

  public get customHomePageSetupModalId(): string {
    return this.customHomePageSetupModalService.id;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private buttonsService: ButtonsService,
    private pageLockAlertModalService: PageLockAlertModalService,
    private originalHomePageSetupModalService: OriginalHomePageSetupModalService,
    private customHomePageSetupModalService: CustomHomePageSetupModalService,
    public modalsService: ModalsService,
    public imageResolutionService: ImageResolutionService,
    public fullscreenManager: FullscreenManager,
    public privatePagePasswordModalService: PrivatePagePasswordModalService,
    public directLinkInputModalService: DirectLinkInputModalService,
    public blocksDeleteModalService: BlocksDeleteModalService,
    public deleteNodeModalService: DeleteNodeModalService,
    public blogSetupService: BlogSetupService,
    public captchaInitService: ContactBlockSetupService,
    public blocksChangelogsService: BlocksChangelogsService,
    public removeHomepageErrorModalService: RemoveHomepageErrorModalService,
    public saveOptionsModalService: SaveOptionsModalService,
    public saveThroughoutWebsiteOptionsModalService: SaveThroughoutWebsiteOptionsModalService,
    public revertBlockToDefaultModalService: RevertBlockToDefaultModalService,
    public setImageModalService: SetImageModalService,
  ) {
  }

  public ngOnInit(): void {
    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(statuses => {
      this.modalsStatus = statuses;

      this.cdr.detectChanges();
    });

    this.fullscreenManager.isOpenSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpen: boolean) => {
      this.isOpen = isOpen;

      this.cdr.detectChanges();
    });

    window.addEventListener('logoDelete', event => {
      this.openDeleteLogoModal(event);
    });
  }

  public onPrivatePagePasswordSave(value: { isLocked: boolean, password: string }) {
    this.privatePagePasswordModalService.onSaveSubject.next(value);
  }

  public onPrivatePagePasswordCancel() {
    this.privatePagePasswordModalService.onCancelSubject.next(void 0);
  }

  public onDirectLinkSave(data: { websiteId: number, nodeId: number, link: string, isInNewTab: boolean }) {
    this.directLinkInputModalService.onSaveSubject.next(data);
  }

  public onDirectLinkCancel() {
    
  }

  openDeleteLogoModal(event) {
    this.deleteLogoCallback = event.detail.cb;
    this.modalsService.open(this.LOGO_DELETE_MODAL_ID);
  }

  public deleteLogo() {
    this.deleteLogoCallback();
    this.buttonsService.enableSaveButton();
    this.modalsService.close(this.LOGO_DELETE_MODAL_ID);
  }

  public ngOnDestroy(): void {
    this.privatePagePasswordModalService.onSaveSubject.next(null);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
