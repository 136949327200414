import {Injectable} from '@angular/core';

import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class IFrameClickOverlayService {
  public onClickSubject: Subject<void> = new Subject();
  public isVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private data: { [key: string]: boolean } = {};

  constructor() {
  }

  public show(key: string): void {
    this.data[key] = true;

    this.init();
  }

  public hide(key: string): void {
    delete this.data[key];
    
    this.init();
  }

  private init(): void {
    this.isVisibleSubject.next(Object.keys(this.data).length > 0);
  }
}
