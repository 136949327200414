import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../modals.service';

import {WebsiteModel} from '../../../../core/models/websites/website.model';

@Injectable()
export class WebsiteSubscriptionSelectModalService {
  public onUpdate: Subject<boolean> = new Subject();

  public readonly id = 'website-subscription-select-modal';

  public isOpened = false;

  public constructor(
    private modalsService: ModalsService
  ) {
  }

  public open({ userId, website } : { userId: number, website: WebsiteModel }): void {
    this.modalsService.open(this.id, { userId, website });

    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
