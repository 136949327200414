// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-show {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 42px);
  z-index: 1003;
  padding: 20px;
  background: #ccc;
}
.slide-show > div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
}
.slide-show > div img {
  max-width: 100%;
  max-height: calc(100% - 20px);
}
.slide-show > div .title {
  margin-top: 10px;
  color: #777;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/image-manager-modal/portfolio-images/image-manager-large-view/image-manager-large-view.component.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;AAHF;AAKE;EACE,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EAEA,YAAA;EACA,WAAA;EACA,kBAAA;AAJJ;AAMI;EACE,eAAA;EACA,6BAAA;AAJN;AAOI;EACE,gBAAA;EACA,WAAA;AALN","sourcesContent":["\n\n\n.slide-show {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: calc(100% - 42px);\n  z-index: 1003;\n  padding: 20px;\n  background: #ccc;\n\n  > div {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n\n    height: 100%;\n    width: 100%;\n    text-align: center;\n\n    img {\n      max-width: 100%;\n      max-height: calc(100% - 20px);\n    }\n\n    .title {\n      margin-top: 10px;\n      color: #777;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
