import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {NativeSetupOptionModel} from '../option/native-setup-option.model';

import {PROPERTY} from './constants';

export class FontStyleModel extends NativeSetupOptionModel {
  constructor(
    public key: string,
    public isEnabled: boolean = true,
  ) {
    super(key, PROPERTY, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions): void {
    this.element = element;

    if (!this.element) return;

    this.initValue();
  }

  public onChange(value: string): void {
    this.value = value;

    this.element.style.fontStyle = value;
  }

  public reset(): void {
    this.element.style.removeProperty(PROPERTY);

    this.initValue();
  }

  private initValue(): void {
    this.value = window.getComputedStyle(this.element)[PROPERTY];
  }
}
