import {SelectOption} from '../../../select/option/option.model';

export const KEYS = {
  UNDER_IMAGE: 'UNDER_IMAGE',
  BOTTOM_OF_PAGE: 'BOTTOM_OF_PAGE',
};

export const ATTRIBUTES = {
  IMAGE_TITLE_POSITION: 'data-image-title-position',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('Under Image', KEYS.UNDER_IMAGE),
  new SelectOption('Bottom of Page', KEYS.BOTTOM_OF_PAGE),
];

export const DEFAULT_OPTION: SelectOption = OPTIONS[0];
