export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `ඔවුන්ගේ සියලු උපකරණ හා මෙවලම් කෙසේ හෝ යහතින් විය.`,
    ],
    [KEYS.ALPHABET]: [
      'අආඇඈඉඊඋඌඍඎඑඒඓඔඕඖඅංඅඃකඛගඝඞඟචඡජඣඥඤටඨඩඪණඬතථදධනඳපඵබභමඹයරලවශෂසහළෆ෴',
    ],
    [KEYS.PARAGRAPH]: [
      `ඈත ග්‍රහමණ්ඩලයකට එබී බැලීමක් අපේ සිතිවිලි පූජනීය සංගීත ධාරාවක්, අනර්ග පින්තූරයක්, හෝ විශිෂ්ට කාව්‍යයක කොටසක් මෙන් පිරිසිදු කළ හැකිය. එය සැම විටම එක් හොඳක් කරයි.`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
