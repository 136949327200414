import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppAnimations } from '../../../../app-animations';
import {SECTIONS} from './constants';

@Component({
  selector: 'app-designer-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SettingsSectionComponent implements OnInit {
  selectedSection;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const urlSection = params['section'];
      const foundSection = Object.keys(SECTIONS).find(section => SECTIONS[section].path.toLowerCase() === urlSection);
      this.selectedSection = SECTIONS[foundSection] ? SECTIONS[foundSection].path : urlSection;
      this.router.navigate(['/app', 'settings', this.selectedSection.toLowerCase()]);
    });
  }

  getSections() {
    return Object.keys(SECTIONS).map(key => SECTIONS[key]);
  }

  selectSection(section) {
    this.router.navigate([
      '/app',
      { outlets:
        {
          primary: ['settings', section.path],
          sidebar: ['settings', section.path]
        }
      }
    ]);
  }
}
