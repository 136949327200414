import {Component} from '@angular/core';

import {ImageSpecificationsModalService} from '../../../services/modals/image-specifications/image-specifications-modal.service';
import {ImageUploadService} from '../../../../core/services/image-manager/upload/image-upload.service';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-image-specifications-modal',
  templateUrl: './image-specifications-modal.component.html',
  styleUrls: ['./image-specifications-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageSpecificationsModalComponent {
  public get id(): string {
    return this.service.id;
  }

  constructor(private service: ImageSpecificationsModalService,
              public imageUploadService: ImageUploadService) {}

  public closeModal() {
    this.service.close();
  }
}
