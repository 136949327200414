import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class IsBlockCenteredModel extends SetupOptionModel {
  public element: HTMLElement;

  private isDropArea: boolean = false;
  private isExhibitionIndex: boolean = false;
  private isExhibitionPortfolio: boolean = false;
  private isSupportedBlock: boolean = false;

  constructor(
    public key: string,
    public type: string,
    public value: boolean = false,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    this.isEnabled = !!element;

    if (!this.isEnabled) return;

    this.isDropArea = !!element.closest('.drop-area');
    this.isExhibitionIndex = !!element.closest('[data-block-category-id="45"]');
    this.isExhibitionPortfolio = !!element.closest('[data-block-category-id="43"]');
    this.isSupportedBlock = !!element.closest('[data-block-template-id="23"],[data-block-template-id="72"],[data-block-template-id="98"]');

    this.isEnabled = this.isDropArea && (this.isSupportedBlock || this.isExhibitionPortfolio) && !this.isExhibitionIndex;

    if (!this.isEnabled) return;

    this.element = <HTMLElement>element.closest('.drop-area');

    if (this.isExhibitionPortfolio) {
      this.isEnabled = this.element.querySelectorAll(':scope > .block').length === 1;

      if (!this.isEnabled) return;
    }

    this.value = this.element.getAttribute(ATTRIBUTES.IS_CENTERED) === 'true';
  }

  public onChange(value: boolean): void {
    this.value = value;

    this.element.setAttribute(ATTRIBUTES.IS_CENTERED, `${value}`);
  }
}
