import {SelectOption} from '../../../../../core/models/select/option/option.model';

export const DEFAULT_COUPON_ID = '-1';

export const TIME_FORMATTERS = {
  FULL: 'YYYY-MM-DD HH:mm:ss Z',
  DATETIME: 'YYYY-MM-DDTHH:mm',
  DATE: 'YYYY-MM-DD',
  TIME: 'HH:mm:ss Z',
};

export const SUPPORT_SORT_KEYS = {
  SUPPORT_DATE: 'SupportedAt',
  SUMMARY: 'Summary',
  METHOD: 'Method',
  PLAN_TIME: 'PlanTimeMin',
  ADD_ON_TIME: 'AddOnTimeMin',
  CREATED_BY: 'AdminId',
  NOTES: 'Notes',
  CREATED_AT: 'CreatedAt',
};

export const SUPPORT_STATUSES_KEYS = {
  VALID: 'VALID',
  REMOVED: 'REMOVED',
};

export const SUPPORT_METHODS_TEXTS = {
  PHONE_CALL: 'Phone Call',
  ONE_ON_ONE_TRAINING: 'One-On-One Training',
  VIDEO_TUTORIAL: 'Video Tutorial',
};

export const SUPPORT_METHODS: SelectOption[] = [
  new SelectOption(SUPPORT_METHODS_TEXTS.PHONE_CALL, SUPPORT_METHODS_TEXTS.PHONE_CALL),
  new SelectOption(SUPPORT_METHODS_TEXTS.ONE_ON_ONE_TRAINING, SUPPORT_METHODS_TEXTS.ONE_ON_ONE_TRAINING),
  new SelectOption(SUPPORT_METHODS_TEXTS.VIDEO_TUTORIAL, SUPPORT_METHODS_TEXTS.VIDEO_TUTORIAL),
];

export const PUBLISH_STATUSES = {
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  PUBLISHING: 'PUBLISHING',
  PUBLISH_ERROR: 'PUBLISH_ERROR',
  HAS_UNPUBLISHED_CHANGES: 'HAS_UNPUBLISHED_CHANGES',
  LAST_CHANGES_PUBLISHED: 'LAST_CHANGES_PUBLISHED',
};

export const PUBLISH_STATUS_TEXT = {
  [PUBLISH_STATUSES.NOT_PUBLISHED]: 'Not Published',
  [PUBLISH_STATUSES.PUBLISHING]: 'Publishing',
  [PUBLISH_STATUSES.PUBLISH_ERROR]: 'Publish Error',
  [PUBLISH_STATUSES.HAS_UNPUBLISHED_CHANGES]: 'Has Changes',
  [PUBLISH_STATUSES.LAST_CHANGES_PUBLISHED]: 'Published',
};
