// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-wrapper {
  padding-left: 10px;
}
.field-wrapper .field {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.field-wrapper .field .name {
  color: #cccccc;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4em;
  width: 50%;
}
.field-wrapper .field .content {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/customizer/components/options/checkbox-option/checkbox-option.component.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;AAFF;AAIE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAII;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;AAFN;AAKI;EACE,kBAAA;AAHN","sourcesContent":["@import \"../../../../../../../../node_modules/compass-mixins/lib/compass\";\n@import \"../../../../../../../styles/colors\";\n\n.field-wrapper {\n  padding-left: 10px;\n\n  .field {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    position: relative;\n\n    .name {\n      color: #cccccc;\n      font-size: 12px;\n      font-weight: 400;\n      line-height: 1.4em;\n      width: 50%;\n    }\n\n    .content {\n      position: relative;\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
