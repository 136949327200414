import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, map} from 'rxjs';

import {WebsitesService} from '../../websites/websites.service';
import {MeasureUnitsHttpService} from '../../interaction/http/measure-units/measure-units-http.service';

import {MeasureUnitModel} from '../../../models/measure-unit/measure-unit.model';
import {MeasureUnitDto} from '../../../models/measure-unit/measure-unit.dto';
import {WebsiteModel} from '../../../models/websites/website.model';

@Injectable()
export class MeasureUnitsService {
  public measureUnitsSubject: BehaviorSubject<MeasureUnitModel[]> = new BehaviorSubject<MeasureUnitModel[]>(null);
  public mainMeasureUnitSubject: BehaviorSubject<MeasureUnitModel> = new BehaviorSubject<MeasureUnitModel>(null);
  public websiteMeasureUnitSubject: BehaviorSubject<MeasureUnitModel> = new BehaviorSubject<MeasureUnitModel>(null);
  public oppositeMeasureUnitSubject: BehaviorSubject<MeasureUnitModel> = new BehaviorSubject<MeasureUnitModel>(null);

  private get measureUnits(): MeasureUnitModel[] {
    return this.measureUnitsSubject.value;
  }

  private get mainMeasureUnit(): MeasureUnitModel {
    return this.mainMeasureUnitSubject.value;
  }

  private get websiteMeasureUnit(): MeasureUnitModel {
    return this.websiteMeasureUnitSubject.value;
  }

  constructor(private measureUnitsHttpService: MeasureUnitsHttpService,
              private websitesService: WebsitesService) {
    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      if (!website) return;

      this.fetch();
    });
  }

  private fetch(): void {
    this.fetchAllMeasureUnits();
    this.fetchWebsiteMeasureUnit();

    this.fetchMain();
  }

  public fetchMain(): Subscription {
    return this.measureUnitsHttpService.fetchMain().pipe(
      map(res => MeasureUnitDto.normalize(res))
    ).subscribe(res => {
      this.mainMeasureUnitSubject.next(res);
    });
  }

  private fetchAllMeasureUnits(): void {
    this.measureUnitsHttpService.getAll().subscribe((units: MeasureUnitModel[]) => {
      this.measureUnitsSubject.next(units);

      this.initOppositeMeasureUnit();
    });
  }

  private fetchWebsiteMeasureUnit(): void {
    this.measureUnitsHttpService.getForWebsite().subscribe((unit: MeasureUnitModel) => {
      this.websiteMeasureUnitSubject.next(unit);

      this.initOppositeMeasureUnit();
    });
  }

  private initOppositeMeasureUnit(): void {
    if (!this.websiteMeasureUnit || !this.measureUnits) return;

    const other = this.measureUnits.find((measureUnit: MeasureUnitModel) => measureUnit.id !== this.websiteMeasureUnit.id);

    this.oppositeMeasureUnitSubject.next(other);
  }

  public convertToMain(value: number, precision: number = 4): string {
    const converted = this.convert(value, this.websiteMeasureUnit, this.mainMeasureUnit);
    return converted ? converted.toFixed(precision) : null;
  }

  public convertFromMain(value: number): number {
    const converted = this.convert(value, this.mainMeasureUnit, this.websiteMeasureUnit);
    return converted ? parseFloat(converted.toFixed(4)) : null;
  }

  public convertFromMainTo(value: number, to: MeasureUnitModel): number {
    if (value === void 0 || value === null) return value;

    const converted = this.convert(value, this.mainMeasureUnit, to);
    return converted ? Math.round(converted * 100) / 100 : null;
  }

  public convert(value: number, from: MeasureUnitModel, to: MeasureUnitModel): number {
    if (value === void 0 || value === null) return value;

    return value / from.conversionRate * to.conversionRate;
  }
}
