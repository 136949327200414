import {Component, EventEmitter, Output} from '@angular/core';

import {AppAnimations} from '../../../../../../../app-animations';

import {CardModel} from '../../../../../../../core/models/payment/card/card.model';

@Component({
  selector: 'app-subscription-order-cards-step',
  templateUrl: './subscription-order-step-cards.component.html',
  styleUrls: ['./subscription-order-step-cards.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class SubscriptionOrderStepCardsComponent {
  @Output() cardSelectHandler: EventEmitter<CardModel> = new EventEmitter<CardModel>();

  constructor() {
  }

  public onCardSelected(card: CardModel) {
    this.cardSelectHandler.emit(card);
  }
}
