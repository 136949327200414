import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {NativeSetupOptionModel} from '../option/native-setup-option.model';

import {PROPERTY, FONT_WEIGHT_MAPPING} from './constants';

export class FontWeightModel extends NativeSetupOptionModel {
  constructor(
    public key: string,
    public isEnabled: boolean = true,
  ) {
    super(key, PROPERTY, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions): void {
    this.element = element;

    if (!this.element) return;

    this.initValue();
  }

  public setValue(value: string): void {
    this.value = value;
  }

  public onChange(value: string): void {
    this.value = value;

    this.element.style.fontWeight = value;
  }

  public reset(): void {
    this.element.style.removeProperty(PROPERTY);

    this.initValue();
  }

  private initValue(): void {
    this.element.classList.add('no-hover-action');

    this.value = window.getComputedStyle(this.element)[PROPERTY];

    if (FONT_WEIGHT_MAPPING[this.value]) this.value = FONT_WEIGHT_MAPPING[this.value];

    this.element.classList.remove('no-hover-action');
  }
}
