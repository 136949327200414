import {Injectable} from '@angular/core';

import {IFrameService} from '../iframe.service';

import {Font} from '../../../models/font/font.model';

@Injectable()
export class FontsService {
  constructor(private iFrameService: IFrameService) {}

  public addFont(font: Font): void {
    const { sandbox } = this.iFrameService;
    if (!sandbox || !sandbox.head || this.getFontLinkElement(font) || !font.link) return;
    const link = document.createElement('link');
    link.setAttribute('href', font.link);
    link.setAttribute('rel', 'stylesheet');
    sandbox.head.appendChild(link);
  }

  private getFontLinkElement(font: Font): Element {
    const { links } = this.iFrameService;
    if (!links || links.length === 0) return;
    return links.find(link => {
      const href = link.getAttribute('href');
      return href === font.link || href === font.lightLink;
    });
  }
}
