// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .modal {
  z-index: 10002;
}

.message-wrapper {
  clear: both;
  overflow: hidden;
}
.message-wrapper .message {
  font-size: 0.9em;
  white-space: pre-line;
  margin: 30px 30px 20px 30px;
  line-height: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/message-modal/message-modal.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;;AAIA;EACE,WAAA;EACA,gBAAA;AADF;AAGE;EACE,gBAAA;EACA,qBAAA;EACA,2BAAA;EACA,kBAAA;AADJ","sourcesContent":[":host ::ng-deep {\n  .modal {\n    z-index: 10002;\n  }\n}\n\n.message-wrapper {\n  clear: both;\n  overflow: hidden;\n  \n  .message {\n    font-size: 0.9em;\n    white-space: pre-line;\n    margin: 30px 30px 20px 30px;\n    line-height: 1.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
