import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../app-animations';

import {UpgradeSubscriptionService} from '../../../core/services/payment/subscriptions/upgrade/upgrade-subscription.service';
import {PaymentSubscriptionsService} from '../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {CartService} from '../../../core/services/cart/cart.service';
import {ReceiptService} from '../../../core/services/payment/receipt/receipt.service';
import {ModalsService} from '../../services/modals/modals.service';
import {CartItemDeleteModalService} from '../../services/modals/cart-item-delete/cart-item-delete-modal.service';

import {PlanModel} from '../../../core/models/plan/plan.model';
import {SubscriptionModel} from '../../../core/models/payment/subscriptions/subscription.model';
import {SubscriptionMetadataModel} from '../../../core/models/payment/subscriptions/subscription-metadata.model';
import {ProrateModel} from '../../../core/models/payment/subscriptions/prorate.model';
import {ICartItem} from '../../../core/models/cart/item/cart-item.model';
import {PlanCartItemModel} from '../../../core/models/cart/item/plan/plan-cart-item.model';
import {AddOnCartItemModel} from '../../../core/models/cart/item/plan/add-on-cart-item.model';
import {ReceiptModalModel} from '../../models/modals/receipt-modal/receipt-modal.model';
import {ReceiptModalItemModel} from '../../models/modals/receipt-modal/receipt-modal-item.model';
import {ModalDataModel} from '../../../core/models/modals/modal-data.model';

import {DISCOUNT_TYPES} from '../../../core/models/stripe/coupon/constants';
import {AMOUNT_FORMATTERS} from '../../../core/models/payment/intent/constants';
import {PRODUCT_TYPES} from '../../../core/services/cart/constants';

@Component({
  selector: 'app-update-subscription-summary',
  templateUrl: './update-subscription-summary.component.html',
  styleUrls: ['./update-subscription-summary.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class UpdateSubscriptionSummaryComponent implements OnInit, OnDestroy {
  public prorate: ProrateModel = null;

  public cart: ICartItem[];

  public toPlanCartItem: PlanCartItemModel;
  public addOnsCartItem: AddOnCartItemModel[];

  public targetDuration: string;
  public couponText: string;
  public supportDiscountText: string;

  public subtotal: string;
  public subtotalAmount: number;

  public modalsStatus: { [key: string]: ModalDataModel };

  public isTargetPlanIncludesSupport: boolean = false;

  private isSupportPlan: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get header(): string {
    return `Plan ${this.action}`;
  }

  public get productTypes() {
    return PRODUCT_TYPES;
  }

  public get action(): string {
    const { currentPlan, toPlanCartItem } = this;

    if (!currentPlan || !toPlanCartItem || !toPlanCartItem.options || !toPlanCartItem.options.plan) return '';

    return currentPlan.level <= toPlanCartItem.options.plan.level ? 'upgrade' : 'downgrade';
  }

  public get currentPlan(): PlanModel {
    const { currentSubscription } = this;

    return currentSubscription && currentSubscription.plan ? currentSubscription.plan : null;
  }

  public get currentSubscription(): SubscriptionModel {
    return this.service.currentSubscription ? this.service.currentSubscription : null;
  }

  public get planAmount(): string {
    if (!this.toPlanCartItem) return null;

    return this.toPlanCartItem.options.plan.amounts[this.toPlanCartItem.options.duration] ? this.toPlanCartItem.options.plan.amounts[this.toPlanCartItem.options.duration].formattedAmount : null;
  }

  public get planAmountValue(): number {
    if (!this.toPlanCartItem) return 0;

    return this.toPlanCartItem.options.plan.amounts[this.toPlanCartItem.options.duration] ? this.toPlanCartItem.options.plan.amounts[this.toPlanCartItem.options.duration].amount : 0;
  }

  private get addOnsAmount(): number {
    if (!this.addOnsCartItem) return 0;

    return this.addOnsCartItem.reduce((res: number, addOn: AddOnCartItemModel) => {
      return res + addOn.options.addOn.amount;
    }, 0);
  }

  public get couponPercentText(): string {
    if (this.service.coupon.metadata.discountType !== DISCOUNT_TYPES.PERCENT) return '';

    return ` (${this.service.coupon.percentOff}%)`;
  }

  constructor(public service: UpgradeSubscriptionService,
              public cartItemDeleteModalService: CartItemDeleteModalService,
              private cartService: CartService,
              private receiptService: ReceiptService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private cdr: ChangeDetectorRef,
              private modalsService: ModalsService) {
  }

  public ngOnInit(): void {
    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isSupportPlan = subscription ? subscription.plan.isSupportPlan : false;
    });

    this.paymentSubscriptionsService.prorateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: ProrateModel) => {
      this.prorate = res;

      this.initCouponText();
      this.initSupportDiscountText();
      this.initReceipt();
    });

    this.cartService.cartSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cart: ICartItem[]) => {
      this.cart = cart;

      this.initCartData();
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });
  }

  private initCartData(): void {
    this.toPlanCartItem = null;
    this.addOnsCartItem = null;
    this.targetDuration = SubscriptionMetadataModel.getDurationText(null);
    this.subtotal = null;

    if (!this.cart) return;

    this.initPlan();
    this.initAddOns();
    this.initSubtotal();
    this.initCouponText();
    this.initSupportDiscountText();
    this.initReceipt();
  }

  private initCouponText(): void {
    this.couponText = null;

    if (!this.prorate) return;

    const diff: number = this.getDiscountAmount() - this.getSupportPlanDiscountAmount();

    if (diff === 0 || Number.parseFloat((diff / 100).toFixed(2)) === 0) return;

    this.couponText = AMOUNT_FORMATTERS.usd(diff * 100);
  }

  private getDiscountAmount(): number {
    if (this.prorate.amountOnAccountAmount > this.subtotalAmount) {
      return Number.parseFloat((this.subtotalAmount - (this.prorate.amountOnAccountAmount - this.prorate.newCreditBalanceAmount)).toFixed(2));
    }

    return Number.parseFloat((this.prorate.totalWithoutDiscountAmount - this.prorate.totalAmount).toFixed(2));
  }

  private initSupportDiscountText(): void {
    this.supportDiscountText = null;

    if (!this.prorate) return;

    const diff: number = this.getSupportPlanDiscountAmount();

    if (diff === 0 || Number.parseFloat((diff / 100).toFixed(2)) === 0) return;

    this.supportDiscountText = AMOUNT_FORMATTERS.usd(diff * 100);
  }

  private getSupportPlanDiscountAmount(): number {
    if (!this.addOnsCartItem) return 0;
    if ((!this.isSupportPlan && !this.toPlanCartItem) || (!!this.toPlanCartItem && !this.isTargetPlanIncludesSupport)) return 0;

    return this.addOnsCartItem.reduce((res: number, item: AddOnCartItemModel) => {
      if (!item.options.addOn.supportPlanDiscount) return res;

      return res + item.options.addOn.amount * (item.options.addOn.supportPlanDiscount) / 100;
    }, 0);
  }

  private initReceipt(): void {
    if (!this.prorate) return;

    const isCouponExists: boolean = !!this.service.coupon && !!this.couponText && !!(this.toPlanCartItem || (this.addOnsCartItem && this.addOnsCartItem.length > 0));

    this.receiptService.receiptSubject.next(new ReceiptModalModel(
      this.toPlanCartItem ? [new ReceiptModalItemModel(`${this.toPlanCartItem.options.plan.name} Plan — ${this.targetDuration} Payment`, this.planAmount)] : null,
      this.addOnsCartItem ? this.addOnsCartItem.map((addOn: AddOnCartItemModel) => new ReceiptModalItemModel(addOn.options.addOn.name, addOn.options.addOn.formattedAmount)) : null,
      this.supportDiscountText ? new ReceiptModalItemModel(`Support Plan Discount`, `-${this.supportDiscountText}`) : null,
      isCouponExists ? new ReceiptModalItemModel(`Discount${this.couponPercentText}`, `-${this.couponText}`) : null,
      this.subtotal,
      this.prorate.amountOnAccount,
      this.prorate.total,
      this.prorate.newCreditBalance,
      false,
    ));
  }

  private initPlan(): void {
    this.toPlanCartItem = <PlanCartItemModel>this.cart.find((item: ICartItem) => item.type === PRODUCT_TYPES.PLAN);

    this.isTargetPlanIncludesSupport = this.toPlanCartItem ? !!this.toPlanCartItem.options && this.toPlanCartItem.options.plan.isSupportPlan : false;

    if (!this.toPlanCartItem || !this.toPlanCartItem.options) return;

    this.targetDuration = SubscriptionMetadataModel.getDurationText(this.toPlanCartItem.options.duration);
  }

  private initAddOns(): void {
    this.addOnsCartItem = <AddOnCartItemModel[]>this.cart.filter((item: ICartItem) => item.type === PRODUCT_TYPES.ADD_ON || item.type === PRODUCT_TYPES.INCLUDED_ADD_ON);
  }

  private initSubtotal(): void {
    this.subtotal = null;

    this.subtotalAmount = this.planAmountValue + this.addOnsAmount;

    if (!this.subtotalAmount) return;

    const roundSubtotalAmount: number = Math.floor(this.subtotalAmount);
    const centsSubtotalAmount: number = Number((this.subtotalAmount * 100).toFixed()) % 100;

    this.subtotal = `$${roundSubtotalAmount}.${`${centsSubtotalAmount}`.padStart(2, '0')}`;
  }

  public ngOnDestroy(): void {
    this.paymentSubscriptionsService.prorateSubject.next(null);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onItemRemoveClick(item: ICartItem): void {
    this.cartItemDeleteModalService.open(item);
  }
}
