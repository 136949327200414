import {RetryPaymentLineItemDto} from './retry-payment-line-item.dto';

import {AMOUNT_FORMATTERS} from './constants';

export class RetryPaymentLineItemModel {
  constructor(
    public amount?: number,
    public amountFormatted?: string,
    public currency?: string,
    public description?: string,
  ) {}

  public static normalize(res: RetryPaymentLineItemDto): RetryPaymentLineItemModel {
    if (!res) {
      return null;
    }

    return new RetryPaymentLineItemModel(
      res.amount,
      AMOUNT_FORMATTERS[res.currency] ? AMOUNT_FORMATTERS[res.currency](res.amount) : ``,
      res.currency,
      res.description,
    );
  }
}
