import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppSettingsService} from '../../../../core/services/app-settings/app-settings.service';

import {AppSettingsModel} from '../../../../core/models/app-settings/app-settings.model';

@Component({
  selector: 'app-admin-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AdminAppSettingsComponent implements OnDestroy {
  public settings: AppSettingsModel = null;
  public isMaintenanceOverlayVisible: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private appSettingsService: AppSettingsService) {
    this.appSettingsService.settingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((settings: AppSettingsModel) => {
      this.settings = settings;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public toggleMaintenanceOverlayVisibility(): void {
    if (!this.settings) return;

    this.appSettingsService.setIsMaintenanceOverlayVisible(!this.settings.isMaintenanceOverlayVisible);
  }
}
