import {Component, Input, Output, EventEmitter} from '@angular/core';

import {Button} from "../../../../common/models/button/button.model";
import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {
  @Input() id = 'message-modal';
  @Input() header: ModalHeader;
  @Input() message: string;
  @Input() buttons: Button[];

  @Output() clickHandler = new EventEmitter();

  constructor(
  ) {}
}

