export enum NestedSelectOptionTypes {
  category,
  text,
}

export class NestedSelectOption {
  constructor(
    public label: string,
    public value: string,
    public type: NestedSelectOptionTypes = NestedSelectOptionTypes.text,
    public children?: NestedSelectOption[],
    public isSelected: boolean = false,
  ) {}
}
