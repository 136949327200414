import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ImagesHttpService} from '../../../../../../../images/images-http.service';

import {ImageLabelDto} from '../../../../../../../../../../models/images/image-label/image-label.dto';
import {ImagePriceModel} from '../../../../../../../../../../models/images/price/image-price.model';
import {ImagePriceDto} from '../../../../../../../../../../models/images/price/image-price.dto';

@Injectable()
export class EducationImagesHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient,
              private imagesHttpService: ImagesHttpService) {
  }

  public updateImageLabel(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, imageLabel: ImageLabelDto): Observable<any> {
    return this.http.put(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios/${portfolioId}/images/${imageId}/image-label`, imageLabel);
  }

  public createPrice(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, price: ImagePriceDto): Observable<any> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios/${portfolioId}/images/${imageId}/image-label/prices`, this.imagesHttpService.convertToMainMeasureUnit(price))
    .pipe(
      map((res: ImagePriceDto) => ImagePriceDto.normalize(res)),
      map((res: ImagePriceModel) => this.imagesHttpService.convertToWebsiteMeasureUnit(res)),
    );
  }

  public updatePrice(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, price: ImagePriceDto): Observable<any> {
    return this.http.put(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios/${portfolioId}/images/${imageId}/image-label/prices/${price.Id}`, this.imagesHttpService.convertToMainMeasureUnit(price))
    .pipe(
      map((res: ImagePriceDto) => ImagePriceDto.normalize(res)),
      map((res: ImagePriceModel) => this.imagesHttpService.convertToWebsiteMeasureUnit(res)),
    );
  }

  public moveAllToLibrary(institutionId: number, classId: number, websiteId: number, portfolioId: number): Observable<any> {
    return this.http.put(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios/${portfolioId}/images/all`, null);
  }

  public deletePrice(institutionId: number, classId: number, websiteId: number, portfolioId: number, imageId: number, priceId: number): Observable<ImagePriceModel> {
    return this.http.delete(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios/${portfolioId}/images/${imageId}/image-label/prices/${priceId}`).pipe(
      map((res: ImagePriceDto) => ImagePriceDto.normalize(res)),
      map((res: ImagePriceModel) => this.imagesHttpService.convertToWebsiteMeasureUnit(res)),
    );
  }
}
