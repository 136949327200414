import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {WebsiteDto} from '../../../core/models/websites/website.dto';
import {WebsiteModel} from '../../../core/models/websites/website.model';
import {AccountSummaryModel} from '../../../core/models/accounts/summary/account-summary.model';
import {SubscriptionModel} from '../../../core/models/payment/subscriptions/subscription.model';
import {SubscriptionDto} from '../../../core/models/payment/subscriptions/subscription.dto';
import {IDefaultResponse} from '../../../core/models/responses/i-default-reponse';

import {PUBLISH_STATUSES} from '../../../services/publish-website/constants';

@Injectable()
export class AdminService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  public fetchCustomers(options: Object, limit: number, page: number, orderByKey: string, isOrderUp: boolean): Observable<any> {
    // todo check params - some params could be object

    const params = new HttpParams()
      .set('options', options ? JSON.stringify(options) : '')
      .set('limit', `${limit}`)
      .set('page', `${page}`)
      .set('orderByKey', `${page}`)
      .set('isOrderUp', `${isOrderUp}`);

    return this.httpClient.get('/api/admin/customers', { params });
  }

  public fetchCustomerSummary(customerId: number): Observable<AccountSummaryModel> {
    return this.httpClient.get(`/api/admin/customers/${customerId}/summary`);
  }

  public getUserSubscriptions(userId: number): Observable<SubscriptionModel[]> {
    return this.httpClient.get(`/api/admin/customers/${userId}/subscriptions`).pipe(
      map((list: SubscriptionDto[]) => {
        if (!list) {
          return [];
        }

        return list.map((item: SubscriptionDto) => {
          return SubscriptionDto.normalize(item);
        });
      })
    );
  }

  public fetchTrialCustomerSubscription(customerId: number): Observable<any> {
    return this.httpClient.get(`/api/admin/customers/${customerId}/subscriptions/trial`);
  }

  public addCustomSubscriptionToUser(userId: number, customSubscriptionId: number): Observable<IDefaultResponse> {
    return this.httpClient.post(`/api/admin/customers/${userId}/subscriptions`, { subscriptionId: customSubscriptionId });
  }

  public setTrialSubscription(customerId: number, trialEnd: string): Observable<IDefaultResponse> {
    return this.httpClient.post(`/api/admin/customers/${customerId}/subscriptions/trial`, { trialEnd });
  }

  public updateTrialEndDate({ userId, subscriptionId, trialEnd }: { userId: number, subscriptionId: string, trialEnd: string }): Observable<IDefaultResponse> {
    return this.httpClient.put(`/api/admin/customers/${userId}/subscriptions/${subscriptionId}/trial`, { trialEnd });
  }

  public setSubscriptionForFree(customerId: number, subscriptionId: string, settings: { resumesAt: number }): Observable<IDefaultResponse> {
    return this.httpClient.put(`/api/admin/customers/${customerId}/subscriptions/${subscriptionId}/free`, settings);
  }

  public deleteCustomSubscriptionFromUser(userId: number, accountSubscriptionId: number): Observable<IDefaultResponse> {
    return this.httpClient.delete(`/api/admin/customers/${userId}/subscriptions/${accountSubscriptionId}`);
  }

  public fetchWebsites(userId: number): Observable<WebsiteModel[]> {
    const params: HttpParams = new HttpParams().set('userId', `${userId}`);

    return this.httpClient.get('/api/admin/websites', { params }).pipe(
      map((websites: WebsiteDto[]) => {
        if (!websites) {
          return null;
        }

        return websites.map((website: WebsiteDto) => {
          return WebsiteDto.normalize(website);
        });
      })
    );
  }

  public fetchWebsite(websiteId: number): Observable<WebsiteModel> {
    return this.httpClient.get(`/api/admin/websites/${websiteId}`).pipe(map((websiteDto: WebsiteDto) => {
      return WebsiteDto.normalize(websiteDto);
    }));
  }

  public enablePublishButton(websiteId: number): Observable<any> {
    return this.httpClient.put(`/api/admin/websites/${websiteId}/publish-button`, {
      status: PUBLISH_STATUSES.READY,
    });
  }

  public enablePublishButtonForActiveWebsites(): Observable<any> {
    return this.httpClient.put('api/admin/websites/publish-button', {
      status: PUBLISH_STATUSES.READY,
    });
  }

  public deleteWebsiteFromAws(websiteId: number): Observable<any> {
    return this.httpClient.delete(`/api/admin/websites/${websiteId}`);
  }

  public fetchDomains(userId: number): Observable<any> {
    return this.httpClient.get('/api/admin/domains', { params: { userId } });
  }

  public addDomain(domainData: any): Observable<any> {
    return this.httpClient.post('/api/admin/domains', domainData);
  }

  public updateDomain(domainData: any): Observable<any> {
    return this.httpClient.put('/api/admin/domains', domainData);
  }

  public updateIsUserImported(customerId: number, isImported: boolean): Observable<any> {
    return this.httpClient.put(`/api/admin/customers/${customerId}/legacy-user`, { isImported });
  }

  public updateIsImageFilesRemoveEnabled(customerId: number, isImageFilesRemoveEnabled: boolean): Observable<any> {
    return this.httpClient.put(`/api/admin/customers/${customerId}`, { isImageFilesRemoveEnabled });
  }

  public updateIsMessagesReviewEnabled(customerId: number, isMessagesReviewEnabled: boolean): Observable<any> {
    return this.httpClient.put(`/api/admin/customers/${customerId}`, { isMessagesReviewEnabled });
  }

  public updateSupportVideoLink(customerId: number, supportVideoLink: string): Observable<any> {
    return this.httpClient.put(`/api/admin/customers/${customerId}`, { supportVideoLink });
  }

  public setWebsiteSubscription({ userId, websiteId, accountSubscriptionId }: { userId: number, websiteId: number, accountSubscriptionId: number }): Observable<IDefaultResponse> {
    return this.httpClient.put(`/api/admin/customers/${userId}/websites/${websiteId}/subscription`, { accountSubscriptionId });
  }

  public removeFailedImages(customerId: number): Observable<any> {
    return this.httpClient.delete(`/api/admin/customers/${customerId}/images/failed`);
  }

  public changePrimary(websiteId: number, domainId: number): Observable<any> {
    return this.httpClient.post(`/api/admin/domains/primary`, { websiteId, domainId });
  }

  public removeDomain(websiteId: number, domainId: number): Observable<any> {
    return this.httpClient.delete('/api/admin/domains', { params: { websiteId, domainId } });
  }

  public updateMailChimpApiKey(key: string, userId: Number): Observable<any> {
    return this.httpClient.put('/api/admin/auth/mailchimp', { key, userId });
  }

  public loadCustomer(customerId: number): Observable<any> {
    return this.httpClient.get(`/api/admin/customers/${customerId}`);
  }

  public loginAsUser(customerId: number): Observable<any> {
    return this.httpClient.post(`/api/admin/auth/login`, { targetUserId: customerId });
  }

  public importUser(userId, isReimport): Observable<any> {
    return this.httpClient.post(`/api/admin/import/user`, { userId, isReimport });
  }

  public fetchLegacyAccounts({ options, limit, offset }: { options: Object, limit: number, offset: number }): Observable<any> {
    const params = new HttpParams()
      .set('options', options ? JSON.stringify(options) : '')
      .set('limit', `${limit}`)
      .set('offset', `${offset}`);

    return this.httpClient.get(`api/admin/legacy-accounts`, { params });
  }
}
