import {Injectable} from '@angular/core';

import {Observable, BehaviorSubject, Subscription, Subscriber} from 'rxjs';

import {DefaultPortfolioHttpService} from './http/default-portfolio.http.service';
import {PortfolioService} from '../portfolios/portfolio.service';
import {IFrameService} from '../iframe/iframe.service';
import {EventsService} from '../interaction/events/events.service';

import {DefaultPortfolioBlockDto} from '../../models/blocks/default-portfolio/default-portfolio-block.dto';
import {BlockModel} from '../../models/blocks/block.model';

@Injectable()
export class DefaultPortfolioService {
  public defaultPortfolio: BehaviorSubject<DefaultPortfolioBlockDto> = new BehaviorSubject<DefaultPortfolioBlockDto>(null);

  constructor(private httpService: DefaultPortfolioHttpService,
              private portfolioService: PortfolioService,
              private iFrameService: IFrameService,
              private eventsService: EventsService) {
  }

  public save({ websiteId, templateId, blockTemplateId, blockCategory, name, version, usedFonts, html, isApplyToAllPortfolios }: {
    websiteId: number,
    templateId: number,
    blockTemplateId: number,
    blockCategory: string,
    name: string,
    version: number,
    usedFonts: string,
    html: string,
    isApplyToAllPortfolios: boolean,
  }): Subscription {
    if (!isApplyToAllPortfolios) {
      return this.doSave({ websiteId, templateId, blockTemplateId, version, usedFonts, html, portfolios: [], isApplyToAllPortfolios });
    }

    this.handleAllPortfolios({ websiteId, templateId, defaultPortfolioHtml: html }).subscribe((portfolios: BlockModel[]) => {
      portfolios.forEach((portfolio: BlockModel) => {
        portfolio.BlockTemplateId = blockTemplateId;
        portfolio.Block_Category = blockCategory;
        portfolio.Name = name;
        portfolio.Version = version;
      });

      return this.doSave({ websiteId, templateId, blockTemplateId, version, usedFonts, html, portfolios, isApplyToAllPortfolios });
    });
  }

  private handleAllPortfolios({ websiteId, templateId, defaultPortfolioHtml }: { websiteId: number, templateId: number, defaultPortfolioHtml: string }): Observable<BlockModel[]> {
    return new Observable((observer: Subscriber<BlockModel[]>) => {
      this.portfolioService.fetchAllWebsitePortfolios({ websiteId, templateId }).subscribe((portfolios: BlockModel[]) => {
        this.eventsService.addFrameListener('defaultPortfolioSlidesMigrated', (e: CustomEvent) => {
          observer.next(e.detail.portfolios);
          observer.complete();
        });

        this.eventsService.dispatchMigratePortfolioSlidesForDefaultPortfolio({ portfolios, defaultPortfolioHtml }, this.iFrameService.sandboxWindow);
      });
    });
  }

  public doSave({ websiteId, templateId, blockTemplateId, version, usedFonts, html, portfolios, isApplyToAllPortfolios }: {
    websiteId: number,
    templateId: number,
    blockTemplateId: number,
    version: number,
    usedFonts: string,
    html: string,
    portfolios: BlockModel[],
    isApplyToAllPortfolios: boolean,
  }): Subscription {
    return this.httpService.save({
      websiteId,
      templateId,
      blockTemplateId,
      version,
      usedFonts,
      html,
      portfolios,
      isApplyToAllPortfolios,
    }).subscribe(() => {
      this.fetch({ websiteId, templateId });
    });
  }

  public fetch({ websiteId, templateId }: { websiteId: number, templateId: number }): Subscription {
    return this.httpService.fetch({ websiteId, templateId }).subscribe((res: DefaultPortfolioBlockDto) => {
      this.defaultPortfolio.next(DefaultPortfolioBlockDto.normalize(res));
    });
  }
}
