export const STATUSES_KEYS = {
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELLED: 'canceled',
};

export const STATUSES = {
  [STATUSES_KEYS.INCOMPLETE]: 'Retry Payment',
  [STATUSES_KEYS.INCOMPLETE_EXPIRED]: 'Payment Expired',
  [STATUSES_KEYS.TRIALING]: 'Trial',
  [STATUSES_KEYS.ACTIVE]: 'Active',
  [STATUSES_KEYS.PAST_DUE]: 'Past Due',
  [STATUSES_KEYS.UNPAID]: 'Unpaid',
  [STATUSES_KEYS.CANCELLED]: 'Canceled',
};

export const STATUSES_ERRORS = {
  [STATUSES_KEYS.INCOMPLETE]: 'Retry Payment',
  [STATUSES_KEYS.PAST_DUE]: 'Retry Payment',
  [STATUSES_KEYS.INCOMPLETE_EXPIRED]: 'Payment Expired',
};

export const UNPAID_STATUSES = {
  [STATUSES_KEYS.INCOMPLETE]: true,
  [STATUSES_KEYS.PAST_DUE]: true,
};

export const ACTIVE_CLASSES = [
  STATUSES_KEYS.TRIALING,
  STATUSES_KEYS.ACTIVE,
];

export const GREEN_STATUSES_CLASSES = [
  STATUSES_KEYS.TRIALING,
  STATUSES_KEYS.ACTIVE,
];

export const YELLOW_STATUSES_CLASSES = [
  STATUSES_KEYS.INCOMPLETE,
];

export const RED_STATUSES_CLASSES = [
  STATUSES_KEYS.CANCELLED,
  STATUSES_KEYS.UNPAID,
  STATUSES_KEYS.PAST_DUE,
  STATUSES_KEYS.INCOMPLETE_EXPIRED,
];

export const DURATIONS = {
  ONE_DAY: 'ONE_DAY',
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTHS: 'THREE_MONTHS',
  ONE_YEAR: 'ONE_YEAR',
};

export const DURATIONS_TEXTS = {
  [DURATIONS.ONE_DAY]: 'daily',
  [DURATIONS.ONE_MONTH]: 'monthly',
  [DURATIONS.THREE_MONTHS]: 'quarterly',
  [DURATIONS.ONE_YEAR]: 'annual',
};

export const METADATA_ACTIONS = {
  BUY_SUBSCRIPTION: ({ planName }) => `${planName ? planName : ''} Subscription`,
  BUY_ADD_ON: ({ addOnName }) => `${addOnName ? addOnName : ''} Subscription`,
  UPGRADE_OR_DOWNGRADE_SUBSCRIPTION: ({ planName }) => `${planName ? planName : ''} Subscription`,
};
