import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AdminEducationTeachersService} from '../../../../../../core/services/admin/education/teachers/admin-education-teachers.service';

import {EducationTeacherModel} from '../../../../../../core/models/education/teachers/education-teacher.model';

@Component({
  selector: 'app-admin-education-teacher-details',
  templateUrl: './teacher-details.component.html',
  styleUrls: ['./teacher-details.component.scss']
})
export class AdminEducationTeacherDetailsComponent implements OnInit, OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public teacher: EducationTeacherModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationTeachersService) {
  }

  public ngOnInit(): void {
    this.service.teacherDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((teacher: EducationTeacherModel) => {
      this.teacher = teacher;
    });
  }

  public cancel(): void {
    this.closeHandler.emit();

    this.service.teacherDetailsSubject.next(null);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
