import {BaseFontWeightModel} from '../base/font-weight/base-font-weight.model';

import {STYLE_PROPERTY_NAME, SELECTORS} from './constants';

export class SlideshowParagraphFontWeightModel extends BaseFontWeightModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled, STYLE_PROPERTY_NAME);
  }

  public init(element: HTMLElement): void {
    const elementToSelect: Element = element.closest(SELECTORS.SLIDESHOW_SLIDE) || element.closest(SELECTORS.BLOCK);

    super.init(<HTMLElement>elementToSelect);
  }
}
