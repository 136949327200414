export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `ਸਵਾਲ ਸਿਰਫ਼ ਸਮੇਂ ਦਾ ਸੀ।`,
      `ਉਹਨਾਂ ਦੇ ਸਾਰੇ ਸਾਜ਼ੋ-ਸਾਮਾਨ ਅਤੇ ਉਪਕਰਨ ਹਾਲੇ ਵੀ, ਕਿਸੇ ਨਾ ਕਿਸੇ ਰੂਪ ਵਿੱਚ ਕਿਰਿਆਸ਼ੀਲ ਹਨ।`,
    ],
    [KEYS.ALPHABET]: [
      'ਆਈਊਏਐਓਔਕਖਗਘਙਚਛਜਝਞਟਠਡਢਣਤਥਦਧਨਪਫਬਭਮਯਰਲਲ਼ਵਸ਼ਸਹੲੳੴ₹',
    ],
    [KEYS.PARAGRAPH]: [
      `ਦੂਰ ਕਿਸੇ ਪਿੰਡ ਤੋਂ ਮਿਲਣ ਵਾਲੀ ਝਲਕ ਵਿੱਚ ਕਿਸੇ ਪਵਿੱਤਰ ਸੰਗੀਤ ਦੀ ਝਣਕਾਰ, ਜਾਂ ਇੱਕ ਪ੍ਰਭਾਵਸ਼ਾਲੀ ਚਿਤਰ, ਜਾਂ ਕਿਸੇ ਮਹਾਨ ਕਵੀ ਦੇ ਪੈਰ੍ਹੇ ਦੇ ਸਮਾਨ ਸਾਡੇ ਵਿਚਾਰਾਂ ਨੂੰ ਉੱਨਤ ਅਤੇ ਪਵਿੱਤਰ ਕਰਨ ਦੀ ਸ਼ਕਤੀ ਹੁੰਦੀ ਹੈ। ਇਸ ਨਾਲ ਹਮੇਸ਼ਾ ਕਿਸੇ ਦਾ ਭਲਾ ਹੀ ਹੁੰਦਾ ਹੈ।`
    ],
    [KEYS.NUMERALS]: [
      '੦੧੨੩੪੫੬੭੮੯੧',
    ],
  };
};
