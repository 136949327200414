import {DEVICES} from '../../services/editor-devices/constants';

export const EDGES = {
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
};

export const DEVICES_TO_CLASSES = {
  [DEVICES.MOBILE]: 'mobile',
  [DEVICES.TABLET]: 'tablet',
  [DEVICES.DESKTOP]: 'desktop',
};

export const DEVICES_SIZES = {
  [DEVICES.MOBILE]: {
    maxWidth: 400,
    maxHeight: 780,
  },
  [DEVICES.TABLET]: {
    maxWidth: 766,
    maxHeight: 960,
  },
};

export const MIN_WIDTH = 400;
export const MIN_HEIGHT = 400;
