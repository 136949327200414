import {METADATA_ACTIONS, DURATIONS_TEXTS} from './constants';

export class SubscriptionMetadataModel {
  get text(): string {
    return METADATA_ACTIONS[this.action] ? METADATA_ACTIONS[this.action](this) : '';
  }

  get durationText(): string {
    return SubscriptionMetadataModel.getDurationText(this.duration);
  }

  constructor(public action?: string,
              public planId?: string,
              public planName?: string,
              public addOnId?: number,
              public addOnKey?: string,
              public addOnName?: string,
              public duration?: string,
              public quantity?: number) {}

  public static getDurationText(key: string): string {
    return DURATIONS_TEXTS[key] || '';
  }
}
