import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {ImagesService} from '../../../../services/images.service';

@Injectable()
export class VideoThumbnailsService {
  constructor(private imagesService: ImagesService) {
  }

  public getThumb(file: File): Observable<Blob> {
    return new Observable(observer => {
       this.getScreenshotFromVideoFile(file).subscribe((blob: Blob) => {
          observer.next(blob);
          observer.complete();
       });
    });
  }

  private getScreenshotFromVideoFile(file: File): Observable<Blob> {
    return new Observable(observer => {
      const container: HTMLElement = document.body;
      const video: HTMLVideoElement = document.createElement('video');
      const canvas: HTMLCanvasElement = document.createElement('canvas');

      video.addEventListener('canplay', () => {
        canvas.width = 1024;
        canvas.height = video.videoHeight / video.videoWidth * 1024;

        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64jpeg = canvas.toDataURL('image/jpeg');
        const blob = this.imagesService.dataUriToBlob(base64jpeg);

        observer.next(blob);
        observer.complete();

        video.pause();
        video.remove();
      });

      video.muted = true;

      video.src = URL.createObjectURL(file);

      container.appendChild(video);

      video.play();
    });
  }
}
