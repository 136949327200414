import {ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ContactBlockSetupService} from '../../../../core/services/contact-block-setup/contact-block-setup.service';
import {CaptchaHttpService} from '../../../../core/services/interaction/http/captcha/captcha-http.service';
import {AuthService} from '../../../../auth/auth.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';

import {AccountModel} from '../../../../core/models/accounts/account.model';
import {Button} from '../../../../common/models/button/button.model';
import {CaptchaDataModel} from '../../../../core/models/captcha-data/captcha-data.model';

@Component({
  selector: 'app-captcha-data',
  templateUrl: './captcha-data.component.html',
  styleUrls: ['./captcha-data.component.scss']
})
export class CaptchaDataComponent implements OnDestroy {
  @Input() data: CaptchaDataModel;

  public buttons: Button[] = [
    {
      text: 'Save',
      className: 'neutral green',
      onClick: this.onSave.bind(this),
    },
  ];

  public isInProgress: boolean = false;

  private account: AccountModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private chRef: ChangeDetectorRef,
              private authService: AuthService,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private service: ContactBlockSetupService,
              private httpService: CaptchaHttpService) {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onSave() {
    if (this.isInProgress) return;

    if (!this.account || !this.data) return console.error(`CaptchaDataComponent: can't save domains.`);

    this.isInProgress = true;

    this.httpService.saveDomains(this.account.activeWebsiteId, this.data.domains).subscribe(() => {
      this.isInProgress = false;
    });
  }

  public copy(e: Event, str: string): void {
    const target = <HTMLElement>e.currentTarget;

    target.classList.add('copied');

    const input = document.createElement('input');

    document.body.appendChild(input);

    input.value = str;

    input.select();

    document.execCommand('copy');

    input.remove();

    setTimeout(() => target.classList.remove('copied'), 500);
  }
}
