import {IStyleOption} from '../option/i-style-option';
import {IStyleModelOptions} from '../option/i-style-model-options';

import {PROPERTY, DEFAULT_VALUE} from './constants';

export class ArrowBackgroundTransparencyModel implements IStyleOption {
  public element: HTMLElement;
  public block: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.block = options.block;

    if (!this.block) return;

    const propertyValue: number = parseFloat(window.getComputedStyle(this.block).getPropertyValue(PROPERTY)) * 100;
    const value: number = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;

    if (value) return this.setValue(Number.isNaN(value) ? void 0 : value.toString());

    this.value = DEFAULT_VALUE;
  }

  public reset(): void {
    if (!this.isEnabled || !this.block) return;

    this.block.style.removeProperty(PROPERTY);

    const computed = window.getComputedStyle(this.block);
    const propertyValue = parseFloat(computed.getPropertyValue(PROPERTY)) * 100;

    this.value = Number.isNaN(propertyValue) ? DEFAULT_VALUE : 100 - propertyValue;
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.block) return;

    this.value = value;

    this.block.style.setProperty(PROPERTY, (1 - parseFloat(value) / 100).toString());
  }
}
