import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';

import {Subject, Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {AuthService} from '../../../auth/auth.service';

import {IPermissionData} from '../../../core/models/permission/i-permission-data';

@Component({
  selector: 'app-permission-checker',
  templateUrl: './permission-checker.component.html',
  styleUrls: ['./permission-checker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionCheckerComponent implements OnDestroy, AfterViewInit {
  @Input() requiredPermissions: IPermissionData[];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private permissionsService: PermissionsService,
              private authService: AuthService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.authService.userSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.changeDetectorRef.detectChanges(); // to check permissions after updating user
    });
  }

  public isUserHasPermissions(): Observable<boolean> {
    if (!this.requiredPermissions || this.requiredPermissions.length === 0) {
      return new Observable<boolean>(observer => {
        observer.next(true);
        observer.complete();
      });
    }

    return this.permissionsService.isUserHasPermissionsObservable(this.requiredPermissions);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
  }
}
