import {ModalHeader} from '../../../../common/models/modal/header/header.model';

export const HEADERS: { [key: string]: ModalHeader } = {
  CONTACT_BLOCK: {
    text: 'CONTACT BLOCK SETUP',
    className: 'neutral-header',
  },
  INQUIRY_MODAL: {
    text: 'INQUIRY FORM SETUP',
    className: 'neutral-header',
  },
};
