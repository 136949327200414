import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class CartItemDeleteModalService {
  public readonly id = 'cart-item-modal';

  public isOpened = false;

  public item: any = null;

  public constructor(private modalsService: ModalsService) {
  }

  public open(item: any): void {
    this.item = item;

    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public close(): void {
    this.isOpened = false;

    this.item = null;

    this.modalsService.close(this.id);
  }
}
