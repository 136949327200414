
import {filter} from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ContentLoaderService} from '../../core/services/loaders/content/content-loader.service';
import {NavigationService} from '../../services/navigation.service';
import {ModalsService} from '../../shared/services/modals/modals.service';
import {AddNodeModalService} from '../../shared/services/modals/add-node/add-node-modal.service';

import {ModalDataModel} from '../../core/models/modals/modal-data.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = true;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  path = '';

  public get addNodeModalId(): string {
    return this.addNodeModalService.id;
  }

  public get isImageManager(): boolean {
    return this.navigationService.isImageManager;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private addNodeModalService: AddNodeModalService,
              private navigationService: NavigationService,
              private modalsService: ModalsService,
              private loaderService: ContentLoaderService) {}

  public ngOnInit(): void {
    this.initPath();

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(modalsStatus => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });

    this.loaderService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => this.initPath());

    setTimeout(() => { this.handleLongLoading(); }, 2500);
  }

  private initPath(): void {
    const child = this.route.snapshot.firstChild;

    if (!child) return;

    const grandchild = child.firstChild;

    this.path = grandchild ? grandchild.data['path'] : child.data['path'];
  }

  private handleLongLoading(): void {
    if (!this.loaderService.isLoaded) return;

    this.isLoaded = true;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
