import {IStyleOption} from '../../option/i-style-option';

import {ATTRIBUTES} from './constants';

export class HamburgerTooltipModel implements IStyleOption {
  private hamburgers: HTMLElement[];
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
    public onChange?: (e?) => void,
  ) {
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.hamburgers = Array.from(this.element.querySelectorAll('section'));

    if (!this.hamburgers || !this.hamburgers.length) return;

    if (this.hamburgers[0].getAttribute(ATTRIBUTES.TOOLTIP)) return this.setValue(true);

    this.setValue(false);
  }

  public reset() {
    if (!this.isEnabled || !this.element) return;

    this.setValue(true);
  }

  public setValue(value: boolean) {
    if (!this.isEnabled || !this.hamburgers || !this.hamburgers.length) return;

    this.value = value;

    if (this.value) return this.hamburgers.forEach(hamburger => hamburger.setAttribute(ATTRIBUTES.TOOLTIP, 'MENU'));

    this.hamburgers.forEach(hamburger => hamburger.removeAttribute(ATTRIBUTES.TOOLTIP));
  }
}
