import {Component, Input, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

import {ModalsService} from "../../../services/modals/modals.service";
import {AppVersionChangesService} from '../../../../core/services/app-version-changes/app-version-changes.service';

import {Button} from "../../../../common/models/button/button.model";
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {AppVersionChangesSummaryItemModel} from '../../../../core/models/app-version-changes-summary-item/app-version-changes-summary-item.model';

@Component({
  selector: 'app-version-changes-summary-modal',
  templateUrl: './app-version-changes-summary-modal.component.html',
  styleUrls: ['./app-version-changes-summary-modal.component.scss']
})
export class AppVersionChangesSummaryModalComponent implements OnDestroy {
  @Input() id: string;

  public modalHeader: ModalHeader = {
    text: 'Version Changes Summary',
    className: 'success-header',
  };

  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.submitHandler.bind(this),
    }
  ];

  public items: AppVersionChangesSummaryItemModel[] = null;

  public selectedVersion: AppVersionChangesSummaryItemModel;

  public isOpened: boolean = false;
  public isExpanded: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private modalsService: ModalsService,
              private service: AppVersionChangesService) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onModalOpen() {
    this.isOpened = true;

    this.service.loadSummary().subscribe((res: AppVersionChangesSummaryItemModel[]) => {
      this.items = res;

      if (!this.items) return;

      this.onItemClick(this.items[0]);
    });
  }

  public submitHandler() {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }

  public onCurrentVersionClick(): void {
    this.isExpanded = !this.isExpanded;
  }

  public onItemClick(item: AppVersionChangesSummaryItemModel): void {
    this.selectedVersion = item;
    this.isExpanded = false;

    if (item.isLoaded) return;

    this.service.loadTextForSummary(item.id).subscribe((text: string) => {
      item.changes = text;
      item.isLoaded = true;
    });
  }
}
