// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep app-modal.image-manager-modal > .modal {
  width: 100%;
  height: 100%;
  max-width: none;
  padding: 30px 60px;
  left: auto;
  top: 0;
  transform: none;
}
:host ::ng-deep app-modal.image-manager-modal > .modal > .modal-body {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid #999;
  padding: 0;
  background: #fdfdfd;
  overflow: visible;
}
:host ::ng-deep app-modal app-close-icon {
  position: absolute;
  right: 0;
  top: 0;
}
:host ::ng-deep app-modal app-close-icon .close-btn {
  transform: translate(200%, 25%) scale(1.25) !important;
}

.image-manager-modal .modal-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
}
.image-manager-modal .close-icon-wrapper {
  position: absolute;
  height: 20px;
  width: 20px;
  right: -15px;
  top: 0;
  z-index: 10;
  transform: translateX(100%);
  opacity: 0.5;
}
.image-manager-modal .close-icon-wrapper:hover {
  opacity: 1;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/image-manager-modal/image-manager-modal.component.scss"],"names":[],"mappings":"AAGM;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;EACA,MAAA;EACA,eAAA;AAFR;AAIQ;EACE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;EACA,iBAAA;AAFV;AAOI;EACE,kBAAA;EACA,QAAA;EACA,MAAA;AALN;AAOM;EACE,sDAAA;AALR;;AAYE;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;AATJ;AAYE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,WAAA;EACA,2BAAA;EACA,YAAA;AAVJ;AAYI;EACE,UAAA;EACA,eAAA;AAVN","sourcesContent":[":host ::ng-deep {\n  app-modal {\n    &.image-manager-modal {\n      >.modal {\n        width: 100%;\n        height: 100%;\n        max-width: none;\n        padding: 30px 60px;\n        left: auto;\n        top: 0;\n        transform: none;\n  \n        >.modal-body {\n          position: relative;\n          display: flex;\n          width: 100%;\n          height: 100%;\n          margin: 0;\n          border: 1px solid #999;\n          padding: 0;\n          background: #fdfdfd;\n          overflow: visible;\n        }\n      }\n    }\n\n    app-close-icon {\n      position: absolute;\n      right: 0;\n      top: 0;\n  \n      .close-btn {\n        transform: translate(200%, 25%) scale(1.25) !important;\n      }\n    }\n  }\n}\n\n.image-manager-modal {\n  .modal-content-wrapper {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    margin: 0;\n  }\n  \n  .close-icon-wrapper {\n    position: absolute;\n    height: 20px;\n    width: 20px;\n    right: -15px;\n    top: 0;\n    z-index: 10;\n    transform: translateX(100%);\n    opacity: 0.5;\n\n    &:hover {\n      opacity: 1;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
