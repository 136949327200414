import {IStyleModelOptions} from '../../../option/i-style-model-options';

import {BasePxStyleOptionModel} from '../../../base/base-px-style-option/base-px-style-option.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX} from './constants';

export class BlogCommentsBorderRadiusModel extends BasePxStyleOptionModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, null, value, isEnabled);
  }

  public init(selectedElement: HTMLElement, options: IStyleModelOptions) {
    if (!selectedElement || !this.isEnabled) return;

    this.isEnabled = options.block && options.block.classList.contains('blog');

    super.init(options.block, {
      selectedElement
    });
  }
}
