import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../../../services/modals/modals.service';
import { RevertBlockToDefaultModalService } from '../../../services/modals/revert-block-to-default/revert-block-to-default-modal.service';
import { WebsiteBlocksService } from '../../../../core/services/websites/blocks/website-blocks.service';
import { PageEditorService } from '../../../../application/main/website-designer/page-editor/page-editor.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-revert-block-to-default-modal',
  templateUrl: './revert-block-to-default-modal.component.html',
  styleUrls: ['./revert-block-to-default-modal.component.scss']
})
export class RevertBlockToDefaultModalComponent implements OnInit, OnDestroy {
  public buttons: Button[] = [
    {
      text: 'CONFIRM',
      className: 'neutral ok-button',
      onClick: this.submitHandler.bind(this),
    },
    {
      text: 'CANCEL',
      className: 'neutral ok-button',
      onClick: this.cancelHandler.bind(this),
    },
  ];

  public isOpened: boolean = false;
  public isLoading: boolean = false;

  private block: {
    blockId: number,
    websiteId: number,
    pageId: number,
    pageType: string,
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private modalsService: ModalsService,
    private service: RevertBlockToDefaultModalService,
    private websiteBlocksService: WebsiteBlocksService,
    private pageEditorService: PageEditorService,
  ) {
  }

  public ngOnInit(): void {
  }

  public onModalOpen({ block }: {
    block: {
      blockId: number,
      websiteId: number,
      pageId: number,
      pageType: string,
    },
  }): void {
    this.block = block;

    this.isOpened = true;
  }

  public submitHandler(): void {
    this.isLoading = true;

    this.websiteBlocksService.deleteOneBlock(this.block).subscribe(() => {
      this.isLoading = false;
      this.isOpened = false;

      this.pageEditorService.reload.next(true);

      this.modalsService.close(this.id);

    });
  }

  public cancelHandler(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
