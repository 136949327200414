// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .object-remove-error-modal .modal .modal-header,
:host ::ng-deep .object-remove-error-modal .modal .modal-body {
  width: 600px !important;
  max-width: 600px;
}

.object-remove-error-modal-body {
  margin: 20px;
  clear: both;
  overflow: hidden;
}
.object-remove-error-modal-body .error-header {
  padding-top: 10px;
}
.object-remove-error-modal-body .error-header:first-child {
  padding-top: 0;
}
.object-remove-error-modal-body .error-items-wrapper {
  margin-top: 10px;
}
.object-remove-error-modal-body .error-items-wrapper .error-item-wrapper {
  padding-top: 5px;
  padding-left: 8px;
  border-left: 2px solid #ccc;
}
.object-remove-error-modal-body .error-items-wrapper .error-item-wrapper:first-child {
  padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/object-remove-error/object-remove-error-modal.component.scss"],"names":[],"mappings":"AAKM;;EAEE,uBAAA;EACA,gBAAA;AAJR;;AAUA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAPF;AASE;EACE,iBAAA;AAPJ;AASI;EACE,cAAA;AAPN;AAWE;EACE,gBAAA;AATJ;AAWI;EACE,gBAAA;EACA,iBAAA;EACA,2BAAA;AATN;AAWM;EACE,cAAA;AATR","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .object-remove-error-modal {\n    .modal {\n      .modal-header,\n      .modal-body {\n        width: 600px !important;\n        max-width: 600px;\n      }\n    }\n  }\n}\n\n.object-remove-error-modal-body {\n  margin: 20px;\n  clear: both;\n  overflow: hidden;\n  \n  .error-header {\n    padding-top: 10px;\n\n    &:first-child {\n      padding-top: 0;\n    }\n  }\n\n  .error-items-wrapper {\n    margin-top: 10px;\n\n    .error-item-wrapper {\n      padding-top: 5px;\n      padding-left: 8px;\n      border-left: 2px solid $primary;\n  \n      &:first-child {\n        padding-top: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
