import {IncludedAddOnsModel} from './included-add-ons.model';
import {AddOnDto} from '../../add-ons/add-on.dto';

export class IncludedAddOnsDto {
  constructor(public Key?: string,
              public Quantity?: number,
              public AddOn?: AddOnDto) {}

  public static toRequest(res: IncludedAddOnsModel): IncludedAddOnsDto {
    if (!res) return null;

    return new IncludedAddOnsDto(
      res.key,
      res.quantity,
      AddOnDto.toRequest(res.addOn),
    );
  }
}
