// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.editor-loader .loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 1002;
  font-size: 10px;
  border: 1px solid rgba(203, 203, 203, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  will-change: transform;
  animation: spinner 0.7s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/app/common/editor-loader/editor-loader.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AADF;AAII;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,0CAAA;EACA,0CAAA;EACA,kBAAA;EACA,sBAAA;EACA,uCAAA;AAFN","sourcesContent":["@import \"../../../styles/colors\";\n\n.editor-loader {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n\n  .loader {\n    &:before {\n      content: '';\n      box-sizing: border-box;\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      width: 50px;\n      height: 50px;\n      margin-top: -25px;\n      margin-left: -25px;\n      z-index: 1002;\n      font-size: 10px;\n      border: 1px solid rgba(203, 203, 203, 0.1);\n      border-left: 1px solid rgba(0, 0, 0, 0.36);\n      border-radius: 50%;\n      will-change: transform;\n      animation: spinner .7s linear infinite;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
