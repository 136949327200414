import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {NativeSetupOptionModel} from '../option/native-setup-option.model';

import {PROPERTY} from './constants';

export class TextDecorationModel extends NativeSetupOptionModel {
  constructor(
    public key: string,
    public isEnabled: boolean = true,
  ) {
    super(key, PROPERTY, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions) {
    this.element = element;

    if (!this.element) return;

    this.value = window.getComputedStyle(this.element)[PROPERTY].split(' ')[0] || 'none';
  }

  public onChange(value: string) {
    this.value = value;

    this.element.style.textDecoration = value;
  }

  public reset(): void {
    this.element.style.removeProperty(PROPERTY);

    this.value = window.getComputedStyle(this.element)[PROPERTY].split(' ')[0] || 'none';
  }
}
