import {TemplatePreviewModel} from './previews/template-preview.model';

import {SRCSET_SIZES} from './constants';

export class TemplateModel {
  public isHovered: boolean = false;
  public isImageLoaded: boolean = false;
  public isOldTemplate: boolean = false;

  public src: string = '';
  public srcSet: string = '';

  constructor(public id?: number,
              public name?: string,
              public portfolioLayout?: number,
              public description?: string,
              public templateCategoryId?: number,
              public orderNo?: number,
              public thumbnail?: string,
              public menuType?: string,
              public preview?: TemplatePreviewModel) {
    this.src = preview && preview.mainImagePath ? this.preview.mainImagePath : this.thumbnail;

    if (!preview || !preview.mainImagePath) {
      this.srcSet = this.thumbnail;

      return;
    }

    const extension = this.preview.mainImagePath.match(/.(\w|\d)+$/gi)[0];
    const src = this.preview.mainImagePath.replace(extension, '');

    this.srcSet = SRCSET_SIZES.map(({ height, width, density }) => `${src}_${width}_${height}${extension} ${density}`).join(',');
  }
}
