import {Injectable} from '@angular/core';

@Injectable()
export class FilesService {
  constructor() {
  }

  // async ok
  async readImage(file: File): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = () => {
        const image: HTMLImageElement = new Image();

        image.src = <string>reader.result;

        image.onload = () => {
          resolve(image);
        };
      };

      reader.readAsDataURL(file);
    });
  }
}
