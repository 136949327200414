import {IStyleModelOptions} from '../option/i-style-model-options';

import {BaseScalableModel} from '../base/scalable/base-scalable.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX, SELECTORS} from './constants';

export class ScalableSlideshowButtonLineHeightModel extends BaseScalableModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, value, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions): void {
    const elementToSelect: Element = element.closest(SELECTORS.SLIDESHOW_SLIDE) || element.closest(SELECTORS.BLOCK);

    super.init(<HTMLElement>elementToSelect, options);
  }
}
