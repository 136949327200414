import {Component} from '@angular/core';

import {RemoveSupportRowModalService} from '../../../services/modals/remove-support-row/remove-support-row-modal.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-remove-support-row-modal',
  templateUrl: './remove-support-row-modal.component.html',
  styleUrls: ['./remove-support-row-modal.component.scss'],
})
export class RemoveSupportRowModalComponent {
  public buttons: Button[] = [
    {
      text: 'Yes',
      className: 'neutral green',
      onClick: this.onSubmit.bind(this),
    },
    {
      text: 'No',
      className: 'neutral ok-button',
      onClick: this.onCancel.bind(this),
    },
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: RemoveSupportRowModalService) {}

  public onSubmit(): void {
    this.service.isDeleteConfirmed.next(true);

    this.service.close();
  }

  public onCancel(): void {
    this.service.isDeleteConfirmed.next(false);

    this.service.close();
  }
}
