import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {PortfolioHttpService} from './http/portfolio.http.service';

import {BlockModel} from '../../models/blocks/block.model';

@Injectable()
export class PortfolioService {
  constructor(private httpService: PortfolioHttpService) {
  }

  public fetchAllWebsitePortfolios({ websiteId, templateId }: { websiteId: number, templateId: number }): Observable<BlockModel[]> {
    return this.httpService.fetchAllWebsitePortfolios({ websiteId, templateId });
  }

  public fetchIsPortfolioWithTextSlidesExists({ websiteId, templateId }: { websiteId: number, templateId: number }): Observable<{ isPortfolioWithTextSlidesExists: boolean }> {
    return this.httpService.fetchIsPortfolioWithTextSlidesExists({ websiteId, templateId });
  }

  public updateShare({ id, isShared }: { id: number, isShared: boolean }): Observable<any> {
    return this.httpService.updateShare({ id, isShared });
  }
}
