import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from "../../../services/modals/modals.service";
import {ImageManagerModalService} from "./image-manager-modal.service";
import {ImageManagerModalFetchService} from '../../../../core/services/image-manager/modal/image-manager-modal-fetch.service';
import {PortfolioDefaultsService} from '../../../../core/services/image-manager/portfolio-defaults/portfolio-defaults.service';
import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';

@Component({
  selector: 'app-image-manager-modal',
  templateUrl: './image-manager-modal.component.html',
  styleUrls: ['./image-manager-modal.component.scss'],
})
export class ImageManagerModalComponent implements OnInit, OnDestroy {
  @Input() id = 'image-manager-modal';

  selectedImage = null;
  public images = [];

  public isOpened: boolean = false;
  public isPortfolioBlocked: boolean = false;
  public isPageBlocked: boolean = false;

  public portfolioId: number;
  public imagePortfolioId: number;

  private isInitPrevented: boolean;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get selectedPortfolioId(): number {
    return this.portfolioId || 0;
  }

  constructor(private modalsService: ModalsService,
              private fetchService: ImageManagerModalFetchService,
              private portfolioDefaultsService: PortfolioDefaultsService,
              private imageManagerService: ImageManagerService,
              public imageManagerModalService: ImageManagerModalService) {
    this.imageManagerModalService.onPortfolioSelect.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ id, nodeId }) => {
      this.portfolioId = id;

      this.imageManagerModalService.isLibrary = this.selectedPortfolioId === 0;

      if (!nodeId) return;

      this.portfolioDefaultsService.fetchPortfolioDefaults(nodeId);
    });

    this.imageManagerModalService.blockedPortfolioId.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id: number) => {
      this.imagePortfolioId = id;

      this.isPortfolioBlocked = id !== null && !Number.isNaN(id);
    });

    this.imageManagerModalService.portfolioInitPrevented.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPrevented: boolean) => {
      this.isInitPrevented = isPrevented;
    });

    this.fetchService.imagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(images => {
      this.images = images;
    });
  }

  public ngOnInit(): void {
    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPageBlocked: boolean) => {
      this.isPageBlocked = isPageBlocked;
    });
  }

  cancelHandler() {
    if (this.isPageBlocked) return;

    this.isOpened = false;

    this.fetchService.onModalClose();

    this.modalsService.close(this.id);
  }

  handleModalOpen() {
    this.isOpened = true;

    this.portfolioId = this.imagePortfolioId === null ? this.portfolioId : this.imagePortfolioId;
    this.imageManagerModalService.isLibrary = this.selectedPortfolioId === 0;

    if (this.isInitPrevented) return;

    this.imageManagerModalService.onPortfolioSelect.next({ id: this.selectedPortfolioId, nodeId: null });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
