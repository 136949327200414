import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EducationInstitutionModel} from '../../../../../../models/education/institutions/education-institution.model';
import {EducationInstitutionDto} from '../../../../../../models/education/institutions/education-institution.dto';

@Injectable()
export class EducationInstitutionsHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public getOne(id: number): Observable<EducationInstitutionModel> {
    return this.http.get(`${this.prefix}/institutions/${id}`).pipe(
      map((item: EducationInstitutionDto) => EducationInstitutionDto.normalize(item))
    );
  }
}
