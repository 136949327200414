import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ButtonsService} from '../buttons/buttons.service';

import {DEVICES, BUTTONS_TO_DEVICES} from './constants';
import {BUTTONS_KEYS} from '../buttons/constants';

@Injectable()
export class EditorDevicesService {
  public onDeviceChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onDeviceIconChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private watchableButtons = {
    [BUTTONS_KEYS.MOBILE_DEVICE]: true,
    [BUTTONS_KEYS.TABLET_DEVICE]: true,
    [BUTTONS_KEYS.DESKTOP_DEVICE]: true,
  };

  private get device(): string {
    return this.onDeviceIconChangeSubject.value;
  }

  constructor(private buttonsService: ButtonsService) {
    this.buttonsService.onButtonChangeSubject.subscribe((key: string) => {
      if (!this.watchableButtons[key]) {
        return;
      }

      this.onDeviceChange(BUTTONS_TO_DEVICES[key]);
    });

    this.onDeviceChange(DEVICES.DESKTOP);
  }

  public onDeviceChange(key: string) {
    if (!key || this.device === key) {
      return;
    }

    this.onDeviceChangeSubject.next(key);
    this.onDeviceIconChangeSubject.next(key);
  }

  public onWidthChange(value: number): void {
    if (value < 480) return this.device !== DEVICES.MOBILE && this.onDeviceIconChangeSubject.next(DEVICES.MOBILE);
    if (value < 768) return this.device !== DEVICES.TABLET && this.onDeviceIconChangeSubject.next(DEVICES.TABLET);

    return this.device !== DEVICES.DESKTOP && this.onDeviceIconChangeSubject.next(DEVICES.DESKTOP);
  }
}
