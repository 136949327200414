import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/custom-menu/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/custom-mobile-menu/constants';

export const KEYS = {
  NONE: 'NONE',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const CLASSES = {
  [DESKTOP_KEYS.MENU_DROPDOWN_ARROW]: {
    [KEYS.NONE]: 'dropdown-none',
    [KEYS.LEFT]: 'dropdown-left',
    [KEYS.RIGHT]: 'dropdown-right',
  },
  [MOBILE_KEYS.MOBILE_MENU_DROPDOWN_ARROW]: {
    [KEYS.NONE]: 'mobile-dropdown-none',
    [KEYS.LEFT]: 'mobile-dropdown-left',
    [KEYS.RIGHT]: 'mobile-dropdown-right',
  },
};

export const CLASSES_TO_KEYS = Object.keys(CLASSES).reduce((res, key) => {
  res[key] = Object.keys(CLASSES[key]).reduce((resInner, keyInner) => {
    resInner[CLASSES[key][keyInner]] = keyInner;
    return resInner;
  }, {});

  return res;
}, {});

export const DEFAULT_KEYS = {
  [DESKTOP_KEYS.MENU_DROPDOWN_ARROW]: CLASSES[DESKTOP_KEYS.MENU_DROPDOWN_ARROW][KEYS.LEFT],
  [MOBILE_KEYS.MOBILE_MENU_DROPDOWN_ARROW]: CLASSES[MOBILE_KEYS.MOBILE_MENU_DROPDOWN_ARROW][KEYS.LEFT],
};

export const CLASSES_LIST = Object.keys(CLASSES).reduce((res, key) => {
  res[key] = Object.values(CLASSES[key]);
  return res;
}, {});

export const HORIZONTAL_OPTIONS = [
  {
    key: KEYS.NONE,
    text: 'none',
  },
  {
    key: KEYS.LEFT,
    text: 'left',
  },
  {
    key: KEYS.RIGHT,
    text: 'right',
  },
];

export const DESKTOP_OPTIONS = [
  {
    key: KEYS.NONE,
    text: 'none',
  },
  {
    key: KEYS.LEFT,
    text: 'left',
  },
];

export const MOBILE_OPTIONS = [
  {
    key: KEYS.NONE,
    text: 'none',
  },
  {
    key: KEYS.LEFT,
    text: 'left',
  },
];
