
import {WebsitesTemplatesPermissionsDto} from './templates-permissions/templates-permissions.dto';
import {EducationClassDto} from '../education/classes/education-class.dto';
import {CourseWebsiteModel} from './course-website.model';

export class CourseWebsiteDto {
  constructor(public WebsiteId: number,
              public UserId: number,
              public ClassId: number,
              public Type: string,
              public Class: EducationClassDto,
              public TemplateId: number,
              public MailChimpListId: string,
              public CurrencyId: number,
              public MeasureUnitId: number,
              public GaAccountId: number,
              public Title: string,
              public Subtitle: string,
              public PublishStatus: string,
              public GaCode: string,
              public CopyrightNotice: string,
              public Contact: string,
              public ContactName: string,
              public ContactEmail: string,
              public ContactPhone: string,
              public ContactAddress1: string,
              public ContactAddress2: string,
              public Company: string,
              public ContactAddressCityStateZipCountry: string,
              public MetaTag_Description: string,
              public MetaTag_Keywords: string,
              public MenuStyle: string,
              public MobileMenuStyle: string,
              public MobilePortfolioKey: string,
              public MobileEnlargementKey: string,
              public PrivatePagePassword: string,
              public PrivatePagePasswordTimeUpdate: string,
              public PayPalEmail: string,
              public FaviconImage: string,
              public RandomizeFromQueue: boolean,
              public WebsitesTemplatesPermissions: WebsitesTemplatesPermissionsDto[],
              public IsSetupCompleted: boolean,
              public IsRightClickDisabled: boolean,
              public IsCopyrightNoticeEnabled: boolean,
              public IsEverPublished: boolean,
              public IsCaptchaEnabled: boolean,
              public IsBlogCommentsApprovable: boolean,
              public IsSimpleImageLabel: boolean,
              public IsMainStudentWebsite: boolean,
              public TimeUpdatedOffset: string) {}

  public static normalize(res: CourseWebsiteDto): CourseWebsiteModel {
    if (!res) return null;

    return new CourseWebsiteModel(
      res.WebsiteId,
      res.UserId,
      res.ClassId,
      res.Type,
      EducationClassDto.normalize(res.Class),
      res.TemplateId,
      res.MailChimpListId,
      res.CurrencyId,
      res.MeasureUnitId,
      res.GaAccountId,
      res.Title,
      res.Subtitle,
      res.PublishStatus,
      res.GaCode,
      res.CopyrightNotice,
      res.Contact,
      res.ContactName,
      res.ContactEmail,
      res.ContactPhone,
      res.ContactAddress1,
      res.ContactAddress2,
      res.Company,
      res.ContactAddressCityStateZipCountry,
      res.MetaTag_Description,
      res.MetaTag_Keywords,
      res.MenuStyle,
      res.MobileMenuStyle,
      res.MobilePortfolioKey,
      res.MobileEnlargementKey,
      res.PrivatePagePassword,
      res.PrivatePagePasswordTimeUpdate,
      res.PayPalEmail,
      res.FaviconImage,
      res.RandomizeFromQueue,
      res.WebsitesTemplatesPermissions ? res.WebsitesTemplatesPermissions.map((item: WebsitesTemplatesPermissionsDto) => WebsitesTemplatesPermissionsDto.normalize(item)) : null,
      res.IsSetupCompleted,
      res.IsRightClickDisabled,
      res.IsCopyrightNoticeEnabled,
      res.IsEverPublished,
      res.IsCaptchaEnabled,
      res.IsBlogCommentsApprovable,
      res.IsSimpleImageLabel,
      res.IsMainStudentWebsite,
      res.TimeUpdatedOffset,
    );
  }
}
