import * as moment from 'moment';

import {EducationInstitutionModel} from './education-institution.model';
import {AccountDto} from '../../accounts/account.dto';

import {TIME_FORMATTER} from './constants';

export class EducationInstitutionDto {
  constructor(public Id?: number,
              public Name?: string,
              public CreatedBy?: AccountDto,
              public DeletedBy?: AccountDto,
              public CreatedAt?: string,
              public UpdatedAt?: string,
              public DeletedAt?: string) {}

  public static normalize(res: EducationInstitutionDto): EducationInstitutionModel {
    if (!res) return null;

    return new EducationInstitutionModel(
      res.Id,
      res.Name,
      AccountDto.normalize(res.CreatedBy),
      AccountDto.normalize(res.DeletedBy),
      moment(res.CreatedAt).format(TIME_FORMATTER),
      moment(res.UpdatedAt).format(TIME_FORMATTER),
      moment(res.DeletedAt).format(TIME_FORMATTER),
    );
  }
}
