import {PortfolioDefaultsModel} from './portfolio-defaults.model';

export class PortfolioDefaultsV2Dto {
  constructor(public PortfolioId?: number,
              public Title?: string,
              public Caption?: string,
              public PrintProcess?: string,
              public OtherPrintProcess?: string,
              public Copyright?: string,
              public PurchaseInfo?: string,
              public Bucket?: string,

              public IsEditioned?: boolean,
              public EditionSize?: string,
              public PrintNumber?: string,

              public Width?: string,
              public Height?: string,
              public Length?: string,
              public MatWidth?: string,
              public MatHeight?: string,

              public Price?: number,
              public PricingStructure?: string,
              public InquiryLinkText?: string,
              public PurchaseText?: string,
              public Availability?: string,
              public IsInquireLinkVisible?: boolean,
              public IsPurchaseButtonVisible?: boolean,

              public Year?: string,
              public ProcessTypeId?: number) {}

  public static normalize(res: PortfolioDefaultsV2Dto): PortfolioDefaultsModel {
    if (!res) return null;

    return new PortfolioDefaultsModel(
      res.PortfolioId,
      res.Title,
      res.Caption,
      res.PrintProcess,
      res.OtherPrintProcess,
      res.Copyright,
      res.PurchaseInfo,
      res.Bucket,

      res.IsEditioned,
      res.EditionSize,
      res.PrintNumber,

      res.Width ? Number.parseFloat(res.Width) : null,
      res.Height ? Number.parseFloat(res.Height) : null,
      res.Length ? Number.parseFloat(res.Length) : null,
      res.MatWidth ? Number.parseFloat(res.MatWidth) : null,
      res.MatHeight ? Number.parseFloat(res.MatHeight) : null,

      res.Price,
      res.PricingStructure,
      res.InquiryLinkText,
      res.PurchaseText,
      res.Availability ? Number.parseInt(res.Availability, 10) : 0,
      res.IsInquireLinkVisible,
      res.IsPurchaseButtonVisible,

      res.Year,
      res.ProcessTypeId,
    );
  }
}
