import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {StyleOptionModel} from '../../../core/models/styles/settings/option/style-option.model';
import {IPaddingStyleOption} from '../../../core/models/styles/setup/option/i-padding-style-option';

@Component({
  selector: 'app-padding-customizer',
  templateUrl: './padding-customizer.component.html',
  styleUrls: ['./padding-customizer.component.scss'],
})
export class PaddingCustomizerComponent implements OnInit {
  @Input() public options: { text: string, key: string, min?: number, max?: number }[] = [];
  @Input() public styleOption: StyleOptionModel = null;
  @Input() public model: IPaddingStyleOption = null;
  @Input() public label: string = 'Padding';
  @Input() public isDisabled: boolean = false;

  @Output() public changeHandler = new EventEmitter();
  @Output() public resetHandler = new EventEmitter();

  public isExpanded: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.isExpanded = !this.model.isValuesEqual;
  }

  public onClick(): void {
    this.isExpanded = !this.isExpanded;

    this.cdr.detectChanges();
  }

  public onValueChange({ key, value }: { key?: string, value: string }): void {
    if (!key) this.isExpanded = false;

    this.model.onChange({ key, value });

    this.changeHandler.emit({ key, value });
  }

  public onValueReset(key?: string): void {
    this.model.reset(key);

    this.isExpanded = !this.model.isValuesEqual;

    this.resetHandler.emit(key);

    this.cdr.detectChanges();
  }
}
