import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject} from 'rxjs';

import {ModalsService} from '../modals.service';

import {PortfolioModel} from '../../../../core/models/portfolios/portfolio.model';

@Injectable()
export class DeletePortfolioModalService {
  public readonly id = 'delete-portfolio-modal';

  public isOpened = false;

  public portfolioSubject: BehaviorSubject<PortfolioModel> = new BehaviorSubject<PortfolioModel>(null);
  public onRemoveSubject: Subject<PortfolioModel> = new Subject<PortfolioModel>();

  public constructor(private modalsService: ModalsService) {
  }

  public open(portfolio: PortfolioModel): void {
    this.portfolioSubject.next(portfolio);

    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public removePortfolio(portfolio: PortfolioModel): void {
    this.onRemoveSubject.next(portfolio);
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
