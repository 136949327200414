import {Injectable} from '@angular/core';

import {Observable, Subscription, BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';

import {ModalsService} from '../../modals.service';
import {ImagesCounterService} from '../../../../../core/services/image-manager/counters/images-counter.service';
import {EducationStudentsImagesService} from '../../../../../core/services/education/teachers/institutions/classes/students/images/education-students-images.service';
import {EducationExhibitionsImagesCounterService} from '../../../../../core/services/education/image-manager/exhibitions-counters/education-exhibitions-images-counter.service';
import {ReviewDeleteConfirmationModalService} from '../review-delete-confirmation/review-delete-confirmation-modal.service';

import {PortfolioModel} from '../../../../../core/models/portfolios/portfolio.model';
import {ImageModel} from '../../../../../core/models/images/image.model';
import {EducationStudentPortfolioModel} from '../../../../../core/models/education/portfolios/education-student-portfolio.model';
import {ImageDataModel} from '../../../../../core/models/image-manager/counters/image-data.model';

@Injectable()
export class SelectExhibitionModalService {
  public readonly id: string = 'select-exhibition-modal';

  public onSelect: BehaviorSubject<PortfolioModel> = new BehaviorSubject<PortfolioModel>(null);
  public isExhibitButtonBlocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public imageToClone: ImageModel = null;
  public studentPortfolio: EducationStudentPortfolioModel = null;
  
  public isOpened: boolean = false;

  constructor(private modalsService: ModalsService,
              private imagesCounterService: ImagesCounterService,
              private reviewDeleteConfirmationModalService: ReviewDeleteConfirmationModalService,
              private educationStudentsImagesService: EducationStudentsImagesService,
              private educationExhibitionsImagesCounterService: EducationExhibitionsImagesCounterService) {
  } 

  public open(): void {
    this.isOpened = true;

    this.modalsService.open(this.id);
  }

  public onImageExhibit(targetPortfolioId: number, { isSingle }: { isSingle: boolean }): Subscription {
    const image: ImageModel = this.imageToClone;

    if (!image || !this.studentPortfolio) {
      return Subscription.EMPTY;
    }

    if (this.isExhibitedToPortfolio(targetPortfolioId)) {
      return this.removeImageFromExhibition(targetPortfolioId, image, { isSingle });
    }

    return this.handleImageClone(targetPortfolioId, image);
  }

  private handleImageClone(targetPortfolioId: number, image: ImageModel): Subscription {
    this.isExhibitButtonBlocked.next(true);

    return this.cloneImage(targetPortfolioId).subscribe((exhibitedImages: ImageModel[]) => {
      image.exhibitedImages = exhibitedImages;

      this.educationStudentsImagesService.currentImageExhibitedImagesSubject.next(exhibitedImages);

      this.imagesCounterService.onImageAdd(targetPortfolioId, 1);
      this.educationExhibitionsImagesCounterService.onImageAdd(targetPortfolioId, 1);

      this.close();
    });
  }
  
  private removeImageFromExhibition(targetPortfolioId: number, image: ImageModel, { isSingle }: { isSingle: boolean }): Subscription {
    const isPublished: boolean = image.isPublished;

    if (!isSingle) {
      this.handleImageRemoveFromExhibition(targetPortfolioId, image, { isPublished });

      return Subscription.EMPTY;
    }

    this.reviewDeleteConfirmationModalService.open();

    return this.reviewDeleteConfirmationModalService.onSubmit.pipe(take(1)).subscribe((isConfirmed: boolean) => {
      if (!isConfirmed) {
        return;
      }

      this.handleImageRemoveFromExhibition(targetPortfolioId, image, { isPublished });
    });
  }

  private handleImageRemoveFromExhibition(targetPortfolioId: number, image: ImageModel, { isPublished }: { isPublished: boolean }): Subscription {
    this.isExhibitButtonBlocked.next(true);

    return this.educationStudentsImagesService.removeClonedImage(
      this.studentPortfolio.institutionId,
      this.studentPortfolio.classId,
      this.studentPortfolio.studentId,
      this.studentPortfolio.id,
      image.id,
      targetPortfolioId,
    ).subscribe((exhibitedImages: ImageModel[]) => {
      image.exhibitedImages = exhibitedImages;

      this.educationStudentsImagesService.currentImageExhibitedImagesSubject.next(exhibitedImages);

      const data: ImageDataModel[] = [
        new ImageDataModel(null, isPublished),
      ];
      
      this.imagesCounterService.onImageRemove(targetPortfolioId, data);
      this.educationExhibitionsImagesCounterService.onImageRemove(targetPortfolioId, data);

      this.close();
    });
  }

  private isExhibitedToPortfolio(portfolioId: number): boolean {
    if (!this.imageToClone || !this.imageToClone.exhibitedImages || this.imageToClone.exhibitedImages.length === 0) {
      return false;
    }

    return !!this.imageToClone.exhibitedImages.find((image: ImageModel) => {
      return image.portfolioId === portfolioId && image.sourceImageId === this.imageToClone.id;
    });
  }

  private cloneImage(targetPortfolioId: number): Observable<ImageModel[]> {
    if (!this.imageToClone || !this.studentPortfolio) {
      return;
    }

    this.onSelect.next(null);

    const observable: Observable<ImageModel[]> = this.educationStudentsImagesService.cloneImage(
      this.studentPortfolio.institutionId,
      this.studentPortfolio.classId,
      this.studentPortfolio.studentId,
      this.studentPortfolio.id,
      this.imageToClone.id, 
      targetPortfolioId,
    );

    this.imageToClone = null;
    this.studentPortfolio = null;

    return observable;
  }

  public close(): void {
    this.isOpened = false;
    this.imageToClone = null;
    this.studentPortfolio = null;

    this.isExhibitButtonBlocked.next(false);

    this.modalsService.close(this.id);
  }
}
