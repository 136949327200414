
import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import { TreeDragDropService } from 'primeng/api';

import {Subject} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {NavigationService} from '../../../../services/navigation.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {PagesService} from './pages.service';
import {AddNodeModalService} from '../../../../shared/services/modals/add-node/add-node-modal.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {OriginalHomePageSetupModalService} from '../../../../shared/services/modals/original-home-page-setup/original-home-page-setup-modal.service';
import {WebsiteTourService} from '../../../../core/services/website-tour/website-tour.service';
import {ImageManagerService} from '../../../main/image-manager/image-manager.service';
import {EducationImageManagerService} from '../../../../core/services/education/image-manager/education-image-manager.service';
import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {ImageManagerChangeWebsiteToPersonalModalService} from '../../../../shared/services/modals/image-manager-change-website-to-personal/image-manager-change-website-to-personal-modal.service';
import {StudentImageManagerService} from '../../../../core/services/education/students/image-manager/student-image-manager.service';

import {TemplateModel} from '../../../../core/models/templates/template.model';
import {NodeModel} from '../../../../core/models/nodes/node.model';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {TOUR_KEYS} from './constants';
import {PAGE_GROUP_TYPES} from './tree/constants';
import {KEYS} from '../../../../core/services/website-tour/constants';
import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';
import {EducatorImageManagerTabs} from '../../../../core/services/education/image-manager/constants';
import {StudentImageManagerTabs} from '../../../../core/services/education/students/image-manager/constants';


@Component({
  selector: 'app-designer-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [
    TreeDragDropService,
  ],
})
export class PagesSectionComponent implements OnInit, OnDestroy {
  public tooltipText: string = `Edit your portfolios (created in PAGES).
    Add new images or entire folders of images, drag-and-drop images to rearrange them, or move images from one portfolio to another.
    You may also move images out of your portfolio into your library by deleting single images or groups of images.`;

  public activeTeacherTab: EducatorImageManagerTabs = 'students';
  public activeStudentTab: StudentImageManagerTabs = 'student';

  public myWebsiteTabText: string = 'MY WEBSITE';

  public isNodeCanBeDropped: boolean = true;
  public isBothMenus: boolean = false;
  public isOriginalHomePageExists: boolean = true;
  public isPageBlocked: boolean = false;
  public isEducatorImageManager: boolean = false;
  public isStudentImageManager: boolean = false;
  public isActiveWebsiteRelatedToUser: boolean = false;

  public partsLoadingData = {
    [PAGE_GROUP_TYPES.ALL]: {
      isLoading: false,
      isEmpty: false,
    },
    [PAGE_GROUP_TYPES.HOME]: {
      isLoading: false,
      isEmpty: false,
    },
    [PAGE_GROUP_TYPES.HORIZONTAL]: {
      isLoading: false,
      isEmpty: false,
    },
    [PAGE_GROUP_TYPES.VERTICAL]: {
      isLoading: false,
      isEmpty: false,
    },
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get buttonText(): string {
    return this.isImageManager ? 'ADD PORTFOLIO' : 'ADD PAGE';
  }

  public get pageGroupTypes(): { ALL: string, HOME: string, HORIZONTAL: string, VERTICAL: string } {
    return PAGE_GROUP_TYPES;
  }

  public get addPageTourGroupKey(): string {
    return this.isImageManager ? 'SIDEBAR_IMAGE_MANAGER_ADD_PORTFOLIO_BUTTON' : 'SIDEBAR_PAGES_ADD_PAGE_BUTTON';
  }

  public get addPageTourKey(): string {
    return this.isImageManager ? 'ADD_PORTFOLIO_BUTTON' : 'ADD_PAGE_BUTTON';
  }

  public get addPageTourLocation(): string {
    return this.isImageManager ? 'SIDEBAR_IMAGE_MANAGER' : 'SIDEBAR_PAGES';
  }

  public get isImageManager(): boolean {
    return this.navigationService.isImageManager;
  }

  public get isPageEditor(): boolean {
    return this.navigationService.isPageEditor;
  }

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private addNodeModalService: AddNodeModalService,
    private route: ActivatedRoute,
    private websitesService: WebsitesService,
    private pagesService: PagesService,
    private nodesService: NodesService,
    private templatesService: TemplatesService,
    private originalHomePageSetupModalService: OriginalHomePageSetupModalService,
    private websiteTourService: WebsiteTourService,
    private imageManagerService: ImageManagerService,
    private educationImageManagerService: EducationImageManagerService,
    private studentImageManagerService: StudentImageManagerService,
    private modalsService: ModalsService,
    private imageManagerChangeWebsiteToPersonalModalService: ImageManagerChangeWebsiteToPersonalModalService,
    private permissionsService: PermissionsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: NavigationEnd) => {
      const { isImageManager, isPageEditor } = this;

      this.websiteTourService.removeVisibleLocation(TOUR_KEYS.SIDEBAR_PAGES);
      this.websiteTourService.removeVisibleLocation(TOUR_KEYS.SIDEBAR_IMAGE_MANAGER);

      this.websiteTourService.removeVisibleItem(KEYS.ADD_PAGE_BUTTON);
      this.websiteTourService.removeVisibleItem(KEYS.ADD_PORTFOLIO_BUTTON);

      if (isImageManager) {
        this.websiteTourService.addVisibleLocation(TOUR_KEYS.SIDEBAR_IMAGE_MANAGER);
        this.websiteTourService.addVisibleItem(KEYS.ADD_PORTFOLIO_BUTTON);
      }

      if (isPageEditor) {
        this.websiteTourService.addVisibleLocation(TOUR_KEYS.SIDEBAR_PAGES);
        this.websiteTourService.addVisibleItem(KEYS.ADD_PAGE_BUTTON);
      }
    });

    this.initImageManagerTabs();
  }

  private initImageManagerTabs(): void {
    const educatorImageManagerPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.EDUCATOR_IMAGE_MANAGER,
    };

    const studentImageManagerPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT_IMAGE_MANAGER,
    };

    this.permissionsService.isUserHasPermissionsObservable([educatorImageManagerPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isEducatorImageManager = isAllowed;

      if (this.isEducatorImageManager) {
        return;
      }
  
      this.educationImageManagerService.setActiveTab('user', { force: true });
    });

    this.permissionsService.isUserHasPermissionsObservable([studentImageManagerPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isStudentImageManager = isAllowed;

      if (this.isStudentImageManager) {
        return;
      }
  
      this.studentImageManagerService.setActiveTab('user', { force: true });
    });
  }

  public ngOnInit(): void {
    this.websitesService.isActiveWebsiteRelatedToUserSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isActiveWebsiteRelatedToUser: boolean) => {
      this.isActiveWebsiteRelatedToUser = isActiveWebsiteRelatedToUser;

      this.initMyWebsiteTabText();
    });

    this.nodesService.isNodeCanBeDroppedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isNodeCanBeDropped: boolean) => {
      if (isNodeCanBeDropped === this.isNodeCanBeDropped) return;

      this.isNodeCanBeDropped = isNodeCanBeDropped;

      this.cdr.detectChanges();
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.isOriginalHomePageExists = !!nodes && nodes.findIndex((node: NodeModel) => node.isHomePage && node.type === 'H') !== -1;

      this.cdr.detectChanges();
    });

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.isBothMenus = template && template.menuType === 'BOTH';
    });

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPageBlocked: boolean) => {
      this.isPageBlocked = isPageBlocked;

      this.cdr.detectChanges();
    });

    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      this.activeTeacherTab = key;
    });

    this.studentImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: StudentImageManagerTabs) => {
      this.activeStudentTab = key;
    });
  }

  private initMyWebsiteTabText(): void {
    this.myWebsiteTabText = this.isActiveWebsiteRelatedToUser ? 'MY WEBSITE' : 'CLASS SITE';
  }

  public onLoadingStart(pageType: string): void {
    this.partsLoadingData[pageType].isLoading = true;
  }

  public onLoadingFinish(pageType: string): void {
    this.partsLoadingData[pageType].isLoading = true;
  }

  public onNodesLoad({ isEmpty, pageType }: { pageType: string, isEmpty: boolean }): void {
    this.partsLoadingData[pageType].isEmpty = isEmpty;
  }

  public openAddPageModal(): void {
    if (this.isPageBlocked) return;

    this.pagesService.parentNodeSubject.next(null);
    
    this.modalsService.open(this.addNodeModalService.id);
  }

  public onRestoreOriginalHomepageClick(): void {
    this.originalHomePageSetupModalService.open();
  }

  public goToTeacherTab(key: EducatorImageManagerTabs): void {
    if (!this.isActiveWebsiteRelatedToUser && key === 'user') {
      this.imageManagerChangeWebsiteToPersonalModalService.open();

      return;
    }

    this.educationImageManagerService.setActiveTab(key, { unlock: true });
  }
  
  public goToStudentTab(key: StudentImageManagerTabs): void {
    if (!this.isActiveWebsiteRelatedToUser && key === 'user') {
      this.imageManagerChangeWebsiteToPersonalModalService.open();

      return;
    }

    this.studentImageManagerService.setActiveTab(key, { unlock: true });
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(this.isImageManager ? TOUR_KEYS.SIDEBAR_IMAGE_MANAGER : TOUR_KEYS.SIDEBAR_PAGES);
    this.websiteTourService.removeVisibleItem(this.isImageManager ? KEYS.ADD_PORTFOLIO_BUTTON : KEYS.ADD_PAGE_BUTTON);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
