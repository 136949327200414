import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {INewReviewsData} from '../../../../models/education/students/images/reviews/i-new-reviews-data.model';

@Injectable()
export class PortfoliosHttpService {
  private model = 'portfolios';

  constructor(
    private http: HttpClient,
  ) {}

  public fetchList(): Observable<any> {
    return this.http.get(`api/${this.model}`);
  }

  public fetchRecent() {
    return this.http.get(`api/${this.model}/recent`);
  }

  public fetchNewReviewsData({ websiteId }: { websiteId: number }): Observable<INewReviewsData> {
    const params: HttpParams = new HttpParams().set('websiteId', `${websiteId}`);

    return this.http.get<INewReviewsData>(`api/${this.model}/new-reviews-data`, { params });
  }

  public reorder({ id }: { id: number }) {
    return this.http.put(`api/${this.model}/${id}/order`, null);
  }
}
