import {Component, Input} from '@angular/core';

import {SidebarContentList} from "../../models/sidebar/content/list/list.model";

@Component({
  selector: 'app-vs-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  public selectedTabId: string; // 'id' property from tabsData, probably
  private tabsData: SidebarContentList[]; // here should be all tabs data

  private get selectedTab() {
    return null;
    //return this.tabsData.find(tab => tab.id === this.selectedTabId); // BUT find is not found. Probably, due to ES is set to version 5 in TS
  }
}
