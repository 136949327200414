import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import * as changeCase from 'change-case';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {

  transform(obj: Object, args?: any): any {
    let map = [];
    for (let key in obj) {
      map.push({
        key: key,
        obj: obj[key]
      });
    }
    return map;
    
  }

}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], key: string, value: any): any {
    if(!items) return [];
    return  items.filter(item => item[key] == value);
  }

}

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: any) {
    if(value)
      return value.charAt(0).toUpperCase() + value.slice(1);

    return value;
  }

}

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: 'base64png'
})
export class Base64PngPipe implements PipeTransform {
  transform(val) {
    return `data:image/png;base64,${val}`;
  }
}

@Pipe({
  name: 'fontWeight'
})
export class FontWeightPipe implements PipeTransform {

  static WEIGHTS = {
    100: 'thin',
    200: 'extra light',
    300: 'light',
    400: 'normal',
    500: 'medium',
    600: 'semi bold',
    700: 'bold',
    800: 'extra bold',
    900: 'heavy',
  };

  transform(val) {
    return FontWeightPipe.WEIGHTS[val] || val;
  }

}

@Pipe({
  name: 'changeCase'
})
export class ChangeCasePipe implements PipeTransform {

  transform(value: string, targetCase: string): any {
    const converter = changeCase[targetCase];

    if(!converter) throw new Error('ChangeCasePipe: Case not found');

    return converter(value);
  }

}
