import {Injectable} from '@angular/core';

import {BehaviorSubject, Subscription, Observable} from 'rxjs';

import {WebsitesHttpService} from '../interaction/http/websites/websites-http.service';
import {NavigationService} from '../../../services/navigation.service';

import {ExclamationIconInfoModel} from '../../models/exclamation-icon-info/exclamation-icon-info.model';


@Injectable()
export class WebsitesExclamationIconService {
  public exclamationIconDataSubject: BehaviorSubject<ExclamationIconInfoModel> = new BehaviorSubject<ExclamationIconInfoModel>(null);
  public exclamationIconButtonVisibilitySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isImageManager: boolean;

  private exclamationButtonFetchDebounced;

  constructor(private httpService: WebsitesHttpService,
              private navigationService: NavigationService) {
    this.navigationService.isImageManagerSubject.subscribe((isImageManager: boolean) => {
      this.isImageManager = isImageManager;

      if (!this.isImageManager) return;

      this.fetchIsExclamationIconVisible();
      this.fetchIsExclamationIconButtonVisible();
    });
  }

  public fetchIsExclamationIconVisible(): Subscription {
    return this.httpService.fetchIsExclamationIconVisible().subscribe((isExclamationIconVisible: boolean) => {
      this.exclamationIconDataSubject.next({ isVisible: isExclamationIconVisible });
    });
  }

  public fetchIsExclamationIconButtonVisible(): Subscription {
    return this.httpService.fetchIsExclamationIconButtonVisible().subscribe((isExclamationIconButtonVisible: boolean) => {
      this.exclamationIconButtonVisibilitySubject.next(isExclamationIconButtonVisible);
    });
  }

  public setIsExclamationIconVisible(isExclamationIconVisible: boolean): Observable<boolean> {
    return this.httpService.setIsExclamationIconVisible(isExclamationIconVisible);
  }
}
