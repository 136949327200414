import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTE} from './constants';

export class IsScalableTextModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    this.element = element && <HTMLElement>element.closest(`[data-scalable-text="true"]`);

    this.isEnabled = !!this.element;

    if (!this.isEnabled) {
      this.value = null;

      return;
    }

    this.onChange(this.element.getAttribute(ATTRIBUTE) === 'true');
  }

  public onChange(value: boolean): void {
    this.value = value;
    this.element.setAttribute(ATTRIBUTE, value.toString());
  }
}
