import {DomainModel} from './domain.model';

export class DomainDto {
  constructor(public DomainID?: number,
              public DomainName?: string,
              public CaptchaKey?: string,
              public CaptchaSecretKey?: string) {}

  public static normalize(res: DomainDto): DomainModel {
    if (!res) return null;

    return new DomainModel(
      res.DomainID,
      res.DomainName,
      res.CaptchaKey,
      res.CaptchaSecretKey,
    );
  }

  public static toRequest(model: DomainModel): DomainDto {
    if (!model) return null;

    return new DomainDto(
      model.id,
      model.name,
      model.key,
      model.secretKey,
    );
  }
}
