import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-dashboard-section-wrapper',
  templateUrl: './dashboard-section-wrapper.component.html',
  styleUrls: ['./dashboard-section-wrapper.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class DashboardSectionWrapperComponent implements AfterViewInit {
  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  @Input() title: string;
  @Input() collapsedByDefault: boolean;
  @Input() collapsible: boolean = true;

  public isCollapsed: boolean = false;
  public isCollapsedByDefault: boolean = false;

  public maxHeight: number = 0;

  public ngAfterViewInit(): void {
    this.isCollapsedByDefault = this.collapsedByDefault;
    this.isCollapsed = this.isCollapsedByDefault;
  }

  public toggleCollapsed(): void {
    this.isCollapsedByDefault = false;
    this.isCollapsed = !this.isCollapsed;
  }
}
