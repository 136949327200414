import {IStyleOption} from '../option/i-style-option';

import {DEFAULT_VALUE, PROPERTY} from './constants';

export class SpaceBetweenColumnsModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: string = computedStyles.getPropertyValue(PROPERTY);

    return this.setValue(value);
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.setValue(`${DEFAULT_VALUE}`);
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    const numberValue: number = Number.parseInt(value);

    this.value = Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue;

    this.element.style.setProperty(PROPERTY, `${this.value}px`);
  }
}
