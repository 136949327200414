import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {AdminService} from '../admin.service';
import {AuthService} from '../../../../auth/auth.service';
import {NavigationService} from '../../../../services/navigation.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {RoutingService} from '../../../../core/services/routing/routing.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import { MessageModalService } from '../../../../../app/services/message-modal.service';

import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {AccountDto} from '../../../../core/models/accounts/account.dto';
import {CustomerFilterOptions} from './customer-filter/customer-filter-options';
import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

import {SORT_KEYS} from './constants';

@Component({
  selector: 'app-admin-customer-list',
  templateUrl: './admin-customer-list.component.html',
  styleUrls: ['./admin-customer-list.component.scss']
})
export class AdminCustomerListComponent implements OnInit, OnDestroy {
  private key = 'AdminCustomerListComponent';

  DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

  customers: Array<any> = [];

  currentPage = 1;
  pageCount: number;

  customersPerPage = 15;

  public customerFilterOptions: CustomerFilterOptions;

  public sortBy: string = SORT_KEYS.ID;
  public isSortUp: boolean = true;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private loginErrorModalHeader = {
    text: 'Error on login',
    className: 'error-header',
  };

  private loginErrorModalButtons = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: () => this.messageModalService.close(),
    },
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get sortKeys() {
    return SORT_KEYS;
  }

  constructor(private adminService: AdminService,
              private cdr: ChangeDetectorRef,
              private authService: AuthService,
              private websitesService: WebsitesService,
              private modalsService: ModalsService,
              private navigationService: NavigationService,
              private routingService: RoutingService,
              private messageModalService: MessageModalService,
              private loaderService: ContentLoaderService) {
    this.fetchCustomers();
  }

  public ngOnInit(): void {
    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(modalsStatus => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public handleFilterSearch(options: CustomerFilterOptions): void {
    this.customerFilterOptions = options;

    this.currentPage = 1;

    this.fetchCustomers();
  }

  public handlePageChange(newPage): void {
    this.currentPage = newPage;
    this.fetchCustomers();
  }

  public enablePublish(): Subscription {
    this.loaderService.show(this.key);

    return this.adminService.enablePublishButtonForActiveWebsites().pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(() => {});
  }

  onLogin(customer): Subscription {
    return this.adminService.loginAsUser(customer.Id).pipe(
      catchError(e => {
        console.error(e);

        this.messageModalService.addMessage(e.error.message, this.loginErrorModalHeader, this.loginErrorModalButtons);

        return throwError(() => e);
      })
    ).subscribe((user: AccountDto) => {
      this.authService.setUser(user);
  
      const unsubscribe: Subject<boolean> = new Subject<boolean>();
  
      this.websitesService.activeWebsiteSubject.pipe(takeUntil(unsubscribe)).subscribe((website: WebsiteModel) => {
        if (!website || website.id !== user.ActiveWebsiteID) return;
  
        unsubscribe.next(true);
        unsubscribe.complete();
  
        return website.isSetupCompleted ? this.navigationService.toHomePage({ isSidebarClosed: true }) : this.routingService.toSetup();
      });
    });
  }

  public sort(column: string): void {
    const oldSortBy: string = this.sortBy;

    this.sortBy = column;
    this.isSortUp = oldSortBy === this.sortBy ? !this.isSortUp : true;

    this.fetchCustomers();
  }

  private fetchCustomers(): Subscription {
    this.loaderService.show(this.key);

    return this.adminService.fetchCustomers(this.customerFilterOptions, this.customersPerPage, this.currentPage, this.sortBy, this.isSortUp).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe((result: { data, pagination: { total } }) => {
      this.customers = result.data;
      this.pageCount = result.pagination.total;
    });
  }
}
