import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {WebsiteTourService} from '../../../../core/services/website-tour/website-tour.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';
import {AuthService} from '../../../../auth/auth.service';
import {NavigationService} from '../../../../services/navigation.service';
import {SidebarSectionsService} from '../../../../services/sidebar-sections.service';

import {IButtonModel} from './i-button.model';
import {WebsiteTourProgressItemModel} from '../../../../core/models/website-tour-progress/item/website-tour-progress-item.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';

import {AppAnimations} from '../../../../app-animations';

import {KEYS_ORDER, ITEMS} from './constants';
import {KEYS, GROUPS_KEYS, GROUPS_ITEMS} from '../../../../core/services/website-tour/constants';

@Component({
  selector: 'app-instructional-tours',
  templateUrl: './instructional-tours.component.html',
  styleUrls: ['./instructional-tours.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class DashboardInstructionalToursComponent implements OnInit, OnDestroy {
  public items: { [key: string]: IButtonModel } = ITEMS();

  private account: AccountModel;

  private clickHandlers: { [key: string]: Function } = {
    [GROUPS_KEYS.TOOLBARS]: this.onToolbarsClick.bind(this),
    [GROUPS_KEYS.PAGES]: this.onPagesClick.bind(this),
    [GROUPS_KEYS.IMAGES]: this.onImagesClick.bind(this),
    [GROUPS_KEYS.BLOCKS]: this.onBlocksClick.bind(this),
    [GROUPS_KEYS.CUSTOMIZER]: this.onCustomizerClick.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get keys(): { [key: string]: string } {
    return KEYS;
  }

  public get groupsKeys(): { [key: string]: string } {
    return GROUPS_KEYS;
  }

  public get keysOrder(): string[] {
    return KEYS_ORDER;
  }

  constructor(private websiteTourService: WebsiteTourService,
              private navigationService: NavigationService,
              private sidebarSectionsService: SidebarSectionsService,
              private utilsService: UtilsService,
              private authService: AuthService) {
    this.reset = this.utilsService.debounce(this.reset.bind(this), 50);

    this.websiteTourService.initList();
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });
  }

  public reset(key: string): void {
    if (!GROUPS_ITEMS[key] || !this.account) return;

    this.items[key].isLoading = true;

    if (this.clickHandlers[key]) this.clickHandlers[key]();

    this.replay(key);

    setTimeout(() => {
      this.items[key].isLoading = false;
    }, 1500);
  }

  public replay(key: string): void {
    const allItems: WebsiteTourProgressItemModel[] = <WebsiteTourProgressItemModel[]>Object.values(this.websiteTourService.items);

    const currentGroupItems: WebsiteTourProgressItemModel[] = allItems.filter((item: WebsiteTourProgressItemModel) => GROUPS_ITEMS[key].includes(item.key));

    this.account.websiteTourProgress.isSkipped = false;

    currentGroupItems.forEach((item: WebsiteTourProgressItemModel) => {
      this.account.websiteTourProgress.progress[item.id] = false;
    });

    this.websiteTourService.setProgress(this.account.websiteTourProgress).add(() => {
      this.authService.updateCurrentUser(false);
    });
  }

  private onToolbarsClick(): void {
    this.navigationService.toHomePage({ sidebar: null, isSidebarClosed: true });
  }

  private onPagesClick(): void {
    this.navigationService.toHomePage({ sidebar: ['pages'], isSidebarClosed: false });
  }

  private onImagesClick(): void {
    this.navigationService.toFirstPortfolio();
  }

  private onBlocksClick(): void {
    this.navigationService.toHomePage({ sidebar: ['blocks'], isSidebarClosed: false });
  }

  private onCustomizerClick(): void {
    this.navigationService.toHomePage({ sidebar: null, isSidebarClosed: false });

    this.sidebarSectionsService.isCustomizerCollapsedSubject.next(false);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
