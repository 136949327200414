import {Injectable} from '@angular/core';

import {Observable, Subject, Subscription, BehaviorSubject} from 'rxjs';

import {EducationWebsitePortfoliosHttpService} from '../../../../../../interaction/http/education/teachers/institutions/classes/students/portfolios/education-website-portfolios-http.service';

import {PortfolioModel} from '../../../../../../../models/portfolios/portfolio.model';
import {NodeModel} from '../../../../../../../models/nodes/node.model';

@Injectable()
export class EducationWebsiteExhibitionsService {
  public listSubject: BehaviorSubject<PortfolioModel[]> = new BehaviorSubject<PortfolioModel[]>(null);
  
  public submitPageCreateSubject: Subject<NodeModel> = new Subject<NodeModel>();
  public errorSubject: Subject<string> = new Subject<string>();

  constructor(private httpService: EducationWebsitePortfoliosHttpService) {
  }

  public fetchList(institutionId: number, classId: number, websiteId: number): Subscription {
    return this.httpService.getPortfolios(institutionId, classId, websiteId).subscribe((res: PortfolioModel[]) => {
      this.listSubject.next(res);
    });
  }

  public create(institutionId: number, classId: number, websiteId: number, node: NodeModel): Observable<NodeModel> {
    return this.httpService.create(institutionId, classId, websiteId, node);
  }

  public updateOne(institutionId: number, classId: number, websiteId: number, exhibitionId: number, exhibition: PortfolioModel): Observable<any> {
    return this.httpService.updateOne(institutionId, classId, websiteId, exhibitionId, exhibition);
  }

  public deleteOne(institutionId: number, classId: number, websiteId: number, exhibitionId: number): Subscription {
    return this.httpService.deleteOne(institutionId, classId, websiteId, exhibitionId).subscribe(() => {});
  }
}
