import {StyleOptionModel} from './style-option.model';
import {StyleOptionTypeDto} from './type/style-option-type.dto';
import {StyleOptionUnitDto} from './unit/style-option-unit.dto';

export class StyleOptionDto {
  constructor(
    public Id: number,
    public OptionKey: string,
    public StyleName: string,
    public Text: string,
    public Min: number,
    public Max: number,
    public TypeId: number,
    public UnitId: number,
    public TypeData: StyleOptionTypeDto,
    public UnitData: StyleOptionUnitDto,
  ) {}

  public static normalize(res: StyleOptionDto): StyleOptionModel {
    if (!res) return null;

    return new StyleOptionModel(
      res.Id,
      res.OptionKey,
      res.StyleName,
      res.Text,
      res.Min,
      res.Max,
      res.TypeId,
      res.UnitId,
      StyleOptionTypeDto.normalize(res.TypeData),
      StyleOptionUnitDto.normalize(res.UnitData),
    );
  }
}
