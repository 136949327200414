import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Observable, Subject, combineLatest} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import * as moment from 'moment';

import {AdminEducationClassesService} from '../../../../../../core/services/admin/education/classes/admin-education-classes.service';
import {AdminEducationInstitutionsService} from '../../../../../../core/services/admin/education/institutions/admin-education-institutions.service';

import {EducationNewClassModel} from '../../../../../../core/models/education/classes/education-new-class.model';
import {EducationInstitutionModel} from '../../../../../../core/models/education/institutions/education-institution.model';
import {NestedSelectOption} from '../../../../../../core/models/select/nested/nested-select-option.model';
import {EducationClassModel} from '../../../../../../core/models/education/classes/education-class.model';

@Component({
  selector: 'app-admin-education-edit-class-modal',
  templateUrl: './edit-class-modal.component.html',
  styleUrls: ['./edit-class-modal.component.scss']
})
export class AdminEducationEditClassModalComponent implements OnInit, OnDestroy {
  @Output() closeHandler = new EventEmitter<void>();

  public classItem: EducationNewClassModel = null;

  public institutions: EducationInstitutionModel[];
  public institutionsOptions: NestedSelectOption[];

  public error: string = '';

  public isSignUpCodeFieldFocused: boolean = false;
  public isEndDateFieldFocused: boolean = false;

  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: AdminEducationClassesService,
              private insitutionsService: AdminEducationInstitutionsService) {
    this.insitutionsService.fetchAll();
  }

  public ngOnInit(): void {
    const detailsObservable: Observable<EducationClassModel> = this.service.classDetailsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(Boolean)
    );

    const institutionsObservable: Observable<EducationInstitutionModel[]> = this.insitutionsService.institutionsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((list: EducationInstitutionModel[]) => !!list && list.length > 0)
    );

    combineLatest([
      detailsObservable,
      institutionsObservable,
    ]).subscribe(([classItem, institutions]: [EducationClassModel, EducationInstitutionModel[]]) => {
      this.initClass(classItem);
      this.initInstitutions(institutions);
    });
  }

  private initClass(item: EducationClassModel): void {
    this.classItem = new EducationNewClassModel();

    this.classItem.id = item.id;
    this.classItem.name = item.name;
    this.classItem.number = item.number;
    this.classItem.signUpCode = item.signUpCode;
    this.classItem.description = item.description;
    this.classItem.institutionId = item.institutionId;
    this.classItem.endDate = moment(item.endDate).format('YYYY-MM-DD');
  }

  private initInstitutions(institutions: EducationInstitutionModel[]): void {
    this.institutions = institutions;

    if (!this.classItem.institutionId) return;

    this.institutionsOptions = this.institutions.map((item: EducationInstitutionModel) => {
      return new NestedSelectOption(
        item.name,
        `${item.id}`,
        void 0,
        void 0,
        this.classItem.institutionId === item.id,
      );
    });
  }

  public onInstitutionSelect(options: NestedSelectOption[]): void {
    const institute: NestedSelectOption = options.find((item: NestedSelectOption) => item.isSelected);
    
    this.classItem.institutionId = institute ? Number.parseInt(institute.value) : void 0;
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.key !== 'Enter') return;

    this.submit();
  }

  public onSignUpCodeFieldFocus(): void {
    this.isSignUpCodeFieldFocused = true;
  }

  public onSignUpCodeFieldBlur(): void {
    this.isSignUpCodeFieldFocused = false;
  }

  public onEndDateFieldFocus(): void {
    this.isEndDateFieldFocused = true;
  }

  public onEndDateFieldBlur(): void {
    this.isEndDateFieldFocused = false;
  }

  public submit(): void {
    this.classItem.validate();

    if (!this.classItem.isValid) return;

    this.isLoading = true;
    
    this.service.update(new EducationClassModel(
      this.classItem.id,
      this.classItem.institutionId,
      void 0,
      void 0,
      void 0,
      void 0,
      this.classItem.name,
      this.classItem.number,
      this.classItem.signUpCode,
      this.classItem.description,
      void 0,
      this.classItem.endDate,
    )).subscribe((err: string) => {
      if (!err) {
        this.closeHandler.emit();

        return;
      }

      this.error = err;

      this.isLoading = false;
    });
  }

  public cancel(): void {
    this.closeHandler.emit();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
