// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.security-settings {
  padding: 30px 25px;
}
.security-settings .security-settings-header {
  font-size: 26px;
  line-height: normal;
  color: #333333;
}
.security-settings .security-settings-description {
  padding: 12px 0;
  line-height: normal;
  font-size: 14px;
}
.security-settings .security-settings-description p {
  margin-bottom: 0;
}
.security-settings .security-settings-options {
  line-height: 1.4em;
  text-decoration: underline;
}
.security-settings .security-settings-options .option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 10px;
}
.security-settings .security-settings-options .option .checkbox {
  margin-right: 10px;
}
.security-settings .security-settings-options .copyright-notice {
  margin-left: 22px;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/settings/security-settings/security-settings.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AADJ;AAIE;EACE,eAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAME;EACE,kBAAA;EACA,0BAAA;AAJJ;AAMI;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAJN;AAMM;EACE,kBAAA;AAJR;AAQI;EACE,iBAAA;AANN","sourcesContent":["\n\n.security-settings {\n  padding: 30px 25px;\n\n  .security-settings-header {\n    font-size: 26px;\n    line-height: normal;\n    color: #333333;\n  }\n\n  .security-settings-description {\n    padding: 12px 0;\n    line-height: normal;\n    font-size: 14px;\n\n    p {\n      margin-bottom: 0;\n    }\n  }\n\n  .security-settings-options {\n    line-height: 1.4em;\n    text-decoration: underline;\n\n    .option {\n      display: flex;\n      align-items: center;\n      cursor: pointer;\n      padding-top: 10px;\n\n      .checkbox {\n        margin-right: 10px;\n      }\n    }\n\n    .copyright-notice {\n      margin-left: 22px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
