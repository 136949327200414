import {Component, Input} from '@angular/core';

import {DeleteNodeModalService} from '../../../services/modals/delete-node/delete-node-modal.service';
import { TreeItemModel } from '../../../../core/models/nodes/tree-item.model';

@Component({
  selector: 'app-delete-node-modal',
  templateUrl: './delete-node-modal.component.html',
  styleUrls: ['./delete-node-modal.component.scss'],
})
export class DeleteNodeModalComponent {
  public node: TreeItemModel;

  @Input() id: string;

  public get modalHeader() {
    return {
      text: this.node ? `Delete ${this.node.data.typeLong}?` : '',
      className: 'warning-header',
    };
  }

  constructor(private deleteNodeModalService: DeleteNodeModalService) {
    this.deleteNodeModalService.onNodeSubject.subscribe(node => {
      this.node = node;
    });
  }

  public removeNode() {
    this.deleteNodeModalService.removeNode(this.node);
  }

  public closeModal() {
    this.deleteNodeModalService.close();
  }
}
