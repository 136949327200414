import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {VideoDto} from '../../../../models/videos/video.dto';
import {VideoModel} from '../../../../models/videos/video.model';

@Injectable()
export class VideosHttpService {
  private model = 'videos';

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<VideoModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: VideoDto[]) => res.map(item => VideoDto.normalize(item)))
    );
  }

  public finishUploading(video: VideoModel, fileName: string): Observable<VideoModel> {
    return this.http.post(`api/${this.model}`, { id: video.id, fileName }).pipe(
      map((res: VideoDto) => VideoDto.normalize(res))
    );
  }

  public deleteMany(ids: number[]): Observable<any> {
    return this.http.request('delete', `api/${this.model}`, { body: { ids }, responseType: 'json' });
  }

  public updateName(id: number, name: string): Observable<any> {
    return this.http.request('put', `api/${this.model}/${id}/name`, { body: { name }, responseType: 'text' });
  }
}
