import * as moment from 'moment';

import {AccountModel} from './account.model';
import {AccountLimitsDto} from './limits/account-limits.dto';
import {WebsiteTourProgressAccountDataModel} from '../website-tour-progress/account-data/website-tour-progress-account-data.model';
import {PreLaunchProgressAccountDataModel} from '../pre-launch-progress/account-data/pre-launch-progress-account-data.model';

import {TIME_FORMATTER} from './constants';

export class AccountDto {
  constructor(public Id?: number,
              public ActiveWebsiteID?: number,
              public Container?: number,
              public Email?: string,
              public FirstName?: string,
              public LastName?: string,
              public Company?: string,
              public LoggedInAs?: number,
              public Address1?: string,
              public Address2?: string,
              public City?: string,
              public State?: string,
              public Zip?: string,
              public Country?: string,
              public PhoneDay?: string,
              public Fax?: string,
              public Provence?: string,
              public MailChimpKey?: string,
              public ReferralOther?: string,
              public WebsiteTourProgress?: string,
              public PreLaunchProgress?: string,
              public CreatedAt?: string,
              public UpdatedAt?: string,
              public LoginAt?: string,
              public LastSeenAt?: string,
              public EmailVerificationCount?: number,
              public SessionKey?: string,
              public CustomSubscriptionId?: number,
              public PlanSupportTimeMin?: number,
              public AddOnSupportTimeMin?: number,
              public TotalAddOnSupportTime?: number,
              public SupportVideoLink?: string,
              public Admin?: boolean,
              public IsECommerce?: boolean,
              public IsEmailVerified?: boolean,
              public IsTrialSubscription?: boolean,
              public IsSubscriptionPaid?: boolean,
              public IsSubscriptionExpired?: boolean,
              public IsNewVersion?: boolean,
              public PrimaryMediumId?: number,
              public IsUnderAdmin?: boolean,
              public SocketKey?: string,
              public IsUserImported?: boolean,
              public IsImageFilesRemoveEnabled?: boolean,
              public IsMessagesReviewEnabled?: boolean,
              public IsPublishInfoModalVisible?: boolean,
              public IsFullImageLabelAccessGranted?: boolean,
              public Limits?: AccountLimitsDto) {}

  public static normalize(res: AccountDto): AccountModel {
    if (!res) return null;

    const lastSeenAt = moment(res.LastSeenAt);

    return new AccountModel(
      res.Id,
      res.ActiveWebsiteID,
      res.Container,
      res.Email,
      res.FirstName,
      res.LastName,
      res.Company,
      res.LoggedInAs,
      res.Address1,
      res.Address2,
      res.City,
      res.State,
      res.Zip,
      res.Country,
      res.PhoneDay,
      res.Fax,
      res.Provence,
      res.MailChimpKey,
      WebsiteTourProgressAccountDataModel.fromJson(res.WebsiteTourProgress),
      PreLaunchProgressAccountDataModel.fromJson(res.PreLaunchProgress),
      res.CreatedAt,
      res.UpdatedAt,
      res.LoginAt,
      lastSeenAt.isValid() ? lastSeenAt.format(TIME_FORMATTER) : 'n/a',
      res.EmailVerificationCount,
      res.SessionKey,
      res.CustomSubscriptionId,
      res.PlanSupportTimeMin,
      res.AddOnSupportTimeMin,
      res.TotalAddOnSupportTime,
      res.SupportVideoLink,
      res.Admin,
      res.IsECommerce,
      res.IsEmailVerified,
      res.IsTrialSubscription,
      res.IsSubscriptionPaid,
      res.IsSubscriptionExpired,
      res.IsUnderAdmin,
      res.IsNewVersion,
      res.PrimaryMediumId,
      res.SocketKey,
      res.IsUserImported,
      res.IsImageFilesRemoveEnabled,
      res.IsMessagesReviewEnabled,
      res.IsPublishInfoModalVisible,
      res.IsFullImageLabelAccessGranted,
      AccountLimitsDto.normalize(res.Limits),
    );
  }
}
