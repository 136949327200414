import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';

const KEY = {
  ESC: 27
};

@Component({
  selector: 'app-editable-content-textarea',
  templateUrl: './editable-content-textarea.component.html',
  styleUrls: ['./editable-content-textarea.component.scss']
})
export class EditableContentTextareaComponent implements AfterViewInit {
  @Input() value;
  @Input() resize;
  @Output() valueChange = new EventEmitter();

  private lastSavedValue;

  private keyPressHandlers = {
    [KEY.ESC]: () => {
      this.save();
    },
  };

  public isEditing: boolean = false;

  constructor() {}

  onClick(input: HTMLElement) {
    this.setEditing(true);
    input.focus();
  }

  handleKeyDown(event) {
    if (this.keyPressHandlers[event.keyCode]) this.keyPressHandlers[event.keyCode]();
  }

  save() {
    this.setEditing(false);

    if (this.lastSavedValue === this.value) return;

    this.lastSavedValue = this.value;
    this.valueChange.emit(this.value);
  }

  setEditing(value) {
    this.isEditing = value;
  }

  ngAfterViewInit(): void {
    this.lastSavedValue = this.value;
  }
}
