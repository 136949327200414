export const ATTRIBUTES = {
  LOADER_KEY: 'data-initial-overlay-click-behavior',
};

export const VALUES = [
  {
    key: 'REGULAR_SCREEN',
    label: 'Regular Screen',
  },
  {
    key: 'FULL_SCREEN',
    label: 'Full Screen',
  },
];

export const DEFAULT_KEY = VALUES[0].key;
