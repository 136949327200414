import {Component, Output, Input, EventEmitter, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-vs-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TogglerComponent),
    multi: true,
  }]
})
export class TogglerComponent implements ControlValueAccessor {
  private isCheckboxChecked: boolean;

  @Output() isCheckedChange = new EventEmitter();
  @Output() changeHandler = new EventEmitter();

  @Input() tooltip: string = '';
  @Input() isDisabled: boolean = false;

  public onChange = (value: boolean) => {};
  public onTouched = () => {};

  @Input()
  public get isChecked() {
    return this.isCheckboxChecked;
  }
  public set isChecked(value) {
    this.isCheckboxChecked = value;

    this.writeValue(this.isCheckboxChecked);

    this.isCheckedChange.emit(this.isCheckboxChecked);
    this.changeHandler.emit(this.isCheckboxChecked);
  }

  public onClick(): void {
    this.isChecked = !this.isCheckboxChecked;
  }

  public writeValue(value: boolean): void {
    this.isCheckboxChecked = value;
    this.onChange(this.isCheckboxChecked);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
