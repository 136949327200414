import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {NativeSetupOptionModel} from '../option/native-setup-option.model';

import {PROPERTY} from './constants';

export class FontSizeModel extends NativeSetupOptionModel {
  constructor(
    public key: string,
    public isEnabled: boolean = true,
  ) {
    super(key, PROPERTY, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions) {
    this.element = element;

    if (!this.element) return;

    this.initValue();
  }

  public onChange(value: string) {
    this.value = value;

    this.element.style.fontSize = this.handleValue(value);
  }

  private handleValue(value: string): string {
    if (!value) {
      return value;
    }

    return value.endsWith('px') ? value : `${value}px`;
  }

  public reset(): void {
    this.element.style.removeProperty(PROPERTY);

    this.initValue();
  }

  private initValue(): void {
    this.value = parseInt(window.getComputedStyle(this.element)[PROPERTY], 10);
  }
}
