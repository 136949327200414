import {IStyleOption} from '../../setup/option/i-style-option';
import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {DEFAULT_VALUE, PROPERTY} from './constants';

export class SpaceBetweenBlocksModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!element) return;

    this.isEnabled = element && element.classList.contains('innerWrapper');

    if (!this.isEnabled) return;

    this.element = element;

    const value = window.getComputedStyle(element).getPropertyValue(PROPERTY);

    return this.setValue(value);
  }

  public reset() {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(PROPERTY);

    this.setValue(window.getComputedStyle(this.element).getPropertyValue(PROPERTY));
  }

  public setValue(value: string) {
    if (!this.isEnabled || !this.element) return;

    const numberValue: number = Number.parseInt(value);

    this.value = Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue;

    this.element.style.setProperty(PROPERTY, `${this.value}px`);
  }
}
