import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {AppAnimations} from '../../../../app-animations';

import {EducatorSidebarSection, SECTIONS} from './constants';

@Component({
  selector: 'app-educator-admin-section',
  templateUrl: './educator-admin-section.component.html',
  styleUrls: ['./educator-admin-section.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class EducatorAdminSectionComponent implements OnInit {
  public selectedSection: string;

  public get sections(): EducatorSidebarSection[] {
    return Object.keys(SECTIONS).map(key => SECTIONS[key]);
  }

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const urlSection = params['section'];

      const foundSection = Object.keys(SECTIONS).find(section => SECTIONS[section].path.toLowerCase() === urlSection);

      this.selectedSection = SECTIONS[foundSection] ? SECTIONS[foundSection].path : urlSection;
    });
  }

  public selectSection(section: EducatorSidebarSection): void {
    this.selectedSection = section.path;

    this.router.navigate([
      '/app',
      {
        outlets: {
          primary: ['educator-admin', section.path],
          sidebar: ['educator-admin', section.sidebarPath],
        },
      },
    ]);
  }
}
