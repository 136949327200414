// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vs-sidebar-content {
  display: flex;
  padding: 5px;
}
.vs-sidebar-content .tab-header {
  font-size: 18px;
}
.vs-sidebar-content .description {
  font-size: 14px;
}
.vs-sidebar-content .list-header {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/sidebar/content/content.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;AADF;AAGE;EACE,eAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,eAAA;AAHJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.vs-sidebar-content {\n  display: flex;\n  padding: 5px;\n\n  .tab-header {\n    font-size: 18px;\n  }\n\n  .description {\n    font-size: 14px;\n  }\n\n  .list-header {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
