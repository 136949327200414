import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {PortfolioModel} from '../../../../../../../../../models/portfolios/portfolio.model';
import {PortfolioDto} from '../../../../../../../../../models/portfolios/portfolio.dto';
import {NodeModel} from '../../../../../../../../../models/nodes/node.model';

@Injectable()
export class EducationWebsitePortfoliosHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public getPortfolios(institutionId: number, classId: number, websiteId: number): Observable<PortfolioModel[]> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios`).pipe(
      map((res: PortfolioDto[]) => res.map((item: PortfolioDto) => PortfolioDto.normalize(item)))
    );
  }

  public create(institutionId: number, classId: number, websiteId: number, data: NodeModel): Observable<NodeModel> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios`, data).pipe(
      map((res: NodeModel) => NodeModel.normalize(res))
    );
  }

  public updateOne(institutionId: number, classId: number, websiteId: number, exhibitionId: number, data: PortfolioModel): Observable<any> {
    return this.http.put(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios/${exhibitionId}`, data);
  }

  public deleteOne(institutionId: number, classId: number, websiteId: number, exhibitionId: number): Observable<any> {
    return this.http.delete(`${this.prefix}/institutions/${institutionId}/classes/${classId}/websites/${websiteId}/portfolios/${exhibitionId}`);
  }
}
