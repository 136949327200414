
import {catchError, filter} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {Subject, throwError} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {SidebarService} from '../../core/services/sidebar/sidebar.service';
import {SidebarSectionsService} from '../../services/sidebar-sections.service';
import {NodesService} from '../../core/services/nodes/nodes.service';
import {ImageManagerService} from '../main/image-manager/image-manager.service';
import {WebsiteTourService} from '../../core/services/website-tour/website-tour.service';
import {PaymentSubscriptionsService} from '../../core/services/payment/subscriptions/payment-subscriptions.service';
import {AuthService} from '../../auth/auth.service';
import {NavigationService} from '../../services/navigation.service';

import {TabSettingsModel} from '../../core/models/sidebar/tabs-settings.model';
import {CategoryModel} from './categories.model';
import {SubscriptionModel} from '../../core/models/payment/subscriptions/subscription.model';
import {AccountModel} from '../../core/models/accounts/account.model';

import {ACCESS_KEYS, CATEGORIES_MAPPING, CATEGORIES, TOUR_KEY} from './constants';
import {KEYS} from '../../core/services/website-tour/constants';
import {DISABLED_TABS_DATA, KEYS as SIDEBAR_KEYS} from '../../core/services/sidebar/constants';

@Component({
  selector: 'app-sidebar-wrapper',
  templateUrl: './sidebar-short.component.html',
  styleUrls: ['./sidebar-short.component.scss'],
})
export class SidebarShortComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarShort') sidebarShort: ElementRef;

  public selectedTab: TabSettingsModel = null;
  public nodes;

  public categories: CategoryModel[] = [];

  public isSectionsCollapsed: boolean = false;
  public isCustomizerCollapsed: boolean = true;
  public isBlocked: boolean = true;
  public isWebsiteTourVisible: boolean = false;
  public isEducatorMode: boolean = true;

  private account: AccountModel;

  private currentSubscription: SubscriptionModel;

  private accessKey: string = ACCESS_KEYS.EMPTY;

  private isBlog: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private nodesService: NodesService,
    private imageManagerService: ImageManagerService,
    private websiteTourService: WebsiteTourService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private navigationService: NavigationService,
    private authService: AuthService,
    public sidebarService: SidebarService,
    public sidebarSectionsService: SidebarSectionsService,
  ) {
  }

  public ngOnInit(): void {
    this.websiteTourService.addVisibleLocation(TOUR_KEY);
    this.websiteTourService.addVisibleItem(KEYS.SIDEBAR);

    this.router.events.pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      const section = this.sidebarSectionsService.getSectionFromRoute(this.route);

      this.sidebarSectionsService.selectSectionCategory(section);
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
      
      this.initList();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;

      this.initList();
    });
    
    this.sidebarService.accessKeySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((accessKey: string) => {
      this.accessKey = accessKey;

      this.initList();
    });

    this.sidebarService.currentTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tab: TabSettingsModel) => {
      this.selectedTab = tab;

      this.cdr.detectChanges();
    });

    this.sidebarSectionsService.isCustomizerCollapsedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCollapsed: boolean) => {
      this.isCustomizerCollapsed = isCollapsed;

      this.cdr.detectChanges();
    });

    this.sidebarSectionsService.isSectionsCollapsedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCollapsed: boolean) => {
      this.isSectionsCollapsed = isCollapsed;

      this.cdr.detectChanges();
    });

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPageBlocked: boolean) => {
      this.isBlocked = isPageBlocked;

      this.cdr.detectChanges();
    });

    this.websiteTourService.isOpened.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpened: boolean) => {
      this.isWebsiteTourVisible = isOpened;

      this.cdr.detectChanges();
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(nodes => {
      this.nodes = nodes;
    });

    this.navigationService.isBlogPostEditingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isBlog = value;
    });
  }

  private initList(): void {
    this.categories = this.getList();
  }

  private getList(): CategoryModel[] {
    if (!CATEGORIES_MAPPING[this.accessKey]) {
      return [];
    }

    return CATEGORIES_MAPPING[this.accessKey].map((key: string) => CATEGORIES[key]);
  }

  public getIsCategoryDisabled(category: CategoryModel): boolean {
    if (this.isBlocked || !this.nodes) {
      return true;
    }

    if (this.isBlog) {
      return DISABLED_TABS_DATA[SIDEBAR_KEYS.BLOG].includes(category.section);
    }

    if (this.selectedTab) {
      return this.selectedTab.isDisabled || this.selectedTab.disabledTabs.includes(category.section);
    }

    return false;
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(TOUR_KEY);
    this.websiteTourService.removeVisibleItem(KEYS.SIDEBAR);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
