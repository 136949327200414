import {Injectable, NgZone} from '@angular/core';

import {EventsService} from '../interaction/events/events.service';
import {ModalsService} from '../../../shared/services/modals/modals.service';

import {EVENTS} from './constants';

@Injectable()
export class ContactBlockSetupService {
  public id = 'contact-block-setup';

  constructor(private eventsService: EventsService,
              private modalsService: ModalsService,
              private ngZone: NgZone) {
    this.eventsService.addFrameListener(EVENTS.CONTACT_BLOCK_SETUP, (e: CustomEvent) => {
      this.ngZone.run(() => this.modalsService.open(this.id, e.detail));
    });
  }
}
