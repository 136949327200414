import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';

import {NodeModel} from '../../../../core/models/nodes/node.model';

@Injectable()
export class OriginalHomePageSetupModalService {
  public readonly id = 'original-home-page-setup-modal';

  public homePage: NodeModel;

  public isOpened: boolean = false;

  public constructor(private modalsService: ModalsService,
                     private nodesService: NodesService) {
    this.nodesService.nodesSubject.subscribe((nodes: NodeModel[]) => {
      this.homePage = nodes ? nodes.find((node: NodeModel) => node.isHomePage) : null;
    });
  }

  public open(): void {
    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
