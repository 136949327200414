import {GoogleFontUsagePageDbo} from './google-font-usage-page.dbo';
import {GoogleFontUsageWebsiteModel} from './google-font-usage-website.model';

export class GoogleFontUsageWebsiteDbo {
  constructor(public websiteId?: number,
              public websiteTitle?: string,
              public blocks?: GoogleFontUsagePageDbo[]) {}

  public static normalize(res: GoogleFontUsageWebsiteDbo): GoogleFontUsageWebsiteModel {
    if (!res) return null;

    return new GoogleFontUsageWebsiteModel(
      res.websiteId,
      res.websiteTitle,
      res.blocks.map((block: GoogleFontUsagePageDbo) => GoogleFontUsagePageDbo.normalize(block)),
    );
  }
}
