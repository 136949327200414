import {AddOnModel} from './add-on.model';

export class AddOnDto {
  constructor(public Id?: string,
              public Key?: string,
              public Name?: string,
              public Amount?: number,
              public StripeProductId?: string,
              public StripePriceId?: string,
              public RecurringType?: string,
              public IncludedSupportTimeMin?: number,
              public SupportPlanDiscount?: number,
              public IsEnabled?: boolean,
              public IsPurchasable?: boolean,
              public IsHidden?: boolean) {}

  public static toRequest(res: AddOnModel): AddOnDto {
    if (!res) return null;

    return new AddOnDto(
      res.id,
      res.key,
      res.name,
      Number((res.amount * 100).toFixed()),
      res.stripeProductId,
      res.stripePriceId,
      res.recurringType,
      res.includedSupportTimeMin,
      res.supportPlanDiscount,
      res.isEnabled,
      res.isPurchasable,
      res.isHidden,
    );
  }
}
