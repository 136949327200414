import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {BehaviorSubject, Subject, Observable, throwError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';

import {MeasureUnitsService} from '../../../core/services/converters/measure-units/measure-units.service';
import {ImageManagerErrorModalService} from '../../../shared/services/modals/image-manager-error/image-manager-error-modal.service';

import {ImageProcessDto} from '../../../models/image-processes/image-process.dto';
import {ImageProcessModel} from '../../../models/image-processes/image-process.model';
import {ImageProcessTypeModel} from '../../../models/image-processes/image-process-type.model';
import {ImageProcessTypeDto} from '../../../models/image-processes/image-process-type.dto';
import {PortfolioDefaultsModel} from '../../../core/models/images/default/portfolio-defaults.model';
import {PortfolioDefaultsV1Dto} from '../../../core/models/images/default/portfolio-defaults-v1.dto';
import {ImageDataModel} from '../../../core/models/image-manager/counters/image-data.model';
import {ImageDto} from '../../../core/models/images/image.dto';
import {ImageModel} from '../../../core/models/images/image.model';
import {ImageLabelDto} from '../../../core/models/images/image-label/image-label.dto';
import {PortfolioDefaultsRequest} from '../../../core/models/images/default/portfolio-defaults.request';
import { IArtworkDimensionsString } from '../../../core/models/artwork-dimensions/i-artwork-dimensions-string';
import { IArtworkDimensionsNumber } from '../../../core/models/artwork-dimensions/i-artwork-dimensions-number';
import { ImagePriceModel } from '../../../core/models/images/price/image-price.model';

import {LIBRARY_ID} from '../../constants';

@Injectable()
export class ImageManagerService {
  public onImageSelectSubject: BehaviorSubject<ImageModel> = new BehaviorSubject<ImageModel>(null);
  public onImageClickSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public onImageReplaceSubject: BehaviorSubject<ImageModel> = new BehaviorSubject<ImageModel>(null);
  public imagesRemovingSubject: BehaviorSubject<ImageDataModel[]> = new BehaviorSubject<ImageDataModel[]>([]);
  public thumbSizeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(42);
  public viewSubject: BehaviorSubject<{ key: string, forceIndex?: number, forceSuggestionsView?: { userId: number } }> = new BehaviorSubject<{ key: string, forceIndex?: number, forceSuggestionsView?: { userId: number } }>({ key: 'thumbnails' });
  public isImagesMovingSubject: BehaviorSubject<{ [key: number]: boolean }> = new BehaviorSubject<{ [key: number]: boolean }>({});
  public isPageBlockedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onImagesForceReinit: Subject<boolean> = new Subject<boolean>();

  public allImages = [];

  constructor(
    private httpClient: HttpClient,
    private measureUnitsService: MeasureUnitsService,
    private imageManagerErrorModalService: ImageManagerErrorModalService
  ) {
  }

  getPortfolioImages(nodeId, portfolioId?): Observable<any> {
    if (nodeId === LIBRARY_ID) {
      return this.httpClient.get('/api/app/image-manager/library').pipe(map((images: ImageDto[]) => {
        return images.map((image: ImageDto) => {
          return ImageDto.normalize(image);
        });
      }));
    }

    const params = new HttpParams()
      .set('nodeId', `${nodeId}`)
      .set('portfolioId', `${portfolioId}`);

    return this.httpClient.get('/api/app/image-manager', { params }).pipe(map((images: ImageDto[]) => {
      return images.map((image: ImageDto) => {
        const model: ImageModel = ImageDto.normalize(image);

        if (model && model.prices) {
          model.prices = model.prices.map((price: ImagePriceModel) => <ImagePriceModel>this.convertToWebsiteMeasureUnit(price));
        }

        return model;
      });
    }));
  }

  getImageDetails(imageId): Observable<any> {
    return this.httpClient.get('/api/app/image-manager/details', { params: { imageid: imageId } });
  }

  saveImageDetails(imageLabel: ImageLabelDto): Observable<any> {
    return this.httpClient.put('/api/app/image-manager/details', imageLabel);
  }

  publishImage({ imageId, isPublish }): Observable<any> {
    return this.httpClient.put('/api/app/image-manager/publish', { imageid: imageId, value: isPublish });
  }

  savePortfolioDefaults(data: PortfolioDefaultsRequest): Observable<any> {
    return this.httpClient.put('/api/app/image-manager/defaults', this.convertToMainMeasureUnit(data));
  }

  getImageProcesses(): Observable<ImageProcessModel[]> {
    return this.httpClient.get('/api/app/image-manager/processes').pipe(map((imageProcessDtos: ImageProcessDto[]) => {
      return imageProcessDtos.map(imageProcessDto => {
        return ImageProcessDto.normalize(imageProcessDto);
      });
    }));
  }

  public deleteImageProcesses(data): Observable<{ notRemovedIds: number[] }> {
    return this.httpClient.request<{ notRemovedIds: number[] }>('delete', '/api/app/image-manager/processes', { body: data });
  }

  getImageProcessesTypes(): Observable<ImageProcessTypeModel[]> {
    return this.httpClient.get('/api/app/image-manager/processes/types').pipe(map((imageProcessTypeDtos: ImageProcessTypeDto[]) => {
      return imageProcessTypeDtos.map(imageProcessTypeDto => {
        return ImageProcessTypeDto.normalize(imageProcessTypeDto);
      });
    }));
  }

  public addNewProcess({ processId, processName, type, userId }: ImageProcessModel): Observable<ImageProcessModel> {
    const imageProcessDto = new ImageProcessDto(processId, processName, type, userId);

    return this.httpClient.post(`/api/app/image-manager/processes`, imageProcessDto).pipe(
      map((res: ImageProcessDto) => ImageProcessDto.normalize(res))
    );
  }

  reorderImages(data): Observable<any> {
    return this.httpClient.put('/api/app/image-manager/reorder', data);
  }

  public moveImage(data: { portfolioId: number, imageIds: number[] }): Observable<any> {
    data.portfolioId = data.portfolioId === LIBRARY_ID ? 0 : data.portfolioId;

    this.isPageBlockedSubject.next(true);

    return this.httpClient.put('/api/app/image-manager/move', data).pipe(
      catchError(e => {
        console.error(e);
  
        this.imageManagerErrorModalService.open({
          error: `Error on image move. Please, try again.`
        });

        return throwError(() => e);
      }),
      finalize(() => {
        this.isPageBlockedSubject.next(false);
      }),
    );
  }

  copyImage(data): Observable<any> {
    return this.httpClient.put('/api/app/image-manager/copy', data);
  }

  getRecycleBin(): Observable<any> {
    return this.httpClient.get('/api/app/image-manager/recycle-bin');
  }

  recycleImages(data: { websiteId: number, portfolioId: number, imagesIds: number[] }): Observable<any> {
    return this.httpClient.put('/api/app/image-manager/recycle-bin', data);
  }

  public recycleAllImages({ portfolioId }): Observable<any> {
    return this.httpClient.put(`/api/app/image-manager/${portfolioId}/all`, null);
  }

  deleteImages(data): Observable<any> {
    const params = new HttpParams().set('images', `${data}`);

    return this.httpClient.delete('/api/app/image-manager/recycle-bin', { params });
  }

  public deleteAllImages({ portfolioId }): Observable<any> {
    return this.httpClient.delete(`/api/app/image-manager/${portfolioId}/all`);
  }

  deleteImage(imageId): Observable<any> {
    return this.httpClient.delete(`/api/app/image-manager/${imageId}`);
  }

  getLibraryImages(): Observable<ImageModel[]> {
    return this.httpClient.get('/api/app/image-manager/library').pipe(map((images: ImageDto[]) => {
      return images.map((image: ImageDto) => {
        return ImageDto.normalize(image);
      });
    }));
  }

  getLibrarySize(): Observable<{ size: number }> {
    return this.httpClient.get<{ size: number }>('/api/app/image-manager/library-size').pipe(
      catchError(e => {
        console.dir(e);
        console.dir(`> err in library size fetch`);
        
        return throwError(() => e);
      })
    );
  }

  getInfoLinkVisibility(pageId: number): Observable<any> {
    return this.httpClient.get(`/api/app/image-manager/${pageId}/info-link-visibility`);
  }

  setInfoLinkVisibility(pageId: number, data: object): Observable<any> {
    return this.httpClient.put(`/api/app/image-manager/${pageId}/info-link-visibility`, data);
  }

  applyInquiryLinkTextToAllPortfolio(data: object) {
    return this.httpClient.put(`/api/app/image-manager/portfolios-default/inquiry-link-text`, data);
  }

  applyPurchaseTextToAllPortfolios(data: object) {
    return this.httpClient.put(`/api/app/image-manager/portfolios-default/purchase-text`, data);
  }

  public fetchPortfolioDefaults(nodeId: number): Observable<PortfolioDefaultsModel> {
    const params = new HttpParams().set('nodeid', `${nodeId}`);

    return this.httpClient.get('api/app/image-manager/defaults', { params }).pipe(
      map((res: PortfolioDefaultsV1Dto) => PortfolioDefaultsV1Dto.normalize(res)),
      map((res: PortfolioDefaultsModel) => this.convertToWebsiteMeasureUnit(res)),
    );
  }

  private convertToMainMeasureUnit(container: IArtworkDimensionsString): IArtworkDimensionsString {
    if (!container) {
      return null;
    }

    container.width = this.measureUnitsService.convertToMain(Number.parseFloat(container.width));
    container.height = this.measureUnitsService.convertToMain(Number.parseFloat(container.height));
    container.length = this.measureUnitsService.convertToMain(Number.parseFloat(container.length));
    container.matWidth = this.measureUnitsService.convertToMain(Number.parseFloat(container.matWidth));
    container.matHeight = this.measureUnitsService.convertToMain(Number.parseFloat(container.matHeight));

    return container;
  }

  public convertToWebsiteMeasureUnit(container: IArtworkDimensionsNumber): IArtworkDimensionsNumber {
    if (!container) {
      return null;
    }

    container.width = this.measureUnitsService.convertFromMain(container.width);
    container.height = this.measureUnitsService.convertFromMain(container.height);
    container.length = this.measureUnitsService.convertFromMain(container.length);
    container.matWidth = this.measureUnitsService.convertFromMain(container.matWidth);
    container.matHeight = this.measureUnitsService.convertFromMain(container.matHeight);

    return container;
  }

  public dispatchImagesMove(imagesId: number[], isMoving: boolean): void {
    try {
      const value: { [key: number]: boolean } = JSON.parse(JSON.stringify(this.isImagesMovingSubject.value));

      imagesId.forEach(id => {
        if (isMoving) {
          value[id] = true;
        } else {
          delete value[id];
        }
      }) ;

      this.isImagesMovingSubject.next(value);
    } catch (e) {
      console.error(e);
    }
  }
}
