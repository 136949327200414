import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-editor-loader',
  templateUrl: './editor-loader.component.html',
  styleUrls: ['./editor-loader.component.scss']
})
export class EditorLoaderComponent {
  _isLoading;

  @Input()
  set isLoading(value) {
    if (!value) {
      this.stopShowingActions();

    } else if (!this._isLoading) {
      this.currentActionIndex = 0;
      this.startShowingActions();
    }
    this._isLoading = value;
  }

  get isLoading() {
    return this._isLoading;
  }

  // TODO: add changing action names on changing loading event (saving, loading, preparing, ...)
  @Input() loadingEvent: string;

  currentActionIndex = 0;

  actions = [
    {title: '', time: 0},
    {title: 'Loading Page...', time: 0},
  ];

  currentAction: any;
  actionTimeout: any;

  constructor() {}

  startShowingActions() {
    const action = this.currentAction;

    clearTimeout(this.actionTimeout);
    this.actionTimeout = setTimeout(() => {
      this.startNextAction();
    }, action ? action.time : 0);
  }

  startNextAction() {
    if (this.currentActionIndex < this.actions.length - 1) {
      this.currentActionIndex++;

      // TODO: refactor
      if (this.loadingEvent === 'saving-blocks') {
        this.currentAction = { title: 'Saving changes...', time: 0 };
      } else {
        this.currentAction = this.actions[this.currentActionIndex];
      }

      this.startShowingActions();
    }
  }

  stopShowingActions() {
    clearTimeout(this.actionTimeout);
    this.currentActionIndex = this.actions.length - 1;
    this.currentAction = null;
  }
}
