import {IStyleOption} from '../option/i-style-option';
import {StyleOptionModel} from '../../settings/option/style-option.model';
import {IsContactInfoUsedModel} from './is-contact-info-used/is-contact-info-used.model';

import {KEYS, TYPES} from '../../../../services/styles/contact-block/constants';

export class ContactBlockSetupModel {
  public element: HTMLElement;

  private enabledStyleOptions: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public isContactInfoUsed: IsContactInfoUsedModel = new IsContactInfoUsedModel(KEYS.IS_CONTACT_INFO_USED, TYPES.ATTRIBUTE);

  public options: IStyleOption[] = [
    this.isContactInfoUsed,
  ];

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.element = element;
    this.enabledStyleOptions = enabledStyleOptions;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(element);

    this.options.forEach(option => {
      option.isEnabled = this.enabledStyleOptions.has(option.key);

      if (!option.init) return;

      try {
        option.init(element, {
          innerWrapper,
          block,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.classList.contains('block') ? element : <HTMLElement>element.closest('.block');
  }
}
