import {WebsitesTemplatesPermissionsModel} from './templates-permissions.model';

export class WebsitesTemplatesPermissionsDto {
  constructor(public Id: number,
              public WebsiteId: number,
              public TemplateId: number) {}

  public static normalize(res: WebsitesTemplatesPermissionsDto): WebsitesTemplatesPermissionsModel {
    if (!res) return null;

    return new WebsitesTemplatesPermissionsModel(
      res.Id,
      res.WebsiteId,
      res.TemplateId,
    );
  }

  public static fromModel(res: WebsitesTemplatesPermissionsModel): WebsitesTemplatesPermissionsDto {
    if (!res) return null;

    return new WebsitesTemplatesPermissionsDto(
      res.id,
      res.websiteId,
      res.templateId,
    );
  }
}
