import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject, catchError, take, takeUntil, throwError} from 'rxjs';

import {ModalsService} from '../../../services/modals/modals.service';
import {SaveOptionsModalService} from '../../../services/modals/save-options/save-options-modal.service';
import { WebsiteBlocksService } from '../../../../core/services/websites/blocks/website-blocks.service';
import { WebsitesService } from '../../../../core/services/websites/websites.service';
import { IFrameRoutingService } from '../../../../core/services/iframe/routing/iframe-routing.service';
import { SaveThroughoutWebsiteOptionsModalService } from '../../../services/modals/save-throughout-website-options/save-throughout-website-options-modal.service';

import {RadiobuttonModel} from '../../../models/radiobutton/radiobutton.model';
import {Button} from '../../../../common/models/button/button.model';
import { WebsiteModel } from '../../../../core/models/websites/website.model';
import { SelectedPageModel } from '../../../../core/models/selected-page/selected-page.model';

import {KEYS} from '../../../services/modals/save-options/constants';
import {KEYS as SAVE_THROUGHOUT_WEBSITE_KEYS} from '../../../services/modals/save-throughout-website-options/constants';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-save-options-modal',
  templateUrl: './save-options-modal.component.html',
  styleUrls: ['./save-options-modal.component.scss'],
})
export class SaveOptionsModalComponent implements OnInit, OnDestroy {
  public radiobuttons: RadiobuttonModel[] = [
    new RadiobuttonModel('save-styles', 'Throughout Website (Change Website Default)', KEYS.WEBSITE_DEFAULTS),
    new RadiobuttonModel('save-styles', 'For this Page Only', KEYS.THIS_PAGE),
  ];

  public buttons: Button[] = [
    {
      text: `Save Changes`,
      className: 'green save-changes-button',
      onClick: this.onSave.bind(this),
    },
    {
      text: `Cancel`,
      className: 'red dont-save-button',
      onClick: this.onCancel.bind(this),
    },
  ];

  public selectedValue: string = KEYS.WEBSITE_DEFAULTS;
  
  public errorMessage: string = '';
  
  public isLoading: boolean = false;

  private website: WebsiteModel;
  private selectedPage: SelectedPageModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: SaveOptionsModalService,
    private saveThroughoutWebsiteOptionsModalService: SaveThroughoutWebsiteOptionsModalService,
    private modalsService: ModalsService,
    private websiteBlocksService: WebsiteBlocksService,
    private websitesService: WebsitesService,
    private iFrameRoutingService: IFrameRoutingService,
  ) {}

  public ngOnInit(): void {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.selectedPage = selectedPage;
    });
  }

  public onModalOpen(data: { isThisPageSave: boolean }): void {
    this.selectedValue = data && data.isThisPageSave ? KEYS.THIS_PAGE : KEYS.WEBSITE_DEFAULTS;
  }

  public onSave(): void {
    this.isLoading = true;

    if (this.selectedValue === KEYS.THIS_PAGE) {
      return this.submitSelectedValue();
    }

    this.websiteBlocksService.getIsWebsiteHasCustomPages({
      websiteId: this.website.id,
      templateId: this.website.templateId,
      currentPageId: this.selectedPage.id,
    }).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      })
    ).subscribe(({ isWebsiteHasCustomPages }: { isWebsiteHasCustomPages: boolean }) => {
      if (!isWebsiteHasCustomPages) {
        return this.submitSelectedValue();
      }

      this.saveThroughoutWebsiteOptionsModalService.open();
      
      this.saveThroughoutWebsiteOptionsModalService.observable.pipe(take(1)).subscribe((data: { value: string, isRejected: boolean }) => {
        if (data.isRejected) {
          this.onCancel();

          return;
        }

        if (data.value === SAVE_THROUGHOUT_WEBSITE_KEYS.REPLACE_ALL_EXCLUDING_CUSTOM) {
          this.selectedValue = KEYS.WEBSITE_DEFAULTS_EXCLUDING_CUSTOM;
        }

        this.submitSelectedValue();
      });
    });
  }

  private submitSelectedValue(isReplaceAll?: boolean): void {
    this.service.observable.next({
      value: this.selectedValue,
    });

    this.modalsService.close(this.id);
  }

  public onCancel(): void {
    this.service.observable.next({
      isRejected: true,
    });
    
    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
