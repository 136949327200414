import {SetupOptionModel} from '../option/setup-option.model';

import {PROPERTY_NAME, STYLE_PROPERTY_NAME, PREVENT_COLOR_CHANGE_SELECTOR} from './constants';

export class LinkColorModel extends SetupOptionModel {
  public element: HTMLElement;
  public selectedElement: HTMLElement;

  public isStylePropertyShouldBeSet: boolean = true;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(selectedElement: HTMLElement): void {
    if (!this.isEnabled || !selectedElement) return;

    const block: HTMLElement = <HTMLElement>selectedElement.closest('.block,.portfolio-enlargement-wrapper');

    this.isEnabled = this.isEnabled && !!block;

    if (!this.isEnabled) return;

    this.element = block;
    this.selectedElement = selectedElement;

    this.isStylePropertyShouldBeSet = !this.selectedElement.matches(PREVENT_COLOR_CHANGE_SELECTOR);

    const selectedElementComputed: CSSStyleDeclaration = window.getComputedStyle(this.selectedElement);
    const elementComputed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.onChange(selectedElementComputed.getPropertyValue(STYLE_PROPERTY_NAME) || elementComputed.getPropertyValue(PROPERTY_NAME));
  }

  public reset(): void {
    if (this.isStylePropertyShouldBeSet) this.element.style.removeProperty(STYLE_PROPERTY_NAME);

    this.element.style.removeProperty(PROPERTY_NAME);

    const selectedElementComputed: CSSStyleDeclaration = window.getComputedStyle(this.selectedElement);
    const elementComputed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.onChange(selectedElementComputed.getPropertyValue(STYLE_PROPERTY_NAME) || elementComputed.getPropertyValue(PROPERTY_NAME));
  }

  public onChange(value: string): void {
    this.value = value || this.getDefaultValue();

    if (!this.isEnabled) return;

    this.element.style.setProperty(PROPERTY_NAME, this.value);

    if (this.isStylePropertyShouldBeSet) this.element.style.setProperty(STYLE_PROPERTY_NAME, this.value);
  }

  private getDefaultValue(): string {
    const selectedElementComputed: CSSStyleDeclaration = window.getComputedStyle(this.selectedElement);
    const elementComputed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: string = selectedElementComputed.getPropertyValue(STYLE_PROPERTY_NAME) || elementComputed.getPropertyValue(PROPERTY_NAME);

    if (!this.selectedElement) return value || '';

    return value || window.getComputedStyle(this.selectedElement).getPropertyValue(STYLE_PROPERTY_NAME);
  }
}
