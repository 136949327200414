import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-sidebar-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class SidebarDescriptionComponent {
  @Input() text: string;
}
