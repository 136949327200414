// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.settings {
  height: 100%;
  overflow: auto;
  margin-top: 10px;
  padding-bottom: 20px;
}
.settings .section .section-header {
  background: #3f3e3e;
}
.settings .section .section-header .top-section {
  padding: 0 25px;
}
.settings .section .section-header .top-section .header-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.settings .section .section-header .top-section .header-title h3 {
  font-size: 17px;
  color: rgb(221, 255, 198);
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px;
}
.settings .section .section-header .top-section .header-title i {
  font-size: 25px;
  color: rgb(221, 255, 198);
}
.settings .section .items {
  overflow: hidden;
  padding: 0;
}
.settings .section .items .item {
  margin: 0;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  color: #bec3c7;
}
.settings .section .items .item:hover {
  background-color: silver;
  color: #333;
}
.settings .section .items .item.selected {
  background-color: #aaa;
  color: #333;
  transition: 0.3s all linear;
}
.settings .section .items .item.selected:hover {
  cursor: default !important;
}`, "",{"version":3,"sources":["webpack://./src/app/application/sidebar-short/sidebar/educator-admin/educator-admin-section.component.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,YAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,gBAAA;EACA,oBAAA;AADF;AAII;EACE,mBAAA;AAFN;AAIM;EACE,eAAA;AAFR;AAGQ;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;AADV;AAGU;EACE,eAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AADZ;AAIU;EACE,eAAA;EACA,yBAAA;AAFZ;AAQI;EACE,gBAAA;EACA,UAAA;AANN;AAQM;EACE,SAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,cAAA;AANR;AAQQ;EACE,wBAAA;EACA,WAAA;AANV;AASQ;EACE,sBAAA;EACA,WAAA;EACA,2BAAA;AAPV;AASU;EACE,0BAAA;AAPZ","sourcesContent":["\n\n.disabled {\n  pointer-events: none;\n  opacity: 0.6;\n}\n\n.settings {\n  height: 100%;\n  overflow: auto;\n  margin-top: 10px;\n  padding-bottom: 20px;\n\n  .section {\n    .section-header {\n      background: #3f3e3e;\n\n      .top-section {\n        padding: 0 25px;\n        .header-title {\n          display: flex;\n          flex-direction: row;\n          justify-content: flex-start;\n\n          h3 {\n            font-size: 17px;\n            color: rgb(221, 255, 198);\n            margin-bottom: 10px;\n            cursor: pointer;\n            padding: 5px;\n          }\n\n          i {\n            font-size: 25px;\n            color: rgb(221, 255, 198);\n          }\n        }\n      }\n    }\n\n    .items {\n      overflow: hidden;\n      padding: 0;\n\n      .item {\n        margin: 0;\n        padding: 10px 16px;\n        font-size: 14px;\n        cursor: pointer;\n        color: #bec3c7;\n\n        &:hover {\n          background-color: silver;\n          color: #333;\n        }\n\n        &.selected {\n          background-color: #aaa;\n          color: #333;\n          transition: 0.3s all linear;\n\n          &:hover {\n            cursor: default !important;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
