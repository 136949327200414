import {Component} from '@angular/core';

import {WebsiteStatisticCollectingInfoModalService} from '../../../services/modals/website-statistic-collecting-info/website-statistic-collecting-info-modal.service';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-website-statistic-collecting-info-modal',
  templateUrl: './website-statistic-collecting-info-modal.component.html',
  styleUrls: ['./website-statistic-collecting-info-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class WebsiteStatisticCollectingInfoModalComponent {
  public get id(): string {
    return this.service.id;
  }

  constructor(private service: WebsiteStatisticCollectingInfoModalService) {}

  public closeModal(): void {
    this.service.close();
  }
}
