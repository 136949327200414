import {Injectable} from '@angular/core';

import {ModalsService} from '../modals.service';

@Injectable()
export class ImageManagerErrorModalService {
  public readonly id = 'image-manager-error-modal';

  public error: string = '';

  public isOpened = false;

  public constructor(private modalsService: ModalsService) {
  }

  public open({ error }: { error: string }): void {
    this.modalsService.open(this.id);

    this.isOpened = true;

    this.error = error;
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
