import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject, combineLatest, Observable} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {ImageManagerModalService} from '../../../../shared/components/modals/image-manager-modal/image-manager-modal.service';
import {ImageFileNameAsTitleModalService} from '../../../../shared/services/modals/image-file-name-as-title/image-file-name-as-title-modal.service';
import {NavigationService} from '../../../../services/navigation.service';
import {WebsitesExclamationIconService} from '../../../../core/services/websites-exclamation-icon/websites-exclamation-icon.service';
import {WebsiteTourService} from '../../../../core/services/website-tour/website-tour.service';

import {ExclamationIconInfoModel} from '../../../../core/models/exclamation-icon-info/exclamation-icon-info.model';
import {ImageModel} from '../../../../core/models/images/image.model';

import {DEFAULT_IMAGE_EXTENSIONS} from './constants';
import {KEYS} from '../../../../core/services/website-tour/constants';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {
  @Input() selected: ImageModel[];
  @Input() hidden: boolean;
  @Input() untouchable: boolean;

  @Output() deleteClickHandler = new EventEmitter();
  @Output() deleteAllClickHandler = new EventEmitter();
  @Output() publishAllClickHandler = new EventEmitter();
  @Output() filesSelectedClickHandler = new EventEmitter();
  @Output() imageReplaceClickHandler = new EventEmitter();

  public isExclamationIconVisible: boolean;
  public isExclamationButtonVisible: boolean;

  public exclamationIconButtonTooltip: string = 'SHOW or HIDE the "!" alert badges on low resolution image files';

  private exclamationIconData: ExclamationIconInfoModel;

  private isImageManager: boolean;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isLibrary(): boolean {
    return this.imageManagerModalService.isLibrary;
  }

  public get isReplaceButtonEnabled(): boolean {
    return this.selected && this.selected.length === 1;
  }

  public get isDeleteButtonEnabled(): boolean {
    return this.selected && this.selected.length > 0;
  }

  constructor(private cdr: ChangeDetectorRef,
              private imageManagerModalService: ImageManagerModalService,
              private imageFileNameAsTitleModalService: ImageFileNameAsTitleModalService,
              private navigationService: NavigationService,
              private websiteTourService: WebsiteTourService,
              private websitesExclamationIconService: WebsitesExclamationIconService) {}

  public ngOnInit(): void {
    this.imageFileNameAsTitleModalService.onFileNameAsTitleSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((options: { isImageFileNameAsTitle: boolean, images: File[] }) => {
      if (!options) return;

      this.filesSelectedClickHandler.next(options);
    });

    const exclamationIconDataObservable: Observable<ExclamationIconInfoModel> = this.websitesExclamationIconService.exclamationIconDataSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((exclamationIconData: ExclamationIconInfoModel) => !!(exclamationIconData))
    );

    const isImageManagerObservable: Observable<boolean> = this.navigationService.isImageManagerSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    );

    combineLatest([exclamationIconDataObservable, isImageManagerObservable]).subscribe(([exclamationIconData, isImageManager]) => {
      this.exclamationIconData = exclamationIconData;
      this.isImageManager = isImageManager;

      this.initIsExclamationIconVisible();
    });

    this.websitesExclamationIconService.exclamationIconButtonVisibilitySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isExclamationButtonVisible: boolean) => {
      this.isExclamationButtonVisible = isExclamationButtonVisible;

      this.cdr.detectChanges();
    });

    this.websiteTourService.addVisibleItem(KEYS.TOP_BUTTONS);
  }

  private initIsExclamationIconVisible(): void {
    if (!this.isImageManager || !this.exclamationIconData) return;

    this.isExclamationIconVisible = this.exclamationIconData.isVisible;
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleItem(KEYS.TOP_BUTTONS);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public handleImageUploadClick(element, files: FileList): void {
    const list: File[] = Array.from(files).filter(file => DEFAULT_IMAGE_EXTENSIONS.includes(file.type));

    this.imageFileNameAsTitleModalService.open(list);

    element.value = null;
  }

  public handleReplaceClick(e: Event) {
    const id = this.selected && this.selected[0] && this.selected[0].id;
    const target: HTMLInputElement = <HTMLInputElement>e.target;
    const files: File[] = Array.from(target.files);

    if (id === void 0 || !files[0]) return;

    this.imageReplaceClickHandler.emit({ id, file: files[0] });

    target.value = null;
  }

  handleDeleteClick() {
    this.deleteClickHandler.emit();
  }

  handleDeleteAllClick() {
    this.deleteAllClickHandler.emit();
  }

  handlePublishAllClick() {
    this.publishAllClickHandler.emit();
  }

  public onExclamationIconToggle(): void {
    if (!this.exclamationIconData) return;

    this.websitesExclamationIconService.setIsExclamationIconVisible(!this.exclamationIconData.isVisible).subscribe(() => {
      this.websitesExclamationIconService.fetchIsExclamationIconVisible();
    });
  }
}
