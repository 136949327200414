import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ModalsService} from '../modals.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';

import {PublishingStepModel} from '../../../../core/models/publishing/step/publishing-step.model';
import {ISocketPublishMessageDataModel} from '../../../../core/models/sockets/message/publish/i-publish-message-data.model';

import {PUBLISH_STATUSES} from '../../../../services/publish-website/constants';

@Injectable()
export class PublishingStatusModalService {
  public readonly id = 'publishing-status-modal';

  public isOpened = false;

  public stepsSubject: BehaviorSubject<PublishingStepModel[]> = new BehaviorSubject<PublishingStepModel[]>([]);

  public steps: PublishingStepModel[] = [
    new PublishingStepModel(PUBLISH_STATUSES.STARTED, `Initialization`, false),
    new PublishingStepModel(PUBLISH_STATUSES.PREPARING_DATA, `Preparing data`, false),
    new PublishingStepModel(PUBLISH_STATUSES.PAGES_GENERATING, `Pages generating`, true),
    new PublishingStepModel(PUBLISH_STATUSES.BUILDING, `Website building`, true),
    new PublishingStepModel(PUBLISH_STATUSES.UPLOADING, `Uploading`, true),
    new PublishingStepModel(PUBLISH_STATUSES.DONE, `Done`, false),
  ];

  public constructor(private modalsService: ModalsService,
                     private socketsService: SocketsService) {
    this.socketsService.publishDataSubject.subscribe((data: ISocketPublishMessageDataModel) => this.onMessage(data));
  }

  private onMessage(data: ISocketPublishMessageDataModel) {
    if (!data || !data.status || data.status === PUBLISH_STATUSES.READY) return;

    const idx: number = this.steps.findIndex((step: PublishingStepModel) => step.key === data.status);

    if (idx === -1) return this.onPublishingFailed();

    this.steps.forEach((step: PublishingStepModel, i: number) => {
      step.isDone = i < idx;
      step.isInProgress = i === idx;
    });

    this.steps[idx].progress = data.stepProgress || 0;

    this.stepsSubject.next(this.steps);
  }

  private onPublishingFailed() {
    this.close();
  }

  public open(): void {
    this.modalsService.open(this.id);
    this.isOpened = true;
  }

  public onCancel() {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
