import {IStyleModelOptions} from '../../../option/i-style-model-options';

import {SetupOptionModel} from '../../../option/setup-option.model';

export class BaseSelectIntModel extends SetupOptionModel {
  public element: HTMLElement;
  public selectedElement: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public propertyName: string,
    public stylePropertyName: string,
    public value?: number,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.selectedElement = options.selectedElement;

    const currentValue = Number.parseInt(this.element.style.getPropertyValue(this.propertyName));

    if (currentValue) return this.onChange(Number.isNaN(currentValue) ? void 0 : currentValue);

    const computedValue = window.getComputedStyle(this.element);
    const value = computedValue.getPropertyValue(this.propertyName) || computedValue[this.stylePropertyName];

    this.onChange(value === 'undefined' ? void 0 : value);
  }

  public reset(): void {
    this.element.style.removeProperty(this.propertyName);
    this.onChange(Number.NaN);
  }

  public onChange(value: number): void {
    this.value = Number.isInteger(value) && !Number.isNaN(value) ? value : Number.parseInt(getComputedStyle(this.selectedElement)[this.stylePropertyName]);

    if (!this.isEnabled) return;

    return this.element.style.setProperty(this.propertyName, `${this.value}`);
  }
}
