import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {WebsitesHttpService} from '../interaction/http/websites/websites-http.service';
import {WebsitesService} from '../websites/websites.service';

import {DomainModel} from '../../models/domain/domain.model';

@Injectable()
export class DomainsService {
  public primaryDomainSubject: BehaviorSubject<DomainModel> = new BehaviorSubject<DomainModel>(null);

  constructor(private websitesHttpService: WebsitesHttpService,
              private websitesService: WebsitesService) {
    this.websitesService.activeWebsiteIdSubject.subscribe((websiteId: number) => {
      if (!websiteId) return;

      this.fetchPrimaryDomain(websiteId);
    });
  }

  private fetchPrimaryDomain(websiteId: number) {
    this.websitesHttpService.getWebsitePrimaryDomain(websiteId).subscribe((domain: DomainModel) => {
      this.primaryDomainSubject.next(domain);
    });
  }
}
