import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {FilesService} from '../../files/files.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';

@Injectable()
export class ImageUploadService {
  private _imageUploadErrorMessage: string = '';
  private ERROR_KEYS = {
    NO_SUBSCRIPTION: 'You don\'t have a subscription.',
    UPLOAD_CANCELED: 'Upload canceled.',
    DISK_SPACE_LIMIT_EXCEEDED: 'Disk space limit exceeded.',
    FILE_TOO_BIG: 'File is too big',
    UNSUPPORTED_IMAGE_FORMAT: 'UNSUPPORTED_IMAGE_FORMAT',
    SERVER: 'Server error',
  };

  public ERROR_MODAL_ID: string = 'error-modal';
  public readonly MAX_FILE_SIZE = 10; // MB
  public readonly MAX_FILE_SIZE_IN_BYTES = this.MAX_FILE_SIZE * 1024 * 1024; // BYTES
  public minLongestSidePx: number = 1000;
  public isErrorOnUpload: boolean = false;
  public uploadImagesErrors: any = {};

  get imageUploadErrorMessage() {
    return this._imageUploadErrorMessage;
  }

  constructor(private http: HttpClient,
              private filesService: FilesService,
              private modalsService: ModalsService) {
  }

  // async ok
  public async readImage(file: File): Promise<HTMLImageElement> {
    return this.filesService.readImage(file);
  }

  public isImageHasEnoughResolution(image: HTMLImageElement): boolean {
    const longestSide = image.width > image.height ? image.width : image.height;
    return longestSide >= this.minLongestSidePx;
  }

  public handleUploadError(filename, errorMessage) {
    this.isErrorOnUpload = true;

    const errorKey = this.getErrorKey(errorMessage);

    if (!this.uploadImagesErrors[errorKey]) {
      this.uploadImagesErrors[errorKey] = [];
    }

    this.uploadImagesErrors[errorKey].push(filename);
  }

  public showErrorMessage() {
    const errorMessages = [];

    Object.keys(this.uploadImagesErrors).forEach((errorKey) => {
      errorMessages.push(this.getErrorMessage(errorKey));
    });

    this._imageUploadErrorMessage = errorMessages.join('');
    
    this.isErrorOnUpload = false;
    
    this.modalsService.open(this.ERROR_MODAL_ID);
  }

  public clearErrorMessages() {
    this._imageUploadErrorMessage = '';
    this.uploadImagesErrors = {};
  }

  private getErrorKey(errorMessage) {
    const message = errorMessage.message || errorMessage;

    if (Object.keys(this.ERROR_KEYS).map(key => this.ERROR_KEYS[key]).includes(message)) {
      return message;
    }

    if (message.startsWith(this.ERROR_KEYS.FILE_TOO_BIG)) {
      return this.ERROR_KEYS.FILE_TOO_BIG;
    }

    if (errorMessage && errorMessage.key === 'UNSUPPORTED_IMAGE_FORMAT') {
      return errorMessage.key;
    }

    return this.ERROR_KEYS.SERVER;
  }

  private getErrorMessage(errorKey) {
    if (!this.uploadImagesErrors[errorKey] || !this.uploadImagesErrors[errorKey].length) {
      return '';
    }

    switch (errorKey) {
      case this.ERROR_KEYS.NO_SUBSCRIPTION: {
        return `You have no service plan.
          Images are not uploaded: ${this.uploadImagesErrors[errorKey].join(', ')}.\n`;
      }
      case this.ERROR_KEYS.DISK_SPACE_LIMIT_EXCEEDED: {
        return `Disk space limit is exceeded. Please upgrade your plan.\n\nImages are not uploaded: ${this.uploadImagesErrors[errorKey].join(', ')}.\n`;
      }
      case this.ERROR_KEYS.UPLOAD_CANCELED: {
        return `Images can be not uploaded: ${this.uploadImagesErrors[errorKey].join(', ')}.\n`;
      }
      case this.ERROR_KEYS.FILE_TOO_BIG: {
        const isMultipleFiles = this.uploadImagesErrors[errorKey].length > 1;

        return `${isMultipleFiles ? 'Files' : 'File'} ${this.uploadImagesErrors[errorKey].join(', ')} ${isMultipleFiles ?
          'are' : 'is'} too big. Max filesize: ${this.MAX_FILE_SIZE}MB.\n`;
      }
      case this.ERROR_KEYS.UNSUPPORTED_IMAGE_FORMAT: {
        return `There's a problem with this image. Please reprocess as a jpg or png and reupload.`;
      }
      case this.ERROR_KEYS.SERVER: {
        return `Error while uploading files: ${this.uploadImagesErrors[errorKey].join(', ')}.\n`;
      }
    }
  }

  public uploadOne({ file, portfolioId, classId, type, isImageFileNameAsTitle }: { file: File, portfolioId: number, classId: number, type: string, isImageFileNameAsTitle: boolean }): Observable<any> {
    const formData: FormData = new FormData();

    const params = new HttpParams()
      .set('portfolioId', `${portfolioId}`)
      .set('isImageFileNameAsTitle', `${isImageFileNameAsTitle}`);

    if (classId) formData.append('classId', `${classId}`);

    formData.append('type', type);
    
    formData.append('file', file, file.name);

    return this.http.post(`/api/app/image-manager/setup`, formData, { reportProgress: true, observe: 'events', responseType: 'json', params });
  }
}
