import {Injectable} from '@angular/core';

import {Observable, Subscription, BehaviorSubject} from 'rxjs';

import {EducationWebsitesHttpService} from '../../../../../interaction/http/education/teachers/institutions/classes/websites/education-websites-http.service';

import {WebsiteModel} from '../../../../../../models/websites/website.model';

@Injectable()
export class EducationWebsitesService {
  public websiteDetailsSubject: BehaviorSubject<WebsiteModel> = new BehaviorSubject<WebsiteModel>(null);

  constructor(private httpService: EducationWebsitesHttpService) {
  }

  public fetchOne(institutionId: number, classId: number, websiteId: number): Subscription {
    return this.httpService.getOne(institutionId, classId, websiteId).subscribe((res: WebsiteModel) => {
      this.websiteDetailsSubject.next(res);
    });
  }

  public setIsSimpleImageLabel(institutionId: number, classId: number, websiteId: number, isSimpleImageLabel: boolean): Observable<boolean> {
    return this.httpService.setIsSimpleImageLabel(institutionId, classId, websiteId, isSimpleImageLabel);
  }
}
