export const KEYS = {
  WIDTH: 'WIDTH',
  HEIGHT: 'HEIGHT',
};

export const CLASSES = {
  [KEYS.WIDTH]: 'image-fit-into-width-wrapper',
  [KEYS.HEIGHT]: 'image-fit-into-height-wrapper',
};

export const CLASSES_LIST = Object.keys(CLASSES).map(key => CLASSES[key]);

export const CLASSES_KEYS = Object.keys(CLASSES).reduce((res, key) => {
  res[CLASSES[key]] = key;
  return res;
}, {});

export const OPTIONS = [
  {
    key: KEYS.HEIGHT,
    text: 'Height',
  },
  {
    key: KEYS.WIDTH,
    text: 'Width',
  },
];

export const DEFAULT_CLASS_NAME = CLASSES[KEYS.WIDTH];
export const DEFAULT_OPTION = OPTIONS[0];
