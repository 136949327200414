import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-vs-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text;

  constructor() {}
}
