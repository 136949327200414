import {Injectable, Component} from '@angular/core';


import {Observable} from 'rxjs';

export interface CanLeaveComponent {
  canLeave: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanLeaveComponentGuard   {
  public canDeactivate(component: CanLeaveComponent): Observable<boolean> | Promise<boolean> | boolean {
    return component && component.canLeave ? component.canLeave() : true;
  }
}
