import {AttributeModel} from '../base/attribute/attribute.model';

import {ATTRIBUTE} from './constants';

export class VideoMutedModel extends AttributeModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, ATTRIBUTE, value, isEnabled);
  }

  public onChange(value: boolean): void {
    if (!this.isEnabled || !this.element) return;

    (<HTMLVideoElement>this.element).muted = value;

    super.onChange(value);
  }
}
