import {PlanModel} from './plan.model';
import {IncludedAddOnsDto} from './included-add-ons/included-add-ons.dto';
import {IncludedAddOnsModel} from './included-add-ons/included-add-ons.model';

export class PlanDto {
  constructor(public Id?: string,
              public Name?: string,
              public IsEcommerce?: boolean,
              public IsEnabled?: boolean,
              public WebsiteLimit?: number,
              public ImagesPricesLimit?: number,
              public PortfolioLimit?: number,
              public PageLimit?: number,
              public DiskSpaceMb?: number,
              public IncludedAddOns?: IncludedAddOnsDto[],
              public StripeProductId?: string,
              public AnnualPlanStripeId?: string,
              public MonthPlanStripeId?: string,
              public DailyPlanStripeId?: string,
              public AmountAnnually?: number,
              public AmountMonthly?: number,
              public AmountDaily?: number,
              public Level?: number) {}

  public static toRequest(res: PlanModel): PlanDto {
    if (!res) return null;

    return new PlanDto(
      res.id,
      res.name,
      res.isEcommerce,
      res.isEnabled,
      res.websiteLimit,
      res.imagesPricesLimit,
      res.portfolioLimit,
      res.pageLimit,
      res.diskSpaceMb,
      res.includedAddOns ? res.includedAddOns.map((item: IncludedAddOnsModel) => IncludedAddOnsDto.toRequest(item)) : null,
      res.stripeProductId,
      res.annualPlanStripeId,
      res.monthPlanStripeId,
      res.dailyPlanStripeId,
      Number((res.amountAnnually * 100).toFixed()),
      Number((res.amountMonthly * 100).toFixed()),
      Number((res.amountDaily * 100).toFixed()),
    );
  }
}
