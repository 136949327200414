import {IStyleOption} from '../option/i-style-option';
import {StyleOptionModel} from '../../settings/option/style-option.model';
import {BlogPostNamesFontSizeModel} from './post-names-font-size/blog-post-names-font-size.model';
import {BlogTopBarBackgroundColorModel} from './top-bar-background-color/blog-top-bar-background-color.model';
import {BlogTopBarFontColorModel} from './top-bar-font-color/blog-top-bar-font-color.model';
import {BlogPostLinksColorModel} from './post-links-color/blog-post-links-color.model';
import {BlogSocialNetworksColorModel} from './social-networks-color/blog-social-networks-color.model';
import {BlogPostTagsColorModel} from './post-tags-color/blog-post-tags-color.model';
import {BlogPostTagsBackgroundModel} from './post-tags-background/blog-post-tags-background.model';
import {BlogIsCommentsEnabledModel} from './is-comments-enabled/blog-is-comments-enabled.model';
import {BlogIsContactEmailUsedModel} from './is-contact-email-used/blog-is-contact-email-used.model';
import {BlogSocialIconsModel} from './social-icons/blog-social-icons.model';

import {KEYS, TYPES} from '../../../../services/styles/blog/constants';

export class BlogSetupModel {
  public element: HTMLElement;

  private enabledStyleOptions: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  public postNamesFontSize: BlogPostNamesFontSizeModel = new BlogPostNamesFontSizeModel(KEYS.BLOG_POST_TITLES_FONT_SIZE, TYPES.STYLE_PROPERTY);
  public postLinksColor: BlogPostLinksColorModel = new BlogPostLinksColorModel(KEYS.BLOG_POST_LINKS_COLOR, TYPES.STYLE_PROPERTY);
  public socialNetworksColor: BlogSocialNetworksColorModel = new BlogSocialNetworksColorModel(KEYS.BLOG_SOCIAL_NETWORKS_COLOR, TYPES.STYLE_PROPERTY);
  public topBarBackgroundColor: BlogTopBarBackgroundColorModel = new BlogTopBarBackgroundColorModel(KEYS.BLOG_TOP_BAR_BACKGROUND_COLOR, TYPES.STYLE_PROPERTY);
  public topBarFontColor: BlogTopBarFontColorModel = new BlogTopBarFontColorModel(KEYS.BLOG_TOP_BAR_COLOR, TYPES.STYLE_PROPERTY);
  public postTagsColor: BlogPostTagsColorModel = new BlogPostTagsColorModel(KEYS.BLOG_POST_TAGS_COLOR, TYPES.STYLE_PROPERTY);
  public postTagsBackground: BlogPostTagsBackgroundModel = new BlogPostTagsBackgroundModel(KEYS.BLOG_POST_TAGS_COLOR, TYPES.STYLE_PROPERTY);
  public socialIcons: BlogSocialIconsModel = new BlogSocialIconsModel(KEYS.BLOG_SOCIAL_ICONS, TYPES.ATTRIBUTE);
  public isCommentsEnabled: BlogIsCommentsEnabledModel = new BlogIsCommentsEnabledModel(KEYS.BLOG_IS_COMMENTS_ENABLED, TYPES.ATTRIBUTE);
  public isContactEmailUsed: BlogIsContactEmailUsedModel = new BlogIsContactEmailUsedModel(KEYS.BLOG_IS_CONTACT_EMAIL_USED, TYPES.ATTRIBUTE);

  public options: IStyleOption[] = [
    this.postNamesFontSize,
    this.postLinksColor,
    this.socialNetworksColor,
    this.topBarBackgroundColor,
    this.topBarFontColor,
    this.postTagsColor,
    this.postTagsBackground,
    this.socialIcons,
    this.isCommentsEnabled,
    this.isContactEmailUsed,
  ];

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    this.element = element;
    this.enabledStyleOptions = enabledStyleOptions;

    const innerWrapper: HTMLElement = element ? <HTMLElement>element.closest('.innerWrapper') : null;
    const block: HTMLElement = this.getBlock(element);

    this.options.forEach(option => {
      option.isEnabled = this.enabledStyleOptions.has(option.key);

      if (!option.init) return;

      try {
        option.init(element, {
          innerWrapper,
          block,
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getBlock(element: HTMLElement): HTMLElement {
    if (!element) return null;

    return element.classList.contains('block') ? element : <HTMLElement>element.closest('.block');
  }
}
