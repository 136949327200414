import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {MeasureUnitsService} from '../../../converters/measure-units/measure-units.service';

import {ImageDto} from '../../../../models/images/image.dto';
import {ImageModel} from '../../../../models/images/image.model';
import {ImagePriceDto} from '../../../../models/images/price/image-price.dto';
import {ImagePriceModel} from '../../../../models/images/price/image-price.model';

@Injectable()
export class ImagesHttpService {
  private model: string = 'images';

  constructor(private httpClient: HttpClient,
              private measureUnitsService: MeasureUnitsService) {}

  public fetchForUser(portfolioNodeKey: number): Observable<ImageModel[]> {
    const params = new HttpParams().set('portfolioNodeKey', `${portfolioNodeKey}`);

    return this.httpClient.get(`api/${this.model}`, { params }).pipe(
      map((res: ImageDto[]) => res.map((item: ImageDto) => ImageDto.normalize(item)))
    );
  }

  public fetchImageDetails(id: number): Observable<ImageModel> {
    return this.httpClient.get(`api/${this.model}/${id}`).pipe(
      map((res: ImageDto) => ImageDto.normalize(res)),
      map((res: ImageModel) => {
        res.prices = res.prices.map(price => this.convertToWebsiteMeasureUnit(price));

        return res;
      }),
    );
  }

  public fetchImagesBucket(): Observable<string> {
    return this.httpClient.get(`api/${this.model}/bucket`, { responseType: 'text' });
  }

  public addNewPrice(imageId: number, price: ImagePriceDto): Observable<ImagePriceModel> {
    return this.httpClient.post(`api/${this.model}/${imageId}/prices`, this.convertToMainMeasureUnit(price))
    .pipe(
      map((res: ImagePriceDto) => ImagePriceDto.normalize(res)),
      map((res: ImagePriceModel) => this.convertToWebsiteMeasureUnit(res)),
    );
  }

  public updatePrice(imageId: number, price: ImagePriceDto): Observable<ImagePriceModel> {
    return this.httpClient.put(`api/${this.model}/${imageId}/prices/${price.Id}`, this.convertToMainMeasureUnit(price))
    .pipe(
      map((res: ImagePriceDto) => ImagePriceDto.normalize(res)),
      map((res: ImagePriceModel) => this.convertToWebsiteMeasureUnit(res)),
    );
  }

  public deletePrice(imageId: number, priceId: number): Observable<ImagePriceModel> {
    return this.httpClient.delete(`api/${this.model}/${imageId}/prices/${priceId}`).pipe(
      map((res: ImagePriceDto) => ImagePriceDto.normalize(res)),
      map((res: ImagePriceModel) => this.convertToWebsiteMeasureUnit(res)),
    );
  }

  public replaceImage(id: number, file: File, portfolioId: number) {
    const formData: FormData = new FormData();
    
    formData.append('file', file, file.name);

    formData.append('portfolioId', `${portfolioId}`);

    return this.httpClient.put(`api/${this.model}/${id}/file`, formData);
  }

  public updateImage(id: number, data: {}): Observable<Object> {
    return this.httpClient.put(`api/${this.model}/${id}`, data);
  }

  public convertToMainMeasureUnit(imagePrice: ImagePriceDto) {
    if (!imagePrice) {
      return null;
    }

    imagePrice.Width = this.measureUnitsService.convertToMain(parseFloat(imagePrice.Width));
    imagePrice.Height = this.measureUnitsService.convertToMain(parseFloat(imagePrice.Height));
    imagePrice.Length = this.measureUnitsService.convertToMain(parseFloat(imagePrice.Length));
    imagePrice.MatWidth = this.measureUnitsService.convertToMain(parseFloat(imagePrice.MatWidth));
    imagePrice.MatHeight = this.measureUnitsService.convertToMain(parseFloat(imagePrice.MatHeight));

    return imagePrice;
  }

  public convertToWebsiteMeasureUnit(imagePrice: ImagePriceModel): ImagePriceModel {
    if (!imagePrice) {
      return null;
    }

    imagePrice.width = this.measureUnitsService.convertFromMain(imagePrice.width);
    imagePrice.height = this.measureUnitsService.convertFromMain(imagePrice.height);
    imagePrice.length = this.measureUnitsService.convertFromMain(imagePrice.length);
    imagePrice.matWidth = this.measureUnitsService.convertFromMain(imagePrice.matWidth);
    imagePrice.matHeight = this.measureUnitsService.convertFromMain(imagePrice.matHeight);

    return imagePrice;
  }
}
