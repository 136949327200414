import {KEYS as DESKTOP_KEYS} from '../../../../../services/styles/custom-menu/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/custom-mobile-menu/constants';

export const STYLE_PROPERTIES = {
  [DESKTOP_KEYS.ACTIVE_COLOR]: 'color',
  [MOBILE_KEYS.MOBILE_ACTIVE_COLOR]: 'color',
};

export const CUSTOM_STYLE_PROPERTIES = {
  [DESKTOP_KEYS.ACTIVE_COLOR]: '--menu-active-link-color',
  [MOBILE_KEYS.MOBILE_ACTIVE_COLOR]: '--mobile-menu-active-link-color',
};
