import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PublishingWebsiteInfoModalService} from '../../../services/modals/publishing-website-info/publishing-website-info-modal.service';
import {AccountsHttpService} from '../../../../core/services/interaction/http/accounts/accounts-http.service';
import {AuthService} from '../../../../auth/auth.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-publishing-website-info-modal',
  templateUrl: './publishing-website-info-modal.component.html',
  styleUrls: ['./publishing-website-info-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PublishingWebsiteInfoModalComponent implements OnInit, OnDestroy {
  public modalHeader: ModalHeader = new ModalHeader('GETTING STARTED — PUBLISH WEBSITE', 'neutral-header');

  public isPublishInfoModalHidden: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public buttons: Button[] = [
    new Button('PUBLISH WEBSITE', null, 'green', this.onPublish.bind(this)),
    new Button('CLOSE', null, 'neutral', this.onClose.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  public get isDashboard(): boolean {
    return this.service.isDashboard;
  }

  constructor(private service: PublishingWebsiteInfoModalService,
              private accountsHttpService: AccountsHttpService,
              private authService: AuthService) {
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.isPublishInfoModalHidden = !!account && !account.isPublishInfoModalVisible;
    });
  }

  public onCheckboxClick(): void {
    this.accountsHttpService.updateAccountData({
      isPublishInfoModalVisible: !this.isPublishInfoModalHidden,
    }).subscribe(() => {
      this.authService.updateCurrentUser(false);
    });
  }

  private onPublish(): void {
    this.service.onConfirm();
  }

  private onClose(): void {
    this.service.onCancel();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
