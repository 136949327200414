// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edge-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  opacity: 0.8;
  z-index: 1;
}
.edge-overlay .text {
  color: black;
  font-size: 22px;
}
.edge-overlay .text .link {
  color: black;
  text-decoration: underline;
}
.edge-overlay .text .link:hover {
  color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/browser-warning-overlay/browser-warning-overlay.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;AACF;AACE;EACE,YAAA;EACA,eAAA;AACJ;AACI;EACE,YAAA;EACA,0BAAA;AACN;AACM;EACE,cAAA;AACR","sourcesContent":[".edge-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #ccc;\n  opacity: 0.8;\n  z-index: 1;\n\n  .text {\n    color: black;\n    font-size: 22px;\n\n    .link {\n      color: black;\n      text-decoration: underline;\n\n      &:hover {\n        color: #0056b3;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
