
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router, Event} from '@angular/router';

import {BehaviorSubject, combineLatest} from 'rxjs';

import {IFrameService} from '../iframe.service';
import {AuthService} from '../../../../auth/auth.service';
import {NavigationService} from '../../../../services/navigation.service';
import {NodesService} from '../../nodes/nodes.service';
import {TemplatesService} from '../../templates/templates.service';

import {SelectedPageModel} from '../../../models/selected-page/selected-page.model';
import {NodeModel} from '../../../models/nodes/node.model';
import {TemplateModel} from '../../../models/templates/template.model';

@Injectable()
export class IFrameRoutingService {
  public srcSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  public selectedPageSubject: BehaviorSubject<SelectedPageModel> = new BehaviorSubject(this.selectedPage);

  public pageId: number;
  public pageType: string;
  public nodeId: number;
  public templateId: number;
  public isHomePage: boolean = false;
  public isCategoryPage: boolean = false;
  public isSplashPage: boolean = false;
  public isSplashEnabled: boolean = false;
  public isDirectLink: boolean = false;

  private lastSelectedPage: SelectedPageModel;
  private nodes: NodeModel[] = [];
  private isLastPageEditor: boolean;

  public get selectedPage(): SelectedPageModel {
    return new SelectedPageModel(
      this.pageId,
      this.pageType,
      this.nodeId,
      this.templateId,
      this.isHomePage,
      this.isSplashPage,
      this.isDirectLink,
    );
  }

  public get src(): string {
    return this.srcSubject.value;
  }

  private get isPageValid(): boolean {
    if (!this.templateId || !this.pageId || !this.pageType) return false;
    if (this.isSplashPage && !this.isSplashEnabled) return false;

    return !this.isCategoryPage || (this.isCategoryPage && this.isValidCategory);
  }

  private get isValidCategory(): boolean {
    const idx: number = this.nodes.findIndex((node: NodeModel) => node.id === this.pageId && node.type === 'C');

    return idx !== -1 && this.nodes[idx].isPageExists;
  }

  private get isValidPortfolio(): boolean {
    if (this.pageId === -1 && this.pageType === 'P' && this.navigationService.isImageManager) return true;

    return this.nodes.findIndex((node: NodeModel) => node.id === this.pageId && node.type === 'P') !== -1;
  }

  constructor(private router: Router,
              private navigationService: NavigationService,
              private templatesService: TemplatesService,
              private authService: AuthService,
              private nodesService: NodesService,
              private iFrameService: IFrameService) {
    const routeObservable = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
    const nodesObservable = this.nodesService.nodesSubject.pipe(filter(nodes => nodes && nodes.length !== 0));
    const isSplashEnabledObservable = this.nodesService.isSplashEnabledSubject;
    const templateObservable = this.templatesService.activeTemplateSubject.pipe(filter(Boolean));

    combineLatest([
      routeObservable,
      nodesObservable,
      isSplashEnabledObservable,
      templateObservable,
    ]).subscribe(([params, nodes, isSplashEnabled, activeTemplate]: [Event, NodeModel[], boolean, TemplateModel]) => {
      const query: URLSearchParams = new URLSearchParams(window.location.search);

      this.pageId = Number.parseInt(query.get('id'), 10);
      this.pageType = query.get('type');
      this.isCategoryPage = this.pageType === 'C';
      this.isSplashPage = query.get('isSplash') === 'true';
      this.isSplashEnabled = isSplashEnabled;
      this.nodes = nodes;
      this.templateId = activeTemplate.id;

      this.initSrc();
    });

    this.authService.websiteChangedSubject.subscribe(this.clear.bind(this));
    this.authService.onSignOut.subscribe(this.clear.bind(this));
  }

  public initSrc() {
    if (!this.navigationService.isPageEditor && !this.navigationService.isImageManager) return;

    if (!this.isPageValid) return this.navigationService.isPageEditor ? this.navigationService.toHomePage({ isSidebarClosed: false }) : this.navigationService.toFirstPortfolio();

    if (!this.pageId && !this.pageType) this.initPageData();

    const page = this.getAsPage();

    if (!page || page.nodeId === -1 && this.navigationService.isPageEditor) return this.navigationService.toHomePage({ isSidebarClosed: false });

    this.nodeId = page.nodeId;
    this.isHomePage = page.isHomePage;
    this.isDirectLink = !!page.directLink;

    const selectedPage = this.selectedPage;
    const isSameFlow = this.isLastPageEditor === this.navigationService.isPageEditor;

    if (this.lastSelectedPage && isSameFlow && selectedPage.isSameTo(this.lastSelectedPage)) return;

    this.lastSelectedPage = selectedPage;
    this.isLastPageEditor = this.navigationService.isPageEditor;

    this.selectedPageSubject.next(selectedPage);

    if (this.navigationService.isImageManager && !this.isValidPortfolio) return this.navigationService.toFirstPortfolio();

    if (!this.navigationService.isPageEditor) return;

    this.setSrc({ id: this.pageId, type: this.pageType });

    this.navigationService.isBlogPostEditingSubject.next(this.selectedPage.type === 'B');
  }

  private initPageData(): void {
    const homePage = this.nodesService.homePage;

    this.pageId = homePage ? homePage.id : null;
    this.pageType = homePage ? homePage.type : null;
    this.isCategoryPage = false;
  }

  private getAsPage(): NodeModel {
    if (this.navigationService.isImageManager) {
      const portfolio: NodeModel = this.nodes.find((node: NodeModel) => node.id === this.pageId && this.pageType === 'P' && node.type === 'P');

      return new NodeModel(null, portfolio ? portfolio.nodeId : -1);
    }

    return this.nodes.find(({ id, type }) => this.pageId === id && this.pageType === type);
  }

  private setSrc({ id, type }): void {
    const next = `${window.location.origin}/api/app/template/${this.templateId}?pageId=${id}&pageType=${type}&isSplash=${this.isSplashPage}`;

    if (this.src === next) return;

    this.iFrameService.isLoadedSubject.next(false);
    this.iFrameService.onContentLoad.next(false);

    this.srcSubject.next(next);
  }

  private clear(): void {
    this.resetPage();

    this.srcSubject.next(null);
    this.templateId = null;
    this.pageId = null;
    this.pageType = null;
    this.isCategoryPage = false;
    this.isDirectLink = false;
    this.isSplashPage = null;
    this.isSplashEnabled = null;
  }

  public resetPage(): void {
    this.selectedPageSubject.next(null);
    this.lastSelectedPage = null;
  }
}
