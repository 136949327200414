import {ImageRemoveErrorModel} from './image-remove-error.model';

export class ImageRemoveErrorDto {
  constructor(public ImageId?: number,
              public TemplateId?: number,
              public PageId?: number,
              public PageType?: string,
              public BlockId?: number,
              public BlockCategory?: string,
              public PageTitle?: string) {}

  public static normalize(res: ImageRemoveErrorDto): ImageRemoveErrorModel {
    if (!res) return null;

    return new ImageRemoveErrorModel(
      res.ImageId,
      res.TemplateId,
      res.PageId,
      res.PageType,
      res.BlockId,
      res.BlockCategory,
      res.PageTitle,
    );
  }

  public static toRequest(model: ImageRemoveErrorModel): ImageRemoveErrorDto {
    if (!model) return null;

    return new ImageRemoveErrorDto(
        model.imageId,
        model.templateId,
        model.pageId,
        model.pageType,
        model.blockId,
        model.blockCategory,
        model.pageTitle,
    );
  }
}
