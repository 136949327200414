import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {MeasureUnitDto} from '../../../../models/measure-unit/measure-unit.dto';
import {MeasureUnitModel} from '../../../../models/measure-unit/measure-unit.model';

@Injectable()
export class MeasureUnitsHttpService {
  private model: string = 'measure-units';

  constructor(private http: HttpClient) {
  }

  public fetchById(id: number): Observable<MeasureUnitDto> {
    return this.http.get(`api/${this.model}/${id}`);
  }

  public fetchMain(): Observable<MeasureUnitDto> {
    return this.http.get(`api/${this.model}/main`);
  }

  public getAll(): Observable<MeasureUnitModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: MeasureUnitDto[]) => res.map(item => MeasureUnitDto.normalize(item)))
    );
  }

  public getForWebsite(): Observable<MeasureUnitModel> {
    return this.http.get(`api/${this.model}/current`).pipe(
      map((res: MeasureUnitDto) => MeasureUnitDto.normalize(res))
    );
  }
}
