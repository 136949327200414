
import {debounceTime} from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {take, takeUntil, distinctUntilChanged} from 'rxjs/operators';

import {CookieService} from 'ngx-cookie-service';

import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';
import {RequestInterceptorService} from '../../../../core/services/interaction/requests/interceptor/request-interceptor.service';
import {NavigationService} from '../../../../services/navigation.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {AuthService} from '../../../../auth/auth.service';
import {AppVersionChangesService} from '../../../../core/services/app-version-changes/app-version-changes.service';
import {ImageResolutionService} from '../../../../services/image-resolution.service';
import {MessageModalService} from '../../../../services/message-modal.service';
import {PublishingStatusModalService} from '../../../../shared/services/modals/publishing-status/publishing-status-modal.service';
import {FailedImagesHandlingModalService} from '../../../../shared/services/modals/failed-images-handling/failed-images-handling-modal.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {ImageUploadService} from '../../../../core/services/image-manager/upload/image-upload.service';
import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';
import {WebsitesLimitExceededModalService} from '../../../../shared/services/modals/websites-limit-exceeded/websites-limit-exceeded-modal.service';
import {TextAlertModalService} from '../../../../services/text-alert-modal.service';
import {MediaManagerService} from '../../../../core/services/media-manager/media-manager.service';
import {ImageSpecificationsModalService} from '../../../../shared/services/modals/image-specifications/image-specifications-modal.service';
import {DiskIsAlmostFullModalService} from '../../../../shared/services/modals/disk-is-almost-full/disk-is-almost-full-modal.service';
import {FirefoxAudioRecordForbiddenModalService} from '../../../../shared/services/modals/firefox-audio-record-forbidden/firefox-audio-record-forbidden-modal.service';
import {NeedToUpgradePlanModalService} from '../../../../shared/services/modals/need-to-upgrade-plan/need-to-upgrade-plan-modal.service';
import {AddBlogModalService} from '../../../../shared/services/modals/add-blog/add-blog-modal.service';
import {UpgradeSubscriptionModalService} from '../../../../shared/services/modals/upgrade-subscription/upgrade-subscription-modal.service';
import {CreditCardsModalService} from '../../../../shared/services/modals/credit-cards/credit-cards-modal.service';
import {CouponsModalService} from '../../../../shared/services/modals/coupons/coupons-modal.service';
import {CardDeclinedModalService} from '../../../../shared/services/modals/card-declined/card-declined-modal.service';
import {AddOnCardDeclinedModalService} from '../../../../shared/services/modals/add-on-card-declined/add-on-card-declined-modal.service';
import {WebsiteStatisticCollectingInfoModalService} from '../../../../shared/services/modals/website-statistic-collecting-info/website-statistic-collecting-info-modal.service';
import {WebsiteSubscriptionSelectModalService} from '../../../../shared/services/modals/website-subscription-select/website-subscription-select-modal.service';
import {ImageFileNameAsTitleModalService} from '../../../../shared/services/modals/image-file-name-as-title/image-file-name-as-title-modal.service';
import {PublishingWebsiteInfoModalService} from '../../../../shared/services/modals/publishing-website-info/publishing-website-info-modal.service';
import {TrialEndSurveyModalService} from '../../../../shared/services/modals/trial-end-survey/trial-end-survey-modal.service';
import {TourCloseModalService} from '../../../../shared/services/modals/tour-close/tour-close-modal.service';
import {RemoveSupportRowModalService} from '../../../../shared/services/modals/remove-support-row/remove-support-row-modal.service';
import {DashboardSupportCallService} from '../../../../shared/services/modals/dashboard-support-call/dashboard-support-call.service';
import {ComparePlansModalService} from '../../../../shared/services/modals/compare-plans/compare-plans-modal.service';
import {PurchaseValidationModalService} from '../../../../shared/services/modals/purchase-validation/purchase-validation-modal.service';
import {HomepageImageSelectModalService} from '../../../../shared/services/modals/homepage-image-select/homepage-image-select-modal.service';
import {CustomSubscriptionUpgradeErrorModalService} from '../../../../shared/services/modals/custom-subscription-upgrade-error/custom-subscription-upgrade-error-modal.service';
import {ImagesRemoveErrorModalService} from '../../../../shared/services/modals/images-remove-error/images-remove-error-modal.service';
import {ObjectRemoveErrorModalService} from '../../../../shared/services/modals/object-remove-error/object-remove-error-modal.service';
import {ImageReplaceInvalidationInfoModalService} from '../../../../shared/services/modals/image-replace-invalidation-info/image-replace-invalidation-info-modal.service';
import {SelectExhibitionModalService} from '../../../../shared/services/modals/education/select-exhibition/select-exhibition-modal.service';
import {DeleteExhibitionModalService} from '../../../../shared/services/modals/delete-exhibition/delete-exhibition-modal.service';
import {ReviewStudentImageModalService} from '../../../../shared/services/modals/education/review-student-image/review-student-image-modal.service';
import {ReviewDeleteConfirmationModalService} from '../../../../shared/services/modals/education/review-delete-confirmation/review-delete-confirmation-modal.service';
import {ReviewsListModalService} from '../../../../shared/services/modals/education/reviews-list/reviews-list-modal.service';
import {WrongImageTypeModalService} from '../../../../shared/services/modals/wrong-image-type/wrong-image-type-modal.service';
import {FroalaImageIsTooLargeModalService} from '../../../../shared/services/modals/froala-image-is-too-large/froala-image-is-too-large-modal.service';
import {FroalaVideoIsNotSupportedModalService} from '../../../../shared/services/modals/froala-video-is-not-supported/froala-video-is-not-supported-modal.service';
import {ImageManagerChangeWebsiteToPersonalModalService} from '../../../../shared/services/modals/image-manager-change-website-to-personal/image-manager-change-website-to-personal-modal.service';
import {WebsitesIconChangeWebsiteModalService} from '../../../../shared/services/modals/websites-icon-change-website/websites-icon-change-website-modal.service';
import {ImageManagerErrorModalService} from '../../../../shared/services/modals/image-manager-error/image-manager-error-modal.service';
import {DeletePortfolioModalService} from '../../../../shared/services/modals/delete-portfolio/delete-portfolio-modal.service';
import {SubscriptionsDisabledForImportedUsersModalService} from '../../../../shared/services/modals/subscriptions-disabled-for-imported-users-modal/subscriptions-disabled-for-imported-users-modal.service';
import {SubscriptionsDisabledForCustomSubscriptionUsersModalService} from '../../../../shared/services/modals/subscriptions-disabled-for-custom-subscription-users-modal/subscriptions-disabled-for-custom-subscription-users-modal.service';
import {AlreadySubscribedErrorModalService} from '../../../../shared/services/modals/already-subscribed-error/already-subscribed-error-modal.service';
import {CustomSubscriptionRemoveErrorModalService} from '../../../../shared/services/modals/custom-subscription-remove-error/custom-subscription-remove-error-modal.service';
import {ClassEndedModalService} from '../../../../shared/services/modals/class-ended/class-ended-modal.service';
import {CourseEndedModalService} from '../../../../shared/services/modals/education/course-ended/course-ended-modal.service';
import {CartItemDeleteModalService} from '../../../../shared/services/modals/cart-item-delete/cart-item-delete-modal.service';
import {StudentCourseEndedModalService} from '../../../../shared/services/modals/student-course-ended/student-course-ended-modal.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {StudentImageManagerService} from '../../../../core/services/education/students/image-manager/student-image-manager.service';
import {PublishingInProgressModalService} from '../../../../shared/services/modals/publishing-in-progress/publishing-in-progress-modal.service';
import {PaypalEmailNotSetInfoModalService} from '../../../../shared/services/modals/paypal-email-not-set-info/paypal-email-not-set-info-modal.service';
import {IsPublishingService} from '../../../../services/is-publishing/is-publishing.service';
import {CartService} from '../../../../core/services/cart/cart.service';
import {UpgradeSubscriptionService} from '../../../../core/services/payment/subscriptions/upgrade/upgrade-subscription.service';
import {ISocketSubscriptionsMessageDataModel} from '../../../../core/models/sockets/message/subscriptions/i-subscriptions-message-data.model';

import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

import {StudentImageManagerTabs} from '../../../../core/services/education/students/image-manager/constants';

@Component({
  selector: 'app-modal-container',
  templateUrl: './app-modal-container.component.html',
  styleUrls: ['./app-modal-container.component.scss'],
})
export class AppModalContainerComponent implements OnInit, OnDestroy {
  private interval = 30 * 1000; // 30 seconds check interval

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get fontsManagerModalId(): string {
    return this.googleFontsService.modalId;
  }

  public get addFontModalId(): string {
    return this.googleFontsService.addModalId;
  }

  public get removeFontModalId(): string {
    return this.googleFontsService.removeModalId;
  }

  public get websitesLimitExceededModalId(): string {
    return this.websitesLimitExceededModalService.id;
  }

  public get addBlogModalId(): string {
    return this.addBlogModalService.id;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private modalsService: ModalsService,
    private socketsService: SocketsService,
    private requestInterceptorService: RequestInterceptorService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private cookieService: CookieService,
    private websitesLimitExceededModalService: WebsitesLimitExceededModalService,
    private addBlogModalService: AddBlogModalService,
    private studentImageManagerService: StudentImageManagerService,
    private websitesService: WebsitesService,
    private eventsService: EventsService,
    private cartService: CartService,
    private upgradeSubscriptionService: UpgradeSubscriptionService,
    public mediaManagerService: MediaManagerService,
    public googleFontsService: GoogleFontsService,
    public appVersionChangesService: AppVersionChangesService,
    public imageResolutionService: ImageResolutionService,
    public paymentSubscriptionsService: PaymentSubscriptionsService,
    public upgradeSubscriptionModalService: UpgradeSubscriptionModalService,
    public imageUploadService: ImageUploadService,
    public imageSpecificationsModalService: ImageSpecificationsModalService,
    public messageModalService: MessageModalService,
    public creditCardsModalService: CreditCardsModalService,
    public websiteStatisticCollectingInfoModalService: WebsiteStatisticCollectingInfoModalService,
    public websiteSubscriptionSelectModalService: WebsiteSubscriptionSelectModalService,
    public couponsModalService: CouponsModalService,
    public cardDeclinedModalService: CardDeclinedModalService,
    public addOnCardDeclinedModalService: AddOnCardDeclinedModalService,
    public trialEndSurveyModalService: TrialEndSurveyModalService,
    public tourCloseModalService: TourCloseModalService,
    public dashboardSupportCallService: DashboardSupportCallService,
    public comparePlansModalService: ComparePlansModalService,
    public publishingStatusModalService: PublishingStatusModalService,
    public publishingWebsiteInfoModalService: PublishingWebsiteInfoModalService,
    public purchaseValidationModalService: PurchaseValidationModalService,
    public failedImagesHandlingModalService: FailedImagesHandlingModalService,
    public textAlertModalService: TextAlertModalService,
    public diskIsAlmostFullModalService: DiskIsAlmostFullModalService,
    public firefoxAudioRecordForbiddenModalService: FirefoxAudioRecordForbiddenModalService,
    public imageFileNameAsTitleModalService: ImageFileNameAsTitleModalService,
    public needToUpgradePlanModalService: NeedToUpgradePlanModalService,
    public homepageImageSelectModalService: HomepageImageSelectModalService,
    public customSubscriptionUpgradeErrorModalService: CustomSubscriptionUpgradeErrorModalService,
    public imagesRemoveErrorModalService: ImagesRemoveErrorModalService,
    public objectRemoveErrorModalService: ObjectRemoveErrorModalService,
    public imageReplaceInvalidationInfoModalService: ImageReplaceInvalidationInfoModalService,
    public selectExhibitionModalService: SelectExhibitionModalService,
    public deleteExhibitionModalService: DeleteExhibitionModalService,
    public reviewStudentImageModalService: ReviewStudentImageModalService,
    public reviewDeleteConfirmationModalService: ReviewDeleteConfirmationModalService,
    public reviewsListModalService: ReviewsListModalService,
    public courseEndedModalService: CourseEndedModalService,
    public imageManagerChangeWebsiteToPersonalModalService: ImageManagerChangeWebsiteToPersonalModalService,
    public websitesIconChangeWebsiteModalService: WebsitesIconChangeWebsiteModalService,
    public wrongImageTypeModalService: WrongImageTypeModalService,
    public froalaImageIsTooLargeModalService: FroalaImageIsTooLargeModalService,
    public froalaVideoIsNotSupportedModalService: FroalaVideoIsNotSupportedModalService,
    public imageManagerErrorModalService: ImageManagerErrorModalService,
    public subscriptionsDisabledForImportedUsersModalService: SubscriptionsDisabledForImportedUsersModalService,
    public subscriptionsDisabledForCustomSubscriptionUsersModalService: SubscriptionsDisabledForCustomSubscriptionUsersModalService,
    public alreadySubscribedErrorModalService: AlreadySubscribedErrorModalService,
    public customSubscriptionRemoveErrorModalService: CustomSubscriptionRemoveErrorModalService,
    public classEndedModalService: ClassEndedModalService,
    public studentCourseEndedModalService: StudentCourseEndedModalService,
    public removeSupportRowModalService: RemoveSupportRowModalService,
    public deletePortfolioModalService: DeletePortfolioModalService,
    public publishingInProgressModalService: PublishingInProgressModalService,
    public paypalEmailNotSetInfoModalService: PaypalEmailNotSetInfoModalService,
    public cartItemDeleteModalService: CartItemDeleteModalService,
    public isPublishingService: IsPublishingService,
  ) {
    this.eventsService.addFrameListener('openFroalaImageIsTooLargeModal', (e: CustomEvent) => {
      this.froalaImageIsTooLargeModalService.open({
        sizeLimitMb: e.detail.sizeLimitMb,
      });
    });
    
    this.eventsService.addFrameListener('openFroalaVideoIsNotSupportedModal', (e: CustomEvent) => {
      this.froalaVideoIsNotSupportedModalService.open();
    });
  }

  public ngOnInit(): void {
    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(modalsStatus => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });

    this.requestInterceptorService.onCourseEndedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((err: { key: string, message?: string, data?: any }) => {
      if (!err || !err.data) {
        return;
      }

      this.handleExpiredStudentSubscription({
        websiteId: err.data.websiteId,
      });
    });

    this.socketsService.subscriptionsDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISocketSubscriptionsMessageDataModel) => {
      if (!data || data.action !== 'STUDENT_EXPIRED') {
        return;
      }

      this.handleExpiredStudentSubscription({
        websiteId: data.websiteId,
      });
    });

    if (this.cookieService.check('vs.expires')) {
      // check for session expiring
      setInterval(() => {

        // if user is logged in then track session expiring
        if (this.authService.user) {
          const expires         = new Date(this.cookieService.get('vs.expires')).valueOf();
          const now             = new Date().valueOf();
          const expires_in_2min = new Date(expires).setMinutes(new Date(expires).getMinutes() - 2);
          const expired         = new Date(expires).setSeconds(new Date(expires).getSeconds() + 30);

          // 2 minutes before session expires prompt the user
          if (now >= expires_in_2min) this.modalsService.open('session-expiring-modal');

          if (now > expired) {
            this.closeModal('session-expiring-modal');
            this.navigationService.toLogin();
          }
        }
      }, this.interval);
    }
    
    this.isPublishingService.openWarningModal.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.publishingInProgressModalService.open();
    });
  }

  private handleExpiredStudentSubscription({ websiteId }: { websiteId: number }): void {
    this.websitesService.activeWebsiteIdSubject.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      take(1),
    ).subscribe((activeWebsiteId: number) => {
      this.studentImageManagerService.activeTabSubject.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        take(1),
      ).subscribe((activeTab: StudentImageManagerTabs) => {
        if (!websiteId || activeTab !== 'student') {
          return;
        }
        
        const isSameWebsiteActive: boolean = websiteId === activeWebsiteId;

        this.studentCourseEndedModalService.open({ websiteId: activeWebsiteId, isSameWebsiteActive });
        
        if (!isSameWebsiteActive) {
          return;
        }

        if (this.navigationService.isSettings) {
          return;
        }

        this.navigationService.toPurchase();
      });
    });
  }

  refreshSession() {
    this.closeModal('session-expiring-modal');

    this.authService.ping().subscribe(() => {});
  }

  closeModal(id: string) {
    this.modalsService.close(id);
  }

  public removeCartItem(): void {
    this.cartService.deleteItem(this.cartItemDeleteModalService.item).add(() => {
      this.cartItemDeleteModalService.close();

      this.paymentSubscriptionsService.initProration(this.upgradeSubscriptionService.couponCode);
    });
  }

  public cancelCartItemRemove(): void {
    this.cartItemDeleteModalService.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
