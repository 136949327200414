// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px 8px 8px 8px;
  background: #eaeaea;
}
.popup-input .title {
  font-size: 11px;
  font-weight: 600;
}
.popup-input .content {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}
.popup-input .content input {
  font-size: 12px;
  width: 200px;
}
.popup-input .content .enter-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-left: 6px;
  border: 1px solid #cecece;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 4px;
  padding-top: 2px;
  font-size: 14px;
}
.popup-input .content .enter-icon:hover {
  background: #c7c7c7;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popup-input/popup-input.component.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,kBAAA;EACA,wBAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAII;EACE,eAAA;EACA,YAAA;AAFN;AAKI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AAHN;AAKM;EACE,mBAAA;AAHR","sourcesContent":["@import \"../../../../styles/colors\";\n\n.popup-input {\n  border: 1px solid $primary;\n  border-radius: 5px;\n  padding: 4px 8px 8px 8px;\n  background: #eaeaea;\n\n  .title {\n    font-size: 11px;\n    font-weight: 600;\n  }\n\n  .content {\n    margin-top: 5px;\n    display: flex;\n    flex-direction: row;\n\n    input {\n      font-size: 12px;\n      width: 200px;\n    }\n\n    .enter-icon {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      width: 20px;\n      margin-left: 6px;\n      border: 1px solid #cecece;\n      border-radius: 5px;\n      cursor: pointer;\n      padding-left: 4px;\n      padding-top: 2px;\n      font-size: 14px;\n\n      &:hover {\n        background: #c7c7c7;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
