import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ImageManagerService} from '../../../../../../application/main/image-manager/image-manager.service';
import {AuthService} from '../../../../../../auth/auth.service';
import {SocketsService} from '../../../../interaction/sockets/sockets.service';
import {StudentPortfoliosService} from '../../websites/portfolios/student-portfolios.service';
import {EducationStudentsService} from '../../education-students.service';

import {ImagesCounterModel} from '../../../../../models/image-manager/counters/images-counter.model';
import {PortfolioModel} from '../../../../../models/portfolios/portfolio.model';
import {EducationStudentModel} from '../../../../../models/education/students/education-student.model';
import {AccountModel} from '../../../../../models/accounts/account.model';
import {ISocketImageManagerMessageDataModel} from '../../../../../models/sockets/message/image-manager/i-image-manager-message-data.model';
import {ImageDataModel} from '../../../../../models/image-manager/counters/image-data.model';

import {LIBRARY_ID} from '../../../../../../application/constants';
import {SOCKET_ACTIONS} from '../../../../../../application/main/image-manager/constants';

@Injectable()
export class StudentImagesCounterService {
  public countersSubject: BehaviorSubject<ImagesCounterModel> = new BehaviorSubject<ImagesCounterModel>(new ImagesCounterModel());

  private student: EducationStudentModel;

  private portfolios: PortfolioModel[];

  private librarySize: number = 0;

  private socketMessageHandlers = {
    [SOCKET_ACTIONS.IMAGE_REORDER_STOP]: this.onReorderStop.bind(this),
  };

  private get counters(): ImagesCounterModel {
    return this.countersSubject.value;
  }

  constructor(
    private studentsService: EducationStudentsService,
    private studentPortfoliosService: StudentPortfoliosService,
    private imageManagerService: ImageManagerService,
    private socketsService: SocketsService,
    private authService: AuthService,
  ) {
    this.studentsService.studentDetailsSubject.subscribe((student: EducationStudentModel) => {
      this.student = student;
    });

    this.studentPortfoliosService.portfoliosSubject.subscribe((portfolios: PortfolioModel[]) => {
      this.portfolios = portfolios || [];

      this.initCounters();
    });

    this.socketsService.imageManagerDataSubject.subscribe((data: ISocketImageManagerMessageDataModel) => {
      if (!data || !this.socketMessageHandlers[data.action]) {
        return;
      }
  
      this.socketMessageHandlers[data.action](data);
    });

    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.initLibrarySize(account);
    });
  }

  private initLibrarySize(account: AccountModel) {
    this.imageManagerService.getLibrarySize().subscribe((data) => {
      this.librarySize = data ? data.size : 0;
  
      this.initCounters();
    });
  }

  private initCounters(): void {
    if (!this.portfolios) {
      return;
    }

    const counters = new ImagesCounterModel();

    this.portfolios.forEach((portfolio: PortfolioModel) => {
      counters.published[portfolio.id] = portfolio.publishedImagesCount || 0;
      counters.total[portfolio.id] = portfolio.totalImagesCount || 0;
    });

    counters.total[LIBRARY_ID] = this.librarySize;

    this.countersSubject.next(counters);
  }

  private onReorderStop(data: ISocketImageManagerMessageDataModel): void {
    if (!this.student || data.userId !== this.student.userId) {
      return;
    }

    const counters: ImagesCounterModel = this.countersSubject.value;

    this.studentPortfoliosService.fetchCountersForPortfolio({
      websiteId: data.websiteId,
      portfolioId: data.portfolioId,
    }).subscribe((portfolio: PortfolioModel) => {
      if (!portfolio) {
        return;
      }

      counters.published[portfolio.id] = portfolio.publishedImagesCount || 0;
      counters.total[portfolio.id] = portfolio.totalImagesCount || 0;

      this.countersSubject.next(counters);
    });
  }

  public onImageAdd(id: number, count: number = 1) {
    this.counters.onImageAdd(id, count);
    
    this.notifyAboutChanges();
  }

  public onImageRemove(id: number, imagesData: ImageDataModel[]) {
    this.counters.onImageRemove(id, imagesData);

    this.notifyAboutChanges();
  }

  private notifyAboutChanges() {
    this.countersSubject.next(this.countersSubject.value);
  }
}
