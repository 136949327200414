// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.delete-images-modal .modal-content-wrapper {
  margin: 20px;
  clear: both;
  overflow: hidden;
}
.delete-images-modal .modal-content-wrapper .question-text {
  font-size: 0.9em;
}
.delete-images-modal .modal-content-wrapper .actions {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/delete-all-images-modal/delete-all-images-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACF;;AAGE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAGI;EACE,gBAAA;AADN","sourcesContent":[":host {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 9999;\n}\n\n.delete-images-modal {\n  .modal-content-wrapper {\n    margin: 20px;\n    clear: both;\n    overflow: hidden;\n\n    .question-text {\n      font-size: 0.9em;\n    }\n\n    .actions {\n      margin-top: 20px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
