// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-icon-picker {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  color: #ccc;
  background-color: #fff;
  padding: 3px;
  border-radius: 5px;
}
.border-icon-picker .selected-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 12px;
  background-color: #fff;
  padding: 1px 0;
  height: 15px;
  background-size: contain;
  background-repeat: repeat-x;
}
.border-icon-picker .icon-list {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 5px;
}
.border-icon-picker .icon-list.hidden {
  display: none;
}
.border-icon-picker .icon-list li {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 12px;
  padding: 3px;
  margin: 3px 3px;
  height: 15px;
  background-size: contain;
  background-repeat: repeat-x;
}
.border-icon-picker .icon-list li.selected {
  background-color: #ddd;
}
.border-icon-picker .icon-list li:hover {
  background-color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/details/styles/border-icon-picker/border-icon-picker.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,wBAAA;EACA,2BAAA;AADJ;AAIE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,sBAAA;EACA,WAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAII;EACE,aAAA;AAFN;AAKI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,wBAAA;EACA,2BAAA;AAHN;AAKM;EACE,sBAAA;AAHR;AAMM;EACE,sBAAA;AAJR","sourcesContent":["\n\n.border-icon-picker {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  font-size: 14px;\n  font-weight: 300;\n  cursor: pointer;\n  color: #ccc;\n  background-color: #fff;\n  padding: 3px;\n  border-radius: 5px;\n\n  .selected-icon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: black;\n    font-size: 12px;\n    background-color: #fff;\n    padding: 1px 0;\n    height: 15px;\n    background-size: contain;\n    background-repeat: repeat-x;\n  }\n\n  .icon-list {\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: #fff;\n    z-index: 10;\n    width: 100%;\n    max-height: 300px;\n    overflow-y: auto;\n    border-radius: 5px;\n\n    &.hidden {\n      display: none;\n    }\n\n    li {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      color: black;\n      font-size: 12px;\n      padding: 3px;\n      margin: 3px 3px;\n      height: 15px;\n      background-size: contain;\n      background-repeat: repeat-x;\n\n      &.selected {\n        background-color: #ddd;\n      }\n\n      &:hover {\n        background-color: #ddd;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
