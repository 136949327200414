import {ColorModel} from '../color.model';

import {KEYS, RELATIONS} from './constants';

export class ColorIntersectionModel {
  public colorsData = {
    [KEYS.DESKTOP_TITLE]: { color: null, isColorOkay: true },
    [KEYS.DESKTOP_SUBTITLE]: { color: null, isColorOkay: true },
    [KEYS.DESKTOP_MENU_TEXT]: { color: null, isColorOkay: true },
    [KEYS.DESKTOP_MENU_HOVER]: { color: null, isColorOkay: true },
    [KEYS.DESKTOP_MENU_ACTIVE]: { color: null, isColorOkay: true },
    [KEYS.DESKTOP_MENU_HAMBURGER]: { color: null, isColorOkay: true },
    [KEYS.DESKTOP_MENU_BACKGROUND]: { color: null, isColorOkay: true },
    [KEYS.MOBILE_TITLE]: { color: null, isColorOkay: true },
    [KEYS.MOBILE_SUBTITLE]: { color: null, isColorOkay: true },
    [KEYS.MOBILE_MENU_TEXT]: { color: null, isColorOkay: true },
    [KEYS.MOBILE_MENU_HOVER_BACKGROUND]: { color: null, isColorOkay: true },
    [KEYS.MOBILE_MENU_HOVER_TEXT_COLOR]: { color: null, isColorOkay: true },
    [KEYS.MOBILE_MENU_ACTIVE]: { color: null, isColorOkay: true },
    [KEYS.MOBILE_MENU_BACKGROUND]: { color: null, isColorOkay: true },
    [KEYS.COMMON_BACKGROUND]: { color: null, isColorOkay: true },
    [KEYS.COMMON_TEXT]: { color: null, isColorOkay: true },
    [KEYS.COMMON_LINK]: { color: null, isColorOkay: true },
    [KEYS.COMMON_LINK_HOVER]: { color: null, isColorOkay: true },
  };

  private keys = Object.keys(this.colorsData);

  public initColor(key: string, color: string) {
    if (!color) {
      return console.error(`ColorIntersectionModel: can't init color for ${key}`);
    }

    this.colorsData[key].color = new ColorModel(color);
  }

  public calculate() {
    this.keys.forEach(key => {
      const data = this.colorsData[key];
      const relations = RELATIONS[key].map(relationKey => this.colorsData[relationKey]);

      data.isColorOkay = relations.every(relation => !ColorModel.isIntersection(data.color, relation.color));
    });
  }

  public isColorOkay(key: string) {
    const data = this.colorsData[key];

    return !!data && data.isColorOkay;
  }
}
