import {Component} from '@angular/core';

@Component({
  selector: 'app-vs-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  onLogoClick() {
    window.location.href = `/home${window.location.search}`;
  }
}
