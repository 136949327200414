import {TabSettingsModel} from '../../models/sidebar/tabs-settings.model';

export const KEYS = {
  ADMIN: 'ADMIN',
  EDUCATOR_ADMIN: 'EDUCATOR_ADMIN',
  EDUCATOR: 'EDUCATOR',
  STUDENT: 'STUDENT',
  WEBSITES_MANAGER: 'WEBSITES_MANAGER',
  CLASSES_MANAGER: 'CLASSES_MANAGER',
  WEBSITE: 'WEBSITE',
  PROFILE: 'PROFILE',
  DASHBOARD: 'DASHBOARD',
  SETTINGS: 'SETTINGS',
  TEMPLATES: 'TEMPLATES',
  IMAGES: 'IMAGES',
  MEDIA: 'MEDIA',
  PAGES: 'PAGES',
  BLOCKS: 'BLOCKS',
  CUSTOMIZER: 'CUSTOMIZER',
  MAIL: 'MAIL',
  BLOG: 'BLOG',
  HELP: 'HELP',
};

export const EDUCATOR_KEYS: string[] = [
  KEYS.EDUCATOR_ADMIN,
  KEYS.WEBSITE,
];

export const STUDENT_KEYS: string[] = [
  KEYS.IMAGES,
];

export const USER_TAB_KEYS: string[] = [
  KEYS.TEMPLATES,
  KEYS.PAGES,
  KEYS.BLOCKS,
  KEYS.BLOG,
  KEYS.CUSTOMIZER,
  KEYS.MEDIA,
  KEYS.PROFILE,
  KEYS.SETTINGS,
  KEYS.DASHBOARD,
];

export const DISABLED_TABS_DATA = {
  [KEYS.ADMIN]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.EDUCATOR_ADMIN]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.EDUCATOR]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.WEBSITE]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.PROFILE]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.DASHBOARD]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.SETTINGS]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.TEMPLATES]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.IMAGES]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.MEDIA]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.MAIL]: [
    KEYS.CUSTOMIZER,
  ],
  [KEYS.BLOG]: [
    KEYS.BLOCKS,
  ],
  [KEYS.HELP]: [
    KEYS.CUSTOMIZER,
  ],
};

export const TABS: { [key: string]: TabSettingsModel } = {
  [KEYS.ADMIN]: new TabSettingsModel(KEYS.ADMIN, 'ADMIN', false, DISABLED_TABS_DATA[KEYS.ADMIN]),
  [KEYS.EDUCATOR_ADMIN]: new TabSettingsModel(KEYS.EDUCATOR_ADMIN, 'ADMIN', false, DISABLED_TABS_DATA[KEYS.EDUCATOR_ADMIN]),
  [KEYS.EDUCATOR]: new TabSettingsModel(KEYS.EDUCATOR, 'EDUCATOR', false, DISABLED_TABS_DATA[KEYS.EDUCATOR]),
  [KEYS.WEBSITE]: new TabSettingsModel(KEYS.WEBSITE, 'WEBSITE', false, DISABLED_TABS_DATA[KEYS.WEBSITE]),
  [KEYS.PROFILE]: new TabSettingsModel(KEYS.PROFILE, 'PROFILE', false, DISABLED_TABS_DATA[KEYS.PROFILE]),
  [KEYS.DASHBOARD]: new TabSettingsModel(KEYS.DASHBOARD, 'DASHBOARD', false, DISABLED_TABS_DATA[KEYS.DASHBOARD]),
  [KEYS.SETTINGS]: new TabSettingsModel(KEYS.SETTINGS, 'SETTINGS', false, DISABLED_TABS_DATA[KEYS.SETTINGS]),
  [KEYS.TEMPLATES]: new TabSettingsModel(KEYS.TEMPLATES, 'TEMPLATES', false, DISABLED_TABS_DATA[KEYS.TEMPLATES]),
  [KEYS.IMAGES]: new TabSettingsModel(KEYS.IMAGES, 'IMAGES', false, DISABLED_TABS_DATA[KEYS.IMAGES]),
  [KEYS.MEDIA]: new TabSettingsModel(KEYS.MEDIA, 'MEDIA', false, DISABLED_TABS_DATA[KEYS.MEDIA]),
  [KEYS.PAGES]: new TabSettingsModel(KEYS.PAGES, 'PAGES'),
  [KEYS.BLOCKS]: new TabSettingsModel(KEYS.BLOCKS, 'BLOCKS'),
  [KEYS.CUSTOMIZER]: new TabSettingsModel(KEYS.CUSTOMIZER, 'CUSTOMIZER'),
  [KEYS.MAIL]: new TabSettingsModel(KEYS.MAIL, 'MAIL', false, DISABLED_TABS_DATA[KEYS.MAIL]),
  [KEYS.BLOG]: new TabSettingsModel(KEYS.BLOG, 'BLOG', false, DISABLED_TABS_DATA[KEYS.BLOG]),
  [KEYS.HELP]: new TabSettingsModel(KEYS.HELP, 'HELP', false, DISABLED_TABS_DATA[KEYS.HELP]),
};

export const TABS_LIST: TabSettingsModel[] = Object.keys(TABS).map((key: string) => TABS[key]);

export const ACCESS_KEYS: { [key: string]: string } = {
  FULL: 'FULL',
  EDUCATOR: 'EDUCATOR',
  STUDENT: 'STUDENT',
  EMPTY: 'EMPTY',
};
