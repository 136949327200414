
import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Subject, throwError} from 'rxjs';
import {catchError, filter, finalize, take, takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../app-animations';

import { Dropzone } from 'dropzone';
import * as $ from 'jquery';
import 'jqueryui';
import Sortable = JQueryUI.Sortable;

import {CanLeaveComponent} from '../../../shared/services/guards/can-leave-component-guard.service';

import {AuthService} from '../../../auth/auth.service';
import {BagService} from '../../../bag.service';
import {EducationImageManagerService} from '../../../core/services/education/image-manager/education-image-manager.service';
import {EducationExhibitionsImagesCounterService} from '../../../core/services/education/image-manager/exhibitions-counters/education-exhibitions-images-counter.service';
import {EducationImageManagerActionService} from '../../../core/services/education/image-manager/reorder/education-image-manager-reorder.service';
import {EducationStudentsService} from '../../../core/services/education/students/education-students.service';
import {EducationStudentsImagesService} from '../../../core/services/education/teachers/institutions/classes/students/images/education-students-images.service';
import {EducationWebsiteExhibitionsService} from '../../../core/services/education/teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';
import {EducationImagesService} from '../../../core/services/education/teachers/institutions/classes/websites/exhibitions/images/education-images.service';
import {FullscreenService} from '../../../core/services/fullscreen/fullscreen.service';
import {IFrameRoutingService} from '../../../core/services/iframe/routing/iframe-routing.service';
import {ImagesCounterService} from '../../../core/services/image-manager/counters/images-counter.service';
import {ImagesIconsService} from '../../../core/services/image-manager/icons/images-icons.service';
import {ImageDetailsService} from '../../../core/services/image-manager/image-details/image-details.service';
import {PortfolioDefaultsService} from '../../../core/services/image-manager/portfolio-defaults/portfolio-defaults.service';
import {ImageUploadService} from '../../../core/services/image-manager/upload/image-upload.service';
import {ImagesHttpService} from '../../../core/services/interaction/http/images/images-http.service';
import {SocketsService} from '../../../core/services/interaction/sockets/sockets.service';
import {ContentLoaderService} from '../../../core/services/loaders/content/content-loader.service';
import {NodesService} from '../../../core/services/nodes/nodes.service';
import {PaymentSubscriptionsService} from '../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {PermissionsService} from '../../../core/services/service-permissions/permissions/permissions.service';
import {UtilsService} from '../../../core/services/utils/utils.service';
import {WebsiteTourService} from '../../../core/services/website-tour/website-tour.service';
import {WebsitesExclamationIconService} from '../../../core/services/websites-exclamation-icon/websites-exclamation-icon.service';
import {WebsitesService} from '../../../core/services/websites/websites.service';
import {ImageProcessesService} from '../../../services/image-processes.service';
import {ImageResolutionService} from '../../../services/image-resolution.service';
import {NavigationService} from '../../../services/navigation.service';
import {ImageManagerModalService} from '../../../shared/components/modals/image-manager-modal/image-manager-modal.service';
import {FullscreenImageManagerEnlargementService} from '../../../shared/services/fullscreen-image-manager-enlargement/fullscreen-image-manager-enlargement.service';
import {ReviewStudentImageModalService} from '../../../shared/services/modals/education/review-student-image/review-student-image-modal.service';
import {ReviewsListModalService} from '../../../shared/services/modals/education/reviews-list/reviews-list-modal.service';
import {SelectExhibitionModalService} from '../../../shared/services/modals/education/select-exhibition/select-exhibition-modal.service';
import {ImageFileNameAsTitleModalService} from '../../../shared/services/modals/image-file-name-as-title/image-file-name-as-title-modal.service';
import {ImageReplaceInvalidationInfoModalService} from '../../../shared/services/modals/image-replace-invalidation-info/image-replace-invalidation-info-modal.service';
import {ImagesRemoveErrorModalService} from '../../../shared/services/modals/images-remove-error/images-remove-error-modal.service';
import {ModalsService} from '../../../shared/services/modals/modals.service';
import {PagesService} from '../../sidebar-short/sidebar/pages/pages.service';
import {ImageManagerService} from './image-manager.service';
import {PortfolioViewsService} from '../../../core/services/portfolios/views/portfolio-views.service';
import {StudentPortfoliosService} from '../../../core/services/education/students/websites/portfolios/student-portfolios.service';
import {StudentImageManagerService} from '../../../core/services/education/students/image-manager/student-image-manager.service';
import {StudentImagesCounterService} from '../../../core/services/education/students/image-manager/student-counters/student-images-counter.service';
import {StudentImageMoveService} from '../../../core/services/education/students/image-manager/image-move/student-image-move.service';
import {CoreImageManagerService} from '../../../core/services/image-manager/core-image-manager.service';

import {AccountModel} from '../../../core/models/accounts/account.model';
import {ImageManagerActionDataModel} from '../../../core/models/education/image-manager-action/image-manager-action-data.model';
import {EducationExhibitionPortfolioModel} from '../../../core/models/education/portfolios/education-exhibition-portfolio.model';
import {EducationStudentPortfolioModel} from '../../../core/models/education/portfolios/education-student-portfolio.model';
import {ImageRemoveErrorDto} from '../../../core/models/errors/images/remove/image-remove-error.dto';
import {ExclamationIconInfoModel} from '../../../core/models/exclamation-icon-info/exclamation-icon-info.model';
import {ImageDataModel} from '../../../core/models/image-manager/counters/image-data.model';
import {PortfolioDefaultsModel} from '../../../core/models/images/default/portfolio-defaults.model';
import {ImageDto} from '../../../core/models/images/image.dto';
import {ImageModel} from '../../../core/models/images/image.model';
import {StudentImageReviewModel} from '../../../core/models/images/review/student-image-review.model';
import {ModalDataModel} from '../../../core/models/modals/modal-data.model';
import {NodeModel} from '../../../core/models/nodes/node.model';
import {SubscriptionModel} from '../../../core/models/payment/subscriptions/subscription.model';
import {PortfolioModel} from '../../../core/models/portfolios/portfolio.model';
import {SelectedPageModel} from '../../../core/models/selected-page/selected-page.model';
import {ImageManagerUserTypeModel} from '../../../core/models/image-manager/user-type/user-type.model';
import {ISocketImageManagerMessageDataModel} from '../../../core/models/sockets/message/image-manager/i-image-manager-message-data.model';
import {IPermissionData} from '../../../core/models/permission/i-permission-data';
import {INewReviewsData} from '../../../core/models/education/students/images/reviews/i-new-reviews-data.model';

import {EducatorImageManagerTabs} from '../../../core/services/education/image-manager/constants';
import {StudentImageManagerTabs} from '../../../core/services/education/students/image-manager/constants';
import {PERMISSIONS} from '../../../core/services/service-permissions/constants';
import {KEYS} from '../../../core/services/website-tour/constants';
import {IMAGE_RESOLUTIONS} from '../../../services/image-resolution.constants';
import {ERRORS_KEYS} from '../../../shared/services/errors/constants';
import {LIBRARY_ID} from '../../constants';
import {SOCKET_ACTIONS, TOUR_KEY} from './constants';

import {dropzoneConfig} from './dropzone.config';

const IMAGES_PER_PAGE = 20;

@Component({
  selector: 'app-image-manager',
  templateUrl: './image-manager.component.html',
  styleUrls: ['./image-manager.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageManagerComponent implements OnInit, OnDestroy, CanLeaveComponent {
  @ViewChild('enlargedImage') enlargedImage: ElementRef;
  @ViewChild('largeImageLabelWrapper') largeImageLabelWrapper: ElementRef;

  private key: string = 'ImageManagerComponent';

  get title() {
    if (!this.imageDetails) {
      return 'Title not set';
    }

    if (this.imageDetails.isTitleDefault) {
      return this.portfolioDefaults ? this.portfolioDefaults.title : '';
    }

    return this.imageDetails.title;
  }

  public account: AccountModel;

  DEFAULT_IMAGE_HEIGHT = 320;

  imagesWithFixedSrc = {};

  images: ImageModel[];
  allImages: ImageModel[];
  currentImages: ImageModel[] = [];
  selected: ImageModel[] = [];
  loading = true;
  thumbHeight: number;

  private dropzoneEmptyMessages = {
    'student': 'No Images',
    'students': 'No Images',
    'exhibitions': 'Drop JPG Files Here',
    'user': 'Drop JPG Files Here',
  };

  public dropzoneEmptyMessage: string = 'Drop JPG Files Here';

  public largeImage: ImageModel;
  public currentImageReview: StudentImageReviewModel = null;
  public currentImageExhibitedImages: ImageModel[] = null;
  public largeStudentImageReview: StudentImageReviewModel = null;

  public hoveredStarIndex: number = 0;

  public isPageBlocked: boolean = false;
  public isBlockedByAction: boolean = false;
  public isSelectedImageExhibited: boolean = false;
  public isImageLabelOpened: boolean = false;
  public isReviewStarsBlocked: boolean = false;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private portfolioDefaults: PortfolioDefaultsModel = null;
  private imageDetails: ImageModel = null;

  public isNewDragging: boolean = false;
  
  public isStudentsDropErrorModalVisible: boolean = false;

  loadedImagesCounter = 0;

  public selectedPage: SelectedPageModel = null;

  private isImagesMoving: boolean = false;

  private nodes = [];

  // Dropzone
  private dropboxInitCompleted: boolean = false;
  
  dropzone: any;

  public config: any = Object.assign({}, dropzoneConfig.imageManager, {
    dictResponseError: 'Error while uploading',
    init: this.handleDropzoneInit.bind(this),
    resize: this.handleDropzoneResize.bind(this),
    error: this.handleDropzoneError.bind(this),
    success: this.handleDropzoneFileUploadSuccess.bind(this),
    queuecomplete: this.handleDropzoneQueueComplete.bind(this),
    processing: this.onDropzoneProcessing.bind(this),
    sending: this.addDynamicParams.bind(this),
    dragenter: (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e && e.dataTransfer && e.dataTransfer.types && !e.dataTransfer.types.includes('Files')) {
        return false;
      }

      const target: HTMLElement = <HTMLElement>e.currentTarget;

      target.style.pointerEvents = 'none';
      
      this.onDragEnter();

      window.setTimeout(() => {
        target.style.removeProperty('pointer-events');
      }, 100);

      return false;
    },
  });
  private dragging = false;
  private dragTimer;

  private onResize: any;

  private isImageFileNameAsTitle: boolean = false;

  public exclamationIconData: ExclamationIconInfoModel;

  public activeTeacherTab: EducatorImageManagerTabs;
  public activeStudentTab: StudentImageManagerTabs;

  public currentView: string = null;
  public view: string = null;

  public isLoaded: boolean = false;
  public isImagesDeleting: boolean = false;
  public isLoaderVisible: boolean = false;
  
  public isRegularUser: boolean = false;
  public isEducatorImageManager: boolean = false;
  public isStudentImageManager: boolean = false;
  public isImageReviewsPermitted: boolean = false;
  public isImageRatingPermitted: boolean = false;
  public isExhibitButtonBlocked: boolean = false;
  public isExhibitButtonDone: boolean = false;
  public isDropzoneInitCompleted: boolean = false;

  private websiteId: number;

  private currentSubscription: SubscriptionModel;

  private studentPortfolio: PortfolioModel;

  private educatorStudentPortfolio: EducationStudentPortfolioModel;
  
  private teacherExhibitions: PortfolioModel[];
  private exhibitionPortfolio: EducationExhibitionPortfolioModel;

  private reorderData: { [key: string]: ImageManagerActionDataModel };

  private newReviewsData: INewReviewsData;

  private imagesLoadedSubject: Subject<boolean> = new Subject<boolean>();

  DELETE_IMAGE_MODAL_ID = 'delete-image-modal';
  DELETE_ALL_IMAGES_MODAL_ID = 'delete-all-images-modal';

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  files = []; // Files that were added by 'addImage'. Used for 'publishAllImages'.

  private userData: ImageManagerUserTypeModel;

  private isDestroyed: boolean = false;

  private nodeSelectedHandlers: { [key: string]: (options?: any) => void } = {
    'student': this.handleStudentTabSelected.bind(this),
    'students': this.handleStudentsTabSelected.bind(this),
    'exhibitions': this.handleExhibitionsTabSelected.bind(this),
    'user': this.handleNodeSelected.bind(this),
  };

  private isImagesRelatedToPageResolvers = {
    'student': this.isImagesRelatedToStudentPage.bind(this),
    'students': this.isImagesRelatedToEducatorStudentPage.bind(this),
    'exhibitions': this.isImagesRelatedToExhibitionPage.bind(this),
    'user': this.isImagesRelatedToUserPage.bind(this),
  };

  private imageUploadSuccessHandlers = {
    'student': this.onStudentImageUploadSuccess.bind(this),
    'exhibitions': this.onExhibitionImageUploadSuccess.bind(this),
    'user': this.onUserImageUploadSuccess.bind(this),
  };

  private publishImageHandlers = {
    'exhibitions': this.publishExhibitionImage.bind(this),
    'user': this.publishUserImage.bind(this),
  };

  private replaceImageHandlers = {
    'student': this.onStudentImageReplace.bind(this),
    'exhibitions': this.onExhibitionImageReplace.bind(this),
    'user': this.onUserImageReplace.bind(this),
  };

  private moveToLibraryHandlers = {
    'student': this.moveStudentImageToLibrary.bind(this),
    'exhibitions': this.moveExhibitionImageToLibrary.bind(this),
    'user': this.moveUserImageToLibrary.bind(this),
  };

  private deleteAllHandlers = {
    'student': this.deleteAllStudentImages.bind(this),
    'exhibitions': this.deleteAllExhibitionImages.bind(this),
    'user': this.deleteAllUserImages.bind(this),
  };

  private viewChangeHandlers = {
    'thumbnails': this.onThumnailsView.bind(this),
    'large': this.onLargeView.bind(this),
    'full-view': this.onFullView.bind(this),
    'fullscreen': this.onFullscreen.bind(this),
  };

  private socketMessageHandlers = {
    [SOCKET_ACTIONS.REVIEW_UPDATED]: this.onReviewUpdatedByTeacher.bind(this),
    [SOCKET_ACTIONS.IMAGE_REVIEW_UPDATED]: this.onReviewUpdatedByTeacher.bind(this),
  };

  private errorsMapping = {
    [ERRORS_KEYS.IMAGES_USED]: this.onImagesUsed.bind(this),
  };

  get userInfo() {
    return this.authService.user;
  }

  get largeImageSrc() {
    if (!this.largeImage) return;

    return `https://${this.largeImage.paths.extraLarge}`;
  }

  public get isStudentTab(): boolean {
    return this.isStudentImageManager && this.tabKey === 'student';
  }

  public get isStudentsTab(): boolean {
    return this.isEducatorImageManager && this.tabKey === 'students';
  }

  public get isExhibitionsTab(): boolean {
    return this.isEducatorImageManager && this.tabKey === 'exhibitions';
  }

  public get isUserTab(): boolean {
    return this.tabKey === 'user';
  }

  private get tabKey(): EducatorImageManagerTabs | StudentImageManagerTabs {
    if (this.isEducatorImageManager || this.isStudentImageManager) {
      return this.userData.tab;
    }

    return 'user';
  }

  private get selectedImagesData(): ImageDataModel[] {
    return this.selected.map((item): ImageDataModel => {
      return new ImageDataModel(item.id, item.isPublished);
    });
  }

  private get isExhibitionInActiveWebsite(): boolean {
    return this.exhibitionPortfolio && this.exhibitionPortfolio.websiteId === this.websiteId;
  }

  constructor(
    private imageManagerService: ImageManagerService,
    private imagesHttpService: ImagesHttpService,
    private pagesService: PagesService,
    private modalsService: ModalsService,
    private navigationService: NavigationService,
    private iFrameRoutingService: IFrameRoutingService,
    private imagesCounterService: ImagesCounterService,
    private imagesIconsService: ImagesIconsService,
    private loaderService: ContentLoaderService,
    private portfolioDefaultsService: PortfolioDefaultsService,
    private nodesService: NodesService,
    private imageDetailsService: ImageDetailsService,
    private imageFileNameAsTitleModalService: ImageFileNameAsTitleModalService,
    private imagesRemoveErrorModalService: ImagesRemoveErrorModalService,
    private websiteTourService: WebsiteTourService,
    private websitesExclamationIconService: WebsitesExclamationIconService,
    private educationExhibitionsImagesCounterService: EducationExhibitionsImagesCounterService,
    private educationImageManagerActionService: EducationImageManagerActionService,
    private imageReplaceInvalidationInfoModalService: ImageReplaceInvalidationInfoModalService,
    private educationImageManagerService: EducationImageManagerService,
    private educationWebsiteExhibitionsService: EducationWebsiteExhibitionsService,
    private selectExhibitionModalService: SelectExhibitionModalService,
    private reviewStudentImageModalService: ReviewStudentImageModalService,
    private educationImagesService: EducationImagesService,
    private reviewsListModalService: ReviewsListModalService,
    private educationStudentsImagesService: EducationStudentsImagesService,
    private fullscreenImageManagerEnlargementService: FullscreenImageManagerEnlargementService,
    private permissionsService: PermissionsService,
    private fullscreenService: FullscreenService,
    private socketsService: SocketsService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private studentPortfoliosService: StudentPortfoliosService,
    private studentImageManagerService: StudentImageManagerService,
    private studentImagesCounterService: StudentImagesCounterService,
    private studentImageMoveService: StudentImageMoveService,
    private educationStudentsService: EducationStudentsService,
    private websitesService: WebsitesService,
    private portfolioViewsService: PortfolioViewsService,
    private coreImageManagerService: CoreImageManagerService,
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef,
    public imageManagerModalService: ImageManagerModalService,
    public imageResolutionService: ImageResolutionService,
    public imageProcessesService: ImageProcessesService,
    public imageUploadService: ImageUploadService,
    public bag: BagService,
    public authService: AuthService,
  ) {
    this.onResize = this.onResizeHandler.bind(this);
    
    this.initSortable = this.utilsService.debounce(this.initSortable.bind(this), 500);

    this.handleDataChange = this.utilsService.debounce(this.handleDataChange.bind(this), 50);

    this.initPermissions();
  }

  onResizeHandler() {
    this.setImageWrapperSize();
  }

  public ngOnInit(): void {
    this.coreImageManagerService.userTypeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageManagerUserTypeModel) => {
      this.userData = data;

      this.isRegularUser = this.userData ? this.userData.type === 'user' : false;
      this.isEducatorImageManager = this.userData ? this.userData.type === 'educator' : false;
      this.isStudentImageManager = this.userData ? this.userData.type === 'student' : false;
    });

    this.portfolioViewsService.newReviewsDataSubject.subscribe((newReviewsData: INewReviewsData) => {
      this.newReviewsData = newReviewsData;

      this.handleReviewIcons();
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.websitesService.activeWebsiteIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websiteId: number) => {
      this.websiteId = websiteId;
    });

    this.imageManagerService.viewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ key, forceIndex }: { key: string, forceIndex?: number }) => {
      this.setView({ key, forceIndex });
    });

    this.imageManagerService.onImagesForceReinit.subscribe(() => {
      this.reInitCurrentImages();
    });

    this.educationStudentsImagesService.imageReviewDeleteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((deletedReview: StudentImageReviewModel) => {
      if (!deletedReview) {
        return;
      }

      const image: ImageModel = this.allImages.find((image: ImageModel) => {
        return image.id === deletedReview.imageId;
      });

      if (!image || !image.reviews) {
        return;
      }

      image.reviews = image.reviews.filter((review: StudentImageReviewModel) => {
        return review.id !== deletedReview.id;
      });
    });

    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      if (key !== this.activeTeacherTab) {
        this.onViewChange('thumbnails');

        this.isBlockedByAction = false;
      }

      this.activeTeacherTab = key;

      this.initDropzoneOptions();
      this.initLargeImage();

      if (key !== 'user') {
        return;
      }

      this.handleDataChange();
    });
    
    this.educationWebsiteExhibitionsService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((exhibitions: PortfolioModel[]) => {
      this.teacherExhibitions = exhibitions;
    });

    this.studentImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: StudentImageManagerTabs) => {
      if (key !== this.activeStudentTab) {
        this.onViewChange('thumbnails');

        this.isBlockedByAction = false;
      }

      this.activeStudentTab = key;

      this.initDropzoneOptions();
      this.initLargeImage();

      if (key !== 'user') {
        return;
      }

      this.handleDataChange();
    });

    this.imageManagerService.isImagesMovingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: { [key: number]: boolean }) => {
      this.isImagesMoving = !!value && Object.keys(value).some(key => value[key]);
    });

    this.imageManagerService.imagesRemovingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageDataModel[]) => {
      if (!images || !this.allImages) return;

      this.allImages = this.allImages.filter((item: ImageModel) => images.findIndex(img => img.id === item.id) === -1);

      this.bag.setImages(this.allImages);

      if (!this.allImages || this.allImages.length === 0) return;

      setTimeout(() => this.selectFirstImageInPortfolio());
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;
    });

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPageBlocked: boolean) => {
      this.isPageBlocked = isPageBlocked;
    });

    this.imageManagerService.thumbSizeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.setThumbHeight.bind(this));

    this.websitesExclamationIconService.exclamationIconDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((exclamationIconData: ExclamationIconInfoModel) => {
      this.exclamationIconData = exclamationIconData;
    });

    this.onViewChange('thumbnails');

    this.bag.change
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.handleBagChange.bind(this));

    this.nodesService.nodesSubject.pipe(filter(nodes => nodes && nodes.length !== 0)).subscribe((nodes: NodeModel[]) => {
      const isSameNodes: boolean = this.isSameNodes(nodes);
      const isSameNodesIgnoreQty: boolean = this.isSameNodesIgnoreQty(nodes);

      if (isSameNodes) {
        return;
      }

      this.nodes = nodes;

      this.handleDataChange({
        isAfterReorder: false,
        isSameNodesIgnoreQty,
      });
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((selectedPage: SelectedPageModel) => !!(selectedPage && selectedPage.id))
    ).subscribe((selectedPage: SelectedPageModel) => {
      const isSameSelectedPage: boolean = this.isSamePage(selectedPage);

      if (isSameSelectedPage) return;

      this.selectedPage = selectedPage;

      this.onViewChange('thumbnails');

      this.onEnlargedClose();

      this.initBlockedState();

      this.handleDataChange();
    });

    this.fullscreenImageManagerEnlargementService.onCurrentPortfolioReload.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.handleDataChange();

      if (!this.studentPortfolio) {
        return;
      }

      this.portfolioViewsService.fetchNewReviewsData({ websiteId: this.studentPortfolio.websiteId });
    });

    this.studentPortfoliosService.selectedPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolio: PortfolioModel) => {
      this.studentPortfolio = portfolio;
      
      this.onViewChange('thumbnails');

      this.initBlockedState();

      this.handleStudentTabSelected();
      
      this.initSortable();
    });
    
    this.studentImageManagerService.portfolioImagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      if (!this.isStudentTab) {
        return;
      }

      this.handlePortfolioImagesFetched(images, { isForceIfNull: true });
    });

    this.studentImageManagerService.portfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isStudentTab) {
        return;
      }
      
      this.portfolioDefaults = portfolioDefaults;
    });

    this.educationImageManagerService.selectedStudentPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPortfolio: EducationStudentPortfolioModel) => {
      this.educatorStudentPortfolio = selectedPortfolio;
      
      this.onViewChange('thumbnails');

      this.initBlockedState();

      this.handleStudentsTabSelected();
    });
    
    this.educationImageManagerService.studentPortfolioImagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      if (!this.isStudentsTab) {
        return;
      }

      this.handlePortfolioImagesFetched(images, { isForceIfNull: true });
    });

    this.educationImageManagerService.studentPortfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isStudentsTab) {
        return;
      }
      
      this.portfolioDefaults = portfolioDefaults;
    });

    this.selectExhibitionModalService.onSelect.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedExhibition: PortfolioModel) => {
      if (!selectedExhibition) return;

      this.onImageExhibit(selectedExhibition.id, { isSingle: false });
    });

    this.educationImageManagerService.selectedExhibitionPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPortfolio: EducationExhibitionPortfolioModel) => {
      if (!!selectedPortfolio && selectedPortfolio.isSameTo(this.exhibitionPortfolio)) {
        return;
      }

      this.exhibitionPortfolio = selectedPortfolio;
      
      this.onViewChange('thumbnails');

      this.initBlockedState();

      this.handleExhibitionsTabSelected();
    });
    
    this.educationImageManagerService.exhibitionPortfolioImagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      if (!this.isExhibitionsTab) return;

      this.handlePortfolioImagesFetched(images, { isForceIfNull: true });
    });

    this.educationImageManagerService.exhibitionPortfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isExhibitionsTab) return;
      
      this.portfolioDefaults = portfolioDefaults;
    });

    this.imageDetailsService.imageDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((imageDetails: ImageModel) => {
      this.imageDetails = imageDetails;

      if (!imageDetails || !this.allImages || !this.allImages.length) return;

      const image = this.allImages.find((img: ImageModel) => img.id === imageDetails.id);

      if (!image) return;

      image.isPublished = imageDetails.isPublished;

      const imageElement = this.dropzoneImagePreviews.filter(`[data-imageId="${imageDetails.id}"]`);
      const redDot = imageElement.find('.red-dot');
      
      imageElement.toggleClass('unpublished-image', !imageDetails.isPublished);

      return imageDetails.isPublished ? redDot.hide() : redDot.show();
    });

    this.portfolioDefaultsService.portfolioDefaultsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      if (!this.isEducatorImageManager || this.tabKey !== 'user') {
        return;
      }

      this.portfolioDefaults = portfolioDefaults;
    });

    this.educationStudentsImagesService.currentImageReviewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((review: StudentImageReviewModel) => {
      this.currentImageReview = review;

      if (!this.currentImageReview) {
        const image: ImageModel = this.getImageToHandle();

        this.educationStudentsImagesService.initEmptyReview(image ? image.id : null);

        return;
      }

      this.initCurrentImageDataOverlay();
    });

    this.educationStudentsImagesService.currentImageExhibitedImagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      this.currentImageExhibitedImages = images;

      this.initIsSelectedImageExhibited();

      this.initCurrentImageDataOverlay();
    });

    this.loaderService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoaded: boolean) => {
      this.isLoaderVisible = !isLoaded;

      this.cdr.detectChanges();
    });

    this.socketsService.imageManagerDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISocketImageManagerMessageDataModel) => {
      if (!data || !this.socketMessageHandlers[data.action]) {
        return;
      }
  
      this.socketMessageHandlers[data.action](data);
    });

    this.educationImageManagerActionService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { [key: string]: ImageManagerActionDataModel }) => {
      this.reorderData = data;

      this.initBlockedState();
    });

    this.educationImageManagerActionService.onStopSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageManagerActionDataModel) => {
      this.onReorderStop(data);
    });

    this.selectExhibitionModalService.isExhibitButtonBlocked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isBlocked: boolean) => {
      return isBlocked ? this.blockExhibitButton() : this.unblockExhibitButton();
    });

    this.websiteTourService.addVisibleLocation(TOUR_KEY);
    this.websiteTourService.addVisibleItem(KEYS.THUMBNAILS_DESCRIPTION);
    this.websiteTourService.addVisibleItem(KEYS.DRAG_DROP_ACTION_DESCRIPTION);

    this.initSortable();

    window.addEventListener('resize', this.onResize);
  }

  private initPermissions(): void {
    const imageReviewsPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.IMAGE_REVIEWS,
    };
    
    const imageRatingPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.IMAGE_RATING,
    };

    this.permissionsService.isUserHasPermissionsObservable([imageReviewsPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isImageReviewsPermitted = isPermitted;

      this.initStudentImageReviews();
    });

    this.permissionsService.isUserHasPermissionsObservable([imageRatingPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isImageRatingPermitted = isPermitted;
    });
  }

  private setView({ key, forceIndex }: { key: string, forceIndex?: number }): void {
    this.currentView = key;

    if (!this.viewChangeHandlers[key]) {
      return;
    }
    
    this.viewChangeHandlers[key]({ key, forceIndex });
  }

  private onThumnailsView({ key, forceIndex }: { key: string, forceIndex?: number }): void {
    this.view = key;

    this.onEnlargedClose();

    if (!Number.isInteger(forceIndex)) {
      return;
    }

    this.selectImageByIndex(forceIndex);
  }

  private onLargeView({ key, forceIndex }: { key: string, forceIndex?: number }): void {
    this.view = key;

    if (!Number.isInteger(forceIndex)) {
      return;
    }

    this.selectImageByIndex(forceIndex);
  }

  private onFullView({ forceIndex }: { key: string, forceIndex?: number }): void {
    this.fullscreenImageManagerEnlargementService.open({
      images: this.allImages,
      currentImage: Number.isInteger(forceIndex) ? this.allImages[forceIndex] : this.selected[0],
      previousView: this.view,
    });
  }

  private onFullscreen({ key, forceIndex }: { key: string, forceIndex?: number }): void {
    this.view = key;

    this.fullscreenService.enlarge(document.body);
    
    this.fullscreenImageManagerEnlargementService.open({
      images: this.allImages,
      currentImage: Number.isInteger(forceIndex) ? this.allImages[forceIndex] : this.selected[0],
      previousView: this.view,
    });
  }

  private initLargeImage(): void {
    if (this.isStudentsTab) {
      return;
    }

    this.currentImageReview = null;
  }

  private initDropzoneOptions(): void {
    this.dropzoneEmptyMessage = this.dropzoneEmptyMessages[this.tabKey] || this.dropzoneEmptyMessages['user'];
  }

  private handleDataChange(options: { isAfterReorder: boolean, isSameNodesIgnoreQty?: boolean } = { isAfterReorder: false }): void {
    if (!this.nodes || !this.selectedPage || options.isSameNodesIgnoreQty) {
      return;
    }

    this.handleSelectedPageChanged(options);
  }

  private isSamePage(selectedPage: SelectedPageModel): boolean {
    if (!selectedPage  || !this.selectedPage) {
      return false;
    }

    return this.selectedPage.isSameTo(selectedPage);
  }

  private isSameNodes(nodes: NodeModel[]): boolean {
    if (!nodes || !this.nodes || nodes.length !== this.nodes.length) {
      return false;
    }

    return this.nodes.every((node: NodeModel, idx: number) => node.isSameTo(nodes[idx]));
  }

  private isSameNodesIgnoreQty(nodes: NodeModel[]): boolean {
    if (!nodes || !this.nodes || nodes.length !== this.nodes.length) {
      return false;
    }

    return this.nodes.every((node: NodeModel, idx: number) => node.isSameToIgnoreQty(nodes[idx]));
  }

  private selectNode() {
    if (!this.nodes || !this.selectedPage || this.isImagesMoving) return;

    const isPortfolio = this.selectedPage.id && this.selectedPage.type === 'P';

    if (isPortfolio) {
      const node = this.nodes.find(n => n.id === this.selectedPage.id && n.type === this.selectedPage.type);

      return this.handleNodeSelect(node ? node.nodeId : LIBRARY_ID);
    }

    this.navigationService.toImageManager({ id: LIBRARY_ID, type: 'P' });
  }
  
  private onDropzoneProcessing(): void {
    if (this.isExhibitionsTab) {
      this.dropzone.options.url = `api/education/teachers/current/institutions/${this.exhibitionPortfolio.institutionId}/classes/${this.exhibitionPortfolio.classId}/websites/${this.exhibitionPortfolio.websiteId}/portfolios/${this.exhibitionPortfolio.id}/images`;

      return;
    }

    if (this.isStudentTab) {
      this.dropzone.options.url = `api/education/students/current/websites/${this.studentPortfolio.websiteId}/portfolios/${this.studentPortfolio.id}/images`;

      return;
    }

    this.dropzone.options.url = dropzoneConfig.imageManager.url;
  }

  private addDynamicParams(file: File, xhr: XMLHttpRequest, formData: FormData): void {
    const currentNodeId: string = <string>formData.get('nodeid');

    if (!!currentNodeId) {
      formData.set('nodeid', `${this.selectedPage.id}`);
    } else {
      formData.append('nodeid', `${this.selectedPage.id}`);
    }

    if (this.isExhibitionsTab) {
      formData.append('classId', `${this.exhibitionPortfolio.classId}`);
    }

    formData.append('type', this.isExhibitionsTab ? 'Class' : 'User');
    formData.append('isImageFileNameAsTitle', `${this.isImageFileNameAsTitle}`);
  }

  private handleSelectedPageChanged(options: { isAfterReorder: boolean } = { isAfterReorder: false }): void {
    this.clearImageSelection();
    this.clearFilesInDropzone();

    this.imagesWithFixedSrc = {}; // TODO: ?

    this.selectNode();

    this.nodeSelectedHandlers[this.tabKey](options);

    this.initSortable();
  }

  private handleDropzoneFileUploadSuccess(file: { previewElement: HTMLElement, image: ImageModel, status: any, isFileUploaded: boolean }): void {
    if (file.previewElement) {
      file.previewElement.classList.add('dz-success');
      
      file.previewElement.classList.remove('uploading');
    }

    const nOfUploadingImages: number = this.dropzoneImagePreviews.filter('.uploading').length;

    if (nOfUploadingImages !== 0) {
      return;
    }

    this.sendSocketAction(SOCKET_ACTIONS.IMAGE_UPLOAD_STOP);
  }

  handleDropzoneQueueComplete() {
    this.imageManagerService.isPageBlockedSubject.next(false);

    if (this.imageUploadService.isErrorOnUpload) {
      this.imageUploadService.showErrorMessage();
    }
  }

  handleDropzoneResize(file) {
    const maxHeight = 200;
    const maxWidth  = 200;

    const info = {
      srcX: 0,
      srcY: 0,
      srcWidth: file.width,
      srcHeight: file.height,

      trgWidth: file.width,
      trgHeight: file.height,

      optWidth: file.width,
      optHeight: file.height
    };

    const srcRatio = file.width / file.height;

    if (info.srcWidth > info.srcHeight) {
      info.trgWidth = maxWidth;
      info.trgHeight = (1 / srcRatio) * maxHeight;
    } else {
      info.trgWidth = srcRatio * maxWidth;
      info.trgHeight = maxHeight;
    }

    return info;
  }

  handleDropzoneError(file, error) {
    if (!file.accepted) this.dropzone.cancelUpload(file);

    this.imageUploadService.handleUploadError(file.name, error);
    this.dropzone.removeFile(file);
  }

  handleDropzoneInit() {
    if (this.dropboxInitCompleted) {
      return;
    }

    this.dropboxInitCompleted = true;
    this.dropzone = Dropzone.forElement('#dropzone');

    if (!this.dropzone) {
      return;
    }

    this.dropzone.options.addedfile = (file) => {
      if (this.dropzone.previewsContainer) {
        if (file.size > this.imageUploadService.MAX_FILE_SIZE_IN_BYTES) return this.dropzone.removeFile(file);

        this.hideDropzoneMessage();

        file.previewElement = Dropzone.createElement(this.dropzone.options.previewTemplate.trim());
        file.previewTemplate = file.previewElement;

        this.setThumbnail(file);

        this.dropzone.previewsContainer.appendChild(file.previewElement);

        if (this.dropzone.options.addRemoveLinks) {
          file.removeLink = Dropzone.createElement('<a class="dz-remove" href="javascript:void(0);" title="Remove image" data-dz-remove><i class="fa fa-remove"></i></a>');
          file.removeLink.click((event) => {
            event.preventDefault();
            event.stopPropagation();
            return this.dropzone.removeFile(file);
          });

          file.previewElement.appendChild(file.removeLink);
        }
      }
    };

    this.addImages();

    this.dropzone.on('addedfile', (file: { previewElement: HTMLElement, image: ImageModel, status: any, isFileUploaded: boolean }) => {
      this.addImage(file);

      if (file.isFileUploaded) {
        return;
      }

      this.sendSocketAction(SOCKET_ACTIONS.IMAGE_UPLOAD_START);

      if (file.previewElement) {
        file.previewElement.classList.add('uploading');

        file.previewElement.style.display = 'none';

        this.addImageLoadHandlers($(file.previewElement), file.image);
      }

      this.imageManagerService.isPageBlockedSubject.next(true);
    });

    if (!this.dropzone.element) return;
    
    this.dropzone.element.style.setProperty('--thumb-height-px', `${this.thumbHeight}px`);
  }

  handleDeleteClick() {
    this.promptDeleteImages();
  }

  public handleDeleteAllClick(): void {
    this.dropzoneImagePreviews.addClass('selected');

    this.handleImagesSelection(this.allImages);

    this.promptDeleteAllImages();
  }

  public onImageReplace({ id, file }) {
    if (!this.replaceImageHandlers[this.tabKey]) {
      return;
    }

    const idx = this.currentSelectedIdx;

    this.loaderService.show(this.key);

    this.sendSocketAction(SOCKET_ACTIONS.IMAGE_REORDER_START);

    this.replaceImageHandlers[this.tabKey]({ idx, id, file });
  }

  private onStudentImageReplace({ idx, id, file }: { idx: number, id: number, file: File }): void {
    const successHandler = () => {
      return this.onImageReplaceSuccess(idx);
    };

    this.imagesHttpService.replaceImage(id, file, this.studentPortfolio.id).pipe(
      catchError(e => {
        this.onImageReplaceFailed();

        return throwError(() => e);
      })
    ).subscribe(successHandler);
  }

  private onExhibitionImageReplace({ idx, id, file }: { idx: number, id: number, file: File }): void {
    const successHandler = () => {
      return this.onImageReplaceSuccess(idx);
    };

    this.educationImageManagerService.replaceImage(this.exhibitionPortfolio, id, file).pipe(
      catchError(e => {
        this.onImageReplaceFailed();

        return throwError(() => e);
      })
    ).subscribe(successHandler);
  }

  private onUserImageReplace({ idx, id, file }: { idx: number, id: number, file: File }): void {
    const successHandler = () => {
      return this.onImageReplaceSuccess(idx);
    };

    this.imagesHttpService.replaceImage(id, file, this.selectedPage.id).pipe(
      catchError(e => {
        this.onImageReplaceFailed();

        return throwError(() => e);
      }),
    ).subscribe(successHandler);
  }

  private onImageReplaceSuccess(idx: number) {
    this.sendSocketAction(SOCKET_ACTIONS.IMAGE_REORDER_STOP);

    this.imagesIconsService.initIcon(this.selectedPage.id);

    this.imageReplaceInvalidationInfoModalService.open();

    this.nodeSelectedHandlers[this.tabKey]();

    this.websitesExclamationIconService.fetchIsExclamationIconButtonVisible();

    this.imagesLoadedSubject.pipe(
      take(1),
      finalize(() => {
        this.loaderService.hide(this.key);
      }),
    ).subscribe(() => {
      this.selectImageInPortfolio(idx);
    });
  }

  private onImageReplaceFailed() {
    this.sendSocketAction(SOCKET_ACTIONS.IMAGE_REORDER_STOP);

    this.nodeSelectedHandlers[this.tabKey]();

    this.loaderService.hide(this.key);
  }

  showDropzoneMessage() {
    $('#dropzone .dz-message').removeClass('hidden-offset');
    $('#dropzone').removeClass('dz-started');
  }

  hideDropzoneMessage() {
    $('#dropzone .dz-message').addClass('hidden-offset');
  }

  handleBagChange(value) {
    if (!value.viewChanged && value.defaultsOnSave) {
      this.portfolioDefaultsService.initOnSave(value.defaultsOnSave);
    }
  }

  public handleImageEnlarge(enlargedImage: ImageModel): void {
    if (!enlargedImage || !this.allImages) {
      return;
    }
    
    const idx: number = this.allImages.findIndex((i: ImageModel) => i.id === enlargedImage.id);
    const image: ImageModel = this.allImages[idx];
    
    this.selectImageInPortfolio(idx);
    this.enlarge(image);
  }

  public canLeave(): boolean {
    return !this.isPageBlocked;
  }

  handleNotPortfolioPageOpened() {
    this.allImages = null;
    this.images = null;

    return Promise.resolve();
  }

  handlePortfolioImagesFetched(images: ImageModel[], options: { isForceIfNull?: boolean } = {}) {
    if (!this.isImagesRelatedToPageResolvers[this.tabKey]) {
      return;
    }

    const isImagesRelatedToPage: boolean = this.isImagesRelatedToPageResolvers[this.tabKey](images, options);

    if (!isImagesRelatedToPage) {
      return;
    }

    const $dropzone = $('#dropzone');
    
    if ($dropzone && $dropzone.sortable('instance')) {
      this.isDropzoneInitCompleted = false;

      $dropzone.sortable('destroy');
    }

    this.allImages = images || [];
    this.images = this.allImages.slice(0, IMAGES_PER_PAGE);

    this.bag.setImages(this.allImages);

    this.addImages();

    this.initSortable();
  }

  private isImagesRelatedToStudentPage(images: ImageModel[], options: { isForceIfNull?: boolean } = {}): boolean {
    if (!images || !this.studentPortfolio) {
      return options.isForceIfNull;
    }

    return images.every((image: ImageModel) => {
      return image.portfolioId === this.studentPortfolio.id;
    });
  }

  private isImagesRelatedToEducatorStudentPage(images: ImageModel[], options: { isForceIfNull?: boolean } = {}): boolean {
    if (!images || !this.educatorStudentPortfolio) {
      return options.isForceIfNull;
    }

    return images.every((image: ImageModel) => {
      return image.portfolioId === this.educatorStudentPortfolio.id;
    });
  }

  private isImagesRelatedToExhibitionPage(images: ImageModel[], options: { isForceIfNull?: boolean } = {}): boolean {
    if (!images || !this.exhibitionPortfolio) {
      return options.isForceIfNull;
    }
    
    return images.every((image: ImageModel) => {
      return image.portfolioId === this.exhibitionPortfolio.id;
    });
  }

  private isImagesRelatedToUserPage(images: ImageModel[]): boolean {
    if (!images || !this.selectedPage) {
      return false;
    }

    if (this.selectedPage.id === LIBRARY_ID) {
      return images.every((image: ImageModel) => {
        return image.isDeleted || image.portfolioId === 0;
      });
    }
    
    return images.every((image: ImageModel) => {
      return image.portfolioId === this.selectedPage.id;
    });
  }

  private handleStudentTabSelected(options: { isAfterReorder: boolean } = { isAfterReorder: false }): void {
    if (!this.studentPortfolio) {
      this.studentImageManagerService.portfolioImagesSubject.next(null);

      return;
    }

    this.loading = true;

    this.studentImageManagerService.fetchPortfolioDefaults({
      websiteId: this.studentPortfolio.websiteId,
      portfolioId: this.studentPortfolio.id,
    });

    this.studentImageManagerService.fetchStudentPortfolioImages({
      websiteId: this.studentPortfolio.websiteId,
      portfolioId: this.studentPortfolio.id,
    }).add(() => {
      if (!options.isAfterReorder) {
        return;
      }

      this.isBlockedByAction = false;

      this.initSortable();
    });
  }

  private handleStudentsTabSelected(options: { isAfterReorder: boolean } = { isAfterReorder: false }): void {
    if (!this.educatorStudentPortfolio) {
      this.educationImageManagerService.studentPortfolioImagesSubject.next(null);

      return;
    }

    this.loading = true;

    this.educationImageManagerService.fetchStudentPortfolioDefaults(this.educatorStudentPortfolio);

    this.educationImageManagerService.fetchStudentPortfolioImages(this.educatorStudentPortfolio).add(() => {
      if (!options.isAfterReorder) {
        return;
      }

      this.isBlockedByAction = false;
    });
  }

  private handleExhibitionsTabSelected(options: { isAfterReorder: boolean } = { isAfterReorder: false }) {
    if (!this.exhibitionPortfolio) {
      this.educationImageManagerService.exhibitionPortfolioImagesSubject.next(null);

      return;
    }

    this.loading = true;

    this.educationImageManagerService.fetchExhibitionPortfolioDefaults(this.exhibitionPortfolio);

    this.educationImageManagerService.fetchExhibitionPortfolioImages(this.exhibitionPortfolio).add(() => {
      if (!options.isAfterReorder) {
        return;
      }

      this.isBlockedByAction = false;
    });
  }

  private handleNodeSelected(options: { isAfterReorder: boolean } = { isAfterReorder: false }) {
    if (!this.selectedPage) {
      return;
    }

    if (this.selectedPage.type !== 'P') {
      return this.handleNotPortfolioPageOpened();
    }

    const selectedPage: SelectedPageModel = this.selectedPage;

    this.loading = true;

    this.portfolioDefaultsService.fetchPortfolioDefaults(this.selectedPage.nodeId);

    this.imageManagerService.getPortfolioImages(this.selectedPage.nodeId, this.selectedPage.id).subscribe((images: ImageModel[]) => {
      if (selectedPage !== this.selectedPage) {
        return;
      }
      
      this.handlePortfolioImagesFetched(images);

      if (!options.isAfterReorder) {
        return;
      }

      this.isBlockedByAction = false;
    });
  }

  handleNodeSelect(nodeId) {
    this.clicks = 0;
    this.imageManagerModalService.isLibrary = nodeId === LIBRARY_ID;

    if (!nodeId) return this.handleNotPortfolioPageOpened();

    this.pagesService.setSelectedNode(nodeId);
  }

  get dropzoneImagePreviews() {
    return $('#dropzone > .dz-preview');
  }

  private get movedImageElements(): HTMLElement[] {
    return <HTMLElement[]>Array.from(document.querySelectorAll('#dropzone > .dz-preview[data-moved=true]'));
  }

  get dropzoneImagePreviewsWithoutError() {
    return this.dropzoneImagePreviews.filter(':not(.dz-error)');
  }

  get dropzoneSelectedImagePreviews() {
    return this.dropzoneImagePreviews.filter('.selected');
  }

  private handleImageLoaded($el, image: ImageModel): void {
    const $img = $el.find('img');

    $el.children().not('span.red-dot,.exhibition-dropdown-menu,.student-image-overlay').show();

    if (image) {
      $img.width(`calc(${image.widthPx / image.heightPx} * var(--thumb-height-px, 130px))`);
    }

    $img.parent().removeClass('gray-thumbnail');

    $el.show();

    if (--this.loadedImagesCounter > 0) {
      return;
    }

    this.loading = false;

    this.appendMoreImages();

    this.initCurrentImages();
  }

  private handleImageError($el, image: ImageModel): void {
    const $img = $el.find('img');

    const currentSrc: string = $img.attr('src');

    if (currentSrc === void 0) {
      return;
    }

    const src = currentSrc.replace(/_\w+\.jpg$/, '.jpg');

    if (this.imagesWithFixedSrc[src]) {
      $img.off('error');
      $img.off('load');

      return this.handleImageLoaded($el, image);
    }

    this.imagesWithFixedSrc[src] = true;

    $img.attr('src', src);
  }

  addImageLoadHandlers($el, image: ImageModel): void {
    const $img = $el.find('img');

    $img.on('load', () => this.handleImageLoaded($el, image));
    $img.on('error', () => this.handleImageError($el, image));
  }

  private getImageThumbnailSizes(maxHeight, image) {
    if (!image.WidthPx || !image.HeightPx) return null;

    return {
      w: image.WidthPx * maxHeight / image.HeightPx,
      h: maxHeight,
    };
  }

  showImageThumbnail($el, image: ImageModel) {
    const $imageItems = $el.children().not('.dz-image,.exhibition-dropdown-menu,.student-image-overlay');
    const $img = $el.find('img');

    $imageItems.hide();

    this.addImageLoadHandlers($el, image);

    const size = this.getImageThumbnailSizes($img.height(), image);

    if (!size) return;

    $img.width(size.w);
    $img.parent().addClass('gray-thumbnail');
  }

  scrollOnDrag(step, target) {
    const $target = $('#' + target);
    const scrollY = $target.scrollTop();
    $target.scrollTop(scrollY + step);
    if (!stop) {
      this.dragTimer = setTimeout(() => { scroll(step, target); }, 0);
    }
  }

  // Need more refactor
  handleMouseMove(e: MouseEvent, startMousePositionY?) {
    const offset = 200;
    let stop = true;
    const step = 5;
    let target;

    setTimeout(() => {
      stop = true;
      clearTimeout(this.dragTimer);

      if ($(e.target).hasClass('scrollbar')) {
        target = $(e.target).attr('id');
      }

      if ($(e.target).closest('.scrollbar').length) {
        target = $(e.target).closest('.scrollbar').attr('id');
      }

      if (this.dragging && target) {
        e.preventDefault();
        e.stopPropagation();

        if (e.clientY < offset /*startMousePositionY*/) {
          stop = false;
          this.scrollOnDrag(-step, target);
        }

        if (e.clientY > window.innerHeight - offset /*startMousePositionY*/) {
          stop = false;
          this.scrollOnDrag(step, target);
        }
      }

    }, 0);
  }

  // If element is not selected then it should be selected and  other elements should be deselected
  // Multiple drag should work on dragging already selected element
  reselectElementIfNeed(event, element) {
    const $element = $(element);
    if (!$element.is('.dz-image-preview.selected')) {
      const imageId = Number.parseInt($element.attr('data-imageid'), 10);
      const file: ImageModel = this.allImages.find((file: ImageModel) => file.id === imageId);

      this.toggleSelect(event, {
        image: file,
        previewElement: this.findImagePreviewByImageId(imageId)[0],
      });
    }
  }

  private addImage(file: { previewElement: HTMLElement, image: ImageModel, status: any, isFileUploaded: boolean }) {
    if (!file) return;

    const $dropzone = $('#dropzone');

    const $el = $(file.previewElement);
    
    if (file.isFileUploaded) {
      $el.addClass('dz-complete');
    }

    if (!$dropzone.children().filter($el).length) {
      $dropzone.append($el);
    }

    if (!file.image) return;
    
    const isStudentsTab: boolean = this.isStudentsTab;

    this.hideDropzoneMessage();

    const isImageResolutionSufficient = this.imageResolutionService.isImageResolutionSufficient(file.image, IMAGE_RESOLUTIONS.HALF_BLEED_IMAGE, true);
    
    $el.find('.dz-image').attr({
      'title': isImageResolutionSufficient ? 'Double click to enlarge.\nDrag to reorder, or move it to a new portfolio in the tree.' : '',
    })

    $el.attr({
      'data-imageid': file.image.id,
      'data-icon': file.image.paths.icon,
    })
    .removeClass('dz-success dz-processing')
    .addClass('dz-success-remote')
    .click(event => {
      this.toggleSelect(event, {
        image: this.allImages.find((i: ImageModel) => i.id === file.image.id),
        previewElement: file.previewElement[0],
      });
    });

    $el.append(`
      <div class="dots-container" title=""></div>
    `);
  
    $el.removeClass('with-exclamation-icon');

    const dotsContainer = $el.find('.dots-container');

    if (!isStudentsTab) {
      if (!this.imageManagerModalService.isLibrary) {
        dotsContainer.append(`
          <span class="red-dot">
            <div class="arrow-left"></div>
            <span class="red-dot-tooltip">CLICK TO PUBLISH</span>
          </span>`
        );
      }

      if (!isImageResolutionSufficient) {
        $el.addClass('with-exclamation-icon');

        dotsContainer.append(`
          <div class="exclamation-icon-wrapper">
            <div class="exclamation-icon">!</div>
            <div class="tooltip-box">
              <div class="hint">
                <div class="triangle"></div>
                <div class="text-wrapper">
                  <div class="text">
                    ${IMAGE_RESOLUTIONS.HALF_BLEED_IMAGE.message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        `);
  
        const exclamationIcon = dotsContainer.find('.exclamation-icon');
  
        exclamationIcon.on('mouseenter', (event) => {
          if (event.target.getBoundingClientRect().left < $dropzone[0].clientWidth) return event.target.classList.remove('left-side');
  
          event.target.classList.add('left-side');
        });
      }
    }

    this.initStudentsTabImage($el, file.image);
    this.initStudentImageOverlay($el, file.image);

    this.showImageThumbnail($el, file.image);

    this.handleImageLoaded($el, file.image);

    const redDot = $el.find('.red-dot');

    $el.toggleClass('unpublished-image', !file.image.isPublished);

    redDot.click(event => {
      event.preventDefault();
      event.stopPropagation();

      this.publishImage(file.image);
    });

    if (file.image.isPublished) {
      redDot.hide();
    } else {
      redDot.show();
    }

    if (file.status && !isImageResolutionSufficient) this.imageResolutionService.showImageResolutionInfoModal();

    this.setThumbnail(file);

    this.files.push(file);
  }
  
  private initCurrentImageDataOverlay(): void {
    if (!this.isStudentsTab || !this.allImages || (!this.currentImageReview && !this.currentImageExhibitedImages)) {
      return;
    }

    const targetImageId: number = this.getTargetStudentImageId();

    const isValid: boolean = this.isStudentImageDataValid(targetImageId);

    if (!isValid) {
      return;
    }

    const image: ImageModel = this.allImages.find((image: ImageModel) => {
      return image.id === targetImageId;
    });

    if (!image) {
      return;
    }

    const element: HTMLElement = <HTMLElement>this.dropzone.element.querySelector(`[data-imageId="${image.id}"]`);

    if (!element) {
      return;
    }

    if (this.currentImageReview.isEmpty && (!this.currentImageExhibitedImages || this.currentImageExhibitedImages.length === 0)) {
      const overlay: HTMLElement = <HTMLElement>element.querySelector('.students-tab-image-overlay');

      if (overlay) {
        overlay.remove();
      }

      return;
    }

    this.initReview(image);

    this.initStudentsTabImage($(element), image);
  }

  private getTargetStudentImageId(): number {
    if (this.currentImageReview) {
      return this.currentImageReview.imageId;
    }

    return this.currentImageExhibitedImages && this.currentImageExhibitedImages.length > 0 ? this.currentImageExhibitedImages[0].sourceImageId : null;
  }

  private isStudentImageDataValid(targetImageId: number): boolean {
    const isReviewValid: boolean = this.currentImageReview ? this.currentImageReview.imageId === targetImageId : true;

    if (!isReviewValid || !this.currentImageExhibitedImages || this.currentImageExhibitedImages.length === 0) {
      return isReviewValid;
    }

    return this.currentImageExhibitedImages.every((image: ImageModel) => {
      return image.sourceImageId === targetImageId;
    });
  }

  private onReviewUpdatedByTeacher(data: ISocketImageManagerMessageDataModel): void {
    if (!this.dropzone || !this.dropzone.element || !this.allImages) {
      return;
    }

    const imageIndex: number = this.allImages.findIndex((item: ImageModel) => {
      return item && item.id == data.imageId;
    });

    if (imageIndex === -1) {
      return;
    }

    this.imagesHttpService.fetchImageDetails(data.imageId).subscribe((image: ImageModel) => {
      if (!image || !this.allImages || !this.allImages[imageIndex]) {
        return;
      }
      
      if (this.allImages[imageIndex].id === image.id) {
        this.allImages[imageIndex] = image;

        if (this.selected && this.selected.some((selectedImage: ImageModel) => selectedImage.id === image.id)) {
          this.selectImageByIndex(imageIndex);
        }
      }

      if (this.largeImage && this.largeImage.id === image.id) {
        this.largeImage = image;

        this.initStudentImageReviews();
      }

      if (this.currentView === 'full-view' || this.currentView === 'fullscreen') {
        this.fullscreenImageManagerEnlargementService.reInit({
          images: this.allImages,
        });
      }

      const element: HTMLElement = <HTMLElement>this.dropzone.element.querySelector(`[data-imageId="${data.imageId}"]`);
  
      if (!element) {
        return;
      }
  
      const existingOverlay: HTMLElement = <HTMLElement>element.querySelector('.student-image-overlay');
  
      if (existingOverlay) {
        existingOverlay.remove();
      }
  
      const existingRatingBar: HTMLElement = <HTMLElement>element.querySelector('.student-image-rating-bar');
  
      if (existingRatingBar) {
        existingRatingBar.remove();
      }

      const isRated: boolean = image.reviews && image.reviews.length > 0;
  
      element.classList.toggle('with-rate-bar', isRated);

      if (!image.reviews || image.reviews.length === 0) {
        return;
      }
  
      const isCommented: boolean = image.reviews.some((review: StudentImageReviewModel) => review.text && review.text.length > 0);
      const hasAudio: boolean = image.reviews.some((review: StudentImageReviewModel) => review.audios && review.audios.length > 0);
      const hasUpdateSuggestion: boolean = image.reviews.some((review: StudentImageReviewModel) => review.imageUpdateSuggestions && review.imageUpdateSuggestions.length > 0);
  
      if (!isRated && !isCommented && !hasAudio && !hasUpdateSuggestion) {
        return;
      }

      const overlay: HTMLElement = this.generateStudentImageOverlay(image, { isForceNew: true });
  
      element.appendChild(overlay);
  
      if (!isRated) {
        return;
      }
  
      const ratingBar: HTMLElement = this.generateStudentImageRatingBar(element, image);
  
      if (!ratingBar) {
        return;
      }
  
      element.appendChild(ratingBar);
    });
  }

  private initBlockedState(): void {
    if (!this.reorderData) {
      this.isBlockedByAction = false;

      return;
    }

    const selectedPageId: number = this.getCurrentPageId();

    if (!this.reorderData[selectedPageId]) {
      return;
    }

    this.isBlockedByAction = true;
  }

  private onReorderStop(data: ImageManagerActionDataModel): void {
    if (!data) {
      return;
    }

    const websiteId: number = this.getCurrentWebsiteId();

    if (websiteId !== data.websiteId) {
      return;
    }

    const selectedPageId: number = this.getCurrentPageId();

    if (selectedPageId !== data.portfolioId) {
      return;
    }

    this.handleDataChange({ isAfterReorder: true });
  }

  private getCurrentWebsiteId(): number {
    if (this.isStudentTab) {
      return this.studentPortfolio ? this.studentPortfolio.websiteId : null;
    }

    if (this.isStudentsTab) {
      return this.educatorStudentPortfolio ? this.educatorStudentPortfolio.websiteId : null;
    }

    if (this.isExhibitionsTab) {
      return this.exhibitionPortfolio ? this.exhibitionPortfolio.websiteId : null;
    }

    return this.websiteId;
  }

  private getCurrentPageId(): number {
    if (this.isStudentTab) {
      return this.studentPortfolio ? this.studentPortfolio.id : null;
    }

    if (this.isStudentsTab) {
      return this.educatorStudentPortfolio ? this.educatorStudentPortfolio.id : null;
    }

    if (this.isExhibitionsTab) {
      return this.exhibitionPortfolio ? this.exhibitionPortfolio.id : null;
    }

    return this.selectedPage ? this.selectedPage.id : null;
  }

  private initReview(image: ImageModel): void {
    image.reviews = image.reviews || [];

    const existingReviewIdx: number = image.reviews.findIndex((review: StudentImageReviewModel) => {
      return review.id === this.currentImageReview.id;
    });

    if (existingReviewIdx === -1) {
      image.reviews.push(StudentImageReviewModel.clone(this.currentImageReview));

      return;
    }

    image.reviews[existingReviewIdx] = StudentImageReviewModel.clone(this.currentImageReview);
  }

  private initStudentsTabImage(wrapper, image: ImageModel): void {
    if (!wrapper || !image || !this.isStudentsTab) {
      return;
    }

    wrapper.find('.students-tab-image-overlay').remove();

    const currentUserReview: StudentImageReviewModel = image.reviews ? image.reviews.find((review: StudentImageReviewModel) => {
      return review.reviewedByUserId === this.account.id;
    }) : null;

    wrapper.append(`
      <div class="students-tab-image-overlay">
        ${this.getImageStars(currentUserReview)}
        ${this.getCommentIcon(currentUserReview)}
        ${this.getExhibitedIcon(image)}
      </div>
    `);

    const educatorButtons = wrapper.find(`.students-tab-image-overlay .educator-thumb-button`);
    const rateButton = wrapper.find(`.students-tab-image-overlay .educator-thumb-button.rate-button`);
    const commentButton = wrapper.find(`.students-tab-image-overlay .educator-thumb-button.comment-button`);
    const exhibitButton = wrapper.find(`.students-tab-image-overlay .educator-thumb-button.exhibited-button`);

    rateButton.click(() => {
      this.openStudentImageReviewModal(image);
    });

    commentButton.click(() => {
      this.openStudentImageReviewModal(image);
    });

    exhibitButton.click(() => {
      this.onAddToExhibitionButtonClick(image);
    });
  
    educatorButtons.on('mouseenter', (e: MouseEvent) => {
      const target: HTMLElement = <HTMLElement>e.currentTarget;

      target.classList.toggle('left-side', target.getBoundingClientRect().left >= this.dropzone.element.clientWidth);
    });
  }

  private getImageStars(review: StudentImageReviewModel): string {
    if (!review || review.rate === 0) {
      return '';
    }

    return `
      <div class="educator-thumb-button rate-button">
        <div class="educator-thumb-button-value">${review.rate}</div>
        <div class="tooltip-box">
          <div class="hint">
            <div class="triangle"></div>
            <div class="text-wrapper">
              <div class="text">
                <div>This image has is a ${review.rate} star rating.</div>
                <div>Click to see rating.</div>
              </div>
            </div>
          </div>
        </div>
      </div>`;
  }

  private getCommentIcon(review: StudentImageReviewModel): string {
    if (!review) {
      return '';
    }
    
    if (review.text.length === 0 && review.audios.length === 0 && review.imageUpdateSuggestions.length === 0) {
      return '';
    }

    return `
      <div class="educator-thumb-button comment-button">
        <div class="educator-thumb-button-value">R</div>
        <div class="tooltip-box">
          <div class="hint">
            <div class="triangle"></div>
            <div class="text-wrapper">
              <div class="text">
                <div>This image has a comment.</div>
                <div>Click to Edit comment.</div>
              </div>
            </div>
          </div>
        </div>
      </div>`;
  }

  private getExhibitedIcon(image: ImageModel): string {
    if (!image || !image.exhibitedImages || image.exhibitedImages.length === 0) {
      return '';
    }

    return `
      <div class="educator-thumb-button exhibited-button">
        <div class="educator-thumb-button-value exhibited">E</div>
        <div class="tooltip-box">
          <div class="hint">
            <div class="triangle"></div>
            <div class="text-wrapper">
              <div class="text">
                <div>This Image has been added to an online exhibit.</div>
                <div>Click to REMOVE from exhibit.</div>
                <div>Re-add by click Exhibit button below.</div>
              </div>
            </div>
          </div>
        </div>
      </div>`;
  }

  private initIsSelectedImageExhibited(): void {
    this.isSelectedImageExhibited = this.currentImageExhibitedImages && this.currentImageExhibitedImages.length > 0;
  }

  private initStudentImageOverlay(wrapper, image: ImageModel): void {
    if (!image || !image.reviews || image.reviews.length === 0 || this.isStudentsTab) {
      return;
    }

    wrapper.append(this.generateStudentImageOverlay(image));
    
    const ratingBar: HTMLElement = this.generateStudentImageRatingBar(wrapper[0], image);

    if (!ratingBar) {
      return;
    }

    wrapper.addClass('with-rate-bar');

    wrapper.append(ratingBar);
  }

  private generateStudentImageOverlay(image: ImageModel, options: { isForceNew: boolean } = { isForceNew: false }): HTMLElement {
    const overlay: HTMLElement = document.createElement('div');
    const buttonWrapper: HTMLElement = document.createElement('div');
    const button: HTMLElement = document.createElement('div');

    button.classList.add('open-reviews-button');

    const isNew: boolean = options.isForceNew || this.isImageHasNewReview(image.id);

    if (isNew) {
      buttonWrapper.classList.add('new-review');
    }

    button.innerText = 'R';

    buttonWrapper.classList.add('open-reviews-button-wrapper');
    
    buttonWrapper.appendChild(button);

    overlay.classList.add('student-image-overlay');
    
    overlay.appendChild(buttonWrapper);

    buttonWrapper.addEventListener('click', () => {
      this.handleReviewModalOpen(image);
    });

    return overlay;
  }

  private generateStudentImageRatingBar(wrapper: HTMLElement, image: ImageModel): HTMLElement {
    if (!image || !image.reviews || image.reviews.every((review: StudentImageReviewModel) => !review.rate)) {
      return null;
    }

    const rating: number = image.reviews.reduce((res, curr) => res + curr.rate, 0) / image.reviews.length;

    const overlay: HTMLElement = document.createElement('div');

    overlay.classList.add('student-image-rating-bar');

    overlay.addEventListener('click', () => {
      this.handleReviewModalOpen(image);
    });

    const isNarrowImage: boolean = this.isNarrowThumbnail(wrapper, image, rating);

    if (isNarrowImage) {
      overlay.innerHTML += `<i class="star fa fa-star" aria-hidden="true"></i><span class="rate-value">${Math.round(rating * 10) / 10}</span>`;
  
      return overlay;
    }

    for (let i = 1; i <= rating; i++) {
      overlay.innerHTML += `<i class="star fa fa-star" aria-hidden="true"></i>`;
    }

    return overlay;
  }

  private handleReviewModalOpen(image: ImageModel): void {
    this.portfolioViewsService.viewImage(image.portfolioId, image.id);

    this.openReviewsList(image);
  }

  private isNarrowThumbnail(wrapper: HTMLElement, image: ImageModel, rating: number): boolean {
    if (!wrapper || !image || !rating) {
      return false;
    }

    const expectedWidth: number = this.calculateCellWidth(wrapper, image);

    return expectedWidth < 16 * rating;
  }

  private calculateCellWidth(wrapper: HTMLElement, image: ImageModel): number {
    if (!wrapper || !image) {
      return 0;
    }

    return image.widthPx * wrapper.clientHeight / image.heightPx;
  }

  public openEnlargedImageReviews(): void {
    if (!this.largeImage) {
      return;
    }

    this.portfolioViewsService.viewImage(this.largeImage.portfolioId, this.largeImage.id);

    this.openReviewsList(this.largeImage);
  }

  private handleReviewIcons(): void {
    if (!this.newReviewsData || !this.dropzone || !this.dropzone.element) {
      return;
    }
    
    const keys: string[] = Object.keys(this.newReviewsData.images);

    if (keys.length === 0) {
      return;
    }

    const selector: string = keys.map((imageId: string) => `[data-imageid="${imageId}"]`).join(',');

    const elements: HTMLElement[] = <HTMLElement[]>this.dropzone.element.querySelectorAll(selector);

    elements.forEach((element: HTMLElement) => {
      const imageId: string = element.getAttribute('data-imageid');

      const reviewsButton: HTMLElement = <HTMLElement>element.querySelector('.open-reviews-button-wrapper');
  
      if (reviewsButton) {
        reviewsButton.classList.toggle('new-review', this.isImageHasNewReview(imageId));
      }
    });
  }

  private isImageHasNewReview(imageId: number | string): boolean {
    if (!this.newReviewsData) {
      return false;
    }

    if (this.newReviewsData.images[imageId] && this.newReviewsData.images[imageId].isNew) {
      return true;
    }

    if (!this.newReviewsData.imageUpdateSuggestions[imageId]) {
      return false;
    }

    return Object.keys(this.newReviewsData.imageUpdateSuggestions[imageId].suggestions).some((suggestionId: string) => {
      return this.newReviewsData.imageUpdateSuggestions[imageId].suggestions[suggestionId];
    });
  }

  private openReviewsList(image: ImageModel): void {
    if (!image) {
      return;
    }

    this.reviewsListModalService.open({
      imageId: image.id,
      imageIndex: this.allImages.findIndex((i: ImageModel) => i.id === image.id)
    });
  }

  public onAddToExhibitionButtonClick(image?: ImageModel): void {
    if (!this.educatorStudentPortfolio) {
      return;
    }

    if (!image) {
      image = this.getImageToHandle();
    }

    if (!image) {
      return;
    }

    this.selectExhibitionModalService.imageToClone = image;
    this.selectExhibitionModalService.studentPortfolio = this.educatorStudentPortfolio;
     
    if (this.teacherExhibitions.length === 1) {
      this.onImageExhibit(this.teacherExhibitions[0].id, { isSingle: true });

      return;
    }

    this.selectExhibitionModalService.open();
  }

  private onImageExhibit(exhibitionId: number, { isSingle }: { isSingle: boolean }): void {
    if (!this.isStudentsTab || this.currentView === 'full-view' || this.currentView === 'fullscreen') {
      return;
    }

    this.selectExhibitionModalService.onImageExhibit(exhibitionId, { isSingle });
  }

  private blockExhibitButton(): void {
    this.isExhibitButtonBlocked = true;
    this.isExhibitButtonDone = true;

    if (this.isDestroyed) {
      return;
    }

    this.cdr.detectChanges();
  }

  private unblockExhibitButton(): void {
    this.isExhibitButtonBlocked = false;

    window.setTimeout(() => {
      if (this.isDestroyed) {
        return;
      }

      this.isExhibitButtonDone = false;

      this.cdr.detectChanges();
    }, 2000);
  }

  public onReviewStudentImageButtonClick(): void {
    const image: ImageModel = this.getImageToHandle();

    this.openStudentImageReviewModal(image);
  }

  private getImageToHandle(): ImageModel {
    if (this.currentView === 'full-view' || this.currentView === 'fullscreen') {
      return this.fullscreenImageManagerEnlargementService.currentImage;
    }

    if (this.currentView === 'large') {
      return this.largeImage;
    }

    return this.selected.length === 1 ? this.selected[0] : null;
  }

  private openStudentImageReviewModal(image: ImageModel): void {
    if (!image || !this.educatorStudentPortfolio) {
      return;
    }

    this.reviewStudentImageModalService.dataSubject.next({
      image,
      studentPortfolio: this.educatorStudentPortfolio,
    });

    this.reviewStudentImageModalService.open({
      imageIndex: this.allImages.findIndex((i: ImageModel) => i.id === image.id)
    });
  }

  private initSortable() {
    this.initStudentSidebar();

    const $dropzone = $('#dropzone');

    if ($dropzone.sortable('instance')) {
      this.isDropzoneInitCompleted = false;
      
      $dropzone.sortable('destroy');
    }

    let mouseMoveHandler; // It's here to have possibility remove handler. Need to refactor

    $dropzone.sortable({
      helper: (event: Event, element: Sortable): Element => {
        this.reselectElementIfNeed(event, element);
        return this.getSelectedImagesGhosts($(element[0]));
      },
      appendTo: document.body,
      items: '.dz-preview',
      placeholder: 'sortable-placeholder',
      cancel: '.dz-error,.student-image-overlay,.dots-container,.student-image-rating-bar,.students-tab-image-overlay',
      connectWith: '.droppable',
      scroll: false,
      tolerance: 'pointer',
      cursorAt: {
        top: 10,
        left: 10
      },
      start: (event, ui) => {
        const previewElement = ui.item.first();
        const previewElementImageId = +previewElement.data('imageid');
        const image: ImageModel = this.allImages.find((img: ImageModel) => img.id === previewElementImageId);

        this.sendSocketAction(SOCKET_ACTIONS.IMAGE_REORDER_START);

        //  if image isn't in selected images, then select this image
        if (image && !this.selected.some((selectedImage => selectedImage.id == image.id))) {
          this.toggleSelect({}, {
            image,
            previewElement: previewElement[0],
          }, true);
        }

        ui.placeholder.width(ui.item.width());
        ui.placeholder.height(ui.item.height());

        const ghost = ui.helper.first();

        if (event.metaKey) {
          ghost.css({ 'cursor': 'copy' });
        }

        ghost.addClass('dragging-image-ghost');

        this.dragging = true;

        mouseMoveHandler = (e) => this.handleMouseMove(e, event.clientY);
        window.addEventListener('mousemove', mouseMoveHandler);
      },
      beforeStop: (event, ui) => {
        const dropDestinationSpan = ui.placeholder.parent();
        const dropDestinationDiv = dropDestinationSpan.parent();

        const isMoved: boolean = ui.item[0].matches('[data-moved=true]');

        if (isMoved) {
          this.handleMovedStudentImages(event, ui);
          
          return;
        }

        if (dropDestinationDiv.hasClass('page-node')) {
          const selectedData = ui.item.data('selected');
          const nodekey = dropDestinationSpan.data('nodekey');
          const nodetype = dropDestinationSpan.data('nodetype');

          const sourcePortfolioId = selectedData && selectedData.length
            ? selectedData[0].portfolioid
            : null;

          if (nodekey === sourcePortfolioId && nodetype === 'P') {
            this.cancelReordering(event, ui);
          } else {
            this.startInsertImageToPortfolioAnimation(event, ui);
          }
        }
      },
      update: (event, ui) => {
        if (event.metaKey) return;

        const targetId = ui.item.parent().data('nodekey');

        const isSamePage: boolean = targetId === this.selectedPage.id;

        if (this.selected.length > 1) {
          const imagesElements: HTMLElement[] = this.selected.map((item: ImageModel) => <HTMLElement>this.dropzone.element.querySelector(`[data-imageid="${item.id}"]`));
  
          this.handleMultipleElementsDrag(ui.item[0], imagesElements);
        }

        const reordered: ImageModel[] = this.getImagesFromDropzone();
    
        if (!isSamePage || targetId === void 0) {
          this.initImages(reordered);
        }

        if (targetId !== void 0 && this.selectedPage && !isSamePage) return;

        const images = reordered.reduce((res, item) => {
          if (item && item.id) {
            res.push(item.id);
          }
          return res;
        }, []);
        
        return this.imageManagerService.reorderImages({
          portfolioId: this.getCurrentPortfolioId(),
          images,
        }).subscribe(() => {
          this.imagesIconsService.initIcon(this.selectedPage.id);

          this.sendSocketAction(SOCKET_ACTIONS.IMAGE_REORDER_STOP);
        });
      },
      deactivate: (event, ui) => {
        if (event.metaKey) return this.cancelReordering(event, ui);
      },
      stop: (event, ui) => {
        this.dragging = false;

        clearTimeout(this.dragTimer);

        window.removeEventListener('mousemove', mouseMoveHandler);
        
        if (!this.dropzone.element.contains(ui.item[0]) && !this.dropzoneImagePreviews.length) {
          this.showDropzoneMessage();

          this.imageDetailsService.imageDetailsSubject.next(null);
        }

        this.sendSocketAction(SOCKET_ACTIONS.IMAGE_REORDER_STOP);
      },
      remove: (event, ui) => {
        if (event.metaKey) return;

        let selected = $(ui.item).data('selected') || [];

        // [evgen] could be someting?
        selected = selected.map(item => {
          return item.ImageID;
        });

        // add the dropped image to the selected images list
        const droppedImageId = $(ui.item[0]).data('imageid');

        if (droppedImageId && !selected.includes(droppedImageId)) {
          selected.push(droppedImageId);
        }

        // get distinct selected images
        selected = selected.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

          //paths.icon
          const icon = this.allImages && this.allImages[0] ? this.allImages[0].paths.icon : null;

          if (!this.selectedPage) {
            return;
          }

          const data = this.selectedImagesData;

          ui.item.data('imagesData', data);

          this.onImagesRemove(this.selectedPage.id, data);

          this.initCurrentImages();

          this.imagesIconsService.setIcon(this.selectedPage.id, icon);
      }
    });

    this.isDropzoneInitCompleted = true;
  }

  private handleMovedStudentImages(event, ui): void {
    const movedImageElements: HTMLElement[] = this.movedImageElements;

    const ids: number[] = movedImageElements.map((element: HTMLElement) => {
      return Number.parseInt(ui.item[0].getAttribute('data-imageid'));
    });

    movedImageElements.forEach((image: HTMLElement) => {
      image.remove();
    });

    this.allImages = this.allImages.filter((image: ImageModel) => {
      return !ids.includes(image.id);
    });

    this.bag.setImages(this.allImages);

    window.setTimeout(() => {
      this.selectFirstImageInPortfolio();
      
      if (!this.dropzoneImagePreviews.length)
        this.showDropzoneMessage();
    }, 0);
  }

  private initStudentSidebar(): void {
    if (!this.isStudentTab) {
      return;
    }

    const elem = $('.portfolio-wrapper');

    if (elem.droppable('instance')) {
      elem.droppable('destroy');
    }

    $(elem).droppable({
      accept: '.dz-preview',
      hoverClass: 'drop-hover',
      tolerance: 'pointer',
      drop: (event, ui) => {
        this.studentImageMoveService.onDrop(event, ui);
      },
    });
  }

  private getCurrentPortfolioId(): number {
    if (this.isStudentTab) {
      return this.studentPortfolio ? this.studentPortfolio.id : null;
    }

    if (this.isStudentsTab) {
      return this.educatorStudentPortfolio ? this.educatorStudentPortfolio.id : null;
    }

    if (this.isExhibitionsTab) {
      return this.exhibitionPortfolio ? this.exhibitionPortfolio.id : null;
    }

    return this.selectedPage ? this.selectedPage.id : null;
  }

  private reInitCurrentImages(): void {
    const images: ImageModel[] = this.getImagesFromDropzone();

    this.initImages(images);

    if (this.allImages.length === 0) {
      return;
    }

    this.hideDropzoneMessage();
  }

  private getImagesFromDropzone(): ImageModel[] {
    const reordered: ImageModel[] = [];

    this.dropzoneImagePreviewsWithoutError.each((idx, el) => {
      const imageId = $(el).data('imageid');

      const image: ImageModel = this.images.find((item: ImageModel) => {
        return item && item.id == imageId;
      });

      if (!image) return;

      reordered.push(image);
    });

    return reordered;
  }

  private initImages(images: ImageModel[]): void {
    this.allImages = images;
    this.imageManagerService.allImages = images;

    this.doCurrentImagesInit();

    if (this.selected[0]) {
      const idx: number = this.allImages.findIndex((image: ImageModel) => image.id === this.selected[0].id);

      this.imageManagerService.onImageClickSubject.next(idx);
    }
  }
  
  private handleMultipleElementsDrag(draggedElement: HTMLElement, imageElements: HTMLElement[]) {
    const draggedIdx: number = imageElements.findIndex((item: HTMLElement) => item === draggedElement);

    if (draggedIdx === -1) return;

    for (let i = 0; i < draggedIdx; i++) {
      draggedElement.parentElement.insertBefore(imageElements[i], draggedElement);
    }

    if (draggedIdx === imageElements.length - 1) return;

    for (let i = imageElements.length - 1; i > draggedIdx; i--) {
      draggedElement.parentElement.insertBefore(imageElements[i], draggedElement.nextSibling);
    }
  }

  appendMoreImages() {
    if (this.images.length < this.allImages.length) {
      const moreImages = this.allImages.slice(this.images.length, this.images.length + IMAGES_PER_PAGE);

      this.images = this.images.concat(moreImages);
      this.loadedImagesCounter = moreImages.length;

      this.addImages({ images: moreImages });
    }
  }

  cancelReordering(event, ui) {
    $('#dropzone').sortable('cancel');

    //  because this element has display 'block' by default and has incorrect view
    const el = $(ui.item.get(0));
    el.css('display', 'inline-block');
  }

  getSelectedElementsWithoutClicked(clickedElement) {
    return clickedElement.parent().children('.dz-image-preview.selected').not(clickedElement);
  }

  getImageGhostDefaultStyles() {
    return {
      'top': '0',
      'left': '0',
      'opacity': 0.7,
      'z-index': 9999999,
      'max-height': '90px',
      'width': 'auto',
    };
  }

  getImageGhostListWrapper(startPosition) {
    const $wrapper = $(`<div></div>`);
    $wrapper.css({
      'position': 'absolute',
      'top': `${startPosition.top}px`,
      'left': `${startPosition.left}px`,
      'z-index': 9999999,
      'max-height': '90px'
    });
    $wrapper.appendTo(document.body);
    return $wrapper;
  }

  getImageGhostList($wrapper) {
    const $list = $(`<div></div>`);
    $list.css({
      'position': 'relative',
    });
    $wrapper.append($list);
    return $list;
  }

  addElementToGhostList($list, $element, styles) {
    const $ghost = $element.clone();
    $ghost.css(styles);
    $ghost.addClass('ui-sortable-helper');
    $list.append($ghost);
  }

  getNotClickedElementStyles(elementPosition, mainElementPosition, defaultStyles) {
    const posX = elementPosition.left - mainElementPosition.left;
    const postY = elementPosition.top - mainElementPosition.top;
    const transform = `translate(${posX}px, ${postY}px)`;
    return Object.assign(defaultStyles, {'position': 'absolute', transform});
  }

  addElementsToGhostList($list, elements, startPosition) {
    Array.from(elements).forEach((element: HTMLElement) => {
      const elPos = element.getBoundingClientRect();
      const styles = this.getNotClickedElementStyles(elPos, startPosition, this.getImageGhostDefaultStyles());
      this.addElementToGhostList($list, $(element), styles);
    });
  }

  getSelectedImagesGhosts(draggedElement) {
    const elements = this.getSelectedElementsWithoutClicked(draggedElement);
    const draggedElPosition = draggedElement.get(0).getBoundingClientRect();
    const $wrapper = this.getImageGhostListWrapper(draggedElPosition);
    const $list = this.getImageGhostList($wrapper);

    this.addElementToGhostList($list, draggedElement, this.getImageGhostDefaultStyles());
    this.addElementsToGhostList($list, elements, draggedElPosition);

    return $wrapper.get(0);
  }

  startInsertElementAnimation(element) {
    const animLastStepStyles = {transform: 'translate(-50%, -50%) scale(0.01, 0.01)'};
    element.on('transitionend', () => element.remove());
    setTimeout(() => element.css(animLastStepStyles)); // To start animation after applying default styles
  }

  setInsertAnimationDefaultStyles(element, startPosition) {
    const defaultStyles = {
      'position': 'absolute',
      'top': `${startPosition.top}px`,
      'left': `${startPosition.left}px`,
      'opacity': 0.7,
      'z-index': 9999999,
      'max-height': '90px',
      'transition': 'transform .3s'
    };
    element.css(defaultStyles);
  }

  startInsertImageToPortfolioAnimation(event, ui) {
    const element = $(ui.helper[0]).clone();
    this.setInsertAnimationDefaultStyles(element, ui.position);
    element.appendTo(document.body);
    this.startInsertElementAnimation(element);
  }

  setThumbnail(file) {
    if (!file.size) {
      file.previewElement.querySelector('img').src = file.dataURL;
    }
  }

  private DELAY  = 400; // Default double click frame
  private clicks = 0;
  private timer  = null;
  private lastSelectedIdx;
  private currentSelectedIdx;

  private lastCoords: { x: number, y: number } = { x: -1, y: -1, };
  private maxCoordsDiff: number = 5; // px

  unselectImages() {
    if (this.allImages) {
      this.allImages.forEach(i => {
        i.isSelected = false;
      });
    }
  }

  toggleSelect(e, file: { image: ImageModel, previewElement: HTMLElement }, forceSingleClick?) {
    if (!file || !file.image) return;

    window.clearTimeout(this.timer);

    this.clicks = forceSingleClick ? 1 : this.clicks + 1;

    if (this.clicks > 1 && this.isCoordsAcceptable(e)) {
      this.onDoubleClick(file);
    }

    this.initCoords(e);
    this.initIndexes(e, file);
    this.handleKeys(e, file);

    this.imageManagerService.onImageSelectSubject.next(file.image);

    const newSelected = this.allImages.filter(item => item && item.isSelected);

    this.handleImagesSelection(newSelected);

    this.timer = setTimeout(() => { this.clicks = 0; }, this.DELAY);
  }

  private onDoubleClick(file: { image: ImageModel, previewElement: HTMLElement }) {
    this.clicks = 0;

    if (this.currentSubscription && this.currentSubscription.isEducator) {
      this.imageManagerService.viewSubject.next({ key: 'full-view' });

      return;
    }
    
    const image: ImageModel = this.allImages.find((i: ImageModel) => i.id === file.image.id);
    
    this.enlarge(image);
  }

  private initCurrentImages() {
    setTimeout(() => {
      this.doCurrentImagesInit();

      if (this.currentImages.length > 0) return;
      
      this.handleImagesSelection([]);
    }, 100);
  }

  private doCurrentImagesInit() {
    const images: ImageModel[] = [];

    this.dropzoneImagePreviewsWithoutError.each((idx, el) => {
      const imageId = $(el).data('imageid');
      const image: ImageModel = this.allImages.find((item: ImageModel) => item && item.id == imageId);
      
      images.push(image);
    });

    this.currentImages = images;
  }

  private selectImageByIndex(index: number): void {
    if (!Number.isInteger(index) || !this.allImages) {
      return;
    }
    
    const image: ImageModel = index < this.allImages.length ? this.allImages[index] : null;

    this.handleImagesSelection(image ? [image] : []);
  }

  // TODO: Refactor
  handleImagesSelection(selected: ImageModel[]) {
    if (!this.dropzone) {
      return;
    }

    this.selected = selected;

    this.initSelectedImageData();

    const allImages: HTMLElement[] = <HTMLElement[]>Array.from(this.dropzone.element.querySelectorAll(`.dz-preview`));

    allImages.forEach((img: HTMLElement) => {
      img.classList.remove('selected');
    });

    if (!selected || selected.length === 0) return;

    const images: HTMLElement[] = <HTMLElement[]>Array.from(this.dropzone.element.querySelectorAll(`${selected.map((item: ImageModel) => `[data-imageId="${item.id}"]`).join(',')}`));
    
    images.forEach((img: HTMLElement) => {
      img.classList.add('selected');
    });
  }

  isCoordsAcceptable(e) {
    const { clientX, clientY } = e;
    return Math.abs(clientX - this.lastCoords.x) < this.maxCoordsDiff && Math.abs(clientY - this.lastCoords.y) < this.maxCoordsDiff;
  }

  initCoords(e) {
    const { clientX, clientY } = e;
    this.lastCoords.x = clientX;
    this.lastCoords.y = clientY;
  }

  initIndexes(e, file) {
    const nextIdx: number = this.allImages.findIndex((i: ImageModel) => i.id === file.image.id);

    this.currentSelectedIdx = nextIdx === -1 ? 0 : nextIdx;
    this.lastSelectedIdx = e.shiftKey ? this.lastSelectedIdx : this.currentSelectedIdx;

    this.imageManagerService.onImageClickSubject.next(this.currentSelectedIdx);
  }

  handleKeys(e, file) {
    if (e.shiftKey) this.handleShiftKey();
    else if (e.ctrlKey || e.metaKey) this.handleCtrlKey(file);
    else this.handleOtherKeys(file);
  }

  handleShiftKey() {
    const { start, end } = this.getIndexes();

    this.handleNonCtrlKey();
    this.allImages.slice(start, end + 1).forEach(item => {
      item.isSelected = true;
  });
    this.dropzoneImagePreviews.toArray().slice(start, end + 1).forEach(item => $(item).addClass('selected'));
  }

  getIndexes(): { start: number, end: number } {
    if (this.lastSelectedIdx === this.currentSelectedIdx) return { start: void 0, end: void 0 };

    const isLess = this.lastSelectedIdx < this.currentSelectedIdx;

    return {
      start: isLess ? this.lastSelectedIdx : this.currentSelectedIdx,
      end: isLess ? this.currentSelectedIdx : this.lastSelectedIdx,
    };
  }

  handleCtrlKey(file: { previewElement: HTMLElement, image: ImageModel }) {
    file.image.isSelected = !file.image.isSelected;

    $(file.previewElement).toggleClass('selected');
  }

  handleOtherKeys(file: { previewElement: HTMLElement, image: ImageModel }) {
    this.handleNonCtrlKey();

    file.image.isSelected = true;

    $(file.previewElement).addClass('selected');
  }

  handleNonCtrlKey() {
    this.unselectImages();

    this.dropzoneImagePreviews.removeClass('selected');
  }

  private enlarge(image: ImageModel): void {
    if (!image) {
      this.largeImage = null;

      this.initLargeImageData();
      this.initStudentImageReviews();

      return;
    }

    if (this.largeImage && image.id === this.largeImage.id) return;

    this.isLoaded = false;

    // deselect all images
    this.allImages.filter((item: ImageModel) => item.id != image.id).forEach(item => {
      item.isSelected = false;
    });

    // always select the current image in order to enlarge it
    image.isSelected = true;

    const newSelected = this.allImages.filter(item => item.isSelected);

    this.largeImage = image;
    this.isImageLabelOpened = false;

    this.initLargeImageData();
    this.initStudentImageReviews();

    const idx: number = this.allImages.findIndex((i: ImageModel) => i.id === image.id);

    this.onViewChange('large', idx);

    this.handleImagesSelection(newSelected);

    $('#main-scroll').scrollTop(0);
  }

  public onEnlargedClose() {
    this.largeImage = null;

    this.initSelectedImageData();
    this.initStudentImageReviews();
  }

  private initSelectedImageData(): void {
    this.initStudentImageData(!!this.selected && this.selected.length === 1 ? this.selected[0] : null);
  }

  private initLargeImageData(): void {
    if (this.currentView === 'full-view' || this.currentView === 'fullscreen') {
      return;
    }

    this.initStudentImageData(this.largeImage);
  }

  private initStudentImageData(image: ImageModel): void {
    if (!this.isStudentsTab) {
      return;
    }

    if (!image || !this.educatorStudentPortfolio || image.portfolioId !== this.educatorStudentPortfolio.id) {
      this.educationStudentsImagesService.initEmptyReview(image ? image.id : null);

      return;
    }

    this.educationStudentsImagesService.getReview(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      image.id,
    );

    this.educationStudentsImagesService.getExhibitedImages(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      image.id,
    );
  }

  private initStudentImageReviews(): void {
    if (!this.isImageReviewsPermitted || !this.largeImage || !this.largeImage.reviews || this.largeImage.reviews.length === 0) {
      this.largeStudentImageReview = null;

      return;
    }

    this.largeStudentImageReview = new StudentImageReviewModel(
      null,
      this.largeImage.id,
      null,
      null,
      this.largeImage.reviews.reduce((res, curr) => res + curr.rate, 0) / this.largeImage.reviews.length,
      null,
      null,
    );

    this.largeStudentImageReview.isRated = this.largeStudentImageReview.rate > 0;
    this.largeStudentImageReview.isCommented = this.largeImage.reviews.some((review: StudentImageReviewModel) => review.text && review.text.length > 0);
    this.largeStudentImageReview.hasAudio = this.largeImage.reviews.some((review: StudentImageReviewModel) => review.audios && review.audios.length > 0);
    this.largeStudentImageReview.hasUpdateSuggestion = this.largeImage.reviews.some((review: StudentImageReviewModel) => review.imageUpdateSuggestions && review.imageUpdateSuggestions.length > 0);
  }

  public onStarClick(idx: number): void {
    if (this.isReviewStarsBlocked) {
      return;
    }

    this.isReviewStarsBlocked = true;

    this.currentImageReview.rate = idx;

    return this.currentImageReview.isEmpty ? this.addReview() : this.updateReview();
  }

  private addReview(): void {
    this.educationStudentsImagesService.addReview(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      this.largeImage.id,
      this.currentImageReview,
    ).pipe(
      catchError(e => {
        alert(e.error && e.error.message ? e.error.message : 'Something went wrong.');

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.initLargeImageData();

      this.isReviewStarsBlocked = false;
    });
  }

  private updateReview(): void {
    this.educationStudentsImagesService.updateReview(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      this.educatorStudentPortfolio.id,
      this.largeImage.id,
      this.currentImageReview,
    ).pipe(
      catchError(e => {
        alert(e.error && e.error.message ? e.error.message : 'Something went wrong.');
        
        return throwError(() => e);
      })
    ).subscribe(() => {
      this.initLargeImageData();

      this.isReviewStarsBlocked = false;
    });
  }

  public onStarMouseEnter(idx: number): void {
    this.hoveredStarIndex = idx;
  }

  public onStarMouseLeave(): void {
    this.hoveredStarIndex = 0;
  }

  // this operations are performed on recycle images and on permanent delete images
  commonDelete() {
    const images = [...this.selected];

    this.allImages = this.allImages.filter(item => images.findIndex((image) => image.id === item.id) === -1);

    this.dropzoneSelectedImagePreviews.remove();

    setTimeout(() => {
      if (!this.dropzoneImagePreviews.length)
        this.showDropzoneMessage();
    }, 0);

    this.bag.setImages(this.allImages);

    return {
      images,
    };
  }

  // this operations are performed on recycle images and on permanent delete images
  private deleteAllImagesFromDropzone(): void {
    this.allImages = [];

    this.clearFilesInDropzone();

    setTimeout(() => {
      if (this.dropzoneImagePreviews.length) return;

      this.showDropzoneMessage();
    });

    this.handleImagesSelection([]);

    this.imageDetailsService.imageDetailsSubject.next(null);

    this.bag.setImages(this.allImages);
  }

  public onImageDeleteSubmit(): void {
    if (this.isExhibitionsTab || this.isStudentTab) {
      return this.recycleImages();
    }

    return this.imageManagerModalService.isLibrary ? this.deleteImages() : this.recycleImages();
  }

  deleteImages() {
    const imagesToDelete: ImageDataModel[] = this.selectedImagesData;

    const images = [...this.selected];
    const imagesIds = images.map(i => i.id);

    this.closeModal(this.DELETE_IMAGE_MODAL_ID);

    this.isImagesDeleting = true;

    this.imageManagerService.isPageBlockedSubject.next(true);

    if (this.isDestroyed) {
      return;
    }

    this.cdr.detectChanges();

    this.imageManagerService.deleteImages(imagesIds).pipe(
      catchError(e => {
        if (this.errorsMapping[e.error.key]) {
          this.errorsMapping[e.error.key](e.error);
        }
  
        this.imageManagerService.isPageBlockedSubject.next(false);
  
        this.isImagesDeleting = false;

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.commonDelete();

      if (this.selectedPage) {
        this.onImagesRemove(this.selectedPage.id, imagesToDelete);
        
        this.initCurrentImages();
      }

      this.websitesExclamationIconService.fetchIsExclamationIconButtonVisible();

      this.isImagesDeleting = false;

      this.imageManagerService.isPageBlockedSubject.next(false);

      const previews = this.dropzoneImagePreviews;
  
      if (this.allImages.length && previews && previews[0] && previews[0].click) previews[0].click();
      else {
        this.handleImagesSelection([]);

        this.imageDetailsService.imageDetailsSubject.next(null);
      }

      if (this.isDestroyed) {
        return;
      }

      this.cdr.detectChanges();
    });
  }

  private onImagesRemove(pageId: number, images: ImageDataModel[]): void {
    for (let i = 0; i < images.length; i++) {
      this.portfolioViewsService.removeImageFromPortfolio(pageId, images[i].id);
    }

    this.imagesCounterService.onImageRemove(pageId, images);
  }

  private onStudentImagesRemove(pageId: number, images: ImageDataModel[]): void {
    for (let i = 0; i < images.length; i++) {
      this.portfolioViewsService.removeImageFromPortfolio(pageId, images[i].id);
    }

    this.studentImagesCounterService.onImageRemove(pageId, images);

    if (this.studentPortfolio.websiteId === this.websiteId) {
      this.imagesCounterService.onImageRemove(pageId, images);
    }
  }

  private onImagesUsed(err: { key: string, message: string, data: { [key: string]: ImageRemoveErrorDto[] } }): void {
    this.imagesRemoveErrorModalService.open(err);
  }

  public deleteAllImages(): void {
    debugger;
    
    this.isImagesDeleting = true;

    this.imageManagerService.isPageBlockedSubject.next(true);

    this.closeModal(this.DELETE_ALL_IMAGES_MODAL_ID);

    this.imageManagerService.deleteAllImages({ portfolioId: 0 }).pipe(
      catchError(e => {
        if (this.errorsMapping[e.error.key]) {
          this.errorsMapping[e.error.key](e.error);
        }
        
        this.imageManagerService.isPageBlockedSubject.next(false);
  
        this.isImagesDeleting = false;

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.deleteAllImagesFromDropzone();

      this.imagesCounterService.onAllImagesRemove(LIBRARY_ID);
  
      this.initCurrentImages();

      this.websitesExclamationIconService.fetchIsExclamationIconButtonVisible();
      
      this.imageManagerService.isPageBlockedSubject.next(false);

      this.isImagesDeleting = false;
    });
  }

  private sendSocketAction(action: string): void {
    if (this.isEducatorImageManager && this.educatorStudentPortfolio) {
      this.sendEducatorSocketAction(action, this.educatorStudentPortfolio.id);
    }
    
    if (this.isStudentImageManager && this.studentPortfolio) {
      this.sendStudentSocketAction(action, this.studentPortfolio.id);
    }
  }

  private sendEducatorSocketAction(action: string, pageId: number): void {
    if (!this.isStudentsTab || !this.educatorStudentPortfolio) {
      return;
    }
    
    this.educationStudentsImagesService.sendAction(
      this.educatorStudentPortfolio.institutionId,
      this.educatorStudentPortfolio.classId,
      this.educatorStudentPortfolio.studentId,
      pageId,
      this.educatorStudentPortfolio.userId,
      this.educatorStudentPortfolio.websiteId,
      action,
    );
  }

  private sendStudentSocketAction(action: string, pageId: number): void {
    if (!this.isStudentImageManager || !this.studentPortfolio || Number.isNaN(pageId)) {
      return;
    }

    this.educationStudentsService.sendAction(
      action,
      this.account.id,
      this.studentPortfolio.websiteId,
      pageId,
    );
  }

  promptDeleteImages() {
    if (this.selected.length)
      this.openModal(this.DELETE_IMAGE_MODAL_ID);
  }

  private promptDeleteAllImages(): void {
    if (!this.selected.length) return;

    this.openModal(this.DELETE_ALL_IMAGES_MODAL_ID);
  }

  recycleImages() {
    const imagesToDelete = this.selectedImagesData;

    const { images } = this.commonDelete();
    const imagesIds = images.map(i => i.id);

    this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_START);

    this.closeModal(this.DELETE_IMAGE_MODAL_ID);

    if (this.selectedPage) {
      let icon;

      if (this.allImages) {
        const firstImage = this.allImages.filter((image: ImageModel) => !imagesIds.includes(image.id))[0];

        icon = firstImage ? firstImage.paths.ICON : null;
      }

      if (this.tabKey === 'user') {
        this.onImagesRemove(this.selectedPage.id, imagesToDelete);

        this.imagesCounterService.onImageAdd(LIBRARY_ID, images.length);

        this.initCurrentImages();

        this.imagesIconsService.setIcon(this.selectedPage.id, icon);
      }
    }

    this.bag.change.next({ portfoliosChange: true });

    this.moveToLibraryHandlers[this.tabKey]({ imagesIds, images, imagesToDelete });
  }

  private moveStudentImageToLibrary({ imagesIds, images, imagesToDelete }: { imagesIds: number[], images: ImageModel[], imagesToDelete: ImageDataModel[] }): void {
    this.onStudentImagesRemove(this.studentPortfolio.id, imagesToDelete);

    this.studentImagesCounterService.onImageAdd(LIBRARY_ID, images.length);

    if (this.studentPortfolio.websiteId === this.websiteId) {
      this.imagesCounterService.onImageAdd(LIBRARY_ID, images.length);
    }

    this.initCurrentImages();

    this.moveUserImageToLibrary({ imagesIds });
  }

  private moveExhibitionImageToLibrary({ imagesIds }: { imagesIds: number[] }): void {
    const portfolioId: number = this.exhibitionPortfolio.id;
    const images: ImageDataModel[] = this.selectedImagesData;
    const isSelectedWebsite: boolean = this.isExhibitionInActiveWebsite;

    this.educationImageManagerService.moveMultipleImagesToLibrary(this.exhibitionPortfolio, imagesIds).subscribe(() => {
      this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_STOP);
      
      this.afterImagesMove();
      
      this.educationExhibitionsImagesCounterService.onImageRemove(portfolioId, images);

      if (!isSelectedWebsite) {
        return;
      }
      
      this.imagesCounterService.onImageAdd(LIBRARY_ID, images.length);
    });
  }

  private moveUserImageToLibrary({ imagesIds }: { imagesIds: number[] }): void {
    this.imageManagerService.recycleImages({
      websiteId: this.getCurrentWebsiteId(),
      portfolioId: this.getCurrentPortfolioId(),
      imagesIds,
    }).subscribe(() => {
      this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_STOP);

      this.afterImagesMove();
    });
  }

  private afterImagesMove(): void {
    this.selectFirstImageInPortfolio();

    if (this.allImages.length === 0) {
      this.handleImagesSelection([]);

      this.imageDetailsService.imageDetailsSubject.next(null);
    }
  }

  public recycleAllImages(): void {
    const images: ImageDataModel[] = this.selectedImagesData;
    
    this.closeModal(this.DELETE_ALL_IMAGES_MODAL_ID);

    if (!this.deleteAllHandlers[this.tabKey]) {
      return;
    }

    this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_START);

    this.deleteAllHandlers[this.tabKey]({ images });
  }

  private deleteAllUserImages(): void {
    if (!this.selectedPage) {
      this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_STOP);

      return;
    }

    for (let i = 0; i < this.allImages.length; i++) {
      this.portfolioViewsService.removeImageFromPortfolio(this.selectedPage.id, this.allImages[i].id);
    }

    this.deleteAllImagesFromDropzone();

    this.imagesCounterService.onAllImagesMoveToLibrary(this.selectedPage.id);

    this.initCurrentImages();

    this.imagesIconsService.setIcon(this.selectedPage.id, null);

    this.bag.change.next({ portfoliosChange: true });
    
    this.imageManagerService.recycleAllImages({ portfolioId: this.selectedPage.id }).subscribe(() => {
      this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_STOP);

      this.handleImagesSelection([]);

      this.imageDetailsService.imageDetailsSubject.next(null);
    });
  }

  private deleteAllExhibitionImages({ images }): void {
    this.imagesCounterService.onImageAdd(LIBRARY_ID, images.length);

    this.educationExhibitionsImagesCounterService.onImageRemove(this.exhibitionPortfolio.id, images);

    this.deleteAllImagesFromDropzone();

    this.educationImagesService.moveAllToLibrary(
      this.exhibitionPortfolio.institutionId,
      this.exhibitionPortfolio.classId,
      this.exhibitionPortfolio.websiteId,
      this.exhibitionPortfolio.id,
    ).subscribe(() => {
      this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_STOP);
    });
  }

  private deleteAllStudentImages({ images }): void {
    this.onStudentImagesRemove(this.studentPortfolio.id, images)

    this.deleteAllImagesFromDropzone();

    this.initCurrentImages();

    this.imagesIconsService.setIcon(this.studentPortfolio.id, null);

    this.studentImageManagerService.deleteAll({
      websiteId: this.studentPortfolio.websiteId,
      portfolioId: this.studentPortfolio.id,
    }).subscribe(() => {
      this.sendSocketAction(SOCKET_ACTIONS.IMAGE_DELETE_STOP);

      this.handleImagesSelection([]);

      this.imageDetailsService.imageDetailsSubject.next(null);
    });
  }

  public publishImage(image: ImageModel) {
    if (image.isPublished) return;

    image.isPublished = true;

    const previewElement = this.findImagePreviewByImageId(image.id);
    $(previewElement).find('.red-dot').hide();

    if (!this.publishImageHandlers[this.tabKey]) {
      return;
    }

    this.publishImageHandlers[this.tabKey](image);
  }

  private publishExhibitionImage(image: ImageModel) {
    this.educationExhibitionsImagesCounterService.handlePublish(this.selectedPage.id, image.isPublished);

    this.educationImageManagerService.updateExhibitionImagePublishState(this.exhibitionPortfolio, image.id, image.isPublished);

    if (this.imageDetails && image.id === this.imageDetails.id) this.imageDetailsService.setIsPublishedState(true);
  }

  private publishUserImage(image: ImageModel) {
    this.imagesCounterService.handlePublish(this.selectedPage.id, image.isPublished);

    this.imageManagerService.publishImage({
      imageId: image.id,
      isPublish: true,
    });

    if (this.imageDetails && image.id === this.imageDetails.id) this.imageDetailsService.setIsPublishedState(true);
  }

  findImagePreviewByImageId(imageId) {
    return $(`[data-imageId=${imageId}]`);
  }

  closeModal(id: string) {
    this.modalsService.close(id);
  }

  openModal(id: string) {
    this.modalsService.open(id);
  }

  onUploadSuccess(event) {
    const file: { previewElement: HTMLElement, image: ImageModel, status: any, isFileUploaded: boolean } = event[0];
    const image: ImageDto = event[1];

    if (!file) {
      return;
    }

    file.image = ImageDto.normalize(image);

    this.allImages.push(file.image);
    this.images.push(file.image);
    this.bag.setImages(this.allImages);
    this.addImage(file);

    window.setTimeout(() => {
      this.selectFirstImageInPortfolio();
    });

    if (!this.imageUploadSuccessHandlers[this.tabKey]) {
      return;
    }

    this.imageUploadSuccessHandlers[this.tabKey](file);
  }

  private onStudentImageUploadSuccess(): void {
    this.studentImagesCounterService.onImageAdd(this.studentPortfolio.id);

    if (this.studentPortfolio.websiteId === this.websiteId) {
      this.imagesCounterService.onImageAdd(this.studentPortfolio.id);
    }
    
    this.initCurrentImages();
  }

  private onExhibitionImageUploadSuccess(file: { previewElement: HTMLElement, image: ImageModel, status: any, isFileUploaded: boolean }): void {
    this.educationExhibitionsImagesCounterService.onImageAdd(file.image.portfolioId);
  }

  private onUserImageUploadSuccess(file: { previewElement: HTMLElement, image: ImageModel, status: any, isFileUploaded: boolean }): void {
    const icon = file.image.paths.icon;

    this.imagesCounterService.onImageAdd(this.selectedPage.id);
    
    this.initCurrentImages();

    this.imagesIconsService.setIcon(this.selectedPage.id, icon);
  }

  private addImages(options?: any) {
    if (!this.dropzone) return;
    
    const images: ImageModel[] = options ? options.images : this.images;
    const isImagesAppending = options && !options.clearDropzone;

    if (!isImagesAppending) {
      this.clearFilesInDropzone();
    }

    if (images && !images.length) this.loading = false;

    if (!images || !images.length) return this.showDropzoneMessage();

    this.loadedImagesCounter = images.length;

    for (let i = 0; i < images.length; i++) {

      // get image from the end
      const image = images[i];

      const mockFile: any = {
        name: image.title,
        size: 0,
        type: 'image/jpeg',

        // Add the image object to the file
        image: image,

        // Mark the file as 'accepted' because it's already uploaded
        accepted: true,

        isFileUploaded: true,
      };

      // Add it in the files collection
      this.dropzone.files.slice().unshift(mockFile);

      // Call the default addedfile event handler
      this.dropzone.emit('addedfile', mockFile);

      // GENERATE THUMBNAIL WHEN THE FILE IS ADDED
      // And optionally show the thumbnail of the file:

      if (image) {
        this.dropzone.emit('thumbnail', mockFile, `https://${image.paths.mediaSmall}?key=${image.key}`);
      }
      // Or if the file on your server is not yet in the right
      // size, you can let Dropzone download and resize it
      // callback and crossOrigin are optional.
      // this.dropzone.createThumbnailFromUrl(mockFile, this.getImageThumb(file)));

      // Make sure that there is no progress bar, etc...
      this.dropzone.emit('complete', mockFile);

      // DON'T USE THIS, INSTEAD SET mockFile.accepted = true;
      // If you use the maxFiles option, make sure you adjust it to the correct amount:
      // this.dropzone.options.maxFiles--;
    }

    this.selectFirstImageInPortfolio();

    this.imagesLoadedSubject.next(true);
  }

  clearFilesInDropzone() {
    if (this.dropzone) {
      this.dropzoneImagePreviews.remove();
      $('#dropzone').removeClass('dz-started');
    }
  }

  selectImageInPortfolio(idx) {
    const image: ImageModel = this.allImages[idx];

    this.toggleSelect({}, {
      image,
      previewElement: image ? this.findImagePreviewByImageId(image.id)[0] : null,
    }, true);
  }

  selectFirstImageInPortfolio() {
    this.selectImageInPortfolio(0);

    this.currentSelectedIdx = 0;
    this.lastSelectedIdx = 0;
  }

  clearImageSelection() {
    const selected = [];
    this.handleImagesSelection(selected);
    this.unselectImages(); // TODO: ?
  }

  handleFilesSelected(options: { isImageFileNameAsTitle: boolean, images: File[] }) {
    this.isImageFileNameAsTitle = options.isImageFileNameAsTitle;

    Array.from(options.images).forEach(file => this.dropzone.addFile(file));
  }

  publishAllImages() {
    this.allImages.forEach((image: ImageModel) => {
      this.publishImage(image);
    });
  }

  onInfoClick() {
    this.isImageLabelOpened = !this.isImageLabelOpened;
  }

  onImageLoaded() {
    this.isLoaded = true;
    this.setImageWrapperSize();
  }

  setImageWrapperSize() {
    if (!this.isLoaded || !this.enlargedImage) return '';

    this.largeImageLabelWrapper.nativeElement.style.width = '';
    this.largeImageLabelWrapper.nativeElement.style.height = '';

    const imageHeight = this.enlargedImage.nativeElement.clientHeight;
    const titleHeight = 20;

    this.largeImageLabelWrapper.nativeElement.style.height = `${imageHeight + titleHeight}px`;

    const imageWidth = this.enlargedImage.nativeElement.clientWidth;
    this.largeImageLabelWrapper.nativeElement.style.width = `${imageWidth}px`;
  }

  public setThumbHeight(thumbSize: number) {
    this.thumbHeight = this.DEFAULT_IMAGE_HEIGHT * (thumbSize / 100);

    if (!this.dropzone || !this.dropzone.element) return;

    this.dropzone.element.style.setProperty('--thumb-height-px', `${this.thumbHeight}px`);
  }

  public onDragEnter(): void {
    this.isNewDragging = true;

    this.cdr.detectChanges();
  }

  public onDragLeave(): void {
    this.isNewDragging = false;

    this.cdr.detectChanges();
  }

  public onDropEventPrevent(): void {
    this.isNewDragging = false;

    this.isStudentsDropErrorModalVisible = true;

    this.cdr.detectChanges();
  }

  public onDrop(files: File[]): void {
    this.isNewDragging = false;

    this.cdr.detectChanges();

    if (this.isStudentsTab) return;

    this.imageFileNameAsTitleModalService.open(files);
  }

  public onStudentsDropErrorModalClose(): void {
    this.isStudentsDropErrorModalVisible = false;
  }

  public onViewChange(key: string, forceIndex?: number): void {
    this.imageManagerService.viewSubject.next({ key, forceIndex });
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    this.websiteTourService.removeVisibleLocation(TOUR_KEY);
    this.websiteTourService.removeVisibleItem(KEYS.THUMBNAILS_DESCRIPTION);
    this.websiteTourService.removeVisibleItem(KEYS.DRAG_DROP_ACTION_DESCRIPTION);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.imageManagerService.isPageBlockedSubject.next(false);
    
    this.educationImageManagerService.setActiveTab('user', { unlock: true });

    this.selectedPage = null;
    this.activeTeacherTab = null;
    this.activeStudentTab = null;

    window.removeEventListener('resize', this.onResize);
  }
}
