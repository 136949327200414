import {Injectable} from '@angular/core';

@Injectable()
export class UtilsService {
  constructor() {
  }

  public debounce(fn: Function, delay: number): () => void {
    let timeoutId;

    return (...args) => {
      window.clearTimeout(timeoutId);

      timeoutId = window.setTimeout(() => {
        fn(...args);

        timeoutId = null;
      }, delay);
    };
  }

  public debounceCancellable(fn: Function, delay: number): {
    fn: () => void,
    cancel: () => void,
  } {
    let timeoutId;

    return {
      fn: (...args) => {
        window.clearTimeout(timeoutId);
  
        timeoutId = window.setTimeout(() => {
          fn(...args);
  
          timeoutId = null;
        }, delay);
      },
      cancel: () => {
        window.clearTimeout(timeoutId);
      },
    };
  }

  public throttle(fn: Function, delay: number) {
    let previous = 0;

    return (...args) => {
      const now = Date.now();

      if (now - previous > delay) {
        fn(...args);
        previous = now;
      }
    };
  }

  public formatImageDimension(dimension: number, useGrouping: boolean = true): string {
    if (!dimension) return '';

    return dimension.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping
    });
  }

  // async ok
  public async copyToClipboard(text: string) {
    try {
      await window.navigator['clipboard'].writeText(text);
    } catch (e) {
      console.error('Failed to copy: ', e);
    }
  }
}
