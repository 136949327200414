// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-btn {
  position: absolute;
  display: flex;
  transform: translate(-100%, 25%);
  right: 0;
  width: 15px;
  height: 15px;
}
.close-btn:hover {
  opacity: 0.75;
  cursor: pointer;
}
.close-btn .close-icon {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/close-icon/close-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,gCAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,eAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".close-btn {\n  position: absolute;\n  display: flex;\n  transform: translate(-100%, 25%);\n  right: 0;\n  width: 15px;\n  height: 15px;\n\n  &:hover {\n    opacity: .75;\n    cursor: pointer;\n  }\n\n  .close-icon {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
