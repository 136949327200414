import {Component, OnInit, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {EducationTeachersService} from '../../../../../core/services/education/teachers/education-teachers.service';
import {EducationInstitutionsService} from '../../../../../core/services/education/teachers/institutions/education-institutions.service';
import {EducationClassesService} from '../../../../../core/services/education/teachers/institutions/classes/education-classes.service';
import {EducationWebsiteExhibitionsService} from '../../../../../core/services/education/teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';
import {EducationTeachersStudentsService} from '../../../../../core/services/education/teachers/institutions/classes/students/education-students.service';
import {EducationImageManagerService} from '../../../../../core/services/education/image-manager/education-image-manager.service';
import {ImageDetailsService} from '../../../../../core/services/image-manager/image-details/image-details.service';
import {EducationImageManagerActionService} from '../../../../../core/services/education/image-manager/reorder/education-image-manager-reorder.service';
import {WebsitesService} from '../../../../../core/services/websites/websites.service';
import {AuthService} from '../../../../../auth/auth.service';
import {SocketsService} from '../../../../../core/services/interaction/sockets/sockets.service';
import {IsPublishingService} from '../../../../../services/is-publishing/is-publishing.service';

import {EducationStudentPortfolioModel} from '../../../../../core/models/education/portfolios/education-student-portfolio.model';
import {EducationStudentModel} from '../../../../../core/models/education/students/education-student.model';
import {EducationClassModel} from '../../../../../core/models/education/classes/education-class.model';
import {EducationInstitutionModel} from '../../../../../core/models/education/institutions/education-institution.model';
import {EducationTeacherModel} from '../../../../../core/models/education/teachers/education-teacher.model';
import {NestedSelectOption} from '../../../../../core/models/select/nested/nested-select-option.model';
import {PortfolioModel} from '../../../../../core/models/portfolios/portfolio.model';
import {WebsiteModel} from '../../../../../core/models/websites/website.model';
import {TeacherPortfolioDataModel} from '../../../../../core/models/education/teacher-portfolio-data/teacher-portfolio-data.model';
import {ImageManagerActionDataModel} from '../../../../../core/models/education/image-manager-action/image-manager-action-data.model';
import {ISocketWebsitesMessageDataModel} from '../../../../../core/models/sockets/message/websites/i-websites-message-data.model';
import {ISocketImageManagerMessageDataModel} from '../../../../../core/models/sockets/message/image-manager/i-image-manager-message-data.model';

import {SOCKET_ACTIONS} from '../../../../main/image-manager/constants';

@Component({
  selector: 'app-educator-image-manager-sidebar',
  templateUrl: './educator-image-manager-sidebar.component.html',
  styleUrls: ['./educator-image-manager-sidebar.component.scss'],
})
export class EducatorImageManagerSidebarComponent implements OnInit, OnDestroy {
  public teacher: EducationTeacherModel;

  public institutions: EducationInstitutionModel[];
  public institutionsOptions: NestedSelectOption[];
  public selectedInstitution: EducationInstitutionModel;

  public classes: EducationClassModel[];
  public classesOptions: NestedSelectOption[];
  public selectedClass: EducationClassModel;

  public websites: WebsiteModel[];

  public studentsOptions: NestedSelectOption[];
  public selectedStudent: EducationStudentModel;

  public selectedPortfolio: EducationStudentPortfolioModel;

  public isInstitutionsDropdownVisible: boolean = false;
  public isClassesDropdownVisible: boolean = false;

  public isBlockedByAction: boolean = false;

  private website: WebsiteModel;

  private reorderData: { [key: string]: ImageManagerActionDataModel };

  private publishingData: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean };
  
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private websitesSocketDataHandlers = {
    'WEBSITES_LIST_CHANGED': this.initCurrentTeacher.bind(this),
    'ACTIVE_WEBSITE_CHANGED': this.initCurrentTeacher.bind(this),
  };

  private imageManagerSocketDataHandlers = {
    [SOCKET_ACTIONS.PORTFOLIO_ACTION]: this.onPortfolioAction.bind(this),
  };

  private get isPublishing(): boolean {
    if (!this.publishingData || !this.publishingData.isActiveWebsite) {
      return false;
    }

    return this.publishingData.isPublishing;
  }

  constructor(
    private teachersService: EducationTeachersService,
    private educationImageManagerService: EducationImageManagerService,
    private institutionsService: EducationInstitutionsService,
    private classesService: EducationClassesService,
    private websiteExhibitionsService: EducationWebsiteExhibitionsService,
    private studentsService: EducationTeachersStudentsService,
    private educationImageManagerActionService: EducationImageManagerActionService,
    private websitesService: WebsitesService,
    private imageDetailsService: ImageDetailsService,
    private isPublishingService: IsPublishingService,
    private socketsService: SocketsService,
    private authService: AuthService,
  ) {
    this.initCurrentTeacher();
  }

  private initCurrentTeacher(): void {
    this.teachersService.fetchCurrent();
  }

  public ngOnInit(): void {
    this.socketsService.websitesDataSubject.subscribe((data: ISocketWebsitesMessageDataModel) => {
      this.onWebsitesSocketData(data);
    });

    this.socketsService.imageManagerDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISocketImageManagerMessageDataModel) => {
      this.onImageManagerSocketData(data);
    });

    this.authService.websiteChangedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.selectedInstitution = null;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;

      if (this.website && this.selectedClass && this.selectedClass.websites && this.selectedClass.websites.length > 0 && this.website.id === this.selectedClass.websites[0].id) {
        return;
      }
      
      this.handleInstitutions();
    });

    this.studentsService.studentDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationStudentModel) => {
      this.selectedStudent = item;

      this.initPortfolios();
    });

    this.classesService.classDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationClassModel) => {
      this.selectedClass = item;

      this.updateActiveUserWebsite();

      this.initStudents();
      this.initWebsites();
      
      this.onStudentsSelect(this.studentsOptions);
    });

    this.institutionsService.institutionDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationInstitutionModel) => {
      this.selectedInstitution = item;

      this.initClasses();
      
      this.onClassesSelect(this.classesOptions);
    });

    this.teachersService.teacherDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((teacher: EducationTeacherModel) => {
      this.teacher = teacher;

      this.handleInstitutions();
    });

    this.educationImageManagerService.selectedStudentPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPortfolio: EducationStudentPortfolioModel) => {
      if (!selectedPortfolio && !this.selectedPortfolio) {
        return;
      }

      if (selectedPortfolio && this.selectedPortfolio && selectedPortfolio.id === this.selectedPortfolio.id) {
        return;
      }

      this.selectedPortfolio = selectedPortfolio;

      this.initBlockedState();

      this.selectPortfolio(this.selectedPortfolio);
    });

    this.educationImageManagerActionService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { [key: string]: ImageManagerActionDataModel }) => {
      this.reorderData = data;

      this.initBlockedState();
    });

    this.educationImageManagerActionService.onStopSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageManagerActionDataModel) => {
      this.onReorderStop(data);
    });

    this.isPublishingService.isPublishingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean }) => {
      this.publishingData = data;
    });
  }

  private onWebsitesSocketData(data: ISocketWebsitesMessageDataModel): void {
    if (!data || !this.websitesSocketDataHandlers[data.key]) {
      return;
    }

    this.websitesSocketDataHandlers[data.key](data);
  }

  private onImageManagerSocketData(data: ISocketImageManagerMessageDataModel): void {
    if (!data || !this.imageManagerSocketDataHandlers[data.action]) {
      return;
    }

    this.imageManagerSocketDataHandlers[data.action](data);
  }

  private onPortfolioAction(data: ISocketImageManagerMessageDataModel): void {
    if (!this.selectedStudent || !this.selectedClass || !this.selectedInstitution) {
      return;
    }

    if (this.selectedStudent.userId !== data.portfolioOwnerUserId) {
      return;
    }
    
    if (!['CREATE', 'UPDATE', 'DELETE'].includes(data.key)) {
      return;
    }

    this.studentsService.fetchOne(this.selectedInstitution.id, this.selectedClass.id, this.selectedStudent.id).add(() => {

    });
  }

  private handleInstitutions(): void {
    this.initInstitutions();
    
    this.onInstitutionSelect(this.institutionsOptions);
  }

  private initInstitutions(): void {
    this.institutions = this.teacher ? this.teacher.institutions : [];

    this.institutionsOptions = this.institutions.map((item: EducationInstitutionModel) => {
      return new NestedSelectOption(
        item.name,
        `${item.id}`,
      );
    });

    this.isInstitutionsDropdownVisible = this.institutionsOptions.length > 1;

    if (this.institutionsOptions.length === 0) {
      this.clearSelectedInstitution();

      return;
    }

    if (!this.selectedInstitution && (!this.website || this.website.type !== 'Class')) {
      this.institutionsOptions[0].isSelected = true;

      return;
    }

    const institutionId: string = this.website.type === 'Class' ? `${this.website.institutionId}` : `${this.selectedInstitution.id}`;

    const option: NestedSelectOption = this.institutionsOptions.find((item: NestedSelectOption) => {
      return item.value === institutionId;
    });

    (option || this.institutionsOptions[0]).isSelected = true;
  }

  public onInstitutionSelect(options: NestedSelectOption[]): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    const institutions: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedInstitution: EducationInstitutionModel = this.institutions.find((item: EducationInstitutionModel) => {
      return institutions.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!selectedInstitution) {
      return;
    }

    this.institutionsService.fetchOne(selectedInstitution.id);
  }

  private initClasses(): void {
    const allClasses: EducationClassModel[] = this.teacher ? this.teacher.classes : [];

    this.classes = this.selectedInstitution ? allClasses.filter((item: EducationClassModel) => {
      return item.institutionId === this.selectedInstitution.id;
    }) : [];

    this.classesOptions = this.classes.map((item: EducationClassModel) => {
      return new NestedSelectOption(
        `${item.name} "${item.number}"`,
        `${item.id}`,
      );
    });

    this.isClassesDropdownVisible = this.isInstitutionsDropdownVisible || this.classesOptions.length > 1;

    if (this.classesOptions.length === 0) {
      this.clearSelectedClass();

      return;
    }
    
    if (!this.selectedClass && (!this.website || this.website.type !== 'Class')) {
      this.classesOptions[0].isSelected = true;

      return;
    }

    const classId: string = this.website.type === 'Class' ? `${this.website.classId}` : `${this.selectedClass.id}`;

    const option: NestedSelectOption = this.classesOptions.find((item: NestedSelectOption) => {
      return item.value === classId;
    });

    (option || this.classesOptions[0]).isSelected = true;
  }

  public onClassesSelect(options: NestedSelectOption[]): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    if (!this.selectedInstitution) {
      return;
    }
    
    const classes: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedClass: EducationClassModel = this.classes.find((item: EducationClassModel) => {
      return classes.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!selectedClass) {
      return;
    }

    this.classesService.fetchOne(this.selectedInstitution.id, selectedClass.id);
  }

  private updateActiveUserWebsite(): void {
    if (!this.website || !this.selectedClass || !this.selectedClass.websites || this.selectedClass.websites.length === 0) {
      return;
    }

    const newActiveWebsiteId: number = this.selectedClass.websites[0].id;

    if (this.website.id === newActiveWebsiteId) {
      return;
    }

    this.authService.updateActiveWebsite(newActiveWebsiteId, false);
  }

  private initStudents(): void {
    const allStudents: EducationStudentModel[] = this.selectedClass ? this.selectedClass.students : [];

    this.studentsOptions = allStudents.map((item: EducationStudentModel) => {
      return new NestedSelectOption(
        item.user.name,
        `${item.id}`,
      );
    });

    if (this.studentsOptions.length === 0) {
      this.clearSelectedStudent();

      return;
    }
    
    if (!this.selectedStudent) {
      this.studentsOptions[0].isSelected = true;

      return;
    }

    const studentId: string = `${this.selectedStudent.id}`;

    const option: NestedSelectOption = this.studentsOptions.find((item: NestedSelectOption) => {
      return item.value === studentId;
    });

    (option || this.studentsOptions[0]).isSelected = true;
  }

  public onStudentsSelect(options: NestedSelectOption[]): void {
    if (!this.selectedInstitution || !this.selectedClass) {
      return;
    }

    const students: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    const selectedStudent: EducationStudentModel = this.selectedClass.students.find((item: EducationStudentModel) => {
      return students.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });

    if (!selectedStudent) {
      return;
    }

    this.studentsService.fetchOne(this.selectedInstitution.id, this.selectedClass.id, selectedStudent.id);
  }

  private initWebsites(): void {
    this.websites = this.selectedClass ? this.selectedClass.websites : [];

    if (!this.selectedInstitution || !this.selectedClass || this.websites.length === 0) return;
    
    this.websiteExhibitionsService.fetchList(this.selectedInstitution.id, this.selectedClass.id, this.websites[0].id);
  }

  private initPortfolios(): void {
    if (!this.selectedStudent) {
      return;
    }

    if (this.selectedStudent.portfolios.length > 0) {
      if (!this.selectedPortfolio
        || this.selectedPortfolio.studentId !== this.selectedStudent.id
        || this.selectedStudent.portfolios.every((p: PortfolioModel) => p.id !== this.selectedPortfolio.id)
      ) {
        this.selectPortfolio(this.selectedStudent.portfolios[0]);
      }

      return;
    }

    this.imageDetailsService.imageDetailsSubject.next(null);
    this.educationImageManagerService.selectedStudentPortfolioSubject.next(null);
  }

  public selectPortfolio(portfolio: PortfolioModel): void {
    if (this.isBlockedByAction) {
      return;
    }

    this.imageDetailsService.imageDetailsSubject.next(null);

    if (!this.selectedInstitution || !this.selectedClass || !this.selectedStudent) {
      return;
    }

    if (!portfolio) {
      this.educationImageManagerService.selectedStudentPortfolioSubject.next(null);
 
      return;
    }

    this.initPortfolioTeacherData(portfolio);

    this.educationImageManagerService.selectedStudentPortfolioSubject.next(new EducationStudentPortfolioModel(
      portfolio.id,
      this.selectedInstitution.id,
      this.selectedClass.id,
      this.selectedStudent.id,
      portfolio.userId,
      portfolio.websiteId,
      portfolio.nodeId,
    ));
  }

  private initPortfolioTeacherData(portfolio: PortfolioModel): void {
    if (!portfolio) {
      return;
    }

    if (!portfolio.teacherData) {
      portfolio.teacherData = new TeacherPortfolioDataModel();
    }

    portfolio.teacherData.status = 'REVIEWED';
    portfolio.teacherData.lastViewedAt = 'Now';
  }

  private initBlockedState(): void {
    if (!this.reorderData || !this.selectedPortfolio) {
      this.isBlockedByAction = false;

      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId]) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});

    const websiteId: number = this.selectedPortfolio ? this.selectedPortfolio.websiteId : null;
    
    this.isBlockedByAction = !!websiteIds[`${websiteId}`];
  }

  private onReorderStop(data: ImageManagerActionDataModel): void {
    if (!data) {
      return;
    }

    const websiteId: number = this.selectedPortfolio ? this.selectedPortfolio.websiteId : null;

    if (data.websiteId !== websiteId) {
      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId] && portfolioId !== `${data.portfolioId}`) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});

    this.isBlockedByAction = !!websiteIds[websiteId];
  }

  private clearSelectedInstitution(): void {
    this.selectedInstitution = null;

    this.clearSelectedClass();

    this.institutionsService.institutionDetailsSubject.next(null);
  }

  private clearSelectedClass(): void {
    this.selectedClass = null;

    this.clearSelectedStudent();

    this.classesService.classDetailsSubject.next(null);
  }

  private clearSelectedStudent(): void {
    this.selectedStudent = null;
    
    this.studentsService.studentDetailsSubject.next(null);
    this.imageDetailsService.imageDetailsSubject.next(null);
    this.educationImageManagerService.selectedStudentPortfolioSubject.next(null);
  }

  public ngOnDestroy(): void {
    this.institutionsService.institutionDetailsSubject.next(null);
    
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
