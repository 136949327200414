const BUTTONS_KEYS = {
  SAVE: 'SAVE',
  CLOSE: 'CLOSE',
  CANCEL: 'CANCEL',
  PUBLISH: 'PUBLISH',
  MOBILE_DEVICE: 'MOBILE_DEVICE',
  TABLET_DEVICE: 'TABLET_DEVICE',
  DESKTOP_DEVICE: 'DESKTOP_DEVICE',


  RESET_TO_DEFAULTS: 'RESET_TO_DEFAULTS', // ??????????
};

export {
  BUTTONS_KEYS,
};
