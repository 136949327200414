export const TOUR_KEYS = {
  IMAGE_MANAGER: 'SIDEBAR_IMAGE_MANAGER_ICON',
  CUSTOMIZER: 'SIDEBAR_CUSTOMIZER_ICON',
  BLOCKS: 'SIDEBAR_BLOCKS_ICON',
};

export const TOUR_DATA = {
  [TOUR_KEYS.IMAGE_MANAGER]: {
    TOUR_GROUP_KEY: 'SIDEBAR_IMAGE_MANAGER_ICON',
    TOUR_KEY: 'SIDEBAR_IMAGE_MANAGER_ICON_ACTIVE',
    TOUR_LOCATION: 'SIDEBAR_IMAGE_MANAGER_ICON',
  },
  [TOUR_KEYS.CUSTOMIZER]: {
    TOUR_GROUP_KEY: 'SIDEBAR_CUSTOMIZER_ICON',
    TOUR_KEY: 'SIDEBAR_CUSTOMIZER_ICON_ACTIVE',
    TOUR_LOCATION: 'SIDEBAR_CUSTOMIZER_ICON',
  },
  [TOUR_KEYS.BLOCKS]: {
    TOUR_GROUP_KEY: 'SIDEBAR_BLOCKS_ICON',
    TOUR_KEY: 'SIDEBAR_BLOCKS_ICON_ACTIVE',
    TOUR_LOCATION: 'SIDEBAR_BLOCKS_ICON',
  },
};
