import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Observable, Subject, Subscription, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import * as $ from 'jquery';

import {PagesService} from '../pages.service';
import {WebsiteDesignerService} from '../../../../main/website-designer/website-designer.service';
import {NavigationService} from '../../../../../services/navigation.service';
import {ModalsService} from '../../../../../shared/services/modals/modals.service';
import {MessageModalService} from '../../../../../services/message-modal.service';
import {DeleteNodeModalService} from '../../../../../shared/services/modals/delete-node/delete-node-modal.service';
import {PrivatePagePasswordModalService} from '../../../../../shared/services/modals/private-page-password/private-page-password-modal.service';
import {DirectLinkInputModalService} from '../../../../../shared/services/modals/direct-link-input/direct-link-input-modal.service';
import {IFrameRoutingService} from '../../../../../core/services/iframe/routing/iframe-routing.service';
import {ImageManagerService} from '../../../../main/image-manager/image-manager.service';
import {ImagesCounterService} from '../../../../../core/services/image-manager/counters/images-counter.service';
import {ImagesIconsService} from '../../../../../core/services/image-manager/icons/images-icons.service';
import {AuthService} from '../../../../../auth/auth.service';
import {AddNodeModalService} from '../../../../../shared/services/modals/add-node/add-node-modal.service';
import {NodesService} from '../../../../../core/services/nodes/nodes.service';
import {SidebarLoaderService} from '../../../../../services/sidebar-loader.service';
import {WebsitesService} from '../../../../../core/services/websites/websites.service';
import {ButtonsService} from '../../../../../core/services/buttons/buttons.service';
import {RemoveHomepageErrorModalService} from '../../../../../shared/services/modals/remove-homepage-error/remove-homepage-error-modal.service';
import {WebsiteTourService} from '../../../../../core/services/website-tour/website-tour.service';
import {PermissionsService} from '../../../../../core/services/service-permissions/permissions/permissions.service';
import {PortfolioService} from '../../../../../core/services/portfolios/portfolio.service';
import {EducationStudentsService} from '../../../../../core/services/education/students/education-students.service';
import {EducationImageManagerActionService} from '../../../../../core/services/education/image-manager/reorder/education-image-manager-reorder.service';
import {EventsService} from '../../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../../core/services/iframe/iframe.service';
import {PortfolioViewsService} from '../../../../../core/services/portfolios/views/portfolio-views.service';
import {UtilsService} from '../../../../../core/services/utils/utils.service';
import { TreeService } from '../../../../../services/tree/tree.service';

import {CustomMenuStylesService} from '../../../../../core/services/styles/custom-menu/custom-menu-styles.service';
import {ModalHeader} from '../../../../../common/models/modal/header/header.model';
import {Button} from '../../../../../common/models/button/button.model';
import {AccountModel} from '../../../../../core/models/accounts/account.model';
import {ImageDataModel} from '../../../../../core/models/image-manager/counters/image-data.model';
import {WebsiteModel} from '../../../../../core/models/websites/website.model';
import {SelectedPageModel} from '../../../../../core/models/selected-page/selected-page.model';
import {ImagesCounterModel} from '../../../../../core/models/image-manager/counters/images-counter.model';
import {MenuSetupModel} from '../../../../../core/models/styles/setup/menu/desktop/menu-setup.model';
import {ImageManagerActionDataModel} from '../../../../../core/models/education/image-manager-action/image-manager-action-data.model';
import {IPermissionData} from '../../../../../core/models/permission/i-permission-data';
import {INewReviewsData} from '../../../../../core/models/education/students/images/reviews/i-new-reviews-data.model';
import {NodeModel} from '../../../../../core/models/nodes/node.model';
import { TreeItemModel } from '../../../../../core/models/nodes/tree-item.model';
import { FlatNodeModel } from '../../../../../core/models/nodes/flat-node.model';
import { NodeMoveDto } from '../../../../../core/models/nodes/node-move.dto';

import {PAGE_GROUP_TYPES} from './constants';
import {LIBRARY_ID} from '../../../../constants';
import {KEYS} from '../../../../../core/services/website-tour/constants';
import {PERMISSIONS} from '../../../../../core/services/service-permissions/constants';
import {SOCKET_ACTIONS} from '../../../../main/image-manager/constants';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class AppTreeComponent implements OnInit, OnDestroy {
  @Input() pagesType: string;

  @Output() loadingStartHandler: EventEmitter<string> = new EventEmitter();
  @Output() loadingFinishHandler: EventEmitter<string> = new EventEmitter();
  @Output() nodesLoadHandler: EventEmitter<{ pageType: string, isEmpty: boolean }> = new EventEmitter();

  @ViewChild('tree') treeComponent: any;//TreeComponent;

  @ViewChild('editInput') set editInput(element: ElementRef) {
    if (element) element.nativeElement.focus();
  }

  public preparedNodes: TreeItemModel[];

  public options: any;//ITreeOptions;

  public selectedNode: SelectedPageModel;
  public selectedTreeItem: TreeItemModel;

  public newReviewsData: INewReviewsData;

  public isStudent: boolean = false;
  public isSharePermitted: boolean = false;
  public isImageManager: boolean = false;
  public isOriginalHomePageExists: boolean = false;
  public isDropdownVisible: boolean = false;
  public isBlockedByAction: boolean = false;
  public isSplashEnabled: boolean = false;
  public isSplashSelected: boolean = false;
  public isDraggable: boolean = false;
  public isImagesMoving: boolean = false;
  public isEmpty: boolean = false;

  public editNodeId: number = 0;

  private account: AccountModel;

  private website: WebsiteModel;

  private nodes: NodeModel[] = [];

  private counters: ImagesCounterModel = null;

  private reorderData: { [key: string]: ImageManagerActionDataModel };

  private loadingPortfolioIconsCount: number = 0;

  // Node that handle private page option toggle, but was prevent by opening password modal
  private nodeToggledPrivatePage: TreeItemModel;

  private sequenceOffset: number;

  private rawTreeNodes: TreeItemModel[];

  private _isLoading: boolean = true;

  private isImageManagerBlocked: boolean = false;

  private errorModalButtons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onModalClose.bind(this),
    }
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get pageGroupTypes(): { ALL: string, HOME: string, HORIZONTAL: string, VERTICAL: string } {
    return PAGE_GROUP_TYPES;
  }

  public get tourGroupKey(): string {
    return this.isImageManager ? 'SIDEBAR_IMAGE_MANAGER' : 'SIDEBAR_PAGES';
  }

  public get tourKey(): string {
    return this.isImageManager ? 'SIDEBAR_IMAGE_MANAGER' : 'SIDEBAR_PAGES_LIST';
  }

  public get tourLocation(): string {
    return this.isImageManager ? 'SIDEBAR_IMAGE_MANAGER' : 'SIDEBAR_PAGES';
  }

  private get homepage(): NodeModel {
    return this.nodes.find((n: NodeModel) => n.isHomePage);
  }

  private get menuSetup(): MenuSetupModel {
    return this.customMenuStylesService.setup;
  }

  private get isLoading(): boolean {
    return this._isLoading || this.loadingPortfolioIconsCount > 0;
  }
  private set isLoading(value) {
    this._isLoading = value;

    if (this._isLoading) {
      this.loadingStartHandler.emit(this.pagesType);
      return;
    }

    this.loadingFinishHandler.emit(this.pagesType);
  }

  // private get nodes(): NodeModel[] {
  //   return this._nodes;
  // }
  // private set nodes(value: NodeModel[]) {
  //   this.updatePreparedNodes(value);

  //   this._nodes = value;

  //   this.initDroppable();

  //   this.nodesLoadHandler.emit({ pageType: this.pagesType, isEmpty: !value || value.length === 0 });

  //   if (!this.selectedNode || !this.nodes || this.nodes.length === 0) return;

  //   this.selectPage(this.selectedNode);
  // }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private pagesService: PagesService,
    private nodesService: NodesService,
    private imageManagerService: ImageManagerService,
    private websiteDesignerService: WebsiteDesignerService,
    private websitesService: WebsitesService,
    private iFrameRoutingService: IFrameRoutingService,
    private modalsService: ModalsService,
    private navigationService: NavigationService,
    private messageModalService: MessageModalService,
    private imagesCounterService: ImagesCounterService,
    private imagesIconsService: ImagesIconsService,
    private deleteNodeModalService: DeleteNodeModalService,
    private privatePagePasswordModalService: PrivatePagePasswordModalService,
    private directLinkInputModalService: DirectLinkInputModalService,
    private addNodeModalService: AddNodeModalService,
    private sidebarLoaderService: SidebarLoaderService,
    private customMenuStylesService: CustomMenuStylesService,
    private buttonsService: ButtonsService,
    private portfolioService: PortfolioService,
    private permissionsService: PermissionsService,
    private removeHomepageErrorModalService: RemoveHomepageErrorModalService,
    private websiteTourService: WebsiteTourService,
    private educationStudentsService: EducationStudentsService,
    private educationImageManagerActionService: EducationImageManagerActionService,
    private eventsService: EventsService,
    private portfolioViewsService: PortfolioViewsService,
    private treeService: TreeService,
    private iFrameService: IFrameService,
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.initDroppable = this.utilsService.debounce(this.initDroppable.bind(this), 25);
  }

  public ngOnInit(): void {
    // this.options = {
    //   idField: 'nodeid',
    //   displayField: 'nodetitle',
    //   allowDrag: this.isDragAllowed.bind(this),
    //   allowDrop: this.isDropAllowed.bind(this),
    //   dropSlotHeight: 23,
    //   actionMapping: {
    //     mouse: {
    //       click: (tree, node) => {
    //         if (this.isImagesMoving || this.isImageManagerBlocked || this.isBlockedByAction) return;

    //         if (node.data.type === 'C' && !node.data.isPageExists) return; // if category pages are not showable

    //         this.handleActivate({node});
    //       },
    //       dragEnd: () => {
    //         this.nodesService.isNodeCanBeDroppedSubject.next(true);
    //       },
    //     },
    //   },
    // };

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.isOriginalHomePageExists = !!nodes && nodes.findIndex((n: NodeModel) => {
        return n.type === 'H' && !n.isTemporaryRemoved;
      }) !== -1;
    });

    this.portfolioViewsService.newReviewsDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((newReviewsData: INewReviewsData) => {
      this.newReviewsData = newReviewsData;
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      const page: SelectedPageModel = selectedPage ? SelectedPageModel.clone(selectedPage) : selectedPage;

      this.isSplashSelected = page && page.isSplash;

      if (page && page.id && page.type) {
        this.selectedNode = page;

        return this.initActiveNode();
      }

      this.selectedNode = null;

      if (!this.treeComponent || !this.treeComponent.treeModel) {
        return;
      }

      const focusedNode = this.treeComponent.treeModel.getFocusedNode();

      if (!focusedNode) {
        return;
      }

      focusedNode.setIsActive(false);
    });

    this.navigationService.isImageManagerSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isImageManager = value;
      this.isDraggable = !this.isImageManager;

      this.handleTour();

      this.initPageNodes();
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;

      if (!account) return;

      this.initPageNodes();
    });

    this.treeService.treeNodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: {
      nodes: TreeItemModel[],
      isImageManager: boolean,
    }) => {
      if (data.isImageManager !== this.navigationService.isImageManager) {
        return;
      }

      this.rawTreeNodes = data ? data.nodes : [];

      this.onNodesFetch(this.rawTreeNodes);

      this.initActiveNode();
    });

    // this.pagesService.pageNodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeTreeDataModel[]) => {
    //   // this.loadingPortfolioIconsCount = this.getPortfolioIconsCount(nodes);

    //   // this.handleNodes(nodes);

    //   // return this.refreshNodes(this.nodes);
    // });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isImageManagerBlocked = value;
    });

    this.deleteNodeModalService.onRemoveSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((node: any) => {
      if (!node) return;

      this.removePage(node);
    });

    this.privatePagePasswordModalService.onSaveSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: { isLocked: boolean, password: string }) => {
      if (value === null) return;

      this.handlePrivatePassModalSave(value);
    });

    this.privatePagePasswordModalService.onCancelSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.handlePrivatePassModalCancel();
    });

    this.directLinkInputModalService.onSaveSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { websiteId: number, nodeId: number, link: string, isInNewTab: boolean }) => {
      this.handleLinkInputModalSave(data);
    });

    this.imagesCounterService.countersSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((counters: ImagesCounterModel) => {
      this.counters = counters;
    });

    this.nodesService.isSplashEnabledSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isSplashEnabled: boolean) => {
      this.isSplashEnabled = isSplashEnabled;
    });

    this.educationImageManagerActionService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { [key: string]: ImageManagerActionDataModel }) => {
      this.reorderData = data;

      this.initBlockedState();
    });

    this.educationImageManagerActionService.onStopSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageManagerActionDataModel) => {
      this.onReorderStop(data);
    });

    this.imageManagerService.isImagesMovingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: { [key: number]: boolean }) => {
      this.isImagesMoving = !!value && Object.keys(value).some(key => value[key]);

      this.cdr.detectChanges();
    });

    this.initPermissions();
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    const sharePermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.SHARE_PAGE,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;
    });

    this.permissionsService.isUserHasPermissionsObservable([sharePermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isSharePermitted = isAllowed;
    });
  }

  decrementIconCounter() {
    if (--this.loadingPortfolioIconsCount > 0) return;
  }

  private handleTour(): void {
    this.websiteTourService.removeVisibleItem(KEYS.SIDEBAR_IMAGE_MANAGER);
    this.websiteTourService.removeVisibleItem(KEYS.SIDEBAR_PAGES_LIST);
    this.websiteTourService.removeVisibleItem(KEYS.ACTIVE_PAGE);
    this.websiteTourService.removeVisibleItem(KEYS.PORTFOLIO_PAGE);
    this.websiteTourService.removeVisibleItem(KEYS.PAGE_TO_REMOVE);

    if (!this.isImageManager) {
      this.websiteTourService.removeVisibleItem(KEYS.LIBRARY);
    }

    if (this.isImageManager) {
      this.websiteTourService.addVisibleItem(KEYS.SIDEBAR_IMAGE_MANAGER);
      this.websiteTourService.addVisibleItem(KEYS.LIBRARY);
    }

    if (this.navigationService.isPageEditor) {
      this.websiteTourService.addVisibleItem(KEYS.SIDEBAR_PAGES_LIST);
    }
  }

  private findNodeInTree(nodeData, nodes: TreeItemModel[]) {
    if (!nodeData || !nodes) {
      return null;
    }

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      if (!node) {
        return;
      }

      if (node.data.type === nodeData.type && (node.data.id == nodeData.id || !nodeData.id)) {
        return node;
      }

      if (node.children) {
        const result = this.findNodeInTree(nodeData, node.children);

        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  private initActiveNode(): void {
    if (!this.selectedNode || !this.preparedNodes || this.preparedNodes.length === 0) {
      return;
    }

    this.selectedTreeItem = this.getActiveNodeFromList(this.preparedNodes);

    this.initDroppable();
  }

  private getActiveNodeFromList(list: TreeItemModel[]): TreeItemModel {
    for (let i = 0; i < list.length; i++) {
      const node = list[i];

      if (node.data.id === this.selectedNode.id) {
        return node;
      }

      if (node.children && node.children.length > 0) {
        const activeChild: TreeItemModel = this.getActiveNodeFromList(node.children);

        if (activeChild) {
          return activeChild;
        }
      }
    }

    return null;
  }

  private isTheSamePage(page): boolean {
    return this.selectedNode && this.selectedNode.id == page.id && this.selectedNode.type == page.type;
  }

  public saveNode(node) {
    this.isLoading = true;

    this.editNodeId = 0;

    node.draggable = true;

    const newTitle = node.data.newtitle;

    if (node.data.title === newTitle) {
      this.isLoading = false;

      return;
    }

    this.pagesService.validatePageName(newTitle).pipe(
      catchError(e => {
        this.onNodeAddingError(node, e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    ).subscribe(() => {
      node.data.title = newTitle;
      node.data.websiteId = this.website.id;
  
      const onSuccess = () => {
        this.pagesService.updateNodesAndPages().subscribe(() => {
          this.nodesService.fetchNodes().add(() => {
            this.isLoading = false;
          });
        });
      };
  
      this.nodesService.updateNodeTitle(node.data.nodeId, newTitle).pipe(
        catchError(e => {
          this.onNodeAddingError(node, e.error.key);
  
          return throwError(() => e);
        }),
      ).subscribe(onSuccess);
    });
  }

  public toggleNodeShare(node: { data: NodeModel }): void {
    if (!this.isSharePermitted) {
      return;
    }

    const isSharedOld: boolean = node.data.isShared;
    const isDesktopPageVisibleOld: boolean = node.data.isDesktopPageVisible;
    const isMobilePageVisibleOld: boolean = node.data.isMobilePageVisible;

    const onSuccess = () => {
      return this.pagesService.updateNodesAndPages().subscribe(() => {});
    };

    this.portfolioService.updateShare({
      id: node.data.id,
      isShared: node.data.isShared,
    }).pipe(
      catchError(e => {
        node.data.isShared = isSharedOld;
        node.data.isDesktopPageVisible = isDesktopPageVisibleOld;
        node.data.isMobilePageVisible = isMobilePageVisibleOld;
        
        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  public onNodeAddingError(node: any, key: string): void {
    const header: ModalHeader = {
      text: this.pagesService.validationHeaders[key] || 'Something went wrong',
      className: 'error-header',
    };
    const message = this.pagesService.validationMessages[key] || key || 'Something went wrong';

    node.data.title = node.data.initialtitle;

    return this.messageModalService.addMessage(message, header, this.errorModalButtons);
  }

  public editNode(node: TreeItemModel): void {
    this.editNodeId = node.data.id;

    node.draggable = false;
  }

  public cancelEdit(node: TreeItemModel): void {
    this.editNodeId = 0;

    node.draggable = true;
  }

  private initTourData(nodes: NodeModel[]): void {
    if (!nodes) {
      return;
    }

    nodes.forEach((node: NodeModel) => {
      node.tourGroupKey = null;
      node.tourKey = null;
      node.tourLocation = null;
    });

    const libraryNode: NodeModel = this.getLibraryTreeNode(nodes);
    const activeNode: NodeModel = this.getActiveNode(nodes);
    const toDrag: NodeModel = this.getDragNode(nodes, activeNode);
    const toRemove: NodeModel = this.getNodeToRemove(nodes, activeNode, toDrag);

    if (libraryNode) {
      libraryNode.tourGroupKey = 'SIDEBAR_IMAGE_MANAGER_LIBRARY';
      libraryNode.tourKey = 'LIBRARY';
      libraryNode.tourLocation = 'SIDEBAR_IMAGE_MANAGER';
    }

    if (activeNode) {
      activeNode.tourGroupKey = 'SIDEBAR_PAGES_ACTIVE_PAGE';
      activeNode.tourKey = 'ACTIVE_PAGE';
      activeNode.tourLocation = 'SIDEBAR_PAGES';
    }

    if (toDrag) {
      toDrag.tourGroupKey = 'SIDEBAR_PAGES_PORTFOLIO_PAGE';
      toDrag.tourKey = 'PORTFOLIO_PAGE';
      toDrag.tourLocation = 'SIDEBAR_PAGES';
    }

    if (toRemove) {
      toRemove.tourGroupKey = 'SIDEBAR_PAGES_PAGE_TO_REMOVE';
      toRemove.tourKey = 'PAGE_TO_REMOVE';
      toRemove.tourLocation = 'SIDEBAR_PAGES';
    }
  }

  private getLibraryTreeNode(nodes: NodeModel[]): NodeModel {
    return nodes.find((node: NodeModel) => {
      return node.id === -1;
    });
  }

  private getActiveNode(nodes: NodeModel[]): NodeModel {
    return this.getNode(
      nodes,
      (node: NodeModel) => node && this.selectedNode && node.nodeId === this.selectedNode.nodeId,
      (nodes: NodeModel[]) => this.getActiveNode(nodes),
    );
  }

  private getDragNode(nodes: NodeModel[], activeNode: NodeModel): NodeModel {
    return this.getNode(
      nodes,
      (node: NodeModel) => node !== activeNode && node.isPageExists && node.type !== 'H',
      (nodes: NodeModel[]) => this.getDragNode(nodes, activeNode),
    );
  }

  private getNodeToRemove(nodes: NodeModel[], activeNode: NodeModel, toDrag: NodeModel): NodeModel {
    return this.getNode(
      nodes,
      (node: NodeModel) => node !== activeNode && node.isPageExists && node !== toDrag && node.type !== 'H',
      (nodes: NodeModel[]) => this.getNodeToRemove(nodes, activeNode, toDrag),
    );
  }

  private getNode(nodes: NodeModel[], checkCondition: Function, recursiveFunction: Function): NodeModel {
    for (let i = 0; i < nodes.length; i++) {
      if (checkCondition(nodes[i])) return nodes[i];

      if (!nodes[i].children || nodes[i].children.length === 0) continue;

      const child: NodeModel = recursiveFunction(nodes[i].children);

      if (child) return child;
    }

    return null;
  }

  private selectPage(page) {
    const selectedNode = this.findNodeInTree(page, this.preparedNodes);

    if (!selectedNode) {
      return;
    }

    const mimicEvent = {
      node: {
        data: selectedNode,
      },
    };

    setTimeout(() => {
      this.activateNode(mimicEvent);
    }, 0);
  }

  private initDroppable() {
    setTimeout(() => {
      const items = $('.droppable');

      if (items.sortable('instance')) {
        items.sortable('destroy');
      }

      items.sortable({
        cancel: '.node-title-wrapper',
        receive: (event, ui) => {
          const isCtrlKey = event.metaKey;

          const $target = $(event.target);
          const targetNodeId = Number.parseInt($target.attr('id'));
          const targetNodeKey = $target.data('nodekey');
          const targetNodeType = $target.data('nodetype');

          const targetNode: TreeItemModel = this.preparedNodes.find((node: TreeItemModel) => node.data.id === targetNodeKey);

          const sourcePortfolioId = this.selectedNode.id;

          const imagesData: ImageDataModel[] = ui.item.data('imagesData');

          if (this.isImageManagerBlocked || this.isImagesMoving || this.isBlockedByAction) {
            this.cancelMove({
              pageId: this.selectedNode.id,
              imagesData,
              ui,
            });

            return;
          }

          if (sourcePortfolioId == targetNodeKey && targetNodeType === 'P') {
            if (targetNode) {
              this.cancelMove({
                pageId: targetNode.data.id,
                imagesData,
                ui,
              });
            }

            ui.item.css('display', '');

            return ui.sender.sortable('cancel');
          }

          this.imageManagerService.imagesRemovingSubject.next(imagesData);

          if (targetNode) {
            for (let i = 0; i < imagesData.length; i++) {
              this.portfolioViewsService.addImageToPortfolio(targetNode.data.id, imagesData[i].id);
            }

            this.imagesCounterService.onImageAdd(targetNode.data.id, imagesData.length);
          }

          if (!event.metaKey) {
            $('.image-manager ul li.selected, #dropzone .dz-preview.selected').remove();
          }

          if (!$('#dropzone .dz-preview').length) {
            $('#dropzone .dz-message').removeClass('hidden-offset');
          }

          const imageIds: number[] = imagesData.map(image => image.id);

          this.imageManagerService.dispatchImagesMove(imageIds, true);

          const params = {
            nodeid: targetNodeId,
            images: imageIds,
            websiteId: this.website.id,
            portfolioId: targetNode.data.id,
            imageIds,
          };

          const observable: Observable<any> = isCtrlKey
            ? this.imageManagerService.copyImage(params)
            : this.imageManagerService.moveImage(params);

          try {
            if (this.isStudent) {
              this.educationStudentsService.sendAction(
                SOCKET_ACTIONS.IMAGE_REORDER_START,
                this.account.id,
                this.website.id,
                targetNode.data.id,
              );
            }

            observable.pipe(
              catchError(e => {
                console.error(e);

                this.cancelMove({
                  pageId: this.selectedNode.id,
                  imagesData,
                  ui,
                });

                this.imagesCounterService.onImageRemove(targetNode.data.id, imagesData);

                this.imageManagerService.onImagesForceReinit.next(true);

                return throwError(() => e);
              })
            ).subscribe(() => {
              if (this.isStudent) {
                this.educationStudentsService.sendAction(
                  SOCKET_ACTIONS.IMAGE_REORDER_STOP,
                  this.account.id,
                  this.website.id,
                  targetNode.data.id,
                );
              }

              this.imageManagerService.dispatchImagesMove(imageIds, false);
  
              this.imagesIconsService.initIcon(targetNodeKey);
              this.imagesIconsService.initIcon(this.selectedNode.id);
            });
          } catch (e) {
            console.error(e);
          }
        },
      });
    }, 0);
  }

  private cancelMove({ pageId, imagesData, ui }): void {
    for (let i = 0; i < imagesData.length; i++) {
      this.portfolioViewsService.addImageToPortfolio(pageId, imagesData[i].id);
    }

    this.imagesCounterService.onImageAdd(pageId, imagesData.length);
            
    ui.item.css('display', '');

    return ui.sender.sortable('cancel');
  }

  private activateNode(event) {
    const node = event.node.data;

    this.initDroppable();

    this.selectedNode.id = node.nodekey;
    this.selectedNode.nodeId = node.nodeid;
    this.selectedNode.type = node.nodetype;
  }

  handleActivate(event) {
    const node = event.node.data;

    this.navigationService.toEditor({
      id: node.nodekey,
      type: node.nodetype,
      nodeid: node.nodeid,
      isPageExists: node.nodetype === 'C' ? node.isPageExists : true,
    });
  }

  onModalClose() {
    this.messageModalService.close();
  }

  removeNode(event, node) {
    event.preventDefault();
    event.stopPropagation();

    const isHomePage: boolean = this.isHomePage(node);

    if (isHomePage) {
      this.removeHomepageErrorModalService.open();

      return;
    }

    this.cancelEdit(node);

    this.deleteNodeModalService.open(node);
  }

  // private isHomePage(node: TreeNode): boolean {
  private isHomePage(node: any): boolean {
    if (node.data.isHomePage) return true;

    if (!node.children) return false;

    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].data.isHomePage) return true;

      const isInnerHomePage: boolean = this.isHomePage(node.children[i]);

      if (isInnerHomePage) return true;
    }

    return false;
  }

  private removeNodeFromTree(node: TreeItemModel, nodes: TreeItemModel[]): TreeItemModel[] {
    nodes = nodes.filter(item => {
      if (item.children) {
        item.children = this.removeNodeFromTree(node, item.children);
      }

      return item.data.nodeId != node.data.nodeId;
    });

    return nodes;
  }

  redirectOnRemovingCurrentPage(node) {
    const currentPage = this.navigationService.getCurrentPage(this.route);

    if (currentPage && currentPage.id === node.data.id && currentPage.type === node.data.type) {
      const homePage: NodeModel = this.homepage;

      if (!homePage) {
        return;
      }

      this.navigationService.toEditor({
        id: homePage.id,
        type: homePage.type,
        nodeid: homePage.nodeId,
        isPageExists: true,
      });
    }
  }

  private removePage(node) {
    if (this.pagesType !== PAGE_GROUP_TYPES.ALL && node.data.groupType !== this.pagesType) return;

    this.buttonsService.disableSaveButton();
    this.buttonsService.disableCancelButton();

    this.closeModal('delete-node-modal');

    this.eventsService.dispatchPageRemove({
      pageId: node.data.id,
      pageType: node.data.type,
    }, this.iFrameService.sandboxWindow);

    node.data.websiteId = this.website.id;

    this.isLoading = true;

    const count = node.data.type === 'C' ? this.getCategoryPortfoliosCount(node) : this.counters.total[node.data.id];
    
    this.pagesService.removePage({ nodeKey: node.data.id, nodeType: node.data.type }).pipe(
      catchError(e => {
        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false;

        this.deleteNodeModalService.onRemoveSubject.next(null);
      })
    ).subscribe(() => {
      const nodes: TreeItemModel[] = this.removeNodeFromTree(node, this.preparedNodes);

      this.updatePreparedNodes(nodes);

      this.redirectOnRemovingCurrentPage(node);

      this.initPageNodes().add(() => {
        if (node.data.type === 'P' || node.data.type === 'C') {
          this.imagesCounterService.onImageAdd(LIBRARY_ID, count);
        }
      });
    });
  }

  private getCategoryPortfoliosCount(node): number {
    if (!node || !node.data || node.data.type !== 'C') return 0;

    const flat = this.flattenCategory(node.data);
    const portfolios = flat.filter(item => item.type === 'P');

    return portfolios.reduce((res, portfolio) => {
      return res + this.counters.total[portfolio.id];
    }, 0);
  }

  private flattenCategory(node: NodeModel): NodeModel[] {
    return node.children.reduce((res, node: NodeModel) => {
      return node.children && node.children.length ? res.concat(this.flattenCategory(node)) : res.concat(node);
    }, []);
  }

  private getPortfolioIconsCount(nodes: NodeModel[]): number {
    if (!nodes || nodes.length === 0) return 0;

    let count = 0;

    function handleNode(node) {
      if (node.nodetype === 'P' && node.image) {
        count++;
      }

      node.children.filter(n => !!n).forEach(n => handleNode(n));
    }

    handleNode({ children: nodes });

    return count;
  }

  refreshNodes(nodes) {
    if (!nodes) {
      return;
    }

    this.initDroppable();
  }

  private initPageNodes(): Subscription {
    if (!this.authService.account) {
      this.onNodesFetch([]);

      return;
    }

    this.isLoading = true;

    return this.pagesService.updateNodesAndPages().pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false
      }),
    ).subscribe(() => {});
  }

  onUpdateTreeData(event) {
    event.treeModel.expandAll();
  }

  public get draggableScope(): string[] {
    const scopes: string[] = [
      'self',
    ];

    if (this.pagesType !== 'ALL') {
      scopes.push(this.pagesType === 'HORIZONTAL' ? 'VERTICAL' : 'HORIZONTAL');
    }

    return [
      'HORIZONTAL',
      'VERTICAL',
    ];
    // return scopes;
  }

  public get droppableScope(): string[] {
    const scopes: string[] = [
      'self',
    ];

    if (this.pagesType !== 'ALL') {
      scopes.push(this.pagesType === 'HORIZONTAL' ? 'VERTICAL' : 'HORIZONTAL');
    }

    return scopes;
  }

  public onDrop(e: { dropNode: TreeItemModel, dragNode: TreeItemModel, index: number, accept: Function }) {
    if (!e || !e.dragNode) {
      return;
    }

    const movedNode: TreeItemModel = e.dragNode;

    if (e.dropNode.parent && e.dropNode.parent.data.type !== 'C') {
      return;
    }

    e.accept();

    this.isLoading = true;
    this.sidebarLoaderService.isLoadingSubject.next(true);

    if (e.dropNode.parent && e.dropNode.parent.data.isPrivate) {
      this.updateNodeTree([movedNode], (node: TreeItemModel) => {
        node.data.isPrivate = true;
      });
    }

    if (e.dropNode.parent && !e.dropNode.parent.data.isDesktopPageVisible) {
      this.updateNodeTree([movedNode], (node: TreeItemModel) => {
        node.data.isDesktopPageVisible = false;
      });
    }

    if (e.dropNode.parent && !e.dropNode.parent.data.isMobilePageVisible) {
      this.updateNodeTree([movedNode], (node: TreeItemModel) => {
        node.data.isMobilePageVisible = false;
      });
    }

    const droppedItem: FlatNodeModel = this.getDroppedTreeItem(e.dragNode);

    const dto: NodeMoveDto = new NodeMoveDto(
      droppedItem.node.data.id,
      droppedItem.node.data.nodeId,
      droppedItem.node.data.type,
      e.dragNode.data.nodeLevel,
      e.dragNode.data.nodeSequence,
      droppedItem.level,
      droppedItem.sequence,
      e.dragNode.data.title,
      [PAGE_GROUP_TYPES.VERTICAL, PAGE_GROUP_TYPES.HORIZONTAL].includes(this.pagesType) ? this.pagesType : PAGE_GROUP_TYPES.HORIZONTAL,
    );

    this.pagesService.movePage(dto).pipe(
      catchError(e => {
        this.messageModalService.addMessage(e, null, this.errorModalButtons);
        
        this.isLoading = false;

        this.onNodesFetch(this.rawTreeNodes);

        this.initPageNodes();

        return throwError(() => e);
      }),
      finalize(() => {
        this.sidebarLoaderService.isLoadingSubject.next(false);
      }),
    ).subscribe(() => {
      this.initPageNodes();
    });
  }

  private getFlatTree(nodes: TreeItemModel[], data: { level: number, sequence: number }, list: FlatNodeModel[] = []): FlatNodeModel[] {
    nodes.forEach((node: TreeItemModel) => {
      list.push({
        node,
        level: data.level,
        sequence: data.sequence,
      });

      data.sequence++;

      if (node.children && node.children.length) {
        if (!node.isEmpty) {
          data.level++;
        }

        this.getFlatTree(node.children, data, list);

        if (!node.isEmpty) {
          data.level--;
        }
      }
    });

    return list;
  }

  private getDroppedTreeItem(draggedItem: TreeItemModel): FlatNodeModel {
    const isSameGroup: boolean = this.pagesType === PAGE_GROUP_TYPES.ALL || draggedItem.data.groupType === this.pagesType;

    const flatTree: FlatNodeModel[] = this.getFlatTree(this.isEmpty ? this.preparedNodes[0].children : this.preparedNodes, {
      level: 0,
      sequence: this.getSequenceOffsetOnDrop(draggedItem, isSameGroup),
    });

    const itemIndex: number = flatTree.findIndex((item: FlatNodeModel) => {
      return item.node.data.id === draggedItem.data.id;
    });

    return flatTree[itemIndex];
  }

  private getSequenceOffsetOnDrop(draggedItem: TreeItemModel, isSameGroup: boolean): number {
    if (isSameGroup || this.pagesType === PAGE_GROUP_TYPES.HORIZONTAL) {
      return this.sequenceOffset;
    }

    const flatTree: FlatNodeModel[] = this.getFlatTree(draggedItem.children, {
      level: 0,
      sequence: 0,
    });

    return this.sequenceOffset - (flatTree.length + 1);
  }

  public isDragAllowed(node): boolean {
    return !this.isImageManager && !this.isDropdownVisible && node.data.type && node.data.type !== 'H';
  }

  public isDropAllowed(node, targetNode): boolean {
    if (targetNode.parent.data.nodetype !== 'C' && !targetNode.parent.data.virtual) return false;

    const siblingNodes = targetNode.parent.data.children;
    const libraryNodeIndex = siblingNodes.findIndex(n => n.nodekey === LIBRARY_ID);

    if (libraryNodeIndex !== -1) {
      this.nodesService.isNodeCanBeDroppedSubject.next(targetNode.index < libraryNodeIndex);
      return targetNode.index < libraryNodeIndex;
    }

    const isSameSiblingExists = siblingNodes.findIndex(n => this.isNodesEqual(n, node)) !== -1;

    this.nodesService.isNodeCanBeDroppedSubject.next(!isSameSiblingExists);

    return !isSameSiblingExists;
  }

  isNodesEqual(n, node) {
    if (!n || !node || !node.data) return false;
    const { mockup, nodeid, nodetitle }  = n;
    const { data } = node;
    return !mockup && nodetitle !== void 0 && nodetitle.toLowerCase() === data.nodetitle.toLowerCase() && nodeid !== data.nodeid
  }

  closeModal(id: string) {
    this.modalsService.close(id);
  }

  private filterNodeTree(nodes: TreeItemModel[], callback) {
    return nodes.filter((node: TreeItemModel) => {
      const filterResult = callback(node);

      if (filterResult && node.children && node.children.length) {
        node.children = this.filterNodeTree(node.children, callback);
      }

      return filterResult;
    });
  }

  private cloneNodes(nodes: TreeItemModel[]): TreeItemModel[] {
    return nodes.map((node: TreeItemModel) => {
      return TreeItemModel.clone(node);
    });
  }

  updateNodeTree(nodes: TreeItemModel[], callback) {
    nodes.forEach((node: TreeItemModel) => {
      if (node.children && node.children.length) this.updateNodeTree(node.children, callback);

      callback(node);
    });
  }

  private nodeTreeToArray(nodes) {
    let result = [];

    nodes.forEach(node => {
      if (!node.children) return;

      const children = this.nodeTreeToArray(node.children);

      result = result.concat(children);

      result.push(Object.assign({children: null}, node));
    });
    
    return result;
  }

  getCategoryChildrenNodeMockUp(category) {
    return {
      mockup: true,
      nodesequence: category.nodesequence + 1,
      nodelevel: category.nodelevel + 1,
    };
  }

  private onNodesFetch(nodes: TreeItemModel[]): void {
    this.sequenceOffset = this.getSequenceOffset(nodes);

    this.updatePreparedNodes(nodes);
  }

  private getSequenceOffset(nodes: TreeItemModel[]): number {
    if (!nodes || this.pagesType === PAGE_GROUP_TYPES.ALL) {
      return 0;
    }

    if (this.pagesType === PAGE_GROUP_TYPES.HOME) {
      return 0;
    }

    if (this.pagesType === PAGE_GROUP_TYPES.HORIZONTAL) {
      return 1;
    }

    const flatTree: FlatNodeModel[] = this.getFlatTree(nodes, {
      level: 0,
      sequence: 0,
    });

    const offset: number = flatTree.reduce((res: number, curr: FlatNodeModel) => {
      return res + (curr.node.data.groupType !== PAGE_GROUP_TYPES.VERTICAL ? 1 : 0);
    }, 0);

    return offset;
  }

  private updatePreparedNodes(nodes: TreeItemModel[]) {
    if (!nodes) {
      return
    }

    this.initTourData(nodes.map((node: TreeItemModel) => {
      return node.data;
    }));

    const data: TreeItemModel[] = this.cloneNodes(nodes);

    this.setPreparedNodes(data);
  }

  private setPreparedNodes(nodes: TreeItemModel[]): void {
    nodes = nodes || [];

    this.preparedNodes = nodes.filter((node: TreeItemModel) => {
      if (this.pagesType === PAGE_GROUP_TYPES.ALL) {
        return true;
      }

      if (node.data.type === 'H') {
        return this.pagesType === PAGE_GROUP_TYPES.HOME;
      }

      return node.data.groupType === this.pagesType;
    });

    this.isEmpty = !this.preparedNodes || this.preparedNodes.length === 0;

    if (this.isEmpty) {
      this.preparedNodes = [
        TreeItemModel.empty(),
      ];
    }

    this.nodes = this.preparedNodes ? this.preparedNodes.map((node: TreeItemModel) => node.data) : [];
  }

  private togglePageLock(node: TreeItemModel) {
    const newNode: TreeItemModel = TreeItemModel.clone(node);

    newNode.data.isPageExists = !node.data.isPrivate;

    let nodesToUpdate = [newNode];

    if (newNode.data.type === 'C') {
      const changedNodes: TreeItemModel[] = this.cloneNodes([newNode]);

      changedNodes.forEach((node: TreeItemModel) => {
        node.data.isPageExists = newNode.data.isPrivate;
      });

      nodesToUpdate = this.nodeTreeToArray(changedNodes);
    }

    this.isLoading = true;

    this.pagesService.updateTreeNodesLock(nodesToUpdate.map((item: TreeItemModel) => item.data)).pipe(
      catchError(e => {
        console.error(e);

        this.onNodesFetch(this.rawTreeNodes);

        this.initPageNodes();

        return throwError(() => e);
      }),
    ).subscribe(() => {
      this.initPageNodes();
    });
  }

  public togglePageVisibility(node: TreeItemModel) {
    this.updateVisibilityForNodes(TreeItemModel.clone(node));
  }

  private async updateVisibilityForNodes(newNode: TreeItemModel) {
    let nodesToUpdate: TreeItemModel[] = [newNode];

    if (newNode.data.type === 'C') {
      const changedNodes: TreeItemModel[] = this.cloneNodes([newNode]);

      changedNodes.forEach((node: TreeItemModel) => {
        node.data.isDesktopPageVisible = newNode.data.isDesktopPageVisible;
        node.data.isMobilePageVisible = newNode.data.isMobilePageVisible;
      });

      nodesToUpdate = this.nodeTreeToArray(changedNodes);
    }

    this.isLoading = true;

    this.pagesService.updateTreeNodesVisibility(nodesToUpdate.map((item: TreeItemModel) => item.data), false).pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    ).subscribe(() => {});
  }

  public onMouseOut() {
    this.pagesService.updateNodesAndPages().subscribe(() => {});
  }

  public togglePageExistence(node: TreeItemModel): void {
    const data: NodeModel = node.data;

    data.isPageExists = !data.isPageExists;

    this.pagesService.updatePageExistence(data).pipe(
      catchError(e => {
        console.error(e);

        this.isLoading = false;

        return throwError(() => e);
      }),
    ).subscribe(() => {
      this.nodesService.fetchNodes();

      this.isLoading = false;
    });
  }

  public tryToTogglePageLock(isPrivate: boolean, node: TreeItemModel) {
    this.cancelEdit(node);

    if (!this.website) {
      return;
    }

    if (!isPrivate || !!this.website.privatePagePassword) {
      node.data.isPrivate = isPrivate;

      return this.togglePageLock(node);
    }

    this.nodeToggledPrivatePage = node;
    
    this.privatePagePasswordModalService.open({ isPrivate });
  }

  private handlePrivatePassModalSave(value: { isLocked: boolean, password: string }) {
    this.isLoading = true;

    this.nodeToggledPrivatePage.data.isPrivate = value.isLocked;

    this.websiteDesignerService.updateWebsite({ PrivatePagePassword: value.password }).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false;
        this.nodeToggledPrivatePage = null;
      }),
    ).subscribe(() => {
      this.togglePageLock(this.nodeToggledPrivatePage);
    });
  }

  handlePrivatePassModalCancel() {
    this.nodeToggledPrivatePage = null;
  }

  public onSelect(event): void {
    const node: TreeItemModel = event.node;

    this.selectedNode.id = node.data.id;
    this.selectedNode.nodeId = node.data.nodeId;
    this.selectedNode.type = node.data.type;

    this.navigationService.toEditor({
      id: node.data.id,
      type: node.data.type,
      nodeid: node.data.nodeId,
      isPageExists: node.data.type === 'C' ? node.data.isPageExists : true,
    });
  }

  // needed to re-select node
  public onUnselect(event): void {
    const selection: TreeItemModel = this.selectedTreeItem;

    window.setTimeout(() => {
      this.selectedTreeItem = selection;
    });
  }

  // public addDirectLink(node: TreeNode): boolean {
  public addDirectLink(node: TreeItemModel): boolean {
    this.cancelEdit(node);

    if (node.data.type === 'H') return;

    this.openDirectLinkInputModal({
      websiteId: this.website.id,
      nodeId: node.data.nodeId,
      link: node.data.directLink,
      isInNewTab: node.data.directLinkInNewTab,
    });
  }

  public onDropdownVisibilityChange(value: boolean): void {
    this.isDropdownVisible = value;
  }

  private openDirectLinkInputModal(data: { nodeId: number, websiteId: number, link: string, isInNewTab: boolean }) {
    this.directLinkInputModalService.open({
      websiteId: this.website.id,
      nodeId: data.nodeId,
      link: data.link,
      isInNewTab: data.isInNewTab,
    });
  }

  private handleLinkInputModalSave(data: { websiteId: number, nodeId: number, link: string, isInNewTab: boolean }) {
    if (!data) {
      return;
    }

    this.isLoading = true;

    this.pagesService.updateTreeNode({
      NodeID: data.nodeId,
      WebsiteID: data.websiteId,
      DirectLink: data.link,
      DirectLinkInNewTab: data.isInNewTab,
    }).pipe(
      catchError(e => {
        console.error(e);

        this.onNodesFetch(this.rawTreeNodes);
  
        this.initPageNodes();

        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(() => {});
  }

  public toSplashPage(e) {
    e.stopPropagation();

    const homePage: NodeModel = this.homepage;

    const { id, type, isPageExists } = homePage;

    this.navigationService.toPageEditor({ id, type, isPageExists, isSplash: true });
  }

  public addNode(node: TreeItemModel): void {
    this.cancelEdit(node);

    const nodeId = node.data.type ? node.data.nodeId : node.parent.data.nodeId;
    const groupType = node.data.groupType ? node.data.groupType : node.parent.data.groupType;

    this.addNodeModalService.open();
    this.pagesService.parentNodeSubject.next({ id: nodeId, groupType });
  }

  public isFirstInGroup(node: NodeModel): boolean {
    if (this.pagesType === PAGE_GROUP_TYPES.ALL || this.pagesType === PAGE_GROUP_TYPES.HOME) return false;

    if (node.groupType !== this.pagesType) return false;

    const idx: number = this.nodes.findIndex((n: NodeModel) => n.nodeId === node.nodeId);

    if (idx <= 0) return false;

    const prevNode = this.nodes[idx - 1];

    return prevNode.isHomePage || prevNode.groupType !== node.groupType;
  }

  public cloneNode(node) {
    this.pagesService.cloneNode({
      id: node.data.nodeId,
      type: node.data.type,
      nodeKey: node.data.id,
      templateId: this.website.templateId,
    }).pipe(
      catchError(e => {
        console.error(e);

        this.onNodeAddingError(node, e.error.key);
        
        return throwError(() => e);
      })
    ).subscribe(() => {
      this.pagesService.updateNodesAndPages().subscribe(() => {
        this.initPageNodes().add(() => {
          this.nodesService.fetchNodes();
        });
      });
    });
  }

  private initBlockedState(): void {
    if (!this.reorderData || !this.website) {
      this.isBlockedByAction = false;

      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId]) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});
    
    this.isBlockedByAction = !!websiteIds[`${this.website.id}`];
  }

  private onReorderStop(data: ImageManagerActionDataModel): void {
    if (!data) {
      return;
    }
    
    if (!this.website || data.websiteId !== this.website.id) {
      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId] && portfolioId !== `${data.portfolioId}`) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});

    this.isBlockedByAction = !!websiteIds[this.website.id];
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleItem(this.isImageManager ? KEYS.SIDEBAR_IMAGE_MANAGER : KEYS.SIDEBAR_PAGES_LIST);

    if (!this.isImageManager) {
      this.websiteTourService.removeVisibleItem(KEYS.ACTIVE_PAGE);
      this.websiteTourService.removeVisibleItem(KEYS.PORTFOLIO_PAGE);
      this.websiteTourService.removeVisibleItem(KEYS.PAGE_TO_REMOVE);
      this.websiteTourService.removeVisibleItem(KEYS.LIBRARY);
    }

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
