export const KEYS = {
  DESKTOP_TITLE: 'DESKTOP_TITLE',
  DESKTOP_SUBTITLE: 'DESKTOP_SUBTITLE',
  DESKTOP_MENU_TEXT: 'DESKTOP_MENU_TEXT',
  DESKTOP_MENU_HOVER: 'DESKTOP_MENU_HOVER',
  DESKTOP_MENU_ACTIVE: 'DESKTOP_MENU_ACTIVE',
  DESKTOP_MENU_HAMBURGER: 'DESKTOP_MENU_HAMBURGER',
  DESKTOP_MENU_BACKGROUND: 'DESKTOP_MENU_BACKGROUND',

  MOBILE_TITLE: 'MOBILE_TITLE',
  MOBILE_SUBTITLE: 'MOBILE_SUBTITLE',
  MOBILE_MENU_TEXT: 'MOBILE_MENU_TEXT',
  MOBILE_MENU_HOVER_BACKGROUND: 'MOBILE_MENU_HOVER_BACKGROUND',
  MOBILE_MENU_HOVER_TEXT_COLOR: 'MOBILE_MENU_HOVER_TEXT_COLOR',
  MOBILE_MENU_ACTIVE: 'MOBILE_MENU_ACTIVE',
  MOBILE_MENU_BACKGROUND: 'MOBILE_MENU_BACKGROUND',

  COMMON_BACKGROUND: 'COMMON_BACKGROUND',
  COMMON_TEXT: 'COMMON_TEXT',
  COMMON_LINK: 'COMMON_LINK',
  COMMON_LINK_HOVER: 'COMMON_LINK_HOVER',
};

export const RELATIONS = {
  [KEYS.DESKTOP_TITLE]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.DESKTOP_SUBTITLE]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.DESKTOP_MENU_TEXT]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.DESKTOP_MENU_HOVER]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.DESKTOP_MENU_ACTIVE]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.DESKTOP_MENU_HAMBURGER]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.DESKTOP_MENU_BACKGROUND]: [
    KEYS.DESKTOP_TITLE,
    KEYS.DESKTOP_SUBTITLE,
    KEYS.DESKTOP_MENU_TEXT,
    KEYS.DESKTOP_MENU_HOVER,
    KEYS.DESKTOP_MENU_ACTIVE,
    KEYS.DESKTOP_MENU_HAMBURGER,
    KEYS.MOBILE_TITLE,
    KEYS.MOBILE_SUBTITLE,
  ],
  [KEYS.MOBILE_TITLE]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.MOBILE_SUBTITLE]: [
    KEYS.DESKTOP_MENU_BACKGROUND,
  ],
  [KEYS.MOBILE_MENU_TEXT]: [
    KEYS.MOBILE_MENU_BACKGROUND,
  ],
  [KEYS.MOBILE_MENU_HOVER_BACKGROUND]: [
    KEYS.MOBILE_MENU_HOVER_TEXT_COLOR,
  ],
  [KEYS.MOBILE_MENU_HOVER_TEXT_COLOR]: [
    KEYS.MOBILE_MENU_HOVER_BACKGROUND,
  ],
  [KEYS.MOBILE_MENU_ACTIVE]: [
    KEYS.MOBILE_MENU_BACKGROUND,
  ],
  [KEYS.MOBILE_MENU_BACKGROUND]: [
    KEYS.MOBILE_MENU_TEXT,
    KEYS.MOBILE_MENU_ACTIVE,
  ],
  [KEYS.COMMON_TEXT]: [
    KEYS.COMMON_BACKGROUND,
  ],
  [KEYS.COMMON_LINK]: [
    KEYS.COMMON_BACKGROUND,
  ],
  [KEYS.COMMON_LINK_HOVER]: [
    KEYS.COMMON_BACKGROUND,
  ],
  [KEYS.COMMON_BACKGROUND]: [
    KEYS.COMMON_TEXT,
    KEYS.COMMON_LINK,
    KEYS.COMMON_LINK_HOVER,
  ],
};
