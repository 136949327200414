import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {AuthService} from '../../../../auth/auth.service';
import {ReceiptService} from '../../../../core/services/payment/receipt/receipt.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';
import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {ReceiptModalModel} from '../../../models/modals/receipt-modal/receipt-modal.model';

@Component({
  selector: 'app-successfully-subscribed-modal',
  templateUrl: './successfully-subscribed-modal.component.html',
  styleUrls: ['./successfully-subscribed-modal.component.scss'],
})
export class SuccessfullySubscribedModalComponent implements OnInit, OnDestroy {
  @Input() header: string;

  public get modalHeader(): ModalHeader {
    return {
      text: 'Thank you!',
      className: 'success-header',
    };
  }

  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.submitHandler.bind(this),
    },
  ];

  public account: AccountModel;
  public currentSubscription: SubscriptionModel;

  public receipt: ReceiptModalModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.paymentSubscriptionsService.successfullySubscribedModalId;
  }

  public get subscriptionPeriod(): string {
    if (!this.currentSubscription) return '';

    return this.currentSubscription.isAnnual ? 'Annual' : 'Monthly';
  }

  constructor(private modalsService: ModalsService,
              private authService: AuthService,
              private receiptService: ReceiptService,
              private paymentSubscriptionsService: PaymentSubscriptionsService) {
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.currentSubscription = subscription;
    });

    this.receiptService.receiptSubject.subscribe((receipt: ReceiptModalModel) => {
      this.receipt = receipt;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public submitHandler(): void {
    this.modalsService.close(this.id);
  }

  public viewPaymentHistory(): void {
    this.paymentSubscriptionsService.toHistory();

    this.modalsService.close(this.id);
  }

  public viewSubscriptions(): void {
    this.paymentSubscriptionsService.toSubscriptions();

    this.modalsService.close(this.id);
  }
}
