import {IStyleModelOptions} from '../option/i-style-model-options';

import {SetupOptionModel} from '../option/setup-option.model';

import {BLOCKS_TYPES_MAPPING, TYPES, ATTRIBUTES, BLOCKS_TEMPLATE_ID} from './constants';

export class IsPortfolioTitleAddedModel extends SetupOptionModel {
  public element: HTMLElement;

  private attribute: string = '';

  constructor(
    public key: string,
    public type: string,
    public value: boolean = false,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !options.block) return;

    this.element = this.getPortfolioBlock(options);

    const blockTemplateId: number = Number.parseInt(this.element.getAttribute('data-block-template-id'));

    const type: string = BLOCKS_TYPES_MAPPING[blockTemplateId];

    this.attribute = type === TYPES.BLOCK ? ATTRIBUTES.IS_TITLE_BLOCK_ADDED : ATTRIBUTES.IS_PORTFOLIO_TITLE_ADDED;

    this.value = this.element.matches(`[${this.attribute}="true"]`);
  }

  private getPortfolioBlock(options: IStyleModelOptions): HTMLElement {
    const isTitleBlock: boolean = options.block.matches(`[data-block-template-id="${BLOCKS_TEMPLATE_ID.TITLE_1}"]`);

    if (!isTitleBlock) return options.block;

    let nextSibling: HTMLElement = <HTMLElement>options.block.nextElementSibling;

    while (nextSibling) {
      if (nextSibling.matches('.portfolio')) return nextSibling;

      nextSibling = <HTMLElement>nextSibling.nextElementSibling;
    }

    return null;
  }

  public onChange(value: boolean): void {
    this.value = value;

    this.element.setAttribute(this.attribute, `${value}`);
  }
}
