import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Subscription} from 'rxjs';

import {AppSettingsModel} from '../../../../../models/app-settings/app-settings.model';
import {FreeTrialModel} from '../../../../../models/free-trial/free-trial.model';

@Injectable()
export class FreeTrialHttpService {
  private prefix: string = 'api/admin';
  private model: string = 'free-trial';

  constructor(private http: HttpClient) {
  }

  public update(settings: AppSettingsModel): Subscription {
    const trialInfo = new FreeTrialModel(Number.parseInt(settings.trialPeriodDays), Number.parseInt(settings.trialPeriodEducationalDays));

    return this.http.put(`${this.prefix}/${this.model}`, trialInfo).subscribe(() => {});
  }
}
