import {Component, Output, EventEmitter, OnDestroy, ChangeDetectorRef, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FullscreenManager} from "../../../../../services/fullscreen-manager.service";
import {EditorDevicesService} from '../../../../../core/services/editor-devices/editor-devices.service';
import {EventsService} from '../../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../../core/services/iframe/iframe.service';

import {DEVICES} from '../../../../../core/services/editor-devices/constants';

@Component({
  selector: 'app-fullscreen-icon',
  templateUrl: './fullscreen-icon.component.html',
  styleUrls: ['./fullscreen-icon.component.scss'],
})
export class FullscreenIconComponent implements OnInit, OnDestroy {
  @Output() clickHandler = new EventEmitter();

  public isDesktop: boolean;
  public isOpen: boolean;
  public isSomeBlockEnabled: boolean = false;

  private blocksData: { element: HTMLElement, isEnabled: boolean }[] = [];

  private isDestroyed: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fullscreenManager: FullscreenManager,
    private editorDevicesService: EditorDevicesService,
    private eventsService: EventsService,
    private iFrameService: IFrameService,
    private cdr: ChangeDetectorRef,
  ) {
    this.eventsService.addFrameListener('fullscreenIconState', (e: CustomEvent) => {
      if (this.isDestroyed) {
        return;
      }

      const isEnabled: boolean = e.detail.state === 'VISIBLE';
      const isDestroyed: boolean = e.detail.state === 'DESTROYED';

      if (isDestroyed) {
        this.blocksData = this.blocksData.filter(({ element }: { element: HTMLElement }) => {
          return element !== e.detail.element;
        });
        
        return;
      }

      const addedBlock: { element: HTMLElement, isEnabled: boolean } = this.blocksData.find(({ element }: { element: HTMLElement }) => {
        return element === e.detail.element;
      });

      if (!addedBlock) {
        this.blocksData.push({
          element: e.detail.element,
          isEnabled,
        });

        this.initIsSomeBlockEnabled();
        
        return;
      }

      addedBlock.isEnabled = isEnabled;

      this.initIsSomeBlockEnabled();
    });
  }

  public ngOnInit(): void {
    this.iFrameService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoaded: boolean) => {
      if (!isLoaded) {
        return;
      }
      
      this.blocksData = [];
      
      this.initIsSomeBlockEnabled();
    });

    this.editorDevicesService.onDeviceChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((deviceKey: string) => {
      this.isDesktop = deviceKey === DEVICES.DESKTOP;
    });

    this.fullscreenManager.isOpenSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpen: boolean) => {
      this.isOpen = isOpen;

      this.cdr.detectChanges();
    });
  }

  private initIsSomeBlockEnabled(): void {
    this.isSomeBlockEnabled = this.blocksData.some(({ isEnabled }: { isEnabled: boolean }) => {
      return isEnabled;
    });

    this.cdr.detectChanges();
  }

  public handleClick(): void {
    this.clickHandler.emit();
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;
    
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
