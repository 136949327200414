import {Component} from '@angular/core';

import {CustomSubscriptionRemoveErrorModalService} from '../../../services/modals/custom-subscription-remove-error/custom-subscription-remove-error-modal.service';

import {Button} from '../../../../common/models/button/button.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-custom-subscription-remove-error-modal',
  templateUrl: './custom-subscription-remove-error-modal.component.html',
  styleUrls: ['./custom-subscription-remove-error-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class CustomSubscriptionRemoveErrorModalComponent {
  public usersData: { Id: number, FirstName: string, LastName: string, Email: string }[] = [];

  public buttons: Button[] = [
    new Button('OK', null, 'neutral', this.onModalClose.bind(this)),
  ];

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: CustomSubscriptionRemoveErrorModalService,
  ) {
  }

  public onOpen(err: { key: string, message: string, data: { Id: number, FirstName: string, LastName: string, Email: string }[] }): void {
    if (!err || !err.data) {
      return;
    }

    this.usersData = err.data;
  }

  private onModalClose(): void {
    this.service.close();
  }
}
