import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {LoginContactData} from '../../../../../application/main/settings/account/login-contact/login-contact-data.model';

import {AccountDto} from '../../../../models/accounts/account.dto';
import {AccountSummaryModel} from '../../../../models/accounts/summary/account-summary.model';
import {ISocketInitialDataMessageDataModel} from '../../../../models/sockets/message/initial-data/i-initial-data-message-data.model';
import {PermissionDto} from '../../../../models/permission/permission.dto';
import {PermissionModel} from '../../../../models/permission/permission.model';

@Injectable()
export class AccountsHttpService {
  private prefix: string = 'api';
  private model: string = 'accounts';

  constructor(private http: HttpClient) {}

  public fetchAccount(): Observable<AccountDto> {
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    return this.http.get(`/api/${this.model}/current`, { headers });
  }

  public fetchPermissions(): Observable<PermissionModel[]> {
    return this.http.get(`/api/${this.model}/current/permissions`).pipe(
      map((permissions: PermissionDto[]) => permissions.map(permission => {
        return PermissionDto.normalize(permission);
      }))
    );
  }

  public setActiveWebsite(websiteId: number): Observable<AccountDto> {
    return this.http.put(`/api/${this.model}/active-website`, { websiteId });
  }

  public requestInitialData(): Observable<ISocketInitialDataMessageDataModel> {
    return this.http.get<ISocketInitialDataMessageDataModel>(`/api/${this.model}/current/initial-data`);
  }

  public updateAccount(account: LoginContactData): Observable<any> {
    return this.http.put(`/api/${this.model}/current`, account);
  }

  public updateAccountData({ isPublishInfoModalVisible }: { isPublishInfoModalVisible: boolean }): Observable<AccountDto> {
    return this.http.put(`/api/${this.model}/current`, { isPublishInfoModalVisible });
  }

  public fetchAccountSummary(): Observable<AccountSummaryModel> {
    return this.http.get(`/api/${this.model}/current/summary`);
  }

  public setPrimaryMedium(primaryMediumId: number): Observable<any> {
    return this.http.put(`${this.prefix}/${this.model}/current/primary-medium`, { primaryMediumId });
  }
}
