import {BlocksTemplatesModel} from '../blocks-templates/blocks-templates.model';
import {DeployModel} from '../deploy/deploy.model';
import {SeverityModel} from '../severity/severity.model';

export class BlocksChangesModel {
  constructor(public id?: number,
              public blockId?: number,
              public deployId?: number,
              public severityId?: number,
              public createdAt?: string,
              public isHandled?: boolean,
              public isExpanded: boolean = false,

              public blockTemplate?: BlocksTemplatesModel,
              public deploy?: DeployModel,
              public severity?: SeverityModel) {}
}
