// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-section-wrapper:not(.collapsed) .title .toggle-arrow {
  top: 1px;
  transform: rotate(45deg);
}
.dashboard-section-wrapper:not(.collapsed-by-default) .content-wrapper {
  transition: max-height 0.25s ease-in-out;
}
.dashboard-section-wrapper.collapsed .title .toggle-arrow {
  top: 4px;
  transform: rotate(-45deg);
}
.dashboard-section-wrapper.collapsed .content-wrapper {
  max-height: 0;
}
.dashboard-section-wrapper.collapsible:hover {
  cursor: pointer;
}
.dashboard-section-wrapper.collapsible.collapsed .content-wrapper {
  max-height: 0;
}
.dashboard-section-wrapper.collapsible .title .toggle-arrow {
  pointer-events: all;
  opacity: 1;
}
.dashboard-section-wrapper .title {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  margin-left: 4px;
  padding-left: 30px;
}
.dashboard-section-wrapper .title .toggle-arrow {
  display: inline-block;
  position: absolute;
  left: 0;
  border: solid #333333;
  border-width: 0 2px 2px 0;
  padding: 5px;
  transition: transform 0.25s ease-in-out, top 0.25s ease-in-out, opacity 0.25s ease-in-out;
  pointer-events: none;
  opacity: 0;
}
.dashboard-section-wrapper .content-wrapper {
  max-height: 200vh;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/dashboard/section-wrapper/dashboard-section-wrapper.component.scss"],"names":[],"mappings":"AAKM;EACE,QAAA;EACA,wBAAA;AAJR;AAUI;EACE,wCAAA;AARN;AAcM;EACE,QAAA;EACA,yBAAA;AAZR;AAgBI;EACE,aAAA;AAdN;AAmBI;EACE,eAAA;AAjBN;AAqBM;EACE,aAAA;AAnBR;AAwBM;EACE,mBAAA;EACA,UAAA;AAtBR;AA2BE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAzBJ;AA2BI;EACE,qBAAA;EACA,kBAAA;EACA,OAAA;EACA,qBAAA;EACA,yBAAA;EACA,YAAA;EACA,yFAAA;EACA,oBAAA;EACA,UAAA;AAzBN;AA6BE;EACE,iBAAA;EACA,gBAAA;AA3BJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.dashboard-section-wrapper {\n  &:not(.collapsed) {\n    .title {\n      .toggle-arrow {\n        top: 1px;\n        transform: rotate(45deg);\n      }\n    }\n  }\n\n  &:not(.collapsed-by-default) {\n    .content-wrapper {\n      transition: max-height 0.25s ease-in-out;\n    }\n  }\n\n  &.collapsed {\n    .title {\n      .toggle-arrow {\n        top: 4px;\n        transform: rotate(-45deg);\n      }\n    }\n\n    .content-wrapper {\n      max-height: 0;\n    }\n  }\n\n  &.collapsible {\n    &:hover {\n      cursor: pointer;\n    }\n\n    &.collapsed {\n      .content-wrapper {\n        max-height: 0;\n      }\n    }\n\n    .title {\n      .toggle-arrow {\n        pointer-events: all;\n        opacity: 1;\n      }\n    }\n  }\n\n  .title {\n    position: relative;\n    font-size: 24px;\n    font-weight: 600;\n    margin-left: 4px;\n    padding-left: 30px;\n\n    .toggle-arrow {\n      display: inline-block;\n      position: absolute;\n      left: 0;\n      border: solid $primary-40;\n      border-width: 0 2px 2px 0;\n      padding: 5px;\n      transition: transform 0.25s ease-in-out, top 0.25s ease-in-out, opacity 0.25s ease-in-out;\n      pointer-events: none;\n      opacity: 0;\n    }\n  }\n\n  .content-wrapper {\n    max-height: 200vh;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
