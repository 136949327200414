import {IStyleModelOptions} from '../option/i-style-model-options';

import {BaseColorRgbModel} from '../base/color-rgb/base-color-rgb.model';

import {CUSTOM_COLOR, CUSTOM_COLOR_RGB, STYLE_PROPERTY} from './constants';

export class PortfolioInfoIconBorderColorModel extends BaseColorRgbModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, STYLE_PROPERTY, CUSTOM_COLOR, CUSTOM_COLOR_RGB, value, isEnabled, false);
  }

  public init(selectedElement: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !selectedElement) return;

    super.init(options.block, {
      selectedElement,
      defaultValueHolderElement: selectedElement,
    });
  }
}
