import * as moment from 'moment';

import {AccountDto} from "../../accounts/account.dto";
import {ImageUpdateSuggestionModel} from "./image-update-suggestion.model";

import {TIME_FORMATTER} from './constants';

export class ImageUpdateSuggestionDto {
  constructor(
    public Id?: number,
    public ImageId?: number,
    public PortfolioId?: number,
    public SuggestedByUserId?: number,
    public ReviewId?: number,
    public SuggestedBy?: AccountDto,
    public Rotate?: number,
    public CropStartX?: number,
    public CropStartY?: number,
    public CropEndX?: number,
    public CropEndY?: number,
    public CreatedAt?: string,
    public LastViewedByStudentAt?: string
  ) {
  }

  public static normalize(res: ImageUpdateSuggestionDto): ImageUpdateSuggestionModel {
    if (!res) {
      return null;
    }

    return new ImageUpdateSuggestionModel(
      res.Id,
      res.ImageId,
      res.PortfolioId,
      res.SuggestedByUserId,
      res.ReviewId,
      AccountDto.normalize(res.SuggestedBy),
      res.Rotate,
      res.CropStartX,
      res.CropStartY,
      res.CropEndX,
      res.CropEndY,
      res.CreatedAt,
      moment.utc(res.CreatedAt).format(TIME_FORMATTER),
      res.LastViewedByStudentAt,
    );
  }

  public static toRequest(res: ImageUpdateSuggestionModel): ImageUpdateSuggestionDto {
    if (!res) {
      return null;
    }

    const cropStartX: number = res.cropStartX < res.cropEndX ? res.cropStartX : res.cropEndX;
    const cropStartY: number = res.cropStartY < res.cropEndY ? res.cropStartY : res.cropEndY;
    const cropEndX: number = res.cropStartX > res.cropEndX ? res.cropStartX : res.cropEndX;
    const cropEndY: number = res.cropStartY > res.cropEndY ? res.cropStartY : res.cropEndY;

    return new ImageUpdateSuggestionDto(
      res.id,
      res.imageId,
      res.portfolioId,
      res.suggestedByUserId,
      res.reviewId,
      null,
      res.rotate,
      cropStartX,
      cropStartY,
      cropEndX,
      cropEndY,
    );
  }
}
