import {IButtonModel} from './i-button.model';

import {GROUPS_KEYS} from '../../../../core/services/website-tour/constants';

export const KEYS_ORDER: string[] = [
  GROUPS_KEYS.TOOLBARS,
  GROUPS_KEYS.PAGES,
  GROUPS_KEYS.IMAGES,
  GROUPS_KEYS.BLOCKS,
  GROUPS_KEYS.CUSTOMIZER,
];

export const ITEMS: () => { [key: string]: IButtonModel } = () => {
  return {
    [GROUPS_KEYS.TOOLBARS]: {
      title: 'TOOLBARS',
      subtitle: 'Save, Preview, Publish & More',
      isLoading: false,
      isEnabled: true,
    },
    [GROUPS_KEYS.PAGES]: {
      title: 'PAGES',
      subtitle: 'Add & Organize Pages',
      isLoading: false,
      isEnabled: true,
    },
    [GROUPS_KEYS.IMAGES]: {
      title: 'IMAGES',
      subtitle: 'Add Portfolios, Upload Images',
      isLoading: false,
      isEnabled: true,
    },
    [GROUPS_KEYS.BLOCKS]: {
      title: 'BLOCKS',
      subtitle: 'Add Blocks to Pages',
      isLoading: false,
      isEnabled: true,
    },
    [GROUPS_KEYS.CUSTOMIZER]: {
      title: 'CUSTOMIZER',
      subtitle: 'Change Settings & Styles',
      isLoading: false,
      isEnabled: true,
    },
  };
};
