import {Component, OnInit, OnDestroy, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

import {BlocksService} from './blocks.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {BlocksDragService} from '../../../../core/services/blocks/drag/blocks-drag.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {DefaultPortfolioService} from '../../../../core/services/default-portfolio/default-portfolio.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {WebsiteTourService} from '../../../../core/services/website-tour/website-tour.service';
import {UtilsService} from '../../../../core/services/utils/utils.service';

import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';
import {DefaultPortfolioBlockDto} from '../../../../core/models/blocks/default-portfolio/default-portfolio-block.dto';
import {BlockModel} from '../../../../core/models/blocks/block.model';

import {CategoryDescription} from './categories-description';

import {AppAnimations} from '../../../../app-animations';

import {TOUR_KEY} from './constants';
import {KEYS} from '../../../../core/services/website-tour/constants';

@Component({
  selector: 'app-designer-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class BlocksSectionComponent implements OnInit, OnDestroy {
  public dragTemplate: HTMLElement = null;

  public defaultPortfolio: DefaultPortfolioBlockDto;
  public defaultPortfolioAsBlock: BlockModel;

  public isAllImagesLoaded: boolean = false;

  private isMenuVertical: boolean = false;
  private isMenuHorizontal: boolean = false;
  private isBothMenus: boolean = false;

  dragTransition = false;

  blocks = [];

  // sections
  sections: any = [];
  _selectedCategory: any = null;
  selectedFile: any;

  // pages
  pages: Array<string> = [];
  selectedPage: string;

  availableCategories = [];
  nonArtistCategories = [];
  artistCategories = [];

  public filteredBlocks: BlockModel[] = [];

  loadingObjects = {};
  _isLoading = true;
  get isLoading() { return this._isLoading; }
  set isLoading(value) {
    this._isLoading = value;
  }

  pageId: number;
  pageType: string;
  isSplash: boolean;

  private website: WebsiteModel;

  categoryDescription = CategoryDescription;

  // TODO: refactor
  majorCategories = [{
    name: 'artist'
  }];
  selectedMajorCategory = null;

  private isLoaded: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get selectedCategory() {
    return this._selectedCategory;
  }

  public set selectedCategory(category) {
    this._selectedCategory = category;

    this.filteredBlocks = category ? this.filterBlocksInCategory(category) : [];
  }

  constructor(private activatedRoute: ActivatedRoute,
              private blocksDragService: BlocksDragService,
              private websitesService: WebsitesService,
              private templatesService: TemplatesService,
              private defaultPortfolioService: DefaultPortfolioService,
              private eventsService: EventsService,
              private websiteTourService: WebsiteTourService,
              public iFrameService: IFrameService,
              public utilsService: UtilsService,
              public blocksService: BlocksService) {
    this.showTour = this.utilsService.debounce(this.showTour.bind(this), 250);
  }

  public ngOnInit(): void {
    this.prepareBlocks();

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.isMenuVertical = template && template.menuType === 'VERTICAL';
      this.isMenuHorizontal = template && template.menuType === 'HORIZONTAL';
      this.isBothMenus = template && template.menuType === 'BOTH';
    });

    this.iFrameService.onContentLoad.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoaded: boolean) => {
      this.isLoaded = isLoaded;
    });

    this.blocksService
      .availableBlocksSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onAvailableBlocksCategoriesUpdate.bind(this));

    this.blocksService
      .toggleLoadingSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({key, value}) => this.toggleLoading(key, value));

    this.websitesService
      .activeWebsiteSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.handleWebsite.bind(this));

    this.defaultPortfolioService.defaultPortfolio.pipe(takeUntil(this.ngUnsubscribe)).subscribe((defaultPortfolio: DefaultPortfolioBlockDto) => {
      this.defaultPortfolio = defaultPortfolio;
      this.defaultPortfolioAsBlock = DefaultPortfolioBlockDto.normalizeToBlock(defaultPortfolio);
    });

    this.blocksDragService.dragTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((elem: ElementRef) => {
      this.dragTemplate = elem && elem.nativeElement;
    });
  }

  private prepareBlocks() {
    this.loadBlocks();

    try {
      this.activatedRoute.queryParams.subscribe(params => {
        const pageId = Number.parseInt(params.id, 10);
        const pageType = params.type;
        const isSplash = params.isSplash === 'true';
  
        if (pageId !== this.pageId || pageType !== this.pageType || isSplash !== this.isSplash) {
          if (this.website) {
            this.blocksService.updatePageData(this.website.templateId, pageId, pageType, isSplash);
          }
        }
  
        this.pageId = pageId;
        this.pageType = pageType;
        this.isSplash = isSplash;
      });

      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  }

  handleWebsite(website: WebsiteModel) {
    if (website && this.pageId && this.pageType) {
      if (!this.website || website.templateId !== this.website.templateId) {
        this.blocksService.updatePageData(website.templateId, this.pageId, this.pageType, this.isSplash);
      }
    }

    this.website = website;

    this.fetchDefaultPortfolio();
  }

  private fetchDefaultPortfolio() {
    if (!this.website) return;

    this.defaultPortfolioService.fetch({
      websiteId: this.website.id,
      templateId: this.website.templateId,
    });
  }

  setBlocks(categories) {
    if (categories) {
      this.blocks = categories.map((categoryData) => {
        categoryData.files.forEach((file) => file.Block_Category = categoryData.category);

        return categoryData;
      });
    }
  }

  loadBlocks(): Subscription {
    return this.blocksService.getCategories().subscribe(categories => {
      return this.setBlocks(categories);
    });
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(TOUR_KEY);
    this.websiteTourService.removeVisibleItem(KEYS.SIDEBAR_BLOCKS);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  toggleElement({ event, element }) {
    event.stopPropagation();

    if (!this.isLoaded) return;

    const isSameCategory = this.selectedCategory && this.selectedCategory.category === element.category;

    this.isAllImagesLoaded = false;
    this.selectedCategory = isSameCategory ? null : element;
  }

  dragStart({ data, block }) {
    this.blocksDragService.onSelectSubject.next(block);

    this.dragTransition = false;
    this.dragTemplate.style.left = data.clientX - this.dragTemplate.offsetWidth / 2 + 'px';
    this.dragTemplate.style.top = data.clientY - this.dragTemplate.offsetHeight / 2 + 'px';

    this.blocksDragService.isDraggingSubject.next(true);

    this.eventsService.dispatchNewBlockDragStart(this.iFrameService.sandboxWindow);
  }

  drop() {
    this.blocksDragService.onSelectSubject.next(null);

    this.dragTransition = false;
    this.blocksDragService.isDraggingSubject.next(false);
  }

  setAvailableCategories(blocks, data) {
    this.availableCategories = blocks.map(block => {
      if (!data.available || !data.available[block.category]) return;

      block.available = data.available[block.category].available;
      block.isArtistBlockCategory = data.available[block.category].isArtistBlockCategory;
      block.disabled = data.available[block.category].disabled;

      if (this.selectedCategory && (!block.available || block.disabled) && this.selectedCategory.category === block.category) {
        this.isAllImagesLoaded = false;
        this.selectedCategory = null;
      }

      if (!block.files) return block;

      block.files.forEach(block => {
        block.BlockTemplateId = block.BlockID;
        block.UpdatedAt = new Date(block.UpdatedAt);
      });

      return block;
    });

    this.filterArtistCategories();
  }

  filterArtistCategories() {
    this.nonArtistCategories = [];
    this.artistCategories = [];

    this.availableCategories.forEach(category => {
      if (!category) return;

      if (category.isArtistBlockCategory) this.artistCategories.push(category);
      else this.nonArtistCategories.push(category);
    });
  }

  onAvailableBlocksCategoriesUpdate(data: any) {
    if (!this.blocks || !this.blocks.length) return;

    this.setAvailableCategories(this.blocks, data);

    this.showTour();
  }

  private showTour(): void {
    this.websiteTourService.addVisibleLocation(TOUR_KEY);
    this.websiteTourService.addVisibleItem(KEYS.SIDEBAR_BLOCKS);
  }

  toggleLoading(key, value) {
    this.loadingObjects[key] = value;
    if (!value) {
      this.checkLoadingEnd();
    }
  }

  checkLoadingEnd() {
    const loadingObject = Object.keys(this.loadingObjects).find(key => this.loadingObjects[key]);
    this.isLoading = !!loadingObject;
  }

  isMajorCategorySelected(category) {
    return this.selectedMajorCategory && this.selectedMajorCategory.name === category.name;
  }

  public onImageLoad(block: BlockModel) {
    block.IsImageLoaded = true;

    this.isAllImagesLoaded = this.filteredBlocks.every(b => b.IsImageLoaded);
  }

  private filterBlocksInCategory({ category, files }) {
    const visibleFiles = files.filter(file => file.IsVisible);

    if (category === 'Menus') return this.filterMenus(visibleFiles);

    return visibleFiles;
  }

  private filterMenus(blocks) {
    const type = this.isMenuVertical ? 'MENU_VERTICAL' : 'MENU_HORIZONTAL';

    return blocks.filter(block => {
      if (this.isBothMenus) return block.Type === 'MENU_VERTICAL' || block.Type === 'MENU_HORIZONTAL';

      return block.Type === type;
    });
  }
}
