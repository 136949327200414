// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .image-manager-change-website-to-personal-modal .modal-header,
:host ::ng-deep .image-manager-change-website-to-personal-modal .modal-body {
  width: 600px !important;
  max-width: 600px !important;
}

.image-manager-change-website-to-personal-modal-body {
  margin: 20px;
  clear: both;
  overflow: hidden;
}
.image-manager-change-website-to-personal-modal-body .paragraph {
  padding-top: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/image-manager-change-website-to-personal/image-manager-change-website-to-personal-modal.component.scss"],"names":[],"mappings":"AAII;;EAEE,uBAAA;EACA,2BAAA;AAHN;;AAQA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AALF;AAOE;EACE,gBAAA;AALJ","sourcesContent":["@import \"../../../../../styles/colors\";\n\n:host ::ng-deep {\n  .image-manager-change-website-to-personal-modal {\n    .modal-header,\n    .modal-body {\n      width: 600px !important;\n      max-width: 600px !important;\n    }\n  }\n}\n\n.image-manager-change-website-to-personal-modal-body {\n  margin: 20px;\n  clear: both;\n  overflow: hidden;\n\n  .paragraph {\n    padding-top: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
