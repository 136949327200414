import {BaseColorModel} from '../../base/color/base-color.model';

import {PROPERTY_NAME} from './constants';

export class BlogPostLinksColorModel extends BaseColorModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, null, value, isEnabled);
  }

  public init(element: HTMLElement) {
    this.isEnabled = element && element.classList.contains('blog');

    super.init(element, {
      selectedElement: element,
      defaultValueHolderElement: element,
    });
  }
}
