import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';

import {Observable, Subject, of, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {AppAnimations} from '../../../../app-animations';

import {CanLeaveComponent} from '../../../../shared/services/guards/can-leave-component-guard.service';

import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {WebsitesHttpService} from '../../../../core/services/interaction/http/websites/websites-http.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';

import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';
import {WebsiteDto} from '../../../../core/models/websites/website.dto';

import {EMAIL_REGEXP} from './constants';
import {BUTTONS_KEYS} from '../../../../core/services/buttons/constants';
import {PUBLISH_STATUSES} from '../../../../services/publish-website/constants';

@Component({
  selector: 'app-contact-info-settings',
  templateUrl: './contact-info-settings.component.html',
  styleUrls: ['./contact-info-settings.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ContactInfoSettingsComponent implements CanLeaveComponent, OnInit, OnDestroy {
  public SAVE_REQUEST_MODAL_ID: string = 'save-request-modal-contact-info';
  private HAS_UNPUBLISHED_CHANGES: string = 'HAS_UNPUBLISHED_CHANGES';

  public canLeaveComponentSubject = new Subject<boolean>();

  public website: WebsiteModel = null;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  public isEmailValid: boolean = true;

  private key = 'WebsiteSettingsComponent';

  private hasChanges = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private websitesService: WebsitesService,
              private websitesHttpService: WebsitesHttpService,
              private modalsService: ModalsService,
              private buttonsService: ButtonsService,
              private loaderService: ContentLoaderService,
              private editorControlButtonsService: EditorControlButtonsService,
              private socketsService: SocketsService,
              private cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });

    this.editorControlButtonsService.controlBtnClick.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.saveHandler.bind(this));
  }

  toggleHasChanges(value) {
    this.hasChanges = value;
    this.buttonsService.setButtonState(BUTTONS_KEYS.SAVE, value);
  }

  public saveHandler() {
    this.save().pipe(
      catchError(e => {
        console.error(e);

        this.buttonsService.enableSaveButton();
        
        this.canLeaveComponentSubject.next(false);

        this.toggleHasChanges(true);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      }),
    ).subscribe(() => {
      this.socketsService.publishDataSubject.next({ status: PUBLISH_STATUSES.READY, websiteId: this.websitesService.activeWebsiteId });

      this.websitesService.fetchWebsite();

      this.canLeaveComponentSubject.next(true);
    });
  }

  private save(): Observable<any> {
    if (!this.website) {
      return of(this.buttonsService.enableSaveButton());
    }

    this.isEmailValid = !this.website.contactEmail || EMAIL_REGEXP.test(this.website.contactEmail);

    if (!this.isEmailValid) {
      return of(this.buttonsService.enableSaveButton());
    }

    this.loaderService.show(this.key);

    this.toggleHasChanges(false);

    this.website.publishStatus = this.HAS_UNPUBLISHED_CHANGES;

    return this.websitesHttpService.updateWebsite(this.website.id, WebsiteDto.fromModel(this.website));
  }

  public dontSaveHandler(): void {
    this.canLeaveComponentSubject.next(true);
  }

  public continueEditingHandler(): void {
    this.canLeaveComponentSubject.next(false);
  }

  public canLeave(): boolean | Observable<boolean> {
    if (this.hasChanges) {
      this.modalsService.open(this.SAVE_REQUEST_MODAL_ID);

      return this.canLeaveComponentSubject;
    }

    return true;
  }

  public onChange(): void {
    this.toggleHasChanges(true);
  }

  public ngOnDestroy(): void {
    this.buttonsService.disableAllButtons();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
