export class AudioModel {
  public sizeFormatted: string = null;
  public durationFormatted: string = null;

  constructor(public id?: number,
              public userId?: number,
              public fileName?: string,
              public originalFileName?: string,
              public duration?: string,
              public fileSize?: string,
              public link?: string,
              public isFirstClicked: boolean = false,
              public isSelected: boolean = false,
              public isUploading: boolean = false,
              public isDeleting: boolean = false,
              public percent: string = '') {
    this.initSize();
    this.initDuration();
  }

  private initSize(): void {
    if (!this.fileSize) return;

    try {
      const size = Number.parseInt(this.fileSize);

      if (Number.isNaN(size)) return;

      this.sizeFormatted = `${Math.round((size / 1048576 + Number.EPSILON) * 10) / 10} MB`;
    } catch (e) {}
  }

  private initDuration(): void {
    if (!this.duration) return;

    try {
      const duration = Number.parseInt(this.duration);

      if (Number.isNaN(duration)) return;

      const h = Math.floor(duration / 3600);
      const m = Math.floor(duration % 3600 / 60);
      const s = duration % 60;

      this.durationFormatted = `${h ? `${h}:` : ''}${this.formatNumber(m)}:${this.formatNumber(s)}`;
    } catch (e) {}
  }

  private formatNumber(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }
}
