import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {GoogleAnalytics4ApiService} from '../../services/google/apis/ga4/ga-4.service';
import {WebsitesService} from '../../services/websites/websites.service';
import {AuthService} from '../../../auth/auth.service';
import {NavigationService} from '../../../services/navigation.service';
import {BrowserService} from '../../services/browser/browser.service';
import {UtilsService} from '../../services/utils/utils.service';

import {WebsiteModel} from '../../models/websites/website.model';
import {AccountModel} from '../../models/accounts/account.model';
import {Ga4AccountSetupModel} from '../../models/google/ga-4/account-setup.model';
import {NestedSelectOption} from '../../models/select/nested/nested-select-option.model';

import {environment} from '../../../../environments/environment';

import {STEPS, STEPS_MAPPING, COUNTRIES_LIST} from './constants';

import {AppAnimations} from '../../../app-animations';

@Component({
  selector: 'app-google-analytics-setup',
  templateUrl: './google-analytics-setup.component.html',
  styleUrls: ['./google-analytics-setup.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class GoogleAnalyticsSetupComponent implements OnInit, OnDestroy {
  public step: string;

  public countryOfBusinessOptions: NestedSelectOption[];

  public isChrome: boolean = false;
  public isInititalLoading: boolean = true;
  public isLoading: boolean = false;
  public isAccountProvision: boolean = false;
  public isFinishingAccountSetup: boolean = false;
  public isForceCompletedStep: boolean = false;
  public isTosLinkCopied: boolean = false;
  public isStepError: boolean = false;
  public isUserCancelError: boolean = false;
  public isUnknownError: boolean = false;

  public accountTicketId: string;

  private accessToken: string;
  private tokenType: string;
  private userName: string;
  private regionCode: string = 'US';
  private websiteTitle: string;

  private tosCopyTimeoutId;

  private stepHandlers = {
    [STEPS.WELCOME]: this.onWelcomeStep.bind(this),
    [STEPS.OAUTH]: this.onOauthStep.bind(this),
    [STEPS.ACCOUNT_PROVISION]: this.onAccountProvisionStep.bind(this),
    [STEPS.COMPLETED]: this.onCompletedStep.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get STEPS() {
    return STEPS;
  }

  private get accountProvisionHref(): string {
    return `https://analytics.google.com/analytics/web/?provisioningSignup=false#/termsofservice/${this.accountTicketId}`;
  }
  
  constructor(
    private googleAnalytics4ApiService: GoogleAnalytics4ApiService,
    private websitesService: WebsitesService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private browserService: BrowserService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isChrome = this.browserService.isChrome;

    this.countryOfBusinessOptions = COUNTRIES_LIST.map(([code, label]) => {
      const option: NestedSelectOption =  new NestedSelectOption(label, code);

      option.isSelected = code === this.regionCode;

      return option;
    });

    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
      this.handleCurrentStep(params['step']);
    });
  }

  public ngOnInit(): void {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.userName = account ? account.name : null;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.websiteTitle = website ? website.title : null;
      
      this.checkAccess(website);
    });
  }

  private checkAccess(website: WebsiteModel): void {
    if (!website) {
      return;
    }

    this.isInititalLoading = false;

    if (this.isForceCompletedStep || !website.isGa4SetupCompleted) {
      return;
    }

    this.navigationService.toStatistic();
  }

  public onCountryOfBusinessSelect(options: NestedSelectOption[]): void {
    const country: NestedSelectOption = options.find((item: NestedSelectOption) => item.isSelected);
    
    this.regionCode = country ? country.value : null;
  }

  private handleCurrentStep(stepMapping: string): void {
    if (!stepMapping) {
      this.setStep(STEPS.WELCOME);
      
      return;
    }

    const key: string = Object.keys(STEPS_MAPPING).find((key: string) => {
      return STEPS_MAPPING[key] === stepMapping;
    });

    this.setStep(key);
  }

  private onWelcomeStep() {

  }

  public selectGoogleAccount(): void {
    this.googleAnalytics4ApiService.authorize();
  }

  private onOauthStep() {
    try {
      this.parseOauthHash();

      this.validateAccess()

      this.isLoading = true;

      this.retrieveAccountTicketId();
    } catch (e) {
      console.error(e);

      this.setStep(STEPS.WELCOME);

      this.isLoading = false;
    }
  }

  private parseOauthHash(): void {
    const params: URLSearchParams = new URLSearchParams(window.location.hash.substring(1));

    this.accessToken = params.get('access_token');
    this.tokenType = params.get('token_type');
  }

  private retrieveAccountTicketId() {
    this.googleAnalytics4ApiService.createAccount({
      accessToken: this.accessToken,
      tokenType: this.tokenType,
      userName: this.userName,
      regionCode: this.regionCode,
      redirectUri: environment.ga4.accountProvisionRedirectUri,
    }).pipe(
      catchError(e => {
        console.error(e);
        
        this.isStepError = true;
        this.isLoading = false;

        return throwError(() => e);
      })
    ).subscribe((res: { accountTicketId: string }) => {
      this.googleAnalytics4ApiService.saveData({
        accountTicketId: res.accountTicketId,
      }).subscribe(() => {});

      this.isLoading = false;

      this.accountTicketId = res.accountTicketId;

      this.setStep(STEPS.ACCOUNT_PROVISION);
    });
  }

  private onAccountProvisionStep(): void {
    try {
      this.validateAccess();
    } catch (e) {
      
    }
  }

  private validateAccess(): void {
    if (!this.accessToken || !this.tokenType) {
      this.isStepError = true;

      throw 'no credentials';
    }
  }

  public doAccountProvision(): void {
    this.isAccountProvision = true;

    window.open(this.accountProvisionHref, '_blank');

    this.finishSetupWithDelay(5000);
  }

  public copyTosLink(): void {
    window.clearTimeout(this.tosCopyTimeoutId);

    this.isTosLinkCopied = true;

    this.utilsService.copyToClipboard(this.accountProvisionHref);

    this.tosCopyTimeoutId = window.setTimeout(() => {
      this.isTosLinkCopied = false;
    }, 1000);
  }

  private finishSetupWithDelay(timeout: number = 2500): void {
    window.setTimeout(() => {
      this.finishSetup();
    }, timeout);
  }

  public finishSetup(): void {
    if (this.isFinishingAccountSetup) {
      return;
    }

    this.isFinishingAccountSetup = true;

    this.googleAnalytics4ApiService.finishSetup({
      accessToken: this.accessToken,
      tokenType: this.tokenType,
      websiteTitle: this.websiteTitle,
    }).pipe(
      catchError(e => {
        console.error(e);
        
        this.isFinishingAccountSetup = false;

        return throwError(() => e);
      })
    ).subscribe((res: Ga4AccountSetupModel) => {
      this.isFinishingAccountSetup = false;

      if (!res) {
        this.finishSetupWithDelay();

        return;
      }
      
      if (res.isError || res.isUnknownError|| res.isUserCancelError) {
        this.isUnknownError = res.isUnknownError;
        this.isUserCancelError = res.isUserCancelError;
        
        return;
      }

      if (!res.isSuccess) {
        this.finishSetupWithDelay();

        return;
      }

      this.websitesService.fetchWebsite();

      this.setStep(STEPS.COMPLETED);
    });
  }

  private onCompletedStep(): void {
    this.isForceCompletedStep = true;
  }

  public toDashboard(): void {
    this.navigationService.toStatistic();
  }

  private setStep(step: string = STEPS.WELCOME): void {
    if (this.step === step) {
      return;
    }

    this.step = step;

    if (!this.stepHandlers[this.step]) {
      return;
    }

    this.stepHandlers[this.step]();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
