import {PostTemplateModel} from './post-template.model';

export class PostTemplateDto {
  constructor(public PostTemplateID: number,
              public Name: string,
              public Url: string,
              public Html: string) {}


  public static normalize(res: PostTemplateDto): PostTemplateModel {
    if (!res) return null;

    return new PostTemplateModel(
      res.PostTemplateID,
      res.Name,
      res.Url,
      res.Html,
    );
  }
}

