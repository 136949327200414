import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {DocumentDto} from '../../../../models/document/document.dto';
import {DocumentModel} from '../../../../models/document/document.model';

@Injectable()
export class DocumentsHttpService {
  private model = 'documents';

  constructor(private http: HttpClient) {
  }

  public finishUploading(document: DocumentModel, fileName: string): Observable<DocumentModel> {
    return this.http.post(`api/${this.model}`, { id: document.id, fileName }).pipe(
      map((res: DocumentDto) => DocumentDto.normalize(res))
    );
  }

  public deleteMany(ids: number[]): Observable<any> {
    return this.http.request('delete', `api/${this.model}`, { body: { ids }, responseType: 'text' });
  }
}
