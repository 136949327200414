import {ImageModel} from "../../../../core/models/images/image.model";

export class StudentImageLabelModel {
  public title: string = '';
  public caption: string = '';

  constructor(
    public image: ImageModel,
    public portfolioDefaults: any
  ) {
    this.init();
  }

  private init(): void {
    if (!this.image || !this.portfolioDefaults) {
      return;
    }

    this.initTitle();
    this.initCaption();
  }

  private initTitle(): void {
    const title: string = this.image.isTitleDefault ? this.portfolioDefaults.title : this.image.title;
    const year: string = this.image.isYearDefault ? this.portfolioDefaults.yearproduced : this.image.year;

    this.title = title || year ? `${title ? title : ''}${title && year ? ', ' : ''}${year ? year : ''}` : '';

    if (this.title.length === 0 || this.title.endsWith('.')) {
      return;
    }

    this.title = `${this.title}.`;
  }

  private initCaption(): void {
    const caption: string = this.image.isCaptionDefault ? this.portfolioDefaults.caption : this.image.caption;

    this.caption = caption ? caption : '';
  }
}
