import {BasePxStyleOptionModel} from '../../base/base-px-style-option/base-px-style-option.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX, STYLE_PROPERTY} from './constants';

export class MenuPaddingTopModel extends BasePxStyleOptionModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, null, value, isEnabled, false);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled) return;

    super.init(element);

    this.migrateOldValue();
  }

  private migrateOldValue(): void {
    const element: HTMLElement = this.element && <HTMLElement>this.element.querySelector('.desktop-section');

    if (!element) return;

    const paddingTop: number = Number.parseInt(element.style[STYLE_PROPERTY]);

    if (Number.isNaN(paddingTop)) return;

    super.onChange(paddingTop);

    element.style.removeProperty(STYLE_PROPERTY);
  }
}
