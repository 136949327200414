import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ImageLabelService} from '../../../core/services/image-manager/image-label/image-label.service';
import {AuthService} from '../../../auth/auth.service';
import {ImagesHttpService} from '../../../core/services/interaction/http/images/images-http.service';
import {ImageDetailsService} from '../../../core/services/image-manager/image-details/image-details.service';
import {WebsitesService} from '../../../core/services/websites/websites.service';

import {ImageLabelModel} from '../../../core/models/images/image-label/image-label.model';
import {AccountModel} from '../../../core/models/accounts/account.model';
import {WebsiteModel} from '../../../core/models/websites/website.model';

@Component({
  selector: 'app-image-label',
  templateUrl: './image-label.component.html',
  styleUrls: ['./image-label.component.scss'],
})
export class ImageLabelComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper') wrapper: ElementRef;

  @Input() showInfo: boolean;
  @Input() userInfo: any;

  public purchaseLinkTooltipInfo: string = 'Purchase Button or Link is disabled in Image Manager.';

  public imageLabel: ImageLabelModel;

  public payPalEmail: string = '';

  public isECommerce: boolean = false;
  public isPurchaseButtonDisabled: boolean = true;
  public isSimpleImageLabel: boolean = true;

  private website: WebsiteModel = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get copyright() {
    return `© ${this.imageLabel.copyright}. All Rights Reserved.`;
  }

  constructor(
    private imageLabelService: ImageLabelService,
    private imageDetailsService: ImageDetailsService,
    private authService: AuthService,
    private websitesService: WebsitesService,
    private httpService: ImagesHttpService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.imageLabelService.imageLabelSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageLabelModel) => {
      this.imageLabel = data;

      if (this.wrapper && this.imageLabel && !this.imageLabel.isVisible) {
        const wrapper = <HTMLElement>this.wrapper.nativeElement;

        wrapper.scrollTop = 0;
      }

      this.isPurchaseButtonDisabled = !this.imageLabel || this.imageLabel.prices.length !== 1;
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.isECommerce = account && account.isECommerce;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
      this.payPalEmail = website ? website.payPalEmail : '';
      this.isSimpleImageLabel = website ? website.isSimpleImageLabel : true;

      this.cdr.detectChanges();
    });
  }

  public onRadiobuttonClick() {
    this.isPurchaseButtonDisabled = false;
  }

  public enableImageLabelVisibility(): void {
    const id = this.imageLabel.imageId;

    this.httpService.updateImage(id, { isInfoVisible: true }).subscribe(() => {
      if (!this.imageLabel || id !== this.imageLabel.imageId) return;

      this.imageDetailsService.fetchImageDetails(id);
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}

