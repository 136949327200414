export const DESCRIPTION_POSITION = {
  BOTTOM_LEFT_CURVED: 'BOTTOM_LEFT_CURVED',
  BOTTOM_RIGHT_CURVED: 'BOTTOM_RIGHT_CURVED',
  VERTICALLY_CENTERED_RIGHT: 'VERTICALLY_CENTERED_RIGHT',
  VERTICALLY_CENTERED_LEFT: 'VERTICALLY_CENTERED_LEFT',
  HORIZONTALLY_CENTERED_BOTTOM: 'HORIZONTALLY_CENTERED_BOTTOM',
  HORIZONTALLY_CENTERED_BOTTOM_2X: 'HORIZONTALLY_CENTERED_BOTTOM_2X',
  HORIZONTALLY_CENTERED_BOTTOM_3X: 'HORIZONTALLY_CENTERED_BOTTOM_3X',
  INNER_BOTTOM_RIGHT_CURVED_30_30: 'INNER_BOTTOM_RIGHT_CURVED_30_30',
  TOP_LEFT_CURVED: 'TOP_LEFT_CURVED',
  DOUBLE_DRAG_DROP_IMAGE: 'DOUBLE_DRAG_DROP_IMAGE',
  HORIZONTALLY_CENTERED_TOP: 'HORIZONTALLY_CENTERED_TOP',
  LEFT_TOP_TO_RIGHT_CENTER: 'LEFT_TOP_TO_RIGHT_CENTER',
};
