import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject} from 'rxjs';

@Injectable()
export class RequestInterceptorService {
  public onCourseEndedSubject: Subject<{ key: string, message?: string, data?: any }> = new Subject<{ key: string, message?: string, data?: any }>();
  public onUnhandledRequestSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onForceLogoutSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
