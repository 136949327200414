import {BaseColorRgbModel} from '../../../../base/color-rgb/base-color-rgb.model';

import {BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB} from './constants';

export class PortfolioEnlargementArrowsBackgroundColorModel extends BaseColorRgbModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB, value, isEnabled, false);
  }

  public init(selectedElement: HTMLElement): void {
    if (!this.isEnabled || !selectedElement) return;

    const wrapper: HTMLElement = <HTMLElement>selectedElement.closest('.portfolio-enlargement-wrapper');

    super.init(wrapper, {
      selectedElement,
      defaultValueHolderElement: selectedElement,
    });
  }
}
