import * as moment from 'moment';

import {BaseFileObjectDto} from '../../../base/file-object/base-file-object.dto';
import {StudentImageReviewAudioModel} from './student-image-review-audio.model';
import {StudentImageReviewAudioListenedAtDto} from './student-image-review-audio-listened-at.dto';

import {TIME_FORMATTER} from '../constants';

export class StudentImageReviewAudioDto extends BaseFileObjectDto {
  constructor(
    public Id: number,
    public UserId: number,
    public ReviewId: number,
    public FileName: string,
    public OriginalFileName: string,
    public MimeType: string,
    public Duration: string,
    public FileSize: string,
    public Link: string,
    public ListenedAt: StudentImageReviewAudioListenedAtDto,
    public CreatedAt: string,
    public UpdatedAt: string
  ) {
    super();
  }

  public static normalize(res: StudentImageReviewAudioDto): StudentImageReviewAudioModel {
    if (!res) return null;

    return new StudentImageReviewAudioModel(
      res.Id,
      res.UserId,
      res.ReviewId,
      res.FileName,
      res.OriginalFileName,
      res.MimeType,
      res.Duration,
      res.FileSize,
      res.Link,
      StudentImageReviewAudioListenedAtDto.normalize(res.ListenedAt),
      moment(res.CreatedAt).format(TIME_FORMATTER),
      moment(res.UpdatedAt).format(TIME_FORMATTER),
    );
  }
}
