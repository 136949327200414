import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {StudentWebsitesService} from '../../../../../core/services/education/students/websites/student-websites.service';
import {EducationStudentsService} from '../../../../../core/services/education/students/education-students.service';
import {ImageDetailsService} from '../../../../../core/services/image-manager/image-details/image-details.service';
import {EducationImageManagerActionService} from '../../../../../core/services/education/image-manager/reorder/education-image-manager-reorder.service';
import {WebsitesService} from '../../../../../core/services/websites/websites.service';
import {AuthService} from '../../../../../auth/auth.service';
import {SocketsService} from '../../../../../core/services/interaction/sockets/sockets.service';
import {StudentPortfoliosService} from '../../../../../core/services/education/students/websites/portfolios/student-portfolios.service';
import {AddNodeModalService} from '../../../../../shared/services/modals/add-node/add-node-modal.service';
import {PagesService} from '../pages.service';
import {MessageModalService} from '../../../../../services/message-modal.service';
import {DeletePortfolioModalService} from '../../../../../shared/services/modals/delete-portfolio/delete-portfolio-modal.service';
import {IsPublishingService} from '../../../../../services/is-publishing/is-publishing.service';

import {EducationStudentModel} from '../../../../../core/models/education/students/education-student.model';
import {NestedSelectOption} from '../../../../../core/models/select/nested/nested-select-option.model';
import {PortfolioModel} from '../../../../../core/models/portfolios/portfolio.model';
import {WebsiteModel} from '../../../../../core/models/websites/website.model';
import {EducationStudentWebsiteModel} from '../../../../../core/models/education/students/websites/education-student-website.model';
import {ImageManagerActionDataModel} from '../../../../../core/models/education/image-manager-action/image-manager-action-data.model';
import {NodeModel} from '../../../../../core/models/nodes/node.model';
import {ISocketWebsitesMessageDataModel} from '../../../../../core/models/sockets/message/websites/i-websites-message-data.model';
import {ISocketImageManagerMessageDataModel} from '../../../../../core/models/sockets/message/image-manager/i-image-manager-message-data.model';
import {ModalHeader} from '../../../../../common/models/modal/header/header.model';
import {Button} from '../../../../../common/models/button/button.model';

import {SOCKET_ACTIONS} from '../../../../main/image-manager/constants';

@Component({
  selector: 'app-student-image-manager-sidebar',
  templateUrl: './student-image-manager-sidebar.component.html',
  styleUrls: ['./student-image-manager-sidebar.component.scss'], 
})
export class StudentImageManagerSidebarComponent implements OnInit, OnDestroy {
  public student: EducationStudentModel;

  public websites: EducationStudentWebsiteModel[];
  public websitesOptions: NestedSelectOption[];
  public selectedWebsite: EducationStudentWebsiteModel;
  public selectedWebsiteOption: NestedSelectOption;
  
  public portfolios: PortfolioModel[];
  public selectedPortfolio: PortfolioModel;

  public isWebsitesDropdownVisible: boolean = false;

  public isBlockedByAction: boolean = false;
  public isSelectedCourseEnded: boolean = false;

  private activeWebsite: WebsiteModel;

  private reorderData: { [key: string]: ImageManagerActionDataModel };

  private publishingData: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean };
  
  private addPageModalUnsubscribe: Subject<boolean>;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private websitesSocketDataHandlers = {
    'WEBSITES_LIST_CHANGED': this.initWebsites.bind(this),
    'ACTIVE_WEBSITE_CHANGED': this.initWebsites.bind(this),
  };

  private imageManagerSocketDataHandlers = {
    [SOCKET_ACTIONS.PORTFOLIO_ACTION]: this.onPortfolioAction.bind(this),
  };

  private errorModalButtons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onErrorModalClose.bind(this),
    },
  ];

  private get isPublishing(): boolean {
    if (!this.publishingData || !this.publishingData.isActiveWebsite) {
      return false;
    }

    return this.publishingData.isPublishing;
  }

  constructor(
    private studentsService: EducationStudentsService,
    private studentWebsitesService: StudentWebsitesService,
    private studentPortfoliosService: StudentPortfoliosService,
    private educationImageManagerActionService: EducationImageManagerActionService,
    private websitesService: WebsitesService,
    private imageDetailsService: ImageDetailsService,
    private addNodeModalService: AddNodeModalService,
    private deletePortfolioModalService: DeletePortfolioModalService,
    private messageModalService: MessageModalService,
    private isPublishingService: IsPublishingService,
    private pagesService: PagesService,
    private socketsService: SocketsService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
    this.initWebsites();
  }

  private initWebsites(): void {
    this.studentWebsitesService.fetchAllForCurrentStudent();
  }

  public ngOnInit(): void {
    this.socketsService.websitesDataSubject.subscribe((data: ISocketWebsitesMessageDataModel) => {
      this.onWebsitesSocketData(data);
    });

    this.socketsService.imageManagerDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISocketImageManagerMessageDataModel) => {
      this.onImageManagerSocketData(data);
    });

    this.authService.websiteChangedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.selectedWebsite = null;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((activeWebsite: WebsiteModel) => {
      this.activeWebsite = activeWebsite;
      
      this.handleWebsites();
    });

    this.studentsService.studentDetailsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((item: EducationStudentModel) => {
      this.student = item;
    });

    this.studentWebsitesService.websitesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: EducationStudentWebsiteModel[]) => {
      this.websites = websites;
      
      this.handleWebsites();
    });

    this.studentPortfoliosService.portfoliosSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolios: PortfolioModel[]) => {
      this.portfolios = portfolios;

      this.selectPortfolio(portfolios ? portfolios[0] : null);
    });

    this.studentPortfoliosService.endedCourse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websiteId: number) => {
      this.isSelectedCourseEnded = this.selectedWebsite && this.selectedWebsite.id === websiteId;
    });

    this.studentPortfoliosService.selectedPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolio: PortfolioModel) => {
      this.selectedPortfolio = portfolio;
    });

    this.deletePortfolioModalService.onRemoveSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolio: PortfolioModel) => {
      if (!portfolio) {
        return;
      }

      this.studentPortfoliosService.removeOne(portfolio).subscribe(() => {
        this.deletePortfolioModalService.close();
        
        if (!this.selectedWebsite) {
          return;
        }

        this.studentPortfoliosService.fetchForWebsite({
          websiteId: this.selectedWebsite.id,
        });
      });
    });

    this.educationImageManagerActionService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { [key: string]: ImageManagerActionDataModel }) => {
      this.reorderData = data;

      this.initBlockedState();
    });

    this.educationImageManagerActionService.onStopSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ImageManagerActionDataModel) => {
      this.onReorderStop(data);
    });

    this.isPublishingService.isPublishingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean }) => {
      this.publishingData = data;
    });
  }

  private onWebsitesSocketData(data: ISocketWebsitesMessageDataModel): void {
    if (!data || !this.websitesSocketDataHandlers[data.key]) {
      return;
    }

    this.websitesSocketDataHandlers[data.key](data);
  }

  private onImageManagerSocketData(data: ISocketImageManagerMessageDataModel): void {
    if (!data || !this.imageManagerSocketDataHandlers[data.action]) {
      return;
    }

    this.imageManagerSocketDataHandlers[data.action](data);
  }

  private onPortfolioAction(data: ISocketImageManagerMessageDataModel): void {
    if (!this.student || !this.selectedWebsite) {
      return;
    }

    if (this.student.userId !== data.portfolioOwnerUserId) {
      return;
    }
    
    if (data.key !== 'CREATE' && data.key !== 'UPDATE') {
      return;
    }

    // this.studentsService.fetchOne(this.selectedInstitution.id, this.selectedClass.id, this.selectedStudent.id).add(() => {

    // });
  }

  private handleWebsites(): void {
    this.initWebsitesOptions();
    
    this.onWebsiteSelect(this.websitesOptions);
  }

  private initWebsitesOptions(): void {
    this.websitesOptions = this.websites ? this.websites.map((item: EducationStudentWebsiteModel) => {
      return new NestedSelectOption(
        `${item.course.name} "${item.course.number}"`,
        `${item.id}`,
      );
    }) : [];

    this.isWebsitesDropdownVisible = this.websitesOptions.length > 1;

    if (this.websitesOptions.length === 0) {
      this.clearSelectedWebsite();

      return;
    }
    
    if (!this.activeWebsite) {
      this.websitesOptions[0].isSelected = true;

      return;
    }

    const websiteId: string = `${this.activeWebsite.id}`;

    const option: NestedSelectOption = this.websitesOptions.find((item: NestedSelectOption) => {
      return item.value === websiteId;
    });

    (option || this.websitesOptions[0]).isSelected = true;
  }

  public onWebsiteSelect(options: NestedSelectOption[]): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    this.isSelectedCourseEnded = false;
    
    if (!this.websites) {
      return;
    }
    
    const websites: NestedSelectOption[] = options ? options.filter((item: NestedSelectOption) => item.isSelected) : null;
    
    this.selectedWebsite = this.websites.find((item: EducationStudentWebsiteModel) => {
      return websites.findIndex((option: NestedSelectOption) => Number.parseInt(option.value) === item.id) !== -1;
    });
    
    this.initSelectedWebsiteOption();

    if (!this.selectedWebsite) {
      return;
    }

    this.studentsService.fetchCurrent(this.selectedWebsite.id);

    this.studentPortfoliosService.fetchForWebsite({
      websiteId: this.selectedWebsite.id,
    });
  }

  private initSelectedWebsiteOption(): void {
    if (!this.selectedWebsite || !this.websites) {
      return;
    }

    this.selectedWebsiteOption = this.websitesOptions.find((option: NestedSelectOption) => {
      return option.isSelected;
    });
  }

  public openAddPageModal(): void {
    if (this.addPageModalUnsubscribe) {
      this.addPageModalUnsubscribe.next(true);
      this.addPageModalUnsubscribe.complete();
    }

    this.addPageModalUnsubscribe = new Subject<boolean>();

    this.pagesService.parentNodeSubject.next(null);
    
    this.addNodeModalService.open();

    this.studentPortfoliosService.submitPageCreateSubject.pipe(takeUntil(this.addPageModalUnsubscribe)).subscribe((node: NodeModel) => {
      if (!node) {
        return;
      }
    
      this.studentPortfoliosService.create({
        websiteId: this.selectedWebsite.id,
        node,
      }).pipe(
        catchError(e => {
          console.error(e);
          
          this.onError(e.error.key);

          return throwError(() => e);
        })
      ).subscribe((node: NodeModel) => {
        this.addPageModalUnsubscribe.next(true);
        this.addPageModalUnsubscribe.complete();

        this.addNodeModalService.close();

        this.studentPortfoliosService.fetchForWebsite({
          websiteId: this.selectedWebsite.id,
        });
      });
    });
  }

  public selectPortfolio(portfolio: PortfolioModel): void {
    if (this.isBlockedByAction) {
      return;
    }

    if (!this.selectedWebsite) {
      return;
    }

    if (portfolio && this.selectedPortfolio && portfolio.id === this.selectedPortfolio.id) {
      return;
    }

    this.imageDetailsService.imageDetailsSubject.next(null);

    this.studentPortfoliosService.selectedPortfolioSubject.next(portfolio);
  }

  private initBlockedState(): void {
    if (!this.reorderData || !this.selectedPortfolio) {
      this.isBlockedByAction = false;

      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId]) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});

    const websiteId: number = this.selectedPortfolio ? this.selectedPortfolio.websiteId : null;
    
    this.isBlockedByAction = !!websiteIds[`${websiteId}`];
  }

  private onReorderStop(data: ImageManagerActionDataModel): void {
    if (!data) {
      return;
    }

    const websiteId: number = this.selectedPortfolio ? this.selectedPortfolio.websiteId : null;

    if (data.websiteId !== websiteId) {
      return;
    }

    const websiteIds: { [key: string]: boolean } = Object.keys(this.reorderData).reduce((res: {}, portfolioId: string) => {
      if (this.reorderData[portfolioId] && portfolioId !== `${data.portfolioId}`) {
        res[this.reorderData[portfolioId].websiteId] = true;
      }

      return res;
    }, {});

    this.isBlockedByAction = !!websiteIds[websiteId];
  }

  private clearSelectedWebsite(): void {
    this.selectedWebsite = null;

    // this.studentWebsitesService.websiteDetailsSubject.next(null);
  }

  private clearSelectedPortfolio(): void {
    this.selectedPortfolio = null;

    // this.studentWebsitesService.websiteDetailsSubject.next(null);
  }

  public toggleShare(portfolio: PortfolioModel): void {
    const isSharedOld: boolean = portfolio.isShared;

    portfolio.isShared = !portfolio.isShared;

    const onSuccess = () => {
      if (!this.selectedWebsite || this.selectedWebsite.id !== portfolio.websiteId) {
        return;
      }

      this.studentPortfoliosService.fetchForWebsite({
        websiteId: this.selectedWebsite.id,
      });
    };

    this.studentPortfoliosService.toggleShare({
      websiteId: portfolio.websiteId,
      portfolioId: portfolio.id,
      isShared: portfolio.isShared,
    }).pipe(
      catchError(e => {
        portfolio.isShared = isSharedOld;
        
        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  public onError(key: string, isUpdate: boolean = false): void {
    const header: ModalHeader = {
      text: this.pagesService.validationHeaders[key] || 'Something went wrong',
      className: 'error-header',
    };
    
    const message: string = this.pagesService.validationMessages[key] || key || 'Something went wrong';
    
    this.cdr.detectChanges();

    this.studentPortfoliosService.errorSubject.next(message);
    
    if (isUpdate) {
      return this.messageModalService.addMessage(message, header, this.errorModalButtons);
    }
  }

  private onErrorModalClose(): void {
    this.messageModalService.close();
  }

  public removePortfolio(portfolio: PortfolioModel): void {
    this.deletePortfolioModalService.open(portfolio);
  }

  public submitEdit(portfolio: PortfolioModel): void {
    this.studentPortfoliosService.updateTitle({
      websiteId: portfolio.websiteId,
      portfolioId: portfolio.id,
      title: portfolio.title,
    }).subscribe(() => {
      if (!this.selectedWebsite || this.selectedWebsite.id !== portfolio.websiteId) {
        return;
      }

      this.studentPortfoliosService.fetchForWebsite({
        websiteId: this.selectedWebsite.id,
      });
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
