import {AppVersionChangesSummaryItemModel} from './app-version-changes-summary-item.model';

export class AppVersionChangesSummaryItemDto {
  constructor(
    public Id?: number,
    public AppVersion?: string,
    public Changes?: string,
    public CreatedAt?: string,
  ) {}

  public static normalize(res: AppVersionChangesSummaryItemDto): AppVersionChangesSummaryItemModel {
    if (!res) return null;

    return new AppVersionChangesSummaryItemModel(
      res.Id,
      res.AppVersion,
      res.Changes,
      res.CreatedAt,
    );
  }
}
