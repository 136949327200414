import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {ImageManagerChangeWebsiteToPersonalModalService} from '../../../services/modals/image-manager-change-website-to-personal/image-manager-change-website-to-personal-modal.service';
import {MultiWebsiteService} from '../../../../core/services/multi-website/multi-website.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {NavigationService} from '../../../../services/navigation.service';
import {EducationImageManagerService} from '../../../../core/services/education/image-manager/education-image-manager.service';
import {IsPublishingService} from '../../../../services/is-publishing/is-publishing.service';

import {Button} from '../../../../common/models/button/button.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-image-manager-change-website-to-personal-modal',
  templateUrl: './image-manager-change-website-to-personal-modal.component.html',
  styleUrls: ['./image-manager-change-website-to-personal-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageManagerChangeWebsiteToPersonalModalComponent implements OnDestroy {
  public buttons: Button[] = [
    new Button('Stay on Class Website', null, 'green', this.stayWithCurrent.bind(this)),
    new Button('Switch to Personal Website', null, 'neutral', this.switchToPersonal.bind(this)),
  ];

  public isBlocked: boolean = false;

  private websites: WebsiteModel[];

  private publishingData: { websiteId: number, isActiveWebsite: boolean, isPublishing: boolean };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  private get isPublishing(): boolean {
    if (!this.publishingData || !this.publishingData.isActiveWebsite) {
      return false;
    }

    return this.publishingData.isPublishing;
  }

  constructor(
    private modalsService: ModalsService,
    private websitesService: WebsitesService,
    private multiWebsiteService: MultiWebsiteService,
    private educationImageManagerService: EducationImageManagerService,
    private isPublishingService: IsPublishingService,
    private navigationService: NavigationService,
    private service: ImageManagerChangeWebsiteToPersonalModalService,
  ) {
    this.multiWebsiteService.websitesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: WebsiteModel[]) => {
      this.websites = websites;
    });
  }

  private stayWithCurrent(): void {
    this.educationImageManagerService.setActiveTab('user', { unlock: true });

    this.closeModal();
  }

  private switchToPersonal(): void {
    if (!this.websites || this.websites.length === 0) {
      return;
    }

    this.setActiveWebsite(this.websites[0].id);
  }

  private setActiveWebsite(websiteId: number): void {
    if (this.isPublishing) {
      this.isPublishingService.openWarningModal.next(true);
      
      return;
    }

    if (this.isBlocked) {
      return;
    }

    this.isBlocked = true;
    
    this.multiWebsiteService.setActiveWebsite(websiteId).add(() => {
      this.websitesService.activeWebsiteIdSubject.pipe(take(1)).subscribe((activeWebsiteId: number) => {
        this.navigationService.openWebsiteImageManager(activeWebsiteId).add(() => {
          this.isBlocked = false;

          this.closeModal();
        });
      });
    });
  }

  private closeModal(): void {
    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
