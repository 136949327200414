import {WebsiteTourProgressItemModel} from '../item/website-tour-progress-item.model';

export class WebsiteTourProgressGroupModel {
  public element: HTMLElement;
  public key: string;
  public idx: number;
  public top: number;
  public left: number;
  public width: number;
  public height: number;
  public isViewed: boolean = false;

  constructor(public orderNo?: number,
              public items: WebsiteTourProgressItemModel[] = []) {
  }
}
