import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {Subject} from 'rxjs';

import {CustomSubscriptionUpgradeErrorModalService} from '../../../services/modals/custom-subscription-upgrade-error/custom-subscription-upgrade-error-modal.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';

@Component({
  selector: 'app-custom-subscription-upgrade-error-modal',
  templateUrl: './custom-subscription-upgrade-error-modal.component.html',
  styleUrls: ['./custom-subscription-upgrade-error-modal.component.scss']
})
export class CustomSubscriptionUpgradeErrorModalComponent implements OnDestroy {
  public header: ModalHeader = {
    text: `Can't Upgrade Subscription`,
    className: 'error-header',
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(private router: Router,
              private service: CustomSubscriptionUpgradeErrorModalService) {
  }

  public close(): void {
    this.service.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
