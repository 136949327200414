import { Subject, takeUntil } from 'rxjs';

import {Component, Output, Input, EventEmitter, Renderer2, ViewChild, ElementRef, ChangeDetectorRef, OnInit, OnDestroy} from '@angular/core';

import { IFrameClickOverlayService } from '../../../core/services/iframe/click-overlay/iframe-click-overlay.service';

import {SelectOption} from '../../../core/models/select/option/option.model';

@Component({
  selector: 'app-vs-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnDestroy {
  private readonly id: string = `SelectComponent${Math.round(Math.random() * 100000)}`;
  
  @ViewChild('container') container: ElementRef;

  @Output() changeHandler = new EventEmitter<SelectOption | SelectOption[]>();

  @Input() allSelectedText: string = 'All';
  @Input() notSelectedText: string = 'Select...';
  @Input() noItemsText: string = 'Sorry, no items';

  @Input() options: SelectOption[] = [];
  @Input() selected: SelectOption;

  @Input() disabled: boolean;

  @Input() isMultiple: boolean = false;

  public containerClass: string = 'vs-select';

  public isExpanded: boolean = false;

  private removeMouseUpListener;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isOptionsExists(): boolean {
    return this.options && this.options.length > 0;
  }

  public get boxText(): string {
    if (!this.isAnySelected) return this.notSelectedText;
    if (!this.isMultiple) return this.selected.label;

    return this.isAllSelected ? this.allSelectedText : this.selectedOptions.map(option => option.label).join(', ');
  }

  public get selectedOption(): SelectOption {
    if (!this.options) {
      return null;
    }
    
    return this.options.find(option => option.isSelected);
  }

  public get selectedOptions(): SelectOption[] {
    if (!this.options) {
      return [];
    }

    return this.options.filter(option => option.isSelected);
  }

  public get isAllSelected(): boolean {
    if (!this.options || !this.selectedOptions) {
      return false;
    }
    
    return this.options.length === this.selectedOptions.length;
  }

  public get isAnySelected(): boolean {
    return this.isMultiple ? this.selectedOptions.length > 0 : !!this.selected;
  }

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private iFrameClickOverlayService: IFrameClickOverlayService,
  ) {
  }

  public ngOnInit(): void {
    this.iFrameClickOverlayService.onClickSubject.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      if (!this.isExpanded) {
        return;
      }

      this.collapse();
    });
  }

  public onBoxClick(): void {
    if (this.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  public onSelect(option: SelectOption): void {
    if (!this.isMultiple) {
      this.selected = option;
      
      this.collapse();

      return this.changeHandler.emit(this.selected);
    }

    option.isSelected = !option.isSelected;

    this.changeHandler.emit(this.options);

    this.cdr.detectChanges();
  }

  public handleClick(e: Event): void {
    const target = <HTMLElement>e.target;

    const container = $(target).closest(`.${this.containerClass}`);

    if (container.length && container[0] === this.container.nativeElement) {
      return;
    }

    this.collapse();
  }

  private expand(): void {
    this.isExpanded = true;

    this.iFrameClickOverlayService.show(this.id);
    
    this.attachMouseUpListener();

    this.cdr.detectChanges();
  }

  private collapse(): void {
    this.isExpanded = false;

    this.iFrameClickOverlayService.hide(this.id);

    if (this.removeMouseUpListener) {
      this.removeMouseUpListener();
    }

    this.cdr.detectChanges();
  }

  private attachMouseUpListener(): void {
    this.removeMouseUpListener = this.renderer.listen('document', 'mouseup', this.handleClick.bind(this));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
