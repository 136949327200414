import {StylesSettingsModel} from './styles-settings.model';
import {StyleOptionDto} from './option/style-option.dto';
import {StyleOptionModel} from './option/style-option.model';

export class StylesSettingsDto {
  constructor(
    public options: { [key: string]: StyleOptionDto },
    public optionsList: StyleOptionDto,
    public blocks: { [key: string]: number[] },
    public blocksCategories: { [key: string]: number[] },
    public customElementsStyles: { [key: string]: number[] },
    public elements: { [key: string]: number[] },
  ) {}

  public static normalize(res: StylesSettingsDto): StylesSettingsModel {
    const options: { [key: number]: StyleOptionModel } = {};
    const optionsList: StyleOptionModel[] = [];

    Object.keys(res.options).forEach(key => {
      options[key] = StyleOptionDto.normalize(res.options[key]);
      optionsList.push(options[key]);
    });

    const blocks = StylesSettingsDto.normalizeBlocks(res, options);
    const blocksCategories = StylesSettingsDto.normalizePart(res, options, 'blocksCategories');
    const customElements = StylesSettingsDto.normalizePart(res, options, 'customElements');
    const elements = StylesSettingsDto.normalizePart(res, options, 'elements');

    return new StylesSettingsModel(options, optionsList, blocks, blocksCategories, customElements, elements);
  }

  public static normalizeBlocks(res: StylesSettingsDto, options: { [key: number]: StyleOptionModel }): { [key: string]: { [key: string]: StyleOptionModel[] } } {
    const items: { [key: string]: { [key: string]: StyleOptionModel[] } } = {};
    const { blocks } = res;
    const ids = Object.keys(blocks);

    ids.forEach(id => {
      const versions = Object.keys(blocks[id]);

      versions.forEach(version => {
        items[id] = items[id] || {};
        items[id][version] = blocks[id][version].map(optionKey => options[optionKey]);
      });
    });

    return items;
  }

  public static normalizePart(res: StylesSettingsDto, options: { [key: number]: StyleOptionModel }, partKey: string): { [key: string]: StyleOptionModel[] } {
    const items: { [key: string]: StyleOptionModel[] } = {};
    const itemsKeys = Object.keys(res[partKey]);

    itemsKeys.forEach(key => {
      items[key] = res[partKey][key].map(optionKey => options[optionKey]);
    });

    return items;
  }
}
