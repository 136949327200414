import {StyleOptionTypeModel} from './type/style-option-type.model';
import {StyleOptionUnitModel} from './unit/style-option-unit.model';

export class StyleOptionModel {
  constructor(
    public id: number,
    public optionKey: string,
    public styleName: string,
    public text: string,
    public min: number,
    public max: number,
    public typeId: number,
    public unitId: number,
    public typeData: StyleOptionTypeModel,
    public unitData: StyleOptionUnitModel,
  ) {}
}
