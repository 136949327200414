
import {map, tap} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subject, BehaviorSubject, Observable, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as _ from 'lodash';
import * as $ from 'jquery';

import {StylesDimensionsService} from "../../../core/services/styles/dimensions/styles-dimensions.service";
import {IFrameMenuService} from "../../../core/services/iframe/menu/menu.service";
import {IFrameService} from '../../../core/services/iframe/iframe.service';
import {WebsiteDesignerService} from '../website-designer/website-designer.service';
import {EventsService} from '../../../core/services/interaction/events/events.service';
import {ButtonsService} from '../../../core/services/buttons/buttons.service';
import {StylesService} from '../../../core/services/styles/styles.service';
import {MathService} from '../../../core/services/math/math.service';
import {EditorControlButtonsService} from '../../../services/editor-control-buttons.service';
import {CustomMenuStylesService} from '../../../core/services/styles/custom-menu/custom-menu-styles.service';
import {IFrameRoutingService} from '../../../core/services/iframe/routing/iframe-routing.service';
import {WebsitesService} from '../../../core/services/websites/websites.service';
import {ColorIntersectionService} from '../../../core/services/styles/color/intersection/color-intersection.service';
import {NavigationService} from '../../../services/navigation.service';

import {CustomizerIssuesModel} from './customizer-issues.model';
import {SelectedPageModel} from '../../../core/models/selected-page/selected-page.model';
import {MenuSetupModel} from '../../../core/models/styles/setup/menu/desktop/menu-setup.model';
import {ColorModel} from '../../../core/models/styles/color/color.model';
import {WebsiteModel} from '../../../core/models/websites/website.model';

import {KEYS as MENU_DROPDOWN_ARROW_KEYS} from '../../../core/models/styles/setup/menu/dropdown-arrow/constants';
import {KEYS as COLOR_KEYS} from '../../../core/models/styles/color/intersection/constants';
import {BUTTONS_KEYS} from '../../../core/services/buttons/constants';

@Injectable()
export class DetailsService implements OnDestroy {
  FULL_PAGE_TYPE = {
    'H': 'home',
    'C': 'category',
    'P': 'portfolio',
    'T': 'text',
    'B': 'blog',
    'E': 'blank',
    'A': 'about',
    'M': 'contact',
    'S': 'splash',
  };

  NOT_TITLE = ':not([data-edit-website-title]):not([data-edit-website-title-mobile])';
  NOT_SUBTITLE = ':not([data-edit-website-subtitle]):not([data-edit-website-subtitle-mobile])';
  NOT_MENU = ':not([data-menu-item-link]):not([data-mobile-menu-item-link]):not(.left-menu-icon):not(.right-menu-icon)';
  NOT_MEDIA = ':not([data-media-icon])';
  NOT_SHARE_MEDIA = ':not([data-share-social-icon])';
  NOT_PORTFOLIO_LINK = ':not([data-portfolio-link])';
  NOT_PURCHASE_LINK = ':not([data-editable-type="PURCHASE_LINK"])';
  NOT_PURCHASE_BUTTON = ':not([data-editable-type="PURCHASE_BUTTON"])';
  NOT_TOOLTIP_LINK = ':not(.tooltip-link)';
  NOT_PREVENT_STYLING = ':not([data-prevent-styling="true"])';

  DETAILS_PROPERTIES = {
    'backgroundcolor': {
      attributeKey: 'data-style-backgroundcolor',
      attributeStyle: 'background-color',
      specificSelector: 'body, [data-style-backgroundcolor]',
      customizerHandlers: [],
      resetHandlers: [
        (elements) => {
          this.websiteStyles['backgroundcolor'] = this.parseStyleProperty($(elements[0]), 'background-color');

          elements.css('--background-color', '');
          elements.css('--background-color-rgb', '');

          this.resetPortfolioThumbDrawerBackground();
        },
      ],
      postHandlers: [
        (elements, value) => {
          const colorModel = new ColorModel(value);

          elements.css('--background-color', value);
          elements.css('--background-color-rgb', colorModel.getRGBstring());
        },
      ],
    },
    'pagefontfamily': {
      attributeKey: 'data-style-text',
      attributeStyle: 'font-family',
    },
    'pagefontsize': {
      attributeKey: 'data-style-text',
      attributeStyle: 'font-size',
      unit: 'px',
    },
    'pagefontweight': {
      attributeKey: 'data-style-text',
      attributeStyle: 'font-weight',
    },
    'pagefontcolor': {
      attributeKey: 'data-style-text',
      attributeStyle: 'color',
      linkedStyles: [
        'textpagelinkfontcolor',
        'textpagelinkfonthovercolor',
      ],
    },
    'pageletterspacing': {
      attributeKey: 'data-style-text',
      attributeStyle: 'letter-spacing',
      unit: 'px',
    },
    'pagelineheight': {
      attributeKey: 'data-style-text',
      attributeStyle: 'line-height',
      unit: 'px',
    },
    'pagewordspacing': {
      attributeKey: 'data-style-text',
      attributeStyle: 'word-spacing',
      unit: 'px',
    },
    'pagetexttransform': {
      attributeKey: 'data-style-text',
      attributeStyle: 'text-transform',
    },
    isMenuSticky: {
      attributeKey: null,
      attributeStyle: null,
      specificSelector: '#content-wrapper',
      handlers: [
        (elements, value) => elements.toggleClass('sticky-menu', !!value),
      ],
    },
    marginBetweenBlocks: {
      attributeKey: null,
      attributeStyle: null,
      specificSelector: '.innerWrapper',
      handlers: [
        (elements, value) => {
          if (value === null) return;

          elements.each((index, elem) => elem.style.setProperty('--margin-between-blocks', `${value}px`));
        },
      ],
    },
    menuBlocksSpacing: {
      attributeKey: null,
      attributeStyle: null,
      specificSelector: '.innerWrapper',
      handlers: [
        (elements, value) => {
        },
      ],
    },
    margin: {
      attributeKey: null,
      attributeStyle: null,
      specificSelector: '.innerWrapper',
      handlers: [
        (elements, value) => {
          elements.css('--website-margin', `${(value || 30) / 10}%`);
        },
      ],
    },
    textpagelinkfontcolor: {
      attributeKey: null,
      attributeStyle: null,
      specificSelector: 'body',
      handlers: [
        (elements, value) => {
          if (!value) {
            return;
          }
          
          elements.css('--website-link-color', `${value}`);
        },
      ],
    },
    textpagelinkfonthovercolor: {
      attributeKey: null,
      attributeStyle: null,
      specificSelector: 'body',
      handlers: [
        (elements, value) => {
          if (!value) {
            return;
          }
          
          elements.css('--website-link-hover-color', `${value}`);
        },
      ],
    },
  };

  // todo (evgen) https://trello.com/c/5OwPEEm5
  private fontsMapping = {
    'Helvetica Neue': 'Helvetica',
  };

  latestStyles: any = {
    websiteStyles: {},
    pageStyles: {},
  };

  private website: WebsiteModel = null;

  websiteStyles: any = {};
  pageStyles: any = {};

  // Used for applying customizer styles to blocks (with rewriting their styles).
  // False: styles don't applied on saved blocks, only on template, menu and new dropped blocks;
  forceApplyStyles = false;

  customizerLoadingEnabled = {
    website: false,
    page: false,
    element: false,
  };

  stylesOverridingMessageIsOpened = true;

  wasChanged = {
    pageDetails: false,
    pageDefaults: false,
    reset: false,
  };

  specialFields = ['websiteid', 'settingid', 'settingtypeid', 'pageid', 'templateid'];

  public WEBSITE_DEFAULTS_VALUES = {
    10: {
      textpagelinkfontcolor: '#444444',
      textpagelinkfonthovercolor: '#222222',
      isMenuSticky: true,
      isLogoVisible: true,
    },
    11: {
      textpagelinkfontcolor: '#FFFFFF',
      textpagelinkfonthovercolor: '#202020',
      isMenuSticky: true,
      isLogoVisible: false,
    },
    12: {
      textpagelinkfontcolor: '#FFFFFF',
      textpagelinkfonthovercolor: '#808080',
      isMenuSticky: true,
      isLogoVisible: false,
    },
    13: {
      textpagelinkfontcolor: '#2080cd',
      textpagelinkfonthovercolor: '#1c6bad',
      isLogoVisible: false,
    },
    14: {
      textpagelinkfontcolor: '#2080cd',
      textpagelinkfonthovercolor: '#1c6bad',
      isLogoVisible: false,
    },
    15: {
      textpagelinkfontcolor: '#ffffff',
      textpagelinkfonthovercolor: '#747167',
      isLogoVisible: false,
    },
    16: {
      textpagelinkfontcolor: '#2080cd',
      textpagelinkfonthovercolor: '#1c6bad',
      isMenuSticky: true,
      isLogoVisible: false,
    },
    default: {
      textpagelinkfontcolor: '#2080cd',
      textpagelinkfonthovercolor: '#1c6bad',
      isLogoVisible: true,
    },
  };

  private ngUnsubscribe = new Subject();

  resetClick: Subject<any> = new Subject();
  websiteStylesReload: Subject<any> = new Subject();

  issues = new BehaviorSubject<CustomizerIssuesModel>({website: {}, element: {}});

  public menuReadySubject = new BehaviorSubject<boolean>(false);
  private menuReadySubscription: Subscription;

  public get menuSetup(): MenuSetupModel {
    return this.customMenuStylesService.setup;
  }

  public get menuDropdownArrowKeys() {
    return MENU_DROPDOWN_ARROW_KEYS;
  }

  constructor(
    private websiteDesignerService: WebsiteDesignerService,
    private stylesService: StylesService,
    private route: ActivatedRoute,
    private mathService: MathService,
    private stylesDimensionsService: StylesDimensionsService,
    private navigationService: NavigationService,
    private iFrameService: IFrameService,
    private iFrameMenuService: IFrameMenuService,
    private customMenuStylesService: CustomMenuStylesService,
    private iFrameRoutingService: IFrameRoutingService,
    private eventsService: EventsService,
    private buttonsService: ButtonsService,
    private websitesService: WebsitesService,
    private editorControlButtonsService: EditorControlButtonsService,
    private colorIntersectionService: ColorIntersectionService,
  ) {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.navigationService.isPageEditorSubject.subscribe((isPageEditor: boolean) => {
      if (isPageEditor) return;

      this.clearEditingObject();
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.clearEditingObject();

      if (!selectedPage) return;

      const { id, type, templateId } = selectedPage;

      if (!id || !type || !this.website) return;

      this.reloadPageDetails(this.website.id, templateId || this.website.templateId, id, type);
    });

    this.hasIssues()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.handleIssuesChanged.bind(this));
  }

  clearWasChangedFlags() {
    Object.keys(this.wasChanged).forEach(key => this.wasChanged[key] = false);
  }

  updateWasChangedFlags(property) {
    if (!Array.isArray(property)) {
      property = [property];
    }

    property.forEach(p => this.wasChanged[p] = true);
  }

  preparePageDetailsForSaving(websiteid, templateid, pageid, pagetype) {
    const fullPageType = `${this.FULL_PAGE_TYPE[pagetype]} page`;

    const websiteStyles = this.preparePageDefaultsForSaving(websiteid, templateid);

    const pageStyles = {};

    Object.keys(this.pageStyles).forEach(key => {
      if (this.specialFields.includes(key)) {
        return;
      }
      if (this.latestStyles.pageStyles[key] !== this.pageStyles[key]) {
        pageStyles[key] = this.pageStyles[key];
      }
    });

    return {
      websiteid,
      pageid,
      templateid,

      pageType: fullPageType,

      ...websiteStyles,
      ...pageStyles,
    };
  }

  preparePageDefaultsForSaving(websiteid, templateid) {
    const websiteStyles = {};

    Object.keys(this.websiteStyles).forEach(key => {
      if (this.specialFields.includes(key)) {
        return;
      }
      if (this.latestStyles.websiteStyles[key] !== this.websiteStyles[key]) {
        websiteStyles[key] = this.websiteStyles[key];
      }
    });

    return {
      websiteid,
      templateid,

      ...websiteStyles
    };
  }

  public saveDetails(websiteId, templateId, pageId, pageType, options = {}): Observable<any> {
    const detailsForSaving: any = { ...options };

    this.buttonsService.disableSaveButton();
    this.buttonsService.disableCancelButton();

    Object.keys(this.wasChanged).forEach(key => {
      switch (key) {
        case 'pageDetails':
          detailsForSaving.details = this.preparePageDetailsForSaving(websiteId, templateId, pageId, pageType);

          break;
        case 'pageDefaults':
          detailsForSaving.defaults = this.preparePageDefaultsForSaving(websiteId, templateId);

          detailsForSaving.defaults.menubarcolor = null;
          detailsForSaving.defaults.mobilebackgroundcolor = null;

          break;
      }
    });

    return this.websiteDesignerService.saveDetails(detailsForSaving).pipe(
      tap(() => {
        this.latestStyles.websiteStyles = Object.assign({}, this.latestStyles.websiteStyles, this.websiteStyles);
        this.latestStyles.pageStyles = Object.assign({}, this.latestStyles.pageStyles, this.pageStyles);
    
        this.clearWasChangedFlags();
      }),
    );
  }

  //  gets all styles of website (website, page, attributes)
  reloadPageDetails(websiteId, templateId, pageId, pageType) {
    this.toggleCustomizerLoading(true);
    this.buttonsService.disableSaveButton();
    this.buttonsService.disableCancelButton();

    return this.websiteDesignerService.getPageDetailsWithDefaults(websiteId, templateId, `${this.FULL_PAGE_TYPE[pageType]} page`, pageId).subscribe(data => {
      this.clearEditingObject();
      this.setDetails(data);
      this.toggleCustomizerLoading(false);
      this.websiteStylesReload.next(true);
  
      if (!this.menuReadySubscription) {
        this.menuReadySubscription = this.menuReadySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isMenuReady) => {
          if (!isMenuReady) return;
  
          this.initializeBlockOptions();
        });
      };
    });
  }

  setDetails({ defaults, details }) {
    this.websiteStyles = defaults;
    this.pageStyles = details;

    this.latestStyles = {
      websiteStyles: Object.assign({}, this.websiteStyles),
      pageStyles: Object.assign({}, this.pageStyles),
    };

    this.initColorIntersection();
  }

  public initColorIntersection() {
    const COLOR_MAPPING = {
      'backgroundcolor': COLOR_KEYS.COMMON_BACKGROUND,
      'pagefontcolor': COLOR_KEYS.COMMON_TEXT,
      'textpagelinkfontcolor': COLOR_KEYS.COMMON_LINK,
      'textpagelinkfonthovercolor': COLOR_KEYS.COMMON_LINK_HOVER,
    };

    Object.keys(COLOR_MAPPING).forEach(key => {
      if (!this.websiteStyles[key]) return;

      this.colorIntersectionService.initColor(COLOR_MAPPING[key], this.websiteStyles[key]);
    });

    this.colorIntersectionService.calculate();
  }

  parseStyleString(styleString) {
    return styleString.split(';').map(v => v.split(':').map(i => i.trim())).reduce((acc, curr) => {
      const [ styleName, styleValue ] = curr;
      if (styleName && styleValue) {
        acc[styleName] = styleValue;
      }
      return acc;
    }, {});
  }

  initializeBlockOptions() {
    this.applyStyle('isMenuSticky', this.websiteStyles.isMenuSticky);
  }

  getStyleName(detailName) {
    return _.get(this.DETAILS_PROPERTIES, `${detailName}.attributeStyle`, null);
  }

  changeWebsiteStyleProperty(propertyName) {
    const styleName = this.getStyleName(propertyName);
    const rawStyleValue = this.websiteStyles[propertyName];

    this.applyStyle(propertyName, rawStyleValue);
    this.setWebsiteStyle(propertyName, styleName, rawStyleValue);

    this.updateWasChangedFlags('pageDefaults');
  }

  setWebsiteStyle(key, styleName, value) {
    const details = this.DETAILS_PROPERTIES[key];

    if (!details.specificSelector || details.attributeStyle !== styleName) return;

    this.websiteStyles[key] = value;

    if (!details.linkedStyles) return;

    details.linkedStyles.forEach(styleKey => {
      this.setWebsiteStyle(styleKey, styleName, value);
      this.changeWebsiteStyleProperty(styleKey);
    });
  }

  clearPageStyles() {
    this.applyStyles(this.websiteStyles);
    this.writeStylesToElementsInFrame(this.convertDetailsToStyles(this.websiteStyles));
  }

  closeStylesOverridingMessage() {
    this.stylesOverridingMessageIsOpened = false;
  }

  clearEditingObject() {
    this.websiteDesignerService.setEditingObject(null);
  }

  deselectSelectedElement() {
    const frame = <any>document.getElementById('sandbox');
    const deselectElement = _.get(frame, 'contentWindow.clearLastEditing');

    if (deselectElement) {
      deselectElement();
    }

    this.clearEditingObject();
  }

  resetStyleFields(stylesState) {
    Object.keys(stylesState).forEach(key => {
      if (!this.specialFields.includes(key)) {
        stylesState[key] = this.getDefaultStyle(key);
      }
    });
  }

  public getDefaultStyle(key: string): any {
    const styles = this.WEBSITE_DEFAULTS_VALUES[this.website.templateId] || this.WEBSITE_DEFAULTS_VALUES.default;

    return styles[key] || null;
  }

  toggleCustomizerLoading(value) {
    this.customizerLoadingEnabled = {
      website: value,
      page: value,
      element: value,
    };
  }

  applyStyle(detail, value) {
    if (!this.DETAILS_PROPERTIES[detail]) return;

    const { attributeKey, attributeStyle, handlers, prepare, specificSelector, postHandlers, customizerHandlers } = this.DETAILS_PROPERTIES[detail];

    if (prepare) prepare(value);

    value = this.stylesDimensionsService.toStyleString(attributeStyle, value);

    const selector = specificSelector ? [specificSelector].join(',') : `[${attributeKey}]`;
    const $elements = $(this.iFrameService.getItems(`${selector}:not([data-not-editable-styles=${attributeStyle}])`));

    this.executeHandlers(handlers, $elements, value);

    if (!handlers || handlers.length === 0) $elements.css(attributeStyle, value);

    this.executeHandlers(postHandlers, $elements, value);
    this.executeHandlers(customizerHandlers, $elements, value);
  }

  executeHandlers(handlers, $elements, value) {
    if (handlers && handlers.length) {
      handlers.forEach(handle => handle($elements, value));
    }
  }

  resetStyles() {
    const elems = $(this.iFrameService.getItems('[style]'));

    elems.not('[data-style-important], [data-not-editable-container] *, [data-not-editable-container]:not([data-force-editable]) *, .editor-tool-panel, .inquire-modal-container, .portfolio-enlargement-wrapper').removeAttr(`style`);
  }

  public resetWebsiteStyles() {
    this.iFrameMenuService.resetStyles();

    this.deselectSelectedElement();
    this.resetStyles();
    this.resetStyleFields(this.websiteStyles);
    this.resetStyleFields(this.pageStyles);
    this.resetBlockOptions();

    this.editorControlButtonsService.onBtnClick(this.editorControlButtonsService.CONTROL_BUTTONS.SAVE, false);
    this.buttonsService.onClick(BUTTONS_KEYS.SAVE);

    this.buttonsService.disableSaveButton();
    this.buttonsService.disableCancelButton();

    if (this.website) {
      this.websiteDesignerService.resetBlocksStyles(this.website.templateId).subscribe(() => {});
    }

    this.websiteStylesReload.next(true);
  }

  private resetBlockOptions(): void {
    this.menuSetup.isLogoVisible.setValue(this.getDefaultStyle('isLogoVisible'));

    this.resetPortfolioThumbDrawerBackground();
  }

  private resetPortfolioThumbDrawerBackground(): void {
    Array.from(this.iFrameService.sandbox.querySelectorAll('.block.portfolio')).forEach((block: HTMLElement) => {
      block.style.removeProperty('--thumb-drawer-background-color');
      block.style.removeProperty('--thumb-drawer-background-color-rgb');

      const value: string = window.getComputedStyle(block).getPropertyValue('background-color');

      block.style.setProperty('--thumb-drawer-background-color', value);

      const colorModel: ColorModel = new ColorModel(value);

      block.style.setProperty('--thumb-drawer-background-color-rgb', colorModel.getRGBstring());

      block.setAttribute('data-thumb-drawer-background-set', 'false');
    });
  }

  resetPageStyles() {
    this.deselectSelectedElement();

    // TODO(max) => except menu parts, because menu styles are common for all pages and can't be changed in some concrete page
    this.resetStyles();

    this.resetStyleFields(this.pageStyles);
    this.clearPageStyles();

    // TODO(max) => add 'pageReset'
    // TODO(max) => only 'pageReset' when it will be ready
    this.updateWasChangedFlags([]);

    this.resetClick.next(true);
  }

  applyStyles(styles) {
    Object.keys(styles).forEach(name => {
      this.applyStyle(name, styles[name]);
    });
  }

  parseCssVariable(element, prefix, propertyName) {
    const styleNameWithoutPrefix = propertyName.substr(prefix.length);

    const styleValue = element[0].style.getPropertyValue(propertyName) || window.getComputedStyle(element[0]).getPropertyValue(propertyName);

    if (styleValue) return this.stylesDimensionsService.toStyleValue(styleNameWithoutPrefix, styleValue);

    return this.parseStyleProperty(element, styleNameWithoutPrefix);
  }

  parseStyleProperty(element, styleName) {
    let styleValue;

    switch (styleName) {
      case 'background-color':
        styleValue = element.css(styleName) || 'rgb(255, 255, 255)';
        break;
      case 'font-family':
        styleValue = this.getFontFamily(element.css(styleName));
        break;
      case 'font-weight':
        return;
      case 'text-decoration':
        styleValue = element.css(styleName).split(' ')[0] || 'none';
        break;
      case 'font-size':
      case 'letter-spacing':
      case 'word-spacing':
      case 'line-height':
      case 'padding-top':
      case 'padding-right':
      case 'padding-bottom':
      case 'padding-left':
        styleValue = Number.parseFloat(element[0].style.getPropertyValue(styleName) || window.getComputedStyle(element[0]).getPropertyValue(styleName));
        break;
      case 'link-color':
        styleValue = element.css('color');
        break;
      case 'border-style':
        return element.css(styleName).split(' ').find(elem => elem !== 'none') || 'none';
      case 'border-width':
        return Math.max(...element.css(styleName).split(' ').map(elem => Number.parseFloat(elem)));
      default:
        styleValue = element.css(styleName);
        break;
    }

    return styleValue;
  }

  getFontFamily(fontString) {
    const elementFonts = fontString.split(',').map((font) => font.replace(new RegExp('"', 'g'), '').trim());
    const fontFamily = elementFonts && elementFonts.length > 0 ? elementFonts[0] : null;
    if (!fontFamily) return fontFamily;
    return this.fontsMapping[fontFamily] ? this.fontsMapping[fontFamily] : fontFamily;
  }

  writeStylesToElementsInFrame(styles) {
    const { sandboxWindow } = this.iFrameService;

    if (!sandboxWindow) return;

    const applyStylesToAllEditableElements = (<any>sandboxWindow).applyStylesToAllEditableElements;

    if (!applyStylesToAllEditableElements) return;

    applyStylesToAllEditableElements(styles);
  }

  getCurrentCombinedDefaultsAndDetails() {
    const combined = {};

    function assignIfValueExists(dest, src, excludeFields) {
      Object.keys(src).forEach(key => {
        if (!excludeFields.includes(key) && src[key]) {
          dest[key] = src[key];
        }
      });
    }

    assignIfValueExists(combined, this.websiteStyles, this.specialFields);
    assignIfValueExists(combined, this.pageStyles, this.specialFields);

    return combined;
  }

  convertDetailsToStyles(details) {
    return Object.keys(details).reduce((styles, detailName) => {
      const styleName = this.getStyleName(detailName);
      let styleValue = details[detailName];

      const unit = _.get(this, `DETAILS_PROPERTIES.${detailName}.unit`);
      if (unit && styleValue && !styleValue.endsWith(unit)) {
        styleValue = styleValue + unit;
      }

      styles[styleName] = styleValue;
      return styles;
    }, {});
  }

  checkForIssues(issues): boolean {
    if (!issues) return false;
    return !!Object.keys(issues).find(key => issues[key]);
  }

  handleIssuesChanged(issues) {
    this.websiteDesignerService.hasSaveIssues = issues;
  }

  hasIssues(): Observable<boolean> {
    return this.issues.pipe(
      map(issues =>
        !!Object.keys(issues)
          .find(key => this.checkForIssues(issues[key]))
      ));
  }

  public saveIsMenuSticky(): void {
    if (this.websiteStyles.isMenuSticky) this.menuSetup.isSizeReducedOnScroll.setValue(true);

    this.saveOption('isMenuSticky');

    this.eventsService.dispatchLeftMenuClose(this.iFrameService.sandboxWindow);
    this.eventsService.dispatchRightMenuClose(this.iFrameService.sandboxWindow);
    this.eventsService.dispatchStickyMenuChanged(this.iFrameService.sandboxWindow);
  }

  private saveOption(name): void {
    this.changeWebsiteStyleProperty(name);

    this.buttonsService.enableSaveButton();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}



