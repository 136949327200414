import {Component, Input} from '@angular/core';

import {AppAnimations} from '../../../app-animations';

@Component({
  selector: 'app-vs-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class LoaderComponent {
  @Input() title: string;
  @Input() isLight: boolean;
  @Input() bgColor = 'rgba(204, 204, 204, 1)';
  @Input() mixBlendModeEnabled: boolean;
}
