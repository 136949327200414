import {PaymentIntentDto} from './payment-intent.dto';
import {PaymentModel} from './payment.model';
import {PaymentDto} from './payment.dto';

export class PaymentIntentModel {
  constructor(
    public hasMore?: boolean,
    public list?: PaymentModel[][],
  ) {}

  public static normalize(res: PaymentIntentDto): PaymentIntentModel {
    if (!res) return null;

    return {
      hasMore: res.hasMore,
      list: res.list.map((items: PaymentDto[]) => {
        return items.map((item: PaymentDto) => {
          return PaymentModel.normalize(item);
        });
      }),
    };
  }
}
