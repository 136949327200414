// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-component {
  height: 100%;
  background: #ccc;
}
.error-component p {
  padding-top: 120px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}
.error-component .error {
  padding-top: 20px;
  text-align: center;
}
.error-component .error h1 {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.error-component .goto-home {
  margin-top: 20px;
  text-align: center;
}
.error-component .goto-home a {
  text-transform: uppercase;
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/app/error/error.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AAEE;EACE,iBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,eAAA;EACA,iBAAA;EACA,yBAAA;AAAN;AAIE;EACE,gBAAA;EACA,kBAAA;AAFJ;AAII;EACE,yBAAA;EACA,WAAA;AAFN","sourcesContent":[".error-component {\n  height: 100%;\n  background: #ccc;\n\n  p {\n    padding-top: 120px;\n    font-size: 16px;\n    text-align: center;\n    text-transform: uppercase;\n  }\n\n  .error {\n    padding-top: 20px;\n    text-align: center;\n\n    h1 {\n      font-size: 20px;\n      font-weight: bold;\n      text-transform: uppercase;\n    }\n  }\n\n  .goto-home {\n    margin-top: 20px;\n    text-align: center;\n\n    a {\n      text-transform: uppercase;\n      color: #333;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
