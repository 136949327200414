import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ImageEditorCropService} from '../../../services/image-editor/crop/image-editor-crop.service';

import {ImageEditorImageUpdateModel} from '../../../models/image-editor/image-editor-crop.model';

import {AnchorKeys} from './constants';

@Component({
  selector: 'app-image-editor-crop',
  templateUrl: './image-editor-crop.component.html',
  styleUrls: ['./image-editor-crop.component.scss'],
})
export class ImageEditorCropComponent implements OnInit, OnDestroy {
  @Input() suggestion: ImageEditorImageUpdateModel;
  @Input() isSuggestionsMode: boolean = false;

  @Output() changeHandler: EventEmitter<ImageEditorImageUpdateModel> = new EventEmitter<ImageEditorImageUpdateModel>();

  public startX: number = 0;
  public startY: number = 0;
  public endX: number = 100;
  public endY: number = 100;

  public isBlockMove: boolean = false;

  private imageElementWidth: number;
  private imageElementHeight: number;

  private initialX: number;
  private initialY: number;

  private minOffsetX: number;
  private minOffsetY: number;

  private currentAnchor: AnchorKeys;

  private rect: any;

  private anchorMouseMoveHandlers: { [key: string]: Function } = {
    'leftTop': this.onLeftTopAnchorMove.bind(this),
    'rightTop': this.onRightTopAnchorMove.bind(this),
    'rightBottom': this.onRightBottomAnchorMove.bind(this),
    'leftBottom': this.onLeftBottomAnchorMove.bind(this),
    'centerTop': this.onCenterTopAnchorMove.bind(this),
    'centerRight': this.onCenterRightAnchorMove.bind(this),
    'centerBottom': this.onCenterBottomAnchorMove.bind(this),
    'centerLeft': this.onCenterLeftAnchorMove.bind(this),
  };

  private handlers: { [key: string]: any } = {
    'resize': this.onResize.bind(this),
    'mouseup': this.onMouseUp.bind(this),

    anchor: {
      'mousemove': this.onAnchorMouseMove.bind(this),
    },

    block: {
      'mousemove': this.onBlockMouseMove.bind(this),
      'mouseup': this.onBlockMouseUp.bind(this),
    },
  };

  private coordsInit: { [key: string]: Function[] } = {
    'leftTop': [
      this.initStartX.bind(this),
      this.initStartY.bind(this),
    ],
    'rightTop': [
      this.initEndX.bind(this),
      this.initStartY.bind(this),
    ],
    'rightBottom': [
      this.initEndX.bind(this),
      this.initEndY.bind(this),
    ],
    'leftBottom': [
      this.initStartX.bind(this),
      this.initEndY.bind(this),
    ],
    'centerTop': [
      this.initStartY.bind(this),
    ],
    'centerRight': [
      this.initEndX.bind(this),
    ],
    'centerBottom': [
      this.initEndY.bind(this),
    ],
    'centerLeft': [
      this.initStartX.bind(this),
    ],
  }

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    public service: ImageEditorCropService,
    private rootElement: ElementRef,
  ) {
  }

  public ngOnInit(): void {
    this.onResize();

    this.notifyAboutChange();

    this.service.resetSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.startX = 0;
      this.startY = 0;
      this.endX = 100;
      this.endY = 100;

      this.onResize();

      this.notifyAboutChange();
    });

    window.addEventListener('resize', this.handlers['resize']);
  }

  private onResize(): void {
    this.rect = this.rootElement.nativeElement.getBoundingClientRect();

    this.imageElementWidth = this.rootElement.nativeElement.clientWidth;
    this.imageElementHeight = this.rootElement.nativeElement.clientHeight;

    this.minOffsetX = (25 / this.imageElementWidth) * 100;
    this.minOffsetY = (25 / this.imageElementHeight) * 100;
  }

  public onAnchorMouseDown(key: AnchorKeys): void {
    if (this.isSuggestionsMode) {
      return;
    }

    this.currentAnchor = key;

    this.service.cursorSubject.next('grab');

    this.removeListeners();
    this.addAnchorListeners();
  }

  public onAnchorMouseMove(e: MouseEvent): void {
    if (this.isSuggestionsMode || !this.anchorMouseMoveHandlers[this.currentAnchor]) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.anchorMouseMoveHandlers[this.currentAnchor](e);

    this.coordsInit[this.currentAnchor].forEach((func: Function) => func());
  }

  public onBlockMouseDown(e: MouseEvent): void {
    if (this.isSuggestionsMode) {
      return;
    }

    const target: HTMLElement = <HTMLElement>e.target;

    if (!target.matches('.crop-wrapper')) {
      return;
    }

    this.isBlockMove = true;

    this.initialX = e.clientX;
    this.initialY = e.clientY;

    this.service.cursorSubject.next('move');

    this.removeListeners();
    this.addBlockListeners();
  }

  public onBlockMouseMove(e: MouseEvent): void {
    if (this.isSuggestionsMode) {
      return;
    }
    
    e.preventDefault();
    e.stopPropagation();

    const offsetX: number = this.getOffsetX(e);
    const offsetY: number = this.getOffsetY(e);

    this.startX -= offsetX;
    this.startY -= offsetY;
    this.endX -= offsetX;
    this.endY -= offsetY;

    this.initialX = e.clientX;
    this.initialY = e.clientY;
  }

  public onBlockMouseUp(): void {
    this.isBlockMove = false;
  }

  private getOffsetX(e: MouseEvent): number {
    const offsetX: number = (this.initialX - e.clientX) / this.imageElementWidth * 100;

    if (this.startX - offsetX < 0) {
      return this.startX;
    }

    return this.endX - offsetX > 100 ? this.endX - 100 : offsetX;
  }

  private getOffsetY(e: MouseEvent): number {
    const offsetY: number = (this.initialY - e.clientY) / this.imageElementHeight * 100;

    if (this.startY - offsetY < 0) {
      return this.startY;
    }

    return this.endY - offsetY > 100 ? this.endY - 100 : offsetY;
  }

  private onLeftTopAnchorMove(e: MouseEvent): void {
    this.startX = this.calculateX(e);
    this.startY = this.calculateY(e);
  }

  private onRightTopAnchorMove(e: MouseEvent): void {
    this.endX = this.calculateX(e);
    this.startY = this.calculateY(e);
  }

  private onRightBottomAnchorMove(e: MouseEvent): void {
    this.endX = this.calculateX(e);
    this.endY = this.calculateY(e);
  }

  private onLeftBottomAnchorMove(e: MouseEvent): void {
    this.startX = this.calculateX(e);
    this.endY = this.calculateY(e);
  }

  private onCenterTopAnchorMove(e: MouseEvent): void {
    this.startY = this.calculateY(e);
  }

  private onCenterRightAnchorMove(e: MouseEvent): void {
    this.endX = this.calculateX(e);
  }

  private onCenterBottomAnchorMove(e: MouseEvent): void {
    this.endY = this.calculateY(e);
  }

  private onCenterLeftAnchorMove(e: MouseEvent): void {
    this.startX = this.calculateX(e);
  }

  private calculateX(e: MouseEvent): number {
    return (e.clientX - this.rect.x) / this.imageElementWidth * 100;
  }

  private calculateY(e: MouseEvent): number {
    return (e.clientY - this.rect.y) / this.imageElementHeight * 100;
  }

  private onMouseUp(): void {
    this.removeListeners();

    this.service.cursorSubject.next('default');

    this.notifyAboutChange();
  }
  
  private notifyAboutChange(): void {
    this.changeHandler.emit(
      new ImageEditorImageUpdateModel(
        null,
        null,
        this.startX,
        this.startY,
        this.endX,
        this.endY
      )
    );
  }

  private initStartX(): void {
    if (this.startX < 0) {
      this.startX = 0;
    }

    if (this.startX + this.minOffsetX > this.endX) {
      this.startX = this.endX - this.minOffsetX;
    }

    if (this.startX + this.minOffsetX > 100) {
      this.startX = 100 - this.minOffsetX;
    }
  }

  private initStartY(): void {
    if (this.startY < 0) {
      this.startY = 0;
    }

    if (this.startY + this.minOffsetY > this.endY) {
      this.startY = this.endY - this.minOffsetY;
    }

    if (this.startY + this.minOffsetY > 100) {
      this.startY = 100 - this.minOffsetY;
    }
  }

  private initEndX(): void {
    if (this.endX < this.minOffsetX) {
      this.endX = this.minOffsetX;
    }

    if (this.endX > 100) {
      this.endX = 100;
    }

    if (this.endX - this.startX < this.minOffsetX) {
      this.endX = this.startX + this.minOffsetX;
    }
  }

  private initEndY(): void {
    if (this.endY < this.minOffsetY) {
      this.endY = this.minOffsetY;
    }

    if (this.endY > 100) {
      this.endY = 100;
    }

    if (this.endY - this.startY < this.minOffsetY) {
      this.endY = this.startY + this.minOffsetY;
    }
  }

  private removeListeners(): void {
    window.removeEventListener('mousemove', this.handlers.anchor['mousemove']);
    window.removeEventListener('mousemove', this.handlers.block['mousemove']);
    window.removeEventListener('mouseup', this.handlers['mouseup']);
  }

  private addAnchorListeners(): void {
    window.addEventListener('mousemove', this.handlers.anchor['mousemove']);
    window.addEventListener('mouseup', this.handlers['mouseup']);
  }

  private addBlockListeners(): void {
    window.addEventListener('mousemove', this.handlers.block['mousemove']);
    window.addEventListener('mouseup', this.handlers['mouseup']);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('resize', this.handlers['resize']);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
