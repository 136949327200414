
import {distinctUntilChanged} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription, Observable} from 'rxjs';

import {ImagesHttpService} from '../../interaction/http/images/images-http.service';
import {MeasureUnitsService} from '../../converters/measure-units/measure-units.service';
import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';
import {AuthService} from '../../../../auth/auth.service';

import {ImageModel} from '../../../models/images/image.model';
import {MeasureUnitModel} from '../../../models/measure-unit/measure-unit.model';

@Injectable()
export class ImageDetailsService {
  private measureUnit: MeasureUnitModel = null;

  public imageDetailsSubject: BehaviorSubject<ImageModel> = new BehaviorSubject<ImageModel>(null);

  constructor(private httpService: ImagesHttpService,
              private authService: AuthService,
              private imageManagerService: ImageManagerService,
              private measureUnitsService: MeasureUnitsService) {
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this.measureUnitsService.websiteMeasureUnitSubject.subscribe((measureUnit: MeasureUnitModel) => this.measureUnit = measureUnit);

    this.imageManagerService.onImageSelectSubject.pipe(distinctUntilChanged()).subscribe((image: ImageModel) => {
      this.handleImageDetailsFetch(image);
    });
  }

  public fetchImageDetails(imageId: number): Subscription {
    return this.httpService.fetchImageDetails(imageId).subscribe((image: ImageModel) => {
      this.handleImageDetailsFetch(image);
    });
  }

  public setIsPublishedState(isPublished: boolean): void {
    const imageData: ImageModel = this.imageDetailsSubject.value;

    if (imageData) {
      imageData.isPublished = isPublished;
    }

    this.imageDetailsSubject.next(imageData);
  }

  private handleImageDetailsFetch(image: ImageModel) {
    if (!this.measureUnit) return;

    this.imageDetailsSubject.next(image);
  }
}
