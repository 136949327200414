import {Component, OnInit, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {MultiWebsiteService} from '../../services/multi-website/multi-website.service';
import {NavigationService} from '../../../services/navigation.service';
import {AuthService} from '../../../auth/auth.service';
import {WebsitesService} from '../../services/websites/websites.service';
import {EducationTeachersWebsitesService} from '../../services/education/teachers/websites/education-teachers-websites.service';
import {WebsitesLimitExceededModalService} from '../../../shared/services/modals/websites-limit-exceeded/websites-limit-exceeded-modal.service';
import {PermissionsService} from '../../services/service-permissions/permissions/permissions.service';

import {WebsiteModel} from '../../models/websites/website.model';
import {CourseWebsiteModel} from '../../models/websites/course-website.model';
import {AccountModel} from '../../models/accounts/account.model';
import {IPermissionData} from '../../models/permission/i-permission-data';

import {PERMISSIONS} from '../../services/service-permissions/constants';

@Component({
  selector: 'app-websites-manager',
  templateUrl: 'websites-manager.component.html',
  styleUrls: ['./websites-manager.component.scss']
})
export class WebsitesManagerComponent implements OnInit, OnDestroy {
  public account: AccountModel = null;

  public websites: WebsiteModel[] = null;
  public educatorWebsites: CourseWebsiteModel[] = null;

  public activeWebsite: WebsiteModel = null;

  public isNewWebsiteAdding: boolean = false;
  
  public isAddWebsiteButtonAccessAllowed: boolean = false;
  public isWebsitesManagerAccessAllowed: boolean = false;
  public isEducatorClassesWebsitesListAccessAllowed: boolean = false;

  private isBlocked: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private get isLimitExceeded(): boolean {
    if (!this.account || !this.websites || this.account.isAdmin) return false;

    return this.websites.length >= this.account.limits.websites;
  }

  constructor(private service: MultiWebsiteService,
              private websitesService: WebsitesService,
              private educationTeachersWebsitesService: EducationTeachersWebsitesService,
              private websitesLimitExceededModalService: WebsitesLimitExceededModalService,
              private authService: AuthService,
              private permissionsService: PermissionsService,
              private navigationService: NavigationService) {}

  public ngOnInit(): void {
    this.service.websitesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: WebsiteModel[]) => {
      this.websites = websites;
    });
    
    this.educationTeachersWebsitesService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: CourseWebsiteModel[]) => {
      this.educatorWebsites = websites;
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.activeWebsite = website;
    });

    this.service.isNewWebsiteAddingSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isNewWebsiteAdding: boolean) => {
      this.isNewWebsiteAdding = isNewWebsiteAdding;
    });

    this.initPermissions();
  }

  private initPermissions(): void {
    const addWebsiteButtonPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.ADD_WEBSITE_BUTTON,
    };

    const websitesManagerPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.MULTI_WEBSITE_ADMIN_PANEL,
    };

    const educatorClassesWebsitesListPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.EDUCATOR_CLASSES_WEBSITES_LIST,
    };

    this.permissionsService.isUserHasPermissionsObservable([addWebsiteButtonPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isAddWebsiteButtonAccessAllowed = isAllowed;
    });

    this.permissionsService.isUserHasPermissionsObservable([websitesManagerPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isWebsitesManagerAccessAllowed = isAllowed;
    });

    this.permissionsService.isUserHasPermissionsObservable([educatorClassesWebsitesListPermission], { isForbiddenForAdmins: true }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllowed: boolean) => {
      this.isEducatorClassesWebsitesListAccessAllowed = isAllowed;
    });
  }

  public setActiveWebsite(websiteId: number): void {
    if (this.isBlocked) return;

    this.isBlocked = true;

    const isActive = this.activeWebsite && this.activeWebsite.id === websiteId;

    if (isActive) {
      this.navigationService.openWebsiteEditor(websiteId).add(() => {
        this.isBlocked = false;
      });

      return;
    }

    this.service.setActiveWebsite(websiteId).add(() => {
      this.websitesService.activeWebsiteIdSubject.pipe(take(1)).subscribe((activeWebsiteId: number) => {
        this.navigationService.openWebsiteEditor(activeWebsiteId).add(() => {
          this.isBlocked = false;
        });
      });
    });
  }

  public openWebsite(website: WebsiteModel): void {
    if (website.isBlocked || !website.isEverPublished) return;

    if (website.url) {
      window.open(website.url, '_blank');

      return;
    }

    website.isBlocked = true;

    this.websitesService.getWebsiteLink(website.id).subscribe((url: string) => {
      website.url = url;

      window.open(url, '_blank');

      website.isBlocked = false;
    });
  }

  public onNewWebsiteAdd(): void {
    if (this.isLimitExceeded) {
      return this.websitesLimitExceededModalService.open();
    }

    this.service.isNewWebsiteAddingSubject.next(true);
  }

  public onNewWebsiteAddingCompleted(website: WebsiteModel): void {
    this.service.initList();

    this.authService.updateCurrentUser(true).add(() => {
      if (!website) {
        this.service.isNewWebsiteAddingSubject.next(false);

        this.service.hideLoader();

        return;
      }

      const unsubscribe: Subject<boolean> = new Subject<boolean>();

      this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((activeWebsite: WebsiteModel) => {
        if (!activeWebsite || activeWebsite.id !== website.id) {
          return;
        }

        unsubscribe.next(true);
        unsubscribe.complete();

        this.service.isNewWebsiteAddingSubject.next(false);

        this.service.hideLoader();
      });
    });
  }

  public onNewWebsiteAddingCancel(): void {
    this.service.isNewWebsiteAddingSubject.next(false);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
