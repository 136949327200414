import {SelectOption} from '../../../select/option/option.model';

export const ATTRIBUTE = 'data-portfolio-masonry-type';

export const KEYS = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};

export const OPTIONS: SelectOption[] = [
  new SelectOption('Horizontal', KEYS.HORIZONTAL),
  new SelectOption('Vertical', KEYS.VERTICAL),
];

export const DEFAULT_KEY = KEYS.HORIZONTAL;
export const DEFAULT_OPTION: SelectOption = OPTIONS[0];
