export const TIME_FORMATTER = 'YYYY-MM-DD h:mmA';

export const COUPON_TYPES = {
  GENERAL: 'GENERAL',
  UNIQUE: 'UNIQUE',
  DROPDOWN: 'DROPDOWN',
};

export const COUPON_APPLIED_TO = {
  FIRST_ANNUAL_SUBSCRIPTION: 'FIRST_ANNUAL_SUBSCRIPTION',
  FIRST_MONTH_SUBSCRIPTION: 'FIRST_MONTH_SUBSCRIPTION',
  ANY_PAYMENT: 'ANY_PAYMENT',
};

export const DISCOUNT_TYPES = {
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
};
