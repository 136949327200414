export class PriceAmountModel {
  constructor(public amount?: number,
              public roundAmount?: number,
              public centsAmount?: number,
              public formattedAmount?: string) {
    this.roundAmount = Math.floor(this.amount);
    this.centsAmount = Number((this.amount * 100).toFixed()) % 100;
    this.formattedAmount = `$${this.roundAmount}.${`${this.centsAmount}`.padStart(2, '0')}`;
  }
}
