import { ImageModel } from "../../../core/models/images/image.model";

const IMAGE_SIZE = {
  MEDIA_LARGE: 'medialarge',
  EXTRA_LARGE: 'extralarge',
  FIXED_HEIGHT_THUMB: 'fixedheightthumb',
  ICON: 'icon',
  LARGE: 'large',
  MEDIA_SMALL: 'mediasmall',
  MEDIA_MEDIUM: 'mediamedium',
  MEDIA_X_LARGE: 'mediaxlarge',
  MEDIUM: 'medium',
  MEDIUM_LARGE: 'mediumlarge',
  MEDIUM_LARGER: 'mediumlarger',
  MEDIUM_SMALL: 'mediumsmall',
  MICRO_THUMB: 'microthumb',
  THUMB: 'thumb'
};

const SRC_SET_SIZES = {
  'mediumlarge': 360,
  'mediumlarger': 525,
  'large': 675,
  'extralarge': 900,
  'medialarge': 1440,
};

const IMAGE_SERVER = 'https:/';

function getSrcSet(image: ImageModel) {
  if (!image) return '';

  const srcset = Object.keys(SRC_SET_SIZES).map(key => {
    return `https://${addSizePrefix(image.paths.default, key)} ${SRC_SET_SIZES[key]}w`;
  }).join(',');

  return srcset + `, https://${image.paths.default}`;
}

function addSizePrefix(url: string, size: string): string {
  const reg = /\.\w+$/;
  const ext = url.match(reg)[0];
  
  return url.replace(reg, `_${size}${ext}`);
}

export {
  IMAGE_SIZE,
  addSizePrefix,
  getSrcSet,
};
