import {Injectable} from '@angular/core';

import {BehaviorSubject, Subscription, catchError, throwError} from 'rxjs';

import {CartHttpService} from '../interaction/http/cart/cart-http.service';

import {ICartItem} from '../../models/cart/item/cart-item.model';
import {PlanCartItemDto} from '../../models/cart/item/plan/plan-cart-item.dto';
import {PlanCartItemModel} from '../../models/cart/item/plan/plan-cart-item.model';
import {AddOnCartItemDto} from '../../models/cart/item/plan/add-on-cart-item.dto';
import {AddOnCartItemModel} from '../../models/cart/item/plan/add-on-cart-item.model';

import {PRODUCT_TYPES} from './constants';

@Injectable()
export class CartService {
  public cartSubject: BehaviorSubject<ICartItem[]> = new BehaviorSubject<ICartItem[]>([]);

  private cartItemHandlers = {
    [PRODUCT_TYPES.PLAN]: this.mapPlan.bind(this),
    [PRODUCT_TYPES.ADD_ON]: this.mapAddOn.bind(this),
    [PRODUCT_TYPES.INCLUDED_ADD_ON]: this.mapAddOn.bind(this),
  };

  constructor(private http: CartHttpService) {
  }

  public fetch(): Subscription {
    return this.http.fetch().pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      })
    ).subscribe((res: ICartItem[]) => {
      this.cartSubject.next(res.map((item: ICartItem) => {
        return this.cartItemHandlers[item.type] ? this.cartItemHandlers[item.type](item) : item;
      }));
    });
  }

  private mapAddOn(dto: AddOnCartItemDto): AddOnCartItemModel {
    return AddOnCartItemModel.normalize(dto);
  }

  private mapPlan(dto: PlanCartItemDto): PlanCartItemModel {
    return PlanCartItemModel.normalize(dto);
  }

  public addItem(item: ICartItem): Subscription {
    return this.http.addItem(item).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.fetch();
    });
  }

  public deleteItem(item: ICartItem): Subscription {
    return this.http.deleteItem(item).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.fetch();
    });
  }
}
