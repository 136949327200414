import {BaseScalableModel} from '../../base/scalable/base-scalable.model';

import {PROPERTY_NAMES, SCROLLED_PROPERTY_NAME, SCROLLED_PROPERTY_NAME_PX} from './constants';
import {PROPERTY_NAMES as FONT_SIZE_PROPERTY_NAMES, SCROLLED_PROPERTY_NAME as FONT_SIZE_SCROLLED_PROPERTY_NAME} from '../scalable-font-size/constants';
import {KEYS as MOBILE_KEYS} from '../../../../../services/styles/subtitle/mobile/constants';

export class WebsiteSubtitleScalableLineHeightModel extends BaseScalableModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAMES[key].OPTION, PROPERTY_NAMES[key].OPTION_PX, value, isEnabled);
  }

  public onChange(value: number): void {
    super.onChange(value);

    const fontSize: number = Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(FONT_SIZE_PROPERTY_NAMES[MOBILE_KEYS.SCALABLE_MOBILE_FONT_SIZE].OPTION));
    const scrolledFontSize: number = Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(FONT_SIZE_SCROLLED_PROPERTY_NAME));

    const scrolledLineHeight: number = this.value / (fontSize / scrolledFontSize);

    if (Number.isNaN(scrolledLineHeight)) return;

    this.element.style.setProperty(SCROLLED_PROPERTY_NAME, `${scrolledLineHeight}`);
    this.element.style.setProperty(SCROLLED_PROPERTY_NAME_PX, `${scrolledLineHeight}px`);
  }
}
