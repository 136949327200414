import {Injectable} from '@angular/core';

import {BehaviorSubject, catchError, finalize, throwError} from 'rxjs';

import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';
import {ImageManagerModalService} from '../../../../shared/components/modals/image-manager-modal/image-manager-modal.service';
import {AuthService} from '../../../../auth/auth.service';
import {WebsitesService} from '../../websites/websites.service';

import { ImageModel } from '../../../models/images/image.model';

@Injectable()
export class ImageManagerModalFetchService {
  public imagesSubject: BehaviorSubject<ImageModel[]> = new BehaviorSubject<ImageModel[]>([]);
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private activePortfolioId: number;
  private fetchedForPortfolioId: number;

  public get images(): any[] {
    return this.imagesSubject.value;
  }

  public get isLibrary(): boolean {
    return this.activePortfolioId === 0;
  }

  constructor(private imageManager: ImageManagerService,
              private authService: AuthService,
              private websitesService: WebsitesService,
              public imageManagerModalService: ImageManagerModalService) {
    this.authService.onSignOut.subscribe(() => {
      this.isLoadingSubject.next(true);
      this.imagesSubject.next([]);
    });

    this.imageManagerModalService.onPortfolioSelect.subscribe(({ id }) => {
      this.activePortfolioId = id;

      this.loadImages(id);
    });

    this.websitesService.activeWebsiteSubject.subscribe(() => {
      this.imagesSubject.next([]);
    });
  }

  public loadImages(portfolioId: number) {
    if (portfolioId === null) {
      return this.setImages(portfolioId, null);
    }

    if (portfolioId === this.fetchedForPortfolioId) {
      return;
    }

    this.isLoadingSubject.next(true);

    const handleError = e => {
      console.error(e);

      return throwError(() => e);
    };

    const handleFinal = () => {
      this.isLoadingSubject.next(false);
    };

    const handleSuccess = (images: ImageModel[]) => {
      if (portfolioId !== this.activePortfolioId) {
        return;
      }

      this.setImages(portfolioId, images);
    };

    if (this.isLibrary) {
      return this.imageManager.getLibraryImages().pipe(
        catchError(handleError),
        finalize(handleFinal),
      ).subscribe(handleSuccess);
    }

    return this.imageManager.getPortfolioImages(null, portfolioId).pipe(
      catchError(handleError),
      finalize(handleFinal),
    ).subscribe(handleSuccess);
  }

  private setImages(portfolioId: number, images: ImageModel[]) {
    if (portfolioId === this.fetchedForPortfolioId) {
      return;
    }

    this.fetchedForPortfolioId = portfolioId;

    this.imagesSubject.next(images || []);

    if (!!images && images.length === 0) {
      this.isLoadingSubject.next(false);
    }
  }

  public onModalClose(): void {
    this.fetchedForPortfolioId = null;
    
    this.imagesSubject.next([]);

    this.isLoadingSubject.next(true);
  }
}
