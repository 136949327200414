import {AccountModel} from '../../accounts/account.model';
import {ImageUpdateSuggestionModel} from '../../education/image-update-suggestion/image-update-suggestion.model';
import {StudentImageReviewAudioModel} from './audio/student-image-review-audio.model';

export class StudentImageReviewModel {
  public isEmpty: boolean = false;
  public isNewUpdateSuggestion: boolean = false;
  
  public isRated: boolean = false;
  public isCommented: boolean = false;
  public hasAudio: boolean = false;
  public hasUpdateSuggestion: boolean = false;
  
  constructor(
    public id: number,
    public imageId: number,
    public reviewedByUserId: number,
    public reviewedByUser: AccountModel,
    public rate: number,
    public text: string,
    public type: string,
    public audios: StudentImageReviewAudioModel[] = [],
    public imageUpdateSuggestions: ImageUpdateSuggestionModel[] = [],
    public createdAt?: string,
    public updatedAt?: string,
    public lastViewedByOwnerAt?: string,
  ) {
    this.isNewUpdateSuggestion = this.imageUpdateSuggestions && this.imageUpdateSuggestions.some((suggestion: ImageUpdateSuggestionModel) => {
      return suggestion.isNew;
    });
  }

  public static clone(item: StudentImageReviewModel): StudentImageReviewModel {
    if (!item) return null;

    const newReview: StudentImageReviewModel = new StudentImageReviewModel(
      item.id,
      item.imageId,
      item.reviewedByUserId,
      item.reviewedByUser,
      item.rate,
      item.text,
      item.type,
      item.audios ? item.audios.map((audio: StudentImageReviewAudioModel) => StudentImageReviewAudioModel.clone(audio)) : [],
      item.imageUpdateSuggestions ? item.imageUpdateSuggestions.map((crop: ImageUpdateSuggestionModel) => ImageUpdateSuggestionModel.clone(crop)) : [],
      item.createdAt,
      item.updatedAt,
      item.lastViewedByOwnerAt,
    );

    newReview.isEmpty = item.isEmpty;

    return newReview;
  }
}
