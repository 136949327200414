// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.private-page-password-input-modal-body {
  padding: 15px;
}
.private-page-password-input-modal-body .tip {
  color: #eee;
}
.private-page-password-input-modal-body .error-text {
  color: #ec392e;
}
.private-page-password-input-modal-body .form-group-sm {
  width: 100%;
}
.private-page-password-input-modal-body .form-group-sm input {
  width: 100%;
  margin: 10px 0;
  border-radius: 0;
}
.private-page-password-input-modal-body .form-group-sm input.error {
  color: #ec392e;
  border-color: #ec392e;
  background: #fff3f4;
}
.private-page-password-input-modal-body .form-group-sm input.error::placeholder {
  color: #ec392e;
}
.private-page-password-input-modal-body .btn-bar {
  height: 40px;
  float: right;
}
.private-page-password-input-modal-body .btn-bar .btn {
  border-radius: 0;
  border: 1px solid #333;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/private-page-password-input-modal/private-page-password-input-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAGE;EACE,WAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,WAAA;AAHJ;AAKI;EACE,WAAA;EACA,cAAA;EACA,gBAAA;AAHN;AAKM;EACE,cAAA;EACA,qBAAA;EACA,mBAAA;AAHR;AAKQ;EACE,cAAA;AAHV;AASE;EACE,YAAA;EACA,YAAA;AAPJ;AASI;EACE,gBAAA;EACA,sBAAA;EACA,iBAAA;AAPN","sourcesContent":["\n\n.private-page-password-input-modal-body {\n  padding: 15px;\n\n  .tip {\n    color: #eee;\n  }\n\n  .error-text {\n    color: #ec392e;\n  }\n\n  .form-group-sm {\n    width: 100%;\n\n    input {\n      width: 100%;\n      margin: 10px 0;\n      border-radius: 0;\n\n      &.error {\n        color: #ec392e;\n        border-color: #ec392e;\n        background: #fff3f4;\n\n        &::placeholder {\n          color: #ec392e;\n        }\n      }\n    }\n  }\n\n  .btn-bar {\n    height: 40px;\n    float: right;\n\n    .btn {\n      border-radius: 0;\n      border: 1px solid #333;\n      margin-left: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
