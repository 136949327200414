import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {AuthService} from '../../../auth/auth.service';
import {ModalsService} from '../../../shared/services/modals/modals.service';
import {AppSettingsService} from '../app-settings/app-settings.service';

import {AccountModel} from '../../models/accounts/account.model';
import {AppSettingsModel} from '../../models/app-settings/app-settings.model';
import {AppVersionChangesSummaryItemDto} from '../../models/app-version-changes-summary-item/app-version-changes-summary-item.dto';
import {AppVersionChangesSummaryItemModel} from '../../models/app-version-changes-summary-item/app-version-changes-summary-item.model';

@Injectable()
export class AppVersionChangesService {
  public id = 'app-version-changes';
  public summaryModalId = 'app-version-changes-summary';

  public account: AccountModel;
  public settings: AppSettingsModel;

  public version: string = '';

  public changes: string = '';
  public changeList: string[] = [];

  constructor(private http: HttpClient,
              private authService: AuthService,
              private modalsService: ModalsService,
              private appSettingsService: AppSettingsService) {
    this.authService.accountSubject.subscribe((account: AccountModel) => {
      this.account = account;

      this.init();
    });

    this.appSettingsService.settingsSubject.subscribe((settings: AppSettingsModel) => {
      this.settings = settings;
      this.version = settings && settings.version;

      if (this.settings && this.settings.versionChanges) {
        this.changes = this.settings.versionChanges.changes;
        this.changeList = this.changes.split(';');
      }

      this.init();
    });
  }

  private init() {
    if (!this.account || this.account.isUnderAdmin || !this.settings || !this.account.isNewVersion) return this.modalsService.close(this.id);

    const isAnyChanges = this.settings.versionChanges && this.settings.versionChanges.changes;

    if (!isAnyChanges) return;

    this.showModal();
  }

  public showModal() {
    this.modalsService.open(this.id);
  }

  public showSummaryModal() {
    this.modalsService.open(this.summaryModalId);
  }

  public loadSummary(): Observable<AppVersionChangesSummaryItemModel[]> {
    return this.http.get(`/api/app-settings/summary`).pipe(
      map((res: AppVersionChangesSummaryItemDto[]) => res.map(item => AppVersionChangesSummaryItemDto.normalize(item)))
    );
  }

  public loadTextForSummary(id: number): Observable<string> {
    return this.http.get(`/api/app-settings/summary/${id}/text`, { responseType: 'text' });
  }
}
