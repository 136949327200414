export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `ఆ రాత్రి మొదటిసారిగా ఒక నక్షత్రం నేలరాలింది.`,
      `ఆకాశం నిర్మలంగా ముదురు నీలంరంగులో ఉంది.`,
      `ఒక ఎర్రని కాంతి వంకర రెక్క అంచుని ఛాయగా ప్రతిబింబించింది`,
    ],
    [KEYS.ALPHABET]: [
      'అఆఇఈఉఊఋఎఏఐఒఓఔఅంఅఃకఖగఘజ్ఞచఛజఝఞటఠడఢణతథదధనపఫబభమయరలవళశషసహఱ',
    ],
    [KEYS.PARAGRAPH]: [
      `ఏదేమైనా నేను అంగారక గ్రహాన్ని చూస్తాను, ఆ అనుభవం ఎంతో అరుదైనది. నాకు తెలిసినంత వరకు సూక్ష్మమైన టెలిస్కోప్ ద్వారా ఆకాశంలోని వాటిని వీక్షించడం, అలాగే ప్రపంచవ్యాప్తంగా పర్యటించడం వంటివి విస్తృత స్థాయి విద్యలో భాగం కావాలి.`
    ],
    [KEYS.NUMERALS]: [
      '౦౧౨౩౪౫౬౭౮౯',
    ],
  };
};
