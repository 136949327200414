import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LoaderService {
  public isLoadedSubject = new BehaviorSubject<boolean>(true);

  private list: Map<string, boolean> = new Map<string, boolean>();

  constructor() {}

  public show(key: string): void {
    this.list.set(key, true);

    this.isLoadedSubject.next(false);
  }

  public hide(key: string): void {
    this.list.delete(key);

    if (this.list.size > 0) return;

    this.isLoadedSubject.next(true);
  }
}
