export const KEYS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
};

export const CLASSES = {
  [KEYS.LEFT]: 'image-caption-position-left',
  [KEYS.CENTER]: 'image-caption-position-center',
  [KEYS.RIGHT]: 'image-caption-position-right',
};

export const CLASSES_LIST = Object.keys(CLASSES).map(key => CLASSES[key]);

export const CLASSES_KEYS = Object.keys(CLASSES).reduce((res, key) => {
  res[CLASSES[key]] = key;
  return res;
}, {});

export const OPTIONS = [
  {
    key: KEYS.LEFT,
    text: 'Left',
  },
  {
    key: KEYS.CENTER,
    text: 'Center',
  },
  {
    key: KEYS.RIGHT,
    text: 'Right',
  },
];

export const DEFAULT_CLASS_NAME = CLASSES[KEYS.LEFT];
export const DEFAULT_OPTION = OPTIONS[0];
