import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class HrefModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.value = this.element.getAttribute(ATTRIBUTES.HREF);
  }

  public onChange(value: string) {
    if (!value) {
      this.value = value;

      return;
    }

    this.value = value.startsWith('http') ? value : `http://${value}`;
  }
}
