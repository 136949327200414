export type AudioState = 'processing' | 'recording' | 'stop';

export const ERRORS_MAPPING = {
  'AbortError': 'An Abort Error has occured.',
  'NotAllowedError': `Your computer's microphone is disabled or permission is being blocked. Please enable your microphone and/or give permission to use microphone in order to record a review.`,
  'NotFoundError': 'A NotFoundError has occured.',
  'NotReadableError': 'A NotReadableError has occured.',
  'SecurityError': 'A SecurityError has occured.',
  'TypeError': 'A TypeError has occured.',
  'InvalidStateError': 'An InvalidStateError has occured.',
  'UnknownError': 'An UnknownError has occured.',
};

export const CUSTOM_ERRORS = {
  NOT_SUPPORTED: 'NOT_SUPPORTED',
};
