import {EducationClassModel} from '../../classes/education-class.model';

export class EducationStudentWebsiteModel {
  constructor(
    public id?: number,
    public userId?: number,
    public classId?: number,
    public accountSubscriptionId?: number,
    public title?: string,
    public type?: string,
    public course?: EducationClassModel,
  ) {}
}
