import {IStyleOption} from '../option/i-style-option';

import {PROPERTY, DEFAULT_VALUE, DEFAULT_THUMB_TRAILER} from './constants';

export class VideoThumbnailModel implements IStyleOption {
  public element: HTMLElement;
  public videoWrapper: HTMLElement;
  public thumbnail: HTMLImageElement;

  public isOptionShown: boolean = false;

  public get src(): string {
    return this.thumbnail ? this.thumbnail.src : '';
  }

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.videoWrapper = <HTMLElement>element.closest('[data-video-container]');

    this.isOptionShown = !!this.videoWrapper;

    if (!this.videoWrapper) return;

    this.thumbnail = <HTMLImageElement>this.videoWrapper.querySelector('.thumbnail-image');

    this.isOptionShown = !!this.thumbnail;

    if (!this.thumbnail) return;

    this.value = this.videoWrapper.style.getPropertyValue(PROPERTY) || DEFAULT_VALUE;

    this.isOptionShown = !this.src.endsWith(DEFAULT_THUMB_TRAILER);
  }

  public onChange(value: string): void {
    if (!this.isEnabled || !this.videoWrapper) return;

    this.value = value;

    this.videoWrapper.style.setProperty(PROPERTY, value);
  }
}
