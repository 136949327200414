import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../../../auth/auth.service';

import {BlocksTemplatesModel} from '../../../../../models/blocks-templates/blocks-templates.model';
import {BlocksChangelogsModel} from '../../../../../models/blocks-changelogs/blocks-changelogs.model';
import {BlocksChangelogsDto} from '../../../../../models/blocks-changelogs/blocks-changelogs.dto';
import {BlockTemplateDataModel} from '../../../../../models/blocks-templates/blocks-templates-data.model';
import {BlockTemplateDataDto} from '../../../../../models/blocks-templates/blocks-templates-data.dto';
import {AccountModel} from '../../../../../models/accounts/account.model';

@Injectable()
export class BlocksTemplatesHttpService implements OnDestroy {
  private prefix: string = 'api/admin';
  private model: string = 'blocks-templates';

  public blocksTemplatesSubject: BehaviorSubject<BlockTemplateDataModel[]> = new BehaviorSubject<BlockTemplateDataModel[]>([]);

  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient,
              private authService: AuthService) {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      if (!account || !account.isAdmin) return;

      this.getAll();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getAll() {
    return this.http.get(`${this.prefix}/${this.model}`).subscribe((res: BlockTemplateDataDto[]) => {
      const blocksTemplates: BlockTemplateDataModel[] = res.map((block: BlockTemplateDataDto) => BlockTemplateDataDto.normalize(block));
      this.blocksTemplatesSubject.next(blocksTemplates);
    });
  }

  public updateTime(block: BlocksTemplatesModel) {
    return this.http.put(`${this.prefix}/${this.model}/${block.id}/time`, {});
  }

  public getBlockTemplateChangelogs(blockTemplateId: number, afterDate?: string): Observable<BlocksChangelogsModel[]> {
    return this.http.get<BlocksChangelogsDto[]>(`api/${this.model}/${blockTemplateId}/changelogs`, {
      params: {
        afterDate,
      },
    }).pipe(
      map((changelogs: BlocksChangelogsDto[]) => {
        return changelogs.map(changelog => BlocksChangelogsDto.normalize(changelog));
      })
    );
  }

  public updateBlockUpdateInfo(blockTemplateId: number, blockUpdateInfo: BlocksChangelogsModel): Observable<any> {
    return this.http.put(`${this.prefix}/${this.model}/${blockTemplateId}/changelogs/${blockUpdateInfo.id}`, BlocksChangelogsDto.toRequest(blockUpdateInfo));
  }
}
