import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES} from './constants';

export class IsSlideshowVideoMutedModel extends SetupOptionModel {
  public element: HTMLVideoElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = <HTMLVideoElement>element;

    this.value = this.element.getAttribute(ATTRIBUTES.IS_SLIDESHOW_VIDEO_MUTED) === 'true';

    this.initMuted();
  }

  public onChange(value: boolean): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.setAttribute(ATTRIBUTES.IS_SLIDESHOW_VIDEO_MUTED, `${value}`);

    this.initMuted();
  }

  private initMuted(): void {
    this.element.muted = this.value;
  }
}
