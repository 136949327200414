import {Component, Input, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FailedImagesHandlingModalService} from '../../../services/modals/failed-images-handling/failed-images-handling-modal.service';

import {FailedImagesStepModel} from '../../../../core/models/images/failed-images-step/failed-images-step.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-failed-images-handling-modal',
  templateUrl: './failed-images-handling-modal.component.html',
  styleUrls: ['./failed-images-handling-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class FailedImagesHandlingModalComponent implements OnDestroy {
  @Input() id: string;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public steps: FailedImagesStepModel[] = [];

  public modalHeader: ModalHeader = new ModalHeader('Handling Images...', 'neutral-header');

  public buttons: Button[] = [
    {
      text: 'Close',
      className: 'neutral ok-button',
      onClick: this.onClose.bind(this),
    },
  ];

  constructor(private service: FailedImagesHandlingModalService) {
    this.service.stepsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((steps: FailedImagesStepModel[]) => {
      this.steps = steps;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private onClose(): void {
    this.service.onCancel();
  }
}
