import {AccountLimitsModel} from './limits/account-limits.model';
import {WebsiteTourProgressAccountDataModel} from '../website-tour-progress/account-data/website-tour-progress-account-data.model';
import {PreLaunchProgressAccountDataModel} from '../pre-launch-progress/account-data/pre-launch-progress-account-data.model';

export class AccountModel {
  public get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(
    public id?: number,
    public activeWebsiteId?: number,
    public container?: number,
    public email?: string,
    public firstName?: string,
    public lastName?: string,
    public company?: string,
    public loggedInAs?: number,
    public address1?: string,
    public address2?: string,
    public city?: string,
    public state?: string,
    public zip?: string,
    public country?: string,
    public phoneDay?: string,
    public fax?: string,
    public provence?: string,
    public mailChimpKey?: string,
    public websiteTourProgress?: WebsiteTourProgressAccountDataModel,
    public preLaunchProgress?: PreLaunchProgressAccountDataModel,
    public createdAt?: string,
    public updatedAt?: string,
    public loginAt?: string,
    public lastSeenAt?: string,
    public emailVerificationCount?: number,
    public sessionKey?: string,
    public customSubscriptionId?: number,
    public planSupportTimeMin?: number,
    public addOnSupportTimeMin?: number,
    public totalAddOnSupportTime?: number,
    public supportVideoLink?: string,
    public isAdmin?: boolean,
    public isECommerce?: boolean,
    public isEmailVerified?: boolean,
    public isTrialSubscription?: boolean,
    public isSubscriptionPaid?: boolean,
    public isSubscriptionExpired?: boolean,
    public isUnderAdmin?: boolean,
    public isNewVersion?: boolean,
    public primaryMediumId?: number,
    public socketKey?: string,
    public isUserImported?: boolean,
    public isImageFilesRemoveEnabled?: boolean,
    public isMessagesReviewEnabled?: boolean,
    public isPublishInfoModalVisible?: boolean,
    public isFullImageLabelAccessGranted?: boolean,
    public limits?: AccountLimitsModel,
  ) {
    if (!this.websiteTourProgress) {
      this.websiteTourProgress = new WebsiteTourProgressAccountDataModel({}, false);
    }

    if (!this.preLaunchProgress) {
      this.preLaunchProgress = new PreLaunchProgressAccountDataModel({});
    }
  }

  public static clone(item: AccountModel): AccountModel {
    if (!item) return null;

    return new AccountModel(
      item.id,
      item.activeWebsiteId,
      item.container,
      item.email,
      item.firstName,
      item.lastName,
      item.company,
      item.loggedInAs,
      item.address1,
      item.address2,
      item.city,
      item.state,
      item.zip,
      item.country,
      item.phoneDay,
      item.fax,
      item.provence,
      item.mailChimpKey,
      WebsiteTourProgressAccountDataModel.clone(item.websiteTourProgress),
      PreLaunchProgressAccountDataModel.clone(item.preLaunchProgress),
      item.createdAt,
      item.updatedAt,
      item.loginAt,
      item.lastSeenAt,
      item.emailVerificationCount,
      item.sessionKey,
      item.customSubscriptionId,
      item.planSupportTimeMin,
      item.addOnSupportTimeMin,
      item.totalAddOnSupportTime,
      item.supportVideoLink,
      item.isAdmin,
      item.isECommerce,
      item.isEmailVerified,
      item.isTrialSubscription,
      item.isSubscriptionPaid,
      item.isSubscriptionExpired,
      item.isUnderAdmin,
      item.isNewVersion,
      item.primaryMediumId,
      item.socketKey,
      item.isUserImported,
      item.isImageFilesRemoveEnabled,
      item.isMessagesReviewEnabled,
      item.isPublishInfoModalVisible,
      item.isFullImageLabelAccessGranted,
      AccountLimitsModel.clone(item.limits),
    );
  }
}
