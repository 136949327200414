export class EducationTeacherDataModel {
  constructor(public nOfWebsites?: number) {
  }

  public static normalize(res: EducationTeacherDataModel): EducationTeacherDataModel {
    if (!res) {
      return null;
    }
    
    return new EducationTeacherDataModel(
      res.nOfWebsites,
    );
  }
}
