
import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

import {Subject} from 'rxjs';
import {take, takeUntil, filter} from 'rxjs/operators';

import {AuthService} from '../auth/auth.service';
import {EditorControlButtonsService} from '../services/editor-control-buttons.service';
import {WebsiteDesignerService} from '../application/main/website-designer/website-designer.service';
import {PublishWebsiteService} from '../services/publish-website/publish-website.service';
import {ModalsService} from '../shared/services/modals/modals.service';
import {PagesService} from '../application/sidebar-short/sidebar/pages/pages.service';
import {IFrameService} from '../core/services/iframe/iframe.service';
import {IFrameRoutingService} from '../core/services/iframe/routing/iframe-routing.service';
import {ButtonsService} from '../core/services/buttons/buttons.service';
import {SetupService} from '../core/services/setup/setup.service';
import {NavigationService} from '../services/navigation.service';
import {AppSettingsService} from '../core/services/app-settings/app-settings.service';
import {PublishingStatusModalService} from '../shared/services/modals/publishing-status/publishing-status-modal.service';
import {AppVersionChangesService} from '../core/services/app-version-changes/app-version-changes.service';
import {WebsitesService} from '../core/services/websites/websites.service';
import {DomainsService} from '../core/services/domains/domains.service';
import {TextAlertModalService} from '../services/text-alert-modal.service';
import {PublishingWebsiteInfoModalService} from '../shared/services/modals/publishing-website-info/publishing-website-info-modal.service';
import {TemplatesService} from '../core/services/templates/templates.service';
import {SocketsService} from '../core/services/interaction/sockets/sockets.service';
import {PaymentSubscriptionsService} from '../core/services/payment/subscriptions/payment-subscriptions.service';
import {WebsiteTourService} from '../core/services/website-tour/website-tour.service';
import {PermissionsService} from '../core/services/service-permissions/permissions/permissions.service';
import {MultiWebsiteService} from '../core/services/multi-website/multi-website.service';
import {EducationTeachersWebsitesService} from '../core/services/education/teachers/websites/education-teachers-websites.service';
import {WebsitesLimitExceededModalService} from '../shared/services/modals/websites-limit-exceeded/websites-limit-exceeded-modal.service';
import { EventsService } from '../core/services/interaction/events/events.service';

import {AccountModel} from '../core/models/accounts/account.model';
import {AppSettingsModel} from '../core/models/app-settings/app-settings.model';
import {WebsiteModel} from '../core/models/websites/website.model';
import {DomainModel} from '../core/models/domain/domain.model';
import {SelectedPageModel} from '../core/models/selected-page/selected-page.model';
import {ModalDataModel} from '../core/models/modals/modal-data.model';
import {SubscriptionModel} from '../core/models/payment/subscriptions/subscription.model';
import {CourseWebsiteModel} from '../core/models/websites/course-website.model';
import {IPermissionData} from '../core/models/permission/i-permission-data';

import {TOUR_KEY} from './constants';
import {BUTTONS_KEYS} from '../core/services/buttons/constants';
import {PUBLISH_BUTTON_STATES} from '../services/publish-website/constants';
import {KEYS} from '../core/services/website-tour/constants';
import {PERMISSIONS} from '../core/services/service-permissions/constants';

import {AppAnimations} from '../app-animations';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    AppAnimations.fadeIn(),
  ],
})
export class NavigationComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('saveButton') saveButton: ElementRef;
  @ViewChild('cancelButton') cancelButton: ElementRef;
  @ViewChild('publishWebsiteButton') publishWebsiteButton: ElementRef;
  @ViewChild('viewWebsiteButton') viewWebsiteButton: ElementRef;

  public logoutModalId = 'logout-save-modal';

  public currentWebsiteTitle: string = '';

  public isSaveButtonEnabled: boolean = false;
  public isCancelButtonEnabled: boolean = false;

  public isSaveButtonVisible: boolean = true;
  public isCancelButtonVisible: boolean = true;

  public isFrameLoaded: boolean = false;
  public isWebsitePreview: boolean = false;
  public isDirectLink: boolean = false;
  public isMaintenanceOverlayVisible: boolean = false;
  public isProfileDropdownVisible: boolean = false;

  public isTrial: boolean = false;
  public isEducator: boolean = false;

  public websiteLink: string;

  private bugReportModalId: string = 'bug-report-modal';

  // Only main outlets
  BUTTON_PACK = {
    WEBSITE_DESIGNER: 'website-designer',
    SETTINGS: 'settings',
    ADMIN: 'admin',
  };

  public website: WebsiteModel = null;
  public isWebsiteSetupCompleted: boolean = false;
  public isTemplateSelected: boolean = false;

  public freeTrialDaysLeft: number;

  public account: AccountModel;
  
  public websites: WebsiteModel[] = null;
  public educatorWebsites: CourseWebsiteModel[] = null;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  public isWebsitesDropdownPermitted: boolean = false;
  public isMultiSubscription: boolean = false;
  public isWaiting: boolean = false;
  public isPublishPossible: boolean = false;
  public isPublished: boolean = false;
  public isPublishing: boolean = false;

  activeButtonPack: string;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  private publishingInfoNgUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private primaryDomain: DomainModel;

  private settings: AppSettingsModel;

  private profileDropdownMouseLeaveTimerId: number;

  private isPrimaryDomainVsx: boolean = false;

  private saveButtonBlockers: { [key: string]: boolean } = {};

  isAppOpened: boolean;
  viewWebsiteWarningModalId = 'view-website-warning-modal';

  private buttonsStatesHandlers = {
    [BUTTONS_KEYS.SAVE]: this.initSaveButtonState.bind(this),
    [BUTTONS_KEYS.CANCEL]: this.initCancelButtonState.bind(this),
  };

  private buttonsVisibilitiesHandlers = {
    [BUTTONS_KEYS.SAVE]: this.initSaveButtonVisibility.bind(this),
    [BUTTONS_KEYS.CANCEL]: this.initCancelButtonVisibility.bind(this),
  };

  private tourHandlers = {
    [KEYS.SAVE_CHANGES_BUTTON]: {
      isVisible: () => !!this.saveButton,
    },
    [KEYS.CANCEL_BUTTON]: {
      isVisible: () => !!this.cancelButton,
    },
    [KEYS.NAVBAR_LEFT_BUTTONS_GROUP]: {
      isVisible: () => !!this.saveButton || !!this.cancelButton,
    },
    [KEYS.PUBLISH_WEBSITE_BUTTON]: {
      isVisible: () => !!this.publishWebsiteButton,
    },
    [KEYS.VIEW_WEBSITE_BUTTON]: {
      isVisible: () => !!this.viewWebsiteButton,
    },
  };

  public get isEditor(): boolean {
    return this.activeButtonPack === this.BUTTON_PACK.WEBSITE_DESIGNER;
  }

  public get isPageEditor(): boolean {
    return this.navigationService.isPageEditor;
  }

  public get isImageManager(): boolean {
    return this.navigationService.isImageManager;
  }

  public get isDashboard(): boolean {
    return this.navigationService.isDashboard;
  }

  public get version(): string {
    return this.settings ? this.settings.version : '';
  }

  public get isSaveButtonExists(): boolean {
    return this.isSaveButtonVisible && (this.activeButtonPack === this.BUTTON_PACK.WEBSITE_DESIGNER || this.activeButtonPack === this.BUTTON_PACK.SETTINGS || this.activeButtonPack === this.BUTTON_PACK.ADMIN);
  }

  public get isCancelButtonExists(): boolean {
    return this.isCancelButtonVisible && (this.activeButtonPack === this.BUTTON_PACK.WEBSITE_DESIGNER);
  }

  private get isLimitExceeded(): boolean {
    if (!this.account || !this.websites || this.account.isAdmin) return false;

    return this.websites.length >= this.account.limits.websites;
  }

  public get isSaveButtonBlocked(): boolean {
    return Object.keys(this.saveButtonBlockers).some((key: string) => this.saveButtonBlockers[key]);
  }

  constructor(private route: ActivatedRoute,
              private iFrameService: IFrameService,
              private iFrameRoutingService: IFrameRoutingService,
              private appSettingsService: AppSettingsService,
              private publishingStatusModalService: PublishingStatusModalService,
              private appVersionChangesService: AppVersionChangesService,
              private cdr: ChangeDetectorRef,
              private domainsService: DomainsService,
              private textAlertModalService: TextAlertModalService,
              private templatesService: TemplatesService,
              private publishingWebsiteInfoModalService: PublishingWebsiteInfoModalService,
              private socketsService: SocketsService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private websiteTourService: WebsiteTourService,
              private multiWebsiteService: MultiWebsiteService,
              private educationTeachersWebsitesService: EducationTeachersWebsitesService,
              private websitesLimitExceededModalService: WebsitesLimitExceededModalService,
              private eventsService: EventsService,
              private permissionsService: PermissionsService,
              public modalService: ModalsService,
              public router: Router,
              public buttonsService: ButtonsService,
              public setupService: SetupService,
              public modalsService: ModalsService,
              public websiteDesignerService: WebsiteDesignerService,
              public authService: AuthService,
              public editorControlButtonsService: EditorControlButtonsService,
              public publishWebsiteService: PublishWebsiteService,
              public pagesService: PagesService,
              public websitesService: WebsitesService,
              public navigationService: NavigationService) {
  }

  public ngOnInit(): void {
    this.initPermissions();

    this.websiteTourService.addVisibleLocation(TOUR_KEY);

    this.eventsService.addFrameListener('froalaImageUploadStatus', (e: CustomEvent) => {
      this.saveButtonBlockers['froalaImageUpload'] = !['uploaded', 'error'].includes(e.detail.status);
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.updateActiveButtonPack(event.url);

      this.isAppOpened = event.url.startsWith('/app');
    });
    
    this.multiWebsiteService.websitesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: WebsiteModel[]) => {
      this.websites = websites;

      this.initCurrentWebsiteTitle();
    });
    
    this.educationTeachersWebsitesService.listSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websites: CourseWebsiteModel[]) => {
      this.educatorWebsites = websites;

      this.initCurrentWebsiteTitle();
    });

    this.modalService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();

      if (!modalsStatus) return;

      if (modalsStatus[this.publishingWebsiteInfoModalService.id] && this.isPrimaryDomainVsx && !this.isPublishing) {
        this.handleVsxDomain();

        return;
      }

      this.publishingInfoNgUnsubscribe.next(true);
    });

    this.buttonsService.onPublishButtonChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string) => {
      if (!state) return;

      this.isWaiting = state === PUBLISH_BUTTON_STATES.DISABLED;
      this.isPublishPossible = state === PUBLISH_BUTTON_STATES.ENABLED;
      this.isPublished = state === PUBLISH_BUTTON_STATES.SUCCESS;
      this.isPublishing = state === PUBLISH_BUTTON_STATES.PUBLISHING;

      this.saveButtonBlockers['publishing'] = this.isPublishing;
    });

    this.buttonsService.onButtonChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(key => {
      if (!this.buttonsStatesHandlers[key]) return;

      this.buttonsStatesHandlers[key]();
    });

    this.buttonsService.onButtonVisibilityChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(key => {
      if (!this.buttonsVisibilitiesHandlers[key]) return;

      this.buttonsVisibilitiesHandlers[key]();
    });

    this.editorControlButtonsService.websiteLinkSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((link: string) => {
      this.websiteLink = link;
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.appSettingsService.settingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((settings: AppSettingsModel) => {
      this.settings = settings;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isTrial = false;
      this.isEducator = false;

      if (!subscription) {
        return;
      }

      this.isTrial = subscription.isTrial;
      this.isEducator = subscription.isEducator;

      if (!this.isTrial) {
        return;
      }

      const endsOn = new Date(subscription.trialEndAtTimestamp * 1000);
      const diffTime = Math.abs(new Date().getTime() - endsOn.getTime());

      this.freeTrialDaysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((page: SelectedPageModel) => {
      this.isDirectLink = page && page.isDirectLink;
    });

    this.iFrameService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isLoaded: boolean) => {
      this.isFrameLoaded = isLoaded;
    });

    this.templatesService.isWebsitePreviewSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isWebsitePreview: boolean) => {
      this.isWebsitePreview = isWebsitePreview;
    });

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;

      this.isWebsiteSetupCompleted = website && website.isSetupCompleted;
      this.isTemplateSelected = !!(website && website.templateId);

      this.initCurrentWebsiteTitle();
    });

    this.domainsService.primaryDomainSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((primaryDomain: DomainModel) => {
      this.primaryDomain = primaryDomain;

      this.isPrimaryDomainVsx = !!primaryDomain && primaryDomain.name.endsWith('.visualserver.com');
    });

    this.socketsService.isMaintenanceOverlayVisibleSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isMaintenanceOverlayVisible: boolean) => {
      this.isMaintenanceOverlayVisible = isMaintenanceOverlayVisible;
    });
  }

  private initPermissions(): void {
    const websitesDropdownPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.WEBSITES_DROPDOWN,
    };

    this.permissionsService.isUserHasPermissionsObservable([websitesDropdownPermission]).pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPermitted: boolean) => {
      this.isWebsitesDropdownPermitted = isPermitted;
    });
  }

  private initCurrentWebsiteTitle(): void {
    this.currentWebsiteTitle = '';

    if (!this.website) {
      return;
    }

    const currentWebsite: WebsiteModel = this.websites ? this.websites.find((website: WebsiteModel) => website.id === this.website.id) : null;

    if (currentWebsite) {
      this.currentWebsiteTitle = this.isEducator ? 'MY WEBSITE' : currentWebsite.title;

      return;
    }

    const currentEducatorWebsite: CourseWebsiteModel = this.educatorWebsites ? this.educatorWebsites.find((website: CourseWebsiteModel) => website.id === this.website.id) : null;

    if (currentEducatorWebsite) {
      this.currentWebsiteTitle = `CLASS WEBSITE`;
      
      return;
    }
  }

  public ngAfterViewChecked(): void {
    this.handleTours();
  }

  private handleTours(): void {
    if (this.websiteTourService.isSkipped) return;

    Object.keys(this.tourHandlers).forEach((key: string) => {
      this.handleButton(key);
    });
  }

  private handleButton(key: string): void {
    const isVisible: boolean = this.tourHandlers[key].isVisible();

    if (isVisible) {
      this.websiteTourService.addVisibleItem(key);

      return;
    }

    this.websiteTourService.removeVisibleItem(key);
  }

  private initSaveButtonState(): void {
    this.isSaveButtonEnabled = this.buttonsService.getButtonState(BUTTONS_KEYS.SAVE);

    this.cdr.detectChanges();
  }

  private initCancelButtonState(): void {
    this.isCancelButtonEnabled = this.buttonsService.getButtonState(BUTTONS_KEYS.CANCEL);

    this.cdr.detectChanges();
  }

  private initSaveButtonVisibility(): void {
    this.isSaveButtonVisible = this.buttonsService.getButtonVisibility(BUTTONS_KEYS.SAVE);

    this.cdr.detectChanges();
  }

  private initCancelButtonVisibility(): void {
    this.isCancelButtonVisible = this.buttonsService.getButtonVisibility(BUTTONS_KEYS.CANCEL);

    this.cdr.detectChanges();
  }

  // TODO: Refactor!!!
  updateActiveButtonPack(url: string) {
    const activeKey = Object.keys(this.BUTTON_PACK).find(key => url.includes(this.BUTTON_PACK[key]));

    this.activeButtonPack = this.BUTTON_PACK[activeKey];
  }

  public onProfileDropdownMouseEnter(): void {
    window.clearTimeout(this.profileDropdownMouseLeaveTimerId);

    this.isProfileDropdownVisible = true;
  }

  public onProfileDropdownMouseLeave(): void {
    this.profileDropdownMouseLeaveTimerId = window.setTimeout(() => {
      this.isProfileDropdownVisible = false;
    }, 100);
  }

  public toProfile(): void {
    if (!this.isWebsiteSetupCompleted) {
      return;
    }
    
    this.navigationService.toAccountInfo();
    
    this.isProfileDropdownVisible = false;
  }

  public toSettings(): void {
    this.navigationService.toWebsiteSettings();

    this.isProfileDropdownVisible = false;
  }

  public toHelp(): void {
    window.open('https://help.visualserver.com/', '_blank');

    this.isProfileDropdownVisible = false;
  }

  public onLogout() {
    this.isProfileDropdownVisible = false;
    
    if (!this.buttonsService.getButtonState(BUTTONS_KEYS.SAVE)) return this.doLogout();

    this.modalsService.open(this.logoutModalId);
  }

  doLogout() {
    this.authService.logout().subscribe(() => {
      this.iFrameService.isLoadedSubject.next(false);
      this.iFrameService.onContentLoad.next(false);
    });
  }

  public handleSave(): void {
    // this.buttonsService.disableSaveButton();
    // this.buttonsService.disableCancelButton();

    this.editorControlButtonsService.onBtnClick(this.editorControlButtonsService.CONTROL_BUTTONS.SAVE, false);

    this.buttonsService.onClick(BUTTONS_KEYS.SAVE);
  }

  public onCancel(): void {
    this.editorControlButtonsService.onBtnClick(this.editorControlButtonsService.CONTROL_BUTTONS.CANCEL, false);
    
    this.buttonsService.onClick(BUTTONS_KEYS.CANCEL);

    // this.buttonsService.disableSaveButton();
    // this.buttonsService.disableCancelButton();
  }

  saveAndPublish() {
    if (this.isSaveButtonEnabled) {
      this.websiteDesignerService
        .onChangesSaved
        .pipe(take(1))
        .subscribe(() => this.onPublish());
      this.handleSave();
    } else {
      this.onPublish();
    }
  }

  public openWebsite() {
    this.publishWebsiteService.openWebsite(this.websiteLink);
  }

  openWebsiteWithWarningIfNeed(): void {
    if (this.isSaveButtonEnabled || this.isPublishPossible) return this.modalsService.open(this.viewWebsiteWarningModalId);

    this.publishWebsiteService.openWebsite(this.websiteLink);
  }

  openBugReportModal() {
    this.modalsService.open(this.bugReportModalId);
  }

  public onPublishButtonClick() {
    if (this.isWaiting || this.isPublished) {
      return;
    }

    if (!this.primaryDomain) {
      return this.textAlertModalService.show({
        message: `This website can't be published because it doesn't have primary domain.`,
      });
    }

    if (this.isSaveButtonEnabled && !this.isPublishing) {
      this.websiteDesignerService.onChangesSaved.pipe(take(1)).subscribe(() => {
        this.handlePublish();
      });

      this.handleSave();

      return;
    }

    this.handlePublish();
  }

  private handlePublish(): void {
    if (this.isPrimaryDomainVsx && !this.isPublishing && this.account && this.account.isPublishInfoModalVisible) {
      this.publishingWebsiteInfoModalService.open();

      return;
    }

    if (this.isPublishPossible) {
      return this.onPublish();
    }
    
    if (this.isPublishing) {
      return this.publishingStatusModalService.open();
    }
  }

  private handleVsxDomain(): void {
    this.publishingWebsiteInfoModalService.onPublishingConfirmSubject.pipe(takeUntil(this.publishingInfoNgUnsubscribe)).subscribe((value: boolean) => {
      if (value) this.onPublish();

      this.publishingInfoNgUnsubscribe.next(true);
    });
  }

  private onPublish() {
    this.isPublishPossible = false;

    this.publishWebsiteService.publishWebsite();
  }

  public openVersionChangesSummaryModal() {
    this.modalsService.open(this.appVersionChangesService.summaryModalId);
  }

  public onWebsitePreviewClose(): void {
    this.templatesService.isWebsitePreviewSubject.next(false);
  }

  public onUseTemplateButtonClick(): void {
    this.templatesService.selectOpenedTemplate();
  }

  public toPurchasePage(): void {
    this.navigationService.toPurchase();
  }

  public ngOnDestroy(): void {
    this.websiteTourService.removeVisibleLocation(TOUR_KEY);
    this.websiteTourService.removeVisibleItem(KEYS.SAVE_CHANGES_BUTTON);
    this.websiteTourService.removeVisibleItem(KEYS.CANCEL_BUTTON);
    this.websiteTourService.removeVisibleItem(KEYS.PUBLISH_WEBSITE_BUTTON);
    this.websiteTourService.removeVisibleItem(KEYS.VIEW_WEBSITE_BUTTON);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
