export class PermissionModel {
  constructor(public id?: number,
              public description?: string,
              public key?: string,
              public name?: string) {}

  public static clone(item: PermissionModel): PermissionModel {
    if (!item) return null;

    return new PermissionModel(
      item.id,
      item.description,
      item.key,
      item.name,
    );
  }

  public static isSame(item1: PermissionModel, item2: PermissionModel): boolean {
    if (item1 === item2 || (!item1 && !item2)) {
      return true;
    }

    return item1.id === item2.id
      && item1.key === item2.key
      && item1.name === item2.name
      && item1.description === item2.description;
  }
}
