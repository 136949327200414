import {IStyleOption} from '../../../option/i-style-option';

import {OPACITY_PROPERTY} from './constants';

export class PortfolioEnlargementBackgroundTransparencyModel implements IStyleOption {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const propertyValue: number = Number.parseFloat(window.getComputedStyle(this.element).getPropertyValue(OPACITY_PROPERTY)) * 100;

    this.value = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;
  }

  public reset(): void {
    if (!this.isEnabled || !this.element) return;

    this.element.style.removeProperty(OPACITY_PROPERTY);

    const propertyValue: number = Number.parseFloat(window.getComputedStyle(this.element).getPropertyValue(OPACITY_PROPERTY)) * 100;

    this.value = Number.isNaN(propertyValue) ? void 0 : 100 - propertyValue;
  }

  public setValue(value: string): void {
    if (!this.isEnabled || !this.element) return;

    this.value = value;

    this.element.style.setProperty(OPACITY_PROPERTY, (1 - Number.parseFloat(value) / 100).toString());
  }
}
