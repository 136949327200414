import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';

import {HomepageImageSelectModalService} from '../../../services/modals/homepage-image-select/homepage-image-select-modal.service';

import {Button} from "../../../../common/models/button/button.model";

@Component({
  selector: 'app-homepage-image-select-modal',
  templateUrl: './homepage-image-select-modal.component.html',
  styleUrls: ['./homepage-image-select-modal.component.scss']
})
export class HomepageImageSelectModalComponent implements OnDestroy {
  public buttons: Button[] = [
    {
      text: 'OK',
      className: 'green ok-button',
      onClick: this.onConfirm.bind(this),
    },
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(private service: HomepageImageSelectModalService) {
  }

  public onConfirm(): void {
    this.service.onConfirm();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
