import {Component, ElementRef, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {IFrameClickCatcherService} from "../../../../../services/iframe-click-catcher.service";
import {wrapToUrl} from "../../../../../shared/util/converting";

@Component({
  selector: 'app-border-icon-picker',
  templateUrl: './border-icon-picker.component.html',
  styleUrls: ['./border-icon-picker.component.scss'],
})
export class BorderIconPickerComponent {
  ICONS = [
    "data:image/svg+xml,<svg data-item-name='dash' preserveAspectRatio='xMinYMin meet' version='1.1' viewBox='0 0 357 357' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'><g><path d='m356,195l-357,0l0,-25l358,0l0,25z'/></g></svg>",
    "data:image/svg+xml,<svg data-item-name='dot' preserveAspectRatio='xMinYMin meet' enable-background='new 0 0 512 512' version='1.1' viewBox='0 0 512 512' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'><g><path d='m256,123.5c-73.06288,0 -132.5,59.43712 -132.5,132.5s59.43712,132.5 132.5,132.5s132.5,-59.43712 132.5,-132.5s-59.43712,-132.5 -132.5,-132.5z'/></g></svg>",
    "data:image/svg+xml,<svg data-item-name='dot' preserveAspectRatio='xMinYMin meet' version='1.1' viewBox='0 0 438.529 438.529' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'><g>   <path d='m343.34256,259.74933c-1.57603,-6.00336 -5.04291,-10.52256 -10.40741,-13.55395l-46.51758,-26.93026l46.51758,-26.93149c5.36449,-3.03138 8.83322,-7.54752 10.40741,-13.55272c1.57174,-6.00459 0.84467,-11.6882 -2.18794,-17.05023l-11.19144,-19.23572c-3.03077,-5.36388 -7.5457,-8.832 -13.55334,-10.40619c-6.00397,-1.57112 -11.68207,-0.84284 -17.04841,2.19039l-46.51758,26.75324l0,-53.68779c0,-6.06338 -2.21795,-11.30965 -6.64895,-15.74005c-4.42916,-4.43039 -9.67667,-6.64528 -15.73821,-6.64528l-22.38166,0c-6.064,0 -11.30965,2.21673 -15.74005,6.64528c-4.42977,4.42977 -6.6459,9.67667 -6.6459,15.74005l0,53.68779l-46.51819,-26.75508c-5.36388,-3.03322 -11.0475,-3.76397 -17.05085,-2.19039c-6.00459,1.57603 -10.52256,5.0423 -13.55395,10.40619l-11.19144,19.23634c-3.03322,5.36204 -3.7609,11.04566 -2.18733,17.05023c1.57664,6.00336 5.0423,10.52196 10.40619,13.55272l46.51819,26.93394l-46.51819,26.93026c-5.36388,3.03138 -8.832,7.54814 -10.40619,13.55395c-1.57296,6.00336 -0.84345,11.68391 2.18733,17.04595l11.19205,19.23817c3.03015,5.36633 7.54631,8.83322 13.55272,10.40985c6.0058,1.56928 11.68697,0.84223 17.05085,-2.19284l46.51819,-26.7557l0,53.69085c0,6.05848 2.2155,11.31088 6.6459,15.73821c4.43039,4.431 9.67789,6.64467 15.74005,6.64467l22.38166,0c6.06155,0 11.31088,-2.21305 15.7376,-6.64467c4.43345,-4.42795 6.64895,-9.68034 6.64895,-15.73821l0,-53.68656l46.51758,26.7557c5.36633,3.03077 11.04443,3.76274 17.04841,2.18855c6.00519,-1.57664 10.52256,-5.04107 13.55334,-10.40741l11.19144,-19.23634c3.03383,-5.36571 3.76151,-11.04811 2.18917,-17.05146l0,0.00001z'/></g></svg>",
    // it's square icon, if need uncomment
    // "data:image/svg+xml,<svg preserveAspectRatio='xMinYMin meet' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'><g><rect x='0' y='0' width='120' height='120' /></g></svg>",
  ];

  private removeMouseUpListener;

  isIconListOpened = false;

  @Input() value = this.ICONS[0];
  @Output() valueChange = new EventEmitter();

  handleClick(event) {
    if (this.elementRef.nativeElement.contains(event.target)) return;

    this.closeIconList();
  }

  constructor(private elementRef: ElementRef,
              private iframeClickCatcher: IFrameClickCatcherService,
              private renderer: Renderer2) {
    iframeClickCatcher.iframeClick.subscribe(() => {
      this.isIconListOpened = false;
    });
  }

  onSelectIcon(value) {
    this.value = value;
    this.valueChange.emit(this.value);
    this.closeIconList();
  }

  openIconList() {
    this.isIconListOpened = true;

    this.attachMouseUpListener();
  }

  closeIconList() {
    this.isIconListOpened = false;

    if (this.removeMouseUpListener) this.removeMouseUpListener();
  }

  getUrlToIcon(val) {
    return wrapToUrl(val);
  }

  private attachMouseUpListener() {
    this.removeMouseUpListener = this.renderer.listen('document', 'mouseup', this.handleClick.bind(this));
  }
}
