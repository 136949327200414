import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Subject, BehaviorSubject, catchError, throwError, tap, mergeMap, Observable} from 'rxjs';

import {WebsiteDesignerService} from '../application/main/website-designer/website-designer.service';
import {WebsitesService} from '../core/services/websites/websites.service';

import {WebsiteModel} from '../core/models/websites/website.model';

@Injectable()
export class MailChimpService implements OnDestroy {
  API_STATUSES = {
    NOT_CONNECTED: 'NOT CONNECTED',
    CONNECTED: 'CONNECTED',
    CHECKING: 'CHECKING...'
  };

  private ngUnsubscribe = new Subject();

  private website: WebsiteModel = null;

  mailchimpListIdSubject = new BehaviorSubject<string>(null);
  status = new BehaviorSubject<string>(this.API_STATUSES.CHECKING);
  error: string = '';

  username: string = '';
  userEmail: string = '';

  constructor(
    private httpClient: HttpClient,
    private websitesService: WebsitesService,
    private websiteDesignerService: WebsiteDesignerService,
  ) {
    this.websitesService.activeWebsiteSubject.subscribe((website: WebsiteModel) => {
      this.website = website;

      if (this.website) this.init();
    });
  }

  init() {
    this.ping().subscribe(() => {
      this.mailchimpListIdSubject.next(this.website.mailChimpListId);
    });
  }

  updateMailChimpListId(id): Observable<void> {
    return this.websiteDesignerService.updateWebsite({ MailChimpListID: id }).pipe(
      tap(() => {
        this.mailchimpListIdSubject.next(id);
      }),
    );
  }

  updateUserInfo() {
    return this.httpClient.get<{ email: string, username: string }>('/api/mailchimp').pipe(
      catchError((e) => {
        this.error = e;

        return throwError(() => e);
      })
    ).pipe(
      tap(({ email, username }) => {
        this.userEmail = email;
        this.username = username;
      })
    );
  }

  ping() {
    this.error = null;
    this.status.next(this.API_STATUSES.CHECKING);

    return this.httpClient.get('/api/mailchimp/ping').pipe(
      catchError((e) => {
        this.error = e;

        this.status.next(this.API_STATUSES.NOT_CONNECTED);

        return throwError(() => e);
      })
    ).pipe(
      mergeMap(() => {
        return this.updateUserInfo().pipe(
          tap(() => {
            this.status.next(this.API_STATUSES.CONNECTED);
          })
        )
      })
    );
  }

  removeApiKey() {
    return this.httpClient.post('/api/mailchimp/logout', {}).pipe(
      catchError((e) => {
        this.error = e;

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.status.next(this.API_STATUSES.NOT_CONNECTED);

      this.userEmail = '';
      this.username = '';
    });
  }

  getLists() {
    return this.httpClient.get<{ lists }>('/api/mailchimp/lists');
  }

  getListMembers(listId: string, search: any): Observable<{ members, total_items }> {
    return this.httpClient.get<{ members, total_items }>(`/api/mailchimp/lists/${listId}/members`, { params: search });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
