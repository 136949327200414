// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-pane {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.theme-pane .head {
  flex: 0;
  width: 700px;
  padding: 50px 50px 0 100px;
  padding-right: 10px;
}
.theme-pane .head .close-icon-wrapper {
  position: absolute;
  right: 30px;
  width: 25px;
  height: 25px;
  margin-left: auto;
}
.theme-pane .head .close-icon-wrapper:hover {
  cursor: pointer;
}
.theme-pane .head .head__title {
  font-size: 23px;
  color: #6f6f6f;
  font-weight: 500;
  padding: 5px 0;
}
.theme-pane .head .head__description {
  font-size: 16px;
  color: #929292;
}
.theme-pane .content {
  overflow: auto;
  flex: 1;
  margin-top: 20px;
  padding: 50px 50px 0 100px;
  flex-wrap: wrap;
  display: flex;
}
.theme-pane .content .theme-block {
  align-items: center;
  width: 250px;
  height: 150px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  margin-right: 35px;
  cursor: pointer;
}
.theme-pane .content .theme-block:hover, .theme-pane .content .theme-block.selected {
  box-shadow: 0 0 0 2px red;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/website-designer/theme-pane/theme-pane.component.scss"],"names":[],"mappings":"AAIA;EACE,uBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAHF;AAKE;EACE,OAAA;EACA,YAAA;EACA,0BAZc;EAad,mBAAA;AAHJ;AAKI;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAHN;AAKM;EACE,eAAA;AAHR;AAOI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;AALN;AAQI;EACE,eAAA;EACA,cAAA;AANN;AAUE;EACE,cAAA;EACA,OAAA;EACA,gBAAA;EACA,0BA5Cc;EA8Cd,eAAA;EACA,aAAA;AATJ;AAWI;EACE,mBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AATN;AAeM;EACE,yBAAA;EACA,YAAA;AAbR","sourcesContent":["@import '../../../../../styles/colors';\n\n$content-padding: 50px 50px 0 100px;\n\n.theme-pane {\n  background-color: white;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .head {\n    flex: 0;\n    width: 700px;\n    padding: $content-padding;\n    padding-right: 10px;\n\n    .close-icon-wrapper {\n      position: absolute;\n      right: 30px;\n      width: 25px;\n      height: 25px;\n      margin-left: auto;\n\n      &:hover {\n        cursor: pointer;\n      }\n    }\n\n    .head__title {\n      font-size: 23px;\n      color: #6f6f6f;\n      font-weight: 500;\n      padding: 5px 0;\n    }\n\n    .head__description {\n      font-size: 16px;\n      color: #929292;\n    }\n  }\n\n  .content {\n    overflow: auto;\n    flex: 1;\n    margin-top: 20px;\n    padding: $content-padding;\n\n    flex-wrap: wrap;\n    display: flex;\n\n    .theme-block {\n      align-items: center;\n      width: 250px;\n      height: 150px;\n      border: 1px solid black;\n      display: flex;\n      justify-content: center;\n      margin-bottom: 35px;\n      margin-right: 35px;\n      cursor: pointer;\n\n      .theme-block__text {\n\n      }\n\n      &:hover, &.selected {\n        box-shadow: 0 0 0 2px red;\n        border: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
