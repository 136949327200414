import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppSettingsService} from '../../../../core/services/app-settings/app-settings.service';
import {FreeTrialHttpService} from '../../../../core/services/interaction/http/admin/free-trial/free-trial-http.service';

import {CustomSubscriptionModel} from '../../../../core/models/custom-subscription/custom-subscription.model';
import {PlanModel} from '../../../../core/models/plan/plan.model';
import {AppSettingsModel} from '../../../../core/models/app-settings/app-settings.model';

@Component({
  selector: 'app-free-trial',
  templateUrl: './free-trial.component.html',
  styleUrls: ['./free-trial.component.scss']
})
export class AdminFreeTrialComponent implements OnInit, OnDestroy {
  public subscriptions: CustomSubscriptionModel[];
  public plans: PlanModel[];

  public settings: AppSettingsModel;

  public isTrialValid: boolean = true;
  public isEducationalTrialValid: boolean = true;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private httpService: FreeTrialHttpService,
              private appSettingsService: AppSettingsService) {
  }

  public ngOnInit(): void {
    this.appSettingsService.settingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((settings: AppSettingsModel) => {
      this.settings = settings;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onSave(): void {
    if (!this.isValid()) return;

    this.httpService.update(this.settings).add(() => {
      this.appSettingsService.init();
    });
  }

  private isValid(): boolean {
    this.isTrialValid = !!this.settings && !Number.isNaN(Number.parseInt(this.settings.trialPeriodDays));
    this.isEducationalTrialValid = !!this.settings && !Number.isNaN(Number.parseInt(this.settings.trialPeriodEducationalDays));

    return this.isTrialValid && this.isEducationalTrialValid;
  }
}
