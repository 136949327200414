import {Component, ElementRef, Input, ViewChild} from '@angular/core';

import { TreeItemModel } from '../../../../../../core/models/nodes/tree-item.model';

@Component({
  selector: 'app-basic-tree-node-wrapper',
  templateUrl: './basic-tree-node-wrapper.component.html',
  styleUrls: ['./basic-tree-node-wrapper.component.scss'],
})
export class BasicTreeNodeWrapperComponent {
  @Input() node: TreeItemModel;
  @Input() index: number;
  @Input() isSelected: boolean;

  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  constructor() {}
}
