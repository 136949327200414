import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

import {ImageAvailabilityDto} from '../../../models/images/availability/image-availability.dto';
import {ImageAvailabilityModel} from '../../../models/images/availability/image-availability.model';

@Injectable()
export class ImagesAvailabilitiesService {
  private model: string = 'images/availabilities';

  public availabilitiesSubject: BehaviorSubject<ImageAvailabilityModel[]> = new BehaviorSubject<ImageAvailabilityModel[]>([]);

  constructor(private http: HttpClient) {
    this.init();
  }

  public init() {
    this.fetch().subscribe((availabilities: ImageAvailabilityModel[]) => {
      this.availabilitiesSubject.next(availabilities);
    });
  }

  private fetch(): Observable<ImageAvailabilityModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: ImageAvailabilityDto[]) => res.map(item => ImageAvailabilityDto.normalize(item)))
    );
  }

  public getById(availabilities: ImageAvailabilityModel[], id: number): ImageAvailabilityModel {
    if (!availabilities) return null;

    return availabilities.find((availability: ImageAvailabilityModel) => availability.id === id);
  }

  public getByText(availabilities: ImageAvailabilityModel[], text: string): ImageAvailabilityModel {
    if (!availabilities) return null;

    return availabilities.find((availability: ImageAvailabilityModel) => availability.text === text);
  }
}
