import {GoogleFontUsageModel} from './google-font-usage.model';
import {GoogleFontUsageWebsiteDbo} from './google-font-usage-website.dbo';

export class GoogleFontUsageDbo {
  constructor(public family?: string,
              public isDefaultWebsiteFont?: boolean,
              public usageData?: GoogleFontUsageWebsiteDbo[]) {}

  public static normalize(res: GoogleFontUsageDbo): GoogleFontUsageModel {
    if (!res) return null;

    const usageData = res.usageData ? res.usageData.map(item => GoogleFontUsageWebsiteDbo.normalize(item)) : null;

    return new GoogleFontUsageModel(
      res.family,
      res.isDefaultWebsiteFont,
      !!res.usageData && res.usageData.length > 0,
      usageData,
    );
  }
}
