import {UserModel} from './user/user.model';
import {CardModel} from './card/card.model';

export class PaymentModel {
  constructor(public user?: UserModel,
              public cards?: CardModel[]) {}

  public static normalize(res: PaymentModel): PaymentModel {
    if (!res) return null;

    const user = res.user ? UserModel.normalize(res.user) : null;
    const defaultCardId = user ? user.defaultSource : void 0;

    return new PaymentModel(
      user,
      res.cards ? res.cards.map(card => CardModel.normalize(card, defaultCardId)) : [],
    );
  }
}
