import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CustomizerMobileTemplatesService} from '../../../../../../../core/services/customizer/mobile/templates/customizer-mobile-templates.service';
import {ButtonsService} from '../../../../../../../core/services/buttons/buttons.service';

import {MobileTemplatesEnlargement} from '../../../../../../../core/models/customizer/mobile/templates/enlargements/mobile-templates-enlargement';

@Component({
  selector: 'app-mobile-templates-enlargements',
  templateUrl: './mobile-templates-enlargements.component.html',
  styleUrls: ['./mobile-templates-enlargements.component.scss']
})
export class MobileTemplatesEnlargementsComponent implements OnInit, OnDestroy {
  public list: MobileTemplatesEnlargement[] = [
    new MobileTemplatesEnlargement('swipe', '/assets/images/customizer/mobile/templates/enlargements/1.jpg'),
    new MobileTemplatesEnlargement('single', '/assets/images/customizer/mobile/templates/enlargements/2.jpg'),
  ];

  public selectedItem: MobileTemplatesEnlargement;

  @Output() closeHandler: EventEmitter<void> = new EventEmitter<void>();

  private ngUnsubscribe = new Subject<void>();

  constructor(private customizerMobileTemplatesService: CustomizerMobileTemplatesService,
              private buttonsService: ButtonsService) {}

  ngOnInit(): void {
    this.customizerMobileTemplatesService.mobileEnlargement.pipe(takeUntil(this.ngUnsubscribe)).subscribe(key => {
      this.selectEnlargement(key);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
  }

  public onCloseHandler() {
    this.closeHandler.emit();
  }

  public onSelect(key) {
    this.customizerMobileTemplatesService.setMobileEnlargement(key);
    this.buttonsService.enableSaveButton();
    this.selectEnlargement(key);
  }

  private selectEnlargement(key) {
    this.selectedItem = this.list.find(item => item.key === key);
  }
}
