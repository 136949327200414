import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ImageManagerService} from '../../../../../../../../application/main/image-manager/image-manager.service';

import {PortfolioModel} from '../../../../../../../models/portfolios/portfolio.model';
import {PortfolioDto} from '../../../../../../../models/portfolios/portfolio.dto';
import {PortfolioDefaultsModel} from '../../../../../../../models/images/default/portfolio-defaults.model';
import {PortfolioDefaultsV2Dto} from '../../../../../../../models/images/default/portfolio-defaults-v2.dto';
import {ImageModel} from '../../../../../../../models/images/image.model';
import {ImageDto} from '../../../../../../../models/images/image.dto';
import {NodeModel} from '../../../../../../../models/nodes/node.model';
import {IDefaultResponse} from '../../../../../../../models/responses/i-default-reponse';
import {ImageLabelDto} from '../../../../../../../models/images/image-label/image-label.dto';

@Injectable()
export class EducationStudentsPortfoliosHttpService {
  private prefix: string = 'api/education/students/current/websites';

  constructor(
    private http: HttpClient,
    private imageManagerService: ImageManagerService,
  ) {
  }

  public create({ websiteId, node }: { websiteId: number, node: NodeModel }): Observable<NodeModel> {
    return this.http.post(`${this.prefix}/${websiteId}/portfolios`, node).pipe(
      map((res: NodeModel) => NodeModel.normalize(res))
    );
  }

  public getAll({ websiteId }: { websiteId: number }): Observable<PortfolioModel[]> {
    return this.http.get(`${this.prefix}/${websiteId}/portfolios`).pipe(
      map((list: PortfolioDto[]) => {
        if (!list) {
          return [];
        }

        return list.map((item: PortfolioDto) => PortfolioDto.normalize(item));
      })
    );
  }

  public getCounters({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Observable<PortfolioModel> {
    return this.http.get(`${this.prefix}/${websiteId}/portfolios/${portfolioId}/counters`).pipe(
      map((item: PortfolioDto) => PortfolioDto.normalize(item))
    );
  }

  public getDefaults({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Observable<PortfolioDefaultsModel> {
    return this.http.get(`${this.prefix}/${websiteId}/portfolios/${portfolioId}/defaults`).pipe(
      map((res: PortfolioDefaultsV2Dto) => PortfolioDefaultsV2Dto.normalize(res)),
      map((res: PortfolioDefaultsModel) => this.imageManagerService.convertToWebsiteMeasureUnit(res)),
    );
  }

  public getImages({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Observable<ImageModel[]> {
    return this.http.get(`${this.prefix}/${websiteId}/portfolios/${portfolioId}/images`).pipe(
      map((images: ImageDto[]) => {
        return images.map((image: ImageDto) => {
          return ImageDto.normalize(image);
        });
      })
    );
  }

  public toggleShare({ websiteId, portfolioId, isShared }: { websiteId: number, portfolioId: number, isShared: boolean }): Observable<IDefaultResponse> {
    return this.http.put(`${this.prefix}/${websiteId}/portfolios/${portfolioId}/share`, { isShared });
  }

  public saveImageDetails({ details }: { details: ImageLabelDto }): Observable<IDefaultResponse> {
    return this.http.put(`${this.prefix}/${details.websiteId}/portfolios/${details.portfolioId}/images/${details.imageId}/details`, details);
  }

  public updateTitle({ websiteId, portfolioId, title }: { websiteId: number, portfolioId: number, title: string }): Observable<IDefaultResponse> {
    return this.http.put(`${this.prefix}/${websiteId}/portfolios/${portfolioId}/title`, { title });
  }

  public moveManyImagesToPortfolio({ websiteId, portfolioId, toPortfolioId, ids }: { websiteId: number, portfolioId: number, toPortfolioId: number, ids: number[] }): Observable<IDefaultResponse> {
    return this.http.put(`${this.prefix}/${websiteId}/portfolios/${portfolioId}/images/portfolio`, { ids, toPortfolioId });
  }

  public removeOne(portfolio: PortfolioModel): Observable<IDefaultResponse> {
    return this.http.delete(`${this.prefix}/${portfolio.websiteId}/portfolios/${portfolio.id}`);
  }

  public deleteAllImages({ websiteId, portfolioId }: { websiteId: number, portfolioId: number }): Observable<IDefaultResponse> {
    return this.http.delete(`${this.prefix}/${websiteId}/portfolios/${portfolioId}/images/all`);
  }
}
