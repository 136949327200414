import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {MediaManagerService} from '../../../../core/services/media-manager/media-manager.service';

import {SectionModel} from '../../../../core/models/media/section/section.model';

@Component({
  selector: 'app-media-manager-sidebar',
  templateUrl: './media-manager-sidebar.component.html',
  styleUrls: ['./media-manager-sidebar.component.scss'],
})
export class MediaManagerSidebarComponent implements OnInit, OnDestroy {
  public sections: SectionModel[] = [];
  public activeSection: SectionModel = null;

  public isSectionsDisabled: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private service: MediaManagerService) {}

  public ngOnInit(): void {
    this.service.sectionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((sections: SectionModel[]) => {
      this.sections = sections;
    });

    this.service.isSectionsDisabledSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isSectionsDisabled: boolean) => {
      this.isSectionsDisabled = isSectionsDisabled;
    });

    this.service.activeSectionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((section: SectionModel) => {
      this.activeSection = section;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onClick(section: SectionModel): void {
    this.service.selectSection(section.key);
  }
}
