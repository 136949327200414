import {
  Component,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import {AppAnimations} from '../../../app-animations';
 
import {SelectOption} from '../../../core/models/select/option/option.model';

import {KEY_CODES} from '../../../core/services/keys/constants';

@Component({
  selector: 'app-vsx-dropdown',
  templateUrl: './vsx-dropdown.component.html',
  styleUrls: ['./vsx-dropdown.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class VsxDropdownComponent implements OnChanges {
  @ViewChild('currentValue') currentValue: ElementRef;

  @Input() isDefault: boolean = false;
  @Input() isTop: boolean = false;
  @Input() isAutoDropdownDirection: boolean = false;
  @Input() tabIndex: number;
  @Input() options: SelectOption[] = [];
  @Input() selectedOption: SelectOption = null;
  @Input() showArrow: boolean = false;
  @Input() showBackground: boolean = false;
  @Input() showFocusBackground: boolean = false;
  @Input() showError: boolean = false;
  @Input() focusColor: string = '';
  @Input() showBorder: boolean = false;
  @Input() borderRadius: string = '';
  @Input() dropdownBorderRadius: string = '';
  @Input() padding: string = '';
  @Input() dropdownMargin: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';

  @Output() selectHandler: EventEmitter<SelectOption> = new EventEmitter<SelectOption>();

  public isTopAuto: boolean = false;

  private removeMouseUpListener = null;

  private _isDropdownShown: boolean = false;

  private arrowHandlers = {
    [KEY_CODES.ARROW_UP]: this.onArrowUp.bind(this),
    [KEY_CODES.ARROW_DOWN]: this.onArrowDown.bind(this),
  };

  public get isDropdownShown(): boolean {
    return this._isDropdownShown;
  }

  public set isDropdownShown(value: boolean) {
    this._isDropdownShown = value;

    if (this._isDropdownShown) {
      this.attachMouseUpListener();

      return;
    }

    if (this.removeMouseUpListener) this.removeMouseUpListener();
  }

  public get selectLabel(): string {
    if (!this.selectedOption) return this.label || this.placeholder || 'Select';

    return this.selectedOption.label || this.placeholder || 'Select';
  }

  constructor(private renderer: Renderer2,
              private cdr: ChangeDetectorRef,
              public sanitizer: DomSanitizer) {
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDefault !== void 0) {
      this.isDropdownShown = this.isDropdownShown && !changes.isDefault;
    }
  }

  public toggleDropdown(): void {
    if (this.isDefault) return;

    this.isTopAuto = window.innerHeight - this.currentValue.nativeElement.getBoundingClientRect().bottom <= 250; // 250 - max dropdown height

    this.isDropdownShown = !this.isDropdownShown;

    this.cdr.detectChanges();
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode === KEY_CODES.ENTER) {
      this.isDropdownShown = !this.isDropdownShown;
      return;
    }

    if (e.keyCode === KEY_CODES.TAB || e.keyCode === KEY_CODES.ESC) {
      this.isDropdownShown = false;
      return;
    }

    if (e.keyCode !== KEY_CODES.ARROW_DOWN) return;

    const target = <HTMLElement>e.target;

    const currentItem: HTMLElement = <HTMLElement>target.parentElement.querySelector('.dropdown-item-wrapper.current');

    if (currentItem) return this.focusItem(currentItem);

    const item: HTMLElement = <HTMLElement>target.parentElement.querySelector('.dropdown-item-wrapper');

    if (!item) return;

    this.focusItem(item);
  }

  public onDropdownKeyDown(e: KeyboardEvent): void {
    const target = <HTMLElement>e.target;

    if (e.keyCode === KEY_CODES.ENTER) {
      e.target.dispatchEvent(new Event('click'));
      return;
    }

    if (e.keyCode === KEY_CODES.ESC) {
      this.isDropdownShown = false;

      this.focusSelectedValue();
    }

    if (e.keyCode === KEY_CODES.TAB) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (!this.arrowHandlers[e.keyCode]) return;

    e.preventDefault();
    e.stopPropagation();

    this.arrowHandlers[e.keyCode](target);
  }

  private onArrowUp(target: HTMLElement): void {
    if (!target || !target.previousElementSibling) return;

    const prev: HTMLElement = <HTMLElement>target.previousElementSibling;

    this.focusItem(prev);
  }

  private onArrowDown(target: HTMLElement): void {
    if (!target || !target.nextElementSibling) return;

    const next: HTMLElement = <HTMLElement>target.nextElementSibling;

    this.focusItem(next);
  }

  public onItemSelect(e: KeyboardEvent, option: SelectOption): void {
    if (this.isDefault) return;

    this.focusSelectedValue();

    this.selectHandler.emit(option);

    this.isDropdownShown = false;

    this.cdr.detectChanges();
  }

  private focusSelectedValue(): void {
    if (!this.currentValue || !this.currentValue.nativeElement) return;

    const item: HTMLElement = <HTMLElement>this.currentValue.nativeElement;

    if (!item) return;

    return item.focus();
  }

  private focusItem(next: HTMLElement): void {
    next.focus();
    next.scrollIntoView();
  }

  private attachMouseUpListener(): void {
    this.removeMouseUpListener = this.renderer.listen('document', 'mousedown', this.closeDropdown);
  }

  private closeDropdown(event) {
    if (this.currentValue.nativeElement.contains(event.target) || event.target.classList.contains('dropdown-wrapper') || event.target.classList.contains('dropdown-item-wrapper')) return;

    this.isDropdownShown = false;
  }
}
