import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BlocksTemplatesHttpService} from '../../../../core/services/interaction/http/admin/blocks-templates/blocks-templates-http.service';
import {NavigationService} from '../../../../services/navigation.service';

import {BlocksTemplatesDto} from '../../../../core/models/blocks-templates/blocks-templates.dto';
import {BlocksTemplatesModel} from '../../../../core/models/blocks-templates/blocks-templates.model';
import {BlockTemplateDataModel} from '../../../../core/models/blocks-templates/blocks-templates-data.model';

@Component({
  selector: 'app-blocks-templates',
  templateUrl: './blocks-templates.component.html',
  styleUrls: ['./blocks-templates.component.scss']
})
export class AdminBlocksTemplatesComponent implements OnDestroy {
  public blocksTemplates: BlockTemplateDataModel[];

  public isAnyExpanded: boolean = false;

  private ngUnsubscribe = new Subject();

  constructor(private blocksTemplatesService: BlocksTemplatesHttpService,
              private navigationService: NavigationService) {
    this.blocksTemplatesService.blocksTemplatesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((blocks: BlockTemplateDataModel[]) => {
      this.blocksTemplates = blocks;

      this.isAnyExpanded = this.blocksTemplates.some(item => item.isExpanded);
    });
  }

  public onTimeUpdate(event: Event, block: BlocksTemplatesModel) {
    event.stopPropagation();

    return this.blocksTemplatesService.updateTime(block).subscribe((updatedBlock: BlocksTemplatesDto) => {
      block.updatedAt = updatedBlock.UpdatedAt;
    });
  }

  public onToggle(item: BlockTemplateDataModel) {
    item.isExpanded = !item.isExpanded;

    this.blocksTemplates.forEach(blockTemplate => {
      if (blockTemplate === item) return;

      blockTemplate.isExpanded = false;
    });

    this.isAnyExpanded = item.isExpanded;
  }

  public onBlockTemplateClick(blockTemplateId: number) {
    this.navigationService.toBlockTemplateChangelog(blockTemplateId);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
