import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {Observable, Subscriber, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PermissionsService} from '../../../../core/services/service-permissions/permissions/permissions.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {SubscriptionsDisabledForImportedUsersModalService} from '../../../../shared/services/modals/subscriptions-disabled-for-imported-users-modal/subscriptions-disabled-for-imported-users-modal.service';
import {SubscriptionsDisabledForCustomSubscriptionUsersModalService} from '../../../../shared/services/modals/subscriptions-disabled-for-custom-subscription-users-modal/subscriptions-disabled-for-custom-subscription-users-modal.service';

import {AppAnimations} from '../../../../app-animations';

import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {IPermissionData} from '../../../../core/models/permission/i-permission-data';

import {PERMISSIONS} from '../../../../core/services/service-permissions/constants';

const SECTIONS: {
  [key: string]: {
    title: string,
    description: string,
    path: string,
    requiredPermissions?: IPermissionData[],
    isDisabledForImportedUsers?: boolean,
    isDisabledForCustomSubscriptionUsers?: boolean,
    isDisabled?: boolean,
  },
} = {
  loginContact: {
    title: 'LOGIN + ACCOUNT INFO',
    description: 'Edit your name, email, password and address information',
    path: 'login-contact',
    isDisabledForImportedUsers: false,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: false,
  },
  purchasePlans: {
    title: 'PURCHASE — PLANS',
    description: 'Select or upgrade your subscription plan',
    path: 'purchase',
    isDisabledForImportedUsers: true,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: false,
  },
  purchase: {
    title: 'PURCHASE — ADD ONS',
    description: 'Get additional help and hire us',
    path: 'purchase-add-ons',
    isDisabledForImportedUsers: false,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: false,
  },
  creditCards: {
    title: 'CREDIT CARDS',
    description: 'Update or change your credit card',
    path: 'credit-cards',
    isDisabledForImportedUsers: false,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: false,
  },
  payments: {
    title: 'PAYMENTS',
    description: 'View your payment history',
    path: 'payments',
    isDisabledForImportedUsers: true,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: false,
  },
  support: {
    title: 'PERSONALIZED SUPPORT',
    description: 'View your support history',
    path: 'support',
    isDisabledForImportedUsers: false,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: false,
  },
  subscriptions: {
    title: 'SUBSCRIPTIONS',
    description: 'View or cancel your subscriptions',
    path: 'subscriptions',
    isDisabledForImportedUsers: true,
    isDisabledForCustomSubscriptionUsers: true,
    isDisabled: false,
  },
  domains: {
    title: 'DOMAINS',
    description: 'Setup your website domain',
    path: 'domains',
    isDisabledForImportedUsers: true,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: true,
    requiredPermissions: [
      {
        type: 'permission',
        value: PERMISSIONS.ADMIN,
      },
    ],
  },
  education: {
    title: 'EDUCATION',
    description: 'Join to class',
    path: 'education',
    isDisabledForImportedUsers: false,
    isDisabledForCustomSubscriptionUsers: false,
    isDisabled: false,
  },
};

@Component({
  selector: 'app-account-section',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class AccountSectionComponent implements OnInit, OnDestroy {
  selectedSection;

  private isCustomSubscription: boolean;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private permissionsService: PermissionsService,
    private subscriptionsDisabledForImportedUsersModalService: SubscriptionsDisabledForImportedUsersModalService,
    private subscriptionsDisabledForCustomSubscriptionUsersModalService: SubscriptionsDisabledForCustomSubscriptionUsersModalService,
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let urlSection = params['section'];

      const foundSection = Object.keys(SECTIONS).find(key => SECTIONS[key].path === urlSection);

      this.selectedSection = foundSection ? urlSection : SECTIONS.loginContact.path;
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isCustomSubscription = !!subscription && subscription.isCustom;
    });
  }

  public getSections(): {
    title: string,
    description: string,
    path: string,
    requiredPermissions?: IPermissionData[],
    isDisabledForImportedUsers?: boolean,
    isDisabledForCustomSubscriptionUsers?: boolean,
    isDisabled?: boolean,
  }[] {
    return Object.keys(SECTIONS).map(key => SECTIONS[key]);
  }

  public selectSection(section: {
    title: string,
    description: string,
    path: string,
    requiredPermissions?: IPermissionData[],
    isDisabledForImportedUsers?: boolean,
    isDisabledForCustomSubscriptionUsers?: boolean,
    isDisabled?: boolean,
  }): void {
    if (this.selectedSection === section.path) {
      return;
    }

    if (section.isDisabledForImportedUsers && this.permissionsService.isUserImported) {
      this.subscriptionsDisabledForImportedUsersModalService.open();

      return;
    }

    if (section.isDisabledForCustomSubscriptionUsers && this.isCustomSubscription) {
      this.subscriptionsDisabledForCustomSubscriptionUsersModalService.open();

      return;
    }

    this.router.navigate([
      '/app',
      {
        outlets: {
          primary: [
            'settings',
            section.path,
          ],
          sidebar: [
            'account',
            section.path,
          ],
          'over-sidebar': null,
        },
      },
    ]);
  }

  public isSectionDisabled({ isDisabled, isDisabledForImportedUsers, requiredPermissions }): Observable<boolean> {
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      if (this.permissionsService.isAdmin) {
        observer.next(false);

        return;
      }
  
      if (isDisabled) {
        observer.next(true);

        return;
      }
  
      if (!this.permissionsService.isUserHasPermissions(requiredPermissions)) {
        observer.next(true);

        return;
      }
  
      observer.next(false);
    });
  }

  public isSectionDisabledForCustomSubscriptionUsers({ isDisabledForCustomSubscriptionUsers }): Observable<boolean> {
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      if (this.permissionsService.isAdmin) {
        observer.next(false);

        return;
      }
  
      observer.next(isDisabledForCustomSubscriptionUsers && this.isCustomSubscription);
    });
  }

  public isSectionDisabledForImportedUsers({ isDisabledForImportedUsers }): Observable<boolean> {
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      if (this.permissionsService.isAdmin) {
        observer.next(false);

        return;
      }
  
      observer.next(isDisabledForImportedUsers && this.permissionsService.isUserImported);
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
