import {Injectable} from '@angular/core';

import {TitleSetupModel} from '../../../models/styles/setup/website-title/title-setup.model';

@Injectable()
export class TitleStylesService {
  public setup: TitleSetupModel;

  public isModernMenu: boolean = false;

  constructor() {
    this.setup = new TitleSetupModel();
  }

  public init(menu: HTMLElement): void {
    if (!menu) return;

    this.isModernMenu = menu.matches('[data-menu-type="modern"]');

    const title: HTMLElement = <HTMLElement>menu.querySelector('[data-website-title]');

    this.setup.init(title);
  }
}
