import {Injectable} from '@angular/core';

import {BehaviorSubject, Subscription} from 'rxjs';

import {PortfoliosHttpService} from '../../interaction/http/portfolios/portfolios-http.service';
import {SocketsService} from '../../interaction/sockets/sockets.service';
import {PermissionsService} from '../../service-permissions/permissions/permissions.service';

import {ISocketImageManagerMessageDataModel} from '../../../models/sockets/message/image-manager/i-image-manager-message-data.model';
import {ISocketImageUpdateSuggestionDataModel} from '../../../models/sockets/message/image-manager/i-image-update-suggestion-data.model';
import {INewReviewsData} from '../../../models/education/students/images/reviews/i-new-reviews-data.model';
import {IPermissionData} from '../../../models/permission/i-permission-data';

import {SOCKET_ACTIONS} from '../../../../application/main/image-manager/constants';
import {PERMISSIONS} from '../../service-permissions/constants';
import { PortfolioModel } from '../../../models/portfolios/portfolio.model';
import { StudentPortfoliosService } from '../../education/students/websites/portfolios/student-portfolios.service';

@Injectable()
export class PortfolioViewsService {
  public newReviewsDataSubject: BehaviorSubject<INewReviewsData> = new BehaviorSubject<INewReviewsData>(null);
  
  public newReviewsData: INewReviewsData = null;

  private studentPortfolio: PortfolioModel;

  private isStudent: boolean = false;

  private socketMessageHandlers = {
    [SOCKET_ACTIONS.REVIEW_UPDATED]: this.onReviewUpdatedByTeacher.bind(this),
    [SOCKET_ACTIONS.IMAGE_UPDATE_SUGGESTION_CREATE]: this.onImageUpdateSuggestionCreate.bind(this),
    [SOCKET_ACTIONS.IMAGE_UPDATE_SUGGESTION_REMOVE]: this.onImageUpdateSuggestionRemove.bind(this),
  };

  constructor(
    private socketsService: SocketsService,
    private permissionsService: PermissionsService,
    private studentPortfoliosService: StudentPortfoliosService,
    private httpService: PortfoliosHttpService,
  ) {
    this.initPermissions();

    this.socketsService.imageManagerDataSubject.subscribe((data: ISocketImageManagerMessageDataModel) => {
      if (!data || !this.socketMessageHandlers[data.action]) {
        return;
      }
  
      this.socketMessageHandlers[data.action](data);
    });

    this.studentPortfoliosService.selectedPortfolioSubject.subscribe((portfolio: PortfolioModel) => {
      this.studentPortfolio = portfolio;

      this.initForStudent();
    });
  }

  private initPermissions(): void {
    const studentPermission: IPermissionData = {
      type: 'permission',
      value: PERMISSIONS.STUDENT,
    };

    this.permissionsService.isUserHasPermissionsObservable([studentPermission], { isForbiddenForAdmins: true }).subscribe((isStudent: boolean) => {
      this.isStudent = isStudent;

      if (!this.isStudent) {
        this.updateNewReviewsData(null);

        return;
      }

      this.initForStudent();
    });
  }

  private initForStudent(): void {
    if (!this.isStudent || !this.studentPortfolio) {
      return;
    }

    this.fetchNewReviewsData({
      websiteId: this.studentPortfolio.websiteId,
    });
  }

  public fetchNewReviewsData({ websiteId }: { websiteId: number }): Subscription {
    return this.httpService.fetchNewReviewsData({ websiteId }).subscribe((res: INewReviewsData) => {
      this.newReviewsData = res;

      this.updateNewReviewsData(this.newReviewsData);
    });
  }

  private onReviewUpdatedByTeacher(data: ISocketImageManagerMessageDataModel): void {
    if (!this.newReviewsData || !this.isStudent) {
      return;
    }

    this.newReviewsData.images[data.imageId] = this.newReviewsData.images[data.imageId] || { portfolioId: data.portfolioId, isNew: true };
    this.newReviewsData.images[data.imageId].isNew = data.isAudioRecorded || data.isCommented || data.isRated || data.isImageUpdateSuggested;

    this.initPortfolioViewsData(data.portfolioId);
  }

  private onImageUpdateSuggestionCreate(data: ISocketImageUpdateSuggestionDataModel): void {
    if (!this.newReviewsData.imageUpdateSuggestions[data.imageId]) {
      this.newReviewsData.imageUpdateSuggestions[data.imageId] = {
        portfolioId: data.portfolioId,
        suggestions: {},
      };
    }

    this.newReviewsData.imageUpdateSuggestions[data.imageId].suggestions[data.suggestionId] = true;
  }
  
  private onImageUpdateSuggestionRemove(data: ISocketImageUpdateSuggestionDataModel): void {
    if (!this.newReviewsData.imageUpdateSuggestions[data.imageId]) {
      return;
    }

    this.newReviewsData.imageUpdateSuggestions[data.imageId].suggestions[data.suggestionId] = false;
  }

  public addImageToPortfolio(portfolioId: number, imageId: number): void {
    if (!this.newReviewsData || portfolioId === -1) {
      return;
    }

    if (this.newReviewsData.images[imageId]) {
      this.newReviewsData.images[imageId].portfolioId = portfolioId;
    }

    if ( this.newReviewsData.imageUpdateSuggestions[imageId]) {
      this.newReviewsData.imageUpdateSuggestions[imageId].portfolioId = portfolioId;
    }

    this.initPortfolioViewsData(portfolioId);
  }

  public removeImageFromPortfolio(portfolioId: number, imageId: number): void {
    if (!this.newReviewsData || portfolioId === -1) {
      return;
    }

    if (this.newReviewsData.images[imageId]) {
      this.newReviewsData.images[imageId].portfolioId = null;
    }

    if (this.newReviewsData.imageUpdateSuggestions[imageId]) {
      this.newReviewsData.imageUpdateSuggestions[imageId].portfolioId = null;
    }

    this.initPortfolioViewsData(portfolioId);
  }

  public viewImage(portfolioId: number, imageId: number): void {
    if (!this.newReviewsData.images[imageId]) {
      return;
    }

    this.newReviewsData.images[imageId].isNew = false;
    
    this.initPortfolioViewsData(portfolioId);
  }

  public viewUpdateSuggestions(portfolioId: number, imageId: number): void {
    if (!this.newReviewsData || !this.newReviewsData.imageUpdateSuggestions[imageId]) {
      return;
    }

    Object.keys(this.newReviewsData.imageUpdateSuggestions[imageId].suggestions).forEach((suggestionId: string) => {
      this.newReviewsData.imageUpdateSuggestions[imageId].suggestions[suggestionId] = false;
    });
    
    this.initPortfolioViewsData(portfolioId);
  }

  private initPortfolioViewsData(portfolioId: number): void {
    this.newReviewsData.portfolios[portfolioId] = this.isNewReviewInPorfolio(portfolioId);

    this.updateNewReviewsData(this.newReviewsData);
  }

  private isNewReviewInPorfolio(portfolioId: number): boolean {
    const isNewImage: boolean = Object.keys(this.newReviewsData.images).some((imageId: string) => {
      if (this.newReviewsData.images[imageId].portfolioId !== portfolioId) {
        return false;
      }
      
      return this.newReviewsData.images[imageId].isNew;
    });

    if (isNewImage) {
      return true;
    }
    
    const isNewImageUpdateSuggestion: boolean = Object.keys(this.newReviewsData.imageUpdateSuggestions).some((imageId: string) => {
      if (this.newReviewsData.imageUpdateSuggestions[imageId].portfolioId !== portfolioId) {
        return false;
      }
      
      return Object.keys(this.newReviewsData.imageUpdateSuggestions[imageId].suggestions).some((suggestionId: string) => {
        return this.newReviewsData.imageUpdateSuggestions[imageId].suggestions[suggestionId];
      });
    });

    return isNewImageUpdateSuggestion;
  }

  public updateNewReviewsData(data: INewReviewsData): void {
    this.newReviewsDataSubject.next(data);
  }
}
