import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AppAnimations } from '../../../../../app-animations';
import { WebsiteDesignerService } from '../../website-designer.service';

@Component({
  selector: 'app-top-bar-button',
  templateUrl: './top-bar-button.component.html',
  styleUrls: ['./top-bar-button.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class TopBarButtonComponent {

  // False
  @Input() altText: string;
  // Default or true
  @Input() defaultText: string;
  @Input() isActive: boolean;

  @Output() toggleHandler = new EventEmitter();

  constructor(private websiteDesignerService: WebsiteDesignerService) {}

  get buttonText() {
    return !this.isActive && this.altText ? this.altText : this.defaultText;
  }

  handleClick() {
    this.isActive = !this.isActive;
    
    this.toggleHandler.emit(this.isActive);
  }
}
