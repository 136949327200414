import {SelectedPageModel} from '../selected-page/selected-page.model';
import {OutdatedBlocksDataModel} from '../outdated-blocks-data/outdated-blocks-data.model';
import {OutdatedBlocksTemplatesModel} from '../outdated-blocks-data/templates/outdated-blocks-templates.model';
import {OutdatedBlocksPageDataModel} from '../outdated-blocks-data/templates/pages/outdated-blocks-page-data.model';
import {OutdatedBlocksPagesModel} from '../outdated-blocks-data/templates/pages/outdated-blocks-pages.model';
import {NodeModel} from '../nodes/node.model';

import {ANY_PAGE_KEY} from './constants';

export class OutdatedPagesDataModel {
  public templates: { [key: number]: Set<string> } = {}; // { templateId: pageIds[] }

  constructor(outdatedBlocksData: OutdatedBlocksDataModel) {
    Object.keys(outdatedBlocksData.blocks).forEach(blockId => {
      const blockData: OutdatedBlocksTemplatesModel = outdatedBlocksData.blocks[blockId];

      Object.keys(blockData.templates).forEach(templateId => {
        const templateData: OutdatedBlocksPagesModel = blockData.templates[templateId];

        Object.keys(templateData.pages).forEach(pageId => {
          const pageData: OutdatedBlocksPageDataModel[] = templateData.pages[pageId];

          if (!pageData || !pageData.find(block => block.isOutdated)) return;

          this.templates[templateId] = this.templates[templateId] || new Set<string>();
          this.templates[templateId].add(`${pageId}`);
        });
      });
    });
  }

  public isPageOutdated(page: NodeModel, selectedPage: SelectedPageModel): boolean {
    if (!selectedPage) return false;

    const id: string = `${page.id}`;

    const currentTemplatePages: Set<string> = this.templates[selectedPage.templateId];

    if (currentTemplatePages) {
      if (currentTemplatePages.has(id)) return true;
      if (currentTemplatePages.has(ANY_PAGE_KEY) && page.isHomePage) return true;
    }

    const anyTemplatePages: Set<string> = this.templates[ANY_PAGE_KEY];

    if (!anyTemplatePages) return false;

    if (anyTemplatePages.has(id)) return true;

    return anyTemplatePages.has(ANY_PAGE_KEY) && page.isHomePage;
  }
}
