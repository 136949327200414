export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `ཁོ་ཚོའི་སྒྲིག་ཆས་དང་ལག་ཆ་ཡོད་ཚད་གསོན་པོ་རེད།`,
    ],
    [KEYS.ALPHABET]: [
      'ཀཁགངཅཆཇཉཏཐདནཔཕབམཙཚཛཝཞཟའཡརལཤསཧཨ',
    ],
    [KEYS.PARAGRAPH]: [
      `རྒྱང་རིང་གི་སྐར་ཕུང་དེ་ནི་ལྷའི་གླུ་དབྱངས་དང་རིན་ཐང་ཅན་གྱི་རི་མོ་སོགས་རླབས་ཆེན་གྱི་སྙན་ངག་པའི་སྙན་རྩོམ་ཇི་བཞིན་སྐད་ཅིག་དེར་ང་ཚོའི་ཡིད་སེམས་འགུག་ཞིང་གཙང་སེལ་གཏོང་པ་མ་ཟད་ནམ་ཡང་བཟང་སྐུལ་བྱེད།`
    ],
    [KEYS.NUMERALS]: [
      '༠༡༢༣༤༥༦༧༨༩',
    ],
  };
};
