import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';

import {PagesService} from '../application/sidebar-short/sidebar/pages/pages.service';
import {EventsService} from '../core/services/interaction/events/events.service';
import {IFrameService} from '../core/services/iframe/iframe.service';
import {WebsitesService} from '../core/services/websites/websites.service';
import {DomainsService} from '../core/services/domains/domains.service';

import {DomainModel} from '../core/models/domain/domain.model';

@Injectable()
export class EditorControlButtonsService {
  CONTROL_BUTTONS = {
    SAVE: 'save',
    PUBLISH: 'publish',
    CANCEL: 'cancel',
    TOGGLE_SPLASH_PAGE: 'toggleSplashPage',
    CLOSE_PREVIEW: 'closePreview'
  };

  public controlBtnClick: Subject<{ buttonName: string, value: boolean }> = new Subject<{ buttonName: string, value: boolean }>();

  cancelButtonEnabled = false;

  public websiteLinkSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private pagesService: PagesService,
              private eventsService: EventsService,
              private iFrameService: IFrameService,
              private websitesService: WebsitesService,
              private domainsService: DomainsService) {
    this.domainsService.primaryDomainSubject.subscribe((domain: DomainModel) => {
      this.updateWebsiteRef(domain);
    });
  }

  private updateWebsiteRef(domain: DomainModel) {
    const websiteLink = domain ? `https://${domain.name}?isUserPreview=true` : '';

    this.websiteLinkSubject.next(websiteLink);
  }

  onBtnClick(buttonName, value?) {
    this.controlBtnClick.next({ buttonName, value });
  }
}
