import {Component, Input} from '@angular/core';

import {ModalsService} from '../../../services/modals/modals.service';
import {DragService} from '../../../../application/directives/drag-n-drop/drag.service';

import {Button} from '../../../../common/models/button/button.model';

@Component({
  selector: 'app-blocks-delete-modal',
  templateUrl: './blocks-delete-modal.component.html',
  styleUrls: ['./blocks-delete-modal.component.scss'],
})
export class BlocksDeleteModalComponent {
  @Input() id: string;

  public header = {
    className: 'warning-header',
  };

  public buttons: Button[] = [
    {
      text: `Cancel`,
      className: 'red',
      onClick: this.cancelDropHandler.bind(this),
    },
    {
      text: `Continue`,
      className: 'green',
      onClick: this.continueDropHandler.bind(this),
    },
  ];

  constructor(private modalsService: ModalsService,
              private dragService: DragService) {
  }

  public cancelDropHandler(): void {
    this.dragService.frame.contentWindow.disableDndMode();
    this.dragService.frame.contentWindow.showBlocks();
    this.dragService.frame.contentWindow.removeBlockThumb();

    this.handleDragEnd();

    this.modalsService.close(this.id);
  }

  private continueDropHandler(): void {
    this.dragService.frame.contentWindow.dropEmulation({ block: this.dragService.data });
    this.dragService.frame.contentWindow.disableDndMode();

    this.handleDragEnd();

    this.modalsService.close(this.id);
  }

  private handleDragEnd(): void {
    this.dragService.frame.style.pointerEvents = 'all';
    this.dragService.data = null;
    this.dragService.isDragging = false;
  }
}
