import {SetupOptionModel} from '../option/setup-option.model';

import { ATTRIBUTES } from './constants';

export class ImageEnlargementModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement) {
    if (!this.isEnabled || !element) return;

    this.element = element;

    this.onChange(this.getAttributeValue());
  }

  public onChange(value: boolean) {
    this.value = value;
    this.element.setAttribute(ATTRIBUTES.DATA_IS_IMAGE_ENLARGEMENT_ENABLED, value.toString());
  }

  private getAttributeValue(): boolean {
    return this.element.getAttribute(ATTRIBUTES.DATA_IS_IMAGE_ENLARGEMENT_ENABLED) === 'true';
  }
}
