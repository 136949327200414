import {Injectable} from '@angular/core';

import {Observable, BehaviorSubject, Subscription} from 'rxjs';

import {EducationTeachersHttpService} from '../../interaction/http/education/teachers/education-teachers-http.service';
import {AuthService} from '../../../../auth/auth.service';

import {PortfolioDefaultsModel} from '../../../models/images/default/portfolio-defaults.model';
import {ImageModel} from '../../../models/images/image.model';
import {EducationStudentPortfolioModel} from '../../../models/education/portfolios/education-student-portfolio.model';
import {EducationExhibitionPortfolioModel} from '../../../models/education/portfolios/education-exhibition-portfolio.model';
import {PortfolioDefaultsRequest} from '../../../models/images/default/portfolio-defaults.request';

import {EducatorImageManagerTabs} from './constants';

@Injectable()
export class EducationImageManagerService {
  public activeTabSubject: BehaviorSubject<EducatorImageManagerTabs> = new BehaviorSubject<EducatorImageManagerTabs>('user');
  
  public selectedStudentPortfolioSubject: BehaviorSubject<EducationStudentPortfolioModel> = new BehaviorSubject<EducationStudentPortfolioModel>(null);
  public studentPortfolioDefaultsSubject: BehaviorSubject<PortfolioDefaultsModel> = new BehaviorSubject<PortfolioDefaultsModel>(null);
  public studentPortfolioImagesSubject: BehaviorSubject<ImageModel[]> = new BehaviorSubject<ImageModel[]>(null);
  
  public selectedExhibitionPortfolioSubject: BehaviorSubject<EducationExhibitionPortfolioModel> = new BehaviorSubject<EducationExhibitionPortfolioModel>(null);
  public exhibitionPortfolioDefaultsSubject: BehaviorSubject<PortfolioDefaultsModel> = new BehaviorSubject<PortfolioDefaultsModel>(null);
  public exhibitionPortfolioImagesSubject: BehaviorSubject<ImageModel[]> = new BehaviorSubject<ImageModel[]>(null);

  private isLocked: boolean = false;

  constructor(
    private httpService: EducationTeachersHttpService,
    private authService: AuthService,
  ) {
    this.authService.accountSubject.subscribe(() => {
      this.selectedStudentPortfolioSubject.next(null);
      this.studentPortfolioDefaultsSubject.next(null);
      this.studentPortfolioImagesSubject.next(null);
      
      this.selectedExhibitionPortfolioSubject.next(null);
      this.exhibitionPortfolioDefaultsSubject.next(null);
      this.exhibitionPortfolioImagesSubject.next(null);
    });
  }

  public fetchStudentPortfolioDefaults(portfolioData: EducationStudentPortfolioModel): Subscription {
    return this.httpService.getStudentPortfolioDefaults(portfolioData).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      this.studentPortfolioDefaultsSubject.next(portfolioDefaults);
    });
  }

  public fetchStudentPortfolioImages(portfolioData: EducationStudentPortfolioModel): Subscription {
    return this.httpService.getStudentPortfolioImages(portfolioData).subscribe((images: ImageModel[]) => {
      this.studentPortfolioImagesSubject.next(images);
    });
  }

  public fetchExhibitionPortfolioDefaults(portfolioData: EducationExhibitionPortfolioModel): Subscription {
    return this.httpService.getExhibitionPortfolioDefaults(portfolioData).subscribe((portfolioDefaults: PortfolioDefaultsModel) => {
      this.exhibitionPortfolioDefaultsSubject.next(portfolioDefaults);
    });
  }

  public fetchExhibitionPortfolioImages(portfolioData: EducationExhibitionPortfolioModel): Subscription {
    return this.httpService.getExhibitionPortfolioImages(portfolioData).subscribe((images: ImageModel[]) => {
      this.exhibitionPortfolioImagesSubject.next(images);
    });
  }

  public updateExhibitionPortfolioDefaults(portfolioData: EducationExhibitionPortfolioModel, portfolioDefaults: PortfolioDefaultsRequest): Observable<any> {
    return this.httpService.updateExhibitionPortfolioDefaults(portfolioData, portfolioDefaults);
  }

  public replaceImage(portfolioData: EducationExhibitionPortfolioModel, imageId: number, file: File): Observable<ImageModel> {
    const formData: FormData = new FormData();
    
    formData.append('file', file, file.name);

    return this.httpService.replaceImage(portfolioData, imageId, formData);
  }

  public updateExhibitionImagePublishState(portfolioData: EducationExhibitionPortfolioModel, imageId: number, isPublished: boolean): Observable<any> {
    return this.httpService.updateExhibitionImagePublishState(portfolioData, imageId, isPublished);
  }

  public moveImageToLibrary(portfolioData: EducationExhibitionPortfolioModel, imageId: number): Observable<any> {
    return this.httpService.moveImageToLibrary(portfolioData, imageId);
  }

  public moveMultipleImagesToLibrary(portfolioData: EducationExhibitionPortfolioModel, imagesIds: number[]): Observable<any> {
    return this.httpService.moveMultipleImagesToLibrary(portfolioData, imagesIds);
  }

  public setActiveTab(key: EducatorImageManagerTabs, options: { force?: boolean, lock?: boolean, unlock?: boolean } = { force: false, lock: false, unlock: false }): void {
    if (options.unlock) {
      this.isLocked = false;
    }

    if (this.isLocked && !options.force) {
      return;
    }
    
    this.activeTabSubject.next(key);

    this.isLocked = !!options.lock;
  }
}
