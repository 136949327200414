export interface EducatorSidebarSection {
  title: string;
  path: string;
  sidebarPath: string;
}

const SECTIONS: { [key: string]: EducatorSidebarSection } = {
  LIST: {
    title: 'Students',
    path: 'students',
    sidebarPath: 'students',
  },
};

export {
  SECTIONS,
};
