import {IStyleOption} from '../../setup/option/i-style-option';
import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {DEFAULT_VALUE, PROPERTIES} from './constants';

export class MenuBlocksSpacingModel implements IStyleOption {
  public element: HTMLElement;
  
  private innerWrapper: HTMLElement;

  private isMobile: boolean;
  private isVertical: boolean;

  private property: string;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions) {
    if (!element) {
      this.isEnabled = false;

      return;
    }

    this.element = <HTMLElement>element.closest('[data-menu-block]');

    if (!this.element) {
      this.isEnabled = false;

      return;
    }

    this.innerWrapper = options.innerWrapper;

    this.isEnabled = !!this.innerWrapper;

    if (!this.isEnabled) return;

    this.isMobile = element.matches('[data-side-menu]');
    this.isVertical = this.element.matches('[data-is-menu-vertical="true"]');

    this.property = this.isVertical ? PROPERTIES.VERTICAL : PROPERTIES.HORIZONTAL;

    const value = window.getComputedStyle(this.innerWrapper).getPropertyValue(this.property);

    return this.setValue(value);
  }

  public reset() {
    if (!this.isEnabled) return;

    this.innerWrapper.style.removeProperty(this.property);

    this.setValue(window.getComputedStyle(this.innerWrapper).getPropertyValue(this.property));
  }

  public setValue(value: string) {
    if (!this.isEnabled) return;

    const numberValue: number = Number.parseInt(value);

    this.value = Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue;

    this.element.style.setProperty(this.property, `${this.value}px`);
    this.innerWrapper.style.setProperty(this.property, `${this.value}px`);
  }
}
