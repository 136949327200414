import {ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {CaptchaHttpService} from '../../../../core/services/interaction/http/captcha/captcha-http.service';
import {ContactBlockStylesService} from '../../../../core/services/styles/contact-block/contact-block-styles.service';
import {AuthService} from '../../../../auth/auth.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';
import {CaptchaDataModel} from '../../../../core/models/captcha-data/captcha-data.model';
import {Button} from '../../../../common/models/button/button.model';
import {ContactBlockSetupModel} from '../../../../core/models/styles/setup/contact-block/contact-block-setup.model';
import {StyleOptionModel} from '../../../../core/models/styles/settings/option/style-option.model';
import {StylesSettingsService} from '../../../../core/services/styles/settings/styles-settings.service';

import {HEADERS} from './constants';

@Component({
  selector: 'app-contact-block-setup-modal',
  templateUrl: './contact-block-setup-modal.component.html',
  styleUrls: ['./contact-block-setup-modal.component.scss']
})
export class ContactBlockSetupModalComponent implements OnDestroy {
  @Input() id: string;

  public modalHeader: ModalHeader = null;

  public buttons: Button[] = [
    {
      text: 'Save',
      className: 'neutral green',
      onClick: this.onSave.bind(this),
    },
  ];

  public data: CaptchaDataModel;

  public isSaved: boolean = false;
  public isOpened: boolean = false;
  public isFetched: boolean = true;
  public isCaptchaVisibilityToggled: boolean = false;
  public isInProgress: boolean = false;

  private account: AccountModel;

  private optionsMap: Map<string, StyleOptionModel> = new Map<string, StyleOptionModel>();

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isButtonsExists(): boolean {
    return !!this.buttons && this.buttons.length > 0;
  }

  public get setup(): ContactBlockSetupModel {
    return this.contactBlockStylesService.setup;
  }

  constructor(public contactBlockStylesService: ContactBlockStylesService,
              private chRef: ChangeDetectorRef,
              private buttonsService: ButtonsService,
              private modalsService: ModalsService,
              private authService: AuthService,
              private eventsService: EventsService,
              private stylesSettingsService: StylesSettingsService,
              private iFrameService: IFrameService,
              private captchaHttpService: CaptchaHttpService) {
    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;
    });

    this.stylesSettingsService.optionsMapSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((options: Map<string, StyleOptionModel>) => {
      this.optionsMap = options;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onModalOpen({ block }: { block: HTMLElement }) {
    this.isOpened = true;

    this.contactBlockStylesService.init(block, this.optionsMap);

    this.modalHeader = this.contactBlockStylesService.isPortfolio ? HEADERS.INQUIRY_MODAL : HEADERS.CONTACT_BLOCK;

    this.fetchData();
  }

  private fetchData() {
    if (!this.isFetched) return;

    if (!this.account) return console.error(`CaptchaInitModalComponent: can't fetch data.`);

    this.isFetched = false;

    this.captchaHttpService.fetchExisting(this.account.activeWebsiteId).subscribe((data: CaptchaDataModel) => {
      this.data = data;
      this.isFetched = true;
      this.chRef.detectChanges();
    });
  }

  public toggleCaptchaVisibility() {
    if (this.isCaptchaVisibilityToggled) return;

    if (!this.account || !this.data) return console.error(`CaptchaInitModalComponent: can't toggle captcha's visibility.`);

    this.isCaptchaVisibilityToggled = true;

    this.captchaHttpService.toggleCaptchaVisibility(this.account.activeWebsiteId, !this.data.isCaptchaEnabled).subscribe(() => {
      this.data.isCaptchaEnabled = !this.data.isCaptchaEnabled;

      this.chRef.detectChanges();

      this.isCaptchaVisibilityToggled = false;

      this.eventsService.dispatchCaptchaVisibilitySet({ isCaptchaEnabled: this.data.isCaptchaEnabled }, this.iFrameService.sandboxWindow);
    });
  }

  public onContactInfoUsedToggle(value: boolean): void {
    this.setup.isContactInfoUsed.onChange(value);

    this.eventsService.dispatchContactInfoUsedChange({
      key: this.setup.isContactInfoUsed.key,
      target: this.setup.isContactInfoUsed.element,
      value,
    }, this.iFrameService.sandboxWindow);

    this.buttonsService.enableSaveButton();
  }

  public onSave() {
    if (this.isInProgress || !this.isFetched) return;

    if (!this.account || !this.data) return console.error(`CaptchaInitModalComponent: can't save domains.`);

    this.isInProgress = true;

    this.captchaHttpService.saveDomains(this.account.activeWebsiteId, this.data.domains).subscribe(() => {
      this.isInProgress = false;

      this.isSaved = true;

      setTimeout(() => { this.isSaved = false }, 5000);
    });
  }

  public onClose() {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
