import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {NodesService} from '../../nodes/nodes.service';
import {AuthService} from '../../../../auth/auth.service';
import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';

import {ImagesCounterModel} from '../../../models/image-manager/counters/images-counter.model';
import {NodeModel} from '../../../models/nodes/node.model';
import {AccountModel} from '../../../models/accounts/account.model';
import {ImageDataModel} from '../../../models/image-manager/counters/image-data.model';

const LIBRARY_ID = -1;

@Injectable()
export class ImagesCounterService {
  public countersSubject: BehaviorSubject<ImagesCounterModel> = new BehaviorSubject<ImagesCounterModel>(new ImagesCounterModel());

  private portfolios: NodeModel[] = [];
  
  private librarySize: number = 0;

  private get counters(): ImagesCounterModel {
    return this.countersSubject.value;
  }

  constructor(
    private nodesService: NodesService,
    private authService: AuthService,
    private imageManagerService: ImageManagerService,
  ) {
    this.nodesService.nodesSubject.subscribe((nodes: NodeModel[]) => {
      if (!nodes) return;
      
      this.portfolios = nodes.filter(node => node.type === 'P');

      this.initCounters();
    });

    this.authService.accountSubject.subscribe(() => this.initLibrarySize());
  }

  private initLibrarySize(): void {
    this.imageManagerService.getLibrarySize().subscribe((data) => {
      this.librarySize = data ? data.size : 0;
  
      if (this.counters) this.counters.total[LIBRARY_ID] = this.librarySize;
  
      return this.initCounters();
    });
  }

  private initCounters(): void {
    if (!this.portfolios) return;

    const counters = new ImagesCounterModel();

    this.portfolios.forEach((portfolio: NodeModel) => {
      counters.published[portfolio.id] = portfolio.qty || 0;
      counters.total[portfolio.id] = portfolio.totalCount || 0;
    });

    counters.total[LIBRARY_ID] = this.counters.total[LIBRARY_ID];

    this.countersSubject.next(counters);
  }

  public onImageAdd(id: number, count: number = 1) {
    this.counters.onImageAdd(id, count);
    
    this.notifyAboutChanges();
  }

  public onImageRemove(id: number, imagesData: ImageDataModel[]) {
    this.counters.onImageRemove(id, imagesData);

    this.notifyAboutChanges();
  }

  public onAllImagesMoveToLibrary(id: number) {
    this.counters.onAllImagesMoveToLibrary(id);

    this.notifyAboutChanges();
  }

  public onAllImagesRemove(id: number) {
    this.counters.onAllImagesRemove(id);

    this.notifyAboutChanges();
  }

  public handlePublish(id: number, isPublish: boolean) {
    return isPublish ? this.onPublish(id) : this.onUnpublish(id);
  } 

  public onPublish(id: number) {
    this.counters.onPublish(id);
    
    this.notifyAboutChanges();
  }

  public onUnpublish(id: number) {
    this.counters.onUnpublish(id);

    this.notifyAboutChanges();
  }

  private notifyAboutChanges() {
    this.countersSubject.next(this.countersSubject.value);
  }
}
