export class AccountLimitsModel {
  constructor(public websites?: number,
              public pages?: number,
              public portfolios?: number,
              public imagesPrices?: number) {}

  public static clone(item: AccountLimitsModel): AccountLimitsModel {
    if (!item) return null;

    return new AccountLimitsModel(
      item.websites,
      item.pages,
      item.portfolios,
      item.imagesPrices,
    );
  }
}
