import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subscription, BehaviorSubject} from 'rxjs';

import {AdminSupportMetaHttpService} from '../../interaction/http/admin/support-meta/admin-support-meta-http.service';
import {AuthService} from '../../../../auth/auth.service';

import {SupportMetaItemModel} from '../../../models/accounts/support-meta/support-meta-item.model';

@Injectable()
export class AdminSupportMetaService {
  public customSubscriptionsSubject: BehaviorSubject<SupportMetaItemModel[]> = new BehaviorSubject<SupportMetaItemModel[]>([]);

  public createError: HttpErrorResponse = null;

  constructor(private httpService: AdminSupportMetaHttpService,
              private authService: AuthService) {
  }

  public create(userId: number, item: SupportMetaItemModel): Subscription {
    this.createError = null;

    return this.httpService.create(userId, item).subscribe(() => {
      this.authService.fetchAccountSummary(userId);
    }, (e: HttpErrorResponse) => {
      this.createError = e;
    });
  }

  public update(userId: number, item: SupportMetaItemModel): Subscription {
    return this.httpService.update(userId, item).subscribe(() => {});
  }

  public updateNotes(userId: number, item: SupportMetaItemModel): Subscription {
    return this.httpService.updateNotes(userId, item).subscribe(() => {});
  }

  public remove(userId: number, item: SupportMetaItemModel): Subscription {
    return this.httpService.remove(userId, item).subscribe(() => {
      this.authService.fetchAccountSummary(userId);
    });
  }

  public getAllForUser(userId: number, orderByKey: string, isOrderUp: boolean): Subscription {
    return this.httpService.getAllForUser(userId, orderByKey, isOrderUp).subscribe((items: SupportMetaItemModel[]) => {
      this.customSubscriptionsSubject.next(items);
    });
  }
}
