import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';

import {ModalsService} from '../../modals.service';

@Injectable()
export class ReviewDeleteConfirmationModalService {
  public readonly id: string = 'review-delete-confirmation-modal';

  public isOpened: boolean = false;

  public onSubmit: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalsService: ModalsService
  ) {
  }

  public open(): void {
    this.isOpened = true;

    this.modalsService.open(this.id);
  }

  public close(): void {
    this.isOpened = false;

    this.modalsService.close(this.id);
  }
}
