import {SetupOptionModel} from '../../../option/setup-option.model';

import {DEFAULT_VALUE, PROPERTIES} from './constants';

export class PortfolioEnlargementImageTransitionDurationModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = element;

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value = Number.parseInt(computedStyles.getPropertyValue(PROPERTIES.ANIMATION_DURATION), 10) || DEFAULT_VALUE;

    this.setValue(value.toString());
  }

  public onChange(value: string): void {
    this.setValue(value);
  }

  public setValue(value: string): void {
    this.value = value;

    this.element.style.setProperty(PROPERTIES.ANIMATION_DURATION, `${value}ms`);
  }
}
