import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalsService} from '../modals.service';
import {PaymentSubscriptionsService} from '../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {AuthService} from '../../../../auth/auth.service';

import {SubscriptionModel} from '../../../../core/models/payment/subscriptions/subscription.model';
import {AccountModel} from '../../../../core/models/accounts/account.model';

@Injectable()
export class NeedToUpgradePlanModalService {
  public readonly id = 'need-to-upgrade-plan-modal';

  public isOpened = false;

  private account: AccountModel;
  private subscription: SubscriptionModel;

  private ngUnsubscribe: Subject<boolean>;

  public constructor(
    private modalsService: ModalsService,
    private authService: AuthService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
  ) {
  }

  public open(): void {
    if (this.ngUnsubscribe) {
      this.ngUnsubscribe.next(true);
      this.ngUnsubscribe.complete();
    }

    this.ngUnsubscribe = new Subject<boolean>();

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      this.account = account;

      this.onOpen();
    });

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.subscription = subscription;

      this.onOpen();
    });
  }

  private onOpen(): void {
    if (!this.account) {
      return;
    }

    if (this.account.isAdmin) {
      this.ngUnsubscribe.next(true);
      this.ngUnsubscribe.complete();

      return;
    }

    if (!this.subscription) {
      return;
    }

    this.modalsService.open(this.id);

    this.isOpened = true;
  }

  public onCancel(): void {
    this.close();
  }

  public close(): void {
    this.isOpened = false;

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    this.modalsService.close(this.id);
  }
}
