import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AudioDto} from '../../../../models/audio/audio.dto';
import {AudioModel} from '../../../../models/audio/audio.model';

@Injectable()
export class AudiosHttpService {
  private model = 'audios';

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<AudioModel[]> {
    return this.http.get(`api/${this.model}`).pipe(
      map((res: AudioDto[]) => res.map(item => AudioDto.normalize(item)))
    );
  }

  public add(formData: FormData): Observable<AudioModel> {
    return this.http.post(`api/${this.model}`, formData).pipe(
      map((res: AudioDto) => AudioDto.normalize(res))
    );
  }

  public finishUploading(audio: AudioModel, fileName: string): Observable<AudioModel> {
    return this.http.post(`api/${this.model}`, { id: audio.id, fileName }).pipe(
      map((res: AudioDto) => AudioDto.normalize(res))
    );
  }

  public deleteMany(ids: number[]): Observable<any> {
    return this.http.request('delete', `api/${this.model}`, { body: { ids }, responseType: 'json' });
  }

  public updateName(id: number, name: string): Observable<any> {
    return this.http.request('put', `api/${this.model}/${id}/name`, { body: { name }, responseType: 'text' });
  }
}
