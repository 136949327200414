import {IStyleModelOptions} from '../../option/i-style-model-options';

import {SetupOptionModel} from '../../option/setup-option.model';

export class BasePxStyleOptionModel extends SetupOptionModel {
  public element: HTMLElement;
  public defaultValueHolderElement: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public propertyName: string,
    public propertyNamePx: string,
    public stylePropertyName?: string,
    public value?: any,
    public isEnabled: boolean = true,
    public isStylePropertyShouldBeSet: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options?: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.element = element;
    this.defaultValueHolderElement = options && options.defaultValueHolderElement ? options.defaultValueHolderElement : element;

    this.value = this.getComputed();
  }

  public reset(): void {
    this.element.style.removeProperty(this.propertyName);
    this.element.style.removeProperty(this.propertyNamePx);
    this.element.style.removeProperty(this.stylePropertyName);

    this.onChange(Number.NaN);
  }

  public onChange(value: number): void {
    this.value = !Number.isNaN(value) ? value : this.getComputed();

    if (!this.isEnabled) return;

    this.element.style.setProperty(this.propertyName, `${this.value}`);
    this.element.style.setProperty(this.propertyNamePx, `${this.value}px`);

    if (this.isStylePropertyShouldBeSet && this.stylePropertyName) this.element.style.setProperty(this.stylePropertyName, `${this.value}px`);
  }

  private getComputed(): number {
    const computedValue: CSSStyleDeclaration = window.getComputedStyle(this.defaultValueHolderElement);
    const value: number = Number.parseFloat(computedValue.getPropertyValue(this.propertyName) || computedValue[this.stylePropertyName]);

    return Number.isNaN(value) ? 0 : value;
  }
}
