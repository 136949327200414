export class BlockModel {
  public IsImageLoaded?: boolean;
  public IsDefaultPortfolio?: boolean;

  constructor(public BlockCategoryId?: number,
              public BlockID?: number,
              public PageID?: number,
              public BlockTemplateId?: number,
              public Block_Category?: string,
              public Dynamic?: boolean,
              public Name?: string,
              public Version?: number,
              public Html?: string,
              public PageType?: string,
              public UsedFonts?: string,
              public Thumb?: string,
              public ThumbFileName?: string,
              public GifThumbnail?: string,
              public IsSingle?: boolean,
              public IsSingleBlockCategory?: boolean) {}

  public static normalize(res: BlockModel): BlockModel {
    if (!res) return null;

    return new BlockModel(
      res.BlockCategoryId,
      res.BlockID,
      res.PageID,
      res.BlockTemplateId,
      res.Block_Category,
      res.Dynamic,
      res.Name,
      res.Version,
      res.Html,
      res.PageType,
      res.UsedFonts,
      res.Thumb,
      res.ThumbFileName,
      res.GifThumbnail,
      res.IsSingle,
      res.IsSingleBlockCategory,
    );
  }
}
