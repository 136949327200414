import {SetupOptionModel} from '../../../../option/setup-option.model';

import {PROPERTY, DEFAULT_VALUE} from './constants';

export class PortfolioEnlargementArrowsSizeModel extends SetupOptionModel {
  public element: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) return;

    this.element = <HTMLElement>element.closest('.portfolio-enlargement-wrapper');

    if (!this.element) return;

    const value: number = Number.parseInt(window.getComputedStyle(this.element).getPropertyValue(PROPERTY), 10) || DEFAULT_VALUE;

    this.setValue(value);
  }

  public onChange(value: number = DEFAULT_VALUE): void {
    this.setValue(value);
  }

  public reset(): void {
    if (!this.isEnabled) return;

    this.element.style.removeProperty(PROPERTY);

    const computed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.setValue(Number.parseInt(computed.getPropertyValue(PROPERTY), 10) || DEFAULT_VALUE);
  }

  public setValue(value: number): void {
    this.value = value;

    this.element.style.setProperty(PROPERTY, `${value}px`);
  }
}
