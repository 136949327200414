import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EducationInstitutionModel} from '../../../../../../models/education/institutions/education-institution.model';
import {EducationInstitutionDto} from '../../../../../../models/education/institutions/education-institution.dto';
import {EducationTeacherDto} from '../../../../../../models/education/teachers/education-teacher.dto';
import {EducationTeacherModel} from '../../../../../../models/education/teachers/education-teacher.model';
import {EducationClassModel} from '../../../../../../models/education/classes/education-class.model';
import {EducationClassDto} from '../../../../../../models/education/classes/education-class.dto';

@Injectable()
export class AdminEducationInstitutionsHttpService {
  private prefix: string = 'api/admin/education/institutions';

  constructor(private http: HttpClient) {
  }

  public create(item: EducationInstitutionModel): Observable<any> {
    return this.http.post(`${this.prefix}`, item);
  }

  public getAll(orderBy?: string, isOrderUp?: boolean): Observable<EducationInstitutionModel[]> {
    const params = new HttpParams()
      .set('orderBy', orderBy)
      .set('isOrderUp', `${isOrderUp}`);

    return this.http.get(`${this.prefix}`, { params }).pipe(
      map((res: EducationInstitutionDto[]) => res.map((item: EducationInstitutionDto) => EducationInstitutionDto.normalize(item)))
    );
  }

  public getInstitutionClasses(id: number): Observable<EducationClassModel[]> {
    return this.http.get(`${this.prefix}/${id}/classes`).pipe(
      map((items: EducationClassDto[]) => items.map((item: EducationClassDto) => EducationClassDto.normalize(item)))
    );
  }

  public getInstitutionTeachers(id: number): Observable<EducationTeacherModel[]> {
    return this.http.get(`${this.prefix}/${id}/teachers`).pipe(
      map((items: EducationTeacherDto[]) => items.map((item: EducationTeacherDto) => EducationTeacherDto.normalize(item)))
    );
  }

  public getOne(id: number): Observable<EducationInstitutionModel> {
    return this.http.get(`${this.prefix}/${id}`).pipe(
      map((item: EducationInstitutionDto) => EducationInstitutionDto.normalize(item))
    );
  }

  public update(item: EducationInstitutionModel): Observable<any> {
    return this.http.put(`${this.prefix}/${item.id}`, item);
  }

  public remove(id: number): Observable<any> {
    return this.http.delete(`${this.prefix}/${id}`);
  }
}
