import {TagModel} from './tag/tag.model';

export class PostModel {
  constructor(
    public id: number,
    public websiteId: number,
    public html: string,
    public title: string,
    public description: string,
    public isPublished: boolean,
    public publishedDate: string,
    public updatedAt: string,
    public tags?: TagModel[]) {
  }
}
