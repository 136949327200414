import {IStyleModelOptions} from '../option/i-style-model-options';

import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES, TAGS} from './constants';

export class VideoEmbedModel extends SetupOptionModel {
  public block: HTMLElement;
  public videoElement: HTMLElement;
  public embedWrapper: HTMLElement;

  public isOptionShown: boolean = false;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    if (!this.isEnabled || !element) return;

    this.block = options.block;

    this.isOptionShown = this.block && !element.matches(TAGS.VIDEO);

    if (this.isOptionShown) return this.initFromEmbed(element);

    this.videoElement = element;

    const embedKey = this.videoElement.getAttribute(ATTRIBUTES.EMBED_VIDEO_KEY);

    this.embedWrapper = <HTMLElement>this.block.querySelector(`[${ATTRIBUTES.EMBED_VIDEO_WRAPPER}="${embedKey}"]`);

    this.isOptionShown = !!this.embedWrapper;

    this.onChange(this.getCurrentValue());
  }

  private initFromEmbed(element: HTMLElement): void {
    if (!element) return;

    this.embedWrapper = element;

    const embedKey = element.getAttribute(ATTRIBUTES.EMBED_VIDEO_WRAPPER);

    this.videoElement = <HTMLElement>this.block.querySelector(`[${ATTRIBUTES.EMBED_VIDEO_KEY}="${embedKey}"]`);

    this.onChange(element.querySelector(TAGS.IFRAME).outerHTML);
  }

  public onChange(value: string): void {
    this.value = value;
  }

  private getCurrentValue(): string {
    return this.embedWrapper ? this.embedWrapper.innerHTML : '';
  }
}
