// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep {
  position: relative !important;
}
:host ::ng-deep > app-vs-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
}

.main {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.main .page-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.main .page-container .main-content {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/application/main/main.component.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;AADF;AAGE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AADJ;;AAKA;EACE,aAAA;EAEA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAHF;AAKE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAHJ;AAKI;EACE,YAAA;AAHN","sourcesContent":["\n\n:host ::ng-deep {\n  position: relative !important;\n\n  & > app-vs-loader {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1000000;\n  }\n}\n\n.main {\n  display: flex;\n\n  position: relative;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n\n  .page-container {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    position: relative;\n\n    .main-content {\n      height: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
