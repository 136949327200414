import {Injectable} from '@angular/core';

import {MobileMenuSetupModel} from '../../../models/styles/setup/menu/mobile/mobile-menu-setup.model';

import {KEYS} from './constants';
import {KEYS as MENU_STYLE_KEYS} from '../../../models/styles/setup/menu/style/constants';

@Injectable()
export class CustomMobileMenuStylesService {
  public setup: MobileMenuSetupModel;

  public get menu(): HTMLElement {
    return this.setup.menu;
  }

  private handlers = {
    [KEYS.MOBILE_MENU_STYLE]: this.onMobileMenuStyleChange.bind(this),
    [KEYS.MOBILE_MENU_DROPDOWN_ARROW]: this.onMobileMenuDropdownArrowChange.bind(this),
    [KEYS.MOBILE_MENU_POSITION]: this.onMobileMenuPositionChange.bind(this),
  };

  public get isMobileMenuCategoryExpandable(): boolean {
    return this.setup.style.value === MENU_STYLE_KEYS.EXPANDED_FLAT || this.setup.style.value === MENU_STYLE_KEYS.EXPANDED_INDENTED;
  }

  constructor() {
    this.setup = new MobileMenuSetupModel({ handlers: this.handlers });
  }

  public init(menu: HTMLElement) {
    this.setup.init(menu);
  }

  private onMobileMenuStyleChange(value: string) {
    this.setup.style.setValue(value);
  }

  private onMobileMenuDropdownArrowChange(value: string) {
    this.setup.dropdownArrow.setValue(value);
  }

  private onMobileMenuPositionChange(value: string) {
    this.setup.position.setValue(value);
  }
}
