import {Injectable} from '@angular/core';

import {BehaviorSubject, Subject} from 'rxjs';

import {CursorKeys} from './constants';

@Injectable()
export class ImageEditorCropService {
  public cursorSubject: BehaviorSubject<CursorKeys> = new BehaviorSubject<CursorKeys>('default');
  
  public resetSubject: Subject<boolean> = new Subject<boolean>();

  public constructor() {
  }
}
