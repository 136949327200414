import {IStyleOption} from '../../setup/option/i-style-option';
import {IStyleModelOptions} from '../../setup/option/i-style-model-options';

import {DEFAULT_VALUE, PROPERTY} from './constants';

export class WebsiteMarginModel implements IStyleOption {
  public innerWrapper: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.innerWrapper = options.innerWrapper;

    this.isEnabled = !!this.innerWrapper;

    if (!this.isEnabled) return;

    const value: string = window.getComputedStyle(this.innerWrapper).getPropertyValue(PROPERTY);
    const numberValue: number = Number.parseFloat(value);

    this.value = Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue * 10;
  }

  public reset(): void {
    if (!this.isEnabled) return;

    this.innerWrapper.style.removeProperty(PROPERTY);

    const value: string = window.getComputedStyle(this.innerWrapper).getPropertyValue(PROPERTY);
    const numberValue: number = Number.parseFloat(value);

    this.setValue(`${numberValue * 10}`);
  }

  public setValue(value: string): void {
    if (!this.isEnabled) return;

    const numberValue: number = Number.parseFloat(value);

    this.value = Number.isNaN(numberValue) ? DEFAULT_VALUE : numberValue;

    this.innerWrapper.style.setProperty(PROPERTY, `${this.value / 10}%`);
  }
}
