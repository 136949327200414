import {SelectOption} from '../../../models/select/option/option.model';
import {FontVariantModel} from '../../../models/font/variant/font-variant.model';
import {FontSubsetModel} from '../../../models/font/subset/font-subset.model';

import {EXAMPLES as ALL_EXAMPLES} from './languages-examples/all';
import {EXAMPLES as ARABIC_EXAMPLES} from './languages-examples/arabic';
import {EXAMPLES as BENGALI_EXAMPLES} from './languages-examples/bengali';
import {EXAMPLES as CHINA_HONGKONG_EXAMPLES} from './languages-examples/china-hongkong';
import {EXAMPLES as CHINA_SIMPLIFIED_EXAMPLES} from './languages-examples/china-simplified';
import {EXAMPLES as CHINA_TRADITIONAL_EXAMPLES} from './languages-examples/china-traditional';
import {EXAMPLES as CYRILLIC_EXAMPLES} from './languages-examples/cyrillic';
import {EXAMPLES as CYRILLIC_EXT_EXAMPLES} from './languages-examples/cyrillic-ext';
import {EXAMPLES as DEVANAGARI_EXAMPLES} from './languages-examples/devanagari';
import {EXAMPLES as GREEK_EXAMPLES} from './languages-examples/greek';
import {EXAMPLES as GREEK_EXT_EXAMPLES} from './languages-examples/greek-ext';
import {EXAMPLES as GUJARATI_EXAMPLES} from './languages-examples/gujarati';
import {EXAMPLES as GURMUKHI_EXAMPLES} from './languages-examples/gurmukhi';
import {EXAMPLES as HEBREW_EXAMPLES} from './languages-examples/hebrew';
import {EXAMPLES as JAPANESE_EXAMPLES} from './languages-examples/japanese';
import {EXAMPLES as KANNADA_EXAMPLES} from './languages-examples/kannada';
import {EXAMPLES as KHMER_EXAMPLES} from './languages-examples/khmer';
import {EXAMPLES as KOREAN_EXAMPLES} from './languages-examples/korean';
import {EXAMPLES as LATIN_EXAMPLES} from './languages-examples/latin';
import {EXAMPLES as LATIN_EXT_EXAMPLES} from './languages-examples/latin-ext';
import {EXAMPLES as MALAYALAM_EXAMPLES} from './languages-examples/malayalam';
import {EXAMPLES as MYANMAR_EXAMPLES} from './languages-examples/myanmar';
import {EXAMPLES as ORIYA_EXAMPLES} from './languages-examples/oriya';
import {EXAMPLES as SINHALA_EXAMPLES} from './languages-examples/sinhala';
import {EXAMPLES as TAMIL_EXAMPLES} from './languages-examples/tamil';
import {EXAMPLES as TELUGU_EXAMPLES} from './languages-examples/telugu';
import {EXAMPLES as THAI_EXAMPLES} from './languages-examples/thai';
import {EXAMPLES as TIBETAN_EXAMPLES} from './languages-examples/tibetan';
import {EXAMPLES as VIETNAMESE_EXAMPLES} from './languages-examples/vietnamese';

export const FONT_BASE = 'https://fonts.googleapis.com/css?family=';

export const CONTENT_TYPE_KEYS = {
  SENTENCE: 'SENTENCE',
  ALPHABET: 'ALPHABET',
  PARAGRAPH: 'PARAGRAPH',
  NUMERALS: 'NUMERALS',
  CUSTOM: 'CUSTOM',
};

export const SUBSETS_KEYS = {
  ALL: 'all',
  ARABIC: 'arabic',
  BENGALI: 'bengali',
  CHINA_HONGKONG: 'chinese-hongkong',
  CHINA_SIMPLIFIED: 'chinese-simplified',
  CHINA_TRADITIONAL: 'chinese-traditional',
  CYRILLIC: 'cyrillic',
  CYRILLIC_EXT: 'cyrillic-ext',
  DEVANAGARI: 'devanagari',
  GREEK: 'greek',
  GREEK_EXT: 'greek-ext',
  GUJARATI: 'gujarati',
  GURMUKHI: 'gurmukhi',
  HEBREW: 'hebrew',
  JAPANESE: 'japanese',
  KANNADA: 'kannada',
  KHMER: 'khmer',
  KOREAN: 'korean',
  LATIN: 'latin',
  LATIN_EXT: 'latin-ext',
  MALAYALAM: 'malayalam',
  MYANMAR: 'myanmar',
  ORIYA: 'oriya',
  SINHALA: 'sinhala',
  TAMIL: 'tamil',
  TELUGU: 'telugu',
  THAI: 'thai',
  TIBETAN: 'tibetan',
  VIETNAMESE: 'vietnamese',
};

export const SUBSETS_MODELS: { [key: string]: FontSubsetModel } = {
  [SUBSETS_KEYS.ARABIC]: new FontSubsetModel(SUBSETS_KEYS.ARABIC, 'Arabic'),
  [SUBSETS_KEYS.BENGALI]: new FontSubsetModel(SUBSETS_KEYS.BENGALI, 'Bengali'),
  [SUBSETS_KEYS.CHINA_HONGKONG]: new FontSubsetModel(SUBSETS_KEYS.CHINA_HONGKONG, 'Chinese (Hong Kong)'),
  [SUBSETS_KEYS.CHINA_SIMPLIFIED]: new FontSubsetModel(SUBSETS_KEYS.CHINA_SIMPLIFIED, 'Chinese (Simplified)'),
  [SUBSETS_KEYS.CHINA_TRADITIONAL]: new FontSubsetModel(SUBSETS_KEYS.CHINA_TRADITIONAL, 'Chinese (Traditional)'),
  [SUBSETS_KEYS.CYRILLIC]: new FontSubsetModel(SUBSETS_KEYS.CYRILLIC, 'Cyrillic'),
  [SUBSETS_KEYS.CYRILLIC_EXT]: new FontSubsetModel(SUBSETS_KEYS.CYRILLIC_EXT, 'Cyrillic Extended'),
  [SUBSETS_KEYS.DEVANAGARI]: new FontSubsetModel(SUBSETS_KEYS.DEVANAGARI, 'Devanagari'),
  [SUBSETS_KEYS.GREEK]: new FontSubsetModel(SUBSETS_KEYS.GREEK, 'Greek'),
  [SUBSETS_KEYS.GREEK_EXT]: new FontSubsetModel(SUBSETS_KEYS.GREEK_EXT, 'Greek Extended'),
  [SUBSETS_KEYS.GUJARATI]: new FontSubsetModel(SUBSETS_KEYS.GUJARATI, 'Gujarati'),
  [SUBSETS_KEYS.GURMUKHI]: new FontSubsetModel(SUBSETS_KEYS.GURMUKHI, 'Gurmukhi'),
  [SUBSETS_KEYS.HEBREW]: new FontSubsetModel(SUBSETS_KEYS.HEBREW, 'Hebrew'),
  [SUBSETS_KEYS.JAPANESE]: new FontSubsetModel(SUBSETS_KEYS.JAPANESE, 'Japanese'),
  [SUBSETS_KEYS.KANNADA]: new FontSubsetModel(SUBSETS_KEYS.KANNADA, 'Kannada'),
  [SUBSETS_KEYS.KHMER]: new FontSubsetModel(SUBSETS_KEYS.KHMER, 'Khmer'),
  [SUBSETS_KEYS.KOREAN]: new FontSubsetModel(SUBSETS_KEYS.KOREAN, 'Korean'),
  [SUBSETS_KEYS.LATIN]: new FontSubsetModel(SUBSETS_KEYS.LATIN, 'Latin'),
  [SUBSETS_KEYS.LATIN_EXT]: new FontSubsetModel(SUBSETS_KEYS.LATIN_EXT, 'Latin Extended'),
  [SUBSETS_KEYS.MALAYALAM]: new FontSubsetModel(SUBSETS_KEYS.MALAYALAM, 'Malayalam'),
  [SUBSETS_KEYS.MYANMAR]: new FontSubsetModel(SUBSETS_KEYS.MYANMAR, 'Myanmar'),
  [SUBSETS_KEYS.ORIYA]: new FontSubsetModel(SUBSETS_KEYS.ORIYA, 'Oriya'),
  [SUBSETS_KEYS.SINHALA]: new FontSubsetModel(SUBSETS_KEYS.SINHALA, 'Sinhala'),
  [SUBSETS_KEYS.TAMIL]: new FontSubsetModel(SUBSETS_KEYS.TAMIL, 'Tamil'),
  [SUBSETS_KEYS.TELUGU]: new FontSubsetModel(SUBSETS_KEYS.TELUGU, 'Telugu'),
  [SUBSETS_KEYS.THAI]: new FontSubsetModel(SUBSETS_KEYS.THAI, 'Thai'),
  [SUBSETS_KEYS.TIBETAN]: new FontSubsetModel(SUBSETS_KEYS.TIBETAN, 'Tibetan'),
  [SUBSETS_KEYS.VIETNAMESE]: new FontSubsetModel(SUBSETS_KEYS.VIETNAMESE, 'Vietnamese'),
};

export const SUBSETS_ORDER: string[] = [
  SUBSETS_KEYS.ARABIC,
  SUBSETS_KEYS.BENGALI,
  SUBSETS_KEYS.CHINA_HONGKONG,
  SUBSETS_KEYS.CHINA_SIMPLIFIED,
  SUBSETS_KEYS.CHINA_TRADITIONAL,
  SUBSETS_KEYS.CYRILLIC,
  SUBSETS_KEYS.CYRILLIC_EXT,
  SUBSETS_KEYS.DEVANAGARI,
  SUBSETS_KEYS.GREEK,
  SUBSETS_KEYS.GREEK_EXT,
  SUBSETS_KEYS.GUJARATI,
  SUBSETS_KEYS.GURMUKHI,
  SUBSETS_KEYS.HEBREW,
  SUBSETS_KEYS.JAPANESE,
  SUBSETS_KEYS.KANNADA,
  SUBSETS_KEYS.KHMER,
  SUBSETS_KEYS.KOREAN,
  SUBSETS_KEYS.LATIN,
  SUBSETS_KEYS.LATIN_EXT,
  SUBSETS_KEYS.MALAYALAM,
  SUBSETS_KEYS.MYANMAR,
  SUBSETS_KEYS.ORIYA,
  SUBSETS_KEYS.SINHALA,
  SUBSETS_KEYS.TAMIL,
  SUBSETS_KEYS.TELUGU,
  SUBSETS_KEYS.THAI,
  SUBSETS_KEYS.TIBETAN,
  SUBSETS_KEYS.VIETNAMESE,
];

export const VARIANTS_KEYS = {
  REGULAR: 'regular',
  ITALIC: 'italic',
  REGULAR_100: '100',
  ITALIC_100: '100italic',
  REGULAR_200: '200',
  ITALIC_200: '200italic',
  REGULAR_300: '300',
  ITALIC_300: '300italic',
  REGULAR_500: '500',
  ITALIC_500: '500italic',
  REGULAR_600: '600',
  ITALIC_600: '600italic',
  REGULAR_700: '700',
  ITALIC_700: '700italic',
  REGULAR_800: '800',
  ITALIC_800: '800italic',
  REGULAR_900: '900',
  ITALIC_900: '900italic',
};

export const VARIANTS = () => {
  return {
    [VARIANTS_KEYS.REGULAR_100]: new SelectOption('Thin 100', VARIANTS_KEYS.REGULAR_100),
    [VARIANTS_KEYS.ITALIC_100]: new SelectOption('Thin 100 Italic', VARIANTS_KEYS.ITALIC_100),
    [VARIANTS_KEYS.REGULAR_200]: new SelectOption('Extra-Light 200', VARIANTS_KEYS.REGULAR_200),
    [VARIANTS_KEYS.ITALIC_200]: new SelectOption('Extra-Light 200 Italic', VARIANTS_KEYS.ITALIC_200),
    [VARIANTS_KEYS.REGULAR_300]: new SelectOption('Light 300', VARIANTS_KEYS.REGULAR_300),
    [VARIANTS_KEYS.ITALIC_300]: new SelectOption('Light 300 Italic', VARIANTS_KEYS.ITALIC_300),
    [VARIANTS_KEYS.REGULAR]: new SelectOption('Regular 400', VARIANTS_KEYS.REGULAR),
    [VARIANTS_KEYS.ITALIC]: new SelectOption('Regular 400 Italic', VARIANTS_KEYS.ITALIC),
    [VARIANTS_KEYS.REGULAR_500]: new SelectOption('Medium 500', VARIANTS_KEYS.REGULAR_500),
    [VARIANTS_KEYS.ITALIC_500]: new SelectOption('Medium 500 Italic', VARIANTS_KEYS.ITALIC_500),
    [VARIANTS_KEYS.REGULAR_600]: new SelectOption('Semi-Bold 600', VARIANTS_KEYS.REGULAR_600),
    [VARIANTS_KEYS.ITALIC_600]: new SelectOption('Semi-Bold 600 Italic', VARIANTS_KEYS.ITALIC_600),
    [VARIANTS_KEYS.REGULAR_700]: new SelectOption('Bold 700', VARIANTS_KEYS.REGULAR_700),
    [VARIANTS_KEYS.ITALIC_700]: new SelectOption('Bold 700 Italic', VARIANTS_KEYS.ITALIC_700),
    [VARIANTS_KEYS.REGULAR_800]: new SelectOption('Extra-Bold 800', VARIANTS_KEYS.REGULAR_800),
    [VARIANTS_KEYS.ITALIC_800]: new SelectOption('Extra-Bold 800 Italic', VARIANTS_KEYS.ITALIC_800),
    [VARIANTS_KEYS.REGULAR_900]: new SelectOption('Black 900', VARIANTS_KEYS.REGULAR_900),
    [VARIANTS_KEYS.ITALIC_900]: new SelectOption('Black 900 Italic', VARIANTS_KEYS.ITALIC_900),
  };
};

export const VARIANTS_MODELS: { [key: string]: FontVariantModel } = {
  [VARIANTS_KEYS.REGULAR_100]: new FontVariantModel(VARIANTS_KEYS.REGULAR_100, 'Thin', 100, 'normal'),
  [VARIANTS_KEYS.ITALIC_100]: new FontVariantModel(VARIANTS_KEYS.ITALIC_100, 'Thin Italic', 100, 'italic'),
  [VARIANTS_KEYS.REGULAR_200]: new FontVariantModel(VARIANTS_KEYS.REGULAR_200, 'Extra-Light', 200, 'normal'),
  [VARIANTS_KEYS.ITALIC_200]: new FontVariantModel(VARIANTS_KEYS.ITALIC_200, 'Extra-Light Italic', 200, 'italic'),
  [VARIANTS_KEYS.REGULAR_300]: new FontVariantModel(VARIANTS_KEYS.REGULAR_300, 'Light', 300, 'normal'),
  [VARIANTS_KEYS.ITALIC_300]: new FontVariantModel(VARIANTS_KEYS.ITALIC_300, 'Light Italic', 300, 'italic'),
  [VARIANTS_KEYS.REGULAR]: new FontVariantModel(VARIANTS_KEYS.REGULAR, 'Regular', 400, 'normal'),
  [VARIANTS_KEYS.ITALIC]: new FontVariantModel(VARIANTS_KEYS.ITALIC, 'Regular Italic', 400, 'italic'),
  [VARIANTS_KEYS.REGULAR_500]: new FontVariantModel(VARIANTS_KEYS.REGULAR_500, 'Medium', 500, 'normal'),
  [VARIANTS_KEYS.ITALIC_500]: new FontVariantModel(VARIANTS_KEYS.ITALIC_500, 'Medium Italic', 500, 'italic'),
  [VARIANTS_KEYS.REGULAR_600]: new FontVariantModel(VARIANTS_KEYS.REGULAR_600, 'Semi-Bold', 600, 'normal'),
  [VARIANTS_KEYS.ITALIC_600]: new FontVariantModel(VARIANTS_KEYS.ITALIC_600, 'Semi-Bold Italic', 600, 'italic'),
  [VARIANTS_KEYS.REGULAR_700]: new FontVariantModel(VARIANTS_KEYS.REGULAR_700, 'Bold', 700, 'normal'),
  [VARIANTS_KEYS.ITALIC_700]: new FontVariantModel(VARIANTS_KEYS.ITALIC_700, 'Bold Italic', 700, 'italic'),
  [VARIANTS_KEYS.REGULAR_800]: new FontVariantModel(VARIANTS_KEYS.REGULAR_800, 'Extra-Bold', 800, 'normal'),
  [VARIANTS_KEYS.ITALIC_800]: new FontVariantModel(VARIANTS_KEYS.ITALIC_800, 'Extra-Bold Italic', 800, 'italic'),
  [VARIANTS_KEYS.REGULAR_900]: new FontVariantModel(VARIANTS_KEYS.REGULAR_900, 'Black', 900, 'normal'),
  [VARIANTS_KEYS.ITALIC_900]: new FontVariantModel(VARIANTS_KEYS.ITALIC_900, 'Black Italic', 900, 'italic'),
};

export const VARIANTS_MAPPING: { [key: string]: string } = {
  [VARIANTS_KEYS.REGULAR_100]: '100',
  [VARIANTS_KEYS.ITALIC_100]: '100',
  [VARIANTS_KEYS.REGULAR_200]: '200',
  [VARIANTS_KEYS.ITALIC_200]: '200',
  [VARIANTS_KEYS.REGULAR_300]: '300',
  [VARIANTS_KEYS.ITALIC_300]: '300',
  [VARIANTS_KEYS.REGULAR]: '400',
  [VARIANTS_KEYS.ITALIC]: '400',
  [VARIANTS_KEYS.REGULAR_500]: '500',
  [VARIANTS_KEYS.ITALIC_500]: '500',
  [VARIANTS_KEYS.REGULAR_600]: '600',
  [VARIANTS_KEYS.ITALIC_600]: '600',
  [VARIANTS_KEYS.REGULAR_700]: '700',
  [VARIANTS_KEYS.ITALIC_700]: '700',
  [VARIANTS_KEYS.REGULAR_800]: '800',
  [VARIANTS_KEYS.ITALIC_800]: '800',
  [VARIANTS_KEYS.REGULAR_900]: '900',
  [VARIANTS_KEYS.ITALIC_900]: '900',
};

export const VARIANTS_MAPPING_NUMBERS: { [key: string]: number } = {
  [VARIANTS_KEYS.REGULAR_100]: 100,
  [VARIANTS_KEYS.ITALIC_100]: 100,
  [VARIANTS_KEYS.REGULAR_200]: 200,
  [VARIANTS_KEYS.ITALIC_200]: 200,
  [VARIANTS_KEYS.REGULAR_300]: 300,
  [VARIANTS_KEYS.ITALIC_300]: 300,
  [VARIANTS_KEYS.REGULAR]: 400,
  [VARIANTS_KEYS.ITALIC]: 400,
  [VARIANTS_KEYS.REGULAR_500]: 500,
  [VARIANTS_KEYS.ITALIC_500]: 500,
  [VARIANTS_KEYS.REGULAR_600]: 600,
  [VARIANTS_KEYS.ITALIC_600]: 600,
  [VARIANTS_KEYS.REGULAR_700]: 700,
  [VARIANTS_KEYS.ITALIC_700]: 700,
  [VARIANTS_KEYS.REGULAR_800]: 800,
  [VARIANTS_KEYS.ITALIC_800]: 800,
  [VARIANTS_KEYS.REGULAR_900]: 900,
  [VARIANTS_KEYS.ITALIC_900]: 900,
};

export const VARIANTS_ORDER: string[] = [
  VARIANTS_KEYS.REGULAR_100,
  VARIANTS_KEYS.ITALIC_100,
  VARIANTS_KEYS.REGULAR_200,
  VARIANTS_KEYS.ITALIC_200,
  VARIANTS_KEYS.REGULAR_300,
  VARIANTS_KEYS.ITALIC_300,
  VARIANTS_KEYS.REGULAR,
  VARIANTS_KEYS.ITALIC,
  VARIANTS_KEYS.REGULAR_500,
  VARIANTS_KEYS.ITALIC_500,
  VARIANTS_KEYS.REGULAR_600,
  VARIANTS_KEYS.ITALIC_600,
  VARIANTS_KEYS.REGULAR_700,
  VARIANTS_KEYS.ITALIC_700,
  VARIANTS_KEYS.REGULAR_800,
  VARIANTS_KEYS.ITALIC_800,
  VARIANTS_KEYS.REGULAR_900,
  VARIANTS_KEYS.ITALIC_900,
];

export const FONT_SIZE_OPTIONS = {
  [CONTENT_TYPE_KEYS.SENTENCE]: 40,
  [CONTENT_TYPE_KEYS.ALPHABET]: 40,
  [CONTENT_TYPE_KEYS.PARAGRAPH]: 24,
  [CONTENT_TYPE_KEYS.NUMERALS]: 24,
  [CONTENT_TYPE_KEYS.CUSTOM]: 24,
};

export const DEFAULT_FONT_SIZE = 40;
export const DEFAULT_LINE_HEIGHT = 1.2;

export const STRINGS_SETS = {
  [SUBSETS_KEYS.ALL]: ALL_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.ARABIC]: ARABIC_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.BENGALI]: BENGALI_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.CHINA_HONGKONG]: CHINA_HONGKONG_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.CHINA_SIMPLIFIED]: CHINA_SIMPLIFIED_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.CHINA_TRADITIONAL]: CHINA_TRADITIONAL_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.CYRILLIC]: CYRILLIC_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.CYRILLIC_EXT]: CYRILLIC_EXT_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.DEVANAGARI]: DEVANAGARI_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.GREEK]: GREEK_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.GREEK_EXT]: GREEK_EXT_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.GUJARATI]: GUJARATI_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.GURMUKHI]: GURMUKHI_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.HEBREW]: HEBREW_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.JAPANESE]: JAPANESE_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.KANNADA]: KANNADA_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.KHMER]: KHMER_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.KOREAN]: KOREAN_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.LATIN]: LATIN_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.LATIN_EXT]: LATIN_EXT_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.MALAYALAM]: MALAYALAM_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.MYANMAR]: MYANMAR_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.ORIYA]: ORIYA_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.SINHALA]: SINHALA_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.TAMIL]: TAMIL_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.TELUGU]: TELUGU_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.THAI]: THAI_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.TIBETAN]: TIBETAN_EXAMPLES(CONTENT_TYPE_KEYS),
  [SUBSETS_KEYS.VIETNAMESE]: VIETNAMESE_EXAMPLES(CONTENT_TYPE_KEYS),
};

export const DIRECTIONS = {
  LEFT_TO_RIGHT: 'ltr',
  RIGHT_TO_LEFT: 'rtl',
};

export const SUBSETS_DIRECTIONS = {
  [SUBSETS_KEYS.ALL]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.ARABIC]: DIRECTIONS.RIGHT_TO_LEFT,
  [SUBSETS_KEYS.BENGALI]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.CHINA_HONGKONG]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.CHINA_SIMPLIFIED]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.CHINA_TRADITIONAL]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.CYRILLIC]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.CYRILLIC_EXT]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.DEVANAGARI]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.GREEK]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.GREEK_EXT]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.GUJARATI]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.GURMUKHI]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.HEBREW]: DIRECTIONS.RIGHT_TO_LEFT,
  [SUBSETS_KEYS.JAPANESE]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.KANNADA]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.KHMER]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.KOREAN]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.LATIN]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.LATIN_EXT]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.MALAYALAM]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.MYANMAR]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.ORIYA]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.SINHALA]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.TAMIL]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.TELUGU]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.THAI]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.TIBETAN]: DIRECTIONS.LEFT_TO_RIGHT,
  [SUBSETS_KEYS.VIETNAMESE]: DIRECTIONS.LEFT_TO_RIGHT,
};
