import {Component, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';

import {Chart, ChartConfiguration} from 'chart.js';

import {PLUGINS} from './constants';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') canvas: ElementRef;

  @Input() width: number = 300;
  @Input() height: number = 300;
  @Input() label: string;
  @Input() labels: string[];
  @Input() data: number[];
  @Input() backgroundColor: string[];
  @Input() borderWidth: number;
  @Input() cutout: number = 50;

  private chart: Chart;
  private config: ChartConfiguration;

  constructor() {
  }

  public ngAfterViewInit(): void {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

    this.config = {
      type: 'doughnut',
      data: {
        labels: this.labels,
        datasets: [{
          label: this.label,
          data: this.data,
          backgroundColor: this.backgroundColor,
          borderWidth: this.borderWidth,
        }]
      },
      options: {
        cutoutPercentage: this.cutout,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          enabled: false,
        },
        hover: {
          mode: null,
        },
        layout: {
          padding: 7,
        },
      },
      plugins: [
        PLUGINS.SHADOW,
      ],
    };

    this.chart = new Chart(ctx, this.config);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.config) return;

    if (changes['data']) {
      this.config.data.datasets[0].data = this.data;

      this.chart.update();
    }

    if (changes['backgroundColor']) {
      this.config.data.datasets[0].backgroundColor = this.backgroundColor;

      this.chart.update();
    }
  }
}
