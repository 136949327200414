export class DocumentModel {
  public sizeFormatted: string = null;

  public isError: boolean = false;

  constructor(public id?: number,
              public userId?: number,
              public fileName?: string,
              public thumbFileName?: string,
              public originalFileName?: string,
              public link?: string,
              public thumbLink?: string,
              public fileSize?: string,
              public isFirstClicked: boolean = false,
              public isSelected: boolean = false,
              public isUploading: boolean = false,
              public isThumbLoaded: boolean = false,
              public isDeleting: boolean = false,
              public percent: string = '') {
    this.initSize();
  }

  private initSize(): void {
    if (!this.fileSize) return;

    try {
      const size = Number.parseInt(this.fileSize);

      if (Number.isNaN(size)) return;

      this.sizeFormatted = `${Math.round((size / 1048576 + Number.EPSILON) * 10) / 10} MB`;
    } catch (e) {}
  }
}
