import { SelectOption } from '../../../select/option/option.model';
import {SetupOptionModel} from '../option/setup-option.model';

import {ATTRIBUTES, DEFAULT_TYPE, DEFAULT_POSITION, OPTIONS} from './constants';

export class ImageLabelButtonModel extends SetupOptionModel {
  public element: HTMLElement;

  public position: string = DEFAULT_POSITION;

  private customSetup: { enabledOptions: string[] };

  public get optionsList(): SelectOption[] {
    if (!this.customSetup || !this.customSetup.enabledOptions) {
      return OPTIONS;
    }

    return OPTIONS.filter(option => {
      return this.customSetup.enabledOptions.includes(option.value);
    });
  }

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled || !element) {
      this.element = null;
      this.customSetup = null;

      return;
    }

    this.element = element;

    this.value = this.element.getAttribute(ATTRIBUTES.TYPE) || DEFAULT_TYPE;
    this.position = this.element.getAttribute(ATTRIBUTES.POSITION) || DEFAULT_POSITION;

    this.initCustomSetup();
  }

  private initCustomSetup(): void {
    try {
      this.customSetup = JSON.parse(this.element.getAttribute(ATTRIBUTES.CUSTOM_SETUP));
    } catch (e) {
      this.customSetup = null;
    }
  }

  public onTypeChange(type: string): void {
    this.value = type;

    this.element.setAttribute(ATTRIBUTES.TYPE, type);
  }

  public onTypeReset(): void {
    this.value = DEFAULT_TYPE;

    this.element.setAttribute(ATTRIBUTES.TYPE, DEFAULT_TYPE);
  }

  public onPositionChange(position: string): void {
    this.position = position;

    this.element.setAttribute(ATTRIBUTES.POSITION, position);
  }

  public onPositionReset(): void {
    this.position = DEFAULT_POSITION;

    this.element.setAttribute(ATTRIBUTES.POSITION, DEFAULT_POSITION);
  }
}
