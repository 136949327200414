import {BaseColorRgbModel} from '../../../base/color-rgb/base-color-rgb.model';

import {CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB} from './constants';

export class PortfolioEnlargementBackgroundColorModel extends BaseColorRgbModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, null, CUSTOM_BACKGROUND_COLOR, CUSTOM_BACKGROUND_COLOR_RGB, value, isEnabled);
  }

  public init(selectedElement: HTMLElement): void {
    if (!this.isEnabled || !selectedElement) return;

    super.init(selectedElement, {
      selectedElement,
      defaultValueHolderElement: selectedElement,
    });
  }
}
