import {TemplatePreviewDescriptionModel} from './template-preview-description.model';

export class TemplatePreviewDescriptionDto {
  constructor(public Id?: number,
              public Text?: string) {}

  public static normalize(res: TemplatePreviewDescriptionDto): TemplatePreviewDescriptionModel {
    if (!res) return null;

    return new TemplatePreviewDescriptionModel(
      res.Id,
      res.Text,
    );
  }
}
