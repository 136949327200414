import {SectionModel} from '../../models/media/section/section.model';

export const SECTIONS_KEYS = {
  VIDEOS: 'VIDEOS',
  AUDIOS: 'AUDIOS',
  DOCUMENTS: 'DOCUMENTS',
  LOGOS: 'LOGOS',
};

export const SECTIONS = [
  new SectionModel(SECTIONS_KEYS.VIDEOS, 'VIDEOS', 'Click any video icon to select it or upload a new video.'),
  new SectionModel(SECTIONS_KEYS.AUDIOS, 'AUDIO', 'Click any audio icon to select it or upload a new audio.'),
  new SectionModel(SECTIONS_KEYS.DOCUMENTS, 'PDFs', 'Click any PDF icon to select it or upload a new PDF.'),
  new SectionModel(SECTIONS_KEYS.LOGOS, 'LOGOS', 'Click any LOGO to select it or upload a new logo.'),
];
