export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `他們所有的設備和儀器彷彿都是有生命的。`,
    ],
    [KEYS.ALPHABET]: [
      '一二三四五六七八九十百千萬上中下左右大小春夏秋冬東南西北金木水火土天地日月星黑白紅橙黃綠藍靛紫食住衣行育樂忠孝仁愛信義和平子曰父母兄弟夫婦君臣馬牛羊雞犬豕喜怒哀懼惡目耳口手足見聞聲貝車雨赤青言語魚鳥羽電不乃之乎人以何俱倫儀先光入具初則匏協去友同名善器嚴執孟孫學宜容專少山師席常幼序從性恩恭情惰應成所才揚擇教敬數文斷方於族昔時智曾有朋本杼某梨機次欲此歲溫為燕玄玉琢畜當相知石祖禮稷稻穀窮竇竹粱紊絲綱習老者而能自至與苟菽處融親調識讓貴身近運過道遠遷鄰長非革音順飼養首香高麥黍齡思源谷歌 ?“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*',
    ],
    [KEYS.PARAGRAPH]: [
      `遠方的星體猶如聖樂、名畫或偉大詩人的詩句，一眼瞬間就能讓我們的思想昇華與淨化。它總是能給人帶來好的影響。`
    ],
    [KEYS.NUMERALS]: [
      '1234567890',
    ],
  };
};
