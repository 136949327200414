import {AddOnDto} from './add-on.dto';
import {PlanModel} from '../plan/plan.model';
import {PriceAmountModel} from '../price-amount/price-amount.model';

export class AddOnModel {
  public roundAmount?: number;
  public centsAmount?: number;
  public formattedAmount?: string;

  public discountedAmount?: number;
  public discountedRoundAmount?: number;
  public discountedCentsAmount?: number;
  public discountedFormattedAmount?: string;

  public isAddedToCart?: boolean;

  constructor(public id?: string,
              public key?: string,
              public name?: string,
              public amount?: number,
              public stripeProductId?: string,
              public stripePriceId?: string,
              public recurringType?: string,
              public includedSupportTimeMin?: number,
              public supportPlanDiscount?: number,
              public isEnabled?: boolean,
              public isPurchasable?: boolean,
              public isHidden?: boolean) {
    this.roundAmount = Math.floor(this.amount);
    this.centsAmount = Number((this.amount * 100).toFixed()) % 100;
    this.formattedAmount = `$${this.roundAmount}.${`${this.centsAmount}`.padStart(2, '0')}`;

    this.discountedAmount = this.amount * ((100 - this.supportPlanDiscount) / 100);
    this.discountedRoundAmount = Math.floor(this.discountedAmount);
    this.discountedCentsAmount = Number((this.amount * (100 - this.supportPlanDiscount)).toFixed()) % 100;
    this.discountedFormattedAmount = `$${this.discountedRoundAmount}.${`${this.discountedCentsAmount}`.padStart(2, '0')}`;
  }

  public static normalize(res: AddOnDto): AddOnModel {
    if (!res) return null;

    return new AddOnModel(
      res.Id,
      res.Key,
      res.Name,
      res.Amount / 100,
      res.StripeProductId,
      res.StripePriceId,
      res.RecurringType,
      res.IncludedSupportTimeMin,
      res.SupportPlanDiscount,
      res.IsEnabled,
      res.IsPurchasable,
      res.IsHidden,
    );
  }

  public getAmountForPlan(plan: PlanModel): PriceAmountModel {
    if (!plan || !plan.isSupportPlan) {
      return new PriceAmountModel(
        this.amount,
        this.roundAmount,
        this.centsAmount,
        this.formattedAmount,
      );
    }

    return new PriceAmountModel(
      Number((this.amount * (100 - this.supportPlanDiscount) / 100).toFixed(2)),
      this.discountedRoundAmount,
      this.discountedCentsAmount,
      this.discountedFormattedAmount,
    );
  }
}
