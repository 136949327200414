import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {Observable, Subject, Subscription, of, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import { AppAnimations } from '../../../../../app-animations';
import { ImageManagerService } from '../../../../../application/main/image-manager/image-manager.service';
import { BagService } from '../../../../../bag.service';

import * as $ from 'jquery';
import 'jqueryui';
import { Dropzone } from 'dropzone';
import * as loadImage from 'blueimp-load-image';
import {ModalsService} from '../../../../services/modals/modals.service';
import {IMAGE_SIZE} from '../../../../util/formatting/image';
import * as _ from 'lodash';
import Sortable = JQueryUI.Sortable;

import {ImageResolutionService} from '../../../../../services/image-resolution.service';
import {ImageManagerModalService} from '../image-manager-modal.service';
import {ImageUploadService} from '../../../../../core/services/image-manager/upload/image-upload.service';
import {ButtonsService} from '../../../../../core/services/buttons/buttons.service';
import {ImageManagerModalFetchService} from '../../../../../core/services/image-manager/modal/image-manager-modal-fetch.service';
import {NodesService} from '../../../../../core/services/nodes/nodes.service';
import {NodeModel} from '../../../../../core/models/nodes/node.model';
import {EventsService} from '../../../../../core/services/interaction/events/events.service';
import {IFrameService} from '../../../../../core/services/iframe/iframe.service';
import {ImagesCounterService} from '../../../../../core/services/image-manager/counters/images-counter.service';
import {UtilsService} from '../../../../../core/services/utils/utils.service';
import {ImagesRemoveErrorModalService} from '../../../../services/modals/images-remove-error/images-remove-error-modal.service';
import {StudentPortfoliosService} from '../../../../../core/services/education/students/websites/portfolios/student-portfolios.service';
import {EducationImageManagerService} from '../../../../../core/services/education/image-manager/education-image-manager.service';
import {WebsitesService} from '../../../../../core/services/websites/websites.service';
import {PaymentSubscriptionsService} from '../../../../../core/services/payment/subscriptions/payment-subscriptions.service';

import {ImageDataModel} from '../../../../../core/models/image-manager/counters/image-data.model';
import {ModalDataModel} from '../../../../../core/models/modals/modal-data.model';
import {ImageModel } from '../../../../../core/models/images/image.model';
import {ImageDto} from '../../../../../core/models/images/image.dto';
import {PortfolioModel} from '../../../../../core/models/portfolios/portfolio.model';
import {EducationExhibitionPortfolioModel} from '../../../../../core/models/education/portfolios/education-exhibition-portfolio.model';
import {EducationStudentPortfolioModel} from '../../../../../core/models/education/portfolios/education-student-portfolio.model';
import {SubscriptionModel} from '../../../../../core/models/payment/subscriptions/subscription.model';
import {ImageRemoveErrorDto} from '../../../../../core/models/errors/images/remove/image-remove-error.dto';

import {PORTION_SIZE} from './constants';
import {KEYS} from '../../../../../core/services/styles/custom/constants';
import {ERRORS_KEYS} from '../../../../services/errors/constants';
import {LIBRARY_ID} from '../../../../../application/constants';
import {IMAGE_RESOLUTIONS} from '../../../../../services/image-resolution.constants';
import {EducatorImageManagerTabs} from '../../../../../core/services/education/image-manager/constants';

import {dropzoneConfig} from '../../../../../application/main/image-manager/dropzone.config';

@Component({
  selector: 'app-portfolio-images',
  templateUrl: './portfolio-images.component.html',
  styleUrls: ['./portfolio-images.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PortfolioImagesComponent implements OnInit, OnChanges, OnDestroy {
  private image;

  DELETE_IMAGE_MODAL_ID = 'delete-image-modal-from-modal-image-manager';
  IMAGE_MANAGER_MODAL_ID = 'image-manager-modal';

  isLibrary = false;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private portfolioId = null;

  private get portionSize() {
    return PORTION_SIZE;
  }

  public selectedImages: ImageModel[] = [];
  @Input() selectedImage: ImageModel = null;
  @Output() selectedImageChange = new EventEmitter();

  private dropzone: Dropzone;

  isLoading = false;
  nOfImagesToLoad: number;

  allImages: ImageModel[] = [];
  images: ImageModel[] = [];

  firstSelectedImageId = null;

  maxImageHeight = 200;
  thumbSize = 80;
  view = 'thumbnails';
  largeViewImageIndex: number;

  public isRandomizable: boolean = false;
  public isRandomized: boolean = false;

  public isPageBlocked: boolean = false;
  public isImagesDeleting: boolean = false;

  public dropzoneConfig: any = Object.assign({}, dropzoneConfig.imageManagerModal, {
    dictResponseError: 'Error while uploading',
    init: this.handleDropzoneInit.bind(this),
    params: { nodeid: 0 },
  });

  private websiteId: number;

  private nodes: NodeModel[] = [];

  private studentPortfolio: PortfolioModel;

  private educatorStudentPortfolio: EducationStudentPortfolioModel;
  private exhibitionPortfolio: EducationExhibitionPortfolioModel;

  private randomizableBlock: HTMLElement = null;
  
  private isStudentsTab: boolean = false;
  private isExhibitionsTab: boolean = false;

  private isEducatorSubscription: boolean = false;
  private isShiftKey: boolean = false;
  private isDestroyed: boolean = false;
  private dropboxInitCompleted: boolean = false;

  private selectFirstImageDebounced: Function;

  private dropzoneSelector = '.dropzone';

  private errorsMapping = {
    [ERRORS_KEYS.IMAGES_USED]: this.onImagesUsed.bind(this),
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private get selectedImagedData(): ImageDataModel[] {
    return this.selectedImages.map((item): ImageDataModel => {
      return new ImageDataModel(item.id, item.isPublished);
    });
  }

  constructor(
    private imageManagerService: ImageManagerService,
    private fetchService: ImageManagerModalFetchService,
    private modalsService: ModalsService,
    private imageResolutionService: ImageResolutionService,
    private imageManagerModalService: ImageManagerModalService,
    private imagesCounterService: ImagesCounterService,
    private imageUploadService: ImageUploadService,
    private imagesRemoveErrorModalService: ImagesRemoveErrorModalService,
    private studentPortfoliosService: StudentPortfoliosService,
    private educationImageManagerService: EducationImageManagerService,
    private buttonsService: ButtonsService,
    private eventsService: EventsService,
    private websitesService: WebsitesService,
    private paymentSubscriptionsService: PaymentSubscriptionsService,
    private iFrameService: IFrameService,
    private nodesService: NodesService,
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef,
    public bag: BagService,
  ) {
    this.selectFirstImageDebounced = this.utilsService.debounce(this.selectFirstImage.bind(this), 50);
  }

  ngOnInit() {
    this.getCurrentImageSize = _.memoize(this.getCurrentImageSize);

    this.paymentSubscriptionsService.currentSubscriptionSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription: SubscriptionModel) => {
      this.isEducatorSubscription = subscription ? subscription.isEducator : false;
    });

    this.imageManagerModalService.onPortfolioSelect.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ id }) => {
      this.portfolioId = id;

      this.initNodeId();
    });

    this.imageManagerModalService.isRandomizedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { element: HTMLElement; block: HTMLElement; isRandomizable: boolean; isRandomized: boolean }) => {
      this.isRandomizable = data ? data.isRandomizable : false;
      this.isRandomized = data ? data.isRandomized : false;
      this.randomizableBlock = data ? data.block : null;
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.nodes = nodes;

      this.initNodeId();
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(modalsStatus => {
      this.modalsStatus = modalsStatus;

      if (this.isDestroyed) return;

      this.cdr.detectChanges();
    });

    this.fetchService.imagesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((images: ImageModel[]) => {
      if (!images) return;

      if (!images.length) $('.dz-message').css('display', 'flex');

      this.allImages = [...images];

      this.images = this.allImages.slice(0, this.portionSize);

      this.nOfImagesToLoad = this.images.length;

      setTimeout(() => {
        if (this.dropzone && this.dropzone.removeAllFiles) {
          this.dropzone.removeAllFiles();
        }

        this.attachExistingImages();
        this.selectFirstImage();
        this.initSortable();

        if (this.images.length === 0) {
          this.selectImages([]);
        }

        if (this.isDestroyed) {
          return;
        }

        this.cdr.detectChanges();
      });
    });

    this.fetchService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;

      if (this.isDestroyed) return;

      this.cdr.detectChanges();
    });

    this.imageManagerService.isPageBlockedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isPageBlocked: boolean) => {
      this.isPageBlocked = isPageBlocked;

      if (this.isDestroyed) return;

      this.cdr.detectChanges();
    });

    this.websitesService.activeWebsiteIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websiteId: number) => {
      this.websiteId = websiteId;
    });

    this.studentPortfoliosService.selectedPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((portfolio: PortfolioModel) => {
      this.studentPortfolio = portfolio;
    });

    this.educationImageManagerService.selectedStudentPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPortfolio: EducationStudentPortfolioModel) => {
      this.educatorStudentPortfolio = selectedPortfolio;
    });

    this.educationImageManagerService.selectedExhibitionPortfolioSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPortfolio: EducationExhibitionPortfolioModel) => {
      this.exhibitionPortfolio = selectedPortfolio;
    });

    this.educationImageManagerService.activeTabSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((key: EducatorImageManagerTabs) => {
      this.isStudentsTab = key === 'students';
      this.isExhibitionsTab = key === 'exhibitions';
    });

    this.init();

    this.initSortable();
  }

  init() {
    this.onImageResolutionWarningSubmit = this.onImageResolutionWarningSubmit.bind(this);
  }

  private initNodeId(): void | Subscription {
    if (this.portfolioId === 0) return this.setNodeId(0);

    if (!this.portfolioId) return;

    const node = this.nodes.find(node => node.id === this.portfolioId);

    if (!node) return;

    return this.setNodeId(node.id);
  }

  private setNodeId(value: number): void | Subscription {
    if (!this.dropzone) return;

    this.dropzone.options.params.nodeid = value;

    return this.fetchService.loadImages(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.portfolioId) return;

    this.view = 'thumbnails';

    $('.dz-message').css('display', 'flex');
  }

  getCurrentImageSize(thumbSize) {
    return Math.round(this.maxImageHeight * thumbSize / 100);
  }

  handleDropzoneInit() {
    if (this.dropboxInitCompleted) {
      return;
    }

    this.dropboxInitCompleted = true;
    
    if (this.dropzone || !document.getElementsByClassName('dropzone').length) {
      return;
    }

    this.dropzone = Dropzone.forElement(this.dropzoneSelector);

    this.dropzone.on('addedfile', file => {
      if (file.size > this.imageUploadService.MAX_FILE_SIZE_IN_BYTES) {
        return this.dropzone.removeFile(file);
      }

      const portfolioId = this.portfolioId;

      this.setThumbnail(file);

      const $previewElement = $(file.previewElement);
      $previewElement.hide();

      const img = $previewElement.find('img');

      const newImageHeight = this.getCurrentImageSize(this.thumbSize);
      img.height(`${newImageHeight}px`);

      img.on('load', e => this.handleImageLoaded(e, portfolioId));
      img.on('error', e => this.handleImageLoadingError(e, portfolioId));

      //  if preview isn't attached to document then attach it
      if (!(<any>$).contains(document, file.previewElement)) {
        $(this.dropzoneSelector).append(file.previewElement);
      }

      $('.dz-message').hide();

      if (file.isFileUploaded) {
        return;
      }

      this.imageManagerService.isPageBlockedSubject.next(true);
    });

    this.dropzone.on('error', (file, error) => {
      if (!file.accepted) (<any>this.dropzone).cancelUpload(file);

      this.imageUploadService.handleUploadError(file.name, error);
      this.dropzone.removeFile(file);
    });

    this.dropzone.on('queuecomplete', () => {
      if (this.imageUploadService.isErrorOnUpload) {
        this.imageUploadService.showErrorMessage();
      }

      this.imageManagerService.isPageBlockedSubject.next(false);
    });

    this.dropzone.on('success', (file: { image: ImageModel, previewElement: HTMLElement, status?: any }, image: ImageDto) => {
      if (file.status && !this.imageResolutionService.isImageResolutionSufficient(file, IMAGE_RESOLUTIONS.HALF_BLEED_IMAGE, true)) this.imageResolutionService.showImageResolutionInfoModal();

      this.setThumbnail(file);

      file.image = image ? ImageDto.normalize(image) : file.image;
      file.previewElement.dataset.imageId = `${file.image.id}`;

      file.previewElement.addEventListener('click', this.selectingHandler.bind(this));
      file.previewElement.addEventListener('dblclick', this.doubleClickHandler.bind(this));

      this.addOptionsToImage(file);
      this.refreshSortable();

      this.selectFirstImageDebounced();

      if (!image) return;

      this.images.push(file.image);
      this.imagesCounterService.onImageAdd(this.portfolioId || LIBRARY_ID);
    });

    this.initNodeId();
  }

  closeModal(id: string) {
    this.modalsService.close(id);
  }

  makeImageOptions() {
    const optionsBlock = $(`
      <div class="options">
        <span id="use-in-block">Use image in block</span><br>
        <span id="enlarge">Enlarge image</span>
      </div>
    `);

    optionsBlock.find('#use-in-block').each((idx, el) => {
      $(el).on('click', ev => {
        ev.stopPropagation();

        const imageId = +$(ev.currentTarget).parent().parent().data('image-id');

        this.setImage(imageId);
      });
    });

    optionsBlock.find('#enlarge').each((idx, el) => {
      $(el).on('click', ev => {
        ev.stopPropagation();
        this.enlargeImage($(ev.currentTarget).parent().parent().index());
      });
    });

    return optionsBlock;
  }

  setImage(imageId) {
    imageId = Number.parseInt(imageId);

    this.image = this.images.find(im => im.id === imageId);

    if (!this.imageResolutionService.isNonFullBleedImage && !this.imageResolutionService.isImageResolutionSufficient(this.image)) {
      this.imageResolutionService.onSubmit(this.onImageResolutionWarningSubmit);

      return this.imageResolutionService.showImageResolutionWarningModal();
    }

    this.replaceImageInBlock(this.image);

    this.closeModal(this.IMAGE_MANAGER_MODAL_ID);
  }

  onImageResolutionWarningSubmit() {
    this.replaceImageInBlock(this.image);

    this.imageResolutionService.closeImageResolutionWarningModal();

    this.closeModal(this.IMAGE_MANAGER_MODAL_ID);
  }

  replaceImageInBlock(image: ImageModel) {
    this.imageManagerService.onImageReplaceSubject.next(image);
  }

  addOptionsToImage(file) {
    $(file.previewElement).append(this.makeImageOptions());
  }

  refreshSortable() {
    if (!$('.dropzone').sortable('instance')) return this.initSortable();
    
    $('.dropzone').sortable('refresh');
  }

  initSortable() {
    $('.dropzone').sortable({
      helper: (event: Event, element: Sortable): Element => {
        return this.getSelectedImagesGhosts(element[0]);
      },
      appendTo: this.IMAGE_MANAGER_MODAL_ID,
      placeholder: 'sortable-placeholder',
      cancel: '.dz-error, span, .options',
      connectWith: '.droppable, .portfolio-item',
      scroll: true,
      items: '.dz-preview',
      tolerance: 'pointer',
      cursorAt: {
        top: 10,
        left: 10
      },
      start: (event, ui) => {
        this.bag.data.isMetaKey = event.metaKey;
        this.isShiftKey = event.shiftKey;

        const draggedImageID = ui.item.data('image-id');

        if (!event.shiftKey && !this.selectedImages.find(im => im.id == draggedImageID)) {
          const newSelectedImages: ImageModel[] = this.images.filter(im => im.id == draggedImageID);

          this.selectImages(newSelectedImages, {
            firstSelectedImageId: draggedImageID,
          });
        }

        ui.placeholder.width(ui.item.width());
        ui.placeholder.height(ui.item.height());

        const ghost = ui.helper.first();

        if (event.metaKey) {
          ghost.css({ 'cursor': 'copy' });
        }
      },
      beforeStop: (event, ui) => {
        if (ui.placeholder.parent().hasClass('portfolio-item')) {
          this.startInsertImageToPortfolioAnimation(event, ui);
        }
      },
      update: (event, ui) => {
        if (this.bag.data.isMetaKey) return;
        
        if (ui.item.parent().hasClass('selected')) return;
        if (ui.item.parent().hasClass('portfolio-item')) return;

        const reordered = [];

        if (this.selectedImages.length > 1) {
          const imagesElements: HTMLElement[] = this.selectedImages.map((item: ImageModel) => <HTMLElement>this.dropzone.element.querySelector(`[data-image-id="${item.id}"]`));
  
          this.handleMultipleElementsDrag(ui.item[0], imagesElements);
        }

        $('.dropzone .dz-preview.dz-complete:not(.dz-error)').each((idx, el) => {
          reordered.push(+el.dataset.imageId);
        });

        return this.imageManagerService.reorderImages({
          portfolioId: this.portfolioId,
          images: reordered,
        }).subscribe(() => {
          //  update image order locally
          this.images = reordered.map(id => this.images.find(im => im.id == id));
        });
      },
      deactivate: (event, ui) => {
        if (this.isShiftKey || this.bag.data.isMetaKey) {
          return this.cancelReordering();
        }
      },
      remove: (event, ui) => {
        if (this.bag.data.isMetaKey || ui.item.parent().hasClass('selected')) return;

        //  TODO(max) => fit it
        setTimeout(() => {
          this.allImages = this.allImages.filter((image: ImageModel) => !this.selectedImages.find((selectedImage: ImageModel) => selectedImage.id === image.id));
          this.images = this.images.filter((image: ImageModel) => !this.selectedImages.find((selectedImage: ImageModel) => selectedImage.id === image.id));

          this.removeFilesFromDropzone(this.selectedImages.map(im => im.id));

          this.imagesCounterService.onImageRemove(this.portfolioId || LIBRARY_ID, this.selectedImagedData);
        
          this.selectFirstImage();

          if (this.allImages.length > 0) return;
          
          this.selectImages([]);
        }, 100);
      },
    });
  }

  private getSelectedImagesGhosts(draggedElement: HTMLElement): HTMLElement {
    const rect = draggedElement.getBoundingClientRect();

    const elements: HTMLElement[] = this.getDraggingElements(draggedElement);
    const wrapper: HTMLElement = this.getImageGhostListWrapper(rect);
    const list: HTMLElement = this.getImageGhostList(wrapper);

    this.addElementToGhostList(list, draggedElement, this.getImageGhostDefaultStyles());
    this.addElementsToGhostList(list, elements, rect);

    return wrapper;
  }

  private getDraggingElements(draggedElement: HTMLElement): HTMLElement[] {
    if (!draggedElement.matches('.selected')) return [draggedElement];

    return <HTMLElement[]>Array.from(draggedElement.parentElement.querySelectorAll('.dz-preview.selected')).filter((element: HTMLElement) => element !== draggedElement);
  }

  private getImageGhostListWrapper(rect): HTMLElement {
    const wrapper = document.createElement('div');

    wrapper.style.position = 'absolute';
    wrapper.style.top = `${rect.top}px`;
    wrapper.style.left = `${rect.left}px`;
    wrapper.style.zIndex = `9999999`;
    wrapper.style.maxHeight = `90px`;
    
    document.body.appendChild(wrapper);
    
    return wrapper;
  }

  private getImageGhostList(wrapper: HTMLElement): HTMLElement {
    const list = document.createElement('div');

    wrapper.style.position = 'relative';
    
    wrapper.appendChild(list);

    return list;
  }

  private addElementsToGhostList(list: HTMLElement, elements: HTMLElement[], rect) {
    Array.from(elements).forEach((element: HTMLElement) => {
      const elementRect = element.getBoundingClientRect();
      const styles = this.getNotClickedElementStyles(elementRect, rect, this.getImageGhostDefaultStyles());

      this.addElementToGhostList(list, element, styles);
    });
  }

  private getNotClickedElementStyles(elementRect, rect, defaultStyles) {
    const transform = `translate(${elementRect.left - rect.left}px, ${elementRect.top - rect.top}px)`;

    return Object.assign(defaultStyles, {'position': 'absolute', transform });
  }

  private addElementToGhostList(list: HTMLElement, element: HTMLElement, styles: { [key: string]: string }) {
    const ghost: HTMLElement = <HTMLElement>element.cloneNode(true);

    Object.keys(styles).forEach((key: string) => {
      ghost.style[key] = styles[key];
    });
    
    ghost.classList.add('ui-sortable-helper');

    list.appendChild(ghost);
  }

  private getImageGhostDefaultStyles(): { [key: string]: string } {
    return {
      'top': '0',
      'left': '0',
      'opacity': '0.7',
      'z-index': '9999999',
      'max-height': '90px',
      'width': 'auto',
    };
  }
  
  private handleMultipleElementsDrag(draggedElement: HTMLElement, imageElements: HTMLElement[]) {
    const draggedIdx: number = imageElements.findIndex((item: HTMLElement) => item === draggedElement);

    for (let i = 0; i < draggedIdx; i++) {
      draggedElement.parentElement.insertBefore(imageElements[i], draggedElement);
    }

    if (draggedIdx === imageElements.length - 1) return;

    for (let i = imageElements.length - 1; i > draggedIdx; i--) {
      draggedElement.parentElement.insertBefore(imageElements[i], draggedElement.nextSibling);
    }
  }

  startInsertImageToPortfolioAnimation(event, ui) {
    const img = $(ui.item.find('img')[0]).clone();

    const styles = {
      'position': 'absolute',
      'top': ui.position.top + 'px',
      'left': ui.position.left + 'px',
      'opacity': 0.7,
      'z-index': 1004,
      'max-height': '90px'
    };
    img.css(styles);
    img.appendTo(document.body);

    const animationOptions = {
      width: [ 'toggle', 'swing' ],
      height: [ 'toggle', 'swing' ],
      opacity: 'toggle'
    };
    img.animate(animationOptions, 300, 'linear', () => img.remove());
  }

  cancelReordering() {
    $('.dropzone').sortable('cancel');
  }

  selectFirstImage() {
    if (this.images.length === 0) return;

    const firstImage = this.images[0];

    this.selectImages([firstImage], {
      firstSelectedImageId: firstImage.id
    });
  }

  selectingHandler(ev) {
    const clickedElement = ev.currentTarget;

    if (!clickedElement.classList.contains('dz-preview')) return;

    const clickedImageId = clickedElement.dataset.imageId;

    let firstSelectedImageId: string = null;
    let selectedImages: ImageModel[] = [];

    if (ev.shiftKey && this.selectedImages.length) {
      const secondSelectedImageID = clickedImageId;
      const firstSelectedImageIndex = this.images.findIndex(im => im.id == this.firstSelectedImageId);
      const secondSelectedImageIndex = this.images.findIndex(im => im.id == secondSelectedImageID);

      const smallerIndex = Math.min(firstSelectedImageIndex, secondSelectedImageIndex);
      const biggerIndex = Math.max(firstSelectedImageIndex, secondSelectedImageIndex);

      selectedImages = this.images.slice(smallerIndex, biggerIndex + 1);
    } else if (ev.metaKey) {
      selectedImages = this.selectedImages.filter(im => im.id != clickedImageId);
      selectedImages.push(this.images.find(im => im.id == clickedImageId));
    } else {
      firstSelectedImageId = clickedImageId;
      selectedImages = this.images.filter(im => im.id == clickedImageId);
    }

    this.selectImages(selectedImages, {
      firstSelectedImageId: ev.shiftKey ? this.firstSelectedImageId : firstSelectedImageId,
    });
  }

  doubleClickHandler(ev) {
    const clickedElement = ev.currentTarget;

    if (!clickedElement.classList.contains('dz-preview')) return;

    this.setImage(clickedElement.dataset.imageId);
  }

  selectImages(images: ImageModel[], options?) {
    if (this.isDestroyed) return;

    const firstImage = images[0];

    if (options && options.firstSelectedImageId) {
      this.firstSelectedImageId = options.firstSelectedImageId;
    } else {
      this.firstSelectedImageId = firstImage && firstImage.id;
    }

    this.selectedImages = images;

    this.updateSelectionUI();

    this.bag.data.selectedImages = this.selectedImages;

    this.selectImage(firstImage);

    this.cdr.detectChanges();
  }

  enlargeImage(idx) {
    this.largeViewImageIndex = idx;
    this.changeView('large');
  }

  updateSelectionUI() {
    $(`.dz-preview`).removeClass('selected');

    const selectorForSelectedImages = this.makeSelectorForImages(this.selectedImages.map(im => im.id));

    $(selectorForSelectedImages).addClass('selected');
  }

  setThumbnail(file) {
    if (!file.size) {
      file.previewElement.querySelector('img').src = file.dataURL;
    } else {
      return loadImage(
        file,
        (img) => this.dropzone.emit('thumbnail', file, img.toDataURL()),
        { orientation: true }
      );
    }
  }

  clearDropzone() {
    if (!this.dropzone) return;
    this.dropzone.files.forEach(f => this.dropzone.removeFile(f));
  }

  removeFilesFromDropzone(imageIds) {
    if (!this.dropzone) return;

    this.dropzone.files
      .filter(f => imageIds.includes(f.image.id))
      .forEach(f => this.dropzone.removeFile(f));
  }

  attachExistingImages(options?: any) {
    const images = options ? options.images : this.images;

    if (!options || options.clearDropzone) this.clearDropzone();

    if (!images || images.length === 0) return;

    this.initImages(images);
  }

  applyMocks(mocks) {
    if (!this.dropzone || !this.dropzone.files) return setTimeout(this.applyMocks.bind(this, mocks), 50);

    mocks.forEach(mock => {
      this.dropzone.files.push(mock);

      this.dropzone.emit('addedfile', mock);
      this.dropzone.emit('complete', mock);
      this.dropzone.emit('success', mock);
    });

    this.updateSelectionUI();
  }

  getImageUrl(image, size?) {
    if (size) {
      return image && image.paths ? `https://${image.paths.default}`.replace('.jpg', `_${size}.jpg`) : '';
    }
    
    return image && image.paths ? `https://${image.paths.default}` : '';
  }

  handleImageLoaded(e, portfolioId) {
    if (portfolioId !== this.portfolioId) return;

    if (--this.nOfImagesToLoad > 0) return;

    $('.dropzone .dz-preview').show();    
    this.isLoading = false;

    if (this.isDestroyed) return;

    this.cdr.detectChanges();

    this.appendMore();
  }

  private appendMore() {
    const images = this.allImages.slice(this.images.length, this.images.length + this.portionSize);

    this.images = [...this.images, ...images];

    this.nOfImagesToLoad = images.length;

    if (images.length === 0) return;

    this.initImages(images);
  }

  private initImages(images) {
    const mocks = images.map(image => {
      return {
        name: image.title,
        size: 0,
        type: 'image/jpeg',
        dataURL: this.getImageUrl(image, IMAGE_SIZE.MEDIA_SMALL),
        image,
        isFileUploaded: true,
      };
    });

    this.applyMocks(mocks);
  }

  fixedImageSrc = {};

  handleImageLoadingError(ev, portfolioId) {
    const target = $(ev.target);

    let src = target.attr('src');

    if (src === 'undefined') return;
    if (this.fixedImageSrc[src]) return this.handleImageLoaded(ev, portfolioId);

    src = src.replace(/_\w+\.jpg$/, '.jpg');
    this.fixedImageSrc[src] = true;
    target.attr('src', src);
  }

  selectImage(image: ImageModel) {
    if (image) image.isSelected = true;

    this.selectedImage = image;
    this.selectedImageChange.emit(this.selectedImage);
    this.imageManagerService.onImageSelectSubject.next(image);
  }

  promptDeleteImages() {
    this.modalsService.open(this.DELETE_IMAGE_MODAL_ID);
  }

  handleDeleteButtonClick() {
    this.promptDeleteImages();
  }

  deleteImages(): Subscription {
    const imagesToRemove: ImageDataModel[] = this.selectedImagedData;

    this.closeModal(this.DELETE_IMAGE_MODAL_ID);

    this.isImagesDeleting = true;

    this.imageManagerService.isPageBlockedSubject.next(true);

    const imageIds = this.selectedImages.map(im => im.id);

    return this.isCurrentPageImagesGoodBeforeDelete({ imageIds }).subscribe((isCurrentPageImagesGood: boolean) => {
      if (!isCurrentPageImagesGood) {
        const err: { key: string, message: string, data: { [key: string]: ImageRemoveErrorDto[] } } = {
          key: 'CURRENT_PAGE_ERROR',
          message: null,
          data: null,
        };
        
        this.imagesRemoveErrorModalService.open(err);
  
        this.isImagesDeleting = false;
  
        this.imageManagerService.isPageBlockedSubject.next(false);
  
        return;
      }
      
      $(this.makeSelectorForImages(imageIds)).hide();
  
      return (
        this.portfolioId === 0
        ? this.imageManagerService.deleteImages(imageIds)
        : this.imageManagerService.recycleImages({ portfolioId: this.portfolioId, imagesIds: imageIds, websiteId: this.getCurrentWebsiteId() })
      ).pipe(
        catchError(e => {
          $(this.makeSelectorForImages(imageIds)).show();
    
          if (this.errorsMapping[e.error.key]) {
            this.errorsMapping[e.error.key](e.error);
          }
    
          this.isImagesDeleting = false;
    
          this.imageManagerService.isPageBlockedSubject.next(false);
          
          if (this.isDestroyed) return;
    
          this.cdr.detectChanges();
          
          return throwError(() => e);
        })
      ).subscribe(() => {
        this.removeFilesFromDropzone(imageIds);
    
        this.images = this.images.filter(im => !imageIds.includes(im.id));
        this.allImages = this.allImages.filter(image => !imageIds.includes(image.id));
    
        if (this.images.length === 0) {
          $('.dz-message').css('display', 'flex');
  
          this.selectImages([]);
        }
  
        if (this.portfolioId > 0) {
          this.imagesCounterService.onImageRemove(this.portfolioId || LIBRARY_ID, imagesToRemove);
          this.imagesCounterService.onImageAdd(LIBRARY_ID, imageIds.length);
        } else {
          this.imagesCounterService.onImageRemove(LIBRARY_ID, imagesToRemove);
        }
  
        this.isImagesDeleting = false;
  
        this.imageManagerService.isPageBlockedSubject.next(false);
  
        if (this.isDestroyed) return;
  
        $(this.makeSelectorForImages(imageIds)).remove();
  
        this.selectFirstImage();
  
        this.cdr.detectChanges();
      });
    });
  }

  private getCurrentWebsiteId(): number {
    if (this.isEducatorSubscription && this.isStudentsTab) {
      return this.educatorStudentPortfolio ? this.educatorStudentPortfolio.websiteId : null;
    }

    if (this.isEducatorSubscription && this.isExhibitionsTab) {
      return this.exhibitionPortfolio ? this.exhibitionPortfolio.websiteId : null;
    }

    return this.websiteId;
  }

  private isCurrentPageImagesGoodBeforeDelete(data: { imageIds: number[] }): Observable<boolean> {
    if (this.portfolioId !== 0) {
      return of(true);
    }

    return new Observable<boolean>(observer => {
      this.eventsService.addFrameListener('imagesDataByImagesId', (e: CustomEvent) => {
        try {
          const { imageIds } = e.detail;

          observer.next(imageIds.length === 0);
        } catch (e) {
          observer.next(false);
        }
      }, { once: true });

      this.eventsService.dispatchRequestImagesDataByImagesId(data, this.iFrameService.sandboxWindow);
    });
  }

  private onImagesUsed(err: { key: string, message: string, data: { [key: string]: ImageRemoveErrorDto[] } }): void {
    this.imagesRemoveErrorModalService.open(err);
  }

  makeSelectorForImages(imageIds) {
    return imageIds
      .map(id => '.dropzone [data-image-id="' + id + '"]')
      .join(',');
  }

  updateThumbSize(newThumbSize) {
    this.thumbSize = newThumbSize;
    const newImageHeight = Math.round(this.maxImageHeight * this.thumbSize / 100);
    $('.dropzone .dz-preview img').height(`${newImageHeight}px`);
  }

  changeView(newView) {
    if (newView === 'large') {
      if (!this.largeViewImageIndex) {
        this.largeViewImageIndex = 1;
      }
    } else {
      this.largeViewImageIndex = null;
    }
    this.view = newView;
  }

  deselectAll() {
    this.selectImages([]);
  }

  selectAll() {
    this.selectImages(this.images);
  }

  public toggleRandom(): void {
    if (!this.randomizableBlock) return;

    this.isRandomized = !this.isRandomized;

    this.dispatchStyleChangedEvent({
      target: this.randomizableBlock,
      key: KEYS.IS_RANDOMIZABLE,
      value: this.isRandomized,
    });

    this.closeModal(this.IMAGE_MANAGER_MODAL_ID);

    this.buttonsService.enableSaveButton();
  }

  private dispatchStyleChangedEvent(data: { target, key, value }) {
    this.eventsService.dispatchCustomStyleChangedEvent(data, this.iFrameService.sandboxWindow);
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;

    this.imageManagerService.isPageBlockedSubject.next(false);

    this.fetchService.onModalClose();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
