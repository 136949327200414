import {EducationClassModel} from '../education/classes/education-class.model';
import {WebsitesTemplatesPermissionsModel} from './templates-permissions/templates-permissions.model';

export class CourseWebsiteModel {
  public isBlocked: boolean = false;
  public url: string = null;

  constructor(public id: number,
              public userId: number,
              public classId: number,
              public type: string,
              public course: EducationClassModel,
              public templateId: number,
              public mailChimpListId: string,
              public currencyId: number,
              public measureUnitId: number,
              public gaAccountId: number,
              public title: string,
              public subtitle: string,
              public publishStatus: string,
              public gaCode: string,
              public copyrightNotice: string,
              public contact: string,
              public contactName: string,
              public contactEmail: string,
              public contactPhone: string,
              public contactAddress1: string,
              public contactAddress2: string,
              public company: string,
              public contactAddressCityStateZipCountry: string,
              public metaTagDescription: string,
              public metaTagKeywords: string,
              public menuStyle: string,
              public mobileMenuStyle: string,
              public mobilePortfolioKey: string,
              public mobileEnlargementKey: string,
              public privatePagePassword: string,
              public privatePagePasswordTimeUpdate: string,
              public payPalEmail: string,
              public faviconImage: string,
              public randomizeFromQueue: boolean,
              public templatesPermissions: WebsitesTemplatesPermissionsModel[],
              public isSetupCompleted: boolean,
              public isRightClickDisabled: boolean,
              public isCopyrightNoticeEnabled: boolean,
              public isEverPublished: boolean,
              public isCaptchaEnabled: boolean,
              public isBlogCommentsApprovable: boolean,
              public isSimpleImageLabel: boolean,
              public isMainStudentWebsite: boolean,
              public timeUpdatedOffset: string) {}
}
