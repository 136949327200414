import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-fonts-manager-slider',
  templateUrl: './fonts-manager-slider.component.html',
  styleUrls: ['./fonts-manager-slider.component.scss']
})
export class FontsManagerSliderComponent {
  @Input() value: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;

  @Output() valueChange = new EventEmitter();

  public onChange(e): void {
    this.value = e.currentTarget.value;
    this.valueChange.emit(this.value);
  }
}

