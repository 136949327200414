import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PageLockAlertModalService} from '../../../services/modals/page-lock-alert/page-lock-alert-modal.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {NavigationService} from '../../../../services/navigation.service';

import {Button} from '../../../../common/models/button/button.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';

@Component({
  selector: 'app-page-lock-alert-modal',
  templateUrl: './page-lock-alert-modal.component.html',
  styleUrls: ['./page-lock-alert-modal.component.scss'],
})
export class PageLockAlertModalComponent implements OnInit, OnDestroy {
  public template: string;

  public buttons: Button[] = [
    new Button('YES', null, 'green ok-button', this.confirm.bind(this)),
    new Button('NO', null, 'red ok-button', this.close.bind(this)),
  ];

  private onConfirm;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(public service: PageLockAlertModalService,
              private templatesService: TemplatesService,
              private navigationService: NavigationService) {
  }

  public ngOnInit(): void {
    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.template = template ? `${template.name}` : '';
    });
  }

  public onOpen({ onConfirm }): void {
    this.onConfirm = onConfirm;
  }

  public toSettings(): void {
    this.navigationService.toWebsiteSettings();

    this.service.close();
  }

  public confirm(): void {
    this.onConfirm();

    this.service.close();
  }

  public close(): void {
    this.service.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
