import {IStyleModelOptions} from '../option/i-style-model-options';

import {BaseFontWeightModel} from '../base/font-weight/base-font-weight.model';

import {STYLE_PROPERTY_NAME} from './constants';

export class PortfolioSlideThumbFontWeightModel extends BaseFontWeightModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, value, isEnabled, STYLE_PROPERTY_NAME);
  }

  public init(selectedElement: HTMLElement, options?: IStyleModelOptions): void {
    super.init(options.block);
  }
}
