import {Injectable} from '@angular/core';

import {ContactBlockSetupModel} from '../../../models/styles/setup/contact-block/contact-block-setup.model';

import {StyleOptionModel} from '../../../models/styles/settings/option/style-option.model';

@Injectable()
export class ContactBlockStylesService {
  public setup: ContactBlockSetupModel;

  public isContactBlock: boolean = false;
  public isPortfolio: boolean = false;

  public get element(): HTMLElement {
    return this.setup.element;
  }

  constructor() {
    this.setup = new ContactBlockSetupModel();
  }

  public init(element: HTMLElement, enabledStyleOptions: Map<string, StyleOptionModel>) {
    if (!element) return;

    this.isContactBlock = element.matches('[data-is-contact-block="true"]');
    this.isPortfolio = element.matches('.portfolio, .portfolio-enlargement-wrapper');

    if (!this.isContactBlock && !this.isPortfolio) return;

    this.setup.init(element, enabledStyleOptions);
  }
}
