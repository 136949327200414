import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Subscription, BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {WebsitesService} from '../websites/websites.service';

import {UserSocialNetwork} from '../../models/social-networks/user-social-network/user-social-network.model';
import {UserSocialNetworkDto} from '../../models/social-networks/user-social-network/user-social-network.dto';
import {SocialNetwork} from '../../models/social-networks/social-network/social-network.model';

import {environment} from '../../../../environments/environment';

@Injectable()
export class UserSocialNetworksService {
  public list: BehaviorSubject<UserSocialNetwork[]> = new BehaviorSubject([]);

  private websiteId: number;

  constructor(private http: HttpClient,
              private websitesService: WebsitesService) {
    this.websitesService.activeTemplateIdSubject.subscribe((id: number) => {
      this.websiteId = id;

      if (!id) return;

      this.init();
    });
  }

  private init(): void {
    this.fetchList(this.websiteId).subscribe((socialNetworks: UserSocialNetwork[]) => {
      this.list.next(socialNetworks);
    });
  }

  private fetchList(websiteId: number): Observable<UserSocialNetwork[]> {
    return this.http.get(`${environment.apiBase}/websites/${websiteId}/social-networks`).pipe(
      map((res: UserSocialNetworkDto[]) => res ? UserSocialNetwork.listFromResponse(res) : null)
    );
  }

  public save(socialNetwork: SocialNetwork) {
    return this.http.post(`${environment.apiBase}/user-social-networks/${socialNetwork.id}`, socialNetwork.userSocialNetwork).subscribe(() => {}, console.error);
  }

  public update(socialNetworks: SocialNetwork[]): Observable<any> {
    const data = socialNetworks.map(socialNetwork => {
      socialNetwork.userSocialNetwork.socialNetworkId = socialNetwork.id.toString();

      return socialNetwork.userSocialNetwork;
    });

    return this.http.put(`${environment.apiBase}/user-social-networks`, data);
  }
}
