import {Component, OnInit, OnDestroy} from '@angular/core';

import {Subject, Observable, combineLatest} from 'rxjs';
import {take, takeUntil, filter} from 'rxjs/operators';

import * as $ from 'jquery';

import {AuthService} from '../../../auth/auth.service';
import {PreLaunchChecklistService} from '../../../core/services/pre-launch-checklist/pre-launch-checklist.service';
import {SidebarSectionsService} from '../../../services/sidebar-sections.service';

import {AccountModel} from '../../../core/models/accounts/account.model';
import {PreLaunchProgressItemModel} from '../../../core/models/pre-launch-progress/item/pre-launch-progress-item.model';

import {AppAnimations} from '../../../app-animations';

import {STORAGE_KEY} from './constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class DashboardComponent implements OnInit, OnDestroy {
  public isPreLaunchOnTop: boolean;

  private account: AccountModel;

  private incompletedPreLaunchCount: number = -1;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthService,
              private preLaunchChecklistService: PreLaunchChecklistService,
              private sidebarSectionsService: SidebarSectionsService) {}

  public ngOnInit(): void {
    this.sidebarSectionsService.isSectionsCollapsedSubject.next(true);

    $('app-page-menu').hide();
    $('.sub-navigation').hide();

    const accountObservable: Observable<AccountModel> = this.authService.accountSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((account: AccountModel) => !!account),
      take(1)
    );

    const preLaunchItemsObservable: Observable<PreLaunchProgressItemModel[]> = this.preLaunchChecklistService.itemsSubject.pipe(
      filter((items: PreLaunchProgressItemModel[]) => !!items),
      take(1)
    );

    combineLatest([accountObservable, preLaunchItemsObservable]).subscribe(([account, items]) => {
      this.account = account;
      this.incompletedPreLaunchCount = items.reduce((res: number, item: PreLaunchProgressItemModel) => res + (item.isCompleted ? 0 : 1), 0);

      this.handlePreLaunchChecklistPosition();
    });
  }

  private handlePreLaunchChecklistPosition(): void {
    if (!this.account || this.incompletedPreLaunchCount === -1) return;

    const data = this.getStoredPreLaunchData();

    if (!data || data.sessionKey === this.account.sessionKey || this.incompletedPreLaunchCount !== 0) {
      this.isPreLaunchOnTop = true;

      this.setStoredPreLaunchData();

      return;
    }

    this.isPreLaunchOnTop = false;
  }

  private getStoredPreLaunchData(): { sessionKey: string } {
    try {
      const data = window.localStorage.getItem(STORAGE_KEY);

      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }

  private setStoredPreLaunchData(): void {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify({ sessionKey: this.account.sessionKey }));
  }

  public ngOnDestroy(): void {
    $('app-page-menu').show();
    $('.sub-navigation').show();

    this.sidebarSectionsService.isSectionsCollapsedSubject.next(false);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
