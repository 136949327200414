import {TagModel} from './tag.model';

export class TagDto {
  constructor(public Id: number,
              public Name: string) {
  }

  public static normalize(res: TagDto): TagModel {
    if (!res) return null;

    return new TagModel(
      res.Id,
      res.Name,
    );
  }
}
