import {AppVersionChangesModel} from '../app-version-changes/app-version-changes.model';

export class AppSettingsModel {
  constructor(
    public trialPeriodDays?: string,
    public trialPeriodEducationalDays?: string,
    public version?: string,
    public versionChanges?: AppVersionChangesModel,
    public isMaintenanceOverlayVisible?: boolean,
  ) {}
}
