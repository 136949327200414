import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject, catchError, takeUntil, throwError} from 'rxjs';

import {ModalsService} from '../../../services/modals/modals.service';
import {SaveThroughoutWebsiteOptionsModalService} from '../../../services/modals/save-throughout-website-options/save-throughout-website-options-modal.service';

import {RadiobuttonModel} from '../../../models/radiobutton/radiobutton.model';
import {Button} from '../../../../common/models/button/button.model';

import {KEYS} from '../../../services/modals/save-throughout-website-options/constants';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-save-throughout-website-options-modal',
  templateUrl: './save-throughout-website-options-modal.component.html',
  styleUrls: ['./save-throughout-website-options-modal.component.scss'],
})
export class SaveThroughoutWebsiteOptionsModalComponent implements OnInit, OnDestroy {
  public radiobuttons: RadiobuttonModel[] = [
    new RadiobuttonModel('save-styles', 'Yes, make any changes to menu block on all pages', KEYS.REPLACE_ALL),
    new RadiobuttonModel('save-styles', `No, don't update saved custom menu blocks`, KEYS.REPLACE_ALL_EXCLUDING_CUSTOM),
  ];

  public buttons: Button[] = [
    {
      text: `Save Changes`,
      className: 'green save-changes-button',
      onClick: this.onSave.bind(this),
    },
    {
      text: `Cancel`,
      className: 'red dont-save-button',
      onClick: this.onCancel.bind(this),
    },
  ];

  public selectedValue: string = KEYS.REPLACE_ALL;
  
  public errorMessage: string = '';
  
  public isLoading: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  constructor(
    private service: SaveThroughoutWebsiteOptionsModalService,
    private modalsService: ModalsService,
  ) {}

  public ngOnInit(): void {
  }

  public onModalOpen(): void {
  }

  public onSave(): void {
    this.isLoading = true;

    this.service.observable.next({
      value: this.selectedValue,
    });

    this.modalsService.close(this.id);
  }

  public onCancel(): void {
    this.service.observable.next({
      isRejected: true,
    });
    
    this.modalsService.close(this.id);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
