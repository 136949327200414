export const EXAMPLES = KEYS => {
  return {
    [KEYS.SENTENCE]: [
      `អ័ព្ទគ្របដណ្ដប់នាវាបីម៉ោងដែលរារាំងដំណើររបស់វាទៅកាន់ផែ`,
      `អ័ព្ទសក្បុសគ្របដណ្តប់លើដំបូងនាវា`,
      `ខ្ញុំបានមើលព្យុះ ដែលមានភាពស្រស់ស្អាតណាស់ ប៉ុន្តែគួរឲ្យខ្លាច`,
    ],
    [KEYS.ALPHABET]: [
      'កខគឃងចឆជឈញដឋឌឍណតថទធនបផពភមយរលវសហឡអ',
    ],
    [KEYS.PARAGRAPH]: [
      `ផ្ទុយពីការគិតពីដំបូងរបស់ខ្ញុំ ខ្ញុំបានសម្រេចធ្វើឲ្យយាន្តនេះមានកម្ពស់ខ្ពស់ ប្រហែលប្រាំបីហ្វ៊ីត និងមានទំហំធំ ដោយសារផ្នែករបស់វាបង្កជាឧបសគ្គដ៏ធំចំពោះល្បឿននៃការធ្វើដំណើរបស់ខ្ញុំ។ បន្ទាប់ពីធ្វើការប្តេជ្ញាចិត្ត និងការចំណាយពេលជាច្រើនខែក្នុងការប្រមូល និងរៀបចំសម្ភារៈរបស់ខ្ញុំដោយជោគជ័យរួចមក ខ្ញុំបានចាប់ផ្តើម។`
    ],
    [KEYS.NUMERALS]: [
      '០១២៣៤៥៦៧៨៩',
    ],
  };
};
