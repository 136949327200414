import { Component, Output, EventEmitter } from "@angular/core";
import { CustomerFilterOptions } from "./import-filter-options";

@Component({
  selector: 'app-admin-import-filter',
  templateUrl: './import-filter.component.html',
  styleUrls: ['./import-filter.component.scss']
})
export class AdminImportFilterComponent {
  filterOptions = new CustomerFilterOptions();

  @Output() searchHandler = new EventEmitter();

  constructor() {}

  handleSearch() {
    this.searchHandler.emit(this.filterOptions.value);
  }
}
