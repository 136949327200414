import {Component, Output, Input, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';

import {SelectOption} from "../../../../core/models/select/option/option.model";

@Component({
  selector: 'app-fonts-manager-select',
  templateUrl: './fonts-manager-select.component.html',
  styleUrls: ['./fonts-manager-select.component.scss'],
})
export class FontsManagerSelectComponent {
  @Output() changeHandler = new EventEmitter<SelectOption | SelectOption[]>();

  @Input() isBorderVisible: boolean = false;
  @Input() isBorderRounded: boolean = false;
  @Input() isHoverable: boolean = true;
  @Input() isItemsHoverable: boolean = true;
  @Input() isMultiple: boolean = false;
  @Input() noPadding: boolean = false;
  @Input() right: boolean = false;

  @Input() title: string = '';
  @Input() header: string = '';
  @Input() boxBorderRadius: string = '0px';

  @Input() options: SelectOption[] = [];

  @ViewChild('scrollableWrapper') scrollableWrapper: ElementRef;

  public isExpanded: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public onClick() {
    this.isExpanded = !this.isExpanded;

    this.cdr.detectChanges();

    this.scrollToSelected();
  }

  private scrollToSelected(): void {
    if (!this.scrollableWrapper || !this.scrollableWrapper.nativeElement) return;

    const selected = this.scrollableWrapper.nativeElement.querySelector('.option.selected');

    if (!selected) return;

    this.scrollableWrapper.nativeElement.scrollTop = selected.offsetTop - this.scrollableWrapper.nativeElement.clientHeight / 2 + selected.clientHeight / 2;
  }

  public onSelect(option: SelectOption): void {
    if (!this.isMultiple) {
      this.isExpanded = false;

      return this.changeHandler.emit(option);
    }

    option.isSelected = !option.isSelected;

    this.changeHandler.emit(this.options);
  }

  public onClose() {
    this.isExpanded = false;
  }
}
