import {Component, Input, Output, EventEmitter} from '@angular/core';

import {ModalsService} from "../../../services/modals/modals.service";

import {ModalHeader} from "../../../../common/models/modal/header/header.model";

import {KEY_CODES} from './constants';

@Component({
  selector: 'app-select-input-modal',
  templateUrl: 'select-input-modal.component.html',
  styleUrls: ['select-input-modal.component.scss']
})
export class SelectInputModalComponent {
  @Input() id = 'select-input-modal';
  @Input() bodyClassName: string = '';

  @Input() headerText: string;
  @Input() headerClassName: string;

  private _options: string[];

  private keyHandlers = {
    [KEY_CODES.ENTER]: () => this.onSave(),
    [KEY_CODES.ESC]: () => this.onCancel(),
  };

  @Input()
  set options(value: string[]) {
    this._options = value;

    if (!this.selectedOption && value && value.length) this.selectedOption = value[0];
  }

  get options(): string[] {
    return this._options;
  }

  @Output() saveHandler = new EventEmitter<string>();
  @Output() cancelHandler = new EventEmitter();

  public get wrapperClassName(): string {
    return `select-input-modal-body ${this.bodyClassName}`;
  }

  public selectedOption: string;

  public get modalHeader(): ModalHeader {
    return {
      text: this.headerText,
      className: this.headerClassName
    };
  }

  constructor(private modalsService: ModalsService) {}

  public onKeyDown(e) {
    this.keyHandlers[e.keyCode]();
  }

  public onSave() {
    this.saveHandler.emit(this.selectedOption);

    this.modalsService.close(this.id);
  }

  public onCancel() {
    this.cancelHandler.emit();

    this.modalsService.close(this.id);
  }
}
