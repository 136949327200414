import {Component, Input} from '@angular/core';

import {ListItemModel} from '../../../../core/models/list/list-item.model';

@Component({
  selector: 'app-multilevel-list-item',
  templateUrl: './multilevel-list-item.component.html',
  styleUrls: ['./multilevel-list-item.component.scss'],
})
export class MultilevelListItemComponent {
  @Input() item: ListItemModel;

  constructor() {
  }

  public onClick() {
    if (!this.item.isExpandable) return;

    this.item.isExpanded = !this.item.isExpanded;
  }
}
