import {CurrencyModel} from './currency.model';

export class CurrencyDto {
  constructor(public Id?: number,
              public Name?: string,
              public Symbol?: string,
              public SortOrder?: number) {}

  public static normalize(res: CurrencyDto): CurrencyModel {
    if (!res) return null;

    return new CurrencyModel(
      res.Id,
      res.Name,
      res.Symbol,
      res.SortOrder
    );
  }
}
