export class EducationNewInstitutionModel {
  public isNameValid: boolean = true;

  public get isValid(): boolean {
    return this.isNameValid;
  }

  constructor(public id: number = void 0,
              public name: string = '') {
  }

  public validate(): void {
    this.name = this.name.trim();

    this.isNameValid = this.name.length > 0;
  }
}
