import {BasePxStyleOptionModel} from '../base/base-px-style-option/base-px-style-option.model';

import {PROPERTY_NAME, PROPERTY_NAME_PX, STYLE_PROPERTY} from './constants';

export class ThumbDrawerWordSpacingModel extends BasePxStyleOptionModel {
  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {
    super(key, type, PROPERTY_NAME, PROPERTY_NAME_PX, STYLE_PROPERTY, value, isEnabled, false);
  }

  public init(element: HTMLElement): void {
    if (!this.isEnabled) return;

    super.init(element);
  }
}
