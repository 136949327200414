import {Injectable} from '@angular/core';

import {ColorModel} from '../../../models/styles/color/color.model';
import {RgbColorSchemeModel} from '../../../models/styles/color/rgb.color.scheme.model';
import {HexColorSchemeModel} from '../../../models/styles/color/hex.color.scheme.model';

@Injectable()
export class ColorService {
  public prepareColor(prevColor: string, nextColor: string) : string {
    const nextRgb = this.getAsRgb(nextColor);
    return nextRgb ? nextRgb.toString() : nextColor;
  }

  public getAsRgb(color: string) : RgbColorSchemeModel {
    if (!color) return null;

    const colorModel = new ColorModel(color);

    if (!colorModel || colorModel.colorScheme !== ColorModel.colorSchemas.RGBA) return null;

    return colorModel.rgbModel;
  }

  public getAsHex(color: string) : HexColorSchemeModel {
    if (!color) return null;

    const colorModel: ColorModel = new ColorModel(color);

    if (!colorModel) return;

    return colorModel.hexModel;
  }
}
