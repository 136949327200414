import {Component, Input} from '@angular/core';

import {AppAnimations} from "../../../../../../app-animations";

@Component({
  selector: 'app-image-manager-large-view',
  templateUrl: './image-manager-large-view.component.html',
  styleUrls: ['./image-manager-large-view.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class ImageManagerLargeViewComponent {
  @Input() image;

  getImageUrl() {
    return `https://${this.image.paths.DEFAULT}`;
  }

}
