import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {StudentImageReviewModel} from '../../../../../../../../../models/images/review/student-image-review.model';
import {StudentImageReviewDto} from '../../../../../../../../../models/images/review/student-image-review.dto';
import {ImageModel} from '../../../../../../../../../models/images/image.model';
import {ImageDto} from '../../../../../../../../../models/images/image.dto';
import {ImageUpdateSuggestionModel} from '../../../../../../../../../models/education/image-update-suggestion/image-update-suggestion.model';
import {ImageUpdateSuggestionDto} from '../../../../../../../../../models/education/image-update-suggestion/image-update-suggestion.dto';
import {IUpdateResponse} from '../../../../../../../../../models/responses/i-update-response';

@Injectable()
export class EducationStudentsImagesHttpService {
  private prefix: string = 'api/education/teachers/current';

  constructor(private http: HttpClient) {
  }

  public cloneImage(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, targetPortfolioId: number): Observable<any> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}`, { targetPortfolioId });
  }

  public sendAction(institutionId: number, classId: number, studentId: number, portfolioId: number, userId: number, websiteId: number, action: string): Observable<any> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/action`, { userId, websiteId, action });
  }

  public sendImageReviewUpdateNotification(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number): Observable<any> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/image-review-update-notification`, null);
  }

  public getReview(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number): Observable<StudentImageReviewModel> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/reviews`).pipe(
      map((res: StudentImageReviewDto) => res ? StudentImageReviewDto.normalize(res) : null),
    );
  }

  public getExhibitedImages(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number): Observable<ImageModel[]> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/exhibited-images`).pipe(
      map((res: ImageDto[]) => res ? res.map((image: ImageDto) => ImageDto.normalize(image)) : null),
    );
  }

  public addReview(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, review: StudentImageReviewModel): Observable<StudentImageReviewModel> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/reviews`, review).pipe(
      map((res: StudentImageReviewDto) => StudentImageReviewDto.normalize(res))
    );
  }

  public updateReview(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, review: StudentImageReviewModel): Observable<IUpdateResponse> {
    return this.http.put(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/reviews/${review.id}`, review);
  }

  public getUpdateSuggestionsForImage(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number): Observable<ImageUpdateSuggestionModel[]> {
    return this.http.get(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/updates`).pipe(
      map((res: ImageUpdateSuggestionDto[]) => res ? res.map((item: ImageUpdateSuggestionDto) => ImageUpdateSuggestionDto.normalize(item)) : null)
    );
  }

  public addUpdateSuggestion(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, updateSuggestion: ImageUpdateSuggestionModel): Observable<ImageUpdateSuggestionModel> {
    return this.http.post(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/updates`, updateSuggestion).pipe(
      map((res: ImageUpdateSuggestionDto) => res ? ImageUpdateSuggestionDto.normalize(res) : null)
    );
  }

  public deleteUpdateSuggestion(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, updateSuggestionId: number): Observable<any> {
    return this.http.delete(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/updates/${updateSuggestionId}`);
  }

  public removeClonedImage(institutionId: number, classId: number, studentId: number, portfolioId: number, imageId: number, targetPortfolioId: number): Observable<any> {
    const params: HttpParams = new HttpParams().set('targetPortfolioId', `${targetPortfolioId}`);

    return this.http.delete(`${this.prefix}/institutions/${institutionId}/classes/${classId}/students/${studentId}/portfolios/${portfolioId}/images/${imageId}/cloned`, { params });
  }
}
