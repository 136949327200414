import {Component, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';

@Component({
  selector: 'app-educator-admin',
  templateUrl: './educator-admin.component.html',
  styleUrls: ['./educator-admin.component.scss']
})
export class EducatorAdminComponent implements OnDestroy {
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
