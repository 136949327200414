// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .modal {
  z-index: 10002;
}

.text-alert-modal-body {
  font-size: 13pt;
  line-height: 17pt;
  margin: 0 20px 10px 20px;
}
.text-alert-modal-body .btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.text-alert-modal-body .btn-wrapper button {
  margin: 0 15px;
  width: 80px;
  font-size: 14px;
}
.text-alert-modal-body .text-wrapper {
  font-size: 0.9em;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modals/text-alert-modal/text-alert-modal.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,wBAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AADJ;AAGI;EACE,cAAA;EACA,WAAA;EACA,eAAA;AADN;AAKE;EACE,gBAAA;EACA,kCAAA;EACA,YAAA;AAHJ","sourcesContent":[":host ::ng-deep {\n  .modal {\n    z-index: 10002;\n  }\n}\n\n.text-alert-modal-body {\n  font-size: 13pt;\n  line-height: 17pt;\n  margin: 0 20px 10px 20px;\n\n  .btn-wrapper {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n\n    button {\n      margin: 0 15px;\n      width: 80px;\n      font-size: 14px;\n    }\n  }\n\n  .text-wrapper {\n    font-size: .9em;\n    padding: .5rem .5rem 1rem .5rem;\n    margin: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
