import {BlocksChangelogsModel} from './blocks-changelogs.model';

export class BlocksChangelogsDto {
  constructor(public Id?: number,
              public BlockTemplateId?: number,
              public Message?: string,
              public CreatedAt?: string) {}

  public static normalize(res: BlocksChangelogsDto): BlocksChangelogsModel {
    if (!res) return null;

    return new BlocksChangelogsModel(
      res.Id,
      res.BlockTemplateId,
      res.Message,
      res.CreatedAt,
    );
  }

  public static toRequest(res: BlocksChangelogsModel): BlocksChangelogsDto {
    if (!res) return null;

    return new BlocksChangelogsDto(
      res.id,
      res.blockTemplateId,
      res.message,
      res.createdAt,
    );
  }
}
