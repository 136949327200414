import {IStyleModelOptions} from '../../option/i-style-model-options';

import {SetupOptionModel} from '../../option/setup-option.model';

export class BaseColorModel extends SetupOptionModel {
  public element: HTMLElement;
  public selectedElement: HTMLElement;
  public defaultValueHolderElement: HTMLElement;

  constructor(
    public key: string,
    public type: string,
    public propertyName: string,
    public stylePropertyName: string,
    public value?: string,
    public isEnabled: boolean = true,
    public isStylePropertyShouldBeSet: boolean = true,
    public isInitOnResetRequired: boolean = true,
  ) {
    super(key, type, value, isEnabled);
  }

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.isEnabled = this.isEnabled && !!element;

    if (!this.isEnabled) return;

    this.element = element;
    this.selectedElement = options && options.selectedElement ? options.selectedElement : null;
    this.defaultValueHolderElement = options && options.defaultValueHolderElement ? options.defaultValueHolderElement : null;

    this.value = this.getDefaultValue();
  }

  public reset(): void {
    if (this.stylePropertyName) this.element.style.removeProperty(this.stylePropertyName);

    this.element.style.removeProperty(this.propertyName);

    if (!this.isInitOnResetRequired) {
      this.value = this.getDefaultValue();

      return;
    }

    const computed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    this.onChange(computed.getPropertyValue(this.propertyName) || computed.getPropertyValue(this.stylePropertyName));
  }

  public onChange(value: string): void {
    this.value = value || this.getDefaultValue();

    if (!this.isEnabled) return;

    this.element.style.setProperty(this.propertyName, this.value);

    if (this.isStylePropertyShouldBeSet && this.stylePropertyName) this.element.style.setProperty(this.stylePropertyName, this.value);
  }

  private getDefaultValue(): string {
    const computed: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: string = computed.getPropertyValue(this.propertyName) || computed.getPropertyValue(this.stylePropertyName);

    if (!this.defaultValueHolderElement) return value || '';

    return value || window.getComputedStyle(this.defaultValueHolderElement).getPropertyValue(this.stylePropertyName);
  }
}
