import {IStyleModelOptions} from '../../option/i-style-model-options';
import {IPaddingStyleOption} from '../../option/i-padding-style-option';

import {STYLE_PROPERTIES, OPTIONS} from './constants';

export class MenuLogoPaddingModel implements IPaddingStyleOption {
  public element: HTMLElement;

  public isValuesEqual: boolean = false;

  public values = {};

  private device: string = '';

  constructor(
    public key: string,
    public type: string,
    public value?: any,
    public isEnabled: boolean = true,
  ) {}

  public init(element: HTMLElement, options: IStyleModelOptions): void {
    this.element = element ? <HTMLElement>element.closest('[data-menu-block]') : null;
    this.isEnabled = !!this.element;

    if (!this.isEnabled) {
      return;
    }

    this.device = options.device;

    const computedStyles = window.getComputedStyle(this.element);

    OPTIONS.forEach(option => {
      this.values[option.key] = Number.parseInt(computedStyles.getPropertyValue(STYLE_PROPERTIES[this.device][option.key])) || 0;
    });

    this.value = Object.values(this.values)[0];

    this.setIsValuesEqual();
  }

  public onChange({ key, value }): void {
    if (!this.isEnabled || !this.element) {
      return;
    }

    if (!key) {
      this.value = value;
      this.isValuesEqual = true;

      return OPTIONS.forEach(option => {
        this.values[option.key] = value;
        this.element.style.setProperty(STYLE_PROPERTIES[this.device][option.key], value);
      });
    }

    this.values[key] = value;

    this.element.style.setProperty(STYLE_PROPERTIES[this.device][key], value);

    this.setIsValuesEqual();
  }

  public reset(key): void {
    if (!this.isEnabled || !this.element) {
      return;
    }

    if (!key) {
      return this.onChange({ key: void 0, value: 0 });
    }

    this.element.style.removeProperty(STYLE_PROPERTIES[this.device][key]);

    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this.element);

    const value: number = Number.parseInt(computedStyles.getPropertyValue(STYLE_PROPERTIES[this.device][key]));

    this.values[key] = Number.isNaN(value) ? 0 : value;

    this.setIsValuesEqual();

    if (!this.isValuesEqual) {
      return;
    }

    this.value = 0;
  }

  private setIsValuesEqual(): void {
    this.isValuesEqual = Object.values(this.values).every((value, i, arr) => value === arr[0]);
  }
}
