import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {Subject, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';

import {ModalsService} from '../../../services/modals/modals.service';
import {AddBlogModalService} from '../../../services/modals/add-blog/add-blog-modal.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {PagesService} from '../../../../application/sidebar-short/sidebar/pages/pages.service';
import {NavigationService} from '../../../../services/navigation.service';
import {ImageManagerService} from '../../../../application/main/image-manager/image-manager.service';

import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';
import {NodeModel} from '../../../../core/models/nodes/node.model';

import {PAGE_GROUP_TYPES} from '../../../../application/sidebar-short/sidebar/pages/tree/constants';

@Component({
  selector: 'app-add-blog-modal',
  templateUrl: './add-blog-modal.component.html',
  styleUrls: ['./add-blog-modal.component.scss'],
})
export class AddBlogModalComponent implements OnInit {
  @ViewChild('titleInput') titleInput: ElementRef;
  
  public title: string = '';

  public isSaving: boolean = false;
  public isError: boolean = false;
  public isBothMenus: boolean = false;
  public isTitleValid: boolean = true;

  public errorMessage: string = '';

  private website: WebsiteModel;

  private nodes: NodeModel[];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.service.id;
  }

  public get inappropriateSymbolsError(): string {
    return this.pagesService.validationMessages[this.pagesService.validationErrorsKeys.inappropriateSymbols];
  }

  constructor(private service: AddBlogModalService,
              private modalsService: ModalsService,
              private navigationService: NavigationService,
              private imageManagerService: ImageManagerService,
              private websitesService: WebsitesService,
              private templatesService: TemplatesService,
              private nodesService: NodesService,
              public pagesService: PagesService) {}

  public ngOnInit(): void {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.nodes = nodes;
    }); 

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.isBothMenus = template && template.menuType === 'BOTH';
    });
  }
  public onInputChange(): void {
    const title: string = this.titleInput.nativeElement.value.trim();

    this.isTitleValid = this.pagesService.PAGE_NAME_REGEXP.test(title);
  }

  public onSave() {
    if (this.isSaving || !this.isTitleValid) {
      return;
    }

    this.isError = this.title.trim().length === 0 || !this.title.match(this.pagesService.PAGE_NAME_REGEXP);

    this.errorMessage = '';

    if (this.isError) {
      return;
    }

    this.isSaving = true;

    const node: NodeModel = this.getNewNodeData();

    this.pagesService.addNode({ node, templateId: this.website.templateId }).pipe(
      catchError(e => {
        this.errorMessage = e || 'Something went wrong. Please, try again.';

        this.isSaving = false;

        return throwError(() => e);
      })
    ).subscribe(page => {
      this.nodesService.fetchNodes().add(() => {
        this.onNewPageAdded(page);
      });

      this.isSaving = false;

      this.modalsService.close(this.id);
    });
  }

  private getNewNodeData(): NodeModel {
    const groupType: string = PAGE_GROUP_TYPES.HORIZONTAL;

    return new NodeModel(
      null,
      null,
      0,
      this.getSequence(),
      null,
      this.title.trim(),
      'B',
      null,
      null,
      null,
      null,
      false,
      false,
      null,
      null,
      groupType,
      true,
      true,
      false,
      true,
      false,
    );
  }

  private getSequence(): number {
    if (!this.isBothMenus) return 1;

    const nodes: NodeModel[] = this.nodes.filter(n => n.groupType === PAGE_GROUP_TYPES.HORIZONTAL);

    const prevNode = nodes.length === 0 ? this.nodes[this.nodes.length - 1] : nodes[nodes.length - 1];

    return prevNode ? prevNode.nodeSequence + 1 : 1;
  }

  private onNewPageAdded(page) {
    const options = {
      id: page.NodeKey,
      type: page.NodeType,
      isPageExists: page.IsPageExists,
      isSplash: false,
    };

    this.imageManagerService.onImageSelectSubject.next(null);

    return this.navigationService.toPageEditor(options);
  }

  public onCancel(): void {
    this.modalsService.close(this.id);
  }
}
