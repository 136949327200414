import {Component, Input} from '@angular/core';

import {WrongImageTypeModalService} from '../../../services/modals/wrong-image-type/wrong-image-type-modal.service';

@Component({
  selector: 'app-wrong-image-type-modal',
  templateUrl: './wrong-image-type-modal.component.html',
  styleUrls: ['./wrong-image-type-modal.component.scss'],
})
export class WrongImageTypeModalComponent {
  @Input() id: string;

  constructor(public service: WrongImageTypeModalService) {}

  public submitHandler(): void {
    this.service.close();
  }
}
