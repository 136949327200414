import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as PdfJs from 'pdfjs-dist/build/pdf';
import * as PdfJsWorker from 'pdfjs-dist/build/pdf.worker.entry';

PdfJs.GlobalWorkerOptions.workerSrc = PdfJsWorker;

@Injectable()
export class PdfThumbnailsService {
  private width = 210;

  constructor() {
  }

  public getThumb(file: File): Observable<Blob> {
    return new Observable(observer => {
      this.readFile(file).subscribe((typedArray: Uint8Array) => {
        this.createThumb(typedArray).then(blob => {
          observer.next(blob);
          observer.complete();
        });
      });
    });
  }

  private readFile(file: File): Observable<Uint8Array> {
    return new Observable<Uint8Array>(observer => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        observer.next(new Uint8Array(<ArrayBuffer>fileReader.result));

        observer.complete();
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  // async ok
  private async createThumb(typedArray: Uint8Array): Promise<Blob> {
    return new Promise(resolve => {
      this.renderPage(typedArray).then(canvas => canvas.toBlob(resolve));
    });
  }

  // async ok
  private async renderPage(typedArray: Uint8Array): Promise<HTMLCanvasElement> {
    const canvas: HTMLCanvasElement = document.createElement('canvas');

    const pdf: PdfJs.PDFDocumentProxy = await PdfJs.getDocument(typedArray).promise;
    const page: PdfJs.PDFPageProxy = await pdf.getPage(1);

    let viewport: PdfJs.PageViewport = page.getViewport({ scale: 1.0 });

    viewport = page.getViewport({ scale: this.width / viewport.width });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    await page.render({
      canvasContext: canvas.getContext('2d'),
      viewport: viewport,
    }).promise;

    return canvas;
  }
}
