import {Component, Input, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PublishingStatusModalService} from '../../../services/modals/publishing-status/publishing-status-modal.service';

import {PublishingStepModel} from '../../../../core/models/publishing/step/publishing-step.model';
import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';

import {AppAnimations} from '../../../../app-animations';

@Component({
  selector: 'app-publishing-status-modal',
  templateUrl: './publishing-status-modal.component.html',
  styleUrls: ['./publishing-status-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PublishingStatusModalComponent implements OnDestroy {
  @Input() id: string;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public steps: PublishingStepModel[] = [];

  public modalHeader: ModalHeader = new ModalHeader('Publishing...', 'neutral-header');

  public buttons: Button[] = [
    {
      text: 'Close',
      className: 'neutral ok-button',
      onClick: this.onClose.bind(this),
    },
  ];

  constructor(private service: PublishingStatusModalService) {
    this.service.stepsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((steps: PublishingStepModel[]) => {
      this.steps = steps;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private onClose(): void {
    this.service.onCancel();
  }
}
