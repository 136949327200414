import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable()
export class WebsitesNodesHttpService {
  constructor(private httpClient: HttpClient) {
  }

  public updateHomePage({ websiteId, nodeId }: { websiteId: number, nodeId: number }): Observable<{ success: boolean }> {
    return this.httpClient.put<{ success: boolean }>(`api/websites/${websiteId}/nodes/home`, { nodeId });
  }

  public updateIsOriginalHomePageRemoved({ websiteId, isRemoved }: { websiteId: number, isRemoved: boolean }): Observable<{ success: boolean }> {
    return this.httpClient.put<{ success: boolean }>(`api/websites/${websiteId}/nodes/home/visibility`, { isRemoved });
  }
}
